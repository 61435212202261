import * as apis from '..';
const localApis = {
  testApi: {
    url: '',
    type: ''
  },
  fleetCompanies: {
    url: '/company/fleets',
    type: 'get'
  },
  companyList: {
    url: '/company/list',
    type: 'get'
  },
  scoreCardApi: {
    url: 'scorecard/company/:id/allStats',
    type: 'get'
  },
  quickStatsApi: {
    url: 'scorecard/company/:id/quickStats',
    type: 'get'
  },
  quickStatsListApi : {
    url: 'scorecard/company/:id/events',
    type: 'post'
  },
  scorecardChart: {
    url: '/scorecard/events/chart',
    type: 'post'
  },
  newScorecardChart: {
    url: '/scorecard/overview?companyId=:companyId',
    type: 'get'
  },
  scorecardReportsApi: {
    url: 'scorecard/events',
    type: 'post'
  },
  scoreCardRadiusApi: {
    url: 'scorecard/company/:id/radiusViolations',
    type: 'get'
  },
  getFleetCompanyDefaultsApi: {
    url: '/company/new',
    type: 'get',
  },
  addFleetCompanyApi: {
    url: '/company/new',
    type: 'post'
  },
  getScoresApi: {
    url: '/company/scoreConfiguration?companyId={id}',
    type: 'get'
  },
  updateScoresApi: {
    url: '/company/scoreConfiguration',
    type: 'post'
  },
  getFleetCompanyDetailsApi: {
    url: 'company/edit',
    type: 'get',
    isQueryString: true
  },
  editFleetCompanyApi: {
    url: '/company/edit',
    type: 'post'
  }
};
const getApi = (api) => {
  return localApis[api] || apis[api];
};
export default getApi;
