import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

#root {
  height: 100%;
}
@font-face {
    font-family: 'SF Pro Display';
    src: url('${process.env.CDN_URL}/clutch/fonts/SFProDisplay/SFProDisplay.eot');
    src: url('${process.env.CDN_URL}/clutch/fonts/SFProDisplay/SFProDisplay.eot?#iefix') format('embedded-opentype'), url('${process.env.CDN_URL}/clutch/fonts/SFProDisplay/SFProDisplay.woff') format('woff'), url('${process.env.CDN_URL}/clutch/fonts/SFProDisplay/SFProDisplay.ttf') format('truetype');
}
@font-face {
    font-family: 'SF Pro Display - Semibold';
    src: url('${process.env.CDN_URL}/clutch/fonts/SFProDisplay-Semibold/SFProDisplay-Semibold.eot');
    src: url('${process.env.CDN_URL}/clutch/fonts/SFProDisplay-Semibold/SFProDisplay-Semibold.eot?#iefix') format('embedded-opentype'), url('${process.env.CDN_URL}/clutch/fonts/SFProDisplay-Semibold/SFProDisplay-Semibold.woff') format('woff'), url('${process.env.CDN_URL}/clutch/fonts/SFProDisplay-Semibold/SFProDisplay-Semibold.ttf') format('truetype');
}
@font-face {
  font-family: 'NotoSans-Bold';
  src: url('${process.env.CDN_URL}/webfleet/fonts/NotoSans-Bold/NotoSans-Bold.ttf');
}
@font-face {
  font-family: 'NotoSans-Regular';
  src: url('${process.env.CDN_URL}/webfleet/fonts/NotoSans-Regular/NotoSans-Regular.ttf');
}
/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFWJ0bbck.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFUZ0bbck.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFWZ0bbck.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVp0bbck.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFWp0bbck.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFW50bbck.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0b.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
`;
export default GlobalStyle;
