import lodash from 'lodash';
import moment from 'moment';
import { AUTH_TOKEN, COOKIE_AUTH_DETAILS, ROLES } from '../config/constants/keys';
import { getCookie } from '../services/cookie';
import { getLocalStorage, setLocalStorage } from '../services/storage';
import { phoneRegExp } from './constants';
import esKey from "./esKey";

import es from "date-fns/locale/es";
import { registerLocale } from "react-datepicker";
const serviceProvider = window.$environment?.SERVICE_PROVIDER;
const appLayout = window.$environment.CURRENT_LAYOUT;
const { Parser } = require('json2csv');
export const removeListItem = (source, field, value) => {
  for (let i = 0; i < source.length; i++) {
    const item = source[i];
    if (item[field] === value) {
      source.splice(i, 1);
    }
  }
  return source;
};

export const getCompanyId = () => {
  // const auth = getAuthDetails();
  // return auth?.enterpriseFeatureDto?.companyId
  return getCookie('companyId');
}
export const getCompanyTimeZone = () => {
  const auth = getCookie(COOKIE_AUTH_DETAILS);
  return auth.timeZone
}
export const getDotNumber = () => {
  // const auth = getCookie(COOKIE_AUTH_DETAILS);
  // return auth.dotNumber ? auth.dotNumber : ''
  return getCookie('dotNumber')
}

export const getCameraUserId = () => {
  // const auth = getCookie(COOKIE_AUTH_DETAILS);
  // return auth.cameraPortalUserId ? auth.cameraPortalUserId : ''
  return getCookie('cameraPortalUserId');
}

export const hasRestrictedRole = (permittedRoles) => {
  const auth = getAuthDetails();
  if (permittedRoles.length && auth?.auth) {
    if (
      permittedRoles.includes('ADMIN') &&
      (auth.auth.includes('OWNER') || auth.auth.includes('FLMANAGER'))
    ) {
      return false;
    } else {
      const result = permittedRoles.some((r) => {
        return auth.auth.includes(r);
      });
      return result;
    }
  } else {
    return true;
  }
};

export const hasRestrictedCommmonRole = (permittedRoles, excludedRoles) => {
  // get the user's authentication details
  const auth = getAuthDetails();

  // check if permittedRoles is not empty and if auth is defined and has a role array
  if (permittedRoles.length && auth?.auth) {
    // if the length of the user's role array matches the length of the permittedRoles array
    if (auth.auth.length === permittedRoles.length) {
      // check if every role in the user's role array is included in the permittedRoles array
      return auth.auth.every(role => permittedRoles.includes(role));
    }
    // if the length of the user's role array is one more than the length of the permittedRoles array
    else if (auth.auth.length === permittedRoles.length + 1) {
      // create a copy of the user's role array
      const authRoles = [...auth.auth];
      // if excludedRoles is an array and not empty
      if (Array.isArray(excludedRoles) && excludedRoles.length > 0) {
        // loop through the excludedRoles array
        excludedRoles.forEach(role => {
          // find the index of the role in the authRoles array
          const excludedRoleIndex = authRoles.indexOf(role);
          // if the role is found, remove it from the authRoles array
          if (excludedRoleIndex >= 0) {
            authRoles.splice(excludedRoleIndex, 1);
          }
        });
      }
      // check if every role in the updated authRoles array is included in the permittedRoles array
      return authRoles.every(role => permittedRoles.includes(role));
    }
  }
  // return false if the user does not have any common role with the permittedRoles
  return false;
}

export const hasEnabledSettings = (module) => {
  const auth = getCookie(COOKIE_AUTH_DETAILS)
  if (auth) {
    const { settings } = auth;
    return settings?.[module] || false
  } else return false
}

export const removeEmptyValues = (obj) => {
  const newObj = {};
  for (let key in obj) {
    if (obj[key] !== '' && obj[key] !== null) {
      newObj[key] = obj[key];
    }
  }
  return newObj;
}

export const hasEnabledFeature = (module) => {
  const auth = getAuthDetails();
  if (auth) {
    const { enterpriseFeatureDto } = auth;
    return (
      (enterpriseFeatureDto?.[module] !== undefined &&
        enterpriseFeatureDto?.[module]) ||
      enterpriseFeatureDto?.[module] === undefined
    );
  } else {
    return true;
  }
};

export const enterpriseFeaturesKeyMapperHandler = (auth) => {
  if (Object.keys(auth).length && auth?.enterpriseFeaturesDto) {
    return {
      enterpriseFeatureDto: {
        companyId: auth?.companyId,
        usDotManagementenabled: auth?.enterpriseFeaturesDto['US_DOT_MANAGEMENT'],
        chatEnabled: auth?.enterpriseFeaturesDto['CHAT'],
        bulkDownloadEnabled: auth?.enterpriseFeaturesDto['BULK_DOWNLOAD'],
        nonVehicleEnabled: auth?.enterpriseFeaturesDto['NON_VEHICLE'],
        dotTripEnabled: auth?.enterpriseFeaturesDto['NON_DOT_TRIP'],
        checkLoadAlertEnabled: auth?.enterpriseFeaturesDto['CHECK_LOAD_ALERT'],
        chartEnabled: auth?.enterpriseFeaturesDto['CHART'],
        advancedDvirEnabled: auth?.enterpriseFeaturesDto['ADVANCED_DVIR'],
        editDrTimeToYmAndPcEnabled: auth?.enterpriseFeaturesDto['EDIT_DR_TIME_TO_YM_AND_PC'],
        udBulkEditEnabled: auth?.enterpriseFeaturesDto['UD_BULK_EDIT'],
        partsInventoryEnabled: auth?.enterpriseFeaturesDto['INVENTORY_PARTS'],
        geometrisEnabled: auth?.enterpriseFeaturesDto['GEOMETRIS'],
        editOilFieldWaitingEnabled: auth?.enterpriseFeaturesDto['EDIT_OIL_FIELD_WAITING'],
        csaViolationFieldWaitingEnabled: auth?.enterpriseFeaturesDto['CSA_VIOLATION'],
        reportsEnabled: auth?.enterpriseFeaturesDto['REPORTS'],
        cycleRuleEnabled: auth?.enterpriseFeaturesDto['CYCLE_RULE'],
        customDvirEnabled: auth?.enterpriseFeaturesDto['CUSTOM_DVIR'],
        cameraIntegrationEnabled: auth?.enterpriseFeaturesDto['CAMERA_INTEGRATION'],
        spireonReportsEnabled: auth?.enterpriseFeaturesDto['SPIREON_REPORTS'],
        dvirOnlyEnabled: auth?.enterpriseFeaturesDto['DVIR_ONLY'],
        dvirOnlyUserEnabled: auth?.enterpriseFeaturesDto['DVIR_ONLY_USER'],
        fleetOpsEnabled: auth?.enterpriseFeaturesDto['FLEETOPS'],
        canadaComplianceEnabled: auth?.enterpriseFeaturesDto['CANADA_COMPLIANCE'],
        cycleRuleUsaEnabled: auth?.enterpriseFeaturesDto['CYCLE_RULE_USA'],
        cycleRuleCanadaEnabled: auth?.enterpriseFeaturesDto['CYCLE_RULE_CANADA'],
        realTimeLogsEnabled: auth?.enterpriseFeaturesDto['REAL_TIME_LOGS'],
        inspectionPdfCanadaEnabled: auth?.enterpriseFeaturesDto['INSPECTION_PDF_CANADA'],
        cycleRuleCaliforniaEnabled: auth?.enterpriseFeaturesDto['CYCLE_RULE_CALIFORNIA'],
        cycleRuleFloridaEnabled: auth?.enterpriseFeaturesDto['CYCLE_RULE_FLORIDA'],
        customPoliciesEnabled: auth?.enterpriseFeaturesDto['CUSTOM_POLICIES'],
        downloadCenterEnabled: auth?.enterpriseFeaturesDto['DOWNLOAD_CENTER'],
        smoothTrackingEnabled: auth?.enterpriseFeaturesDto['SMOOTH_TRACKING'],
        taxableMilesViewEnabled: auth?.enterpriseFeaturesDto['IFTA_UPDATE_CENTER'],
      },
      subscriptions: {
        wDashboard: auth?.enterpriseFeaturesDto['DASHBOARD_WEB'],
        wLogs: auth?.enterpriseFeaturesDto['LOGS_WEB'],
        wUsersList: auth?.enterpriseFeaturesDto['USER_LIST_WEB'],
        wUserDetails: auth?.enterpriseFeaturesDto['USER_DETAILS_WEB'],
        wVehiclesList: auth?.enterpriseFeaturesDto['VEHICLE_LIST_WEB'],
        wVehicleDetails: auth?.enterpriseFeaturesDto['VEHICLE_DETAILS_WEB'],
        wAccidents: auth?.enterpriseFeaturesDto['ACCIDENTS_WEB'],
        wReportAndMaintenance: auth?.enterpriseFeaturesDto['REPAIR_AND_MAINTENANCE_WEB'],
        wInspections: auth?.enterpriseFeaturesDto['INSPECTIONS_WEB'],
        wFuelConsumptionReport: auth?.enterpriseFeaturesDto['FUEL_CONSUMPTION_REPORT_WEB'],
        wIFTAReport: auth?.enterpriseFeaturesDto['IFTA_REPORT_WEB'],
        wPoliciesAndProcedures: auth?.enterpriseFeaturesDto['POLICIES_AND_PROCEDURES_WEB'],
        wCompanyDocuments: auth?.enterpriseFeaturesDto['COMPANY_DOCUMENTS_WEB'],
        wDailyDocuments: auth?.enterpriseFeaturesDto['DAILY_DOCUMENTS_WEB'],
        wMechanicLogin: auth?.enterpriseFeaturesDto['MECHANIC_LOGIN_WEB'],
        wCompanySummary: auth?.enterpriseFeaturesDto['COMPANY_SUMMARY_WEB'],
        wAlerts: auth?.enterpriseFeaturesDto['ALERTS_WEB'],
        wIbeacon: auth?.enterpriseFeaturesDto['IBEACON_WEB'],
      }
    }
  } else {
    return {
      enterpriseFeatureDto: {},
      subscriptions: {}
    }
  }
}

export const getAuthDetails = (isParsingNeeded = true) => {
  const auth = getCookie(COOKIE_AUTH_DETAILS);
  if (isParsingNeeded) {
    if (auth && Object.keys(auth).length) {
      return (
        {
          ...parseJwt(auth[AUTH_TOKEN]),
          enterpriseFeatureDto: enterpriseFeaturesKeyMapperHandler(auth)?.enterpriseFeatureDto,
          subscriptions: enterpriseFeaturesKeyMapperHandler(auth)?.subscriptions,
          adminSuspended: auth.adminSuspended,
          userId: auth?.userId
        } || null
      );
    } else {
      return auth ? { ...auth, enterpriseFeatureDto: enterpriseFeaturesKeyMapperHandler(auth)?.enterpriseFeatureDto, subscriptions: enterpriseFeaturesKeyMapperHandler(auth)?.subscriptions } : null;
    }
  } else {
    return auth ? { ...auth, enterpriseFeatureDto: enterpriseFeaturesKeyMapperHandler(auth)?.enterpriseFeatureDto, subscriptions: enterpriseFeaturesKeyMapperHandler(auth)?.subscriptions } : null;
  }
};

const handleGetImagePathFailure = (file) => {
  try {
    const s = require(`@/assets/${serviceProvider}/${file}`);
    return s;
  } catch (error) {
    return null
  }
}

export const getLogo = (file) => {
  if (window.$environment.SERVICE_PROVIDER == 'eldx') return `${window.$environment.S3_URL}/${JSON.parse(sessionStorage.getItem("reseller_data"))?.logo}`
  else return getImagePath(file)
}
export const getImagePath = (file) => {
  if (file) {
    try {
      const s = require(`@/assets/${serviceProvider}/${appLayout}/${file}`);
      return s;
    } catch (error) {
      const image = handleGetImagePathFailure(file)
      return image;
    }
  }
};

export const isCRMImport = () => {
  return window?.$environment?.SERVICE_PROVIDER === 'riskTheory' && hasRestrictedRole([ROLES.UNDER_WRITER, ROLES.LOSS_CONTROL, ROLES.CLAIM_ADJUSTER]);
}

export const removeDuplicates = (arr) => {
  const seen = new Set();
  return arr.filter(obj => {
    const stringified = JSON.stringify(obj);
    if (!seen.has(stringified)) {
      seen.add(stringified);
      return true;
    }
    return false;
  });
}

export const getLastQuaters = (lastQuater) => {
  let today = new Date()
  let quaters = {}
  let currentMonth = new Date().getMonth()
  if (currentMonth > 8) quaters = {
    ['Q1' + ' - ' + today.getFullYear()]: [moment(new Date(today.getFullYear(), 0)).startOf('month'), moment(new Date(today.getFullYear(), 2)).endOf('month')],
    ['Q2' + ' - ' + today.getFullYear()]: [moment(new Date(today.getFullYear(), 3)).startOf('month'), moment(new Date(today.getFullYear(), 5)).endOf('month')],
    ['Q3' + ' - ' + today.getFullYear()]: [moment(new Date(today.getFullYear(), 6)).startOf('month'), moment(new Date(today.getFullYear(), 8)).endOf('month')],
    ['Q4' + ' - ' + today.getFullYear()]: [moment(new Date(today.getFullYear(), 9)).startOf('month'), moment(new Date(today.getFullYear(), 11)).endOf('month')]
  }
  else if (currentMonth > 5) {
    quaters = {
      ['Q4' + ' - ' + (today.getFullYear() - 1)]: [moment(new Date((today.getFullYear() - 1), 9)).startOf('month'), moment(new Date((today.getFullYear() - 1), 11)).endOf('month')],
      ['Q1' + ' - ' + today.getFullYear()]: [moment(new Date(today.getFullYear(), 0)).startOf('month'), moment(new Date(today.getFullYear(), 2)).endOf('month')],
      ['Q2' + ' - ' + today.getFullYear()]: [moment(new Date(today.getFullYear(), 3)).startOf('month'), moment(new Date(today.getFullYear(), 5)).endOf('month')],
      ['Q3' + ' - ' + today.getFullYear()]: [moment(new Date(today.getFullYear(), 6)).startOf('month'), moment(new Date(today.getFullYear(), 8)).endOf('month')],
    }
  } else if (currentMonth > 2) {
    quaters = {
      ['Q3' + ' - ' + (today.getFullYear() - 1)]: [moment(new Date((today.getFullYear() - 1), 6)).startOf('month'), moment(new Date((today.getFullYear() - 1), 8)).endOf('month')],
      ['Q4' + ' - ' + (today.getFullYear() - 1)]: [moment(new Date((today.getFullYear() - 1), 9)).startOf('month'), moment(new Date((today.getFullYear() - 1), 11)).endOf('month')],
      ['Q1' + ' - ' + today.getFullYear()]: [moment(new Date(today.getFullYear(), 0)).startOf('month'), moment(new Date(today.getFullYear(), 2)).endOf('month')],
      ['Q2' + ' - ' + today.getFullYear()]: [moment(new Date(today.getFullYear(), 3)).startOf('month'), moment(new Date(today.getFullYear(), 5)).endOf('month')],
    }
  } else {
    quaters = {
      ['Q2' + ' - ' + (today.getFullYear() - 1)]: [moment(new Date((today.getFullYear() - 1), 3)).startOf('month'), moment(new Date((today.getFullYear() - 1), 5)).endOf('month')],
      ['Q3' + ' - ' + (today.getFullYear() - 1)]: [moment(new Date((today.getFullYear() - 1), 6)).startOf('month'), moment(new Date((today.getFullYear() - 1), 8)).endOf('month')],
      ['Q4' + ' - ' + (today.getFullYear() - 1)]: [moment(new Date((today.getFullYear() - 1), 9)).startOf('month'), moment(new Date((today.getFullYear() - 1), 11)).endOf('month')],
      ['Q1' + ' - ' + today.getFullYear()]: [moment(new Date(today.getFullYear(), 0)).startOf('month'), moment(new Date(today.getFullYear(), 2)).endOf('month')],
    }
  }
  if (lastQuater) return Object.values(quaters)[3]
  else return quaters
}

export const getDateLocale = () => {
  if (getLocalStorage('Language') && getLocalStorage('Language') == 'es') {
    registerLocale("es", es);
    return "es"
  }
  else return false
}
export const getFilterLocaleValues = (values, i18n) => {
  if (getLocalStorage('Language') && getLocalStorage('Language') == 'es' && values) {
    let returnValues = JSON.parse(JSON.stringify(values))
    returnValues.forEach(ele => {
      if (ele && ele.label) ele.label = i18n.t(ele.label.trim())
    })
    return returnValues
  }
  else return values
}
export const getEsKey = (value) => {
  if (getLocalStorage('Language') && getLocalStorage('Language') == 'es') {
    let returnVal = ''
    Object.keys(esKey).forEach(ele => {
      if (esKey[ele] == value) returnVal = ele
    })
    returnVal = returnVal ? returnVal : value
    return returnVal
  }
  else return value
}
export const hasValue = (source, key, value) => {
  if (source) {
    for (let index = 0; index < source.length; index++) {
      const element = source[index];

      if (value && element[key] === value) {
        return element;
      }
    }
    return null;
  } else {
    return null;
  }
};

export const getLanguageCode = (code) => {
  return code === 'en' ? 'es' : 'en';
};
export const setLanguage = (i18n) => {
  const currentCode = getLanguageCode(i18n.language);
  if (currentCode !== i18n.language) {
    setLocalStorage('Language', currentCode);
    i18n.changeLanguage(currentCode).then(() => {
      // if (window.location.href.includes('logs'))
      window.location.reload()

    });
  }
  return currentCode;
};
export const setLanguageFromStorage = (i18n, defaultLang) => {
  const currentLanguage = getLocalStorage('Language') || defaultLang;
  if (currentLanguage !== i18n.language) {
    setLocalStorage('Language', currentLanguage);
    i18n.changeLanguage(currentLanguage);
  }
  return currentLanguage;
};

export const showLanguageCode = (defaultLang, keepTextAsLang) => {
  const currentLanguage = getLocalStorage('Language') || defaultLang;
  return keepTextAsLang ? currentLanguage : getLanguageCode(currentLanguage);
};

export const getStyle = (style, theme, defaultValue) => {
  const styleValue = style || '';
  const themeValue = theme || '';
  return styleValue ? styleValue : themeValue ? themeValue : defaultValue;
};

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const checkIsAdmin = () => {
  const auth = getCookie(COOKIE_AUTH_DETAILS);
  if (auth && Object.keys(auth).length) {
    let token = parseJwt(auth[AUTH_TOKEN])
    return Boolean(token.from_admin)
  }
}

export const getUserMail = () => {
  const auth = getCookie(COOKIE_AUTH_DETAILS);
  if (auth && Object.keys(auth).length) {
    let token = parseJwt(auth[AUTH_TOKEN])
    return token.sub || ''
  }
}

export const filterList = (source, key, value) => {
  let result = [];
  if (typeof key === 'string') {
    if (key.includes('.')) {
      let keyObj = key.split('.');
      result = lodash.filter(source, (item) =>
        item[keyObj[0]]
          ? item[keyObj[0]][keyObj[1]]
            .toLowerCase()
            .includes(value.toLowerCase())
          : ''
      );
    } else {
      result = lodash.filter(source, (item) => {
        return item[key]
          ? item[key].toString().toLowerCase().includes(value.toLowerCase())
          : '';
      });
    }
  } else {
    result = lodash.filter(source, (item, index) => {
      let temp = key.filter((keys) => {
        return item[keys]?.toString()?.toLowerCase()?.includes(value.toString().toLowerCase());
      });
      return temp.length ? true : false;
    });
  }
  return result;
};

export const filterWithDateRange = (source, dateFrom, dateTo) => {
  const from =
    dateFrom !== null && dateFrom !== undefined
      ? moment(dateFrom).format('X')
      : moment('01/01/2000').format('X');
  const to =
    dateTo !== null && dateTo !== undefined
      ? moment(dateTo).format('X')
      : moment(new Date()).format('X');
  const result = lodash.filter(source, (item) => {
    let d = moment(item.date.split(' ')[0]).format('X');
    // return d.isBetween(
    return parseInt(from) <= parseInt(d) && parseInt(to) >= parseInt(d);
  });
  return result;
};

export const exportData = (fields, data, filename) => {
  const field = fields;
  const json2csvParser = new Parser({ field });
  const csv = json2csvParser.parse(data);
  var element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(csv)
  );
  element.setAttribute('download', filename + '.csv');
  element.style.display = 'none';
  if (typeof element.download != 'undefined') {
    //browser has support - process the download
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  } else {
    //browser does not support - alert the user
    alert(
      'This functionality is not supported by the current browser, recommend trying with Google Chrome instead.'
    );
  }
};

export const getQueryStringKeys = (string, key) => {
  const urlParams = new URLSearchParams(string);
  return urlParams.get(key);
};
const isEs = () => localStorage.getItem(window.$environment?.SERVICE_PROVIDER + 'Language') == 'es'

export const getLogsInfoDisplay = (i18n, name, date) => {
  name = i18n.t(name)
  return name ? isEs() ? `${i18n.t('common.logbookDetails', { name })} ${date}` : `${name}'s ${i18n.t('common.logbookDetails')} ${date}` : 'Daily Log';
}
export const consoleLog = (params) => { };

export const phoneNumberValidation = (inputVal) => {
  if (inputVal.substring(0, 12).match(phoneRegExp)) {
    return inputVal.substring(0, 12);
  } else {
    const number = /^[0-9]+$/;
    let value = inputVal.split('-').join('');
    const isValid = value.match(number);
    let updatedValue = isValid
      ? value.match(/.{1,3}/g).join('-')
      : value.split(value.length, 0);
    updatedValue =
      updatedValue.length === 13
        ? updatedValue.slice(0, updatedValue.length - 2)
        : updatedValue;
    return updatedValue || '';
  }
};

export const generateDropdownOptions = (list, displayField, valueField) => {
  return list?.length
    ? list.map((item) => {
      return {
        value: item[valueField],
        label: item[displayField]
      };
    })
    : [];
};

export const getListOfSelectedField = (list, field, callback) => {
  const result = list?.length ? list.map((item) => item[field]) : [];
  return result;
};

export const deleteJsonArrayItemByField = (source, key, value) => {
  return source.filter((option) => option[key] !== value);
};

export const objectIntoArray = (list = null) => {
  return list ? Object.entries(list)
  .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
  .map(([key, value]) => ({ value: key, label: value })) : [];
}

export const objectArrayFromDiffFields = (list, field, value, callback) => {
  return list?.length
    ? list.map((item) => {
      if (callback) return callback(item, field, value);
      else
        return {
          label: item[value],
          value: item[field]
        };
    })
    : [];
};

export const objectArrayFromSameField = (list) => {
  return list?.length
    ? list.map((item) => {
      return {
        label: item,
        value: item
      };
    })
    : [];
};

export const objectArrayFromMultipleFields = (list, value, field, callback) => {
  return list?.length
    ? list.map((item) => {
      if (callback) return callback(item, value, field);
      else
        return {
          label: `${item[field[0]]} ${item[field[1]]}`,
          value: item[value]
        };
    })
    : [];
};
export const isValidObject = (source) => {
  const isNotUndefined = typeof source !== 'undefined';
  const isNotNull = !(typeof source !== 'object' || !source);
  return isNotUndefined && isNotNull;
};

export const getUniqueList = (source) => {
  return source.filter((v, i) => source.indexOf(v) === i);
};

export const loadStyle = (ParentStyled, url) => {
  try {
    const style = require(`@/${url}/${window?.$environment?.CURRENT_LAYOUT}`)
      ?.default;
    return { ...ParentStyled, ...style };
  } catch (error) {
    return ParentStyled;
  }
};

export const loadSvg = () => {
  try {
    const images = require(`@/config/svg/${serviceProvider}/${appLayout}`)
      .default;
    return images;
  } catch (error) {
    const images = require(`@/config/svg/${serviceProvider}`).default;
    return images;
  }
};

export const getNewObject = (parentProps, newProps) => {
  return Object.keys(parentProps)
    .filter((key) => newProps.includes(key))
    .reduce(
      (final, current) => ({ ...final, [current]: parentProps[current] }),
      {}
    );
};

export const findAndDeleteParentInJson = (source, key, value) => {
  const resultArray = source.map((element) => {
    return element[key] !== value ? element : null;
  });

  for (let index = 0; index < resultArray.length; index++) {
    const element = resultArray[index];
    if (!element) {
      resultArray.splice(index, 1);
      index--;
    }
  }

  return resultArray;
};

export const loadPageConfig = () => {
  try {
    return require(`@/config/pages/${serviceProvider}/${appLayout}`);
  } catch (error) {
    return require(`@/config/pages/${serviceProvider}`);
  }
};

export const getLocaleFile = (file) => {
  try {
    return require(`@/config/locales/${file}/${serviceProvider}/${appLayout}`);
  } catch (error) {
    return require(`@/config/locales/${file}/${serviceProvider}`);
  }
};

export const getLanguage = (code) => {
  if (code) {
    return code === 'English' ? 'EN' : 'ES';
  } else return 'EN';
};


export const isDriver = () => {
  const auth = getAuthDetails(false);
  if (auth && auth.roles && auth.roles.includes("DRIVER")) return true;
  return false;
}

export const isdvirOnly = () => {
  const auth = getAuthDetails(false);
  if (auth && auth.enterpriseFeatureDto && auth.enterpriseFeatureDto?.dvirOnlyEnabled) return true;
  return false;
}

export const isdvirOnlyUser = () => {
  const auth = getAuthDetails(false);
  if (auth && auth.enterpriseFeatureDto && auth.enterpriseFeatureDto?.dvirOnlyUserEnabled) return true;
  return false;
}

export const isSingleRoleOnlyUser = (role = '') => {
  const auth = getAuthDetails(false);
  if (auth && auth.roles && auth.roles?.length === 1 && auth.roles.includes(role)) return true;
  return false;
}

export const isInsuracePortalUser = () => {
  const auth = getAuthDetails(false);
  if (auth && auth.roles.some(role => [ROLES.UNDER_WRITER, ROLES.LOSS_CONTROL, ROLES.CLAIM_ADJUSTER].includes(role))) return true;
  return false;
}

export const findListItem = (
  roles,
  currentRole,
  dependencies,
  enterpriseFeatureDto
) => {
  const isDvirOnlyFeatureEnabled = isdvirOnly()
  if (roles?.length && currentRole) {
    const result = roles.some((r) => {
      return currentRole.includes(r);
    });

    let isValidModule = true;
    if (dependencies?.length) {
      for (let index = 0; index < dependencies.length; index++) {
        const element = dependencies[index];

        if (
          element === 'paymentInformation' &&
          currentRole.includes('ADMIN') &&
          !currentRole.includes('FLMANAGER') &&
          !currentRole.includes('OWNER')
        ) {
          isValidModule = false;
        } else if (element === 'udBulkEditEnabled') {
          if (
            (enterpriseFeatureDto?.[element] !== undefined &&
              enterpriseFeatureDto?.[element] === false) ||
            enterpriseFeatureDto?.[element] === undefined
          ) {
            isValidModule = true;
          } else {
            isValidModule = false;
          }
        }
        else if (element === 'dvirSpecific' && !isDvirOnlyFeatureEnabled) {
          isValidModule = false
        }
        else if (element === 'dvirSpecific' && isDvirOnlyFeatureEnabled) {
          isValidModule = true
        }
        else if (element === 'dvirOnly' && isDvirOnlyFeatureEnabled) {
          isValidModule = true
        }
        else if (element !== 'dvirOnly' && isDvirOnlyFeatureEnabled) {
          isValidModule = false
        }
        else if (element === 'dvirOnly' && !isDvirOnlyFeatureEnabled) {
          isValidModule = true
        }
        else if (
          (enterpriseFeatureDto?.[element] !== undefined &&
            enterpriseFeatureDto?.[element]) ||
          enterpriseFeatureDto?.[element] === undefined
        ) {
          isValidModule = true;
        } else {
          if (element === "reportsEnabled" && window.$environment.SERVICE_PROVIDER === 'spireon' && enterpriseFeatureDto.spireonReportsEnabled) {
            isValidModule = true;
          } else {
            isValidModule = false;
          }

        }
      }
    }

    return result && isValidModule;
  } else if (roles?.length === 0) {
    return true;
  } else {
    return null;
  }
};