import styled from 'styled-components';
import Parent from './style';
const Wrapper = styled(Parent.Wrapper)`
.MuiAvatar-root { 
  width: 20px;
  height: 20px !important;
}
`;
const TopWrapper = styled(Parent.TopWrapper)`
 
`;
const IconWrapper = styled(Parent.IconWrapper)`
  .MuiAvatar-root { 
    width: 27px !important;
  }
`;

const ImpExpWrapper = styled(Parent.ImpExpWrapper)`
${({ theme }) => `
.iconContainer {
  flex: 0 0 10%;
}
.importVehicleCsvReaderInput label {
  font-size: 14px !important;
  color: ${theme.colors.LINK_COLOR};
}
#addUsers.vehicleModal{
 right: 27%;
  &::before {
    left: 14%;
  }
}
#importItems.vehicleModal{
  right: 20%;
  &::before {
    left: 20%;
  }
}
#ImportVehicles.vehicleModal {
  right: 7%;
  &::before {
    right: 70%;
  }
}
.importUserTemplateContainer .iconContainer .MuiAvatar-root,
.exportWrapper .iconContainer .MuiAvatar-root,
.exportContainer .iconContainer .MuiAvatar-root { 
  width: 16px !important;
  height: 16px !important;
}
.exportContainer .exportWrapper {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    width: 155px;
  }
}
 .MuiAvatar-root { 
  width: 35px !important;
  height: auto !important;
}
.importUserTemplateContainer,
.importUserContainer {
  margin-right: 10px;
}
`}
`;

const SearchWrapper = styled(Parent.SearchWrapper)`

`;

const ButtonsWrapper = styled(Parent.ButtonsWrapper)`

`;
const TableWrapper = styled(Parent.TableWrapper)`
.MuiGrid-align-items-xs-center {
  justify-content: center;
}
`;
const DocContainer = styled(Parent.DocContainer)`
`;
const MiddleWrapper = styled(Parent.MiddleWrapper)`
`;
const MapWrapper = styled(Parent.MapWrapper)`
`;
const TrackWrapper = styled(Parent.TrackWrapper)`
${({theme})=>`
  background-color: ${theme.colors.FORM_BACKGROUND};
`}
`;
const BottomWrapper = styled(Parent.BottomWrapper)`
${({ theme }) => `
padding-top: 2%;
.vehicleTabs {
  background-color: ${theme.colors.FORM_BACKGROUND};
  header{
    box-shadow: none; 
  }
  .viewAll{
    padding-right: 10px;
    .MuiGrid-container .MuiAvatar-root {
      width: 24px !important;
      height: 16px !important;
    }
  }
  .MuiTabs-flexContainer {
    border-top: 0;
    background-color: transparent;
    .Mui-selected{
      background-color: ${theme.colors.TAB_HIGHLIGHT_BACKGROUND};
    }
  }
  .MuiTabs-root {
    .MuiButtonBase-root.MuiTab-root {
      border-right: 0;
      border-bottom: 4px solid transparent;
    }
    .MuiTab-wrapper {
      font-size: 16px;
    }
    .Mui-selected .MuiTab-wrapper {
      color: ${theme.colors.LINK_COLOR};
    }
  }
}
`}
`;
const FormWrapper = styled(Parent.FormWrapper)`
.dropdownWrapper .vehicleTrack {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    margin-bottom: 63px;
  }
  .MuiFormLabel-root {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS styles go here */
      padding-bottom: 18px;
    }
  }
  .MuiFormLabel-root + .MuiBox-root {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS styles go here */
      height: 40px;
    }
  }
}
`;
const OptionsWrapper = styled(Parent.OptionsWrapper)`
`;

const Styled = {
    Wrapper,
    IconWrapper,
    ImpExpWrapper,
    SearchWrapper,
    TopWrapper,
    ButtonsWrapper,
    TableWrapper,
    DocContainer,
    MiddleWrapper,
    MapWrapper,
    TrackWrapper,
    BottomWrapper,
    FormWrapper,
    OptionsWrapper
  };
  export default Styled;