import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
   .MuiTable-root {
        .MuiTableSortLabel-active {
            padding-left: 0;
        }
        .MuiTableSortLabel-active .MuiTableSortLabel-iconDirectionDesc,
        .MuiTableSortLabel-active .MuiTableSortLabel-iconDirectionAsc{
            left: -24px;
        }
    }
    .pdfIcon {
        width: 22px;
        height: 18px;
        opacity: 0.6;
    }
    .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:last-child {
        svg {
             width: 28px;
             height: 17px;
             color: #425061;
        }
    }
   } 
`}
`;

const Styled = { Wrapper };

export default Styled;
