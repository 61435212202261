import Box from '@material-ui/core/Box';
import styled from 'styled-components';

const Wrapper = styled(Box)`
    position: relative;
    padding: 32px;
`;

const TopWrapper = styled(Box)`
  width: 100%;
`;

const TableWrapper = styled(Box)``;

const SearchWrapper =styled.div`
${({ theme }) => `
  flex: 0 0 35%;
  display: flex;
  position: relative;
  width: 40%;
  #clearSearch {
    display: none;
  }
  .iconContainer {
    flex: 0 0 10%;
    justify-content: end;
  }
  .iconContainer {
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flexbox;
    display: flex;
    justify-content: flex-start;
    margin-right: 10px;
  }
  .MuiAvatar-root {
    width: 35px !important;
    height: auto !important;
  }
  #addUsers.vehicleModal{
    left: 87%;
    // &::before {
    //   left: 7%;
    // }
  }`}
`;

const FilterWrap = styled(Box)`
    .filter-section {
      display: inline-flex;
      justify-content: space-between;
      gap: 12px;
      padding-top: 12px;
      .buttons {
        display: flex;
        justify-content: flex-start;
        width: 40%;
        gap: 12px;
      }
      .inputs {
        display: flex;
        justify-content: flex-start;
        width: 60%;   
      }
      .vertical {
        width: 98%;
        margin-right: 1% !important;
      }
        .vertical-button {
          width: 20%;
          justify-content: flex-start;
          .MuiBox-root {
            width: 100% !important;
          }
            button {
              margin-top: 29px;
          }
        }
    }
`;

const LabelWrapper = styled.div`
    padding-left: 3%;
    line-height: 41px;
    label {
      font-size: 24px;
      line-height: 41px !important;
    }
`;

const Styled = { Wrapper, TopWrapper, SearchWrapper, TableWrapper, FilterWrap, LabelWrapper };

export default Styled;
