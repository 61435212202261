import styled from 'styled-components';
import Container from '../Base/style';

export default styled(Container)`
.MuiTypography-h2{
font-size: 20px !important;
text-transform: capitalize !important;
}
color: #000000 !important;
font-size: 20px !important;
font-weight: 600 !important;
padding-left: 0px !important;
`;
