import styled from 'styled-components';
import Parent from '../Base/style';

const MenuWrapper = styled(Parent.MenuWrapper)`
  ${({ collapsible, open }) => `
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: #bdd731;
  p, p:hover{
    color: #38444d !important;
  }
}
.MuiListItem-button:hover,
.MuiListItem-root.Mui-selected:hover, {
  background-color: #293338;
}

li {
  padding:0;
    border-bottom: 0;
    .MuiTypography-body1{
      color: #fff;
      border-bottom: 1px solid hsla(0,0%,100%,.2);
      width: 100%;
      align-items: center;
      margin-left: 2%;
      ${collapsible && !open ? `display:none;` : 'display:flex'}
      font-size: 17px;
    }
}
a {
    width: 100%;
    ${collapsible && !open && `justify-content: center`}
}
.MuiListItemIcon-root{
  padding: 8px 17px;
   svg {
    width: 25px;
    height: 25px;
  }
}
`}
`;
const MenuToolTip = styled(Parent.MenuToolTip)``;
const Wrapper = { MenuWrapper, MenuToolTip };

export default Wrapper;
