import React, { useState, lazy, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Filters from './Filters';
import { useHistory } from 'react-router-dom';
import { isInsuracePortalUser, getQueryStringKeys, removeDuplicates } from '@/utils/utils';
import { periodList } from '@/config/constants/keys';
import BarGraph from './Graphs/BarGraph';
import PieGraph from './Graphs/PieGraph';
import ScatterPlot from './Graphs/ScatterPlot';
import moment from 'moment';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Tab = lazy(() => import(`@/components/UI/Tab/${layout}`));
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Breadcrumb = lazy(() => import(`@/components/Breadcrumb`));

const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Breadcrumb filters={filters} />
      <Component {...contentProps} />
    </Box>
  );
};
export default Index;
const Component = ({
  paginateList,
  chartsData,
  hasFetchApiStatus,
  Styled,
  i18n,
  changeRequest,
  request,
  pagination,
  setPagination,
  companyId,
  downloadDocument,
  callback,
  vehicles
}) => {
  const history = useHistory();
  const queryPeriod = getQueryStringKeys(history.location.search, 'period');

  const [values, setInitialValues] = useState({
    period: periodList.filter(ele => ele.value == queryPeriod)[0],
    behavior: null,
    severity: null,
    vehicleId: null
  });
  const insuracePortalUserRole = isInsuracePortalUser();

  const columns = [
    ...(insuracePortalUserRole ? [{
      id: 'companyName',
      numeric: false,
      disablePadding: false,
      label: i18n.t('policyHolder'),
      needSorting: false
    }] : []),
    {
      id: 'behavior',
      numeric: false,
      disablePadding: false,
      label: i18n.t('Behavior'),
      needSorting: true
    },
    {
      id: 'day',
      numeric: true,
      disablePadding: true,
      label: i18n.t('Date'),
      needSorting: true
    },
    {
      id: 'severity',
      numeric: false,
      disablePadding: false,
      label: i18n.t('Severity'),
      needSorting: true
    },
    {
      id: 'vehicleName',
      numeric: false,
      disablePadding: false,
      label: i18n.t('common.vehicle'),
      needSorting: true
    },
    {
      id: 'behaviorScore',
      numeric: true,
      disablePadding: false,
      label: i18n.t('Behavior Score'),
      needSorting: true
    },
    {
      id: 'severityScore',
      numeric: true,
      disablePadding: false,
      label: i18n.t('Severity Score'),
      needSorting: true
    },
    {
      id: 'totalScore',
      numeric: true,
      disablePadding: false,
      label: i18n.t('Total Score'),
      needSorting: true
    }
  ];

  const generateRows = () => {
    return paginateList.content || []
  };

  const information = hasFetchApiStatus?.errorMessage ? (
    <Message type="error" message={hasFetchApiStatus.errorMessage} />
  ) : (
    'common.tableEmptyMessage'
  );
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabValues = [
    {
      name: i18n.t('Bar Graph'),
      value: (
        <BarGraph period={request.period || queryPeriod} data={chartsData.barChartList || []} />
      )
    },
    {
      name: i18n.t('Pie Chart'),
      value: (
        <PieGraph data={chartsData.pieChartList || []} />
      )
    },
    {
      name: i18n.t('Scatterplot'),
      value: (
        <ScatterPlot data={chartsData.scatterPlotList} />
      )
    }
  ];

  const monthVals = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
  const getMonth = () => {
    if (request.period) {
      if (request.period == 'MONTHLY') return monthVals[new Date().getMonth()]
      else return '01'

    } else {
      if (queryPeriod == 'MONTHLY') return monthVals[new Date().getMonth()]
      else return '01'
    }
  }
  const getVehicleList = () => {
    if (vehicles?.vehicleList) {
      if (insuracePortalUserRole) {
        return vehicles.vehicleList?.filter(ele => ele.companyId == companyId).map(ele => {
          return {
            label: ele.unit_id,
            value: ele.id
          }
        }) || []
      } else {
        return vehicles.vehicleList?.map(ele => {
          return {
            label: ele.unit_id,
            value: ele.id
          }
        }) || []
      }
    } else return []
  }

  return (
    <Styled.Wrapper>
      <div className="quickFilter"></div>
      <div className="tableWrapper">
        <div className='filterWrapper'>
          <Filters
            filterList={(data) => {
              setPagination(true)
              changeRequest({
                ...data,
                displayLength: '10',
                displayStart: '1'
              })
            }}
            vehicleList={getVehicleList()}
            handleReset={() => {
              changeRequest({
                period: queryPeriod,
                behavior: null,
                severity: null,
                vehicleId: null,
                displayLength: '10',
                displayStart: '1'
              })
            }}
            history={history}
            values={values}
            setInitialValues={setInitialValues}
          />
          <Button
            type="submit"
            label={i18n.t('Download Report')}
            onClick={(e) => {
              downloadDocument({
                apiId: 'scoreCardReportDownload',
                callback,
                data: {
                  companyIds: [companyId],
                  type: 'SCORE_CARD',
                  endDate: moment(new Date()).format('MM/DD/YYYY'),
                  startDate: `${getMonth()}/01/${new Date().getFullYear()}`
                }
              })
            }}
          />
        </div>

        <div className='pageWrapper'>
          <div>
            <Table
              type="server"
              changeRequest={changeRequest}
              baseRequest={request}
              // disablePadding
              defaultSortBy={{ sortBy: 'behaviour', order: 'desc' }}
              title=""
              totalRecords={paginateList?.totalElements || 0}
              columns={[...columns]}
              data={generateRows()}
              emptyMessage={information}
              itemsPerPage={10}
              pageinationReset={pagination}
              actions={[]}
              i18n={i18n}
              pagenationClicked={(e) => {
                setPagination(!e);
              }}
            />
          </div>
          <div className='graphWrapper'>
            <Tab
              data={tabValues}
              currentTab={value}
              onChange={handleChange}
              className="graphTabs"
            />
          </div>
        </div>


      </div>
    </Styled.Wrapper>
  );
};

Component.propTypes = {
  list: PropTypes.array.isRequired,
  hasFetchApiStatus: PropTypes.object.isRequired,
  Styled: PropTypes.object.isRequired,
  i18n: PropTypes.array.isRequired
};
