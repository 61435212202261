import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { useParams } from 'react-router-dom';

import { object, string } from 'yup';
import RenderComponent from './RenderComponent';
// import Styled from './style';
import ParentStyled from './style';
import { loadStyle, getCompanyId } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'components/modules/FuelComsumptionAddEdit');
const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));
const Component = ({
  closeModal,
  saveDocument,
  uploadApi,
  callback,
  parent,
  types,
  currentData,
  vehicleList,
  driverList,
  stateListData
}) => {
  const match = useParams();
  const stateArrayFromDiffFields = (list, value, field1) => {
    return list && Object.keys(list)?.length ? Object.keys(list).flatMap((state) =>
        list[state].map((item) => {
            return {
              label: `${item[field1]} (${state})`,
              value: item[value],
            };
        })
      ) : []
  };

  function removeEmptyKeys(obj) {
    Object.keys(obj).forEach(key => {
      if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
        delete obj[key];
      }
      else if (typeof obj[key] === 'object') {
        removeEmptyKeys(obj[key]);
        if (Object.keys(obj[key]).length === 0) {
          delete obj[key];
        }
      }
    });
    return obj;
  }
  
  const stateList = stateArrayFromDiffFields(stateListData?.State, 'key', 'displayName');

  const { i18n } = useTranslation();
  let instialState = {};
  const companyId = getCompanyId();
  if(currentData) {
    instialState = {
      id: currentData?.id || '',
      driver: currentData?.driverId  ? driverList.filter(el => {
        return el.value === currentData.driverId
      })[0] : {},
      vehicle: currentData?.vehicle  ? vehicleList.filter(el => {
        return el.value === currentData.vehicle
      })[0] : {},
      jurisdiction: currentData?.jurisdiction  ? stateList.filter(el => {
        return el.value === currentData.jurisdiction
      })[0] : {},
      cost: currentData?.cost || '',
      odometer: currentData?.odometr || '',
      amount: currentData?.amount || '',
      date: currentData?.date ?   moment(currentData?.date).tz(moment.tz.guess()).format('MM/DD/YYYY') :  '',
      location: currentData?.location || ''
    }
  }
  const modifyDocumentType = (data) => {
    const modifiedData = { ...data };
    modifiedData.receipts = modifiedData.receipts.map(receipt => {
      const modifiedReceipt = { ...receipt };
      modifiedReceipt.documentType = modifiedReceipt?.documentType?.name;
      return modifiedReceipt;
    });
    return modifiedData;
  }
  return (
    <Styled.Wrapper>
      <Form
        type="server"
        direction="column"
        initialValues={instialState}
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {
          const requestData = removeEmptyKeys({
              id: currentData?.id || '',
              companyId: companyId || '',
              vehicle: fields?.vehicle?.value || '',
              driverId: fields?.driver?.value || '',
              jurisdiction: fields?.jurisdiction?.value || '',
              location: fields?.location || '',
              cost: fields?.cost || '',
              odometr: fields?.odometer || '',
              amount: fields?.amount || '',
              date: fields?.date  ?  moment(fields?.date, 'MM/DD/YYYY').format('YYYY-MM-DD') : "",
            })
          if (
            document?.querySelectorAll('span.filepond--file-status-main')[0]
              ?.innerText === '' || currentData?.id
          ) {
            let payloadData = {
              apiId: uploadApi,
              param: currentData ? {...modifyDocumentType(currentData), ...requestData} : requestData,
              data: {
                data: {
                  file: null
                }
              },
              callback
            }
            if(fields?.upload?.[0]) {
             payloadData = {
              apiId: uploadApi,
              param: currentData ? {...modifyDocumentType(currentData), ...requestData} : requestData,
              data: {
                data: {
                  file: fields?.upload?.[0]
                }
              },
              callback
             }
            }
            if(match?.id) {
              payloadData.refillHistoryId = match?.id;
            }
            saveDocument(payloadData);
            closeModal();
          }
        }}
        customProps={{ parent, types, Styled, vehicleList, driverList, stateList }}
        handleClear={() => {}}
        validationSchema={object().shape({
          ...(!currentData?.id ? {upload: string().required(i18n.t('common.fieldRequiredMessage'))} : {}),
          ...(currentData?.location && currentData?.id ? {location: string().required(i18n.t('common.fieldRequiredMessage'))} : {}),
          ...(!currentData?.location ? {jurisdiction: string().required(i18n.t('common.fieldRequiredMessage'))} : {}),
          vehicle: string().required(i18n.t('common.fieldRequiredMessage')),
          driver: string().required(i18n.t('common.fieldRequiredMessage')),
          cost: string().required(i18n.t('common.fieldRequiredMessage')),
          odometer: string().required(i18n.t('common.fieldRequiredMessage')),
          amount: string().required(i18n.t('common.fieldRequiredMessage')),
          date: string().required(i18n.t('common.fieldRequiredMessage')),
        })}
      />
    </Styled.Wrapper>
  );
};

export default Component;

Component.propTypes = {
  apiStatus: PropTypes.object,
  callback: PropTypes.func.isRequired
};
