const leftMenu = [
  {
    icon: 'logs.svg',
    label: 'leftMenu.logs',
    roles: ['FLMANAGER', 'DRIVER'],
    path: '/logs/list'
  },
  {
    icon: 'user.svg',
    label: 'leftMenu.users',
    roles: ['FLMANAGER'],
    path: '/user/list'
  },
  {
    icon: 'vehicle.svg',
    label: 'leftMenu.vehicles',
    roles: ['FLMANAGER'],
    path: '/vehicle/list'
  },
  {
    icon: 'accident.svg',
    label: 'leftMenu.accidents',
    roles: ['FLMANAGER'],
    path: '/accidents/list'
  },
  {
    icon: 'repair.svg',
    label: 'leftMenu.repairMaintenance',
    roles: ['FLMANAGER'],
    path: '/test'
  },
  {
    icon: 'inspection.svg',
    label: 'leftMenu.inspections',
    roles: ['FLMANAGER'],
    path: '/test'
  },
  {
    icon: 'filter.svg',
    label: 'leftMenu.filters',
    roles: ['FLMANAGER'],
    isFilter: true
  },
  {
    icon: 'company.svg',
    label: 'leftMenu.companyData',
    roles: ['FLEETMG'],
    path: '/test'
  },
  {
    icon: 'usdot.svg',
    label: 'leftMenu.USDOT',
    roles: ['FLEETMG'],
    path: '/test'
  },
  {
    icon: 'dotAudit.svg',
    label: 'leftMenu.DOTAudit',
    roles: ['FLMANAGER'],
    path: '/logs/fmcsaLogs'
  },
  {
    icon: 'policy.svg',
    label: 'leftMenu.policiesProcedures',
    roles: ['FLMANAGER'],
    path: '/test'
  },
  {
    icon: 'document.svg',
    label: 'leftMenu.documents',
    roles: ['FLMANAGER'],
    path: '/company/document/edit'
  },
  {
    icon: 'dailyDocuments.svg',
    label: 'leftMenu.dailyDocuments',
    roles: ['FLMANAGER'],
    path: '/dailyDocuments/list'
  },
  {
    icon: 'departments.svg',
    label: 'common.departments',
    roles: ['FLMANAGER'],
    path: '/department/list/default'
  },
  {
    icon: 'fuel.svg',
    label: 'leftMenu.fuelReport',
    roles: ['FLMANAGER'],
    path: '/test'
  },
  {
    icon: 'ifta.svg',
    label: 'leftMenu.IFTAReport',
    roles: ['FLMANAGER'],
    path: '/test'
  },
  {
    icon: 'reports.svg',
    label: 'leftMenu.reports',
    roles: ['FLMANAGER'],
    module: 'reportsEnabled',
    path: '/report'
  },
  {
    icon: 'developer.svg',
    label: 'leftMenu.developerRequests',
    roles: ['FLMANAGER'],
    path: '/alerts/requests'
  },
  {
    icon: 'feedback.svg',
    label: 'leftMenu.feedback',
    roles: ['FLMANAGER'],
    path: '/feedback'
  }
];
export default leftMenu;
