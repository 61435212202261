import React from 'react';

import { getNewObject } from '@/utils/utils';

export default ({
  name,
  props,
  commonProps,
  title,
  parentProps,
  commonParentProps,
  component,
  children,
  childComponent,
  ...rest
}) => {
  const Component = component;
  let newProps = props?.length ? getNewObject(parentProps, props) : {};
  newProps = {
    ...newProps,
    ...(commonProps?.length ? getNewObject(commonParentProps, commonProps) : {})
  };
  if (children) {
    const ChildComponent = childComponent;
    const {
      name: childName,
      title: childTitle,
      commonProps: childCommonProps,
      props: childProps,
      ...childRest
    } = children;

    let childrenNewProps = childProps?.length
      ? getNewObject(parentProps, childProps)
      : {};
    childrenNewProps = {
      ...childrenNewProps,
      ...(childCommonProps?.length
        ? getNewObject(commonParentProps, childCommonProps)
        : {})
    };
    return (
      <Component {...rest} {...newProps}>
        <ChildComponent {...childrenNewProps} {...childRest} />
      </Component>
    );
  } else return <Component {...rest} {...newProps} />;
};
