import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import underscore from 'underscore';
import CSVReader from 'react-csv-reader';

const Import = (props) => {
  const { i18n } = useTranslation();
  const { ImportWrapper } = props;
  const fileData = (e) => {
    let fields = [...e];
    var length = e.length;
    let data = [];
    for (var i = 1; i < length; i++) {
      data.push(underscore.object(fields[0], fields[i]));
    }
    props.onClick(fields[0], data);
  };

  const handleFileChange = (e) => {
    const inputElement = props.id
      ? document.getElementById(`fileSelect${props.id}`).files[0]
      : document.getElementById('fileSelect').files[0];
    let form_data = new FormData();
     form_data.append(
      props.reqDataModel ? props.reqDataModel : 'file',
      inputElement
    );
    props.onClick(form_data);
  };

  return (
    <React.Fragment>
      <ImportWrapper
        userImport={props.userImport}
        className={props.className}
        importVehicle={props.importVehicle}
      >
        {props.importVehicle ? (
          <div className="importVehicleCsvReaderInput">
            <input
              type="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              id={props.id ? `fileSelect${props.id}` : 'fileSelect'}
              onChange={(e) => handleFileChange(e)}
              className='fileSelect'
            />
            <label> {props.importVehicle} </label>
          </div>
        ) : props.asBinary ? (
          <div className="csv-reader-input">
            <input
              type="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              id="fileSelect"
              onChange={(e) => handleFileChange(e)}
              className='fileSelect'
            />
            <span className="tooltiptext">
              {props.userImport
                ? i18n.t('common.importUser')
                : i18n.t('common.import')}
            </span>
          </div>
        ) : (
          <CSVReader
            onFileLoaded={(data) => fileData(data)}
            label={i18n.t('common.importUser')}
            cssClass="csv-reader-input"
          />
        )}
      </ImportWrapper>
    </React.Fragment>
  );
};

Import.propTypes = {
  fields: PropTypes.array,
  data: PropTypes.array,
  filename: PropTypes.string
};

Import.defaultProps = {
  onClick: () => {}
};

export default Import;
