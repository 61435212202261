import { manageLogListApi, manageUnsignedLogListApi, manageArchiveLogListApi, getDocumentTokenApi } from '../../../../config/apis';

const initialState = {
  list: [],
  filteredList: [],
  totalCount: 0,
  selectedRow: { index: -1, row: {} }
};

export const manageLogList = {
  state: { ...initialState },
  reducers: {
    listSuccess(state, payload) {
      return {
        ...state,
        list: [...payload],
        filteredList: [...payload],
        totalHours: payload?.reduce((a, b) => a + (b['hours'] || 0), 0),
        totalDistance: payload?.reduce((a, b) => a + (b['distance'] || 0), 0)
      };
    },
    saveTotalCount(state, payload) {
      return {
        ...state,
        totalCount: payload
      }
    },
    listFailed() {
      return { ...initialState };
    },
    filterSuccess(state, list) {
      return {
        ...state,
        filteredList: [...list],
        totalHours: list?.reduce((a, b) => a + (b['hours'] || 0), 0),
        totalDistance: list?.reduce((a, b) => a + (b['distance'] || 0), 0)
      };
    },
    setSelectedItem(state, data) {
      return { ...state, selectedRow: { ...data } };
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: payload.realTimeLogs ? manageUnsignedLogListApi : manageLogListApi
          },
          id: payload.apiId
        });
        response && this.listSuccess(response);
      } catch (error) {
        this.listFailed();
      }
    },
    async fetchPDF(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: getDocumentTokenApi
          },
          id: payload.apiId
        });

        if (response) {
          let a = document.createElement('a');
          a.href = ``;
          a.onclick = function () {
            window.open(`${window.$environment.BE_BASE_URL}/document/download/${payload.data}?token=${response}`);
            window.close();
            return false
          }
          document.body.appendChild(a);
          a.click()
          a.remove()
        }
        return false
      } catch (error) { }
    },
    async fetchArchiveData(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: manageArchiveLogListApi,
            data: payload.data
          },
          id: payload.apiId
        });
        if (response) {
          this.listSuccess(response.content);
          this.saveTotalCount(response.totalCount)
        }
      } catch (error) {
        this.listFailed();
      }
    }
  })
};
