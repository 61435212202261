import * as apis from '..';
const localApis = {
  testApi: {
    url: '',
    type: ''
  }
};
const getApi = (api) => {
  return localApis[api] || apis[api];
};
export default getApi;
