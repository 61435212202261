import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
  ${({ theme }) => `
  .MuiTypography-body1 {
    font-size: 18px;
  }
  .filepond--wrapper {
    .filepond--drop-label label {
      color: ${theme.colors.PRIMARY_CONTRAST};
      opacity: 1;
    }
  }
  form {
    div:last-child {
      justify-content: center;
      #button {
        width: 210px;
        margin-top: 0;
      }
    }
  }
  div[type='error'] p {
    font-size: 16px;
  }
  
  `}
`;
const UploadWrapper = styled(Box)``;
const PopupUpload = styled(Box)`
${({ theme }) => `
.timeZone {
  margin-top: 0;
  padding-bottom: 10px;
  font-size: 20px;
  color: ${theme.colors.PRIMARY_CONTRAST};
}
.buttonWrapper {
  justify-content: center;
  padding-bottom: 19px;
  padding-top: 10px;
  button {
    min-width: 210px;
  }
  .MuiBox-root:first-child {
      margin-right: 4%;
  }
}
`}
`;
const Styled = { Wrapper, UploadWrapper, PopupUpload };

export default Styled;
