const routeConfigs = [
  {
    url: '/registration',
    component: 'Registration',
    title: 'Registration',
    layout: 'plain',
    module: 'registration'
  },
  {
    url: '/login',
    component: 'Login',
    title: 'Login',
    layout: 'login',
    module: 'login'
  },
  {
    url: '/privacy-policy',
    component: 'PrivacyPolicyScreen',
    layout: 'plain',
    module: 'privacyPolicyScreen',
    title: 'Privacy Policy'
  },
  {
    url: '/auth/recovery',
    component: 'ForgotPassword',
    title: 'Forgot Password',
    layout: 'login',
    module: 'recovery'
  },
  // {
  //   url: '/feedback',
  //   component: 'Feedback',
  //   title: 'Feedback',
  //   module: 'feedback'
  // },
  {
    url: '/user/resetPassword',
    component: 'ResetPassword',
    layout: 'welcome',
    module: 'resetPassword',
    title: 'Reset Password'
  },
  {
    url: '/user/mfa',
    component: 'MFA',
    layout: 'login',
    module: 'MFAPage',
    title: 'MFA'
  }
];

export default routeConfigs;
