import React from 'react';
import PropTypes from 'prop-types';
import Form from './Form';
import Box from '@material-ui/core/Box';

const Index = ({ theme, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Component {...contentProps} />
    </Box>
  );
};
export default Index;

const Component = (props) => {
  return <Form {...props} />;
};

Component.propTypes = {
  history: PropTypes.object.isRequired
};
