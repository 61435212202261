import React, { lazy, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { hasValue, getImagePath } from '@/utils/utils';
import { imageUrls } from '@/config/constants/keys';

import Box from '@material-ui/core/Box';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import TopActions from './TopActions';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const Modal = require(`@/components/UI/Modal/${layout}`).default;

const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle, overflowX: 'hidden' }}>
      <Component {...contentProps} filters={filters} />
    </Box>
  );
};
export default Index;

const Component = ({
  apiStatus,
  callback,
  history,
  Styled,
  fetchInspections,
  bulkDownloadReport,
  inspections,
  list,
  changeRequest,
  isAdvancedDviRequired,
  isCustomDviRequired,
  logBook,
  clearApiStatus,
  i18n,
  topAction,
  getDocumentToken,
  filters,
  viewReport,
  request,
  pagination,
  setPagination,
  needSort
}) => {
  const [open, setOpen] = useState(false);
  // const [currentItem, setCurrentItem] = useState();

  const [ModalObject, setModalObject] = useState({
    header: '',
    content: () => {
      return null;
    }
  });

  const closeModal = (event) => {
    setOpen(false);
  };
  const beBaseUrl = window?.$environment?.BE_BASE_URL;
  const hasApiStatus = hasValue(apiStatus.status, 'api', fetchInspections);
  const information =
    hasApiStatus && hasApiStatus.errorMessage ? (
      <Message type="error" s message={i18n.t(hasApiStatus.errorMessage)} />
    ) : (
      i18n.t('common.tableEmptyMessage')
    );
  const columns = [
    {
      id: 'date',
      numeric: false,
      disablePadding: false,
      label: i18n.t('accidents.dateAndTime'),
      needSorting: true
    },
    {
      id: 'vehicle',
      numeric: true,
      disablePadding: false,
      label: i18n.t('vehicles.vin'),
      needSorting: true
    },
    {
      id: 'driver',
      numeric: false,
      disablePadding: false,
      label: i18n.t('common.driverName'),
      needSorting: true
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      needSorting: needSort,
      label: i18n.t('inspections.inspectionType'),
      needSorting: true
    },

    {
      id: 'document',
      numeric: false,
      disablePadding: false,
      label: i18n.t('common.photos')
    },
    {
      id: 'report',
      numeric: false,
      disablePadding: false,
      label: i18n.t('inspections.inspectionReport')
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: i18n.t('logs.status')
    }
  ];
  if(window.$environment.SERVICE_PROVIDER === 'edvir') {
    columns.splice(6,1)
  }
  const viewPictureCallback = (type, data, currentItem) => {
    currentItem &&
      setModalObject({
        header: '',
        content: () => {
          return (
            <Carousel
              showIndicators={false}
              showThumbs={false}
              showStatus={false}
            >
              {currentItem.photos.map((photo) => {
                return (
                  <div>
                    <img
                      src={`${beBaseUrl}/document/open/${photo.files[0].id}?token=${data}`}
                    />
                    <p className="legend">
                      {`${photo?.sectionName}${ photo?.info ? ` : ${photo?.info}` : ""}`}
                    </p>
                  </div>
                );
              })}
            </Carousel>
          );
        }
      });
    setOpen(true);
  };
  const viewPicture = (item) => {
    getDocumentToken({
      apiId: 'getDocToken',
      callback: viewPictureCallback,
      item: item
    });
  };

  const generateRows = () => {
    const updatedList = inspections?.content?.map((item) => {
      let vinTrimmedValue = item?.vehicle
      if(item?.vehicle){
        vinTrimmedValue = item.vehicle.substr(item.vehicle.length - 8)
        vinTrimmedValue = `...${vinTrimmedValue}`
      }
      item.vehicle = vinTrimmedValue
      item.report = (
        <a
          // href={`${beBaseUrl}/document/open/${item?.id}?token=${logBook?.documentToken}`}
          className="viewReport"
          // target="_blank"
          onClick={() =>
            viewReport({
              apiId: 'viewReportApi',
              data: item?.id
            })
          }
        >
          {i18n.t('inspections.viewReport')}
        </a>
      );
      item.status =
        item.violationType === 'DISTANCE' ? (
          <div
            class="circle_orange csadvirAlign"
            title="Minimum Distance condition is not satisfied"
          ></div>
        ) : item.violationType === 'DURATION' ? (
          <div
            class="circle_yellow csadvirAlign"
            title="Minimum Duration condition is not satisfied"
          ></div>
        ) : item.violationType === 'BOTH' ? (
          <div
            class="circle_red csadvirAlign"
            title="Minimum Distance & Minimum Duration conditions are not satisfied"
          ></div>
        ) : item.violationType === 'NONE' || item.violationType === null ? (
          '-'
        ) : item.violationType === 'OK' ? (
          <div
            class="circle_green csadvirAlign"
            title="Completed correctly"
          ></div>
        ) : (
          <div class="wrapOpt"></div>
        );
      item.document = item.photos.length ? (
        window.$environment.CURRENT_LAYOUT === 'SpireonNew' ? (
          <a
            onClick={() => {
              // setCurrentItem(item);
              viewPicture(item);
            }}
          >
            {i18n.t('View')}
          </a>
        ) : (
          <Icon
            src={getImagePath(imageUrls.full)}
            tableicon
            onClick={() => {
              // setCurrentItem(item);
              viewPicture(item);
            }}
          ></Icon>
        )
      ) : window.$environment.CURRENT_LAYOUT === 'SpireonNew' ? (
        ''
      ) : (
        <div className="noPointer"><Icon src={getImagePath(imageUrls.notFull)} tableicon></Icon></div>
      );
      return item;
    });
    return updatedList || [];
  };
  return (
    <Styled.Wrapper>
      {topAction && (
        <TopActions
          {...{
            isAdvancedDviRequired,
            isCustomDviRequired,
            i18n,
            history,
            list,
            bulkDownloadReport,
            apiStatus,
            clearApiStatus,
            callback,
            Styled,
            changeRequest,
            hasApiStatus,
            filters,
            request,
            pagination,
            setPagination
          }}
        />
      )}
      <div className="quickFilter"></div>
      <Styled.TableWrapper>
        <div className="driverstatusTitle">
          <span className="spireonOldOnly">
            Inspections
          </span>
        </div>
        <Table
          type="server"
          defaultSortBy={needSort ? { sortBy: 'type', order: 'desc' } : null}
          changeRequest={changeRequest}
          baseRequest={request}
          title=""
          columns={[...columns]}
          data={generateRows()}
          emptyMessage={information}
          itemsPerPage={
            window.$environment.SERVICE_PROVIDER === 'spireon' ? 10 : 15
          }
          totalRecords={inspections?.totalCount}
          actions={[]}
          i18n={i18n}
          pageinationReset={pagination}
          pagenationClicked={(e) => {
            setPagination(!e);
          }}
        />
      </Styled.TableWrapper>
      <Modal
        open={open}
        setOpen={setOpen}
        data={ModalObject}
        onCloseModal={closeModal}
        background="black"
        inspectionPopup
        withCloseIcon
      />
    </Styled.Wrapper>
  );
};

Component.propTypes = {
  history: PropTypes.object.isRequired,
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  fetchViolations: PropTypes.func.isRequired,
  handleExportCsaViolations: PropTypes.func.isRequired
};
