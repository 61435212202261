import { hasEnabledFeature } from "@/utils/utils";

const initialState = { list: [], filteredList: [], total: 0, downloadUrl: '', iftaErrors: false, csvPayload: {} };
export const iftaMileage = {
  state: { ...initialState },
  reducers: {
    getSuccess(state, payload) {
      let response = { ...payload.data };
      let list = []
      Object.keys(response).forEach(key => {
        list.push({
          state: key,
          totalMiles: response[key]?.totalMileage,
          ...(hasEnabledFeature('taxableMilesViewEnabled') ? { totalTaxableMiles: response[key]?.taxableMiles } : {}),
          fuelUsage: payload.fuelData[key] ? payload.fuelData[key] : 0
        })
      })
      let finalList = list.filter(ele => ele.totalMiles)
      return {
        ...state,
        list: [...list],
        filteredList: [...finalList],
        total:
          payload.data &&
          Object.entries(payload.data)
            .map(([state, totalMiles]) => ({
              state,
              totalMiles: totalMiles?.totalMileage || 0
            }))
            .reduce((a, b) => a + (b['totalMiles'] || 0), 0)
      };
    },
    setErrorFlg(state, payload) {
      return { ...state, iftaErrors: payload}
    },
    savePayloadDataForCSV(state, payload) {
      return { ...state, csvPayload: payload}
    },
    getFailed(state) {
      return { ...initialState };
    },
    downloadSuccess(state, payload) {
      return { ...state, downloadUrl: payload };
    },
    filterSuccess(state, status) {
      let iftaResponse = [ ...state.list ];
      if (status) {
        iftaResponse = iftaResponse.filter(ele => ele.totalMiles)
      }
      return {
        ...state,
        total: state.total,
        list: [ ...state.list ],
        filteredList: [ ...iftaResponse ]
      };
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload, rootState) {
      this.setErrorFlg(false)
      try {
        this.savePayloadDataForCSV(payload.data)
        const getApi = await import(
          `../../../../config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('getIftaMIleageApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject,
            data: payload.data
          },
          callback: payload.callback,
          id: payload.id
        });
        if (response?.data)  {
          this.setErrorFlg(response.iftaErrorExists)
          this.getSuccess(response);
        }
      } catch (error) {
        this.getFailed();
      }
    },
    async getIftaAudit(payload, rootState) {
      try {
        const getApi = await import(
          `../../../../config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('getIftaAuditApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...apiObject,
              url: `${apiObject.url}?dateTimeFrom=${rootState.iftaMileage.csvPayload.dateTimeFrom}&dateTimeTo=${rootState.iftaMileage.csvPayload.dateTimeTo}&id=${rootState.iftaMileage.csvPayload?.id ? rootState.iftaMileage.csvPayload.id : ''}`
            }
          },
          callback: payload.callback,
          id: payload.id
        });
      } catch (error) {
        // this.getFailed();
      }
    },
    async generateReport(payload, rootState) {
      try {
        const getApi = await import(
          `../../../../config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('getIftaReportApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...apiObject,
              url: `${apiObject.url}?dateTimeFrom=${payload.data.dateTimeFrom}&dateTimeTo=${payload.data.dateTimeTo}&fromOdometer=${payload.data.fromOdometer}&id=${payload.data.id ? payload.data.id : ''}`
            }
          },
          callback: payload.callback,
          id: payload.id
        });
        response && this.downloadSuccess(response);
        setTimeout(() => {
          this.downloadSuccess('')
        }, 1000)
      } catch (error) {
        payload.callback('error', error.message);
      }
    },
    async download(payload) {
      try {
        const getApi = await import(
          `../../../../config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('downloadTruckHistoryApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject,
            data: payload.data
          },
          id: payload.apiId
        });
        response && this.downloadSuccess(response);
      } catch (error) {
        this.getFailed();
      }
    },
    filterList(status) {
      this.filterSuccess(status);
    }
  })
};
