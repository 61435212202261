import styled from 'styled-components';

const Wrapper = styled.div`
  #fleetManagerInfoSubmit {
    display: none;
  }
  .addressWrapper,
  .bottomWrapper {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    .MuiBox-root {
      flex-direction: column;
    }
  }

  .companyWrapper {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    padding-bottom: 1% !important;
  }
  .stateSelect label {
    padding-bottom: 14px;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS styles go here */
      padding-bottom: 5px;
    }
  }
`;
const TextWrapper = styled.div`
  /* flex: 0 0 25%; */
`;

const FieldsWrapper = styled.div`
${({ theme }) => `
flex: 0 0 30%;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  flex-direction: column;
  padding-right: 3%;
  @media (max-width: ${theme.breakpoints.values.md - 1}px) {
    flex: 2 0 30%;
   }
  `}
`;

const Styled = {
  Wrapper,
  TextWrapper,
  FieldsWrapper
};

export default Styled;
