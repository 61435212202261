import styled from 'styled-components';
import * as Parent from '../Base/style';

export const ModalWrapper = styled(Parent.ModalWrapper)`
${({ theme }) => `
.scheduleForm{
    overflow: auto;
    max-height: 600px;
    .itemWrapper{
      margin-bottom: 10px;
      display: -webkit-inline-box;
        width: 100%;
        .label {
          width: 18%;
        }
        .value {
          font-weight: 600;
        }
      }
    form {
      align-items: center;
    }
    .scheduleDateWrapper{
      width: 50%;
      .fieldWrapper{
        display: block !important;
      }
    }
    padding: 0 60px;
    .fullWidth{
      width:100%;
  }
  .radioWrapper{
      width:100%;
      margin-bottom: 10px !important;
      .label{
          align-items: center;
          display: flex;
          flex: 0 0 18% !important;
          color: ${theme.colors.PRIMARY_CONTRAST};
      }
      .recurrenceIntervalBox {
          display: block !important;
      }
      .infoText{
          color: ${theme.colors.SECONDARY_MAIN};
      }
  }
  .recurrenceIntervalBoxLabel{
      padding-bottom: 25px;;
  }
  }
.MuiPopover-root {
    .MuiMenu-paper .MuiButtonBase-root{
        padding: 0;
    }
}
.fieldSection{
    justify-content: space-between;
     .MuiBox-root{
    flex: 0 0 70%;
     }
}
.subheaderSection {
    padding-left: 0 !important;
    text-transform: capitalize
    color: #000000 !important;
}
.deletePopupBtn {
    button {
        font-family: ${theme.palette.titleFont};
        font-size: 23px;
        height: 22px;
        font: normal 12px "Helvetica Neue", Helvetica, Arial, sans-serif;
        border: none;
        padding: 5px 15px !important;
        border-radius: 4px;
        text-transform: capitalize;
        &:hover {
            background: #2d3942 !important;
        }
    }
}
.imageSection img {
    width: 58px;
}
.successpopup h2 {
    font-size: 20px;
    margin-top: 0;
}
`}
`;
export const ModalWrapper2 = styled(Parent.ModalWrapper2)`
${({ theme }) => `
.MuiPopover-root {
    .MuiMenu-paper .MuiButtonBase-root{
        padding: 0;
    }
}
.fieldSection{
    justify-content: space-between;
     .MuiBox-root{
    flex: 0 0 70%;
     }
}
.MuiTypography-h1{
    padding-left: 0 !important;
    color: #000000 !important;
    text-transform: capitalize !important;
}
.subheaderSection {
    padding-left: 0 !important;
    text-transform: capitalize
    color: #000000 !important;
}
.imageSection img {
    width: 58px;
}
.deletedRow {
    color: ${theme.colors.MODAL_HEADING};
    padding-bottom: 20px;
}
.carousel-root .carousel.carousel-slider {
    .control-arrow {
        height: 34px !important;
    }
    .control-arrow:before{
        top: 0px;
        left: -10px;
        position: relative;
    }
    .control-next.control-arrow:before{
        left: -6px;
    }
}
.deletePopupBtn {
}
.successpopup h2 {
    font-size: 20px;
}
`}
`;
export const ParentWrapper = styled(Parent.ParentWrapper)`
.MuiPopover-root {
    .MuiMenu-paper .MuiButtonBase-root{
        padding: 0;
    }
}
`;
