import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
padding: 0;
`;

const SearchWrapper = styled(Parent.SearchWrapper)`
flex: 0 0 68%;
`;

const MapWrapper = styled(Parent.MapWrapper)`
${({ theme }) => `
padding: 0;
#map {
    border:0 !important;
    
}
.quickFilter{
    display:block;
    color: ${theme.colors.WHITE};
    text-transform: capitalize;
    border-top: 2px solid ${theme.colors.PRIMARY_BORDER} !important;
    background: ${theme.colors.LINK_COLOR};
    padding: 1%;
    padding-left: 2%;
    font-size: 22px;
  }
`}
`;
const TableWrapper = styled(Parent.TableWrapper)`
padding: 0 2%;
`;
const Styled = { Wrapper, SearchWrapper, MapWrapper, TableWrapper };

export default Styled;
