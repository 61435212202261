import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Container = styled(Box)`
  ${({ theme, type }) => `
  color: ${(theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN};
  .active {
    background-color: transparent !important;
  }
  a {
    color: ${theme.colors.LINK_COLOR}; 
    text-decoration: none;
    &:hover {
      cursor: pointer;
        text-decoration: underline !important;
    }
  }
  tr {
    vertical-align: top;
    position: relative;
  }
  td {
    position: relative;
  }
  .noData {
    text-align: center;
    svg {
      width: 40px;
      height: 40px;
      color: ${
        (theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN
      };
    }
  }
  width: 100%;
  .MuiPaper-root {
    width: 100%;
  }
  svg {
    width: 13px;
    height: 13px;
  }

  .MuiTableCell-head {
    padding: 10px 15px 10px 7px !important;
    text-align: left !important;
    font-size: 17px;
    line-height: 1.2;
    color: ${
      (theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN
    };
  }
  .MuiTableSortLabel-active{
    position: relative;
    padding-left: 20px;
  }
  
  .MuiTableSortLabel-active .MuiTableSortLabel-iconDirectionAsc,
  .MuiTableSortLabel-active .MuiTableSortLabel-iconDirectionDesc {
    position: absolute;
    right: 11px;
    top: -5px;
    width: 1.9rem;
    // left: -11px;
    svg {
      width: 1.9rem;
      height: 1.6rem;
    }
  }
  .MuiTableSortLabel-active .MuiTableSortLabel-iconDirectionAsc{
    top: -9px !important;
  }
  .MuiTableSortLabel {
    line-height: 0.5;
  }
  .MuiIcon-root {
    width: 1.1rem;
    height: auto;
  }
  .MuiTableBody-root tr {
    background: ${theme.colors.BLACK};
    td {
      background-color: transparent;
    }
  }
  .MuiTableBody-root tr:hover {
    background: ${theme.colors.PRIMARY_MAIN};
  }
  .MuiTableSortLabel-iconDirectionAsc {
    width: 1.1rem;
    color: ${theme.colors.WHITE};
  }

  .MuiTableSortLabel-iconDirectionDesc {
    width: 1.1rem;
    color: ${theme.colors.WHITE};
  }
  .MuiTableCell-root {
    padding: 8px 10px;
    border-bottom: 2px solid
      ${(theme.palette.primary || {}).border || theme.colors.PRIMARY_BORDER};
  }
  .MuiTableBody-root .MuiTableCell-root {
    font-size: 16px;
    border-bottom: 0.5px solid
      ${(theme.palette.primary || {}).border || theme.colors.PRIMARY_MAIN};
  }
  .MuiAvatar-root {
    width: 20px;
    height: 20px;
  }
  .MuiTableRow-root {
    background-color: ${type === 'udLogs' ? 'none' : theme.colors.PRIMARY_MAIN};
  }
  `}
`;

const TableContainer = styled(Box)`
  // overflow-x: auto;
  overflow: visible;
  .visuallyHidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1;
    margin: -1;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 20;
    width: 1;
  }
  table {
    min-width: 300px;
  }
  .MuiTableCell-alignCenter {
    text-align: center !important;
    width: 50px;
  }
`;
const PaginationContainer = styled(Box)`
  ${({ theme }) => `
  text-align: center;
  .MuiButton-root {
    background-color: ${theme.colors.PAGINATION_BACKGROUND};
    font-size: 1.5rem;
    font-weight: bold;
  }
  .MuiButton-textPrimary {
    color: ${theme.colors.PAGINATION_COLOR};
    // padding: 0px;
  }
  .MuiFlatPageButton-rootEnd {
    padding: 0px;
  }
  .MuiButton-root.Mui-disabled {
    color: #666;
  }
  `}
`;
const PaginationWrapper = styled(Box)`
  ${({ theme }) => `
  text-align: center;
  `}
`;
const Styled = {
  Container,
  TableContainer,
  PaginationContainer,
  PaginationWrapper
};

export default Styled;
