import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
padding: 0;
`;

const SearchWrapper = styled(Parent.SearchWrapper)`
flex: 0 0 68%;
`;

const MapWrapper = styled(Parent.MapWrapper)`
padding: 0;
#map {
    border:0 !important;
}
`;
const TableWrapper = styled(Parent.TableWrapper)`
  ${({ theme }) => `
  .MuiPaper-root {
    padding: 0 3%;
    }
  border-top: 2px solid ${theme.colors.PRIMARY_BORDER};
.iconContainer  {
    background: ${theme.colors.LINK_COLOR};
    padding-right: 5%;
    padding-top: 9px;
    .MuiAvatar-root {
        width: 20px;
        height: 21px;
    }
}
.iconContainer:hover + .tooltiptext {
    visibility: hidden;
}
.MuiTable-root .MuiTableCell-root:last-child {
    text-align: center !important;
    .iconContainer  {
        justify-content: center;
    }
}
`}
`;
const Styled = { Wrapper, SearchWrapper, MapWrapper, TableWrapper };

export default Styled;
