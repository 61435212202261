import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
${({theme})=>`
  .filterForm {
    flex: 0 0 72%;
    input{
      background: ${theme.colors.INPUT_BACKGROUND};
    }
    .react-datepicker-wrapper input{
      background-color: ${theme.colors.INPUT_BACKGROUND};
    }
  }
  .exportContainer {
    min-width: 92px;
    margin-right: 10px !important;
    .exportWrapper {
      width: 100%;
    }
  }
`}
`;
const ExportOptionWrapper = styled(Parent.ExportOptionWrapper)`
${({theme})=>`
  .MuiBox-root {
    background: ${theme.colors.SECONDARY_MAIN};
    a {
      color: #fff;
    }
  }
`}
`;
const TableWrapper = styled(Parent.TableWrapper)``;
const SearchWrapper = styled(Parent.SearchWrapper)``;
const Styled = { Wrapper, ExportOptionWrapper, TableWrapper, SearchWrapper };

export default Styled;
