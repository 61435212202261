import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';

const layout = window?.$environment?.CURRENT_LAYOUT;
const SvgIcon = lazy(() => import(`../../../components/SvgIcon/${layout}`));

const FleetInfo = ({ title, value, icon, description }) => {
  return (
    <Tooltip poverlayStyle={{ maxWidth: "300px", opacity: 1}}  mouseEnterDelay={0.5} placement='top' overlay={<span>{description || 'NA'}</span>}>
      <div className="grid-item count" id="my-element" data-tooltip-content="hello world">
        <div className="icon-wrapper">
          <SvgIcon value={icon} />
        </div>
        <div className="summary-wrapper">
          <span className="summary-header">{title}</span>
          <span className="summary-content">{value}</span>
        </div>
      </div>
    </Tooltip>
  );
};

export default FleetInfo;

FleetInfo.defaultProps = {};

FleetInfo.propTypes = {
  title: PropTypes.object.isRequired,
  value: PropTypes.object.isRequired,
  icon: PropTypes.object.isRequired,

};
