import styled from 'styled-components';
import Parent from './style';
const Wrapper = styled(Parent.Wrapper)`
    ${({ theme }) => `
    min-height: calc(100vh - 77px);
    justify-content: flex-start;
    `}
`;

const Styled = { Wrapper};

export default Styled;
