import styled from 'styled-components';
import Parent from './style';

const ExportWrapper = styled(Parent.ExportWrapper)``;
const Header = styled(Parent.Header)`
  ${({ theme }) => `
justify-content: flex-start;
padding: 0 25px;
.udcheckWrapper{
  flex: 0 0 17%;
  .MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-size: 15px !important;
  }
}
.filterForm {
  padding-top: 10px;
  .MuiBox-root[type="text"] p {
      display: none;
  }
  p {
      color: #000000 !important;
      font-size: 20px !important;
      opacity: 0.87;
  }
  div[type='date'] {
    position: relative;
    width: 10%;
    .MuiSvgIcon-root {
      position: absolute;
      right: 0;
      width: 18px;
      height: 18px;
      color: #767676;
      bottom: 7px;
    }
  }
}
.exportWrapper .exportContainer {
  .tooltiptext {
    visibility: hidden;
  }
  a {
    color: #007aff;
    font-size: 15px;
    text-decoration: none;
  }
}
`}
`;
const InfoContainer = styled(Parent.InfoContainer)`
padding-right: 2%;
padding-top:0;
.seperator {
  justify-content: flex-end;
  flex-direction: column;
  p:first-child {
    color: #000;
    font-size: 20px;
    text-align: right;
  }
  :first-child {
    padding-top: 0;
  }
}
.seperator:first-child {
  padding-right: 27px;
 }
p:last-child {
  font-size: 18px;
}
`;
const ToolsContainer = styled(Parent.ToolsContainer)``;
const UdLists = styled(Parent.UdLists)``;
const Arrowdown = styled(Parent.Arrowdown)`
  .udlogExpandedtable {
    border-top: 1px solid #ddd;
    background: #fff;
    form {
      padding: 0;

      .MuiInputBase-input.Mui-disabled{
        opacity: 1 !important;
        color: black !important;
        border: none !important;
    }
    }
    button.MuiButtonBase-root {
      font-size: 12px;
      padding-left: 0;
    }
    .MuiTableRow-head {
      border-bottom: 1px solid #ddd;
    }
  }
`;
const UdlogTable = styled(Parent.UdlogTable)`
  ${({ theme }) => `
.spireonNewOnly{
  display flex;
  align-items: flex-end;
  padding-left: 25px;
  padding-bottom: 20px;
  span {
      padding-right: 25px;
      font-size: 20px;
  }
}

#udLogTable {
  .expanded img {
    transform: rotate(360deg) !important
  }
.saveIcon {
  width: 17px;
  height: 18px;
  opacity: 0.5;
  margin-left: 9px;
}
  .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:last-child {
    padding-left: 0 !important;
    text-align: left !important;
    .historyIcon {
      width: 20px;
      height: 20px;
      color: #757575;
    }
  }
  .floatingudTable{
    .MuiTable-root {
      .MuiTableCell-root:first-child {
        padding-left: 59px !important;
      }
    } 
    
    .MuiTable-root .MuiTableRow-root .MuiTableCell-root .MuiBox-root .MuiBox-root,
    .MuiTableBody-root .MuiTableCell-root{
      color: rgba(0, 0, 0, 0.7);
    } 
    div[type="error"] p {
      text-align: center;
    }
  }
  .MuiTableRow-root.nodataspireon .MuiTableCell-root:last-child {
    text-align: center !important;
  }
  .MuiTableRow-root td:first-child {
      span {
          padding-left: 28px;
      }
      .MuiGrid-root {
          position: absolute;
          left: 10px;
          background-color: #4a4a4a;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          opacity: 0.54;
          img {
              width: 12px;
              height: 10px;
              object-fit: contain;
              object-position: bottom;
              transform: rotate(-90deg);
          }
      }
  }
  .MuiButtonBase-root span {
    padding-left: 0 !important;
  }
}
`}
`;
const UnidentifiedSection = styled(Parent.UnidentifiedSection)`
  ${({ theme }) => `
  position: relative;
    width: 100%;
.MuiTableHead-root .MuiTableCell-root {
  font-size: 12px !important;
}
  .MuiPaper-root .MuiBox-root:first-child {
    overflow-x: initial;
  }
  .select__option{
    font-size: 12px !important;
  }
  .MuiTableBody-root .MuiTableCell-root:nth-child(6) .Selectclass{
    min-width: 85px;
    .select__placeholder {
      max-width: calc(100% - 8px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .Selectclass .select__control {
    color: rgba(0, 0, 0, 0.87); !important;
    border: 0;
    height: 20px;
    min-height: 20px !important;
    max-height: 20px;
  }
  .Selectclass {
    border: 1px solid #d0d0d0 !important;
  }
  .MuiInputBase-root{
    border:0 !important;
    .MuiInputBase-input {
      border: 1px solid #d0d0d0 !important;
      color: rgba(0, 0, 0, 0.7);
      &:active,
      &:focus {
        border: 1px solid #000 !important;
    }
    }
    &:active,
    &:focus {
      border: 0 !important;
    }
  }
  input {
    padding-left: 2px !important;
  }
  .saveButton{
    justify-content: flex-start;
    border-top: 1px solid #ddd;
    border-bottom: 2px solid #ddd;
    padding-bottom: 4px;
    padding-left: 56px;
    visibility: hidden;
    height: 0;
  }
  #button{
    padding: 0;
  }
  .select--is-disabled{
    pointer-events: auto;
  }
  .MuiInputBase-root.Mui-disabled{
    opacity: 0.8;
    cursor: no-drop;
  }
  .select__control.select__control--is-disabled:hover {
    cursor: no-drop;
  }
  .MuiInputBase-input.Mui-disabled{
    opacity: 1 !important;
    :hover {
      cursor: no-drop;
    }
  }
  input:focus{
    border: 0 !important; 
  }
  .MuiInput-root{
    height: 20px;
  }
  .MuiInputBase-root .MuiInputBase-input,
  .MuiInputBase-root {
    font-size: 12px !important;
  }
.Selectclass{
  .select__control .select__single-value {
    font-size: 12px;
    top: 47%;
    color: rgba(0, 0, 0, 0.7);
  }
  .select__placeholder{
    top: 43%;
    font-size: 12px !important;
    color: rgba(0, 0, 0, 0.7);
  }
}
`}
`;
const Content = styled(Parent.Content)`
  position: relative;
  .goBack {
    position: absolute;
    top: 11px;
    left: 25px;
  }
  .logwrapper .MuiTabs-root {
    padding-left: 0;
  }
  header {
    box-shadow: none;
    padding-left: 25px;
  }
`;
const Styled = {
  ExportWrapper,
  Header,
  InfoContainer,
  ToolsContainer,
  UdLists,
  Arrowdown,
  UdlogTable,
  UnidentifiedSection,
  Content
};

export default Styled;
