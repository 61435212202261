import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
padding: 0;
.MuiPaper-root {
    box-shadow: 0 2px 10px rgba(0,0,0,0.03);
    .MuiAccordionDetails-root{
        border-top: 2px solid #000;
        padding: 1% 5%;
    }
    .MuiAccordionSummary-content h2 {
        font-size: 24px !important;
        text-transform: uppercase !important;
        color: ${theme.colors.WHITE}!important;;
    }
    .MuiIconButton-label {
        .MuiSvgIcon-root {
            fill: ${theme.colors.LINK_COLOR};
        }
    }
    .MuiAccordionDetails-root form .buttonWrapper button {
        margin-top: 29px;
    }
}
`}
`;

const Styled = { Wrapper };

export default Styled;
