import React, { lazy } from 'react';

const layout = window?.$environment?.CURRENT_LAYOUT;

const Search = lazy(() => import(`@/components/UI/SimpleSearch/${layout}`));

const TopActions = ({ Styled, vehicleStatus, i18n, setPagination, filterSuccess }) => {
  const selectChange = (e) => {
    filterSuccess(e);
    setPagination(true)
  };
  return (
    <Styled.SearchWrapper>
      <Search
        id={'clearSearch'}
        onChange={(e) => selectChange(e)}
        default={vehicleStatus?.vehicleStatus}
        fromData={true}
        placeHolder={i18n.t('vehicles.searchVehicle')}
        query={'vehicleName'}
        width="35%"
        vehicleStatus
        id='searchVehicleList'
      />
    </Styled.SearchWrapper>
  );
};
export default TopActions;
