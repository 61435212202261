import Styled from 'styled-components';

const PrivacyWrapper = Styled.div`
padding: 10px;
font-family: Arial;
background: colors.WHITE;

h1 {
    text-align: center;
    font-size: 24px;

}
.contentWrapper {
  padding: 20px 30px;
  font-size: 12px;

a {
  color: #f58220;
    text-decoration: none;
}
}
`;

export default PrivacyWrapper;
