import React, { useEffect, lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RenderComponent from '@/components/modules/DynamicRenderPage';

import ParentStyled from './style';
import { loadStyle, loadPageConfig, hasRestrictedRole } from '@/utils/utils';
import { ROLES } from '@/config/constants/keys'
let Styled = loadStyle(ParentStyled, 'pages/web/WorkOrders');
let timer;
const config = loadPageConfig();
const Title = lazy(() => import(`@/components/Title`));
const Content = lazy(() => import(`@/${config.workOrders[1].name}`));
const TopActions =
  config.userList[0].children &&
  lazy(() => import(`${config.userList[0].children.name}`));

const isSpireonNew =  window.$environment.CURRENT_LAYOUT === 'SpireonNew';  

const WorkOrders = (parentProps) => {
  const { fetchWorkOrders, history, i18n, theme, getFilters, workOrders } = parentProps;
  const basicRequest = {
    displayLength: '10',
    displayStart: '1',
    state: "OPEN"
  };
  const [request, setRequest] = useState({
    ...basicRequest
  });
  const commonParentProps = { history, i18n, Styled, theme };
  const [pagination, setPagination] = useState(false);
  const [filter, setFilter] = useState('OPEN');

  const apiId = 'fetchWorkOrders';

  const sortColumnMapping = {
    // unitId: "unitId",
    // work_order_id: "workOrderId",
    // type: "type",
    // reported_date: "reportedDate",
    // scheduled_date: "scheduledDate",
    // due_date: "dueDate",
    // repair_cost: "repairCost",
    // workOrderType: "workOrderType",
    // status: "status",
    // report: "report",
    report: "inspection.report",
    status: "state",
    workOrderType: "type",
    repair_cost: "cost",
    due_date: "endDate",
    scheduled_date: "beginDate",
    reported_date: "createDate",
    type: "assetType",
    work_order_id: "idPub",
    mechanicName: "mechanicName",
    driverName: "driverName",
    unitType: "unitType",

    unitId: "vehicleName",
    vin: "vin"
  }

  const changeRequest = (fields) => {
    if (fields?.sortCol) {
      const type = fields.sortCol
      fields.sortCol = sortColumnMapping[type] ? sortColumnMapping[type] : fields.sortCol
    }
    setRequest({ ...basicRequest, ...fields });
  };
  useEffect(() => {
    if (filter != request.state) setRequest({ ...request, state: filter })
  }, [filter])

  useEffect(() => {
    fetchWorkOrders({
      apiId: apiId,
      data: request,
    });
  }, [request]);

  const filterSuccess = (data) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setRequest({ ...request,  displayLength: basicRequest?.displayLength, displayStart: basicRequest?.displayStart, search: data })
    }, 500);

  }

  const searchConfig = {
    setPagination,
    searchPlaceholder: i18n.t('workOrders.search'),
    fromData: false,
    filterSuccess,
    data: workOrders?.list,
    query: ['work_order_id', 'unitId', 'driverName', 'mechanicName'],
    isSpireonNew
  };
  const viewOnly = hasRestrictedRole([ROLES.VIEW_ONLY_FM]);
  useEffect(() => {
    getFilters({ apiId: 'fetchFilters' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <RenderComponent
        {...config.workOrders[0]}
        parentProps={{ ...parentProps, pagination, setPagination, filterSuccess }}
        commonParentProps={commonParentProps}
        component={Title}
        childComponent={TopActions}
        searchConfig={
          window.$environment.CURRENT_LAYOUT === 'SpireonNew'
            ? searchConfig
            : null
        }

      />
      <RenderComponent
        {...config.workOrders[1]}
        parentProps={{ ...parentProps, pagination, setPagination, viewOnly, request, changeRequest, filter, setFilter, filterSuccess }}
        commonParentProps={commonParentProps}
        component={Content}
      />
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    workOrders: state.workOrders,
    filters: state.filters
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchWorkOrders: (data) => dispatch.workOrders.fetchPaginateData(data),
    viewReport: (data) => dispatch.viewReport.viewReportDocument(data),
    getFilters: (data) => dispatch.filters.fetchData(data),
    clearState: (data) => dispatch.viewReport.clearReceipt()
  };
};

export default connect(mapState, mapDispatch)(WorkOrders);
WorkOrders.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  fetchWorkOrders: PropTypes.func.isRequired,
  workOrders: PropTypes.array.isRequired
};
