import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import { getImagePath } from '../../../../../utils/utils';
const Wrapper = styled(Box)`
${({ theme }) => `
.welcomeText {
  padding-top: 3%;
  padding-right: 3%;
}
.details {
  opacity: 0.67;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  padding-top: 20px;
  .linkColor {
    color: ${theme.colors.LINK_COLOR};
    text-decoration: none;
  }
}
.downloadWrapper {
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  padding-top: 42px;
  padding-bottom: 20px;
}
.downloadLabel {
  font-size: 25px;
  font-weight: 600;
  padding-right: 21px;
  color: #fff;
  margin-top: 8px;
}
.downloadApp {
  width: 17%;
  img {
    width: 85%;
    border: 1px solid #fff;
    border-radius: 4px;
  }
}
.iframeWrapper {
  position: relative;
  overflow: hidden;
  padding-top: 37.25%;
  border-radius: 4px;
}
.respIframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
  `}
`;

const Styled = { Wrapper };

export default Styled;