import React, { useEffect, lazy, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import RenderComponent from '@/components/modules/DynamicRenderPage';
import ParentStyled from './style';
import { loadStyle, loadPageConfig, getUserMail, getCompanyId } from '@/utils/utils';

let Styled = loadStyle(ParentStyled, 'pages/web/ManageScheduling');
const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.manageScheduling[0].name}`));
const Content = lazy(() => import(`@/${config.manageScheduling[1].name}`));

const ManageScheduling = (parentProps) => {
  const { fetchReportTypes, fetchSchedulingList, deleteScheduling, editScheduling, callback, fetchVehicles, getDrivers, drivers, vehicles } = parentProps;
  const [pagination, setPagination] = useState(false);
  const basicRequest = {
    displayLength: '15',
    displayStart: '1'
  };
  const [request, setRequest] = useState({
    ...basicRequest
  });
  const history = useHistory();
  const theme = useTheme();
  const i18n = useTranslation();
  const commonParentProps = { history, i18n, Styled, theme };

  useEffect(() => {
    !vehicles.vehicleList.length &&
      fetchVehicles({
        apiId: 'fetchVehicles'
      });
    !drivers.data.length && getDrivers({ apiId: 'getDrivers' });
  }, []);

  const fetchPaginateApi = 'fetchSchedulingPaginateApi'

  const sortColumnMapping = {
    requestDateKey: 'requestDate',
    reportType: 'type'
  }
  const changeRequest = (fields) => {
    if (fields?.sortCol) {
      const type = fields.sortCol
      fields.sortCol = sortColumnMapping[type] ? sortColumnMapping[type] : fields.sortCol
    }
    setRequest({ ...basicRequest, ...fields });
  };

  useEffect(() => {
    fetchSchedulingList({
      apiId: fetchPaginateApi,
      data: { request: { ...request, fleetManagerEmail: getUserMail(), companyId: getCompanyId() } },
    });
  }, [request]);

  const handleDelete = (id) => {
    deleteScheduling({
      apiId: 'deleteSchedulingAPI',
      callback,
      data: {
        id,
        callbackPayload: {
          apiId: fetchPaginateApi,
          data: { request: { ...request, fleetManagerEmail: getUserMail(), companyId: getCompanyId() } },
        }
      }
    })
  }

  const handleEdit = (data) => {
    editScheduling({
      ...data,
      callbackPayload: {
        apiId: fetchPaginateApi,
        data: { request: { ...request, fleetManagerEmail: getUserMail(), companyId: getCompanyId() } },
      }
    })
  }

  useEffect(() => {
    fetchReportTypes({
      apiId: 'fetchReportTypes',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <RenderComponent
        {...config.manageScheduling[0]}
        parentProps={{
          ...parentProps,
        }}
        commonParentProps={commonParentProps}
        component={Title}
      />
      <RenderComponent
        {...config.manageScheduling[1]}
        parentProps={{
          ...parentProps,
          handleDelete,
          handleEdit,
          changeRequest,
          request,
          pagination,
          setPagination
        }}
        commonParentProps={commonParentProps}
        component={Content}
        pagination={pagination}
        setPagination={setPagination}
      />
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    paginateList: state.downloadCenter.schedulingList,
    reportTypes: state.downloadCenter.reportTypes,
    drivers: state.drivers,
    vehicles: state.vehicles,
  };
};

const mapDispatch = (dispatch) => {
  return {
    fetchSchedulingList: (data) => dispatch.downloadCenter.fetchSchedulingList(data),
    fetchReportTypes: (data) => dispatch.downloadCenter.fetchReportTypes(data),
    deleteScheduling: (data) => dispatch.downloadCenter.deleteScheduling(data),
    editScheduling: (data) => dispatch.downloadCenter.editScheduling(data),
    fetchVehicles: (data) => dispatch.vehicles.fetchData(data),
    getDrivers: () => dispatch.drivers.getDrivers(),
  };
};

export default connect(mapState, mapDispatch)(ManageScheduling);

