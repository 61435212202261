import styled from 'styled-components';

const Wrapper = styled.div`
  #companyInfoSubmit {
    display: none;
  }
  .addressWrapper {
    flex-direction: column;
  }

  .addressRow {
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flexbox;
    display: flex;
    flex-direction: row;
  }
  .companyWrapper,
  .phoneWrapper {
    padding-bottom: 10px;
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flexbox;
    display: flex;
  }
  .bottomWrapper {
    padding-bottom: 1.5% !important;
  }
  .buttonSection {
    justify-content: flex-end;
  }
  .stateSelect {
    label {
      padding-bottom: 14px;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        padding-bottom: 5px;
      }
    }
  }
`;
const TextWrapper = styled.div`
  margin-top: -3px;
`;

const FieldsWrapper = styled.div`
  ${({ theme }) => `
flex: 0 0 30%;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  flex-direction: column;
  padding-right: 3%;
  @media (max-width: ${theme.breakpoints.values.md - 1}px) {
    flex: 2 0 30%;
   }
  `}
`;

const Styled = {
  Wrapper,
  TextWrapper,
  FieldsWrapper
};

export default Styled;
