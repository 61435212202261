/* eslint-disable jsx-a11y/alt-text */
import React, { lazy, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import { deleteCookie } from '@/services/cookie';
import { pageUrls } from '@/config/constants/keys';
import { phoneNumberValidation, hasRestrictedRole,  hasEnabledFeature } from '@/utils/utils';
const layout = window?.$environment?.CURRENT_LAYOUT;
const Modal = require(`@/components/UI/Modal/${layout}`).default;
const Input = lazy(() => import(`@/components/UI/Input/${layout}`));
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const SubHeader = lazy(() => import(`@/components/SubHeader/${layout}`));
const CustomMessage = lazy(() =>
  import(`@/components/CustomMessage/${layout}`)
);
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);

const RenderComponent = ({
  values,
  handleEdit,
  errors,
  isNotValid,
  validateForm,
  submitAction,
  customProps,
}) => {
  const { Styled, i18n, getCsvStatusHistory, mfaStatus, qrString, generateMfaQrCode, validateMfaSetup, modifyMfaSettings, } = customProps;
  const Style = FormStyle.default;
  const [open, setOpen] = useState(false);
  const inputQrRef = useRef();
  const QrInputValueAuthHandler = (event) => {
    if(event.length > 6) {
       const inputField = document.getElementById('QrInput')
       if(inputField) {
        inputField.value = (event).slice(0, 6) // For 6 digit input
       }
    }
  }

  const ModalListObject = {
    header: 'MFA',
    content: () => <div>
      <h2>Please Scan the QR to generate the code.</h2>
      <img src={`data:image/jpeg;base64,${qrString}`} style={{height: "250px", width: "250px", margin: "10px auto", display: "block"}}/> 
      <Input id="QrInput" type="number" inputRef={inputQrRef} placeholder={i18n.t('common.qrCode')} 
          handleOnChange={QrInputValueAuthHandler}
        />
      <Button
        label={i18n.t('common.authenticateMFA')}
        onClick={() => {
          if (inputQrRef?.current?.children[0]?.value && inputQrRef?.current?.children[0]?.value?.length === 6) {
            validateMfaSetup({
              apiId: 'validateMfa',
              data: { mfaOtp: (inputQrRef?.current?.children[0]?.value).trim()}
            });
            setOpen(false)
          }
        }}
      />
    </div>
  };
  return (
    <Styled.Profile>
      {/* {customProps?.hasApiStatus?.errorMessage && (
        <CustomMessage
          type="error"
          message={customProps.hasApiStatus.errorMessage}
        />
      )} */}
      <div className="section-wrapper">
        <Style.TitleWrapper>
          <SubHeader variant="h1" className="userHeading" text={i18n.t('common.userMainInformation')} />
        </Style.TitleWrapper>
        <Style.FieldsWrapper className="vertical" width="20%">
          <Label variant="body1" mode="dark">
            {i18n.t('common.firstName')}
          </Label>
          <Style.TextWrapper mode="dark">
            <Field
              name="firstName"
              handleOnChange={(event) =>
                handleEdit(event, { field: 'firstName' })
              }
              as={Input}
              disabled={!customProps.disabled}
              placeholder={i18n.t('common.firstName')}
              value={values.firstName}
              type="text"
            />
            {errors.firstName && !isNotValid && (
              <Message type="error" message={errors.firstName} />
            )}
          </Style.TextWrapper>
        </Style.FieldsWrapper>

        <Style.FieldsWrapper className="vertical" width="20%">
          <Label variant="body1" mode="dark">
            {i18n.t('common.lastName')}
          </Label>
          <Style.TextWrapper>
            <Field
              name="lastName"
              handleOnChange={(event) => handleEdit(event, { field: 'lastName' })}
              as={Input}
              disabled={!customProps.disabled}
              placeholder={i18n.t('common.lastName')}
              value={values.lastName}
              type="text"
              mode="dark"
            />
            {errors.lastName && !isNotValid && (
              <Message type="error" message={errors.lastName} />
            )}
          </Style.TextWrapper>
        </Style.FieldsWrapper>

        <Style.FieldsWrapper className="vertical" width="20%">
          <Label variant="body1" mode="dark">
            {i18n.t('common.email')}
          </Label>
          <Style.TextWrapper>
            <Field
              name="email"
              handleOnChange={(event) => handleEdit(event, { field: 'email' })}
              as={Input}
              disabled={!customProps.disabled}
              placeholder={i18n.t('common.email')}
              value={values.email}
              type="email"
              mode="dark"
            />
            {errors.email && !isNotValid && (
              <Message type="error" message={errors.email} />
            )}
          </Style.TextWrapper>
        </Style.FieldsWrapper>

        <Style.FieldsWrapper className="vertical" width="20%">
          <Label variant="body1" mode="dark">
            {i18n.t('common.cellPhone')}
          </Label>
          <Style.TextWrapper>
            <Field
              name="userPhone"
              handleOnChange={(event) =>
                handleEdit(event, { field: 'userPhone' })
              }
              as={Input}
              mode="dark"
              disabled={!customProps.disabled}
              placeholderText={i18n.t('common.cellPhone')}
              value={values.userPhone}
              updateValue={phoneNumberValidation}
              type="text"
            />
            {errors.userPhone && !isNotValid && (
              <Message type="error" message={errors.userPhone} />
            )}
          </Style.TextWrapper>
        </Style.FieldsWrapper>
      </div>    
      <div className="section-wrapper">
        <Style.TitleWrapper>
          <SubHeader
            variant="h1"
            text={i18n.t('common.resetPassword')}
            className="heading"
          />
        </Style.TitleWrapper>
        <Style.FieldsWrapper className="vertical" width="20%">
          <Label variant="body1" mode="dark">
            {i18n.t('common.password')}
          </Label>
          <Style.TextWrapper>
            <Field
              name="password"
              handleOnChange={(event) => handleEdit(event, { field: 'password' })}
              as={Input}
              placeholder={i18n.t('common.password')}
              value={values.password}
              type="password"
              mode="dark"
            />
            {errors.password && !isNotValid && (
              <Message type="error" message={errors.password} />
            )}
          </Style.TextWrapper>
        </Style.FieldsWrapper>

        <Style.FieldsWrapper className="vertical" width="20%">
          <Label variant="body1" mode="dark">
            {i18n.t('common.confirmPassword')}
          </Label>
          <Style.TextWrapper>
            <Field
              name="confirmPassword"
              handleOnChange={(event) =>
                handleEdit(event, { field: 'confirmPassword' })
              }
              as={Input}
              placeholder={i18n.t('common.confirmPassword')}
              value={values.confirmPassword}
              type="password"
              mode="dark"
            />
            {errors.confirmPassword && !isNotValid && (
              <Message type="error" message={errors.confirmPassword} />
            )}
          </Style.TextWrapper>
        </Style.FieldsWrapper>
      </div>
      {window.$environment?.SERVICE_PROVIDER === 'spireon' && hasRestrictedRole(['DRIVER']) && <Style.TextWrapper><p onClick={() => getCsvStatusHistory({ apiId: 'getCsvStatusHistory' })}>Download: Driver Record of Duty Status History</p></Style.TextWrapper>}
      <Style.ButtonsWrapper className="vertical-button" width="100%">
        <div className='MFAWrapper'>
       { hasEnabledFeature("multiFactorAuthenticationEnabled") &&  
         <div style={{display: "flex" }}>
           {mfaStatus === 'YET_TO_SETUP' && 
            <div style={{marginRight:"20px"}}>
             <Button
               label={i18n.t('common.setUpMFA')}
               onClick={() => {
                 setOpen(true)
                 generateMfaQrCode({ apiId: 'generateMfaQrCode' })
               }}
              /> 
             </div>
            }
            {mfaStatus === 'ENABLED' && 
            <div style={{marginRight:"20px"}}>
             <Button
              label={i18n.t('common.disableMFA')}
              onClick={() => {
                modifyMfaSettings({
                  apiId: 'modifyMfaSetting',
                  data: {
                    action: "DISABLE"
                  }
                })
              }}
              />
           </div>
           }
            {mfaStatus === 'DISABLED' && 
            <div style={{marginRight:"20px"}}>
             <Button
              label={i18n.t('common.enableMFA')}
              onClick={() => {
                modifyMfaSettings({
                  apiId: 'modifyMfaSetting',
                  data: {
                    action: "ENABLE"
                  }
                })
              }}
            />
          </div>
          }
          {(mfaStatus === 'ENABLED' || values?.mfaStatus === 'DISABLED') && 
           <div style={{marginRight:"20px"}}>
            <Button
              label={i18n.t('common.removeMFA')}
              onClick={() => {
                modifyMfaSettings({
                  apiId: 'modifyMfaSetting',
                  data: {
                    action: "REMOVE"
                  }
                })
              }}
            />
           </div>
          }    
           </div>
          }
          <div>
            <Button
              type="submit"
              label={i18n.t('common.save')}
              onClick={() => {
                const validateResponse = validateForm();
                validateResponse.then((data) => submitAction(data));
              }}
            />
            {window.$environment?.SERVICE_PROVIDER === 'spireon' && <Button
              type="submit"
              label={i18n.t('Logout')}
              onClick={() => {
                deleteCookie('SPIREON_AUTH_DETAILS');
                window.location.href = pageUrls.login;
              }}
            />}
          </div>
        </div>

      </Style.ButtonsWrapper>
      <Modal open={open && !!qrString} setOpen={setOpen} data={ModalListObject} deletePopup />
    </Styled.Profile>
  );
};

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  customProps: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};

export default RenderComponent;
