import styled from 'styled-components';
import Box from '@material-ui/core/Box';

import { getStyle } from '../../../../utils/utils';

const FormWrapper = styled(Box)`
  ${({ theme, style, direction }) => `
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  flex-wrap: wrap;
  flex: ${(style || {}).flex || ''};
  flex-direction: ${direction || 'row'};
  .checkboxWrapper {
    height: 100%;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  align-items: flex-end;
}
  .MuiButtonBase-root {
    color: ${theme.colors.WHITE};
    font-size: 23px;
    border-radius: 0px;
    height: 45px;
    padding: 0px 30px !important
  }
 
  .MuiInputBase-input {
    color: ${theme.colors.PRIMARY_CONTRAST};
  }
  .MuiButton-root:hover {
    text-decoration: none;
    background-color: ${
      (theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN
    };
  }
  
  .react-datepicker-wrapper {
    width: 100%;
    min-height: 45px;
    height: 45px;
  }
  .react-datepicker__input-container input:disabled{
    opacity: 0.5;
  }
  .react-datepicker__month,
  .react-datepicker {
    background-color: ${theme.colors.PRIMARY_MAIN};
  }
  .react-datepicker__header {
    padding-top: 2px;
    border-bottom: none;
    background-color: ${theme.colors.PRIMARY_MAIN};
  }
  .react-datepicker__current-month,
  .react-datepicker__day-name,
  .react-datepicker__day {
    color: ${getStyle(
      (style || {}).color,
      (theme.palette.primary || {}).contrastText,
      theme.colors.PRIMARY_CONTRAST
    )};
  }
  .react-datepicker__day {
    border: 1px solid #8c8787 !important;
    padding-left: 9px;
  }
  .react-datepicker__input-container {
    height: 40px;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__day-name {
    font-weight: 700;
  }
  .react-datepicker__current-month {
    border: 1px solid #8c8787;
    height: 30px;
    border-radius: 4px;
    width: 97%;
    margin: 0 auto;
    padding-top: 5px;
  }

  .react-datepicker__day:hover {
    border-radius: 0px !important;
    background-color: ${
      (theme.palette.primary || {}).main || theme.colors.PRIMARY_MAIN
    };
    // color: ${theme.colors.WHITE};
  }
  .react-datepicker__day--selected {
    border-radius: 0px !important;
    background-color: ${
      (theme.palette.primary || {}).main || theme.colors.PRIMARY_MAIN
    }!important;
  }
  .react-datepicker__day--outside-month {
    visibility: hidden;
  }
  .react-datepicker__day--disabled,
  .react-datepicker__navigation--next--disabled,
  .react-datepicker__navigation--previous--disabled {
    opacity: 0.35;
  }
  .react-datepicker__day--today {
    background-color: ${
      (theme.palette.primary || {}).main || theme.colors.PRIMARY_MAIN
    }!important;
    color: ${
      (theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN
    }!important;
  }
  .react-datepicker__day--keyboard-selected {
    border-radius: 0px;
  }
  .css-1okebmr-indicatorSeparator {
    background-color: transparent;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    line-height: 1.9em;
    margin: 0.05em;
    height: 1.8em;
  }
  .react-datepicker__current-month {
    font-size: 1em;
  }
  .react-datepicker__navigation {
    line-height: 1.7em;
  }
  .react-datepicker-popper {
    z-index: 9999 !important;
  }
  .react-datepicker-popper[data-placement^='bottom'] {
    margin-top: 0px;
  }
  .select__menu {
    margin-top: 2px !important;
    z-index: 1000;
  }
  .select__placeholder + div {
    color: ${getStyle(
      (style || {}).color,
      (theme.palette.primary || {}).contrastText,
      theme.colors.PRIMARY_CONTRAST
    )}
    font-family: ${(theme.palette || {}).fontFamily};
  }
  .css-1wa3eu0-placeholder {
    font-family: ${(theme.palette || {}).fontFamily};
    font-size: 18px;
  }
  .select__multi-value {
    background: ${theme.colors.HEADER_COLOR};
    font-family: ${(theme.palette || {}).fontFamily};
  }
  .react-datepicker-popper[data-placement^='bottom'] {
    margin-top: 0px;
  }
  .react-datepicker-time__header {
    color: ${getStyle(
      (style || {}).color,
      (theme.palette.primary || {}).contrastText,
      theme.colors.PRIMARY_CONTRAST
    )}
  }
  .react-datepicker__time {
    background-color: ${
      (theme.palette.primary || {}).main || theme.colors.PRIMARY_MAIN
    }!important;
    color: ${getStyle(
      (style || {}).color,
      (theme.palette.primary || {}).contrastText,
      theme.colors.PRIMARY_CONTRAST
    )}
  }
  .react-datepicker__time-list {
    height: 177px !important;
    background-color: ${
      (theme.palette.primary || {}).main || theme.colors.PRIMARY_MAIN
    }!important;
  }
  .react-datepicker__time-list-item {
    border-top: 1px solid #8c8787 !important;
    border-right: 1px solid #8c8787 !important;
    height: 15px !important;
  }
  .react-datepicker__header--time {
    height: 32px;
    border: 1px solid #8c8787 !important;
  }
  li.react-datepicker__time-list-item:hover {
    cursor: pointer;
    background-color: ${
      (theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN
    }!important;
    color: white;
  }
  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 95px !important;
  }
  .react-datepicker__time-list-item--selected {
    background-color: ${theme.colors.LIGHT_BORDER} !important;
    color: ${
      (theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN
    }!important;
  }
  li.react-datepicker__time-list-item--selected:hover {
    background-color: ${theme.colors.LIGHT_BORDER} !important;
    color: white !important;
  }
  .react-datepicker__day--keyboard-selected {
    background-color: transparent;
  }
  .react-datepicker__header__dropdown {
    padding: 5px 0;
  }
  .react-datepicker__month {
    margin: 0.2rem;
    margin-bottom: .3rem;
  }
  .react-datepicker__month-select,
  .react-datepicker__year-select {
    background-color: ${
      (theme.palette.primary || {}).main || theme.colors.PRIMARY_MAIN
    }!important;
    color: ${getStyle(
      (style || {}).color,
      (theme.palette.primary || {}).contrastText,
      theme.colors.PRIMARY_CONTRAST
    )}
    font-family: ${(theme.palette || {}).fontFamily};
    margin-top: 1px;
    cursor: pointer;
    border: 1px solid #8c8787;
    border-radius: 1px;
    padding:8px;
  }
  .react-datepicker-wrapper input {
    width: 100%;
    height: 45px;
    padding-left: 10px;
    background: ${
      (theme.palette.primary || {}).main || theme.colors.PRIMARY_MAIN
    };
      none repeat scroll 0 0;
    border: 2px solid
    ${(theme.palette.primary || {}).border || theme.colors.PRIMARY_BORDER};
    color: ${getStyle(
      (style || {}).color,
      (theme.palette.primary || {}).contrastText,
      theme.colors.PRIMARY_CONTRAST
    )} ;
    font-family: ${(theme.palette || {}).fontFamily};
    font-size: ${(theme.palette || {}).bodyFontSize || '18px'};
  }
  .react-datepicker__close-icon::after {
    background-color: ${
      (theme.palette.primary || {}).border || theme.colors.PRIMARY_BORDER
    };
  }
  .react-datepicker__close-icon {
    top: 10px;
  }
  `}
`;

const FieldsWrapper = styled(Box)`
  ${({ theme }) => `
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  flex-direction: row;
  justify-content: flex-end;
  .MuiCheckbox-root {
    width: auto !important;
  }
  .MuiTypography-body1 {
    color: ${theme.colors.WHITE} !important ;
  }
  margin-right: 20px;
  width: ${({ width }) => width}
  align-items: ${({ type }) =>
    type === 'submit' || type === 'reset' ? 'flex-end' : ''};
  display: ${({ type }) =>
    type === 'submit' || type === 'reset' ? 'flex' : ''};
  `}
`;
const ButtonWrapper = styled(Box)`
  ${({ theme }) => `
  .buttonWrapper {
    width: 30%;
    margin-left: 45%;
  }
  .MuiTypography-body1 {
    font-size: 24px !important;
  }
  .MuiTypography-colorPrimary {
    padding-top: 7px;
    flex: 0 0 56%;
    justify-content: flex-end;
    color: ${theme.colors.WHITE};
  }
  `}
`;
const LoginHeader = styled(Box)`
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 22px;
`;
const LoginFields = styled(Box)`
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
`;
const LoginFieldWrapper = styled.div`
  flex: 0 0 77%;
`;

const Styled = {
  FormWrapper,
  ButtonWrapper,
  FieldsWrapper
};
export default Styled;
