import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
  ${({ theme }) => `
.MuiPaper-root{
background-color: ${theme.colors.PAGINATION_BACKGROUND} !important
}
.MuiPaper-root .MuiIconButton-label .MuiSvgIcon-root {
  fill: ${window.$environment.WHITE_THEME ? '#000' : '#fff'};
}
.MuiPaper-root .MuiAccordionSummary-content h2 {
  font-size: 18px;
    text-transform: capitalize;
    color: ${window.$environment.WHITE_THEME ? '#000' : '#fff'} !important;
}
.MuiCollapse-wrapperInner {
  background-color: ${theme.colors.BODY_BACKGROUND} !important;
}

.MuiAccordionDetails-root {
  padding-top: 30px!important
}
.headingWrapper h2 {
    text-transform: inherit
}
.buttonStyle {
  margin-top: 30px
}
.buttonStyle .MuiBox-root  {
  margin-left: 15px
}
  display: flex;
  flex-direction: column;
  .dvirForms {
    justify-content: flex-start;
    width: 100%;
    .vertical-button {
      .MuiBox-root:first-child {
        margin-right: 14px;
      }
      .MuiButtonBase-root {
        margin-top: 17px;
        padding-left: 0;
        padding-right: 0;
        .MuiButton-label {
          padding: 2px 22px;
        }
      }
    }
  }
  .saveForms {
    flex-direction: column;
  }
  .btn-wrapper {
    display: flex;
    width: 100%;
  }
  .leftWrapper{
    width: 16%;
    .dvir-btn-active {
      border: 2px solid ${theme.colors.PRIMARY_BORDER};
      border-right: 0;
    }
  }
  .rightWrapper {
    width: 84%;
    border: 2px solid ${theme.colors.PRIMARY_BORDER};
    border-left: 0;
    padding: 1%;
    .dvir-content-item-active {
      display: flex;
      .partdetails {
        width: auto;
        border: 1px solid ${theme.colors.LINK_COLOR};
        padding: 4px 8px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        margin-right: 1%;
        
      }
      .normalCheckbox svg {
        width: 20px;
        height: 20px;
      }
      .MuiFormLabel-root {
        flex: 0 0 auto;
        padding-right: 10px;
        padding-bottom: 0;
        align-self: center;
        font-size: 16px;
        padding-bottom: 0 !important;
      }
      .roleWrapper {
        align-items: center;
        .Checkbox {
          top: 2px;
        }
        .MuiGrid-root {
          width: auto;
        }
        span {
          padding-left: 0px;
          padding-right: 4px;
          top: -6px;
          position: relative;
        }
      }
    }
  }
  .partsName {
    justify-content flex-start;
    width: 100%;
    border: 0;
    border-right: 2px solid ${theme.colors.PRIMARY_BORDER};
    color: ${theme.colors.PRIMARY_CONTRAST};
    padding: 10px;
    text-align: left;
    font-size: 20px;
    outline: none;
    text-transform: uppercase;
    cursor: pointer;
  }
  .saveChanges {
    padding-top: 1%;
  }
  .MuiButtonBase-root {
    margin-top: 0 !important;
  }
  .buttonWrapper {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 2%;
    .advancedDVIR {
      margin-right: 1%;
    }
    button {
      margin-top: 0;
    }
  }
  .dvir-content-item-active {
    display: block;
  }
  .dvir-content-item {
    display: none;
  }
 .deleteParent h2 {
   text-align: center;
 }
 .vehicleModal {
  text-align: start;
  z-index: 1;
  height: auto;
  padding: 10px;
  width: 167px;
  display: none;
  position: absolute;
  border: 2px solid ${window?.$environment?.SERVICE_PROVIDER === 'webfleet' || theme.colors.NEW_THEME? theme.colors.SECONDARY_CONTRAST: theme.colors.PRIMARY_BORDER};
  background: ${theme.colors.PRIMARY_MAIN};
  top: 49px;
  ul {
    list-style-type: none;
    padding-inline-start: 10px;
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding-left: 0;
    li {
      padding-bottom: 3px;
      a {
        color: ${theme?.colors?.LINK_COLOR} !important;
      }
    }
  }
  &::before {
    content: '';
    position: absolute;
    width: 0;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    border-bottom: 6px solid ${theme.colors.PRIMARY_BORDER};
    top: -7.5px;
    right: 7%;
  }
}
 #addUsers.vehicleModal{
  right: 100px;
  // &::before {
  //   left: 21%;
  // }
}
// #addUsers.vehicleModal::before {
//   left: 21%;
// }
#addUsers.vehicleModal{
  right: 0px;
  // &::before {
  //   left: 7%;
  // }
}
  `}
`;

const TopWrapper = styled(Box)`
  ${({ theme }) => `
  display: flex;
  justify-content: space-between;
  .exportContainer .MuiAvatar-root {
    width: 30px;
    height: 30px;
  }
  @media (max-width: ${theme.breakpoints.values.smd}px)  {
    flex-direction: column;
  }
  `}
`;
const LinkWrapper = styled(Box)`
  text-align: right;
  padding-bottom: 30px;
  ${({ theme }) => `
.addNewForm {
  color: ${theme.colors.LINK_COLOR};
  cursor: pointer
  with: 50px;

  .MuiAvatar-root {
    width: 35px !important;
    height: auto !important;
    margin-left: auto !important;
  }
}
`}
`;

const TableWrapper = styled(Box)`
  padding-top: 2.5%;
`;

const Styled = { Wrapper, TopWrapper, TableWrapper, LinkWrapper };

export default Styled;
