import styled from 'styled-components';
import Parent from './style';
const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `

`}
`;
const TableWrapper = styled(Parent.TableWrapper)`
${({ theme }) => `
.MuiTable-root .MuiTableBody-root .MuiTableCell-root:last-child {
    padding-left: 0 !important;
    .iconContainer .MuiAvatar-root {
        width: 15px !important;
        height: 18px !important;
    }
}
`}
`;
const Styled = { Wrapper, TableWrapper};

export default Styled;
