import React, { lazy, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';

import { useHistory } from 'react-router-dom';
import useTheme from '@material-ui/core/styles/useTheme';
import { useTranslation } from 'react-i18next';
import {
  getQueryStringKeys
} from '@/utils/utils';
import Styled from './style';

const Apology = ({ apiStatus, ...rest }) => {
  const history = useHistory();
  const theme = useTheme();
  const i18n = useTranslation();
  return (
    <>
    <Box style={{ ...theme.palette.contentStyle }}>
    <Styled.NotFoundWrapper>
          <h1>{i18n.t('apology')}...</h1>
          <p>{i18n.t('page moved')}</p>
          <p>
            {i18n.t('Please send us feedback')}
            <a href="/feedback" className="here" target="_blank">
              {i18n.t('here')}{' '}
            </a>
          </p>
        </Styled.NotFoundWrapper>
        </Box>
    </>
  );
};

export default Apology;
