import styled from 'styled-components';
import { FormStyle as ParentStyle } from '../Base/style';
const TitleWrapper = styled(ParentStyle.TitleWrapper)``;
const ButtonsWrapper = styled(ParentStyle.ButtonsWrapper)`
`;
const FieldsWrapper = styled(ParentStyle.FieldsWrapper)`
.MuiFormLabel-root,
p {
  font-size: 18px;
}

`;
const TextWrapper = styled(ParentStyle.TextWrapper)``;
const TextareaWrapper = styled(ParentStyle.TextareaWrapper)``;
const CheckBoxWrapper = styled(ParentStyle.CheckBoxWrapper)``;
const FormWrapper = styled(ParentStyle.FormWrapper)``;
const FormStyle = {
TitleWrapper,
  ButtonsWrapper,
  FieldsWrapper,
  TextWrapper,
  TextareaWrapper,
  CheckBoxWrapper,
  FormWrapper
};

export default FormStyle;
