import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
// padding: 3%;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  flex-direction: column;
  .quickfilter {
    display: none;
  }
`;

const TableWrapper = styled(Box)`
  padding-top: 2.5%;
`;
const Styled = {
  Wrapper,
  TableWrapper
};
export default Styled;
