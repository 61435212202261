import React, { useState, useEffect, lazy } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { imageUrls, pageUrls } from '@/config/constants/keys';
import FilterLogsListForm from './FilterForm';
import UdLogTable from './UdLogTable';
import {
  getImagePath,
  hasEnabledFeature,
  hasRestrictedRole
} from '@/utils/utils';
import { Link as LinkLi } from '@material-ui/core';
const layout = window?.$environment?.CURRENT_LAYOUT;

const Label = lazy(() => import(`@/components/UI/Label/${layout}`));
const Export = lazy(() => import(`@/components/UI/Export/${layout}`));
const Checkbox = lazy(() => import(`@/components/UI/Checkbox/${layout}`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const isEs = () => localStorage.getItem(window.$environment?.SERVICE_PROVIDER + 'Language') === 'es';
const UndefinedDriverLogs = ({
  i18n,
  history,
  udLogList,
  udFilterSuccess,
  apiStatus,
  fetchUdLogList,
  fetchUnAssignedEntries,
  unAssignedEntries,
  saveUnAssignedEntries,
  setUdAssignEntryList,
  clearUdAssignEntryListById,
  callback,
  exportData,
  totalData,
  udLogApiId,
  Styled,
  setPagination,
  pagination,
  clearAll
}) => {
  //form values for the filter form
  const [values, setValues] = useState({
    driver_name: '',
    dateFrom: '',
    dateTo: ''
  });
  const [selectedDriverProfile, setSelectedDriverProfile] = useState(false);
  const [selectedDriverTime, setSelectedDriverTime] = useState(true);
  const [errorMsg, setError] = useState('');
  const [selectedId, setSelectedId] = useState(false);
  useEffect(() => {
    setPagination(true);
    setValues({
      driver_name: '',
      dateFrom: '',
      dateTo: ''
    })
  }, [selectedDriverProfile]);
  const setSelectedIndexfn = (index) => { };

  const columns = [
    {
      id: 'driver_name',
      numeric: false,
      disablePadding: true,
      label: i18n.t('common.driver'),
      needSorting: true
    },
    {
      id: 'vehicle_name',
      numeric: false,
      disablePadding: false,
      label: i18n.t('common.vehicle'),
      needSorting: true
    },
    {
      id: 'date',
      numeric: false,
      disablePadding: false,
      label: i18n.t('common.date'),
      needSorting: true
    },
    {
      id: 'hours',
      numeric: true,
      disablePadding: false,
      label: i18n.t('common.hoursWorked'),
      needSorting: true
    },
    {
      id: 'distance',
      numeric: true,
      disablePadding: false,
      label: i18n.t('common.distance'),
      needSorting: true
    },

    {
      id: hasRestrictedRole(['ADMIN']) ? '' : 'actions',
      label: hasRestrictedRole(['ADMIN']) ? '' : 'Actions'
    }
  ];
  const actions = hasRestrictedRole(['ADMIN'])
    ? []
    : [
      {
        icon: (row, index) => {
          return (
            <>
              {!hasEnabledFeature('udBulkEditEnabled') && (
                <LinkLi
                  component={Link}
                  onClick={() => {
                    history.push(pageUrls.editLog.replace(':id', row.idPub));
                  }}
                  to={pageUrls.editLog.replace(':id', row.idPub)}
                >
                  <Icon
                    src={getImagePath(imageUrls.editLogIcon)}
                    tooltip={i18n.t('Edit')}
                  />
                </LinkLi>
              )}
              <LinkLi
                component={Link}
                onClick={() => {
                  history.push(
                    pageUrls.logsHistory.replace(':id', row.idPub)
                  );
                }}
                to={pageUrls.logsHistory.replace(':id', row.idPub)}
              >
                <Icon
                  src={getImagePath(imageUrls.logHistory)}
                  tooltip={i18n.t('History')}
                />
              </LinkLi>
            </>
          );
        }
      }
    ];

  const englishToSpanish = {
    'seconds': 'segundos',
    'minutes': 'minutos',
    'hours': 'horas',
    'days': 'días',
    'weeks': 'semanas',
    'months': 'meses',
    'years': 'años'
  };

  const englishTimeRegex = /(\d+)\s*(seconds?|minutes?|hours?|days?|weeks?|months?|years?)/g;

  function convertEnglishToSpanishTime(inputString) {
    return inputString.replace(englishTimeRegex, (match, value, unit) => {
      const spanishUnit = englishToSpanish[unit];
      const spanishValue = value === '1' ? '1' : value;
      const spanishValueWithUnit = `${spanishValue} ${spanishUnit}`;
      return spanishValueWithUnit;
    });
  }

  function convertEnglishToSpanishTimeInText(inputText) {
    const regex = /(\d+\s*(seconds?|minutes?|hours?|days?|weeks?|months?|years?))\/?(\d+\s*(seconds?|minutes?|hours?|days?|weeks?|months?|years?))?/g;
    return inputText.replace(regex, match => convertEnglishToSpanishTime(match));
  }
  useEffect(() => {
    if (exportData.length === 0) {
      udLogList.filteredList.map((item) => {
        return exportData.push({
          [i18n.t('common.driver')]: item.driver_name === 'Unidentified Driver' ? i18n.t('Unidentified Driver') : item.driver_name,
          [i18n.t('common.vehicle')]: item.vehicle_name,
          [i18n.t('common.date')]: item.date,
          [i18n.t('common.hoursWorked')]: item.hours,
          [i18n.t('common.distance')]: item.distance,
          [i18n.t('common.cycleRule')]: isEs() ? convertEnglishToSpanishTimeInText(item.cycleRule) : item.cycleRule
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportData]);
  const [clearAllClicked, setClearAllClicked] = useState(false)
  const handleClear = () => {
    setClearAllClicked(true)
  }
  return (
    <>
      <Styled.InfoContainer>
        <p className="seperator">
          <Label>{i18n.t('common.totalHoursWorked')}</Label>
          <Label>{totalData.hours?.toFixed(2)}</Label>
        </p>
        <p className="seperator">
          <Label>{i18n.t('common.totalDistance')}</Label>
          <Label>{totalData.distance}</Label>
        </p>
      </Styled.InfoContainer>
      <Styled.Header>
        <>
          <FilterLogsListForm
            values={values}
            udLogList={udLogList}
            handleClear={handleClear}
            udFilterSuccess={udFilterSuccess}
            setValues={setValues}
            i18n={i18n}
            setPagination={setPagination}
            Styled={Styled}
          />
        </>
        <div className="exportWrapper">
          <Export
            src={imageUrls.exportIcon}
            data={exportData}
            filename={i18n.t('logs.logs')}
            toolTip={i18n.t('common.export')}
            className="exportContainer"
            buttonName={i18n.t('Export Data')}
          />
        </div>
      </Styled.Header>
      <Styled.Header>
        <div className="udcheckWrapper">
          <Checkbox
            label={i18n.t('logs.unassignedDriveTime')}
            checked={selectedDriverTime}
            onClick={() => {
              if (!selectedDriverTime) {
                fetchUdLogList({
                  apiId: udLogApiId,
                  data: { showUnidentifiedDriver: false }
                });

                setSelectedDriverTime(true);
                setSelectedDriverProfile(false);
              }
            }}
            id="selectedDriverTime"
          />
          <Checkbox
            label={i18n.t('logs.unidentifiedDriverProfile')}
            checked={selectedDriverProfile}
            onClick={() => {
              if (!selectedDriverProfile) {
                fetchUdLogList({
                  apiId: udLogApiId,
                  data: { showUnidentifiedDriver: true }
                });
                setSelectedDriverTime(false);
                setSelectedDriverProfile(true);
              }
            }}
            id="selectedDriverProfile"
          />
        </div>
      </Styled.Header>
      <Styled.UdlogTable>
        <UdLogTable
          udLogList={udLogList}
          fetchUnAssignedEntries={fetchUnAssignedEntries}
          i18n={i18n}
          unAssignedEntries={unAssignedEntries}
          apiStatus={apiStatus}
          columns={columns}
          setSelectedIndexfn={setSelectedIndexfn}
          saveUnAssignedEntries={saveUnAssignedEntries}
          setUdAssignEntryList={setUdAssignEntryList}
          clearUdAssignEntryListById={clearUdAssignEntryListById}
          callback={callback}
          Styled={Styled}
          actions={actions}
          pageinationReset={pagination}
          pagenationClicked={(e) => {
            setPagination(!e);
          }}
          selectedDriverProfile={selectedDriverProfile}
          setSaveIconClicked={() => { }}
          setError={setError}
          setSelectedId={setSelectedId}
          clearAll={clearAll}
        />
      </Styled.UdlogTable>
    </>
  );
};

export default UndefinedDriverLogs;

UndefinedDriverLogs.defaultProps = {};

UndefinedDriverLogs.propTypes = {
  i18n: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  udLogList: PropTypes.object.isRequired,
  udFilterSuccess: PropTypes.func.isRequired,
  apiStatus: PropTypes.object.isRequired,
  fetchUdLogList: PropTypes.func.isRequired,
  fetchUnAssignedEntries: PropTypes.func.isRequired,
  unAssignedEntries: PropTypes.object.isRequired,
  saveUnAssignedEntries: PropTypes.func.isRequired,
  setUdAssignEntryList: PropTypes.func.isRequired,
  clearUdAssignEntryListById: PropTypes.func.isRequired
};
