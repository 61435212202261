import styled from 'styled-components';
import Parent from '../Base/clientStyle';
const ButtonWrapper = styled(Parent.ButtonWrapper)``;
const FieldsWrapper = styled(Parent.FieldsWrapper)``;
const FormWrapper = styled(Parent.FormWrapper)``;
const Styled = {
  ButtonWrapper,
  FieldsWrapper,
  FormWrapper
};

export default Styled;
