import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
padding: 2%;
padding-top: 0;
.userHeading {
    margin-top: 0;
}
form {
    flex-direction: column;
    width: 100%;
    .vertical {
        flex-direction: row;
        width: 100%;
    }
    .vertical-button {
        justify-content: flex-start;
    }
}

`;
const Profile = styled(Parent.Profile)`

`;
const Styled = { Wrapper, Profile };
export default Styled;
