import AES from 'crypto-js/aes';
import CryptoJS from 'crypto-js';
import { COOKIE_ENCRYPT_KEY, COOKIE_EXPIRY, COOKIE_AUTH_DETAILS } from '../config/constants/keys';
import { enterpriseFeaturesKeyMapperHandler } from '../utils/utils';
import cookie from 'react-cookies'
const cookieExpiry = window?.$environment?.COOKIE_EXPIRY;


const encryptData = (data) => {
  return AES.encrypt(JSON.stringify(data), COOKIE_ENCRYPT_KEY).toString()
}
export const setCookie = (cname, cvalue, rememberMe, exdays = cookieExpiry) => {
  const encryptedData = AES.encrypt(JSON.stringify(cvalue), COOKIE_ENCRYPT_KEY).toString();
  if (rememberMe) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 168 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();

    if (cname == COOKIE_AUTH_DETAILS) {
      let entDto = cvalue?.enterpriseFeaturesDto
      let auth_without_entDto = JSON.parse(JSON.stringify(cvalue))
      delete auth_without_entDto.enterpriseFeaturesDto

      document.cookie = `${cname}=${encryptData(auth_without_entDto)};${expires};path=/`;
      document.cookie = `${cname}_1=${encryptData(entDto)};${expires};path=/`;
    }
    else document.cookie = `${cname}=${encryptedData};${expires};path=/`;
  }
  else {
    if (cname == COOKIE_AUTH_DETAILS) {
      let entDto = cvalue?.enterpriseFeaturesDto
      let auth_without_entDto = JSON.parse(JSON.stringify(cvalue))
      delete auth_without_entDto.enterpriseFeaturesDto

      document.cookie = `${cname}=${encryptData(auth_without_entDto)};path=/`;
      document.cookie = `${cname}_1=${encryptData(entDto)};path=/`;
    }
    else document.cookie = `${cname}=${encryptedData};path=/`;
  }
};

const paseCookieData = (data) => {
  return JSON.parse(AES.decrypt(data, COOKIE_ENCRYPT_KEY).toString(CryptoJS.enc.Utf8))
}

export const getCookie = (cname) => {
  const name = cname + '=';
  const ca = document.cookie.split(';');
  let decryptedData = null;

  if (cname === COOKIE_AUTH_DETAILS) {
    let data = cookie.load(COOKIE_AUTH_DETAILS) || ''
    if (data) {
      try {
        decryptedData = paseCookieData(data)
        decryptedData.enterpriseFeaturesDto = paseCookieData(cookie.load(`${COOKIE_AUTH_DETAILS}_1`))
      } catch (err) {
        cookie.remove(cname, { path: '/' })
        cookie.remove(`${cname}_1`, { path: '/' })
      }
      if (decryptedData) return { ...decryptedData, enterpriseFeatureDto: enterpriseFeaturesKeyMapperHandler(decryptedData)?.enterpriseFeatureDto, subscriptions: enterpriseFeaturesKeyMapperHandler(decryptedData)?.subscriptions };
    }

  } else {
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        const data = c.substring(name.length, c.length);
        decryptedData = paseCookieData(data)
      }
    }
  }
  return decryptedData;
};

export const checkCookie = (name) => {
  const user = getCookie(name);
  if (user !== '') {
    return true;
  } else {
    return false;
  }
};

export const deleteCookie = (cname) => {
  cookie.remove(cname, { path: '/' })
  if (cname == COOKIE_AUTH_DETAILS) {
    cookie.remove(`${cname}_1`, { path: '/' })
  }
};
