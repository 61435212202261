import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// import Header from '../../../components/Header';
import Component from './Component';

const Login = ({ auth, i18n, theme, history, ...rest }) => {
  return (
    <>
      {/* <Header header="" auth={auth} i18n={i18n} history={history} /> */}
      <Component {...rest} theme={theme} i18n={i18n} />
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    login: state.login
  };
};
const mapDispatch = (dispatch) => {
  return {
    getLogin: (data) => dispatch.Login.getLogin(data),
    saveLogin: (data) => dispatch.Login.saveLogin(data)
  };
};

export default connect(mapState, mapDispatch)(Login);

Login.propTypes = {
  login: PropTypes.object.isRequired,
  apiStatus: PropTypes.object.isRequired,
  getLogin: PropTypes.func.isRequired,
  saveLogin: PropTypes.func.isRequired
};
