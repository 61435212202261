import styled from 'styled-components';

const TitleWrapper = styled.div`
  display: flex;

  .MuiBox-root {
    padding: 1% 3% 0 0;
  }

  & > div:first-child {
    width: 100%;
  }
`;

const Styled = {
  TitleWrapper
};
export default Styled;