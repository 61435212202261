export default {
    "vehicleOrFMsearch": "Use SEARCH to find a vehicle or contact your fleet manager.",
    "identifyAllParties": 'It is important to identify all parties involved and gather their information right away. If there are no witnesses click continue.',
    "signLogbook": "you are operating outside of compliance. Please Sign your logbook",
    "choosePngJpg": "Please choose .png or jpg",
    "invalidCharacters": "Invalid character(s) identified and removed.",
    "checkInternet": "You are offline. Please check your internet connection.",
    "breakEndsIn": 'Your Break Ends in :',
    "movedToOffDutyStatus": "You will be moved to OFF_DUTY status to begin the rest break. Are you sure you want to begin rest break?",
    "pendingReq": "You have pending requests. Would you like to view?",
    "companySuspended": "The company is suspended, please contact your fleet manager.",
    "cycleSwitchNotPosiible": "Cycle switch not possible without 24 hours Off Duty.",
    "provideSign": "Please provide your signature before saving.",
    "locationValidation": "Location should be at least 5 characters long and should not contain special symbols.",
    "includeTrailerNames": "Please include \\',\\' between trailer names. Only include trailer names.",
    "savedTemporarily": "Changes saved temporarily. The saved edits will be synced once you are online.",
    "vehicleMust": "You must have a vehicle selected to attach a trailer.",
    "vehicleBeforeDrivingStatus": "Please select a vehicle before switching to Driving status.",
    "vehicleBeforeSleeperStatus": "Please select a vehicle before switching to Sleeper status.",
    "adjustEdit": "You may not edit driving time captured by the ELD. Please adjust your edit accordingly.",
    "logoutTrackingWillStop": "You are going to logout. All tracking will be stopped.",
    "viewLastDownloadDailyLogs": "You are offline. You can only view the last downloaded dailylogs",
    "unidentifiedDriverLogsProcessed": "Unidentified Driver Logs are being processed. Please try again later.",
    "addCoDriver": "You have not added any Co-Drivers. Please search and add Co-Drivers by clicking the Add button.",
    "incompleteInspection": "You will not be able to continue edit because inspection is incomplete and it will be deleted.",
    "accidentDataWillBeLost": "Accident data will be lost and nothing will be sent to server.",
    "ScheduledDateBeforeReportedDate.": "Scheduled date cannot be before the reported date.",
    "DueDateGreaterthanScheduledOrReportedDate.": "Due date cannot be greater than scheduled or reported date.",
    "UnidentifiedDriverOnELD.": "There is an unidentified driver on the ELD.",
    "unexpectedErrorRegistration": "An unexpected error occurred while processing registration request. Please try again later.",
    "passwordValidation": "Password must have at least an uppercase character, a lowercase character, a digit, a special character, at least 6 characters and at most 15 characters long.",
    "linkToDownloadInspections": "Your download is being prepared. You will get an email with a link to download the inspections.",
    "subscriptionCancelled": "Your Subscription is cancelled . Please register the company again and try login",
    "incorrectCredentials": "Incorrect email or password. Please retry using correct credentials.",
    "recoveryLink": "If your email address is registered with us, you will receive a password recovery link at your email address.",
    "VINnumberValidation": "VIN number must be 17 character long. Numbers and letters only. I, O, or Q are not allowed.",
    "valueBwtween1to10Length": "Please enter value between 1 to 10 characters length.",
    "onlyNumbers": "Please use only numbers and - . Length must be 12 digits",
    "FMOrAdminCannotBeDriver": "Fleet Manager or Admin can not be a Driver.",
    "cantModifyFMroles": "You can't modify Fleet Manager and Fleet Manager (Default) roles.",
    "subscriptionError": "Some error occurred while updating the subscription. Please try after some time",
    "resetHelp": "We’ll help you reset it and get back on track.",
    "unexpectedErrorReq":"An unexpected error occurred while processing request. Please try again later.",
    "linkToDownloadLogs": "Your download is being prepared. You will get an email with a link to download the logs.",
    "reprtBeingPrepared": "Your report is being prepared and will be sent to your email."

}