import {
  dutyStatusReportApi,
  driverPerformanceReportApi,
  editedDataReportApi,
  rejectedEditsApi,
  drivingEventReportApi,
  eldDisconnectReportApi,
  odometerJumpReportApi,
  malfunctionReportApi,
  unassignedDrivingReportApi,
  violationReportApi,
  downloadReportApi,
  hosViolationsReportApi,
  billOfLadingReportApi,
  dailyHosViolationReportApi,
  dailyUnsignedLogReportApi,
  dailyFailedInspectionReportApi,
  dailyUnidentifiedDrivingReportApi

} from '../../../config/apis';

const initialState = {};

export const reports = {
  state: { ...initialState },
  reducers: {},
  effects: (dispatch) => ({
    async dutyStatusReport(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            data: payload.data,
            api: dutyStatusReportApi
          },
          callback: payload.callback,
          id: payload.id
        });
        payload.callback('success', response);
      } catch (error) {
        // this.listFailed();
      }
    },

    async driverPerformanceReport(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            data: payload.data,
            api: driverPerformanceReportApi
          },
          callback: payload.callback,
          id: payload.id
        });
        payload.callback('success', response);
      } catch (error) {
        // this.listFailed();
      }
    },
    async editedDataReport(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            data: payload.data,
            api: editedDataReportApi
          },
          callback: payload.callback,
          id: payload.id
        });
        payload.callback('success', response);
      } catch (error) {
        // this.listFailed();
      }
    },
    async rejectedEditsReport(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            data: payload.data,
            api: rejectedEditsApi
          },
          callback: payload.callback,
          id: payload.id
        });
        payload.callback('success', response);
      } catch (error) {
        // this.listFailed();
      }
    },
    async drivingEventReport(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            data: payload.data,
            api: drivingEventReportApi
          },
          callback: payload.callback,
          id: payload.id
        });
        payload.callback('success', response);
      } catch (error) {
        // this.listFailed();
      }
    },
    async eldDisconnectReport(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            data: payload.data,
            api: eldDisconnectReportApi
          },
          callback: payload.callback,
          id: payload.id
        });
        payload.callback('success', response);
      } catch (error) {
        // this.listFailed();
      }
    },
    async odometerJumpReport(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            data: payload.data,
            api: odometerJumpReportApi
          },
          callback: payload.callback,
          id: payload.id
        });
        payload.callback('success', response);
      } catch (error) {
        // this.listFailed();
      }
    },
    async malfunctionReport(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            data: payload.data,
            api: malfunctionReportApi
          },
          callback: payload.callback,
          id: payload.id
        });
        payload.callback('success', response);
      } catch (error) {
        // this.listFailed();
      }
    },
    async billOfLadingReport(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            data: payload.data,
            api: billOfLadingReportApi
          },
          callback: payload.callback,
          id: payload.id
        });
        payload.callback('success', response);
      } catch (error) {
        // this.listFailed();
      }
    },
    async unassignedDrivingReport(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            data: payload.data,
            api: unassignedDrivingReportApi
          },
          callback: payload.callback,
          id: payload.id
        });
        payload.callback('success', response);
      } catch (error) {
        // this.listFailed();
      }
    },
    async violationReport(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            data: payload.data,
            api: violationReportApi
          },
          callback: payload.callback,
          id: payload.id
        });
        payload.callback('success', response);
      } catch (error) {
        // this.listFailed();
      }
    },
    async downloadReport(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            data: payload.data,
            api: downloadReportApi
          },
          callback: payload.callback,
          id: payload.id
        });
        payload.callback('success', response);
      } catch (error) {
        // this.listFailed();
      }
    },
    async hosViolationsReport(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            data: payload.data,
            api: hosViolationsReportApi
          },
          callback: payload.callback,
          id: payload.id
        });
        payload.callback('success', response);
      } catch (error) {
        // this.listFailed();
      }
    },
    async dailyHosViolationReport(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            data: payload.data,
            api: dailyHosViolationReportApi
          },
          callback: payload.callback,
          id: payload.id
        });
        payload.callback('success', response);
      } catch (error) {
      }
    },
    async dailyUnsignedLogReport(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            data: payload.data,
            api: dailyUnsignedLogReportApi
          },
          callback: payload.callback,
          id: payload.id
        });
        payload.callback('success', response);
      } catch (error) {
      }
    },
    async dailyFailedInspectionReport(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            data: payload.data,
            api: dailyFailedInspectionReportApi
          },
          callback: payload.callback,
          id: payload.id
        });
        payload.callback('success', response);
      } catch (error) {
      }
    },
    async dailyUnidentifiedDrivingReport(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            data: payload.data,
            api: dailyUnidentifiedDrivingReportApi
          },
          callback: payload.callback,
          id: payload.id
        });
        payload.callback('success', response);
      } catch (error) {
      }
    }
  })
};
