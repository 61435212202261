import styled from 'styled-components';
import { getStyle } from '../../../utils/utils';
import Box from '@material-ui/core/Box';

const Wrapper = styled.div`
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 15px;
  padding-top: 20px;
  justify-content: space-between;
  align-items: baseline;
`;
const Main = styled.main`
  ${({ theme, drawerwidth, widthnounit }) => `
  background-color: ${theme.colors.PRIMARY_MAIN};
  box-shadow: 0 0 3px #bec3c6;
  width: ${`calc(100% - 16.8%)`};
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  flex-direction: column;
  background-position: center;
  flex: 0 0 81.8%;
`}
`;
const LeftDrawer = styled(Box)`
  ${({ theme, drawerwidth, widthnounit }) => `
  width: 16.8%;
  align-self: flex-start;
  .MuiIconButton-root:hover {
    background-color: ${getStyle(
      '',
      (theme.palette.primary || {}).main,
      theme.colors.PRIMARY_MAIN
    )};
  }
  .menuItems {
    background: #f9f9f9;
    color: #ffffff;
    height: auto;
    z-index: 1;
    overflow: auto;
    box-shadow: 0 0 3px #bec3c6;
    border-radius: 5px;
  }
  #header {
    background-image: -webkit-linear-gradient(top, #86919a 0%, #637179 100%);
    height: 32px;
    padding: 6px 10px 3px;
    color: #fff;
    box-sizing: border-box;
    text-shadow: 0px -1px 0px black;
    font-size: 15px;
    font-weight: bold;
  }
  .drawerIcon {
    position: absolute;
    top: 2.9%;
    z-index: 1000;
    left: ${widthnounit - 1}%;
    color: ${getStyle(
      '',
      (theme.palette.secondary || {}).main,
      theme.colors.SECONDARY_MAIN
    )};
    background-color: ${getStyle(
      '',
      (theme.palette.primary || {}).main,
      theme.colors.PRIMARY_MAIN
    )};
    border: solid 3px
      ${getStyle(
        '',
        (theme.palette.primary || {}).border,
        theme.colors.PRIMARY_BORDER
      )};
    padding: 0px;
    font-size: 1.2rem;
    ${theme.breakpoints.down('xl')}: {
      top: 2.9% !important;
    }
    .MuiSvgIcon-root {
      width: 0.7em;
      height: 0.7em;
    }
  }
  .MuiBox-root {
    width: 100%;
  }

  #logoContainer {
    text-align: center;
    border-bottom: 1px solid ${theme.colors.PRIMARY_BORDER};
    img {
      @media (min-width: ${theme.breakpoints.values.xsm}px) and (max-width: ${
    theme.breakpoints.values.sm
  }px) {
        width: 53px;
        height: 53px;
      }
      @media (min-width: ${theme.breakpoints.values.xs}px) and (max-width: ${
    theme.breakpoints.values.xsm
  }px) {
        width: 45px;
        height: 45px;
      }
      @media (min-width: ${theme.breakpoints.values.md}px) and (max-width: ${
    theme.breakpoints.values.lg
  }px) {
        width: 45px;
        height: 45px;
      }

      @media (min-width: ${theme.breakpoints.values.sm}px) and (max-width: ${
    theme.breakpoints.values.md - 1
  }px) and (orientation: landscape) {
        width: 85px;
        height: 80px;
      }
      @media (min-width: ${theme.breakpoints.values.lg}px) and (max-width: ${
    theme.breakpoints.values.xl - 1
  }px) {
        width: 95px;
        height: 80px;
      }
    }
  }
 
  #poweredBy {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    span {
      color: #222;
      font-size: 16px;
    }
    img {
      padding-left: 11px;
    }
  }
  `}
`;
const Content = styled(Box)`
  flex: 0 0 92%;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  flex-direction: column;
`;
const FooterWrapper = styled(Box)`
  ${({ drawerwidth }) => `

  margin-left: ${drawerwidth};
  width: 100%;
  `}
`;

const Styled = { Wrapper, Main, LeftDrawer, Content, FooterWrapper };

export default Styled;
