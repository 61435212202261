import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import { getImagePath } from '@/utils/utils';
import {imageUrls } from '@/config/constants/keys';

const Wrapper = styled(Box)`
  ${({ theme }) => `
  .section-wrapper{
    background-color: #fff;
    padding: 2% 3%;
    margin-bottom: 30px;
    border-radius: 5px;
    .subheaderSection{
      padding-left: 0 !important;
      font-weight: 700;
    }
  }
  padding: 3%;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${theme.breakpoints.values.lg}px) {
    flex-direction: column;
  }
  .addworkorder {
    padding: 0;
  }
  form {
    width: 100%;
    .MuiTypography-body1 {
      font-size: 18px;
    }
  }
  ul {
    border: 2px solid ${theme.colors.LIGHT_BORDER};
    padding-left: 0;
    li {
      border-bottom: 0.5px solid ${theme.colors.PRIMARY_MAIN};
    }
  }
  .deleteParts {
    background: url(${getImagePath(imageUrls.deleteIcon)}) no-repeat;
    width: 20px;
    height: 20px;
    background-size: contain;
    align-self: center;
    cursor:pointer;

  }
  .partSpecific {
    .addPartform {
      display: flex;
      justify-content: space-between;
      .addPartSelect {
        flex: 0 0 50%;
      }
      .addPartfield {
        flex: 0 0 40%;
      }
    }
  }
  .noteSection {
    flex: 0 0 100%;
    flex-direction: column;
  }
  .woOld,
  .Forem {
    display: none;
  }
  .editInspection {
    border: 2px solid ${theme.colors.PRIMARY_BORDER};
    display: flex;
    margin-bottom: 20px;
    .detailSection {
      width: 70%;
      padding-left: 1%;
      padding-top: 5px;
      border-right: 2px solid ${theme.colors.PRIMARY_BORDER};
    }
    .editServiceRequest{
      width: 100%;
      flex-direction: column;
      .addedValues .labels{
        flex: 0 0 25% !important;
        min-width: fit-content;
      }
    }
    .inspectionView {
      width: 30%;
      align-self: center;
      .MuiGrid-container{
        justify-content: center;
        .MuiAvatar-root{
          width: 25px;
          height: 25px;
          margin-bottom: 10px;
        }
      }
      .iconContainer {
        justify-content: center;
        padding-bottom: 14px;
        .MuiAvatar-root {
          width: 30px;
          height: 30px;
        }
      }
    }
    .addedValues {
      display : flex;
      .labels {
        flex: 0 0 32%;
        min-width: 72px;
      }
      .values {
        flex: 0 0 60%;
        min-width: fit-content;
        color:${theme.colors.NEW_THEME? theme.colors.LIST_TEXT : '#dbdbdb'} !important;
        margin: 0;
        font-family: ${(theme.palette || {}).fontFamily};
      }
    }
  }

  .partsWrapper {
    position: relative;
    .addPartsPlus {
      position: absolute;
      top: 0px;
    right: 15px;
    font-size: 35px;
    color: white;
    cursor: pointer;
    }

  }
  .addPartSelect {
    .select__menu {
      height: 127px;
      overflow: auto;
      .select__menu-list {
        height: 210px;
        max-height: 100%;
      }
    }
  }
  .mechanic_disable {
    pointer-events:none;
  }
  `}
`;
const LeftWrapper = styled(Box)`
  ${({ theme }) => `
  flex: 0 0 48.5%;
  .userHeading {
    margin-top: 0;
  }
  .statusSection {
    display: flex;
    justify-content: space-between;
    ul, li {
      border: 0;
      list-style: none;
      .status-disabled{
        pointer-events: none;
      }
    }
    span{
      display: block;
      color: ${theme.colors.SECONDARY_MAIN};
      padding-left: 17px;
    }
    img {
      width: 100px;
      cursor: pointer;
    }
  }
  @media (max-width: ${theme.breakpoints.values.lg}px) {
    flex: 0 0 100%;
  }


  `}
`;
const RightWrapper = styled(Box)`
  ${({ theme }) => `
  flex: 0 0 48.5%;
  .documentsHeader {
    @media (min-width: ${theme.breakpoints.values.lg}px) {
      margin-top: 0;
    }
  }
  @media (max-width: ${theme.breakpoints.values.md}px) {
    flex: 0 0 100%;
  }
  .detailSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .fieldWrapper {
      flex-direction: column;
      flex: 0 0 48%;
    }
    .setWidth{
      flex: 0 0 24%;
    }
  }
  .editServiceRequest{
    width: 100%;
    flex-direction: column;
  }
  .Formechanic{
    display: none;
  }
  `}
`;

const submitUser = styled(Box)`
  .MuiBox-root {
    flex: 0 0 8%;
  }
  .MuiBox-root .MuiBox-root:first-child {
    margin-right: 2%;
  }
`;
const documentWrapper = styled.div`
  ul {
    border: none;
    li {
      .MuiGrid-container .MuiAvatar-rounded {
        width: 22px;
        height: 22px;
      }
      &:hover {
        background: none;
      }
    }
  }
`;
const PolicyProcedure = styled.div`
  ${({ theme }) => `
  ul {
    border: 2px solid ${theme.colors.PRIMARY_BORDER} !important;
  }
  li {
    border-bottom: 0.5px solid ${theme.colors.PRIMARY_BORDER} !important;
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flexbox;
    display: flex;
    padding: 8px 10px;
    &:hover {
      background-color: ${theme.colors.PRIMARY_BORDER};
    }
    p:first-child {
      color: ${theme.colors.LIST_TEXT};
      flex: 0 0 20%;
      text-align: left;
      font-size: 16px;
      text-transform: capitalize;
    }
  }
  `}
`;

const MainWrapper = styled.div`
 ${({ theme }) => `
 .workOrderIdWrapper {
 color:  ${theme.colors.SECONDARY_MAIN};
 text-align: end;
 font-size: 24px;
 }
 `}
`;
const TableWrapper = styled(Box)`
  ${({ theme, type }) => `

  .MuiPaper-rounded .MuiBox-root:first-child {
    overflow-x: inherit !important;
  }
  .noPointer{
    .MuiAvatar-img {
      cursor: unset !important;
    }
  }
  .MuiPaper-rounded .MuiBox-root:nth-child(2) {
    .MuiButtonBase-root{
    width:auto;
    }
  }
  position:relative;
    table {
      .MuiTableCell-head {
        padding-right 0 !important;
      }
      .MuiButtonBase-root{
          font-size:12px !important;
          justify-content: flex-end;
          width: auto;
      }
      .MuiTableBody-root .MuiTableCell-root:nth-child(8) .MuiGrid-container {
        justify-content: center;
      }
      .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root:nth-child(8) {
        text-align: center !important;
      }
      .MuiTableBody-root .MuiTableCell-root:nth-child(2) {
        text-transform: capitalize !important;
      }
      overflow-x: scroll;
    }
  `}
`;

const Styled = {
  Wrapper,
  RightWrapper,
  LeftWrapper,
  submitUser,
  documentWrapper,
  PolicyProcedure,
  MainWrapper,
  TableWrapper
};
export default Styled;
