import React, { lazy, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import ReactDatePicker from 'react-datepicker';
import { getDateLocale, getLastQuaters, hasEnabledFeature } from '@/utils/utils';
import { UI_LOGBOOK_DATE, UI_DATE_FORMAT } from '@/config/constants/static';
import moment from 'moment';
import { getPageUrls, getUrls } from '@/config/constants/keys';
import { useHistory } from 'react-router-dom';

const publicUrls = getPageUrls('publicConfig');
const privateUrls = getPageUrls('privateConfig');
const commonUrls = getPageUrls('commonConfig');

const layout = window?.$environment?.CURRENT_LAYOUT;
const Select = lazy(() => import(`@/components/UI/Select/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const RangePicker = lazy(() => import(`@/components/UI/DateRangePicker/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const RadioButton = lazy(() => import(`@/components/UI/Radio/${layout}`));
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));
const urls = getUrls([...publicUrls, ...privateUrls, ...commonUrls]);
export const pageUrls = {
  ...urls,
  notFound: '/notfound'
};

const RenderComponent = ({
  values,
  handleEdit,
  validateForm,
  customProps,
  submitAction
}) => {
  const { i18n } = useTranslation();
  const [suggestions, setSuggestions] = useState([]);
  const history = useHistory();
  useEffect(() => {
    values.vehicleType === 'active'
      ? setSuggestions(customProps.activeVehicles)
      : setSuggestions(customProps.deActivatedVehicles);
  }, [values.vehicleType, customProps]);

  useEffect(() => {
    values.id = { label: 'All', value: '' };
  }, [values.vehicleType]);
  const exportIfta = (data) => {
    customProps.handleExportIfta({
      id: 'exportIfta',
      data: { ...data }
    });
  };
  return (
    <>
      <div className="radiobuttonWrapper">
        <FormStyle.default.FieldsWrapper className="checkWrapper">
          <FormStyle.default.CheckBoxWrapper direction="row" width="100%">
            <RadioButton
              aria-labelledby="subscriptionTier"
              value={values.vehicleType}
              options={[
                {
                  label: i18n.t('iftaMileage.activeVehicles'),
                  value: 'active'
                },
                {
                  label: i18n.t('iftaMileage.deactivateVehicles'),
                  value: 'inactive'
                }
              ]}
              onClick={(event) => {
                handleEdit(event, {
                  field: 'vehicleType',
                  type: 'radio'
                });
              }}
            />
          </FormStyle.default.CheckBoxWrapper>
        </FormStyle.default.FieldsWrapper>
        <FormStyle.default.FieldsWrapper className="vertical">
          <FormStyle.default.TextWrapper>
            <Field
              name="vehicle"
              onChange={(event) => {
                handleEdit(event, { field: 'id' });
              }}
              id="vehicle"
              as={Select}
              value={values.id}
              suggestions={suggestions}
            />
          </FormStyle.default.TextWrapper>
        </FormStyle.default.FieldsWrapper>
      </div>
      <FormStyle.default.FieldsWrapper className="checkWrapper emOnly">
        <FormStyle.default.CheckBoxWrapper direction="row" width="100%">
          <RadioButton
            aria-labelledby="subscriptionTier"
            value={values.vehicleType}
            options={[
              {
                label: i18n.t('iftaMileage.activeVehicles'),
                value: 'active'
              },
              {
                label: i18n.t('iftaMileage.deactivateVehicles'),
                value: 'inactive'
              }
            ]}
            onClick={(event) => {
              handleEdit(event, {
                field: 'vehicleType',
                type: 'radio'
              });
            }}
          />
        </FormStyle.default.CheckBoxWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.FieldsWrapper className="vertical emOnly">
        <FormStyle.default.TextWrapper>
          <Field
            name="vehicle"
            onChange={(event) => {
              handleEdit(event, { field: 'id' });
            }}
            id="vehicle"
            as={Select}
            value={values.id}
            suggestions={suggestions}
          />
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      {!customProps?.isOldView && (
        <FormStyle.default.FieldsWrapper className="odometerWrapper">
          <FormStyle.default.CheckBoxWrapper direction="row">
            <RadioButton
              aria-labelledby="odometer"
              value={values.fromOdometer}
              options={[
                { label: i18n.t('iftaMileage.odometer'), value: 'true' }
              ]}
            />
          </FormStyle.default.CheckBoxWrapper>
        </FormStyle.default.FieldsWrapper>
      )}

      <div className="dateFieldwrapper">
        <FormStyle.default.FieldsWrapper className="vertical">
          <Label variant="body1" htmlFor="dateRange">
            {`${i18n.t('common.dateFrom')} - ${i18n.t('common.dateTo')}`}
          </Label>
          <FormStyle.default.TextWrapper>
            <Field
              id="dateRange"
              name="dateRange"
              onChange={(date, dateStrings) => {
                handleEdit(dateStrings, { field: 'dateRange' });
                handleEdit(dateStrings[0], { field: 'dateTimeFrom', type: 'date' });
                handleEdit(dateStrings[1], { field: 'dateTimeTo', type: 'date' });

              }}
              onBlur={() => {}}  // For ignoring formik error
              predefinedRanges={getLastQuaters()}
              as={RangePicker}
              format={UI_DATE_FORMAT}
              inputReadOnly
              allowClear={false}
              locale={getDateLocale()}
              placeholderText={UI_LOGBOOK_DATE}
              value={values.dateRange.map(ele => moment(ele))}
              showPopperArrow={false}
              showTimeSelect={false}
              maxDate={new Date()}
              showDisabledMonthNavigation
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              isClearable
            />
          </FormStyle.default.TextWrapper>
        </FormStyle.default.FieldsWrapper>
        <FormStyle.default.ButtonsWrapper className="vertical-button">
          <Button
            type="submit"
            label={i18n.t('common.show')}
            onClick={(e, d) => {
              const validateResponse = validateForm();
              validateResponse.then((data) => {
                submitAction(data);
              });
            }}
          />
        </FormStyle.default.ButtonsWrapper>
        <FormStyle.default.ButtonsWrapper className="vertical-button reportBtn">
          <Button
            label={i18n.t('iftaMileage.generateReport')}
            onClick={(e, d) => {
              const validateResponse = validateForm();
              validateResponse.then((data) => {
                customProps.fetchReport(values)
              });
            }}
          />
        </FormStyle.default.ButtonsWrapper>
        {hasEnabledFeature('taxableMilesViewEnabled') && (
          <FormStyle.default.ButtonsWrapper className="vertical-button reportBtn">
            <Button
              label={i18n.t('iftaMileage.updateMiles')}
              onClick={(e, d) => {
                history.push({
                  pathname: pageUrls.iftaList,
                  state: {
                  ...values
                  }
                })
              }}
            />
          </FormStyle.default.ButtonsWrapper>
        )}
      </div>
      
      <FormStyle.default.FieldsWrapper className="vertical emOnly">
          <Label variant="body1" htmlFor="dateRange">
            {`${i18n.t('common.dateFrom')} - ${i18n.t('common.dateTo')}`}
          </Label>
          <FormStyle.default.TextWrapper>
            <Field
              id="dateRange"
              name="dateRange"
              onChange={(date, dateStrings) => {
                handleEdit(dateStrings, { field: 'dateRange' });
                handleEdit(dateStrings[0], { field: 'dateTimeFrom', type: 'date' });
                handleEdit(dateStrings[1], { field: 'dateTimeTo', type: 'date' });

              }}
              predefinedRanges={getLastQuaters()}
              as={RangePicker}
              format={UI_DATE_FORMAT}
              inputReadOnly
              allowClear={false}
              locale={getDateLocale()}
              placeholderText={UI_LOGBOOK_DATE}
              value={values.dateRange.map(ele => moment(ele))}
              showPopperArrow={false}
              showTimeSelect={false}
              maxDate={new Date()}
              showDisabledMonthNavigation
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              isClearable
            />
          </FormStyle.default.TextWrapper>
        </FormStyle.default.FieldsWrapper>
      {customProps?.isOldView && (
        <FormStyle.default.FieldsWrapper className="odometerWrapper">
          <FormStyle.default.CheckBoxWrapper direction="row">
            <RadioButton
              aria-labelledby="odometer"
              value={values.fromOdometer}
              options={[
                { label: i18n.t('iftaMileage.odometer'), value: 'true' }
              ]}
            />
          </FormStyle.default.CheckBoxWrapper>
        </FormStyle.default.FieldsWrapper>
      )}
      <FormStyle.default.ButtonsWrapper className="vertical-button emOnly">
        <Button
          type="submit"
          label={i18n.t('common.show')}
          onClick={(e, d) => {
            const validateResponse = validateForm();
            validateResponse.then((data) => {
              submitAction(data);
            });
          }}
        />
      </FormStyle.default.ButtonsWrapper>
      <FormStyle.default.ButtonsWrapper className="vertical-button reportBtn emOnly">
          <Button
            label={i18n.t('iftaMileage.generateReport')}
            onClick={(e, d) => {
              const validateResponse = validateForm();
              validateResponse.then((data) => {
                customProps.fetchReport(values)
              });
            }}
          />
        </FormStyle.default.ButtonsWrapper>
    </>
  );
};

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  hasApiStatus: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};

export default RenderComponent;
