import React, { lazy, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RenderComponent from '@/components/modules/DynamicRenderPage';

import ParentStyled from './style';
import { loadStyle, loadPageConfig } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'pages/web/DriverStatusViewMore');

const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.driverStatusViewMore[0].name}`));
const Content = lazy(() => import(`@/${config.driverStatusViewMore[1].name}`));
const TopActions =
  config.driverStatusViewMore[0].children &&
  lazy(() => import(`${config.driverStatusViewMore[0].children.name}`));

const DriverStatusViewMore = (parentProps) => {
  const { getDriverStatus, history, i18n, theme, getLatestLog, latestLogUrlFlg, totalElements } = parentProps;

  const changeRequest = (fields) => {
    getDriverStatus({
      apiId: 'driverStatusSearchApi',
      data: {
        ...request,
        ...fields,
        isDashboard: false
      }
    });
    setPagination(true);
  };
  const commonParentProps = { history, i18n, Styled, theme };
  const [pagination, setPagination] = useState(false);
  const [numberPerPage, setNumberPerPage] = useState('10');
  const [request, setRequest] = useState({
    displayLength: numberPerPage,
    displayStart: '0',
    sortCol: '',
    search: '',
    sortDir: ''
  });
  useEffect(() => {
    getDriverStatus({
      apiId: 'getDriverStatus',
      data: { ...request, isDashboard: false }
    });
    if (window.$environment.SERVICE_PROVIDER === 'spireon' || window.$environment.SERVICE_PROVIDER === 'cyntrx') {
      let interval = setInterval(() => {
        getDriverStatus({
          apiId: 'getDriverStatusUpdate',
          data: { ...request, isDashboard: false }
        });
      }, 60000);
      
      document.addEventListener(
        'visibilitychange',
        () => {
          if (document.visibilityState === 'hidden') {
            clearInterval(interval);
          } else {
            interval = setInterval(() => {
              getDriverStatus({
                apiId: 'getDriverStatusUpdate',
                data: { ...request, isDashboard: false }
              });
            }, 60000);
          }
        },
        false
      );
      return () => clearInterval(interval);
    }
  }, []);
  const searchConfig = {
    setRequest,
    setPagination,
    searchPlaceholder: 'Search Driver Status',
    fromData: false,
    changeRequest
  };
  return (
    <>
      <RenderComponent
        {...config.driverStatusViewMore[0]}
        parentProps={{
          ...parentProps,
          changeRequest,
          request,
          pagination,
          setPagination,
          totalElements
        }}
        commonParentProps={commonParentProps}
        component={Title}
        getLatestLog={getLatestLog}
        latestLogUrlFlg={latestLogUrlFlg}
        childComponent={TopActions}
        searchConfig={
          window.$environment.CURRENT_LAYOUT === 'SpireonNew'
            ? searchConfig
            : null
        }
      />
      <RenderComponent
        {...config.driverStatusViewMore[1]}
        parentProps={{
          ...parentProps,
          changeRequest,
          request,
          pagination,
          setPagination
        }}
        getLatestLog={getLatestLog}
        latestLogUrlFlg={latestLogUrlFlg}
        commonParentProps={commonParentProps}
        component={Content}
        setNumberPerPage={setNumberPerPage}
        numberPerPage={numberPerPage}
      />
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    list: state.dashboard,
    driverStatusList: state.dashboard.driverStatusList,
    driverStatusFilteredList: state.dashboard.driverStatusFilteredList,
    totalElements: state.dashboard.totalElements,
    latestLogUrlFlg: state.dashboard.latestLogUrlFlg
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchDriverStatus: (data) => dispatch.driverStatusList.refreshData(data),
    getLatestLog: (data) => dispatch.dashboard.getLatestLog(data),
    getDriverStatus: (data) => dispatch.dashboard.getDriverStatus(data),
    filterSuccess: (data) => dispatch.dashboard.filterSuccess(data),
    refreshDriverStatusMap: (data) =>
      dispatch.dashboard.refreshDriverStatusMap(data)
  };
};

export default connect(mapState, mapDispatch)(DriverStatusViewMore);
DriverStatusViewMore.defaultTypes = {};
DriverStatusViewMore.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  fetchDriverStatus: PropTypes.func.isRequired
};
