import styled from 'styled-components';
import Parent from '../Base/style';
import { getImagePath, getStyle } from '../../../../utils/utils';
const TabWrapper = styled(Parent)`
  ${({ theme }) => `
.MuiTabs-flexContainer {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.03);
  // background-color: #20263a;
  border-top: 1px solid ${theme.colors.SECONDARY_BORDER};
  flex-wrap: wrap;
  @media (max-width: ${theme.breakpoints.values.md - 1}px) {
    flex-direction: row;
    text-align: -webkit-center;
  }
  .Mui-selected {
    background-color: ${theme.colors.BACKGROUND_MENU};
    border-bottom: 4px solid  ${theme.colors.LINK_COLOR} !important;
    font-weight: 700;
  }
  
}
.MuiTab-root {
  max-width: 100%;
  min-width: auto;
  border-bottom: 4px solid  transparent;
  &:hover {
    border-bottom: 4px solid  ${theme.colors.LINK_COLOR} !important;
    .MuiTab-wrapper {
      color: ${theme.colors.TAB_HIGHLIGHT};
    }
  }
}
.MuiButtonBase-root.MuiTab-root {
  border-right: 1px solid #2C3249;
}
.MuiBox-root-35{
  // min-height: 440px;
}

.MuiTab-wrapper {
  color: ${theme.colors.ACTION_BORDER};
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize !important;
  @media only screen and (min-width: ${
    theme.breakpoints.values.mlg
  }px) and (max-width: ${theme.breakpoints.values.xl}px) {
    font-size: 13px;
  }
}
.Mui-selected  .MuiTab-wrapper {
  color: ${theme.colors.NEW_THEME? '#fff' : theme.colors.TAB_HIGHLIGHT};
  font-weight: ${window?.$environment?.clutchNewUI? 'bold': '400'};
}
.MuiTabs-indicator {
  display: none;
}
.PrivateTabIndicator-root-331 {
  height: 3px;
}
.MuiTab-textColorInherit {
  opacity: 1;
}
.MuiAppBar-colorPrimary {
  background: ${getStyle(
    '',
    (theme.palette.primary || {}).main,
    theme.colors.PRIMARY_MAIN
  )};
}
`}
`;
export default TabWrapper;
