import styled from 'styled-components';

const Wrapper = styled.div`
.capitalize {
  text-transform: uppercase;
}
#termInfoSubmit {
  display:none;
}
.tcWrapper {
  color: #fff;
}
.subHeading {
  font-size: 20px;
  font-weight: 600;
  opacity: 0.78;
}
.contentSection {
  opacity: 0.78;
  color: #ffffff;
  font-size: 13px;
  font-weight: 400;
  padding-bottom: 8px;
}
.noPadding {
  padding: 0;
  margin: 0;
}
.noPaddingtop {
  padding-top: 0
  margin-top: 0;
}
.noPaddingbottom {
  padding-bottom: 0;
}
.conclusion {
  padding-top: 14px;
}
.agreeWrapper {
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex; 
  padding-top: 20px;
  .signeeForm {
    flex-direction: row;
    flex: 0 0 38%;
    label {
      flex: 0 0 28%;
      padding-top: 20px;
    }
    .formSection {
      flex: 0 0 76%;
    }
  }
  .normalCheckbox {
    padding-bottom: 24px;
  }
  .normalCheckbox svg rect {
    stroke: #fff;
    opacity: 0.75;
  }
}
.tcSection {
	height: 500px;
	overflow-y: scroll;
}
.scrollbar {
  min-height: 550px;
  color: #fff;
  padding-right: 4%;
}
#scroll::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: rgba(216, 216, 216, 0.08);
  opacity: 0.08;
}

#scroll::-webkit-scrollbar {
  display: block;
	width: 10px;
	background-color: rgba(216, 216, 216, 0.08);
}

#scroll::-webkit-scrollbar-thumb {
  height: 54px;
  background-color: ${window.$environment.WHITE_THEME ? '#5e5f67'  : '#d8d8d8'};
}
.agreeTerms {
  padding-right: 0;
}
`;
const TextWrapper = styled.div`
`;

const FieldsWrapper = styled.div`
flex: 0 0 30%;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  flex-direction: column;
  padding-right: 3%;
`;

const Styled = {
  Wrapper,
  TextWrapper,
  FieldsWrapper
};

export default Styled;
