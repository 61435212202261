import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const ParentWrapper = styled(Box)``
const Wrapper = styled(Box)`
padding: 3%;
`;
const ChartWrapper = styled(Box)`
  ${({ theme }) => `
width: 100%;
  .svg-container {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    position: relative;
    width: 100%;
    margin-top: 4%;
    padding-bottom: 26%;
    vertical-align: top;
  }
  .svg-content {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    position: absolute;
    top: 0;
    left: 0;
  }
  .updateEventsection {
    flex-wrap: wrap;
    @media (max-width: ${theme.breakpoints.values.lg}px) {
      justify-content: center;
      padding-top: 12px;
      .MuiBox-root {
        margin-bottom: 5px;
      }
    }
    .button-status{
      width:95%;
    }
    .MuiButton-outlinedSizeSmall {
      padding: 3px 14px !important;
    }
    #update_graph + div {
      #button {
        border: none;
      }
    }
  }
  .button--disabled: hover {
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.primary.contrastText};
    cursor: inherit;
  }
  .button--status-normal: hover,
  .button--update-normal: hover,
  .button--update-normal,
  .button-add {
    color: ${theme.palette.secondary.contrastText + '!important'};
    background-color: ${theme.palette.secondary.main + '!important'};
    cursor: pointer;
  }
  .button-add {
    font-size: 23px !important;
    text-transform: capitalize;
  }

  .active {
    background-color: ${theme.palette.secondary.main}!important ;
    color: ${theme.palette.secondary.contrastText}!important ;
  }

  .button-status {
    margin-right: 4px;
    padding-top: 0px;
    padding-bottom: 0px;
    color: ${theme.palette.primary.contrastText};
    background-color: ${theme.palette.primary.main};
    font-size: 17px;
    border: ${`1px solid ${theme.palette.primary.border}`};
    @media (max-width: ${theme.breakpoints.values.md}px) {
      font-size: 15px;
    }
    @media (max-width: ${theme.breakpoints.values.sm}px) {
      padding: 0;
    }
  }

  .button-update {
    font-size: 23px !important;
    margin-left: 0.5rem;
    color: ${theme.palette.primary.contrastText};
    background-color: ${theme.palette.primary.main};
    border: ${`1px solid ${theme.palette.primary.contrastText}`};
    text-transform: capitalize;
    padding: 0px 26px;
    width: auto !important;
    @media (max-width: ${theme.breakpoints.values.md}px) {
      font-size: 15px;
      padding: 0 15px;
    }

  }
  #addNewRow {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    justify-content: flex-end;
  }
  .cancelSubmitwrapper {
    width:100%;
  }
  `}
`;
const LabelWrap = styled(Box)`
  p {
    font-size: 21px;
    margin-bottom: 20px;
  }
`;
const StatusModifier = styled(Box)`
  .status {
    min-width: 98px;
  }
  .stateModifier {
    min-width: 120px;
  }
  .driverId,
  .stateModifier {
    .select__placeholder {
      font-size: 16px;
    }
    .select__dropdown-indicator {
      padding-left: 0;
      padding: 3px;
    }
  }
  .react-datepicker--time-only {
    border: 2px solid colors.PRIMARY_BORDER;
    color: colors.PRIMARY_CONTRAST;
    font-family: Yanone Kaffeesatz, tahoma;
    font-size: 14px;
    font-weight: normal;
    .react-datepicker__time-list {
      padding-top: 6px;
    }
    .react-datepicker__time-list::-webkit-scrollbar {
      width: 0;
    }
    .react-datepicker__time-list-item {
      border-top: none !important;
    }
    .react-datepicker__header--time {
      display: none;
    }
  }
`;
const CancelSaveWrapper = styled(Box)`
  ${({ theme }) => `

  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  justify-content: flex-end;
  @media (max-width: ${theme.breakpoints.values.sm}px) {
    justify-content: center;
  }
  width:100%;
  &:first-child {
    margin-right:3px;
  }
  .MuiBox-root:first-child {
    margin-right: 3px;
  }
  .MuiBox-root {
    width: auto;
    min-width: 117px;
    margin-right:0;
  }
  #button {
    padding: 4px 30px;
  }
  `}
`;
const Editnodata = styled(Box)`
  ${({ theme }) => `
  .messageSection{
    color: ${theme.colors.LINK_COLOR};
    padding-bottom: 11px;
    padding-top: 10px;
    font-size: 20px;
  }
  
  `}
`;
const Styled = { Wrapper, ChartWrapper, LabelWrap, StatusModifier, CancelSaveWrapper, Editnodata, ParentWrapper };
export default Styled;
