import React, { useEffect, lazy } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RenderComponent from '@/components/modules/DynamicRenderPage';

import { useHistory } from 'react-router-dom';
import useTheme from '@material-ui/core/styles/useTheme';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ParentStyled from './style';
import { loadStyle, loadPageConfig, getLogsInfoDisplay } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'pages/web/LogHistory');

const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.logsHistory[0].name}`));
const Content = lazy(() => import(`@/${config.logsHistory[1].name}`));
const layout = window?.$environment?.CURRENT_LAYOUT;

const Label = lazy(() => import(`@/components/UI/Label/${layout}`));

const History = (parentProps) => {
  const { handleLogHistory, callback, logsHistory } = parentProps;
  const history = useHistory();
  const theme = useTheme();
  const i18n = useTranslation();
  const match = useParams();
  const apiId = 'logHistoryApi';
  const commonParentProps = { history, i18n, Styled, theme };
  useEffect(() => {
    const id = match.id;
    handleLogHistory({
      data: { id },
      apiId,
      callback,
      history
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <RenderComponent
        {...config.logsHistory[0]}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        component={Title}
        withBackground={window.$environment.CURRENT_LAYOUT === 'SpireonNew'}
        childComponent={() => (
          <Label className="logsInfoDisplay">
            {getLogsInfoDisplay(
              i18n,
              logsHistory?.driverName,
              logsHistory?.day
            )}
          </Label>
        )}
      />
      {logsHistory?.driverName && (
        <>
          <RenderComponent
            {...config.logsHistory[1]}
            parentProps={parentProps}
            commonParentProps={commonParentProps}
            component={Content}
          />
        </>
      )}
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    logsHistory: state.logsHistory
  };
};
const mapDispatch = (dispatch) => {
  return {
    handleLogHistory: (data) => dispatch.logsHistory.fetchData(data),
    viewReport: (data) => dispatch.viewReport.viewReportDocument(data)
  };
};

export default connect(mapState, mapDispatch)(History);
History.defaultTypes = {};
History.propTypes = {
  history: PropTypes.object.isRequired,
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  handleLogHistory: PropTypes.func.isRequired
};
