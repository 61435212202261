import React, { useEffect, lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RenderComponent from '@/components/modules/DynamicRenderPage';

import ParentStyled from './style';
import { loadStyle, loadPageConfig, hasValue } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'pages/web/CSAViolation');

const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.csaViolation[0].name}`));
const Content = lazy(() => import(`@/${config.csaViolation[1].name}`));
const TopActions =
  config.csaViolation[0].children &&
  lazy(() => import(`${config.csaViolation[0].children.name}`));

const CSAViolation = (parentProps) => {
  const { fetchViolations, history, i18n, theme, apiStatus } = parentProps;
  const apiId = 'fetchViolations';
  const hasFetchApiStatus = hasValue(apiStatus.status, 'api', apiId);
  const [pagination, setPagination] = useState(false);

  const basicRequest = {
    displayLength: '15',
    displayStart: '1'
  };
  const [request, setRequest] = useState({
    ...basicRequest
  });

  const changeRequest = (fields) => {
    setRequest({ ...basicRequest, ...fields });
  };

  useEffect(() => {
    fetchViolations({
      apiId: 'fetchViolations',
      data: { request }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request]);

  const commonParentProps = { history, i18n, Styled, theme };

  return (
    <>
      <RenderComponent
        {...config.csaViolation[0]}
        parentProps={{
          ...parentProps,
          hasApiStatus: hasFetchApiStatus,
          changeRequest,
          request,
          pagination,
          setPagination
        }}
        commonParentProps={commonParentProps}
        component={Title}
        childComponent={TopActions}
      />
      <RenderComponent
        {...config.csaViolation[1]}
        parentProps={{
          ...parentProps,
          changeRequest,
          request,
          hasApiStatus: hasFetchApiStatus,
          pagination,
          setPagination
        }}
        commonParentProps={commonParentProps}
        component={Content}
      />
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    csaViolations: state.csaViolations
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchViolations: (data) => dispatch.csaViolations.fetchData(data),
    handleExportCsaViolations: (data) => dispatch.csaViolations.exportData(data)
  };
};

export default connect(mapState, mapDispatch)(CSAViolation);
CSAViolation.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  fetchViolations: PropTypes.func.isRequired
};
