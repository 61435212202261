import React, { useEffect, lazy, useState } from 'react';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import RenderComponent from '@/components/modules/DynamicRenderPage';

import ParentStyled from './style';
import { loadStyle, loadPageConfig, getUserMail } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'pages/web/DownloadCenter');

const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.downloadCenter[0].name}`));
const Content = lazy(() => import(`@/${config.downloadCenter[1].name}`));

const DownloadCenter = (parentProps) => {
  const {  getFilters, getDocumentToken, logBook, fetchReportTypes, fetchDownloadList } = parentProps;
  const [pagination, setPagination] = useState(false);
  const basicRequest = {
    displayLength: '15',
    displayStart: '1'
  };
  const [request, setRequest] = useState({
    ...basicRequest
  });
  const history = useHistory();
  const theme = useTheme();
  const i18n = useTranslation();
  const commonParentProps = { history, i18n, Styled, theme };

  const fetchPaginateApi = 'fetchDownloadsPaginateApi'
  const apiId = 'getFilters';
  const getDocumentTokenApiId = 'getDocToken';

  const sortColumnMapping = {

  }
  const changeRequest = (fields) => {
    if (fields?.sortCol) {
      const type = fields.sortCol
      fields.sortCol = sortColumnMapping[type] ? sortColumnMapping[type] : fields.sortCol
    }
    setRequest({ ...basicRequest, ...fields });
  };

  useEffect(() => {
    fetchDownloadList({
        apiId: fetchPaginateApi,
        data: { request: {...request, fleetManagerEmail: getUserMail()} },
      });
      

  }, [request]);

  useEffect(() => {
    getFilters({ apiId });
    if (!logBook?.documentToken?.length) {
      getDocumentToken({ apiId: getDocumentTokenApiId });
    }
    fetchReportTypes({
      apiId: 'fetchReportTypes',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <RenderComponent
        {...config.downloadCenter[0]}
        parentProps={{
          ...parentProps,
        }}
        commonParentProps={commonParentProps}
        component={Title}
      />
      <RenderComponent
        {...config.downloadCenter[1]}
        parentProps={{
          ...parentProps,
          changeRequest,
          request,
          pagination,
          setPagination
        }}
        commonParentProps={commonParentProps}
        component={Content}
        pagination={pagination}
        setPagination={setPagination}
      />
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    paginateList: state.downloadCenter.list,
    filters: state.filters,
    logBook: state.logBook,
    reportTypes: state.downloadCenter.reportTypes,
  };
};

const mapDispatch = (dispatch) => {
  return {
    fetchDownloadList: (data) => dispatch.downloadCenter.fetchData(data),
    getFilters: (data) => dispatch.filters.fetchData(data),
    viewReport: (data) => dispatch.viewReport.viewReportDocument(data),
    getDocumentToken: (data) => dispatch.logBook.documentToken(data),
    fetchReportTypes: (data) => dispatch.downloadCenter.fetchReportTypes(data),
  };
};

export default connect(mapState, mapDispatch)(DownloadCenter);

