import styled from 'styled-components';
import Parent from '../Base/style';
const ButtonContainer = styled(Parent)`
${({ theme }) => `
.MuiButton-root {
    font-size: ${window?.$environment?.clutchNewUI? `14px`:  `12px`} !important;
    background: ${theme.colors.LINK_COLOR};
    border-radius: 4px !important;
    text-transform: uppercase;
    : hover {
        background: ${theme.colors.LINK_COLOR};
    }
}
.MuiButtonBase-root {
    height: 40px;
    margin-top: 15px;
}
.reset, .reset: hover {
  background: ${theme.colors.RESET_BACKGROUND} !important;
  color: ${theme.colors.RESET_COLOR} !important;
}
`}
`;

export default ButtonContainer;
