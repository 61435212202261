import React, { useState, lazy } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import moment from 'moment'

import RenderComponent from './RenderComponent';

import {
  objectArrayFromDiffFields,
  objectArrayFromMultipleFields,
  hasRestrictedRole
} from '@/utils/utils';
import { COOKIE_AUTH_DETAILS, ROLES } from '../../../config/constants/keys';
import { getCookie } from '@/services/cookie';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));

const ViewForm = ({
  changeRequest,
  hasApiStatus,
  callback,
  history,
  list,
  setPagination
}) => {
  const { i18n } = useTranslation();
  const [values, setValues] = useState({
    driverId: '',
    vehicleId: null,
    startDate: null,
    endDate: null,
    companyName: null,
    mechanicId: ''
  });
  const token = getCookie(COOKIE_AUTH_DETAILS);
  const [validationError, setValidationError] = useState('');
  const [dateValidationError, setDateValidationError] = useState('');
  const userList = objectArrayFromMultipleFields(list?.drivers, 'id', [
    'firstName',
    'lastName'
  ]);
  let vehicleList = objectArrayFromDiffFields(
    list?.vehicles,
    'vehicleId',
    'vin'
  );
  vehicleList = vehicleList.map(o=> ({...o,label: `...${o?.label.substr(o?.label?.length - 8)}`}))
  const mechanicList = objectArrayFromMultipleFields(
    list?.mechanics,'id', [
      'firstName',
      'lastName'
    ]
  );
  return (
    <>
      <Form
        type="server"
        direction="row"
        initialValues={{ ...values }}
        className="CSAForms"
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {
          setDateValidationError('');
          if (
            fields.driverId?.value ||
            fields.vehicleId?.value ||
            fields.startDate ||
            fields.endDate ||
            fields.companyName ||
            fields.mechanicId?.value
          ) {
            if (
              fields.startDate &&
              moment(fields.endDate).diff(fields.startDate, 'days') < 0
            ) {
              setDateValidationError('Date To should not be less than Date From');
            } else {
            setValidationError('');
            const finalData = {
              driverId: hasRestrictedRole(['DRIVER'])? token?.userId: fields.driverId?.value || null,
              vehicleId: fields.vehicleId?.value || null,
              startDate: fields.startDate? fields.startDate.replaceAll('/', '-'): null,
              endDate: fields.endDate? fields.endDate.replaceAll('/', '-'): null,
              companyName: fields.companyName || null,
              mechanicId: hasRestrictedRole(['MECHANIC'])? token?.userId: fields.mechanicId?.value || null,

            };
            Object.keys(finalData).forEach(
              (key) => finalData[key] === null && delete finalData[key]
            );
            setPagination(true);
            changeRequest({ ...finalData });
            }
          } else {
            setValidationError('Please select atleast one filter');
          }
        }}
        handleOnCancel={(fields) => {
          setPagination(true);
          changeRequest();
          setValidationError('');
        }}
        customProps={{
          history,
          callback,
          hasApiStatus,
          vehicleList,
          userList,
          validationError,
          mechanicList,
          dateValidationError,
          setDateValidationError
        }}
      />
    </>
  );
};

ViewForm.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default ViewForm;
