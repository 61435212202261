import { periodList } from '@/config/constants/keys';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import React, { lazy, useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useHistory } from 'react-router-dom';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const SubHeader = lazy(() => import(`@/components/SubHeader/${layout}`));
const Select = lazy(() => import(`@/components/UI/Select/${layout}`));

const QuickStatsItem = ({ title, value, text, description, period, event, companyId }) => {
  const history = useHistory();
  return (
    // <Tooltip mouseEnterDelay={0.5} placement='top' overlayStyle={{ maxWidth: "300px", opacity: 1}} overlay={<span>{description || 'NA'}</span>}>
    <div onClick={() => {
      history.push(`/scorecard/quickStats?period=${period}&event=${event || ''}&companyId=${companyId}`)
    }} className="quick-stats-item">
      <CircularProgressbar
        value={value}
        text={text}
        strokeWidth={5}
        styles={buildStyles({
          textColor: '#fff',
          pathColor: '#007ac7',
          trailColor: '#161a29'
        })}
      />
      <span>{title}</span>
    </div>
    // </Tooltip>
  )
}

const QuickStats = ({ i18n, quickStats, quickStatsdescription, fetchQuickStatsDetails, companyId }) => {
  const history = useHistory();

  const statsItems = [
    {
      key: 'accelerationEventScore',
      title: i18n.t('Acceleration'),
      unit: 'percentage',
      description: quickStatsdescription.fleetManagementScore,
      eventName: 'ACCELERATION'
    },
    {
      key: 'accidentEventScore',
      title: i18n.t('Accidents'),
      unit: 'percentage',
      description: quickStatsdescription.vehicleInspectionScore,
      eventName: 'ACCIDENT'
    },
    {
      key: 'brakingEventScore',
      title: i18n.t('Braking'),
      unit: 'percentage',
      description: quickStatsdescription.consistentEquipment,
      eventName: 'BRAKING'
    },
    {
      key: 'radiusEventScore',
      title: i18n.t('Radius'),
      unit: 'percentage',
      description: quickStatsdescription.vehicleComposition,
      eventName: 'RADIUS'
    },
    // removing According to FLEETMG-16582
    // {
    //   key: 'speedingEventScore',
    //   title: i18n.t('Speeding'),
    //   unit: 'percentage',
    //   description: quickStatsdescription.driverTurnOver,
    //   eventName: 'SPEEDING'
    // },
    {
      key: 'turningEventScore',
      title: i18n.t('Turning'),
      unit: 'percentage',
      description: quickStatsdescription.driverTenure,
      eventName: 'TURNING'
    },
  ]
  const [period, setPeriod] = useState({ label: 'Monthly', value: 'MONTHLY' })

  useEffect(() => {
    fetchQuickStatsDetails({ data: { id: companyId, period: period.value }, apiId: 'fetchQuickStatsDetails' })
  }, [period])

  return (
    <div className="grid-item">
      <div className='quickStatsHeadingWrapper'>
        <div className='reportIconWrapper'>
          <SubHeader variant="h1" className="statsHeading" text={i18n.t('scoreCard.quickStats')} />
          <Tooltip placement='top' overlayStyle={{ maxWidth: "300px", opacity: 1 }} overlay={`View Reports`}>
            <Button
              type="submit"
              label={i18n.t('Reporting')}
              onClick={(e, d) => {
                history.push(`/scorecard/report?companyId=${companyId}&period=${period.value || ''}`)
              }}
            />
            {/* <img onClick={() => {
              history.push(`/scorecard/report?companyId=${companyId}&period=${period.value || ''}`)
            }} src={getImagePath(imageUrls.reportsIconSelected)} alt={'reports'}></img> */}
          </Tooltip>
        </div>
        <Select
          className="choose-period"
          value={period}
          onChange={(event) => {
            setPeriod(event)
          }}
          suggestions={periodList}
        />
      </div>

      <div className="quick-stats-wrapper">
        {
          quickStats && statsItems.map((item) => <QuickStatsItem companyId={companyId} event={item.eventName} period={period.value || ''} description={item.description} title={item.title} value={quickStats[item.key] ? quickStats[item.key] : 0} text={quickStats[item.key] ? item.unit === 'percentage' ? `${quickStats[item.key]}%` : quickStats[item.key] : '0%'} />)
        }
      </div>
    </div>
  );
};

export default QuickStats;

QuickStats.defaultProps = {};

QuickStats.propTypes = {
  quickStats: PropTypes.object.isRequired
};
