import React, { useState, lazy } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import Filters from './Filters';

import { getImagePath, isInsuracePortalUser, objectArrayFromDiffFields } from '@/utils/utils';
import { imageUrls } from '@/config/constants/keys';
const layout = window?.$environment?.CURRENT_LAYOUT;
const Audio = lazy(() => import(`@/components/Audio/${layout}`));
const TableRowPopup = lazy(() =>
  import(`@/components/TableRowPopup/${layout}`)
);

const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const Label = lazy(() => import(`@/components/UI/Label/${layout}`));
const Link = lazy(() => import(`@/components/UI/Link`));
const Breadcrumb = lazy(() => import(`@/components/Breadcrumb`));

const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Breadcrumb filters={filters} />
      <Component {...contentProps} />
    </Box>
  );
};
export default Index;
const Component = ({
  list,
  paginateList,
  hasFetchApiStatus,
  Styled,
  i18n,
  viewReport,
  logBook,
  fleetCompanies,
  changeRequest,
  request,
  pagination,
  setPagination
}) => {
  const [values, setInitialValues] = useState({
    companyId: null
  });
  const insuracePortalUserRole = isInsuracePortalUser();
  const [isDocClicked, setisDocClicked] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedDocArray, setSelectedDocArray] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const beBaseUrl = window?.$environment?.BE_BASE_URL;
  const fleetCompanyList = objectArrayFromDiffFields(
    fleetCompanies,
    'id',
    'name',
  );
  const columns = [
    ...(insuracePortalUserRole ? [{
      id: 'company_name',
      numeric: false,
      disablePadding: false,
      label: i18n.t('policyHolder'),
      needSorting: true
    }] : []),
    {
      id: 'full_name',
      numeric: false,
      disablePadding: false,
      label: i18n.t('common.driver'),
      needSorting: true
    },
    {
      id: 'vehicle',
      numeric: false,
      disablePadding: false,
      label: i18n.t('common.vehicle'),
      needSorting: true
    },
    {
      id: 'phone',
      numeric: false,
      disablePadding: false,
      label: i18n.t('common.phone'),
      needSorting: true
    },
    {
      id: 'photos',
      numeric: true,
      disablePadding: false,
      label: i18n.t('common.photos'),
      needSorting: true
    },
    {
      id: 'witness_statement',
      numeric: true,
      disablePadding: false,
      label: i18n.t('accidents.witnessStatement'),
      needSorting: true
    },
    {
      id: 'accident_report',
      numeric: true,
      disablePadding: false,
      label: i18n.t('accidents.accidentReport'),
      needSorting: true
    },
    {
      id: 'date_and_time',
      numeric: true,
      disablePadding: false,
      label: i18n.t('accidents.dateAndTime'),
      needSorting: true
    }
  ];

  const clickedRow = (row, id) => {
    setSelectedIndex(id);
  };

  const docIconClicked = (item, type, index) => {
    setisDocClicked(true);
    setSelectedDocArray(
      ...[
        type === 'witness'
          ? item.witness_statement.audioList
          : item.documents.docList
      ]
    );
    setSelectedType(type);
  };

  const docList = () => {
    return selectedDocArray.map((item, key) => {
      return (
        <ul>
          <li>
            {selectedType === 'witness' ? (
              <>
                <Label>{item.name} </Label>
                {item.url && (
                  <Audio
                    url={`${beBaseUrl}/document/open/${item.url}?token=${logBook.documentToken}`}
                  />
                )}
              </>
            ) : (
              <Link
                variant="body2"
                newPage
                onClick={() =>
                  viewReport({
                    apiId: 'viewReportApi',
                    data: item.url
                  })
                }
              >
                {item.name}
              </Link>
            )}
          </li>
        </ul>
      );
    });
  };

  const generateRows = (tableData) => {
    return tableData.map((item, index) => {
      return {
        ...item,
        photos: item.documents?.docList?.length ? (
          <Icon
            src={getImagePath(imageUrls.full)}
            tableicon="true"
            onClick={(e) => docIconClicked(item, 'full', index)}
          />
        ) : (
          <Icon src={getImagePath(imageUrls.notFull)} tableicon="true" />
        ),
        date_and_time: moment(new Date(item.date_and_time)).format(
          'MM/DD/YYYY HH:mm:ss'
        ),
        witness_statement: item.witness_statement ? (
          <Link onClick={(e) => docIconClicked(item, 'witness', index)}>
            {i18n.t('accidents.viewWitness')}
          </Link>
        ) : (
          i18n.t('accidents.viewWitness')
        ),
        accident_report: (
          <Link
            newPage
            onClick={() =>
              viewReport({
                apiId: 'viewReportApi',
                data: item?.accident_report
              })
            }
          >
            {i18n.t('accidents.viewReport')}
          </Link>
        )
      };
    });
  };

  const information = hasFetchApiStatus?.errorMessage ? (
    <Message type="error" message={hasFetchApiStatus.errorMessage} />
  ) : (
    'common.tableEmptyMessage'
  );

  return (
    <Styled.Wrapper>
      <div className="quickFilter"></div>
      <div className="tableWrapper">
        {insuracePortalUserRole && (
          <Filters
            fleetCompanyList={fleetCompanyList}
            filterList={(data) => { 
              changeRequest(data)
            }}
            handleReset={() => { 
              changeRequest({
                companyId: null
              })
             }}
             values={values}
             setInitialValues={setInitialValues}
          />)
        }
        {insuracePortalUserRole ?
          <Table
            type="server"
            defaultSortBy={{ sortBy: 'full_name', order: 'desc' }}
            title=""
            changeRequest={changeRequest}
            baseRequest={request}
            totalRecords={paginateList?.count}
            columns={[...columns]}
            data={generateRows(paginateList?.accidentListItems)}
            emptyMessage={information}
            itemsPerPage={15}
            pageinationReset={pagination}
            actions={[]}
            clickedRow={(row, id) => clickedRow(row, id)}
            i18n={i18n}
            pagenationClicked={(e) => {
              setPagination(!e);
            }}
          />

          : <Table
            type="client"
            defaultSortBy={{ sortBy: 'full_name', order: 'desc' }}
            title=""
            columns={[...columns]}
            data={generateRows(list)}
            emptyMessage={information}
            itemsPerPage={15}
            actions={[]}
            clickedRow={(row, id) => clickedRow(row, id)}
            i18n={i18n}
          />}
        <TableRowPopup
          isDocClicked={isDocClicked}
          index={selectedIndex}
          setisDocClicked={setisDocClicked}
          docList={docList}
          selectedType={selectedType}
          leftPosition={selectedType === 'witness' ? '48%' : '36%'}
        />
      </div>
    </Styled.Wrapper>
  );
};

Component.propTypes = {
  list: PropTypes.array.isRequired,
  hasFetchApiStatus: PropTypes.object.isRequired,
  Styled: PropTypes.object.isRequired,
  i18n: PropTypes.array.isRequired
};
