  import styled from 'styled-components';
  import Parent from './style';
  
  const DocumentSection = styled(Parent.DocumentSection)`
  `;
  const LeftWrapper = styled(Parent.LeftWrapper)`${({ theme }) => `
    background-color: ${theme.colors.POLICIES_BACKGROUND ? theme.colors.POLICIES_BACKGROUND : '#fff'};
    padding: 2% 3%;
    margin-bottom: 30px;
    border-radius: 5px;
    .documentTitle{
      padding-left: 0 !important;
    }
  `}`;
  const Styled = { DocumentSection, LeftWrapper };
  
  export default Styled;