import React, { lazy } from 'react';
import PropTypes from 'prop-types';

import Table from './TableComponent';
import Box from '@material-ui/core/Box';
import TopActions from './TopActions';
import ParentStyled from './style';
import { loadStyle } from '@/utils/utils';
let Styled = loadStyle(
  ParentStyled,
  'components/modules/DataTableWithSearchExport'
);

const Breadcrumb = lazy(() => import(`@/components/Breadcrumb`));

const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Breadcrumb filters={filters} />
      <Component {...contentProps} />
    </Box>
  );
};
export default Index;

const Component = ({
  list,
  filterSuccess,
  apiStatus,
  deleteData,
  exportAllData,
  callback,
  apiIds,
  fileName,
  exportCsvData,
  exportPdfData,
  needSearch,
  needFilter,
  needExportAll,
  tableColumns,
  noAction,
  i18n,
  logBook,
  columns,
  topAction,
  history,
  defaultSortBy,
  getDocumentToken,
  needExportPopup,
  pagination,
  setPagination,
  isOldView,
  isspOld
}) => {
  return (
    <>
      {topAction && (
        <TopActions
          {...{
            filterSuccess,
            Styled,
            columns,
            i18n,
            needExportAll,
            exportAllData,
            apiIds,
            needFilter,
            list,
            needSearch,
            fileName,
            exportCsvData,
            exportPdfData,
            logBook,
            history,
            getDocumentToken,
            needExportPopup,
            setPagination,
            isOldView
          }}
        />
      )}
      <Styled.TableWrapper>
        <div className="spireonOnly">{isspOld ? 'Violations' : 'Alerts'}</div>
        <div className="quickFilter"></div>
        <Table
          type="client"
          defaultSortBy={defaultSortBy}
          list={list}
          apiStatus={apiStatus}
          deleteData={deleteData}
          callback={callback}
          columns={tableColumns ? [...tableColumns] : [...columns]}
          apiIds={apiIds}
          noAction={noAction}
          i18n={i18n}
          pagination={pagination}
          setPagination={setPagination}
        />
      </Styled.TableWrapper>
    </>
  );
};

Component.propTypes = {
  list: PropTypes.object.isRequired,
  filterSuccess: PropTypes.func.isRequired,
  apiStatus: PropTypes.object.isRequired,
  exportAllData: PropTypes.func.isRequired,
  deleteData: PropTypes.func.isRequired
};
