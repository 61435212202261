import { getCameraUrlApi } from '../../../config/apis';

const initialState = {
    url: null,
    fetchCameraUrl : false
};

export const cameraUrl = {
  state: { ...initialState },
  reducers: {
    getSuccessCameraUrl: (state, payload) => {
      return { ...state, url:payload, fetchCameraUrl:true  };
    },
    getFailedCameraUrl: (state) => {
      return { ...state, fetchCameraUrl:true };
    }
  },
  effects: (dispatch) => ({
    async getCameraUrl(payload) {
        try {
          const response = await dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              api: getCameraUrlApi,
            },
            callback: () => {
              this.getFailedCameraUrl();
            }
          });
          this.getSuccessCameraUrl(response)
        } catch (error) {
          this.getFailedCameraUrl();
        }
      }
  })
};
