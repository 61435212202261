import styled from 'styled-components';
import Parent from './style';
const Wrapper = styled(Parent.Wrapper)`

`;
const TopWrapper = styled(Parent.TopWrapper)`
 
`;
const IconWrapper = styled(Parent.IconWrapper)`
margin-left: 2px;
align-self: center;
  .MuiAvatar-root { 
    width: 27px !important;
  }
`;

const ImpExpWrapper = styled(Parent.ImpExpWrapper)`
flex: 0 0 58%;
.importUserTemplateContainer  .MuiAvatar-root { 
  width: 16px !important;
  height: 16px !important;
}
.exportContainer .MuiAvatar-root { 
  width: 16px !important;
  height: 16px !important;
}
`;

const SearchWrapper = styled(Parent.SearchWrapper)`

`;

const ButtonsWrapper = styled(Parent.ButtonsWrapper)`
${({theme}) =>`
.MuiButton-root {
  font-size: 12px !important;
  background: transparent !important;
  border: 2px solid ${theme.colors.INPUT_BORDER};
}

`}`;

const DocContainer = styled(Parent.DocContainer)`
border: 1px solid rgba(255,255,255,0.66) !important;
a {
  font-size: 14px;
  text-decoration: none;
}
`;
const TableWrapper = styled(Parent.TableWrapper)`
`;
const Styled = {
  Wrapper,
  IconWrapper,
  ImpExpWrapper,
  SearchWrapper,
  TopWrapper,
  ButtonsWrapper,
  TableWrapper,
  DocContainer
};
export default Styled;
