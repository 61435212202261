
import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
.MuiExpansionPanelSummary-root { 
  h2 {
    text-transform: capitalize;
  }
}

.MuiAccordionSummary-content .MuiTypography-h2{
  margin-top: 0px !important;
}

.MuiAccordionSummary-content{
  display: flex;
  align-items: center;
}


.MuiPaper-root {
  box-shadow: none;
  background: -webkit-linear-gradient(top, #d0d8db 0%, #b3bec3 100%);

}
.MuiAccordionSummary-content.Mui-expanded h2 {
  text-transform: capitalize;
    font-size: 12px !important;
    color: #42505a !important;
    font-weight: bold;
}
.MuiAccordionSummary-root.Mui-expanded {
  min-height: 20px;
  background: -webkit-linear-gradient(top, #d0d8db 0%, #b3bec3 100%);
  opacity: 1;
  padding-left: 0;
  font-size: 12px;
  padding: 10px;
  height: 34px !important;
}
.MuiAccordion-root.Mui-expanded{
  margin: 0;
}
.MuiAccordionDetails-root {
  padding: 0;
}
.MuiCollapse-wrapperInner {
  background-color: ${theme.colors.PRIMARY_MAIN};
}
.MuiExpansionPanelDetails-root {
  position: relative;
  padding-top: 0px;
}
.viewAll {
  position: absolute;
  top: 8px;
  width: 50%;
  right: 6px;
  padding-bottom:0;
  padding-top: 0;
  font-size: 12px;
  font-weight: bold
  a{
    color: #42505a;
  }
  .normalCheckbox {
    svg {
      width: 22px;
      height: 22px;
      background: #f8f8f8;
    }
    .Checkbox {
      top: 0;
      rect.square {
        stroke: #fff;
      }
    }
      label {
      color: #fff !important;
      position: relative;
      font-size: 14px;
      top: -3px;
    }
  }
}
.statusIcon {
  top: 0 !important;
  .iconContainer:first-child {
    order:2;
    img {
      width: 20px;
      height: 20px;
      margin-left: 8px;
    }
  }
}
`}
`;
const Header = styled(Parent.Header)``;
const Content = styled(Parent.Content)`
.MuiPaper-root {
  padding: 0;
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 9px !important;
  border: 0 !important;
}
`;

const Accordion = { Wrapper, Header, Content  };

export default Accordion;
