/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React, { lazy, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import CircularProgress from '@material-ui/core/CircularProgress';
import underscore from 'underscore';

import { getImagePath, getFilterLocaleValues } from '@/utils/utils';
import { imageUrls } from '@/config/constants/keys';
import Label from '@/components/UI/FormLabel/Clutch';
import { useTranslation } from 'react-i18next';

import Styled from './style';
// import { truckInfo } from '@/controller/models';
const layout = window?.$environment?.CURRENT_LAYOUT;
const Input = lazy(() => import(`@/components/UI/Input/${layout}`));
// const Label = lazy(() =>
//   import(`@/components/UI/Label/${layout}`)
// );
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Select = lazy(() => import(`@/components/UI/Select/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const Modal = require(`@/components/UI/Modal/${layout}`).default;
const RenderComponent = ({
  values,
  handleEdit,
  errors,
  isNotValid,
  validateForm,
  customProps,
  submitAction,
  onClear
}) => {
  useEffect(() => {
    window.onbeforeunload = closeIt;
  }, [values]);

  useEffect(() => {
    return () => {
      window.onbeforeunload = null;
    };
  }, []);
  const closeIt = () => {
    return 'Are you sure you want to leave?';
  };
  const [open, setOpen] = React.useState(false);
  const [openModel, setOpenModel] = useState(false);
  const modalRef2 = useRef();
  const closeModalDelete = (event) => {
    // setOpenModel(false)
    // modalRef2.current.handleClose1(event);
    setOpen(false);
  };
  const clickedModalDelete = (e) => {
    // setOpenModel(true)
    // modalRef2.current.handleOpen(e);
    setOpen(true);
  };
  const [suggestionArray, setSuggestionArray] = useState([
    {
      name: '9 Pin',
      img: getImagePath(imageUrls.ninePin),
      value: '9 Pin',
      suggested: true
    },
    {
      name: '6 Pin',
      img: getImagePath(imageUrls.sixPin),
      value: '6 Pin',
      suggested: false
    },
    {
      name: 'OBDII',
      img: getImagePath(imageUrls.obdii),
      value: 'OBDII',
      suggested: false
    },
    {
      name: 'DUAL CAM',
      img: getImagePath(imageUrls.dualCam),
      value: 'DUAL CAM',
      suggested: false
    },
    {
      name: 'Mack',
      img: getImagePath(imageUrls.mack),
      value: 'Mack',
      suggested: false
    },
    {
      name: 'Volvo',
      img: getImagePath(imageUrls.volvo),
      value: 'Volvo',
      suggested: false
    }
  ]);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [suggType, setSuggestion] = useState(
    values['connectionType'] ? values['connectionType'] : null
  );
  let setInitialIndex;
  let selName;
  let selImage;
  let selValue;
  useEffect(() => {
    if (activeIndex > -1) {
      let elementsPresent = document.getElementsByClassName('connectWrap');
      for (let i = 0, len = elementsPresent.length; i < len; i++) {
        elementsPresent[i].classList.remove('active');
      }
      let element = document.getElementById(`selectListActive${activeIndex}`);
      if (element) {
        element.classList.add('active');
      }
    }
  }, [activeIndex]);
  useEffect(() => {
    let a = underscore.findIndex(suggestionArray, (item) => {
      return item.value === values['connectionType'];
    });
    setActiveType(a);
  }, [values.connectionType, openModel]);
  const [ModalListObject2, setModalListObject2] = useState({
    header: 'Selection Connection Type',
    content: () => {
      return (
        <div className="connectionTypeWrapper">
          <ul className="suggestionLinks">
            {suggestionArray.map((items, index) => {
              return (
                <li
                  id={`selectListActive${index}`}
                  className={`connectWrap selectList${index}`}
                  onClick={() => {
                    selName = items.name;
                    selImage = items.img;
                    selValue = items.value;
                    setActiveIndex(index);
                    setInitialIndex = index;
                  }}
                >
                  {items.suggested ? (
                    <label className="suggested">(Suggested)</label>
                  ) : (
                    ''
                  )}
                  <img src={items.img} alt="close"></img>
                  <div className="listNo">{items.name}</div>
                </li>
              );
            })}
          </ul>
          <div className="PopupBtn">
            <div className="cancelBtn">
              <Button
                label={i18n.t('Cancel')}
                type={'reset'}
                onClick={() => {
                  closeModalDelete();
                  setActiveIndex(-1);
                }}
              ></Button>
            </div>
            <div className="savebtn">
              <Button
                label={i18n.t('common.save')}
                onClick={() => {
                  setActiveIndex(-1);
                  setChosenType(selImage);
                  setChosenTypeName(selName);
                  handleEdit(selValue, { field: 'connectionType' });
                  closeModalDelete();
                }}
              ></Button>
            </div>
          </div>
        </div>
      );
    }
  });
  useEffect(() => {
    if (
      !values.connectionType &&
      values?.make &&
      values?.model &&
      values?.year
    ) {
      customProps?.truckInfo?.connectionType.map((item, index) => {
        if (
          item.includes(values?.make?.value) &&
          item.includes(values?.model?.value) &&
          parseInt(values?.year) >= item[0] &&
          parseInt(values?.year) <= item[1]
        ) {
          let tempName = item[4];
          setChosenType(getImage(tempName));
          setChosenTypeName(item[4]);
          handleEdit(tempName, { field: 'connectionType' });
          setSuggestionArray(
            suggestionArray.map((key, i) => {
              if (key.value === tempName) return { ...key, suggested: true };
              else return { ...key, suggested: false };
            })
          );
          let a = underscore.findIndex(suggestionArray, (items) => {
            return items.value === item[4];
          });
          setActiveType(a);
          setSuggestion(tempName);
        }
      });
    }
  }, [
    values.make,
    values.model,
    values.year,
    values.connectionType,
    suggestionArray
  ]);
  const [chosenType, setChosenType] = useState(getImagePath(imageUrls.ninePin));
  const [chosenTypeName, setChosenTypeName] = useState('9 Pin');
  const connectType = () => {
    clickedModalDelete();
    // setOpen(true)
  };
  const { i18n } = useTranslation();
  const showError = (field, validationErrors, isNotValid, apiError) => {
    if (validationErrors[field] && !isNotValid) {
      return <Message type="error" message={errors[field]} />;
    }
    if (apiError?.[field]) {
      return <Message type="error" message={apiError[field]} />;
    }
  };
  const yearUpdation = (value) => {
    return value.substring(0, 4);
  };
  const unitIdUpdation = (value) => {
    return value.substring(0, 10);
  };
  const [modelArray, setModalArray] = useState([]);
  useEffect(() => {
    setModalArray([]);
    if (values && values.make) {
      setModalArray(customProps?.truckInfo.model[values.make.value]);
    }
  }, [values.make]);
  const getImage = (type) => {
    let a = underscore.find(suggestionArray, (item) => {
      return item.value === type;
    });
    return a?.img;
  };
  const setActiveType = (a) => {
    setModalListObject2({
      header: 'Select Connection Type',
      content: () => {
        return (
          <div className="connectionTypeWrapper">
            <ul className="suggestionLinks">
              {suggestionArray.map((items, index) => {
                return (
                  <li
                    id={`selectListActive${index}`}
                    className={`connectWrap selectList${index}  ${
                      a === index ? 'active' : ''
                    }`}
                    onClick={() => {
                      selName = items.name;
                      selImage = items.img;
                      selValue = items.value;
                      setActiveIndex(index);
                      setInitialIndex = index;
                    }}
                  >
                    {items.suggested ? (
                      <label className="suggested">(Suggested)</label>
                    ) : (
                      ''
                    )}
                    <img src={items.img} alt="close"></img>
                    <div className="listNo">{items.name}</div>
                  </li>
                );
              })}
            </ul>
            <div className="PopupBtn">
              <div className="cancelBtn">
                <Button
                  label={i18n.t('Cancel')}
                  type={'reset'}
                  onClick={() => {
                    closeModalDelete();
                    setActiveIndex(-1);
                  }}
                ></Button>
              </div>
              <div className="savebtn">
                <Button
                  label={i18n.t('common.save')}
                  onClick={() => {
                    setActiveIndex(-1);
                    setChosenType(selImage);
                    setChosenTypeName(selName);
                    handleEdit(selValue, { field: 'connectionType' });
                    closeModalDelete();
                  }}
                ></Button>
              </div>
            </div>
          </div>
        );
      }
    });
  };
  return (
    <Styled.Wrapper>
      <div className="companyWrapper">
        <Styled.FieldsWrapper className="stateSelect">
          <Label variant="body1">Vehicle Type</Label>
          <Styled.TextWrapper>
            <Field
              name="type"
              onChange={(event) => handleEdit(event, { field: 'type' })}
              as={Select}
              placeholder="Select Vehicle Type"
              value={getFilterLocaleValues([values['type']], i18n)}
              suggestions={getFilterLocaleValues(customProps?.truckTypes.map((types) => ({
                value: types,
                label: types
              })), i18n)}
            />
            {showError(
              'type',
              errors,
              isNotValid,
              customProps.hasApiStatus?.errorMessage
            )}
          </Styled.TextWrapper>
        </Styled.FieldsWrapper>
      </div>

      <div className="addressWrapper">
        <Styled.FieldsWrapper>
          <Label variant="body1">Year</Label>
          <Styled.TextWrapper>
            <Field
              name="year"
              handleOnChange={(event) => {
                handleEdit(event, { field: 'year' });
                handleEdit('', { field: 'connectionType' });
                setChosenType(getImagePath(imageUrls.ninePin));
                setChosenTypeName('9 Pin');
                setSuggestionArray(
                  suggestionArray.map((key, i) => {
                    if (i == 0) return { ...key, suggested: true };
                    else return { ...key, suggested: false };
                  })
                );
                setActiveType(0);
              }}
              as={Input}
              placeholder="Select Year"
              value={values['year']}
              updateValue={(e) => yearUpdation(e)}
            />
            {showError(
              'year',
              errors,
              isNotValid,
              customProps.hasApiStatus?.errorMessage
            )}
          </Styled.TextWrapper>
        </Styled.FieldsWrapper>
        <Styled.FieldsWrapper className="stateSelect">
          <Label variant="body1">Make</Label>
          <Styled.TextWrapper>
            <Field
              name="make"
              onChange={(event) => {
                handleEdit(event, { field: 'make' });
                handleEdit('', { field: 'model' });
                handleEdit('', { field: 'connectionType' });
                setChosenType(getImagePath(imageUrls.ninePin));
                setChosenTypeName('9 Pin');
                setSuggestionArray(
                  suggestionArray.map((key, i) => {
                    if (i == 0) return { ...key, suggested: true };
                    else return { ...key, suggested: false };
                  })
                );
                setActiveType(0);
              }}
              as={Select}
              placeholder="Select Make"
              value={values['make']}
              suggestions={customProps?.truckInfo.make.map((makes) => ({
                value: makes,
                label: makes
              }))}
              id="makeContainer"
            />
            {showError(
              'make',
              errors,
              isNotValid,
              customProps.hasApiStatus?.errorMessage
            )}
          </Styled.TextWrapper>
        </Styled.FieldsWrapper>
      </div>
      <div className="addressWrapper">
        <Styled.FieldsWrapper className="stateSelect">
          <Label variant="body1">Model</Label>
          <Styled.TextWrapper>
            <Field
              name="model"
              onChange={(event) => {
                handleEdit('', { field: 'connectionType' });
                handleEdit(event, { field: 'model' });
                setChosenType(getImagePath(imageUrls.ninePin));
                setChosenTypeName('9 Pin');
                setSuggestionArray(
                  suggestionArray.map((key, i) => {
                    if (i == 0) return { ...key, suggested: true };
                    else return { ...key, suggested: false };
                  })
                );
                setActiveType(0);
              }}
              as={Select}
              placeholder="Select Model"
              value={values['model']}
              suggestions={modelArray.map((types) => ({
                value: types,
                label: types
              }))}
            />
            {showError(
              'model',
              errors,
              isNotValid,
              customProps.hasApiStatus?.errorMessage
            )}
          </Styled.TextWrapper>
        </Styled.FieldsWrapper>
        <Styled.FieldsWrapper>
          <Label variant="body1">Unit ID</Label>
          <Styled.TextWrapper>
            <Field
              name="unitId"
              handleOnChange={(event) => handleEdit(event, { field: 'unitId' })}
              as={Input}
              placeholder="Type Unit ID"
              value={values['unitId']}
              updateValue={(e) => unitIdUpdation(e)}
            />
            {showError(
              'unitId',
              errors,
              isNotValid,
              customProps.hasApiStatus?.errorMessage
            )}
          </Styled.TextWrapper>
        </Styled.FieldsWrapper>
      </div>
      <div className="addressWrapper">
        <Styled.FieldsWrapper className="stateSelect">
          <Label variant="body1" className="connectionType">
            Connection Type
            {suggType === values['connectionType'] ||
            chosenTypeName === '9 Pin' ? (
              <span className="label">(Suggested)</span>
            ) : (
              ''
            )}
          </Label>
          <Styled.TextWrapper className="popupContainer suggested">
            <div className="typePopupContainer">
              <img
                alt=""
                src={
                  values['connectionType']
                    ? getImage(values['connectionType'])
                    : chosenType
                }
              />
              <div className="listNo">
                {values['connectionType']
                  ? values['connectionType']
                  : chosenTypeName}
              </div>
            </div>
            <Button
              className="selectConnection"
              label={'Change'}
              onClick={() => connectType()}
            ></Button>
            {showError(
              'connectionType',
              errors,
              isNotValid,
              customProps.hasApiStatus?.errorMessage
            )}
          </Styled.TextWrapper>
        </Styled.FieldsWrapper>
      </div>
      <FormStyle.default.ButtonsWrapper id="buttonWrapper">
        <Button
          id="backTruckInfoButton"
          type="submit"
          label={'Previous'}
          onClick={() => {
            customProps.previousClicked();
          }}
        />
        <Button
          id="nextTruckInfoButton"
          type="submit"
          label={
            customProps?.hasApiStatus?.isLoading ? (
              <CircularProgress color="inherit" />
            ) : (
              'Next'
            )
          }
          onClick={() => {
            const validateResponse = validateForm();
            validateResponse.then((data) => {
              submitAction(data);
              if (Object.keys(data).length === 0) {
                if (customProps?.index + 1 !== customProps?.trucks) {
                  onClear();
                }
                setChosenType(null);
                setChosenTypeName(null);
              }
            });
          }}
        />
      </FormStyle.default.ButtonsWrapper>
      <Modal
        open={open}
        setOpen={setOpen}
        data={ModalListObject2}
        connectionType
      />
    </Styled.Wrapper>
  );
};

export default RenderComponent;

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  customProps: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};
