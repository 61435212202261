import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import LoginForm from './Form';
import { useTranslation } from 'react-i18next';
const layout = window.$environment?.CURRENT_LAYOUT;
const SvgIcon = lazy(() => import(`@/components/SvgIcon/${layout}`));
const FormStyle =
  require(`../../../components/UI/Form/${layout}/serverStyle`).default;

const Component = ({ Styled, ...rest }) => {
  const { i18n } = useTranslation();
  return (
    <Styled.LoginParentWrapper>
      <Styled.LeftWrapper>
        <div className="login-right-wrap">
          <LoginForm {...rest} Styled={Styled} />
        </div>
      </Styled.LeftWrapper>
      <Styled.RightWrapper>
      </Styled.RightWrapper>
    </Styled.LoginParentWrapper>
  );
};

export default Component;
Component.defaultProps = { hasRegistration: true };
Component.propTypes = {
  Styled: PropTypes.object.isRequired
};
