import React, { useState, lazy } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import moment from 'moment'

import RenderComponent from './RenderComponent';

import {
  objectArrayFromDiffFields,
  objectArrayFromMultipleFields
} from '@/utils/utils';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));

const ViewForm = ({
  changeRequest,
  hasApiStatus,
  callback,
  history,
  list,
  setPagination
}) => {
  const { i18n } = useTranslation();
  const [values, setValues] = useState({
    userId: '',
    vehicleId: null,
    startDate: null,
    endDate: null
  });

  const [validationError, setValidationError] = useState('');
  const [dateValidationError, setDateValidationError] = useState('');
  const userList = objectArrayFromMultipleFields(list?.drivers, 'id', [
    'firstName',
    'lastName'
  ]);
  let vehicleList = objectArrayFromDiffFields(
    list?.vehicles,
    'vehicleId',
    'vin'
  );
  vehicleList = vehicleList.map(o=> ({...o,label: `...${o?.label.substr(o?.label?.length - 8)}`}))

  return (
    <>
      <Form
        type="server"
        direction="row"
        initialValues={{ ...values }}
        className="CSAForms"
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {
          setDateValidationError('');
          if (
            fields.userId?.value ||
            fields.vehicleId?.value ||
            fields.startDate ||
            fields.endDate
          ) {
            if (
              fields.startDate &&
              moment(fields.endDate).diff(fields.startDate, 'days') < 0
            ) {
              setDateValidationError('Date To should not be less than Date From');
            } else {
            setValidationError('');
            const finalData = {
              userId: fields.userId?.value || null,
              vehicleId: fields.vehicleId?.value || null,
              startDate: fields.startDate || null,
              endDate: fields.endDate || null
            };
            Object.keys(finalData).forEach(
              (key) => finalData[key] === null && delete finalData[key]
            );
            setPagination(true);
            changeRequest({ ...finalData });
            }
          } else {
            setValidationError('Please select atleast one filter');
          }
        }}
        handleOnCancel={(fields) => {
          setPagination(true);
          changeRequest();
          setValidationError('');
        }}
        customProps={{
          history,
          callback,
          hasApiStatus,
          vehicleList,
          userList,
          validationError,
          dateValidationError,
          setDateValidationError
        }}
      />
    </>
  );
};

ViewForm.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default ViewForm;
