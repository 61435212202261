import React from 'react';
import PropTypes from 'prop-types';

import Form from './Form';

const Component = ({ Styled, ...rest }) => {
  return (
    <Styled.Wrapper>
      <div className="loginformwrap">
        <Form Styled={Styled} {...rest} />
      </div>
    </Styled.Wrapper>
  );
};

export default Component;

Component.propTypes = {
  Styled: PropTypes.object.isRequired
};
