import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import { getStyle } from '../../../utils/utils';

const Container = styled(Box)`
  ${({ theme, type }) => `
  color: ${(theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN};
  }};
  .active {
    background-color: transparent !important;
  }
  tr {
    vertical-align: top;
    position: relative;
  }
  td {
    position: relative;
  }
  .noData {
    text-align: center;
    svg {
      width: 40px;
      height: 40px;
      color: ${
        (theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN
      };
    }
  }
  width: 100%;
  .MuiPaper-root {
    width: 100%;
  }
  svg {
    width: 13px;
    height: 13px;
  }

  .MuiTableCell-head {
    padding: 10px 15px 10px 7px !important;
    text-align: left !important;
    font-size: 17px;
    color: ${
      (theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN
    };
  }
  .MuiTableSortLabel-active .MuiTableSortLabel-iconDirectionAsc,
  .MuiTableSortLabel-active .MuiTableSortLabel-iconDirectionDesc {
    position: absolute;
    right: 16px;
    top: -6px;
    width: 1.9rem;
    svg {
      width: 1.9rem;
      height: 1.6rem;
    }
  }
  .MuiTableSortLabel-active .MuiTableSortLabel-iconDirectionAsc{
    top: 1px;
  }
  .MuiTableSortLabel {
    line-height: 0.5;
  }
  .MuiIcon-root {
    width: 1.1rem;
  }
  .MuiTableBody-root tr {
    background: ${theme.colors.BLACK};
    td {
      background-color: transparent;
    }
  }
  .MuiTableBody-root tr:hover {
    background: ${theme.colors.PRIMARY_MAIN};
  }
  .MuiTableSortLabel-iconDirectionAsc {
    width: 1.1rem;
    color: ${theme.colors.PRIMARY_CONTRAST};
  }

  .MuiTableSortLabel-iconDirectionDesc {
    width: 1.1rem;
    color: ${theme.colors.PRIMARY_CONTRAST};
  }
  .MuiTableCell-root {
    padding: 8px 10px;
    border-bottom: 2px solid
      ${(theme.palette.primary || {}).border || theme.colors.PRIMARY_BORDER};
  }
  .MuiTableBody-root .MuiTableCell-root {
    font-size: 16px;
    border-bottom: 0.5px solid
      ${(theme.palette.primary || {}).border || theme.colors.PRIMARY_MAIN};
  }
  .MuiAvatar-root {
    width: 20px;
    height: 20px;
  }
  .MuiTableRow-root {
    background-color: ${type === 'udLogs' ? 'none' : theme.colors.PRIMARY_MAIN};
  }
  `}
`;
const Header = styled(Box)`
  ${({ theme }) => `
  display: flex;
  padding-bottom: 30px;
  padding: 3% 2%;
  padding-top: 1%;
  position: relative;
  .filterForm {
    flex: 0 0 90%;
    .MuiCheckbox-root {
      padding: 7px !important;
    }
    div[type='text'],
    div[type='date'], div[type='select'] {
      width: 18%;
      flex-direction: column;
      p {
        color: ${theme.colors.PRIMARY_CONTRAST};
      }
      .select__control{
        margin-bottom: 0px;
      }
      @media (max-width: ${theme.breakpoints.values.md}px) {
        width: auto;
        margin-bottom: 16px;
      }
      @media only screen and (min-width: ${theme.breakpoints.values.md}px) and (max-width: ${theme.breakpoints.values.mlg}px) {
        margin-right: 18px;
      }
    }
    div[type='submit'],
    div[type='reset'] {
      align-items: flex-end;
      @media (max-width: ${theme.breakpoints.values.md}px) {
        margin-bottom: 16px;
      }
    }
    .MuiInput-root {
      @media (max-width: ${theme.breakpoints.values.md}px) {
        padding: 12px 10px;
      }
    }
    .MuiInput-root,
    .react-datepicker-wrapper {
      @media (max-width: ${theme.breakpoints.values.md}px) {
        min-width: 180px;
      }
    }
    .MuiTypography-body1 {
      font-size: 20px;
    }
    .MuiInputBase-input{
      ${window.$environment?.clutchNewUI && `background: #fff;`}
    }
    .react-datepicker-wrapper input{
      ${window.$environment?.clutchNewUI && `background-color: #fff;`}
    }
  }
  .exportForm {
    flex-wrap: nowrap !important;
    padding-left: 10px;
    padding-bottom: 10px;
  }

  .MuiGrid-root {
    justify-content: flex-end;
  }
  .exportContainer {
    display: flex;
    align-items: flex-end;
  }
  .tooltiptext {
    width: 155px;
    font-size: 15px;
    right: 0;
    top: 82px;
  }
  .MuiAvatar-root {
    width: 35px;
    height: 35px;
  }
  @media (max-width: ${theme.breakpoints.values.md}px) {
    flex-direction: column;
  }
  `}
`;
const ExportWrapper = styled(Box)`
  border: solid 2px
    ${(({ style, theme }) =>
      getStyle((style || {}).border, theme.palette.primary || {}).border,
    '')};
  background-color: ${(({ style, theme }) =>
    getStyle((style || {}).border, theme.palette.primary || {}).main,
  '')};
  width: 450px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  position: absolute;
  transition-property: left;
  transition-duration: 2s;
  right: ${({ right }) => right || '0px'};
`;
const Content = styled(Box)`
  ${({theme})=> `
  .logwrapper > header{
    ${theme.colors.NEW_THEME ? `
    .Mui-selected {
      :hover{
        color: #fff;
        .MuiTab-wrapper {
          color: #fff;
        }
      }
    }
    ` : ''}
  }
  .logwrapper {
    overflow: hidden;
    display: flex;
    // height: 100vh;
    header.MuiAppBar-positionStatic {
      width: 16%;
      border-right: 1px solid ${theme.colors.SECONDARY_BORDER};
      padding-top: 3%;
    }
    div[role='tabpanel'].MuiTypography-root {
      width: 100%;
    }
    .filterdetails {
      padding-left: 2%;
    }
    .MuiTab-wrapper {
      align-items: normal;
      text-align: left;
    }
    .MuiButtonBase-root.MuiTab-root {
      border-right: 0;
      padding-left: 8%;
      width: 100%;
      text-transform: capitalize !important;
      border-bottom: 1px solid ${theme.colors.SECONDARY_BORDER};
      background: ${theme.colors.PRIMARY_MAIN};
      margin-right: 0 !important;
      :hover {
        border-bottom: 1px solid #2c3249 !important;
        color: ${theme.colors.TAB_HIGHLIGHT};
      }
    }
    .MuiTabs-flexContainer .Mui-selected {
      background-color: ${theme.colors.BACKGROUND_MENU};
      border-bottom: 0 !important;
      font-weight: 700;
      color: ${theme.colors.PRIMARY_CONTRAST};
      
    }
    .exportContainer {
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        flex: 0 0 11%;
      }
    }
    .vehicleTabs {
      background-color: ${theme.colors.FORM_BACKGROUND};
      .MuiPaper-root.MuiAppBar-positionStatic{
        width: 100%;
        padding: 3.5% 2% 0 2%;
      }
      header{
        box-shadow: none; 
      }
      .viewAll{
        padding-right: 10px;
        .MuiGrid-container .MuiAvatar-root {
          width: 24px !important;
          height: 16px !important;
        }
      }
      .MuiTabs-flexContainer {
        border-top: 0;
        background-color: transparent;
        .Mui-selected{
          background-color: ${theme.colors.TAB_HIGHLIGHT_BACKGROUND} !important;
          border-bottom: 4px solid ${theme.colors.TAB_BOTTOM_BORDER} !important;
        }
        .MuiButtonBase-root{
          flex: 0 0 20%;
          padding: 6px 12px;
          .MuiTab-wrapper{
            align-items: center;
          }
        }
      }
      .MuiTabs-root {
        .MuiButtonBase-root.MuiTab-root {
          border-right: 0;
          border-bottom: 4px solid transparent;
        }
        .MuiTab-wrapper {
          font-size: 16px;
        }
        .MuiButtonBase-root.MuiTab-root:hover {
            border-bottom: 4px solid ${theme.colors.TAB_BOTTOM_BORDER} !important;
            color: ${theme.colors.TAB_HIGHLIGHT};
        }
        .Mui-selected .MuiTab-wrapper {
          color: ${theme.colors.SELECTED_TAB_COLOR};
        }
      }
    }
  }`}
`;
const tableWrapper = styled(Box)`
  padding: 0 2%;
  .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:nth-child(8) {
    width: 32px;
    .iconContainer .MuiAvatar-root {
      height: 18px !important;
    }
  }
  .spireonNewOnly,
  .spireonOldOnly {
    display: none;
  }
`;
const Styled = { Container, Header, ExportWrapper, Content, tableWrapper };

export default Styled;
