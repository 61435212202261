
const initialState = {
  data: null,
};

const LESS_THAN_FIVE = "LESS_THAN_FIVE";
const TWENTY_AND_ABOVE = "TWENTY_AND_ABOVE";
const FIVE_TO_NINETEEN = "FIVE_TO_NINETEEN";

const LESS_THAN_ONE = "LESS_THAN_ONE";
const ONE_TO_THREE = "ONE_TO_THREE";
const THREE_TO_FIVE = "THREE_TO_FIVE";
const FIVE_AND_ABOVE = "FIVE_AND_ABOVE";

const ONE_TO_NINE = "ONE_TO_NINE";
const ELEVEN_TO_NINETEEN = "ELEVEN_TO_NINETEEN";


// structure
const mockData = {
  "errors": null,
  "data": {
    "companyName": "GS Staging Company 1",
    "totalVehicleCount": 49,
    "distractedDriving": {
      "totalDays": 8,
      "totalScore": 90,
      "rangeAndVehiclesCount": {
        "LESS_THAN_FIVE": 48,
        "TWENTY_AND_ABOVE": 1,
        "FIVE_TO_NINETEEN": 0
      }
    },
    "speeding": {
      "totalDays": 7,
      "totalScore": 100,
      "rangeAndVehiclesCount": {
        "LESS_THAN_FIVE": 48,
        "TWENTY_AND_ABOVE": 1,
        "FIVE_TO_NINETEEN": 0
      }
    },
    "accident": {
      "totalDays": 2,
      "totalScore": 100,
      "rangeAndVehiclesCount": {
        "LESS_THAN_ONE": 48,
        "ONE_TO_THREE": 1,
        "THREE_TO_FIVE": 0,
        "FIVE_AND_ABOVE": 0
      }
    },
    "totalDrivingBehaviorScore": 50,
    "unpoweredDays": {
      "totalDays": 132,
      "totalScore": 100,
      "rangeAndVehiclesCount": {
        "ONE_TO_NINE": 5,
        "TWENTY_AND_ABOVE": 0,
        "ELEVEN_TO_NINETEEN": 44
      }
    },
    "radiusViolations": {
      "totalDays": 0,
      "totalScore": 100,
      "rangeAndVehiclesCount": {
        "ONE_TO_NINE": 49,
        "TWENTY_AND_ABOVE": 0,
        "ELEVEN_TO_NINETEEN": 0
      }
    },
    "aggregatedUnpoweredDaysScore": 17,
    "aggregatedDrivingBehaviorScore": 40,
    "aggregatedRadiusViolationsScore": 19,
    "fleetScore": 70
  }
};


const restructedData = (data) => {
  const distractedDriveRange = data?.distractedDriving?.rangeAndVehiclesCount;
  const speedingRange = data?.speeding?.rangeAndVehiclesCount;
  const accidentRange = data?.accident?.rangeAndVehiclesCount;
  const unpoweredDaysRange = data?.unpoweredDays?.rangeAndVehiclesCount;
  const radiusViolationsRange = data?.radiusViolations?.rangeAndVehiclesCount;
  return {
    ...data,
    "data": {
      ...data,
      "totalDrivingBehaviorData" : [parseInt(data?.distractedDriving?.totalScore / 3), parseInt(data?.speeding?.totalScore / 3), parseInt(data?.accident?.totalScore/ 3)],
      "distractedDriving": {
        ...data?.distractedDriving,
        chartData: [distractedDriveRange[LESS_THAN_FIVE],
        distractedDriveRange[FIVE_TO_NINETEEN],
        distractedDriveRange[TWENTY_AND_ABOVE]],
      },
      "speeding": {
        ...data?.speeding,
        chartData: [speedingRange[LESS_THAN_FIVE],  speedingRange[FIVE_TO_NINETEEN],
        speedingRange[TWENTY_AND_ABOVE]],
      },
      "accident": {
        ...data?.accident,
        chartData: [accidentRange[LESS_THAN_ONE], accidentRange[ONE_TO_THREE], accidentRange[THREE_TO_FIVE],accidentRange[FIVE_AND_ABOVE]],
      },
      "unpoweredDays": {
        ...data?.unpoweredDays,
        chartData: [unpoweredDaysRange[ONE_TO_NINE], unpoweredDaysRange[ELEVEN_TO_NINETEEN], unpoweredDaysRange[TWENTY_AND_ABOVE]],
      },
      "radiusViolations": {
        ...data?.radiusViolations,
        chartData: [radiusViolationsRange[ONE_TO_NINE], radiusViolationsRange[ELEVEN_TO_NINETEEN], radiusViolationsRange[TWENTY_AND_ABOVE]],
      },
    }
  }
}

export const newScoreCard = {
  state: { ...initialState },
  reducers: {
    fetchSuccess(state, payload) {
      return {
        ...state,
        data: payload,
      };
    },
    fetchFailed(state, payload) {
      return {
        ...state,
        data: {},
      };
    },
  },
  effects: (dispatch) => ({
    async clearScoreCard(payload, rootState) {
      this.fetchFailed();
    },
    async fetchScoreCard(payload, rootState) {
      try {
        const getApi = await import(
          `../../../config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('newScorecardChart');

        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...apiObject,
              url: `${apiObject.url.replace(':companyId', payload?.data.id)}${payload?.data?.isYearly ? '&period=YEARLY' : ''}`
            },
            data: payload.data?.request
          },
          id: payload.apiId
        });
        console.log("saddsadassda", response)
        response ? this.fetchSuccess(restructedData(response)) : this.fetchSuccess({ data: {} });
      } catch (error) {
        this.fetchFailed();
      }
    },
  })
};
