import React, { lazy } from 'react';

const layout = window?.$environment?.CURRENT_LAYOUT;

const Search = lazy(() => import(`@/components/UI/SimpleSearch/${layout}`));

const TopActions = ({
  filterSuccess,
  Styled,
  i18n,
  notInUseVehicles,
  setPagination
}) => {
  const selectChange = (e) => {
    filterSuccess(e);
    setPagination(true);
  };
  return (
    <Styled.TopWrapper>
      <Styled.SearchWrapper>
        <Search
          id={'clearSearch'}
          onChange={(e) => selectChange(e)}
          default={notInUseVehicles?.notInUseVehicles}
          fromData
          placeHolder={i18n.t('vehicles.searchVehicle')}
          query={[
            'unit_id',
            'drivers',
            'type',
            'vin',
            'year',
            'manufact',
            'model'
          ]}
          id='out_of_service'
        />
      </Styled.SearchWrapper>
    </Styled.TopWrapper>
  );
};
export default TopActions;
