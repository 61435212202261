import {
  COOKIE_AUTH_DETAILS,
  COOKIE_CLIENT_AUTH_DETAILS
} from '@/config/constants/keys';
import { setCookie, deleteCookie } from '@/services/cookie';
const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  companyName: '',
  rememberMe: false,
  loginData: {}
};

export const login = {
  state: { ...initialState },
  reducers: {
    setLoginSuccess(state, payload) {
      return {
        ...state,
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        companyName: payload.companyName,
        rememberMe: payload.rememberMe,
        enterpriseFeatureDto: payload.enterpriseFeatureDto,
        subscriptions: payload.subscriptions,
        adminSuspended: payload?.adminSuspended,
        previousPaymentFailed: payload?.previousPaymentFailed
      };
    },
    setLoginData(state, payload) {
      return {
        ...state,
        loginData: payload
      }
    },
    setLoginFailed() {
      return { ...initialState };
    }
  },
  effects: (dispatch) => ({
    async fetchResellerData(payload) {
      try {
        let response;
        response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              url: `${window.$environment.S3_URL}/reseller_info.json`,
              type: 'get',
              isPublic: true
            }
          },
          callback: payload.callback,
          id: payload.apiId
        });
        if (response) {
          payload.callback(response)
        }
      } catch (err) {
        console.log(err)
      }
    },
    async fetchData(payload) {
      try {
        let response;
        if (payload.data.token) {
          const getApi = await import(
            `@/config/apis/${window?.$environment?.SERVICE_PROVIDER}`
          );
          const apiObject = getApi.default('authApiWithToken');

          response = await dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              data: { ...payload.data },
              api: apiObject
            },
            callback: payload.callback,
            id: payload.apiId
          });

        } else {
          const getApi = await import(
            `@/config/apis/${window?.$environment?.SERVICE_PROVIDER}`
          );
          const apiObject = getApi.default('authApi');
          this.setLoginData(payload)
          response = await dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              data: { ...payload.data },
              api: apiObject
            },
            id: payload.apiId
          });

        }
        if (response) {
          if (response.status == 206) {
            payload.callback('mfaEnabled')
          } else {
            response.rememberMe = payload?.data?.rememberMe;
            this.setLoginSuccess(response);
            setCookie(COOKIE_AUTH_DETAILS, response, payload?.data?.rememberMe);
            setCookie('dotNumber', response?.dotNumber)
            setCookie('companyId', response?.companyId)
            setCookie('cameraPortalUserId', response?.cameraPortalUserId)
            payload.callback(response);
          }

        }
      } catch (error) {
        this.setLoginFailed();
      }
    },

    async authenticteOtp(payload, root) {
      try {
        this.fetchData({ ...root.login.loginData, data: { ...root.login.loginData.data, mfaOtp: payload.data.mfacode } })

      } catch (error) {
        this.setLoginFailed();
      }
    },

    async handleLogout(payload) {
      try {
        // const getApi = await import(
        //   `@/config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        // );
        // const apiObject = getApi.default('logoutApi');
        // await dispatch.asyncRequests.handleAsyncRequests({
        //   payload: {
        //     api: apiObject,
        //     callback: payload.callback
        //   },
        //   id: payload.apiId
        // });
        deleteCookie(COOKIE_CLIENT_AUTH_DETAILS);
        deleteCookie(COOKIE_AUTH_DETAILS);
        dispatch({ type: 'RESET_APP' });
        payload.callback('success');
      } catch (error) { }
    }
  })
};
