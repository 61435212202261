import styled from 'styled-components';

const CheckboxWrapper = styled.div`
  ${({ theme }) => `
  .MuiSvgIcon-root {
    border: 2px solid ${theme.colors.INPUT_BORDER};

    background: colors.BLACK;
  }

  .MuiIconButton-root:hover {
    background-color: transparent;
  }
  .normalCheckbox svg {
    width: 19px;
    height: 19px;
    position: absolute;
    top: 2px;
  }
  .Checkbox.checked .tick{
    stroke-dashoffset: -2;
  }
  .Checkbox.unchecked .tick {
    opacity: 0;
  }
  .Checkbox .tick {
    stroke-dasharray: 21;
  }
  input#checkbox:checked + span {
    opacity: 1;
  }
  .normalCheckbox svg rect {
    rx: 0;
    ry: 0;
  }
  .normalCheckbox .Checkbox.checked .square {
    fill-opacity: 0;
  }
  .normalCheckbox .unchecked.disabledCheck .square {
    fill-opacity: 1 !important;
  }
  .normalCheckbox .Checkbox.unchecked .square {
    fill-opacity: 0;
  }
  `}
`;

export default CheckboxWrapper;
