import React, { lazy, useEffect, useState } from 'react';
import ParentStyled from '../style';
import { useTranslation } from 'react-i18next';
import { loadStyle } from '@/utils/utils';
import RenderComponent from './RenderComponent'
import { object, string, number } from 'yup';

let Styled = loadStyle(ParentStyled, 'pages/web/CompanyManagement');

const layout = window.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`../../../../components/UI/Form/${layout}`));

const ScoreManagement = (props) => {
  const { i18n } = useTranslation();
  const { handleCancel, updateScores, scoreValues, companyId } = props;
  const [initialValues, setInitialValues] = useState({})
  useEffect(() => {
    if (scoreValues && Object.keys(scoreValues).length) {
      let initial = {}
      if (scoreValues.behaviorScores) scoreValues.behaviorScores.forEach(ele => {
        initial[ele.behavior.toLowerCase()] = String(ele.score)
      })
      if (scoreValues.severityScores) scoreValues.severityScores.forEach(ele => {
        initial[`severity_${ele.severity.toLowerCase()}`] = String(ele.score)
      })
      setInitialValues(initial)
    } else {
      setInitialValues({
        radius: '0',
        acceleration: '0',
        braking: '0',
        turning: '0',
        speeding: '0',
        accident: '0',
        none: '0',
        severity_less_than_33_percentage: '0',
        severity_between_33_to_67_percentage: '0',
        severity_greater_than_67_percentage: '0',
        severity_speeding: '0',
        severity_accident: '0',
        severity_hard: '0',
        severity_harsh: '0',
        severity_severe: '0',
        severity_none: '0'
      })
    }
  }, [scoreValues])
  return <>
    <Form
      type="server"
      direction="column"
      initialValues={initialValues}
      RenderComponent={RenderComponent}
      handleOnSubmit={(fields) => {
        let payload = {
          companyId: Number(companyId),
          behaviorScores: [],
          severityScores: []
        }
        Object.keys(fields).forEach(ele => {
          if (ele.includes('severity_')) {
            payload.severityScores.push({
              severity: ele.split('severity_')[1].toUpperCase(),
              score: Number(fields[ele])
            })
          } else {
            payload.behaviorScores.push({
              behavior: ele.toUpperCase(),
              score: Number(fields[ele])
            })
          }
        })
        updateScores({
          data: payload,
          apiId: 'updateScores'
        })

      }}
      customProps={{
        Styled,
        i18n,
      }}
      handleOnCancel={() => {
        handleCancel()
      }}
      validationSchema={object().shape({
        radius: string().required(i18n.t('common.fieldRequiredMessage')).matches(/^[0-9]*$/, 'Must be a number'),
        acceleration: string().required(i18n.t('common.fieldRequiredMessage')).matches(/^[0-9]*$/, 'Must be a number'),
        braking: string().required(i18n.t('common.fieldRequiredMessage')).matches(/^[0-9]*$/, 'Must be a number'),
        turning: string().required(i18n.t('common.fieldRequiredMessage')).matches(/^[0-9]*$/, 'Must be a number'),
        speeding: string().required(i18n.t('common.fieldRequiredMessage')).matches(/^[0-9]*$/, 'Must be a number'),
        accident: string().required(i18n.t('common.fieldRequiredMessage')).matches(/^[0-9]*$/, 'Must be a number'),
        none: string().required(i18n.t('common.fieldRequiredMessage')).matches(/^[0-9]*$/, 'Must be a number'),
        severity_less_than_33_percentage: string().required(i18n.t('common.fieldRequiredMessage')).matches(/^[0-9]*$/, 'Must be a number'),
        severity_between_33_to_67_percentage: string().required(i18n.t('common.fieldRequiredMessage')).matches(/^[0-9]*$/, 'Must be a number'),
        severity_greater_than_67_percentage: string().required(i18n.t('common.fieldRequiredMessage')).matches(/^[0-9]*$/, 'Must be a number'),
        severity_accident: string().required(i18n.t('common.fieldRequiredMessage')).matches(/^[0-9]*$/, 'Must be a number'),
        severity_speeding: string().required(i18n.t('common.fieldRequiredMessage')).matches(/^[0-9]*$/, 'Must be a number'),
        severity_hard: string().required(i18n.t('common.fieldRequiredMessage')).matches(/^[0-9]*$/, 'Must be a number'),
        severity_harsh: string().required(i18n.t('common.fieldRequiredMessage')).matches(/^[0-9]*$/, 'Must be a number'),
        severity_severe: string().required(i18n.t('common.fieldRequiredMessage')).matches(/^[0-9]*$/, 'Must be a number'),
        severity_none: string().required(i18n.t('common.fieldRequiredMessage')).matches(/^[0-9]*$/, 'Must be a number'),
      })
      }
    />
  </>

}

export default ScoreManagement