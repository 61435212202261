import styled from 'styled-components';
import Parent from './style';
const Wrapper = styled(Parent.Wrapper)`
// padding: 2%;
`;
const TableWrapper = styled(Parent.TableWrapper)`
${({ theme }) => `
  padding-top: 0;
`}
`;
const Styled = { Wrapper, TableWrapper};

export default Styled;
