import {
  driverStatusHistoryListApi,
  exportCsvDriverStatusApi,
  exportPdfDriverStatusApi
} from '../../../../config/apis';

const initialState = {
  list: [],
  filteredList: [],
  selectedRow: { index: -1, row: {} }
};

export const driverStatusHistory = {
  state: { ...initialState },
  reducers: {
    listSuccess(state, payload) {
      return { ...state, list: [...payload], filteredList: [...payload] };
    },
    listFailed() {
      return { ...initialState };
    },
    filterSuccess(state, list) {
      return { ...state, filteredList: [...list] };
    },
    setSelectedItem(state, data) {
      return { ...state, selectedRow: { ...data } };
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: driverStatusHistoryListApi,
            data: { id: payload.data.id }
          },
          id: payload.apiId
        });
        response && this.listSuccess(response);
      } catch (error) {
        this.listFailed();
      }
    },

    async exportCsvData(payload, rootState) {
      try {
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: exportCsvDriverStatusApi
          },
          id: payload.id
        });
      } catch (error) {}
    },
    async exportPdfData(payload, rootState) {
      try {
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...exportPdfDriverStatusApi,
              url: exportPdfDriverStatusApi.url.replace(
                '{docType}',
                payload.docType
              )
            }
          },
          id: payload.id
        });
      } catch (error) {}
    }
  })
};
