import styled from 'styled-components';
import Parent from '../style';

const PolicyWrapper = styled(Parent.PolicyWrapper)`
${({ theme }) => `
.viewSample {
    display: block;
    cursor:pointer;
    color: #C92100;
    font-size: 12px;
    &:hover {
        text-decoration: underline;
    }
}
.sampleIcon {
    display: none;
}
`}
`;
const SelectedPolicy = styled(Parent.SelectedPolicy)`
${({ theme }) => `
flex: 0 0 25%;
padding-top: 0;
align-self: flex-start;
.MuiBox-root {
    margin-bottom: 0;
}
.viewMore {
    align-self: center;
    padding-left: 4px;
    padding-top: 3px;
}
.MuiAvatar-root {
    width: auto;
    height: auto;
}
.subFormsection {
    padding-top: 0;
    padding-left: 37px;
    .subFields:first-child {
        padding-top: 5px;
    }
    .subFields:last-child {
        padding-bottom: 3px;
    }
}
.subFields {
    display: flex;
    flex-direction: row;
    padding-bottom: 8px;
    label {
     font-size: 12px !important;
     color: #373737 !important;
     font-weight: bold;
     flex: 0 0 214px;
     width: 200px;
     padding-right: 10px;
     font-family: ${(theme.palette || {}).fontFamily};
    }
    .MuiInputBase-root.MuiInput-root {
        .MuiInputBase-input{
            padding: 0;
        }
    }
    .MuiInputBase-root {
        width: 75%;
    }
}
.normalCheckbox {
    padding-right: 0;
    label {
        padding-left: 6px;
    }
    .Checkbox { 
        top: 2px;
    }
    span {
        padding-left: 10px;
        font-size: 20px;
    }
}
`}
`;
const Styled = { SelectedPolicy , PolicyWrapper };
export default Styled;
