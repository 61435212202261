import RenderComponent from '@/components/modules/DynamicRenderPage';
import React, { lazy, useState } from 'react';
import { connect } from 'react-redux';

import { getLastQuaters, loadPageConfig, loadStyle } from '@/utils/utils';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import ParentStyled from './style';


const layout = window?.$environment?.CURRENT_LAYOUT;
const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.vehicleDTCView[0].name}`));
const Content = lazy(() => import(`@/${config.vehicleDTCView[1].name}`));
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));

let Styled = loadStyle(ParentStyled, 'pages/web/ViewDCA');


const ViewVehicleDCA = (parentProps) => {
  const { fetchDCAList } = parentProps;
  const { i18n } = useTranslation();
  const commonParentProps = {  i18n };
  const [pagination, setPagination] = useState(false);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const { state } = location;
  const lastQuater = getLastQuaters(true);
  const basicRequest = {
    displayLength: 15,
    displayStart: 1,
    vehicleId: location?.pathname?.split('/')[3],
  };
  const [request, setRequest] = useState({
    ...basicRequest
  });

  React.useEffect(() => {
    fetchDCAList({ apiId: 'dtaList', data: { ...request } });
  }, [request]);

  const sortColumnMapping = {
    code: 'dtcCode',
    fromDate: 'startTime',
    toDate: 'endTime',
  }

  const changeRequest = (fields) => {
    if (fields?.sortCol) {
      const type = fields.sortCol
      fields.sortCol = sortColumnMapping[type] ? sortColumnMapping[type] : fields.sortCol
    }
    setRequest({ ...basicRequest, ...fields });
  };

  return (
    <>
      <RenderComponent
        {...config.vehicleDTCView[0]}
        parentProps={{
          ...parentProps,
        }}
        header={() => (
         <Styled.LabelWrapper>
            <Label className="header" varaint="h1">{`DIAGNOSTIC CODE ALERTS - ${(query.get('name') || '').replace('*', ' ')}`}</Label>
         </Styled.LabelWrapper>
        )}
        commonParentProps={commonParentProps}
        component={Title}
        Styled={Styled}
        request={request}
      />
      <RenderComponent
        {...config.vehicleDTCView[1]}
        parentProps={{
          ...parentProps,
          request,
          pagination,
          setPagination,
          basicRequest
        }}
        commonParentProps={commonParentProps}
        component={Content}
        Styled={Styled}
        information="No Data Found"
        changeRequest={changeRequest}
        pagination={pagination}
        setPagination={setPagination}

      />
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    dcaList: state.dcaList,
  };
};

const mapDispatch = (dispatch) => {
  return {
    fetchDCAList: (data) => dispatch.dcaList.fetchData(data),
  };
};

export default connect(mapState, mapDispatch)(ViewVehicleDCA);
