import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
.titlePolicy {
    display: none;
}
.policyLine {
    justify-content: flex-start;
    padding: 0;
    padding-left: 13%;
    padding-top: 20px;
    .policyText p:first-child {
        font-size: 20px;
        opacity: 0.87;
    }
    .policyText p:last-child {
        color: rgba(0, 0, 0, 0.87);
        font-size: 15px;
    }
}
.selectedCheckbox {
    flex-direction: column;
    padding-left: 13%;
    padding-top: 15px;
}
.policySubmit {
    justify-content: flex-end;
    padding: 15px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    #submitPolicy {
        margin-top: 0;
        min-width: auto;
        padding: 0 15px !important;
    }
}
`;

const Styled = { Wrapper };

export default Styled;
