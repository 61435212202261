import React, { useEffect, lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { UI_LOGBOOK_DATE } from '@/config/constants/static';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  getImagePath,
  loadStyle,
  hasRestrictedRole,
  getNewObject,
  loadPageConfig,
  isDriver,
  hasEnabledFeature
} from '@/utils/utils';
import { UI_DATE_LOWER_FORMAT } from '@/config/constants/static';
import ParentStyled from '@/components/modules/LogsManagement/style';
import { Link as LinkLi } from '@material-ui/core';
import { imageUrls, pageUrls } from '@/config/constants/keys';
import { useTheme } from '@material-ui/core/styles';
import HistoryIcon from '@material-ui/icons/History';
import EditSharpIcon from '@material-ui/icons/EditSharp';
const layout = window?.$environment?.CURRENT_LAYOUT;
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const Tab = lazy(() => import(`@/components/UI/Tab/${layout}`));
const config = loadPageConfig();
const Component = lazy(() => import(`@/${config.logsList[1].name}`));
const SvgIcon = lazy(() => import(`@/components/SvgIcon/${layout}`));

let Styled = loadStyle(ParentStyled, 'components/modules/LogsManagement');
const Index = (parentProps) => {
  const { manageLogList, fetchLogList, getFilters } = parentProps;
  const [pagination, setPagination] = useState(false);
  const history = useHistory();
  const i18n = useTranslation();
  const theme = useTheme();
  const commonParentProps = { history, i18n, Styled, theme };
  const [selectedIndex, setSelectedIndex] = useState(0);
  const setSelectedIndexfn = (index) => {
    let rowLen =
      window.$environment.SERVICE_PROVIDER === 'spireon'
        ? 36
        : window.$environment.APP_LAYOUT === 'NewUi'
          ? 40
          : 39;
    let value = rowLen * index;
    let wl =
      window.$environment.SERVICE_PROVIDER === 'spireon'
        ? 104
        : window.$environment.APP_LAYOUT === 'NewUi'
          ? 82
          : 74;
    let changeValue = wl + value;
    setSelectedIndex(changeValue);
  };

  useEffect(() => {
    fetchLogList({ apiId: 'fetchLoglistApiId', realTimeLogs: true });
    getFilters({ apiId: 'fetchFilters' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (document.getElementById('list1')) {
      const isSelected = document
        .getElementById('list1')
        ?.className.includes('Mui-selected');
      document.getElementById('list1').className = !isSelected
        ? document.getElementById('list1').className + ' Mui-selected'
        : document.getElementById('list1').className;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.getElementById('list1')]);

  return (
    <RenderComponent
      {...config.logsList[1]}
      parentProps={parentProps}
      commonParentProps={commonParentProps}
      component={Component}
      history={history}
      i18n={i18n}
      list={manageLogList}
      selectedIndex={selectedIndex}
      setSelectedIndexfn={setSelectedIndexfn}
      pagination={pagination}
      setPagination={setPagination}
    />
  );
};

const RenderComponent = ({
  name,
  props,
  commonProps,
  title,
  parentProps,
  commonParentProps,
  component,
  history,
  i18n,
  verticalTab,
  selectedIndex,
  setSelectedIndexfn,
  pagination,
  setPagination,
  ...rest
}) => {
  const Component = component;
  const handleChange = (event, newValue, fromSubTab) => {
    if (fromSubTab) history.push(subTabValues[newValue].url);
    else history.push(tabValues[newValue].url);
  };
  const [isToolsClicked, setIsToolsClicked] = useState(false);

  const actions = hasRestrictedRole(['ADMIN'])
    ? []
    : window.$environment.CURRENT_LAYOUT === 'SpireonNew'
      ? [
        {
          icon: (row, index) => {
            let day = moment(new Date(row.date)).format(UI_LOGBOOK_DATE)
            return (
              <LinkLi
                component={Link}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                to={`${pageUrls.logBook.replace(':id', row.idPub)}?driverId=${row.driverId}&logDay=${day}`}
              >
                <img src={getImagePath(imageUrls.logbookIcon)} className="logbookIcon" alt="logbookIcon" />
              </LinkLi>
            );
          }
        },
        {
          icon: (row, index) => {
            return (
              <LinkLi
                style={{ visibility: "hidden" }}
                component={Link}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                to={pageUrls.logsEdit.replace(':id', row.idPub)}
              >
                <EditSharpIcon className="historyIcon" />
              </LinkLi>
            );
          }
        },

        {
          icon: (row, index) => {
            return (
              <LinkLi
                style={{ visibility: "hidden" }}

                component={Link}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                to={pageUrls.logsHistory.replace(':id', row.idPub)}
              >
                <HistoryIcon className="historyIcon" />
              </LinkLi>
            );
          }
        }
      ]
      : layout !== 'Clutch'
        ? [
          {
            icon: (row, index) => {
              return (
                <Icon
                  src={getImagePath(imageUrls.toolsIcon)}
                  onClick={() => {
                    setIsToolsClicked(true);
                    parentProps.setSelectedManageLogItem({ index, row });
                    setSelectedIndexfn(index);
                  }}
                />
              );
            }
          }
        ]
        : [];

  let newProps = props?.length ? getNewObject(parentProps, props) : {};
  newProps = {
    ...newProps,
    ...(commonProps?.length
      ? getNewObject(commonParentProps, commonProps)
      : {}),
    ...rest
  };

  const selectedRow = (row) => {
    let day = moment(new Date(row.date)).format(UI_LOGBOOK_DATE)
    !hasRestrictedRole(['ADMIN']) &&
      history.push(`${pageUrls.logBook.replace(':id', row.idPub)}?driverId=${row.driverId}&logDay=${day}`);
  };
  const subTabValues = [{
    name: i18n.t('logs.manageLogsHeaderSigned'),
    value: null,
    url: pageUrls.logsList
  }, {
    name: i18n.t('logs.manageLogsHeaderUnSigned'),
    value: (
      <Component
        selectedRow={(row, id) => selectedRow(row)}
        isDriverLogs={false}
        {...newProps}
        isRealTimeLogs
        actions={actions}
        isToolsClicked={isToolsClicked}
        setIsToolsClicked={setIsToolsClicked}
        selectedIndex={selectedIndex}
        pagination={pagination}
        setPagination={setPagination}
        fields={[
          {
            type: 'text',
            value: '',
            field: 'driver_name',
            placeholder: i18n.t('common.driver'),
            label: i18n.t('common.driver')
          },
          {
            type: 'date',
            value: '',
            field: 'dateFrom',
            placeholder: UI_DATE_LOWER_FORMAT,
            label: i18n.t('common.dateFrom'),
            isTimeNeeded: false,
            isClearable: true
          },
          {
            type: 'date',
            value: '',
            field: 'dateTo',
            placeholder: UI_DATE_LOWER_FORMAT,
            label: i18n.t('common.dateTo'),
            isTimeNeeded: false,
            minValue: 'dateFrom'
          }
        ]}
      />
    ),
    url: pageUrls.realTimeLogsList

  },]
  const isSpireon = window.$environment.SERVICE_PROVIDER === 'spireon';
  const tabValues = [
    {
      name: i18n.t('logs.manageLogsHeader'),
      value: (
        <>
          <Tab
            data={subTabValues}
            className={isSpireon ? "sublogwrapper" : "vehicleTabs"}
            currentTab={1}
            onChange={(event, newValue) => handleChange(event, newValue, true)}
            vertical={isSpireon ? verticalTab : false}
          />
        </>
      ),
      url: pageUrls.logsList
    },
    ...(!isDriver() ? [
      {
        name: i18n.t('logs.undefinedDriverHeader'),
        value: null,
        url: pageUrls.logsUdList
      },
      ...(window.$environment.SERVICE_PROVIDER !== 'spireon' ? [
      {
        name: i18n.t('logs.addLogHeader'),
        value: null,
        url: pageUrls.logsAdd
      },
      {
        name: i18n.t('logs.archiveLogsHeader'),
        value: null,
        url: pageUrls.archiveLogsList
      }] : []),
    ] : []),
    ...(isSpireon ? [
      {
        name: i18n.t('leftMenu.DOTAudit'),
        value: null,
        url: pageUrls.fmcsaLogs
      }] : []),
  ]

  return (
    <Styled.Content>
      <div className="goBack">
        <SvgIcon
          value={'pageBackButton.svg'}
          onClick={() => history.goBack()}
        />
      </div>
      <Tab
        data={tabValues}
        className="logwrapper"
        currentTab={0}
        onChange={handleChange}
        vertical={verticalTab}
      />
    </Styled.Content>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    manageLogList: state.manageLogList,
    filters: state.filters
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchLogList: (data) => dispatch.manageLogList.fetchData(data),
    filterSuccess: (data) => dispatch.manageLogList.filterSuccess(data),
    setSelectedManageLogItem: (data) =>
      dispatch.manageLogList.setSelectedItem(data),
    handleLogBookStatus: (data) =>
      dispatch.logBookStatus.handleLogBookStatus(data),
    clearApiStatus: () => dispatch.asyncRequests.clearApiStatus(),
    getFilters: (data) => dispatch.filters.fetchData(data),
    viewReport: (data) => dispatch.viewReport.viewReportDocument(data)
  };
};

export default connect(mapState, mapDispatch)(Index);
Index.defaultTypes = {};
Index.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  manageLogList: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  filterSuccess: PropTypes.func.isRequired,
  handleLogBookStatus: PropTypes.func.isRequired,
  clearApiStatus: PropTypes.func.isRequired,
  setSelectedManageLogItem: PropTypes.func.isRequired,
  fetchUdLogList: PropTypes.func.isRequired,
  udFilterSuccess: PropTypes.func.isRequired,
  fetchUnAssignedEntries: PropTypes.func.isRequired,
  unAssignedEntries: PropTypes.object.isRequired,
  saveUnAssignedEntries: PropTypes.func.isRequired,
  setUdAssignEntryList: PropTypes.func.isRequired,
  clearUdAssignEntryListById: PropTypes.func.isRequired
};
