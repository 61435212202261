import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
padding: 2%;
`;
const ChartWrapper = styled(Box)`
  ${({ theme }) => `
width: 100%;
.svg-container,
.updateEventsection,
.quickFilter {
  display: none;
}
#addNewRow {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    .MuiButton-root {
        padding: 0 10px !important;
    }
}
textarea {
    resize: none;
}
  `}
`;
const LabelWrap = styled(Box)`
  p {
    font-size: 14px;
    margin-bottom: 20px;
  }
`;
const StatusModifier = styled(Box)`
 ${({ theme }) => `
 padding-top: 40px;
  .status {
    min-width: 98px;
  }
  .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:nth-last-child(2) {
    padding: 23px 0 0 23px !important;
    text-align: center !important;
    .iconContainer {
      justify-content: center;
      .MuiAvatar-root {
        width: 15px;
        height: 18px;
      }
    }
  }
  .MuiPaper-root .MuiBox-root:first-child {
    @media (min-width: 882px) {
      overflow-x: inherit;
    }
  }
  .MuiTableHead-root .MuiTableRow-root{
    border-bottom: 1px solid #ddd;
  }
  .MuiTableHead-root .MuiTableRow-root {
    .MuiTableCell-root:first-child {
      min-width: 112px;
    }
    .MuiTableCell-root:nth-child(6),
    .MuiTableCell-root:nth-child(2) {
      min-width: 150px;
    }
  }
  .MuiInputBase-input{
      border: 0 !important;
        color: #373737!important;
  }
  .MuiInputBase-root.Mui-disabled,
  .MuiInputBase-input.Mui-disabled{
    cursor: no-drop;
  }
  .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root:first-child {
    min-width: 147px;
  }
  .driverId,
  .stateModifier {
    .select__placeholder {
      font-size: 16px;
    }
    .select__dropdown-indicator {
      padding-left: 0;
      padding: 3px;
    }
  }
  .time_wrapper {
    margin-top: 15px;
  }
  .time_wrapper .react-datepicker-wrapper{
    min-height: 20px !important;
    height: 20px !important;
    .react-datepicker__input-container {
      height: 20px !important;
      input {
        height: 20px !important;
        border: 1px solid rgb(211,215,219)!important;
      }
    }
  }
  // .basic-multi-select .select__control {
  //   min-height: 20px !important;
  //   height: 20px;
  //   overflow-y: hidden !important;
  //   .select__single-value {
  //     top: 53%;
  //     height: 20px;
  //     // padding: 0;
  //   }
  //   .select__value-container {
  //     height: 20px;
  //   }
  //   .select__indicator.select__dropdown-indicator {
  //     padding: 0px;
  //   }
  // }
  .MuiInputBase-root.MuiInput-root {
    height: 20px;
    margin-top: 15px;
    .MuiInputBase-input.MuiInput-input {
      height: 20px;
      padding: 0;
      border: 2px solid rgb(211,215,219) !important;
    }
  }
  .react-datepicker--time-only {
    font-family: Yanone Kaffeesatz, tahoma;
    font-size: 14px;
    font-weight: normal;
    .react-datepicker__time-list {
      padding-top: 6px;
    }
    .react-datepicker__time-list-item {
      border-top: none !important;
    }
    .react-datepicker__header--time {
      display: none;
    }
  }
  .dailyLogDateError {
    border: 2px solid red !important;
  }
  .MuiInputBase-input.MuiInput-input.Mui-disabled.Mui-disabled{
    opacity: 1 !important;
    color: black !important;
    border: none !important;
}
.time_wrapper .react-datepicker-wrapper .react-datepicker__input-container input:disabled {
  border: none !important;
  opacity: 1 !important;
}
.MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:nth-last-child(2):{
  padding: 0  0 0 23px !important;
}
  `}
`;
const CancelSaveWrapper = styled(Box)`
  ${({ theme }) => `

  `}
`;
const Styled = { Wrapper, ChartWrapper, LabelWrap, StatusModifier, CancelSaveWrapper };
export default Styled;
