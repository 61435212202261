import React from 'react';
import Chart from 'chart.js';
import { fade } from '@material-ui/core/styles/colorManipulator';

export default class ScatterGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  chartRef = React.createRef();

  componentDidMount() {
    const history = this.props.history;
    const pageurl = this.props.pageUrls;
    const noHyperLink = this.props?.noHyperLink;
    const myChartRef = this.chartRef.current.getContext('2d');
    new Chart(myChartRef, {
      type: 'scatter',
      data: {
        datasets: this.props.data
      },
      options: {
        onClick: function (e, c) {
          // Route to new page
          // history.push(pageurl);
          if (noHyperLink === false) {
            window.open(pageurl, '_blank')
          }
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                display: true,
                color: this.props.theme.colors.PRIMARY_BORDER
              },
              type: 'linear', // Y-axis type is linear for scatter charts
              position: 'left',
              ticks: {
                beginAtZero: true,
                steps: 10,
                stepValue: 5
              },
              scaleLabel: {
                display: true,
                labelString: 'Severity'
              }
            }
          ],
          xAxes: [
            {
              type: 'linear', // X-axis type is linear for scatter charts
              position: 'bottom',
              gridLines: {
                display: true,
                color: this.props.theme.colors.PRIMARY_BORDER
              },
              scaleLabel: {
                display: true,
                labelString: 'Behavior'
              },
              ticks: {
                beginAtZero: true,
                fontSize: 10
              }
            }
          ]
        },
        legend: {
          display: true,
          labels: {
            boxWidth: 10
          },
          position: 'bottom'
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              var label = data.datasets[tooltipItem.datasetIndex].label || '';
              return label + ': (' + tooltipItem.xLabel + ', ' + tooltipItem.yLabel + ')';
            },
          },
        },
        title: {
          display: true,
          text: this.props.title,
          fontFamily: 'Yanone Kaffeesatz',
          fontSize: 18
        }
      }
    });
  }
  render() {
    return (
      <div>
        <canvas id="barChart" width={this.props.width} height={this.props.height} ref={this.chartRef} />
      </div>
    );
  }
}
