import React, { lazy } from 'react';
import moment from 'moment';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Table = lazy(() => import(`../../../components/UI/Table/${layout}`));
const PotentialViolations = ({ potentialViolations, i18n }) => {
  const columns = window.$environment.SERVICE_PROVIDER != 'webfleet' ? [
    {
      id: 'driver',
      disablePadding: false,
      label: i18n.t('vehicles.fullName')
    },
    {
      id: 'id',
      numeric: false,
      disablePadding: false,
      label: i18n.t('roadInspection.driverId')
    },

    {
      id: 'phone',
      numeric: true,
      disablePadding: false,
      label: i18n.t('common.phone')
    },
    {
      id: 'violationType',
      disablePadding: false,
      label: i18n.t('dashboard.tabs.potentialViolations.violationType')
    }]
    : [{
      id: 'driver',
      disablePadding: false,
      label: i18n.t('vehicles.fullName')
    },
    {
      id: 'id',
      numeric: false,
      disablePadding: false,
      label: i18n.t('roadInspection.driverId')
    },

    {
      id: 'phone',
      numeric: true,
      disablePadding: false,
      label: i18n.t('common.phone')
    },
    {
      id: 'date',
      disablePadding: false,
      label: i18n.t('common.date'),
      needSorting: true
    },
    {
      id: 'violationType',
      disablePadding: false,
      label: i18n.t('dashboard.tabs.potentialViolations.violationType')
    }
    ];
  const generateRows = () => {
    const updatedList = potentialViolations?.map((item) => {
      var result = {};
      result.driver = item.items[0];
      result.id = item.items[1];
      result.phone = item.items[2];
      result.violationType = item.items[3];
      result.date = item.items[4] ? moment(new Date(item.items[4])).format('MM/DD/YYYY hh:mm:ss')
      : '';
      return result;
    });
    return updatedList;
  };
  const information = i18n.t('common.tableEmptyMessage');
  return (
    <>
      <Table
        type="client"
        title=""
        columns={[...columns]}
        data={generateRows()}
        emptyMessage={information}
        itemsPerPage={10}
        actions={[]}
        i18n={i18n}
        noPagination
        id="potentialDashboard"
      />
    </>
  );
};

export default PotentialViolations;

PotentialViolations.defaultProps = {};

PotentialViolations.propTypes = {};
