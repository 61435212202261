import React, {useEffect} from 'react';
import Chart from 'chart.js';
import { fade } from '@material-ui/core/styles/colorManipulator';

// const BarGraph = (props) => {
  export default class BarGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
   chartRef = React.createRef();
  // useEffect(() => {
    componentDidMount(){
      let props = this.props;
    const myChartRef = this.chartRef.current.getContext('2d');
    var variableY =
      props.data?.dot?.length === 0 &&
      props.data?.tow?.length === 0 &&
      props.data?.injuries?.length === 0 &&
      props.data?.fatalities?.length === 0 &&
      props.data?.citations?.length;
    new Chart(myChartRef, {
      type: 'bar',
      data: {
        labels: props.labels,
        datasets: props.data
      },
      options: {
        responsive: true,
        barValueSpacing: 20,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true,
                color: '#c0bbb7'
              },
              ticks: {
                beginAtZero: true,
                fontSize: 11,
                fontFamily: 'Yanone Kaffeesatz',
                color: '#c0bbb7',
                maxRotation: 0,
                minRotation: 0
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: variableY ? false : true,
                color: '#c0bbb7'
              },
              ticks: {
                display: variableY ? false : true,
                min: 0,
                // stepSize: 2,
                fontSize: 15,
                fontFamily: 'Yanone Kaffeesatz'
              }
            }
          ]
        },
        legend: {
          display: true,
          labels: {
            boxWidth: 20,
            fontSize: 16,
            fontFamily: 'Yanone Kaffeesatz',
            fontColor: '#c0bbb7'
          }
        }
      }
    });
  // }, [props]);
    }
  render() {
  return (
    <div>
      <canvas
        id="barChart"
        width={this.props.width}
        height={this.props.height}
        ref={this.chartRef}
      />
    </div>
  );
  }
};

// export default BarGraph;
