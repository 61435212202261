import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import { Helmet } from 'react-helmet';

import CompanyInfo from './companyInfo/Component';
import FleetManagerInfo from './fleetManagerInfo/Component';
import TruckInfo from './truckInfo/Component';
import Checkout from './checkout/Component';
import Terms from './terms/Component';
import Styled from './style';
import { hasValue } from '../../../../utils/utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import NotFound from './notfound';

import { getCookie } from '@/services/cookie';
import { COOKIE_AUTH_DETAILS } from '../../../../config/constants/keys';
const Index = (props) => {
  const [token, setToken] = useState(null);
  useEffect(() => {
    setToken(getCookie(COOKIE_AUTH_DETAILS));
  }, []);
  return !token ? <Component {...props} /> : <NotFound />;
};

export default Index;

const Component = ({
  history,
  companyInfo,
  callback,
  getStates,
  addCompanyInfo,
  apiStatus,
  getCompanyInfo,
  addFleetManagerInfo,
  fleetmanagerInfo,
  getFleetManagerInfo,
  addTruckInfo,
  getTruckInfo,
  truckInfo,
  getTruckTypes,
  states,
  addCheckout,
  getCheckout,
  addSigneeName,
  setValidationSuccess,
  pageTitle,
  clearCompanyInfoValues,
  clearFleetInfoValues,
  clearTruckInfoValues,
  ...rest
}) => {
  useEffect(() => {
    if (Object.keys(companyInfo.companyInfoData).length) {
      validationSuccess();
    }
  }, [companyInfo?.companyInfoData]);

  useEffect(() => {
    if (Object.keys(fleetmanagerInfo.fleetmanagerInfoData).length) {
      validationSuccess();
    }
  }, [fleetmanagerInfo?.fleetmanagerInfoData]);

  useEffect(() => {
    if (Object.keys(truckInfo.addTruckInfoData).length) {
      validationSuccess();
    }
  }, [truckInfo?.addTruckInfoData]);
  useEffect(() => {
    if (fleetmanagerInfo.signed === true) validationSuccess();
  }, [fleetmanagerInfo.signed]);
  const hasCompanyApiStatus = hasValue(apiStatus.status, 'api', 'addCompany');
  const hasFleetManagerApiStatus = hasValue(
    apiStatus.status,
    'api',
    'addFleetManager'
  );
  const hasTruckInfoApiStatus = hasValue(
    apiStatus.status,
    'api',
    'addTruckInfo'
  );

  useEffect(() => {}, []);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [addTruckInfoApiCall, setaddTruckInfoApiCall] = useState(false);
  const steps = getSteps();

  useEffect(() => {
    if (activeStep !== 2) {
      document.getElementById('nextButtonStep').style.display = 'block';
      document.getElementById('backButtonStep').style.display = 'block';
    } else {
      document.getElementById('nextButtonStep').style.display = 'none';
    }
  }, [activeStep]);

  useEffect(() => {
    if (activeStep === 0) {
      document.getElementById('backButtonStep').style.display = 'none';
    } else if (activeStep === 4) {
      document.getElementById('nextButtonStep').style.display = 'none';
    }
  }, [activeStep]);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      display: 'flex'
    },
    button: {
      marginRight: theme.spacing(1)
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      fontSize: 30
    }
  }));

  const classes = useStyles();
  function getSteps() {
    return ['Company Info', 'Fleet Manager', 'Truck Info', 'Terms', 'Checkout'];
  }
  const [addMoreClicked, setaddMoreClicked] = useState(false);
  function getStepContent(step, history) {
    switch (step) {
      case 0: {
        return (
          <CompanyInfo
            history={history}
            apiStatus={apiStatus}
            states={states}
            addCompanyInfo={addCompanyInfo}
            getCompanyInfoApi={companyInfo.getcompanyInfoData}
            addTruck={addMoreClicked}
          />
        );
      }
      case 1:
        return (
          <FleetManagerInfo
            history={history}
            apiStatus={apiStatus}
            addFleetManagerInfo={addFleetManagerInfo}
            getCompanyInfoApi={companyInfo.getcompanyInfoData}
            getFleetManagerInfo={fleetmanagerInfo.getfleetmanagerInfoData}
          />
        );
      case 2:
        return (
          <TruckInfo
            history={history}
            apiStatus={apiStatus}
            states={companyInfo.states}
            addCompanyInfo={addCompanyInfo}
            getCompanyInfoApi={companyInfo.getcompanyInfoData}
            getFleetManagerInfo={fleetmanagerInfo.getfleetmanagerInfoData}
            showNextStep={showNextStep}
            addTruckInfo={addTruckInfo}
            addTruckInfoApiCall={addTruckInfoApiCall}
            truckTypes={truckInfo.truckTypes}
            truckInfo={truckInfo}
          />
        );
      case 3:
        return (
          <Terms
            history={history}
            apiStatus={apiStatus}
            getToCheckout={getToCheckout}
            addSigneeName={addSigneeName}
            getSigneeInfo={fleetmanagerInfo.signeeName}
            getCompanyInfoApi={companyInfo.getcompanyInfoData}
          />
        );
      case 4:
        return (
          <Checkout
            history={history}
            apiStatus={apiStatus}
            states={states}
            getCompanyInfoApi={companyInfo.getcompanyInfoData}
            checkoutDetails={companyInfo.checkoutDetails}
            getFleetManagerInfo={fleetmanagerInfo.getfleetmanagerInfoData}
            addCheckout={addCheckout}
            callback={callback}
            companyInfo={companyInfo}
            addMoreTrucks={addMoreTrucks}
            clearCompanyInfoValues={clearCompanyInfoValues}
            clearFleetInfoValues={clearFleetInfoValues}
            clearTruckInfoValues={clearTruckInfoValues}
          />
        );
      default:
        return 'Unknown step';
    }
  }

  const addMoreTrucks = () => {
    setaddMoreClicked(true);
    setActiveStep(0);
  };
  const getToCheckout = () => {
    if (activeStep === 3) {
      // validationSuccess();
      // setActiveStep(4);
    }
  };
  const showNextStep = () => {
    document.getElementById('nextButtonStep').style.display = 'block';
  };
  const validationSuccess = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (activeStep === 0) {
      document.getElementById('companyInfoSubmit').click();
      // validationSuccess()
    } else if (activeStep === 1) {
      document.getElementById('fleetManagerInfoSubmit').click();
      // validationSuccess()
      // document.getElementById("nextButtonStep").style.display = "none";
    } else if (activeStep === 2) {
      // document.getElementById('fleetManagerInfoSubmit').click();
      // validationSuccess()
      document.getElementById('nextTruckInfoButton').click();
    } else if (activeStep === 3) {
      document.getElementById('termInfoSubmit').click();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    clearFleetInfoValues({
      apiId: 'clearFleetInfoValues'
    });
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const baseUrl = `${process.env.CDN_URL || ''}/${
    window.$environment?.SERVICE_PROVIDER
  }`;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="manifest" href={`${baseUrl}/manifest.json`} />
        <link rel="icon" type="image/x-icon" href={`${baseUrl}/favicon.ico`} />
      </Helmet>
      <Styled.Wrapper>
        <div className={classes.root}>
          <div className="leftMenuWrapper">
            <Styled.LogoWrapper></Styled.LogoWrapper>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
          <div className="contentWrapper">
            {/* {activeStep === steps.length ? (
            <div>
              <div className={classes.instructions}>
                All steps completed - you&apos;re finished
              </div>
              <Button onClick={handleReset} className={classes.button}>
                Reset
              </Button>
            </div>
          ) : ( */}
            <div className="contentWrap">
              <div className={classes.instructions}>
                {getStepContent(activeStep, history)}
              </div>
              <div
                className={
                  activeStep === 2
                    ? 'buttonSection topbutton'
                    : activeStep === 3
                    ? 'termsButton buttonSection'
                    : 'buttonSection'
                }
              >
                <Button
                  disabled={activeStep === 0}
                  onClick={() => handleBack()}
                  id="backButtonStep"
                >
                  Previous
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleNext()}
                  id="nextButtonStep"
                >
                  {hasCompanyApiStatus?.isLoading ||
                  hasFleetManagerApiStatus?.isLoading ||
                  hasTruckInfoApiStatus?.isLoading ? (
                    <CircularProgress color="inherit" />
                  ) : (
                    'Next'
                  )}
                </Button>
              </div>
            </div>
            {/* )} */}
          </div>
        </div>
      </Styled.Wrapper>
    </>
  );
};
