import React, { useState, lazy, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import Box from '@material-ui/core/Box';

import { useTranslation } from 'react-i18next';
import useTheme from '@material-ui/core/styles/useTheme';

import Component from './OverrideComponent';
import Styled from './Clutch';
import { pageUrls } from '@/config/constants/keys';
import { hasEnabledFeature } from '@/utils/utils';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Title = lazy(() => import(`@/components/Title`));
const Breadcrumb = lazy(() => import(`@/components/UI/Breadcrumb/${layout}`));
const DatePopup = lazy(() =>
  import(`@/components/modules/DatePopup/${layout}`)
);
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Link = lazy(() => import(`@/components/UI/Link`));

const LogBook = ({
  history,
  callback,
  apiStatus,
  logBook,
  setSelectedManageLogItem,
  fetchManageLogList,
  fetchLogBookView,
  getLogbookEvents,
  updateShowEvents,
  fetchLogbookBreadcrumb,
  handleLogBookStatus,
  clearApiStatus,
  dailyLogDownloadData,
  getDocumentToken,
  drivers,
  dates,
  currentDriver,
  unSignedDates,
  ...rest
}) => {
  const viewApiId = 'fetchLogBookView';
  const fetchLogbookBreadcrumbApiId = 'fetchLogbookBreadcrumb';
  const [isBulkDownloadClicked, setIsBulkDownloadClicked] = useState(false);
  const [formValues, setFormValues] = useState({
    driverId: logBook?.selectedDailyLog?.driverId,
    driverName: logBook?.selectedDailyLog?.driverName,
    type: 'DAILY_LOG'
  });

  useEffect(() => {
    setFormValues({
      driverId: logBook?.selectedDailyLog?.driverId,
      driverName: logBook?.selectedDailyLog?.driverName,
      type: 'DAILY_LOG'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logBook?.selectedDailyLog?.driverId]);

  const { i18n } = useTranslation();
  const theme = useTheme();
  const match = useParams();
  const breadcrumbData = [
    {
      name: 'Logs',
      url: `${window.$environment.RESELLER ? window.$environment.RESELLER : ''}/logs/list`
    },
    { name: 'Manage Logs', url: `${window.$environment.RESELLER ? window.$environment.RESELLER : ''}/logs/list` },
    {
      name: 'Logbook',
      url: ''
    }
  ];

  return (
    <Styled.LogbookWrap>
      <Breadcrumb data={breadcrumbData}></Breadcrumb>
      <div className="logbookButtons">
        <div className="logbookTitle">
          <Title header={'logs.logBook'} i18n={i18n} />
        </div>
        <div className="linkContainer commonLinks">
          {hasEnabledFeature('bulkDownloadEnabled') && (
            <Button
              className="buttonBulkDownload"
              type={'submit'}
              label={i18n.t('common.bulkDownloadReport')}
              onClick={() => setIsBulkDownloadClicked(true)}
            />
          )}
          <Link
            className={`uppercase ${logBook?.selectedDailyLog?.status !== 'SIGNED' ? 'logView': 'logEdit'}`}
            onClick={() => {
              history.push(
                logBook?.selectedDailyLog?.status !== 'SIGNED' ?
                  pageUrls.logsView.replace(
                  ':id',
                  logBook?.selectedDailyLog?.id
                ) :
                  pageUrls.logsEdit.replace(
                    ':id',
                    logBook?.selectedDailyLog?.idPub
                  )
              );
            }}
          >
            {logBook?.selectedDailyLog?.status !== 'SIGNED' ?
            i18n.t('common.view')
            : i18n.t('common.edit')}
          </Link>

          {/* <Link
            className="uppercase dailyDocument"
            // href={`${pageUrls.dailyDocuments}?driver=${
            //   logBook?.selectedDailyLog?.driverId
            // }&day=${moment(logBook?.selectedDailyLog?.day).format(
            //   UI_DATE_FORMAT
            // )}`}
          >
            {i18n.t('logs.viewDocument')}
          </Link> */}

          <Link
            className="uppercase history"
            onClick={() => {
              history.push(
                pageUrls.logsHistory.replace(
                  ':id',
                  logBook?.selectedDailyLog?.idPub
                )
              );
            }}
          >
            {i18n.t('common.history')}
          </Link>
        </div>
      </div>
      <DatePopup
        formId="bulkDownloadForm"
        isOpen={isBulkDownloadClicked}
        setIsOpen={setIsBulkDownloadClicked}
        exportHandler={handleLogBookStatus}
        apiStatus={apiStatus}
        clearApiStatus={clearApiStatus}
        data={logBook.violationsListDto}
        submit={dailyLogDownloadData}
        params={{ ...formValues }}
        noValidation
        callback={callback}
      />
      <Box style={{ ...theme.palette.contentStyle }}>
        <Component
          {...rest}
          theme={theme}
          i18n={i18n}
          history={history}
          apiStatus={apiStatus}
          viewApiId={viewApiId}
          drivers={drivers}
          dates={dates}
          logBook={logBook}
          currentDriver={currentDriver}
          fetchLogBookView={fetchLogBookView}
          getLogbookEvents={getLogbookEvents}
          updateShowEvents={updateShowEvents}
          fetchLogbookBreadcrumb={fetchLogbookBreadcrumb}
          fetchLogbookBreadcrumbApiId={fetchLogbookBreadcrumbApiId}
          logId={match.id}
          showMap={false}
          Styled={Styled}
          violationListing={true}
          getDocumentToken={getDocumentToken}
          unSignedDates={unSignedDates}
        />
      </Box>
    </Styled.LogbookWrap>
  );
};

export default LogBook;
LogBook.defaultTypes = {};
LogBook.propTypes = {
  history: PropTypes.object.isRequired,
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  logBook: PropTypes.object.isRequired,
  fetchLogBookView: PropTypes.func.isRequired,
  getLogbookEvents: PropTypes.func.isRequired,
  updateShowEvents: PropTypes.func.isRequired,
  handleLogBookStatus: PropTypes.func.isRequired,
  clearApiStatus: PropTypes.func.isRequired,
  fetchLogbookBreadcrumb: PropTypes.func.isRequired,
  breadCrumbForMap: PropTypes.array.isRequired
};
