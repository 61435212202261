import styled from 'styled-components';

const Wrapper = styled.div`
  ${({ theme }) => `
  .addressWrapper,
  .bottomWrapper {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
    .MuiBox-root {
      flex-direction: column;
    }
  }
  .addMore {
    border: 1px solid #ffffff;
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 7px;
    border-radius: 3px;
    height: 35px;
  }
  .registerButton {
    width: 100%;
    .MuiBox-root {
      width: 100%;
      .MuiButtonBase-root {
        width:100%;
        background-color: #1ea7db;
        color: #ffffff;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.6px;
        text-transform: uppercase;
        border-radius: 4px;
      }
    }
  }
#checkOutSubmit {
  color: #ffffff;
    font-size: 12px;
    font-weight: 700;
    -webkit-letter-spacing: 0.6px;
    -moz-letter-spacing: 0.6px;
    -ms-letter-spacing: 0.6px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    background: #1ea7db;
    padding: 12px 18px;
    border-radius: 4px;
    border: 0;
    text-align: left;
    min-width: 160px;
}
  .companyWrapper,
  .phoneWrapper {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flexbox;
    display: flex;
  }
  .active {
    color: ${theme.colors.PRIMARY_MAIN};
    border: 2px solid ${theme.colors.SECONDARY_MAIN};
  }
  .subBtnWrapper {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
    justify-content: center;
  }
  .vehicleCount {
    padding-bottom: 10%;
    padding-left: 20%;
    padding-right: 20%;
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flexbox;
    display: flex;
    justify-content: space-between;
    .MuiButtonBase-root {
      background: transparent;
      padding-left: 12px;
      padding-right: 12px;
    }
  }
  .countList {
    align-self: center;
  }
  .yearSub {
    font-weight: 500;
  }
  .paymentWrapper {
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.02);
    background: #20263a;
    border-radius: 3px;
    color: #fff;
    padding: 6% 8%;
    font-size: 20px;
    .setWrapper {
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
      .dim {
        opacity: 0.4;
      }
      .setupFee {
        font-weight: 500;
        opacity: 1;
      }
    }
    ul{
      font-size: 16px;
        font-weight: 400;
        margin: 0;
        padding: 0;
    }

    .calculator {
      list-style: none;
      border-bottom: 1px solid rgba(151, 151, 151, 0.22);
      padding-bottom: 14px;
      li {
        font-size: 16px;
        font-weight: 400;
        display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
        justify-content: space-between;
        padding-bottom: 4%;
        .count {
          font-weight: 500;
        }
      }
      .year {
        color: #1ea7db;
        font-size: 16px;
        font-weight: 300;
        padding-left: 5px;
        span {
          padding-right: 4px;
        }
      }
    }
    .totalCalculator {
      display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
      display: -ms-flexbox;  /* TWEENER - IE 10 */
      display: -webkit-flexbox;
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 500;
      padding-top: 20px;
      span:first-child {
        padding-top: 10px;
      }
      .amountTotal {
        color: #ffffff;
        font-size: 32px;
        font-weight: 700;
      }
    }
    .strikeOut {
      text-align: end;
      color: #ffffff;
      font-size: 26px;
      padding-top: 20px;
      font-weight: 300;
      .count {
        font-weight: 300;
        color: rgba(255, 255, 255, .6);
        position: relative;
        :before {
          background-color: #fff;
          content: "";
          height: 2px;
          left: 0;
          position: absolute;
          right: 0;
          top: 50%;
        }
      }
    }
    .yearly {
      color: rgba(255,255,255,.6);
    }
  }
  .count {
    padding-left: 5px;
    font-weight: 500;
  }

  .perYearWrapper,
    .vehicleCount {
      font-size: 16px;
      font-weight: 400;
    }
    .perYearWrapper {
      padding-bottom: 7%;
      text-align: left;
      display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
      display: -ms-flexbox;  /* TWEENER - IE 10 */
      display: -webkit-flexbox;
      display: flex;
      justify-content: space-between;
    }
    .yearly {
      position: relative;
      color: rgba(255,255,255,.5) !important;
        :before {
          background-color: #fff;
          content: "";
          height: 0.5px;
          left: 0;
          position: absolute;
          right: 0;
          top: 47%;
        }
    }
  .setupFee {
    // opacity: 0.5;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
  }
  .settepText {
    opacity: 0.5;
  }
  #shippingAddress {
    .subHeading {
      color: #ffffff;
      font-size: 20px;
      font-weight: 400;
      padding-bottom: 35px;
    }
  }

    .stateSelect label {
      padding-bottom: 7px;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        padding-bottom: 5px;
      }
    }
    .mainWrapper {
      display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
      display: -ms-flexbox;  /* TWEENER - IE 10 */
      display: -webkit-flexbox;
      display: flex;
      justify-content: space-between;
      @media (max-width: ${theme.breakpoints.values.md}px) {
        flex-direction: column;
      }
      .rightWrapper,
      .leftWrapper {
          flex: 0 0 48%;
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            /* IE10+ CSS styles go here */
            flex: 0 0 44%;
          }
        @media (max-width: ${theme.breakpoints.values.md}px) {
          flex: 0 0 100%;
        }
      }
    }
    .radioButtons {
      display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
      display: -ms-flexbox;  /* TWEENER - IE 10 */
      display: -webkit-flexbox;
      display: flex;
      padding-bottom: 3%;
      .MuiFormControlLabel-root {
        margin-right: 10px;
      }
      .checkbox-label {
        color: #dbdbdb;
        font-size: 14px !important;
        font-weight: 400;
        padding-top: 10px;
        span {
          font-weight: 600;
          color: #fff;
        }
      }
    }
    #checkOutSubmit {
      display:none;
    }
  `}
`;
const TextWrapper = styled.div`
  /* flex: 0 0 25%; */
`;

const FieldsWrapper = styled.div`
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  flex-direction: column;
  padding-right: 3%;
  flex: 0 0 50%;
`;

const FeeWrapper = styled.div`
  padding-left: 20%;
  padding-right: 20%;
`;

const SubWrapper = styled.div`
  ${({ theme }) => `
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
    text-align: center;
    .selectSub {
      flex: 0 0 100%;
      box-shadow: 0 3px 15px rgba(0, 0, 0, 0.02);
      background: #20263a;
      border-radius: 3px;
      color: #fff;
      padding: 6% 8%;
      margin-bottom: 6%;
    }
    .selectSubscription {
      color: #ffffff;
      font-size: 20px;
      font-weight: 500;
      padding-bottom: 20px;
    }
    .setupWrapper.monthlyFee {
      opacity: 1;
      .setupFee {
        opacity: 1 !important;
      }
    }
    .setupWrapper {
      // opacity: 0.5;
      color: #ffffff;
      font-size: 16px;
      font-weight: 300;
      display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
      display: -ms-flexbox;  /* TWEENER - IE 10 */
      display: -webkit-flexbox;
      display: flex;
      justify-content: space-between;
    }
    #subBtnWrapper button {
      border: none;
      color: #ffffff;
      background: transparent;
      font-size: 14px;
      font-weight: 400;
      border-radius: 4px;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      border-right: 0;
      height: 42px;
      width: 100%;
      top: -4px;
      margin-top: 0;
    }
    #subBtnWrapper .active {
      border: 2px solid #1ea7db;
      background-color: #1ea7db;
      border-radius: 4px;
    }
    .buttonToggle {
      display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
      display: -ms-flexbox;  /* TWEENER - IE 10 */
      display: -webkit-flexbox;
      display: flex;
      border: 1px solid #1ea7db;
      border-radius: 4px;
      margin-bottom: 42px;
      height: 43px;
      width: 85%;
      .MuiBox-root:first-child {
        width:50%;
      }
      .MuiBox-root:last-child {
        width:50%;
      }
    }

`}
`;

const ShippingWrapper = styled.div`
  ${({ theme }) => `
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flexbox;
    display: flex;
    flex-direction: column;
    padding-bottom: 35px;
    .subHeading {
      color: #ffffff;
      font-size: 20px;
      font-weight: 400;
      padding-top: 35px;
      padding-bottom: 35px;
    }
    .MuiFormControlLabel-label {
      color: #dbdbdb;
      font-size: 14px !important;
      font-weight: 400;
    }
`}
`;
const Styled = {
  Wrapper,
  TextWrapper,
  FieldsWrapper,
  FeeWrapper,
  SubWrapper,
  ShippingWrapper
};

export default Styled;
