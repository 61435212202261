import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
  ${({ theme }) => `
  
.MuiPaper-root{
  background-color: ${theme.colors.PAGINATION_BACKGROUND} !important
  }
  .MuiPaper-root .MuiIconButton-label .MuiSvgIcon-root {
    fill: ${window.$environment.WHITE_THEME ? '#000' : '#fff'};
  }
  .MuiPaper-root .MuiAccordionSummary-content h2 {
    font-size: 18px;
      text-transform: capitalize;
      color: ${window.$environment.WHITE_THEME ? '#000' : '#fff'} !important;
  }
  .MuiCollapse-wrapperInner {
    background-color: ${theme.colors.BODY_BACKGROUND} !important;
  }
  
  .MuiAccordionDetails-root {
    padding-top: 30px!important
  }
  .headingWrapper h2 {
      text-transform: inherit
  }
  .buttonStyle {
    margin-top: 30px
  }
  .buttonStyle .MuiBox-root  {
    margin-left: 15px
  }
    display: flex;
    flex-direction: column;
    .dvirForms {
      justify-content: flex-start;
      width: 100%;
      .vertical-button {
        .MuiBox-root:first-child {
          margin-right: 14px;
        }
        .MuiButtonBase-root {
          margin-top: 17px;
          padding-left: 0;
          padding-right: 0;
          .MuiButton-label {
            padding: 2px 22px;
          }
        }
      }
    }
    .saveForms {
      flex-direction: column;
    }
    .btn-wrapper {
      display: flex;
      width: 100%;
    }
    .leftWrapper{
      width: 16%;
      .dvir-btn-active {
        border: 2px solid ${theme.colors.PRIMARY_BORDER};
        border-right: 0;
      }
    }
    .rightWrapper {
      width: 84%;
      border: 2px solid ${theme.colors.PRIMARY_BORDER};
      border-left: 0;
      padding: 1%;
      .dvir-content-item-active {
        display: flex;
        .partdetails {
          width: auto;
          border: 1px solid ${theme.colors.LINK_COLOR};
          padding: 4px 8px;
          margin-bottom: 5px;
          display: flex;
          align-items: center;
          margin-right: 1%;
          
        }
        .normalCheckbox svg {
          width: 20px;
          height: 20px;
        }
        .MuiFormLabel-root {
          flex: 0 0 auto;
          padding-right: 10px;
          padding-bottom: 0;
          align-self: center;
          font-size: 16px;
          padding-bottom: 0 !important;
        }
        .roleWrapper {
          align-items: center;
          .Checkbox {
            top: 2px;
          }
          .MuiGrid-root {
            width: auto;
          }
          span {
            padding-left: 0px;
            padding-right: 4px;
            top: -6px;
            position: relative;
          }
        }
      }
    }
    .partsName {
      justify-content flex-start;
      width: 100%;
      border: 0;
      border-right: 2px solid ${theme.colors.PRIMARY_BORDER};
      color: ${theme.colors.PRIMARY_CONTRAST};
      padding: 10px;
      text-align: left;
      font-size: 20px;
      outline: none;
      text-transform: uppercase;
      cursor: pointer;
    }
    .saveChanges {
      padding-top: 1%;
    }
    .MuiButtonBase-root {
      margin-top: 0 !important;
    }
    .buttonWrapper {
      display: flex;
      justify-content: flex-start;
      padding-bottom: 2%;
      .advancedDVIR {
        margin-right: 1%;
      }
      button {
        margin-top: 0;
      }
    }
    .dvir-content-item-active {
      display: block;
    }
    .dvir-content-item {
      display: none;
    }
   .deleteParent h2 {
     text-align: center;
   }
  display: flex;
  flex-direction: column;
  .dvirForms {
    justify-content: flex-start;
    width: 100%;
    .vertical-button {
      .MuiBox-root:first-child {
        margin-right: 14px;
      }
      .MuiButtonBase-root {
        margin-top: 17px;
        padding-left: 0;
        padding-right: 0;
        .MuiButton-label {
          padding: 2px 22px;
        }
      }
    }
  }
  .saveForms {
    flex-direction: column;
  }
  .btn-wrapper {
    display: flex;
    width: 100%;
  }
  .leftWrapper{
    width: 16%;
    .dvir-btn-active {
      border: 2px solid ${theme.colors.PRIMARY_BORDER};
      border-right: 0;
    }
  }
  .rightWrapper {
    width: 84%;
    border: 2px solid ${theme.colors.PRIMARY_BORDER};
    border-left: 0;
    padding: 1%;
    .dvir-content-item-active {
      display: flex;
      .partdetails {
        width: auto;
        border: 1px solid ${theme.colors.LINK_COLOR};
        padding: 4px 8px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        margin-right: 1%;
        
      }
      .normalCheckbox svg {
        width: 20px;
        height: 20px;
      }
      .MuiFormLabel-root {
        flex: 0 0 auto;
        padding-right: 10px;
        padding-bottom: 0;
        align-self: center;
        font-size: 16px;
        padding-bottom: 0 !important;
      }
      .roleWrapper {
        align-items: center;
        .Checkbox {
          top: 2px;
        }
        .MuiGrid-root {
          width: auto;
        }
        span {
          padding-left: 0px;
          padding-right: 4px;
          top: -6px;
          position: relative;
        }
      }
    }
  }
  .partsName {
    justify-content flex-start;
    width: 100%;
    border: 0;
    border-right: 2px solid ${theme.colors.PRIMARY_BORDER};
    color: ${theme.colors.PRIMARY_CONTRAST};
    padding: 10px;
    text-align: left;
    font-size: 20px;
    outline: none;
    text-transform: uppercase;
    cursor: pointer;
  }
  .saveChanges {
    padding-top: 1%;
  }
  .MuiButtonBase-root {
    margin-top: 0 !important;
  }
  .buttonWrapper {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 2%;
    .advancedDVIR {
      margin-right: 1%;
    }
    button {
      margin-top: 0;
    }
  }
  .dvir-content-item-active {
    display: block;
  }
  .dvir-content-item {
    display: none;
  }

  .button-wrapper {
      display: flex;
  }
  `}
`;
const TopWrapper = styled(Box)`
  ${({ theme }) => `
  display: flex;
  justify-content: space-between;
  .exportContainer .MuiAvatar-root {
    width: 30px;
    height: 30px;
  }
  @media (max-width: ${theme.breakpoints.values.smd}px)  {
    flex-direction: column;
  }
  `}
`;

const AreasListkWrapper = styled(Box)`
  ${({ theme }) => `
  .MuiAccordionSummary-root{
    background: ${theme.colors.HEADER_COLOR} ;
  }
p{
  padding-bottom: 20px
}
h2
{
  color: ${window.$environment.WHITE_THEME ? '#000' : '#fff'}!important;
  font-weight: normal;

}
.MuiAccordionSummary-expandIcon {
  width: 10% !important;
  }
.MuiAccordionSummary-content .MuiInput-root {
  width: 66%

}

.addNewParts {
  min-width: 120px;
  color: ${theme.colors.LINK_COLOR};
  cursor: pointer;
  font-size: 18px!important;
  line-height: 38px;
  display: flex;
  align-items: center;
}
.defectsAccordion{
  margin-bottom: 20px;
  margin-left: 5%;
  width: 40%;
  .MuiPaper-root{
    border: 0px !important;
  }
}
.partsContainer{
  padding-bottom: 15px;
  padding-left: 10px;
  padding-top: 25px;
  box-shadow: 0px 3px 7px 0px ${theme.colors.HEADER_COLOR};
}
.defectsTitle {
  padding-bottom: 0px !important;
  background: ${theme.colors.HEADER_COLOR};
  height: 40px;
  margin-top: 0;
  align-items: center;
  display: flex;
  padding-left: 15px;
  font-size: 16px;
  color: ${theme.colors.LINK_COLOR};
}
.defectsWrapper{
  padding-left: 13px;
  display: flex;
  .MuiInput-root {
    width: 98%;
  }
  .iconContainer{
    padding-bottom: 18px;
  }
}
.MuiGrid-container {
  width: 25px;
  margin: 0 5px;
}
.MuiGrid-align-items-xs-center {
  justify-content: center;
}
.MuiInput-root {
  width: 60%
}
.partsText .MuiInput-root {
  width: 100%
}
.partsDelete {
  padding-bottom: 18px;
}
`}
`;

const AreaButtonkWrapper = styled(Box)`
  text-align: right;
  padding-bottom: 30px;
  .addNewArea {
    width: 13% !important;
  }
  .button-wrapper{
    display: flex;
  }
`;

const leftSection = styled.div`
  ${({ theme }) => `
  flex: 0 0 45%;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  .values {
    color: ${theme.colors.PRIMARY_CONTRAST};
  }
  .checkboxesCompany{
    display: flex;
    .agreeTerms{
      flex: 0 0 40%;
    }
  }
  .valueWrapper{
    min-height: 39px;
    .MuiFormLabel-root{
      padding-right: 18px;
      line-height: 1.3;
    }
  }
`}
`;
const rightSection = styled.div`
  ${({ theme }) => `
  flex: 0 0 45%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;  
 
`}
`;
const AreaWrapper = styled.div`
${({ theme }) => `
.MuiFormGroup-root{
  .MuiFormControlLabel-root .MuiFormControlLabel-label{
    padding-top: 0px !important;
  }
}
.button-wrapper{
  display: flex;
  justify-content: flex-end;
}
`}
`;
const Styled = {
  Wrapper,
  TopWrapper,
  AreaButtonkWrapper,
  AreasListkWrapper,
  leftSection,
  rightSection,
  AreaWrapper
};

export default Styled;
