import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
.bulkDownload button,
.advancedDVIR button {
    padding: 0 12px !important;
}
.advancedDVIR {
    display: none;
}
.viewReport{
    color: ${theme.colors.SECONDARY_MAIN};
}
.datepopup {
    position: absolute !important;
    right: 10px;
    top: 43px;
    .vertical-button button .MuiButton-label {
        padding: 0 20px !important;
    }
}
.vertical {
    position: relative;
    .MuiSvgIcon-root {
        position: absolute;
        right: 0;
        width: 18px;
        height: 18px;
        color: #767676;
        bottom: 7px;
    }
}
.buttonWrapper {
    padding-bottom: 0;
    padding-right: 10px;
}
.CSAForms {
    padding-left: 14px;
    .vertical:first-child {
        width: 19%;
    }
    .vertical:nth-child(2){
        width: 15%;
    }
    .vertical:nth-child(3),
    .vertical:nth-child(4){
        width: 11%;
    }
}
`}
`;
const TopWrapper = styled(Parent.TopWrapper)`
 
`;
const TableWrapper = styled(Parent.TableWrapper)`
${({ theme }) => `
padding: 0;
.MuiTable-root .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:nth-child(5) a {
    color: ${theme.colors.SECONDARY_MAIN};
    cursor: pointer;
}
`}
`;
const Styled = { Wrapper, TopWrapper, TableWrapper };

export default Styled;
