import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
${({ theme }) => `
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  justify-content: space-between;
  padding-top: 4%;
  @media (max-width: ${theme.breakpoints.values.lg}px) {
    flex-direction: column;
  }
  form {
    width: 100%;
    .MuiTypography-body1 {
      font-size: 18px;
    }
  }
 
  ul {
    border: 2px solid
    ${(theme.palette.primary || {}).border || theme.colors.LIGHT_BORDER};
    padding-left: 0;
    li {
      border-bottom: 0.5px solid ${theme.palette.background.default};
    }
  }
`}
`;
const LeftWrapper = styled(Box)`
${({ theme }) => `
  flex: 0 0 18%;
  .userHeading {
    margin-top: 0;
  }
  .active-region {
    background-color: ${theme.colors.PRIMARY_BORDER};
  }
  .subheaderSection {
    padding: 11px 4%;
    text-transform: uppercase;
    margin-bottom: 15px;
  }
  .normalCheckbox {
    padding-right: 9px;
    .Checkbox {
      top: 3px;
    }
    span {
      font-size: 18px;
      padding-left: 12px;
    }
  }
  .driverCheckbox {
    padding-left: 25%;
    width: 100%;
    .normalCheckbox {
      margin: 15px 2% 15px 0;
      width: 100%;
      span {
        width: 100%;
      }
    }
  }
  @media (max-width: ${theme.breakpoints.values.lg}px) {
    flex: 0 0 100%;
  }
  `}
`;
const RightWrapper = styled(Box)`
${({ theme }) => `
  flex: 0 0 80%;
  .documentsHeader {
    @media (min-width: ${theme.breakpoints.values.lg}px) {
      margin-top: 0;
    }
  }
  .subheaderSection {
    padding: 11px 2%;
    margin-bottom: 15px;
  }
  @media (max-width: ${theme.breakpoints.values.md}px) {
    flex: 0 0 100%;
  }
  `}
`;
const RegionListWrapper = styled(Box)``;
const TerminalListWrapper = styled(Box)`

`;

const RegionWrapper = styled(Box)`
${({ theme }) => `
  background-color: ${theme.colors.PRIMARY_MAIN};
  color: ${theme.colors.PRIMARY_CONTRAST};
  font-size: 18px;
  border-bottom: 1px solid ${theme.colors.PRIMARY_BORDER};
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  width: 100%;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
  :hover {
    background-color: ${theme.colors.PRIMARY_BORDER};
  }
  
  .region {
    width: 56%;
    text-transform: capitalize;
  }
  .iconSection {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flexbox;
    display: flex; 
    justify-content: flex-end;
    width: 44%;
    .iconContainer {
      padding-right: 7px;
      width: auto;
    }
  }
  `}
`;
const TerminalWrapper = styled(Box)`
${({ theme }) => `
.Nodata {
  margin-top: 5%;
  color: ${theme.colors.PRIMARY_CONTRAST} !important;
  text-align: center;
  font-size: 20px;
}
.MuiIconButton-root {
  border-radius:0;
}
`}
`;
const Department = styled(Box)`
${({ theme }) => `
position: relative;
// padding: 3%;
.AddButton {
  position: absolute;
  right: 0;
  width: auto;
  .MuiAvatar-root {
    width: 30px;
    height: 30px;
  }
}
`}
`;
const Styled = {
  Wrapper,
  LeftWrapper,
  RightWrapper,
  RegionListWrapper,
  TerminalListWrapper,
  RegionWrapper,
  TerminalWrapper,
  Department
};
export default Styled;
