import React from 'react';

const SubHeader = ({ text, className, Container, ...rest }) => {
  return (
    <Container className={className || ''} {...rest}>
      {text}
    </Container>
  );
};

export default SubHeader;
