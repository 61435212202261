import React, { useEffect, lazy } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useHistory } from 'react-router-dom';
import useTheme from '@material-ui/core/styles/useTheme';
import { useTranslation } from 'react-i18next';

import { getNewObject, loadPageConfig } from '@/utils/utils';
const config = loadPageConfig();

const Title = lazy(() => import(`@/${config.profile[0].name}`));
const Content = lazy(() => import(`@/${config.profile[1].name}`));
const Profile = (parentProps) => {
  const { fetchProfile } = parentProps;

  const history = useHistory();
  const theme = useTheme();
  const i18n = useTranslation();
  const commonParentProps = { history, i18n, theme, isEditable: true };

  const apiId = 'fetchProfile';

  useEffect(() => {
    fetchProfile({
      apiId
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <RenderComponent
        {...config.profile[0]}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        component={Title}
      />
      <RenderComponent
        {...config.profile[1]}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        component={Content}
      />
    </>
  );
};

const RenderComponent = ({
  name,
  props,
  commonProps,
  title,
  parentProps,
  commonParentProps,
  component,
  ...rest
}) => {
  const Component = component;
  let newProps = props?.length ? getNewObject(parentProps, props) : {};
  newProps = {
    ...newProps,
    ...(commonProps?.length ? getNewObject(commonParentProps, commonProps) : {})
  };

  return <Component {...rest} {...newProps} />;
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    profile: state.profile
  };
};
const mapDispatch = (dispatch) => {
  return {
    updateProfile: (data) => dispatch.profile.updateData(data),
    generateMfaQrCode: (data) => dispatch.profile.generateMfaQrCode(data),
    validateMfaSetup: (data) => dispatch.profile.validateMfaSetup(data),
    modifyMfaSettings: (data) => dispatch.profile.modifyMfaSettings(data),
    fetchProfile: (data) => dispatch.profile.fetchData(data),
    clearApiStatus: () => dispatch.asyncRequests.clearApiStatus(),
    getCsvStatusHistory:(data)=> dispatch.profile.getCsvStatusHistory(data),
    generateMfaQrCode:(data)=> dispatch.profile.generateMfaQrCode(data),
    validateMfaSetup:(data)=> dispatch.profile.validateMfaSetup(data),
    modifyMfaSettings:(data)=> dispatch.profile.modifyMfaSettings(data),
  };
};

export default connect(mapState, mapDispatch)(Profile);

Profile.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  Profile: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  fetchProfile: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  generateMfaQrCode: PropTypes.func.isRequired,
  validateMfaSetup: PropTypes.func.isRequired,
  modifyMfaSettings:PropTypes.func.isRequired,
};


