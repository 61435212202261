import * as apis from '..';
const localApis = {
  customDvirFormsList: {
    url: '/inspections/customdvir/list',
    type: 'get'
  },
  deleteCustomDvir: {
    url: '/inspections/customdvir/delete',
    type: 'delete',
   isQueryString: true
  },
  enableCustomDvir: {
    url: '/inspections/customdvir/enable',
    type: 'post',
   isQueryString: true,
  }
  ,getCustomDvirForm :{
    url: 'inspections/customdvir/form',
    type: 'get',
    isQueryString: true,
  },postCustomDvirForm :{
    url: 'inspections/customdvir/form',
    type: 'post',
  },
  listAllVehicles :{
    url: 'vehicle/all',
    type: 'get',
  },
  listAllTrailers: {
    url: 'trailer/all  ',
    type: 'get',
  }
};
const getApi = (api) => {
  return localApis[api] || apis[api];
};
export default getApi;
