import RenderComponent from '@/components/modules/DynamicRenderPage';
import {
  loadPageConfig, loadStyle,
  objectArrayFromSameField
} from '@/utils/utils';
import PropTypes from 'prop-types';
import React, { lazy, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import ParentStyled from '../CompanyManagement/style';

let Styled = loadStyle(ParentStyled, 'pages/web/CompanyData');
const layout = window.$environment?.CURRENT_LAYOUT;
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));

const ScoreManagement = lazy(() => import(`@/pages/web/CompanyManagement/ScoresManagement/index`))

const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.companiesEdit[0].name}`));
const Modal = require(`@/components/UI/Modal/${layout}`).default;
const Content = lazy(() => import(`@/${config.companiesEdit[1].name}`));
const TopActions =
  config.companiesEdit[0].children &&
  lazy(() => import(`${config.companiesEdit[0].children.name}`));

const EditCompany = (parentProps) => {
  const { companyData, getCompanyData, history, i18n, theme, module, updateScores, getScores, callback } = parentProps;
  const commonParentProps = { history, i18n, theme };
  const [modalObject, setModalObject] = useState({
    header: '',
    content: () => {
      return <> </>;
    }
  });
  const [modalOpen, setModalOpen] = useState(false);
  const match = useParams();
  const stateToDisplay = objectArrayFromSameField(companyData?.states);
  const allCycleRules = objectArrayFromSameField(companyData?.allCycleRules);
  const allCycleExceptions = objectArrayFromSameField(
    companyData?.allCycleExceptions
  );
  const allVehicleTypes = objectArrayFromSameField(
    companyData?.allVehicleTypes
  );
  useEffect(() => {
    getCompanyData({
      apiId: 'getCompanyData',
      type: 'edit',
      data: {
        companyId: match.id,
        error: ''
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const TitleComponent = () => <Styled.TitleSection >
    <RenderComponent
      {...config[module][0]}
      parentProps={{
        ...parentProps
      }}
      commonParentProps={commonParentProps}
      component={Title}
      childComponent={TopActions}
    />
    {/* 
    FLEETMG-17265 // hide scorecard
    <Button
      id="companyScoresSubmit"
      type="submit"
      label={'Update Scores'}
      onClick={() => {
        getScores({
          apiId: 'getScores',
          data: match.id,
          callback: (res) => {
            setModalObject({
              header: '',
              content: () => {
                return <ScoreManagement
                  handleCancel={() => setModalOpen(false)}
                  companyId={match.id}
                  updateScores={(data) => {
                    updateScores({
                      ...data,
                      callback: () => {
                        setModalOpen(false)
                        callback('success', 'Scores updated successfully')
                      }
                    })
                  }}
                  scoreValues={res}
                />
              }
            })
            setModalOpen(true)
          }
        })
      }}
    /> */}
  </Styled.TitleSection>

  return (
    <>
      <TitleComponent />
      <RenderComponent
        {...config[module][1]}
        parentProps={{
          ...parentProps,
          companyData: companyData,
          states: stateToDisplay,
          allCycleRules,
          allCycleExceptions,
          allVehicleTypes
        }}
        commonParentProps={commonParentProps}
        component={Content}
      />
      <Modal
        open={modalOpen}
        setOpen={setModalOpen}
        data={modalObject}
        width={'850px'}
        withCloseIcon
        alignItemsLeft
        removeTopSpace
      />
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    companyData: state.fleetCompanyList.data
  };
};
const mapDispatch = (dispatch) => {
  return {
    getCompanyData: (data) => dispatch.fleetCompanyList.fetchCompanyData(data),
    saveCompanyData: (data) => dispatch.fleetCompanyList.saveData(data),
    updateScores: (data) => dispatch.fleetCompanyList.updateScores(data),
    getScores: (data) => dispatch.fleetCompanyList.fetchScores(data),
  };
};

export default connect(mapState, mapDispatch)(EditCompany);

EditCompany.propTypes = {
  login: PropTypes.object.isRequired,
  apiStatus: PropTypes.object.isRequired,
  fetchLogin: PropTypes.func.isRequired
};
