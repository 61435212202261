import React, { useState, lazy } from 'react';
import moment from 'moment';

import { imageUrls } from '../../../config/constants/keys';
import { getImagePath } from '../../../utils/utils';

const layout = window?.$environment?.CURRENT_LAYOUT;

const Button = lazy(() => import(`../../../components/UI/Button/${layout}`));
const Link = lazy(() => import(`../../../components/UI/Link`));
const Icon = lazy(() => import(`../../../components/UI/Icon/${layout}`));
const Table = lazy(() => import(`../../../components/UI/Table/${layout}`));
const Modal = require(`../../../components/UI/Modal/${layout}`);
const DeveloperRequest = ({
  list,
  handleDeletePermissionRequest,
  handleAcceptPermissionRequest,
  callback,
  i18n
}) => {
  const [ModalListObject, setModalListObject] = useState({
    header: '',
    content: () => {}
  });
  const [open, setOpen] = useState(false);
  const closeModal = (event) => {
    setOpen(false);
  };
  const clickedModal = (e) => {
    setOpen(true);
  };

  const columns = [
    {
      id: 'description',
      disablePadding: false,
      label: i18n.t('common.description'),
      needSorting: true
    },
    {
      id: 'date',
      disablePadding: false,
      label: i18n.t('common.date'),
      needSorting: true
    },
    {
      id: 'phone',
      disablePadding: false,
      label: i18n.t('common.phone'),
      needSorting: true
    },
    { id: 'actions' }
  ];
  const generateRows = () => {
    const updatedList = list?.map((item) => {
      for (let i = 0; i < columns.length; i++) {
        if (columns[i].id === 'description') {
          item.description = item.url ? (
            <Link href={window.location.origin + item.url.replace('.', '')}>
              {item.description}
            </Link>
          ) : (
            item.description
          );
          item.date = item.date
            ? moment(item.date).format('MM/DD/YYYY hh:mm:ss')
            : '';
        }
        if(columns[i].id === 'date') {
          item.date = item.date ? moment(item.date).format("MM/DD/YYYY hh:mm:ss") : '';
        }
      }

      return item;
    });
    return updatedList;
  };
  const information = i18n.t('common.tableEmptyMessage');
  return (
    <>
      <Table
        type="client"
        defaultSortBy={{ sortBy: 'date', order: 'desc' }}
        title=""
        columns={[...columns]}
        data={generateRows()}
        emptyMessage={information}
        itemsPerPage={10}
        noPagination
        actions={[
          {
            icon: (row, index) => {
              return (
                <Icon
                  src={getImagePath(imageUrls.tickIcon)}
                  onClick={() => {
                    setModalListObject({
                      content: () => (
                        <div className="deleteParent">
                          <div className="imageSection">
                            <img
                              src={getImagePath(imageUrls.confirmIcon)}
                              alt="Are you sure"
                            />
                          </div>
                          <h2> {i18n.t('common.sureConfirm')} </h2>
                          <div className="deletePopupBtn">
                            <div className="cancelBtn">
                              <Button
                                label={i18n.t('common.cancel')}
                                type={'reset'}
                                onClick={() => {
                                  closeModal();
                                }}
                              ></Button>
                            </div>
                            <div className="deletebtn">
                              <Button
                                label={i18n.t('common.acceptPopup')}
                                onClick={() => {
                                  handleAcceptPermissionRequest({
                                    apiId: 'acceptPermissionRequest',
                                    data: {
                                      data: { id: row?.id }
                                    },
                                    callback
                                  });
                                  closeModal();
                                }}
                              ></Button>
                            </div>
                          </div>
                        </div>
                      )
                    });
                    clickedModal();
                  }}
                />
              );
            }
          },
          {
            icon: (row, index) => {
              return (
                <Icon
                  src={getImagePath(imageUrls.deleteIcon)}
                  onClick={() => {
                    setModalListObject({
                      content: () => (
                        <div className="deleteParent">
                          <div className="imageSection">
                            <img
                              src={getImagePath(imageUrls.confirmIcon)}
                              alt="Are you sure"
                            />
                          </div>
                          <h2> {i18n.t('common.sureConfirm')} </h2>
                          <div className="deletePopupBtn">
                            <div className="cancelBtn">
                              <Button
                                label={i18n.t('common.cancel')}
                                type={'reset'}
                                onClick={() => {
                                  closeModal();
                                }}
                              ></Button>
                            </div>
                            <div className="deletebtn">
                              <Button
                                label={i18n.t('common.deletePopup')}
                                onClick={() => {
                                  handleDeletePermissionRequest({
                                    apiId: 'deleteAlert',
                                    data: {
                                      data: { id: row?.id }
                                    },
                                    callback
                                  });
                                  closeModal();
                                }}
                              ></Button>
                            </div>
                          </div>
                        </div>
                      )
                    });
                    clickedModal();
                  }}
                />
              );
            }
          }
        ]}
        i18n={i18n}
      />
      <Modal.default
        open={open}
        setOpen={setOpen}
        data={ModalListObject}
        deletePopup
      />
    </>
  );
};

export default DeveloperRequest;

DeveloperRequest.defaultProps = {};
