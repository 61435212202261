import React, { useState, useEffect, lazy } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import RenderComponent from './RenderComponent';

import { objectArrayFromDiffFields, getLastQuaters } from '@/utils/utils';
import { UI_DATE_FORMAT } from '@/config/constants/static';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));

const ViewForm = ({
  hasApiStatus,
  callback,
  vehicles,
  fetchIftaMileage,
  iftaApiId,
  handleExportIfta,
  tableData,
  history,
  fetchReport,
  commonProps,
  isOldView,
  i18n
}) => {
  const activeVehicles = objectArrayFromDiffFields(
    vehicles?.vehicles,
    'id',
    'unit_id'
  );
  const deActivatedVehicles = objectArrayFromDiffFields(
    vehicles?.deactivatedVehicles,
    'id',
    'unit_id'
  );
  useEffect(() => {
    activeVehicles?.length &&
      activeVehicles.unshift({ label: i18n.t('users.All'), value: '' });
    deActivatedVehicles?.length &&
      deActivatedVehicles.unshift({ label: i18n.t('users.All'), value: '' });
  }, [activeVehicles, deActivatedVehicles]);
  const lastQuater = getLastQuaters(true)
  const [initialValues] = useState({
    id: { label: i18n.t('users.All'), value: '' },
    vehicleType: 'active',
    fromOdometer: 'true',
    dateRange: [lastQuater[0].format(UI_DATE_FORMAT), lastQuater[1].format(UI_DATE_FORMAT)],
    dateTimeFrom: lastQuater[0].format(UI_DATE_FORMAT),
    dateTimeTo: lastQuater[1].format(UI_DATE_FORMAT),
    hideStates: true
  });
  return (
    <>
      <Form
        type="server"
        direction="row"
        initialValues={{ ...initialValues }}
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {
          fetchIftaMileage({
            apiId: iftaApiId,
            data: {
              id: fields.vehicleType == "inactive" && fields.id.label == 'All' ? '0' : fields.id.value,
              fromOdometer: fields.fromOdometer,
              dateTimeFrom: fields.dateTimeFrom?.replace(
                /(\d\d)\/(\d\d)\/(\d{4})/,
                '$3-$1-$2'
              ),

              dateTimeTo: fields.dateTimeTo?.replace(
                /(\d\d)\/(\d\d)\/(\d{4})/,
                '$3-$1-$2'
              )
            }
          });
          tableData(fields);
        }}
        handleOnCancel={(fields) => { }}
        customProps={{
          callback,
          hasApiStatus,
          activeVehicles,
          deActivatedVehicles,
          handleExportIfta,
          fetchReport,
          commonProps,
          isOldView,
          history
        }}
      />
    </>
  );
};

ViewForm.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired
};

export default ViewForm;
