import {
  getDailyLogDetailsApi,
  updateDailyLogDetailsApi,
  providerUpdateDailyLogDetailsApi,
  viewDailyLogDetailsApi,
  getUdDailyLogDetailsApi,
  getUdProfileDailyLogDetailsApi
} from '../../../../config/apis';
import { pageUrls } from '@/config/constants/keys';

const initialState = {
  finalData: {},
  originalData: {}
};

export const dailyLogDetails = {
  state: { ...initialState },
  reducers: {
    setDailyLogEntries(state, payload) {
      if (payload.asArray) {
        return {
          ...state,
          finalData: {
            ...state.finalData,
            dailyLogEntries: [...payload.data]
          }
        };
      } else {
        const data = state?.finalData?.dailyLogEntries;
        if (data[payload.index]) {
          data[payload.index][payload.field] = payload.value;
        }
        return {
          ...state,
          finalData: {
            ...state.finalData,
            dailyLogEntries: [...data]
          }
        };
      }
    },
    setFetchSuccess(state, payload) {
      return { ...state, ...payload };
    },
    setFetchFailed() {
      return { ...initialState };
    },
    clearUpdateData(state) {
      const data = getFormattedData(state.originalData);
      return { ...state, finalData: { ...data } };
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload) {
      try {
        const apiObject = payload.udEdit
          ? window.location.href.includes('/0/')
            ? getUdDailyLogDetailsApi
            : getUdProfileDailyLogDetailsApi
          : getDailyLogDetailsApi;
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...apiObject,
              url: apiObject.url + payload.data.id + (payload.providerMode ? '?providerMode=true' : '')
            }
          },
          callback: (type, message) => {
            payload.callback(
              type,
              message,
              () => payload.history && payload.history.push(pageUrls.logsList)
            );
          },
          id: payload.apiId
        });
        if (response?.dailyLog?.dailyLogEntries?.length) {
          response.dailyLog.dailyLogEntries.forEach(ele => {
            ele.eldData = ele.readOnly && ele.status == 'DRIVING'
          })
        }
        const finalData = getFormattedData(response, payload.udEdit);
        const sanitizedLogEntries = sanitizeDailyLogEntries(finalData.dailyLogEntries)
        finalData.dailyLogEntries = finalData.dailyLogEntries.length
          ? [...sanitizedLogEntries]
          : [
            {
              annotation: null,
              coDriverId: null,
              driverFullName: null,
              driverId: null,
              eldData: false,
              id: 0,
              isAOBRDEdit: false,
              oilFieldWaiting: false,
              unIdentifiedLogStatus: 'UNASSIGNED',
              time_from: '12:00am',
              time_to: '12:00am',
              status: 'OFF_DUTY',
              note: '',
              stateModifier: '',
              location: ''
            }
          ];
        const compareItem = formattedData(response.dailyLog?.dailyLogEntries);
        compareItem.dailyLogEntries = compareItem?.dailyLogEntries.length
          ? [...compareItem.dailyLogEntries]
          : [
            {
              annotation: '',
              coDriverId: '',
              driverFullName: '',
              driverId: '',
              eldData: false,
              id: 0,
              isAOBRDEdit: false,
              oilFieldWaiting: false,
              unIdentifiedLogStatus: 'UNASSIGNED',
              time_from: '12:00am',
              time_to: '12:00am',
              status: 'OFF_DUTY',
              note: '',
              stateModifier: '',
              location: ''
            }
          ];
        this.setFetchSuccess({ finalData, originalData: response, compareItem });
      } catch (error) {
        this.setFetchFailed();
      }
    },

    async updateData(payload) {
      try {
        let apiObject = payload.providerMode ? providerUpdateDailyLogDetailsApi : updateDailyLogDetailsApi;
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...apiObject,
              url: apiObject.url + payload.data.id
            },
            data: payload.data.data
          },
          id: payload.apiId
        });
        payload.callback && payload.callback();
      } catch (error) {
        this.fetchData({
          data: { id: payload.data.id },
          udEdit: payload.udEdit,
          apiId: 'fetchDailyLogDetails'
        });
      }
    },
    async fetchViewData(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...viewDailyLogDetailsApi,
              url: viewDailyLogDetailsApi.url + payload.data.id
            }
          },
          id: payload.apiId
        });
        const finalData = viewFormattedData(response);
        finalData.dailyLogEntries = finalData.dailyLogEntries.length
          ? [...finalData.dailyLogEntries]
          : [
            {
              annotation: null,
              coDriverId: null,
              driverFullName: null,
              driverId: null,
              eldData: false,
              id: 0,
              isAOBRDEdit: false,
              oilFieldWaiting: false,
              unIdentifiedLogStatus: 'UNASSIGNED',
              time_from: '12:00am',
              time_to: '12:00am',
              status: 'OFF_DUTY',
              note: '',
              stateModifier: '',
              location: ''
            }
          ];
        const compareItem = formattedData(response.dailyLogEditDto.dailyLogEntries);
        compareItem.dailyLogEntries = compareItem.dailyLogEntries.length
          ? [...compareItem.dailyLogEntries]
          : [
            {
              annotation: '',
              coDriverId: '',
              driverFullName: '',
              driverId: '',
              eldData: false,
              id: 0,
              isAOBRDEdit: false,
              oilFieldWaiting: false,
              unIdentifiedLogStatus: 'UNASSIGNED',
              time_from: '12:00am',
              time_to: '12:00am',
              status: 'OFF_DUTY',
              note: '',
              stateModifier: '',
              location: ''
            }
          ];

        this.setFetchSuccess({
          finalData,
          originalData: renameKey(response, 'dailyLogEditDto', 'dailyLog'),
          compareItem
        });
      } catch (error) {
        this.setFetchFailed();
      }
    }
  })
};

const getFormattedData = (response, udEdit) => {
  const finalData = response.dailyLog || {};
  return finalData;
};

const sanitizeDailyLogEntries = (dailyLogs) => {
  if (dailyLogs.length) {
    dailyLogs.forEach((item, idx) => {
      if (item.minutes_from === 0 && item.minutes_to === 1440 && idx < dailyLogs.length - 1) {
        item.minutes_to = 0;
      }
    });
    return dailyLogs
  } else {
    return []
  }
}
const viewFormattedData = (response) => {
  const dailyLogEntries = response.dailyLogEditDto.dailyLogEntries.map(
    (item) => item
  );
  const finalData = response.dailyLogEditDto || {};
  finalData.dailyLogEntries = [...dailyLogEntries];
  return finalData;
};

const formattedData = (response) => {
  const dailyLogEntries = response.map(
    (item) => {
      for (var key of Object.keys(item)) {
        item[key] = item[key] !== null ? item[key] : ''
      }
      return item
    }
  );
  const finalData = response.dailyLogEditDto || {};
  finalData.dailyLogEntries = [...dailyLogEntries];
  return finalData;
};

const renameKey = (object, key, newKey) => {
  const clonedObj = clone(object);

  const targetKey = clonedObj[key];

  delete clonedObj[key];

  clonedObj[newKey] = targetKey;

  return clonedObj;
};

const clone = (obj) => Object.assign({}, obj);
