import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
position relative;
.formLogin{
    padding-left: 20px;
    border-top: 2px solid #000;
    padding-top: 20px;
}
.titlePolicy {
    display: block;
    width: auto;
    position: absolute;
    font-size: 15px !important
    padding: 0 !important;
    margin: 0 !important;
    color: #42505a !important;
    font-weight: bold;
    background: transparent !important;
}
.customPolicy {
    display: block;
    width: auto;
    font-size: 15px !important
    padding: 0 !important;
    margin: 0 !important;
    color: #42505a !important;
    font-weight: bold;
    background: transparent !important;
}
.selectpolicy{
    top: 164px;
}
.policyLine {
    justify-content: flex-start;
    padding: 0;
    padding-left: 14%;
    .policyText {
        color: #222;
    }
    .policyText p:first-child {
        font-size: 12px;
        font-weight: bold;
    }
    .policyText p:last-child {
        color: #777777;
        font-size: 14px;
    }
}
.selectedCheckbox {
    flex-direction: column;
    padding-left: 14%;
    padding-top: 0;
}
.policySubmit {
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
}
#submitPolicy {
    min-width: 100px;
    margin-bottom: 50px;
    margin-top: 0;
    padding: 5px 15px !important;
}

`;

const Styled = { Wrapper };

export default Styled;
