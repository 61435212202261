import React from 'react';
import PropTypes from 'prop-types';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ParentStyled from './style';
import { loadStyle } from '../../utils/utils';
let Styled = loadStyle(ParentStyled, 'components/Accordion');

const AccordionComponent = ({ data }) => {
  return (
    <Styled.Wrapper>
      {data.map((templateItem) => {
        const {
          name: header,
          value: Content,
          id,
          defaultExpanded,
          disabled,
          component : Component,
        } = templateItem;

        return (
          <Accordion
            key={id}
            // TransitionProps={{ unmountOnExit: true }}
            defaultExpanded={defaultExpanded || false}
            disabled={disabled || false}
          >
            <AccordionSummary
              expandIcon={disabled ? null : <ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {
Component ?<Component /> : 
<Styled.Header variant="h2">{header}</Styled.Header>

              }
            </AccordionSummary>
            <AccordionDetails>
              <Styled.Content>{typeof (Content) === 'object'? Content : <Content />}</Styled.Content>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Styled.Wrapper>
  );
};

export default AccordionComponent;
AccordionComponent.defaultProps = {
  color: 'primary',
  defaultExpanded: false,
  disabled: false,
  positonChange: false
};

AccordionComponent.propTypes = {
  data: PropTypes.array.isRequired,
  color: PropTypes.string,
  // Styled: PropTypes.object.isRequired,
  defaultExpanded: PropTypes.bool,
  disabled: PropTypes.bool,
  positonChange: PropTypes.bool
};
