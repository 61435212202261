/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, lazy } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import RenderComponent from './RenderComponent';

import { hasValue } from '../../../../../utils/utils';
import { phoneRegExp } from '../../../../../utils/constants';
import Modal from '../../../../../components/UI/Modal/Clutch';
import Button from '../../../../../components/UI/Button/Clutch';
// import Styled from '../../Login/style';
import ErrorIcon from '../../../../../components/ErrorIcon/Clutch';
import SuccessIcon from '../../../../../components/SuccessIcon/Clutch';
import { pageUrls } from '../../../../../config/constants/keys';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`../../../../../components/UI/Form/${layout}`));

const CheckoutForm = ({
  history,
  apiStatus,
  clearApiStatus,
  addCompanyInfo,
  states,
  getCompanyInfoApi,
  getCompanyInfo,
  getFleetManagerInfo,
  addCheckout,
  differentAddress,
  totalBill,
  subFilterOption,
  checkoutDetails,
  callback,
  companyInfo
}) => {
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    if (companyInfo.checkout.status) {
      setModalListObject2({
        content: () => (
          <div className="successpopup">
            <h2>
              {' '}
              {companyInfo.checkout.status === 200
                ? 'Registration Completed'
                : 'Something Went Wrong!'}{' '}
            </h2>
            {companyInfo.checkout.status !== 200 ? (
              <ErrorIcon />
            ) : (
              <SuccessIcon />
            )}
            <label>
              {' '}
              {companyInfo.checkout.status === 200
                ? `Thank you, your registration with Clutch ELD is successfully completed.
               Click the button below to proceed to payment page.`
                : 'Please try again later'}{' '}
            </label>
            <div className="deletePopupBtn">
              <Button
                type="submit"
                label={
                  companyInfo.checkout.status === 200
                    ? i18n.t('Proceed to Pay')
                    : i18n.t('common.ok')
                }
                onClick={() => {
                  if (companyInfo.checkout.status === 200) {
                    // window.location = 'https://subscriptions.zoho.com/hostedpage/2-afcf3bb963fa6357e31648c68403fa5afa1b3bbdbcf7b8cd7b7dba753840b17b46044f745b0631e046b1d3085a933452/checkout';
                    window.location = companyInfo.checkout.message;
                    // history.push(companyInfo.checkout.message)
                  }
                  // checkoutProceesClick(companyInfo.checkout.status);

                  closeModalSuccess();
                }}
                className="proceedLogin"
              />
            </div>
          </div>
        )
      });
      openModelSuccess();
    }
  }, [companyInfo.checkout]);
  useEffect(() => {
    if (companyInfo?.checkout?.status === 200) window.onbeforeunload = null;
    else window.onbeforeunload = closeIt;
  }, [companyInfo?.checkout?.status]);
  const closeIt = () => {
    return 'Are you sure you want to leave?';
  };
  const modalRef2 = useRef();
  const closeModalSuccess = (event) => {
    // modalRef2.current.handleClose1(event);
    setOpen(false);
  };
  const openModelSuccess = (e) => {
    // modalRef2.current.handleOpen(e);
    setOpen(true);
  };
  const [ModalListObject2, setModalListObject2] = useState({
    header: '',
    content: ''
  });
  // const checkoutProceesClick = (status) => {
  //   //console.log('change this condition)
  //   if (status === 200) {
  //     history.push(pageUrls.login);
  //     closeModalSuccess();
  //   } else {
  //     closeModalSuccess();
  //   }
  // };
  const { i18n } = useTranslation();

  const apiId = 'addCompany';
  const hasApiStatus = hasValue(apiStatus.status, 'api', apiId);
  const handleDiffSubmit = (fields) => {
    if (getCompanyInfoApi?.companyId) {
      fields.companyId = getCompanyInfoApi?.companyId;
      fields.userId = getFleetManagerInfo?.userId;
    }
    fields.recipentName = fields.recipentName
      ? fields.recipentName
      : getCompanyInfoApi?.companyName;
    fields.address = fields.address
      ? fields.address
      : getCompanyInfoApi?.address;
    fields.state = fields.state.value
      ? fields.state.value
      : getCompanyInfoApi?.state;
    fields.city = fields.city ? fields.city : getCompanyInfoApi?.city;
    fields.zipCode = fields.zipCode
      ? fields.zipCode
      : getCompanyInfoApi?.zipCode;
    fields.shippingOption = 'Standard';
    subFilterOption === 'Monthly'
      ? (fields.totalBillMonthly = totalBill)
      : (fields.totalBillYearly = totalBill);
    fields.subscriptionPlan = subFilterOption;
    fields.agree = true;
    subFilterOption === 'Monthly'
      ? (fields.taxesMonthly = checkoutDetails.taxesMonthly)
      : (fields.taxesYearly = checkoutDetails.taxesYearly);
    addCheckout({
      id: 'addCompany',
      data: fields,
      callback: callback
    });
  };
  return (
    <div className="shippingForm">
      <div className="formLogin forgotPassword">
        <Form
          type="server"
          direction="column"
          initialValues={{
            recipentName: '',
            address: '',
            state: '',
            city: '',
            zipCode: ''
          }}
          RenderComponent={RenderComponent}
          handleOnSubmit={(fields) => {
            if (getCompanyInfoApi?.companyId) {
              fields.companyId = getCompanyInfoApi?.companyId;
              fields.userId = getFleetManagerInfo?.userId;
            }
            fields.state = fields.state.value
              ? fields.state.value
              : getCompanyInfoApi?.state;
            fields.shippingOption = 'Standard';
            subFilterOption === 'Monthly'
              ? (fields.totalBillMonthly = totalBill)
              : (fields.totalBillYearly = totalBill);
            fields.subscriptionPlan = subFilterOption;
            fields.agree = true;
            subFilterOption === 'Monthly'
              ? (fields.taxesMonthly = checkoutDetails.taxesMonthly)
              : (fields.taxesYearly = checkoutDetails.taxesYearly);
            addCheckout({
              id: 'addCompany',
              data: fields
            });
          }}
          customProps={{
            hasApiStatus,
            clearApiStatus,
            states,
            differentAddress,
            handleDiffSubmit
          }}
          validationSchema={Yup.object().shape({
            recipentName: Yup.string().required(
              i18n.t('common.fieldRequiredMessage')
            ),
            address: Yup.string().required(
              i18n.t('common.fieldRequiredMessage')
            ),
            city: Yup.string().required(i18n.t('common.fieldRequiredMessage')),
            zipCode: Yup.string().required(
              i18n.t('common.fieldRequiredMessage')
            ),
            state: Yup.string().required(i18n.t('common.fieldRequiredMessage'))
          })}
        />
      </div>
      <Modal open={open} setOpen={setOpen} data={ModalListObject2} />
    </div>
  );
};

export default CheckoutForm;

CheckoutForm.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  requestForgotPassword: PropTypes.func.isRequired,
  callback: PropTypes.func,
  clearApiStatus: PropTypes.func.isRequired
};
