import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import { getImagePath } from '../../../utils/utils';

export const CloseWrapper = styled(Box)`
  background: rgba(0, 0, 0, 0) url('${({ icon }) =>
    getImagePath(icon)}')  no-repeat;
    background-position: center;
    background-size: contain; 
  height: 11px;
  position: relative;
  right: 0px;
  top: 0px;
  width: 11px;
  z-index: 2;
  cursor: pointer;
`;

export default CloseWrapper;
