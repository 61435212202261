import React, { lazy, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { object, string, ref } from 'yup';

import RenderComponent from './RenderComponent';

import { hasValue, phoneNumberValidation } from '@/utils/utils';
import { phoneRegExp, emailRegExp, passwordRegExp } from '@/utils/constants';
import ParentStyled from './style';
import { loadStyle } from '@/utils/utils';
const Styled = loadStyle(ParentStyled, 'components/modules/UserProfile');

const layout = window?.$environment?.CURRENT_LAYOUT;
const CustomMessage = lazy(() =>
  import(`@/components/CustomMessage/${layout}`)
);

const Form = lazy(() => import(`@/components/UI/Form/${layout}`));

const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Component {...contentProps} />
    </Box>
  );
};
export default Index;

const Component = ({
  apiStatus,
  token,
  clearApiStatus,
  fetchApiId,
  updateApiId,
  updateProfile,
  callback,
  isEditable,
  i18n,
  profile,
  getCsvStatusHistory,
  generateMfaQrCode,
  validateMfaSetup,
  modifyMfaSettings,
}) => {
  useEffect(() => {
    if (isEditable) {
      setValidationScheme({
        ...validationScheme,
        firstName: string().required(i18n.t('common.fieldRequiredMessage')),
        lastName: string().required(i18n.t('common.fieldRequiredMessage')),
        ...(window.$environment.SERVICE_PROVIDER == 'webfleet' ? {} : {userPhone: string()
          .matches(phoneRegExp, i18n.t('common.validNumFormat'))
          .required(i18n.t('common.fieldRequiredMessage'))}),
        email: string()
          .matches(emailRegExp, i18n.t('common.emailValidationMessage'))
          .required(i18n.t('common.fieldRequiredMessage'))
      });
    }
  }, [isEditable]);
  const [validationScheme, setValidationScheme] = useState({
    password: string()
      .nullable()
      .matches(passwordRegExp, i18n.t('common.passwordValidMessage'))
      .required(i18n.t('common.fieldRequiredMessage')),
    confirmPassword: string()
      .nullable()
      .oneOf([ref('password'), null], i18n.t('common.confirmPasswordMessage'))
      .required(i18n.t('common.fieldRequiredMessage'))
  });
  const hasFetchApiStatus = hasValue(apiStatus.status, 'api', fetchApiId);
  const hasUpdateApiStatus = hasValue(apiStatus.status, 'api', updateApiId);
  return (
    <Styled.Wrapper>
      <CustomMessage
        type="error"
        message={i18n.t(
          hasFetchApiStatus?.errorMessage || hasUpdateApiStatus?.errorMessage
        )}
      />
      <Form
        type="server"
        direction="row"
        initialValues={{ ...profile }}
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {
          if (token) {
            updateProfile({
              data: {
                newPassword: fields.password,
                confirmPassword: fields.confirmPassword,
                token
              },
              apiId: updateApiId,
              callback: callback
            });
          } else {
            delete fields['confirmPassword'];
            updateProfile({
              data: fields,
              callback: callback,
              apiId: updateApiId
            });
          }
        }}
        customProps={{
          hasApiStatus: hasUpdateApiStatus,
          disabled: isEditable,
          clearApiStatus: clearApiStatus,
          mfaStatus: profile.mfaStatus,
          qrString: profile.qrString,
          Styled,
          i18n,
          getCsvStatusHistory,
          generateMfaQrCode,
          validateMfaSetup,
          modifyMfaSettings,
        }}
        validationSchema={object().shape({
          ...validationScheme
        })}
      />
    </Styled.Wrapper>
  );
};

// export default Component;

Component.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  updateProfile: PropTypes.func.isRequired,
  profile: PropTypes.object,
  i18n: PropTypes.array.isRequired,
  callback: PropTypes.func.isRequired,
  token: PropTypes.string
};
