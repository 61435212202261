import React from 'react';
import PropTypes from 'prop-types';

import Region from './Region';

const RegionList = ({
  data,
  history,
  callback,
  updateRegion,
  deleteRegion,
  Styled,
  isCrudRestricted,
  neglectAccessLevel
}) => {
  return (
    <Styled.RegionListWrapper>
      {data?.data?.regionsDtos?.map((item) => (
        <Region
          region={item}
          fleetManagers={data?.data?.fleetManagers}
          serviceCenters={data?.data?.serviceCenters}
          history={history}
          key={item.id}
          callback={callback}
          updateRegion={updateRegion}
          deleteRegion={deleteRegion}
          isCrudRestricted={isCrudRestricted}
          Styled={Styled}
          neglectAccessLevel={neglectAccessLevel}
        />
      ))}
    </Styled.RegionListWrapper>
  );
};

export default RegionList;

RegionList.propTypes = {
  regions: PropTypes.array
};
