import React, { lazy } from 'react';
import { pageUrls } from '@/config/constants/keys';
import { Link as LinkLi } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { hasEnabledFeature, hasRestrictedRole } from '@/utils/utils';
const layout = window?.$environment?.CURRENT_LAYOUT;

const Search = lazy(() => import(`@/components/UI/SimpleSearch/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));

const TopActions = ({
  filterSuccess,
  Styled,
  workOrders,
  i18n,
  history,
  setPagination,
  viewOnly
}) => {
  const selectChange = (e) => {
    filterSuccess(e);
    setPagination(true);
  };
  return (
    <Styled.TopWrapper>
      {window.$environment.CURRENT_LAYOUT !== 'SpireonNew' &&(
      <Styled.SearchWrapper>
        <Search
          id={'clearSearch'}
          onChange={(e) => selectChange(e)}
          default={workOrders?.list}
          placeHolder={i18n.t('workOrders.search')}
          query={['work_order_id', 'unitId', 'driverName', 'mechanicName']}
          id="workOrders"
        ></Search>
      </Styled.SearchWrapper>)
      }
      <div className="status-btn-wrapper">
        {!viewOnly && 
        (<FormStyle.default.ButtonsWrapper className="addWorkorder">
          <LinkLi component={Link} to={pageUrls.addWorkOrder}>
            <Button
              type="submit"
              className="buttonStyle"
              label={i18n.t('workOrders.addWorkOrders')}
            />
          </LinkLi>
        </FormStyle.default.ButtonsWrapper>)
         }
         {hasEnabledFeature('partsInventoryEnabled')&& 
         (<FormStyle.default.ButtonsWrapper>
            <LinkLi component={Link} to={pageUrls.partsInventoryEnabled}>
              <Button
                type="submit"
                className="buttonStyle"
                label={i18n.t('workOrders.manageParts')}
              />
            </LinkLi>
          </FormStyle.default.ButtonsWrapper>)
          }
        {window.$environment.CURRENT_LAYOUT === 'SpireonNew' && !hasRestrictedRole(['DRIVER', 'MECHANIC']) && (
          <FormStyle.default.ButtonsWrapper className="addWorkorder">
            <LinkLi component={Link} to={pageUrls.mechanicList}>
              <Button
                type="submit"
                className="buttonStyle"
                label={i18n.t('workOrders.manageMechanics')}
              />
            </LinkLi>
          </FormStyle.default.ButtonsWrapper>
        )}
      </div>
    </Styled.TopWrapper>
  );
};
export default TopActions;
