import React, { lazy, useEffect } from 'react';
import PropTypes from 'prop-types';

import { withRouter, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Link from '@material-ui/core/Link';

import {
  getImagePath,
  setLanguage,
  setLanguageFromStorage
} from '../../../utils/utils';

import { imageUrls, externalUrls } from '../../../config/constants/keys';

import Styled from './publicStyle';

const layout = window?.$environment?.CURRENT_LAYOUT;

const Footer = lazy(() => import(`../../Footer/${layout}`));
const Logo = lazy(() => import(`../../../components/Logo`));
const PublicWrapper = ({ children, isI18nNeeded, layout }) => {
  const { i18n } = useTranslation();
  const history = useHistory();
  // Change Code Here
  useEffect(() => {
    let code = setLanguageFromStorage(i18n, i18n.t(`common.en`).toLowerCase());
    if (document.getElementById(code)) {
      document.getElementById(code).selected = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnLanguage = () => {
    setLanguage(i18n);
  };
  const serviceProvider = window.$environment.SERVICE_PROVIDER;
  return (
    <Styled.Wrapper>
      <Styled.Content>
        <Styled.HeaderContainer>
          <div className="not-authenticated" id="container">
            <Logo
              src={getImagePath(imageUrls.logo)}
              size="large"
              width={'220px'}
              isWeblogin
              history={history}
              className="logo"
            />
            {isI18nNeeded && (
              <Styled.LanguageContainer>
                <select
                  name="languages"
                  id="languages"
                  onChange={handleOnLanguage}
                >
                  <option id="en" value="EN">
                    EN
                  </option>
                  <option id="es" value="SP">
                    SP
                  </option>
                </select>
              </Styled.LanguageContainer>
            )}
          </div>
        </Styled.HeaderContainer>

        {children}
         {layout !== 'noi18n' &&
        <Styled.IconWrapper>
          <Link
            href={externalUrls[serviceProvider]['appStore']}
            target="_blank"
          >
            <img
              src={getImagePath(imageUrls.appStore)}
              alt="Icon for app store"
            />
          </Link>

          <Link
            href={externalUrls[serviceProvider]['googlePlay']}
            target="_blank"
          >
            <img
              src={getImagePath(imageUrls.googleplay)}
              alt="Icon for google play"
            />
          </Link>
        </Styled.IconWrapper>
            }
      </Styled.Content>

      <Footer />
    </Styled.Wrapper>
  );
};

export default withRouter(PublicWrapper);
PublicWrapper.defaultProps = {
  children: null,
  isI18nNeeded: true
};
PublicWrapper.propTypes = {
  children: PropTypes.node,
  isI18nNeeded: PropTypes.bool
};
