const initialState = {
  list: [],
  filteredList: []
};

export const usDotManagement = {
  state: { ...initialState },
  reducers: {
    listSuccess(state, payload) {
      return {
        ...state,
        list: [...payload],
        filteredList: [...payload]
      };
    },
    listFailed(state) {
      return { ...state };
    },
    filterSuccess(state, list) {
      return { ...state, filteredList: [...list] };
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload, rootState) {
      try {
        const getApi = await import(
          `../../../config/apis/${window.$environment.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('getUsDotManagementApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject
          },
          id: payload.apiId
        });
        response && this.listSuccess(response);
      } catch (error) {
        this.listFailed();
      }
    },
    async saveDot(payload) {
      try {
        const getApi = await import(
          `../../../config/apis/${window.$environment.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('addUsDotManagementApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject,
            data: payload.data
          },
          id: payload.apiId,
          callback: payload.callback
        });
        payload.callback('success', 'USDOT Number added successfully');
        response &&
          dispatch.usDotManagement.fetchData({
            apiId: 'fetchUsDotManagement'
          });
      } catch (error) {
        this.listFailed();
      }
    },
    async updateDot(payload) {
      try {
        const getApi = await import(
          `../../../config/apis/${window.$environment.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('editUsDotManagementApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject,
            data: payload.data
          },
          id: payload.apiId,
          callback: payload.callback
        });
        payload.callback('success', 'USDOT Number updated successfully');
        response &&
          dispatch.usDotManagement.fetchData({
            apiId: 'fetchUsDotManagement'
          });
      } catch (error) {
        this.listFailed();
      }
    },
    async handleDeleteAlert(payload, state) {
      const getApi = await import(
        `../../../config/apis/${window.$environment.SERVICE_PROVIDER}`
      );
      const apiObject = getApi.default('deleteUsDotManagementApi');
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...apiObject,
              url: apiObject.url.replace('{id}', payload.data.id)
            }
          },
          callback: payload.callback,
          id: payload.apiId
        });
        payload.callback('success', payload.i18n.t('common.usdotDetails'));
        response &&
          dispatch.usDotManagement.fetchData({
            apiId: 'fetchUsDotManagement'
          });
      } catch (error) {
        this.listFailed();
      }
    }
  })
};
