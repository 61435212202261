import styled from 'styled-components';
import Box from '@material-ui/core/Box';
const Wrapper = styled(Box)`
  ${({ theme }) => `
  form {
    justify-content: space-between;
    @media (max-width: ${theme.breakpoints.values.sm}px) {
      .MuiBox-root {
        width: 100%;
      }
    }
    p {
      padding: 16px 11px;
      font-size: 21px;
    }
    .MuiBox-root:nth-child(6) {
      justify-content: flex-end;
      @media (max-width: ${theme.breakpoints.values.smd}px) {
        flex-direction: column;
      }
      width:100%;
      .MuiBox-root {
        flex: 0 0 15%;
        &:last-child {
          margin-right:0;
        }
      }
    }
  }

  .buttonStyle,
  .reset {
    span {
      padding: 5px+ 80px !important;
      @media (max-width: ${theme.breakpoints.values.sm}px) {
        padding: 5px 20px !important;
      }
    }
  }
  .MuiBox-root {
    margin-top: 20px;
    width: 49%;
    @media (max-width: ${theme.breakpoints.values.md}px) {
      width: 100%;
    }
    .filepond--drop-label {
      padding-left: 6px;
    }
    p {
      flex: 0 0 20%;
      padding: 0;
      font-size: 20px;
      @media (max-width: ${theme.breakpoints.values.md}px) {
        font-size: 16px;
      }
    }
    .MuiBox-root {
      margin-right: 1em;
      margin-top: 0px;
      // flex: 0 0 80%;
    }
  }
  `}
`;

const Content = styled(Box)`
${({ theme }) => `
.addLogTitle {
  text-transform: uppercase;
}
`}
`;
const Styled = { Wrapper, Content };

export default Styled;
