import React, { useEffect, lazy } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useHistory } from 'react-router-dom';
import useTheme from '@material-ui/core/styles/useTheme';
import { useTranslation } from 'react-i18next';
import Content from './Component';
import Box from '@material-ui/core/Box';

import ParentStyled from './style';
import { loadStyle, hasValue } from '@/utils/utils';
const Title = lazy(() => import(`@/components/Title`));

let Styled = loadStyle(ParentStyled, 'pages/web/DeveloperRequests');

const DeveloperRequests = ({ apiStatus, fetchDeveloperRequests, callback, ...rest }) => {
  const history = useHistory();
  const theme = useTheme();
  const i18n = useTranslation();

  const fetchApi = 'fetchDeveloperRequests';
  const hasFetchApiStatus = hasValue(apiStatus.status, 'api', fetchApi);

  useEffect(() => {
    fetchDeveloperRequests({
      apiId: fetchApi
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const contentProps = {
    Styled,
    history,
    callback,
    ...rest
  };
  return (
    <>
      <Title header={'developerRequests.listHeading'} i18n={i18n} />
      <>
        <Content {...contentProps} />
      </>
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    developerRequest: state.developerRequest
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchDeveloperRequests: (data) => dispatch.developerRequest.fetchData(data),
    approveData: (data) => dispatch.developerRequest.approveData(data),
    denyData: (data) => dispatch.developerRequest.denyData(data)
  };
};

export default connect(mapState, mapDispatch)(DeveloperRequests);
DeveloperRequests.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  fetchDeveloperRequests: PropTypes.func.isRequired,
  developerRequests: PropTypes.array.isRequired
};
