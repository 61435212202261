const loadTheme = () => {
  try {
    const theme = require(`@/config/theme/${window.$environment.SERVICE_PROVIDER}/${window.$environment.CURRENT_LAYOUT}`)
      .default;

    const GlobalTheme = require(`@/config/theme/${window.$environment.SERVICE_PROVIDER}/${window.$environment.CURRENT_LAYOUT}/GlobalTheme`)
      .default;
    return { theme, GlobalTheme };
  } catch (error) {
    const theme = require(`@/config/theme/${window.$environment.SERVICE_PROVIDER}`)
      .default;

    const GlobalTheme = require(`@/config/theme/${window.$environment.SERVICE_PROVIDER}/GlobalTheme`)
      .default;
    return { theme, GlobalTheme };
  }
};
export default loadTheme();
