import styled from 'styled-components';
import Box from '@material-ui/core/Box';
const Wrapper = styled(Box)`
  .logsInfoDisplay {
    font-size: 23px;
    padding-bottom: 30px;
  }
  .MuiTableBody-root .MuiTableCell-alignCenter {
    text-align: left !important;
  }
`;

const Styled = { Wrapper };

export default Styled;
