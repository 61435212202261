import styled from 'styled-components';
import Parent from '../Base/style';
const SelectWrapper = styled(Parent.SelectWrapper)`
${({theme})=>`
  .select__control,
  .css-yk16xz-control,
  .select__control--menu-is-open {
    color: #fff;
    border: 1px solid ${theme.colors.INPUT_BORDER};
    min-height: 40px !important;
    position: relative;
    font-size: 16px;
    border-radius: 3px;
    margin-bottom: 18px;
    background: ${theme.colors.FORM_INPUT_BG} !important;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS styles go here */
      height: 40px;
    }
  }
.select__control--is-focused .select__dropdown-indicator,
.select__indicator:hover,
.select__indicator{
    color: ${theme.colors.SELECT_ACTIONS} !important;
}
  .css-8r3dx3-control:hover {
    border: 1px solid ${theme.colors.INPUT_BORDER} !important;
  }
  .select__placeholder {
    color: #868e96;
    font-size: 14px;
    opacity: 0.5;
    font-weight: 400;
  }
  .css-q5i2ns-control:hover {
    border: 1px solid ${theme.colors.PRIMARY_BORDER} !important;
  }
  .css-8r3dx3-control,
  .css-q5i2ns-control,
  .react-select__menu-list,
  .css-1wmipuf-option,
  .css-1irrqr3-option,
  .css-1hys26h-singleValue,
  .css-1ebjif1-option,
  .react-select__menu-list:active,
  .css-1wmipuf-option:active,
  .css-1ebjif1-option:active,
  .css-kpwi51-option:active,
  .css-kpwi51-option,
  .css-1wmipuf-option {
    font-family: ${(theme.palette || {}).fontFamily};
    background-color: ${theme.colors.FORM_INPUT_BG} !important;
  }
  .css-1irrqr3-option:hover,
  .css-1irrqr3-option:focus,
  .react-select__menu-list:hover,
  .react-select__menu-list:focus,
  .css-1wmipuf-option:focus,
  .css-1wmipuf-option:hover,
  .css-1ebjif1-option:hover,
  .css-kpwi51-option:hover {
    background-color: #20263a !important;
  }
  .MuiButton-root:hover {
    background-color: #fff !important;
  }
  .select__menu {
    font: 14px Open Sans;
    border: 1px solid ${theme.colors.FORM_INPUT_BG};
    background-color: ${theme.colors.FORM_INPUT_BG} !important;
    border-radius: 3px;
    z-index: 1000;
    color: ${theme.colors.PRIMARY_CONTRAST}
  }
`}
`;
const Styled = { SelectWrapper };
export default Styled;
