import styled from 'styled-components';
import { Box } from '@material-ui/core';

const ChartWrapper = styled(Box)`
  ${({ theme }) => `
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
    color: #757473
  }
width: 100%;
  .svg-container {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
    position: relative;
    width: 100%;
    margin-top: 4%;
    padding-bottom: 26%;
    vertical-align: top;
  }
  .svg-content {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
    position: absolute;
    top: 0;
    left: 0;
    rect.leftChip,
    rect.rightChip{
      fill: #fff;
    }
    .leftTime,
    .rightTime{
      fill: #c0bbb7;
    }
    .overlay .selectedArea {
      fill: rgba(245, 130, 32, 0.3) !important;
    }
  }
  .editmodeNotification {
    width: 53%;
    background-color: #bdbdbd;
    color: #fff;
    font-size: 18px;
    text-align: center;
    margin: 0 auto;
    margin-top: 15px;
    // font-weight: bold;
  }
  .updateEventsectionWrapper{
    display:flex;
    justify-content: space-between;
    .disableProvider {
      opacity: 0.5;
      pointer-events: none;
    }
    .providerWrapper{
      .ant-switch-checked {
          background-color: ${theme.colors.SECONDARY_MAIN} !important;
      }
      .ant-switch {
        margin: 0;
        padding: 0;
        font-size: 14px;
        list-style: none;
        position: relative;
        display: inline-block;
        box-sizing: border-box;
        min-width: 44px;
        height: 22px;
        line-height: 22px;
        vertical-align: middle;
        border: 0;
        border-radius: 100px;
        background-color: ${window.$environment.WHITE_THEME ? '#00000066' : '#ffffff80'};
        cursor: pointer;
        transition: all .2s;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      .ant-switch-checked .ant-switch-inner {
        margin: 0 25px 0 7px;
      }
      .ant-switch-handle:before {
        position: absolute;
        inset: 0;
        background-color: #fff;
        border-radius: 9px;
        box-shadow: 0 2px 4px #00230b33;
        transition: all .2s ease-in-out;
        content: "";
      }
      .ant-switch-handle {
        position: absolute;
        top: 2px;
        left: 2px;
        width: 18px;
        height: 18px;
        transition: all .2s ease-in-out;
      }
      .ant-switch-checked .ant-switch-handle {
        left: calc(100% - 20px);
      }
      .ant-switch-inner {
        display: block;
        margin: 0 7px 0 25px;
        color: #fff;
        font-size: 12px;
        transition: margin .2s;
      }

      width: fit-content;
      align-items: center;
      gap: 10px;
      display: flex;
      .providerLabel {
        color: ${theme.colors.PRIMARY_CONTRAST};
        font-size: 20px;
        margin-top: 3px;
        cursor: pointer;
      }
      .infoIcon{
        color: ${theme.colors.PRIMARY_CONTRAST};
      }
    }
    .providerIcon{
      width: fit-content;
      .MuiAvatar-root{
        width: 35px !important;
        height: 35px !important;
      }
    }
  }
  .updateEventsection {
    flex-wrap: wrap;
    padding-bottom: 1%;
    @media (max-width: ${theme.breakpoints.values.lg}px) {
      justify-content: center;
      padding-top: 12px;
      .MuiBox-root {
        margin-bottom: 5px;
      }
    }
    .button--disabled:hover {
      background: #1b1b1b;
    }
    .button-status{
      width:95%;
      color: ${theme.colors.PRIMARY_CONTRAST};
      font-size: 16px;
    }
    .MuiButton-outlinedSizeSmall {
      padding: 3px 14px !important;
    }
    #update_graph + div {
      #button {
        border: none;
      }
    }
  }
  #update_graph {
    .button-update{
      border: 1px solid ${theme.colors.PRIMARY_CONTRAST};
      :hover {
        background: #1b1b1b;
      }
    }
    .button--update-normal{
    border: 0;
    color: #fff !important;
  }
  }
  .button--disabled: hover {
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.primary.contrastText};
    cursor: inherit;
  }
  .button--status-normal: hover,
  .button--update-normal: hover,
  .button--update-normal,
  .button-add {
    color: ${theme.palette.secondary.contrastText + '!important'};
    background-color: ${theme.palette.secondary.main + '!important'};
    cursor: pointer;
  }
  .btn-disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
  .button-add {
    font-size: 23px !important;
    text-transform: capitalize;
  }

  .active {
    background-color: ${theme.palette.secondary.main}!important ;
    color: ${theme.palette.secondary.contrastText}!important ;
  }

  .button-status {
    margin-right: 4px;
    padding-top: 0px;
    padding-bottom: 0px;
    color: ${theme.palette.primary.contrastText};
    background-color: ${theme.palette.primary.main};
    font-size: 17px;
    border: ${`1px solid ${theme.palette.primary.border}`};
    @media (max-width: ${theme.breakpoints.values.md}px) {
      font-size: 15px;
    }
    @media (max-width: ${theme.breakpoints.values.sm}px) {
      padding: 0;
    }
  }
  #updategraph{
    border: 1px solid ${theme.colors.LINK_COLOR};
    color: #fff !important;
  }
  .button-update {
    font-size: 23px !important;
    margin-left: 0.5rem;
    color: ${theme.palette.primary.contrastText} !important;
    background-color: ${theme.palette.primary.main};
    border: 0;
    text-transform: capitalize;
    padding: 0px 26px
    width: auto !important;
    @media (max-width: ${theme.breakpoints.values.md}px) {
      font-size: 15px;
      padding: 0 15px;
    }

  }
  #addNewRow {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
    justify-content: flex-end;
    padding-top: 10px;
  }
  .cancelSubmitwrapper {
    width:100%;
  }

  .viewIconWrapper {
    padding-top:13px;
    .MuiAvatar-root{
    height:21px !important;
    width: auto !important;
    border-radius:0px !important;
  }
}
  .iconContainer {
    padding-top:13px;
    .MuiAvatar-root{
    height:19px;
    width:19px;
    }
  }

  `}
`;
const LabelWrap = styled(Box)`
  p {
    font-size: 21px;
    margin-bottom: 20px;
  }
`;
const StatusModifier = styled(Box)`
  .status {
    min-width: 98px;
  }
  .select--is-disabled{
    pointer-events: auto;
    opacity: 1 !important;
  }
  .select--is-disabled,
  .select__single-value--is-disabled:hover,
  .select__control.select__control--is-disabled:hover,
  input:disabled,
  .MuiInputBase-root.Mui-disabled,
  .MuiInputBase-input.Mui-disabled{
    cursor: no-drop;
    opacity: 0.6 !important;
    pointer-events: none;
  }
  .MuiTableHead-root .MuiTableRow-root {
    .MuiTableCell-root:first-child {
      min-width: 112px;
    }
    .MuiTableCell-root:nth-child(5),
    .MuiTableCell-root:nth-child(6),
    .MuiTableCell-root:nth-child(2) {
      min-width: 120px;
    }
  }
  .MuiTable-root .MuiTableCell-head {
    padding-right: 7px !important;
  }
  .stateModifier {
    min-width: 120px;
  }
  .driverId,
  .stateModifier {
    .select__placeholder {
      font-size: 16px;
    }
    .select__dropdown-indicator {
      padding-left: 0;
      padding: 3px;
    }
  }
  .react-datepicker--time-only {
    border: 2px solid colors.PRIMARY_BORDER;
    color: colors.PRIMARY_CONTRAST;
    font-family: Yanone Kaffeesatz, tahoma;
    font-size: 14px;
    font-weight: normal;
    .react-datepicker__time-list {
      padding-top: 6px;
      overflow-y: initial;
    }
    .react-datepicker__time-list-item {
      border-top: none !important;
    }
    .react-datepicker__header--time {
      display: none;
    }
  }
  .dailyLogDateError {
    border: 2px solid red !important;
  }
  }
`;
const Styled = { ChartWrapper, LabelWrap, StatusModifier };
export default Styled;
