import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { getStyle } from '../../../../utils/utils';

const Wrapper = styled(Typography)`
  padding: ${({ style }) => getStyle((style || {}).padding, '', '0px')};
  variant: ${({ style }) => getStyle((style || {}).variant, '', 'body1')};
  color: ${({ style, theme, mode }) => {
    return mode === 'light'
      ? theme.colors.WHITE + '!important'
      : getStyle(
          (style || {}).color,
          (theme.palette.primary || {}).main,
          theme.colors.PRIMARY_MAIN
        );
  }};
  text-transform: uppercase;
  flex: ${(props) => getStyle((props.style || {}).flex, '', '')};
  font-size: ${(props) => getStyle((props.style || {}).fontSize, '', '20px')};
`;

export default Wrapper;
