import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { object, string } from 'yup';

import { hasValue } from '@/utils/utils';
const Form = lazy(() =>
  import(`@/components/UI/Form/${window?.$environment?.CURRENT_LAYOUT}`)
);

const MFAForm = ({
  apiStatus,
  authenticteOtp,
  clearApiStatus,
  Styled,
  innerComponent,
  i18n
}) => {
  const apiId = 'login';
  const hasApiStatus = hasValue(apiStatus.status, 'api', apiId);
  const RenderComponent = lazy(() => import(`@/${innerComponent}`));
  const NewUI = window?.$environment?.clutchNewUI
  return (
    <Styled.LoginWrapper newUI={NewUI}>
      <div className="formLogin forgotPassword">
        <Form
          type="server"
          direction="column"
          initialValues={{
            mfacode: ''
          }}
          RenderComponent={RenderComponent}
          handleOnSubmit={(fields) => {
            authenticteOtp({
              data: fields,
              apiId
            });
          }}
          customProps={{
            hasApiStatus,
            clearApiStatus,
            Styled,
            i18n
          }}
          validationSchema={object().shape({
            mfacode: string()
              .required(i18n.t('common.fieldRequiredMessage'))
          })}
        />
      </div>
    </Styled.LoginWrapper>
  );
};

export default MFAForm;

MFAForm.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  authenticteOtp: PropTypes.func.isRequired,
  clearApiStatus: PropTypes.func.isRequired,
  Styled: PropTypes.object.isRequired,
  i18n: PropTypes.array.isRequired
};
