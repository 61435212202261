import React from 'react';
import PropTypes from 'prop-types';

const Audio = ({ url }) => {
  return (
    <audio controls>
      <source src={url} type="audio/mp4" />
      Your browser does not support the audio element.
    </audio>
  );
};

export default Audio;

Audio.propTypes = {
  url: PropTypes.string
};

Audio.defaultTypes = { url: '' };
