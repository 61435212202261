import styled from 'styled-components';
import Parent from '../style';

const PolicyWrapper = styled(Parent.PolicyWrapper)`
${({ theme }) => `
.viewSample {
    display: block;
    cursor:pointer;
    color: ${theme.colors.LINK_COLOR};
    font-family: ${theme.palette.tableFont};
    font-size: 15px;
    &:hover {
        text-decoration: underline;
    }
}
.sampleIcon {
    display: none;
}
`}
`;
const SelectedPolicy = styled(Parent.SelectedPolicy)`
${({ theme }) => `
flex: 0 0 25%;
padding-top: 8px;
align-self: flex-start;
.normalCheckbox label {
    color: #000 !important;
}
.viewMore {
    align-self: center;
    padding-left: 14px;
    padding-top: 9px;
}
.MuiAvatar-root {
    width: auto;
    height: auto;
}
.subFormsection {
    padding-top: 10px;
    padding-left: 36px;
    .MuiFormLabel-root{
      color: #373737;
    }
    .normalCheckbox label{
        font-size: 16px;
        color: #373737 !important;
    }
}
.subFields {
    display: flex;
    flex-direction: row;
    label {
     padding-bottom: 5px;
     font-size: 16px;
    flex: 0 0 40%;
    padding-right: 8px;
    font-family: ${theme.palette.tableFont};
    }
    label + .MuiBox-root {
        flex: 0 0 60%;
    }
}

.normalCheckbox {
    .Checkbox { 
        top: 2px;
    }
    span {
        padding-left: 10px;
        font-size: 20px;
    }
}
`}
`;
const Styled = { SelectedPolicy , PolicyWrapper };
export default Styled;
