import styled from 'styled-components';
import Box from '@material-ui/core/Box';

import { getStyle } from '../../../../utils/utils';

const RangePickerContainer = styled(Box)`
  ${({ theme }) => `
  .ant-picker-range{
      height: 45px;
      border: 2px solid ${theme.colors.PRIMARY_BORDER};
      background-color: ${theme.colors.BACKGROUND};
      .ant-picker-input > input{
        color: ${theme.colors.PRIMARY_CONTRAST};
        text-align: center;
        font-family: ${theme.palette.fontFamily};
        font-size: 18px;
      }
      .anticon-swap-right svg, .anticon-calendar svg {
          fill: ${theme.colors.PRIMARY_CONTRAST};
      }
      .ant-picker-active-bar {
          background: ${theme.colors.SECONDARY_MAIN};
      }
  }
  .ant-picker:hover, .ant-picker-focused {
    border: 2px solid ${theme.colors.PRIMARY_BORDER};
    box-shadow: none;
  }
  .ant-picker-dropdown{
    .ant-picker-panel-container{
        background-color: ${theme.colors.BACKGROUND};
      }
      .ant-picker-header-view button{
        font-family: ${theme.palette.fontFamily};
        font-size: 16px;
        color: ${theme.colors.PRIMARY_CONTRAST};
      }
  }  
  `}
`;

export default RangePickerContainer;
