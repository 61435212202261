import * as apis from '..';
const localApis = {
  // getTimezoneApi: {
  //   url: '/registration/timeZones',
  //   type: 'get',
  //   isPublic: true
  // },
  // saveRegistrationApi: {
  //   url: '/registration/step1',
  //   type: 'post',
  //   isPublic: true
  // },
  authApiWithToken: {
    url: '/spireon/login',
    type: 'get',
    isPublic: true,
    isQueryString: true
  }
};
const getApi = (api) => {
  return localApis[api] || apis[api];
};
export default getApi;
