import styled from 'styled-components';
import Parent from './style';
const Wrapper = styled(Parent.Wrapper)`
flex: 0 0 69%;
position: relative;
// padding-bottom: 26px;
margin-top: -8px;
justify-content: space-between;
.breadcrumbClass {
    position: absolute;
    bottom: -33px;
    width: 100%;
    padding-left: 0;
    left: -28%;
}
`;
const TopWrapper = styled(Parent.TopWrapper)` 
padding-left: 19px;
#simpleSearchWrapper {
    width: 320px;
}
`;
const IconWrapper = styled(Parent.IconWrapper)`
${({ theme }) => `
.userNew {
    display: none;
}
.userOld {
    display: block;
}
.addUserButtonhover:hover {
    text-decoration: none !important;
}
.userOld .addUser {
    padding: 0 8px;
    background: #C92100;
    color: #fff;
    font-family: YanoneKaffeesatz;
    font-size: 23px!important;
    border: none;
    padding: 5px 15px !important;
    font-family: ${theme.palette.titleFont};
    height: 43px !important;
    border-radius: 0 !important;
    :hover {
        font-size: 23px!important;
        background: #C92100;
    }
}
position: absolute;
right: 0;
top: -2px;
`}
`;

const ImpExpWrapper = styled(Parent.ImpExpWrapper)`
position: relative;
flex: 0 0 59.5%;
top: 42px;
.userNew {
    display: none;
}
.userOld {
    display: block;
  }
.importUserTemplateContainer{
    order: 3;
    padding-left: 7px;
    margin-right: 0;
    .exportText {
        font-size: 14px;
    }
}
.importUserContainer{
    order: 2;
}
.exportContainer{
    order: 1;
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
    .exportText {
        font-size: 14px;
    }
}
importVehicleCsvReaderInput{
    cursor: pointer;
}
.importUserContainer.userOld {
    .importVehicleCsvReaderInput{
        font-family: tahoma;
        justify-content: flex-end;
        align-items: center;
        width: 82px;
    }
    label{
        font-size: 14px;
    }
}

`;

const SearchWrapper = styled(Parent.SearchWrapper)`

`;

const ButtonsWrapper = styled(Parent.ButtonsWrapper)`
${({ theme }) => `
padding-top: 3.5%;
.quickfilter{
    display: inline-block;
    color: #42505a;
    align-self: center;
    font-size: 24px;
    font-family: ${theme.palette.titleFont};
}
.userBtnWrapper {
    color: #42505a;
    font: bold 12px "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding: 10px;
    background: -webkit-linear-gradient(top, #d0d8db 0%, #b3bec3 100%);
    .MuiButton-root{
        width: auto;
        font-size: 16px !important;
        background: -webkit-linear-gradient(top, #d0d8db 0%, #b3bec3 100%) !important;
        border: 0!important;
        font-style: italic;
        font-family: tahoma;
        &:hover {
            text-decoration: underline;
            box-shadow: none;
        }
    }
    .active {
        text-decoration: underline;
    }
    .MuiBox-root{
        margin-right: 1%;
    }
}
`}
`;

const DocContainer = styled(Parent.DocContainer)`
${({ theme }) => `
border: 1px solid #ddd;
padding-bottom: 10px;
li a {
    font-size: 22px;
    font-family: ${theme.palette.titleFont};
}
.ToolsOptionClose{
    background-size: 10px;
}
`}
`;
const TableWrapper = styled(Parent.TableWrapper)`
padding: 0; 
`;
const Styled = {
  Wrapper,
  IconWrapper,
  ImpExpWrapper,
  SearchWrapper,
  TopWrapper,
  ButtonsWrapper,
  TableWrapper,
  DocContainer
};
export default Styled;
