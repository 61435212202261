import {
  fetchAccidentsApi,
  recentAccidentsClearApi, fetchAccidentsPaginateApi
} from '../../../../config/apis';

const initialState = {
  list: [],
  paginateList: {
    accidentListItems: []
  }
};

export const accidents = {
  state: {...initialState},
  reducers: {
    listSuccess(state, payload) {
      return {...state, ...payload};
    },
    listFailed() {
      return {...initialState};
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: fetchAccidentsApi
          },
          id: payload.apiId
        });
        response && this.listSuccess({list: response});
      } catch (error) {
        this.listFailed();
      }
    },
    async fetchPaginationData(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: fetchAccidentsPaginateApi,
            data: payload.data?.request
          },
          id: payload.apiId
        });
        response && this.listSuccess({paginateList: response});
      } catch (error) {
        this.listFailed();
      }
    },
    async clearAllRecentAccidents(payload, rootState) {
      try {
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: recentAccidentsClearApi
          },
          callback: payload.callback,
          id: payload.apiId
        });
        dispatch.dashboard.clearRecentAccidentsData();
      } catch (error) {
        this.listFailed();
      }
    }
  })
};
