import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
padding: 2%;
.fieldWrapper {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        margin-bottom: 73px;
      }
}
.MuiPaper-root {
    .MuiAccordionSummary-content h2 {
        font-size: 18px;
        text-transform: capitalize;
        color: ${theme.colors.MENU_BORDER_COLOR} !important; 
    }
    .MuiIconButton-label {
        .MuiSvgIcon-root {
            fill: ${theme.colors.MENU_BORDER_COLOR};
        }
    }
}
form {
    .MuiInputBase-input,
    .react-datepicker-wrapper input,
    .css-8r3dx3-control,
    .css-q5i2ns-control,
    .react-select__menu-list,
    .css-1wmipuf-option,
    .css-1irrqr3-option,
    .css-1hys26h-singleValue,
    .css-1ebjif1-option,
    .react-select__menu-list:active,
    .css-1wmipuf-option:active,
    .css-1ebjif1-option:active,
    .css-kpwi51-option:active,
    .css-kpwi51-option,
    .css-1wmipuf-option{
        background-color: ${theme.colors.INPUT_BACKGROUND} !important;
    }
}
`}
`;

const Styled = { Wrapper };

export default Styled;
