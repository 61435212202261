import styled from 'styled-components';
import Parent from '../Base/style';
const Wrapper = styled(Parent)`
color: #848c96 !important;
font-size: 14px !important;
line-height: 15px !important;
font-family: tahoma;
`;

export default Wrapper;
