import { removeListItem } from '../../utils/utils';
import { fetch } from '../../services/fetch';

const initialState = {
  status: [
    {
      api: '',
      errorMessage: '',
      isLoading: false,
      isValidVinLoading: false
    }
  ]
};

export const asyncRequests = {
  state: { ...initialState },
  reducers: {
    setStatus(state, payload) {
      let newList;
      if (payload.api == 'regeneratePdfLogbook') {
        newList = [...state.status, {
          api: 'regeneratePdfLogbook',
          errorMessage: '',
          isLoading: true,
          isValidVinLoading: false
        }]
      } else {
        let temp = state.status.filter(ele => ele.api != 'regeneratePdfLogbook')
        newList = removeListItem(temp, 'api', payload.api);
      }

      return {
        status: [...newList, payload]
      };
    },
    removeStatus(state, api) {
      const newList = removeListItem(state.status, 'api', api);
      return {
        status: [...newList]
      };
    },
    clearApiStatus() {
      return { ...initialState };
    }
  },

  effects: {
    async handleAsyncRequests(payload) {
      let loaderReqArray = [
        'isValidVIN',
        'exportUserTemplate',
        'exportUser',
        'getDriverStatus',
        'fetchTerminals',
        // 'fetchTerminal',
        // 'fetchDepartments',
        // 'fetchDSubDepartments',
        'sendBroadCastChatApi',
        'createChatToken',
        'addUserToChanelChat',
        'getDriversVehiclePage',
        'refreshDashboardMap',
        'fetchAllTruckClutchWelcome',
        'driverStatusSearchApi',
        'updateDriverStatus',
        'getDriverStatusUpdate',
        'fetchSearchUserApi',
        'iftaListRefresh',
        'featureFlagApi',
        'validateOdometer',
        'fetchLatLong',
      ];
      try {
        this.setStatus({
          api: payload.id,
          errorMessage: '',
          isLoading: !loaderReqArray.includes(payload.id),
          isValidVinLoading: payload.id === 'isValidVIN' ? true : false
        });
        if (payload.id === 'fetchEditTrailerData' || payload.id === 'fetchTrailerData') {
          this.removeStatus('isValidVIN');
        }
        const response = await fetch(payload.payload);
        this.removeStatus(payload.id);
        if (response.config.url.includes('login') && response.status == 206) {
          return response
        }
        if (response.config.url.includes('logs/create') && response.status == 200) {
          return response
        }
        else if (response?.data?.data) {
          //  return response.data.data
          if (payload.id === 'isValidVIN') {
            // Need to remove this check once the response got corrected from BE
            this.setStatus({
              api: payload.id,
              errorMessage: response.data.data,
              isLoading: false,
              isValidVinLoading: false
            });
          } else return response.data.data;
        } else if (response.config.url == '/vehicle/edit' && response.status == 207) {
          return response
        } else if (response?.data?.errors) {
          this.setStatus({
            api: payload.id,
            errorMessage: response.data.errors,
            isLoading: false
          });
        } else if (payload.id == 'resellerDetailsApi') return response.data
        return;
      } catch (error) {
        if (error?.status === 422 && payload?.callback) {
          this.removeStatus(payload.id);
          payload.callback(
            'error',
            error.message,
            () => payload?.history && payload?.redirectionUrl && payload.history.push(payload.redirectionUrl)
          );
          throw new Error(error.message);
        } else if (payload?.callback) {
          this.removeStatus(payload.id);
          payload.callback('error', error.message);
          throw new Error(error.message);
        } else {
          let message = '';
          if (error.status == 400 && typeof error.message == 'object') {
            if (error?.message?.[0]) {
              message = error.message[0] ? error.message[0].replace(':', '-') : '';
            } else {
              const firstKey = Object.keys(error.message)[0];
              message = error.message[firstKey].replace(':', '-');
            }
            
          } else message = error.message

          if(payload.id === 'editSaveVehicleData') {
            this.setStatus({
              api: payload.id,
              errorMessage: '',
              isLoading: false
            });
            const firstKey = Object.keys(error.message)[0];
            let message = error.message[firstKey];
            if (typeof message === 'string') {
              message = message.replace(':', '-');
              throw new Error(message);
            } else if (typeof message === 'object' && message !== null) {
              const errorKeys = Object.keys(message);
              if (errorKeys.length > 0) {
                message = message[errorKeys[0]];
                if (typeof message === 'string') {
                  message = message.replace(':', '-');
                  throw new Error(message);
                }
              }
            }
            throw new Error("Something went wrong!");
          } else {
            this.setStatus({
              api: payload.id,
              errorMessage: message,
              isLoading: false
            });
            throw new Error(error.message);
          }
        }
      }
    }
  }
};
