import React, { useEffect } from 'react';
import Chart from 'chart.js';

export default class LineGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  chartRef = React.createRef();
  // useEffect(() => {
  componentDidMount() {
    let props = this.props;
    let basicmeasureChart;
    let myChartRef =
      document.getElementById(props.id) &&
      document.getElementById(props.id).getContext('2d');

    const history = props.history;
    const pageurl = props.pageUrls;
    const noHyperLink = props?.noHyperLink;
    const dataItems = props;
    const average = (arr) => arr.reduce((p, c) => p + c, 0) / arr.length;
    const dataOne = props.data1;
    const dataTwo = props.data2;
    let function123 = 'updateDataset1';
    if (myChartRef) {
      basicmeasureChart = new Chart(myChartRef, {
        type: 'line',
        data: {
          labels: props.labels,
          datasets: props.data
        },
        options: {
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: true,
                  color: '#2d2b2b'
                },
                ticks: {
                  fontSize: 12,
                  color: '#c0bbb7',
                  fontFamily: 'Yanone Kaffeesatz',
                  maxRotation: 45,
                  minRotation: 45
                }
              }
            ],
            yAxes: [
              {
                gridLines: {
                  display:
                    props.mainData && props.mainData.length === 0
                      ? false
                      : true,
                  color: '#2d2b2b'
                },
                ticks: {
                  display:
                    props.mainData && props.mainData.length === 0
                      ? false
                      : true,
                  fontSize: 15,
                  color: '#c0bbb7',
                  fontFamily: 'Yanone Kaffeesatz',
                  // max: 10,
                  min: 0
                  // stepSize: 2
                }
              }
            ]
          },
          legend: {
            display:
              props.mainData && props.mainData.length === 0 ? false : true,
            position: 'top',
            labels: {
              boxWidth: 15,
              fontSize: 15,
              fontFamily: 'Yanone Kaffeesatz',
              fontColor: '#c0bbb7',
              paddingBottom: 500
            }
          }
        }
      });
    }
  }
  render() {
    return (
      <div>
        <canvas
          id={this.props.id}
          width={this.props.width}
          height={this.props.height}
        />
      </div>
    );
  }
}
