import styled from 'styled-components';
import { MapWrapper as Parent } from '../Base/style';

export const MapWrapper = styled(Parent)`
${({ theme }) => `
.infoImg{
    display: none;
}
.nameLabel{
    display: inline-block;
    font-family: tahoma !important;
    font-size: 14px;
}
.infoWindow-data {
    padding-left: 4px !important;
}
.cluster img {
    width: 50px;
}
.gmnoprint.gm-style-cc a{
    color: #c3c3c3 !important;
}
.gm-ui-hover-effect img{
    display: block !important;
}
.gm-ui-hover-effect{
    top: -4px !important;
    right: 3px !important;
}
.gm-style-iw #content:first-child a{
    top: 0;
}
.gmnoprint.gm-style-cc div, 
.gmnoprint .gm-style-cc div {
    background-color: #DEEDF8 !important;
}
.gmnoprint.gm-style-cc a,
.gmnoprint .gm-style-cc div{
    color: #444 !important;
}
.infoWindow-data,
.gm-style-iw.gm-style-iw-c a {
    color: ${theme.colors.LINK_COLOR} !important;
    font-family: tahoma !important;
    font-size: 14px !important;
    font-weight: 600 !important;
}
.infoWindow-data{
    color: #000 !important;
    font-weight: 600;
}
.gm-style-mtc div {
    border: 0 !important;
    background-color: ${theme.colors.WHITE} !important;
    color: ${theme.colors.BLACK} !important;
    height: 40px !important;
    font-size: 18px !important;
}
.gm-bundled-control .gmnoprint div {
    color: #444 !important;
}
.gm-svpc,
.gm-bundled-control .gmnoprint div,
.gm-style-cc div,
.gm-control-active.gm-fullscreen-control {
    border: 0 !important;
    background-color: ${theme.colors.WHITE} !important;
}
a[href^="https://maps.google.com/maps"] img
{
  opacity: 1;
  width: 66px !important;
  height: 26px !important;
}
`}
`;
