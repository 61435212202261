import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import useTheme from '@material-ui/core/styles/useTheme';
import PropTypes from 'prop-types';

import Component from './Component';
import Styled from './style';
import { hasValue } from '@/utils/utils';

const Trailers = ({ apiStatus, history, callback, trailers,insuracePortalUserRole, fleetCompanyList, handleReset, ...rest }) => {
  const theme = useTheme();
  const apiId = 'fetchVehicles';

  const hasFetchApiStatus = hasValue(apiStatus.status, 'api', apiId);

  return (
    <>
      {/* <Box style={{ ...theme.palette.contentStyle }}> */}
        <Component
          {...rest}
          history={history}
          trailers={trailers}
          apiStatus={apiStatus}
          callback={callback}
          Styled={Styled}
          insuracePortalUserRole={insuracePortalUserRole}
          fleetCompanyList={fleetCompanyList}
          handleReset={handleReset}
        />
      {/* </Box> */}
    </>
  );
};

export default Trailers;

Trailers.propTypes = {
  callback: PropTypes.func.isRequired,
  trailers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  handleDeleteAlert: PropTypes.func.isRequired,
  apiStatus: PropTypes.object.isRequired
};
