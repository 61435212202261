import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const DailyLogContainer = styled(Box)`
  ${({ theme }) => `
  table {
    min-width: auto;
  }
  .driver {
    font-size: 17px;
    margin-top:0 !important;
    margin-bottom:0;
    color: ${theme.colors.PRIMARY_CONTRAST};
  }
  .date {
    font-size: 15.5px;
    color: #6d6d6d;
    margin-top:0 !important;
    margin-bottom:0;
  }
  a {
    background-color: ${
      (theme.palette.primary || {}).main || theme.colors.PRIMARY_MAIN
    };
    color: ${
      (theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN
    };
    padding: 5px 30px;
    font-size: 20px;
  }
  a:hover {
    text-decoration: none;
  }
 
  .ItemsContainer {
    flex: 0 0 70%;
  }
  .ButtonHolder {
    align-items: flex-end;
    padding-bottom: 16px;
    justify-content: flex-start;
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    flex: 0 0 20%;
    span {
      padding: 2px 15px;
    }
  }
  .viewContainer {
    .css-8r3dx3-control,
    .css-q5i2ns-control,
    .react-select__menu-list,
    .css-1wmipuf-option,
    .css-1irrqr3-option,
    .css-1hys26h-singleValue,
    .css-1ebjif1-option,
    .react-select__menu-list:active,
    .css-1wmipuf-option:active,
    .css-1ebjif1-option:active,
    .css-kpwi51-option:active,
    .css-kpwi51-option,
    .css-1wmipuf-option{
      background: ${theme.colors.INPUT_BACKGROUND} !important;
    }
    .react-datepicker-wrapper input{
      background-color: ${theme.colors.INPUT_BACKGROUND} !important;
    }
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    justify-content: space-between;
    form {
      @media (max-width: 1452px) {
        flex: 0 0 75%;
      }
     flex: 0 0 60%;
     justify-content: flex-start;
     .vertical-button .MuiButtonBase-root {
      padding: 1px 0;
      margin-top: 30px;
      .vertical-button {
        div:first-child {
          margin-right: 1%;
        }
      } 
     }
     .vertical-button .MuiBox-root{
        margin-right: 1rem;
     }
    }
    .buttonBulkUploadDoc {
      margin-top: 30px;
      padding:1px 30px;
      border: none;
    }
    .MuiBox-root-278 {
      padding-bottom: 16px;
      flex: 0 0 49%;
      justify-content: flex-end;
      display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
      align-items: flex-end;
      .MuiButton-outlined {
        padding: 2px 15px;
      }
      .MuiButton-root:hover {
        background-color: ${
          (theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN
        };
      }
    }
  }
  .displayContainer {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    justify-content: space-between;
    @media (max-width: ${theme.breakpoints.values.lg}px) {
      flex-direction: column;
    }
    .MuiBox-root p {
      margin-top: 3%;
    }
    .eldList {
      display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
      flex-wrap: wrap;
      justify-content: space-between;
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        justify-content: flex-start;
      }
      padding: 4% 0 2%;
      .MuiBox-root {
        flex: 0 0 22%;
        margin-top: 2px;
        .MuiGrid-container {
          justify-content: center;
          .MuiAvatar-img {
            width: 100px;
            height: 100px;
          }
        }
      }
      .MuiAvatar-root {
        width: 110px;
        height: 110px;
      }
    }
  }
  .rightSide {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    flex-direction: column;
    flex: 0 0 48%;
  }
  .linkContainer {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    justify-content: flex-end;
  }
  .next {
    justify-content: flex-end;
    flex: 0 0 50%;
  }
  .prev {
    justify-content: flex-start;
    flex: 0 0 50%;
  }
  `}
`;
const EldContainer = styled(Box)`
  ${({ theme }) => `
  ${(window?.$environment?.clutchNewUI || window.$environment.WHITE_THEME) ? `
  padding: 2%;
  background: ${theme.colors.FORM_BACKGROUND};
  ` : ''}
  flex: 0 0 48%;
  @media (min-width: ${theme.breakpoints.values.lg + 1}px) and (max-width: ${
    theme.breakpoints.values.xl
  }px) {
    flex: 0 0 45%;
  }
  .mainContainer {
    margin-top: 20px;
    .MuiTableHead-root .MuiTableCell-head {
      padding: 25px 15px 10px 7px !important;
    }
    .MuiTableBody-root .MuiTableCell-root {
      padding: 8px 10px;
      // .MuiAvatar-root {
      //   @media (min-width: ${theme.breakpoints.values.lg}px) {
      //     width: auto;
      //     height: auto;
      //   }
      // }
    }
  }
  `}
`;
const OtherContainer = styled(Box)`
  ${({ theme }) => `
  ${(window?.$environment?.clutchNewUI || window.$environment.WHITE_THEME) ? `
  padding: 2%;
  background: ${theme.colors.FORM_BACKGROUND};
  ` : ''}
  flex: 0 0 50%;
  @media (min-width: ${theme.breakpoints.values.lg + 1}px) and (max-width: ${
    theme.breakpoints.values.xl
  }px) {
    flex: 0 0 45%;
  }
  .linkContainer {
    padding-top: 4.5%;
    .MuiTableHead-root .MuiTableCell-head {
      padding: 17px 15px 17px 7px !important;
    }
    .MuiTableBody-root .MuiTableCell-root {
      padding: 8px 10px;
      // .MuiAvatar-root {
      //   @media (min-width: ${theme.breakpoints.values.lg}px) {
      //     width: auto;
      //     height: auto;
      //   }
      // }
    }
  }
  `}
`;
const ViolationContainer = styled(Box)`
  ${({ theme }) => `
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  border: 2px solid
    ${(theme.palette.primary || {}).border || theme.colors.PRIMARY_BORDER};
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  flex-direction: column;
  .heading {
    padding: 5px;
    color: ${
      (theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN
    };
    background-color: ${
      (theme.palette.primary || {}).main || theme.colors.PRIMARY_MAIN
    };
  }
  `}
`;
const Styled = {
  DailyLogContainer,
  EldContainer,
  OtherContainer,
  ViolationContainer
};

export default Styled;
