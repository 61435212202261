import PropTypes from 'prop-types';
import React, { lazy, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import RenderComponent from '@/components/modules/DynamicRenderPage';

import { hasValue, loadPageConfig, loadStyle, removeEmptyValues } from '@/utils/utils';
import moment from 'moment';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ParentStyled from './style';
let Styled = loadStyle(ParentStyled, 'pages/web/Camera');

const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.cameraVehicleEvents[0].name}`));
const Content = lazy(() => import(`@/${config.cameraVehicleEvents[1].name}`));

const CameraVehicleEvents = (parentProps) => {
  const {
    history,
    i18n,
    theme,
    eventData,
    fetchEventsCount,
    apiStatus
  } = parentProps;
  const commonParentProps = { history, i18n, Styled, theme };
  const { id } = useParams();

  const basicRequest = {
    displayStart: 1,
    displayLength: 10,
  };

  const [request, setRequest] = useState({
    ...basicRequest
  });

  const sortColumnMapping = {
    'event_type': 'eventType',
    'count': 'eventCount',
    'last_occurence': 'lastOccurrenceTime'
  }

  const changeRequest = (fields, dataUsageFlg) => {
    if (fields?.sortCol) {
      const type = fields.sortCol
      fields.sortCol = sortColumnMapping[type] ? sortColumnMapping[type] : fields.sortCol
    }
    setRequest({ ...basicRequest, ...fields });
  };


  const [pagination, setPagination] = useState(false);


  const apiId = "eventsCount"
  const hasSaveApiStatus = hasValue(apiStatus.status, 'api', apiId);

  useEffect(() => {

    let page = Math.round((request.displayStart / 10) + 1)
    const data = removeEmptyValues({
      eventType: request?.eventType,
      vehicleId: parseInt(id),
      pageNumber: page - 1,
      pageSize: request?.displayLength,
      sortCol: request?.sortCol,
      sortDir: request?.sortDir,
      endDate: request?.endDate ? moment(request?.endDate ).valueOf() : null,
      startDate: request?.startDate ? moment(request?.startDate).valueOf() : null,
    })
    fetchEventsCount({
        apiId,
        data: {  ...data  },
      });
  }, [request]);

  return (
    <>
      <RenderComponent
        {...config.cameraVehicleEvents[0]}
        parentProps={{
          ...parentProps,
          pagination,
          setPagination
        }}
        commonParentProps={commonParentProps}
        component={Title}
      />
      <RenderComponent
        {...config.cameraVehicleEvents[1]}
        parentProps={{
          ...parentProps,
          changeRequest,
          request,
        }}
        commonParentProps={commonParentProps}
        component={Content}
        eventData={eventData}
        pagination={pagination}
        setPagination={setPagination}
        hasSaveApiStatus={hasSaveApiStatus}
      />
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    filters: state.filters,
    eventData: state.cameraEvents?.eventData,
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchEventsCount: (data) => dispatch.cameraEvents.getCameraEventsByCount(data),
  };
};

export default connect(mapState, mapDispatch)(CameraVehicleEvents);

CameraVehicleEvents.propTypes = {
  callback: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
};
