import React from 'react';
// import Header from '../../../components/Header';

const Test = ({ auth, history, ...rest }) => {
  return (
    <>
      {/* <Header header={'Admin Dashboard'} auth={auth} history={history} /> */}
      <div
        style={{
          minHeight: 'calc(100vh - 181px)',
          padding: '40px',
          backgroundColor: 'colors.BLACK',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <h1 style={{ color: 'colors.PRIMARY_CONTRAST' }}>Under maintenance </h1>
      </div>
    </>
  );
};

export default Test;
