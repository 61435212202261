import React, { useEffect, lazy } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';

import { getLogsInfoDisplay, hasRestrictedRole } from '@/utils/utils';
import RenderComponent from '@/components/modules/DynamicRenderPage';

import ParentStyled from './style';
import { loadStyle, loadPageConfig } from '@/utils/utils';
import { pageUrls } from '@/config/constants/keys';
import { ROLES } from '@/config/constants/keys';

let Styled = loadStyle(ParentStyled, 'pages/web/EditDailyLog');
const Label = lazy(() => import(`@/components/UI/Label/${layout}`));

const config = loadPageConfig();
const layout = window?.$environment?.CURRENT_LAYOUT;

const Title =
  config.logsEdit[0] && lazy(() => import(`@/${config.logsEdit[0].name}`));
const Content =
  config.logsEdit[1] && lazy(() => import(`@/${config.logsEdit[1].name}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));

const EditDailyLog = (parentProps) => {
  const {
    getFilters,
    getDailyLogDetails,
    fetchViewData,
    module,
    callback,
    dailyLogDetails
  } = parentProps;

  const history = useHistory();
  const i18n = useTranslation();
  const match = useParams();
  const theme = useTheme();

  const viewOnly = module === 'logsView' || module === 'signedLogsView';
  const fetchDailyLogDetailsApiId = 'fetchDailyLogDetails';
  const apiId = 'getFilters';

  const commonParentProps = { history, i18n, Styled, theme };

  useEffect(() => {
    viewOnly && module !== 'signedLogsView' ?      
       fetchViewData({
          data: { id: match.id },
          apiId: fetchDailyLogDetailsApiId,
          callback
        })
      : getDailyLogDetails({
          data: { id: match.id },
          apiId: fetchDailyLogDetailsApiId,
          udEdit: config[module][1]?.udEdit || false,
          callback,
          history
        });
    getFilters({ apiId });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Styled.ParentWrapper>
      {Title && (
        <RenderComponent
          {...config[module][0]}
          parentProps={parentProps}
          commonParentProps={commonParentProps}
          component={Title}
          header={
            viewOnly
              ? window.$environment.CURRENT_LAYOUT === 'SpireonNew'? 'View ':
              'logs.manageLogsHeader'
              : window.$environment.CURRENT_LAYOUT === 'SpireonNew'
              ? 'Edit'
              : 'logs.editHeading'
          }
          withBackground={window.$environment.CURRENT_LAYOUT === 'SpireonNew'}
          childComponent={() => (
            <Label className="logsInfoDisplay">
              {getLogsInfoDisplay(
                i18n,
                dailyLogDetails?.originalData?.dailyLog?.driverName,
                dailyLogDetails?.originalData?.dailyLog?.day
              )}
            </Label>
          )}
        />
      )}
      <>
        {Content && dailyLogDetails.originalData?.dailyLog?.id ? (
          <RenderComponent
            {...config[module][1]}
            parentProps={parentProps}
            commonParentProps={commonParentProps}
            component={Content}
            id={match.id}
          />
        ) : dailyLogDetails.originalData?.dailyLog &&
          !dailyLogDetails.originalData?.dailyLog?.id &&
          viewOnly ? (
          <div style={{ ...theme.palette.contentStyle }}>
            <Styled.Editnodata>
              <div className="messageSection">
                {i18n.t('logs.noDataMessage')}
              </div>
              <Button
                label={i18n.t('logs.backToDashboard')}
                type={'submit'}
                onClick={() => {
                  history.push(pageUrls.dashboard);
                }}
              />
            </Styled.Editnodata>
          </div>
        ) : null}
      </>
    </Styled.ParentWrapper>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    manageLogList: state.manageLogList,
    dailyLogDetails: state.dailyLogDetails,
    filters: state.filters
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchManageLogList: (data) => dispatch.manageLogList.fetchData(data),
    setSelectedManageLogItem: (payload) =>
      dispatch.manageLogList.setSelectedItem(payload),
    saveDailyLogDetails: (payload) =>
      dispatch.dailyLogDetails.updateData(payload),
    getDailyLogDetails: (payload) =>
      dispatch.dailyLogDetails.fetchData(payload),
    clearUpdateDailyLogDetails: () =>
      dispatch.dailyLogDetails.clearUpdateData(),
    setDailyLogEntries: (data) =>
      dispatch.dailyLogDetails.setDailyLogEntries(data),
    getFilters: (data) => dispatch.filters.fetchData(data),
    fetchViewData: (payload) => dispatch.dailyLogDetails.fetchViewData(payload)
  };
};

export default connect(mapState, mapDispatch)(EditDailyLog);
EditDailyLog.defaultTypes = {};
EditDailyLog.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  manageLogList: PropTypes.object.isRequired,
  fetchManageLogList: PropTypes.func.isRequired,
  setSelectedManageLogItem: PropTypes.func.isRequired,
  saveDailyLogDetails: PropTypes.func.isRequired,
  getDailyLogDetails: PropTypes.func.isRequired,
  clearUpdateDailyLogDetails: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  getFilters: PropTypes.func.isRequired
};
