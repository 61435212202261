import { chartTypes } from "@/config/constants/keys";

const initialState = {
  data: {
  },
  radiusData: {},
  quickStatsList: {},
  reportList: {},
  chartsData: {}
};

export const scoreCard = {
  state: { ...initialState },
  reducers: {
    fetchSuccess(state, payload) {
      return {
        ...state,
        data: { ...state.data, ...payload }
      };
    },
    fetchQuickStatsSuccess(state, payload) {
      return {
        ...state,
        data: {
          ...state.data,
          quickStatsData: { ...payload }
        }
      }
    },
    fetchReportsListSuccess(state, payload) {
      return {
        ...state,
        reportList: { ...payload }
      }
    },
    fetchChartSuccess(state, payload) {
      return {
        ...state,
        chartsData: {
          ...state.chartsData,
          [chartTypes[payload.chartType]]: payload.chartList
        }
      }
    },
    fetchQuickStatsListSuccess(state, payload) {
      return {
        ...state,
        quickStatsList: { ...payload }
      }
    },
    fetchRadiusSuccess(state, payload) {
      return {
        ...state,
        radiusData: { ...payload }
      }
    }
  },
  effects: (dispatch) => ({
    async fetchReportChart(payload) {
      try {
        const getApi = await import(
          `../../../config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('scorecardChart');

        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...apiObject,
              url: apiObject.url
            },
            data: payload.data?.request
          },
          id: payload.apiId
        });
        response && this.fetchChartSuccess({ chartList: response, chartType: payload.data.request.chartType });
      } catch (error) {
        // this.listFailed();
      }
    },
    async fetchReportList(payload) {
      try {
        const getApi = await import(
          `../../../config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('scorecardReportsApi');

        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...apiObject,
              url: apiObject.url
            },
            data: payload.data?.request
          },
          id: payload.apiId
        });
        response && this.fetchReportsListSuccess(response);
      } catch (error) {
        // this.listFailed();
      }
    },
    async fetchQuickStatsList(payload) {
      try {
        const getApi = await import(
          `../../../config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('quickStatsListApi');

        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...apiObject,
              url: apiObject.url.replace(":id", payload.data.companyId) + `/${payload.data.period}/${payload.data.event}`
            },
            data: payload.data?.request
          },
          id: payload.apiId
        });
        response && this.fetchQuickStatsListSuccess(response);
      } catch (error) {
        // this.listFailed();
      }
    },
    async fetchRadiusData(payload, rootState) {
      try {
        const getApi = await import(
          `../../../config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('scoreCardRadiusApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...apiObject,
              url: apiObject.url.replace(":id", payload.data.id)
            },
          },
          id: payload.apiId
        });
        if (response) {
          this.fetchRadiusSuccess(response)
        }
      } catch (error) {
        // this.listFailed();
        payload.callback && payload.callback('warning', error.message);
      }
    },
    async getVehicles(payload, rootState) {
      try {
        const getApi = await import(
          `../../../config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('vehiclesForRadiusApi');

        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...apiObject,
              url: apiObject.url.replace(':companyId', payload.data.companyId).replace(':enum', payload.data.complianceEnum)
            }
          },
          id: payload.apiId,
          callback: payload.callback,
        });
        payload.callback('success', response, payload);
      } catch (error) { }
    },
    async fetchQuickStatsData(payload, rootState) {
      try {
        const getApi = await import(
          `../../../config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('quickStatsApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...apiObject,
              url: apiObject.url.replace(":id", payload.data.id) + `/${payload.data.period}`
            },
          },
          id: payload.apiId
        });
        if (response) {
          this.fetchQuickStatsSuccess(response)
        }
      } catch (error) {
        // this.listFailed();
        payload.callback && payload.callback('warning', error.message);
      }
    },
    async fetchData(payload, rootState) {
      try {
        const getApi = await import(
          `../../../config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('scoreCardApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...apiObject,
              url: apiObject.url.replace(":id", payload.data.id)
            },
          },
          id: payload.apiId
        });
        if (response) {
          this.fetchSuccess(response)
        }
      } catch (error) {
        // this.listFailed();
        payload.callback && payload.callback('warning', error.message);
      }
    },
  })
};
