
import styled from 'styled-components';
import Parent from './style';

const HeaderContainer = styled(Parent.HeaderContainer)`
${({ theme, vertical }) => `
padding: 15px 0;
#container{
  position: relative;
  padding-right: 0;
    h1{
        font-family: ${theme.palette.titleFont};
        padding-left: 20px;
    }
}
 
`}
`;
const Styled = {
  HeaderContainer
};
export default Styled;


