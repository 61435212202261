import React, { lazy, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import TopActions from './TopActions';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));

const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Component {...contentProps} filters={filters} />
    </Box>
  );
};
export default Index;

const Component = ({
  fuelConsumption,
  filterSuccess,
  Styled,
  i18n,
  topAction,
  filters,
  pagination,
  setPagination,
  vehicleList,
  driverList,
  getVehicleListDropDown,
  getDrivers,
  getStateListData,
  addEditFuelData,
  callback,
}) => {
  const columns = [
    {
      id: 'vehicleName',
      numeric: false,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('common.vehicle')
    },
    {
      id: 'drivers',
      numeric: false,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('fuelConsumption.currentDrivers')
    },
    {
      id: 'jurisdictionLocation',
      numeric: false,
      disablePadding: false,
      needSorting: true,
      label: window.$environment?.SERVICE_PROVIDER == 'tp' ? i18n.t('fuelConsumption.JurisdictionLocation') : i18n.t('fuelConsumption.Jurisdiction')
    },
    {
      id: 'milesPerGallon',
      numeric: true,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('fuelConsumption.miles')
    },
    {
      id: 'history',
      numeric: false,
      disablePadding: false,
      label: i18n.t('fuelConsumption.history')
    }
  ];
  const generateRows = () => {
    const updatedList = fuelConsumption?.filteredList?.map((item) => {
      let result = { ...item };
      let jurisdictionLocation = ``;
      if(item.jurisdiction == 'Unknown' || !item.jurisdiction) jurisdictionLocation = item.location;
      if(item.jurisdiction == 'Unknown') item.jurisdiction =  i18n.t('fuelConsumption.unknown');
      else jurisdictionLocation = `${item.jurisdiction} - ${item.location}`
      result.jurisdictionLocation = window.$environment?.SERVICE_PROVIDER == 'tp' ? jurisdictionLocation : item.jurisdiction
      result.vehicleName = (
        <a
          className="showDoc"
          href={`/vehicle/edit/${item.vehicle?.id}`}
          target="_blank"
        >
          {item.vehicleName}
        </a>
      );
      result.history = (
        <a
          className="showDoc"
          href={`/fuel/history/${item.vehicle?.id}`}
          target="_blank"
        >
          {i18n.t('View History')}
        </a>
      );
      return result;
    });
    return updatedList;
  };
  useEffect(() => {
    generateRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fuelConsumption?.filteredList]);

  const information = i18n.t('common.tableEmptyMessage');
  return (
    <>
      <Styled.Wrapper>
        {topAction && (
          <TopActions
            {...{
              Styled,
              fuelConsumption,
              i18n,
              filterSuccess,
              filters,
              setPagination,
              vehicleList,
              driverList,
              getVehicleListDropDown,
              getDrivers,
              getStateListData,
              addEditFuelData,
              callback
            }}
          />
        )}
        <Styled.TableWrapper>
          <div className="quickFilter"></div>
          <Table
            type="client"
            title=""
            columns={[...columns]}
            data={generateRows()}
            emptyMessage={information}
            actions={[]}
            defaultSortBy={{ sortBy: 'vehicleName', order: 'asc' }}
            itemsPerPage={15}
            i18n={i18n}
            className="fuelConsumption"
            pageinationReset={pagination}
            pagenationClicked={(e) => {
              setPagination(!e);
            }}
          />
        </Styled.TableWrapper>
      </Styled.Wrapper>
    </>
  );
};

Component.propTypes = {
  filterSuccess: PropTypes.func.isRequired
};
