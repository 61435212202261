import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import SubHeader from '../../../components/SubHeader/Clutch';
import Button from '../../../components/UI/Button/Clutch';
import LoginForm from './Form';
import { useTranslation } from 'react-i18next';
const layout = window.$environment?.CURRENT_LAYOUT;
const SvgIcon = lazy(() => import(`@/components/SvgIcon/${layout}`));
const FormStyle =
  require(`../../../components/UI/Form/${layout}/serverStyle`).default;

const Component = ({ Styled, ...rest }) => {
  const { i18n } = useTranslation();
  return (
    <Styled.LoginParentWrapper>
      <Styled.LeftWrapper>
        <div className="login-right-wrap">
          <LoginForm {...rest} Styled={Styled} />
        </div>
      </Styled.LeftWrapper>
      <Styled.RightWrapper>
        {/* <Styled.Banner>
          <h1>Help comply with HoS and DVIR for complete peace of mind</h1>
          <p>
            WEBFLEET ELD Manager allows business to have the complete peace of
            mind that they comply with ELD legislation around HoS.
          </p>
          <Styled.LoginButtonWrapper newUI={true}>
            <FormStyle.ButtonsWrapper>
              <Button
                type="submit"
                label={
                  <span>
                    {i18n.t('Find out more')}
                    <SvgIcon
                      value={'findmore'}
                      // onClick={() => history.goBack()}
                    />
                  </span>
                }
                onClick={() => {}}
                id="findMoreButton"
              ></Button>
            </FormStyle.ButtonsWrapper>
          </Styled.LoginButtonWrapper>
        </Styled.Banner> */}
      </Styled.RightWrapper>
    </Styled.LoginParentWrapper>
  );
};

export default Component;
Component.defaultProps = { hasRegistration: true };
Component.propTypes = {
  Styled: PropTypes.object.isRequired
};
