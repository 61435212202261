
import styled from 'styled-components';
import Parent from './style';
import Box from '@material-ui/core/Box';
const LogBookContainer = styled(Parent.LogBookContainer)`
${({ theme }) => `
padding: 0;
flex: 0 0 73%;
form {
  align-items: flex-end;
  .vertical{
    align-self: flex-start;
  }
}
.dailyDocument{
    display: none;
}
.buttonBulkDownload {
    min-height: 36px;
    padding: 0 15px !important;
}
.viewContainer{
  padding-top: 60px;
  padding-left: 2%;
}
.leftWrapper {
  flex: 0 0 49.5%;
}
.viewContainer{
  .buttonBulkDownload {
      min-height: 36px;
      padding: 0 15px !important;
      margin-top: 31px !important;
      font-size: 14px !important;
  }
  .vertical-button button {
    margin-top: 8px !important;
  }
  .react-datepicker__day:not(.react-datepicker__day--highlighted):not(.react-datepicker__day--disabled) {
    background: #ffffff !important;
    border: 2px solid #d3d3d3 !important;
    border-bottom: 2px solid #0379fc !important;
    color: #555555;
  }
}
.datepopup{
  margin-left: 39.5%;
  .vertical {
    flex: 0 0 35%;
  }
}
.heading {
  padding: 10px !important;
  font-size: 22px;
}
.viewContainrer {
  padding-left:2%;
}
.displayContainer { 
  padding : 0 2%;
}
.linkContainer a {
  background: #007aff;
  color: ${theme.colors.WHITE};
  font-size: 14px;
  padding-top: 7px;
  height: 36px;
  border-radius: 2px;
}
.linkContainer div {
    margin-left: 3px;
}
.rightSide {
  flex: 0 0 49%;
  flex-direction: column;
    .linkContainer {
        justify-content: flex-start;
        padding-left: 29%;
    }
    div[type='info'] p {
        text-align: left;
        font-size: 16px;
        padding-top: 10px;
        padding-left: 30%;
    }
}
.verticalCal {
  position: relative;
  width: 10%;
  .MuiSvgIcon-root {
      position: absolute;
      right: 0;
      width: 18px;
      height: 18px;
      color: #767676;
      bottom: 4px;
  }
}
.MuiPaper-root{
  overflow-y: scroll;
  max-height: 164px;
}
`}
`;
const PdfContainer = styled(Parent.PdfContainer)``;
const MapContainer = styled(Parent.MapContainer)`
max-height: 100px !important;
`;
const MapWrapper = styled(Parent.MapWrapper)`
    display: none;
`;
const ViolationContainer = styled(Parent.ViolationContainer)`
${({ theme }) => `
border: 0;
// padding-left: 30%;
.heading {
  background: transparent;
  color:#000;
  font-size: 20px;
  padding: 0 !important;
  // padding-left: 30% !important;
}
.MuiPaper-root {
  border-top: 2px solid #e1e1e1;
}
`}
`;
const LogbookWrap = styled(Parent.LogbookWrap)``;
const Styled = {
    LogBookContainer,
    PdfContainer,
    MapContainer,
    MapWrapper,
    ViolationContainer,
    LogbookWrap
  };
  
  export default Styled;