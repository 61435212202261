
import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
    .quickFilter {
        display: block;
        background: -webkit-linear-gradient(top, #d0d8db 0%, #b3bec3 100%);
        color: #f4f6f9;
        height: 20px;
    }
`}
`;
const Styled = { Wrapper };

export default Styled;