import React, { useState, useEffect, lazy } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ViewForm from './Form';
import { hasValue, isDriver, isdvirOnly } from '@/utils/utils';
import { pageUrls } from '@/config/constants/keys';
import {
  UI_DATE_FORMAT
} from '@/config/constants/static';
import Violations from '../Dashboard/Notfications';
const layout = window?.$environment?.CURRENT_LAYOUT;

const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const PdfView = lazy(() => import(`./OveridePdfViewer`));
const Link = lazy(() => import(`@/components/UI/Link`));

const Component = ({
  apiStatus,
  callback,
  fetchLogBookView,
  getLogbookEvents,
  updateShowEvents,
  i18n,
  history,
  drivers,
  dates,
  logBook,
  currentDriver,
  viewApiId,
  fetchLogbookBreadcrumb,
  fetchLogbookBreadcrumbApiId,
  Styled,
  getDocumentToken,
  unSignedDates,
  regeneratePdf
}) => {
  const [formValues, setFormValues] = useState({
    driverId: logBook?.selectedDailyLog?.driverId,
    driverName: logBook?.selectedDailyLog?.driverName,
    type: 'DAILY_LOG'
  });

  useEffect(() => {
    setFormValues({
      driverId: logBook?.selectedDailyLog?.driverId,
      driverName: logBook?.selectedDailyLog?.driverName,
      type: 'DAILY_LOG'
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logBook?.selectedDailyLog?.driverId]);

  const hasLogBookApiStatus = hasValue(apiStatus.status, 'api', viewApiId);
  const [newDate, setNewDate] = useState(null);
  useEffect(() => {
    setNewDate(logBook?.selectedDailyLog?.day);
  }, [logBook]);
  const isDvirOnlyFlagEnable = isdvirOnly()
  return (
    <Styled.LogBookContainer includeRealtimeLog>
      {hasLogBookApiStatus && hasLogBookApiStatus.errorMessage && (
        <Message
          type="error"
          message={i18n.t(hasLogBookApiStatus.errorMessage)}
        />
      )}
      <div className="viewContainer">
        <ViewForm
          apiStatus={apiStatus}
          fetchLogBookView={fetchLogBookView}
          history={history}
          callback={callback}
          drivers={drivers}
          dates={dates}
          currentDate={logBook?.selectedDailyLog?.day || ''}
          currentDriver={currentDriver}
          fetchLogbookBreadcrumb={fetchLogbookBreadcrumb}
          fetchLogbookBreadcrumbApiId={fetchLogbookBreadcrumbApiId}
          setFormValues={setFormValues}
          formValues={formValues}
          changedDate={(e) => setNewDate(e)}
          getDocumentToken={getDocumentToken}
          unSignedDates={unSignedDates}
        />
        <div className="linkContainer commonLinks">
          {!isDriver() && !isDvirOnlyFlagEnable && (
            <div>
              <Link
                className="uppercase dailyDocument"
                href={`${pageUrls.dailyDocuments}?driver=${logBook?.selectedDailyLog?.driverId
                  }&day=${moment(logBook?.selectedDailyLog?.day).format(
                    UI_DATE_FORMAT
                  )}`}
              >
                {i18n.t('logs.viewDocument')}
              </Link>
            </div>
          )}
        </div>
      </div>

      <div className="displayContainer">
        <div className="leftWrapper">
          {logBook?.dailyLogs?.length ? (
            <>
              <PdfView
                list={logBook?.dailyLogs}
                i18n={i18n}
                date={newDate}
                driver={currentDriver.value}
                documentToken={logBook?.documentToken}
                Styled={Styled}
                updateShowEvents={updateShowEvents}
                getLogbookEvents={(idPub) => getLogbookEvents({
                  apiId: 'LoadMoreEvents',
                  callback: callback,
                  data: { id: idPub }
                })}
                fetchLogBookView={(date) =>
                  fetchLogBookView({
                    apiId: viewApiId,
                    callback: callback,
                    data: { data: { date, driverId: currentDriver.value } }
                  })
                }
                getDocumentToken={getDocumentToken}
                eventsData={logBook?.eventsData}
                selectedDailyLog={logBook?.selectedDailyLog}
                regeneratePdf={({ date, callbackFuntion }) =>
                  regeneratePdf({
                    apiId: 'fetchLogbookBreadcrumb',
                    callback: callback,
                    data: { data: { date: newDate, driverId: currentDriver.value } },
                    newCallback: () => {
                      fetchLogBookView({
                        apiId: 'fetchLogBookView',
                        callback: callback,
                        data: { data: { driverId: currentDriver.value, date: newDate } },
                        history
                      });
                      callbackFuntion();
                    }
                  })
                }
              />
            </>

          ) : null}
        </div>
        <div className="rightSide">
          <Violations
            potentialViolations={logBook?.violationsListDto || []}
            heading={'Violations'}
          />
        </div>
      </div>
    </Styled.LogBookContainer>
  );
};

export default Component;

Component.defaultProps = {
  drivers: {}
};

Component.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  manageLogList: PropTypes.object.isRequired,
  logBook: PropTypes.object.isRequired,
  handleLogBookStatus: PropTypes.func.isRequired,
  clearApiStatus: PropTypes.func.isRequired,
  fetchLogbookBreadcrumb: PropTypes.func.isRequired,
  breadCrumbForMap: PropTypes.array.isRequired
};
