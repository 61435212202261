import styled from 'styled-components';
import Parent from './style';
const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
    padding: 0;
    .CSAForms {
        padding-left: 21%;
        padding-top: 13px;
    }
    #msgWrap p {
        color: ${theme.colors.PRIMARY_CONTRAST};
    }
    `}
`;
const TopWrapper = styled(Parent.TopWrapper)`
  
`;
const TableWrapper = styled(Parent.TableWrapper)`
    ${({ theme }) => `
    padding-top:0;
       border-top: 2px solid ${theme.colors.BLACK};
    `}
`;
const Styled = { Wrapper, TopWrapper, TableWrapper };

export default Styled;
