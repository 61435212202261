import React, { lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { UI_DATE_FORMAT_WITH_TIME_24 } from '@/config/constants/static';
import moment from 'moment';
import { getImagePath} from '@/utils/utils';
import { imageUrls } from '@/config/constants/keys';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const Modal = require(`@/components/UI/Modal/${layout}`);
const FuelComsumptionAddEdit = lazy(() => import(`@/components/modules/FuelComsumptionAddEdit`));

const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Component {...contentProps} />
    </Box>
  );
};
export default Index;
const Component = ({ 
  refillsHistory, 
  Styled, 
  viewReport,

  callback,
  vehicleList,
  driverList,
  getVehicleListDropDown,
  getDrivers,
  getStateListData,
  addEditFuelData,
  fuelConsumption
 }) => {
  const uploadApi = 'fuelAddEditApi';
  const [open, setOpen] = useState(false);
  const [withCloseIcon, setwithCloseIcon] = useState(true);
  const [backgroundModal, setbackgroundModal] = useState(false);
  const closeModal = (event) => {
    setOpen(false);
  };
  const clickedModal = (e) => {
    setOpen(true);
  };

  const modalDataHandler = (data) => {
    getVehicleListDropDown({ apiId: 'getVehicleListDropDown' });
    getDrivers({ apiId: 'getDrivers' })
    getStateListData({ apiId: 'getStateListData', data: { idPub: data.idPub } })
  }

  const { i18n } = useTranslation();
  const columns = [
    {
      id: 'date',
      numeric: false,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('common.date')
    },
    {
      id: 'driver',
      numeric: false,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('common.driver')
    },
    {
      id: 'odometer',
      numeric: true,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('refillsHistory.odometer')
    },
    {
      id: 'gallons',
      numeric: true,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('refillsHistory.fuelAmount')
    },
    {
      id: 'cost',
      numeric: true,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('refillsHistory.cost')
    },
    {
      id: 'jurisdictionLocation',
      numeric: false,
      disablePadding: false,
      needSorting: true,
      label: window.$environment?.SERVICE_PROVIDER == 'tp' ? i18n.t('fuelConsumption.JurisdictionLocation') : i18n.t('fuelConsumption.Jurisdiction')
    },
    {
      id: 'receipt',
      numeric: false,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('eld.receipts')
    },
    {
      id: 'actions',
      label: i18n.t('Actions')
    }
  ];
  const generateRows = () => {
    const updatedList = refillsHistory?.map((item) => {
      var result = { ...item };
      result.date = moment(new Date(item.date)).format(
        UI_DATE_FORMAT_WITH_TIME_24
      );
      let jurisdictionLocation = ``;
      if(item.jurisdiction == 'Unknown' || !item.jurisdiction) jurisdictionLocation = item.location;
      if(item.jurisdiction == 'Unknown') item.jurisdiction =  i18n.t('fuelConsumption.unknown');
      else jurisdictionLocation = `${item.jurisdiction} - ${item.location}`
      result.jurisdictionLocation = window.$environment?.SERVICE_PROVIDER == 'tp' ? jurisdictionLocation : item.jurisdiction
      result.receipt = (
        <a
          onClick={() =>
            viewReport({
              apiId: 'viewReportApi',
              data: item?.id
            })
          }
          className="viewReport"
          target="_blank"
        >
        {i18n.t('accidents.viewReceipt')}
        </a>
      );
      return result;
    });
    return updatedList || [];
  };

  const information = i18n.t('common.tableEmptyMessage');
  return (
    <>
      <Styled.Wrapper>
        <Styled.TableWrapper>
        <div className="quickFilter"></div>
        <div className="tableWrapper">
          <Table
            type="client"
            title=""
            columns={[...columns]}
            data={generateRows()}
            emptyMessage={information}
            defaultSortBy={{ sortBy: 'date', order: 'desc' }}
            itemsPerPage={10}
            i18n={i18n}
            actions = {
              [{
                  icon: (row, index) => {
                     return ( < Icon src = {
                           getImagePath(imageUrls.edit)
                          }
                          tooltip = {
                              i18n.t('Edit')
                          }
                          onClick={() => {
                            modalDataHandler({
                              idPub: row.idPub
                            })
                            setbackgroundModal(false);
                            setwithCloseIcon(true);
                            clickedModal();
                          }}
                        />)
                    }
                },
              ]
            }
          />
        </div>
        </Styled.TableWrapper>
      </Styled.Wrapper>

      {(driverList?.length > 0) && (vehicleList?.length > 0) && fuelConsumption?.stateListData && <Modal.default
          open={open}
          width="700px"
          setOpen={setOpen}
          data={
            {
              header: '',
              content: () => {
                return (
                  <FuelComsumptionAddEdit
                    currentData={fuelConsumption?.stateListData?.Fuel}
                    saveDocument={addEditFuelData}
                    uploadApi={uploadApi}
                    stateListData={fuelConsumption?.stateListData}
                    driverList={driverList}
                    vehicleList={vehicleList}
                    closeModal={closeModal}
                    callback={callback}
                    parent="FuelComsumptionReport"
                  />
                  );
                }
            }
          }
          onCloseModal={closeModal}
          background="black"
          withCloseIcon={withCloseIcon}
          backgroundModal={backgroundModal}
          deletePopup
        />
        }
    </>
  );
};

Component.propTypes = {
  vehicles: PropTypes.array.isRequired
};
