let eldX_reseller = window.location.pathname.split("/")[1] || sessionStorage.getItem("eldX_reseller")
export default {
  'client.gorillasafety.com': {
    APP_LAYOUT: 'NewUi',
    BASE_URL: 'https://client.gorillasafety.com',
    BE_BASE_URL: 'https://client.gorillasafety.com/api',
    BASE_CAMERA_URL: 'https://client.gorillasafety.com/api/waylens-client/',
    CAMERA_TOKEN: '9cf11b4ccf46a304a871ad80ec360a4fde57c12820953b61732f287f10e833e9',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'gs',
    SWITCH_APP_LAYOUT: 'OldUi',
    GMAP_KEY: 'AIzaSyC6lPNUb2_l3lx71qPxEV2u6VMlzOoWFp8',
    BRAINTREE_KEY: 'production_gprdt8c6_jg6zmkfq4s58kh6y',
    GANALYTICS_KEY: 'UA-69509752-1',
    SITE_KEY: '6LcHgdcZAAAAAMsmu5CAGngZCOnaFjLj1nkyrzwz',
    SUPPORT_EMAIL: 'support@gorillasafety.com'
  },

  'login.clutcheld.com': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://dev.clutch.com',
    BE_BASE_URL: 'https://login.clutcheld.com/api',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'clutch',
    GMAP_KEY: 'AIzaSyAk3JohIR_jOk9zBWb6lk60U1hAswHP_4o',
    BRAINTREE_KEY: 'production_gprdt8c6_jg6zmkfq4s58kh6y',
    SITE_KEY: '6LcHgdcZAAAAAMsmu5CAGngZCOnaFjLj1nkyrzwz'
  },
  'eld-pro.com': {
    APP_LAYOUT: 'OldUi',
    BASE_URL: 'https://eld-pro.com',
    BE_BASE_URL: 'https://eld-pro.com/api',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'em',
    GMAP_KEY: 'AIzaSyCC8c21znoJly89haW74TPwR6QoliU7vzk',
    BRAINTREE_KEY: 'production_gprdt8c6_jg6zmkfq4s58kh6y',
    SITE_KEY: '6LcHgdcZAAAAAMsmu5CAGngZCOnaFjLj1nkyrzwz'
  },
  'www.eld-pro.com': {
    APP_LAYOUT: 'OldUi',
    BASE_URL: 'https://eld-pro.com',
    BE_BASE_URL: 'https://eld-pro.com/api',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'em',
    GMAP_KEY: 'AIzaSyCC8c21znoJly89haW74TPwR6QoliU7vzk',
    BRAINTREE_KEY: 'production_gprdt8c6_jg6zmkfq4s58kh6y',
    SITE_KEY: '6LcHgdcZAAAAAMsmu5CAGngZCOnaFjLj1nkyrzwz'
  },
  'compliance.spireon.com': {
    APP_LAYOUT: 'SpireonNew',
    BASE_URL: 'https://compliance.spireon.com/',
    BE_BASE_URL: 'https://compliance.spireon.com/api',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'spireon',
    SWITCH_APP_LAYOUT: 'SpireonOld',
    GMAP_KEY: 'AIzaSyDKNSVf3qP5_Ck_SQ_BmzHTrocqO2XhUXA',
    BRAINTREE_KEY: 'production_gprdt8c6_jg6zmkfq4s58kh6y',
    SITE_KEY: '6LcHgdcZAAAAAMsmu5CAGngZCOnaFjLj1nkyrzwz'
  },
  'truckerpatheldpro.com': {
    APP_LAYOUT: 'OldUi',
    BASE_URL: 'https://truckerpatheldpro.com',
    BE_BASE_URL: 'https://truckerpatheldpro.com/api',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'tp',
    GMAP_KEY: 'AIzaSyDqXRRnP06BWdgEAWRZQteFk7rOFj_9n28',
    BRAINTREE_KEY: 'production_gprdt8c6_jg6zmkfq4s58kh6y',
    SITE_KEY: '6LcHgdcZAAAAAMsmu5CAGngZCOnaFjLj1nkyrzwz',
    // ZENDESK_KEY: '8f409486-f4c4-4a8a-a45c-3be20c7627e1'
  },
  'www.truckerpatheldpro.com': {
    APP_LAYOUT: 'OldUi',
    BASE_URL: 'https://truckerpatheldpro.com',
    BE_BASE_URL: 'https://truckerpatheldpro.com/api',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'tp',
    GMAP_KEY: 'AIzaSyDqXRRnP06BWdgEAWRZQteFk7rOFj_9n28',
    BRAINTREE_KEY: 'production_gprdt8c6_jg6zmkfq4s58kh6y',
    SITE_KEY: '6LcHgdcZAAAAAMsmu5CAGngZCOnaFjLj1nkyrzwz',
    // ZENDESK_KEY: '8f409486-f4c4-4a8a-a45c-3be20c7627e1'
  },
  'trackoneld.com': {
    APP_LAYOUT: 'OldUi',
    BASE_URL: 'https://trackoneld.com',
    BE_BASE_URL: 'https://trackoneld.com/api',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'trackon',
    GMAP_KEY: 'AIzaSyC6lPNUb2_l3lx71qPxEV2u6VMlzOoWFp8',
    BRAINTREE_KEY: 'production_gprdt8c6_jg6zmkfq4s58kh6y',
    REG_LINK: 'https://client.gorillasafety.com/registration/step1',
    SITE_KEY: '6LcHgdcZAAAAAMsmu5CAGngZCOnaFjLj1nkyrzwz'
  },
  'www.trackoneld.com': {
    APP_LAYOUT: 'OldUi',
    BASE_URL: 'https://trackoneld.com',
    BE_BASE_URL: 'https://trackoneld.com/api',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'trackon',
    GMAP_KEY: 'AIzaSyC6lPNUb2_l3lx71qPxEV2u6VMlzOoWFp8',
    BRAINTREE_KEY: 'production_gprdt8c6_jg6zmkfq4s58kh6y',
    REG_LINK: 'https://client.gorillasafety.com/registration/step1',
    SITE_KEY: '6LcHgdcZAAAAAMsmu5CAGngZCOnaFjLj1nkyrzwz'
  },
  'pro.eldmandate.biz': {
    APP_LAYOUT: 'OldUi',
    BASE_URL: 'https://pro.eldmandate.biz/',
    BE_BASE_URL: 'https://pro.eldmandate.biz//api',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'em',
    GMAP_KEY: 'AIzaSyCC8c21znoJly89haW74TPwR6QoliU7vzk',
    BRAINTREE_KEY: 'production_gprdt8c6_jg6zmkfq4s58kh6y',
    SITE_KEY: '6LcHgdcZAAAAAMsmu5CAGngZCOnaFjLj1nkyrzwz'
  },
  'spireoneldprod.gorillasafety.com': {
    APP_LAYOUT: 'SpireonNew',
    BASE_URL: 'https://spireoneldprod.gorillasafety.com/',
    BE_BASE_URL: 'https://spireoneldprod.gorillasafety.com/api',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'spireon',
    SWITCH_APP_LAYOUT: 'SpireonOld',
    GMAP_KEY: 'AIzaSyDKNSVf3qP5_Ck_SQ_BmzHTrocqO2XhUXA',
    BRAINTREE_KEY: 'production_gprdt8c6_jg6zmkfq4s58kh6y',
    SITE_KEY: '6LcHgdcZAAAAAMsmu5CAGngZCOnaFjLj1nkyrzwz'
  },
  'spireoneld.gorillasafety.com': {
    APP_LAYOUT: 'SpireonNew',
    BASE_URL: 'https://spireoneld.gorillasafety.com/',
    BE_BASE_URL: 'https://spireoneld.gorillasafety.com/api',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'spireon',
    SWITCH_APP_LAYOUT: 'SpireonOld',
    GMAP_KEY: 'AIzaSyDKNSVf3qP5_Ck_SQ_BmzHTrocqO2XhUXA',
    BRAINTREE_KEY: 'production_gprdt8c6_jg6zmkfq4s58kh6y',
    SITE_KEY: '6LcHgdcZAAAAAMsmu5CAGngZCOnaFjLj1nkyrzwz'
  },
  'compliance.gpsservicenetwork.com': {
    APP_LAYOUT: 'SpireonNew',
    BASE_URL: 'https://compliance.gpsservicenetwork.com/',
    BE_BASE_URL: 'https://compliance.gpsservicenetwork.com/api',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'spireon',
    SWITCH_APP_LAYOUT: 'SpireonOld',
    GMAP_KEY: 'AIzaSyDKNSVf3qP5_Ck_SQ_BmzHTrocqO2XhUXA',
    BRAINTREE_KEY: 'production_gprdt8c6_jg6zmkfq4s58kh6y',
    SITE_KEY: '6LcHgdcZAAAAAMsmu5CAGngZCOnaFjLj1nkyrzwz'
  },
  'compliance.fleetlocate.com': {
    APP_LAYOUT: 'SpireonNew',
    BASE_URL: 'https://compliance.fleetlocate.com/',
    BE_BASE_URL: 'https://compliance.fleetlocate.com/api',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'spireon',
    SWITCH_APP_LAYOUT: 'SpireonOld',
    GMAP_KEY: 'AIzaSyDKNSVf3qP5_Ck_SQ_BmzHTrocqO2XhUXA',
    BRAINTREE_KEY: 'production_gprdt8c6_jg6zmkfq4s58kh6y',
    SITE_KEY: '6LcHgdcZAAAAAMsmu5CAGngZCOnaFjLj1nkyrzwz'
  },
  'eldplus.cyntrx.com': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://eldplus.cyntrx.com',
    BE_BASE_URL: 'https://eldplus.cyntrx.com/api',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'cyntrx',
    GMAP_KEY: 'AIzaSyAk3JohIR_jOk9zBWb6lk60U1hAswHP_4o',
    BRAINTREE_KEY: 'production_gprdt8c6_jg6zmkfq4s58kh6y',
    SITE_KEY: '6LcHgdcZAAAAAMsmu5CAGngZCOnaFjLj1nkyrzwz'
  },
  'edvir.cyntrx.com': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://edvir.cyntrx.com',
    BE_BASE_URL: 'https://edvir.cyntrx.com/api',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'edvir',
    GMAP_KEY: 'AIzaSyAk3JohIR_jOk9zBWb6lk60U1hAswHP_4o',
    BRAINTREE_KEY: 'production_gprdt8c6_jg6zmkfq4s58kh6y',
    SITE_KEY: '6LcHgdcZAAAAAMsmu5CAGngZCOnaFjLj1nkyrzwz'
  },
  'fleetmanagement.constitutionrisk.com': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://fleetmanagement.constitutionrisk.com',
    BE_BASE_URL: 'https://fleetmanagement.constitutionrisk.com/api',
    BASE_CAMERA_URL: 'https://fleetmanagement.constitutionrisk.com/api/waylens-client/',
    CAMERA_TOKEN: '9cf11b4ccf46a304a871ad80ec360a4fde57c12820953b61732f287f10e833e9',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'riskTheory',
    GMAP_KEY: 'AIzaSyAk3JohIR_jOk9zBWb6lk60U1hAswHP_4o',
    BRAINTREE_KEY: 'production_gprdt8c6_jg6zmkfq4s58kh6y',
    SITE_KEY: '6LcHgdcZAAAAAMsmu5CAGngZCOnaFjLj1nkyrzwz'
  },
  'www.eld.webfleet.com': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://www.eld.webfleet.com',
    BE_BASE_URL: 'https://www.eld.webfleet.com/api',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'webfleet',
    GMAP_KEY: 'AIzaSyAk3JohIR_jOk9zBWb6lk60U1hAswHP_4o',
    BRAINTREE_KEY: 'production_gprdt8c6_jg6zmkfq4s58kh6y',
    SITE_KEY: '6LcHgdcZAAAAAMsmu5CAGngZCOnaFjLj1nkyrzwz',
    clutchNewUI: true
  },
  'eldx.gorillasafety.com': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://eldx.gorillasafety.com',
    BE_BASE_URL: 'https://eldx.gorillasafety.com/api',
    COOKIE_EXPIRY: 1,
    WHITE_THEME: true,
    SERVICE_PROVIDER: 'eldx',
    RESELLER: '/' + eldX_reseller,
    S3_URL: `https://eldx-reseller-prod.s3.amazonaws.com/${eldX_reseller}`,
    GMAP_KEY: 'AIzaSyAk3JohIR_jOk9zBWb6lk60U1hAswHP_4o',
    BRAINTREE_KEY: 'production_gprdt8c6_jg6zmkfq4s58kh6y',
    SITE_KEY: '6LcHgdcZAAAAAMsmu5CAGngZCOnaFjLj1nkyrzwz'
  },
  'livetrack.site' : {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://livetrack.site',
    BE_BASE_URL: 'https://livetrack.site/api',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'at',
    GMAP_KEY: 'AIzaSyAk3JohIR_jOk9zBWb6lk60U1hAswHP_4o',
    BRAINTREE_KEY: 'production_gprdt8c6_jg6zmkfq4s58kh6y',
    SITE_KEY: '6LcHgdcZAAAAAMsmu5CAGngZCOnaFjLj1nkyrzwz'
  },
  'hos.simplexeld.com': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://hos.simplexeld.com',
    BE_BASE_URL: 'https://hos.simplexeld.com/api',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'simplex',
    WHITE_THEME: true,
    GMAP_KEY: 'AIzaSyAk3JohIR_jOk9zBWb6lk60U1hAswHP_4o',
    BRAINTREE_KEY: 'production_gprdt8c6_jg6zmkfq4s58kh6y',
    SITE_KEY: '6LcHgdcZAAAAAMsmu5CAGngZCOnaFjLj1nkyrzwz',
    SUPPORT_EMAIL: 'ELDSupport@simplexgroup.net'
  },
  'elddvir.trakitgps.com': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://elddvir.trakitgps.com',
    BE_BASE_URL: 'https://elddvir.trakitgps.com/api',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'trackit',
    GMAP_KEY: 'AIzaSyAk3JohIR_jOk9zBWb6lk60U1hAswHP_4o',
    BRAINTREE_KEY: 'production_gprdt8c6_jg6zmkfq4s58kh6y',
    SITE_KEY: '6LcHgdcZAAAAAMsmu5CAGngZCOnaFjLj1nkyrzwz',
    SUPPORT_EMAIL: 'alert@trakitgps.com'
  },
  'fleettrackhos.lbtelematics.net': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://fleettrackhos.lbtelematics.net',
    BE_BASE_URL: 'https://fleettrackhos.lbtelematics.net/api',
    COOKIE_EXPIRY: 1,
    WHITE_THEME: true,
    SERVICE_PROVIDER: 'ft',
    GMAP_KEY: 'AIzaSyAk3JohIR_jOk9zBWb6lk60U1hAswHP_4o',
    BRAINTREE_KEY: 'production_gprdt8c6_jg6zmkfq4s58kh6y',
    SITE_KEY: '6LcHgdcZAAAAAMsmu5CAGngZCOnaFjLj1nkyrzwz',
    SUPPORT_EMAIL: 'support@lbtechnology.com'
  },
  'elogs.hcssapps.com': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://elogs.hcssapps.com',
    BE_BASE_URL: 'https://elogs.hcssapps.com/api',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'hcss',
    GMAP_KEY: 'AIzaSyAk3JohIR_jOk9zBWb6lk60U1hAswHP_4o',
    BRAINTREE_KEY: 'production_gprdt8c6_jg6zmkfq4s58kh6y',
    SITE_KEY: '6LcHgdcZAAAAAMsmu5CAGngZCOnaFjLj1nkyrzwz',
    SUPPORT_EMAIL: 'support@hcss.com'
  },
  'track.patrioteld.com': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://track.patrioteld.com',
    BE_BASE_URL: 'https://track.patrioteld.com/api',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'patriot',
    GMAP_KEY: 'AIzaSyAk3JohIR_jOk9zBWb6lk60U1hAswHP_4o',
    BRAINTREE_KEY: 'production_gprdt8c6_jg6zmkfq4s58kh6y',
    SITE_KEY: '6LcHgdcZAAAAAMsmu5CAGngZCOnaFjLj1nkyrzwz',
    SUPPORT_EMAIL: 'support@patrioteld.com '
  }
};
