import React, { useState, useEffect, lazy } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Link = lazy(() => import(`@/components/UI/Link`));
const Modal = require(`@/components/UI/Modal/${layout}`).default;
const isEs = () => localStorage.getItem(window.$environment?.SERVICE_PROVIDER + 'Language') == 'es';
const ExportPopup = ({ isOpen, setIsOpen, Styled, logBook, getDocumentToken }) => {
  const i18n = useTranslation();
  useEffect(() => {
    if (isOpen) {
      clickedModal1();
    }
  }, [isOpen]);

  const [open, setOpen] = useState(false);
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const beBaseUrl = window?.$environment?.BE_BASE_URL;
  useEffect(() => {
    setModalListObject({
      content: () => {
        return (
          <Styled.ExportOptionWrapper>
            <Link
              onClick={() => {
                handleExportAsCsv();
              }}
            >
              {' '}
              CSV
            </Link>
            <Link
              onClick={() => {
                handleExportAsPdf();
              }}
            >
              {' '}
              PDF
            </Link>
            <Link
              onClick={() => {
                handleExportPrint();
              }}
            >
              {i18n.t('Print')}
            </Link>
          </Styled.ExportOptionWrapper>
        );
      }
    });
  }, [logBook?.documentToken]);
  const handleExportAsCsv = () => {
    getDocumentToken && getDocumentToken({ apiId: 'exportUser' })
    let pageurl = window.location.href.includes('alerts')
      ? '/alerts/export/CSV?token='
      : window.location.href.includes('driverStatus')
        ? `/driverStatus/export/CSV?id=${query.get('id')}&token=`
        : '/violations/exportNonDeletedViolations?token=';
    let url = beBaseUrl + pageurl + logBook.documentToken;
    const iframe = document.getElementById('importUserTemplateFrame');
    iframe.contentWindow.fetch(url, {
        headers: {
            'Accept-Language': isEs() ? 'es' : 'en'
        }
    }).then(response => {
        if(response.ok) {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'file.csv';
                a.click();
            });
        } else {
            console.log('Error: ' + response.status);
        }
    }).catch(error => {
        console.log(error);
    });
    closeModal1();
  };
  const handleExportAsPdf = (fields) => {
    getDocumentToken && getDocumentToken({ apiId: 'exportUser' })
    let pageurl = window.location.href.includes('alerts')
      ? '/alerts/export/PDF?token='
      : window.location.href.includes('driverStatus')
        ? `/driverStatus/export/PDF?id=${query.get('id')}&token=`
        : '/violations/exportNonDeletedViolations/pdf?token=';
    let url = beBaseUrl + pageurl + logBook.documentToken;
    const iframe = document.getElementById('importUserTemplateFrame');
    iframe.contentWindow.fetch(url, {
        headers: {
          'Accept-Language': isEs() ? 'es' : 'en'
        }
    }).then(response => {
        if(response.ok) {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'file.pdf';
                a.click();
            });
        } else {
            console.log('Error: ' + response.status);
        }
    }).catch(error => {
        console.log(error);
    });
    closeModal1();
  };
  const handleExportPrint = (fields) => {
    getDocumentToken && getDocumentToken({ apiId: 'exportUser' })
    // let driverValToShow = document.getElementsByClassName(
    //   'MuiInputBase-input'
    // )[0].value;
    // let dateFrom = document.querySelectorAll("input[name='dateFrom']")[0].value;
    // let dateTo = document.querySelectorAll("input[name='dateTo']")[0].value;
    // let fromDateItems = dateFrom.replace(/\//g, '-').split('-');
    // let todateItems = dateTo.replace(/\//g, '-').split('-');
    // let dataToSend = {
    //   id: driverValToShow,
    //   dateTimeFrom:
    //     fromDateItems[2] + '-' + fromDateItems[0] + '-' + fromDateItems[1],
    //   dateTimeTo: todateItems[2] + '-' + todateItems[0] + '-' + todateItems[1]
    // };
    // let driverParam = driverValToShow !== '' ? dataToSend.id : '';
    // let FromParam = dateFrom !== '' ? dataToSend.dateTimeFrom : '';
    // let chartToParam = dateTo !== '' ? dataToSend.dateTimeTo : '';
    let languageParam = isEs() ? 'es' : 'en'; // default to English
    let pageurl = window.location.href.includes('alerts')
      ? 'alerts?'
      : window.location.href.includes('driverStatus')
        ? `driving/status?id=${query.get('id')}&`
        : 'violations?';
    
    let printUrl =
      beBaseUrl +
      `/document/html/print/${pageurl}` +
      'token=' +
      logBook.documentToken +
      '&language=' + languageParam;
    let pdfWindow = window.open(printUrl);
    pdfWindow.focus();
    // pdfWindow.onload = () => {
    //   pdfWindow.print();
    //   pdfWindow.close();
    // };
  };
  const closeModal1 = (event) => {
    setIsOpen(false);
    setOpen(false);
  };

  const clickedModal1 = (e) => {
    setOpen(true);
  };
  const onCloseModal = (e) => {
    setOpen(false);
  };
  const [ModalListObject, setModalListObject] = useState({
    header: '',
    content: () => {
      return <> </>;
    }
  });
  return (
    <>
      {isOpen && (
        <Modal
          open={open}
          setOpen={setOpen}
          data={ModalListObject}
          isExportPopup
          onClose={() => {
            setIsOpen(false);
          }}
          className="modalExport"
        />
      )}
      <iframe id="importUserTemplateFrame" style={{ display: 'none' }}></iframe>
    </>
  );
};

export default ExportPopup;
