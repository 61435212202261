import { isInsuracePortalUser } from '@/utils/utils';
import moment from 'moment';
import React, { lazy, useState } from 'react';
const layout = window?.$environment?.CURRENT_LAYOUT;
const Modal = require(`@/components/UI/Modal/${layout}`).default;
const Table = lazy(() => import(`../../../components/UI/Table/${layout}`));

const RadiusComplianceAlerts = ({
  list,
  i18n
}) => {
  const insuracePortalUserRole = isInsuracePortalUser()
  const [openModalDesc, setModalDesc] = useState(false);
  const [ModalDescData, setModalDescData] = useState({
    header: '',
    content: () => {
      return <> </>;
    }
  });

  const descColumns = [
    {
      id: 'description',
      disablePadding: false,
      label: i18n.t('dashboard.tabs.radiusComplianceAlerts.description'),
      needSorting: true
    },
    {
      id: 'date',
      disablePadding: false,
      label: i18n.t('dashboard.tabs.radiusComplianceAlerts.date'),
      needSorting: true
    },
  ]

  const columns = [
    ...(insuracePortalUserRole ? [{
      id: 'companyName',
      disablePadding: false,
      label: i18n.t('dashboard.tabs.radiusComplianceAlerts.company'),
      needSorting: true
    },
    {
      id: 'type',
      disablePadding: false,
      label: i18n.t('dashboard.tabs.radiusComplianceAlerts.type'),
      needSorting: true
    }] : []),
    {
      id: 'description',
      disablePadding: false,
      label: i18n.t('dashboard.tabs.radiusComplianceAlerts.description'),
      needSorting: true
    },
    {
      id: 'date',
      disablePadding: false,
      label: i18n.t('dashboard.tabs.radiusComplianceAlerts.date'),
      needSorting: true
    },
    ...(insuracePortalUserRole ? [{
      id: 'lossControl',
      disablePadding: false,
      label: i18n.t('dashboard.tabs.radiusComplianceAlerts.lossControl'),
      needSorting: true
    },
    {
      id: 'underwriter',
      disablePadding: false,
      label: i18n.t('dashboard.tabs.radiusComplianceAlerts.underwriter'),
      needSorting: true
    }] : [])
  ];
  const generateRows = () => {
    list.map(ele => {
      if (ele.descriptions?.length > 1) {
        ele.description = <div className="descPopUp" onClick={() => handleDecPopup(ele)}>
          {ele.type.toLowerCase().includes('radius') ? i18n.t('multipleRadiusAlert') : ele.type.toLowerCase().includes('accident') ? i18n.t('multipleAccidentAlert') : ele.type.toLowerCase().includes('dvir') ? i18n.t('multipleDvirAlert') : i18n.t('multipleAlert')}
        </div>
      } else if (ele.descriptions?.length == 1) {
        ele.description = ele.descriptions[0]?.description
      }
    })
    return list;
  };
  const generateDescRows = (list) => {
    list.map(ele => {
      let date = new Date(ele.date)
      date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()))
      ele.date = moment(date).format(
        'MM/DD/YYYY HH:mm:ss'
      )
    })
    return list
  }
  const handleDecPopup = (data) => {
    setModalDesc(true)
    setModalDescData({
      content: () => {
        return (
          <div className="descModal">
            <div className="headWrapper">{data.companyName}</div>
            <Table
              type="client"
              defaultSortBy={{ sortBy: 'date', order: 'desc' }}
              title=""
              columns={[...descColumns]}
              data={generateDescRows(data.descriptions)}
              emptyMessage={information}
              itemsPerPage={5}
              id="dashBoardAlertsDescriptionPopUp"
              actions={[]}
              i18n={i18n}
            />
          </div>
        )
      }
    })
  }
  const information = i18n.t('common.tableEmptyMessage');
  return (
    <>
      <Table
        type="client"
        defaultSortBy={{ sortBy: 'date', order: 'desc' }}
        title=""
        columns={[...columns]}
        data={generateRows()}
        emptyMessage={information}
        itemsPerPage={10}
        id="dashBoardAlerts"
        noPagination
        actions={[]}
        i18n={i18n}
      />
      <Modal
        open={openModalDesc}
        setOpen={setModalDesc}
        data={ModalDescData}
        deletePopup
        withCloseIcon
        width={'800px'}
        alignItemsLeft
      />
    </>
  );
};

export default RadiusComplianceAlerts;

RadiusComplianceAlerts.defaultProps = {};
