import styled from 'styled-components';

const Wrapper = styled.div`
${({ theme }) => `
  #fleetManagerInfoSubmit {
    display: none;
  }
  .addressWrapper,
  .bottomWrapper {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
    .MuiBox-root {
      flex-direction: column;
    }
  }
  .companyWrapper,
  .addressWrapper {
    padding-bottom: 1% !important;
  }
  .companyWrapper,
  .phoneWrapper {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
  }
  .HeadingWrap {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
    .index {
      margin-top: 30px;
      margin-left: 20px;
      background-color: rgba(255, 255, 255, .15);
      font-size: 16px;
      height: 39px;
      min-width: 65px;
      padding: 6px;
      border-radius: 3px;
      text-align: center;
    }
  }
  #buttonWrapper {
    justify-content: space-between;
    width: 57%;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS styles go here */
      width: 63%;
    }
    @media (max-width: ${theme.breakpoints.values.md - 1}px) {
      flex-direction: column-reverse;
     }
  }
  .otherHalf {
    color: #ffffff;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.6;
  }
  .indexNo {
    font-size: 16px;
    color: #fff !important;
    font-family: "SF Pro Display - Semibold";
    font-weight: 600;
    padding-right: 4px;

  }
  .nextHalf {
    opacity: 0.76;
    color: #ffffff;
    font-size: 16px;
    font-weight: 300;
    span {
      padding-right: 4px;
    }
  }
  .stateSelect label {
    padding-bottom: 14px;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS styles go here */
      padding-bottom: 5px;
    }
  }
  .addressWrapper div[type='error'] p {
    line-height: 1;
    padding-bottom: 5px;
  }
  `}
`;
const TextWrapper = styled.div`
  /* flex: 0 0 25%; */
`;

const FieldsWrapper = styled.div`
${({ theme }) => `
flex: 0 0 30%;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  flex-direction: column;
  padding-right: 3%;
  @media (max-width: ${theme.breakpoints.values.md - 1}px) {
    flex: 2 0 30%;
   }
    .popupContainer {
      display: flex;
      padding-bottom: 35px;
      position: relative;
      div[type="error"] {
        position: absolute;
        bottom: 8px;
      }
      .selectConnection {
        background: transparent;
      }
    }
   .connectionType {
    font-size: 16px !important;
    font-weight: 600;
    padding-bottom: 35px !important;
    padding-top: 20px;
    .label {
      color: #7B7C82;
      font-weight: normal;
      padding-left: 10px;
    }
   }
   .suggested .typePopupContainer {
     padding-right: 55px;
   }
   .typePopupContainer + .MuiBox-root {
    padding-top: 12px;
   }
   .listNo {
    padding-top: 10px;
    text-align: center;
    font-weight: 600;
    font-size: 13px;
    color: #fff;
  }
   .selectConnection {
    border: 1px solid #ffffff;
    width: 102px;
    border-radius: 3px;
    height 35px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.6px;
    text-transform: uppercase;
   }
  `}
`;

const Styled = {
  Wrapper,
  TextWrapper,
  FieldsWrapper
};

export default Styled;
