import { editProfileApi, getProfileApi, getCsvStatusHistoryApi, generateMfaQrCodeApi, validateMfaSetupApi, modifyMfaSettingsApi} from '../../../config/apis';
import { PASSWORD_RESET_MESSAGE } from '../../../config/constants/static';

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  cellPhone: '',
  password: '',
  confirmPassword: '',
  mfaStatus: '',
  qrString: ''
};

export const profile = {
  state: { ...initialState },
  reducers: {
    setFetchSuccess(state, payload) {
      return {
        ...payload
      };
    },
    setFetchFailed() {
      return { ...initialState };
    },
    setUpdateSuccess(state, payload) {
      return {
        ...payload
      };
    },
    setUpdateFailed() {
      return { ...initialState };
    },
    setFetchSucessQrString(state, payload) {
      return {
        ...state,
        qrString: payload
      }
    },
  },
  effects: (dispatch) => ({
    async fetchData(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            data: { ...payload.data },
            api: getProfileApi
          },
          id: payload.apiId
        });
        this.setFetchSuccess(response);
      } catch (error) {
        this.setFetchFailed();
      }
    },
    async generateMfaQrCode(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            data: { ...payload.data },
            api: generateMfaQrCodeApi
          },
          id: payload.apiId
        });
        this.setFetchSucessQrString(response);
      } catch (error) {
        // this.setFetchFailed();
      }
    },
    async validateMfaSetup(payload) {
      try {
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            data: { ...payload.data },
            api: validateMfaSetupApi
          },
          id: payload.apiId
        });
        this.fetchData({ apiId: 'fetchProfile'})
      } catch (error) {
        // this.setFetchFailed();
      }
    },
    async modifyMfaSettings(payload) {
      try {
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            data: { ...payload.data },
            api: modifyMfaSettingsApi
          },
          id: payload.apiId
        });
        this.fetchData({ apiId: 'fetchProfile'})
      } catch (error) {
      }
    },
    async updateData(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            data: payload.data,
            api: editProfileApi
          },
          id: payload.apiId
        });
        payload.callback('success', response)
        if (!response) {
          payload.callback('success', PASSWORD_RESET_MESSAGE);
        } else {
          payload.callback('mailIdChanged', PASSWORD_RESET_MESSAGE);
        }
      } catch (error) {
        // this.setUpdateFailed();
      }
    },
    async getCsvStatusHistory(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: getCsvStatusHistoryApi
          },
          id: payload.apiId
        });
      } catch (error) {
      }
    }
  })
};
