import React, { lazy, useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import Filters from './Filters';
import { behaviourList } from '@/config/constants/keys';
import { fade } from '@material-ui/core/styles/colorManipulator';

const layout = window?.$environment?.CURRENT_LAYOUT;
const BarChart = lazy(() => import(`@/components/UI/Graph/BarGraph/${layout}`));

const BarGraph = ({
    data, period
}) => {
    const [values, setInitialValues] = useState({
        month: null
    })
    const [month, setMonth] = useState('')
    const [chartData, setChartData] = useState([])
    const [monthList, setMonthList] = useState([])
    const [chartFlg, setChartFlag] = useState(true)

    useEffect(() => {
        handleData()
    }, [period, month])

    useEffect(() => {
        if (month) setMonth('')
        else handleData()
    }, [data])

    const handleData = () => {
        if (data && period) {
            setChartFlag(false)
            if (period == 'MONTHLY') {
                setChartData(data)
            } else {
                if (month) {
                    setChartData(data.filter(ele => ele.day.includes(month)))
                } else {
                    let months = Array.from(new Set(data.map(ele => ele.day.substring(0, 7))))
                    let results = []
                    months.forEach(ele => {
                        let obj = {
                            day: ele,
                            behaviorScore: 0,
                            severityScore: 0,
                            totalScore: 0
                        }
                        data.forEach(item => {
                            if (item.day.includes(ele)) {
                                obj.behaviorScore += item.behaviorScore
                                obj.severityScore += item.severityScore
                                obj.totalScore += item.totalScore
                            }
                        })
                        results.push(obj)
                    })
                    setChartData(results)
                    setMonthList(months.map(ele => ({ label: ele, value: ele })))
                }
            }
            setTimeout(() => {
                setChartFlag(true)
            })
        }
    }

    const theme = useTheme();
    return (
        <div className='graphContainer'>
            {period == 'YEARLY' ? <Filters
                filterList={(data) => {
                    if (month != data.month) {
                        setChartFlag(false)
                        setMonth(data.month)
                    }
                }}
                monthList={monthList}
                handleReset={() => {
                    if (month) {
                        setChartFlag(false)
                        setTimeout(() => {
                            setMonth('')
                        })
                    }
                }}
                values={values}
                setInitialValues={setInitialValues}
            /> : null}
            {chartData.length && chartFlg ? <BarChart
                width={'470px'}
                height={'230px'}
                labels={chartData.map(ele => ele.day) || []}
                datasets={[
                    {
                        label: 'Sum of Behavior Score',
                        data: chartData?.map(ele => ele.behaviorScore) || [],
                        backgroundColor: fade(theme.colors.CHART_BLUE, 0.5),
                        borderColor: theme.colors.CHART_BLUE,
                        borderWidth: 1,
                        lineTension: 0
                    },
                    {
                        label: 'Sum of Severity Score',
                        data: chartData?.map(ele => ele.severityScore) || [],
                        backgroundColor: fade(theme.colors.CHART_PURPLE, 0.5),
                        borderColor: theme.colors.CHART_PURPLE,
                        borderWidth: 1,
                        lineTension: 0
                    },
                    {
                        label: 'Sum of Total Score',
                        data: chartData?.map(ele => ele.totalScore) || [],
                        backgroundColor: fade(theme.colors.CHART_GREEN, 0.5),
                        borderColor: theme.colors.CHART_GREEN,
                        borderWidth: 1,
                        lineTension: 0
                    }
                ]}
                noMaxValue
                areagraph
                title={''}
                theme={theme}
                noHyperLink
            ></BarChart> : <div className='noData'>
            No data available
        </div>}

        </div>
    )
}

export default BarGraph