import React from 'react';
import Modal from '../Base';
import { ModalWrapper, ModalWrapper2, ParentWrapper } from './style';

const Index = (props) => {
  return (
    <Modal
      {...props}
      ModalWrapper={ModalWrapper}
      ModalWrapper2={ModalWrapper2}
      ParentWrapper={ParentWrapper}
    />
  );
};

export default Index;
