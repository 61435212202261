import React from 'react';
import PropTypes from 'prop-types';

import Terminal from './Terminal';

const TerminalList = ({
  data,
  history,
  callback,
  updateTerminal,
  deleteTerminal,
  saveDepartment,
  updateDepartment,
  saveSubDepartment,
  updateSubDepartment,
  getTmezonePopup,
  deleteDepartment,
  deleteSubDepartment,
  Styled,
  isButtonStyle,
  isCrudRestricted,
  isTermCrudRestricted,
  isDeptCrudRestricted,
  isLocCrudRestricted,
  isShowDotNumber,
  neglectAccessLevel
}) => {
  return (
    <Styled.TerminalListWrapper>
      <Terminal
        data={data}
        history={history}
        callback={callback}
        updateTerminal={updateTerminal}
        deleteTerminal={deleteTerminal}
        saveDepartment={saveDepartment}
        updateDepartment={updateDepartment}
        saveSubDepartment={saveSubDepartment}
        updateSubDepartment={updateSubDepartment}
        getTmezonePopup={getTmezonePopup}
        deleteDepartment={deleteDepartment}
        deleteSubDepartment={deleteSubDepartment}
        Styled={Styled}
        isButtonStyle={isButtonStyle}
        isCrudRestricted={isCrudRestricted}
        isTermCrudRestricted={isTermCrudRestricted}
        isDeptCrudRestricted={isDeptCrudRestricted}
        isLocCrudRestricted={isLocCrudRestricted}
        isShowDotNumber={isShowDotNumber}
        neglectAccessLevel={neglectAccessLevel}
      />
    </Styled.TerminalListWrapper>
  );
};

export default TerminalList;

TerminalList.propTypes = {
  data: PropTypes.object
};
