import React, { useEffect, lazy } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { getNewObject, loadPageConfig } from '@/utils/utils';

const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.addWorkOrders[0].name}`));
const Content = lazy(() => import(`@/${config.addWorkOrders[1].name}`));

const AddWorkOrders = (parentProps) => {
  const { fetchAddWorkOrders } = parentProps;

  const history = useHistory();
  const i18n = useTranslation();
  const theme = useTheme();

  const commonParentProps = { history, i18n, theme };
  const apiId = 'fetchWorkOrders';

  useEffect(() => {
    fetchAddWorkOrders({
      apiId: apiId
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <RenderComponent
        {...config.addWorkOrders[0]}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        component={Title}
      />
      <RenderComponent
        {...config.addWorkOrders[1]}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        component={Content}
      />
    </>
  );
};

const RenderComponent = ({
  name,
  props,
  commonProps,
  title,
  parentProps,
  commonParentProps,
  component,
  ...rest
}) => {
  const Component = component;
  let newProps = props?.length ? getNewObject(parentProps, props) : {};
  newProps = {
    ...newProps,
    ...(commonProps?.length ? getNewObject(commonParentProps, commonProps) : {})
  };
  return <Component {...rest} {...newProps} />;
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    regions: state.regions,
    documents: state.documents,
    user: state.user,
    logBook: state.logBook,
    workOrders: state.workOrders
  };
};
const mapDispatch = (dispatch) => {
  return {
    saveDocument: (data) => dispatch.workOrders.saveDocument(data),
    deleteDocument: (data) => dispatch.workOrders.deleteDocument(data),
    fetchCountData: (data) => dispatch.dashboard.fetchCountData(data),
    downloadDocument: (data) => dispatch.documents.downloadDocument(data),
    getDocumentToken: (data) => dispatch.logBook.documentToken(data),
    handleLogout: (data) => dispatch.login.handleLogout(data),
    fetchAddWorkOrders: (data) => dispatch.workOrders.fetchAddData(data),
    saveAddWorkOrders: (data) => dispatch.workOrders.saveAddData(data),
    clearState:(data)=> dispatch.viewReport.clearReceipt()
  };
};

export default connect(mapState, mapDispatch)(AddWorkOrders);
AddWorkOrders.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  fetchTerminals: PropTypes.func.isRequired,
  fetchDepartments: PropTypes.func.isRequired,
  saveDocument: PropTypes.func.isRequired,
  deleteDocument: PropTypes.func.isRequired,
  fetchUserData: PropTypes.func.isRequired,
  saveUserData: PropTypes.func.isRequired,
  fetchSubDepartments: PropTypes.func.isRequired
};
