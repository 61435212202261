import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
  padding: 0;
`;

const SearchWrapper = styled(Parent.SearchWrapper)`
  flex: 0 0 68%;
  padding-left: 20px;
  margin-top: -14px;
  #simpleSearchWrapper {
    width: 27% !important;
    .MuiInputBase-root.MuiInput-root {
      height: 22px !important;
    }
  }
  
`;

const MapWrapper = styled(Parent.MapWrapper)`
  padding: 0;
`;
const TableWrapper = styled(Parent.TableWrapper)`
  ${({ theme }) => `
  .driverstatusTitle{
    color: #42505a;
    font: bold 12px "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding: 5px;
    padding-left: 10px;
    background: -webkit-linear-gradient(top, #d0d8db 0%, #b3bec3 100%);
    align-items: center;
    margin-top: 30px;
    .iconContainer{
        padding-bottom: 0;
        img {
            width: 24px;
            height: 24px;
        }
    } 
    .tooltiptext {
      display: none;
    }
  }
  .spireonOldOnly{
      display: block;
      width: 120px;
  }
`}
`;
const Styled = { Wrapper, SearchWrapper, MapWrapper, TableWrapper };

export default Styled;
