import React, { useState, lazy, useEffect } from 'react';
const layout = window.$environment?.CURRENT_LAYOUT;
const Link = lazy(() => import(`@/components/UI/Link`));
const beBaseUrl = window?.$environment?.BE_BASE_URL;
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));

const PdfView = ({
  list,
  i18n,
  date,
  driver,
  Styled,
  documentToken,
  fetchLogBookView,
  getLogbookEvents,
  updateShowEvents,
  fetchLogbookBreadcrumb,
  id,
  getDocumentToken,
  selectedDailyLog,
  regeneratePdf,
  currentIndex,
  setCurrentIndex,
  eventsData
}) => {
  const getDocumentTokenApiId = 'getDocToken';
  // const [currentIndex, setCurrentIndex] = useState(0);
  const getIndex = () => {
    const formattedDate = date?.replace('/', '-');
    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      if (element.day === formattedDate) {
        return index;
      }
    }
    return 0;
  };
  useEffect(() => {
    if (date && date.includes('-'))
      getDocumentToken({ apiId: getDocumentTokenApiId, callback: tokenPdfShow });
  }, [date, driver]);

  const tokenPdfShow = (type, data) => {
    const index = getIndex();
    setCurrentIndex(index);
    setDocLiink(
      beBaseUrl +
      '/document/open/' +
      selectedDailyLog?.document?.id +
      '?token=' +
      data +
      '&output=embed'
    );
  }
  const [docLink, setDocLiink] = useState('');

  const getListElements = (data) => {
    if(data?.length) return data?.map(ele => {
      return <ul>
        <li>
          <span className="name">{ele.status}</span>
          <span className="message">{ele.start}</span>
        </li>
      </ul>
    })
    else return <ul>
    <li style={
      {
        textAlign: 'center'
      }
    }>
      <span className="name">{i18n.t('No data available')}</span>
    </li>
  </ul>
  }

  return (
    <Styled.PdfContainer>
      {/* <div className="linkContainer">
        <div className="prev">
          <Link
            className="uppercase"
            onClick={() => {
              if (currentIndex < list.length - 1) {
                setCurrentIndex(currentIndex + 1);
                fetchLogBookView(list[currentIndex + 1]?.day || date);
                fetchLogbookBreadcrumb({
                  apiId: 'fetchLogbookBreadcrumb',
                  data: {
                    id,
                    day: list[currentIndex + 1]?.day || date
                  }
                });
              }
            }}
          >
            {i18n.t('common.previous')}
          </Link>
        </div>
        <div className="next">
          <Link
            className="uppercase"
            onClick={() => {
              if (currentIndex > 0) {
                setCurrentIndex(currentIndex - 1);
                fetchLogBookView(list[currentIndex - 1]?.day || date);
                fetchLogbookBreadcrumb({
                  apiId: 'fetchLogbookBreadcrumb',
                  data: {
                    id,
                    day: list[currentIndex - 1]?.day || date
                  }
                });
              }
            }}
          >
            {i18n.t('common.next')}
          </Link>
        </div>
      </div> */}
      <div className="pdfWrap">
        <iframe src={docLink} width="100%" height="720px"></iframe>
        <div id="regeneratePdf-wrapper">
          <Link id="regeneratePdf"
            onClick={() => regeneratePdf({
              date: (list[currentIndex - 1]?.day || date), callbackFuntion: () => {
                if (date && date.includes('-')) getDocumentToken({ apiId: getDocumentTokenApiId, callback: tokenPdfShow });
              }
            })}
          >
            {i18n.t('common.regeneratePdf')}
          </Link>
        </div>
      </div>
      <Button
        label={eventsData?.showEvents ? i18n.t('common.viewLess') : i18n.t('common.viewMore')}
        onClick={(e, d) => {
          if (eventsData?.showEvents) updateShowEvents(false)
          else {
            if (Boolean(eventsData?.data?.engineEvents)) updateShowEvents(true)
            else getLogbookEvents(selectedDailyLog?.idPub || '')
          }
        }}
      />
      {eventsData?.showEvents ? <div className={'events'}>
        <div className='eventContainer'>
          <div className="heading">{i18n.t('Engine Events')}</div>
          {getListElements(eventsData?.data?.engineEvents)}
        </div>
        <div className='eventContainer'>
          <div className="heading">{i18n.t('Login Events')}</div>
          {getListElements(eventsData?.data?.loginEvents)}
        </div>
      </div> : null
      }
    </Styled.PdfContainer>
  );
};

export default PdfView;
