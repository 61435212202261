import React from 'react';
import Message from '../Base';
import { PopperContainer, ModalHeader, ModalContent, DocClose } from './style';
const Index = (props) => {
  return (
    <Message
      {...props}
      PopperContainer={PopperContainer}
      ModalHeader={ModalHeader}
      ModalContent={ModalContent}
      DocClose={DocClose}
    />
  );
};

export default Index;
