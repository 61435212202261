import React, { useEffect, lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import {
  getNewObject,
  generateDropdownOptions,
  loadPageConfig,
  isInsuracePortalUser,
  objectArrayFromSameField
} from '@/utils/utils';
import { pageUrls } from '@/config/constants/keys';

const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.editVehicle[0].name}`));
const Content = lazy(() => import(`@/${config.editVehicle[1].name}`));
const EditVehicle = (parentProps) => {
  const {
    fetchEditVehicleData,
    getDocumentToken,
    vehicle,
    regions,
    removeApiStatus,
    clearReceipt,
    fetchCustomDvir,
    callback,
    fetchCompanyList,
    fetchInsuranceEditVehicleData
  } = parentProps;

  const history = useHistory();
  const i18n = useTranslation();
  const theme = useTheme();
  const match = useParams();

  const commonParentProps = { history, i18n, theme };
  const fetchEditVehicleDataApi = 'fetchEditVehicleData';
  const fetchInsuranceEditVehicleDataApi = 'fetchInsuranceEditVehicleData';
  const vehicleId = match.id;
  const getDocumentTokenApiId = 'getDocToken';

  useEffect(() => {
    fetchCustomDvir({
      apiId: 'fetchCustomDvir'
    });
    isInsuracePortalUser() ? fetchInsuranceEditVehicleData({
      data: { id: vehicleId },
      apiId: fetchInsuranceEditVehicleDataApi,
      callback,
      history,
      redirectionUrl: pageUrls.vehicleList
    }) : fetchEditVehicleData({
      data: { id: vehicleId },
      apiId: fetchEditVehicleDataApi,
      callback,
      history,
      redirectionUrl: pageUrls.vehicleList
    });
    getDocumentToken({ apiId: getDocumentTokenApiId });
    fetchCompanyList({ apiId: 'fetchFleetCompanies' });
    removeApiStatus();
    clearReceipt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let object = { ...disabledCriteria };
    if (vehicle?.accessLevel < 1) {
      object.regionId = true;
      object.terminalId = true;
      object.departmentId = true;
      object.subDepartmentId = true;
    } else if (vehicle?.accessLevel < 2) {
      object.regionId = true;
      object.terminalId = true;
      object.departmentId = true;
      object.subDepartmentId = false;
    } else if (vehicle?.accessLevel < 3) {
      object.regionId = true;
      object.terminalId = true;
      object.departmentId = false;
      object.subDepartmentId = false;
    } else if (vehicle?.accessLevel < 4) {
      object.regionId = true;
      object.terminalId = false;
      object.departmentId = false;
      object.subDepartmentId = false;
    } else {
      object.regionId = false;
      object.terminalId = false;
      object.departmentId = false;
      object.subDepartmentId = false;
    }
    setDisabledCriteria(object);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicle?.accessLevel]);

  const [disabledCriteria, setDisabledCriteria] = useState({
    regionId: false,
    terminalId: false,
    departmentId: false,
    subDepartmentId: false
  });
  const recallUpdate = (company) => {
    fetchInsuranceEditVehicleData({
      data: {
        id: vehicleId, company,
        recall: true
      },
      apiId: fetchInsuranceEditVehicleDataApi,
      callback,
      history,
      redirectionUrl: pageUrls.vehicleList
    });
  }
  return (
    <>
      <RenderComponent
        {...config.editVehicle[0]}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        component={Title}
      />
      <RenderComponent
        {...config.editVehicle[1]}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        recallUpdate={recallUpdate}
        component={Content}
        regions={generateDropdownOptions(
          isInsuracePortalUser() && regions?.data?.regions?.length
            ? regions.data.regions
            : vehicle.regions,
          'regionName',
          'id'
        )}
        radiusCompliances = {objectArrayFromSameField(vehicle?.radiusCompliances )}
        states={objectArrayFromSameField(vehicle?.states)}
        terminals={generateDropdownOptions(
          regions?.data?.terminals?.length
            ? regions.data.terminals
            : regions.data.terminals,
          'terminalName',
          'terminalId'
        )}
        departments={generateDropdownOptions(
          regions?.data?.departments?.length
            ? regions.data.departments
            : !regions?.data?.departments?.length &&
              regions?.data?.terminals?.length
              ? []
              : regions.data.departments,
          'departmentName',
          'departmentId'
        )}
        subDepartments={generateDropdownOptions(
          regions?.data?.subDepartments?.length
            ? regions.data.subDepartments
            : !regions?.data?.subDepartments?.length &&
              regions?.data?.departments?.length
              ? []
              : regions.data.subDepartments,
          'subDepartmentName',
          'subDepartmentId'
        )}
        disabledCriteria={disabledCriteria}
      />
    </>
  );
};

const RenderComponent = ({
  name,
  props,
  commonProps,
  title,
  parentProps,
  commonParentProps,
  component,
  ...rest
}) => {
  const Component = component;
  let newProps = props?.length ? getNewObject(parentProps, props) : {};
  newProps = {
    ...newProps,
    ...(commonProps?.length ? getNewObject(commonParentProps, commonProps) : {})
  };
  return <Component {...rest} {...newProps} />;
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    regions: state.regions,
    documents: state.documents,
    vehicle: state.vehicleEdit,
    logBook: state.logBook,
    receipt: state.viewReport,
    fleetCompanies: state.fleetCompanyList.companyList,
    customDvir: state.customDvir
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchTerminals: (data) => dispatch.regions.fetchTerminals(data),
    fetchDepartments: (data) => dispatch.regions.fetchDepartments(data),
    fetchSubDepartments: (data) => dispatch.regions.fetchSubDepartments(data),
    fetchEditVehicleData: (data) => dispatch.vehicleEdit.fetchData(data),
    fetchInsuranceEditVehicleData: (data) => dispatch.vehicleEdit.fetchInsuranceUserData(data),
    saveVehicleData: (data) => dispatch.vehicleEdit.saveData(data),
    getLatLongAndSave: (data) => dispatch.vehicleEdit.fetchLatLongSave(data),
    saveDocument: (data) => dispatch.vehicleEdit.saveDocument(data),
    getRegions: (data) => dispatch.regions.fetchRegionsByCompany(data),
    fetchCompanyList: (data) => dispatch.fleetCompanyList.fetchCompanyList(data),
    deleteDocument: (data) => dispatch.vehicleEdit.deleteDocument(data),
    isValidVin: (data) => dispatch.vehicleAdd.isValidVin(data),
    downloadDocument: (data) => dispatch.viewReport.downloadReport(data),
    getDocumentToken: (data) => dispatch.logBook.documentToken(data),
    removeApiStatus: () => dispatch.vehicleEdit.removeApiStatus(),
    clearReceipt: () => dispatch.viewReport.clearReceipt(),
    fetchCustomDvir: (data) => dispatch.customDvir.fetchData(data)
  };
};

export default connect(mapState, mapDispatch)(EditVehicle);
EditVehicle.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  getRegions: PropTypes.func.isRequired,
  fetchCompanyList: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  fetchTerminals: PropTypes.func.isRequired,
  fetchDepartments: PropTypes.func.isRequired,
  saveDocument: PropTypes.func.isRequired,
  deleteDocument: PropTypes.func.isRequired,
  fetchEditVehicleData: PropTypes.func.isRequired,
  fetchInsuranceEditVehicleData: PropTypes.func.isRequired,
  saveVehicleData: PropTypes.func.isRequired,
  getLatLongAndSave: PropTypes.func.isRequired,
  fetchSubDepartments: PropTypes.func.isRequired
};
