export const dashboard = [
  {
    name: 'pages/web/Dashboard/Header',
    props: ['dashboard'],
    commonProps: ['i18n', 'history', 'Styled']
  },
  {
    name: 'pages/web/Dashboard/Component',
    props: [
      'dashboard',
      'getDriverStatus',
      'getLatestLog',
      'refreshVehicleStatus',
      'refreshDriverStatus',
      'enableNotification',
      'clearAllAlerts',
      'clearAll',
      'handleDeleteAlert',
      'fetchDashboardData',
      'viewReport',
      'refreshDriverStatusMap',
      'filterSuccess',
      'getVehicleStatus',
      'callback'
    ],
    commonProps: ['Styled', 'i18n', 'history'],
    alternateComponent: false,
    iconsAsLink: false,
    accordion: false,
    mapRequired: true,
    dataList: [
      {
        id: 'driverBehavior',
        nameKey: 'dashboard.tabs.driverBehavior',
        value: null,
        dependency: 'geometrisEnabled'
      },
      {
        id: 'vehicleStatus',
        nameKey: 'dashboard.tabs.vehicleStatus',
        value: null,
        dependency: 'geometrisEnabled'
      },
      {
        id: 'driverStatus',
        nameKey: 'dashboard.tabs.driverStatus.heading',
        value: null,
        dependency: ''
      },
      {
        id: 'alerts',
        nameKey: 'dashboard.tabs.alerts.heading',
        value: null,
        dependency: ''
      },
      {
        id: 'csaViolationFieldWaitingEnabled',
        nameKey: 'dashboard.tabs.csaSnapshot',
        value: null,
        dependency: ''
      },
      {
        id: 'potentialViolations',
        nameKey: 'dashboard.tabs.potentialViolations.heading',
        value: null,
        dependency: ''
      },
      {
        id: 'recentAccidents',
        nameKey: 'dashboard.tabs.recentAccidents',
        value: null,
        dependency: ''
      }
    ]
  }
];

export const login = [
  {
    name: 'pages/web/Login/Component',
    props: ['apiStatus', 'login', 'fetchLogin'],
    commonProps: ['Styled', 'i18n', 'history'],
    hasRegistration: true,
    innerComponent: 'pages/web/Login/RenderComponent'
  }
];

export const vehicleDTCView = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'dca.dcAlert'
  },
  {
    name: 'pages/web/ViewDCA/Component',
    props: [
      'apiStatus',
      'callback',
      'dcaList',
      'setSearch',
      'search',
      'basicRequest',
      'request',
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled', 'search', 'setSearch'],
    title: 'component',
    type: 'dcaView',
    topAction: true
  }
];

export const userList = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'users.listHeading'
  },
  {
    name: 'pages/web/Users/Component',
    topAction: true,
    props: [
      'apiStatus',
      'users',
      'handleImportUser',
      'handleExportUser',
      'filterSuccess',
      'handleRestoreAlert',
      'handleDeleteAlert',
      'handleResetPassword',
      'fetchUsers',
      'changeRequest',
      'request',
      'setFilterData',
      'callback',
      'handleExportUserTemplate',
      'filters',
      'logBook'
    ],
    commonProps: ['Styled', 'i18n', 'history', 'theme'],
    title: 'component',

    columns: [
      {
        id: 'full_name',
        numeric: false,
        disablePadding: true,
        label: 'vehicles.fullName',
        needSorting: true
      },
      {
        id: 'role',
        numeric: false,
        disablePadding: false,
        label: 'vehicles.role',
        needSorting: false
      },
      {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: 'vehicles.email',
        needSorting: true
      },
      {
        id: 'empId',
        numeric: true,
        disablePadding: false,
        label: 'vehicles.empId',
        needSorting: true
      },
      {
        id: 'phone',
        numeric: true,
        disablePadding: false,
        label: 'common.phone',
        needSorting: false
      },
      {
        id: 'assigned_vehicles',
        numeric: false,
        disablePadding: false,
        label: 'vehicles.assignedVehicles',
        needSorting: false
      },
      {
        id: 'document',
        label: 'common.documents'
      },
      {
        id: 'last_login',
        numeric: false,
        disablePadding: false,
        label: 'vehicles.lastLogin',
        needSorting: false
      },
      {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
        needSorting: true
      },
      {
        id: 'eld',
        numeric: false,
        disablePadding: false,
        label: 'ELD',
        needSorting: false
      },
      {
        id: 'actions',
        label: ''
      }
    ]
  }
];

export const userDriverStatus = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: ''
  },
  {
    name: 'pages/web/DriverStatus/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'driverStatus',
      'changeRequest',
      'match',
      'pagination',
      'setPagination',
      'request'
    ],
    commonProps: ['Styled', 'i18n', 'history', 'theme'],
    title: 'component',
    topAction: true,
    columns: [
      {
        id: 'full_name',
        numeric: false,
        disablePadding: false,
        needSorting: true,
        label: 'vehicles.fullName'
      },
      {
        id: 'hire_date',
        numeric: true,
        disablePadding: false,
        needSorting: true,
        label: 'common.hireDate'
      },
      {
        id: 'email',
        numeric: false,
        disablePadding: false,
        needSorting: true,
        label: 'vehicles.email'
      },
      {
        id: 'empId',
        numeric: true,
        disablePadding: false,
        needSorting: true,
        label: 'vehicles.empId'
      },
      {
        id: 'phone',
        numeric: true,
        disablePadding: false,
        label: 'common.phone'
      },
      {
        id: 'assigned_vehicles',
        numeric: false,
        disablePadding: false,
        label: 'vehicles.assignedVehicles'
      },
      {
        id: 'joined',
        numeric: false,
        disablePadding: false,
        label: 'users.joined'
      },
      {
        id: 'status',
        numeric: false,
        disablePadding: false,
        needSorting: true,
        label: 'logs.status'
      }
    ]
  }
];

export const addUser = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'users.addHeading'
  },
  {
    name: 'components/modules/UserManagement/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'fetchUserData',
      'user',
      'saveDocument',
      'saveUserData',
      'deleteDocument',
      'handleDownloadDoc',
      'fetchCountData',
      'downloadDocument',
      'onFleetChange',
      'vehicleList',
      'logBook',
      'getDocumentToken',
      'documents',
      'accessLevel',
      'fetchDepartments',
      'fetchSubDepartments',
      'fetchRegions',
      'fetchTerminals',
      'handleLogout',
      'callback',
      'fleetCompanies',
      'removeApiStatus',
      'getDrivers',
      'downloadUrl',
      'userRole'
    ],
    commonProps: ['Styled', 'i18n', 'history', 'theme'],
    title: 'component',
    type: 'add'
  }
];

export const editUser = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'users.editHeading'
  },
  {
    name: 'components/modules/UserManagement/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'fetchUserData',
      'user',
      'saveDocument',
      'saveUserData',
      'deleteDocument',
      'handleDownloadDoc',
      'downloadDocument',
      'logBook',
      'fleetCompanies',
      'getDocumentToken',
      'accessLevel',
      'fetchDepartments',
      'fetchSubDepartments',
      'fetchRegions',
      'onFleetChange',
      'vehicleList',
      'fetchTerminals',
      'handleLogout',
      'removeApiStatus',
      'getDrivers',
      'downloadUrl',
      'receipt'
    ],
    commonProps: ['Styled', 'i18n', 'history', 'theme'],
    title: 'component',
    type: 'edit',
    viewOnly: false
  }
];

export const vehicleStatusViewMore = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'dashboard.tabs.vehicleStatus'
  },
  {
    name: 'pages/web/VehicleStatusList/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'fetchVehicleStatus',
      'vehicleStatus',
      'filterSuccess'
    ],
    commonProps: ['Styled', 'i18n', 'history', 'theme'],
    title: 'component',
    topAction: true
  }
];

export const vehicleList = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'vehicles.listHeading'
  },
  {
    name: 'pages/web/Vehicles/Component',
    props: [
      'vehicles',
      'apiStatus',
      'fetchVehicles',
      'handleDeleteAlert',
      'callback',
      'filterSuccess',
      'filterBoth',
      'handleExportVehicle',
      'handleExportTrailer',
      'handleImportVehicle',
      'handleImportTrailer',
      'handleImportVehiclePost',
      'handleImportTrailerPost',
      'fetchVehiclePageHistoryMap',
      'vehiclePageMap',
      'openDocument',
      'getDrivers',
      'trailers',
      'fetchTrailers',
      'fetchVehiclePageMap',
      'logBook',
      'handleTrailerDeleteAlert',
      'filters',
      'fetchFleetCompanies',
      'fleetCompanies',
      'trailerFilterSuccess',
      'fetchLiveUrl',
      'videoUrl',
      'fetchCameraStatus',
      'cameraStatus',
      'clearFetchLiveUrlTimeout',
      'fetchVehicleRadius'
    ],
    commonProps: ['Styled', 'i18n', 'history', 'theme'],
    title: 'component',

    columns: [
      {
        id: 'full_name',
        numeric: false,
        disablePadding: true,
        label: 'vehicles.fullName',
        needSorting: true
      },
      {
        id: 'role',
        numeric: false,
        disablePadding: false,
        label: 'vehicles.role',
        needSorting: false
      },
      {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: 'vehicles.email',
        needSorting: true
      },
      {
        id: 'empId',
        numeric: true,
        disablePadding: false,
        label: 'vehicles.empId',
        needSorting: true
      },
      {
        id: 'phone',
        numeric: true,
        disablePadding: false,
        label: 'common.phone',
        needSorting: false
      },
      {
        id: 'assigned_vehicles',
        numeric: false,
        disablePadding: false,
        label: 'vehicles.assignedVehicles',
        needSorting: false
      },
      {
        id: 'document',
        label: 'common.documents'
      },
      {
        id: 'last_login',
        numeric: false,
        disablePadding: false,
        label: 'vehicles.lastLogin',
        needSorting: false
      },
      {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
        needSorting: true
      },
      {
        id: 'eld',
        numeric: false,
        disablePadding: false,
        label: 'ELD',
        needSorting: false
      },
      {
        id: 'actions',
        label: ''
      }
    ]
  }
];

export const cameraList = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'camera.listHeading'
  },
  {
    name: 'pages/web/Camera/Component',
    props: [
      'apiStatus',
      'callback',
      'openDocument',
      'filters',
      'eventsData',
      'dataUsageList',
      'changeDataUsageFilter',
      'changeEventType',
      'changeRequest',
      'request',
      'changeDataUsageRequest',
      'dataUsageRequest',
      'fetchVideoUrl',
      'paramsList',
      'vehicleList',
      'eventTypes',
    ],
    commonProps: ['Styled', 'i18n', 'history', 'theme'],
    title: 'component',
  }
];

export const cameraEvents = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'Camera Events'
  },
  {
    name: 'pages/web/CameraEvents/Component',
    props: [
      'apiStatus',
      'callback',
      'openDocument',
      'filters',
      'changeRequest',
      'request',
    ],
    commonProps: ['Styled', 'i18n', 'history', 'theme'],
    title: 'component',
  }
];

export const cameraVehicleEvents = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'Camera Events'
  },
  {
    name: 'pages/web/CameraVehicleEvents/Component',
    props: [
      'apiStatus',
      'callback',
      'openDocument',
      'filters',
      'changeRequest',
      'request',
    ],
    commonProps: ['Styled', 'i18n', 'history', 'theme'],
    title: 'component',
  }
];

export const addVehicle = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'vehicles.addHeading'
  },
  {
    name: 'components/modules/VehicleManagement/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'fetchVehicleData',
      'vehicle',
      'fetchCountData',
      'deleteDocument',
      'saveDocument',
      'isValidVin',
      'fleetCompanies',
      'getRegions',
      'saveVehicleData',
      'getLatLongAndSave',
      'fetchSubDepartments',
      'fetchDepartments',
      'fetchTerminals',
      'downloadDocument',
      'logBook',
      'getDocumentToken',
      'removeApiStatus',
      'receipt',
      'callback'
    ],
    commonProps: ['Styled', 'i18n', 'history', 'theme'],
    title: 'component',
    type: 'add'
  }
];

export const editVehicle = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'vehicles.editHeading'
  },
  {
    name: 'components/modules/VehicleManagement/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'fetchVehicleData',
      'vehicle',
      'fetchCountData',
      'deleteDocument',
      'saveDocument',
      'isValidVin',
      'saveVehicleData',
      'fetchSubDepartments',
      'fetchDepartments',
      'fetchTerminals',
      'downloadDocument',
      'logBook',
      'getLatLongAndSave',
      'fleetCompanies',
      'getRegions',
      'getDocumentToken',
      'removeApiStatus',
      'receipt',
      'callback'
    ],
    commonProps: ['Styled', 'i18n', 'history', 'theme'],
    title: 'component',
    type: 'edit'
  }
];

export const driverStatusViewMore = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'dashboard.tabs.driverStatus.heading'
  },
  {
    name: 'pages/web/DriverStatusViewMore/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'fetchDriverStatus',
      'list',
      'driverStatusList',
      'getDriverStatus',
      'refreshDriverStatusMap',
      'driverStatusFilteredList',
      'filterSuccess',
      'changeRequest',
      'pagination',
      'setPagination',
      'request'
    ],
    commonProps: ['Styled', 'i18n', 'history', 'theme'],
    title: 'component',
    topAction: true,
    mapRequired: true
  }
];

export const companyDataEdit = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'companyData.pageHeading'
  },
  {
    name: 'pages/web/CompanyData/Component',
    props: [
      'apiStatus',
      'callback',
      'getCompanyData',
      'saveCompanyData',
      'cancelSubscription',
      'updateCard',
      'updateCardDetails',
      'states',
      'allCycleRules',
      'allCycleExceptions',
      'allVehicleTypes',
      'companyData',
      'eventTypes'
    ],
    innerComponent: 'pages/web/CompanyData/RenderComponent',
    commonProps: ['Styled', 'i18n', 'history', 'theme'],
    title: 'component',
    topAction: true
  }
];

export const profile = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'users.profileHeading'
  },
  {
    name: 'components/modules/UserProfile/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'generateMfaQrCode',
      'validateMfaSetup',
      'modifyMfaSettings',
      'list',
      'callback',
      'profile',
      'updateProfile',
      'fetchProfile',
      'clearApiStatus',
      'getCsvStatusHistory'
    ],
    commonProps: ['i18n', 'history', 'theme', 'isEditable'],
    title: 'component'
  }
];

export const resetPassword = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'users.resetPasswordHeading'
  },
  {
    name: 'components/modules/UserProfile/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'profile',
      'callback',
      'updateResetPassword',
      'fetchResetPassword',
      'updateProfile'
    ],
    commonProps: ['i18n', 'history', 'theme'],
    title: 'component'
  }
];

export const vehicleNotInUse = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'dashboard.notInUseVehicles.pageHeading.INSHOP',
    remainingHeader: true
  },
  {
    name: 'pages/web/NotInUseVehicles/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'notInUseVehicles',
      'filterSuccess',
      'pagination',
      'setPagination'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    topAction: true
  }
];

export const alertViewMore = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'common.alerts'
  },
  {
    name: 'components/modules/DataTableWithSearchExport/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'list',
      'callback',
      'columns',
      'logBook',
      'getDocumentToken',
      'filterSuccess',
      'exportAllData',
      'pagination',
      'setPagination',
      'deleteData'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    apiIds: {
      export: 'exportAllAlertData',
      list: 'alertList',
      delete: 'deleteAlerts'
    },
    fileName: 'Alerts.pdf',
    needSearch: false,
    needFilter: true,
    needExportAll: true,
    needExportPopup: false,
    topAction: true,
    isOldView: false
  }
];

export const potentialViolationsViewMore = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'potentialViolations.pageHeading'
  },
  {
    name: 'components/modules/DataTableWithSearchExport/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'list',
      'callback',
      'filterSuccess',
      'exportAllData',
      'exportCsvData',
      'exportPdfData',
      'deleteData',
      'logBook',
      'getDocumentToken',
      'columns',
      'pagination',
      'setPagination'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled', 'emProps'],
    title: 'component',
    apiIds: {
      export: 'exportAllPotentialViolationsData',
      list: 'potentialViolations',
      delete: 'deletePotentialViolations'
    },
    fileName: 'Violations.pdf',
    needSearch: false,
    needFilter: true,
    needExportAll: true,
    needExportPopup: false,
    topAction: true,
    isOldView: false
  }
];

export const downloadCenter = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'Download Center'
  },
  {
    name: 'pages/web/DownloadCenter/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'list',
      'callback',
      'filters',
      'viewReport',
      'getDocumentToken',
      'logBook',
      'reportTypes',
      'paginateList',
      'changeRequest',
      'request',
      'pagination',
      'setPagination'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    apiIds: {
      export: 'exportAllPotentialViolationsData',
      list: 'potentialViolations',
      delete: 'deletePotentialViolations'
    },
    fileName: 'Violations.pdf',
    needSearch: false,
    needFilter: true,
    needExportAll: true
  }
];

export const manageScheduling = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'Manage Scheduling'
  },
  {
    name: 'pages/web/ManageScheduling/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'list',
      'callback',
      'handleDelete',
      'filters',
      'viewReport',
      'getDocumentToken',
      'logBook',
      'reportTypes',
      'paginateList',
      'changeRequest',
      'request',
      'pagination',
      'drivers',
      'vehicles',
      'setPagination',
      'handleEdit'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    apiIds: {
      export: 'exportAllPotentialViolationsData',
      list: 'potentialViolations',
      delete: 'deletePotentialViolations'
    },
    fileName: 'Violations.pdf',
    needSearch: false,
    needFilter: true,
    needExportAll: true
  }
];

export const accidentsList = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'accidents.heading'
  },
  {
    name: 'pages/web/Accidents/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'list',
      'callback',
      'filters',
      'viewReport',
      'getDocumentToken',
      'logBook',
      'fleetCompanies',
      'paginateList',
      'changeRequest',
      'request',
      'pagination',
      'setPagination'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    apiIds: {
      export: 'exportAllPotentialViolationsData',
      list: 'potentialViolations',
      delete: 'deletePotentialViolations'
    },
    fileName: 'Violations.pdf',
    needSearch: false,
    needFilter: true,
    needExportAll: true
  }
];

export const driverStatusHistory = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'users.driverStatusHistory'
  },
  {
    name: 'components/modules/DataTableWithSearchExport/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'fetchDriverStatusHistory',
      'callback',
      'list',
      'filterSuccess',
      'exportAllData',
      'exportCsvData',
      'exportPdfData',
      'logBook',
      'getDocumentToken',
      'pagination',
      'setPagination'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    needFilter: false,
    needExportAll: false,
    needExportPopup: true,
    noAction: true,
    needSearch: true,
    apiIds: {
      export: 'exportAllDriverStatusHistoryData',
      list: 'driverStatusHistory'
    },
    fileName: 'DriverStatusHistory.pdf',
    defaultSortBy: { sortBy: 'truck_id', order: 'desc' },

    columns: [
      {
        id: 'truck_id',
        numeric: true,
        disablePadding: false,
        label: 'common.truckId',
        needSorting: true
      },
      {
        id: 'geo_location',
        numeric: false,
        disablePadding: true,
        label: 'common.geocodedLocation',
        needSorting: true
      },
      {
        id: 'driver_Status',
        numeric: false,
        disablePadding: false,
        label: 'dashboard.tabs.driverStatus.heading',
        needSorting: true
      },
      {
        id: 'engine_status',
        numeric: false,
        disablePadding: false,
        label: 'common.mph',
        needSorting: true
      },

      {
        id: 'time',
        numeric: false,
        disablePadding: false,
        label: 'common.updatedTime',
        needSorting: true
      }
    ],
    topAction: true,
    isOldView: false
  }
];

export const privacyPolicy = [
  {
    name: 'pages/web/PrivacyPolicy/gs',
    props: [],
    commonProps: [],
    title: 'component'
  }
];

export const privacyPolicyScreen = [
  {
    name: 'pages/web/PrivacyPolicyScreen/component',
    props: [],
    commonProps: [],
    title: 'component'
  }
];

export const recovery = [
  {
    name: 'pages/web/ForgotPassword/Component',
    props: ['history', 'requestForgotPassword', 'clearApiStatus', 'apiStatus'],
    commonProps: ['Styled', 'i18n'],
    title: 'component',
    innerComponent: 'pages/web/ForgotPassword/RenderComponent'
  }
];

export const MFAPage = [
  {
    name: 'pages/web/MFA/Component',
    props: ['history', 'authenticteOtp', 'clearApiStatus', 'apiStatus'],
    commonProps: ['Styled', 'i18n'],
    title: 'component',
    innerComponent: 'pages/web/MFA/RenderComponent'
  }
]

export const registration = [
  {
    name: 'pages/web/Registration/Common/Component',
    props: ['apiStatus', 'registration', 'saveRegistration', 'callback'],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component'
  }
];

export const logsList = [
  {
    name: 'components/modules/LogsManagement/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'list',
      'callback',
      'filters',
      'filterSuccess',
      'handleLogBookStatus',
      'clearApiStatus',
      'setPagination',
      'pagination'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    apiIds: {
      export: 'exportAllPotentialViolationsData',
      list: 'potentialViolations',
      delete: 'deletePotentialViolations'
    },
    fileName: 'Violations.pdf',
    needSearch: false,
    needFilter: true,
    needExportAll: true,
    header: 'logs.manageLogsHeader',
    verticalTab: false,
    columns: [
      {
        id: 'driver_name',
        numeric: false,
        disablePadding: true,
        label: 'common.driver',
        needSorting: true
      },
      {
        id: 'vehicle_name',
        numeric: false,
        disablePadding: false,
        label: 'common.vehicle',
        needSorting: true
      },
      {
        id: 'date',
        numeric: false,
        disablePadding: false,
        label: 'common.date',
        needSorting: true
      },
      {
        id: 'hours',
        numeric: true,
        disablePadding: false,
        label: 'common.hoursWorked',
        needSorting: true
      },
      {
        id: 'distance',
        numeric: true,
        disablePadding: false,
        label: 'common.distance',
        needSorting: true
      },
      {
        id: 'cycleRule',
        numeric: false,
        disablePadding: false,
        label: 'common.cycleRule',
        needSorting: true
      },
      {
        id: 'actions',
        label: 'common.tools'
      }
    ]
  },
  {
    name: 'components/modules/LogsManagement/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'list',
      'callback',
      'filters',
      'filterSuccess',
      'handleLogBookStatus',
      'clearApiStatus',
      'setPagination',
      'pagination'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    apiIds: {
      export: 'exportAllPotentialViolationsData',
      list: 'potentialViolations',
      delete: 'deletePotentialViolations'
    },
    fileName: 'Violations.pdf',
    needSearch: false,
    needFilter: true,
    needExportAll: true,
    header: 'logs.manageLogsHeader',
    verticalTab: false,
    columns: [
      {
        id: 'driver_name',
        numeric: false,
        disablePadding: true,
        label: 'common.driver',
        needSorting: true
      },
      {
        id: 'vehicle_name',
        numeric: false,
        disablePadding: false,
        label: 'common.vehicle',
        needSorting: true
      },
      {
        id: 'date',
        numeric: false,
        disablePadding: false,
        label: 'common.date',
        needSorting: true
      },
      {
        id: 'hours',
        numeric: true,
        disablePadding: false,
        label: 'common.hoursWorked',
        needSorting: true
      },
      {
        id: 'distance',
        numeric: true,
        disablePadding: false,
        label: 'common.distance',
        needSorting: true
      },
      {
        id: 'cycleRule',
        numeric: false,
        disablePadding: false,
        label: 'common.cycleRule',
        needSorting: true
      },
      {
        id: 'actions',
        label: 'common.tools'
      }
    ]
  }
];

export const archiveLogsList = [
  {
    name: 'components/modules/LogsManagement/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'list',
      'callback',
      'filters',
      'filterSuccess',
      'handleLogBookStatus',
      'clearApiStatus',
      'setPagination',
      'pagination'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    apiIds: {
      export: 'exportAllPotentialViolationsData',
      list: 'potentialViolations',
      delete: 'deletePotentialViolations'
    },
    fileName: 'Violations.pdf',
    needSearch: false,
    needFilter: true,
    needExportAll: true,
    header: 'logs.archiveLogsHeader',
    verticalTab: false,
    columns: [
      {
        id: 'driver_name',
        numeric: false,
        disablePadding: true,
        label: 'common.driver',
        needSorting: true
      },
      {
        id: 'vehicle_name',
        numeric: false,
        disablePadding: false,
        label: 'common.vehicle',
        needSorting: true
      },
      {
        id: 'date',
        numeric: false,
        disablePadding: false,
        label: 'common.date',
        needSorting: true
      },
      {
        id: 'actions',
        label: 'common.pdf'
      }
    ]
  }
];

export const logsUdList = [
  {
    name: 'pages/web/UndefinedDriverList/Component',
    props: [
      'apiStatus',
      'udLogList',
      'unAssignedEntries',
      'fetchUdLogList',
      'fetchUnAssignedEntries',
      'clearUdAssignEntryListById',
      'setUdAssignEntryList',
      'saveUnAssignedEntries',
      'callback',
      'udFilterSuccess',
      'udSetSelectedRow',
      'filters',
      'clearAll'
    ],
    commonProps: ['history', 'i18n', 'Styled'],
    title: 'component',
    verticalTab: false
  }
];

export const logsView = [
  {
    name: 'components/Title',
    props: ['apiStatus', 'dailyLogDetails'],
    commonProps: ['i18n', 'Styled'],
    header: 'logs.editHeading'
  },
  {
    name: 'pages/web/EditDailyLog/Component',
    props: [
      'apiStatus',
      'dailyLogDetails',
      'setDailyLogEntries',
      'saveDailyLogDetails'
    ],
    commonProps: ['Styled', 'i18n', 'history'],
    viewOnly: true
  }
];

export const logsEdit = [
  {
    name: 'components/Title',
    props: ['apiStatus', 'dailyLogDetails'],
    commonProps: ['i18n', 'Styled'],
    header: 'logs.editHeading'
  },
  {
    name: 'pages/web/EditDailyLog/Component',
    props: [
      'apiStatus',
      'dailyLogDetails',
      'setDailyLogEntries',
      'saveDailyLogDetails',
      'getDailyLogDetails'
    ],
    commonProps: ['Styled', 'i18n', 'history'],
    viewOnly: false
  }
];

export const logBook = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'logs.logBook'
  },
  {
    name: 'pages/web/LogBook/Component',
    props: [
      'apiStatus',
      'logBook',
      'callback',
      'handleLogBookStatus',
      'clearApiStatus',
      'breadCrumbForMap',
      'getLogbookEvents',
      'updateShowEvents',
      'fetchLogBookView',
      'dailyLogDownloadData',
      'changedDate',
      'fetchLogbookBreadcrumb',
      'getDocumentToken',
      'formValues',
      'setFormValues',
      'currentDriver',
      'dates',
      'drivers',
      'newDate',
      'unSignedDates',
      'setNewDate',
      'regeneratePdf'
    ],
    title: 'component',
    topAction: true,
    mapRequired: true,
    commonProps: ['Styled', 'i18n', 'history', 'theme']
  }
];

export const logsHistory = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'logs.history'
  },
  {
    name: 'pages/web/LogHistory/Component',
    props: ['apiStatus', 'logsHistory', 'documentToken', 'viewReport'],
    commonProps: ['Styled', 'i18n', 'theme', 'history']
  }
];

export const unhandled = [
  {
    name: 'pages/web/Unhandled/Component',
    props: ['message'],
    commonProps: ['Styled', 'i18n', 'theme', 'history']
  }
];
export const subscriptionMessage = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'Upgrade subscription'
  },

  {
    name: 'pages/web/SubscriptionMessage/Component',
    props: [],
    commonProps: ['Styled', 'i18n', 'history', 'theme']
  }
];

export const reportsEnabled = [
  {
    name: 'pages/web/Report/Component',
    props: [
      'history',
      'apiStatus',
      'callback',
      'fetchRegions',
      'regions',
      'fetchTerminals',
      'fetchDepartments',
      'fetchSubDepartments',
      'drivers',
      'getDrivers',
      'users',
      'fetchUsers',
      'vehicles',
      'companies',
      'fetchVehicles',
      'dutyStatusReport',
      'driverPerformanceReport',
      'editedDataReport',
      'drivingEventReport',
      'eldDisconnectReport',
      'odometerJumpReport',
      'malfunctionReport',
      'unassignedDrivingReport',
      'violationReport',
      'hosViolationsReport',
      'downloadReport',
      'rejectedEditsReport',
      'billOfLadingReport',
      'dailyHosViolationReport',
      'dailyFailedInspectionReport',
      'dailyUnidentifiedDrivingReport',
      'dailyUnsignedLogReport',
      'Styled'
    ],
    commonProps: ['Styled', 'i18n', 'history', 'theme'],
    title: 'component'
  }
];

export const editLog = [
  {
    name: 'components/Title',
    props: ['apiStatus', 'dailyLogDetails'],
    commonProps: ['i18n', 'Styled'],
    header: 'logs.editHeading'
  },
  {
    name: 'pages/web/EditDailyLog/Component',
    props: [
      'apiStatus',
      'dailyLogDetails',
      'setDailyLogEntries',
      'saveDailyLogDetails'
    ],
    commonProps: ['Styled', 'i18n', 'history'],
    udEdit: true
  }
];

export const editLog1 = [
  {
    name: 'components/Title',
    props: ['apiStatus', 'dailyLogDetails'],
    commonProps: ['i18n', 'Styled'],
    header: 'logs.editHeading'
  },
  {
    name: 'pages/web/EditDailyLog/Component',
    props: [
      'apiStatus',
      'dailyLogDetails',
      'setDailyLogEntries',
      'saveDailyLogDetails'
    ],
    commonProps: ['Styled', 'i18n', 'history'],
    udEdit: true
  }
];

export const policyEdit = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'policies.heading'
  },
  {
    name: 'pages/web/Policies/Component',
    props: [
      'clearApiStatus',
      'submitPoliciesAndProcedures',
      'policies',
      'uploadLogo',
      'apiStatus',
      'downloadDocument',
      'policyDownload',
      'logBook',
      'deleteLogo',
      'clearState',
      'fetchCustomPolicy',
      'saveCustomPolicy',
      'editCustomPolicy',
      'customPolicy',
      'handleDeleteCustomPolicyAlert',
      'callback',
      'downloadCustomPolicy'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component'
  }
];

export const dailyDocuments = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'common.manageDailyDocuments'
  },
  {
    name: 'pages/web/DailyDocuments/Component',
    props: [
      'callback',
      'apiStatus',
      'dailyDocuments',
      'filterListSuccess',
      'fetchDailyDocuments',
      'setCurrentDriver',
      'setModalObject',
      'ModalObject',
      'closeModal',
      'clickedModal',
      'open',
      'setOpen',
      'handleDeleteAlert',
      'saveDocument',
      'filterByParams',
      'filterEldSuccess',
      'viewReport',
      'setbackgroundModal',
      'withCloseIcon',
      'backgroundModal',
      'setwithCloseIcon',
      'filterByDriverAndDate',
      'setPagination',
      'pagination',
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    topAction: true
  }
];

export const workOrders = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'workOrders.pageHeading'
  },
  {
    name: 'pages/web/WorkOrders/Component',
    props: [
      'workOrders',
      'filterSuccess',
      'Styled',
      'history',
      'viewReport',
      'filters',
      'setPagination',
      'pagination',
      'clearState',
      "viewOnly",
      "changeRequest",
      "request",
      "filter",
      "setFilter"
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    topAction: true
  }
];

export const advancedDvirEnabled = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'inspections.advancedDvir'
  },
  {
    name: 'pages/web/AdvancedConfig/Component',
    props: [
      'apiStatus',
      'callback',
      'fetchAdvancedConfig',
      'advancedConfig',
      'updateAdvancedConfigData',
      'updateAdvancedConfig',
      'saveData'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component'
  }
];

export const customDvirEnabled = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'inspections.customDvir'
  },
  {
    name: 'pages/web/CustomDvir/Component',
    props: [
      'apiStatus',
      'callback',
      'fetchCustomDvir',
      'deleteCustomDvir',
      'customDvir',
      'enableCustomDvir',
      'isCustomDviRequired'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component'
  }
];

export const addOrEditNewForm = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'inspections.addOrEditNewForm'
  },
  {
    name: 'pages/web/AddOrEditNewForm/Component',
    props: [
      'apiStatus',
      'callback',
      'getCustomDvirForm',
      'customDvir',
      'postCustomDvirForm'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component'
  }
];

export const addOrEditNewFormTrailer = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'inspections.addOrEditNewForm'
  },
  {
    name: 'pages/web/AddOrEditNewFormTrailer/Component',
    props: [
      'apiStatus',
      'callback',
      'getCustomDvirForm',
      'customDvir',
      'postCustomDvirForm'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component'
  }
];

export const fuelConsumptionReport = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'fuelConsumption.pageHeading'
  },
  {
    name: 'pages/web/FuelConsumptionReport/Component',
    props: [
      'fuelConsumption',
      'filterSuccess',
      'filters',
      'pagination',
      'setPagination',
      'getVehicleListDropDown',
      'vehicleList',
      'driverList',
      'getDrivers',
      'getStateListData',
      'addEditFuelData',
      'callback',
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    topAction: true
  }
];

export const refillsHistory = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'refillsHistory.pageHeading'
  },
  {
    name: 'pages/web/RefillsHistory/Component',
    props: [
      'refillsHistory',
      'viewReport',

      'getVehicleListDropDown',
      'vehicleList',
      'driverList',
      'getDrivers',
      'getStateListData',
      'addEditFuelData',
      'fuelConsumption',
      'callback',
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component'
  }
];

export const companyDocument = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'documents.listHeading'
  },
  {
    name: 'pages/web/Documents/Component',
    props: [
      'documents',
      'saveDocument',
      'deleteDocument',
      'apiStatus',
      'callback',
      'downloadDocument',
      'receipt',
      'clearState'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component'
  }
];

export const usDotManagementenabled = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'usDotManagement.pageHeading'
  },
  {
    name: 'pages/web/UsDotNoManagement/Component',
    props: [
      'usDotManagement',
      'filterSuccess',
      'handleDeleteAlert',
      'callback',
      'options',
      'setModalListObject',
      'closeModal',
      'clickedModal',
      'getStates',
      'pagination',
      'setPagination'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    topAction: true
  }
];

export const reportMileage = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'iftaMileage.listHeading'
  },
  {
    name: 'pages/web/IFTAMileage/Component',
    props: [
      'apiStatus',
      'callback',
      'history',
      'fetchIftaMileage',
      'getIftaAuditCSV',
      'ifta',
      'filterList',
      'fetchVehicles',
      'vehicles',
      'logBook',
      'getDocumentToken',
      'handleExportIfta',
      'generateReport'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    isOldView: false
  }
];


export const iftaList = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'iftaMileage.listHeading'
  },
  {
    name: 'pages/web/IFTAList/Component',
    props: [
      'apiStatus',
      'history',
      'iftaList',
      'vehicles',
      'states',
      'handleDeleteAlert',
      'apiIftaMileage',
      'fetchIFTAStates',
      'callback',
      'changeRequest',
      'setPagination',
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    isOldView: false
  }
];

export const addState = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'iftaMileage.listHeading'
  },
  {
    name: 'components/modules/IFTAStateManagement/Component',
    props: [
      'iftaState',
      'vehicles',
      'states',
      'drivers',
      'addUpdateIFTAState',
      'submitAction',
      'callback',
      'fetchLatLong',
      'validateOdometer'
    ],
    commonProps: ['Styled', 'i18n', 'history', 'theme', 'errorMessage',   'fetchLatLong', 'setError', 'setLatLng'],
    title: 'component',
    type: 'add'
  }
];

export const editState = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'iftaMileage.listHeading'
  },
  {
    name: 'components/modules/IFTAStateManagement/Component',
    props: [
      'iftaState',
      'vehicles',
      'states',
      'drivers',
      'addUpdateIFTAState',
      'apiIftaMileage',
      'iftaDetails',
      'callback',
      'fetchLatLong',
      'validateOdometer'
    ],
    commonProps: ['Styled', 'i18n', 'history', 'theme', 'errorMessage', 'isUnknownState', 'fetchLatLong', 'setError', 'setLatLng'],
    title: 'component',
    type: 'edit',
    isEdit: true
  }
];

export const addWorkOrders = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'workOrders.addHeading'
  },
  {
    name: 'components/modules/WorkOrdersManagement/Component',
    props: [
      'workOrders',
      'apiStatus',
      'hasFetchApiStatus',
      'fetchUserData',
      'user',
      'saveDocument',
      'saveUserData',
      'deleteDocument',
      'handleDownloadDoc',
      'fetchCountData',
      'downloadDocument',
      'logBook',
      'getDocumentToken',
      'documents',
      'accessLevel',
      'fetchDepartments',
      'fetchSubDepartments',
      'fetchRegions',
      'fetchTerminals',
      'handleLogout',
      'workOrders',
      'fetchAddWorkOrders',
      'saveAddWorkOrders',
      'callback',
      'clearState'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    type: 'add'
  }
];

export const departmentsList = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'common.departments'
  },
  {
    name: 'pages/web/Department/Component',
    props: [
      'apiStatus',
      'callback',
      'fetchTerminals',
      'data',
      'saveRegion',
      'updateRegion',
      'deleteRegion',
      'saveTerminal',
      'deleteTerminal',
      'updateTerminal',
      'saveDepartment',
      'updateDepartment',
      'deleteDepartment',
      'saveSubDepartment',
      'updateSubDepartment',
      'deleteSubDepartment',
      'setAddEditModalObject',
      'clickedAddEditModal',
      'closeAddEditModal',

      'isTermCrudRestricted',
      'getTmezonePopup',
      'isCrudRestricted',
      'isDeptCrudRestricted',
      'isLocCrudRestricted'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    topAction: true,
    isButtonStyle: false,
    isShowDotNumber: true
  }
];

export const editWorkOrder = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'workOrders.addHeading'
  },
  {
    name: 'components/modules/WorkOrdersManagement/Component',
    props: [
      'workOrders',
      'apiStatus',
      'hasFetchApiStatus',
      'fetchUserData',
      'user',
      'saveDocument',
      'saveUserData',
      'deleteDocument',
      'handleDownloadDoc',
      'fetchCountData',
      'downloadDocument',
      'logBook',
      'getDocumentToken',
      'documents',
      'accessLevel',
      'fetchDepartments',
      'fetchSubDepartments',
      'fetchRegions',
      'fetchTerminals',
      'handleLogout',
      'workOrders',
      'fetchAddWorkOrders',
      'saveAddWorkOrders',
      'callback',
      'viewReport',
      'receipt',
      'clearState'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    type: 'edit'
  },
  {
    name: 'components/modules/WorkOrdersManagement/defectList',
    props: [
      'workOrders',
      'apiStatus',
      'hasFetchApiStatus',
      'saveDocument',
      'deleteDocument',
      'handleDownloadDoc',
      'downloadDocument',
      'getDocumentToken', ,
      'workOrders',
      'callback',
      'viewReport',
      'clearState'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    type: 'edit'
  }
];

export const inspections = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'inspections.listHeading'
  },
  {
    name: 'pages/web/Inspections/Component',
    props: [
      'apiStatus',
      'callback',
      'fetchInspections',
      'bulkDownloadReport',
      'inspections',
      'list',
      'changeRequest',
      'isAdvancedDviRequired',
      'isCustomDviRequired',
      'logBook',
      'clearApiStatus',
      'getDocumentToken',
      'filters',
      'viewReport',
      'request',
      'setPagination',
      'pagination'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    type: 'inspections',
    topAction: true
  }
];

export const subscriptionLink = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'SUBSCRIPTION LIST'
  },
  {
    name: 'pages/web/Subscriptions/Component',
    props: [
      'fetchSubscriptions',
      'subscriptions',
      'subscriptionTier',
      'setSubscription',
      'apiId',
      'callback',
      'upgrade'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    type: 'subscriptions',
    topAction: true,
    isPrimeNeeded: true
  }
];

export const hardAcceleration = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'dashboard.tabs.driverBehavior'
  },
  {
    topAction: true,
    name: 'pages/web/HardAcceleration/Component',
    props: [
      'apiStatus',
      'callback',
      'clearApiStatus',
      'getDrivers',
      'chat',
      'fetchDriverBehaviorDetails',
      'fetchChartData',
      'fetchTableData',
      'driverList',
      'vehicleListDropDown',
      'vehicleList'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component'
  }
];

export const hardDeceleration = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'dashboard.tabs.driverBehavior'
  },
  {
    topAction: true,
    name: 'pages/web/HardDeceleration/Component',
    props: [
      'apiStatus',
      'callback',
      'clearApiStatus',
      'getDrivers',
      'chat',
      'fetchDriverBehaviorDetails',
      'fetchChartData',
      'fetchTableData',
      'driverList',
      'vehicleListDropDown',
      'vehicleList'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component'
  }
];

export const speeding = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'dashboard.tabs.driverBehavior'
  },
  {
    topAction: true,
    name: 'pages/web/Speeding/Component',
    props: [
      'apiStatus',
      'callback',
      'clearApiStatus',
      'getDrivers',
      'chat',
      'fetchDriverBehaviorDetails',
      'fetchChartData',
      'fetchTableData',
      'driverList',
      'vehicleListDropDown',
      'vehicleList'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component'
  }
];

export const driveHrsExceeding = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'dashboard.tabs.driverBehavior'
  },
  {
    topAction: true,
    name: 'pages/web/DriveHrsExceeding/Component',
    props: [
      'apiStatus',
      'callback',
      'clearApiStatus',
      'getDrivers',
      'chat',
      'fetchDriverBehaviorDetails',
      'fetchChartData',
      'fetchTableData',
      'driverList',
      'vehicleListDropDown',
      'vehicleList'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component'
  }
];

export const fuelEconomy = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'dashboard.tabs.driverBehavior'
  },
  {
    topAction: true,
    name: 'pages/web/FuelEconomy/Component',
    props: [
      'apiStatus',
      'callback',
      'clearApiStatus',
      'getDrivers',
      'chat',
      'fetchDriverBehaviorDetails',
      'fetchChartData',
      'fetchTableData',
      'driverIdSuggestion'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component'
  }
];

export const seatBeltWarning = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'dashboard.tabs.driverBehavior'
  },
  {
    topAction: true,
    name: 'pages/web/SeatBeltWarning/Component',
    props: [
      'apiStatus',
      'callback',
      'clearApiStatus',
      'getDrivers',
      'chat',
      'fetchDriverBehaviorDetails',
      'fetchChartData',
      'fetchTableData',
      'driverList',
      'vehicleListDropDown',
      'vehicleList'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component'
  }
];

export const hardTurn = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'dashboard.tabs.driverBehavior'
  },
  {
    topAction: true,
    name: 'pages/web/HardTurn/Component',
    props: [
      'apiStatus',
      'callback',
      'clearApiStatus',
      'getDrivers',
      'chat',
      'fetchDriverBehaviorDetails',
      'fetchChartData',
      'fetchTableData',
      'driverList',
      'vehicleListDropDown',
      'vehicleList'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component'
  }
];

export const outOfService = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'dashboard.notInUseVehicles.pageHeading.OUTOFSERVICE',
    remainingHeader: true
  },
  {
    name: 'pages/web/OutOfService/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'notInUseVehicles',
      'filterSuccess',
      'pagination',
      'setPagination'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component'
  }
];

export const csaViolation = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'csaViolation.listHeading'
  },
  {
    name: 'pages/web/CSAViolation/Component',
    props: [
      'apiStatus',
      'changeRequest',
      'hasApiStatus',
      'csaViolations',
      'handleExportCsaViolations',
      'request',
      'setPagination',
      'pagination'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    topAction: true
  }
];

export const paymentInformation = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'paymentInformation.listHeading'
  },
  {
    name: 'pages/web/PaymentInformation/Component',
    props: [
      'apiStatus',
      'receipt',
      'downloadReceipt',
      'fetchPaymentInformation',
      'paymentInformation',
      'clearState'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    topAction: true
  }
];

export const partsInventoryEnabled = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'workOrders.manageParts'
  },
  {
    name: 'pages/web/Parts/Component',
    props: [
      'apiStatus',
      'partsList',
      'fetchParts',
      'filters',
      'saveParts',
      'callback',
      'changeRequest',
      'handleDeleteAlert',
      'updateParts',
      'getFilters',
      'request',
      'viewOnly'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    topAction: true
  }
];

export const unassignedInspections = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'inspections.listHeading'
  },
  {
    name: 'pages/web/UnassignedInspections/Component',
    props: [
      'apiStatus',
      'callback',
      'fetchInspections',
      'bulkDownloadReport',
      'inspections',
      'list',
      'changeRequest',
      'isAdvancedDviRequired',
      'isCustomDviRequired',
      'logBook',
      'clearApiStatus',
      'getDocumentToken',
      'filters',
      'viewReport',
      'request',
      'setPagination',
      'pagination'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    type: 'inspections',
    topAction: true
  }
];

export const companyList = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'companies.listHeading'
  },
  {
    name: 'pages/web/Companies/Component',
    topAction: true,
    props: [
      'apiStatus',
      'companies',
      'filterSuccess',
      'fetchCompanies',
      'callback',
      'filters',
      'logBook'
    ],
    commonProps: ['Styled', 'i18n', 'history', 'theme'],
    title: 'component',

    columns: [
      {
        id: 'full_name',
        numeric: false,
        disablePadding: true,
        label: 'vehicles.fullName',
        needSorting: true
      },
      {
        id: 'role',
        numeric: false,
        disablePadding: false,
        label: 'vehicles.role',
        needSorting: false
      },
      {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: 'vehicles.email',
        needSorting: true
      },
      {
        id: 'empId',
        numeric: true,
        disablePadding: false,
        label: 'vehicles.empId',
        needSorting: true
      },
      {
        id: 'phone',
        numeric: true,
        disablePadding: false,
        label: 'common.phone',
        needSorting: false
      },
      {
        id: 'assigned_vehicles',
        numeric: false,
        disablePadding: false,
        label: 'vehicles.assignedVehicles',
        needSorting: false
      },
      {
        id: 'document',
        label: 'common.documents'
      },
      {
        id: 'last_login',
        numeric: false,
        disablePadding: false,
        label: 'vehicles.lastLogin',
        needSorting: false
      },
      {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
        needSorting: true
      },
      {
        id: 'eld',
        numeric: false,
        disablePadding: false,
        label: 'ELD',
        needSorting: false
      },
      {
        id: 'actions',
        label: ''
      }
    ]
  }
];
