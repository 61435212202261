import React, { lazy, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';

import Label from '../../../../../components/UI/FormLabel/Clutch';
import Styled from './style';
const layout = window.$environment?.CURRENT_LAYOUT;
const Input = lazy(() =>
  import(`../../../../../components/UI/Input/${layout}`)
);
const Message = lazy(() =>
  import(`../../../../../components/UI/Message/${layout}`)
);
const Button = lazy(() =>
  import(`../../../../../components/UI/Button/${layout}`)
);
const FormStyle = require(`../../../../../components/UI/Form/${layout}/serverStyle`);
const CheckBox = lazy(() =>
  import(`../../../../../components/UI/Checkbox/${layout}`)
);
const RenderComponent = ({
  values,
  handleEdit,
  errors,
  isNotValid,
  validateForm,
  customProps,
  submitAction
}) => {
  useEffect(() => {
    window.onbeforeunload = closeIt;
  }, []);

  useEffect(() => {
    return () => {
      window.onbeforeunload = null;
    };
  }, []);
  const closeIt = () => {
    return 'Are you sure you want to leave?';
  };
  const { i18n } = useTranslation();
  const showError = (field, validationErrors, isNotValid, apiError) => {
    if (validationErrors[field] && !isNotValid) {
      return <Message type="error" message={errors[field]} />;
    }
    if (apiError?.[field]) {
      return <Message type="error" message={apiError[field]} />;
    }
  };
  return (
    <Styled.Wrapper>
      <div className="agreeWrapper">
        <Styled.FieldsWrapper className="signeeForm">
          <Label variant="body1">Name of Signee</Label>
          <Styled.TextWrapper className="formSection">
            <Field
              name="signeeName"
              handleOnChange={(event) =>
                handleEdit(event, { field: 'signeeName' })
              }
              as={Input}
              placeholder="Enter Signee Name"
              value={values['signeeName']}
              type="text"
              className="borderStyle"
              onLoadFocus
            />
            {showError(
              'signeeName',
              errors,
              isNotValid,
              customProps.hasApiStatus?.errorMessage
            )}
          </Styled.TextWrapper>
        </Styled.FieldsWrapper>
        <Styled.FieldsWrapper className="agreeTerms">
          <Styled.TextWrapper>
            <CheckBox
              aria-labelledby="agree"
              type="NormalGrey"
              checked={values.agree}
              label={i18n.t('I agree to the Terms and Conditions')}
              onClick={(event) =>
                handleEdit(event ? 'true' : '', {
                  field: 'agree'
                })
              }
            />
            {showError(
              'agree',
              errors,
              isNotValid,
              customProps.hasApiStatus?.errorMessage
            )}
          </Styled.TextWrapper>
        </Styled.FieldsWrapper>
        <FormStyle.default.ButtonsWrapper>
          <Button
            id="termInfoSubmit"
            type="submit"
            label={'Next'}
            onClick={() => {
              const validateResponse = validateForm();
              validateResponse.then((data) => submitAction(data));
            }}
          />
        </FormStyle.default.ButtonsWrapper>
      </div>
    </Styled.Wrapper>
  );
};

export default RenderComponent;

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  customProps: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};
