import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import { getImagePath } from '../../../utils/utils';
import { imageUrls } from '../../../config/constants/keys';

const Wrapper = styled.div`
  ${({ theme }) => `
  .loginformwrap {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flexbox;
    display: flex; 
    max-width: 450px;
    margin: 0 auto;
    min-height: 100vh;
    .loginForm {
      width: 100%;
    }
  }
  div[type="error"] p {
    font-size: 14px;
  }
  .MuiInputBase-input {
    padding: 10px 15px;
  }
  .MuiInputBase-root,
  input {
    background-color: #20263a !important;
    color: ${theme.colors.WHITE} !important;
  }
  .registrationLink {
    color: ${theme.colors.WHITE};
  }
  .registrationheader: hover {
    text-decoration: none !important;
  }
  .MuiFormLabel-root {
    color: #dbdbdb;
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 10px;
  }
  .passwordField {
    position: relative;
    img {
      position: absolute;
      ${window.$environment.WHITE_THEME ? 'filter: invert(1);' : ''}
      bottom: 16px;
      right: 10px;
      cursor: pointer;
    }
  }
  `}
`;
const LoginWrapper = styled.div`
  ${({ theme }) => `
  width: 100%;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex; 
  flex-direction: column;
  justify-content: center;
  .formLogin {
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.02);
    background-color: #20263a;
    padding: 11% 14%;
    border-radius: 3px;
    padding-top: 9%;
  }
  .buttonWrapper {
    margin-top: 1%;
    width: 50% !important;
    margin-left: 37% !important;
  }
  .loginForm {
    flex: 0 0 85%;
    @media only screen and (min-width: ${theme.breakpoints.values.lg}px) and (max-height: ${theme.breakpoints.values.xxl}px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      flex: 0 0 55%;
    }
  }
  .forgotPassword {
    .MuiButtonBase-root {
      border: none;
    }
    .MuiBox-root:first-child {
      p {
        flex: 0 0 24%;
      }
      p + div {
        flex: 0 0 71%;
      }
    }
    form {
      .MuiBox-root:last-child {
        justify-content: center;
      }
      button {
        margin-top: 13px;
        padding: 2px 44px;
      }
    }
    .MuiButton-root:hover {
      outline: none;
      cursor: pointer;
    }
  }
  `}
`;
const IconWrapper = styled(Box)`
  ${({ theme }) => `
    display: none;
  `}
`;
const LoginHeader = styled(Box)`
  ${({ theme }) => `
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flexbox;
    display: flex; 
    flex-direction: column;
  .loginHeader {
    text-transform: initial;
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    letter-spacing: 1px;
    padding-top: 24px;
  }
  .ClutchLogo {
    padding: ${window.$environment.SERVICE_PROVIDER == 'hcss' || window.$environment.SERVICE_PROVIDER == 'patriot' ? '0' : '30px'} 0;
    max-width: ${window.$environment.SERVICE_PROVIDER == 'hcss' ? '120px' : '100%'};
    margin: 0 auto;
    max-height: 200px;
    ${ window.$environment.SERVICE_PROVIDER == 'patriot' ? 'margin-bottom: 20px;' : ''}
  }
  .subHeading {
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 35px; 
    margin-top: 10px;
    text-align: center;
    width: 100%;
  }
  
  `}
`;

const userNameWrapper = styled.div`
  ${({ theme }) => `
  margin-bottom: 10px;
  .MuiBox-root:first-child {
    flex-direction: column;
  }
 
  .MuiInputBase-root {
    height: 41px;
    margin-bottom: 0;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
  `}
`;
const LoginButtonWrapper = styled.div`
  ${({ theme }) => `
  .MuiBox-root {
    @media (max-width: ${theme.breakpoints.values.lg}px) {
      justify-content: center;
    }
    .MuiBox-root {
      width: 100% ;
    }
  }
  .MuiButton-root:hover {
    outline: none;
    cursor: pointer;
  }
  #forgotPasswordButton {
    color: #ffffff;
    font-size: 12px;
    font-weight: 700;
    -webkit-letter-spacing: 0.6px;
    -moz-letter-spacing: 0.6px;
    -ms-letter-spacing: 0.6px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    background: #1ea7db;
    padding: 12px 18px;
    border-radius: 4px;
    border: 0;
    text-align: left;
    min-width: 160px;
  }
  `}
`;
const LoginError = styled.div`
  padding-bottom: 10px;
`;
const mainWrapper = styled.div``;
const registerSection = styled.div`
${({ theme }) => ` display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 5%;
  .linkToregister {
    font-size: 14px;
    color: ${theme.colors.LINK_COLOR};
    text-decoration: none;
    font-weight: 600;
    padding-top: 3px;
  }
`}`;
const Styled = {
  Wrapper,
  LoginWrapper,
  IconWrapper,
  LoginHeader,
  userNameWrapper,
  LoginButtonWrapper,
  LoginError,
  mainWrapper,
  registerSection
};
export default Styled;
