import React, { lazy, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import RenderComponent from './RenderComponent';
import { pageUrls } from '@/config/constants/keys';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));
const FormStyle = lazy(() =>
  import(`@/components/UI/Form/${layout}/serverStyle`)
);

const SaveDVIRForm = ({
  hasApiStatus,
  callback,
  history,
  updateAdvancedConfig,
  category,
  advancedConfig,
  selected,
  setSelected,
  saveData
}) => {
  const { i18n } = useTranslation();
  const [initialValues, setInitialValues] = useState({});
  useEffect(() => {
    setInitialValues({});
    let obj = category?.map(
      (cat) =>
        (initialValues[cat.label] = advancedConfig?.data?.filter(
          (item) => item?.categoryType.replace(/_/g, ' ') === cat.label
        ))
    );
    setInitialValues(initialValues);
  }, [category, advancedConfig]);
  return (
    <>
      {advancedConfig?.loaded && (
        <Form
          type="server"
          direction="row"
          initialValues={{ ...initialValues }}
          className="saveForms"
          RenderComponent={RenderComponent}
          handleOnSubmit={(fields) => {
            let finalData = { inspectionCategories: { ...fields } };
            saveData({
              apiId: 'saveAdvanceConfig',
              data: { ...finalData },
              callback: history.push(pageUrls.inspections)
            });
          }}
          handleOnCancel={(fields) => {}}
          customProps={{
            history,
            callback,
            hasApiStatus,
            selected,
            setSelected,
            updateAdvancedConfig,
            initialValues
          }}
        />
      )}
    </>
  );
};

SaveDVIRForm.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default SaveDVIRForm;
