import PropTypes from 'prop-types';
import React, { lazy } from 'react';

import { imageUrls } from '@/config/constants/keys';
import { getImagePath } from '@/utils/utils';

const layout = window?.$environment?.CURRENT_LAYOUT;
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const Checkbox = lazy(() => import(`@/components/UI/Checkbox/${layout}`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));

const CategoryItem = ({
  categoryItem,
  i18n,
  changeInspectionEnabled,
  handleDeleteItem,
  index
}) => {

  return (
    <>
      <FormStyle.default.FieldsWrapper width="100%" className="partdetails">
        <Label variant="body1">{categoryItem.vehicleSectionName}</Label>
        <FormStyle.default.CheckBoxWrapper
          direction="row"
          className="roleWrapper"
        >
          <Checkbox
            type="NormalGrey"
            defaultValue
            checked={categoryItem.inspectionEnabled}
            onClick={(event) => {
              changeInspectionEnabled(categoryItem, index, event);
            }}
          />
          <Icon
            src={getImagePath(imageUrls.deleteIcon)}
            onClick={() => handleDeleteItem(categoryItem, index)}
          />
        </FormStyle.default.CheckBoxWrapper>
      </FormStyle.default.FieldsWrapper>
    </>
  );
};
export default CategoryItem;

CategoryItem.propTypes = {
  history: PropTypes.object.isRequired,
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  fetchAdvancedConfig: PropTypes.func.isRequired,
  advancedConfig: PropTypes.object.isRequired
};
