import {
    addCompanyApi,
    getCompanyApi,
    addCheckoutApi,
    getCheckoutApi
  } from '../../../../config/apis';

  const initialState = {
    companyInfoData: {},
    states: [],
    selectedRow: { index: -1, row: {} },
    getcompanyInfoData: {},
    checkoutDetails: {},
    checkout:{
      status: null,
      message: null
    }
  };

  export const companyInfo = {
    state: { ...initialState },
    reducers: {
      listSuccess(state, payload) {
        return { ...state, companyInfoData: {...payload}};
      },
      listFailed(state) {
        return { ...initialState, getcompanyInfoData: {...state.getcompanyInfoData} , checkoutDetails: {...state.checkoutDetails}};
      },
      getlistSuccess(state, payload) {
        return { ...state, getcompanyInfoData: {...payload}};
      },
      checkoutSuccess (state, payload){
        return { ...state, checkout: {status: 200, message: payload}};
      },
      getcheckoutSuccess(state, payload) {
        return { ...state, checkoutDetails: {...payload}};
      },
      checkoutFalied(state, payload){
        return { ...state, checkout: {status: 400, message: {payload}}};
      },
      clearPost(state) {     
        return {...state, companyInfoData:{}, checkoutDetails:{}}
      }
    },
    effects: (dispatch) => ({
      async addCompany(payload) {
        try {
          const response = await dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              api: addCompanyApi,
              data: payload.data
            },
            id: payload.id
          });
          response && this.listSuccess(response);
        } catch (error) {
          this.listFailed();
        }
      },
      async getCompany(payload) {
        try {
          const response = await dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              api: getCompanyApi,
              data: payload.data
            },
            id: payload.id
          });
          response && this.getlistSuccess(response);
        } catch (error) {
          this.listFailed();
        }
      },
      async addCheckout(payload) {
        try {
          const response = await dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              api: addCheckoutApi,
              data: payload.data
            },
            id: payload.id
          });
          response && this.checkoutSuccess(response);
        } catch (error) {
          this.checkoutFalied(error);
        }
      },
      async getCheckout(payload) {
        try {
          const response = await dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              api: getCheckoutApi,
              data: payload.data
            },
            id: payload.id
          });
          response && this.getcheckoutSuccess(response);
        } catch (error) {
          this.listFailed();
        }
      },
      async clearValues() {
        this.clearPost()
      }
    })
  };
