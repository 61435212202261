import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';
import { getLocaleFile } from '@/utils/utils';

const getLocale = (type) => {
  let translationEng = getLocaleFile('en').default;
  let translationGer = getLocaleFile('es').default;

  i18n
    .use(XHR)
    .use(LanguageDetector)
    .init({
      debug: false,
      lng: 'en',
      fallbackLng: 'en', // use en if detected lng is not available

      keySeparator: '.',
      interpolation: {
        escapeValue: false // react already safes from xss
      },

      resources: {
        en: {
          translations: translationEng
        },
        es: {
          translations: translationGer
        }
      },
      // have a common namespace used around the full app
      ns: ['translations'],
      defaultNS: 'translations'
    });
  return i18n;
};

export default getLocale;
