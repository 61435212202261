import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useHistory } from 'react-router-dom';
import useTheme from '@material-ui/core/styles/useTheme';
import { useTranslation } from 'react-i18next';
import { COOKIE_AUTH_DETAILS, pageUrls } from '@/config/constants/keys';
import { getCookie } from '@/services/cookie';

import Content from './Clutch/Component';
import Styled from './style';
import { hasRestrictedRole } from '@/utils/utils';
const faceBookScript = 'https://www.facebook.com/tr?id=346489423406607&ev=PageView&noscript=1';
const env = process.env.REACT_APP_MODE;

const Welcome = ({
  login,
  truckInfo,
  getAllTrucks,
  updateAllTruck,
  isValidVin,
  apiStatus,
  handleLogout,
  pageTitle
}) => {
  const [showWelcome, setShowWelcome] = useState(true);
  const token = getCookie(COOKIE_AUTH_DETAILS);
  useEffect(() => {
    setShowWelcome(token?.onboardCompleted ? false : true);
    if (env === 'production' && window.$environment.SERVICE_PROVIDER === 'clutch'){
      var script = document.createElement("script");
      script.innerHTML = `!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '346489423406607');
      fbq('track', 'PageView');`
      document.body.appendChild(script);
      let img = document.createElement('img');
      img.src = faceBookScript;
      img.style.display = "none";
      document.body.appendChild(img);
}
  }, []);
  useEffect(() => {
    if (!truckInfo.onboardingTrucks.length && !token?.onboardCompleted) {
      getAllTrucks({
        id: 'fetchAllTruckClutchWelcome'
      });
    }
  }, [truckInfo.onboardingTrucks]);
  const history = useHistory();
  const theme = useTheme();
  const i18n = useTranslation();
  const newProps = {
    history,
    theme,
    i18n,
    login,
    truckInfo,
    updateAllTruck,
    isValidVin,
    apiStatus,
    handleLogout,
    pageTitle: pageTitle
  };
  return (
    <>
      {!showWelcome ? (
        <Styled.NotFoundWrapper>
          <h1>{i18n.t('apology')}...</h1>
          <p>{i18n.t('page moved')}</p>
          <p>
            {i18n.t('Click')}
            <a
              href={
                hasRestrictedRole(['DRIVER']) ? pageUrls.profile : '/dashboard'
              }
              className="here"
            >
              {i18n.t('here')}{' '}
            </a>
            {hasRestrictedRole(['DRIVER'])
              ? i18n.t('to go back to user profile')
              : i18n.t('to go back to dasboard')}
          </p>
        </Styled.NotFoundWrapper>
      ) : (
        <Content {...newProps} />
      )}
    </>
  );
};

const mapState = (state) => {
  return {
    login: state.login,
    truckInfo: state.truckInfo,
    vehicle: state.vehicleAdd,
    apiStatus: state.asyncRequests
  };
};

const mapDispatch = (dispatch) => {
  return {
    getAllTrucks: (data) => dispatch.truckInfo.getAllTruckData(data),
    updateAllTruck: (data) => dispatch.truckInfo.updateAllTruckData(data),
    isValidVin: (data) => dispatch.vehicleAdd.isValidVinForClutch(data),
    handleLogout: (data) => dispatch.login.handleLogout(data)
  };
};

export default connect(mapState, mapDispatch)(Welcome);

Welcome.defaultTypes = {};
Welcome.propTypes = {
  login: PropTypes.object.isRequired
};
