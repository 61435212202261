import styled from 'styled-components';

const Container = styled.div`
text-align: center;
  img {
    width: 120px;
  }
  .resetHeading {
    font-size: 20px;
    margin-top: 0;
  }
`;


const Styled = { Container };
export default Styled;