import React, { lazy } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Modal from '@material-ui/core/Modal';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';

import { getImagePath } from '../../../../utils/utils';
import { imageUrls } from '../../../../config/constants/keys';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Icon = lazy(() => import(`../../Icon/${layout}`));
const SimpleModal = ({
  open,
  setOpen,
  ParentWrapper,
  ModalWrapper,
  ModalWrapper2,
  exportDisplay,
  isExportPopup,
  isUserProfile,
  optionListPopup,
  top: propTop,
  right: propRight,
  isresets,
  width: propWidth,
  withCloseIcon,
  isLoader,
  contractHistory,
  background,
  alignItemsLeft,
  data,
  onCloseModal,
  removeTopSpace,
  deletePopup,
  isSuccess,
  disableEscapeKeyDown,
  disableBackdropClick,
  registrationPopup,
  isdepartmentModal,
  onClose,
  companyData,
  timeZone,
  backgroundModal,
  registrationcolorPopup,
  inspectionPopup,
  departmentTerminalModal,
  onCloseFn
}) => {
  const theme = useTheme();

  function getModalStyle() {
    if (!isUserProfile && !isExportPopup) {
      const top = 50;
      const left = 50;

      return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        outline: 'none'
      };
    } else if (isUserProfile && !isdepartmentModal) {
      const top = optionListPopup ? propTop : 40;
      const right = propRight || 1;

      return {
        top: `${top}px`,
        right: `${right}%`,
        outline: 'none',
        background: optionListPopup
          ? theme.colors.PRIMARY_MAIN
          : theme.colors.MODAL_BACKGROUND
      };
    } else if (isExportPopup) {
      const top = 119;
      const left = 80.5;

      return {
        top: `${top}px`,
        left: `${left}%`,
        outline: 'none'
      };
    } else if (isdepartmentModal && isUserProfile) {
      const top = 158;
      const right = 3;

      return {
        top: `${top}px`,
        right: `${right}%`,
        outline: 'none',
        backgroundColor: '#1b1b1b'
      };
    }
  }
  const sm = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const md = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  let heightSet = sm ? 300 : md ? 342 : 400;
  let heightSet1 = sm ? 300 : md ? 342 : 516;
  let companyWidth = sm ? 300 : md ? 342 : 700;

  const useStyles = makeStyles((theme) => ({
    modal: {
      backgroundColor: isresets ? theme.colors.BLACK : ''
    },
    paper: {
      position: 'absolute',
      width: isUserProfile
        ? ''
        : isExportPopup
          ? 150
          : withCloseIcon && !propWidth && !companyData && !contractHistory && !inspectionPopup
            ? heightSet
            : propWidth
              ? propWidth
              : isLoader
                ? '100%'
                : contractHistory
                  ? '690px'
                  : registrationPopup
                    ? registrationPopup
                    : timeZone
                      ? '563px'
                      : companyData
                        ? companyWidth
                        : inspectionPopup
                          ? '350px'
                          : heightSet1,
      backgroundColor: background && !backgroundModal
        ? background
        : isUserProfile
          ? theme.colors.MODAL_BACKGROUND
          : backgroundModal
            ? '#1b1b1b'
            : registrationcolorPopup
              ? '#000'
              : theme.colors.BACKGROUND,
      border:
        isExportPopup || isLoader
          ? 'none'
          : `2px solid ${theme.colors.MODAL_BORDER}`,
      boxShadow: theme.shadows[5],
      borderRadius: companyData ? '6px' : '',
      padding: isUserProfile ? '' : timeZone ? '25px' : '17px',
      color: theme.colors.MODAL_LINK,
      justifyContent: 'center',
      alignItems: background
        ? 'space-around'
        : alignItemsLeft
          ? 'left'
          : 'center',
      display: exportDisplay || 'flex',
      flexDirection: 'column',
      height: isExportPopup ? 100 : isLoader ? '100%' : '',
      opacity: isLoader ? '0.8' : ''
    }
  }));

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const handleClose = () => {
    onCloseFn && onCloseFn()
    setOpen(false);
    return onCloseModal ? onCloseModal('closed') : '';
  };
  return (
    <ParentWrapper>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={onClose || handleClose}
        className={classes.modal}
        disableEscapeKeyDown={disableEscapeKeyDown || false}
        disableBackdropClick={disableBackdropClick || false}
      >
        {!isUserProfile && !deletePopup && !removeTopSpace ? (
          <ModalWrapper2 style={modalStyle} className={classes.paper}>
            {withCloseIcon ? (
              <div id="closeBtn">
                <Icon
                  onClick={() => handleClose()}
                  src={getImagePath(imageUrls.closeIcon)}
                ></Icon>
              </div>
            ) : (
              ''
            )}
            {withCloseIcon || isSuccess || timeZone ? (
              ''
            ) : (
              <h2 id="modal-title">{data.header}</h2>
            )}
            {typeof data.content == 'string' ? (
              <p id="modal-description">{data.content}</p>
            ) : (
              <data.content />
            )}
            {withCloseIcon || isSuccess || timeZone ? (
              ''
            ) : (
              <p id="modal-footer">{data.id}</p>
            )}
          </ModalWrapper2>
        ) : removeTopSpace ?
          (
            <ModalWrapper
              style={modalStyle}
              className={classes.paper}
              width={propWidth}
            >
              <div style={{
                position: "absolute",
                top: "15px",
                right: "15px"
              }} id="closeBtn">
                <Icon
                  onClick={() => handleClose()}
                  src={getImagePath(imageUrls.closeIcon)}
                ></Icon>
              </div>
              <data.content />
            </ModalWrapper>
          )
          : (
            <ModalWrapper
              style={modalStyle}
              className={classes.paper}
              width={propWidth}
            >
              {withCloseIcon ? (
                <div id="closeBtn">
                  <Icon
                    onClick={() => handleClose()}
                    src={getImagePath(imageUrls.closeIcon)}
                  ></Icon>
                </div>
              ) : (
                ''
              )}
              {typeof data.content == 'string' ? (
                <p id="modal-description">{data.content}</p>
              ) : (
                <data.content />
              )}
            </ModalWrapper>
          )}
      </Modal>
    </ParentWrapper>
  );
};

export default SimpleModal;
