import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import RenderComponent from './RenderComponent';

import {
  getListOfSelectedField,
  objectArrayFromMultipleFields
} from '@/utils/utils';

import Styled from './style';
const layout = window?.$environment?.CURRENT_LAYOUT;

const Form = lazy(() => import(`@/components/UI/Form/${layout}`));

const Component = ({
  closeModal,
  saveRegion,
  callback,
  currentItem,
  fleetManagers,
  serviceCenters,
  isEdit,
  isCrudRestricted,
  commonProps,
  neglectAccessLevel
}) => {
  const { i18n } = useTranslation();
  const saveRegionApi = 'saveRegionApi';
  const fleetmanagerCallback = (item, value, field) => {
    if (currentItem?.fleetManagerIds?.includes(item[value]))
      return {
        label: `${item[field[0]]} ${item[field[1]]}`,
        value: item[value],
        disabled: true
      };
    else
      return {
        label: `${item[field[0]]} ${item[field[1]]}`,
        value: item[value]
      };
  };
  const fleetManagerList = objectArrayFromMultipleFields(
    fleetManagers,
    'id',
    ['firstName', 'lastName'],
    fleetmanagerCallback
  );
  const serviceCenterCallback = (item, value, field) => {
    if (currentItem?.fleetManagerIds?.includes(item[value]))
      return {
        label: item[field],
        value: item[value],
        disabled: true
      };
    else
      return {
        label: item[field],
        value: item[value]
      };
  };
  const serviceCenterList = objectArrayFromMultipleFields(
    serviceCenters,     
    'id',
    'name',
    serviceCenterCallback
  );

  return (
    <Styled.Wrapper>
      <Form
        type="server"
        direction="column"
        initialValues={{
          id: currentItem?.id || '',
          regionName: currentItem?.regionName || '',
          regionDescription: currentItem?.regionDescription || '',
          fleetManagerIds:
            fleetManagerList?.filter((item) =>
              currentItem?.fleetManagerIds?.includes(item.value)
            ) || [],
          serviceCenterIds:   serviceCenterList?.filter((item) =>
          currentItem?.serviceCenterIds?.includes(item.value)
        ) || [],
        }}
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {
          let finalData = {
            ...fields
          };
          finalData.fleetManagerIds = getListOfSelectedField(
            fields.fleetManagerIds,
            'value'
          );
          finalData.serviceCenterIds = getListOfSelectedField(
            fields.serviceCenterIds,
            'value'
          );
          saveRegion({
            apiId: saveRegionApi,
            data: {
              ...finalData
            },
            i18n: i18n,
            callback
          });
          closeModal();
        }}
        customProps={{ fleetManagerList, isEdit, serviceCenterList, isCrudRestricted, neglectAccessLevel }}
        handleClear={() => {}}
        validationSchema={Yup.object().shape({
          regionName: Yup.string().required(
            i18n.t('common.fieldRequiredMessage')
          )
        })}
      />
    </Styled.Wrapper>
  );
};

export default Component;

Component.propTypes = {
  apiStatus: PropTypes.object,
  callback: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  saveRegion: PropTypes.func,
  currentItem: PropTypes.object
};
