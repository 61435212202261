import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
  ${({ theme }) => `
  .basicMeasures,
  .middleWrapper,
  .bottomWrapper
   {
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
  justify-content: space-between;
  #noData #msgWrap{
    height: 165px;
    border-bottom: 1px solid ${theme.colors.PRIMARY_BORDER};
    border-left: 1px solid ${theme.colors.PRIMARY_BORDER};
    width: auto;
    text-align: center;
  }
  #noData #nameWrap {
    text-align: center;
    color: #4C4C4C;
    // color: ${theme.colors.PRIMARY_BORDER};
    font-weight: bold;
  }
  #msgWrap p {
    padding-top: 65px;
    font-size: 14px;
    color: ${theme.colors.PRIMARY_CONTRAST};
}
.chartContainer {
  margin-right: 20px;
  cursor: pointer;
  width: 30%;
}
  }
  }

  .middleWrapper,
  .bottomWrapper {
    margin-top: 50px;
  }

  .basicMeasures {
    ${window.$environment.WHITE_THEME ? `background: white;` : ''}
  }
  `}
`;

const Styled = {
  Wrapper
};
export default Styled;
