import React, { useState, lazy } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Filters from './Filters';
import { useHistory } from 'react-router-dom';
import { isInsuracePortalUser, getQueryStringKeys } from '@/utils/utils';
import { periodList } from '@/config/constants/keys';
const layout = window?.$environment?.CURRENT_LAYOUT;

const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Breadcrumb = lazy(() => import(`@/components/Breadcrumb`));

const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Breadcrumb filters={filters} />
      <Component {...contentProps} />
    </Box>
  );
};
export default Index;
const Component = ({
  paginateList,
  hasFetchApiStatus,
  Styled,
  i18n,
  changeRequest,
  request,
  pagination,
  setPagination
}) => {
  const history = useHistory();
  const queryPeriod = getQueryStringKeys(history.location.search, 'period');

  const [values, setInitialValues] = useState({
    period: periodList.filter(ele => ele.value == queryPeriod)[0]
  });
  const insuracePortalUserRole = isInsuracePortalUser();

  const columns = [
    ...(insuracePortalUserRole ? [{
      id: 'companyName',
      numeric: false,
      disablePadding: false,
      label: i18n.t('policyHolder'),
      needSorting: false
    }] : []),
    {
      id: 'vehicleName',
      numeric: false,
      disablePadding: false,
      label: i18n.t('common.vehicle'),
      needSorting: true
    },
    {
      id: 'behavior',
      numeric: false,
      disablePadding: false,
      label: i18n.t('Behavior'),
      needSorting: true
    },
    {
      id: 'severity',
      numeric: false,
      disablePadding: false,
      label: i18n.t('Severity'),
      needSorting: true
    },
    {
      id: 'day',
      numeric: true,
      disablePadding: false,
      label: i18n.t('Date'),
      needSorting: true
    },
    {
      id: 'behaviorScore',
      numeric: true,
      disablePadding: false,
      label: i18n.t('Behavior Score'),
      needSorting: true
    },
    {
      id: 'severityScore',
      numeric: true,
      disablePadding: false,
      label: i18n.t('Severity Score'),
      needSorting: true
    },
    {
      id: 'totalScore',
      numeric: true,
      disablePadding: false,
      label: i18n.t('Total Score'),
      needSorting: true
    }
  ];

  const generateRows = (tableData) => {
    return tableData || []
  };

  const information = hasFetchApiStatus?.errorMessage ? (
    <Message type="error" message={hasFetchApiStatus.errorMessage} />
  ) : (
    'common.tableEmptyMessage'
  );

  return (
    <Styled.Wrapper>
      <div className="quickFilter"></div>
      <div className="tableWrapper">
        <Filters
          filterList={(data) => {
            changeRequest(data)
          }}
          handleReset={() => {
            changeRequest({
              period: queryPeriod
            })
          }}
          history={history}
          values={values}
          setInitialValues={setInitialValues}
        />
        <Table
          type="server"
          defaultSortBy={{ sortBy: 'vehicle', order: 'desc' }}
          title=""
          changeRequest={changeRequest}
          baseRequest={request}
          totalRecords={paginateList?.totalElements || 0}
          columns={[...columns]}
          data={generateRows(paginateList?.content)}
          emptyMessage={information}
          itemsPerPage={15}
          pageinationReset={pagination}
          actions={[]}
          i18n={i18n}
          pagenationClicked={(e) => {
            setPagination(!e);
          }}
        />

      </div>
    </Styled.Wrapper>
  );
};

Component.propTypes = {
  list: PropTypes.array.isRequired,
  hasFetchApiStatus: PropTypes.object.isRequired,
  Styled: PropTypes.object.isRequired,
  i18n: PropTypes.array.isRequired
};
