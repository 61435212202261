import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import usaGeo from '@/config/constants/usaGeo';

const ChoroPleth = ({ data }) => {
  useEffect(() => {
    var margin_choropleth = {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0
      },
      width_choropleth = 650,
      width_choropleth =
        width_choropleth - margin_choropleth.left - margin_choropleth.right,
      mapRatio = 0.9,
      height_choropleth = width_choropleth * mapRatio;

    // D3 Projection
    var projection = d3
      .geoAlbersUsa()
      .scale(width_choropleth)
      .translate([width_choropleth / 2, height_choropleth / 2]);

    // Define path generator
    var path = d3.geoPath().projection(projection);

    var tooltip = d3.select('body').append('div').attr('class', 'tooltip');

    var svg_choropleth = d3
      .select('#myDiv')
      .append('svg')
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .attr('viewBox', '90 100 ' + '480' + ' ' + '400' + '');

    svg_choropleth
      .append('g')
      .attr('id', 'states')
      .selectAll('path')
      .data(usaGeo.features)
      .enter()
      .append('path')
      .attr('d', path)
      .style('stroke', '#20263a')
      .style('stroke-width', '0.5')
      .style('fill', function (d) {
        return d && data && data[d.id] > 0 ? '#007ac7' : '#00558b';
      })
      .on('mouseover', function (d) {
        if(data && data[d.id] > 0){
          return tooltip
          .style('visibility', 'visible')
          .style('top', d3.event.pageY - 30 + 'px')
          .style('left', d3.event.pageX + 'px')
          .html(`<p> ${d?.properties?.name} : ${data[d.id]}%</p>`);
        }
        return null;
      })
      .on('mouseout', function () {
        return tooltip.style('visibility', 'hidden');
      });

    svg_choropleth
      .append('g')
      .attr('class', 'states-names')
      .selectAll('text')
      .data(usaGeo.features)
      .enter()
      .append('svg:text')
      .style('font-size', '8px')
      .text( (d)=> {
        if(data && data[d.id] > 0){
          return `${data[d.id]}%`
        }
        return '';
      })
      .attr('x', function (d) {
        return path.centroid(d)[0];
      })
      .attr('y', function (d) {
        return path.centroid(d)[1];
      })
      .attr('text-anchor', 'middle')
      .attr('fill', 'white');
  }, []);
  return <div id="myDiv"></div>;
};

export default ChoroPleth;

ChoroPleth.defaultProps = {};

ChoroPleth.propTypes = {
  driverStatus: PropTypes.array.isRequired
};
