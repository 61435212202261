import React, { forwardRef } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Modal from '@material-ui/core/Modal';

import Icon from '../../Icon/Clutch/index';
import { getImagePath } from '../../../../utils/utils';
import { imageUrls } from '../../../../config/constants/keys';
import useTheme from '@material-ui/core/styles/useTheme';

import useMediaQuery from '@material-ui/core/useMediaQuery';

const SimpleModal = forwardRef((props, ref) => {
  function getModalStyle() {
    if (!props.isUserProfile && !props.isExportPopup) {
      const top = 50;
      const left = 50;

      return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        outline: 'none',
        padding: '20px'
      };
    } else if (props.isUserProfile && !props.isdepartmentModal) {
      const top = props.optionListPopup ? props.top : 40;
      const right = props.right ? props.right : 1;

      return {
        top: `${top}px`,
        right: `${right}%`,
        outline: 'none',
        background: '#181C2D'
      };
    } else if (props.isExportPopup) {
      const top = 240;
      const left = 78.5;

      return {
        top: `${top}px`,
        left: `${left}%`,
        outline: 'none'
      };
    } else if (props.isdepartmentModal && props.isUserProfile) {
      const top = window?.$environment?.clutchNewUI? 210: 292;
      const right = window?.$environment?.clutchNewUI? 2.5: 2;

      return {
        top: `${top}px`,
        right: `${right}%`,
        outline: 'none'
      };
    }
  }
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const md = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  let heightSet = sm ? 300 : md ? 342 : 400;
  let heightSet1 = sm ? 300 : md ? 342 : 516;

  const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: props.isUserProfile
        ? ''
        : props.isExportPopup
        ? 168
        : props.withCloseIcon && !props.width
        ? heightSet
        : props.width
        ? props.width
        : props.isLoader
        ? '100%'
        : props.connectionType
        ? 'auto'
        : heightSet1,
      backgroundColor: props?.newUI && props.isLoader? '#f0f2f2' :props.connectionType ? '#20263a' : theme.colors.MODAL_BACKGROUND,
      boxShadow: theme.shadows[5],
      padding: props.connectionType ? '28px !important' : '17px',
      color: theme.palette.primary.contrastText,
      justifyContent: 'center',
      alignItems: props.background
        ? 'space-around'
        : props.alignItemsLeft
        ? 'left'
        : 'center',
      display: props.exportDisplay ? props.exportDisplay : props.isSuccess ? 'block' : 'flex',
      flexDirection: 'column',
      height: props.isExportPopup
        ? 10
        : props.isLoader
        ? '100%'
        : props.isError
        ? '340px'
        : '',
      fontFamily: theme.typography.fontFamily,
      borderRadius: '4px',
      // overflow: props.isSuccess ? 'auto' : ''
    }
  }));

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const handleClose = () => {
    props.setOpen(false);
    return props.onCloseModal ? props.onCloseModal('closed') : '';
  };
  return (
    <props.ParentWrapper>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={props.open}
        onClose={props.onClose || handleClose}
        className={classes.modal}
        disableEscapeKeyDown={props.disableEscapeKeyDown || false}
        disableBackdropClick={props.disableBackdropClick || false}
      >
        {!props.isUserProfile && !props.deletePopup ? (
          <props.ModalWrapper2 style={modalStyle} className={classes.paper}>
            {props.withCloseIcon ? (
              <div id="closeBtn">
                <Icon
                  onClick={() => handleClose()}
                  src={getImagePath(imageUrls.closeIcon)}
                ></Icon>
              </div>
            ) : (
              ''
            )}
            {props.withCloseIcon || props.isSuccess ? (
              ''
            ) : (
              <h2
                id="modal-title"
                className={props.connectionType ? 'connectionHeading' : ''}
              >
                {props.data.header}
              </h2>
            )}
            {typeof props.data.content == 'string' ? (
              <p id="modal-description">{props.data.content}</p>
            ) : (
              <props.data.content />
            )}
            {props.withCloseIcon || props.isSuccess || props.connectionType ? (
              ''
            ) : (
              <p id="modal-footer">{props.data.id}</p>
            )}
          </props.ModalWrapper2>
        ) : (
          <props.ModalWrapper
            style={modalStyle}
            className={classes.paper}
            width={props.width}
          >
            {props.withCloseIcon ? (
              <div id="closeBtn">
                <Icon
                  onClick={() => handleClose()}
                  src={getImagePath(imageUrls.closeIcon)}
                ></Icon>
              </div>
            ) : (
              ''
            )}
            {typeof props.data.content == 'string' ? (
              <p id="modal-description">{props.data.content}</p>
            ) : (
              <props.data.content />
            )}
          </props.ModalWrapper>
        )}
      </Modal>
    </props.ParentWrapper>
  );
});

export default SimpleModal;
