import {
  trailerDeleteApi,
  editTrailerApi,
  addTrailerApi,
  isValidVinTrailerApi,
  documentUploadApi,
  documentDeleteApi,
  exportTrailerApi,
  editTrailerInsuranceApi
} from '../../../../config/apis';
const initialState = {};

export const trailers = {
  state: { ...initialState },
  reducers: {
    addSuccess(state, payload) {
      return {};
    },
    addFailed() {
      return { ...initialState };
    },
    getSuccess(state, payload) {
      return { ...payload };
    },
    getRecallSuccess(state, payload) {
      return { ...payload, trailerDetails: payload.trailerDetails }
    },
    getFailed() {
      return { ...initialState };
    },
    updateLocalDocumentList(state, response) {
      const documents = state.documents.map((item) => {
        if (item.type.name === response?.response?.type?.name) {
          return { ...response.response };
        } else if (item.id === response?.id) {
          if (response?.isDelete) {
            return {
              ...item,
              id: null,
              name: null,
              url: null,
              uploaded: null,
              expiration_date: null
            };
          } else {
            return { ...item };
          }
        } else {
          return { ...item };
        }
      });
      return { ...state, documents: [...documents] };
    }
  },
  effects: (dispatch) => ({
    async handleDeleteAlert(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...trailerDeleteApi,
              url: trailerDeleteApi.url.replace('{id}', payload.data.id)
            }
          },
          callback: payload.callback,
          id: payload.id
        });
        payload.callback('success', response);
        //remove item
        response && dispatch.vehicles.updateTrailerList(payload.data.id);
      } catch (error) {
        //handle error
      }
    },
    async handleExportTrailer(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: exportTrailerApi
          },
          id: payload.apiId,
          callback: payload.callback
        });
        //remove item
        payload.callback('success', response);
      } catch (error) {
        // this.listFailed();
      }
    },
    async fetchData(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: editTrailerApi
          },
          id: payload.apiId
        });
        this.getSuccess(response);
      } catch (error) {
        this.getFailed();
      }
    },
    async fetchInsuranceData(payload, rootState) {
      try {
        let queryParam = '?'
        if (payload?.data?.company) queryParam += `companyId=${payload?.data?.company}`
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...editTrailerInsuranceApi,
              url: editTrailerInsuranceApi.url + `${queryParam.length > 1 ? queryParam : ''}`
            }
          },
          id: payload.apiId
        });
        if (payload?.data?.recall) this.getRecallSuccess(response)
        else this.getSuccess(response)
      } catch (error) {
        this.getFailed();
      }
    },
    async saveData(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: addTrailerApi,
            data: payload.data.data
          },
          id: payload.apiId
        });
        response && dispatch.vehicles.updateListResponse(response);
        payload.callback && payload.callback();
      } catch (error) {
        // this.addFailed();
      }
    },
    async isValidVin(payload, rootState) {
      try {
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: isValidVinTrailerApi,
            data: payload.data
          },
          id: payload.apiId
        });
      } catch (error) {}
    },
    async saveDocument(payload) {
      try {
        const data = payload.data.data;
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: documentUploadApi,
            data: data
          },
          id: payload.apiId,
          callback: payload.callback
        });
        response && this.updateLocalDocumentList({ response });
      } catch (error) {
        this.saveFailed();
      }
    },
    async deleteDocument(payload) {
      try {
        const url = documentDeleteApi.url.split('{')[0];
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: { ...documentDeleteApi, url: url + payload.data.id }
          },
          id: payload.apiId
        });
        this.updateLocalDocumentList({
          isDelete: true,
          id: payload.data.id
        });
      } catch (error) {
        this.deleteFailed();
      }
    }
  })
};
