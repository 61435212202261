import { udLogListApi } from '../../../../config/apis';

const initialState = {
  list: [],
  filteredList: [],
  selectedRow: { index: -1, row: {} }
};

export const udLogList = {
  state: { ...initialState },
  reducers: {
    listSuccess(state, payload) {
      return {
        ...state,
        list: [...payload.dailyLogListDtos],
        filteredList: [...payload.dailyLogListDtos],
        totalDistance: payload.totalDistance,
        totalHours: payload.totalHours
      };
    },
    listFailed() {
      return { ...initialState };
    },
    filterSuccess(state, list) {
      return {
        ...state,
        filteredList: [...list],
        totalHours: list?.reduce((a, b) => a + (b['hours'] || 0), 0),
        totalDistance: list?.reduce((a, b) => a + (b['distance'] || 0), 0)
      };
    },
    setSelectedItem(state, data) {
      return { ...state, selectedRow: { ...data } };
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: udLogListApi,
            data: payload.data
          },
          id: payload.apiId
        });
        if (response) {
          response['totalHours'] = payload.data?.showUnidentifiedDriver
            ? response.dailyLogListDtos?.reduce(
                (a, b) => a + (b['hours'] || 0),
                0
              )
            : response.dailyLogListDtos
                ?.filter(
                  (item) => item.unIdentifiedLogStatus !== 'UNIDENTIFIED'
                )
                ?.reduce((a, b) => a + (b['hours'] || 0), 0);
          response['totalDistance'] = payload.data?.showUnidentifiedDriver
            ? response.dailyLogListDtos?.reduce(
                (a, b) => a + (b['distance'] || 0),
                0
              )
            : response.dailyLogListDtos
                ?.filter(
                  (item) => item.unIdentifiedLogStatus !== 'UNIDENTIFIED'
                )
                .reduce((a, b) => a + (b['distance'] || 0), 0);
          response['dailyLogListDtos'] = payload.data?.showUnidentifiedDriver
            ? response.dailyLogListDtos
            : response.dailyLogListDtos?.filter(
                (item) => item.unIdentifiedLogStatus !== 'UNIDENTIFIED'
              );

          this.listSuccess(response);
        }
      } catch (error) {
        this.listFailed();
      }
    }
  })
};
