import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import { getStyle } from '@/utils/utils';

const Wrapper = styled(Box)``;

const SearchWrapper = styled.div``;

const MapWrapper = styled.div`
  padding: 2% 0;
`;
const TableWrapper = styled.div`
  ${({ theme }) => `
position: relative;
.driverstatusTitle{
  display: flex;
}
.spireonOldOnly{
  display: none;
}
.iconContainer  {
    padding-bottom 1%;
    justify-content: flex-end;
    .MuiAvatar-root {
        width: 34px;
        height: 34px;
    }
}
.tooltiptext {
    visibility: hidden;
    width: 100px;
    background: ${getStyle(
      '',
      (theme.palette.primary || {}).main,
      theme.colors.GREY_ONE
    )};
    color: ${theme.colors.PRIMARY_CONTRAST};
    text-align: center;
    padding: 2px 0;
    border-radius: 0px;
    border: solid 1px ${theme.colors.WHITE};
    position: absolute;
    z-index: 1;
    right: 0;
    top: 35px !important;
    font-size: 15px;
  }
  .iconContainer:hover + .tooltiptext {
      visibility: visible;
    }
  }
  .eld-active {
    background: green;
    height: 10px;
    width: 10px;
    border-radius: 5px;
    margin-top: 5px;
  }
  .eld-inActive {
    background: red;
    height: 10px;
    width: 10px;
    border-radius: 5px;
    margin-top: 5px;
  }
  .eld-loggedOut {
    background: #716F6E;
    height: 10px;
    width: 10px;
    border-radius: 5px;
    margin-top: 5px;
  }
  `}
`;
const Styled = { Wrapper, SearchWrapper, MapWrapper, TableWrapper };

export default Styled;
