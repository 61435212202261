import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import { getImagePath, getLogo } from '../../../utils/utils';
import { imageUrls } from '../../../config/constants/keys';

const Wrapper = styled.div`
  ${({ theme }) => `
  .loginformwrap {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flexbox;
    display: flex; 
    max-width: 450px;
    margin: 0 auto;
    min-height: 100vh;
    position: relative;
    .patentText{
      font-size: 12px;
      color: #b6bec3;
      position: absolute;
      text-align: center !important;
      width: 100%;
      bottom: 3vh;
      display: flex;
      justify-content: center;
    }    .loginForm {
      width: 100%;
    }
  }
  div[type="error"] p {
    font-size: 14px;
  }
  .MuiInputBase-root,
  input {
    background-color: ${window.$environment.SERVICE_PROVIDER == 'simplex' ? theme.colors.MENU_BACKGROUND :theme.colors.HEADER_COLOR} !important;
    color: ${window.$environment.SERVICE_PROVIDER == 'simplex' ? theme.colors.WHITE :theme.colors.INPUT_COLOR} !important;
    ${window?.$environment?.clutchNewUI && `border: 1px solid #f0f2f2 !important;`}
  }
  .registrationLink {
    color: ${theme.colors.WHITE};
  }
  .registrationheader: hover {
    text-decoration: none !important;
  }
  .MuiFormLabel-root {
    color: #dbdbdb;
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 10px;
  }
  .passwordField {
    position: relative;
    img {
      position: absolute;
      ${window.$environment.WHITE_THEME && window.$environment.SERVICE_PROVIDER != 'simplex' ? 'filter: invert(1);' : ''}
      top: 16px;
      right: 10px;
      cursor: pointer;
    }
  }
  `}
`;
const LoginWrapper = styled.div`
  ${({ theme, newUI }) => `
  width: 100%;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex; 
  flex-direction: column;
  justify-content: center;
  .formLogin {
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.02);
    ${!(newUI || window.$environment.SERVICE_PROVIDER == 'azuga') && 
      `background-color: ${theme.colors.LOGIN_BACKGROUND ? theme.colors.LOGIN_BACKGROUND :theme.colors.MENU_BACKGROUND};
      padding: 11% 14%;`
    }
    border-radius: 3px;
  }
  .buttonWrapper {
    margin-top: 1%;
    width: 50% !important;
    margin-left: 37% !important;
  }
  .loginForm {
    flex: 0 0 85%;
    @media only screen and (min-width: ${theme.breakpoints.values.lg}px) and (max-height: ${theme.breakpoints.values.xxl}px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      flex: 0 0 55%;
    }
  }
  .forgotPassword {
    .MuiButtonBase-root {
      border: none;
    }
    .MuiBox-root:first-child {
      p {
        flex: 0 0 24%;
      }
      p + div {
        flex: 0 0 71%;
      }
    }
    form {
      margin-top: 1%;
      margin-left: 3%;
      .MuiBox-root:last-child {
        justify-content: center;
      }
      button {
        margin-top: 13px;
        padding: 2px 44px;
      }
    }
    .MuiButton-root:hover {
      outline: none;
      cursor: pointer;
    }
  }
  `}
`;
const IconWrapper = styled(Box)`
  ${({ theme }) => `
    display: none;
  `}
`;
const LoginHeader = styled(Box)`
  ${({ theme }) => `
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flexbox;
    display: flex; 
    flex-direction: column;
    .subHeading {
      color: #ffffff;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      padding-bottom: 20px;
      margin-top: -16px;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        padding-bottom: 0;
        margin-top: 11px;
      }
    }
  .loginHeader {
    text-transform: initial;
    text-align: center;
    font-size: 25px !important;
    font-weight: 600;
    letter-spacing: 1px;
    padding-top: 24px !important;
    padding-bottom: 32px !important;
    background-color: ${theme.colors.LOGIN_BACKGROUND ? theme.colors.LOGIN_BACKGROUND : theme.colors.MENU_BACKGROUND} !important;
    margin-bottom: 0 !important;
    box-shadow: none;
    color: ${window.$environment.SERVICE_PROVIDER == 'simplex' ? '#fff' :theme.colors.PRIMARY_CONTRAST} !important;
  }
  .ClutchLogo {
    padding: ${window.$environment.SERVICE_PROVIDER == 'hcss' || window.$environment.SERVICE_PROVIDER == 'patriot' ? '0' : '30px'} 0;
    margin: 0 auto;
    max-width: ${window.$environment.SERVICE_PROVIDER == 'hcss' ? '120px' : '100%'};
    max-height: 200px;
    ${ window.$environment.SERVICE_PROVIDER == 'patriot' ? 'margin-bottom: 20px;' : ''}
  }
  `}
`;

const LoginContent = styled(Box)`
  ${({newUI})=>`
    ${newUI?
    `background-color: #fff;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 11% 14%;`: ''
  }
  .MuiInputBase-root{
    ${window?.$environment?.clutchNewUI && `border: 1px solid #f0f2f2 !important;`}
  }
  .loginHeader{
    ${newUI &&
    `box-shadow: none;`}
  }
`}
`

const PasswordHandler = styled(Box)`
  ${({ theme, newUI }) => `
  justify-content: ${newUI? 'flex-start':'flex-end'};
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex; 
  .MuiBox-root {
    flex: 0 0 50%;
    text-align: right;
    margin-top: -7px;
    a {
        font-size: 14px;
        color: ${theme.colors.LOGIN_BUTTON};
    }
  }
  .normalCheckbox svg {
    width: 18px;
    height: 19px;
    rect {
      fill: rgba(255, 255, 255,0.75);
    }
  }
  .MuiTypography-colorPrimary {
    color: ${theme.colors.WHITE};
    font-weight: bold !important;
  }
  .MuiCheckbox-root {
    width: auto !important;
  }
  .normalCheckbox span {
    color: ${theme.colors.WHITE};
    font-weight: bold;
    ${
      window.$environment.SERVICE_PROVIDER == 'eldx' ? `color: rgb(69, 85, 96) !important;` : ''
    }
  }
  label {
    margin-right: 0px;
  }
  .MuiSvgIcon-root {
    border: none;
    background: transparent;
  }
  cursor: pointer;
  .remember {
    flex: 0 0 50%;
    margin-top: -16px;
    font-size: 14px;
    .normalCheckbox span {
      padding-left: 3px !important;
      font-weight: bold;
    }
    .Checkbox {
      top: 6px;
    }
  }
  .remember-light-theme span{
    color: #455560 !important;
    font-weight: normal;
  }
  .forgot-password{
    font-weight: bold !important;
  }
  `}
`;
const userNameWrapper = styled.div`
  ${({ theme }) => `
  margin-bottom: 10px;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    margin-bottom: 53px;
  }
  ${window.$environment.SERVICE_PROVIDER == 'simplex' ? `
  .MuiInputLabel-root {
    color: #fff !important;
  }
  ` : ''}
  .MuiFormLabel-root{
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS styles go here */
      padding-bottom: 26px !important;
    }
  }
  .MuiBox-root:first-child {
    flex-direction: column;
  }
 
  .MuiInputBase-root {
    height: 41px;
    margin-bottom: 0;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
  `}
`;
const passwordWrapper = styled.div`
  ${({ theme }) => `
  margin-bottom: 10px;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    margin-bottom: 53px;
  }
  ${window.$environment.SERVICE_PROVIDER == 'simplex' ? `
  .MuiInputLabel-root {
    color: #fff !important;
  }
  ` : ''}
  .MuiFormLabel-root{
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS styles go here */
      padding-bottom: 26px !important;
    }
  }
  .MuiBox-root:first-child {
    flex-direction: column;
  }
  .MuiInputBase-root {
    height: 41px;
    margin-bottom: 0;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
  .password-labels{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .forgot-password{
      font-weight: bold;
    }
  }
}
  `}
`;
const LoginButtonWrapper = styled.div`
  ${({ theme, width, newUI }) => `
  #forgotPasswordButton {
    margin-top: -10px;
    background: ${theme.colors.LOGIN_BUTTON};
    color: ${theme.colors.PRIMARY_BUTTON_TEXT};
    ${newUI? `font-size:14px !important;`: ``}
  }
  .MuiBox-root {
    @media (max-width: ${theme.breakpoints.values.lg}px) {
      ${newUI? `justify-content: flex-end;`: `justify-content: center;`}
    }
    .MuiBox-root {
      width: ${theme.constants?.loginButtonWidth ? theme.constants.loginButtonWidth :width? width:  `100%`} ;
      margin-top: 15px;
    }
  }
  .MuiButton-root:hover {
    outline: none;
    cursor: pointer;
  }
  #loginButton {
    color: ${theme.colors.PRIMARY_BUTTON_TEXT};
    font-size: 12px;
    font-weight: 700;
    -webkit-letter-spacing: 0.6px;
    -moz-letter-spacing: 0.6px;
    -ms-letter-spacing: 0.6px;
    letter-spacing: 0.6px;
    background: ${theme.colors.LOGIN_BUTTON};
    padding: 12px 18px;
    border-radius: 4px;
    border: 0;
    text-align: left;
    min-width: 160px;
    ${newUI && `border-radius: 25px !important;`}
  }
  `}
`;
const mainWrapper = styled.div``;
const LoginError = styled.div`
  padding-bottom: 10px;
`;
const registerSection = styled.div`
${({ theme }) => `display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 5%;
  .registerLabel {
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
  }
  .linkToregister {
    font-size: 14px;
    color: ${theme.colors.LOGIN_BUTTON};
    text-decoration: none;
    font-weight: 600;
    padding-top: 3px;
  }
  `}
`;

const LoginParentWrapper = styled(Box)`
  display: flex;
  .MuiButton-root{
    text-transform: none !important;
    svg{
      margin-left: 5px;
    }
  }
`
const LeftWrapper = styled.div`
  ${({theme})=>`
  justify-content: center;
  align-items: center;
  display: flex;
  background: #455560;
  flex-basis: 45%;
  .login-right-wrap {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flexbox;
    display: flex; 
    min-width: 420px;
    margin: 0 auto;
    min-height: 100vh;
    flex-basis: 50%;
    position: relative;
    .patentText{
      font-size: 12px;
      color: #b6bec3;
      position: absolute;
      text-align: center !important;
      width: 100%;
      bottom: 3vh;
      display: flex;
      justify-content: center;
    }
    .loginForm {
      width: 100%;
    }
  }
  @media (max-width: ${theme.breakpoints.values.md}px) {
    flex-basis: 100%;
  }
  #loginButton{
    font-size: 14px !important;
  }
  `}`

const RightWrapper = styled.div`
${({theme})=>`
  background-image: url(${getImagePath(imageUrls.loginbg)});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  flex-basis: 55%;
  @media (max-width: ${theme.breakpoints.values.md}px) {
    display: none;
  }
  `}`

const Banner = styled(Box)`
  padding: 3%;
  margin-top: 5%;
  margin-right: 19%;
  display: flex;
  flex-direction: column;
  #findMoreButton{
    border-radius: 25px !important;
    font-size: 14px !important;
  }
  h1{
    color: #bdd731;
    font-family: 'NotoSans-Bold';
    font-size: 2.5rem;
  }
  p{
    color: #fff;
    font-size: 1.1rem;
    margin: 0;
  }
}
`
const Styled = {
  Wrapper,
  LoginWrapper,
  IconWrapper,
  LoginHeader,
  PasswordHandler,
  userNameWrapper,
  passwordWrapper,
  LoginButtonWrapper,
  mainWrapper,
  LoginError,
  registerSection,
  LoginContent,
  LoginParentWrapper,
  LeftWrapper,
  RightWrapper,
  Banner
};
export default Styled;
