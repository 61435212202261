import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
padding: 3% 6%;
padding-top: 1%;
.searchTitle { 
    padding-top: 6px;
    text-transform: capitalize;
}
form {
    flex-direction: row;
    justify-content: flex-start;
    .vertical {
        justify-content: flex-end;
        align-self: flex-end;
    }
    .vertical-button {
        justify-content: flex-start;
        button {
            margin-top: 0;
        }
    }
}
.odometerWrapper,
.checkWrapper {
    width: 100%;
    .MuiFormControlLabel-label {
       font-size: 18px !important;
    }
    .MuiFormControlLabel-root {
        align-items: center;
    }
}
.quickFilters{
    background: ${theme.colors.LINK_COLOR};
    height: 15px;
    display: block;
}
.emOnly {
    display: flex;
}
.exportContainer {
    justify-content: flex-end;  
}
.exportSection {
    justify-content: flex-start;
}
.MuiTable-root {
    min-width: auto;
}
.fieldSection {
    border-top: 2px solid ${theme.colors.BLACK};
    padding-top: 16px;
    justify-content: flex-end;
    margin-top: 20px;
    .MuiFormLabel-root {
        flex: 0 0 6%;
        align-self: center;
        padding-right: 4%;
        // font-size: 15px;
        font-size: ${theme.palette.iftaReport};
        font-family: ${theme.palette.tableFont};
    }
    .MuiFormLabel-root + .MuiBox-root {
        flex: 0 0 47.5%;
    }
}
.radiobuttonWrapper,
.dateFieldwrapper {
    display: none;
}
`}
`;
const TopWrapper = styled(Parent.TopWrapper)`
 
`;
const TableWrapper = styled(Parent.TableWrapper)`
 
`;
const Styled = { Wrapper, TopWrapper, TableWrapper };

export default Styled;
