const initialState = {
  data: [],
  currentItem: { vehicleIds: [], pages: [] },
  vehicleList: []
};
export const customDvir = {
  state: { ...initialState },
  reducers: {
    getListSuccess(state, payload) {
      return {
        ...state,
        data: [...payload]
      };
    },
    getListFailed(state) {
      return {
        ...initialState
      };
    },
    getVehicleListSuccess(state, payload) {
      return {
        ...state,
        vehicleList: payload
      };
    },
    getVehicleListFailed(state, payload) {
      return {
        ...state,
        vehicleList: []
      };
    },
    clearData(state) {
      return {
        ...initialState
      };
    },
    getDataSuccess(state, payload) {
      return {
        ...state,
        currentItem: payload
      };
    },
    newFormData(state) {
      return {
        ...state,
        currentItem: { vehicleIds: [], pages: [] }
      };
    },
    setInitialData() {
      return {
        loaded: false
      };
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload) {
      try {
        const getApi = await import(
          `@/config/apis/${window.$environment.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('customDvirFormsList');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject
          },
          id: payload.apiId
        });
        if (response) {
          this.getListSuccess([...response]);
        }
      } catch (error) {
        this.getListFailed();
      }
    },
    async deleteCustomDvir(payload, state) {
      try {
        const getApi = await import(
          `@/config/apis/${window.$environment.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('deleteCustomDvir');

        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject,
            data: { id: payload.data.id }
          },
          callback: payload.callback,
          id: payload.apiId
        });

        if (response) {
          let newList = [...state.customDvir.data];

          const index = newList.findIndex(
            (item) => item.id === payload.data.id
          );

          newList.splice(index, 1);
          this.getListSuccess([...newList]);
        }
      } catch (error) {}
    },
    async enableCustomDvir(payload, state) {
      try {
        const getApi = await import(
          `@/config/apis/${window.$environment.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('enableCustomDvir');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject,
            data: payload.data
          },
          callback: payload.callback,

          id: payload.apiId
        });
        if (response) {
          let newList = [...state.customDvir.data];
          const index = newList.findIndex(
            (item) => item.id === payload.data.id
          );
          newList[index].isEnabled = payload.data.isEnable;
          this.getListSuccess([...newList]);
        }
      } catch (error) {}
    },

    async getCustomDvirForm(payload, state) {
      try {
        const getApi = await import(
          `@/config/apis/${window.$environment.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('getCustomDvirForm');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject,
            data: payload.data
          },
          callback: payload.callback,

          id: payload.apiId
        });
        if (response) {
          let pages = [...response.pages];
          pages = pages.map((item) => {
            const key = item.title.replace(/ /g, '_').toLowerCase();
            return { ...item, isEditing: false, key };
          });

          this.getDataSuccess({ ...response, pages });
        }
      } catch (error) {}
    },

    async postCustomDvirForm(payload, state) {
      try {
        const getApi = await import(
          `@/config/apis/${window.$environment.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('postCustomDvirForm');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject,
            data: payload.data
          },
          callback: payload.callback.callback,

          id: payload.apiId
        });
        if (response) {
          this.clearData();
          payload.callback.successCallback();
        }
      } catch (error) {}
    },
    async listAllVehicles(payload, state) {
      try {
        const getApi = await import(
          `@/config/apis/${window.$environment.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('listAllVehicles');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject
          },
          id: payload.apiId
        });
        if (response) {
          const objectArrayFromDiffFields = (list, field, value) => {
            return list?.length
              ? list.map((item) => {
                  return {
                    label: item[value],
                    value: item[field]
                  };
                })
              : [];
          };
          const vehicleDriverList = objectArrayFromDiffFields(
            response,
            'vehicleId',
            'name'
          );

          this.getVehicleListSuccess(vehicleDriverList);
        }
      } catch (error) {
        this.getVehicleListFailed();
      }
    },
    async listAllTrailers(payload, state) {
      try {
        const getApi = await import(
          `@/config/apis/${window.$environment.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('listAllTrailers');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject
          },
          id: payload.apiId
        });
        if (response) {
          const objectArrayFromDiffFields = (list, field, value) => {
            return list?.length
              ? list.map((item) => {
                  return {
                    label: item[value],
                    value: item[field]
                  };
                })
              : [];
          };
          const vehicleDriverList = objectArrayFromDiffFields(
            response,
            'trailerId',
            'name'
          );

          this.getVehicleListSuccess(vehicleDriverList);
        }
      } catch (error) {
        this.getVehicleListFailed();
      }
    }
  })
};
