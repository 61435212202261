import React, { useEffect, useState, lazy } from 'react';
import PropTypes from 'prop-types';

import { imageUrls } from '../../../config/constants/keys';

const layout = window?.$environment?.CURRENT_LAYOUT;
const CloseButton = lazy(() => import(`../../CloseButton/${layout}`));

const Popup = ({
  isDocClicked,
  index,
  setisDocClicked,
  docList,
  selectedType,
  leftPosition,
  Styled
}) => {
  useEffect(() => {
    let value = 9 * index;
    let changeValue = 19.2 + value;
    setSelectedIndex(changeValue);
  }, [index]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <>
      {isDocClicked && (
        <Styled.Wrapper
          selectedindex={selectedIndex}
          selectedType={selectedType}
          leftPosition={leftPosition}
        >
          <CloseButton
            className="ToolsOptionClose"
            icon={imageUrls.popupClose}
            onClick={() => {
              setisDocClicked(false);
            }}
          />
          {docList()}
        </Styled.Wrapper>
      )}
    </>
  );
};

export default Popup;
Popup.defaultTypes = {};
Popup.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  fetchAccidents: PropTypes.func.isRequired
};
