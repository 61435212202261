import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

const defaultTheme = createMuiTheme();

//create config for colors;
const theme = ({ colors, fonts, secondaryFont='', headerFont='', subTitle='',tableFont='', titleLabel='', footerTheme='',footerPadding='', footerAlign='',footerWidth='', oldLogo='',formlabelFont='', titleFont='', buttonFont='', checkboxFont='', inputFont='', addbuttonFont='', companyDataFont='', registrationtrackon='',footerContact='',filterFont='', iftaReport='', inspectionInput='', inspectionresolution='', companyDashboard='' }) => ({
  palette: {
    primary: {
      main: colors.PRIMARY_MAIN,
      contrastText: colors.PRIMARY_CONTRAST,
      border: colors.PRIMARY_BORDER
    },
    secondary: {
      main: colors.SECONDARY_MAIN,
      contrastText: colors.WHITE
    },
    grey: { color: colors.DAILY_LOG_GRAPH_BORDER },
    background: {
      default: colors.PRIMARY_MAIN
    },
    error: {
      main: colors.RED
    },
    warning: {
      main: colors.WARNING
    },
    leftMenuText: {
      color: colors.PRIMARY_CONTRAST,
      fontSize: '18px',
      textDecoration: 'none',
      paddingTop: '2px'
    },
    fontFamily: fonts,
    secondaryFont,
    headerFont,
    subTitle,
    tableFont,
    titleLabel,
    footerTheme,
    registrationtrackon,
    footerPadding,
    footerAlign,
    footerWidth,
    oldLogo,
    formlabelFont,
    titleFont,
    buttonFont,
    checkboxFont,
    inputFont,
    addbuttonFont,
    companyDataFont,
    footerContact,
    filterFont,
    iftaReport,
    inspectionInput,
    inspectionresolution,
    companyDashboard,
    bodyFontSize: '18px',
    bodySmallFontSize: '16px',
    contentStyle: {
      minHeight: 'calc(100vh)',
      padding: '3%',
      backgroundColor: colors.BODY_BACKGROUND
    },
    contentlogsStyle: {
      minHeight: 'calc(100vh)',
      padding: '3%',
      backgroundColor: colors.BODY_BACKGROUND
    }
  },
  colors: { ...colors },
  overrides: {
    MuiExpansionPanelDetails: {
      root: {
        padding: '16px 16px 0px 16px'
      }
    },
    MuiButtonBase: {
      root: {
        disabled: true
      }
    },
    MuiTableSortLabel: {
      root: {
        color: colors.SECONDARY_MAIN + ' !important',
        '&:hover': {
          color: colors.SECONDARY_MAIN + ' !important'
        }
      },
      iconDirectionDesc: {},
      active: {
        color: colors.SECONDARY_MAIN + ' !important'
      },
      icon: {
        color: colors.SECONDARY_MAIN + ' !important'
      }
    },
    MuiTableRow: {
      root: {
        backgroundColor: colors.BACKGROUND,
        color: colors.PRIMARY_CONTRAST,
        '&:hover': {
          backgroundColor: colors.BACKGROUND
        }
      }
    },
    MuiSvgIcon: { root: { fontSize: '145.5%' } },
    MuiTable: {
      root: {
        border: '2px solid ' + colors.PRIMARY_BORDER
      }
    },
    MuiTypography: {
      body1: {
        color: colors.PRIMARY_CONTRAST,
        fontSize: '18px'
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: 'none'
      }
    },
    MuiInput: {
      root: {
        width: '100%'
      }
    },
    MuiInputBase: {
      input: {
        color: colors.PRIMARY_CONTRAST
      }
    },
    MuiTableCell: {
      head: {
        color: colors.WHITE
      },
      paddingCheckbox: {
        color: colors.PRIMARY_MAIN + ' !important'
      },
      body: {
        backgroundColor: colors.BLACK,
        color: colors.PRIMARY_CONTRAST,
        borderBottom: '.5px solid ' + colors.PRIMARY_MAIN,
        fontSize: '17px'
      }
    },
    MuiIconButton: {
      root: {
        color: colors.SECONDARY_MAIN
      }
    },
    MuiButton: {
      root: {
        color: colors.WHITE,
        backgroundColor: colors.SECONDARY_MAIN
        // padding: '0px!important'
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      xsm: 320,
      sm: 450,
      smd: 481,
      md: 768,
      mlg: 960,
      lg: 1024,
      xl: 1280,
      xxl: 1366
    }
  },

  typography: {
    body1: {
      color: colors.PRIMARY_CONTRAST,
      fontSize: '18px',
      textTransform: 'none'
    },
    body2: {
      fontSize: '18px'
    },
    h1: {
      fontSize: '20px',
      color: colors.PRIMARY_CONTRAST,
      [defaultTheme.breakpoints.up('sm')]: {
        fontSize: '24px'
      }
    },
    h2: {
      fontSize: '18px',
      color: colors.SECONDARY_MAIN,
      [defaultTheme.breakpoints.up('sm')]: {
        fontSize: '22px'
      }
    },

    fontFamily: fonts,
    color: colors.PRIMARY_CONTRAST,
    subtitle1: {
      fontSize: '16px',
      [defaultTheme.breakpoints.between('sm', 'md')]: {
        fontSize: '18px'
      },
      [defaultTheme.breakpoints.up('lg')]: {
        fontSize: '25px'
      }
    }
  }
});

export default theme;
