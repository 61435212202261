import styled from 'styled-components';
import Parent from '../Base/style';

const UploadWrapper = styled(Parent)`
.filepond--wrapper{
    border: 1px solid #bcb1b1 !important;
}
.filepond--panel-root{
    background: #fff !important;
}
.filepond--file-action-button {
    border-radius: 50%;
    background-color: rgba(0,0,0,.5) !important;
    padding: 0 !important;
}
.filepond--item {
    top: 12px !important;
}
.filepond--file [data-align*=left] {
    left:  -0.3em;
    top: -0.3em;
}
`;
export default UploadWrapper;
