import React, { useEffect, lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useHistory } from 'react-router-dom';
import useTheme from '@material-ui/core/styles/useTheme';
import { useTranslation } from 'react-i18next';

import { hasValue, generateDropdownOptions, isInsuracePortalUser } from '@/utils/utils';
import Box from '@material-ui/core/Box';
const Title = lazy(() => import(`@/components/Title`));
const Content = lazy(() => import(`@/components/modules/TrailerManagement/Component`));


const AddTrailer = ({ apiStatus, fetchTrailerData, fetchInsuranceTrailerData, fetchCompanyList, getDocumentToken, regions, trailer, clearReceipt, ...rest }) => {
  const history = useHistory();
  const theme = useTheme();
  const i18n = useTranslation();

  const fetchTrailerDataApi = 'fetchTrailerData';
  const fetchInsuraceTrailerDataApi = 'fetchInsuranceTrailerData';
  const getDocumentTokenApiId = 'getDocToken';
  const hasFetchApiStatus = hasValue(
    apiStatus.status,
    'api',
    fetchTrailerDataApi
  );

  useEffect(() => {
    isInsuracePortalUser() ? fetchInsuranceTrailerData({ apiId: fetchInsuraceTrailerDataApi }) : fetchTrailerData({ apiId: fetchTrailerDataApi });
    fetchCompanyList({ apiId: 'fetchFleetCompanies' });
    getDocumentToken({ apiId: getDocumentTokenApiId })
    clearReceipt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let object = { ...disabledCriteria };
    if (trailer?.accessLevel < 1) {
      object.regionId = true;
      object.terminalId = true;
      object.departmentId = true;
      object.subDepartmentId = true;
    } else if (trailer?.accessLevel < 2) {
      object.regionId = true;
      object.terminalId = true;
      object.departmentId = true;
      object.subDepartmentId = false;
    } else if (trailer?.accessLevel < 3) {
      object.regionId = true;
      object.terminalId = true;
      object.departmentId = false;
      object.subDepartmentId = false;
    } else if (trailer?.accessLevel < 4) {
      object.regionId = true;
      object.terminalId = false;
      object.departmentId = false;
      object.subDepartmentId = false;
    } else {
      object.regionId = false;
      object.terminalId = false;
      object.departmentId = false;
      object.subDepartmentId = false;
    }
    setDisabledCriteria(object);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trailer?.accessLevel]);

  const [disabledCriteria, setDisabledCriteria] = useState({
    regionId: false,
    terminalId: false,
    departmentId: false,
    subDepartmentId: false
  });
  const recallUpdate = (company) => {
    fetchInsuranceTrailerData({
      apiId: fetchInsuraceTrailerDataApi, data: {
        company,
        recall: true
      }
    });
  }

  return (
    <>
      <Title header={i18n.t('trailers.addHeading')} i18n={i18n} />
      <Box style={{ ...theme.palette.contentStyle }}>

        <Content
          {...rest}
          apiStatus={apiStatus}
          recallUpdate={recallUpdate}
          regions={generateDropdownOptions(
            isInsuracePortalUser() && regions?.data?.regions?.length
              ? regions.data.regions
              : trailer.regions,
            'regionName',
            'id'
          )}
          terminals={generateDropdownOptions(
            regions?.data?.terminals?.length ? regions.data.terminals : trailer.terminals,
            'terminalName',
            'terminalId'
          )}
          departments={generateDropdownOptions(
            regions?.data?.departments?.length ? regions.data.departments :
              !regions?.data?.departments?.length && regions?.data?.terminals?.length ? [] :
                trailer.departments,
            'departmentName',
            'departmentId'
          )}
          subDepartments={generateDropdownOptions(
            regions?.data?.subDepartments?.length ? regions.data.subDepartments :
              !regions?.data?.subDepartments?.length && regions?.data?.departments?.length ? [] :
                trailer.subDepartments,
            'subDepartmentName',
            'subDepartmentId'
          )}
          disabledCriteria={disabledCriteria}
          trailer={trailer}

        />
      </Box> </>);
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    regions: state.regions,
    documents: state.documents,
    trailer: state.trailers,
    fleetCompanies: state.fleetCompanyList.companyList,
    logBook: state.logBook,
    receipt: state.viewReport
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchTerminals: (data) => dispatch.regions.fetchTerminals(data),
    fetchDepartments: (data) => dispatch.regions.fetchDepartments(data),
    fetchSubDepartments: (data) => dispatch.regions.fetchSubDepartments(data),
    fetchTrailerData: (data) => dispatch.trailers.fetchData(data),
    saveTrailerData: (data) => dispatch.trailers.saveData(data),
    fetchInsuranceTrailerData: (data) => dispatch.trailers.fetchInsuranceData(data),
    getRegions: (data) => dispatch.regions.fetchRegionsByCompany(data),
    fetchCompanyList: (data) => dispatch.fleetCompanyList.fetchCompanyList(data),
    isValidVin: (data) => dispatch.trailers.isValidVin(data),
    saveDocument: (data) => dispatch.trailers.saveDocument(data),
    deleteDocument: (data) => dispatch.trailers.deleteDocument(data),
    getDocumentToken: (data) => dispatch.logBook.documentToken(data),
    downloadDocument: (data) => dispatch.viewReport.downloadReport(data),
    clearReceipt: () => dispatch.viewReport.clearReceipt()
  };
};

export default connect(mapState, mapDispatch)(AddTrailer);
AddTrailer.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  fetchRegions: PropTypes.func.isRequired,
  getRegions: PropTypes.func.isRequired,
  fetchInsuranceTrailerData: PropTypes.func.isRequired,
  fetchCompanyList: PropTypes.func.isRequired,
  fetchTerminals: PropTypes.func.isRequired,
  fetchDepartments: PropTypes.func.isRequired,
  saveDocument: PropTypes.func.isRequired,
  deleteDocument: PropTypes.func.isRequired,
  fetchTrailerData: PropTypes.func.isRequired,
  saveTrailerData: PropTypes.func.isRequired,
  fetchSubDepartments: PropTypes.func.isRequired
};
