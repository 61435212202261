import React, { lazy, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import Label from '../../../../../components/UI/FormLabel/Clutch';
import Styled from './style';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '../../../../../components/UI/Button/Clutch';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Input = lazy(() =>
  import(`../../../../../components/UI/Input/${layout}`)
);
const Message = lazy(() =>
  import(`../../../../../components/UI/Message/${layout}`)
);
const Select = lazy(() =>
  import(`../../../../../components/UI/Select/${layout}`)
);

const FormStyle = require(`../../../../../components/UI/Form/${layout}/serverStyle`);

const RenderComponent = ({
  values,
  handleEdit,
  errors,
  isNotValid,
  validateForm,
  customProps,
  submitAction,
  onClear
}) => {
  const showError = (field, validationErrors, isNotValid, apiError) => {
    if (validationErrors[field] && !isNotValid) {
      return <Message type="error" message={errors[field]} />;
    }
    if (apiError?.[field]) {
      return <Message type="error" message={apiError[field]} />;
    }
  };
  useEffect(() => {
    if (!customProps.differentAddress) {
      document.getElementById('shippingAddress').style.display = 'none';
      Object.keys(errors).forEach(function (key) {
        delete errors[key];
      });
    } else {
      document.getElementById('shippingAddress').style.display = 'block ';
    }
  }, [customProps.differentAddress]);
  return (
    <Styled.Wrapper>
      <div id="shippingAddress">
        <div className="subHeading">Shipping Address</div>
        <div className="companyWrapper">
          <Styled.FieldsWrapper>
            <Label variant="body1">Recipient Name</Label>
            <Styled.TextWrapper>
              <Field
                name="recipentName"
                handleOnChange={(event) =>
                  handleEdit(event, { field: 'recipentName' })
                }
                as={Input}
                placeholder="Recipient Name"
                value={values['recipentName']}
                type="text"
              />
              {showError(
                'recipentName',
                errors,
                isNotValid,
                customProps.hasApiStatus?.errorMessage
              )}
            </Styled.TextWrapper>
          </Styled.FieldsWrapper>
        </div>

        <div className="addressWrapper">
          <Styled.FieldsWrapper>
            <Label variant="body1">Address</Label>
            <Styled.TextWrapper>
              <Field
                name="address"
                handleOnChange={(event) =>
                  handleEdit(event, { field: 'address' })
                }
                as={Input}
                placeholder="Type Address"
                value={values['address']}
                type="text"
              />
              {showError(
                'address',
                errors,
                isNotValid,
                customProps.hasApiStatus?.errorMessage
              )}
            </Styled.TextWrapper>
          </Styled.FieldsWrapper>
          <Styled.FieldsWrapper className="stateSelect">
            <div className="stateSelector">
              <Label variant="body1">State</Label>
              <Styled.TextWrapper>
                <Field
                  name="state"
                  onChange={(event) => {
                    handleEdit(event, { field: 'state' });
                  }}
                  as={Select}
                  placeholder="Select State"
                  value={values['state']}
                  suggestions={customProps?.states}
                />
                {showError(
                  'state',
                  errors,
                  isNotValid,
                  customProps.hasApiStatus?.errorMessage
                )}
              </Styled.TextWrapper>
            </div>
          </Styled.FieldsWrapper>
        </div>
        <div className="addressWrapper">
          <Styled.FieldsWrapper>
            <Label variant="body1">City</Label>
            <Styled.TextWrapper>
              <Field
                name="city"
                handleOnChange={(event) => handleEdit(event, { field: 'city' })}
                as={Input}
                placeholder="Type City"
                value={values['city']}
                type="text"
              />
              {showError(
                'city',
                errors,
                isNotValid,
                customProps.hasApiStatus?.errorMessage
              )}
            </Styled.TextWrapper>
          </Styled.FieldsWrapper>
          <Styled.FieldsWrapper>
            <Label variant="body1">Zip Code</Label>
            <Styled.TextWrapper>
              <Field
                name="zipCode"
                handleOnChange={(event) =>
                  handleEdit(event, { field: 'zipCode' })
                }
                as={Input}
                placeholder="Enter Zip Code"
                value={values['zipCode']}
                type="text"
              />
              {showError(
                'zipCode',
                errors,
                isNotValid,
                customProps.hasApiStatus?.errorMessage
              )}
            </Styled.TextWrapper>
          </Styled.FieldsWrapper>
        </div>
      </div>
      <FormStyle.default.ButtonsWrapper>
        <Button
          id="checkOutSubmit"
          onClick={() => {
            if (customProps.differentAddress) {
              const validateResponse = validateForm();
              validateResponse.then((data) => submitAction(data));
            } else {
              return customProps?.handleDiffSubmit(values);
            }
          }}
        />
      </FormStyle.default.ButtonsWrapper>
    </Styled.Wrapper>
  );
};

export default RenderComponent;

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  customProps: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};
