import React, { useEffect, lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RenderComponent from '@/components/modules/DynamicRenderPage';

import ParentStyled from './style';
import { loadStyle, loadPageConfig } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'pages/web/FuelConsumptionReport');

const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.fuelConsumptionReport[0].name}`));
const Content = lazy(() => import(`@/${config.fuelConsumptionReport[1].name}`));
const TopActions =
  config.fuelConsumptionReport[0].children &&
  lazy(() => import(`${config.fuelConsumptionReport[0].children.name}`));

const FuelConsumptionReport = (parentProps) => {
  const {
    fetchFuelConsumptionReport,
    history,
    i18n,
    theme,
    getFilters,
    vehicleListDropDown,
    driverListDropDown,
  } = parentProps;
  const [pagination, setPagination] = useState(false);
  const commonParentProps = { history, i18n, Styled, theme };

  const objectArrayFromDiffFields = (list, value, field1, field2, callback) => {
    return list?.length
      ? list.map((item) => {
          if (callback) return callback(item, field1, field2, value);
          else
            return {
              label: field2 ? `${item[field1]} ${item[field2]}` : item[field1],
              value: item[value]
            };
        })
      : [];
  };

  useEffect(() => {
    fetchFuelConsumptionReport({
      apiId: 'fetchFuelConsumptionReport'
    });
    getFilters({ apiId: 'fetchFilters' });
    // getVehicleListDropDown({ apiId: 'getVehicleListDropDown' });
    // getDrivers({ apiId: 'getDrivers' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const vehicleList = objectArrayFromDiffFields(
    vehicleListDropDown,
    'vehicleId',
    'name'
  );

  return (
    <>
      <RenderComponent
        {...config.fuelConsumptionReport[0]}
        parentProps={{ ...parentProps, pagination, setPagination, vehicleList, driverList: driverListDropDown?.data }}
        commonParentProps={commonParentProps}
        component={Title}
        childComponent={TopActions}
      />
      <RenderComponent
        {...config.fuelConsumptionReport[1]}
        parentProps={{ ...parentProps, pagination, setPagination, vehicleList, driverList: driverListDropDown?.data }}
        commonParentProps={commonParentProps}
        component={Content}
      />
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    filters: state.filters,
    fuelConsumption: state?.fuelConsumption,
    vehicleListDropDown: state?.vehicles?.vehicleListDropDown,
    driverListDropDown: state?.drivers,
    stateListData: state?.fuelConsumption,
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchFuelConsumptionReport: (data) =>
      dispatch.fuelConsumption.fetchData(data),
    filterSuccess: (data) => dispatch.fuelConsumption.filterSuccess(data),
    getFilters: (data) => dispatch.filters.fetchData(data),
    getStateListData: (data) => dispatch.fuelConsumption.fetchStateListData(data),
    getVehicleListDropDown: (data) => dispatch.vehicles.fetchVehicleListDropDown(data),
    getDrivers: () => dispatch.drivers.getDrivers(),
    addEditFuelData: (data) => dispatch.fuelConsumption.saveData(data),
  };
};

export default connect(mapState, mapDispatch)(FuelConsumptionReport);
FuelConsumptionReport.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  fetchFuelConsumptionReport: PropTypes.func.isRequired,
  getVehicleListDropDown: PropTypes.func.isRequired,
  fuelConsumption: PropTypes.array.isRequired,
};
