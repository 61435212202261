import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RenderComponent from '@/components/modules/DynamicRenderPage';

import ParentStyled from './style';
import { loadStyle, loadPageConfig } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'pages/web/SubscriptionMessage');

const config = loadPageConfig();

const Title = lazy(() => import(`@/${config.subscriptionMessage[0].name}`));
const Content = lazy(() => import(`@/${config.subscriptionMessage[1].name}`));

const SubscriptionMessage = (parentProps) => {
  const { history, i18n, theme } = parentProps;
  const commonParentProps = { history, i18n, Styled, theme };
  return (
    <>
      <RenderComponent
        {...config.subscriptionMessage[0]}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        component={Title}
      />
      <RenderComponent
        {...config.subscriptionMessage[1]}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        component={Content}
      />
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    driverStatusHistory: state.driverStatusHistory
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchDriverStatusHistory: (data) =>
      dispatch.driverStatusHistory.fetchData(data),
    exportCsvData: (data) => dispatch.driverStatusHistory.exportCsvData(data),
    exportPdfData: (data) => dispatch.driverStatusHistory.exportPdfData(data)
  };
};
export default connect(mapState, mapDispatch)(SubscriptionMessage);

SubscriptionMessage.propTypes = {
  message: PropTypes.string.isRequired,
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};
