import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import Private from './Private';
import OverRidePrivate from './OverridePrivate';
import Public from './Public';

const Clutch = ({
  children,
  layout,
  setAuth,
  pageTitle,
  folderPath,
  showFilterPopup,
  callback,
  handleLogout,
  dashboard,
  apiStatus,
  clearRedux
}) => {
  const privateRootProps = {
    layout,
    setAuth,
    folderPath,
    showFilterPopup,
    callback,
    handleLogout,
    dashboard,
    apiStatus,
    clearRedux
  };
  const baseUrl = `${process.env.CDN_URL || ''}/${window.$environment?.SERVICE_PROVIDER}`;
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="manifest" href={`${baseUrl}/manifest.json`} />
        <link rel="icon" type="image/x-icon" href={`${baseUrl}/favicon.ico`} />
      </Helmet>
      {layout === 'login' ? (
        <Public>{children}</Public>
      ): window.$environment.SERVICE_PROVIDER === 'webfleet'? 
      (
        <OverRidePrivate {...privateRootProps}>{children}</OverRidePrivate>
      )
      :(
        <Private {...privateRootProps}>{children}</Private>
      )}
    </>
  );
};
export default Clutch;
