import styled from 'styled-components';

import Box from '@material-ui/core/Box';

import { getImagePath } from '../../../utils/utils';
import { imageUrls } from '../../../config/constants/keys';

const Wrapper = styled.main``;
const Content = styled(Box)`
  background-image: url(${getImagePath(imageUrls.bgClutch)});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  position: relative;
`;
const IconWrapper = styled(Box)`
  ${({ theme }) => `
  text-align: left;
  padding-left: 10%;
  padding-top: 5%;
  position: absolute;
  bottom: 3px;
  .MuiTypography-colorPrimary:first-child {
    margin-right: 60px;
  }
  img {
    @media (max-width: ${theme.breakpoints.values.md}px) {
      width: 30%;
    }
  }
  @media (max-height: 720px) {
    display: none;
  }
  `}
`;
const Styled = { Wrapper, Content, IconWrapper };

export default Styled;
