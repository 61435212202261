import React from 'react';

// import Form from '../../../../components/UI/Form';
import { hasValue } from '../../../../utils/utils';

const Component = ({ i18n, apiStatus, addCompany }) => {
  const companyRegistrationForm = () => {
    const formId = 'companyRegistrationForm';
    //Check whether the api is calling
    const hasApiStatus = hasValue(apiStatus.status, 'api', formId);
    return (
      // <Form
      //   view={'column'}
      //   hasApiStatus={hasApiStatus}
      //   id={formId}
      //   direction="row"
      //   initialValues={{
      //     company: '',
      //     dotNumber: '',
      //     address: '',
      //     city: '',
      //     state: '',
      //     initialTrucks: '',
      //     contractLength: '',
      //     discount: '',
      //     firstName: '',
      //     lastName: '',
      //     email: '',
      //     userPhone: '',
      //     password: ''
      //   }}
      //   handleOnSubmit={fields => {
      //     // addCompany({ fields, callback });
      //   }}
      //   fields={{
      //     data: [
      //       {
      //         title: i18n.t('COMPANY INFORMATION'),
      //         direction: 'column',

      //         items: [
      //           {
      //             type: 'text',
      //             value: '',
      //             field: 'company',
      //             placeholder: '',
      //             label: i18n.t('Company'),
      //             isTimeNeeded: true
      //           },
      //           {
      //             type: 'text',
      //             value: '',
      //             field: 'dotNumber',
      //             placeholder: '',
      //             label: i18n.t('DOT No.'),
      //             isTimeNeeded: false
      //           },
      //           {
      //             type: 'text',
      //             value: '',
      //             field: 'address',
      //             label: i18n.t('Address'),
      //             placeholder: ''
      //           },
      //           {
      //             type: 'text',
      //             value: '',
      //             field: 'city',
      //             label: i18n.t('City'),
      //             placeholder: ''
      //           },
      //           {
      //             type: 'select',
      //             value: '',
      //             field: 'state',
      //             placeholder: '',
      //             label: i18n.t('State'),
      //             handleOnChange: () => {},
      //             suggestions: [],
      //             multiple: false
      //           },
      //           {
      //             type: 'text',
      //             value: '',
      //             field: 'initialTrucks',
      //             label: i18n.t('Number of Trucks'),
      //             placeholder: ''
      //           },
      //           {
      //             type: 'text',
      //             value: '',
      //             field: 'contractLength',
      //             label: i18n.t('Contract Length'),
      //             placeholder: ''
      //           },
      //           {
      //             type: 'text',
      //             value: '',
      //             field: 'discount',
      //             label: i18n.t('Discount %'),
      //             placeholder: ''
      //           }
      //         ]
      //       },
      //       {
      //         title: i18n.t('FLEET MANAGER INFORMATION'),
      //         direction: 'column',

      //         items: [
      //           {
      //             type: 'text',
      //             value: '',
      //             field: 'firstName',
      //             placeholder: '',
      //             label: i18n.t('First Name')
      //           },
      //           {
      //             type: 'text',
      //             value: '',
      //             field: 'lastName',
      //             placeholder: '',
      //             label: i18n.t('Last Name')
      //           },
      //           {
      //             type: 'text',
      //             value: '',
      //             field: 'email',
      //             label: i18n.t('Email'),
      //             placeholder: ''
      //           },
      //           {
      //             type: 'text',
      //             value: '',
      //             field: 'userPhone',
      //             label: i18n.t('Phone'),
      //             placeholder: ''
      //           },
      //           {
      //             type: 'password',
      //             value: '',
      //             field: 'password',
      //             placeholder: '',
      //             label: i18n.t('Password')
      //           }
      //         ]
      //       }
      //     ],
      //     buttons: [
      //       { type: 'button', value: i18n.t('Cancel') },
      //       { type: 'submit', value: i18n.t('Submit') }
      //     ]
      //   }}
      //   validationSchema={Yup.object().shape({
      //     company: Yup.string().required(i18n.t('This field is required')),
      //     dotNumber: Yup.string().required(i18n.t('This field is required')),
      //     address: Yup.string().required(i18n.t('This field is required')),
      //     city: Yup.string().required(i18n.t('This field is required')),
      //     state: Yup.string().required(i18n.t('This field is required')),
      //     initialTrucks: Yup.string().required(
      //       i18n.t('This field is required')
      //     ),
      //     contractLength: Yup.string().required(
      //       i18n.t('This field is required')
      //     ),
      //     discount: Yup.string().required(i18n.t('This field is required')),
      //     firstName: Yup.string().required(i18n.t('This field is required')),
      //     lastName: Yup.string().required(i18n.t('This field is required')),
      //     email: Yup.string().required(i18n.t('This field is required')),
      //     userPhone: Yup.string().required(i18n.t('This field is required')),
      //     password: Yup.string().required(i18n.t('This field is required'))
      //   })}
      // />
      null
    );
  };

  return <>{companyRegistrationForm()}</>;
};

export default Component;

Component.defaultProps = {};
