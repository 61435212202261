import styled from 'styled-components';
import Parent from '../Base/style';
import { getImagePath, getStyle } from '../../../../utils/utils';
import { imageUrls } from '../../../../config/constants/keys';
const ExportContainer = styled(Parent)`
${({ theme, exportUserTemplate }) => `
.exportWrapper{
    color: ${theme.colors.LINK_COLOR};
    font-size: 14px;
    font-family: tahoma;
    background:
      url(${
        exportUserTemplate
          ? getImagePath(imageUrls.importUserold)
          : ''
      }) no-repeat;
    &:hover{
        text-decoration: underline;
        cursor: pointer;
    }
    width: ${ exportUserTemplate ? '25px' : 'auto'}; 
    height: ${ exportUserTemplate ? '25px' : 'auto'}; 
    .exportText {
      display: ${ exportUserTemplate ? 'none' : 'block'};
      white-space:  ${ exportUserTemplate ? 'initial' : 'nowrap'};

    }
}
`}
`;

export default ExportContainer;
