import React, { useEffect, lazy } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';

import { useHistory } from 'react-router-dom';
import useTheme from '@material-ui/core/styles/useTheme';
import { useTranslation } from 'react-i18next';
import ParentStyled from './style';
import Content from './Component';
import { hasEnabledFeature, hasValue, loadStyle } from '@/utils/utils';
import { pageUrls } from '@/config/constants/keys';
const Title = lazy(() => import(`@/components/Title`));
const layout = window?.$environment?.CURRENT_LAYOUT;

const SvgIcon = lazy(() => import(`@/components/SvgIcon/${layout}`));
const Tab = lazy(() => import(`@/components/UI/Tab/${layout}`));

let Styled = loadStyle(ParentStyled, 'pages/web/DotAudit');
const DotAudit = ({ apiStatus, getDrivers, drivers, ...rest }) => {
  const history = useHistory();
  const theme = useTheme();
  const i18n = useTranslation();

  const apiId = 'getDrivers';
  const hasFetchApiStatus = hasValue(apiStatus.status, 'api', apiId);

  useEffect(() => {
    !drivers.data.length &&
      getDrivers({
        apiId: 'getDrivers'
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (document.getElementById('list1')) {
      const isSelected = document
        .getElementById('list1')
        ?.className.includes('Mui-selected');
      document.getElementById('list1').className = !isSelected
        ? document.getElementById('list1').className + ' Mui-selected'
        : document.getElementById('list1').className;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.getElementById('list1')]);

  const contentProps = {
    Styled,
    i18n,
    history,
    apiStatus,
    drivers,
    ...rest
  };
  const handleChange = (event, newValue) => {
    history.push(tabValues[newValue].url);
  };

  const tabValues = [
    {
      name: i18n.t('logs.manageLogsHeader'),
      value: null,
      url: pageUrls.logsList
    },
    {
      name: i18n.t('logs.undefinedDriverHeader'),
      value: null,
      url: pageUrls.logsUdList
    },
    {
      name: i18n.t('leftMenu.DOTAudit'),
      value: <Content {...contentProps} />,
      url: pageUrls.fmcsaLogs
    },
    ...(window.$environment.SERVICE_PROVIDER !== 'spireon' ? [
    {
      name: i18n.t('logs.addLogHeader'),
      value: null,
      url: pageUrls.logsAdd
    },
    {
      name: i18n.t('logs.archiveLogsHeader'),
      value: null,
      url: pageUrls.archiveLogsList
    }] : []),
    
  ];

  if (window.$environment.SERVICE_PROVIDER === 'spireon') {
    return (
      <Styled.Content>
        <div className="goBack">
          <SvgIcon
            value={'pageBackButton.svg'}
            onClick={() => history.goBack()}
          />
        </div>
        <Tab
          data={tabValues}
          className="logwrapper"
          currentTab={2}
          onChange={handleChange}
          vertical={false}
        />
      </Styled.Content>
    );
  } else
    return (
      <>
        <Title header={hasEnabledFeature('canadaComplianceEnabled') ? "leftMenu.Audit" : "leftMenu.DOTAudit"} i18n={i18n} />
        <Box style={{ ...theme.palette.contentStyle }}>
          <Content {...contentProps} />
        </Box>
      </>
    );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    drivers: state.drivers
  };
};
const mapDispatch = (dispatch) => {
  return {
    sendFmcsa: (data) => dispatch.dotAudit.sendData(data),
    getDrivers: () => dispatch.drivers.getDrivers()
  };
};

export default connect(mapState, mapDispatch)(DotAudit);
DotAudit.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  sendFmcsa: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  drivers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getDrivers: PropTypes.func.isRequired
};
