import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import { getStyle } from '../../../utils/utils';

const Wrapper = styled(Box)`
  ${({ theme }) => `
  #udNotification {
    background: ${theme.colors.LINK_COLOR};
    text-align: center;
    font-size: 16px;
    position: fixed;
    top: 0;
    width: 100%;
    a {
      color: ${theme.colors.WHITE} !important;
    }
  }
  .tabs {
    .MuiTab-wrapper {
      font-size: 22px;
      text-transform: capitalize !important;
    }
    .MuiPaper-root.MuiAppBar-positionStatic {
      padding: 0px 1%;
      padding-top: 20px;
      background: transparent;
    }
    .MuiButtonBase-root.MuiTab-root {
      width:13%;
    }
  }
  
  .tooltiptext {
    visibility: hidden;
    width: 100px;
    background: ${getStyle(
      '',
      (theme.palette.primary || {}).main,
      theme.colors.GREY_ONE
    )};
    color: ${theme.colors.WHITE};
    text-align: center;
    padding: 2px 0;
    border-radius: 0px;
    border: solid 1px ${theme.colors.WHITE};
    position: absolute;
    z-index: 1;
    right: 0;
    top: 35px !important;
    font-size: 15px;
  }
  .iconContainer:hover + .tooltiptext {
      visibility: visible;
    }
  }
  .iconContainer:first-child {
    margin-right: 8px;
  }
  .viewAll{
    display: flex;
    justify-content: flex-end;
    position: relative;
    padding-bottom: 10px;
    padding-top: 13px;
    .MuiGrid-container {
      width: auto;
      .MuiAvatar-root{
        width:32px
        height: 32px;
        
      }
    }
    .normalCheckbox span {
      padding: 0 5%;
    }
    .clearAll {
      margin-right: 10px;
    }
    .enable {
      width:30%;
      text-align: right;
    }
  }
  .seatbeathStatus {
    padding: 5px 18px;
    width: 156px;
    display: inline-block;
    text-align: center;
    font-size: 13px !important;
    color: ${theme.colors.WHITE};
  }
  .noData {
    background: ${theme.colors.GREY_DATA};
  }
  .notBuckled {
    background: ${theme.colors.RED};
  }
  .buckled {
    background: ${theme.colors.GREEN};
  }
  .eld-active {
    background: green;
    height: 10px;
    width: 10px;
    border-radius: 5px;
    margin-top: 5px;
  }
  .eld-inActive {
    background: red;
    height: 10px;
    width: 10px;
    border-radius: 5px;
    margin-top: 5px;
  }
  .eld-loggedOut {
    background: #716F6E;
    height: 10px;
    width: 10px;
    border-radius: 5px;
    margin-top: 5px;
  }
  `}
`;

const HeaderWrapper = styled.ul`
  ${({ theme }) => `
  list-style: none;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  flex-wrap: wrap;
  margin-top: 0;
  width: 100%;
  margin-bottom: 0;
  border-left: 0;
  padding: 0.4% 0.9%;
  padding-left: 30px;
  padding-bottom: 0.8%;
  padding-top: 0;
  padding-right: 0;
  justify-content: flex-start;
  .clutchOnly {
    display: block;
    align-self: center;
    font-size:18px;
    width: 130px;
    margin-top: 12px;
  }
  li {
    border-right: 0;
    padding: 0 23px;
    flex: 0 0 auto;
    color: ${theme.colors.BLACK};
    text-align: center;
    align-self: baseline;
    flex-direction: column-reverse;
    display: flex;
    :last-child {
      display: flex;
      flex-direction: column-reverse;
    }
    .totalAmount {
      color: #425061 !important;
      font-size: 22px !important;
    }
    .noLink {
      color: ${theme.colors.BLACK} !important;
    }
    .total {
      font-size: 42px !important;
    }
    div:first-child {
      color: ${theme.colors.BLACK};
      font-size: 22px;
      font-weight: normal;
      margin-top: -5px;
      @media (max-width: ${theme.breakpoints.values.md - 1}px) {
        font-size: 12px;
      }
    }
    div:last-child {
      color: ${theme.colors.BLACK};
      font-size: 32px;
      font-weight: bold;
      @media (max-width: ${theme.breakpoints.values.md - 1}px) {
        font-size: 14px;
      }
    }
  }
  a{
    display: flex;
    flex-direction: column-reverse;
    :hover {
      text-decoration: none;
    }
  }
  .total {
    color:  #425061!important;
  }
  .noLink {
    color: #000 !important;
  }
  `}
`;
const NotFoundWrapper = styled.div`
  ${({ theme }) => `
  color: ${theme.colors.PRIMARY_CONTRAST};
  marign: auto;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 2%;
  h1 {
    text-align: center;
  }
  p {
    margin-top: 0;
    font-size: 20px;
  }
  .here {
    padding-left: 5px;
    text-decoration: none;
  }
  `}
`;

const SummarySection = styled.div`
  .spireonOnly {
    display: none;
  }
`;
const MapWrapper = styled.div``;
const Styled = {
  Wrapper,
  HeaderWrapper,
  MapWrapper,
  NotFoundWrapper,
  SummarySection
};

export default Styled;
