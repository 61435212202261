import styled from 'styled-components';
import * as Parent from '../Base/style';

export const ModalWrapper = styled(Parent.ModalWrapper)`
${({ theme, width }) => `
.scheduleForm{
  overflow: auto;
  max-height: 600px;
    .itemWrapper{
      margin-bottom: 10px;
      display: -webkit-inline-box;
        width: 100%;
        .label {
          width: 20%;
        }
        .value {
          font-weight: 600;
        }
      }
    form {
      align-items: center;
    }
    .scheduleDateWrapper{
      width: 50%;
      .fieldWrapper{
        display: block !important;
      }
    }
    padding: 0 30px;
    .fullWidth{
      width:100%;
  }
  .radioWrapper{
      width:100%;
      margin-bottom: 10px !important;
      .label{
          align-items: center;
          display: flex;
          flex: 0 0 20% !important;
          color: ${theme.colors.PRIMARY_CONTRAST};
      }
      .recurrenceIntervalBox {
          display: block !important;
      }
      .infoText{
          color: ${theme.colors.SECONDARY_MAIN};
      }
  }
  .recurrenceIntervalBoxLabel{
      padding-bottom: 25px;;
  }
  }
.driverNameModal {
    li{
        border-bottom:0;
        background-color: #fff;
        color: #444;
        padding: 10px;
    }
    li:nth-child(odd){
        background-color: #efefef;
    }
    
}
.departmentConfirm {
    color: #575757;
}
.deleteParent,
.successpopup {
    h2 {
        color: #575757;
    }
}
`}`;
export const ModalWrapper2 = styled(Parent.ModalWrapper2)`
.departmentConfirm {
    color: #575757;
}
.deletedRow {
    color: #575757;
    font-size: 30px;
    text-align: center;
    font-weight: 600;
    text-transform: none;
    position: relative;
    margin-top: 5px;
    margin-bottom: 25px;
    padding: 0;
    line-height: 40px;
    display: block;
    width: 100%;
}
`;
export const ParentWrapper = styled(Parent.ParentWrapper)``;
