import styled from 'styled-components';
import Parent from './style';
const Wrapper = styled(Parent.Wrapper)`
    ${({ theme }) => `
    padding:2%;
    .formSection{
        padding: 0;
        padding-left: 20%;

    }
        form button.MuiButtonBase-root {
            margin-top: 28px;
            min-width: 117px !important;
            .MuiButton-label{
                padding: 2px 0px;
            }
        }
    `}
`;

const Styled = { Wrapper};

export default Styled;
