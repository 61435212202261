import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
  .heading {
    text-transform: uppercase;
  }
`;
const Profile = styled(Box)`
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 5px 75px;
  .MuiInput-root {
    width: 50%;
  }
  h1 {
    background: #77838c none repeat scroll 0 0 !important;
    color: #fff !important;
    font-weight: 600;
  }
  .vertical {
    width: 100%;
    margin-right: 1%;
    align-self: flex-start;
    flex-direction: row;
  }
  .vertical-button {
    justify-content: center;
  }
  .section-wrapper{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .vertical-button div {
    margin-right: 3px !important;
  }
  p {
    color: #425061;
    cursor: pointer;
  }
  p: hover {
    text-decoration: underline;
  }
  .MuiFormLabel-root{
    font: bold 12px "Helvetica Neue",Helvetica,Arial,sans-serif !important;
    color: #222 !important;
  }
`;
const Styled = {
  Wrapper,
  Profile
};
export default Styled;
