import styled from 'styled-components';
import { getImagePath } from '../../../utils/utils';

export const FooterContainer = styled.div`
${({ theme, style }) => `
background: ${
  style
    ? style.color
    : theme.palette.primary
    ? theme.palette.primary.main
    : theme.colors.PRIMARY_MAIN
};
height: 77px;
display: flex;
align-items: center;
justify-content: space-between;
flex-direction: column;
@media (min-width: ${theme.breakpoints.values.lg + 1}px) {
  flex-direction: row;
}
`}
`;

export const FooterContacts = styled.div`
${({ theme }) => `
display: inline-block;
width: 71%;
.flexDiv {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  @media (max-width: ${theme.breakpoints.values.lg}px) {
    justify-content: center;
  }
}
.textFooter {
  margin-left: 10px;
  margin-top: 5px;
  padding-right: 20px;
  @media (max-width: ${theme.breakpoints.values.md}px) {
    flex: 0 0 50%;
    text-align: left;
    padding-right: 0;
  }
  @media (min-width: ${theme.breakpoints.values.md}px) and (max-width: ${
  theme.breakpoints.values.lg
}px) {
    flex: 0 0 27%;
    text-align: left;
  }
}
.footerContactItems {
  margin-top: 10px;
  color: ${theme.colors.NORMAL_LINK};
  text-align: center;
  font-size: 16px;
  padding: 0 2% 0 6%;
  position: relative;
  max-width: auto;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  @media (max-width: ${theme.breakpoints.values.smd}px) {
    font-size: 13px;
  }
  @media (max-width: ${theme.breakpoints.values.md}px) {
    padding: 0;
  }
  @media (min-width: ${theme.breakpoints.values.lg + 1}px) {
    flex-direction: row;
    margin-top: 10px;
  }
}
@media (max-width: ${theme.breakpoints.values.lg}px) {
  width: 100% !important;
}

.footerContactItems .addressImg {
  background-image: url(${getImagePath('icons/address.svg')});
  height: 40px;
  left: 0;
  width: 40px;
  flex: 0 0 40px;
}

.footerContactItems .phoneImg {
  background: url(${getImagePath('icons/phone.svg')});
  height: 40px;
  width: 40px;
  left: 14rem;
  flex: 0 0 40px;
}

.footerContactItems .emailImg {
  background: url(${getImagePath('icons/mail.svg')});
  height: 40px;
  width: 40px;
  left: 25.5rem;
  flex: 0 0 40px;
}
.footerContactItems .footerContactsEmail a {
  text-decoration: none;
  color: ${theme.colors.NORMAL_LINK};
}

.footerContactItems span {
  color: ${theme.colors.WHITE};
  font-size: 18px;
  padding-right: 0.5rem;
}
`}
`;

export const FooterCopy = styled.div`
${({ theme }) => `

  font-size: 18px;
  width: 100%;
  text-align: center;
  vertical-align: top;
  color: ${theme.colors.NORMAL_LINK};
  margin-right: 30px;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  justify-content: center;
  a {
    text-decoration: none;
    color: ${theme.colors.NORMAL_LINK};
  }
  #privacyCheck {
    color: ${theme.colors.PRIMARY_CONTRAST} !important;
  }
  @media (min-width: ${theme.breakpoints.values.lg + 1}px) {
    width: 32%;
    text-align: right;
    margin-right: 30px;
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    justify-content: flex-end;
  }
  @media (min-width: ${theme.breakpoints.values.xs}px) and (max-width: ${
    theme.breakpoints.values.md
  }px) {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    justify-content: center;
  }
  .patentText::after {
    content: '|';
    padding-left: 4px;
    padding-right: 4px;
  }
  `}
`;
