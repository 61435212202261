import React, { lazy, useState } from 'react';
import AddEditParts from './AddEditParts';
const layout = window?.$environment?.CURRENT_LAYOUT;

const Modal = require(`@/components/UI/Modal/${layout}`).default;

const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);

const TopActions = ({
  Styled,
  i18n,
  saveParts,
  callback,
  viewOnly
}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [withCloseIcon, setwithCloseIcon] = useState(true);
    const [backgroundModal, setbackgroundModal] = useState(false);
    const closeModal = (event) => {
        setModalOpen(false);
      };
      const [ModalObject, setModalObject] = useState({
        header: '',
        content: () => {}
      });
        const clickedModal = (e) => {
    setModalOpen(true);
  };
  return (
    <>
      {!viewOnly &&
      (<Styled.Wrapper>
        <FormStyle.default.ButtonsWrapper>
          <Button
            className="buttonStyle"
            label={i18n.t('parts.addPart')}
            onClick={() => {
              setwithCloseIcon(true);
              setbackgroundModal(false);
              setModalObject({
                header: '',
                content: () => {
                  return (
                    <AddEditParts
                      closeModal={closeModal}
                      callback={callback}
                      saveParts={saveParts}
                      Styled={Styled}
                    />
                  );
                }
              });
              clickedModal();
            }}
          />
        </FormStyle.default.ButtonsWrapper>
        <Modal
          open={modalOpen}
          setOpen={setModalOpen}
          data={ModalObject}
          onCloseModal={closeModal}
          background="black"
          withCloseIcon={withCloseIcon}
          backgroundModal={backgroundModal}
          deletePopup
        />
        </Styled.Wrapper>)
        }
      </>
  );
};
export default TopActions;
