import React, { lazy } from 'react';
import { Field } from 'formik';


const layout = window.$environment?.CURRENT_LAYOUT;
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`).default;
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const SubHeader = lazy(() => import(`@/components/SubHeader/${layout}`));
const Input = lazy(() => import(`@/components/UI/Input/${layout}`));

const RenderComponent = (props) => {
    const { values,
        handleEdit,
        errors,
        isNotValid,
        validateForm,
        customProps: {
            i18n,
            Styled
        },
        submitAction,
        cancelAction } = props;

    const showError = (field, validationErrors, isNotValid, apiError) => {
        if (validationErrors[field] && !isNotValid) {
            return <Message type="error" message={errors[field]} />;
        }
        if (apiError?.[field]) {
            return <Message type="error" message={apiError[field]} />;
        }
    };
    return <>
        <Styled.Wrapper>
            {/* <h2 className='scoreHeading'>{i18n.t('Behaviors / Severity Scores')}</h2> */}
            <div className="section-wrapper">
                <div className='scoreSection' >
                    <div className='sections'>
                        <SubHeader
                            text={i18n.t('Behavior Scores')}
                            className="subheaderSection companyTitle"
                            variant="h2"
                        />
                        <div className="companyForm firstRow">
                            {behavioursMap.map(ele => {
                                return <FormStyle.FieldsWrapper>
                                    <Label htmlFor={ele.label.toLowerCase()} variant="body1">
                                        {i18n.t(ele.label)}
                                    </Label>
                                    <FormStyle.TextWrapper>
                                        <Field
                                            name={ele.label.toLowerCase()}
                                            id={ele.label.toLowerCase()}
                                            handleOnChange={(event) =>
                                                handleEdit(event, { field: ele.label.toLowerCase() })
                                            }
                                            as={Input}
                                            placeholder={i18n.t(ele.placeHolder)}
                                            value={values[ele.label.toLowerCase()]}
                                            type="text"
                                        />
                                        {showError(
                                            ele.label.toLowerCase(),
                                            errors,
                                            isNotValid
                                        )}
                                    </FormStyle.TextWrapper>
                                </FormStyle.FieldsWrapper>
                            })}
                        </div>

                    </div>
                    <div className='sections'>

                        <SubHeader
                            text={i18n.t('Severity Level Scores')}
                            className="subheaderSection companyTitle"
                            variant="h2"
                        />
                        <div className="companyForm firstRow">
                            {severityMap.map(ele => {
                                return <FormStyle.FieldsWrapper>
                                    <Label htmlFor={ele.valueMap.toLowerCase()} variant="body1">
                                        {i18n.t(ele.label)}
                                    </Label>
                                    <FormStyle.TextWrapper>
                                        <Field
                                            name={ele.valueMap.toLowerCase()}
                                            id={ele.valueMap.toLowerCase()}
                                            handleOnChange={(event) =>
                                                handleEdit(event, { field: ele.valueMap.toLowerCase() })
                                            }
                                            as={Input}
                                            placeholder={i18n.t(ele.placeHolder)}
                                            value={values[ele.valueMap.toLowerCase()]}
                                            type="text"
                                        />
                                        {showError(
                                            ele.valueMap.toLowerCase(),
                                            errors,
                                            isNotValid
                                        )}
                                    </FormStyle.TextWrapper>
                                </FormStyle.FieldsWrapper>
                            })}

                        </div>
                    </div>
                </div>

                <div className="buttonSection">
                    <FormStyle.ButtonsWrapper className="submitSection">
                        <Button
                            className="buttonStyle"
                            type="reset"
                            label={i18n.t('common.cancel')}
                            onClick={(e) => {
                                cancelAction();
                            }}
                        />
                        <Button
                            id="companyInfoSubmit"
                            type="submit"
                            label={i18n.t('Save')}
                            onClick={() => {
                                const validateResponse = validateForm();
                                validateResponse.then((data) => submitAction(data));
                            }}
                        />
                    </FormStyle.ButtonsWrapper>
                </div>
            </div>
        </Styled.Wrapper>
    </>
}

const behavioursMap = [
    {
        label: 'Radius',
        placeHolder: 'Radius Score'
    },
    {
        label: 'Acceleration',
        placeHolder: 'Acceleration Score'
    },
    {
        label: 'Braking',
        placeHolder: 'Braking Score'
    },
    {
        label: 'Turning',
        placeHolder: 'Turning Score'
    },
    {
        label: 'Speeding',
        placeHolder: 'Speeding Score'
    },
    {
        label: 'Accident',
        placeHolder: 'Accident Score'
    },
    {
        label: 'None',
        placeHolder: 'None Score'
    }
]

const severityMap = [
    {
        label: 'Less than 33%',
        valueMap: 'SEVERITY_LESS_THAN_33_PERCENTAGE',
        placeHolder: '< 33%'
    },
    {
        label: '33% to 67%',
        valueMap: 'SEVERITY_BETWEEN_33_TO_67_PERCENTAGE',
        placeHolder: '33% to 67%'
    },
    {
        label: 'Greater than 67%',
        valueMap: 'SEVERITY_GREATER_THAN_67_PERCENTAGE',
        placeHolder: '> 67%'
    },
    {
        label: 'Speeding',
        valueMap: 'SEVERITY_SPEEDING',
        placeHolder: 'Speeding'
    },
    {
        label: 'Accident',
        valueMap: 'SEVERITY_ACCIDENT',
        placeHolder: 'Speeding'
    },
    {
        label: 'Hard',
        valueMap: 'SEVERITY_HARD',
        placeHolder: 'Hard'
    },    
    {
        label: 'Harsh',
        valueMap: 'SEVERITY_HARSH',
        placeHolder: 'Harsh'
    },
    {
        label: 'Severe',
        valueMap: 'SEVERITY_SEVERE',
        placeHolder: 'Severe'
    },
    {
        label: 'None',
        valueMap: 'SEVERITY_NONE',
        placeHolder: 'None'
    }
]

export default RenderComponent;