import React, { lazy } from 'react';
import PropTypes from 'prop-types';

import { Field } from 'formik';
import CircularProgress from '@material-ui/core/CircularProgress';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Input = lazy(() => import(`@/components/UI/Input/${layout}`));
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`).default;
const RenderComponent = ({
  values,
  handleEdit,
  errors,
  isNotValid,
  validateForm,
  customProps: { i18n, hasApiStatus },
  submitAction
}) => {
  return (
    <>
      {hasApiStatus?.errorMessage && (
        <Message type="error" className="errorMessage" message={i18n.t(hasApiStatus.errorMessage)} />
      )}
      <FormStyle.FieldsWrapper width="100%">
        <Label mode="light" className="labelSize">
          {i18n.t('Email')}
        </Label>
        <FormStyle.TextWrapper>
          <Field
            name="email"
            handleOnChange={(event) =>
              handleEdit(event, { field: 'email', type: 'email' })
            }
            as={Input}
            placeholder={i18n.t('mail@gmail.com')}
            value={values.email}
            type="email"
            mode="light"
          />
          {errors['email'] && !isNotValid && (
            <Message type="error" message={errors['email']} />
          )}
        </FormStyle.TextWrapper>
      </FormStyle.FieldsWrapper>

      <FormStyle.ButtonsWrapper>
        <Button
          type="submit"
          label={
            hasApiStatus?.isLoading ? (
              <CircularProgress color="inherit" />
            ) : (
              i18n.t('common.resetPassword')
            )
          }
          onClick={() => {
            const validateResponse = validateForm();
            validateResponse.then((data) => submitAction(data));
          }}
        />
      </FormStyle.ButtonsWrapper>
    </>
  );
};

export default RenderComponent;

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  customProps: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};
