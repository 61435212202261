import {
  monthlyDetailApi,
  fetchTableDataApi,
  fetchChartDataApi,
  fuelEconomyApi
} from '../../../config/apis';

const initialState = {
  hardDeceleration: {
    table: [],
    chart: []
  },
  hardAcceleration: {
    table: [],
    chart: []
  },
  speeding: {
    table: [],
    chart: []
  },
  driveHrsExceeding: {
    table: [],
    chart: []
  },
  seatBeltWarning: {
    table: [],
    chart: []
  },
  hardTurn: {
    table: [],
    chart: []
  },
  fuelEconomy: {
    table: [],
    chart: []
  },
  chartSuccess: false
};

export const driverbehavior = {
  state: { ...initialState },
  reducers: {
    listSuccess(state, payload) {
      return {
        ...state,
        hardDeceleration: {
          ...state.hardDeceleration,
          chart: payload.hardDecceleration
        },
        hardAcceleration: {
          ...state.hardAcceleration,
          chart: payload.hardAcceleration
        },
        speeding: { ...state.speeding, chart: payload.speeding },
        driveHrsExceeding: {
          ...state.driveHrsExceeding,
          chart: payload.driveHrsExceeding
        },
        seatBeltWarning: {
          ...state.seatBeltWarning,
          chart: payload.seatBeltWarning
        },
        hardTurn: { ...state.hardTurn, chart: payload.hardTurn },
        chartSuccess: true
      };
    },
    listTableSuccess(state, payload, type) {
      return {
        ...state,
        [type]: { ...state[type], table: payload }
      };
    },
    listChartSuccess(state, payload, type) {
      if (type === 'hardDecceleration')
        return {
          ...state,
          hardDeceleration: {
            ...state['hardDeceleration'],
            chart: payload[type]
          },
          chartSuccess: true
        };
      else
        return {
          ...state,
          [type]: { ...state[type], chart: payload[type] },
          chartSuccess: true
        };
    },
    listFailed(state) {
      return { ...state, chartSuccess: false };
    },
    listFuelEconomySuccess(state, payload) {
      return {
        ...state,
        fuelEconomy: { ...state.fuelEconomy, chart: payload.fuelEconomy },
        chartSuccess: true
      };
    }
  },
  effects: (dispatch) => ({
    async fetchDriverBehaviorDetails(payload) {
      this.listFailed();
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: monthlyDetailApi
          },
          id: payload.apiId
        });
        response && this.listSuccess(response);
      } catch (error) {
        this.listFailed();
      }
    },

    async fetchTableData(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...fetchTableDataApi,
              url: fetchTableDataApi.url
                .replace('{id}', payload.data.id)
                .replace('{chart}', payload.type)
                .replace('{startDate}', payload.data.startDate)
                .replace('{endDate}', payload.data.endDate)
                .replace('{vehicleIdValue}', payload.data.vehicleId ? payload.data.vehicleId : '')
            }
          },
          id: payload.apiId
        });
        let type =
          payload.type === 'hardDecceleration'
            ? 'hardDeceleration'
            : payload.type;
        response && this.listTableSuccess(response, type);
      } catch (error) {
        this.listFailed();
      }
    },
    async fetchChartData(payload) {
      this.listFailed();
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...fetchChartDataApi,
              url:
                payload.data.startDate === '' && payload.data.endDate === ''
                  ? fetchChartDataApi.url
                      .replace('{id}', payload.data.id)
                      .replace('{chart}', payload.type)
                      .replace('{startDate}', payload.data.startDate)
                      .replace('{endDate}', payload.data.endDate)
                      .replace('dateRange', '')
                      .replace('{vehicleIdValue}', payload.data.vehicleId ? payload.data.vehicleId : '')
                  : fetchChartDataApi.url
                      .replace('{id}', payload.data.id)
                      .replace('{chart}', payload.type)
                      .replace('{startDate}', payload.data.startDate)
                      .replace('{endDate}', payload.data.endDate)
                      .replace('{vehicleIdValue}', payload.data.vehicleId ? payload.data.vehicleId : '')
            }
          },
          id: payload.apiId
        });
        response && this.listChartSuccess(response, payload?.type);
      } catch (error) {
        this.listFailed();
      }
    },
    async fetchFuelEconomy(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: fuelEconomyApi
          },
          id: payload.apiId
        });
        response && this.listFuelEconomySuccess(response);
      } catch (error) {
        this.listFailed();
      }
    }
  })
};
