import React from 'react';
import PropTypes from 'prop-types';

import DotAudit from './Form';
import { hasEnabledFeature } from '@/utils/utils';

const Component = ({
  apiStatus,
  sendFmcsa,
  history,
  callback,
  drivers,
  Styled
}) => {
  const isCanadaEnabled = hasEnabledFeature('canadaComplianceEnabled')
  return (
    <Styled.Wrapper>
      {isCanadaEnabled && <h3 className="auditSectionTitle">For USA</h3>}
      <DotAudit
        apiStatus={apiStatus}
        sendFmcsa={sendFmcsa}
        history={history}
        callback={callback}
        drivers={drivers}
        Styled={Styled}
      />
      {isCanadaEnabled && <>
        <h3 style={{ marginTop: '80px' }} className="auditSectionTitle">For Canada</h3>
        <DotAudit
          apiStatus={apiStatus}
          sendFmcsa={(data) => {
            sendFmcsa({
              ...data, data: {
                ...data.data,
                isCanada: true
              }
            })
          }}
          history={history}
          callback={callback}
          drivers={drivers}
          Styled={Styled}
          canada
        />
      </>}
    </Styled.Wrapper>
  );
};

export default Component;

Component.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  sendFmcsa: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired
};
