import styled from 'styled-components';
import Parent from '../Base/style';
const SelectWrapper = styled(Parent.SelectWrapper)`
${({ theme }) => `
.select__control {
    border: 0;
    background: #fff;
    border-bottom: 1px solid #d3d7db;
    outline: none;
    font-size: 15px;
    min-height: 36px !important;
    &:hover {
        border: 0;
        border-bottom: 1px solid #d3d7db;
    }
    .select__indicator svg {
        fill: #767676;
    }
}
.select__placeholder {
    font-size: 15px;
}
.react-select__option :focus,
.react-select__menu-list:focus {
    background-color: #75828a !important;
  }
.select__menu {
    background: #fff;
    border: 1px solid #efefef;
    border-radius: 0px;
    z-index: 1000;
    color: #000 !important;
    font-size: 12px !important;
}
fill: #767676;
.select__option--is-focused,
.select__option:hover {
    background: #75828a !important;
    color: #fff;
}
.select__value-container{
    padding-left: 0;
}
.select__single-value{
    font-family: ${(theme.palette || {}).fontFamily} !important;
}
`}
`;
const Styled = { SelectWrapper };
export default Styled;
