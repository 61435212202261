import React, { useEffect } from 'react';

const Component = ({ getCompanies, companies, history, i18n }) => {
  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  // const handleAddNewCompany = () => {
  //   history.push('/admin/companies/new');
  // };
  // const handleSort = (column, order) => {
  //   getCompanies(column, 'sort', order);
  // };
  // const handlefilter = () => {};
  // const handlePagination = (page) => {
  //   getCompanies(page, 'pagination');
  // };

  // const renderTable = () =>
  // <Table
  //   title=""
  //   columns={[
  //     {
  //       id: 'companyName',
  //       numeric: false,
  //       label: 'Comapany',
  //       needSorting: true,
  //       link: history.location.pathname,
  //       align: 'center',
  //       sortOrder: 'asc'
  //     },
  //     {
  //       id: 'dot',
  //       numeric: true,
  //       disablePadding: false,
  //       label: 'Dot #',
  //       needSorting: true,
  //       sortOrder: 'desc'
  //     },
  //     {
  //       id: 'activeTrucks',
  //       numeric: true,
  //       disablePadding: false,
  //       label: 'Active Trucks',
  //       needSorting: false
  //     },
  //     {
  //       id: 'contractLength',
  //       numeric: true,
  //       disablePadding: false,
  //       label: 'Contract Length',
  //       needSorting: true,
  //       sortOrder: 'des'
  //     },
  //     {
  //       id: 'contractExpiration',
  //       numeric: false,
  //       type: 'date',
  //       // format: 'yyyy/mm/dd',
  //       disablePadding: false,
  //       label: 'Contract Expiration',
  //       needSorting: true
  //     },
  //     {
  //       id: 'email',
  //       numeric: false,
  //       disablePadding: false,
  //       label: 'Email',
  //       needSorting: false
  //     },
  //     {
  //       id: 'phone',
  //       numeric: false,
  //       disablePadding: true,
  //       label: 'Phone',
  //       needSorting: true
  //     },
  //     {
  //       id: 'registrationDate',
  //       numeric: false,
  //       disablePadding: false,
  //       label: 'Registration Date',
  //       needSorting: true
  //     },
  //     {
  //       id: 'status',
  //       numeric: false,
  //       disablePadding: false,
  //       label: 'Status',
  //       needSorting: true
  //     },
  //     {
  //       id: 'producerCode',
  //       numeric: true,
  //       disablePadding: false,
  //       label: 'Contract Length',
  //       needSorting: true
  //     },
  //     {
  //       id: 'producerName',
  //       numeric: false,
  //       disablePadding: false,
  //       label: 'Producer Name',
  //       needSorting: true
  //     },
  //     {
  //       id: 'actions'
  //     }
  //   ]}
  //   data={companies.data}
  //   actions={[
  //     {
  //       icon: (row, index) => <Link>Reset password</Link>
  //     }
  //   ]}
  //   isPaginationNeeded={true}
  //   handleSort={handleSort}
  //   handlePagination={handlePagination}
  //   handlefilter={handlefilter}
  // />
  // null;
  return (
    <>
      {/* <Button label={i18n.t('Add New Company')} onClick={handleAddNewCompany} />
      <Wrapper>{renderTable()}</Wrapper> */}
    </>
  );
};

export default Component;

Component.defaultProps = {};
