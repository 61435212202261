import React, { useEffect, lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RenderComponent from '@/components/modules/DynamicRenderPage';
import { loadPageConfig } from '@/utils/utils';
import { ROLES } from '../../../config/constants/keys';
import { hasRestrictedRole } from '../../../utils/utils';

const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.alertViewMore[0].name}`));
const Content = lazy(() => import(`@/${config.alertViewMore[1].name}`));
const TopActions =
  config.alertViewMore[0].children &&
  lazy(() => import(`@/${config.alertViewMore[0].children.name}`));

const Alert = (parentProps) => {
  const {
    fetchAlertList,
    list,
    history,
    i18n,
    theme,
    getDocumentToken,
    filterSuccess
  } = parentProps;
  const [pagination, setPagination] = useState(false);

  const commonParentProps = { history, i18n, theme };
  const viewOnly = hasRestrictedRole([ROLES.VIEW_ONLY_FM])

  useEffect(() => {
    !list.length && fetchAlertList({ apiId: 'alertList' });
    getDocumentToken({ apiId: 'getDocToken' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const columns = [
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: i18n.t('common.description'),
      needSorting: true
    },
    {
      id: 'driver',
      numeric: false,
      disablePadding: true,
      label: i18n.t('common.driverName'),
      needSorting: true
    },
    {
      id: 'date',
      numeric: false,
      disablePadding: false,
      label: i18n.t('common.date'),
      needSorting: true
    },
    {
      id: 'vehicle',
      numeric: false,
      disablePadding: false,
      label: i18n.t('common.vehicle'),
      needSorting: true
    },

    {
      id: 'phone',
      numeric: true,
      disablePadding: false,
      label: i18n.t('common.driverPhone'),
      needSorting: true
    },

    ...(!viewOnly?[{
      id: 'actions',
      label: 'common.delete'
    }]: [])
  ];
  const searchConfig = {
    filterSuccess,
    setPagination,
    searchPlaceholder: 'Search Compliance Notifications',
    data: list.list,
    query: 'description',
    fromData: true
  };
  return (
    <>
      <RenderComponent
        {...config.alertViewMore[0]}
        parentProps={{ ...parentProps, columns, pagination, setPagination,noAction: viewOnly }}
        commonParentProps={commonParentProps}
        component={Title}
        childComponent={TopActions}
        searchConfig={
          window.$environment.CURRENT_LAYOUT === 'SpireonNew'
          ? searchConfig
          : null
          }
      />
      <RenderComponent
        {...config.alertViewMore[1]}
        parentProps={{ ...parentProps, columns, pagination, setPagination,noAction: viewOnly }}
        commonParentProps={commonParentProps}
        component={Content}
      />
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    list: state.alertList,
    logBook: state.logBook
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchAlertList: (data) => dispatch.alertList.fetchData(data),
    filterSuccess: (data) => dispatch.alertList.filterSuccess(data),
    exportAllData: (data) => dispatch.alertList.handleExportAllAlert(data),
    deleteData: (data) => dispatch.alertList.handleDeleteAlert(data),
    getDocumentToken: (data) => dispatch.logBook.documentToken(data)
  };
};

export default connect(mapState, mapDispatch)(Alert);
Alert.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  accidents: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,

  handleLogout: PropTypes.func.isRequired,
  getFilters: PropTypes.func.isRequired,
  fetchAlertList: PropTypes.func.isRequired,
  exportAllData: PropTypes.func.isRequired,
  filterSuccess: PropTypes.func.isRequired,
  deleteData: PropTypes.func.isRequired
};
