import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
  ${({ theme }) => `
  display: flex;
  flex-direction: column;
  .filter-btn {
    color: ${theme.colors.LINK_COLOR} !important;
    border: 2px solid ${theme.colors.LINK_COLOR} !important;
  }
  .status-wrapper {
    display: flex;
    justify-content: flex-start;
    padding-top: 1%;
    .openFilter {
      padding-right: 2%;
    }
    .buttonStyle {
      font-size: 18px;
      background: ${theme.colors.BACKGROUND_MENU};
      border: 1px solid #2b2b2d;
      color: ${theme.colors.NEW_THEME ? theme.colors.WHITE :theme.colors.PRIMARY_CONTRAST};
      &:hover {
        background: #1b1b1b;
        ${theme.colors.NEW_THEME ? `color: ${theme.colors.SELECTED_TAB_COLOR};` : ''}
      }
    }
    .quickFilters {
      display: none;
    }
  }
  `}
`;
const TopWrapper = styled(Box)`
  ${({ theme }) => `

  display: flex;
  @media (max-width: ${theme.breakpoints.values.smd}px) {
    flex-direction: column;
  }
  .status-btn-wrapper {
    flex: 0 0 60%;
    display: flex;
    justify-content: flex-end;
    .addWorkorder {
        margin-right: 1%;
    }
    .MuiLink-underlineHover:hover {
      text-decoration: none !important;
    }
    .MuiButton-root {
      margin-top: 0;
      font-size: 20px;
    }
  }
  `}
`;

const SearchWrapper = styled.div`
  ${({ theme }) => `

  flex: 0 0 40%;
  @media (max-width: ${theme.breakpoints.values.mlg}px) {
    flex: 0 0 38%;
  }
  
  #clearSearch {
    display: none;
  }
  `}
`;

const TableWrapper = styled(Box)`
  padding-top: 2.5%;
  .MuiPaper-root.MuiPaper-elevation1.MuiPaper-rounded{
    overflow-x: hidden;
  }
  .MuiTable-root .MuiTableHead-root .MuiTableRow-root .MuiTableCell-head {
    padding: 10px 8px 10px 7px !important;
  }
`;

const LabelWrap = styled(Box)`
  p {
    font-size: 21px;
    margin-bottom: 20px;
  }
`;
const Styled = {
  Wrapper,
  SearchWrapper,
  TopWrapper,
  TableWrapper,
  LabelWrap
};
export default Styled;
