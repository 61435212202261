import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
// import Styled from './style';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Input = lazy(() => import(`@/components/UI/Input/${layout}`));
const Upload = lazy(() => import(`@/components/FileUpload/${layout}`));
const SubHeader = lazy(() => import(`@/components/SubHeader/${layout}`));
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Select = lazy(() => import(`@/components/UI/Select/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const RenderComponent = ({
  values,
  handleEdit,
  errors,
  isNotValid,
  validateForm,
  customProps,
  submitAction
}) => {
  const { i18n } = useTranslation();
  return (
    <customProps.Styled.PopupUpload>
      <SubHeader text={i18n.t('Upload')} variant="h1" className="uploadHeading" />
      <customProps.Styled.UploadWrapper>
        <div className="uploadFields">
          <FormStyle.default.FieldsWrapper width="100%" className="vertical">
            <Label variant="body1">{i18n.t('users.selectFileToUpload')}</Label>
            <FormStyle.default.TextWrapper>
              <Field
                name="upload"
                onUpload={(event) => {
                  handleEdit(event, { field: 'upload', type: 'file' });
                }}
                as={Upload}
                allowMultiple={false}
                totalFiles={1}
                placeholderText=""
                value={values.upload}
                type="file"
                normal
                acceptedFileTypes={[
                  '.docx',
                  '.DOCX',
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                ]}
                labelFileTypeNotAllowed={'Only Docx file is allowed.'}
                allowFileTypeValidation
              />

              {errors['upload'] && !isNotValid && (
                <Message type="error" message={errors['upload']} />
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
            <>
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1">{i18n.t('common.name')}</Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="name"
                    handleOnChange={(event) =>
                      handleEdit(event, { field: 'name' })
                    }
                    as={Input}
                    type="text"
                  />

              {errors['name'] && !isNotValid && (
                <Message type="error" message={errors['name']} />
              )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>

              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1">{i18n.t('common.language')}</Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="Langauge"
                    placeholder={i18n.t('selectWithDot')}
                    onChange={(event) =>
                      handleEdit(event, { field: 'language' })
                    }
                    as={Select}
                    value={values.language}
                    suggestions={customProps.languages}
                  />
                  {errors['language'] && !isNotValid && (
                    <Message type="error" message={i18n.t('common.fieldRequiredMessage')} />
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
            </>
          <FormStyle.default.ButtonsWrapper>
            <Button
              type="submit"
              className="buttonStyle"
              label={i18n.t('common.save')}
              onClick={(e, d) => {
                const validateResponse = validateForm();
                validateResponse.then((data) => {
                  submitAction(data);
                });
              }}
              id="docUploadButton"
            />
          </FormStyle.default.ButtonsWrapper>
        </div>
      </customProps.Styled.UploadWrapper>
    </customProps.Styled.PopupUpload>
  );
};

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  customProps: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};

export default RenderComponent;
