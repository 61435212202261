import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Styled from './style';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import { Helmet } from 'react-helmet';

import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import { InlineShareButtons } from 'sharethis-reactjs';
import WelcomePage from './welcome';
import HardwareInstallation from './hardwareInstallation';
import ApplicationDownload from './applicationDownload';
import DownloadManuals from './downloadManuals';
import AdditionalDetails from './additionalDetails';
import VehicleInstallation from './vehicleInstallation';
import Pairing from './pairing';
import { useTranslation } from 'react-i18next';
import { imageUrls, pageUrls } from '../../../../config/constants/keys';
import { getImagePath, getAuthDetails } from '../../../../utils/utils';
import Modal from '../../../../components/UI/Modal/Clutch';
import { COOKIE_AUTH_DETAILS } from '../../../../config/constants/keys';
import { getCookie, deleteCookie } from '../../../../services/cookie';
import { removeLocalStorage } from '@/services/storage';
const Welcome = ({
  history,
  login,
  truckInfo,
  updateAllTruck,
  isValidVin,
  apiStatus,
  handleLogout,
  callback,
  pageTitle
}) => {
  const closeModalDelete = (event) => {
    setOpenCallback(false);
  };
  const clickedModalDelete = (e) => {
    setOpenCallback(true);
  };

  const [ModalListObject2, setModalListObject2] = useState({
    header: 'Share Video',
    content: () => {}
  });
  const [openCallback, setOpenCallback] = React.useState(false);
  const [username, setUserName] = useState('');
  const [auth, setAuth] = useState(getCookie(COOKIE_AUTH_DETAILS));
  useEffect(() => {
    const token = getCookie(COOKIE_AUTH_DETAILS);
    setAuth(token)
    setUserName(token.email);
  }, [login]);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const steps = getSteps();

  useEffect(() => {
    if (activeStep === 0) {
      document.getElementById('backButtonStep').style.display = 'none';
    } else {
      document.getElementById('backButtonStep').style.display = 'block';
    }
  }, [activeStep]);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      display: 'flex'
    },
    button: {
      marginRight: theme.spacing(1)
    },
    instructions: {
      // marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      fontSize: 30
    }
  }));

  const classes = useStyles();
  function getSteps() {
    if(auth && auth?.enterpriseFeatureDto?.dvirOnlyEnabled){
      return [
        'Welcome',
        'Application Download',
        'Download Manuals',
        'Additional Details'
      ];  
    }
    return [
      'Welcome',
      'Hardware Installation',
      'Vehicle Installation',
      'Device Pairing',
      'Application Download',
      'Download Manuals',
      'Additional Details'
    ];
  }
  function getStepContent(step, history) {
    if(auth && auth?.enterpriseFeatureDto?.dvirOnlyEnabled){
    switch (step) {
      case 0:
        return <WelcomePage loginInfo={login} />;
      case 1:
        return <ApplicationDownload />;
      case 2:
        return <DownloadManuals />;
      case 3:
        return (
          <AdditionalDetails
            truckInfo={truckInfo}
            updateAllTruck={updateAllTruck}
            isValidVin={isValidVin}
            apiStatus={apiStatus}
            history={history}
          />
        );
      default:
        return 'Unknown step';
    }
  }
  else{
    switch (step) {
      case 0:
        return <WelcomePage loginInfo={login} />;
      case 1:
        return <HardwareInstallation />;
      case 2:
        return <VehicleInstallation />;
      case 3:
        return <Pairing />;
      case 4:
        return <ApplicationDownload />;
      case 5:
        return <DownloadManuals />;
      case 6:
        return (
          <AdditionalDetails
            truckInfo={truckInfo}
            updateAllTruck={updateAllTruck}
            isValidVin={isValidVin}
            apiStatus={apiStatus}
            history={history}
          />
        );
      default:
        return 'Unknown step';
    }
  }
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleNext = () => {
    let newSkipped = skipped;
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  const { i18n } = useTranslation();
  const handleOnLogout = () => {
    const auth = getAuthDetails(false);
    if (auth) {
      handleLogout({
        apiId: 'logout',
        callback: (type, message) => {
          if (type === 'error') {
            callback(type, message);
          } else {
            // history.push(pageUrls.login);
            window.location.href = pageUrls.login; 
          }
        }
      });
    }
  };
  const baseUrl = `${process.env.CDN_URL || ''}/${
    window.$environment?.SERVICE_PROVIDER
  }`;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>

        <link rel="manifest" href={`${baseUrl}/manifest.json`} />
        <link rel="icon" type="image/x-icon" href={`${baseUrl}/favicon.ico`} />
      </Helmet>
      <Styled.Wrapper>
        <div className={classes.root}>
          <div className="leftMenuWrapper">
            <Styled.LogoWrapper></Styled.LogoWrapper>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <div className="logout">
              <img src={getImagePath(imageUrls.logedIcon)} alt="logedIcon" />
              <div class="content">
                <span className="nameSection" title={username}>
                  {' '}
                  {username}
                </span>
                <span
                  className="labelLogout"
                  onClick={() => {
                    handleOnLogout();
                  }}
                >
                  Logout
                </span>
              </div>
            </div>
          </div>
          <div className="contentWrapper">
            <div className="contentWrap">
              <div className={classes.instructions}>
                {getStepContent(activeStep, history)}
              </div>
              <div
                className={
                  activeStep === 0
                    ? 'buttonSection topbutton'
                    : activeStep === 1
                    ? 'buttonSection hardareWrapper'
                    : activeStep === 2
                    ? 'buttonSection appDownload'
                    : 'buttonSection'
                }
              >
                <Button onClick={() => handleBack()} id="backButtonStep">
                  Previous
                </Button>
                {activeStep === 1 ? (
                  <div className="hardwareButton">
                    <Button variant="contained" className="referenceGuide">
                      <a
                        href="https://s1.q4cdn.com/806093406/files/doc_downloads/test.pdf"
                        download
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {' '}
                        Quick Reference Guide
                      </a>
                    </Button>
                    <Button
                      variant="contained"
                      className="shareVideo"
                      onClick={() => {
                        setModalListObject2({
                          header: 'Share Video',
                          content: () => (
                            <div className="connectionTypeWrapper">
                              <InlineShareButtons
                                config={{
                                  id: 'shareVideoWrapper',
                                  alignment: 'center',
                                  color: 'social',
                                  enabled: true,
                                  font_size: 16,
                                  labels: null,
                                  language: 'en',
                                  networks: [
                                    'linkedin',
                                    'facebook',
                                    'twitter',
                                    'email'
                                  ],
                                  padding: 12,
                                  radius: 4,
                                  show_total: false,
                                  size: 40,
                                  url:
                                    'https://www.youtube.com/embed/knr-UrKl6Ks',
                                  image: 'https://bit.ly/2CMhCMC',
                                  description: 'custom text',
                                  title: 'custom title',
                                  message: 'custom email text',
                                  subject: 'custom email subject',
                                  username: 'custom twitter handle'
                                }}
                              />
                            </div>
                          )
                        });
                        clickedModalDelete();
                      }}
                    >
                      Share Video
                    </Button>
                  </div>
                ) : activeStep === 2 || activeStep === 3 ? (
                  <div className="hardwareButton">
                    <Button
                      variant="contained"
                      className="shareVideo"
                      onClick={() => {
                        setModalListObject2({
                          header: 'Share Video',
                          content: () => (
                            <div className="connectionTypeWrapper">
                              <InlineShareButtons
                                config={{
                                  id: 'shareVideoWrapper',
                                  alignment: 'center',
                                  color: 'social',
                                  enabled: true,
                                  font_size: 16,
                                  labels: null,
                                  language: 'en',
                                  networks: [
                                    'linkedin',
                                    'facebook',
                                    'twitter',
                                    'email'
                                  ],
                                  padding: 12,
                                  radius: 4,
                                  show_total: false,
                                  size: 40,
                                  url:
                                    activeStep === 2
                                      ? 'https://www.youtube.com/embed/5fzN8v26v28'
                                      : 'https://www.youtube.com/embed/FOw1Ggc393s',
                                  image: 'https://bit.ly/2CMhCMC',
                                  description: 'custom text',
                                  title: 'custom title',
                                  message: 'custom email text',
                                  subject: 'custom email subject',
                                  username: 'custom twitter handle'
                                }}
                              />
                            </div>
                          )
                        });
                        clickedModalDelete();
                      }}
                    >
                      Share Video
                    </Button>
                  </div>
                ) : (
                  ''
                )}
                {activeStep !== 6 ? (
                  <Button
                    variant="contained"
                    onClick={() => handleNext()}
                    id="nextButtonStep"
                  >
                    {activeStep === 0 ? 'continue onboarding' : 'Next'}
                  </Button>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={openCallback}
          setOpen={setOpenCallback}
          data={ModalListObject2}
          connectionType
        />
      </Styled.Wrapper>
    </>
  );
};

export default Welcome;

Welcome.propTypes = {
  history: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired
};
