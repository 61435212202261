export const colors = {
  PRIMARY_MAIN: '#1b1b1b',
  HEADER_COUNT_TEXT: '#c0bbb7',
  PRIMARY_CONTRAST: '#c0bbb7',
  PRIMARY_BORDER: '#2b2b2b',
  GREY: '#999',
  DAILY_LOG_GRAPH_BORDER: '#919191',
  NORMAL_LINK: '#b0b0b0',
  MODAL_TEXT: '#797979',
  MODAL_HEADING: '#575757',
  LIST_TEXT: '#6d6d6d',
  LIGHT_BORDER: '#2d2b2b',
  SECONDARY_MAIN: '#f58220',
  WHITE: '#fff',
  INPUT_BACKGROUND: '#efefef',
  RED: '#ff0000',
  BLACK: '#000000',
  BACKGROUND: '#1b1b1b',
  BLACK_THREE: '#373737',
  RED_TWO: '#d34343',
  GREEN: '#32cd32',
  GREEN_TWO: '#97AE46',
  GREEN_THREE: '#008000',
  GREY_ELEVEN: '#777070',
  GREY_TWELVE: '#c3c3c3',
  BLACK_FOUR: '#333333',
  GRAPH_BLACK: '#212121',
  CHART_BLUE: '#00e2ff',
  WARNING: '#ffff00',
  GREY_DATA: '#696969',
  RESET_BACKGROUND: 'rgba(0,0,0,0.3)',
  RESET_COLOR: 'rgba(255,255,255,0.66)',
  CHECKBOX_TICK: '#fff',
  ACTIVE_ROW: '#fff',
  PAGINATION_BACKGROUND: '#000',
  PAGINATION_COLOR: '#fff',
  MAP: {
    LIGHT_BLACK: '#212121',
    GREY: '#757575',
    LIGHT_GREY: '#9e9e9e',
    LIGHTER_GREY: '#bdbdbd',
    BLACK: '#181818',
    LIGHTER_BLACK: '#3c3c3c',
    WATER: '#000000',
    ROAD: '#4e4e4e'
  },
  FOOTER_HEADING: '#f4f6f9',
  PUBLIC_HEADERCOLOR: '#1b1b1b',
  LANGUAGE_SWITCH: '#1589ee',
  HEADER_TEXT: '#c0bbb7',
  MODAL_BACKGROUND: '#000',
  MODAL_BORDER: '#2b2b2b',
  MODAL_LINK: '#c0bbb7',
  BODY_BACKGROUND: '#000',
  LINK_COLOR: '#f58220',
  HEADER_COLOR: '#1b1b1b',
  DOCUMENT_LINE: '#1b1b1b',
  INPUT_BORDER: '#999',
  DIABLED_COLOR: '#373737',
  RADIO_BACKGROUND: '#000',
  SELECTED_AREA: 'rgba(245, 130, 32, 0.3)',
  BACKGROUND_MENU:'#1b1b1b',
  BUCKLED_GREEN: '#008000',
  RANGE_PICKER: {
    CELL_BG: '#393938',
    CELL_HOVER_BG: '#4d4d4d'
  },
  USER_LOGO_BACKGROUND: "#fff",
  USER_LOGO_COLOR: "#f58220"
};
export const fonts = ['Yanone Kaffeesatz', 'tahoma'].join(',');
