import React from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';

import Styled from './style';
// import Form from '../../../components/UI/Form';
import { getImagePath } from '../../../utils/utils';

const Component = ({ apiStatus, callback, i18n }) => {
  return (
    <Styled.Wrapper>
      <Login apiStatus={apiStatus} callback={callback} i18n={i18n} />
    </Styled.Wrapper>
  );
};

export default Component;

const Login = ({ apiStatus, callback, i18n }) => {
  const appStore = getImagePath('appstore.png');
  const googlePlay = getImagePath('googleplay.png');
  //Check whether the api is calling
  return (
    <Styled.LoginWrapper>
      {/* <Form
        view="column"
        type="login"
        hasApiStatus={hasApiStatus}
        id={formId}
        direction="column"
        initialValues={{
          email: '',
          password: '',
        }}
        handleOnSubmit={(fields) => {
          handleLogin({ fields, callback });
        }}
        fields={{
          data: [
            {
              title: '',
              items: [
                {
                  type: 'email',
                  value: '',
                  field: 'Email',
                  placeholder: i18n.t('Email or Employee Id'),
                  label: i18n.t('Email'),
                },
                {
                  type: 'password',
                  value: '',
                  field: 'Password',
                  placeholder: i18n.t('common.password'),
                  label: i18n.t('common.password'),
                },
              ],
            },
          ],
          buttons: [{ type: 'submit', value: i18n.t('Log in') }],
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().required(i18n.t('This field is required')),
          password: Yup.string().required(i18n.t('This field is required')),
        })}
      /> */}
      <Styled.IconWrapper>
        <Link href="https://itunes.apple.com/gb/app/gorilla-fleet-intelligence/id1449686307?mt=8">
          <img src={appStore} alt="Icon for app store" />
        </Link>

        <Link href="https://play.google.com/store/apps/details?id=com.app.gorillafleetintelligence">
          <img src={googlePlay} alt="Icon for google play" />
        </Link>
      </Styled.IconWrapper>
    </Styled.LoginWrapper>
  );
};

Component.propTypes = {
  apiStatus: PropTypes.object.isRequired
};
