const routeConfigs = [
  {
    url: '/auth/login',
    component: 'Login',
    module: 'login',
    title: 'Login',
    layout: 'login'
  },
  {
    url: '/auth/recovery',
    component: 'ForgotPassword',
    module: 'recovery',
    title: 'Reset Password',
    layout: 'login'
  },
  {
    url: '/privacy-policy',
    component: 'PrivacyPolicyScreen',
    layout: 'plain',
    module: 'privacyPolicyScreen',
    title: 'Privacy Policy'
  },
  {
    url: '/user/resetPassword',
    component: 'ResetPassword',
    layout: 'authenticatedStyle',
    module: 'resetPassword',
    title: 'Reset Password'
  },
  {
    url: '/auth/legal',
    component: 'PrivacyPolicy',
    layout: 'noi18n',
    module: 'privacyPolicy',
    title: 'Privacy Policy'
  },
  {
    url: '/registration/step1',
    component: 'Registration',
    layout: 'authenticatedWithoutMenu',
    module: 'registration',
    title: 'Registration'
  },
  {
    url: '/feedback',
    component: 'Feedback',
    title: 'Feedback',
    module: 'feedback'
  },
  {
    url: '/notfound',
    component: 'Apologies',
    layout: 'ApologiesPage',
    module: 'apologies',
    title: 'Page Not Found'
  },
  {
    url: '/user/mfa',
    component: 'MFA',
    layout: 'login',
    module: 'MFAPage',
    title: 'MFA'
  }
];

export default routeConfigs;
