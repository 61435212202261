const initialState = { data: [], loaded: false };
const path = `../../../config/apis/${window?.$environment?.SERVICE_PROVIDER}`;
export const advancedConfig = {
  state: { ...initialState },
  reducers: {
    getSuccess(state, payload) {
      return {
        data: [...payload],
        loaded: true
      };
    },
    getFailed(state) {
      return {
        data: [],
        loaded: true
      };
    },
    updatePayload(state, payload) {
      return {
        ...state,
        data: [...state.data, payload]
      };
    },
    updateFullPayload(state, payload) {
      return {
        ...state,
        data: [...payload]
      };
    },
    setInitialData() {
      return {
        loaded: false
      };
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload) {
      try {
        this.setInitialData();
        const getApi = await import(
          `../../../config/apis/${window.$environment.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('getAdvancedConfigApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject
          },
          id: payload.apiId
        });
        response && this.getSuccess(response);
      } catch (error) {
        this.getFailed();
      }
    },
    async saveData(payload) {
      try {
        const getApi = await import(
          `../../../config/apis/${window.$environment.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('saveAdvancedConfigApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject,
            data: payload.data
          },
          id: payload.apiId
        });
        response && payload.callback();
        //response && this.getSuccess(response);
      } catch (error) {
        this.getFailed();
      }
    },
    updateAdvancedConfig(payload) {
      this.updatePayload(payload?.data?.data);
    },
    updateAdvancedConfigData(fullData) {
      this.updateFullPayload(fullData);
    }
  })
};
