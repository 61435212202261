import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
padding: 2%;
`;
const ChartWrapper = styled(Box)`
  ${({ theme }) => `
width: 100%;
.svg-container,
.updateEventsection {
  display: none;
}
.quickFilter {
  background: -webkit-linear-gradient(top, #d0d8db 0%, #b3bec3 100%);
  color: #f4f6f9;
  padding: 10px 3%;
  margin-top: 15px;
}
  .select__control{
    background: #efefef;
    border: 0;
  }
  .editmodeNotification {
    width: 53%;
    background-color: #bdbdbd;
    color: #fff;
    font-size: 18px;
    text-align: center;
    margin: 0 auto;
    margin-top: 15px;
    // font-weight: bold;
  }
  .svg-content {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    position: absolute;
    top: 0;
    left: 0;
    rect.square {
        fill: #fff;
    }
    text {
        font-size: 3px !important;
        fill: #000;
        font-family: Arial, "Geneva CY", sans-serif;
    }
    .overlay .selectedArea {
        fill: ${theme.colors.SELECTED_AREA}!important;
    }
    rect.leftChip,
    rect.rightChip{
      fill: #fff;
      stroke: #C92100;
    }
    polyline {
      stroke: #C92100;
    }
  }
  .updateEventsection {
    flex-wrap: wrap;
    @media (max-width: ${theme.breakpoints.values.lg}px) {
      justify-content: center;
      padding-top: 12px;
      .MuiBox-root {
        margin-bottom: 5px;
      }
    }
    .button--disabled:hover {
      background: #ebebeb;
      box-shadow: none;
    }
    .button-status{
      width:95%;
      font-family: Arial, "Geneva CY", sans-serif;
    }
    
    .MuiButton-outlinedSizeSmall {
      padding: 3px 14px !important;
      background-color: #ebebeb;
      color: #6b6b6b;
      font-weight: 600;
      font-size: 16px;
      cursor: pointer;
    }
    #update_graph + div {
      #button {
        border: none;
      }
    }
  }
  #update_graph {
    .MuiButton-outlinedSizeSmall{
      border: 1px solid #ddd;
    }
    :hover {
      // background: #ebebeb;
      box-shadow: none;
    }
  }
  .button--disabled: hover {
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.primary.contrastText};
    cursor: inherit;
  }
  .button--status-normal: hover,
  .button--update-normal: hover,
  .button--update-normal,
  .button-add {
    color: ${theme.palette.secondary.contrastText + '!important'};
    background-color: ${theme.palette.secondary.main + '!important'};
    cursor: pointer;
  }
  .button-add {
    font-size: 12px !important;
    text-transform: capitalize;
    
  }
  #addNewRow .button-add {
    padding: 5px 15px !important;
  }
  .active {
    background-color: ${theme.palette.secondary.main}!important ;
    color: ${theme.palette.secondary.contrastText}!important ;
  }

  .button-status {
    margin-right: 4px;
    padding-top: 0px;
    padding-bottom: 0px;
    color: ${theme.palette.primary.contrastText};
    background-color: ${theme.palette.primary.main};
    font-size: 12px;
    border: ${`1px solid ${theme.palette.primary.border}`};
    @media (max-width: ${theme.breakpoints.values.sm}px) {
      padding: 0;
    }
  }

  .button-update {
    font-size: 12px !important;
    margin-left: 0.5rem;
    color: ${theme.palette.primary.contrastText};
    background-color: ${theme.palette.primary.main};
    border: ${`1px solid ${theme.palette.primary.contrastText}`};
    text-transform: capitalize;
    padding: 0px 26px;
    width: auto !important;
    font-weight: normal !important;

  }
  #addNewRow {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    justify-content: center;
  }
  .cancelSubmitwrapper {
    width:100%;
  }
  `}
`;
const LabelWrap = styled(Box)`
  p {
    font-size: 14px;
    margin-bottom: 20px;
  }
`;
const StatusModifier = styled(Box)`
 ${({ theme }) => `
  .status {
    min-width: 98px;
  }
  .MuiPaper-root .MuiBox-root:first-child {
    overflow-x: inherit;
  }
  .MuiTableHead-root .MuiTableRow-root{
    border-bottom: 1px solid #ddd;
  }
  .MuiTableHead-root .MuiTableRow-root {
    .MuiTableCell-root:first-child {
      min-width: 112px;
    }
    .MuiTableCell-root:nth-child(6),
    .MuiTableCell-root:nth-child(2) {
      min-width: 150px;
    }
  }
  .MuiInputBase-input{
      border: 0 !important;
        color: #373737!important;
  }
  .MuiInputBase-root.Mui-disabled,
  .MuiInputBase-input.Mui-disabled{
    cursor: no-drop;
  }
  .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root:first-child {
    min-width: 147px;
  }
  .select__control,
  .react-datepicker-wrapper input,
  input {
    background: #fff none repeat scroll 0 0 !important;
    border: 1px solid #d0d0d0 !important;
  }
  .select__single-value {
    background: #fff none repeat scroll 0 0 !important;
    border:0;
  }
  .select__control:hover{
    border: 1px solid #d0d0d0 !important;
  }
  .MuiTableBody-root tr:nth-child(even):hover,
  .MuiTableBody-root tr:nth-child(odd):hover,
  .MuiTableBody-root tr:nth-child(odd) {
    background: ${theme.colors.WHITE} !important;
    
    }
  .stateModifier {
    min-width: 120px;
  }
  .driverId,
  .stateModifier {
    .select__placeholder {
      font-size: 16px;
    }
    .select__dropdown-indicator {
      padding-left: 0;
      padding: 3px;
    }
  }
  
  .react-datepicker--time-only {
    border: 2px solid colors.PRIMARY_BORDER;
    color: colors.PRIMARY_CONTRAST;
    font-family: Yanone Kaffeesatz, tahoma;
    font-size: 14px;
    font-weight: normal;
    .react-datepicker__time-list {
      padding-top: 6px;
    }
    .react-datepicker__time-list-item {
      border-top: none !important;
    }
    .react-datepicker__header--time {
      display: none;
    }
  }
  .dailyLogDateError {
    border: 2px solid red !important;
  }
  `}
`;
const CancelSaveWrapper = styled(Box)`
  ${({ theme }) => `

  `}
`;
const Styled = { Wrapper, ChartWrapper, LabelWrap, StatusModifier, CancelSaveWrapper };
export default Styled;
