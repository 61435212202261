import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled.div`
  ${({ theme }) => `
  position: relative;
  flex-direction: column;
  .loginformwrap {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flexbox;
    display: flex;
    width: 100%;
    /* Change the white to any color ;) */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active  {
        -webkit-box-shadow: 0 0 0 30px #efefef inset !important;
    }

    /*Change text in autofill textbox*/
    input:-webkit-autofill {
        -webkit-text-fill-color: #000 !important;
    }
  }
  .registrationLink {
    color: ${theme.colors.WHITE} !important;
  }
  .registrationheader: hover {
    text-decoration: none !important;
  }
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  .MuiTypography-h1 {
    align-items: center;
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flexbox;
    display: flex;
    padding: 0px;
  }
  `}
`;
const LoginWrapper = styled.div`
  ${({ theme }) => `
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  flex-direction: column;
  flex: 0 0 33%;
  margin-left: 5%;
  margin-top: 42px;
  .formLogin {
    flex: 0 0 85%;
    padding-bottom: 20px;
    @media only screen and (min-width: ${theme.breakpoints.values.lg}px) and (max-height: ${theme.breakpoints.values.xxl}px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      flex: 0 0 55%;
    }
  }
  .MuiTypography-colorPrimary {
    flex: 0 0 2% !important;
  }
  .buttonWrapper {
    margin-top: 1%;
    width: 50% !important;
    margin-left: 37% !important;
  }
  div[type='error'] p {
    font-size: 17px;
  }
  .forgotPassword {
    div[type='error'] p {
      font-size: 20px !important;
      margin-bottom: 15px;
    }
  }
  @media (max-width: ${theme.breakpoints.values.sm}px) {
    flex: 0 0 89% !important;
    margin-left: 20px;
  }

  @media (min-width: ${theme.breakpoints.values.md}px) and (max-width: ${theme.breakpoints.values.lg}px) {
    flex: 0 0 75% !important;
  }
  @media (max-width: ${theme.breakpoints.values.lg}px) {
    margin-top: 34px;
  }
  @media (max-width: ${theme.breakpoints.values.sm}px) {
    margin-top: 24px;
  }
  @media (max-width: ${theme.breakpoints.values.xxl}px) and (min-width: ${theme.breakpoints.values.xl}px) {
    margin-top: 345px;
  }
  @media all and (orientation: landscape) {
    flex: 0 0 75%;
  }

  @media (min-width: ${theme.breakpoints.values.sm}px) and (max-width: ${theme.breakpoints.values.md}px) and (orientation: landscape) {
    margin-top: 20px;
    flex: 0 0 50%;
  }
  @media (min-width: ${theme.breakpoints.values.lg}px) and (orientation: landscape) {
    margin-top: 90px;
    flex: 0 0 39.9%;
  }
  /* Ipad pro */
  @media only screen and (min-width: ${theme.breakpoints.values.lg}px) and (max-height: ${theme.breakpoints.values.xxl}px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    margin-top: 335px;
  }
  @media (max-width: ${theme.breakpoints.values.md}px) {
    flex: 0 0 75%;
  }
  .MuiFormLabel-root {
    font-size: 24px !important;
  }
  .loginForm {
    flex: 0 0 85%;
    @media only screen and (min-width: ${theme.breakpoints.values.lg}px) and (max-height: ${theme.breakpoints.values.xxl}px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      flex: 0 0 55%;
    }
  }
  .forgotPassword {
    .MuiButtonBase-root {
      border: none;
    }

    .MuiBox-root:first-child {
      .MuiFormLabel-root {
        flex: 0 0 24%;
      }
      .MuiFormLabel-root + div {
        flex: 0 0 71%;
      }
    }
    form {
      margin-top: 1%;
      margin-left: 3%;
      .erorMessage {
        font-size: 20px;
        margin-bottom: 15px;
      }
      .MuiBox-root:last-child {
        justify-content: center;
      }
      input,
      .MuiInputBase-root {
        background-color: ${theme.colors.INPUT_BACKGROUND} !important;
      }
      button {
        margin-top: 13px;
        padding: 2px 44px;
      }
    }
    .MuiButton-root:hover {
      box-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);
      outline: none;
      cursor: pointer;
    }
  }
  `}
`;

const LoginHeader = styled(Box)`
  ${({ theme }) => `
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  .subHeading {
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
  }
  .loginHeader {
    flex: 0 0 29%;
    font-size: 30px;
    @media (max-width: ${theme.breakpoints.values.md}px) {
      font-size: 25px;
    }
  }
  .registrationHeader {
    flex: 0 0 49% !important;
    justify-content: flex-end;
    font-size: 30px;
    color: ${theme.colors.WHITE} !important;
    padding-right: 0 !important;
    background: transparent !important;
    padding-top: 7px;
    @media (max-width: ${theme.breakpoints.values.md}px) {
      font-size: 25px;
    }
    .linkWrap {
     display: flex;
     justify-content: flex-end;
    }
    .registrationHeaderlink {
      font-size: 30px;
      font-weight: normal;
      color: ${theme.colors.WHITE} !important;
      @media (max-width: ${theme.breakpoints.values.md}px) {
        font-size: 25px;
      }
    }
  }
  `}
`;

const PasswordHandler = styled(Box)`
  ${({ theme }) => `
  justify-content: flex-end;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  padding-top: 12px;
  padding-left: 25%;
  .MuiBox-root {
    flex: 0 0 50% !important;
    a {
      @media (max-width: ${theme.breakpoints.values.md}px) {
        font-size: 16px;
      }
      @media only screen and (min-width: ${theme.breakpoints.values.xsm}px) and (max-width: ${theme.breakpoints.values.sm}px) {
        font-size: 14px;
      }
    }
  }

  .MuiTypography-colorPrimary {
    color: ${theme.colors.WHITE} !important;
  }
  .MuiCheckbox-root {
    width: auto !important;
  }
  .normalCheckbox span {
    color: ${theme.colors.WHITE} !important;
    margin-left: 6px;
  }
  label {
    margin-right: 0px;
  }
  .MuiSvgIcon-root {
    border: none;
    background: transparent;
  }
  a {
    padding-top: 10px;
  }
  cursor: pointer;
  .remember {
    flex: 0 0 50%;
    text-align: right;
    padding-right: 5px;
    margin-top: -7px;
    @media (max-width: ${theme.breakpoints.values.md}px) {
      font-size: 16px;
    }
    @media only screen and (min-width: ${theme.breakpoints.values.xsm}px) and (max-width: ${theme.breakpoints.values.sm}px) {
      font-size: 15px;
    }
    .Checkbox {
      top: 2px;
    }
  }
  `}
`;
const userNameWrapper = styled.div`
  ${({ theme }) => `
  margin-bottom: 7px;
  .MuiInputBase-input {
    color: ${theme.colors.BLACK};
    background-color: ${theme.colors.INPUT_BACKGROUND} !important;
  }
  input:: ::placeholder {
    color: #514b4b;
  }
  p {
    font-size: 24px;
    @media (max-width: ${theme.breakpoints.values.md}px) {
      font-size: 20px;
    }
  }
  .MuiInputBase-root {
    background-color: ${theme.colors.INPUT_BACKGROUND} !important;
    height: 41px;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
  .MuiBox-root {
    p {
      flex: 0 0 23%;
    }
    p + div {
      flex: 0 0 77%;
    }
  }
  `}
`;
const passwordWrapper = styled.div`
  ${({ theme }) => `
  .MuiInputBase-input {
    color: ${theme.colors.BLACK};
    background-color: ${theme.colors.INPUT_BACKGROUND} !important;
  }
  .MuiInputBase-root {
    background-color: ${theme.colors.INPUT_BACKGROUND} !important;
    height: 41px;
  }
  p {
    font-size: 24px;
    @media (max-width: ${theme.breakpoints.values.md}px) {
      font-size: 20px;
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
  .MuiBox-root {
    p {
      flex: 0 0 23%;
    }
    p + div {
      flex: 0 0 77%;
    }
  }
  `}
`;
const LoginButtonWrapper = styled.div`
  ${({ theme }) => `
  .MuiBox-root {
    @media (max-width: ${theme.breakpoints.values.lg}px) {
      justify-content: center;
    }
    .MuiBox-root {
      width: 33% !important;
      margin-right: 8rem !important;
      @media only screen and (min-width: ${theme.breakpoints.values.xsm}px) and (max-width: ${theme.breakpoints.values.sm}px) {
        width: 55% !important;
        font-size: 20px;
      }
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        margin-right: 0 !important;
      }
    }
  }
  div #button {
    border: none;
    height: 42px;
  }

  .MuiButton-root:hover {
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);
    outline: none;
    cursor: pointer;
  }
  `}
`;
const mainWrapper = styled.div``;
const LoginError = styled.div`
  margin-bottom: 15px;
  div[type='error'] p {
    font-size: 20px;
  }
`;
const Styled = {
  Wrapper,
  LoginWrapper,
  LoginHeader,
  PasswordHandler,
  userNameWrapper,
  passwordWrapper,
  LoginButtonWrapper,
  mainWrapper,
  LoginError
};
export default Styled;
