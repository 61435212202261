import React, { lazy, useEffect } from 'react';
import PropTypes from 'prop-types';

import { withRouter, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Link from '@material-ui/core/Link';

import {
  getImagePath,
  setLanguage,
  setLanguageFromStorage,
  showLanguageCode
} from '../../../utils/utils';

import { imageUrls } from '../../../config/constants/keys';

import Styled from './publicStyle';
const Loader = lazy(() => import(`@/components/Loader`));
const Logo = lazy(() => import(`../../Logo`));
const PublicWrapper = ({ children }) => {
  const { i18n } = useTranslation();
  const history = useHistory();
  useEffect(() => {
    setLanguageFromStorage(i18n, 'en');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleOnLanguage = () => {
    setLanguage(i18n);
  };
  const serviceProvider = window.$environment?.SERVICE_PROVIDER;
  if (history.location.state?.search)
    return (
      <>
        <Loader />
        {children}
      </>
    );
  else
    return (
      <Styled.Wrapper>
        <Styled.Content>
          <Styled.HeaderContainer>
            <div className="not-authenticated" id="container">
              <Logo
                src={getImagePath(imageUrls.logo)}
                size="large"
                width={'220px'}
                isWeblogin
                history={history}
              />

              <Styled.LanguageContainer onClick={handleOnLanguage}>
                {i18n.t(`common.${showLanguageCode('en', false)}`)}
              </Styled.LanguageContainer>
            </div>
          </Styled.HeaderContainer>

          {children}
          <Styled.IconWrapper>
            <Link
              href={`externalUrls.${serviceProvider}.appStore`}
              target="_blank"
            >
              <img
                src={getImagePath(imageUrls.appStore)}
                alt="Icon for app store"
              />
            </Link>

            <Link
              href={`externalUrls.${serviceProvider}.googlePlay`}
              target="_blank"
            >
              <img
                src={getImagePath(imageUrls.googleplay)}
                alt="Icon for google play"
              />
            </Link>
          </Styled.IconWrapper>
        </Styled.Content>
      </Styled.Wrapper>
    );
};

export default withRouter(PublicWrapper);
PublicWrapper.defaultProps = {
  children: null
};
PublicWrapper.propTypes = {
  children: PropTypes.node
};
