import React, { useEffect, lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RenderComponent from '@/components/modules/DynamicRenderPage';
import { useParams } from 'react-router-dom';

import { hasRestrictedRole } from '@/utils/utils';
import { COOKIE_AUTH_DETAILS, ROLES } from '@/config/constants/keys';
import { getCookie } from '../../../services/cookie';

import ParentStyled from './style';
import { loadStyle, loadPageConfig } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'pages/web/Department');

const layout = window?.$environment?.CURRENT_LAYOUT;
const Modal = require(`@/components/UI/Modal/${layout}`).default;
const Timezone = lazy(() => import(`@/components/modules/Timezone/${layout}`));

const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.departmentsList[0].name}`));
const Content = lazy(() => import(`@/${config.departmentsList[1].name}`));
const TopActions =
  config.departmentsList[0].children &&
  lazy(() => import(`${config.departmentsList[0].children.name}`));

const Department = (parentProps) => {
  const match = useParams();
  const {
    fetchTerminals,
    data,
    history,
    i18n,
    theme,
    callback,
    saveTerminal,
    updateTerminal,
    saveSubDepartment,
    updateSubDepartment,
    showFilterPopup
  } = parentProps;
  const commonParentProps = { history, i18n, Styled, theme };
  const [addOpen, setAddOpen] = useState(false);
  const token = getCookie(COOKIE_AUTH_DETAILS);
  const viewOnly = hasRestrictedRole([ROLES.VIEW_ONLY_FM]);
  const isTermCrudRestricted =
    !viewOnly && (token?.accessLevel >= 3 || hasRestrictedRole(['FLMANAGER']));
  const isDeptCrudRestricted =
    !viewOnly && (token?.accessLevel >= 2 || hasRestrictedRole(['FLMANAGER']));
  const isLocCrudRestricted =
    !viewOnly && (token?.accessLevel >= 1 || hasRestrictedRole(['FLMANAGER']));
  const [withCloseIcon, setwithCloseIcon] = useState(true);

  const getTmezonePopup = (type, message, payload) => {
    if (type === 'success') callback('success', message);
    else {
      if (message === 'Time zone not available') {
        setwithCloseIcon(false);
        setAddEditModalObject({
          header: '',
          content: () => {
            return (
              <Timezone
                closeModal={closeAddEditModal}
                callback={callback}
                payload={payload}
                saveTerminal={saveTerminal}
                updateTerminal={updateTerminal}
                saveSubDepartment={saveSubDepartment}
                updateSubDepartment={updateSubDepartment}
              />
            );
          }
        });
        clickedAddEditModal();
      } else callback('error', message);
    }
  };
  const isCrudRestricted = hasRestrictedRole(['FLMANAGER']);

  const [AddEditModalObject, setAddEditModalObject] = useState({
    header: '',
    content: () => {
      return null;
    }
  });
  const clickedAddEditModal = (e) => {
    setAddOpen(true);
  };
  const closeAddEditModal = (event) => {
    setAddOpen(false);
  };
  useEffect(() => {
    !data?.length &&
      fetchTerminals({
        apiId: 'fetchTerminalsById',
        data: { id: match.id }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.id]);
  return (
    <>
      <RenderComponent
        {...config.departmentsList[0]}
        parentProps={{
          ...parentProps,
          setAddEditModalObject,
          clickedAddEditModal,
          closeAddEditModal,
          isTermCrudRestricted,
          getTmezonePopup,
          isCrudRestricted,
          isDeptCrudRestricted,
          isLocCrudRestricted
        }}
        commonParentProps={commonParentProps}
        component={Title}
        childComponent={TopActions}
      />
      <RenderComponent
        {...config.departmentsList[1]}
        parentProps={{
          ...parentProps,
          setAddEditModalObject,
          clickedAddEditModal,
          closeAddEditModal,
          isTermCrudRestricted,
          getTmezonePopup,
          isCrudRestricted,
          isDeptCrudRestricted,
          isLocCrudRestricted,
        }}
        commonParentProps={commonParentProps}
        component={Content}
        withCloseIcon={withCloseIcon}
      />
      <Modal
        open={addOpen}
        setOpen={setAddOpen}
        data={AddEditModalObject}
        onCloseModal={closeAddEditModal}
        background="black"
        timeZone={false}
        withCloseIcon={withCloseIcon}
      />
    </>
  );
};
const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    data: state.departments
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchTerminals: (data) => dispatch.departments.fetchData(data),
    saveRegion: (data) => dispatch.departments.saveRegion(data),
    updateRegion: (data) => dispatch.departments.updateRegion(data),
    deleteRegion: (data) => dispatch.departments.deleteRegion(data),
    saveTerminal: (data) => dispatch.departments.saveTerminal(data),
    deleteTerminal: (data) => dispatch.departments.deleteTerminal(data),
    updateTerminal: (data) => dispatch.departments.updateTerminal(data),
    saveDepartment: (data) => dispatch.departments.saveDepartment(data),
    updateDepartment: (data) => dispatch.departments.updateDepartment(data),
    deleteDepartment: (data) => dispatch.departments.deleteDepartment(data),
    saveSubDepartment: (data) => dispatch.departments.saveSubDepartment(data),
    updateSubDepartment: (data) =>
      dispatch.departments.updateSubDepartment(data),
    deleteSubDepartment: (data) =>
      dispatch.departments.deleteSubDepartment(data)
  };
};

export default connect(mapState, mapDispatch)(Department);
Department.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  fetchTerminals: PropTypes.func.isRequired
};
