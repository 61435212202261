import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
padding: 2%;
.fieldWrapper {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        margin-bottom: 73px;
      }
}
.MuiAccordionDetails-root{
    padding: 8px 16px 16px !important;
}

.MuiPaper-root {
    background: #fff !important; 
    .MuiAccordionSummary-content h2 {
        font-size: 18px;
        text-transform: capitalize;
        color: ${theme.colors.LINK_COLOR} !important; 
    }
    .MuiIconButton-label {
        .MuiSvgIcon-root {
            fill: ${theme.colors.LINK_COLOR};
        }
    }
}
`}
`;

const Styled = { Wrapper };

export default Styled;
