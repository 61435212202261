import React, { lazy, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { pageUrls, imageUrls } from '@/config/constants/keys';
import { Link as LinkDom} from 'react-router-dom';
import { Link as LinkLi } from '@material-ui/core';
import { getImagePath, hasRestrictedRole } from '@/utils/utils';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Box from '@material-ui/core/Box';
import TopActions from './TopActions';


const layout = window?.$environment?.CURRENT_LAYOUT;
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Breadcrumb = lazy(() => import(`@/components/Breadcrumb`));

const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const Link = lazy(() => import(`@/components/UI/Link`));

const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      {layout !== 'OldUi' && <Breadcrumb filters={filters} />}
      <Component {...contentProps} filters={filters} />
    </Box>
  );
};
export default Index;
const Component = ({
  workOrders,
  filterSuccess,
  Styled,
  history,
  topAction,
  viewReport,
  filters,
  pagination,
  setPagination,
  viewOnly,
  changeRequest,
  request,
  setFilter,
  filter
}) => {
  const { i18n } = useTranslation();
  const columns = [
    {
      id: 'work_order_id',
      numeric: true,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('workOrders.workOrderNo')
    },
    {
      id: 'unitId',
      numeric: false,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('vehicles.unitId')
    },
    {
      id: 'vin',
      numeric: false,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('vehicles.vin')
    },
    {
      id: 'mechanicName',
      numeric: false,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('common.mechanic')
    },
    {
      id: 'driverName',
      numeric: false,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('common.driver')
    },
    {
      id: 'unitType',
      numeric: false,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('workOrders.unitType')
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('companyData.assetsType')
    },
    {
      id: 'reported_date',
      numeric: true,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('workOrders.reportedDate')
    },
    {
      id: 'scheduled_date',
      numeric: true,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('workOrders.scheduledDate')
    },
    {
      id: 'due_date',
      numeric: false,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('workOrders.dueDate')
    },
    {
      id: 'repair_cost',
      numeric: true,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('workOrders.repairCost')
    },
    {
      id: 'report',
      numeric: false,
      needSorting: true,
      disablePadding: false,
      label: i18n.t('workOrders.inspectionReport')
    },
    {
      id: 'workOrderType',
      numeric: false,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('workOrders.serviceType')
    },
    window.$environment.SERVICE_PROVIDER == 'spireon' && {
      id: 'dvirLocation',
      numeric: false,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('workOrders.dvirLocation')
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('Status')
    },
    {
      id: 'actions',
      label: ''
    }
  ];
 
  let formattedType = {
    TRACTOR_DAY_CAB: 'Tractor Day Cab',
    TRACTOR_SLEEPER: 'Tractor Sleeper',
    BOBTAIL: 'Bobtail',
    STRAIGHT_TRUCK: 'Straight Truck',
    BOX_TRUCK: 'Box Truck',
    PICKUP: 'Pickup',
    PRIVATE_PASSENGER: 'Private Passenger',
    VACUUM: 'Vacuum',
    VAN: 'Van',
    OTHER: 'Other'
  };
  const RTFMuser = window?.$environment?.SERVICE_PROVIDER === 'riskTheory' && hasRestrictedRole(['FLMANAGER'])
  const generateRows = () => {
    const updatedList = workOrders.filteredList.map((item) => {
      let result = { ...item };
      result.type = item.type || '-'
      result.status = item.status?.name;
      result.work_order_id = (
        <Link href={viewOnly? pageUrls.viewWorkOrder.replace(':id', item.work_order_id) :pageUrls.editWorkOrder.replace(':id', item.work_order_id)}>
          {item.work_order_id}
        </Link>
      );
      result.unitId = window.$environment.CURRENT_LAYOUT !== 'SpireonNew' && !RTFMuser ? (
        <Link href={`/${item.unitType === 'TRAILER' ? 'trailer' : 'vehicle'}/edit/${item.unit_id?.id}`}>{item.unitId}</Link>
      ): item.unitId;
      result.mechanicName = (
        <Link href={pageUrls.editUser.replace(':id', item.mechanic?.id)}>
          {item.mechanicName}
        </Link>
      );
      result.driverName = (
        RTFMuser ? item.driverName : <Link href={ pageUrls.editUser.replace(':id', item.driver?.id)}>{item.driverName}</Link> 
      );
      result.report = item.inspection_report?.id ? (
        <Link
          onClick={() =>
            viewReport({
              apiId: 'viewReportApi',
              data: item?.inspection_report?.id
            })
          }
        >
          {i18n.t('workOrders.viewReport')}
        </Link>
      ) : (
        i18n.t('workOrders.noReport')
      );
      result.id = item.work_order_id;
      return result;
    });
    return updatedList || [];
  };
  useEffect(() => {
    generateRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workOrders?.filteredList]);

  const filterByStatus = (status) => {
    setFilter(status);
  };
  const information = i18n.t('common.tableEmptyMessage');
  return (
    <>
      <Styled.Wrapper>
        {topAction && (
          <TopActions
            {...{
              filterSuccess,
              Styled,
              workOrders,
              i18n,
              history,
              setPagination,
              viewOnly
            }}
          />
        )}
        <div className="status-wrapper">
          <span className="quickFilters">Quick filters:</span>
          <FormStyle.default.ButtonsWrapper className="openFilter">
            <Button
              type="submit"
              className={
                filter == 'OPEN' ? 'buttonStyle filter-btn' : 'buttonStyle'
              }
              label={i18n.t('workOrders.open')}
              onClick={() => {
                filterByStatus('OPEN');
              }}
            />
          </FormStyle.default.ButtonsWrapper>
          <FormStyle.default.ButtonsWrapper>
            <Button
              type="submit"
              className={
                filter == 'CLOSED' ? 'buttonStyle filter-btn' : 'buttonStyle'
              }
              label={i18n.t('workOrders.closed')}
              onClick={() => {
                filterByStatus('CLOSED');
              }}
            />
          </FormStyle.default.ButtonsWrapper>
        </div>
        <div className="breadcrumbClass">
          {layout === 'OldUi' && <Breadcrumb filters={filters} />}
        </div>
        <Styled.TableWrapper>
          <Table
            type="server"
            defaultSortBy={{ sortBy: 'work_order_id', order: 'desc' }}
            title=""
            baseRequest={request}
            totalRecords={workOrders.totalCount}
            changeRequest={changeRequest}
            columns={[...columns]}
            data={generateRows()}
            emptyMessage={information}
            actions={[
              {
                icon: (row, index) => {
                  return (
                    <LinkLi 
                    component={LinkDom}
                    to={viewOnly? pageUrls.viewWorkOrder.replace(':id', row.id): pageUrls.editWorkOrder.replace(':id', row.id)}>
                    <>
                    {viewOnly? <VisibilityIcon className="historyIcon"/>: 
                    <Icon
                        src={getImagePath(imageUrls.edit)}
                        // onClick={() => {
                        //   history.push(
                        //     pageUrls.editWorkOrder.replace(':id', row.id)
                        //   );
                        // }}
                      />}
                    </>
                    </LinkLi>
                  );
                }
              }
            ]}
            itemsPerPage={10}
            i18n={i18n}
            pageinationReset={pagination}
            pagenationClicked={(e) => {
              setPagination(!e);
            }}
          />
        </Styled.TableWrapper>
      </Styled.Wrapper>
    </>
  );
};

Component.propTypes = {
  filterSuccess: PropTypes.func.isRequired
};
