import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Parent from './style';
const Wrapper = styled(Parent.Wrapper)`
  ${({ theme }) => `
form {
    .MuiBox-root:nth-child(6){
        border-top: 2px solid #000;
        padding-right: 1%;
    }
    .formWrapper {
        padding: 0 8px;
    }
}
`}
`;

const Content = styled(Box)`
${({ theme }) => `
.addLogTitle {
    text-transform: capitalize;
    border-top: 2px solid #000;
    margin-bottom: 0 !important;
}
.MuiTab-root {
    min-width: 100px;
}
.MuiButtonBase-root.MuiTab-root{
  padding: 0;
  line-height: 1.4;
  margin-right: 1%;
}
.MuiTabs-fixed{
  padding-bottom: 5px;
}
header {
    margin-left: 20%;
    padding-top: 21px;
    padding-bottom: 8px;
  }
`}
`;

const Styled = { Wrapper, Content };

export default Styled;
