import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`

`;
const TopWrapper = styled(Parent.TopWrapper)`
justify-content: flex-start;
padding-left: 21%;
padding-top: 2%;
.CSAForms {
  justify-content: flex-start;
  .csafield {
    width: 13.5%;
  }
  .vertical-button {
    .MuiButtonBase-root {
      margin-top: 28px;
    }
    .MuiBox-root{
      margin-right: 14px;
    }
    .MuiButton-label{
      padding: 0 !important;
    }
  }
}
`;
const TableWrapper = styled(Parent.TableWrapper)`
${({ theme }) => `
    padding: 0;
    .tableWrapper {
      padding: 0 2%;
  }
    .quickFilter {
      background: ${theme.colors.LINK_COLOR} none repeat scroll 0 0;
      color: #f4f6f9;
      padding: 10px 3%;
  }
  .MuiTableHead-root tr:first-child .MuiTableCell-root:nth-child(4){
    background: #efefef;
  }
  .MuiTableHead-root tr:last-child {
    .MuiTableCell-root {
      background-color: #ddd;
    }
  }
  `}
`;
const Styled = { Wrapper, TopWrapper, TableWrapper };

export default Styled;
