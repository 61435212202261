import PropTypes from 'prop-types';
import React, { lazy, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import RenderComponent from '@/components/modules/DynamicRenderPage';

import { loadPageConfig, loadStyle } from '@/utils/utils';
import ParentStyled from './style';
let Styled = loadStyle(ParentStyled, 'pages/web/VehicleStatusList');

const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.vehicleStatusViewMore[0].name}`));
const Content = lazy(() => import(`@/${config.vehicleStatusViewMore[1].name}`));
const TopActions =
  config.vehicleStatusViewMore[0].children &&
  lazy(() => import(`${config.vehicleStatusViewMore[0].children.name}`));

const VehicleStatusList = (parentProps) => {
  const { fetchVehicleStatus, history, i18n, theme } = parentProps;

  const commonParentProps = { history, i18n, Styled, theme };

  useEffect(() => {
    fetchVehicleStatus({
      apiId: 'vehicleStatusList',
      data: { isVehicleStatusPageFlag: true }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [pagination, setPagination] = useState(false);
  return (
    <>
      <RenderComponent
        {...config.vehicleStatusViewMore[0]}
        parentProps={{ ...parentProps, pagination, setPagination }}
        commonParentProps={commonParentProps}
        component={Title}
        childComponent={TopActions}
      />
      <RenderComponent
        {...config.vehicleStatusViewMore[1]}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        component={Content}
        pagination={pagination}
        setPagination={setPagination}
      />
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    vehicleStatus: state.vehicleStatusList
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchVehicleStatus: (data) => dispatch.vehicleStatusList.refreshData(data),
    filterSuccess: (data) => dispatch.vehicleStatusList.filterSuccess(data),
  };
};

export default connect(mapState, mapDispatch)(VehicleStatusList);
VehicleStatusList.defaultTypes = {};
VehicleStatusList.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  fetchVehicleStatus: PropTypes.func.isRequired
};
