import { ROLES } from '@/config/constants/keys'
const currentLayout = window.$environment.CURRENT_LAYOUT;
const baseUrl = currentLayout === 'SpireonNew' ? '/spireon' : '';
const leftMenu = [
  {
    icon: 'logs.svg',
    label: 'leftMenu.summary',
    roles: ['FLMANAGER', 'ADMIN', 'DRIVER', ROLES.VIEW_ONLY_FM],
    path: baseUrl + '/summary'
  },
  {
    icon: 'logs.svg',
    label: 'leftMenu.logs',
    roles: ['FLMANAGER', 'ADMIN', ROLES.VIEW_ONLY_FM],
    path: baseUrl + '/logs'
  },
  {
    icon: 'user.svg',
    label: 'Drivers Status',
    roles: ['FLMANAGER', 'ADMIN', ROLES.VIEW_ONLY_FM],
    path: baseUrl + '/driverStatus/more'
  },
  {
    icon: 'user.svg',
    label: 'leftMenu.users',
    roles: ['FLMANAGER', 'ADMIN', ROLES.VIEW_ONLY_FM],
    path: '/user/all'
  },
  {
    icon: 'vehicle.svg',
    label: 'leftMenu.vehicles',
    roles: ['FLMANAGER', 'ADMIN', 'MECHANIC', ROLES.VIEW_ONLY_FM],
    path: '/vehicle/list'
  },
  {
    icon: 'accident.svg',
    label: 'leftMenu.accidents',
    roles: ['FLMANAGER', 'ADMIN', 'MECHANIC', ROLES.VIEW_ONLY_FM],
    path: '/accidents/list'
  },
  {
    icon: 'inspection.svg',
    label: 'leftMenu.inspections',
    roles: ['FLMANAGER', 'ADMIN', ROLES.VIEW_ONLY_FM],
    path: baseUrl + '/inspections'
  },
  {
    icon: 'reports.svg',
    label: 'leftMenu.reports',
    roles: ['FLMANAGER', 'ADMIN', ROLES.VIEW_ONLY_FM],
    path: baseUrl +'/report'
  },
  {
    icon: 'policy.svg',
    label: 'leftMenu.policiesProcedures',
    roles: ['FLMANAGER', 'ADMIN', ROLES.VIEW_ONLY_FM],
    path: baseUrl + '/policy'
  }
];
export default leftMenu;
