import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
.status-wrapper {
    padding-top: 0;
    margin-top: 0;
    background: ${theme.colors.LINK_COLOR};
    padding-left: 2%;
    .openFilter {
        padding: 0 2%;
      }
    .buttonStyle {
      color: #fff !important;
        width: auto;
        font-size: 16px !important;
        background: ${theme.colors.LINK_COLOR}!important;
        border: 0!important;
        font-style: italic;
        font-family: ${theme.palette.tableFont};
        margin: 0;
        padding-left: 0;
        padding-right: 0;
      &:hover {
        background: none;
        text-decoration: underline;
        box-shadow: none;
        font-size: 16px !important;
      }
    }
    .filter-btn {
      text-decoration: underline;
    }
    .active {
      text-decoration: underline;
    }
    .quickFilters {
      display: inline-block;
      color: ${theme.colors.WHITE};
      align-self: center;
      font-size: 15px;
      font-size: ${theme.palette.headerFont};
    }
  }
  .breadcrumbClass {
    border-top: 2px solid #000;
   border-bottom: 2px solid #000;
   padding-left: 5%;
  //  margin-bottom: 1%;
   min-height: 19px;
  }
  `}
`;
const SearchWrapper = styled(Parent.SearchWrapper)``;
const TopWrapper = styled(Parent.TopWrapper)`
flex: 0 0 65%;
.status-btn-wrapper {
  .MuiButton-root {
    font-size: 24px;
  }
  .MuiLink-underlineHover:hover {
    text-decoration: none !important;
  }
}
`;
const TableWrapper = styled(Parent.TableWrapper)`
padding: 0 2%;
`;
const LabelWrap = styled(Parent.LabelWrap)``;

const Styled = {
    Wrapper,
    SearchWrapper,
    TopWrapper,
    TableWrapper,
    LabelWrap
  };

export default Styled;
