import PropTypes from 'prop-types';
import React, { lazy, useState } from 'react';

import { imageUrls, pageUrls } from '@/config/constants/keys';
import Box from '@material-ui/core/Box';

import { getImagePath } from '@/utils/utils';

const layout = window?.$environment?.CURRENT_LAYOUT;

const Breadcrumb = lazy(() => import(`../../../components/Breadcrumb`));
const GoogleMap = lazy(() => import(`@/components/GoogleMap/${layout}`));
const Search = lazy(() => import(`@/components/UI/SimpleSearch/${layout}`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Modal = require(`../../../components/UI/Modal/${layout}`).default;

const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Breadcrumb filters={filters} />
      <Component {...contentProps} />
    </Box>
  );
};
export default Index;

const Component = ({
  changeRequest,
  request,
  i18n,
  driverStatusList,
  history,
  getDriverStatus,
  Styled,
  filterSuccess,
  driverStatusFilteredList,
  totalElements,
  pagination,
  setPagination
}) => {
  // useEffect(() => {
  //   getDriverStatus({
  //     apiId: 'getDriverStatus',
  //     data: { isDashboard: true }
  //   });
  // }, []);
  const selectChange = (e) => {
    changeRequest({ search: e });
    // filterSuccess(e);
    setPagination(true);
  };
  const [modal, setModal] = useState(false)
  const columns = [
    { id: 'name', disablePadding: false, label: i18n.t('Driver Name') },
    {
      id: 'unitName',
      numeric: true,
      disablePadding: false,
      label: i18n.t('dashboard.tabs.driverStatus.unit')
    },
    {
      id: 'driveTimeRemaining',
      disablePadding: false,
      label: i18n.t('dashboard.tabs.driverStatus.driveTime')
    },
    {
      id: 'onDutyTimeRemaining',
      numeric: false,
      disablePadding: false,
      label: i18n.t('dashboard.tabs.driverStatus.onDutyTime')
    },
    {
      id: 'dutyCycleRemaining',
      numeric: true,
      disablePadding: false,
      label: i18n.t('dashboard.tabs.driverStatus.dutyCycleRemaining')
    },
    {
      id: 'lastKnownLocation',
      numeric: false,
      disablePadding: false,
      label: i18n.t('dashboard.tabs.driverStatus.lastKnownLocation')
    },
    {
      id: 'lastUpdated',
      numeric: true,
      disablePadding: false,
      label: i18n.t('dashboard.tabs.driverStatus.lastUpdated')
    },
    ...(window?.$environment?.SERVICE_PROVIDER === 'cyntrx'? [{
      id: 'eldState',
      numeric: false,
      disablePadding: false,
      label: i18n.t('dashboard.tabs.driverStatus.eld')
    }]: []),
    ...(window?.$environment?.SERVICE_PROVIDER !== 'riskTheory'? [{ 
      id: 'currentStatus',
      disablePadding: false, 
      label: i18n.t('Logs') 
    }] : []),
  ];
  const information = i18n.t('common.tableEmptyMessage');

  const getELDStatusIcon = (data) => {
    if (data === 'NOT_STARTED' || data === 'TAMPERED' || data === 'DISCONNECTED') {
      return "eld-inActive";
    } else if (data === 'CONNECTED') {
      return "eld-active";
    } else if (data === '') {
      return "eld-loggedOut"
    } else {
      return ""
    }
  }

  const generateRows = () => {
    const updatedList = driverStatusFilteredList.map((item, index) => {
      const rowItem = { ...item };
      rowItem.currentStatus = item.dailyLogId ? (
        <Icon
          src={getImagePath(imageUrls.logsSelectedIcon)}
          tableicon
          onClick={() =>
            history.push(pageUrls.logsView.replace(':id', item.dailyLogId))
          }
        ></Icon>
      ) : '-';
      rowItem.driveTimeRemaining = `${rowItem.driveTimeRemaining} hours`;
      rowItem.onDutyTimeRemaining = `${rowItem.onDutyTimeRemaining} hours`;
      rowItem.dutyCycleRemaining = `${rowItem.dutyCycleRemaining} hours`;
      if(window?.$environment?.SERVICE_PROVIDER === 'cyntrx'){
      rowItem.eldState = (
        <a
          onClick={() => {
            if (rowItem.dailyLogUrl)
              history.push(rowItem.dailyLogUrl.replace('.', '/'));
            else setModal(true);
          }}
        >
          <div className={getELDStatusIcon(rowItem.eldState)} />
        </a>
      );
      }
      return rowItem;
    });
    return updatedList;
  };
  return (
    <>
      <Styled.Wrapper>
        <Styled.SearchWrapper>
          <Search
            default={[...driverStatusList]}
            onChange={(e) => selectChange(e)}
            width="35%"
           // query={'name'}
            // fromData
            id='driver_Status_1'
          />
        </Styled.SearchWrapper>
       {(window.$environment?.SERVICE_PROVIDER !== 'cyntrx' && window.$environment?.SERVICE_PROVIDER !== 'webfleet') && <Styled.MapWrapper>
          <GoogleMap
            type={'driverStatusMap'}
            height={'400px'}
            data={driverStatusList}
          />
        </Styled.MapWrapper>}
        <Styled.TableWrapper>
          <Icon
            src={getImagePath(imageUrls.refreshIcon)}
            onClick={() => changeRequest()}
          />
          <span className="tooltiptext">{i18n.t('common.refresh')}</span>
          <Table
            type="server"
            title=""
            changeRequest={changeRequest}
            baseRequest={request}
            itemsPerPage={10}
            totalRecords={totalElements}
            data={generateRows()}
            columns={[...columns]}
            emptyMessage={information}
            actions={[]}
            i18n={i18n}
            pageinationReset={pagination}
            pagenationClicked={(e) => {
              setPagination(!e);
            }}
          />
        </Styled.TableWrapper>
      </Styled.Wrapper>
      <Modal
      open={modal}
      setOpen={() => setModal(m => !m)}
      withCloseIcon
      alignItemsLeft
      noDataPopup
      data={{
        header: '',
        content: () => <div >No Logs available</div>
      }}
    />
    </>
  );
};

Component.propTypes = {
  list: PropTypes.object.isRequired,
  filterSuccess: PropTypes.func.isRequired
};
