import styled from 'styled-components';
import Parent from './style';
const Wrapper = styled(Parent.Wrapper)`
.fleet-details-name{
  margin-right: 10px;
}
.quickStatsHeadingWrapper{
  margin-bottom: 15px;
  align-items: center;
  .select__control{
    margin-bottom: 0 !important;
  }
}
.reportIconWrapper {
  display:flex;
  gap: 15px;
  align-items: center;
  .statsHeading {
    margin-bottom: 0 !important;
  }
  .MuiButtonBase-root{
    margin-top: 0 !important;
  }
}
.dailyRadiusHeader{
  display:flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  .statsHeading {
    margin-bottom: 0 !important;
  }
}
`;

const Styled = {
  Wrapper,
};
export default Styled;
