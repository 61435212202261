import styled from 'styled-components';
import Parent from '../Base/style';

const InputWrapper = styled(Parent.InputWrapper)`
border: 1px solid #c8c8b5 !important;

.MuiInputBase-input {
    padding: 0;
    font-size: 12px !important;
}
`;
const TextAreaWrapper = styled(Parent.TextAreaWrapper)`
background: #efefef none repeat scroll 0 0;
border: medium none;
font: 18px tahoma;
padding: 12px 15px;
`;
const MainWrapper = styled(Parent.MainWrapper)`
.MuiInputBase-root.MuiInput-root{
    height: 20px;
}
`;
const Styled = { InputWrapper, TextAreaWrapper, MainWrapper };
export default Styled;
