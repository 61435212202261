import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  .CSAForms {
    justify-content: flex-start;
    .vertical-button {
      .MuiBox-root:first-child {
        margin-right: 14px;
      }
      .MuiButtonBase-root {
        margin-top: 29px;
        padding-left: 0;
        padding-right: 0;
        .MuiButton-label {
          padding: 2px 22px;
        }
      }
    }
  }
`;
const TopWrapper = styled(Box)`
  ${({ theme }) => `
  display: flex;
  justify-content: space-between;
  .exportContainer .MuiAvatar-root {
    width: 30px;
    height: 30px;
  }
  @media (max-width: ${theme.breakpoints.values.smd}px)  {
    flex-direction: column;
  }
  `}
`;

const TableWrapper = styled(Box)`
  padding-top: 2.5%;
  .MuiTableHead-root tr:first-child .MuiTableCell-root:nth-child(4){
    border-bottom: 0;
    text-align: center !important;
    background: #000;
  }
  .MuiTableCell-root{
    vertical-align: middle;
  }
  .MuiTableHead-root tr:last-child {
    background-color:transparent;
    .MuiTableCell-root {
      background-color: #1d1d1d99;
    }
  }
`;

const Styled = { Wrapper, TopWrapper, TableWrapper };

export default Styled;
