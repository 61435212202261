import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { string, object } from 'yup';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import RenderComponent from './RenderComponent';
import { hasValue } from '@/utils/utils';
const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));

const ForgotPasswordForm = ({
  Styled,
  apiStatus,
  fetchVehiclePageHistoryMap,
  vehicles,
  callback,
  insuracePortalUserRole,
  fleetCompanyList,
  fetchVehicleRadius,
  driverArray,
  driverIdSuggestion
}) => {
  // const [driverIdSuggestion, setdriverIdSuggestion] = React.useState(
  // React.useEffect(()=>{
  //   if(!driverIdSuggestion.length)
  //   setdriverIdSuggestion(vehicles.vehicleList.map((items) => ({
  //     label: items.unit_id,
  //     value: items.id
  //   })))
  // },[vehicles., driverIdSuggestion])
  const { i18n } = useTranslation();
  const apiId = 'getHistory';
  const hasApiStatus = hasValue(apiStatus.status, 'api', apiId);
  const submitForm = (fields) => {
    let data = {
      id: fields?.id || '',
      dateTimeFrom: moment(fields.dateTimeFrom).format('x'),
      dateTimeTo: moment(fields.dateTimeTo).format('x')
    };
    // let data = {
    //   id: fields?.id || '',
    //   dateTimeFrom: parseInt((new Date(fields.dateTimeFrom).getTime() / 1000).toFixed(0)),
    //   dateTimeTo: parseInt((new Date(fields.dateTimeTo).getTime() / 1000).toFixed(0))
    // };
    fetchVehiclePageHistoryMap({
      data: data,
      apiId,
      callback: callback
    });
  };
  return (
    <Styled.FormWrapper>
      <Form
        type="server"
        direction="column"
        initialValues={{
          id: '',
          dateTimeFrom: '',
          dateTimeTo: ''
        }}
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {
          submitForm(fields);
        }}
        customProps={{
          hasApiStatus,
          vehicles,
          fetchVehicleRadius,
          driverIdSuggestion,
          Styled,
          i18n,
          insuracePortalUserRole,
          fleetCompanyList,
          driverArray
        }}
        validationSchema={object().shape({
          id: string().required(i18n.t('This field is required')),
          dateTimeFrom: string().required(i18n.t('This field is required')).nullable(),
          dateTimeTo: string().required(i18n.t('This field is required')).nullable(),
          ...(insuracePortalUserRole? 
              { 
                companyId: string().required(i18n.t('This field is required')).nullable()
              }: {})
        })}
      />
    </Styled.FormWrapper>
  );
};

export default ForgotPasswordForm;

ForgotPasswordForm.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  fetchVehiclePageHistoryMap: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  vehicles: PropTypes.func.isRequired
};
