import React, { lazy } from 'react';
import { filterList } from '@/utils/utils';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));

const filterAlertListForm = (
  values,
  list,
  filterSuccess,
  setValues,
  i18n,
  setPagination,
  fleetCompanyList,
  setErrorMessage
) => {
  return (
    <Form
      type="client"
      direction="row"
      values={{ ...values }}
      className="filterForm"
      handleOnSubmit={() => {
        if(values.companyId?.value){
          setErrorMessage('')
          setPagination(true);
          const filters = values;
          Object.keys(filters).map((key) => {
            filters[key] === null || (filters[key] === '' && delete filters[key]);
          });
          
            const result = list?.list.filter( (item) => {
              return item?.companyName
                ? item?.companyName === values.companyId?.label
                : '';
            });
          filterSuccess(result);
        }else{
          setErrorMessage('Please select a company')
        }

      }}
      handleOnCancel={(fields) => {
        setPagination(true);
        setErrorMessage('')
        setValues({ companyId: null });
        filterSuccess(list.list);
      }}
      setValues={setValues}
      fields={[
        {
          type: 'select',
          value: '',
          field: 'companyId',
          placeholder: i18n.t('dashboard.tabs.radiusComplianceAlerts.company'),
          label: i18n.t('dashboard.tabs.radiusComplianceAlerts.company'),
          suggestions: fleetCompanyList
        },
        { type: 'submit', value: i18n.t('common.view') },
        { type: 'reset', value: i18n.t('common.clear') }
      ]}
    />
  );
};

export default filterAlertListForm;
