import styled from 'styled-components';
import Parent from '../Base/style';
const TabWrapper = styled(Parent)`
${({ theme }) => `
.MuiTab-wrapper{
    font-size: 24px;
    font-family: ${theme.palette.titleFont};
}
.MuiTab-root:hover {
    border-bottom: 3px solid transparent !important;
    .MuiTab-wrapper {
        color: #000;
    }
}
.MuiTabs-flexContainer .Mui-selected {
    border-bottom: 3px solid #637179;
    :hover {
        border-bottom: 3px solid #637179 !important;
    }
}
.Mui-selected .MuiTab-wrapper {
    color: #000;
}
`}
`;
export default TabWrapper;
