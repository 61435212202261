import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
    
`}
`;
const UploadWrapper = styled(Parent.UploadWrapper)`
${({ theme }) => `
`}
`;
const PopupUpload = styled(Parent.PopupUpload)`
.filepond--wrapper{
    height: 42px !important;
}
.uploadHeading {
    color: #000 !important;
    opacity: .87;
    font-size: 20px;
    text-transform: capitalize;
    padding: 0 !important;
    margin: 0 !important;
    margin-bottom: 10px !important;
}
.verticalExp {
    position: relative;
    label {
        font-size: 16px;
    }
    .MuiSvgIcon-root {
        position: absolute;
        right: 0;
        width: 18px;
        height: 18px;
        color: #767676;
        bottom: 4px;
    }
}
.buttonStyle {
    background: transparent !important;
    color: #007aff !important;
    box-shadow: none;
    &:hover {
        background: transparent !important;
    }
}
`;

const Styled = { Wrapper, UploadWrapper, PopupUpload };
export default Styled;
