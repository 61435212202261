import React from 'react';
import Chart from 'chart.js';
import { fade } from '@material-ui/core/styles/colorManipulator';

export default class BarGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  chartRef = React.createRef();

  componentDidMount() {
    const history = this.props.history;
    const pageurl = this.props.pageUrls;
    const noHyperLink = this.props?.noHyperLink;
    const myChartRef = this.chartRef.current.getContext('2d');
    new Chart(myChartRef, {
      type: 'bar',
      data: {
        labels: this.props.labels,
        datasets: this.props.datasets || [
          {
            label: 'Event Count',
            data: this.props.data,
            backgroundColor: fade(this.props.theme.colors.CHART_BLUE, 0.5),
            borderColor: this.props.theme.colors.CHART_BLUE,
            borderWidth: 1,
            lineTension: 0
          }
        ]
      },
      options: {
        onClick: function (e, c) {
          // Route to new page
          // history.push(pageurl);
          if (noHyperLink === false) {
            window.open(pageurl, '_blank')
          }
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                display: true,
                color: this.props.theme.colors.PRIMARY_BORDER
              },
              ticks: {
                beginAtZero: true,
                steps: 10,
                stepValue: 5,
                ...(this.props.noMaxValue ? {} : { max: (this.props.maxValue) || (Math.max.apply(null, this.props.data) + 4) })

              }
            }
          ],
          xAxes: [
            {
              gridLines: {
                display: true,
                color: this.props.theme.colors.PRIMARY_BORDER
              },
              ticks: {
                fontSize: 10
              }
            }
          ]
        },
        legend: {
          display: this.props.datasets ? true : false,
          labels: {
            boxWidth: 10
          },
          position: 'bottom'
        },
        title: {
          display: true,
          text: this.props.title,
          fontFamily: 'Yanone Kaffeesatz',
          fontSize: 18
        }
      }
    });
  }
  render() {
    return (
      <div>
        <canvas id="barChart" width={this.props.width} height={this.props.height} ref={this.chartRef} />
      </div>
    );
  }
}
