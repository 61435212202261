import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
const TopWrapper = styled(Box)`
  ${({ theme }) => `

  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
  justify-content: space-between;
  @media (max-width: ${theme.breakpoints.values.sm}px) {
    flex-direction: column;
  }
  `}
`;
const IconWrapper = styled.div`
  margin-left: 10px;
  .MuiAvatar-root {
    width: 35px !important;
    height: auto;
    padding-top: 15px;
  }
`;

const ImpExpWrapper = styled.div`
${({ theme }) => `
  flex: 0 0 64%;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  justify-content: flex-end;
  position: relative;
  .vehicleModal {
    z-index: 1;
    height: auto;
    padding: 10px;
    width: 167px;
    display: none;
    position: absolute;
    border: 2px solid ${window?.$environment?.SERVICE_PROVIDER === 'webfleet' || theme.colors.NEW_THEME? theme.colors.SECONDARY_CONTRAST: theme.colors.PRIMARY_BORDER};
    background: ${theme.colors.PRIMARY_MAIN};
    top: 49px;
    ul {
      list-style-type: none;
      padding-inline-start: 10px;
      margin-block-start: 0em;
      margin-block-end: 0em;
      padding-left: 0;
      li {
        padding-bottom: 3px;
        a {
          color: ${theme?.colors?.LINK_COLOR} !important;
        }
      }
    }
    &::before {
      content: '';
      position: absolute;
      width: 0;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
      border-bottom: 6px solid ${theme.colors.PRIMARY_BORDER};
      top: -7.5px;
      right: 7%;
    }
  }
  #addUsers.vehicleModal{
    right: 130px;
    // &::before {
    //   left: 21%;
    // }
  }
  // #addUsers.vehicleModal::before {
  //   left: 21%;
  // }
  #importItems.vehicleModal{
    right: 86px;
    // &::before {
    //   left: 43%;
    // }
  }
  // #importItems.vehicleModal::before {
  //   left: 43%;
  // }
  #ImportVehicles.vehicleModal{
   right: 42px;
    // &::before {
    //   right: 7;
    // }
  }

  // #ImportVehicles.vehicleModal::before {
  //   right: 28%;
  // }
  .MuiAvatar-root {
    width: 35px !important;
    height: auto !important;
  }
  .importUserTemplateContainer {
    .tooltiptext {
      right: -40px;
      width: 70px;
      top: 54px;
    }
  }
  .exportLink{
    cursor: pointer;
    font-size: 14px;
    margin-top: 3px;
    color: ${theme.colors.LINK_COLOR};
  }
  .exportContainer {
    margin-right: 10px ;
    .tooltiptext {
      width: 100px;
      right: 0;
      top: 54px;
    }
    .iconContainer {
      margin-right: 0;
    }
  }
  .tooltiptext {
    // top: 40px;
    top: 54px;
    font-size: 15px;
    right: -70px;
  }
  .iconContainer {
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flexbox;
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
  }
  .importUserContainer {
    .tooltiptext {
      width: 70px;
      right: -40px;
    }
  }
  `}
`;

const SearchWrapper =styled.div`
${({ theme }) => `
  flex: 0 0 35%;
  display: flex;
  position: relative;
  #clearSearch {
    display: none;
  }
  .vehicleModal {
    z-index: 1;
    height: auto;
    padding: 10px;
    width: 167px;
    display: none;
    position: absolute;
    border: 2px solid ${window?.$environment?.SERVICE_PROVIDER === 'webfleet' || theme.colors.NEW_THEME? theme.colors.SECONDARY_CONTRAST: theme.colors.PRIMARY_BORDER};
    background: ${theme.colors.PRIMARY_MAIN};
    top: 49px;
    ul {
      list-style-type: none;
      padding-inline-start: 10px;
      margin-block-start: 0em;
      margin-block-end: 0em;
      padding-left: 0;
      li {
        padding-bottom: 3px;
        a {
          color: ${theme?.colors?.LINK_COLOR} !important;
        }
      }
    }
    &::before {
      content: '';
      position: absolute;
      width: 0;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
      border-bottom: 6px solid ${window?.$environment?.SERVICE_PROVIDER === 'webfleet' || theme.colors.NEW_THEME? theme.colors.SECONDARY_CONTRAST: theme.colors.PRIMARY_BORDER};
      top: -7.5px;
      left: 7%;
    }
  }
  .iconContainer {
    flex: 0 0 10%;
    justify-content: end;
  }
  .iconContainer {
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flexbox;
    display: flex;
    justify-content: flex-start;
    margin-right: 10px;
  }
  .MuiAvatar-root {
    width: 35px !important;
    height: auto !important;
  }
  #addUsers.vehicleModal{
    left: 87%;
    // &::before {
    //   left: 7%;
    // }
  }`}
`;

const ButtonsWrapper = styled(Box)`
  ${({ theme }) => `

  .userBtnWrapper {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
    @media (min-width: ${theme.breakpoints.values.xs}px) and (max-width: ${
    theme.breakpoints.values.md
  }px) {
      flex-direction: column;
    }
  }
  padding-left: 20px;
  padding-top: 2%;
  .MuiButton-root {
    background: ${
      (theme.palette.primary || {}).main || theme.colors.PRIMARY_MAIN
    };
    border: 2px solid ${
      (theme.palette.primary || {}).border || theme.colors.PRIMARY_BORDER
    };
    color:  ${theme.colors.PRIMARY_CONTRAST}
    width: 110px;
    font-size: 18px;
  }
  .MuiBox-root {
    margin-right: 15px;
    @media (min-width: ${theme.breakpoints.values.xs}px) and (max-width: ${
    theme.breakpoints.values.md
  }px) {
      margin-top: 10px;
    }
  }
  .MuiButton-root:hover {
    background: ${
      (theme.palette.primary || {}).main || theme.colors.PRIMARY_MAIN
    };
  }
  .MuiTouchRipple-root {
    display: none;
  }
  .active {
    color: ${theme.colors.SECONDARY_MAIN};
    border: 2px solid ${theme.colors.SECONDARY_MAIN};
  }
  `}
`;

const DocContainer = styled.div`
  ${({ theme, selectedType, selectedindex }) => `

  position: absolute;
  background: ${
    (theme.palette.primary || {}).main || theme.colors.PRIMARY_MAIN
  };
  border: solid 2px
    ${(theme.palette.primary || {}).border || theme.colors.PRIMARY_BORDER};
  top: ${selectedindex ? selectedindex + 'px' : '280px'};
  right: 245px;
  li {
    list-style: none;
    margin: 0 0 5px;
  }
  a {
    color: ${
      selectedType === 'full' ? theme.colors.GREEN : theme.colors.RED_TWO
    } !important;
    font-size: 20px;
  }
  .ToolsOptionClose {
    color: ${
      selectedType === 'full' ? theme.colors.GREEN : theme.colors.RED_TWO
    } !important;
    margin-top: 10px;
    width: 100%;
    background-position: right;
    right: 10px;
  }

  ul {
    width: 175px;
    min-height: 35px;
    padding-inline-start: 15px;
    width: 300px;
    margin-block-start: 0em;
    margin-block-end: 0em;
  }
  cursor: pointer;
  `}
`;
const TableWrapper = styled(Box)`
  ${({ theme, type }) => `

  padding-top: 2%;
  .driverNamePopup {
    color: ${theme.colors.SECONDARY_MAIN};
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
    padding-left: 25px;
  }
  .CSAForms {
    justify-content: flex-start;
    .MuiFormLabel-root{
      padding-bottom: 0px !important;
    }
    .vertical-button {
      .MuiBox-root:first-child {
        margin-right: 14px;
      }
      .MuiButtonBase-root {
        margin-top: 24px;
        padding-left: 0;
        padding-right: 0;
        .MuiButton-label {
          padding: 2px 22px;
        }
      }
    }
  }
  `}
`;
const MiddleWrapper = styled.div`
  ${({ theme }) => `

  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
  justify-content: space-between;
  padding: 3% 0;
  @media (max-width: ${theme.breakpoints.values.md - 1}px) {
    flex-direction: column;
  }
  `}
`;

const MapWrapper = styled.div`
  flex: 0 0 70%;
`;

const TrackWrapper = styled.div`
  ${({ theme }) => `

  @media (max-width: ${theme.breakpoints.values.md - 1}px) {
    margin-top: 4%;
  }
  // height: 400px;
  flex: 0 0 29%;
  width: auto;
  border: 2px solid ${theme.colors.PRIMARY_BORDER};
  .trackVehicleForm {
    padding: 15px 4%;
  }
  `}
`;

const BottomWrapper = styled.div`
  ${({ theme }) => `

  .vehicleTabs {
    .MuiAppBar-colorPrimary {
      background: transparent;
      .MuiTab-wrapper {
        text-transform: none;
        color: ${theme.colors.NORMAL_LINK};
        font-size: 22px;
      }
      .MuiTabs-flexContainer {
        button {
          flex: 0 0 20%;
        }
      }
    }
    .viewAll .iconContainer {
      margin-bottom: -10px;
    }
    #simple-tabpanel-0 {
      .MuiBox-root {
        padding: 0px;
        padding-top: 10px;
      }
    }
    #simple-tabpanel-1 {
      .MuiBox-root {
        padding: 0px !important;
        padding-top: 7px !important;
      }
      .MuiTableCell-alignCenter .MuiBox-root {
        padding-top: 0 !important;
      }
    }
    #simple-tabpanel-2 {
      .MuiBox-root {
        padding: 0px !important;
        padding-top: 7px !important;
      }
    }
  }
  `}
`;

const FormWrapper = styled.div`
  ${({ theme }) => `

  .header {
    color: ${theme.colors.PRIMARY_CONTRAST};
    font-size: 24px;
    margin-bottom: 10px;
  }
  .MuiBox-root {
    flex-direction: column;
  }
  `}
`;

const OptionsWrapper = styled.div`
  .select__control--is-focused,
  .css-8r3dx3-control {
    min-height: 40px !important;
  }
  .react-datepicker-wrapper input,
  .MuiButtonBase-root {
    height: 40px;
  }
  .MuiButtonBase-root {
    margin-top: 0px;
  }
`;
const Styled = {
  Wrapper,
  IconWrapper,
  ImpExpWrapper,
  SearchWrapper,
  TopWrapper,
  ButtonsWrapper,
  TableWrapper,
  DocContainer,
  MiddleWrapper,
  MapWrapper,
  TrackWrapper,
  BottomWrapper,
  FormWrapper,
  OptionsWrapper
};
export default Styled;
