import React, { useState, useEffect, useRef } from 'react';
import SockJsClient from 'react-stomp';

import { getCookie } from '@/services/cookie';
import { COOKIE_AUTH_DETAILS, AUTH_TOKEN } from '@/config/constants/keys';
import { getCompanyId } from '@/utils/utils';

const LocationTracker = ({ onResponse }) => {
  let interval;
  const token = getCookie(COOKIE_AUTH_DETAILS)[AUTH_TOKEN];
  const [isConnected, setIsConnected] = useState(false);
  const clientRef = useRef(null);

  useEffect(() => {
    if (isConnected) {
      clientRef.current.sendMessage(`/app/location/subscribe/${getCompanyId()}/50`)
      interval = setInterval(() => {
        clientRef.current.sendMessage(`/app/location/subscribe/${getCompanyId()}/50`)
      }, 10000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isConnected])

  return (
    <div>
      <SockJsClient
        ref={(client) => {
          clientRef.current = client;
        }}
        topics={['/user/topic/vehicle/location']}
        url={`${window.$environment.BE_BASE_URL}/websocket-gs?token=${token}`}
        onMessage={(msg) => {
          onResponse(msg || [])
        }}
        onDisconnect={() => {
          setIsConnected(false)
        }}
        onConnect={() => {
          setIsConnected(true)
        }}
      />
    </div>
  );
}

export default LocationTracker;