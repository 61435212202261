import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import Title from '../../../components/Title';
import Component from './Component';

const Users = ({ auth, history, ...rest }) => {
  return (
    <>
      {/* <Title header={'Users'} /> */}
      <div
        style={{
          minHeight: 'calc(100vh - 181px)',
          padding: '40px',
          backgroundColor: 'colors.BLACK'
        }}
      >
        <Component {...rest} />
      </div>
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests
  };
};
const mapDispatch = (dispatch) => {
  return {
    dutyStatusReport: (data) => dispatch.reports.dutyStatusReport(data),
    driverPerformanceReport: (data) =>
      dispatch.reports.driverPerformanceReport(data),
    editedDataReport: (data) => dispatch.reports.editedDataReport(data),
    drivingEventReport: (data) => dispatch.reports.drivingEventReport(data),
    eldDisconnectReport: (data) => dispatch.reports.eldDisconnectReport(data),
    odometerJumpReport: (data) => dispatch.reports.odometerJumpReport(data),
    malfunctionReport: (data) => dispatch.reports.malfunctionReport(data),
    unassignedDrivingReport: (data) =>
      dispatch.reports.unassignedDrivingReport(data),
    violationReport: (data) => dispatch.reports.violationReport(data),
    hosViolationsReport: (data) => dispatch.reports.hosViolationsReport(data),
    rejectedEditsReport: (data) => dispatch.reports.rejectedEditsReport(data)
  };
};

export default connect(mapState, mapDispatch)(Users);

Users.propTypes = {
  auth: PropTypes.object.isRequired,
  apiStatus: PropTypes.object.isRequired,
  dutyStatusReport: PropTypes.func.isRequired,
  driverPerformanceReport: PropTypes.func.isRequired,
  editedDataReport: PropTypes.func.isRequired,
  drivingEventReport: PropTypes.func.isRequired,
  eldDisconnectReport: PropTypes.func.isRequired,
  odometerJumpReport: PropTypes.func.isRequired,
  malfunctionReport: PropTypes.func.isRequired,
  unassignedDrivingReport: PropTypes.func.isRequired,
  violationReport: PropTypes.func.isRequired,
  hosViolationsReport: PropTypes.func.isRequired,
  rejectedEditsReport: PropTypes.func.isRequired
};
