import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const ParentWrapper = styled(Box)``
const Wrapper = styled(Box)`
${({ theme, viewOnly }) => `
padding: 0;
form {
  .MuiInputBase-root.Mui-disabled{
    border-bottom: none !important;
  }
  .MuiInputBase-input.Mui-disabled{
    opacity: 1;
  }
  padding-bottom: 2%;
  padding-top: 1%;
  justify-content: flex-start;
  .MuiFormLabel-root {
      font-size: 15px;
  }
  .editForms {
    width: 30%;
    flex-direction: column;
    margin: 0 1% 20px 2%;
  }
  .annotationField{
    width: 250px;
    height: 45px !important;
    margin-left: 22px;
    textarea {
    resize: none;
    height: 45px !important;
    padding-left: 0;
    }
    textarea::-webkit-scrollbar {
      display: none;
    }
  }
}`}
`;
const ChartWrapper = styled(Box)`
  ${({ theme }) => `
  `}
`;
const LabelWrap = styled(Box)`
${({ theme }) => `

  `}
`;
const StatusModifier = styled(Box)`
`;
const CancelSaveWrapper = styled(Box)`
  ${({ theme }) => `
  padding: 0 1%;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex; 
  justify-content: flex-end;
  @media (max-width: ${theme.breakpoints.values.sm}px) {
    justify-content: center;
  }
  width:100%;
  &:first-child {
    margin-right: 8px;
  }
  .MuiBox-root:first-child {
    margin-right: 3px;
  }
  .MuiBox-root {
    width: auto;
    min-width: 117px;
    margin-right:0;
  }
  .MuiBox-root .MuiButtonBase-root[type="reset"]{
    background: ${theme.colors.WHITE} none repeat scroll 0 0 !important;
    border:0;
    color: #343E48 !important;
    box-shadow: none;
    font-weight: normal;
    border-radius: 0;
  }
  `}
`;
const Editnodata = styled(Box)`
  ${({ theme }) => `
  padding-left: 21%;
  .messageSection{
    color: ${theme.colors.LINK_COLOR};
    padding-bottom: 11px;
    padding-top: 10px;
    font-size: 20px;
  }
  
  `}
`;
const Styled = { Wrapper, ChartWrapper, LabelWrap, StatusModifier, CancelSaveWrapper, Editnodata, ParentWrapper };
export default Styled;
