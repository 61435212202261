import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';

import {
  UI_DATE_LOWER_FORMAT,
  UI_DATE_FORMAT
} from '@/config/constants/static';
import { pageUrls } from '@/config/constants/keys';
import { hasValue, generateDropdownOptions, getDateLocale } from '@/utils/utils';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Input = lazy(() => import(`@/components/UI/Input/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));
const Select = lazy(() => import(`@/components/UI/Select/${layout}`));
const Upload = lazy(() => import(`@/components/FileUpload/${layout}`));
const SubHeader = lazy(() => import(`@/components/SubHeader/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const RenderComponent = ({
  values,
  handleEdit,
  errors,
  isNotValid,
  validateForm,
  submitAction,
  onClear,
  customProps: {
    i18n,
    Styled,
    history,
    hasDutyStatusApiStatus,
    regions,
    fetchTerminals,
    fetchDepartments,
    fetchSubDepartments,
    setDutyStatusInitialValues
  }
}) => {
  return (
    <>
      <FormStyle.default.FieldsWrapper className="fieldWrapper">
        <Label htmlFor="startDate">{i18n.t('common.dateFrom')}</Label>
        <FormStyle.default.TextWrapper>
          <Field
            name="startDate"
            onChange={(event) => {
              handleEdit(event, { field: 'startDate', type: 'date' });
              setDutyStatusInitialValues({
                ...values,
                startDate: moment(event).format(UI_DATE_FORMAT)
              });
            }}
            as={ReactDatePicker}
            locale={getDateLocale()}
            placeholderText={i18n.t('common.datePicker')}
            value={values.startDate}
            showPopperArrow={false}
            showTimeSelect={false}
            showDisabledMonthNavigation
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            maxDate={values.endDate ? new Date(values.endDate) : new Date()}
          />
          {errors['startDate'] && !isNotValid && (
            <Message type="error" message={errors['startDate']} />
          )}
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.FieldsWrapper className="fieldWrapper">
        <Label htmlFor="endDate">{i18n.t('common.dateTo')}</Label>
        <FormStyle.default.TextWrapper>
          <Field
            name="endDate"
            onChange={(event) => {
              handleEdit(event, { field: 'endDate', type: 'date' });
              setDutyStatusInitialValues({
                ...values,
                endDate: moment(event).format(UI_DATE_FORMAT)
              });
            }}
            as={ReactDatePicker}
            locale={getDateLocale()}
            placeholderText={i18n.t('common.datePicker')}
            value={values.endDate}
            showPopperArrow={false}
            showTimeSelect={false}
            maxDate={new Date()}
            showDisabledMonthNavigation
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            minDate={
              values.startDate ? new Date(values.startDate) : new Date('01/01/2000')
            }
          />
          {errors['endDate'] && !isNotValid && (
            <Message type="error" message={errors['endDate']} />
          )}
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      {window?.$environment?.SERVICE_PROVIDER !== 'webfleet' &&
      (<>
        <FormStyle.default.FieldsWrapper className="fieldWrapper">
          <Label htmlFor="region">{i18n.t('common.region')}</Label>
          <FormStyle.default.TextWrapper>
            <Field
              name="region"
              onChange={(event) => {
                fetchTerminals({
                  apiId: 'fetchTerminals',
                  data: { id: event?.value || '' }
                });
                handleEdit(event, { field: 'regionId' });
                handleEdit('', { field: 'terminalId' });
                handleEdit('', { field: 'departmentId' });
                handleEdit('', { field: 'subDepartmentId' });
                setDutyStatusInitialValues({
                  ...values,
                  regionId: event,
                  terminalId: '',
                  departmentId: '',
                  subDepartmentId: ''
                });
              }}
              as={Select}
              // disabled={disabledCriteria?.regionId}
              placeholder={i18n.t('common.pleaseSelect')}
              value={values.regionId}
              suggestions={generateDropdownOptions(
                regions.data.regions,
                'regionName',
                'id'
              )}
              isClearable
            />
          </FormStyle.default.TextWrapper>
        </FormStyle.default.FieldsWrapper>
        <FormStyle.default.FieldsWrapper className="fieldWrapper">
          <Label htmlFor="terminal">{i18n.t('common.terminal')}</Label>
          <FormStyle.default.TextWrapper>
            <Field
              name="terminal"
              onChange={(event) => {
                fetchDepartments({
                  apiId: 'fetchDepartments',
                  data: { id: event?.value || '' }
                });
                handleEdit(event, { field: 'terminalId' });
                handleEdit('', { field: 'departmentId' });
                handleEdit('', { field: 'subDepartmentId' });
                setDutyStatusInitialValues({
                  ...values,
                  terminalId: event,
                  departmentId: '',
                  subDepartmentId: ''
                });
              }}
              as={Select}
              // disabled={disabledCriteria?.regionId}
              placeholder={i18n.t('common.pleaseSelect')}
              value={values.terminalId}
              suggestions={generateDropdownOptions(
                regions.data.terminals,
                'terminalName',
                'terminalId'
              )}
              isClearable
            />
          </FormStyle.default.TextWrapper>
        </FormStyle.default.FieldsWrapper>

        <FormStyle.default.FieldsWrapper className="fieldWrapper">
          <Label htmlFor="department">{i18n.t('common.department')}</Label>
          <FormStyle.default.TextWrapper>
            <Field
              name="department"
              onChange={(event) => {
                fetchSubDepartments({
                  apiId: 'fetchDSubDepartments',
                  data: { id: event?.value || '' }
                });
                handleEdit(event, { field: 'departmentId' });
                handleEdit('', { field: 'subDepartmentId' });
                setDutyStatusInitialValues({
                  ...values,
                  departmentId: event,
                  subDepartmentId: ''
                });
              }}
              as={Select}
              // disabled={disabledCriteria?.regionId}
              placeholder={i18n.t('common.pleaseSelect')}
              value={values.departmentId}
              suggestions={generateDropdownOptions(
                regions.data.departments,
                'departmentName',
                'departmentId'
              )}
              isClearable
            />
          </FormStyle.default.TextWrapper>
        </FormStyle.default.FieldsWrapper>

        <FormStyle.default.FieldsWrapper className="fieldWrapper">
          <Label htmlFor="location">{i18n.t('common.location')}</Label>
          <FormStyle.default.TextWrapper>
            <Field
              name="location"
              onChange={(event) => {
                handleEdit(event, { field: 'subDepartmentId' });
                setDutyStatusInitialValues({
                  ...values,
                  subDepartmentId: event
                });
              }}
              as={Select}
              // disabled={disabledCriteria?.regionId}
              placeholder={i18n.t('common.pleaseSelect')}
              value={values.subDepartmentId}
              suggestions={generateDropdownOptions(
                regions.data.subDepartments,
                'subDepartmentName',
                'subDepartmentId'
              )}
              isClearable
            />
          </FormStyle.default.TextWrapper>
        </FormStyle.default.FieldsWrapper>
      </>)
      }
      <FormStyle.default.ButtonsWrapper className="buttonWrapper">
        <Button
          type="submit"
          className="buttonStyle"
          label={i18n.t('common.submit')}
          onClick={(e, d) => {
            const validateResponse = validateForm();
            validateResponse.then((data) => {
              submitAction(data);
            });
          }}
        />
      </FormStyle.default.ButtonsWrapper>
    </>
  );
};

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  customProps: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};

export default RenderComponent;
