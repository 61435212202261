import React, { lazy, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { useHistory } from 'react-router-dom';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Modal = require(`@/components/UI/Modal/${layout}`).default;
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const SubHeader = lazy(() => import(`@/components/SubHeader/${layout}`));
const PercentageBarGraph = lazy(() =>
  import(`@/components/UI/Graph/PercentageBarGraph/${layout}`)
);
const ChoroPleth = lazy(() =>
  import(`@/components/UI/Graph/Choropleth/${layout}`)
);

const OperatingTerritory = ({ i18n, iftaStats, radiusOverages, operatingTerritoryDescription, companyId, getVehicles }) => {
  const [total, setTotal] = useState(0);
  const history = useHistory();

  useEffect(() => {
    if (radiusOverages) {
      setTotal(radiusOverages.reduce((sum, ele) => {
        return sum + ele.vehicleCount
      }, 0))
    }
  }, [radiusOverages])

  const getPercentage = (count) => {
    return `${(count / total) * 100}%`
  }
  const [openModalVehicleName, setModalVehicleName] = useState(false);
  const [ModalVehicleNameData, setModalVehicleNameData] = useState({
    header: '',
    content: () => {
      return <> </>;
    }
  });

  const openVehicleNamePopupModal = (type, res, req) => {
    setModalVehicleName(true);
    return setModalVehicleNameData({
      content: () => {
        return (
          <div className="driverNameModal">
            <div className="headWrapper">{i18n.t('Vehicles of Radius ')} {res?.complianceName}</div>
            <ul className="scrollContent">
              {res?.vehicles?.map((items, index) => {
                return <li key={index}><a className='vehicleLink' href={`/vehicle/edit/${items.idPub}`}>{items.name}</a></li>;
              })}
            </ul>
          </div>
        );
      }
    });
  };

  return (
    <div className="grid-item">
      <div className='dailyRadiusHeader'>
        <SubHeader
          variant="h1"
          className="statsHeading"
          text={i18n.t('scoreCard.radiusStats')}
        />
        <Button
          type="submit"
          label={i18n.t('Track Vehicle')}
          onClick={(e, d) => {
            history.push(`/vehicle/list`)
          }}
        />
      </div>

      {/* <Tooltip mouseEnterDelay={0.5} placement='top' overlayStyle={{ maxWidth: "300px", opacity: 1 }} overlay={<span>{operatingTerritoryDescription?.overageStatus || 'NA'}</span>}> */}
      <div className="teritory-details">
        {radiusOverages && radiusOverages.map(item => {
          return (<PercentageBarGraph onClick={() => {
            getVehicles({
              apiId: 'getVehiclesForRadius',
              data: {
                complianceEnum: item.complianceEnum,
                companyId: companyId
              },
              callback: openVehicleNamePopupModal
            });
          }} label={item?.complianceName} percentage={getPercentage(item.vehicleCount || 0)} count={item.vehicleCount || 0} />)
        }
        )}
      </div>
      {/* </Tooltip> */}
      {/* <div style={{marginTop: '100px'}}>
       
      </div> */}

      {/* <Tooltip mouseEnterDelay={0.5} placement='top' overlayStyle={{ maxWidth: "300px", opacity: 1 }} overlay={<span>{operatingTerritoryDescription?.isftaStatus || 'NA'}</span>}>
        <div>
          <ChoroPleth data={iftaStats?.ifta} i18n={i18n} />
        </div>
      </Tooltip> */}
      <Modal
        open={openModalVehicleName}
        setOpen={setModalVehicleName}
        data={ModalVehicleNameData}
        deletePopup
        withCloseIcon
        alignItemsLeft
        width={'260px'}
      />
    </div>
  );
};

export default OperatingTerritory;

OperatingTerritory.defaultProps = {};

OperatingTerritory.propTypes = {
  radiusOverages: PropTypes.object.isRequired
};
