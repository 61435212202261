import React, { lazy } from 'react';

import { getImagePath } from '@/utils/utils';
import { imageUrls } from '@/config/constants/keys';
const layout = window?.$environment?.CURRENT_LAYOUT;

const Search = lazy(() => import(`@/components/UI/SimpleSearch/${layout}`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));

const TopActions = ({
  Styled,
  usDotManagement,
  i18n,
  options,
  filterSuccess,
  setPagination
}) => {
  const selectChange = (e) => {
    filterSuccess(e);
    setPagination(true);
  };

  return (
    <Styled.TopWrapper>
      <Styled.SearchWrapper>
        {window.$environment.CURRENT_LAYOUT === 'SpireonNew' ? null: <Search
          onChange={(e) => selectChange(e)}
          default={usDotManagement?.list}
          fromData
          placeHolder={i18n.t('usDotManagement.search')}
          query={[
            'dotNumber',
            'carrierName',
            'mainAddress',
            'state',
            'city',
            'zipCode'
          ]}
          id="usDotManagement"
        ></Search>}
      </Styled.SearchWrapper>
      {layout === 'OldUi' ? (
        <Styled.AddWrapper>
        <Button
          label={i18n.t('usDotManagement.addDot')}
          type={'submit'}
          onClick={() => {
            options('');
          }}
          className="AddButton"
        />
        </Styled.AddWrapper>
      ) : (
        <Icon
          src={getImagePath(imageUrls.addVehicle)}
          onClick={() => options('')}
          tooltip={i18n.t('common.add')}
          className="AddButton"
        />
      )}
    </Styled.TopWrapper>
  );
};
export default TopActions;
