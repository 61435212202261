import styled from 'styled-components';
import Parent from './style';
const Wrapper = styled(Parent.Wrapper)`
.MuiAvatar-root { 
  width: 20px;
  height: 20px !important;
}
`;
const TopWrapper = styled(Parent.TopWrapper)`
 
`;
const IconWrapper = styled(Parent.IconWrapper)`
margin-left: 2px;
align-self: center;
`;

const ImpExpWrapper = styled(Parent.ImpExpWrapper)`
flex: 0 0 58%;
.importUserTemplateContainer{
.MuiAvatar-root { 
  width: 16px !important;
  height: 16px !important;
}
}
.exportContainer .MuiAvatar-root { 
  width: 16px !important;
  height: 16px !important;
}
.importUserContainer .csv-reader-input .tooltiptext{
  ${window?.$environment?.clutchNewUI && `font-weight: bold;`}
}
`;

const SearchWrapper = styled(Parent.SearchWrapper)`

`;

const ButtonsWrapper = styled(Parent.ButtonsWrapper)`
${({theme})=>`
.MuiButton-root {
  font-size: 12px !important;
  background: transparent !important;
  ${window?.$environment?.clutchNewUI || theme.colors.NEW_THEME && 
    `border: 2px solid ${theme.colors.ACTION_BORDER}};`
  }
`}
`;

const DocContainer = styled(Parent.DocContainer)`
border: 1px solid rgba(255,255,255,0.66) !important;
a {
  font-size: 14px;
  text-decoration: none;
}
`;
const TableWrapper = styled(Parent.TableWrapper)`
.MuiGrid-align-items-xs-center {
  justify-content: center;
}
table {
.MuiTableHead-root .MuiTableRow-root .MuiTableCell-root:nth-child(7) {
  // text-align: initial !important;
}
}
`;
const Styled = {
  Wrapper,
  IconWrapper,
  ImpExpWrapper,
  SearchWrapper,
  TopWrapper,
  ButtonsWrapper,
  TableWrapper,
  DocContainer
};
export default Styled;
