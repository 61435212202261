import React, { useState, lazy, useEffect } from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import { getImagePath } from '../../../utils/utils';
import { Link as LinkLi } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { imageUrls, pageUrls } from '@/config/constants/keys';

const layout = window?.$environment?.CURRENT_LAYOUT;

const Breadcrumb = lazy(() => import(`@/components/Breadcrumb`));
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const Modal = lazy(() => import(`@/components/UI/Modal/${layout}`));

const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Breadcrumb filters={filters} />
      <Component {...contentProps} />
    </Box>
  );
};
export default Index;
const Component = ({
  callback,
  history,
  Styled,
  deleteCustomDvir,
  i18n,
  customDvir,
  enableCustomDvir
}) => {
  const [ModalListObject, setModalListObject] = useState({
    header: '',
    content: () => {}
  });
  const [open, setOpen] = useState(false);
  const closeModal = (event) => {
    setOpen(false);
  };
  const clickedModal = (e) => {
    setOpen(true);
  };
  const columns = [
    {
      id: 'formName',
      numeric: false,
      disablePadding: false,
      label: i18n.t('inspections.formName'),
      needSorting: true,
      width: '30%'
    },
    {
      id: 'assetCount',
      numeric: true,
      disablePadding: false,
      label: i18n.t('inspections.assetsCount'),
      needSorting: true,
      width: '30%'
    },
    {
      id: 'unitType',
      numeric: false,
      disablePadding: false,
      label: i18n.t('inspections.unitType'),
      needSorting: true,
      width: '30%'
    },
    {
      id: 'actions',
      label: ''
    }
  ];

  const generateRows = () => {
    return customDvir.data;
  };

  const information = 'common.tableEmptyMessage';

  const addVehicle = () => {
    let dv = document.getElementById('addUsers');
    if (dv.style.display === 'block') {
      dv.style.display = 'none';
    } else {
      dv.style.display = 'block';
    }
  };

  useEffect(()=>{
    document.addEventListener('click', (event) => {
      if (
        event?.target?.parentElement?.parentElement?.id !== 'addVehiclesTarget'
      ) {
        let dv = document.getElementById('addUsers');
        if (dv) dv.style.display = 'none';
      }
    });
  },[])
  return (
    <Styled.Wrapper>
        <Styled.LinkWrapper>
            <div style={{position: "relative"}}>
                <Icon
                    className="addNewForm"
                    src={getImagePath(imageUrls.addVehicle)}
                    onClick={() => addVehicle()}
                    tooltip={i18n.t('common.add')}
                    id="addVehiclesTarget"
                  />
                  <div id="addUsers" className="vehicleModal">
                    <ul>
                      <li>
                        <LinkLi
                          component={Link}
                          variant="body2"
                          to={`${pageUrls.customDvirEnabled}/add`}
                          onClick={() => {
                            closeModal();
                          }}
                        >
                          {i18n.t('common.vehicle')}
                          {/* {i18n.t('insections.addNewVehicleForm')} */}
                        </LinkLi>
                      </li>
                      <li>
                        <LinkLi
                          component={Link}
                          variant="body2"
                          to={`${pageUrls.customDvirEnabled}/addTrailer`}
                          onClick={() => {
                            closeModal();
                          }}
                        >
                          {i18n.t('common.trailer')}
                          {/* {i18n.t('inspections.addNewTrailerForm')} */}
                        </LinkLi>
                      </li>
                    </ul>
                </div>
           </div>
      </Styled.LinkWrapper>

      <Table
        type="client"
        defaultSortBy={{ sortBy: 'formName', order: 'desc' }}
        title=""
        columns={[...columns]}
        data={generateRows()}
        emptyMessage={information}
        itemsPerPage={15}
        actions={[
          {
            icon: (row, index) => {
              return (
                <Icon
                  src={getImagePath(imageUrls.edit)}
                  onClick={() => {
                    if(row.unitType == 'TRAILER') {
                      history.push(`${pageUrls.customDvirEnabled}/editTrailer/${row.id}`);
                    } else {
                      history.push(`${pageUrls.customDvirEnabled}/edit/${row.id}`);
                    }
                  }}
                />
              );
            }
          },
          {
            icon: (row, index) => {
              return (
                <Icon
                  src={getImagePath(imageUrls.deleteIcon)}
                  onClick={() => {
                    setModalListObject({
                      content: () => {
                        return (
                          <div className="deleteParent">
                            <div className="imageSection">
                              <img
                                src={getImagePath(imageUrls.confirmIcon)}
                                alt="Are you sure"
                              />
                            </div>
                            <h2 style={{textAlign: 'center'}}> {i18n.t('common.sureConfirm')} </h2>
                            <div className="deletePopupBtn">
                              <div className="cancelBtn">
                                <Button
                                  label={i18n.t('common.cancel')}
                                  type={'reset'}
                                  onClick={() => {
                                    closeModal();
                                  }}
                                ></Button>
                              </div>
                              <div className="deletebtn">
                                <Button
                                  label={i18n.t('common.deletePopup')}
                                  onClick={() => {
                                    deleteCustomDvir({
                                      apiId: 'deleteCustomDvir',
                                      data: {
                                        id: row.id
                                      },
                                      callback: callback
                                    });
                                    closeModal();
                                  }}
                                ></Button>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    });

                    clickedModal();
                  }}
                />
              );
            }
          },
          {
            icon: (row, index) => {
              return (
                <Icon
                  src={getImagePath(
                    row.isEnabled ? imageUrls.toggleOn : imageUrls.toggleOff
                  )}
                  onClick={() => {
                    setModalListObject({
                      content: () => {
                        return (
                          <div className="deleteParent">
                            <div className="imageSection">
                              <img
                                src={getImagePath(imageUrls.confirmIcon)}
                                alt="Are you sure"
                              />
                            </div>
                            <h2 style={{textAlign: 'center'}}> {i18n.t('common.sureConfirm')} </h2>
                            <div className="deletePopupBtn">
                              <div className="cancelBtn">
                                <Button
                                  label={i18n.t('common.cancel')}
                                  type={'reset'}
                                  onClick={() => {
                                    closeModal();
                                  }}
                                ></Button>
                              </div>
                              <div className="deletebtn">
                                <Button
                                  label={i18n.t(
                                    row.isEnabled
                                      ? 'common.disablePopup'
                                      : 'common.enablePopup'
                                  )}
                                  onClick={() => {
                                    enableCustomDvir({
                                      apiId: 'enableCustomDvir',
                                      data: {
                                        id: row.id,
                                        isEnable: row.isEnabled ? false : true
                                      },
                                      callback: callback
                                    });
                                    closeModal();
                                  }}
                                ></Button>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    });

                    clickedModal();
                  }}
                />
              );
            }
          }
        ]}
        i18n={i18n}
      />
      <Modal open={open} setOpen={setOpen} data={ModalListObject} />
    </Styled.Wrapper>
  );
};

Component.propTypes = {
  history: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired
};
