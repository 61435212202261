import React, { lazy, useState } from 'react';
import ViewForm from './Form';

import {
  getQueryStringKeys,
  objectArrayFromDiffFields,
  objectArrayFromMultipleFields
} from '@/utils/utils';
const layout = window?.$environment?.CURRENT_LAYOUT;

const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Upload = lazy(() => import(`@/components/modules/DocumentUpload`));
const TopActions = ({
  history,
  apiStatus,
  fetchDailyDocuments,
  callback,
  Styled,
  i18n,
  setModalObject,
  saveDocument,
  closeModal,
  clickedModal,
  dailyDocuments,
  setOpen,
  filterByDriverAndDate,
  setwithCloseIcon,
  setbackgroundModal,
  pagination,
  setPagination
}) => {
  const uploadApi = 'dailyDocumentUploadApi';
  const [currentItem] = useState({});
  const driver = getQueryStringKeys(history.location.search, 'driver');
  const day = getQueryStringKeys(history.location.search, 'day');
  const types = objectArrayFromDiffFields(
    dailyDocuments?.documentTypesList,
    'name',
    'fullName'
  );
  const drivers = objectArrayFromMultipleFields(
    dailyDocuments?.driversList,
    'id',
    ['firstName', 'lastName']
  );
  return (
    <Styled.DailyLogContainer>
      <div className="viewContainer">
        <ViewForm
          apiStatus={apiStatus}
          fetchDailyDocuments={fetchDailyDocuments}
          history={history}
          callback={callback}
          drivers={drivers}
          currentDriver={driver}
          currentDate={day}
          Styled={Styled}
          filterByDriverAndDate={filterByDriverAndDate}
          setPagination={setPagination}
        />
        <Button
          className="buttonBulkUploadDoc"
          type={'submit'}
          label={i18n.t('dailyDocuments.uploadNewDoc')}
          onClick={() => {
            setbackgroundModal(false);
            setwithCloseIcon(true);
            setModalObject({
              header: '',
              content: () => {
                return (
                  <Upload
                    currentItem={currentItem}
                    closeModal={closeModal}
                    saveDocument={saveDocument}
                    uploadApi={uploadApi}
                    callback={callback}
                    parent="dailyDocuments"
                    currentDriver={driver}
                    types={types}
                    drivers={drivers}
                  />
                );
              }
            });
            clickedModal();
          }}
        />
      </div>
    </Styled.DailyLogContainer>
  );
};
export default TopActions;
