import styled from 'styled-components';

const UploadWrapper = styled.div`
  ${({ theme, allowMultiple, normal, fileChosen }) => `
  font-family: ${(theme.palette || {}).fontFamily};
  .filepond--root {
    width: '100%';
    height: ${allowMultiple ? 'fit-content' : normal ? '40px' : '140px'};
    margin: 0 auto;
    font-family: ${(theme.palette || {}).fontFamily};
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  }
  .filepond--wrapper {
    border: 2px solid ${theme.colors.LIGHT_BORDER} !important;
    background: ${theme.colors.BACKGROUND};
    height: 45px;
  }
  .filepond--file-status-sub {
    opacity: 0 !important;
  }
  .filepond--file [data-align*=left] {
    left: .3em;
  }
  .filepond--file{
    [data-filepond-item-state*=invalid] .filepond--file-info {
      margin-left: 2.25em;
    }
    .filepond--file-status {
      margin-right: 2em;
      width: 0px;
    }
  }
  
  .filepond--drop-label {
    color: #aaaaaa;
    font-family: ${(theme.palette || {}).fontFamily};
    font-size: 17px;
  }
  .filepond--drop-label label {
    line-height: 1;
  }
  .filepond--label-action {
    text-decoration: none;
  }
  .filepond--file-info {
    margin: ${
      allowMultiple ? '0px 3.5em 0 0 !important;' : ' 0 -1.5em 0 0.5rem !important'
    };
  }
  .filepond--file-status {
    display: 'block';
    top: 40px;
    position: relative;
  }
  .filepond--panel-root {
    border-radius: 0px !important;
    color: #aaaaaa;
    background: ${theme.colors.BACKGROUND} !important;
  }
  .filepond--item {
    top: ${allowMultiple || normal ? '2px' : '30px'} !important;
  }
  .filepond--drop-label {
    padding-top: ${allowMultiple || normal ? '0px' : '50px'};
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    justify-content: left;
    padding-left: ${allowMultiple ? '25px' : '15px'};
  }
  .filepond--list-scroller {
    margin-top: ${normal ? '0px !important' : '1em'};
    height: ${normal ? '50px !important' : '7px'};
  }
  .filepond--item-panel {
    background-color: transparent;
    border: none;
  }
  .filepond--file {
    padding: ${normal ? '0' : '.5625em'};
  }
  .filepond--root .filepond--drop-label {
    min-height: ${normal ? '40px !important' : '4.75em'};
  }
  .filepond--browser.filepond--browser {
    z-index: ${!fileChosen ? '100' : '-99'};
    height: 40px;
  }
  .filepond--drop-label.filepond--drop-label label {
    padding: ${normal ? '5px !important' : '.5em'};
    opacity: 0.5;
  }
  .filepond--file-info .filepond--file-info-main {
    font-family: ${(theme.palette || {}).fontFamily};
    font-size: 15px;
    color: #73787d;
  }
  .filepond--file-status-main {
    font-size: 16px !important;
    margin-top: -26px;
    color: ${
      theme.palette.error.main ? theme.palette.error.main : theme.colors.RED
    };
  }
  [data-filepond-item-state*='invalid'] .filepond--item-panel {
    background-color: transparent;
  }
  `}
`;
export default UploadWrapper;
