import { addLogApi } from '../../../../config/apis';

const initialState = {};

export const addLog = {
  state: { ...initialState },
  reducers: {
    addSuccess(state, payload) {
      return {};
    },
    addFailed() {
      return { ...initialState };
    }
  },
  effects: (dispatch) => ({
    async handleAddLog(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: addLogApi,
            data: payload.data.data
          },
          id: payload.apiId
        });
        if (response?.type === 'error') {
          payload.callback('error', response.message);
        } else if (response?.type === 'success') {
          this.addSuccess(response);
          payload.callback('success', response.message);
        }
      } catch (error) {
        this.addFailed();
      }
    }
  })
};
