import React, { lazy, useEffect } from 'react';
import { UI_DATE_LOWER_FORMAT } from '@/config/constants/static';
import { filterList, filterWithDateRange } from '@/utils/utils';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));

const FilterLogsListForm = (
  {values,
  udLogList,
  handleClear,
  udFilterSuccess,
  setValues,
  i18n,
  setPagination,
  Styled,
  selectedDriverProfile}
) => {
  const formId = 'filterUdLogsListForm';

  useEffect(() => {
    if (values && Object.keys(values).length > 0) {      
      handleFilter()
    }
  }, [JSON.stringify(udLogList?.filteredList)])

  const handleFilter = () => {
    const filters = values;
    Object.keys(filters).map(
      (key) =>
        filters[key] === null ||
        (filters[key] === '' && delete filters[key])
    );
    let result = filters?.driver_name
      ? filterList(udLogList.list, 'driver_name', values.driver_name)
      : udLogList.list;
    if (filters?.dateFrom || filters?.dateTo) {
      result = filterWithDateRange(
        result,
        filters?.dateFrom,
        filters?.dateTo
      );
    }

    udFilterSuccess(result);
    setPagination(true);
  }
  return (
    <Form
      id={formId}
      direction="row"
      type="client"
      values={{ ...values }}
      className="filterForm"
      handleOnSubmit={() => {
        handleFilter()
        handleClear()
      }}
      handleOnCancel={(fields) => {
        setValues({
          driver_name: 'Unidentified Driver',
          dateFrom: '',
          dateTo: ''
        });
        udFilterSuccess(udLogList.list);
        setPagination(true);
        handleClear()
      }}
      setValues={setValues}
      fields={[
        {
          type: 'text',
          value: i18n.t('Unidentified Driver'),
          field: 'driver_name',
          placeholder: i18n.t('common.driver'),
          label: i18n.t('common.driver'),
          disabled: true,
          defaultValue: i18n.t('Unidentified Driver')
        },
        {
          type: 'date',
          value: '',
          field: 'dateFrom',
          placeholder: UI_DATE_LOWER_FORMAT,
          label: i18n.t('common.dateFrom'),
          isTimeNeeded: false
        },
        {
          type: 'date',
          value: '',
          field: 'dateTo',
          placeholder: UI_DATE_LOWER_FORMAT,
          label: i18n.t('common.dateTo'),
          isTimeNeeded: false
        },

        { type: 'submit', value: i18n.t('common.view') },
        { type: 'reset', value: i18n.t('common.clear') }
      ]}
    />
  );
};

export default FilterLogsListForm;
