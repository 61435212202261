/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Styled from './style';
import Button from '@material-ui/core/Button';
import { saveAs } from 'save-as'
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils'

import DotPdf from '@/assets/clutch/pdf/ClutchDOTCard.pdf'
const DownloadManuals = ({ history, loginInfo }) => {
  const [username, setUserName] = useState('');
  const downloadAll = () =>{
    let zip = new JSZip();
    let count = 0;
    let zipFilename = "All Manuals.zip";
    let urls = [
      'https://s1.q4cdn.com/806093406/files/doc_downloads/test.pdf',
      DotPdf,
      'https://s1.q4cdn.com/806093406/files/doc_downloads/test.pdf'
    ];
    let filename = [
      'Cab Card.pdf',
      'Dot Inspection Guide.pdf',
      'User Guide.pdf'
    ]
    urls.forEach(function(url, index){
      // loading a file and add it in a zip file
      JSZipUtils.getBinaryContent(url, function (err, data) {
         if(err) {
            throw err; // or handle the error
         }
         zip.file(filename[index], data, {binary:true});
         count++;
         if (count === urls.length) {
           zip.generateAsync({type:'blob'}).then(function(content) {
              saveAs(content, zipFilename);
           });
        }
      });
    });
  }
  return (
    <Styled.Wrapper>
      <div className="welcomeText">
        <div className="headingWrapper">
          <h1 className="stepTitle">Download Manuals</h1>
          {/* <Button variant="contained" className="download downloadAll" onClick={()=> downloadAll()}>
            Download All
          </Button> */}
        </div>
        <div className="downloadPdfWrapper">
          <div className="downloadText">
            <span className="serialNo">1</span> <span className="items">User Manual</span>
          </div>
          {' '}
          <Button variant="contained" className="download">
            <a
              href="https://clutcheld.com/wp-content/uploads/2020/08/769732_Clutch-ELD-Manual_07162011396.pdf"
              download
              target="_blank"
              rel="noopener noreferrer"
            >
              Download
            </a>
          </Button>
        </div>
        <div className="downloadPdfWrapper">
          <div className="downloadText">
            <span className="serialNo">2</span> <span className="items">Dot Inspection Guide</span>
          </div>
          {' '}
          <Button variant="contained" className="download">
          <a
              href="https://clutcheld.com/wp-content/uploads/2020/08/769735_ClutchDOTCard_073020.pdf"
              download
              target="_blank"
              rel="noopener noreferrer"
            >
            Download
            </a>
          </Button>
        </div>
        {/* <div className="downloadPdfWrapper">
          <div className="downloadText">
            <span className="serialNo">3</span> <span className="items">User Guide</span>
          </div>
          {' '}
          <Button variant="contained" className="download">
          <a
              href="https://s1.q4cdn.com/806093406/files/doc_downloads/test.pdf"
              download
              target="_blank"
              rel="noopener noreferrer"
            >
            Download
            </a>
          </Button>
        </div> */}
        <div className="details">
        You can find and print out the DOT Cab Card, DOT Inspection Guide and User Guide all right here. Each driver needs to have access to these while using their ELD. This can be found on within the mobile application and can be downloaded to the phone.
        </div>
      </div>
    </Styled.Wrapper>
  );
};

export default DownloadManuals;

DownloadManuals.propTypes = {
  history: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired
};
