import React, { lazy, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RenderComponent from '@/components/modules/DynamicRenderPage';
import ParentStyled from './style';
import { loadStyle, loadPageConfig } from '@/utils/utils';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { COOKIE_AUTH_DETAILS } from '@/config/constants/keys';
import { getCookie } from '@/services/cookie';

let Styled = loadStyle(ParentStyled, 'pages/web/Scorecard');
const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.scoreCard[0].name}`));
const Content = lazy(() => import(`@/${config.scoreCard[1].name}`));

const ScoreCard = (parentProps) => {
  const inputref = useRef();
  const i18n = useTranslation();
  const history = useHistory();
  const match = useParams();
  const { fetchDetails, scoreCard, callback, module, fetchRadiusDetails, radiusData, getVehicles } = parentProps;
  const commonParentProps = { history, i18n, Styled };
  const fetchDataApi = 'getData';
  const fetchRadiusDataApi = 'getRadiusData';
  const token = getCookie(COOKIE_AUTH_DETAILS);

  useEffect(() => {
    if (module === 'fleetScoreCard') {
      fetchDetails({ data: { id: token?.companyId }, apiId: fetchDataApi });
      fetchRadiusDetails({ data: { id: token?.companyId }, apiId: fetchRadiusDataApi })
    } else {
      fetchDetails({ data: { id: match.id }, apiId: fetchDataApi });
      fetchRadiusDetails({ data: { id: match.id }, apiId: fetchRadiusDataApi });
    }
  }, []);

  return (
    <>
      <RenderComponent
        {...config[module][0]}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        component={Title}
        header={i18n
          .t(config[module][0].header)
          .replace(':name', `${scoreCard?.companyData?.companyName}`)}
        i18n={i18n}
      />
      <RenderComponent
        {...config[module][1]}
        getVehicles={getVehicles}
        companyId={module === 'fleetScoreCard' ? token?.companyId : match.id}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        component={Content}
        i18n={i18n}
      />
      <div id="tooltip" ref={inputref} className="tooltips"></div>
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    scoreCard: state.scoreCard.data,
    radiusData: state.scoreCard.radiusData,
    filters: state.filters
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchDetails: (data) => dispatch.scoreCard.fetchData(data),
    fetchQuickStatsDetails: (data) => dispatch.scoreCard.fetchQuickStatsData(data),
    getVehicles: (data) => dispatch.scoreCard.getVehicles(data),
    fetchRadiusDetails: (data) => dispatch.scoreCard.fetchRadiusData(data)
  };
};

export default connect(mapState, mapDispatch)(ScoreCard);

ScoreCard.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  handleDeleteAlert: PropTypes.func.isRequired,
  handleRestoreAlert: PropTypes.func.isRequired,
  filterSuccess: PropTypes.func.isRequired,
  handleExportUser: PropTypes.func.isRequired,
  handleImportUser: PropTypes.func.isRequired,
  handleExportUserTemplate: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  getFilters: PropTypes.func.isRequired
};
