import { UI_DATE_FORMAT_WITH_TIME_24 } from '@/config/constants/static';
import { getLastQuaters } from '@/utils/utils';
import moment from 'moment';
import React, { lazy, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ViewForm from './Form';


const layout = window?.$environment?.CURRENT_LAYOUT;

const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Icon = lazy(() => import(`../../../components/UI/Icon/${layout}`));
const Modal = require(`@/components/UI/Modal/${layout}`).default;
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));

const ViewDCA = ({
    i18n,
    changeRequest,
    apiStatus,
    dcaList,
    request,
    information,
    Styled,
    callback,
    basicRequest,
    commonProps,
}) => {
    const history = useHistory();
    const lastQuater = getLastQuaters(true);

    const [pagination, setPagination] = React.useState(false);


    const columns = [
        {
            id: 'code',
            numeric: false,
            disablePadding: true,
            label: i18n.t('dca.code'),
            needSorting: true
        },
        {
            id: 'description',
            numeric: false,
            disablePadding: true,
            label: i18n.t('dca.description'),
            needSorting: false
        },
        {
            id: 'fromDate',
            numeric: false,
            disablePadding: true,
            label: i18n.t('dca.startTime'),
            needSorting: true
        },
        {
            id: 'toDate',
            numeric: false,
            disablePadding: true,
            label: i18n.t('dca.endTime'),
            needSorting: true
        },
    ];

    const generateRows = () => {
        const list = dcaList?.list;
        return list.map((e) => {
            return {
                ...e,
                'fromDate': e?.startTime ? moment(e?.startTime).format(UI_DATE_FORMAT_WITH_TIME_24) : '-',
                'toDate': e?.endTime ? moment(e?.endTime).format(UI_DATE_FORMAT_WITH_TIME_24) : '-',
            }
        });
    }
    const [filterValue, setFilterValue] = useState({});
    const tableData = (fields) => {
        setFilterValue(fields);
    };
    

    return (
        <Styled.Wrapper>
            <Styled.TopWrapper>
                <ViewForm
                    hasApiStatus={apiStatus}
                    callback={callback}
                    request={request}
                    changeRequest={changeRequest}
                    setPagination={setPagination}
                    tableData={(fields) => tableData(fields)}
                    commonProps={commonProps}
                    i18n={i18n}
                    basicRequest={basicRequest}
                />
            </Styled.TopWrapper>
            <Styled.TableWrapper>
                <Table
                    type="server"
                    defaultSortBy={{}}
                    title=""
                    columns={[...columns]}
                    changeRequest={(e) => {
                        changeRequest({ ...e, ...filterValue });
                    }}
                    apiStatus={apiStatus}
                    baseRequest={request}
                    data={generateRows()}
                    emptyMessage={information}
                    totalRecords={dcaList?.totalRecords || 0}
                    itemsPerPage={15}
                    i18n={i18n}
                    pageinationReset={pagination}
                    actions={[]}
                    pagenationClicked={(e) => {
                        document.body.scrollTop = 0;
                        document.documentElement.scrollTop = 0;
                        setPagination(!e);
                    }}
                />
            </Styled.TableWrapper>
        </Styled.Wrapper>
    )
}

export default ViewDCA;