import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import { behaviourList } from '@/config/constants/keys';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Select = lazy(() => import(`@/components/UI/Select/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Input = lazy(() => import(`@/components/UI/Input/${layout}`));

const RenderComponent = ({
    values,
    handleEdit,
    validateForm,
    customProps,
    submitAction,
    cancelAction,
}) => {
    const { i18n } = useTranslation();

    return (
        <>
            <FormStyle.default.FieldsWrapper className="csafield vertical">
                <Label variant="body1" htmlFor="month">
                    {i18n.t('Month')}
                </Label>
                <FormStyle.default.TextWrapper>
                    <Field
                        name="month"
                        onChange={(event) => handleEdit(event, { field: 'month' })}
                        isClearable={true}
                        as={Select}
                        placeholder={i18n.t('common.pleaseSelect')}
                        value={values['month']}
                        suggestions={customProps.monthList}
                    />
                    <Message type="error" message={customProps.validationError} />
                </FormStyle.default.TextWrapper>
            </FormStyle.default.FieldsWrapper>
            <FormStyle.default.ButtonsWrapper className="vertical-button">
                <Button
                    type="submit"
                    label={i18n.t('common.view')}
                    onClick={(e, d) => {
                        const validateResponse = validateForm();
                        validateResponse.then((data) => {
                            submitAction(data);
                        });
                    }}
                />
                <Button
                    className=""
                    type={'reset'}
                    label={i18n.t('common.clear')}
                    onClick={() => {
                        cancelAction();
                    }}
                />
            </FormStyle.default.ButtonsWrapper>
        </>
    );
};

RenderComponent.propTypes = {
    values: PropTypes.object.isRequired,
    handleEdit: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    isNotValid: PropTypes.bool.isRequired,
    hasApiStatus: PropTypes.object,
    validateForm: PropTypes.func.isRequired,
    submitAction: PropTypes.func.isRequired
};

export default RenderComponent;
