import styled from 'styled-components';
import Parent from '../Base/style';

const MenuWrapper = styled(Parent.MenuWrapper)`
${({ theme }) => `
ul {
    display: flex;
    justify-content: flex-end;
}
li {
  border-bottom: 0;
  padding: 15px 1% 15px;
  width: auto;
  a{
    color: #fff !important;
  }
  a:hover {
    text-decoration: underline !important;
  }
  p{
    font-family: ${theme.palette.secondaryFont};
  }
}
.MuiListItem-button:hover,
.MuiListItem-root.Mui-selected:hover,
.MuiListItem-root.Mui-selected {
  background-color: transparent;
}
// li:first-child,
// li:nth-child(1){
//  width: 7%;
// }
// li:nth-child(6),
// li:nth-child(4) {
//   width: 11%;
// }
// li:nth-child(5) {
//   width: 21%;
// }
.MuiListItemIcon-root {
  min-width: 1.7rem;
  padding-right: 12px;
  svg {
    width: '20px';
    height: '20px';
  }
  @media only screen and (min-width: 375px) and (max-width: 812px) and (orientation: landscape) {
    padding-right: 22px;
  }
}
.MuiListItem-root img {
  height: 1.2em;
  @media (min-width: 1920px) {
    height: 2.2em;
  }
}
.MuiList-padding {
  padding: 0;
}
.menuItem span {
  display: none;
}
a {
  display: flex;
  width: 100%;
  p {
    color: ${theme.colors.HEADER_TEXT};
    text-transform: uppercase;
    font-size: 19px;
    @media (max-width: ${theme.breakpoints.values.xxl + 2}px) {
      font-size: 17px;
    }
    @media (max-width: ${theme.breakpoints.values.lg - 1}px) {
      display: block;
      font-size: 17px;
    }
  }
}
.MuiTouchRipple-root {
  display: none;
}
.MuiListItem-button:hover {
 
  p {
    color: ${theme.colors.WHITE} !important;
  }
}
p {
  @media only screen and (min-device-width: ${
    theme.breakpoints.values.md
  }px) and (max-device-width: ${
  theme.breakpoints.values.lg
}px) and (orientation: landscape) {
    font-size: 15px !important;
  }
  @media only screen and (min-device-width: ${
    theme.breakpoints.values.lg
  }px) and (max-device-width: ${
  theme.breakpoints.values.xxl
}px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    font-size: 15px !important;
  }
}
`}
`;
const MenuToolTip = styled(Parent.MenuToolTip)`
${({ needToolTip, index, horizontal }) => `

:hover span {
  display: ${needToolTip || horizontal ? 'block' : 'none'};
  left: 40px;
  top: ${index ? index : ''};
  position: fixed;
  z-index: 1000;
  color: #f58220;
}
img {
  height: ${needToolTip ? '2.2em!important' : '1.2em'};
  padding-left: ${needToolTip ? '10px' : '0px'};
  padding-right: ${needToolTip ? '16px' : '0px'};
  @media (min-width: 1920px) {
    height: ${needToolTip ? ' 3.2em !important' : '2.2em'};
  }
}
svg {
  width: '20px';
  height: '20px';
}
`}
`;
const Wrapper = { MenuWrapper, MenuToolTip };

export default Wrapper;
