import { ROLES, imageUrls, pageUrls } from '@/config/constants/keys';
import { BACKEND_DATE_FORMAT } from '@/config/constants/static';
import { generateDropdownOptions, getImagePath, getListOfSelectedField, hasEnabledFeature, hasRestrictedRole, hasValue, isInsuracePortalUser } from '@/utils/utils';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { lazy, useEffect, useState } from 'react';
import { number, object, string } from 'yup';
import RenderComponent from './RenderComponent';

const layout = window?.$environment?.CURRENT_LAYOUT;

const Form = lazy(() => import(`@/components/UI/Form/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Modal = require(`@/components/UI/Modal/${layout}`).default;

const isRiskTheoryIsFLmanagar = window?.$environment?.SERVICE_PROVIDER === 'riskTheory' && hasRestrictedRole(['FLMANAGER'])
const VehicleForm = ({
  apiStatus,
  saveVehicleData,
  getLatLongAndSave,
  vehicle,
  history,
  saveDocument,
  deleteDocument,
  callback,
  fetchTerminals,
  fetchDepartments,
  fetchSubDepartments,
  regions,
  type,
  states,
  radiusCompliances,
  i18n,
  isValidVin,
  fetchCountData,
  downloadDocument,
  logBook,
  terminals,
  departments,
  subDepartments,
  disabledCriteria,
  fleetCompanies,
  Styled,
  receipt,
  customDvir,
  newUI,
  getRegions,
  recallUpdate
}) => {
  const [forms, setForms] = useState([]);
  const insuracePortalUserRole = isInsuracePortalUser();
  const [isCameraEnabled, setCameraEnabled] = useState(false);
  useEffect(() => {
    let url = receipt ? receipt : '';
    document.getElementById(`downloadDocument`).src = url;
  }, [receipt]);
  useEffect(() => {
    if (layout !== 'Clutch') {
      setValidationlist({
        type: string().required(i18n.t('common.fieldRequiredMessage')),
        name: string()
          .required(i18n.t('common.fieldRequiredMessage'))
          .max(10, i18n.t('common.validName')),
          vin: window.$environment.SERVICE_PROVIDER !== 'spireon' ?  string()
          .required(i18n.t('common.fieldRequiredMessage'))
          .matches(/[^\WqioqIOQ]+$/, i18n.t('common.isValidVin'))
          .test(
            'len',
            i18n.t('common.isValidVin'),
            (val) => val?.length === 17
          ) : string().when(["deviceType"], {
            is: (value) => value === 'ELD',
            then: string().required(i18n.t('common.fieldRequiredMessage'))
            .matches(/[^\WqioqIOQ]+$/, i18n.t('common.isValidVin'))
            .test(
              'len',
              i18n.t('common.isValidVin'),
              (val) => val?.length === 17
            ),
            otherwise: string()
          }),
        make: string().required(i18n.t('common.fieldRequiredMessage')),
        model: string().required(i18n.t('common.fieldRequiredMessage')),
        next_maintenance_date: string().required(
          i18n.t('common.fieldRequiredMessage')
        ),
        esn: string().when(["hardwareDeviceKey", "deviceType"], {
          is: (value1, value2) => ((value2 === 'ELD' && value1?.value === 7001) || (value2 === 'ELD' && value1?.value === 7007) || (value2 === 'ELD' && value1?.value === 7010) || value2 === 'Tracking'),
          then: string().required('common.fieldRequiredMessage'),
          otherwise: string()
        }),
        trackingDevice: string().when(["deviceType"], {
          is: (value) => value === 'Tracking',
          then: string().required('common.fieldRequiredMessage'),
          otherwise: string()
        }),
        nextMaintenanceMileage: number()
          .typeError(i18n.t('common.validNumberMessage'))
          .positive(i18n.t('common.positiveNumberMessage')),
        currentOdometer: number()
          .typeError(i18n.t('common.validNumberMessage'))
          .positive(i18n.t('common.positiveNumberMessage')),
        ...(checkCameraFeature() ? {
          cameraType: string().when(["camera"], {
            is: (value) => value === 'Yes',
            then: string().required('common.fieldRequiredMessage'),
            otherwise: string()
          }),
          cameraSerialNo: string().when('camera', {
            is: 'Yes',
            then: string()
              .trim()
              .required('common.fieldRequiredMessage')
              .matches(/^[a-zA-Z0-9\-]+$/, 'Only alphanumeric characters and hyphens are allowed')
              .min(1, 'Camera serial must be at least 1 character')
              .max(50, 'Camera serial must be at most 50 characters'),
              otherwise: string()
          }),
          cameraPassword: string().when(["camera"], {
            is: (value) => value === 'Yes',
            then: string().trim().required('common.fieldRequiredMessage')
            .min(1, 'Password must be at least 1 character')
            .max(50, 'Password must be at most 50 characters'),
            otherwise: string()
          }),
          plateNumber: string().required(i18n.t('common.fieldRequiredMessage')),
        } : {}),
      });
    } else if (window.$environment?.SERVICE_PROVIDER === 'webfleet') {
      setValidationlist({
        esn: string().when(["hardwareDeviceKey", "deviceType"], {
          is: (value1, value2) => ((value2 === 'ELD' && value1?.value === 7001) || (value2 === 'ELD' && value1?.value === 7007) || (value2 === 'ELD' && value1?.value === 7010) || value2 === 'Tracking'),
          then: string().required('common.fieldRequiredMessage'),
          otherwise: string()
        }),
        trackingDevice: string().when(["deviceType"], {
          is: (value) => value === 'Tracking',
          then: string().required('common.fieldRequiredMessage'),
          otherwise: string()
        }),
      });
    } else {
      setValidationlist({
        type: string().required(i18n.t('common.fieldRequiredMessage')),
        name: string()
          .required(i18n.t('common.fieldRequiredMessage'))
          .max(10, i18n.t('common.validName')),
        vin: string()
          .required(i18n.t('common.fieldRequiredMessage'))
          .matches(/[^\WIOQioq]+$/, i18n.t('common.isValidVin'))
          .test(
            'len',
            i18n.t('common.isValidVin'),
            (val) => val?.length === 17
          ),
        company: insuracePortalUserRole ? string().required('common.fieldRequiredMessage') : string(),
        make: string().required(i18n.t('common.fieldRequiredMessage')),
        model: string().required(i18n.t('common.fieldRequiredMessage')),
        nextMaintenanceMileage: number()
          .typeError(i18n.t('common.validNumberMessage'))
          .positive(i18n.t('common.positiveNumberMessage')),
        currentOdometer: number()
          .typeError(i18n.t('common.validNumberMessage'))
          .positive(i18n.t('common.positiveNumberMessage')),
        esn: string().when(["hardwareDeviceKey", "deviceType"], {
          is: (value1, value2) => ((value2 === 'ELD' && value1?.value === 7001) || (value2 === 'ELD' && value1?.value === 7007) || (value2 === 'ELD' && value1?.value === 7010) || value2 === 'Tracking'),
          then: string().required('common.fieldRequiredMessage'),
          otherwise: string()
        }),
        trackingDevice: string().when(["deviceType"], {
          is: (value) => value === 'Tracking',
          then: string().required('common.fieldRequiredMessage'),
          otherwise: string()
        }),
        ...(checkCameraFeature() ? {
          cameraType: string().when(["camera"], {
            is: (value) => value === 'Yes',
            then: string().required('common.fieldRequiredMessage'),
            otherwise: string()
          }),
          cameraSerialNo: string().when(["camera"], {
            is: 'Yes',
            then: string()
              .trim()
              .required('common.fieldRequiredMessage')
              .matches(/^[a-zA-Z0-9\-]+$/, 'Only alphanumeric characters and hyphens are allowed')
              .min(1, 'Camera serial must be at least 1 character')
              .max(50, 'Camera serial must be at most 50 characters'),
              otherwise: string()
          }),
          cameraPassword: string().when(["camera"], {
            is: (value) => value === 'Yes',
            then: string().trim().required('common.fieldRequiredMessage')
            .min(1, 'Password must be at least 1 character')
            .max(50, 'Password must be at most 50 characters'),
            otherwise: string()
          }),
          plateNumber: string().required(i18n.t('common.fieldRequiredMessage')),
        } : {}),
        ...(window?.$environment?.SERVICE_PROVIDER === 'riskTheory' && !isRiskTheoryIsFLmanagar  ? {
          garagingAddress: string().required(
            i18n.t('common.fieldRequiredMessage')
          ),
          garagingCity: string().required(
            i18n.t('common.fieldRequiredMessage')
          ),
          garagingState: string().required(
            i18n.t('common.fieldRequiredMessage')
          ),
          garagingZipCode: string().required(
            i18n.t('common.fieldRequiredMessage')
          ),
          radiusCompliance: string().required(
            i18n.t('common.fieldRequiredMessage')
          )
        } : {})
      });
    }
  }, [layout]);
  const apiId = type + 'SaveVehicleData';
  const latLongApiId = type + 'fetchLatLong'
  const hasApiStatus = hasValue(apiStatus.status, 'api', apiId);
  const [validationlist, setValidationlist] = useState({});

  /* Create a popup starts */
  const [ModalListObject, setModalListObject] = useState({
    header: '',
    content: () => { }
  });

  const [open, setOpen] = useState(false);

  const objectArrayFromSameField = (list) => {
    return list?.length
      ? list.map((item) => {
        return {
          label: item,
          value: item
        };
      })
      : [];
  };
  const objectArrayFromDiffFields = (list, value, field1, field2, callback) => {
    return list?.length
      ? list.map((item) => {
        if (callback) return callback(item, field1, field2, value);
        else
          return {
            label: field2 ? `${item[field1]} ${item[field2]}` : item[field1],
            value: item[value]
          };
      })
      : [];
  };
  const vehicleTypes = objectArrayFromSameField(vehicle.vehicleTypes);
  const cameraTypes = objectArrayFromSameField([...(vehicle.cameraTypes || [])]);
  const fuelTypes = objectArrayFromSameField(vehicle.fuelTypes);
  const years = objectArrayFromSameField(vehicle.years);
  const driverList = objectArrayFromDiffFields(
    vehicle?.drivers,
    'id',
    'firstName',
    'lastName'
  );
  const mechanicList = objectArrayFromDiffFields(
    vehicle?.mechanics,
    'id',
    'firstName',
    'lastName'
  );
  const trailerList = objectArrayFromDiffFields(
    vehicle?.companyTrailers,
    'trailerId',
    'name'
  );
  const hardwareDeviceList = objectArrayFromDiffFields(
    vehicle?.hardwareDevices,
    'deviceKey',
    'name'
  );


  const [initialValues, setInitialValues] = useState({
    deviceType: 'NONE',
    camera: 'No'
  });
  const defaultYear = () => {
    const currentYear = new Date().getFullYear();
    const defaultYear = years.find(o => o.value === currentYear);
    return defaultYear || years[0]
  }

  const checkCameraFeature = () => {
    if (window.$environment?.SERVICE_PROVIDER === 'riskTheory' && hasRestrictedRole([ROLES.UNDER_WRITER, ROLES.LOSS_CONTROL, ROLES.CLAIM_ADJUSTER])) {
      return vehicle?.cameraIntegrationEnabled;
    }
    return vehicle?.cameraIntegrationEnabled || hasEnabledFeature('cameraIntegrationEnabled')
  }

  const trackingDeviceList = [
    {
      label: 'PT 10',
      value: 'PT10'
    },
    {
      label: 'Geometris',
      value: 'Geometris'
    }
  ]

  useEffect(() => {
    const objectArrayFromDiffFields = (list, field, value) => {
      const lt = list?.length
        ? list.map((item) => {
          return {
            label: item[value],
            value: item[field]
          };
        })
        : [];
      return lt;
    };

    const inspectionForms = objectArrayFromDiffFields(
      vehicle?.customDvirForms?.filter(ele => ele.unitType == 'VEHICLE'),
      'idPub',
      'name'
    );
    setForms(inspectionForms)

    const terminalList = generateDropdownOptions(
      vehicle?.terminals?.length
        ? vehicle.terminals
        : [],
      'terminalName',
      'terminalId'
    )
    const departmentList = generateDropdownOptions(
      vehicle?.departments?.length
        ? vehicle.departments
        : [],
      'departmentName',
      'departmentId'
    )
    const subdepartmentList = generateDropdownOptions(
      vehicle?.subDepartments?.length
        ? vehicle.subDepartments
        : [],
      'subDepartmentName',
      'subDepartmentId'
    )
    let drivers = []
    if (vehicle?.vehicleDetails?.driversIds && type != 'add') {
      vehicle.vehicleDetails.driversIds.forEach(item => {
        if (driverList) driverList.forEach(ele => {
          if (item == ele.value) drivers.push(ele)
        })
      })
    }
    setInitialValues({
      ...initialValues,
      wlId: vehicle?.vehicleDetails?.wlId || '',
      companyId: vehicle?.vehicleDetails?.companyId || '',
      deviceType: vehicle?.vehicleDetails?.deviceType || 'NONE',
      camera: vehicle?.vehicleDetails?.cameraSerialNo ? 'Yes' : 'No',
      driversIds: drivers,
      esn: vehicle?.vehicleDetails?.esn || '',
      gvw: vehicle?.vehicleDetails?.gvw || '',
      trackingDevice: vehicle?.vehicleDetails?.trackingDevice
        ? trackingDeviceList.filter(
          (ele) => ele.value === vehicle?.vehicleDetails?.trackingDevice
        )[0]
        : '',
      hardwareDeviceKey: vehicle?.vehicleDetails?.hardwareDeviceKey
        ? hardwareDeviceList.filter(
          (hd) => hd.value === vehicle?.vehicleDetails?.hardwareDeviceKey
        )[0]
        : '',
      company: vehicle?.company
        ? {
          label: vehicle.company.name,
          value: vehicle.company.id
        }
        : '',
      ibeaconRequired: vehicle?.vehicleDetails?.ibeaconRequired || '',
      ibeaconUuid: vehicle?.vehicleDetails?.ibeaconUuid || '',
      idPub: vehicle?.vehicleDetails?.idPub,
      cameraIntegrationEnabled: vehicle?.cameraIntegrationEnabled || false,
      isEdit: vehicle?.vehicleDetails?.isEdit,
      notes: window.$environment?.SERVICE_PROVIDER === 'webfleet' ? vehicle?.vehicleDetails?.notes : vehicle?.vehicleDetails?.lienholder || '',
      make: vehicle?.vehicleDetails?.make || '',
      mechanicId:
        mechanicList.filter(
          (mech) => mech.value === vehicle?.vehicleDetails?.mechanicId
        )[0] || '',
      model: vehicle?.vehicleDetails?.model || '',
      webFleetUid: vehicle?.vehicleDetails?.webFleetUid || '',
      name: vehicle?.vehicleDetails?.name || '',
      nextMaintenanceMileage:
        vehicle?.vehicleDetails?.nextMaintenanceMileage || '',
      next_maintenance_date:
        vehicle?.vehicleDetails?.next_maintenance_date || '',
      currentOdometer: vehicle?.vehicleDetails?.odometer || '',
      ...(checkCameraFeature() ? {
        cameraType: vehicle?.vehicleDetails?.cameraType ? cameraTypes?.find(e => e?.value === vehicle?.vehicleDetails?.cameraType)  : cameraTypes[0],
        cameraSerialNo: vehicle?.vehicleDetails?.cameraSerialNo || '',
        cameraPassword: vehicle?.vehicleDetails?.cameraPassword || ''
      } : {}),
      plateNumber: vehicle?.vehicleDetails?.plateNumber || '',
      garagingAddress: vehicle?.vehicleDetails?.garagingAddress || '',
      radiusCompliance: vehicle?.vehicleDetails?.radiusCompliance
        ? {
          label: vehicle?.vehicleDetails?.radiusCompliance,
          value: vehicle?.vehicleDetails?.radiusCompliance
        }
        : '',
      garagingState: vehicle?.vehicleDetails?.garagingState
        ? {
          label: vehicle?.vehicleDetails?.garagingState,
          value: vehicle?.vehicleDetails?.garagingState
        }
        : '',
      garagingCity: vehicle?.vehicleDetails?.garagingCity || '',
      garagingZipCode: vehicle?.vehicleDetails?.garagingZipCode || '',
      regionId:
        (regions &&
          regions.length &&
          regions.filter(
            (reg) => reg.value === vehicle?.vehicleDetails?.regionId
          )[0]) ||
        '',
      terminalId:
        (terminalList &&
          terminalList.length &&
          terminalList.filter(
            (ter) => ter.value === vehicle?.vehicleDetails?.terminalId
          )[0]) ||
        '',
      departmentId:
        (departmentList &&
          departmentList.length &&
          departmentList.filter(
            (dep) => dep.value === vehicle?.vehicleDetails?.departmentId
          )[0]) ||
        '',
      subDepartmentId:
        (subdepartmentList &&
          subdepartmentList.length &&
          subdepartmentList.filter(
            (sub) => sub.value === vehicle?.vehicleDetails?.subDepartmentId
          )[0]) ||
        '',
      trailerIdPubs:
        trailerList.filter((trailer) =>
          vehicle?.vehicleDetails?.trailerIdPubs?.includes(trailer.value)
        ) || [],
      type: vehicle?.vehicleDetails?.type
        ? {
          label: vehicle?.vehicleDetails?.type,
          value: vehicle?.vehicleDetails?.type
        }
        : vehicleTypes[0],
      fuelType: vehicle?.vehicleDetails?.fuelType
        ? {
          label: vehicle?.vehicleDetails?.fuelType,
          value: vehicle?.vehicleDetails?.fuelType
        }
        : fuelTypes[0],
      vehicleState: vehicle?.vehicleDetails?.vehicleState || '',
      vin: vehicle?.vehicleDetails?.vin || '',
      year: vehicle?.vehicleDetails?.year
        ? {
          label: vehicle?.vehicleDetails?.year,
          value: vehicle?.vehicleDetails?.year
        }
        : defaultYear(),
      customDvirFormId:
        (vehicle?.vehicleDetails?.customDvirFormId &&
          inspectionForms.filter(
            (form) => form.value === vehicle?.vehicleDetails?.customDvirFormId
          )[0]) ||
        '',
    });
  }, [vehicle?.vehicleDetails, vehicle]);

  useEffect(() => {
    setCameraEnabled(vehicle.cameraIntegrationEnabled);
  }, [vehicle.cameraIntegrationEnabled])

  const companies = objectArrayFromDiffFields(fleetCompanies, 'id', 'name')
  const documentsList = vehicle?.documents;
  const submitVehicleData = (fields) => {
    let finalData = {
      wlId: fields.wlId || null,
      companyId: fields.company?.value || null,
      departmentId: (fields.departmentId && fields.departmentId.value) || null,
      deviceType: fields.deviceType || 'NONE',
      esn: fields.esn,
      gvw: fields.gvw,
      fuelType: fields.fuelType?.value || null,
      ibeaconRequired: fields.ibeaconRequired || null,
      ibeaconUuid: fields.ibeaconUuid || null,
      idPub: fields.idPub,
      isEdit: fields.isEdit,
      ...(window.$environment?.SERVICE_PROVIDER === 'webfleet' ? { webFleetUid: fields.webFleetUid } : {}),
      latitude: fields.latitude,
      longitude: fields.longitude,
      garagingAddress: fields.garagingAddress,
      garagingCity: fields.garagingCity,
      garagingState: fields.garagingState?.value,
      garagingZipCode: fields.garagingZipCode,
      radiusCompliance: fields.radiusCompliance?.value,
      lienholder: fields.notes,
      make: fields.make,
      mechanicId: fields.mechanicId?.value || null,
      model: fields.model || null,
      name: fields.name || null,
      nextMaintenanceMileage: fields.nextMaintenanceMileage
        ? parseFloat(fields.nextMaintenanceMileage)
        : null,

      odometer: fields.currentOdometer
        ? parseInt(fields.currentOdometer)
        : null,
      ...(checkCameraFeature() ? {
        cameraType: fields.camera == 'Yes' && fields.cameraType ? fields.cameraType?.value : null,
        cameraSerialNo: fields.camera == 'Yes' && fields.cameraSerialNo ? fields.cameraSerialNo : null,
        cameraPassword: fields.camera == 'Yes' && fields.cameraPassword ? fields.cameraPassword : null
      } : {}),
      plateNumber: fields.plateNumber,
      regionId: (fields.regionId && fields.regionId.value) || null,
      terminalId: (fields.terminalId && fields.terminalId.value) || null,
      subDepartmentId:
        (fields.subDepartmentId && fields.subDepartmentId.value) || null,
      type: fields.type?.value || null,
      vehicleState: fields.vehicleState || null,
      vin: fields.vin,
      year: fields.year?.value || null,
      customDvirFormId: fields.customDvirFormId?.value || null,
    };
    if ((layout !== 'Clutch' || window.$environment?.SERVICE_PROVIDER === 'cyntrx') && moment(fields?.next_maintenance_date).isValid()) {
      finalData.next_maintenance_date = moment(
        fields.next_maintenance_date
      ).format(BACKEND_DATE_FORMAT);
    } else if (moment(fields?.next_maintenance_date).isValid()) {
      finalData.next_maintenance_date = moment(
        fields.next_maintenance_date
      ).format(BACKEND_DATE_FORMAT);;
    } else finalData.next_maintenance_date = '';
    if (fields.deviceType === 'ELD') {
      finalData['hardwareDeviceKey'] = fields.hardwareDeviceKey?.value || null;
    }
    if (fields.deviceType === 'Tracking') {
      finalData['trackingDevice'] = fields.trackingDevice?.value || null;
    }
    finalData.trailerIdPubs =
      getListOfSelectedField(fields.trailerIdPubs, 'value') || null;
    finalData.driversIds =
      getListOfSelectedField(fields.driversIds, 'value') || null;

    if (window?.$environment?.SERVICE_PROVIDER === 'riskTheory') {
      getLatLongAndSave({
        data: {
          data: {
            ...finalData
          }
        },
        address: `${fields.garagingAddress || ""},${fields.garagingCity || ""},${fields.garagingState.value || ""},${fields.garagingZipCode || ""}`,
        apiId,
        latLongApiId,
        i18n,
        latLongCallback: callback,
        callback: (cameraError, errorMsg, fromCatch) => {
          if (fromCatch) {
              callback('error', errorMsg);
          } else {
            if (cameraError) {
              if(Array.isArray(errorMsg) && errorMsg?.length > 1) {
                callback('warning', ' ', () => {
                  history.push(pageUrls.vehicleList);
                }, errorMsg)
              } else {
                callback('warning', errorMsg?.[0], () => {
                  history.push(pageUrls.vehicleList);
                }, [])
              }
            } else {
              let apiId = 'fetchCountApi';
              history.push(pageUrls.vehicleList);
              fetchCountData({
                apiId
              });
            }
          }
        }
      })
    } else {
      saveVehicleData({
        data: {
          data: {
            ...finalData
          }
        },
        apiId,
        callback: (cameraError, errorMsg, fromCatch) => {
          if (fromCatch) {
              callback('error', errorMsg);
          } else {
            if (cameraError) {
              if(Array.isArray(errorMsg) && errorMsg?.length > 1) {
                callback('warning', ' ', () => {
                  history.push(pageUrls.vehicleList);
                }, errorMsg)
              } else {
                callback('warning', errorMsg?.[0], () => {
                  history.push(pageUrls.vehicleList);
                }, [])
              }
            } else {
              let apiId = 'fetchCountApi';
              history.push(pageUrls.vehicleList);
              fetchCountData({
                apiId
              });
            }
          }
        },
      });
    }

  };

  return (
    <>
      <Form
        type="server"
        direction="column"
        initialValues={{ ...initialValues }}
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {
          {
            /* if (!document.querySelector("div[type='error']")){ */
          }
          fields.isEdit
            ? submitVehicleData(fields)
            : setModalListObject({
              content: () => (
                <div className="deleteParent">
                  <div className="imageSection">
                    <img
                      src={getImagePath(imageUrls.confirmIcon)}
                      alt="Are you sure"
                    />
                  </div>
                  <h2>
                    {window?.$environment?.SERVICE_PROVIDER === 'riskTheory' ?
                      i18n.t('common.insurancePortalWarning') :
                      `You are changing vehicle count to ${vehicle?.totalTrucks + 1} vehicles`}
                  </h2>
                  <div className="deletePopupBtn">
                    <div className="cancelBtn">
                      <Button
                        label={window?.$environment?.SERVICE_PROVIDER === 'riskTheory' ? i18n.t('common.ok') : i18n.t('common.cancel')}
                        type={'reset'}
                        onClick={() => {
                          setOpen(false);
                        }}
                      ></Button>
                    </div>
                    <div className="deletebtn">
                      <Button
                        label={window?.$environment?.SERVICE_PROVIDER === 'riskTheory' ? i18n.t('common.proceedToAdd') : i18n.t('common.ok')}
                        onClick={() => {
                          submitVehicleData(fields);
                          setOpen(false);
                        }}
                      ></Button>
                    </div>
                  </div>
                </div>
              )
            });
          !fields.isEdit && setOpen(true);
          {
            /* } */
          }
        }}
        isCameraEnabled={isCameraEnabled}
        customProps={{
          forms,
          hasApiStatus,
          saveDocument,
          deleteDocument,
          apiStatus,
          callback,
          vehicleTypes,
          fuelTypes,
          fetchTerminals,
          fetchDepartments,
          fetchSubDepartments,
          setModalListObject,
          setOpen,
          setValidationlist,
          trackingDeviceList,
          validationlist,
          years,
          driverList,
          mechanicList,
          trailerList,
          hardwareDeviceList,
          vehicle,
          regions,
          type,
          isValidVin,
          i18n,
          downloadDocument,
          logBook,
          terminals,
          companies,
          departments,
          radiusCompliances,
          states,
          subDepartments,
          disabledCriteria,
          setInitialValues,
          documentsList,
          Styled,
          newUI,
          getRegions,
          recallUpdate,
          cameraTypes,
        }}
        handleOnCancel={() => {
          history.push(pageUrls.vehicleList);
        }}
        validationSchema={object().shape({
          ...validationlist
        })}
      />
      <Modal open={open} setOpen={setOpen} data={ModalListObject} deletePopup />
      <iframe id="downloadDocument" style={{ display: 'none' }}></iframe>
    </>
  );
};

VehicleForm.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired
};

export default VehicleForm;
