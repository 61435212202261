import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { hasRestrictedRole, isdvirOnly } from '@/utils/utils';
import { pageUrls } from '@/config/constants/keys';
import { ROLES } from '@/config/constants/keys'

const Unhandled = ({ message, Styled }) => {
  const { i18n } = useTranslation();
  const isDVIROnlyFlagEnabled = isdvirOnly()

  return (
    <>
      <Styled.Wrapper>
        <h1>{i18n.t('apology')}...</h1>
        <p>
          {i18n.t('Click')}
          {''}
          <a
            href={
              isDVIROnlyFlagEnabled? 
              hasRestrictedRole([ROLES.SERVICE_MANAGER])?
              pageUrls.unassignedInspections: 
              pageUrls.pendingMechanicInspections:
              hasRestrictedRole(['DRIVER'])?
              pageUrls.profile : 
              '/dashboard'
            }
            className="here"
          >
            {i18n.t('here')}
          </a>
          {''}{' '}
          {  hasRestrictedRole(['DRIVER'])
            ? i18n.t('to go back to user profile'): 
            isDVIROnlyFlagEnabled? i18n.t('goToInspections')
            : i18n.t('to go back to dashboard')}
        </p>
        {message}
      </Styled.Wrapper>
    </>
  );
};

export default Unhandled;

Unhandled.propTypes = {
  message: PropTypes.string.isRequired,
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};
