import React, { useEffect, lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RenderComponent from '@/components/modules/DynamicRenderPage';

import ParentStyled from './style';
import { loadStyle, hasEnabledFeature, loadPageConfig, hasRestrictedRole } from '@/utils/utils';
import { ROLES } from '@/config/constants/keys';
let Styled = loadStyle(ParentStyled, 'pages/web/Inspections');

const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.inspections[0].name}`));
const Content = lazy(() => import(`@/${config.inspections[1].name}`));
const TopActions =
  config.inspections[0].children &&
  lazy(() => import(`${config.inspections[0].children.name}`));

const Inspections = (parentProps) => {
  const {
    fetchInspections,
    getFilters,
    fetchDriverUserListing,
    getDocumentToken,
    history,
    i18n,
    theme
  } = parentProps;
  const commonParentProps = { history, i18n, Styled, theme };

  const basicRequest = {
    displayLength:
      window.$environment.SERVICE_PROVIDER === 'spireon' ? '10' : '15',
    displayStart: '1'
  };
  const [pagination, setPagination] = useState(false);

  const [request, setRequest] = useState({
    ...basicRequest
  });

  const selectChange = (e) => {
    changeRequest({ search: e });
    setPagination(true);
  };

  const changeRequest = (fields) => {
    setRequest({ ...basicRequest, ...fields });
  };

  const searchConfig = {
    setPagination,
    searchPlaceholder: 'Search Inspection Logs',
    changeRequest,
    fromData: false
  };

  useEffect(() => {
    fetchInspections({
      apiId: 'fetchInspections',
      data: { request }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request]);

  useEffect(() => {
    fetchDriverUserListing({
      listApiId: 'fetchDriverUser'
    });
    getFilters({ apiId: 'getFilters' });
    getDocumentToken({ apiId: 'getDocToken' });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const isCustomDviRequired = hasRestrictedRole([ROLES.VIEW_ONLY_FM])? false: hasEnabledFeature('customDvirEnabled');
  const isAdvancedDviRequired = hasRestrictedRole([ROLES.VIEW_ONLY_FM])? false: hasEnabledFeature('advancedDvirEnabled');
  return (
    <>
      <RenderComponent
        {...config.inspections[0]}
        parentProps={{
          ...parentProps,
          isAdvancedDviRequired,
          isCustomDviRequired,
          changeRequest,
          request,
          pagination,
          setPagination
        }}
        commonParentProps={commonParentProps}
        component={Title}
        childComponent={TopActions}
        searchConfig={
          window.$environment.CURRENT_LAYOUT === 'SpireonNew'
            ? searchConfig
            : null
        }
        noBackbutton={true}
      />
      <RenderComponent
        {...config.inspections[1]}
        parentProps={{
          ...parentProps,
          isAdvancedDviRequired,
          isCustomDviRequired,
          changeRequest,
          request,
          pagination,
          setPagination
        }}
        commonParentProps={commonParentProps}
        component={Content}
      />
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    inspections: state.inspectionList,
    list: state.inspections,
    filters: state.filters,
    logBook: state.logBook
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchInspections: (data) => dispatch.inspectionList.fetchData(data),
    bulkDownloadReport: (data) => dispatch.logBook.downloadData(data),
    fetchDriverUserListing: (data) => dispatch.inspections.fetchData(data),
    getFilters: (data) => dispatch.filters.fetchData(data),
    getDocumentToken: (data) => dispatch.logBook.documentToken(data),
    clearApiStatus: () => dispatch.asyncRequests.clearApiStatus(),
    viewReport: (data) => dispatch.viewReport.viewReportDocument(data)
  };
};

export default connect(mapState, mapDispatch)(Inspections);
Inspections.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  fetchInspections: PropTypes.func.isRequired
};
