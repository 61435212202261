import React, { useEffect, lazy } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RenderComponent from '@/components/modules/DynamicRenderPage';

import ParentStyled from './style';
import { loadStyle, loadPageConfig, isInsuracePortalUser } from '@/utils/utils';

let Styled = loadStyle(ParentStyled, 'pages/web/PaymentInformation');
const isInsurancePortalUser = isInsuracePortalUser();

const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.paymentInformation[0].name}`));
const Content = lazy(() => import(`@/${config.paymentInformation[1].name}`));
const TopActions =
  config.paymentInformation[0].children &&
  lazy(() => import(`${config.paymentInformation[0].children.name}`));

const PaymentInformation = (parentProps) => {
  const { fetchPaymentInformation, i18n, history, theme } = parentProps;
  const query = new URLSearchParams(history.location.search); 
  useEffect(() => {
    fetchPaymentInformation({
      apiId: 'fetchPaymentInformation',
      data: isInsurancePortalUser ? { id: query.get('companyId') } : undefined
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const commonParentProps = {
    Styled,
    i18n,
    history,
    theme
  };

  return (
    <>
      <RenderComponent
        {...config.paymentInformation[0]}
        parentProps={{
          ...parentProps
        }}
        commonParentProps={commonParentProps}
        component={Title}
        childComponent={TopActions}
      />
      <RenderComponent
        {...config.paymentInformation[1]}
        parentProps={{
          ...parentProps
        }}
        commonParentProps={commonParentProps}
        component={Content}
      />
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    paymentInformation: state.paymentInformation,
    receipt: state.viewReport
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchPaymentInformation: (data) =>
      dispatch.paymentInformation.fetchData(data),
    downloadReceipt: (data) => dispatch.viewReport.downloadReport(data),
    clearState:(data)=> dispatch.viewReport.clearReceipt()
  };
};

export default connect(mapState, mapDispatch)(PaymentInformation);
PaymentInformation.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  fetchPaymentInformation: PropTypes.func.isRequired
};
