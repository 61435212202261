import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import { getStyle } from '../../../../utils/utils';

const ExportWrapper = styled(Box)`
  ${({ theme, style, transition }) => `

  .exportClose {
    position: absolute;
    top: 10px;
    right: 0px;
    padding-right: 45px;
  }
  border: solid 2px
    ${
      (getStyle((style || {}).border, theme.palette.primary || {}).border,
      theme.colors.PRIMARY_BORDER)
    };
  background-color: ${
    (getStyle((style || {}).border, theme.palette.primary || {}).main,
    theme.colors.PRIMARY_MAIN)
  };
  padding: 5px;
  padding-top: 11px;
  width: 390px;
  z-index: 10;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  justify-content: flex-end;
  flex-direction: column;
  position: absolute;
  right: ${({ right }) => right || '30px'};
  @media (max-width: ${theme.breakpoints.values.sm}px) {
    width: 240px;
    form {
      flex-direction: column;
      
      #button {
        margin-top: 2px;
      }
    }
  }
  animation: ${transition || 'fromRight'}
    1.5s forwards ease-in-out;
  @keyframes fromRight {
    from {
      right: -450px;
    }
    to {
      right: 3px;
    }
  }
   form .vertical {
    flex: 0 0 30%;
  }
  .bulkSection {
    padding: 2%;
    padding-top: 3%;
    .vertical {
      flex: 0 0 48%;
    }
    .vertical-button {
      justify-content: center;
      display: flex;
      width: 100%;
      .MuiBox-root {
        width: 30% !important;
      }
      .MuiButtonBase-root {
        margin-top: -14px;
      }
    }
  }
 
  .vertical-button .MuiBox-root {
    width: 100% !important;
    margin-right: 0px !important;
  }
  .react-datepicker-wrapper input,
  .react-datepicker-wrapper {
    height: 35px !important;
    padding-left: 4px !important;
    min-height: auto;
  }
  @keyframes toRight {
    from {
      right: 3px;
    }
    to {
      right: -450px;
    }
  }
  .MuiButtonBase-root {
    height: 35px !important;
    font-size: 18px;
    margin-top: 30px;
  }
  .MuiButton-label {
    height: 35px !important;
  }
  `}
`;

const Header = styled(Box)`
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
`;

const Styled = { ExportWrapper, Header };

export default Styled;
