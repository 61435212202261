import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';

// import Header from '../../../components/Header';
import Component from './Component';

const Subscriptions = ({ auth, i18n, theme, history, ...rest }) => {
  return (
    <>
      {/* <Header
        header={i18n.t('SUBSCRIPTION LIST')}
        auth={auth}
        i18n={i18n}
        history={history}
      /> */}
      <Box style={{ ...theme.palette.contentStyle }}>
        <Component {...rest} theme={theme} i18n={i18n} />
      </Box>
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    subscriptions: state.subscriptions
  };
};
const mapDispatch = (dispatch) => {
  return {
    getSubscriptions: (data) => dispatch.subscriptions.getSubscriptions(data),
    saveSubscriptions: (data) => dispatch.subscriptions.saveSubscriptions(data)
  };
};

export default connect(mapState, mapDispatch)(Subscriptions);

Subscriptions.propTypes = {
  subscriptions: PropTypes.object.isRequired,
  apiStatus: PropTypes.object.isRequired,
  getSubscriptions: PropTypes.func.isRequired,
  saveSubscriptions: PropTypes.func.isRequired
};
