import * as parent from '../variables';

export const colors = {
  ...parent.colors,
  PRIMARY_MAIN: '#f0f2f2',
  SELECTED_MENU_BG: '#17181e',
  PRIMARY_CONTRAST: '#000',
  HEADER_BG: '#030000',
  HEADER_COUNT_TEXT: '#fff',
  // HIDE_MENU_TOP_BORDER: true,
  NEW_THEME: true,
  PRIMARY_BORDER: 'rgba(186, 194, 255, 0.08)',
  GREY: '#999',
  DAILY_LOG_GRAPH_BORDER: '#919191',
  NORMAL_LINK: '#455560',
  MODAL_TEXT: '#797979',
  MODAL_HEADING: '#575757',
  MODAL_BACKGROUND: '#f0f2f2',
  LIST_TEXT: '#5e5f67',
  HEADER_LIST_TEXT: '#8a8a8a',
  LIGHT_BORDER: '#2d2b2b',
  SECONDARY_MAIN: '#0277d0',
  SECONDARY_CONTRAST: '#5e5f67',
  WHITE: '#fff',
  INPUT_BACKGROUND: '#ffffff',
  RED: '#ff0000',
  BLACK: '#000',
  BACKGROUND: '#fff',
  BLACK_THREE: '#373737',
  RED_TWO: '#d34343',
  GREEN: '#32cd32',
  GREEN_TWO: '#97AE46',
  GREY_ELEVEN: '#777070',
  GREY_TWELVE: '#c3c3c3',
  BLACK_FOUR: '#333333',
  GRAPH_BLACK: '#212121',
  CHART_BLUE: '#00e2ff',
  WARNING: '#ffff00',
  GREY_DATA: '#696969',
  SELECTED_TAB_COLOR: '#0277d0',
  LINK_COLOR: '#0277d0',
  HEADER_COLOR: '#fff',
  DOCUMENT_LINE: '#2C3249',
  DIABLED_COLOR: '#889ccc',
  RADIO_BACKGROUND: '#f0f2f2',
  FORM_COLOR: '#191e22',
  SUGGESTED_COLOR: '#00b7a0',
  BODY_BACKGROUND: '#f0f2f2',
  CHECKBOX_TICK: '#0277d0',
  PAGINATION_BACKGROUND: '#fff',
  PAGINATION_ALTERNATE_BACKGROUND: '#f8f8f8',
  PAGINATION_COLOR: '#fff',
  SELECTED_AREA: 'rgba(32, 177, 232, 0.6)',
  MAP: {
    LIGHT_BLACK: '#353740',
    LIGHTER_BLACK: '#353740',
    BLACK: '#353740',
    GREY: '#d1d1d1',
    LIGHT_GREY: '#d1d1d1',
    LIGHTER_GREY: '#d1d1d1',
    WATER: '#5e5f67',
    ROAD: '#37425C'
  },
  LOGIN_BUTTON: '#0277d0',
  TAB_LABEL: '#5e5f67',
  BACKGROUND_MENU: '#5e5f67',
  TITLE_COLOR: '#191e22',
  INPUT_COLOR: '#000',
  INPUT_BORDER: '#ccc',
  INPUT_FOCUS: '#0277d0',
  TABLE_ROW_COLOR: '#39434c',
  SORT_ICON: '#0277d0',
  LOGOUT_BUTTON: '#181c2d',
  PAGINATION_HEADER: '#5e5f67',
  HEADER_TEXT: '#39434c',
  PAGINATION_BORDER: '#f0f2f2',
  RESET_BUTTON: '#455560',
  ACTION_BORDER: '#5e5f67',
  PRIMARY_BUTTON_TEXT: '#fff',
  FORM_INPUT_BG: '#f0f2f2',
  MULTISELECT_BACKGROUND: '#5e5f67',
  MULTISELECT_LABEL: '#f0f2f2',
  FORM_BACKGROUND: '#fff',
  RESET_BACKGROUND: '#5e5f67',
  RESET_COLOR: '#ffffff',
  CHECKBOX_BORDER: '#d1d3d6',
  SELECT_ACTIONS: '#444',
  SECONDARY_BORDER: '#d0d4d7',
  TAB_HIGHLIGHT: '#000',
  TAB_BOTTOM_BORDER: '#0277d0',
  TAB_HIGHLIGHT_BACKGROUND: '#fff',
  ACCORDION_BACKGROUND: '#fff',
  USER_LOGO_BACKGROUND: '#5e5f67',
  USER_LOGO_COLOR: '#ffffff',
  POLICIES_BACKGROUND: '#fff',
  MENU_TITLE: '#fff',
  SELECTED_MENU_TITLE: '#fff',
  MENU_BORDER_COLOR: '#0277d0',
  MENU_BACKGROUND:'#1f2126',
  LOGIN_BACKGROUND: '#fff',
  RANGE_PICKER: {
    CELL_BG: '#dfdfdf',
    CELL_HOVER_BG: '#bbbbbb'
  }

};
export default colors;
export const fonts = ['NotoSans-Regular', 'sans-serif'].join(',');

