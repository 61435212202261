import styled from 'styled-components';
import Parent from './style';
const Wrapper = styled(Parent.Wrapper)`
    button.MuiButtonBase-root {
        margin-top: 0;
    }
`;
const TopWrapper = styled(Parent.TopWrapper)``;
const SearchWrapper = styled(Parent.SearchWrapper)``;
const TableWrapper = styled(Parent.TableWrapper)`
${({ theme }) => `
padding:0;
.quickFilters{
    background: ${theme.colors.LINK_COLOR} none repeat scroll 0 0;
    color: #f4f6f9;
    padding: 10px 3%;
}
.MuiPaper-root  {
    padding: 0 2%;
}
`}
`;
const LabelWrap = styled(Parent.LabelWrap)``;

const Styled = {
    Wrapper,
    SearchWrapper,
    TopWrapper,
    TableWrapper,
    LabelWrap
  };
  export default Styled;
