import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { hasValue, getLogsInfoDisplay } from '@/utils/utils';
import moment from 'moment';
import { UI_DATE_FORMAT_WITH_TIME_24 } from '@/config/constants/static';
import { getImagePath } from '@/utils/utils';
import { imageUrls } from '@/config/constants/keys';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
const layout = window?.$environment?.CURRENT_LAYOUT;

const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Link = lazy(() => import(`@/components/UI/Link`));
const Label = lazy(() => import(`@/components/UI/Label/${layout}`));
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));

const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Component {...contentProps} />
    </Box>
  );
};
export default Index;

const Component = ({
  i18n,
  logsHistory,
  apiStatus,
  apiId,
  Styled,
  viewReport
}) => {
  const generateRows = () => {
    const updatedList = logsHistory?.dailyLogs?.map((item) => {
      item.date = moment(new Date(item.date)).format(
        UI_DATE_FORMAT_WITH_TIME_24
      );
      item.pdf = item.reportId ? (
        <Link
          newPage
          onClick={() =>
            viewReport({
              apiId: 'viewReportApi',
              data: item.reportId
            })
          }
        >
          {window.$environment.CURRENT_LAYOUT === 'SpireonNew' ? (
            // <FontAwesomeIcon icon={faFilePdf} />
            <img src={getImagePath(imageUrls.historyPdficon)} className="pdfIcon" alt="historyIcon" />
          ) : (
            'PDF'
          )}
        </Link>
      ) : (
        ''
      );
      return item;
    });
    return updatedList || [];
  };

  const columns = [
    {
      id: 'version',
      numeric: true,
      disablePadding: true,
      label: 'common.version',
      needSorting: true
    },
    {
      id: 'date',
      numeric: false,
      disablePadding: false,
      label: 'common.date',
      needSorting: true
    },
    {
      id: 'modifiedBy',
      numeric: false,
      disablePadding: false,
      label: 'common.modifiedBy',
      needSorting: true
    },
    {
      id: 'comment',
      numeric: false,
      disablePadding: false,
      label: 'common.annotation',
      needSorting: true
    },

    {
      id: 'pdf',
      label: i18n.t('Actions'),
      disablePadding: false,
      needSorting: true,
      numeric: false
    }
  ];
  const hasHistoryApiStatus = hasValue(apiStatus.status, 'api', apiId);
  const information =
    hasHistoryApiStatus && hasHistoryApiStatus.errorMessage ? (
      <Message
        type="error"
        message={i18n.t(hasHistoryApiStatus.errorMessage)}
      />
    ) : (
      i18n.t('common.tableEmptyMessage')
    );
  return (
    <Styled.Wrapper>
      {window.$environment.CURRENT_LAYOUT !== 'SpireonNew' && (
        <Label className="logsInfoDisplay">
          {getLogsInfoDisplay(i18n, logsHistory?.driverName, logsHistory?.day)}
        </Label>
      )}
      <div className="tableWrapper">
        <Table
          type="client"
          defaultSortBy={{ sortBy: 'version', order: 'desc' }}
          title=""
          columns={[...columns]}
          emptyMessage={information}
          data={generateRows()}
          itemsPerPage={
            window.$environment.SERVICE_PROVIDER === 'spireon' ? 10 : 15
          }
          actions={[]}
          i18n={i18n}
        />
      </div>
    </Styled.Wrapper>
  );
};

// export default Component;

Component.propTypes = {
  apiStatus: PropTypes.object.isRequired
};
