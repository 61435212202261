import { getAccessLevel } from '../../../config/apis';

const initialState = {};

export const accessLevel = {
  state: { ...initialState },
  reducers: {
    getSuccess: (state, data) => {
      return { ...data };
    },
    getFailed: () => {
      return { ...initialState };
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: getAccessLevel
          },
          id: payload.apiId
        });
        if (response) {
          this.getSuccess({ data: response });
        }
      } catch (error) {
        this.getFailed();
      }
    }
  })
};
