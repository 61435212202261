import React, { lazy, useState } from 'react';
import { Field } from 'formik';
import ReactDatePicker from 'react-datepicker';
import { getDateLocale, hasEnabledFeature } from '@/utils/utils';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { recurrenceIntervals, recurrenceTypes } from '@/config/constants/keys';
import Styled from './style';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));
const RadioButton = lazy(() => import(`@/components/UI/Radio/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const Modal = require(`@/components/UI/Modal/${layout}`).default;
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));

const ScheduleComponent = ({
    values,
    handleEdit,
    validateForm,
    submitAction,
    customProps: {
        i18n, editPage, drivers,
        vehicles }
}) => {

    const [open, setOpen] = useState(false);
    const [ModalListObject, setModalListObject] = useState({
        header: '',
        content: () => { }
    });
    const [err, setErr] = useState('')
    const getCurrSaturday = () => {
        const currentDate = new Date();
        // Find the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
        const currentDayOfWeek = currentDate.getDay();

        // Calculate the number of days remaining until Saturday
        const daysUntilSaturday = 6 - currentDayOfWeek;

        // Create a new date for the current Saturday by adding the days remaining
        const currentSaturday = new Date(currentDate);
        currentSaturday.setDate(currentDate.getDate() + daysUntilSaturday);

        return currentSaturday
    }

    const getLastDayOfMonth = () => {
        const currentDate = new Date();
        // Set the date to the last day of the current month
        currentDate.setMonth(currentDate.getMonth() + 1, 0);
        return currentDate
    }

    const getMinDate = () => {
        if (values.recurrenceType == 'RECURRING') {
            if (values.recurrenceInterval == 'WEEKLY') {
                return getCurrSaturday()
            } else if (values.recurrenceInterval == 'MONTHLY') {
                return getLastDayOfMonth()
            } else return new Date()
        } else return new Date()
    }

    const getModalContent = (modalText) => {
        setModalListObject({
            content: () => (
                <>
                    <InfoOutlinedIcon style={{ height: '2.5em', width: '2.5em', marginBottom: '15px' }} />
                    <div style={{ fontSize: '22px', marginBottom: '15px' }}>{modalText}</div>
                    <Button
                        type='submit'
                        className="buttonStyle"
                        label={i18n.t('Ok')}
                        onClick={() => {
                            setOpen(false)
                            submitAction({})
                        }}
                    />
                </>
            )
        })
        setOpen(true)
    }

    const isEndOfMonth = (date) => {
        const nextDay = new Date(date);
        nextDay.setDate(date.getDate() + 1);
        return date.getMonth() !== nextDay.getMonth();
    }

    const addDayClass = (date) => {
        let currDate = new Date(date)
        if (values.recurrenceType == 'RECURRING') {
            if (values.recurrenceInterval == 'WEEKLY' && currDate.getDay() != 6) {
                return 'react-datepicker__day--disabled'
            } else if (values.recurrenceInterval == 'MONTHLY' && !isEndOfMonth(currDate)) {
                return 'react-datepicker__day--disabled'
            } else return ''
        } else return ''

    }

    const [driverFlg, setDriverFlg] = useState(false)
    const [vehicleFlg, setVehicleFlg] = useState(false)

    const getDrivers = () => {
        if (values.driverIds.length <= 7 || driverFlg) {
            return <div>
                <span className='value'>{drivers.data.filter(ele => values.driverIds.includes(ele.value)).map(ele => ele.label).join(', ')}</span>
                {values.driverIds.length > 7 ? <div onClick={() => setDriverFlg(!driverFlg)} className='showBtn'>{driverFlg ? 'Show less' : 'Show more'}</div> : null}
            </div>
        } else {
            return <div>
                <span className='value'>{drivers.data.filter(ele => values.driverIds.includes(ele.value)).map(ele => ele.label).slice(0, 7).join(', ')}</span>
                <div onClick={() => setDriverFlg(!driverFlg)} className='showBtn'>{driverFlg ? 'Show less' : 'Show more'}</div>
            </div>
        }
    }
    const getVehicles = () => {
        if (values.vehicleIds.length <= 7 || vehicleFlg) {
            return <div>
                <span className='value'>{vehicles?.vehicleList.filter(ele => values.vehicleIds.includes(ele.id)).map(ele => ele.unit_id).join(', ')}</span>
                {values.vehicleIds.length > 7 ? <div onClick={() => setVehicleFlg(!vehicleFlg)} className='showBtn'>{vehicleFlg ? 'Show less' : 'Show more'}</div> : null}
            </div>
        } else {
            return <div>
                <span className='value'>{vehicles?.vehicleList.filter(ele => values.vehicleIds.includes(ele.id)).map(ele => ele.unit_id).slice(0, 7).join(', ')}</span>
                <div onClick={() => setVehicleFlg(!vehicleFlg)} className='showBtn'>{vehicleFlg ? 'Show less' : 'Show more'}</div>
            </div>
        }
    }
    return (
        <>
            {hasEnabledFeature('downloadCenterEnabled') ? <>
                {editPage ? <><div className='itemWrapper'>
                    <div className="label">
                        {i18n.t('Recurrance')}
                    </div>
                    <span className='value'>{recurrenceTypes.filter(ele => ele.value == values.recurrenceType)[0].label}</span>
                </div>
                    {values.driverIds.length ? <Styled.ShowWrapper className='itemWrapper'>
                        <div className="label">
                            {i18n.t('Driver')}
                        </div>
                        {getDrivers()}
                    </Styled.ShowWrapper> : null}
                    {values.vehicleIds.length ? <Styled.ShowWrapper className='itemWrapper'>
                        <div className="label">
                            {i18n.t('Vehicle')}
                        </div>
                        {getVehicles()}
                    </Styled.ShowWrapper> : null}
                </> :
                    <FormStyle.default.FieldsWrapper className="radioWrapper">
                        <FormStyle.default.TextWrapper className="label">
                            {i18n.t('Recurrance')}
                        </FormStyle.default.TextWrapper>
                        <FormStyle.default.CheckBoxWrapper direction="row">
                            <RadioButton
                                aria-labelledby="recurrenceType"
                                value={values.recurrenceType}
                                options={recurrenceTypes}
                                onClick={(event) => {
                                    handleEdit('', {
                                        field: 'scheduleEndDate',
                                        type: 'text'
                                    });
                                    handleEdit(event, {
                                        field: 'recurrenceType',
                                        type: 'radio'
                                    });
                                }}
                            />
                        </FormStyle.default.CheckBoxWrapper>
                    </FormStyle.default.FieldsWrapper>}
                {values.recurrenceType == 'RECURRING' ?
                    <FormStyle.default.FieldsWrapper className="radioWrapper">
                        <FormStyle.default.TextWrapper className="label recurrenceIntervalBoxLabel">
                            {i18n.t('Run Every')}
                        </FormStyle.default.TextWrapper>
                        <FormStyle.default.CheckBoxWrapper direction="row" className="recurrenceIntervalBox">
                            <RadioButton
                                aria-labelledby="recurrenceInterval"
                                value={values.recurrenceInterval}
                                options={recurrenceIntervals}
                                onClick={(event) => {
                                    handleEdit('', {
                                        field: 'scheduleEndDate',
                                        type: 'text'
                                    });
                                    handleEdit(event, {
                                        field: 'recurrenceInterval',
                                        type: 'radio'
                                    });
                                }}
                            />
                            <div className='infoText'>{`The report will be generated at the end of ${values.recurrenceInterval == 'MONTHLY' ? 'every month' : values.recurrenceInterval == 'WEEKLY' ? 'every week' : 'each day'}`}</div>
                        </FormStyle.default.CheckBoxWrapper>
                    </FormStyle.default.FieldsWrapper>
                    : null}
                {values.recurrenceType != 'NONE' ?
                    <Styled.Wrapper className='fullWidth'>
                        <div className='scheduleDateWrapper'>
                            <FormStyle.default.FieldsWrapper className="fieldWrapper">
                                <Label htmlFor="scheduleEndDate">{values.recurrenceType == 'ONE_TIME' ? i18n.t('Schedule Date') : i18n.t('Ends On')}</Label>
                                <FormStyle.default.TextWrapper>
                                    <Field
                                        name="scheduleEndDate"
                                        onChange={(event) => {
                                            if (event) setErr('')
                                            handleEdit(event, { field: 'scheduleEndDate', type: 'date' });
                                        }}
                                        as={ReactDatePicker}
                                        locale={getDateLocale()}
                                        dayClassName={addDayClass}
                                        placeholderText={i18n.t('common.datePicker')}
                                        value={values.scheduleEndDate}
                                        showPopperArrow={false}
                                        showTimeSelect={false}
                                        showDisabledMonthNavigation
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        minDate={getMinDate()}
                                    />
                                    {err ? (
                                        <Message type="error" message={err} />
                                    ) : null}
                                </FormStyle.default.TextWrapper>
                            </FormStyle.default.FieldsWrapper>
                        </div>
                    </Styled.Wrapper>
                    : null}
            </> : null}
            <FormStyle.default.ButtonsWrapper className="buttonWrapper">
                <Button
                    type="submit"
                    className="buttonStyle"
                    label={i18n.t('common.submit')}
                    onClick={() => {
                        const validateResponse = validateForm();
                        validateResponse.then((data) => {
                            if (hasEnabledFeature('downloadCenterEnabled') && Object.keys(data).length == 0) {
                                if (values.recurrenceType != 'NONE' && !values.scheduleEndDate) {
                                    setErr(`This field is required.`)
                                } else {
                                    // if (values.recurrenceType == 'RECURRING') {
                                    //     if (values.recurrenceInterval == 'WEEKLY') {
                                    //         getModalContent(`Reports for weekly recurrence are generated every Saturday, and data after that will not be considered.`)
                                    //     } else if (values.recurrenceInterval == 'MONTHLY') {
                                    //         getModalContent(`Reports for monthly recurrence are generated at the end of each month, and data after the month-end will not be considered.`)
                                    //     } else submitAction(data)
                                    // }
                                    // else 
                                    submitAction(data);
                                }
                            } else submitAction(data);
                        });
                    }}
                />
            </FormStyle.default.ButtonsWrapper>
            <Modal open={open} setOpen={setOpen} data={ModalListObject} deletePopup />
        </>
    );
};

export default ScheduleComponent;
