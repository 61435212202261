import styled from 'styled-components';
const MenuWrapper = styled.div`
  ${({ theme }) => `
  font-weight: bold;
  font-size: 1.2em;
  color: white;
  background-color: colors.BACKGROUND;
  overflow: auto;
  border-top: '1px solid ' + theme.palette.primary.border;
  li {
    border-bottom: 0.5px solid ${theme.colors.LIGHT_BORDER};
    padding: 13px;
  }

  .MuiListItem-button:hover,
  .MuiListItem-root.Mui-selected:hover,
  .MuiListItem-root.Mui-selected {
    background-color: #373535;
  }
  .MuiListItem-root.Mui-selected {
    p {
      color: #fff;
    }
  }
  .MuiListItem-root:nth-child(7) .MuiListItemIcon-root svg {
    width: 22px;
    height: 22px;
    margin-top: -2px;
  }
  .MuiListItemIcon-root {
    min-width: 1.7rem;
    padding-right: 12px;
    svg {
      width: '20px';
      height: '20px';
    }
    @media only screen and (min-width: 375px) and (max-width: 812px) and (orientation: landscape) {
      padding-right: 22px;
    }
  }
  .MuiListItem-root img {
    height: 1.2em;
    @media (min-width: 1920px) {
      height: 2.2em;
    }
  }
  .MuiList-padding {
    padding: 0;
  }
  .menuItem span {
    display: none;
  }
  a {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    width: 100%;
    p {
      @media (max-width: ${theme.breakpoints.values.lg - 1}px) {
        display: none;
      }
    }
  }
  .MuiTouchRipple-root {
    display: none;
  }
  .MuiListItem-button:hover {
    p {
      color: ${theme.colors.WHITE} !important;
    }
  }
  p {
    @media only screen and (min-device-width: ${
      theme.breakpoints.values.md
    }px) and (max-device-width: ${
    theme.breakpoints.values.lg
  }px) and (orientation: landscape) {
      font-size: 15px !important;
    }
    @media only screen and (min-device-width: ${
      theme.breakpoints.values.lg
    }px) and (max-device-width: ${
    theme.breakpoints.values.xxl
  }px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
      font-size: 15px !important;
    }
  }
  .noCameraModal{
    color: black !important;
  }
  `}
`;

const modalContent = styled.div`
    * {
      color: black !important;
    }
`;


const MenuToolTip = styled.div`
  ${({ needToolTip, index, horizontal }) => `

  :hover span {
    display: ${needToolTip || horizontal ? 'block' : 'none'};
    left: 40px;
    top: ${index ? index : ''};
    position: fixed;
    z-index: 1000;
    color: #f58220;
  }
  img {
    height: ${needToolTip ? '2.2em!important' : '1.2em'};
    padding-left: ${needToolTip ? '10px' : '0px'};
    padding-right: ${needToolTip ? '16px' : '0px'};
    @media (min-width: 1920px) {
      height: ${needToolTip ? ' 3.2em !important' : '2.2em'};
    }
  }
  svg {
    width: '20px';
    height: '20px';
  }
  `}
`;
const Wrapper = { MenuWrapper, MenuToolTip, modalContent };
export default Wrapper;
