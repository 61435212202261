import React, { useEffect, lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RenderComponent from '@/components/modules/DynamicRenderPage';

import ParentStyled from './style';
import { loadStyle, getQueryStringKeys, loadPageConfig } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'pages/web/DailyDocuments');

const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.dailyDocuments[0].name}`));
const Content = lazy(() => import(`@/${config.dailyDocuments[1].name}`));
const TopActions =
  config.dailyDocuments[0].children &&
  lazy(() => import(`${config.dailyDocuments[0].children.name}`));

const DailyDocuments = (parentProps) => {
  const { fetchDailyDocuments, history, i18n, theme, callback, filterListSuccess, filterByDriverDate } = parentProps;

  const commonParentProps = { history, i18n, Styled, theme };
  const [pagination, setPagination] = useState(false);

  const driverId = getQueryStringKeys(history.location.search, 'driver');
  const day = getQueryStringKeys(history.location.search, 'day');
  const [ModalObject, setModalObject] = useState({
    header: '',
    content: () => { }
  });
  const [open, setOpen] = useState(false);

  const clickedModal = (e) => {
    setOpen(true);
  };
  const closeModal = (event) => {
    setOpen(false);
  };

  const data = {};
  const [withCloseIcon, setwithCloseIcon] = useState(true);
  const [backgroundModal, setbackgroundModal] = useState(false);
  const [filter, setFilter] = useState({ driver: "", day: "", eld: 'all' })
  useEffect(() => {
    if (driverId) data['driver'] = driverId;
    if (day) data['day'] = day;
    fetchDailyDocuments({
      data: {
        ...data
      },
      apiId: 'fetchDailyDocuments'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const filterEldSuccess = (data, eld) => {
    setFilter({ ...filter, eld })
    if(filter.driver || filter.day) filterByDriverDate({ ...filter, eld, i18n })
    else filterListSuccess(data)
  }
  const filterByDriverAndDate = (data) => {
    setFilter({ ...filter, ...data })
    filterByDriverDate({ ...filter, ...data, i18n })
  }
  return (
    <>
      <RenderComponent
        {...config.dailyDocuments[0]}
        parentProps={{
          ...parentProps,
          setModalObject,
          ModalObject,
          closeModal,
          clickedModal,
          open,
          setOpen,
          setbackgroundModal,
          withCloseIcon,
          backgroundModal,
          setwithCloseIcon,
          pagination,
          setPagination
        }}
        commonParentProps={commonParentProps}
        component={Title}
        childComponent={TopActions}
      />
      <RenderComponent
        {...config.dailyDocuments[1]}
        parentProps={{
          ...parentProps,
          setModalObject,
          filterEldSuccess,
          filterByDriverAndDate,
          ModalObject,
          closeModal,
          clickedModal,
          open,
          setOpen,
          setbackgroundModal,
          withCloseIcon,
          backgroundModal,
          setwithCloseIcon,
          pagination,
          setPagination
        }}
        commonParentProps={commonParentProps}
        component={Content}
      />
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    dailyDocuments: state.dailyDocuments
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchDailyDocuments: (data) => dispatch.dailyDocuments.fetchData(data),
    saveDocument: (data) => dispatch.dailyDocuments.saveData(data),
    handleDeleteAlert: (data) => dispatch.dailyDocuments.deleteData(data),
    filterListSuccess: (data) =>
      dispatch.dailyDocuments.filterListSuccess(data),
    filterOtherListSuccess: (data) =>
      dispatch.dailyDocuments.filterOtherListSuccess(data),
    filterByParams: (data) => dispatch.dailyDocuments.filterByParams(data),
    viewReport: (data) => dispatch.viewReport.viewReportDocument(data),
    filterByDriverDate: (data) =>
      dispatch.dailyDocuments.filterByDriverAndDate(data)
  };
};

export default connect(mapState, mapDispatch)(DailyDocuments);
DailyDocuments.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  dailyDocuments: PropTypes.object.isRequired,
  fetchDailyDocuments: PropTypes.func.isRequired,
  saveDocument: PropTypes.func.isRequired,
  handleDeleteAlert: PropTypes.func.isRequired,
  filterListSuccess: PropTypes.func.isRequired,
  filterOtherListSuccess: PropTypes.func.isRequired
};
