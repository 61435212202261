import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
  ${({ theme }) => `
   .alertMessage  {
        color: ${theme.colors.LINK_COLOR};
        text-transform: uppercase;
        font-size: 24px;
    }
`}
`;

const Styled = { Wrapper };
export default Styled;
