import { DatePicker } from 'antd';
import React from 'react';
import './style.css';
const { RangePicker } = DatePicker;

const DateRangePicker = ({ RangePickerContainer, onChange, value, predefinedRanges, ...rest }) => {
    return <RangePickerContainer>
        <RangePicker
            ranges={predefinedRanges}
            onChange={onChange}
            value={value}
            {...rest}
        />
    </RangePickerContainer>

        };

export default DateRangePicker;