import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { object, string } from 'yup';
import RenderComponent from './RenderComponent';
// import Styled from './style';
import ParentStyled from './style';
import { loadStyle } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'components/modules/DocumentUpload');
const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));
const Component = ({
  closeModal,
  saveDocument,
  currentItem,
  uploadApi,
  callback,
  userId,
  trailerIdPub,
  vehicleIdPub,
  isEdit,
  parent,
  drivers,
  types,
  currentDriver
}) => {
  const { i18n } = useTranslation();

  return (
    <Styled.Wrapper>
      <Form
        type="server"
        direction="column"
        initialValues={{
          upload: currentItem.id ? currentItem.name : '',
          date: currentItem.id ? currentItem.expiration_date : '',
          driverId: currentDriver,
          type: types && types[0]
        }}
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {
          const requestData =
            parent === 'dailyDocuments'
              ? {
                  driverId: fields.driverId?.value || null,
                  type: fields.type?.value || null,
                  timeZone: fields.timezone || 'UTC+5.5:00',
                  location: fields.location || null,
                  customerName: fields.customerName || null,
                  customerJob: fields.customerJob || null
                }
              : {
                  expiration_date: fields.date,
                  type: currentItem?.type?.name,
                  editUpload: isEdit
                };

          if (userId) {
            requestData.userId = userId;
          }
          if (vehicleIdPub) {
            requestData.vehicleIdPub = vehicleIdPub;
          }
          if (trailerIdPub) {
            requestData.trailerIdPub = trailerIdPub;
          }
          if (currentItem?.id) {
            requestData.id = currentItem?.id;
          }
          if (
            document?.querySelectorAll('span.filepond--file-status-main')[0]
              ?.innerText === ''
          ) {
            saveDocument({
              apiId: uploadApi,
              data: {
                data: {
                  file: fields.upload[0],
                  request: JSON.stringify(requestData)
                }
              },
              callback
            });
            closeModal();
          }
        }}
        customProps={{ parent, drivers, types, currentDriver, Styled }}
        handleClear={() => {}}
        validationSchema={object().shape({
          upload: string().required(i18n.t('common.fieldRequiredMessage')),
          ...(parent === 'dailyDocuments' && window.$environment.SERVICE_PROVIDER == 'webfleet' ? {driverId: string().required(i18n.t('common.fieldRequiredMessage'))} : {})
        })}
      />
    </Styled.Wrapper>
  );
};

export default Component;

Component.propTypes = {
  apiStatus: PropTypes.object,
  callback: PropTypes.func.isRequired
};
