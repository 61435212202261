import React, { lazy } from 'react';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const SubHeader = lazy(() => import(`@/components/SubHeader/${layout}`));

const ContractHistory = ({ Styled, contractHistoryList, i18n }) => {
  const information = i18n.t('common.tableEmptyMessage');
  const columns = [
    {
      id: 'startDate',
      numeric: false,
      disablePadding: false,
      label: i18n.t('paymentInformation.start')
    },
    {
      id: 'endDate',
      numeric: false,
      disablePadding: false,
      label: i18n.t('paymentInformation.end')
    },
    {
      id: 'noOfDays',
      numeric: true,
      disablePadding: false,
      label: i18n.t('paymentInformation.noOfDays')
    },

    {
      id: 'tier',
      numeric: false,
      disablePadding: false,
      label: i18n.t('registration.subscriptionTier')
    }
  ];
  return (
    <Styled.TableWrapper>
      <SubHeader
        variant="h2"
        className="contractHistorytitle"
        text={i18n.t('paymentInformation.contractHistory')}
      />
      <div className="contractHistory tcSection" id="scroll">
        <div className="scrollbar">
          <Table
            type="client"
            defaultSortBy={{ sortBy: 'startDate', order: 'asc' }}
            title=""
            columns={[...columns]}
            data={contractHistoryList || []}
            emptyMessage={information}
            i18n={i18n}
            actions={[]}
            itemsPerPage={contractHistoryList?.length}
          />
        </div>
      </div>
    </Styled.TableWrapper>
  );
};

export default ContractHistory;
