import React, { useEffect, useState, lazy } from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { useTranslation } from 'react-i18next';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
const Breadcrumb = ({
    Styled,
    data
}) => {
  const { i18n } = useTranslation();
return (
  <Styled.Wrapper>
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
        {data.map((items, index) => {
            return (
                index+1 !== data.length ?  
                <Link color="inherit" href={items.url ? items.url : '#'}>
                {i18n.t(items.name)}
              </Link> :
              <span>{i18n.t(items.name)}</span>
            )
        })}
    </Breadcrumbs>
  </Styled.Wrapper>
);
}

export default Breadcrumb;
