import React, { lazy, useState } from 'react';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Modal = lazy(() => import(`../../UI/Modal/${layout}`));
const Department = lazy(() => import(`../../modules/Department/Wrapper`));
const GlobalFilter = ({ open }) => {
  const [filterPopupOpen, setFilterPopupOpen] = useState(open);

  if (open)
    return (
      <Modal
        open={open || filterPopupOpen}
        setOpen={setFilterPopupOpen}
        data={{
          header: '',
          content: () => <Department action={() => setFilterPopupOpen(false)} />
        }}
        background="black"
        withCloseIcon
      />
    );
  else return null;
};

export default GlobalFilter;
