import styled from 'styled-components';
import Parent, { FormStyle as ParentStyle } from '../Base/serverStyle';
const TitleWrapper = styled(ParentStyle.TitleWrapper)``;
const ButtonsWrapper = styled(ParentStyle.ButtonsWrapper)``;
const FieldsWrapper = styled(ParentStyle.FieldsWrapper)``;
const TextWrapper = styled(ParentStyle.TextWrapper)``;
const TextareaWrapper = styled(ParentStyle.TextareaWrapper)``;
const CheckBoxWrapper = styled(ParentStyle.CheckBoxWrapper)``;
const FormWrapper = styled(Parent.FormWrapper)``;
const FormStyle = {
  TitleWrapper,
  ButtonsWrapper,
  FieldsWrapper,
  TextWrapper,
  TextareaWrapper,
  CheckBoxWrapper
};

export const Styled = {
  FormWrapper
};
export default FormStyle;
