import React, { useState, useEffect, lazy } from 'react';
import PropTypes from 'prop-types';

import Form from './Form';
import { Link as LinkDom } from 'react-router-dom';
import { getImagePath, hasValue } from '@/utils/utils';
import { imageUrls, pageUrls } from '@/config/constants/keys';
import { Link as LinkLi } from '@material-ui/core';
import Trailers from './Trailers';
import RoadsideInspections from './RoadsideInspections';

const layout = window?.$environment?.CURRENT_LAYOUT;

const GoogleMap = lazy(() => import(`@/components/GoogleMap/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Modal = require(`@/components/UI/Modal/${layout}`).default;
const SubHeader = lazy(() => import(`@/components/SubHeader/${layout}`));

const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <>
      <Component {...contentProps} filters={filters} />
    </>
  );
};
export default Index;

const Component = ({
  history,
  vehicles,
  apiStatus,
  fetchVehicles,
  handleDeleteAlert,
  callback,
  filterSuccess,
  handleExportVehicle,
  handleImportVehiclePost,
  handleImportTrailerPost,
  fetchVehiclePageHistoryMap,
  vehiclePageMap,
  openDocument,
  getDrivers,
  Styled,
  i18n,
  logBook,
  handleTrailerDeleteAlert,
  pagination,
  setPagination
}) => {
  const [openModalRefEmptyData, setModalRefEmptyData] = useState(false);
  const [driverIdSuggestion, setDriverIdSuggestion] = useState([]);
  const [openModalRef2, setModalRef2] = useState(false);
  const [openModalDriverName, setModalDriverName] = useState(false);
  // let driverIdSuggestion = [];
  useEffect(() => {
    if (vehicles.vehicleList.length) driverArray();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicles]);
  useEffect(() => {
    if (
      vehiclePageMap.type === 'vehicleHistoryMap' &&
      vehiclePageMap.data &&
      vehiclePageMap.data.length === 0
    ) {
      clickedModalEmptyData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehiclePageMap]);
  const driverArray = () => {
    let driverIdSuggestion = vehicles.vehicleList.map((items) => ({
      label: items.unit_id,
      value: items.id
    }));
    setDriverIdSuggestion(driverIdSuggestion)
  };

  const closeModalDelete = (event) => {
    setModalRef2(false);
  };
  const clickedModalDelete = (e) => {
    setModalRef2(true);
  };

  const closeModalEmptyData = (event) => {
    setModalRefEmptyData(false);
  };
  const clickedModalEmptyData = (e) => {
    setModalRefEmptyData(true);
  };

  const [ModalListObject2, setModalListObject2] = useState({
    header: '',
    content: () => {}
  });

  const [ModalDriverNameData, setModalDriverNameData] = useState({
    header: '',
    content: () => {
      return <> </>;
    }
  });
  const [ModalListObjectEmptyData] = useState({
    header: '',
    content: () => (
      <div className="deleteParent">
        <div className="imageSection">
          <img src={getImagePath(imageUrls.confirmIcon)} alt="Are you sure" />
        </div>
        <h2> {i18n.t('common.noBreadcrumbData')} </h2>
        <div className="deletePopupBtn">
          <div className="deletebtn">
            <Button
              label={i18n.t('common.ok')}
              type={'submit'}
              onClick={() => {
                closeModalEmptyData();
              }}
            ></Button>
          </div>
        </div>
      </div>
    )
  });

  const columns = [
    {
      id: 'unit_id',
      numeric: false,
      disablePadding: true,
      label: i18n.t('vehicles.unitId'),
      needSorting: true
    },
    {
      id: 'driversList',
      numeric: true,
      disablePadding: false,
      label: i18n.t('vehicles.drivers'),
      needSorting: true
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: i18n.t('vehicles.type'),
      needSorting: true
    },
    {
      id: 'vin',
      numeric: false,
      disablePadding: false,
      label: i18n.t('vehicles.vin'),
      needSorting: true
    },
    {
      id: 'year',
      numeric: true,
      disablePadding: false,
      label: i18n.t('vehicles.year'),
      needSorting: true
    },
    {
      id: 'manufact',
      numeric: false,
      disablePadding: false,
      label: i18n.t('vehicles.manufacturer'),
      needSorting: true
    },
    {
      id: 'model',
      numeric: false,
      disablePadding: false,
      label: i18n.t('vehicles.model'),
      needSorting: true
    },
    {
      id: 'actions',
      label: i18n.t('Actions')
    }
  ];
  const generateRows = () => {
    const updatedList = vehicles.vehicleFilteredList.map((item, index) => {
      item.driversList = (
        <div
          className="driverNamePopup"
          onClick={() => openDriverNamePopup(item.drivers, item.unit_id)}
        >
          {item.driversCount}
        </div>
      );
      return item;
    });
    return updatedList;
  };

  const openDriverNamePopup = (drivers, unit_id) => {
    // setDriver(unit_id);
    getDrivers({
      apiId: 'getDriversVehiclePage',
      data: {
        driverIds: drivers
      },
      callback: openDriverNamePopupModal,
      unit_id: unit_id
    });
  };
  const openDriverNamePopupModal = (type, res, req) => {
    let str = res?.response;
    let str_array = str.split(',');
    let array = [];
    for (var i = 0; i < str_array.length; i++) {
      array.push(str_array[i]);
    }
    setModalDriverName(true);
    return setModalDriverNameData({
      content: () => {
        return (
          <div className="driverNameModal">
            <div className="headWrapper">{i18n.t('DRIVERS OF')} {req?.unit_id}</div>
            <ul>
              {array.map((items, index) => {
                return <li key={index}>{items}</li>;
              })}
            </ul>
          </div>
        );
      }
    });
  };
  const hasUserListApiStatus = hasValue(apiStatus.status, 'api', fetchVehicles);
  const information =
    hasUserListApiStatus && hasUserListApiStatus.errorMessage ? (
      <Message
        type="error"
        message={i18n.t(hasUserListApiStatus.errorMessage)}
      />
    ) : (
      i18n.t('common.tableEmptyMessage')
    );

  return (
    <>
      <Styled.Wrapper>
        <Styled.MiddleWrapper>
          <div className="mapContainer">
            <Styled.MapWrapper>
              <GoogleMap
                type={vehiclePageMap.type}
                height={'700px'}
                data={vehiclePageMap}
              />
            </Styled.MapWrapper>
            <Styled.TrackWrapper>
              <div className="trackVehicleForm">
                <Form
                  apiStatus={apiStatus}
                  fetchVehiclePageHistoryMap={fetchVehiclePageHistoryMap}
                  history={history}
                  vehicles={vehicles}
                  driverIdSuggestion={driverIdSuggestion}
                  Styled={Styled}
                />
              </div>
            </Styled.TrackWrapper>
          </div>
          <Styled.TableWrapper>
            <SubHeader
              className="subheaderSection userHeading vehicleTable"
              text={i18n.t('vehicles.tabs.listVehicles')}
              variant="h2"
            />
            <div className="vehicleTable">
              <Table
                type="client"
                defaultSortBy={{ sortBy: 'unit_id', order: 'asc' }}
                title=""
                columns={[...columns]}
                data={generateRows()}
                emptyMessage={information}
                itemsPerPage={10}
                actions={[
                  {
                    icon: (row, index) => {
                      return (
                        <LinkLi
                          component={LinkDom}
                          to={pageUrls.editVehicle.replace(':id', row.id)}
                          onClick={() => {
                          }}
                          className="normalLink"
                        >
                          <Icon src={getImagePath(imageUrls.edit)} />
                        </LinkLi>
                      );
                    }
                  },
                  {
                    icon: (row, index) => {
                      return (
                        <Icon
                          src={getImagePath(imageUrls.delete)}
                          onClick={() => {
                            setModalListObject2({
                              content: () => (
                                <div className="deleteParent">
                                  <div className="imageSection">
                                    <img
                                      src={getImagePath(imageUrls.confirmIcon)}
                                      alt="Are you sure"
                                    />
                                  </div>
                                  <h2> {i18n.t('common.sureConfirm')} </h2>
                                  <p> {i18n.t('common.recoverConfirm')}</p>
                                  <div className="deletePopupBtn">
                                    <div className="cancelBtn">
                                      <Button
                                        label={i18n.t('Cancel')}
                                        type={'reset'}
                                        onClick={() => {
                                          closeModalDelete();
                                        }}
                                      ></Button>
                                    </div>
                                    <div className="deletebtn">
                                      <Button
                                        label={i18n.t('common.deletePopup')}
                                        onClick={() => {
                                          handleDeleteAlert({
                                            id: 'deleteAlert',
                                            data: { id: row.id },
                                            callback: callback
                                          });
                                          closeModalDelete();
                                        }}
                                      ></Button>
                                    </div>
                                  </div>
                                </div>
                              )
                            });
                            clickedModalDelete();
                          }}
                        />
                      );
                    }
                  }
                ]}
                defaultSort={'full_name'}
                i18n={i18n}
                pageinationReset={pagination}
                pagenationClicked={(e) => {
                  setPagination(!e);
                }}
              />
            </div>
          </Styled.TableWrapper>
        </Styled.MiddleWrapper>

        <Styled.BottomWrapper>
          <div className="vehicleLists">
            <p className="title">{i18n.t('vehicles.tabs.roadInspection')}</p>
            <RoadsideInspections
              history={history}
              openDocument={openDocument}
              i18n={i18n}
            />
          </div>
          <div className="vehicleLists">
            <p className="title">{i18n.t('vehicles.tabs.listTrailers')}</p>
            <Trailers
              history={history}
              callback={callback}
              trailers={vehicles?.trailerFilteredList}
              apiStatus={apiStatus}
              handleDeleteAlert={handleTrailerDeleteAlert}
            />
          </div>
        </Styled.BottomWrapper>
      </Styled.Wrapper>

      <Modal
        open={openModalRef2}
        setOpen={setModalRef2}
        data={ModalListObject2}
        deletePopup
      />

      <Modal
        open={openModalRefEmptyData}
        setOpen={setModalRefEmptyData}
        data={ModalListObjectEmptyData}
        deletePopup
        width={'450px'}
      />
      <Modal
        open={openModalDriverName}
        setOpen={setModalDriverName}
        data={ModalDriverNameData}
        deletePopup
        withCloseIcon
        alignItemsLeft
        width={'260px'}
      />
    </>
  );
};

Component.propTypes = {
  callback: PropTypes.func.isRequired,
  vehicles: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  fetchVehicles: PropTypes.func.isRequired,
  handleDeleteAlert: PropTypes.func.isRequired,
  filterSuccess: PropTypes.func.isRequired,
  handleExportVehicle: PropTypes.func.isRequired,
  handleImportVehicle: PropTypes.func.isRequired,
  handleImportTrailer: PropTypes.func.isRequired,
  handleImportVehiclePost: PropTypes.func.isRequired,
  handleImportTrailerPost: PropTypes.func.isRequired,
  fetchVehiclePageMap: PropTypes.func.isRequired,
  fetchVehiclePageHistoryMap: PropTypes.func.isRequired,
  vehiclePageMap: PropTypes.func.isRequired
};
