import {
  fuelAddEditApi
} from '../../../config/apis';

const initialState = [];

export const refillsHistory = {
  state: [...initialState],
  reducers: {
    listSuccess(state, payload) {
      return [...payload];
    },
    listFailed() {
      return [...initialState];
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload, rootState) {
      try {
        const getApi = await import(
          `../../../config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('getRefilsHistoryApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...apiObject,
              url: apiObject.url.replace('{id}', payload.data.id)
            }
          },
          id: payload.apiId
        });
        response && this.listSuccess(response);
      } catch (error) {
        this.listFailed();
      }
    },

    async saveData(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
               url:  `${fuelAddEditApi?.url}?fuelDto=${encodeURIComponent(JSON.stringify(payload?.param)) || ''}`,
               type: fuelAddEditApi.type,
               isFormData: fuelAddEditApi.isFormData
            },
            data: payload?.data?.data
          },
          id: payload.apiId,
          callback: payload.callback
        });
        response && this.fetchData({ apiId: 'getRefilsHistoryApi', data: { id: payload?.refillHistoryId } });
      } catch (error) {
        // this.saveFailed();
      }
    },
  })
};
