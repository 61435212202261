import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const LogBookContainer = styled(Box)`
  ${({ theme }) => `
  // padding: 3%;
  .uppercase {
    text-transform: uppercase;
  }
  .logsInfoDisplay {
    font-size: 20px;
    padding-top: 4px;
    padding-bottom: 10px;
  }

  #regeneratePdf-wrapper {
    position: relative;
    #regeneratePdf {
      color: ${theme.colors.LINK_COLOR};
      position: absolute;
      right: 0;
      background: none !important;
      text-decoration: none; 
      padding: 5px !important;
    }
    
    #regeneratePdf:hover {
      text-decoration: underline; 
    }
  }
  .react-datepicker__day:not(.react-datepicker__day--highlighted):not(.react-datepicker__day--disabled) {
    border-bottom: 2px solid #0379fc !important;
    color: ${theme.colors.PRIMARY_CONTRAST};
  }
  .react-datepicker__day--highlighted{
    border-bottom: 2px solid #f7b946 !important;
    color: ${theme.colors.PRIMARY_CONTRAST} !important;
  }


  .buttonBulkDownload {
    padding: 2px 30px !important;
    margin-top: 16px;
    min-height: 45px;
    font-size: 23px;
    @media (max-width: ${theme.breakpoints.values.mlg}px) {
      padding: 2px 22px !important;
      font-size: 21px;
    }
    @media (max-width: ${theme.breakpoints.values.md - 1}px) {
      padding: 2px 20px !important;
      font-size: 21px;
      margin-top: 0;
    }
  }
  table {
    min-width: auto;
  }
  a {
    background-color: ${
      (theme.palette.primary || {}).main || theme.colors.PRIMARY_MAIN
    };
    color: ${
      (theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN
    };
    padding: 5px 30px;
    font-size: 20px;
  }
  a:hover {
    text-decoration: none;
  }
  form {
    flex: 0 0 50%;
    @media (max-width: ${theme.breakpoints.values.md}px) {
    }
    @media (min-width: ${theme.breakpoints.values.mlg}px) and (max-width: ${
    theme.breakpoints.values.xl
  }px) {
      flex: 0 0 58%;
    }
    @media (max-width: ${theme.breakpoints.values.mlg - 1}px) {
      flex: 0 0 70%;
    }
    justify-content: flex-start;
    .vertical {
      flex: 0 0 35%;
      @media (max-width: ${theme.breakpoints.values.md - 1}px) {
        flex: 0 0 100%;
      }
    }
    .vertical-button {
      flex: 0 0 20%;
      .MuiButtonBase-root {
        padding: 1px 0;
        margin-top: 16px;
      }
    }
  }
  form + .MuiBox-root {
    flex: 0 0 30%;
  }
  .ItemsContainer {
    flex: 0 0 85%;
  }
  .ButtonHolder {
    align-items: flex-end;
    padding-bottom: 16px;
    justify-content: flex-start;
    span {
      padding: 2px 15px;
    }
  }
  .viewContainer {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
    justify-content: flex-start;
    .MuiBox-root-277 {
      display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
      align-items: flex-end;
      padding-bottom: 16px;
      .MuiButton-outlined {
        padding: 2px 15px;
      }
      .MuiButton-root:hover {
        background-color: ${
          (theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN
        };
      }
    }
    @media (max-width: ${theme.breakpoints.values.md}px) {
      flex-direction: column;
    }
    .vertical-button .MuiBox-root {
      margin-bottom: 20px;
      margin-top: 0px;
    }
  }

  .displayContainer {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
flex-direction: column;
    justify-content: space-between;
    @media (max-width: ${theme.breakpoints.values.md}px) {
      flex-direction: column;
    }
    @media (min-width: ${theme.breakpoints.values.md}px) and (max-width: ${
    theme.breakpoints.values.lg
  }px) {
      flex-direction: column;
    }
  }
  .leftWrapper {
    flex: 0 0 98%;
    // max-width: 830px;
  }
  .rightSide {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
    // flex-direction: column;
    flex-direction: row;
    justify-content: space-between;
    flex: 0 0 98%;
    div[type='info'] p {
      text-align: center;
      padding: 10px 0;
    }
    @media (max-width: ${theme.breakpoints.values.lg}px) {
      flex-direction: column;
    }
  }
  .pdfViolationsContainer {
    display: flex;
  }
  .violationsContainer {
    // max-height: 720px;
    margin-top: 15px;
    margin-left: 20px
    flex: 0 0 30%;
  }
  .pdfLinkContainer {
    flex: 0 0 70%;
    max-width: 830px;
  }
  // .logEditButtons {
  //   display: flex;
  //   flex-wrap: wrap;
  // }
  .linkButtonsContainer {
    display: flex;
    justify-content: space-between !important;;
  }
  // .logEditButton {
  //   padding-left: 10px !important;
  //   padding-right: 10px !important;
  // }
  .linkContainer {
    margin-bottom: 20px;
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
    justify-content: flex-end;
    // margin-top: 25px;
    .prev,
    .next {
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        margin-left: 0;
      }
    }
    .MuiLink-root {
      @media (min-width: ${
        theme.breakpoints.values.lg + 1
      }px) and (max-width: ${theme.breakpoints.values.xl - 1}px) {
        padding: 5px 20px;
      }
    }
    div {
      display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
      margin-left: 10px;
      @media (max-width: ${theme.breakpoints.values.md}px) {
        margin-left: 4px;
      }
    }

    @media (max-width: ${theme.breakpoints.values.md}px) {
      justify-content: flex-start;
      a#button {
        padding: 6px;
        font-size: 12px;
      }
    }
    @media (min-width: ${theme.breakpoints.values.md}px) and (max-width: ${
    theme.breakpoints.values.lg
  }px) {
      justify-content: flex-start;
    }
  }
  .next {
    justify-content: flex-end;
    flex: 0 0 50%;
  }
  .prev {
    justify-content: flex-start;
    flex: 0 0 50%;
  }
  table {
min-width: auto !important;
}
  `}
`;
const PdfContainer = styled(Box)`
  ${({ theme }) => `
  width: 100%;
  .heading {
    padding: 5px;
    color: ${
      (theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN
    };
    background-color: ${
      (theme.palette.primary || {}).main || theme.colors.PRIMARY_MAIN
    };
  }
  .events{
    margin-top: 10px;
    display: grid;
    column-gap: 15px;
    row-gap: 15px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .eventContainer { 
    border: 2px solid ${theme.colors.PRIMARY_BORDER};
    ul {
      list-style: none;
      border-bottom: 1px solid ${theme.colors.PRIMARY_BORDER};
      padding: 5px 0;
      margin: 0;
      padding-left: 10px;
    }
  }
  .name {
    color: ${theme.colors.HEADER_TEXT};
    font-size: 16px;
    font-weight: 600;
    padding-right: 6px;
  }
  .message {
    width: 100%;
    color: ${theme.colors.HEADER_TEXT};
    font-size: 14px;
    font-weight: 400;
    display: block;
  }
  .pdfWrap {
    margin-top: 15px;
    margin-bottom: 40px;
    @media (max-width: ${theme.breakpoints.values.md}px) {
      iframe {
        height: 350px;
      }
    }
  }
  `}
`;
const MapContainer = styled(Box)`
  flex: 0 0 48%;
`;
const MapWrapper = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 17px;
  margin-bottom: 10px;
`;
const ViolationContainer = styled(Box)`
  ${({ theme }) => `
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
flex: 0 0 48%;
display: flex;
  border: 2px solid
    ${(theme.palette.primary || {}).border || theme.colors.PRIMARY_BORDER};
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
  flex-direction: column;
  .heading {
    padding: 5px;
    color: ${
      (theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN
    };
    background-color: ${
      (theme.palette.primary || {}).main || theme.colors.PRIMARY_MAIN
    };
  }
  p {
    color: ${
      (theme.palette.primary || {}).contrastText ||
      theme.colors.PRIMARY_CONTRAST
    } !important;
  }
  `}
`;

const LogbookWrap = styled(Box)`
  overflow: hidden;
  position: relative;
`;
const Styled = {
  LogBookContainer,
  PdfContainer,
  MapContainer,
  MapWrapper,
  ViolationContainer,
  LogbookWrap
};

export default Styled;
