import * as parent from '../../variables';

export const colors = {
  ...parent.colors,
  PRIMARY_MAIN: '#fff',
  BACKGROUND: '#efefef',
  HEADER_COUNT_TEXT: '#373737',
  PRIMARY_CONTRAST: '#373737',
  SECONDARY_MAIN: 'rgb(45, 57, 66)',
  PUBLIC_HEADERCOLOR: '#061c3f',
  LANGUAGE_SWITCH: '#1589ee',
  HEADER_TEXT: '#f4f6f9',
  BODY_BACKGROUND: '#fff',
  MODAL_BACKGROUND: '#fff',
  MODAL_BORDER: '#d9d9d9',
  MODAL_LINK: '#1589ee',
  LINK_COLOR: '#000',
  HEADER_COLOR: '#1589ee',
  CHECKBOX_TICK: '#000',
  ACTIVE_ROW:'rgba(247, 247, 247, 0.7)',
  PAGINATION_BACKGROUND: '#fff',
  PAGINATION_COLOR: '#000',
  SELECTED_AREA: 'rgba(32, 177, 232, 0.6)',
  BACKGROUND_MENU:'#f4f6f9'
};
export const fonts = ['"Helvetica Neue", Helvetica, Arial, sans-serif'].join(',');
export const titleFont = 'Yanone Kaffeesatz';
