import React, { useEffect, lazy, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SubHeader from '../../../components/SubHeader/Clutch';


import { COOKIE_AUTH_DETAILS, pageUrls } from '@/config/constants/keys';
import AuthContext from '@/services/auth';

import ParentStyled from './style';
import { loadStyle } from '@/utils/utils';
import { getCookie } from '@/services/cookie';
let Styled = loadStyle(ParentStyled, 'pages/web/ELDXUnhandled');

const UnhandledRoute = (parentProps) => {
  const { history, i18n, fetchLogin, clearRedux } = parentProps;
  const auth = useContext(AuthContext);
  const token = getCookie(COOKIE_AUTH_DETAILS);
useEffect(() => {
  if (auth?.auth) {
    history.push(pageUrls.dashboard)
  }
},[])




  return (
    <Styled.Wrapper>
      <div className="contentWrap">
        <Styled.ContentWrapper >
          <div className="wrapBox">
            <SubHeader
              variant="h2"
              text={i18n.t('Please Login through appropriate link!')}
              mode="light"
              className="ContentHeader"
            />
          </div>
        </Styled.ContentWrapper>
      </div>
    </Styled.Wrapper>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    login: state.login
  };
};
const mapDispatch = (dispatch) => {
  return {
    clearRedux: () => dispatch({ type: 'RESET_APP' })
  };
};

export default connect(mapState, mapDispatch)(UnhandledRoute);

UnhandledRoute.propTypes = {
  login: PropTypes.object.isRequired,
  apiStatus: PropTypes.object.isRequired,
  fetchLogin: PropTypes.func.isRequired
};
