import styled from 'styled-components';
import { Form } from 'formik';
import Box from '@material-ui/core/Box';

import { getStyle } from '../../../../utils/utils';

const FormWrapper = styled(Form)`
  ${({ theme, direction, style }) => `

.vertical {
  flex-direction: column;
  margin-right: 2%;

}
input:-internal-autofill-selected {
  color: ${theme.colors.PRIMARY_CONTRAST}!important;
}
.vertical-button {
  .MuiBox-root{
    width: auto;
    margin-right: 0rem;
  }
  button {
    .MuiButton-label {
      padding: 2px 40px ;
    }

  }
  @media (max-width: ${theme.breakpoints.values.sm}px) {
    justify-content: flex-start;
  }
}
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  flex-direction: ${direction || 'row'};
  flex-wrap: ${direction === 'row' ? 'wrap' : 'no-wrap'};
  justify-content: space-between;
  .ButtonHolder {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    justify-content: flex-end
  }
  .MuiTypography-h1 {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .MuiButtonBase-root {
    width: 100%;
    color: ${theme.colors.WHITE};
    font-size: 23px;
    border-radius: 0px;
  }

  .MuiButton-root:hover {
    text-decoration: none;
    background-color: ${
      (theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN
    };
  }
  .react-datepicker__input-container input:disabled{
    opacity: 0.5;
  }
  .react-datepicker-popper {
    z-index: 9999 !important;
  }
  .react-datepicker-wrapper {
    width: 100%;
    min-height: 45px;
    height: 45px;
  }
  .react-datepicker__month,
  .react-datepicker {
     background-color: ${theme.colors.PRIMARY_MAIN};
  }
  .react-datepicker__header {
    padding-top: 2px;
    border-bottom: none;
      background-color: ${theme.colors.PRIMARY_MAIN};
  }
  .react-datepicker__current-month,
  .react-datepicker__day-name,
  .react-datepicker__day {
    color: ${getStyle(
      (style || {}).color,
      (theme.palette.primary || {}).contrastText,
      theme.colors.PRIMARY_CONTRAST
    )};
  }
  .react-datepicker__day {
    border: 1px solid #8c8787 !important;
    padding-left: 9px;
  }
  .react-datepicker__input-container {
    height: 40px;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__day-name {
    font-weight: 700;
  }
  .react-datepicker__current-month {
    border: 1px solid #8c8787;
    height: 30px;
    border-radius: 4px;
    width: 97%;
    margin: 0 auto;
    padding-top: 5px;
  }

  .react-datepicker__day:hover {
    border-radius: 0px !important;
    background-color: ${
      (theme.palette.primary || {}).main || theme.colors.PRIMARY_MAIN
    };
    // color: ${theme.colors.WHITE};
  }
  .react-datepicker__day--selected {
    border-radius: 0px !important;
    background-color: ${
      (theme.palette.primary || {}).main || theme.colors.PRIMARY_MAIN
    }!important;
  }
  .react-datepicker__day--outside-month {
    visibility: hidden;
  }
  .react-datepicker__day--disabled,
  .react-datepicker__navigation--next--disabled,
  .react-datepicker__navigation--previous--disabled {
    opacity: 0.35;
  }
  .react-datepicker__day--today {
    background-color: ${
      (theme.palette.primary || {}).main || theme.colors.PRIMARY_MAIN
    }!important;
    color: ${
      (theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN
    }!important;
  }
  .react-datepicker__day--keyboard-selected {
    border-radius: 0px;
  }
  .css-1okebmr-indicatorSeparator {
    background-color: transparent;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    line-height: 1.9em;
    margin: 0.05em;
    height: 1.8em;
  }
  .react-datepicker__current-month {
    font-size: 1em;
  }
  .react-datepicker__navigation {
    line-height: 1.7em;
  }
  .react-datepicker-popper[data-placement^='bottom'] {
    margin-top: 0px;
  }
  .select__menu {
    margin-top: 2px !important;
    z-index: 1000;
  }
  .select__placeholder + div {
    color: ${getStyle(
      (style || {}).color,
      (theme.palette.primary || {}).contrastText,
      theme.colors.PRIMARY_CONTRAST
    )}
    font-family: ${(theme.palette || {}).fontFamily};
  }
  .css-1wa3eu0-placeholder {
    font-size: 18px;
  }
  .react-datepicker-popper[data-placement^='bottom'] {
    margin-top: 0px;
  }
  .react-datepicker-time__header {
    color: ${getStyle(
      (style || {}).color,
      (theme.palette.primary || {}).contrastText,
      theme.colors.PRIMARY_CONTRAST
    )}
  }
  .react-datepicker__time {
    background-color: ${
      (theme.palette.primary || {}).main || theme.colors.PRIMARY_MAIN
    }!important;
    color: ${getStyle(
      (style || {}).color,
      (theme.palette.primary || {}).contrastText,
      theme.colors.PRIMARY_CONTRAST
    )}
  }
  .react-datepicker__time-list {
    height: 177px !important;
    background-color: ${
      (theme.palette.primary || {}).main || theme.colors.PRIMARY_MAIN
    }!important;
  }
  .react-datepicker__time-list-item {
    border-top: 1px solid #8c8787 !important;
    border-right: 1px solid #8c8787 !important;
    height: 15px !important;
  }
  .react-datepicker__header--time {
    height: 32px;
    border: 1px solid #8c8787 !important;
  }
  li.react-datepicker__time-list-item:hover {
    cursor: pointer;
    background-color: ${
      (theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN
    }!important;
    color: white;
  }
  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 95px !important;
  }
  .react-datepicker__time-list-item--selected {
    background-color: ${theme.colors.LIGHT_BORDER} !important;
    color: ${
      (theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN
    }!important;
  }
  li.react-datepicker__time-list-item--selected:hover {
    background-color: ${theme.colors.LIGHT_BORDER} !important;
    color: white !important;
  }
  .react-datepicker__day--keyboard-selected {
    background-color: transparent;
  }
  .react-datepicker__header__dropdown {
    padding: 5px 0;
  }
  .react-datepicker__month {
    margin: 0.2rem;
    margin-bottom: .3rem;
  }
  .react-datepicker__year-select{
    overflow-y: scroll !important;
  }
  .react-datepicker__year-select::-webkit-scrollbar {
    display: none;
  }
  .react-datepicker__month-select,
  .react-datepicker__year-select {
    background-color: ${
      (theme.palette.primary || {}).main || theme.colors.PRIMARY_MAIN
    }!important;
    color: ${getStyle(
      (style || {}).color,
      (theme.palette.primary || {}).contrastText,
      theme.colors.PRIMARY_CONTRAST
    )}
    font-family: ${(theme.palette || {}).fontFamily};
    margin-top: 1px;
    cursor: pointer;
    border: 1px solid #8c8787;
    border-radius: 1px;
    padding:8px;
  }
  .react-datepicker-wrapper input {
    width: 100%;
    height: 45px;
    padding-left: 10px;
    background: ${
      (theme.palette.primary || {}).main || theme.colors.PRIMARY_MAIN
    };
      none repeat scroll 0 0;
    border: 2px solid
    ${(theme.palette.primary || {}).border || theme.colors.PRIMARY_BORDER};
    color: ${getStyle(
      (style || {}).color,
      (theme.palette.primary || {}).contrastText,
      theme.colors.PRIMARY_CONTRAST
    )} ;
    font-family: ${(theme.palette || {}).fontFamily};
    font-size: ${(theme.palette || {}).bodyFontSize || '18px'};
  }
  .react-datepicker__close-icon::after {
    background-color: ${
      (theme.palette.primary || {}).border || theme.colors.PRIMARY_BORDER
    };
  }
  .react-datepicker__close-icon {
    top: 10px;
  }
  `}
`;

const FieldsWrapper = styled(Box)`
  ${({ theme, width }) => `

  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  margin-bottom: 18px;
  width: ${({ width }) => {
    return width ? width : '30px';
  }};
  @media (max-width: ${theme.breakpoints.values.smd}px) {
    width: ${width ? '100%' : '30px'}!important;
  }
  .MuiFormLabel-root {
    padding-bottom: 5px;
  }
  .MuiFormLabel-root,
  p {
    flex: 0 0 25%;
    font-size: 20px;
    line-height: 25px;
    @media (max-width: ${theme.breakpoints.values.md}px) {
      font-size: 14px;
    }
  }
  .yearSelect {
    flex: 0 0 30%;
  }
  .roleWrapper {
    flex-wrap: wrap;
  }
  div[type="error"] p {
    font-size: 17px;
  }
  `}
`;
const TextWrapper = styled(Box)`
  ${({ theme }) => `

  flex: 0 0 75%;
  .MuiInputBase-input.Mui-disabled {
    color: ${theme.colors.GREY_ELEVEN};
    opacity: 0.5;
  }
  `}
`;
const TextareaWrapper = styled(Box)`
  width: 100%;
`;
const ButtonsWrapper = styled(Box)`
  ${({ theme }) => `

  justify-content: flex-end;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  .MuiCircularProgress-root {
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .MuiButtonBase-root {  
    margin-top: 20px;
    padding: 1px 30px;
    font-size: 23px;
    height: 45px;
  }
  .vehicleSubmit {
    width: 100%;
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    justify-content: flex-end;
    div:first-child {
      margin-right:1%;
    }
    #button {
      @media (min-width: ${theme.breakpoints.values.md}px) {
        min-width:210px;
        padding: 1px 40px;
      }
    }
  }
  `}
`;

const TitleWrapper = styled(Box)`
  flex: 0 0 100%;
`;
const CheckBoxWrapper = styled(Box)`
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  flex-direction: ${({ direction }) => direction};
`;
export const FormStyle = {
  TitleWrapper,
  ButtonsWrapper,
  FieldsWrapper,
  TextWrapper,
  TextareaWrapper,
  CheckBoxWrapper,
  FormWrapper
};
