import styled from 'styled-components';
import Parent from './style';

export const Wrapper = styled(Parent.Wrapper)`
  .appliedBreadcrumb {
    font-size: 14px;
  }
  .breadCrumbTag {
      font-size: 12px;
      ${window?.$environment?.clutchNewUI && `border: 2px solid #455560;`}
      :before{
        ${window?.$environment?.clutchNewUI && `border-left: 12px solid #455560;`}
      }
  }
`;

const Styled = {
  Wrapper
};
export default Styled;
