import React, { lazy } from 'react';
import { Field } from 'formik';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Select = lazy(() => import(`@/components/UI/Select/${layout}`));
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const Department = ({
  values,
  handleEdit,
  i18n,
  fetchTerminals,
  fetchDepartments,
  fetchSubDepartments,
  regions,
  terminals,
  departments,
  subDepartments,
  errors,
  disabledCriteria,
  isNotValid,
  viewOnly
}) => {
  return (
    <>
      <FormStyle.default.FieldsWrapper width="100%" className="filterFields">
        <Label variant="body1" className="filterLabel">
          {i18n.t('common.region')}
        </Label>
        <FormStyle.default.TextWrapper className="filterField">
          <Field
            name="region"
            onChange={(event) => {
              fetchTerminals({
                apiId: 'fetchTerminal',
                data: { id: event?.value }
              });
              handleEdit(event, { field: 'regionId' });
              handleEdit('', { field: 'terminalId' });
              handleEdit('', { field: 'departmentId' });
              handleEdit('', { field: 'subDepartmentId' });
            }}
            as={Select}
            isDisabled={disabledCriteria?.regionId}
            placeholder={i18n.t('common.pleaseSelect')}
            value={values.regionId}
            suggestions={regions}
            isClearable={true}
            disabled={viewOnly}
          />
          {errors?.regionId && !isNotValid && (
            <Message type="error" message={errors.regionId} />
          )}
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>

      <FormStyle.default.FieldsWrapper width="100%" className="filterFields">
        <Label variant="body1" className="filterLabel">
          {i18n.t('common.terminal')}
        </Label>
        <FormStyle.default.TextWrapper className="filterField">
          <Field
            name="terminal"
            onChange={(event) => {
              fetchDepartments({
                apiId: 'fetchDepartments',
                data: { id: event?.value }
              });
              handleEdit(event, { field: 'terminalId' });
              handleEdit('', { field: 'departmentId' });
              handleEdit('', { field: 'subDepartmentId' });
            }}
            as={Select}
            placeholder={i18n.t('common.pleaseSelect')}
            isDisabled={disabledCriteria?.terminalId}
            value={values.terminalId}
            suggestions={values.regionId ? terminals : []}
            isClearable={true}
            disabled={viewOnly}
          />
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>

      <FormStyle.default.FieldsWrapper width="100%" className="filterFields">
        <Label variant="body1" className="filterLabel">
          {i18n.t('common.department')}
        </Label>
        <FormStyle.default.TextWrapper className="filterField">
          <Field
            name="department"
            onChange={(event) => {
              fetchSubDepartments({
                apiId: 'fetchDSubDepartments',
                data: { id: event?.value }
              });
              handleEdit(event, { field: 'departmentId' });
              handleEdit('', { field: 'subDepartmentId' });
            }}
            as={Select}
            placeholder={i18n.t('common.pleaseSelect')}
            isDisabled={disabledCriteria?.departmentId}
            value={values.departmentId}
            suggestions={values.terminalId ? departments : []}
            isClearable={true}
            disabled={viewOnly}
          />
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>

      <FormStyle.default.FieldsWrapper width="100%" className="filterFields">
        <Label variant="body1" className="filterLabel">
          {i18n.t('common.location')}
        </Label>
        <FormStyle.default.TextWrapper className="filterField">
          <Field
            name="location"
            onChange={(event) =>
              handleEdit(event, { field: 'subDepartmentId' })
            }
            as={Select}
            placeholder={i18n.t('common.pleaseSelect')}
            isDisabled={disabledCriteria?.subDepartmentId}
            value={values.subDepartmentId}
            suggestions={values.departmentId ? subDepartments : []}
            isClearable={true}
            disabled={viewOnly}
          />
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
    </>
  );
};

export default Department;
