import React, { useEffect, lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { getNewObject, loadPageConfig, isdvirOnly, hasRestrictedRole } from '@/utils/utils';
import { useParams } from 'react-router-dom';
import { ROLES } from '@/config/constants/keys'
import AssignWorkOrder from 'components/modules/WorkOrdersManagement/AssignWorkOrder';

import ParentStyled from './style';
import { loadStyle } from '@/utils/utils';
import { pageUrls } from '@/config/constants/keys';

let Styled = loadStyle(ParentStyled, 'components/modules/EditWorkOrders');


const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.editWorkOrder[0].name}`));
const Content = lazy(() => import(`@/${config.editWorkOrder[1].name}`));
const layout = window?.$environment?.CURRENT_LAYOUT;
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Modal = require(`@/components/UI/Modal/${layout}`).default;

const EditWorkOrders = (parentProps) => {
  const { fetchEditWorkOrderData, fetchServiceCenters, workOrders, reAssignrServiceCenter, callback, module  } = parentProps;
  const DefectList = lazy(() => import(`@/${config[module][2].name}`));
  const viewOnly = module === 'viewWorkOrders';
  const history = useHistory();
  const i18n = useTranslation();
  const theme = useTheme();
  const match = useParams();

  const commonParentProps = { history, i18n, theme };
  const fetchWoEditApi = 'fetchWoData';
  const isDVIROnlyFlagEnabled = isdvirOnly()

  const [openAssignWOForm, setOpenAssignWOForm] = useState(false)
  const [AddEditModalObject, setAddEditModalObject] = useState({
    header: '',
    content: () => {
          return null;
        }
  });
  const [open, setOpen] = useState(false);
  const [ModalObject, setModalObject] = useState({
    header: '',
    content: () => {
      return null;
    }
  });
  const closeModal = (event) => {
    setOpen(false);
  };
  const [pagination, setPagination] = useState(false);
  useEffect(() => {
    let url = isDVIROnlyFlagEnabled? hasRestrictedRole([ROLES.SERVICE_MANAGER])? pageUrls.unassignedInspections: pageUrls.pendingMechanicInspections: pageUrls.workOrders;

    fetchEditWorkOrderData(
      {
      data: { id: match.id },
      apiId: fetchWoEditApi,
      callback,
      history,
      redirectionUrl: url 
    });
    fetchServiceCenters('');
    // getDocumentToken({ apiId: 'getDocToken' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
    <Styled.TitleWrapper>
      <RenderComponent
        {...config[module][0]}
        header = {isDVIROnlyFlagEnabled? 'workOrders.addHeadingDvir': 'workOrders.addHeading' }
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        component={Title}
      />
       {isDVIROnlyFlagEnabled && hasRestrictedRole([ROLES.SERVICE_MANAGER]) && (<Button
            type="submit"
            className="buttonStyle"
            label={i18n.t('workOrders.assignToServiceCeenter')}
            onClick={() => {
              setAddEditModalObject({
                header: '',
                content: () => {
                      return (
                        <AssignWorkOrder
                        serviceCenters = {workOrders?.serviceCenters}
                        fetchServiceCenters = {fetchServiceCenters}
                        currentItemId={workOrders?.getData?.workOrderEditDto?.idPub}
                        reAssignrServiceCenter={reAssignrServiceCenter}
                        setOpenAssignWOForm={setOpenAssignWOForm}
                        callback={callback}
                        history={history}
                        currentItemState={workOrders?.getData?.workOrderEditDto?.state}
                        />
                      );
                    }
              })
              setOpenAssignWOForm(true) }}
          />)}
      </Styled.TitleWrapper>
      <RenderComponent
        {...config[module][1]}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        component={Content}
        ModalObjectPhoto={ModalObject}
        setModalObjectPhoto={setModalObject}
        setOpenPhoto={setOpen}
        open={open}
      />
        <Modal
        open={openAssignWOForm}
        setOpen={setOpenAssignWOForm}
        data={AddEditModalObject}
        background="black"
        withCloseIcon
      />
        <Modal
        open={open}
        setOpen={setOpen}
        data={ModalObject}
        onCloseModal={closeModal}
        background="black"
        inspectionPopup
        withCloseIcon
      />
    </>
  );
};

const RenderComponent = ({
  name,
  props,
  commonProps,
  title,
  parentProps,
  commonParentProps,
  component,
  ...rest
}) => {
  const Component = component;
  let newProps = props?.length ? getNewObject(parentProps, props) : {};
  newProps = {
    ...newProps,
    ...(commonProps?.length ? getNewObject(commonParentProps, commonProps) : {})
  };
  return <Component {...rest} {...newProps} />;
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    regions: state.regions,
    documents: state.documents,
    user: state.user,
    logBook: state.logBook,
    workOrders: state.workOrdersEdit,
    receipt: state.viewReport
  };
};
const mapDispatch = (dispatch) => {
  return {
    saveDocument: (data) => dispatch.workOrdersEdit.saveDocument(data),
    deleteDocument: (data) => dispatch.workOrdersEdit.deleteDocument(data),
    fetchCountData: (data) => dispatch.dashboard.fetchCountData(data),
    downloadDocument: (data) => dispatch.viewReport.downloadReport(data),
    getDocumentToken: (data) => dispatch.logBook.documentToken(data),
    handleLogout: (data) => dispatch.login.handleLogout(data),
    fetchAddWorkOrders: (data) => dispatch.workOrdersEdit.fetchAddData(data),
    saveAddWorkOrders: (data) => dispatch.workOrdersEdit.saveAddData(data),
    fetchEditWorkOrderData: (data) =>
      dispatch.workOrdersEdit.fetchEditWorkOrderData(data),
      viewReport: (data) => dispatch.viewReport.viewReportDocument(data),
      clearState:(data)=> dispatch.viewReport.clearReceipt(),
    fetchServiceCenters: (data)=> dispatch.workOrdersEdit.fetchServiceCenterList(data),
    reAssignrServiceCenter: (data) => dispatch.workOrdersEdit.reAssignrServiceCenter(data),
  };
};

export default connect(mapState, mapDispatch)(EditWorkOrders);
EditWorkOrders.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  fetchTerminals: PropTypes.func.isRequired,
  fetchDepartments: PropTypes.func.isRequired,
  saveDocument: PropTypes.func.isRequired,
  deleteDocument: PropTypes.func.isRequired,
  fetchUserData: PropTypes.func.isRequired,
  saveUserData: PropTypes.func.isRequired,
  fetchSubDepartments: PropTypes.func.isRequired
};
