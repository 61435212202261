import { forgotPasswordApi } from '../../../config/apis';
import { FORGOT_PASSWORD_MESSAGE } from '../../../config/constants/static';

const initialState = {};

export const forgotPassword = {
  state: { ...initialState },
  reducers: {
    setPasswordSuccess(state, payload) {
      return {
        ...payload
      };
    },
    setPasswordFailed() {
      return { ...initialState };
    }
  },
  effects: (dispatch) => ({
    async requestForgotPassword(payload) {
      try {
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            data: { ...payload.data },
            api: forgotPasswordApi
          },
          id: payload.apiId
        });
        dispatch.asyncRequests.setStatus({
          api: payload.apiId,
          errorMessage: FORGOT_PASSWORD_MESSAGE,
          isLoading: false
        });
      } catch (error) {
        this.setPasswordFailed();
      }
    }
  })
};
