import React, { useState, useEffect, lazy } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Link from '@material-ui/core/Link';

import { getImagePath, hasValue } from '@/utils/utils';
// import Styled from './style';
import { imageUrls } from '@/config/constants/keys';
import ParentStyled from './style';
import { loadStyle } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'components/modules/DocumentUploadList');
const deleteApi = 'deleteApi';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Upload = lazy(() => import(`@/components/modules/DocumentUpload`));
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Modal = require(`@/components/UI/Modal/${layout}`);
const beBaseUrl = window?.$environment?.BE_BASE_URL;

const List = ({
  list,
  saveDocument,
  deleteDocument,
  apiStatus,
  callback,
  userId,
  trailerIdPub,
  vehicleIdPub,
  isEdit,
  downloadDocument,
  logBook,
  viewOnly
}) => {
  const { i18n } = useTranslation();
  const [currentItem, setCurrentItem] = useState({});
  const [open, setOpen] = useState(false);
  const closeModal = (event) => {
    setOpen(false);
  };
  const uploadApi = 'uploadApi';

  const hasDocumentDeleteApi = hasValue(apiStatus?.status, 'api', deleteApi);
  const hasUploadApi = hasValue(apiStatus?.status, 'api', uploadApi);
  const clickedModal = (e) => {
    setOpen(true);
  };
  const [ModalListObject, setModalListObject] = useState({});
  useEffect(() => {
    setModalListObject({
      header: '',
      content: () => {
        return (
          <Upload
            closeModal={closeModal}
            saveDocument={saveDocument}
            currentItem={currentItem}
            uploadApi={uploadApi}
            callback={callback}
            userId={userId}
            trailerIdPub={trailerIdPub}
            vehicleIdPub={vehicleIdPub}
            isEdit={isEdit}
          />
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItem]);
  return (
    <Styled.Wrapper>
      {hasDocumentDeleteApi?.errorMessage && (
        <Message
          type="error"
          message={i18n.t(hasDocumentDeleteApi.errorMessage)}
        />
      )}
      {hasUploadApi?.errorMessage && (
        <Message type="error" message={i18n.t(hasUploadApi.errorMessage)} />
      )}
      <iframe id="downloadDocumentFrame" style={{ display: 'none' }}></iframe>
      {list.map((item, i) => {
        return (
          <Row
            key={i}
            item={item}
            openModal={clickedModal}
            deleteDocument={deleteDocument}
            setCurrentItem={setCurrentItem}
            userId={userId}
            trailerIdPub={trailerIdPub}
            vehicleIdPub={vehicleIdPub}
            isEdit={isEdit}
            downloadDocument={downloadDocument}
            logBook={logBook}
            viewOnly={viewOnly}
          />
        );
      })}
      <Modal.default
        open={open}
        setOpen={setOpen}
        data={ModalListObject}
        onCloseModal={closeModal}
        background="black"
        withCloseIcon
      />
    </Styled.Wrapper>
  );
};

export default List;

List.propTypes = {
  apiStatus: PropTypes.object,
  callback: PropTypes.func.isRequired
};

const Row = ({
  item,
  openModal,
  deleteDocument,
  setCurrentItem,
  userId,
  trailerIdPub,
  vehicleIdPub,
  isEdit,
  downloadDocument,
  viewOnly
}) => {
  const { i18n } = useTranslation();
  return (
    <>
      {(!viewOnly || (viewOnly && item.id)) &&
      (<li>
        <>
        {!viewOnly && 
          (<Icon
            onClick={() => {
              setCurrentItem(item);
              openModal();
            }}
            className="imageUploadsection"
            src={
              item.id
                ? getImagePath(imageUrls.updateIcon)
                : getImagePath(imageUrls.uploadIcon)
            }
          />)
          }
          {item.id && !viewOnly ? 
          <Icon
            onClick={() => {
              setCurrentItem(item);
              openModal();
            }}
            className="imageUploadsectionsp"
            src={getImagePath(imageUrls.updateIcon)}
          /> : null}
          {!viewOnly && 
          (<Link
            onClick={() => {
              setCurrentItem(item);
              openModal();
            }}
            className="uploadSection"
          >
            {item.id ? <span>{i18n.t('Update')}</span> : <span className="uploadText">{i18n.t('Upload')}</span>}
          </Link>)}
        </>
        {item.id || viewOnly ? null : <Label> {i18n.t(item?.type?.fullName)}</Label>}
        {item.id && (
          <>
            <Styled.UpdateWrapper>
              <Label className="details"> {i18n.t(item.type.fullName)}</Label>
              <Label className="uploadDetails">{i18n.t('Uploaded')} {item.uploaded} </Label>
              {item.expiration_date ? (
                <Label className="uploadDetails">
                  {i18n.t("Expiration")} {item.expiration_date}
                </Label>
              ) : null}
            </Styled.UpdateWrapper>
            <Styled.ItemUrl>
              <Link
                onClick={() => {
                  downloadDocument({
                    apiId: 'downloadReceipt',
                    data: item?.id
                  });
                }}
                className="downloadImage"
              >
                <>
                  <Icon
                    src={getImagePath(imageUrls.downloadIcon)}
                    onClick={() =>
                      downloadDocument({
                        apiId: 'downloadReceipt',
                        data: item?.id
                      })
                    }
                  />
                  {item.name}
                </>
              </Link>
            </Styled.ItemUrl>
            {!viewOnly && (<Icon
              src={getImagePath(imageUrls.deleteIcon)}
              onClick={() => {
                setCurrentItem(item);
                deleteDocument({
                  apiId: deleteApi,
                  data: { id: item.id },
                  config: {
                    userId: userId,
                    trailerIdPub: trailerIdPub,
                    vehicleIdPub: vehicleIdPub,
                    isEdit: isEdit
                  }
                });
              }}
              className="imageUploadsection"
            />)
            }
            { !viewOnly && (
            <Icon
              src={getImagePath(imageUrls.uploaddownloadIcon)}
              onClick={() => {
                setCurrentItem(item);
                deleteDocument({
                  apiId: deleteApi,
                  data: { id: item.id },
                  config: {
                    userId: userId,
                    trailerIdPub: trailerIdPub,
                    vehicleIdPub: vehicleIdPub,
                    isEdit: isEdit
                  }
                });
              }}
              className="imageUploadsectionsp"
            />)
          }
          </>
        )}
      </li>)
      }
    </>
  );
};
