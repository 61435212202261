import React from 'react';

import Styled from './style';
import Form from './Form';
import { useTranslation } from 'react-i18next';

const Component = ({
  apiStatus,
  history,
  clearApiStatus,
  getToCheckout,
  addSigneeName,
  getSigneeInfo,
  getCompanyInfoApi
}) => {
  const { i18n } = useTranslation();
  return (
    <Styled.Wrapper>
    <h1 className="stepTitle">Clutch ELD Terms of Service</h1>
    <div className="tcSection" id="scroll">
      <div className="scrollbar">
        {/* <label className="subHeading">{i18n.t('clutchTerms.introduction.heading')}</label> */}
        <p className="contentSection">{i18n.t('clutchTerms.introduction.intro1')}</p>
        <p className="contentSection">{i18n.t('clutchTerms.introduction.intro2')}</p>
        <p className="contentSection">{i18n.t('clutchTerms.introduction.intro3')}</p>
        <p className="contentSection ">{i18n.t('clutchTerms.introduction.intro4')}</p>
        <p className="contentSection ">{i18n.t('clutchTerms.introduction.intro5')}</p>
        <p className="contentSection ">{i18n.t('clutchTerms.introduction.intro6')}</p>
        <p className="contentSection ">{i18n.t('clutchTerms.introduction.intro7')}</p>
        <p className="contentSection ">{i18n.t('clutchTerms.introduction.intro8')}</p>
        <p className="contentSection ">{i18n.t('clutchTerms.introduction.intro9')}</p>
        <p className="contentSection ">{i18n.t('clutchTerms.introduction.intro10')}</p>
        <p className="contentSection ">{i18n.t('clutchTerms.introduction.intro11')}</p>
        <p className="contentSection ">{i18n.t('clutchTerms.introduction.intro12')}</p>
        <p className="contentSection ">{i18n.t('clutchTerms.introduction.intro13')}</p>
        <p className="contentSection ">{i18n.t('clutchTerms.introduction.intro14')}</p>

        <label className="subHeading">{i18n.t('clutchTerms.restrictions.heading')}</label>
        <p className='contentSection'>{i18n.t('clutchTerms.restrictions.warn')} <span className='capitalize'>{i18n.t('clutchTerms.restrictions.warnCap')}</span></p>
        <p className="contentSection">{i18n.t('clutchTerms.restrictions.listhead')}</p>
        <p className="contentSection ">{i18n.t('clutchTerms.restrictions.listing1')}</p>
        <p className="contentSection ">{i18n.t('clutchTerms.restrictions.listing2')}</p>
        <p className="contentSection ">{i18n.t('clutchTerms.restrictions.listing3')}</p>
        <p className="contentSection">{i18n.t('clutchTerms.restrictions.listing4')}<span className='capitalize'>{i18n.t('clutchTerms.restrictions.listing5')}</span></p>
        <p className="contentSection capitalize">{i18n.t('clutchTerms.restrictions.listing6')}</p>
        <p className="contentSection ">{i18n.t('clutchTerms.restrictions.listing7')}</p>
        <p className="contentSection">{i18n.t('clutchTerms.restrictions.listing8')}<span className='capitalize'>{i18n.t('clutchTerms.restrictions.listing9')}</span></p>
        <p className="contentSection ">{i18n.t('clutchTerms.restrictions.listing10')}</p>
        <p className="contentSection ">{i18n.t('clutchTerms.restrictions.listing11')}</p>
        <p className="contentSection ">{i18n.t('clutchTerms.restrictions.listing12')}</p>
        <p className="contentSection ">{i18n.t('clutchTerms.restrictions.listing13')}</p>
        <p className="contentSection ">{i18n.t('clutchTerms.restrictions.listing14')}</p>
        <p className="contentSection ">{i18n.t('clutchTerms.restrictions.listing15')}</p>
        <p className="contentSection ">{i18n.t('clutchTerms.restrictions.listing16')}</p>
        <p className="contentSection ">{i18n.t('clutchTerms.restrictions.listing17')}</p>
        <p className="contentSection ">{i18n.t('clutchTerms.restrictions.listing18')}</p>
        <p className="contentSection ">{i18n.t('clutchTerms.restrictions.listing19')}</p>

      </div>
    </div>
    <div className="loginformwrap">
        <Form
          apiStatus={apiStatus}
          history={history}
          clearApiStatus={clearApiStatus}
          getToCheckout = {getToCheckout}
          addSigneeName = {addSigneeName}
          getSigneeInfo = {getSigneeInfo}
          getCompanyInfoApi= {getCompanyInfoApi}
        />
      </div>
    </Styled.Wrapper>
  );
};

export default Component;
