import React, { lazy, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { hasValue, getImagePath, hasRestrictedRole } from '@/utils/utils';
import { imageUrls } from '@/config/constants/keys';

import Box from '@material-ui/core/Box';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import TopActions from './TopActions';
import { COOKIE_AUTH_DETAILS, ROLES } from '../../../config/constants/keys';
import { getCookie } from '@/services/cookie';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const Modal = require(`@/components/UI/Modal/${layout}`).default;
const Link = lazy(() => import(`@/components/UI/Link`));
const Index = ({ theme, filters, ...contentProps }) => {
  const token = getCookie(COOKIE_AUTH_DETAILS);
  return (
    <Box style={{ ...theme.palette.contentStyle, overflowX: 'hidden' }}>
      <WorkOrderComponent {...contentProps} filters={filters} />
    </Box>
  );
};
export default Index;

const WorkOrderComponent = ({
  apiStatus,
  callback,
  history,
  Styled,
  fetchInspections,
  bulkDownloadReport,
  inspections,
  list,
  changeRequest,
  isAdvancedDviRequired,
  isCustomDviRequired,
  logBook,
  clearApiStatus,
  i18n,
  topAction,
  getDocumentToken,
  filters,
  viewReport,
  request,
  pagination,
  setPagination,
  needSort
}) => {
  const [open, setOpen] = useState(false);
  // const [currentItem, setCurrentItem] = useState();

  const [ModalObject, setModalObject] = useState({
    header: '',
    content: () => {
      return null;
    }
  });

  const closeModal = (event) => {
    setOpen(false);
  };
  const hasApiStatus = hasValue(apiStatus.status, 'api', fetchInspections);
  const information =
    hasApiStatus && hasApiStatus.errorMessage ? (
      <Message type="error" s message={i18n.t(hasApiStatus.errorMessage)} />
    ) : (
      i18n.t('common.tableEmptyMessage')
    );

  const columns = [
    {
      id: 'work_order_id',
      numeric: true,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('workOrders.workOrderId')
    },
    {
      id: 'companyName',
      numeric: false,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('workOrders.company')
    },
    {
      id: 'unitId',
      numeric: false,
      disablePadding: false,
      needSorting: false,
      label: i18n.t('vehicles.vin')
    },
    {
      id: 'reported_date',
      numeric: true,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('workOrders.reportedDate')
    },
    {
      id: 'workOrderType',
      numeric: false,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('workOrders.serviceType')
    },
    {
      id: 'report',
      numeric: false,
      needSorting: false,
      disablePadding: false,
      label: ''
    }
  ];
  let formattedType = {
    TRACTOR_DAY_CAB: 'Tractor Day Cab',
    TRACTOR_SLEEPER: 'Tractor Sleeper',
    BOBTAIL: 'Bobtail',
    STRAIGHT_TRUCK: 'Straight Truck',
    BOX_TRUCK: 'Box Truck',
    PICKUP: 'Pickup',
    PRIVATE_PASSENGER: 'Private Passenger',
    VACUUM: 'Vacuum',
    VAN: 'Van',
    OTHER: 'Other'
  };

  const generateRows = () => {
    const updatedList = inspections?.content?.map((item) => {
      let result = { ...item };
      result.type = formattedType[item.type] || '-';
      result.work_order_id = (
        <Link href={`/inspections/edit/${item.work_order_id}`}>
          {item.work_order_id}
        </Link>
      );
      let vinTrimmedValue = result?.unit_id?.name
      if(result?.unit_id?.name){
        vinTrimmedValue = result.unit_id.name.substr(result?.unit_id?.name.length - 8)
        vinTrimmedValue = `...${vinTrimmedValue}`
      }
      result.unitId = (
        <Link href={`/${item.unitType === 'TRAILER' ? 'trailer' : 'vehicle'}/edit/${item.unit_id?.id}`}>{vinTrimmedValue}</Link>
      );
      if(hasRestrictedRole([ROLES.SERVICE_MANAGER])){
        result.unitId = vinTrimmedValue
      }
      result.driverName = hasRestrictedRole([ROLES.SERVICE_MANAGER]) ? (
        item.driverName
      ) : (
        <Link href={`/user/edit/${item.driver?.id}`}>{item.driverName}</Link>
      );
      result.report = item.inspection_report?.id ? (
        <Icon
          src={getImagePath(imageUrls.dailyDocumentSelected)}
          tableicon
          onClick={() =>
            viewReport({
              apiId: 'viewReportApi',
              data: item?.inspection_report?.id
            })
          }
          tooltip={i18n.t('workOrders.viewReport')}
        ></Icon>
      ) : (
        <div className="noPointer"><Icon src={getImagePath(imageUrls.dailyDocument)} tableicon></Icon></div>
      );
      result.id = item.work_order_id;
      return result;
    });
    return updatedList || [];
  };

  return (
    <Styled.Wrapper>
      {topAction && (
        <TopActions
          {...{
            isAdvancedDviRequired,
            isCustomDviRequired,
            i18n,
            history,
            list,
            bulkDownloadReport,
            apiStatus,
            clearApiStatus,
            callback,
            Styled,
            changeRequest,
            hasApiStatus,
            filters,
            request,
            pagination,
            setPagination
          }}
        />
      )}
      <div className="quickFilter"></div>
      <Styled.TableWrapper>
        <div className="driverstatusTitle">
          <span className="spireonOldOnly">Inspections</span>
        </div>
        <Table
          type="server"
          defaultSortBy={needSort ? { sortBy: 'type', order: 'desc' } : null}
          changeRequest={changeRequest}
          baseRequest={request}
          title=""
          columns={[...columns]}
          data={generateRows()}
          emptyMessage={information}
          itemsPerPage={
            window.$environment.SERVICE_PROVIDER === 'spireon' ? 10 : 15
          }
          totalRecords={inspections?.totalElements}
          actions={[]}
          i18n={i18n}
          pageinationReset={pagination}
          pagenationClicked={(e) => {
            setPagination(!e);
          }}
        />
      </Styled.TableWrapper>
      <Modal
        open={open}
        setOpen={setOpen}
        data={ModalObject}
        onCloseModal={closeModal}
        background="black"
        inspectionPopup
        withCloseIcon
      />
    </Styled.Wrapper>
  );
};

WorkOrderComponent.propTypes = {
  history: PropTypes.object.isRequired,
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  fetchViolations: PropTypes.func.isRequired,
  handleExportCsaViolations: PropTypes.func.isRequired
};
