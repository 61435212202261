import styled from 'styled-components';
import Parent from '../Base/style';

const MenuWrapper = styled(Parent.MenuWrapper)`
${({ theme }) => `
li {
    display: block;
    padding: 6.5px 2px 6.5px 7px;
    position: relative;
    border: 1px solid #e2e6e9;
    border-radius: 3px;
    margin: 0px 2px 10px 2px;
    color: black;
    background-color: #fff;
    font-size: 0;
    &:hover {
        background-image: -webkit-linear-gradient(top, #d0d8db 0%, #b3bec3 100%);
        text-shadow: 0 1px 0 white;
        color: #42505A !important;
    }
    a {
        padding-top: 0 !important;
    }
    p {
        font-size: 12px;
        @media (max-width: ${theme.breakpoints.values.lg - 1}px) {
            display: block;
          }
    }
    .MuiListItemIcon-root,
    svg {
        display: none;
    }
}
.MuiListItem-button:hover,
.MuiListItem-root.Mui-selected:hover,
.MuiListItem-root.Mui-selected {
  background-color: #c0cace;
}
.MuiListItem-button:hover p,
.MuiListItem-root.Mui-selected p {
    color: #000 !important;
}
.MuiList-padding {
    padding: 5px;
}
`}
`;
const MenuToolTip = styled(Parent.MenuToolTip)``;
const Wrapper = { MenuWrapper, MenuToolTip };

export default Wrapper;
