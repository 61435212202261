/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Styled from './style';
import {COOKIE_AUTH_DETAILS} from '../../../../../config/constants/keys';
import { getCookie, setCookie } from '../../../../../services/cookie';

const WelcomePage = ({ history, loginInfo }) => {
    const [username, setUserName] = useState('')
    useEffect(()=>{
        const token = getCookie(COOKIE_AUTH_DETAILS)
        setUserName(token.email)
    }, [loginInfo])
    return (
        <Styled.Wrapper>
            <div className="welcomeText">
                <h1>Thank you</h1>
                <h2>
                    for choosing <span>Clutch ELD</span>
                    for your hours of service and ELD needs.
                </h2>
                <div className="loginDetails">
                    <div className="fieldWrapper">
                        <label className="label">Username/Email</label>:<label className="info">{username}</label>
                    </div>
                    <div className="fieldWrapper password">
                        <label className="label">Password</label>:<label className="info">
                            ********
                            </label>
                    </div>
                </div>
                <div className="details">
                The next pages will take you through what you need to know on how to get your account set up and your drivers on the road with their new service. Select “Continue Onboarding” to take you to the hardware installation training.
                </div>
            </div>
        </Styled.Wrapper>
    )
};

export default WelcomePage;

WelcomePage.propTypes = {
  history: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired
};