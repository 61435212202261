import * as parent from '../variables';

export const colors = {
  ...parent.colors,
  PRIMARY_MAIN: '#fff',
  BACKGROUND: '#efefef',
  HEADER_COUNT_TEXT: '#373737',
  PRIMARY_CONTRAST: '#373737',
  SECONDARY_MAIN: '#78c81a',
  PUBLIC_HEADERCOLOR: '#2c2c2c',
  LANGUAGE_SWITCH: '#78c81a',
  HEADER_TEXT: '#f4f6f9',
  BODY_BACKGROUND: '#fff',
  MODAL_BACKGROUND: '#fff',
  MODAL_BORDER: '#d9d9d9',
  MODAL_LINK: '#78c81a',
  LINK_COLOR: '#78c81a',
  HEADER_COLOR: '#78c81a',
  CHECKBOX_TICK: '#000',
  ACTIVE_ROW:'rgba(254, 236, 222, 0.7)',
  PAGINATION_BACKGROUND: '#fff',
  PAGINATION_COLOR: '#000',
  SELECTED_AREA: 'rgba(120, 200, 26, 0.3)',
  BACKGROUND_MENU:'#f4f6f9',
  RANGE_PICKER: {
    CELL_BG: '#dfdfdf',
    CELL_HOVER_BG: '#bbbbbb'
  },
  INPUT_BORDER: '#ccc',
  USER_LOGO_BACKGROUND: "#fff",
  USER_LOGO_COLOR: "#78c81a"
};
export const fonts = ['Yanone Kaffeesatz', 'tahoma',].join(',');
export const secondaryFont = 'Permanent Marker';
export const tableFont = 'tahoma';
export const headerFont = '24px';
export const subTitle = '22px';
export const titleLabel= '24px';
export const formlabelFont = '24px';
export const buttonFont = '23px';
export const inputFont = '16px';
export const checkboxFont = '17px';
export const addbuttonFont = '23px';
export const companyDataFont = '28px';
export const iftaReport = '16px';
export const inspectionInput = '24px';
export const inspectionresolution = '20px';