import styled from 'styled-components';
import Parent from '../Base/style';

const Wrapper = styled(Parent.Wrapper)`
${({ theme, selectedindex, leftPosition, selectedType }) => `
border: 1px solid rgba(255,255,255,0.66);
a {
    font-size: 14px;
    color: ${theme.colors.SECONDARY_MAIN} !important;
}
`}
`;
const Styled = { Wrapper };
export default Styled;
