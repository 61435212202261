import styled from 'styled-components';

const MessageContainer = styled.div`
  width: 100%;
  p {
    font-size: 16px;
    padding-top: 2px;
    text-transform: none;
    font-weight: normal;
  }
  .MuiTypography-body1 {
    color: ${({ type, theme }) =>
      type === 'error'
        ? theme.palette.error.main
        : type === 'info'
        ? theme.palette.secondary.main
        : theme.palette.warning.main};
  }
`;

export default MessageContainer;
