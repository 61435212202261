import styled from 'styled-components';

import { getImagePath, getStyle } from '../../../utils/utils';

export const ChatWrapper = styled.div`
  ${({
    theme,
    width,
    noDriver,
    noChats,
    openWin,
    openRightWindow,
    openMsgWindow,
    newConv,
    newConv2,
    broadWin,
    display,
    // isZendeskChatOpen,
    // shiftToLeft
  }) => `
.openWindowWrapper {
    display: ${openWin || 'block'};
}
.newConversationWrapper {
    display: ${newConv || 'none'};
    padding-top: 15px;
}
.newConversationWrapper2 {
    display: ${newConv2 || 'none'};
    width: 400px;
    padding-top: 15px;
}
.broadcastWrapper {
    display: ${broadWin || 'none'};
    padding-top: 0;
}
.chat-popup {
  display: ${display === true ? 'block' : 'none'};
  position: fixed;
  bottom: 2%;
  right: 2%;
  z-index: 9;
  min-height: 560px;
  width: ${width || '400px'};
  background-color: ${theme.colors.BODY_BACKGROUND};
  box-shadow: 1px 1px 15px ${getStyle(
    '',
    (theme.palette.secondary || {}).main,
    theme.colors.SECONDARY_MAIN
  )};
  border-radius: 8px;

}
.chatWindow{
    width: 380px;
    display: ${openRightWindow || 'none'};
    border-left: 1px solid #bdbdbd;
    padding-top: 25px;
}
.parentWrapper {
    display:${openMsgWindow || 'block'};
    width: 400px;
    vertical-align: top;
    padding-top: 15px;
}
.openRightMenu {
    display:${openRightWindow || 'none'};
}
.chat-close {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
    justify-content: flex-end;
    margin-right: 3px;
    margin-top: -20px;
    cursor: pointer;

}
.chat-close img {
    margin-left: 5px;
    border: 2px solid ${getStyle(
      '',
      (theme.palette.secondary || {}).main,
      theme.colors.SECONDARY_MAIN
    )};
    border-radius: 25px;
    background-color: ${theme.colors.LINK_COLOR};
    padding: 0px;
    transform: scale(0.80);
}
.go-back {
    color:white;
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
    justify-content: flex-start;
    cursor: pointer;
    height: 30px;
}
.message-button-wrapper {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
    justify-content: space-around;
    padding: 0 3%;
}
.MuiButtonBase-root:hover {
background-color: ${getStyle(
    '',
    (theme.palette.secondary || {}).main,
    theme.colors.SECONDARY_MAIN
  )};
}
.MuiButton-root {
border-radius: 0px !important;
}

.search-wwrapper {
    margin-top:10px;
    #simpleSearchWrapper:first-child {
        padding-left:20px;
    }
}

.chat-details-wrapper,
.userWrapper {
    margin-top: 10px;
}
}
.chat-list,
.all-users-list {
    max-height: 410px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.chat-list::-webkit-scrollbar { width: 0 !important }
.all-users-list::-webkit-scrollbar { width: 0 !important }
.chat-list { overflow: -moz-scrollbars-none; }
.chat-list { -ms-overflow-style: none; }
.all-users-list { overflow: -moz-scrollbars-none; }
.all-users-list { -ms-overflow-style: none; }

.chat-tile {
    display:flex;
    flex-direction:column;
    padding: 5px 12px;
    cursor: pointer;
    padding-left: 18px;
}
.selected-chat-highlight {
    background-color: ${theme.colors.BACKGROUND};
}
.deselected-chat-highlight {
    background-color: none;
}
.all-users-list2 {
    display:flex;
    flex-direction:column;
    padding: 5px 12px;
    cursor: pointer;
    width: 90%;
}
.chat-tile:hover,
.userNames:hover {
    background-color:  ${theme.colors.LIGHT_BORDER};
}
.chat-person {
    color:${theme.colors.PRIMARY_CONTRAST} ;
    font-size: 18px ;
}
.last-chat-message {
    font-size: 16px;
    color: #545454;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 350px;
}
.headWrapper {
    display:flex;
    padding-left: 8px;

}

.userNames {
    padding-left: 30px;
    padding-top: 3px;
    margin-bottom: 10px;
    color: ${theme.colors.PRIMARY_CONTRAST};
}
.message-type-wrapper {
    margin-top: 15px;
    justify-content: space-around;
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
    margin-left:44px;
    textarea {
        height: 120px !important;
    }
}

.userWrapper2 {
    font-size:16px !important;
    width: 100%;
}
.userWrapper {
    font-size: 18px !important;
}
.search-new {
    width: 100%;
}
.all-users-list {
    display:flex;
    flex-direction:column;
    padding-top: 5px;
    cursor: pointer;
}

.header-wrapper {
    color: ${theme.colors.PRIMARY_CONTRAST};
    padding-left: 20px;
    margin-bottom: 25px;
}
.talk-bubble {
    margin-left: 35px;
    margin-bottom: 20px;
  display: inline-block;
  position: relative;
	height: auto;
	background-color: ${theme.colors.BACKGROUND};
    color: ${theme.colors.PRIMARY_CONTRAST};
    font-size:16px;
    word-wrap: break-word;
    border: 1px solid ${theme.colors.LIGHT_BORDER};
    min-width: 37%;
    max-width: 264px;
    border-radius: 5px;
}

/* Right triangle placed top left flush. */
/* Right triangle, left side slightly down */
.tri-right.border.left-in:before {
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: -40px;
	right: auto;
  top: -8px;
	bottom: auto;
	border: 32px solid;
    border-color:
    ${theme.colors.BACKGROUND}
    ${theme.colors.BACKGROUND}
     transparent transparent;
}
.tri-right.left-in:after{
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
    left: -9px;
	right: auto;
    top: 20px;
	bottom: auto;
	border: 10px solid;
	border-color: ${theme.colors.BACKGROUND}
    ${theme.colors.BACKGROUND} transparent transparent;
    transform: rotate(224deg);
}
.userName {
    text-align:left;
    padding-top: 5px;
    padding-left: 10px;
}


/* talk bubble contents */
.talktext{
	text-align: left;
    color: ${theme.colors.WHITE};
    padding-top: 5px;
    padding-left: 10px;
    padding-bottom: 5px;
    padding-right: 10px;
}
.talk-bubble.sb1:after {
    content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid ${getStyle(
    '',
    (theme.palette.secondary || {}).main,
    theme.colors.SECONDARY_MAIN
  )};
  border-right: 7px solid transparent;
  border-top: 7px solid ${getStyle(
    '',
    (theme.palette.secondary || {}).main,
    theme.colors.SECONDARY_MAIN
  )};
  border-bottom: 10px solid transparent;
  right: -9px;
  top: 18px;
  transform: rotate(133deg);
}

.select__multi-value {
    font-family: ${theme.palette.fonts};
}
.rightFloat {
    margin-right: 35px;
    border: 1px solid ${getStyle(
      '',
      (theme.palette.secondary || {}).main,
      theme.colors.SECONDARY_MAIN
    )};
    background: ${theme.colors.PRIMARY_MAIN};
    display:inline-block;
}
.chat-send-section {
    padding-left: 15px;
    width: 48.6%;
    border-top: 1px solid #dbdde3;
    padding-bottom: 8px;
    padding-top: 8px;
    position: absolute;
    bottom: 0;
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flexbox;
    display: flex;
    justify-content: space-around;
    background-color: ${theme.colors.BLACK};
}
.new-con {
    padding-top: 5px;
    margin-top:0px !important;
}
.header-wrapper .chatContainer,
.chatWindow .chatContainer {
    height: calc(480px - 58px) !important;
}
.chatContainer {
    overflow-y:scroll;
    position:relative;
    height: 480px;
    padding-bottom: 50px;
    /* overflow:auto; */
}
.chatContainer::-webkit-scrollbar { width: 0 !important }
.chatContainer { overflow: -moz-scrollbars-none; }
.chatContainer { -ms-overflow-style: none; }


.right {
    text-align:right;
}

.send-button {
    margin-top: 17px;
    margin-right: 15px;

}
.send-button img {
    cursor:pointer;
}
.msg-send-time {
    display: none;

}
.chat-loader {
  height: 100%;
  background-image:  url(${getImagePath('icons/chatLoader.gif')});
  background-position: center;
    background-size: 60px 60px;
  padding: 0px;
  background-color: rgba(248, 248, 241, 0.2);
  position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background-repeat: no-repeat;
}
.parentWrapper .MuiInput-root,
.chat-send-section .MuiInput-root,
.search-new .MuiInput-root{
    background:
    ${theme.colors.BACKGROUND} !important;
    border: 2px solid #46444494;
}
.newConversationWrapper .go-back {
    margin-top: 6px;
}
.broadcastWrapper .go-back {
    margin-top: 10px;
}

#no_drivers {
display: ${noDriver || 'none'};
text-align: center;
margin-top: 25px;
color: ${theme.colors.WHITE};
}

.noChatHistory {
display: ${noChats || 'none'};
text-align: center;
margin-top: 25px;
color: ${theme.colors.WHITE};
font-size: 18px;
}

.all-users-list2 .select__clear-indicator {
    padding: 6px !important;
}

.message-button-wrapper {
    .MuiButton-label {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
    justify-content: start;
    // font-size: 15px;
    font-size: 17px;
  }
  .MuiButton-root {
        text-transform: none !important;
        padding-left:13px;
        padding-right: 25px;
  }
}
.MuiButton-outlined {
    border: none;
}

.message-button-wrapper div:first-child {
    width:55%;
}

.message-button-wrapper button {
    width: 100%
}
.message-button-wrapper div:last-child {
    width:35%;
}
.message-type-wrapper .MuiInputBase-root{
    border:none;
}
.userWrapper2 .all-users-list2 .css-1hb7zxy-IndicatorsContainer {
    position: fixed !important;
    right: 80px !important;
  }

.chat-details-wrapper #no_chat_warning {
    padding-left: 25px;
    color:${theme.colors.PRIMARY_CONTRAST};
}
  `}
`;
