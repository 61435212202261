import styled from 'styled-components';
import Parent from '../Base/style';
const RadioWrapper = styled(Parent)`
${({ theme, color, textColor }) => `
.MuiIconButton-label {
    border-radius: 50%;
    input:checked + div {
        border-radius: 50%;
    }
}
.MuiRadio-colorSecondary.Mui-checked {
  color: ${theme.colors.INPUT_BORDER};
}
span.MuiFormControlLabel-label {
  color: ${
    textColor
      ? textColor
      : theme.palette.secondary.contrastText
      ? theme.palette.secondary.contrastText
      : theme.colors.PRIMARY_CONTRAST
  };
  font-size: 14px !important;
}
`}
`;
export default RadioWrapper;
