import PropTypes from 'prop-types';
import React, { lazy, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import RenderComponent from './RenderComponent';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));

const Filters = ({
  hasApiStatus,
  callback,
  fleetCompanyList,
  filterList,
  handleReset,
  values,
  vehicleList,
  setInitialValues
}) => {
  const [validationError, setValidationError] = useState('');
  const history = useHistory();
  return (
    <>
      <Form
        type="server"
        direction="row"
        initialValues={{ ...values }}
        className="CSAForms"
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {
          setValidationError('');
          const finalData = {
            ...fields,
            eventType: fields.eventType?.value,
            ...(fields?.endDate ? { endDate: fields?.endDate } : {}),
            ...(fields?.eventType?.length ? { eventType: fields?.eventType?.map(e => e?.value) } : {}),
            ...(fields?.startDate ? { startDate: fields?.startDate } : {}),
            ...(fields?.vehicleId ? { vehicleId: fields?.vehicleId?.value } : {}),
          };
          if (fields.eventType?.length || fields.endDate || fields.vehicleId?.value || fields.startDate) {
            Object.keys(finalData).forEach(
              (key) => !finalData[key] && delete finalData[key]
            );
            filterList(finalData);
          } else {
            setValidationError('Please select filter');
          }
        }}
        handleOnCancel={(fields) => {
          handleReset();
          setInitialValues({
            eventType: null
          });
          history.replace('/camera')
          setValidationError('');
        }}
        customProps={{
          history,
          callback,
          hasApiStatus,
          validationError,
          fleetCompanyList,
          vehicleList,
          setInitialValues,
        }}
      />
    </>
  );
};

Filters.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default Filters;
