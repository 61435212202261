import styled from 'styled-components';
import Parent from './style';

const FilterSubmit = styled(Parent.FilterSubmit)`
`;
const Filter = styled(Parent.Filter)`
.filterLabel{
    padding-left: 0 !important;
}
.MuiTypography-h1{
   padding-left: 0 !important;
}
`;
const Department = styled(Parent.Department)`
`;

const Styled = { FilterSubmit, Filter };
export default Styled;
