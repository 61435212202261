import Chart from 'chart.js';
import React, { lazy, useEffect, useRef, useState } from 'react';
import Styled from './style';
const layout = window?.$environment?.CURRENT_LAYOUT;

const Label = lazy(() => import(`@/components/UI/Label/${layout}`));

const defaultBackgroundColor = ['green', 'yellow', 'red'];
const defaultData = [];


const DonutChart = ({
    width,
    height,
    backgroundColor = null,
    borderColor = null,
    data = null,
    centerTextData = null,
    small,
    labels = null,
    onClick = null,
    hideHover = false,
}) => {
    const chartRef = useRef();

    const [tooltipData, setTooltipData] = useState(null);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });


    useEffect(() => {
        const myChartRef = chartRef.current.getContext('2d');
        if (myChartRef.chart) {
            myChartRef.chart.destroy();
        }
        new Chart(myChartRef, {
            type: 'doughnut',
            data: {
                datasets: [{
                    data: data || defaultData,
                    backgroundColor: borderColor || defaultBackgroundColor,
                    borderColor: borderColor || defaultBackgroundColor,
                    borderWidth: 0,
                    borderRadius: 20,
                }],
                ...(labels ? { labels } : {})
            },
            
            options: {
                responsive: true,
                maintainAspectRatio: false,
                onClick: function (e, c) {
                    onClick && onClick(e, c);
                },
                legend: {
                    display: true,
                    labels: {
                        boxWidth: 10
                    },
                    position: 'bottom'
                },
                tooltips: {
                    enabled: false,
                },
                cutoutPercentage: '75',
                elements: {
                    arc: {
                        borderWidth: 5,
                    }
                },
                onHover: function(event) {
                    const activePoints = this.getElementsAtEvent(event);
                    if (activePoints.length) {
                        const datasetIndex = activePoints[0]._datasetIndex;
                        const index = activePoints[0]._index;
                        const value = this.data.datasets[datasetIndex].data[index];

                        setTooltipData(`Vehicles: ${value || 111}`);
                        const position = this.getDatasetMeta(datasetIndex).data[index].tooltipPosition();
                        setTooltipPosition({ x: position.x, y: position.y });
                    } else {
                        setTooltipData(null);
                    }
                },
            }
        });
        return () => {
            if (myChartRef.chart) {
                myChartRef.chart.destroy();
            }
        };
    }, [backgroundColor, data, borderColor, labels]);

    return (
        <Styled.Wrapper style={{ width: width, height }} width={width} height={height}>
            <canvas id="donutChart"  style={{ width: '100%', height: '100%', }} ref={chartRef} />
            {centerTextData && <div style={{ zIndex: 1, }} className={small ? "centerText3" : "centerText"}>
                <Label className={small ? "textsmall" : "text"}>{centerTextData?.text}</Label>
                <Label className={small ? "countsmall" : "count"}>{centerTextData?.count}</Label>
            </div>}
            {tooltipData && !hideHover && (
                <Styled.ToolTip style={{
                    left: tooltipPosition.x,
                    top:tooltipPosition.y,
                    zIndex: 1000,
                }}>
                    {tooltipData}
                </Styled.ToolTip>
            )}
        </Styled.Wrapper>
    )
}

export default DonutChart;
