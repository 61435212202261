import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

import Form from './Form';
const Title = lazy(() => import(`@/components/Title`));

const Index = ({ theme, i18n, ...contentProps }) => {
  return (
    <>
      <Title header="COMPANY REGISTRATION" i18n={i18n} />
      <Box style={{ ...theme.palette.contentStyle }}>
        <Component {...contentProps} i18n={i18n} />
      </Box>
    </>
  );
};
export default Index;
const Component = (props) => {
  return <Form {...props} />;
};

Component.propTypes = {
  Styled: PropTypes.object.isRequired,
  i18n: PropTypes.array.isRequired
};
