import React, { lazy, useEffect, useState } from 'react';
import { Field } from 'formik';
import { imageUrls } from '../../../config/constants/keys';
import { getImagePath } from '../../../utils/utils';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Input = lazy(() => import(`../../UI/Input/${layout}`));
const CheckBox = lazy(() => import(`../../UI/Toggle/${layout}`));
const Icon = lazy(() => import(`../../UI/Icon/${layout}`));
const Label = lazy(() => import(`../../UI/FormLabel/${layout}`));
const FormStyle = require(`../../UI/Form/${layout}/serverStyle`);
const Message = lazy(() => import(`../../UI/Message/${layout}`) );
const Policy = ({
  item,
  Styled,
  i18n,
  values,
  handleEdit,
  errFields,
  downloadDocument,
  disabled
}) => {
  return (
    <>
      <Styled.SelectedPolicy>
        <Styled.PolicyWrapper>
          <FormStyle.default.FieldsWrapper width="100%" id={item.policyType}>
            <CheckBox
              aria-labelledby={item.policyType}
              checked={values[item.policyType]}
              label={i18n.t(`policies.${item.policyType}`)}
              type="NormalGrey"
              disabled={disabled || false}
              onClick={(event) => {
                handleEdit(event, { field: item.policyType, type: 'checkbox' });
                if (event) {
                  values.policyTypes.push(item.policyType);
                } else {
                  values.policyTypes = values?.policyTypes?.filter(
                    (val) => val !== item?.policyType
                  );
                }
                handleEdit(values.policyTypes, {
                  field: 'policyTypes',
                  type: ''
                });
              }}
            />
            <a className="viewMore">
              <span
                className="viewSample"
                onClick={() =>
                  downloadDocument({
                    apiId: 'getPolicyDocDownload',
                    data: item.policyType
                  })
                }
              >
                {i18n.t('viewSample')}
              </span>
              <Icon
                src={getImagePath(imageUrls.viewMoreIcon)}
                className="sampleIcon"
                onClick={() =>
                  downloadDocument({
                    apiId: 'getPolicyDocDownload',
                    data: item.policyType
                  })
                }
              />
            </a>
          </FormStyle.default.FieldsWrapper>
        </Styled.PolicyWrapper>
        <div className="subFormsection">
          {values[item.policyType] &&
            item.parameters &&
            Object.entries(item.parameters)?.map(([key, value]) =>
              key === 'ALLOW_USING_PHONES' ? (
                <FormStyle.default.FieldsWrapper width="100%" id={key}>
                  <CheckBox
                    aria-labelledby={key}
                    checked={values?.policyParameters[key]}
                    label={i18n.t(`policies.${key}`)}
                    type="NormalGrey"
                    onClick={(event) =>
                      handleEdit(event, {
                        field: `policyParameters[${key}]`,
                        type: 'checkbox'
                      })
                    }
                  />
                </FormStyle.default.FieldsWrapper>
              ) : (
                <FormStyle.default.FieldsWrapper className="subFields">
                  <Label variant="body1" htmlFor={key}>
                    {i18n.t(`policies.${key}`)}
                  </Label>
                  <FormStyle.default.TextWrapper>
                    <Field
                      id={key}
                      name={key}
                      handleOnChange={(event) => {
                        handleEdit(event, {
                          field: `policyParameters[${key}]`,
                          type: 'checkbox'
                        });
                      }}
                      as={Input}
                      type="text"
                      value={values?.policyParameters[key]}
                    />
                    { errFields[key] && (
                      <Message type="error" message={i18n.t('common.fieldRequiredMessage')} />
                    )} 
                  </FormStyle.default.TextWrapper>
                </FormStyle.default.FieldsWrapper>
              )
            )}
        </div>
      </Styled.SelectedPolicy>
    </>
  );
};

export default Policy;
