import styled from 'styled-components';
import * as Parent from '../Base/style';

export const PaymentWrapper = styled(Parent.PaymentWrapper)`
${({ theme }) => `
.suspended {
  background: #ddd !important;
  color: ${theme.colors.LINK_COLOR} !important;
}
`}
`;
export const NameWrapper = styled(Parent.NameWrapper)`
padding-bottom: 3%;
#nameOnCard {
    border-radius: 0;
    background-color: rgb(239,239,239) !important;
    height: 5px;
    margin: 0 4px;
    font-size: 18px;
  }
  input:focus{
    border: 0;
  }
  p{
    font-size: 23px;
  }
  .MuiInputBase-root{
    border-color: transparent!important;
    background-color: rgb(239, 239, 239) !important;
    height: 33px;
  }
  .MuiInputBase-input{
      color: #000 !important;
  }
`;
export const InputWrapper = styled(Parent.InputWrapper)``;
