import React, { lazy, useEffect, useState } from 'react';
import PropTypes, { element } from 'prop-types';
import { Field } from 'formik';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Accordion from '@/components/Accordion';
import { getImagePath, hasEnabledFeature, isdvirOnly } from '@/utils/utils';
import { imageUrls, pageUrls } from '@/config/constants/keys';

const layout = window?.$environment?.CURRENT_LAYOUT;
const SubHeader = lazy(() => import(`@/components/SubHeader/${layout}`));

const Input = lazy(() => import(`@/components/UI/Input/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Select = lazy(() => import(`@/components/UI/Select/${layout}`));
const Modal = lazy(() => import(`@/components/UI/Modal/${layout}`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const Label = lazy(() => import(`@/components/UI/Label/${layout}`))
const RadioButton = lazy(() => import(`@/components/UI/Radio/${layout}`));

const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`).default;
const RenderComponent = ({
  values,
  handleEdit,
  errors,
  isNotValid,
  validateForm,
  customProps,
  submitAction
}) => {
  const history = useHistory();
  const { i18n } = useTranslation();
  /* Create a popup starts */
  const [ModalListObject, setModalListObject] = useState({
    header: '',
    content: () => { }
  });
  const isDvirOnlyFeatureEnabled = isdvirOnly()
  const [open, setOpen] = useState(false);
  const [isValidAraaSection, setIsValidAraaSection] = useState('');
  const [formNameError, setFormNameError] = useState('');
  const [saveFlag, setSaveFlag] = useState(false);
  const [flag, setFlag] = useState(false);
  /* Create a popup ends*/
  const [accordionListArray, setAccordionListArray] = useState([]);
  const [newlySelectedVehicles, setNewlySelectedVehicles] = useState(null);
  const Styled = customProps.Styled
  useEffect(() => {
    const getVehicleList = () => {
      return customProps.vehicleList.length && customProps.selectedVehicles.map((source) => {
        const result =
          customProps.vehicleList.filter((e) => e.value === source)[0];
        return result;
      });
    };
    const vehicleIdsList = getVehicleList() || [];
    customProps.setInitialValues({
      ...values,
      vehicles: newlySelectedVehicles ? [...newlySelectedVehicles] : [...vehicleIdsList]
    });
  }, [values.vehicles && values.vehicles.length === 0])
  const editArea = (areaId) => {
    setModalListObject({
      content: () => (
        <AreaNameComponent parentList={values.pages || []} areaId={areaId} isEdit={true} />
      )
    });

    setOpen(true);
  }
  useEffect(() => {
    const list = [...(values.pages || [])];

    const newList = list.map((item, idx) => {
      return {
        id: item.key,
        component: () => (
          <CreateAreaRow
            text={item.title}
            areaId={item.key}
            indexId={idx}
            partsId={null}
            isEditing={item.isEditing}
            editArea={editArea}
            areaType={isDvirOnlyFeatureEnabled ? String(item?.isTrailerSection) === 'true' ? '(Trailer)' : '(Vehicle)' : ''}
          />
        ),
        defaultExpanded: item.isOpen || false,
        value: () => {
          const partsList = item.sections;

          return (
            <div>
              {partsList.map((innerData, idx) => {
                return (
                  <CreatePartsRow
                    text={innerData.title}
                    areaId={item.key}
                    partsId={innerData.key}
                    indexId={idx}
                    defects={innerData.defects}
                  />
                );
              })}
            </div>
          );
        },
        dependency: ''
      };
    });
    setAccordionListArray(newList);
  }, [values.pages, saveFlag, flag]);
  const showError = (field, validationErrors, isNotValid, apiError) => {
    if (validationErrors[field] && !isNotValid) {
      return <Message type="error" message={errors[field]} />;
    }
    if (apiError?.[field]) {
      return <Message type="error" message={apiError[field]} />;
    }
  };

  const CreateAreaRow = (props) => {
    return <CreateRow {...props} isAddNeeded={true} isArea={true} />;
  };
  const CreatePartsRow = (props) => {
    return <CreateRow {...props} isAddNeeded={false} isEditing={true} />;
  };
  const CreateRow = ({ text, areaId, partsId, indexId, isEditing, isAddNeeded, isArea, editArea, areaType, defects }) => {
    const [textEdit, setTextEdit] = useState('');
    const [error, setError] = useState('');
    const [accordinList, setAccordinList] = useState([])
    useEffect(() => {
      if (saveFlag) validAraaSection()
    })
    useEffect(() => {
      let list = defects;
      let newList = [{
        id: indexId,
        component: () => (
          <div style={{ display: 'flex', justifyContent: "space-between", width: "100%" }}><p className="defectsTitle">{i18n.t('inspections.defects')}</p>
            <a onClick={(e) => { handleAddDefect(e, areaId); }} className="addNewParts" >
              {' '}
              {i18n.t('inspections.addNewDefects')}
            </a>
          </div>
        ),
        defaultExpanded: saveFlag || false,
        value: () => {
          return (
            <div>
              {list && list.length > 0 ? list.map((ele, idx) => {
                return <div className="defectsWrapper"> <Input
                  name={areaId + partsId + idx}
                  handleOnChange={(value, event) => handleDefectTextEdit(value, event, idx)}
                  onClick={(e) => e.stopPropagation()}
                  placeholder={''}
                  value={ele}
                  type="text"
                  autocomplete="off"
                  id={areaId + partsId + idx}
                  onLoadFocus
                />
                  <Icon
                    src={getImagePath(imageUrls.deleteIcon)}
                    className={ele && 'partsDelete'}
                    onClick={(e) => {
                      handleDefectDelete(idx)
                    }}
                  />
                  {(ele.length == 0 && saveFlag) && <div style={{ position: 'absolute', left: '102%', whiteSpace: "nowrap", paddingTop: '5px' }}> <Message type="error" message={i18n.t('This field is required.')} /> </div>}

                </div>
              })
                : <div className="defectsWrapper">
                  <Input
                    name={areaId + partsId + 0}
                    handleOnChange={(value, event) => handleDefectTextEdit(value, event, 0)}
                    onClick={(e) => e.stopPropagation()}
                    placeholder={''}
                    value={''}
                    type="text"
                    autocomplete="off"
                    id={areaId + partsId + 0}
                    onLoadFocus
                  />
                  <Icon
                    src={getImagePath(imageUrls.deleteIcon)}
                    className={'' && 'partsDelete'}
                    onClick={(e) => {
                      handleDefectDelete(0)
                    }}
                  />
                  {saveFlag && <div style={{ position: 'absolute', left: '102%', whiteSpace: "nowrap", paddingTop: '5px' }}> <Message type="error" message={i18n.t('common.fieldRequiredMessage')} /> </div>}

                </div>}
            </div>
          );
        },
        dependency: ''
      }]
      setAccordinList(newList)
    }, [defects, saveFlag])
    useEffect(() => {
      setTextEdit(text);
    }, [text]);
    const handleTextEdit = (value, e) => {
      setTextEdit(value);

      e.stopPropagation();
    };
    const handleDefectTextEdit = (value, e, idx) => {
      e.stopPropagation();
      let list = values.pages
      const parentId = list.findIndex((item) => item.key === areaId);
      const parent = list[parentId];
      parent.sections[indexId].defects = parent.sections[indexId].defects ? parent.sections[indexId].defects : []
      let defects = parent.sections[indexId].defects
      defects[idx] = value
      customProps.setInitialValues({
        ...values,
        pages: [...list]
      })

    };
    const completeEdit = (areaId, partsId = null) => {
      const list = [...(values.pages || [])];
      const parentId = list.findIndex((item) => item.key === areaId);
      const parent = list[parentId];
      if (partsId) {
        const part = parent.sections.findIndex((item) => item.key === partsId);

        let repitition = 0;
        repitition = parent.sections.filter(
          (inneritem, index) =>
            textEdit && textEdit === inneritem.title && part !== index
        );
        if (repitition.length > 0) {
          parent.isOpen = true;

          setError('Please add unique part name');
        } else if (textEdit === '' && list[parentId].sections[part].title === '') {
          parent.isOpen = true;

          setError('This field is required.');
        } else {
          setError('');

          list[parentId].sections[part].title =
            textEdit || list[parentId].sections[part].title;
          list[parentId].sections[part].key =
            parent.title.replace(/ /g, '_') +
            '.' +
            list[parentId].sections[part].title.replace(/ /g, '_');
        }
      } else {
        let repitition = 0;
        repitition = list.filter(
          (inneritem, index) =>
            textEdit === inneritem.title && parentId !== index
        );
        if (repitition.length > 0) {
          parent.isEditing = true;
          setError('Please add unique area name');
        } else if (textEdit === '') {
          parent.isEditing = true;
          setError('This field is required.');
        } else {
          setError('');
          parent.isEditing = false;
        }
        parent.title = textEdit;

      }
      customProps.setInitialValues({
        ...values,
        vehicles: newlySelectedVehicles ? [...newlySelectedVehicles] : [...values.vehicles],
        pages: [...list]
      });
    };
    const startEditing = (e, areaId) => {
      const list = [...values.pages];
      const parent = list.find((item) => item.key === areaId);

      parent.isEditing = true;

      customProps.setInitialValues({
        ...values,
        pages: [...list]
      });
    };

    const handleAdd = (e, areaId) => {
      const list = [...values.pages];
      const parent = list.findIndex((item) => item.key === areaId);
      const isExpanded =
        e.target.parentElement.parentElement.parentElement.className.indexOf(
          'Mui-expanded'
        ) !== -1;
      isExpanded && e.stopPropagation();

      list[parent].sections.push({
        key: list[parent].title.replace(/ /g, '_').toLowerCase() + '.',
        title: '',
        defects: []
      });
      customProps.setInitialValues({
        ...values,
        pages: [...list]
      });
    };
    const handleDelete = (areaId, partsId = null) => {
      let list = [...(values.pages || [])];
      const parentId = list.findIndex((item) => item.key === areaId);
      const parent = list[parentId];

      if (partsId) {
        const part = parent.sections.findIndex((item) => item.key === partsId);

        parent.sections.splice(part, 1);

        if (!parent.sections.length) {
          parent.sections = [
            {
              title: '',
              key: parent.title.replace(/ /g, '_').toLowerCase() + '.',
              defects: []
            }
          ];
        }

        list[parentId] = parent;
      } else {
        list = list.filter((item) => item.key !== areaId);
      }
      customProps.setInitialValues({
        ...values,
        pages: [...list]
      });
    };
    const handleAddDefect = (e) => {
      const isExpanded =
        e.target.parentElement.parentElement.parentElement.className.indexOf(
          'Mui-expanded'
        ) !== -1;
      isExpanded && e.stopPropagation();
      let defect = defects ? defects : []
      defect.push('')
      let list = values.pages
      const parentId = list.findIndex((item) => item.key === areaId);
      const parent = list[parentId];

      parent.sections[indexId].defects = defect
      customProps.setInitialValues({
        ...values,
        pages: [...list]
      })
    }
    const getMessage = () => {
      let message = ''
      if (hasEnabledFeature('inspectionPdfCanadaEnabled')) {
        if (defects && defects.every(item => !item)) message = 'Please add at least one Defect for each Part'
        else if (!defects) message = 'Please add at least one Defect for each Part'
      }
      if (textEdit.length == 0) message = i18n.t('common.fieldRequiredMessage')
      return message
    }
    const handleDefectDelete = (idx) => {
      let defect = defects ? defects : []
      defect.splice(idx, 1)
      let list = values.pages
      const parentId = list.findIndex((item) => item.key === areaId);
      const parent = list[parentId];
      parent.sections[indexId].defects = defect
      customProps.setInitialValues({
        ...values,
        pages: [...list]
      })
    }
    return (
      <>
        <div style={{ width: '100%', display: 'flex' }} className={hasEnabledFeature('inspectionPdfCanadaEnabled') && partsId ? 'partsContainer' : ''}>
          <div id={isEditing ? `${areaId}_${indexId}` : ``} style={{ width: '100%', display: 'flex', height: isEditing ? '58px' : '' }} key={areaId + partsId}>
            <div style={{ width: partsId ? '49%' : '84%', display: 'flex', marginRight: '10px' }}
              className={partsId ? 'partsText' : 'areaText'}
            >
              {isEditing ? (
                <>
                  <Input
                    name={areaId + partsId}
                    handleOnChange={(value, event) => handleTextEdit(value, event)}
                    onClick={(e) => e.stopPropagation()}
                    placeholder={''}
                    value={textEdit}
                    type="text"
                    autocomplete="off"
                    id={areaId + partsId}
                    onLoadFocus
                    onBlur={() => {
                      partsId && completeEdit(areaId, partsId);
                    }}
                  />

                  {
                    !partsId &&
                    <div style={{
                      position: 'relative',
                      left: '-36%',
                      top: '10px'
                    }}>
                      <Icon
                        src={getImagePath(imageUrls.tickIcon)}
                        onClick={(e) => {
                          completeEdit(areaId, partsId);
                          e.stopPropagation();
                        }}
                      /></div>
                  }
                  {saveFlag && <div style={{ position: 'absolute', left: '52%', paddingTop: '5px' }}> <Message type="error" message={getMessage()} /> </div>}
                </>
              ) : (
                <h2>{text} {isArea ? areaType : ''}</h2>
              )}
            </div>

            {!isEditing && (
              <Icon
                src={getImagePath(imageUrls.edit)}
                className={'accordinIcon'}
                onClick={(e) => {
                  if (isArea) {
                    editArea(areaId);
                  }
                  else {
                    startEditing(e, areaId, partsId);
                  }
                  e.stopPropagation();
                }}
              />
            )}

            <Icon
              src={getImagePath(imageUrls.deleteIcon)}
              className={`${partsId ? 'partsDelete' : 'accordinIcon'}`}
              onClick={(e) => {
                if (textEdit.length > 0) {
                  if (partsId) {
                    const parentId = values.pages.findIndex((item) => item.key === areaId);
                    const parent = values.pages[parentId];
                    if (parent.sections.length === 1) {
                      setModalListObject({
                        content: () => {
                          return (
                            <div className="deleteParent">
                              <h2> There is atleast one part under each area. You cannot delete this. </h2>
                              <div className="deletePopupBtn">

                                <div className="deletebtn">
                                  <Button
                                    label={i18n.t('common.ok')}
                                    onClick={() => {
                                      setOpen(false);
                                    }}
                                  ></Button>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      });
                    } else {
                      setModalListObject({
                        content: () => {
                          return (
                            <div className="deleteParent">
                              <div className="imageSection">
                                <img
                                  src={getImagePath(imageUrls.confirmIcon)}
                                  alt="Are you sure"
                                />
                              </div>
                              <h2> {i18n.t('common.sureConfirm')} </h2>
                              <div className="deletePopupBtn">
                                <div className="cancelBtn">
                                  <Button
                                    label={i18n.t('common.cancel')}
                                    type={'reset'}
                                    onClick={() => {
                                      setOpen(false);
                                    }}
                                  ></Button>
                                </div>
                                <div className="deletebtn">
                                  <Button
                                    label={i18n.t('common.deletePopup')}
                                    onClick={() => {
                                      handleDelete(areaId, partsId);
                                      setOpen(false);
                                    }}
                                  ></Button>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      });
                    }
                  } else {
                    setModalListObject({
                      content: () => {
                        return (
                          <div className="deleteParent">
                            <div className="imageSection">
                              <img
                                src={getImagePath(imageUrls.confirmIcon)}
                                alt="Are you sure"
                              />
                            </div>
                            <h2> {i18n.t('common.sureConfirm')} </h2>
                            <div className="deletePopupBtn">
                              <div className="cancelBtn">
                                <Button
                                  label={i18n.t('common.cancel')}
                                  type={'reset'}
                                  onClick={() => {
                                    setOpen(false);
                                  }}
                                ></Button>
                              </div>
                              <div className="deletebtn">
                                <Button
                                  label={i18n.t('common.deletePopup')}
                                  onClick={() => {
                                    handleDelete(areaId, partsId);
                                    setOpen(false);
                                  }}
                                ></Button>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    });
                  }
                  setOpen(true);
                } else handleDelete(areaId, partsId);
                e.stopPropagation();
              }}
            />

            {isAddNeeded && (
              <a
                onClick={(e) => {
                  handleAdd(e, areaId);
                }}
                className="addNewParts"
              >
                {' '}
                {i18n.t('inspections.addNewParts')}
              </a>
            )}
          </div>
          {hasEnabledFeature('inspectionPdfCanadaEnabled') && isEditing
            ?
            <div className="defectsAccordion" style={{ marginBottom: "20px", marginLeft: "5%" }}>
              <Accordion
                data={accordinList}
                className="accordionWrapper"
              ></Accordion>
            </div> : null
          }
        </div>
      </>
    );
  };

  const AreaNameComponent = ({ parentList, isEdit, areaId }) => {
    const area = parentList.find(o => o.key === areaId)
    const isDvirOnlyFeatureEnabled = isdvirOnly()
    const [areaName, setAreaName] = useState(isEdit ? area.title : '');
    const [message, setMessage] = useState('');
    const [isTrailerSection, setIsTrailerSection] = useState(isEdit ? String(String(area?.isTrailerSection) === 'true') : 'false');

    return (
      <Styled.AreaWrapper>
        <Label>{i18n.t('inspections.areaName')}</Label>
        <Input
          name="areaNameText"
          handleOnChange={(value) => {
            setAreaName(value);
          }}
          placeholder={i18n.t('inspections.areaName')}
          value={areaName}
          type="text"
          autocomplete="off"
          id="areaNameText"
        />
        <Message type="error" message={message} />
        {/* {isDvirOnlyFeatureEnabled &&
          (
            <>
              <Label>{i18n.t('inspections.areaType')}</Label>
              <RadioButton
                aria-labelledby="subscriptionTier"
                value={isTrailerSection}
                options={[
                  {
                    label: i18n.t('common.vehicle'),
                    value: 'false'
                  },
                  {
                    label: i18n.t('common.trailer'),
                    value: 'true'
                  }
                ]}
                onClick={(event) => {
                  setIsTrailerSection(event);
                }}
              />
            </>
          )
        } */}
        <div className="button-wrapper">
          <Button
            label={i18n.t('common.ok')}
            disabled={areaName === ''}
            onClick={() => {

              const newList = [...parentList];
              let repitition = 0;
              const parentId = newList.findIndex((item) => item.key === areaId);

              repitition = newList.filter(
                (inneritem, idx) => areaName.toLowerCase() === inneritem.title.toLowerCase() && (isEdit ? parentId !== idx : true)
              );
              if (repitition.length > 0) {
                setMessage('Please add unique area name');
              } else {
                setOpen(false);
                if (isEdit) {
                  const parent = newList[parentId];
                  newList[parentId] = {
                    ...parent,
                    ...(isDvirOnlyFeatureEnabled && { isTrailerSection }),
                    title: areaName,
                    key: areaName.replace(/ /g, '_').toLowerCase(),
                  }
                }
                else {
                  newList.push({
                    isEditing: false,
                    isOpen: true,
                    title: areaName,
                    ...(isDvirOnlyFeatureEnabled && { isTrailerSection }),
                    key: areaName.replace(/ /g, '_').toLowerCase(),
                    sections: [
                      {
                        title: '',
                        key: areaName.replace(/ /g, '_').toLowerCase() + '.'
                      }
                    ]
                  });
                }
                customProps.setInitialValues({
                  ...values,
                  pages: [...newList]
                });
              }
            }}
          ></Button>
        </div>
      </Styled.AreaWrapper>
    );
  };

  const validAraaSection = () => {
    const pages = values.pages || [];
    const isAreaValid =
      pages.length !== 0 && pages.findIndex((item) => item.title === '') === -1;
    const partsData = pages.map((item) => {
      return (
        item.sections.length !== 0 &&
        item.sections.findIndex((innerItem) => innerItem.title === '') === -1
      );
    });
    let partDefectsData = []
    pages.map((item) => {
      if (item.sections.length !== 0) {
        item.sections.forEach(ele => {
          if (ele.defects) partDefectsData.push(ele.defects)
        });
      }
    });
    partDefectsData = partDefectsData.flat()
    const isPartValid = partsData.filter((item) => !item).length < 1;
    const isDefectValid = partDefectsData.filter(item => !item).length < 1;
    let isDefectAvailable = true
    if (hasEnabledFeature('inspectionPdfCanadaEnabled')) {
      pages.map((item) => {
        if (item.sections.length !== 0) {
          item.sections.forEach(ele => {
            if (ele.defects?.length > 0) {
              let nullChk = ele.defects.every(ele => ele == null)
              if (nullChk) isDefectAvailable = false

            } else isDefectAvailable = false
          });
        }
      });
    }
    setIsValidAraaSection(
      !isAreaValid
        ? 'Please add area and related parts'
        : !isPartValid || !isDefectValid
          ? 'Please fill all required fields'
          : ''
    );

    return isAreaValid && isPartValid && isDefectValid && isDefectAvailable;
  };

  return (
    <Styled.Wrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around'
        }}
      >
        <Styled.leftSection>
          <FormStyle.FieldsWrapper>
            <Label>{i18n.t('inspections.formName')}</Label>
            <FormStyle.TextWrapper>
              <Field
                name="formName"
                handleOnChange={(event) => handleEdit(event, { field: 'name' })}
                as={Input}
                placeholder={i18n.t('inspections.formName')}
                value={values.name}
                type="text"
                className="borderStyle"
                id="FrormInfoId"
                onBlur={() => {
                  let isItExisting = customProps.id
                    ? customProps.formsList.filter(
                      (item) =>
                        item.formName === values.name &&
                        parseInt(customProps.id, 10) !== item.id
                    )
                    : customProps.formsList.filter(
                      (item) => item.formName === values.name
                    );

                  if (isItExisting.length > 0) {
                    setFormNameError('Please enter a unique form name');
                  } else if (values.name === '') {
                    setFormNameError('This field is required.');
                  } else if (values.name) {
                    setFormNameError('');
                  }
                }}
              />
              {showError(
                'name',
                errors,
                isNotValid,
                customProps.hasApiStatus?.errorMessage
              )}
              {formNameError && (
                <Message type="error" message={formNameError} />
              )}
            </FormStyle.TextWrapper>
          </FormStyle.FieldsWrapper>

          <FormStyle.FieldsWrapper className="vehiclesSelect">
            <Label>{i18n.t('common.trailer')}</Label>
            <FormStyle.TextWrapper className="dropdown">
              <Field
                name="vehicleIds"
                onChange={(event) => {
                  customProps.setInitialValues({
                    ...values,
                    vehicles: event
                  });
                  setNewlySelectedVehicles(event ? [
                    ...event
                  ] : [])
                }}
                as={Select}
                placeholder="Select"
                value={values.vehicles}
                suggestions={customProps.vehicleList}
                isMulti
                isClearable={false}
              />
            </FormStyle.TextWrapper>
          </FormStyle.FieldsWrapper>
        </Styled.leftSection>
        <Styled.rightSection>
          <FormStyle.FieldsWrapper>
            <Label>{i18n.t('common.description')}</Label>

            <FormStyle.TextareaWrapper>
              <Field
                name="description"
                handleOnChange={(event) =>
                  handleEdit(event, { field: 'description' })
                }
                as={Input}
                placeholder={i18n.t('common.description')}
                value={values.description}
                multiLine
                type="text"
              />

              {errors['description'] && !isNotValid && (
                <Message type="error" message={errors['description']} />
              )}
              {showError(
                'description',
                errors,
                isNotValid,
                customProps.hasApiStatus?.errorMessage
              )}
            </FormStyle.TextareaWrapper>
          </FormStyle.FieldsWrapper>
        </Styled.rightSection>
      </div>

      <Styled.AreasListkWrapper>
        <div className="headingWrapper">
          <SubHeader text={i18n.t('inspections.areasAndParts')} variant="h2" />
        </div>

        <Styled.AreaButtonkWrapper>
          <Button
            label={i18n.t('inspections.addNewArea')}
            className="addNewArea"
            onClick={() => {
              setModalListObject({
                content: () => (
                  <AreaNameComponent parentList={values.pages || []} />
                )
              });

              setOpen(true);
            }}
          ></Button>
        </Styled.AreaButtonkWrapper>
        {isValidAraaSection && (
          <Message type="error" message={isValidAraaSection} />
        )}

        <Accordion
          data={accordionListArray}
          className="accordionWrapper"
        ></Accordion>
        <FormStyle.ButtonsWrapper className="buttonStyle">
          <Button
            type="reset"
            label={i18n.t('common.cancel')}
            onClick={(e) => {
              history.push(pageUrls.customDvirEnabled);
            }}
          />
          <Button
            type="submit"
            label={i18n.t('common.save')}
            onClick={(e, d) => {
              setSaveFlag(true)
              setFlag(!flag)
              setFormNameError(
                !values.name || values.name === ''
                  ? 'This field is required.'
                  : ''
              );

              const validArea = validAraaSection();
              const validateResponse = validateForm();
              if (validArea && formNameError === '') {
                validateResponse.then((vaidationDdata) => {
                  submitAction(vaidationDdata);
                });
              }
            }}
          />
        </FormStyle.ButtonsWrapper>
      </Styled.AreasListkWrapper>

      <Modal
        open={open}
        setOpen={setOpen}
        data={ModalListObject}
        withCloseIcon
        background="black"
      />
    </Styled.Wrapper>
  );
};

export default RenderComponent;

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  customProps: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};
