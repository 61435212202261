import styled from 'styled-components';
import Parent from '../Base/style';
const ButtonContainer = styled(Parent)`
${({ theme }) => `
.MuiButtonBase-root,
.MuiButton-root {
    border: 0;
    font-size: 12px !important;
    padding: 0 !important;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    height: 23px!important;
    border-radius: 4px!important;
    padding: 0 !important;
}
.reset,
.reset:hover {
    background: ${theme.colors.SECONDARY_MAIN} !important;
    color: ${theme.colors.WHITE} !important;
}
`}
`;

export default ButtonContainer;
