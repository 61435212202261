import { useTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import React, { lazy, useEffect, useState } from 'react';

const layout = window?.$environment?.CURRENT_LAYOUT;
const ScatterGraph = lazy(() => import(`@/components/UI/Graph/ScatterGraph/${layout}`));

const ScatterPlot = ({
    data
}) => {
    const theme = useTheme();
    const [chartData, setChartData] = useState([])
    const [chartFlg, setChartFlag] = useState(true)

    useEffect(() => {
        if (data) {
            setChartFlag(false);
            // removing speeding from data FLEETMG-16582
            const res = data.filter(ele => ele?.behavior !== "SPEEDING");
            setChartData(res)
            setTimeout(() => {
                setChartFlag(true)
            })
        }
    }, [data])

    const changeCase = (inputString) => {
        // Convert the entire string to lowercase
        const lowercaseString = inputString.toLowerCase();
      
        // Capitalize the first character
        const resultString = lowercaseString.charAt(0).toUpperCase() + lowercaseString.slice(1);
      
        return resultString;
      }

    const themeColors = [theme.colors.CHART_BLUE, theme.colors.CHART_PURPLE, theme.colors.CHART_GREEN, theme.colors.CHART_PINK, theme.colors.CHART_YELLOW, theme.colors.CHART_LIGHT_BLUE]
    return (
        <div className='graphContainer'>
            {chartData.length && chartFlg ? <ScatterGraph
                width={'470px'}
                height={'230px'}
                data={
                    chartData.map((ele, index) => {
                        return {
                            data: [{
                                y: ele.severityScore,
                                x: ele.behaviorScore
                            }],
                            label: changeCase(ele.behavior),
                            backgroundColor: fade(themeColors[index], 0.5),
                            borderColor: themeColors[index],
                            borderWidth: 1,
                            pointRadius: 8,
                            pointHoverRadius: 8,
                            lineTension: 0
                        }
                    })
                }
                areagraph
                title={''}
                theme={theme}
                noHyperLink
            ></ScatterGraph> : null}

        </div>
    )
}

export default ScatterPlot