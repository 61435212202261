import React, { lazy, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import CircularProgress from '@material-ui/core/CircularProgress';

import Label from '../../../../../components/UI/FormLabel/Clutch';

import { phoneNumberValidation } from '../../../../../utils/utils';
import Styled from './style';
const layout = window?.$environment?.CURRENT_LAYOUT;
const Input = lazy(() =>
  import(`../../../../../components/UI/Input/${layout}`)
);
// const Label = lazy(() =>
//   import(`../../../../../components/UI/Label/${layout}`)
// );
const Message = lazy(() =>
  import(`../../../../../components/UI/Message/${layout}`)
);
const Button = lazy(() =>
  import(`../../../../../components/UI/Button/${layout}`)
);
const FormStyle = require(`../../../../../components/UI/Form/${layout}/serverStyle`);
const RenderComponent = ({
  values,
  handleEdit,
  errors,
  isNotValid,
  validateForm,
  customProps,
  submitAction
}) => {
  useEffect(() => {
    window.onbeforeunload = closeIt;
  }, []);

  useEffect(() => {
    return () => {
      window.onbeforeunload = null;
    };
  }, []);
  const closeIt = () => {
    return 'Are you sure you want to leave?';
  };
  const { i18n } = useTranslation();
  const showError = (field, validationErrors, isNotValid, apiError) => {
    if (validationErrors[field] && !isNotValid) {
      return <Message type="error" message={errors[field]} />;
    }
    if (apiError?.[field]) {
      return <Message type="error" message={apiError[field]} />;
    }
  };
  return (
    <Styled.Wrapper>
      <div className="companyWrapper">
        <Styled.FieldsWrapper>
          <Label variant="body1">First Name</Label>
          <Styled.TextWrapper>
            <Field
              name="firstName"
              handleOnChange={(event) =>
                handleEdit(event, { field: 'firstName' })
              }
              as={Input}
              placeholder="Type First Name"
              value={values['firstName']}
              type="text"
              onLoadFocus
            />
            {showError(
              'firstName',
              errors,
              isNotValid,
              customProps.hasApiStatus?.errorMessage
            )}
          </Styled.TextWrapper>
        </Styled.FieldsWrapper>

        <Styled.FieldsWrapper>
          <Label variant="body1">Last Name</Label>
          <Styled.TextWrapper>
            <Field
              name="lastName"
              handleOnChange={(event) =>
                handleEdit(event, { field: 'lastName' })
              }
              as={Input}
              placeholder="Type Last Name"
              value={values['lastName']}
              type="text"
            />
            {showError(
              'lastName',
              errors,
              isNotValid,
              customProps.hasApiStatus?.errorMessage
            )}
          </Styled.TextWrapper>
        </Styled.FieldsWrapper>
      </div>
      <div className="companyWrapper">
        <Styled.FieldsWrapper>
          <div className="stateSelector">
            <Label variant="body1">Email Address</Label>
            <Styled.TextWrapper>
              <Field
                name="email"
                handleOnChange={(event) => {
                  handleEdit(event, { field: 'email' });
                }}
                as={Input}
                placeholder="Type Email Address"
                value={values['email']}
                type="text"
              />
              {showError(
                'email',
                errors,
                isNotValid,
                customProps.hasApiStatus?.errorMessage
              )}
            </Styled.TextWrapper>
          </div>
        </Styled.FieldsWrapper>
        <Styled.FieldsWrapper>
          <Label variant="body1">Cell Phone #</Label>
          <Styled.TextWrapper>
            <Field
              name="userPhone"
              handleOnChange={(event) =>
                handleEdit(event, { field: 'userPhone' })
              }
              as={Input}
              placeholder="Type Cell Phone"
              value={values['userPhone']}
              type="text"
              updateValue={phoneNumberValidation}
            />
            {showError(
              'userPhone',
              errors,
              isNotValid,
              customProps.hasApiStatus?.errorMessage
            )}
          </Styled.TextWrapper>
        </Styled.FieldsWrapper>
      </div>
      <div className="companyWrapper">
        <Styled.FieldsWrapper>
          <Label variant="body1">Password</Label>
          <Styled.TextWrapper>
            <Field
              name="password"
              handleOnChange={(event) =>
                handleEdit(event, { field: 'password' })
              }
              as={Input}
              placeholder="Enter Password"
              value={values['password']}
              type="password"
            />
            {showError(
              'password',
              errors,
              isNotValid,
              customProps.hasApiStatus?.errorMessage
            )}
          </Styled.TextWrapper>
        </Styled.FieldsWrapper>
        <Styled.FieldsWrapper>
          <Label variant="body1">Confirm Password</Label>
          <Styled.TextWrapper>
            <Field
              name="confPassword"
              handleOnChange={(event) =>
                handleEdit(event, { field: 'confPassword' })
              }
              as={Input}
              placeholder="Confirm Password"
              value={values['confPassword']}
              type="password"
            />
            {showError(
              'confPassword',
              errors,
              isNotValid,
              customProps.hasApiStatus?.errorMessage
            )}
          </Styled.TextWrapper>
        </Styled.FieldsWrapper>
      </div>
      <FormStyle.default.ButtonsWrapper>
        <Button
          id="fleetManagerInfoSubmit"
          type="submit"
          label={
            customProps?.hasApiStatus?.isLoading ? (
              <CircularProgress color="inherit" />
            ) : (
              'Next'
            )
          }
          onClick={() => {
            const validateResponse = validateForm();
            validateResponse.then((data) => submitAction(data));
          }}
        />
      </FormStyle.default.ButtonsWrapper>
    </Styled.Wrapper>
  );
};

export default RenderComponent;

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  customProps: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};
