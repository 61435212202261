import React, { useEffect, lazy } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import { useHistory } from 'react-router-dom';
import useTheme from '@material-ui/core/styles/useTheme';
import { useTranslation } from 'react-i18next';

import ParentStyled from './style';
import { loadStyle, getNewObject, getLastQuaters } from '@/utils/utils';

import { loadPageConfig } from '@/utils/utils';
import { UI_LOGBOOK_DATE } from '@/config/constants/static';

let Styled = loadStyle(ParentStyled, 'pages/web/IFTAMileage');

const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.reportMileage[0].name}`));
const Content = lazy(() => import(`@/${config.reportMileage[1].name}`));

const IFTAMileage = (parentProps) => {
  const { fetchIftaMileage, fetchVehicles } = parentProps;

  const history = useHistory();
  const theme = useTheme();
  const i18n = useTranslation();

  const commonParentProps = { history, i18n, Styled, theme };

  const apiId = 'fetchVehicles';
  const iftaApiId = 'fetchIftaMileageList';
  const getDocumentTokenApiId = 'getDocToken';
  const lastQuater = getLastQuaters(true)
  const request = {
    id: '',
    fromOdometer: true,
    dateTimeFrom: lastQuater[0].format(UI_LOGBOOK_DATE),
    dateTimeTo: lastQuater[1].format(UI_LOGBOOK_DATE)
  };

  useEffect(() => {
    fetchVehicles({
      apiId
    });
    fetchIftaMileage({
      iftaApiId,
      data: { ...request }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <RenderComponent
        {...config.reportMileage[0]}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        component={Title}
      />
      <RenderComponent
        {...config.reportMileage[1]}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        component={Content}
        getDocumentTokenApiId={getDocumentTokenApiId}
      />
    </>
  );
};

const RenderComponent = ({
  name,
  props,
  commonProps,
  title,
  parentProps,
  commonParentProps,
  component,
  ...rest
}) => {
  const Component = component;
  let newProps = props?.length ? getNewObject(parentProps, props) : {};
  newProps = {
    ...newProps,
    ...(commonProps?.length ? getNewObject(commonParentProps, commonProps) : {})
  };
  return <Component {...rest} {...newProps} commonProps={commonProps} />;
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    ifta: state.iftaMileage,
    vehicles: state.iftaVehicleInfo,
    logBook: state.logBook
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchVehicles: (data) => dispatch.iftaVehicleInfo.fetchData(data),
    fetchIftaMileage: (data) => dispatch.iftaMileage.fetchData(data),
    getIftaAuditCSV: (data) => dispatch.iftaMileage.getIftaAudit(data),
    handleExportIfta: (data) => dispatch.iftaMileage.download(data),
    generateReport: (data) => dispatch.iftaMileage.generateReport(data),
    filterList: (data) => dispatch.iftaMileage.filterList(data),
    getDocumentToken: (data) => dispatch.logBook.documentToken(data)
  };
};

export default connect(mapState, mapDispatch)(IFTAMileage);
IFTAMileage.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  fetchIftaMileage: PropTypes.func.isRequired,
  getIftaAuditCSV: PropTypes.func.isRequired
};
