import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Link as LinkLi } from '@material-ui/core';
import { getAuthDetails } from '../../../utils/utils';

const Header = ({ dashboard, i18n, headerText, Styled }) => {
  const headerNames = {
    TOTALDRIVERS: i18n.t('dashboard.header.TOTALDRIVERS'),
    ONDUTY: i18n.t('dashboard.header.ONDUTY'),
    DRIVING: i18n.t('dashboard.header.DRIVING'),
    SLEEPER: i18n.t('dashboard.header.SLEEPER'),
    OFFDUTY: i18n.t('dashboard.header.OFFDUTY'),
    TOTALTRUCKS: i18n.t('dashboard.header.TOTALTRUCKS'),
    AVAILABLETRUCKS: i18n.t('dashboard.header.AVAILABLETRUCKS'),
    INSHOP: i18n.t('dashboard.header.INSHOP'),
    OUTOFSERVICE: i18n.t('dashboard.header.OUTOFSERVICE')
  };
  const list =
    window.$environment.SERVICE_PROVIDER === 'spireon'
      ? dashboard?.data?.companySummary?.items.slice(0, 5)
      : dashboard?.data?.companySummary?.items;
  const auth = getAuthDetails();
  return (
    <>
      {auth?.subscriptions?.wCompanySummary ? (
        <>
          <Styled.SummarySection>
            {list && <p className="spireonOnly"> {i18n.t('common.summary')}</p>}
          </Styled.SummarySection>
          <Styled.HeaderWrapper>
            {list && (
              <>
                {headerText && (
                  <p className="clutchOnly"> {i18n.t(headerText)}:</p>
                )}
                {list?.map((item, index) => {
                  item.name = headerNames[item.itemType];
                  return (
                    headerNames[item.itemType] && (
                      <Item item={item} key={`${item.name}${index}`} />
                    )
                  );
                })}
              </>
            )}
          </Styled.HeaderWrapper>
        </>
      ): <div className="notSubscriped"></div>}
    </>
  );
};

const Item = ({ item }) => {
  return (
    <li>
      {item.hasLink ? (
        <LinkLi component={Link} to={item.link}>
          <div
            className={
              item.itemType === 'TOTALDRIVERS' ||
              item.itemType === 'TOTALTRUCKS'
                ? 'totalAmount'
                : ''
            }
          >
            {item.name}
          </div>
          <div
            className={
              item.itemType === 'TOTALDRIVERS' ||
              item.itemType === 'TOTALTRUCKS'
                ? 'total'
                : ''
            }
          >
            {item.count}
          </div>
        </LinkLi>
      ) : (
        <>
          <div>{item.name}</div> <div className="noLink">{item.count}</div>
        </>
      )}
    </li>
  );
};

Item.defaultTypes = {
  drivers: {},
  regions: {}
};
Item.propTypes = {
  item: PropTypes.object.isRequired
};

Header.defaultProps = {
  headerText: '',
  data: []
};
Header.propTypes = {
  headerText: PropTypes.string,
  data: PropTypes.array
};
export default Header;
