import styled from 'styled-components';
import Parent from './style';

const HeaderContainer = styled(Parent.HeaderContainer)`
${({ theme, vertical }) => `
  .pageSpecific {
    flex-direction: ${vertical ? 'column': 'row'} !important;
    border-bottom: 2px solid #000;
    padding-bottom: 38px !important;
    padding-right: 2% !important;
    position: relative;
    h1 {
      padding: 0 !important;
      line-height: 22px !important;
      padding-left: ${vertical ? '0': '15% '}!important;
      margin-left: ${vertical ? '21%': '0'}!important;
      border-bottom: ${vertical ? '2px solid #000': '0'} !important;
      padding-bottom:${vertical ? '20px': '0'} !important;
      padding-top: 10px !important;
    }
  }
  #container {
    padding-top: 2%;
    font-size: 18px;
    padding-right: 0;
    h1 {
      color: #222;
      font-size: ${theme.palette.titleLabel};
      line-height: 26px;
      text-transform: uppercase;
      padding-left: 21%;
      padding: 18px 2% 41px 21%;
      border-bottom: 2px solid #000;
    }
  }
  `}
`;
const Styled = {
  HeaderContainer
};
export default Styled;


