import React from 'react';
import PrivacyWrapper from './style';
import { Helmet } from 'react-helmet';

const Component = ({ pageTitle }) => {
    const baseUrl = `${process.env.CDN_URL || ''}/${window.$environment?.SERVICE_PROVIDER
        }/${window.$environment.CURRENT_LAYOUT}`;

    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
                <link rel="manifest" href={`${baseUrl}/manifest.json`} />
                <link rel="icon" type="image/x-icon" href={`${baseUrl}/favicon.ico`} />
            </Helmet>
            <PrivacyWrapper>
                <h1> PRIVACY POLICY </h1>
                <div className="contentWrapper">
                    <p>
                        Protecting your privacy is important to us. To better protect your privacy,
                        we are providing this Privacy Policy to explain our practices regarding the collection,
                        use and disclosure of information that we receive when you use our Services
                        (as defined in our Terms of Service). This Privacy Policy applies only to those websites,
                        services and applications included within “Services” and doesn’t apply to any third-party websites,
                        services or applications, even if they are accessible through our Services. Also, please note that,
                        unless we define a term in this Privacy Policy, all capitalized words used in this Privacy Policy have
                        the same meanings as in our Terms of Service.
                    </p>

                    <b>HOW WE COLLECT AND USE INFORMATION</b>

                    <p>
                        We collect information in an effort to provide and improve our Services and to better administer your use of the Services.
                    </p>
                    <p><u>Account Information.</u> When you create an Account we will collect certain information that can be used to identify you, such as your name, phone number, email address, and office addresses (“Personal Information). We may also collect certain information that is not Personal Information because it cannot be used by itself to identify you, such as your DOT number, carrier name, VIN, unit name or number and cycle rule.</p>
                    <p><u>Use of Cookies.</u> We collect certain information through the use of “cookies,” which are small files that are saved by your browser when you access our Services. We may use cookies to identify that you’ve logged in to the Services and to tell us how and when you interact with our Services, which assists us in being able to customize and improve our Services. Although most browsers automatically accept cookies, you can change your browser options to stop automatically accepting cookies or to prompt you before accepting cookies. Please note, however, that if you don’t accept cookies, you may not be able to access all portions or features of the Services. Some third-party services providers that we engage may also place their own cookies on your browser. Please note that this Privacy Policy covers only our use of cookies and does not include use of cookies by such third parties.</p>
                    <p><u>Information Related to Use of the Services.</u> Our servers may automatically record certain information about how Users utilize our Services. (This data may include information such as a User’s Internet Protocol (IP) address, browser type, operating system, the web page that a User was visiting before accessing our Services, the pages or features of our Services to which a User browsed and the time spent on those pages or features, the links on our Services that a User clicked on, driving logs entered into the Services, documents uploaded to the Services, messages exchanged on the Services and other statistics. We use this information to administer the Services and we analyze (and may engage third parties such as Google Analytics, to analyze) this information to improve and enhance the Services by expanding their features and functionality and tailoring them to our Users’ needs and preferences. To learn more about the privacy policy of Google Analytics, and to learn how to opt out of that service.We may use a person’s IP address to fight spam, malware and identity theft. We also use the IP Address to generate aggregate, non-identifying information about how our Services are used.</p>

                    <p><u>Location Information.</u> In some cases we collect and store information about where you are located, such as by converting your IP address into a rough geolocation or through GPS or wireless network triangulation. We may use location information to improve and personalize our Services for you (for example, by helping you complete your driving logs). This information will also be used in the operation of the Services in such ways as geocoding document capture, vehicle location history and mileage by jurisdiction.</p>

                    <b>HOW WE SHARE INFORMATION</b>

                    <p>We may rent, sell or share any Personal Information that we have collected from you with third-parties for certain purposes.</p>
                    <p><u>Information Shared with Our Service Providers.</u> We may engage third party service providers to work with us to administer, provide and improve the Services, analyzing usage of the Services and marketing purposes.</p>
                    <p><u>Information Shared with Third Parties.</u> We may share aggregated information and non-identifying information with third parties for industry analysis, demographic profiling and other similar purposes.</p>
                    <p><u>Information Disclosed in Connection with Business Transactions.</u> Information that we collect from our Users, including Personal Information, is considered to be a business asset. As a result, if we go out of business or enter bankruptcy or if we are acquired as a result of a transaction such as a merger, acquisition or asset sale, your Personal Information may be disclosed or transferred to the third-party acquirer in connection with the transaction.</p>
                    <p><u>Information Disclosed for Our Protection and the Protection of Others.</u> It is our policy to protect you from having your privacy violated through abuse of the legal systems, whether by individuals, entities or government, and to contest claims that we believe to be invalid under applicable law. However, it is also our policy to cooperate with government and law enforcement officials and private parties. Accordingly, we reserve the right to disclose any information about you to government or law enforcement officials or private parties as we, in our sole discretion, believe necessary: (i) to satisfy or comply with any applicable law, regulation or legal process or to respond to lawful requests, including subpoenas, warrants or court orders; (ii) to protect our property, rights and safety and the rights, property and safety of third parties or the public in general; and (iii) to prevent or stop activity we consider to be illegal or unethical.</p>







                    <b>HOW WE SECURE INFORMATIOIN</b>

                    <p>
                        We take reasonable measures to protect the information that we collect from or about you (including your Personal Information) from unauthorized access, use or disclosure. Please be aware, however, that no method of transmitting information over the Internet or storing information is completely secure. Accordingly, we cannot guarantee the absolute security of any information.
                    </p>

                    <b>LINKS TO OTHER SITES</b>

                    <p>
                        Our Services may contain links to other websites and services. Any information that you provide on or to a third-party website or service is provided directly to the owner of the website or service and is subject to that party’s privacy policy. Our Privacy Policy does not apply to such websites or services and we are not responsible for the content, privacy or security practices and policies of those websites or services. To protect your information, we recommend that you carefully review the privacy policies of other websites and services that you access.
                    </p>

                    <b>MODIFYING YOUR INFORMATION</b>

                    <p>
                        You can access and modify the Personal Information associated with your Account by changing your “account settings” on the Site or the App. If you want us to delete your Personal Information and your Account, please contact us at {window.$environment.SUPPORT_EMAIL} with your request. We’ll take steps to delete your information as soon as is practicable, but some information may remain in archived/backup copies for our records or as otherwise required by law.
                    </p>

                    <b>INTERNATIONAL TRANSFERS</b>

                    <p>
                        Your Personal Information may be transferred to, and maintained on, computers located outside of your state, province, country or other governmental jurisdiction where the privacy laws may not be as protective as those in your jurisdiction. If you’re located outside the United States and choose to provide your Personal Information to us, we may transfer your Personal Information to the United States and process it there. Your consent to this Privacy Policy followed by your submission of any Personal Information represents your agreement to that transfer.
                    </p>

                    <b>OUR POLICY TOWARD CHILDREN</b>

                    <p>
                        Our Services are not directed to children under the age of 13, in accordance with The Children’s Online Privacy Protection Act, we do not knowingly collect Personal Information from children under age of 13. If we learn that we have collected Personal Information of a child under the age of 13 we will take steps to delete such information from our files as soon as possible.
                    </p>

                    <b>CHANGES TO OUR PRIVACY POLICY</b>

                    <p>
                        Any information that we collect is subject to the privacy policy in effect at the time such information is collected. We may, however, modify and revise this Privacy Policy from time to time. If we make any material changes to this Privacy Policy, we’ll notify you of such changes by posting them on the Services or by sending you an email or other notification, and we’ll indicate when such changes will become effective.
                    </p>

                    <b>Questions?</b>

                    <p>
                        Please contact us at {window.$environment.SUPPORT_EMAIL} if you have any questions about our Privacy Policy.
                    </p>


                </div>
            </PrivacyWrapper>
        </>
    );
};

export default Component;
