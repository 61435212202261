import React, { lazy, Fragment } from 'react';
import ParentStyled from './style';
import { loadStyle, loadPageConfig } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'pages/web/PrivacyPolicyScreen');

const PrivacyPolicy = ({ pageTitle }) => {
  const config = loadPageConfig();
  return config.privacyPolicyScreen.map((item) => {
    const { name, title } = item;
    const Component = lazy(() => import(`@/${name}`));
    return (
      <Fragment key={title}>
        <Component pageTitle={pageTitle} Styled={Styled} />
      </Fragment>
    );
  });
};

export default PrivacyPolicy;
