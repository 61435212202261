import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

import { hasRestrictedRole } from '@/utils/utils';
import { ROLES } from '@/config/constants/keys';
import RegionList from './RegionList';
import TerminalList from './TerminalList';
import TopActions from './TopActions';
const layout = window?.$environment?.CURRENT_LAYOUT;
const SubHeader = lazy(() => import(`@/components/SubHeader/${layout}`));
const Breadcrumb = lazy(() => import(`@/components/Breadcrumb`));

const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Breadcrumb filters={filters} />
      <Component {...contentProps} />
    </Box>
  );
};
export default Index;

const Component = ({
  data,
  history,
  callback,
  saveRegion,
  updateRegion,
  deleteRegion,
  saveTerminal,
  updateTerminal,
  deleteTerminal,
  saveDepartment,
  updateDepartment,
  saveSubDepartment,
  updateSubDepartment,
  deleteDepartment,
  deleteSubDepartment,
  Styled,
  i18n,
  isButtonStyle,
  setAddEditModalObject,
  closeAddEditModal,
  clickedAddEditModal,
  topAction,
  isTermCrudRestricted,
  getTmezonePopup,
  isCrudRestricted,
  isDeptCrudRestricted,
  isLocCrudRestricted,
  isShowDotNumber,
  showFilterPopup,
  neglectAccessLevel
}) => {
  const checkCrudPrivilege =  !hasRestrictedRole([ROLES.VIEW_ONLY_FM]) && neglectAccessLevel

  return (
    <Styled.Department>
      {topAction && (
        <TopActions
          {...{
            isTermCrudRestricted,
            callback,
            setAddEditModalObject,
            closeAddEditModal,
            i18n,
            saveTerminal,
            data,
            getTmezonePopup,
            isCrudRestricted,
            isButtonStyle,
            isShowDotNumber,
            clickedAddEditModal,
            saveRegion,
            Styled,
            showFilterPopup,
            neglectAccessLevel: checkCrudPrivilege
          }}
        />
      )}
      <Styled.Wrapper>
        <Styled.LeftWrapper>
          <SubHeader
            className="subheaderSection userHeading"
            text={i18n.t('common.region')}
            variant="h1"
          />
          <RegionList
            data={data}
            history={history}
            callback={callback}
            updateRegion={updateRegion}
            deleteRegion={deleteRegion}
            Styled={Styled}
            isCrudRestricted={isCrudRestricted}
            neglectAccessLevel={checkCrudPrivilege}
          />
        </Styled.LeftWrapper>
        <Styled.RightWrapper>
          <SubHeader
            className="subheaderSection userHeading"
            text={i18n.t('departments.terminalsAndDepartments')}
            variant="h1"
          />
          <TerminalList
            data={data}
            history={history}
            callback={callback}
            updateTerminal={updateTerminal}
            deleteTerminal={deleteTerminal}
            getTmezonePopup={getTmezonePopup}
            saveDepartment={saveDepartment}
            updateDepartment={updateDepartment}
            saveSubDepartment={saveSubDepartment}
            updateSubDepartment={updateSubDepartment}
            deleteDepartment={deleteDepartment}
            deleteSubDepartment={deleteSubDepartment}
            Styled={Styled}
            isButtonStyle={isButtonStyle}
            isCrudRestricted={isCrudRestricted}
            isTermCrudRestricted={isTermCrudRestricted}
            isDeptCrudRestricted={isDeptCrudRestricted}
            isLocCrudRestricted={isLocCrudRestricted}
            isShowDotNumber={isShowDotNumber}
            neglectAccessLevel={checkCrudPrivilege}
          />
        </Styled.RightWrapper>
      </Styled.Wrapper>
    </Styled.Department>
  );
};

Component.propTypes = {
  regions: PropTypes.array
};
