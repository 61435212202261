import styled from 'styled-components';

const DocumentSection = styled.div`
padding: 3%;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
`;
const LeftWrapper = styled.div`
  ${({ theme }) => `
flex: 0 0 50%;
@media (max-width: ${theme.breakpoints.values.lg}px) {
    flex: 0 0 100%;
}
.finish {
  button{
    min-width: 210px;
  }
}
`}
`;
const Styled = { DocumentSection, LeftWrapper };

export default Styled;
