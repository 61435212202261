import styled from 'styled-components';
import Parent from './style';
const Wrapper = styled(Parent.Wrapper)`
    ${({ theme }) => `
        div[type="info"] p {
            margin-top: 0;
            font-size: 14px;
        }
        .MuiInput-root,
        .select__control {
            margin-bottom: 0 !important;
        }
        div[type="error"] p {
            font-size: 14px;
            padding-bottom: 10px;
        }
        form button.MuiButtonBase-root {
            margin-top: 32px;
            min-width: 117px !important;
            .MuiButton-label{
                padding: 2px 0px;
            }
        }
        form {
            .MuiInputBase-input,
            .react-datepicker-wrapper input,
            .css-8r3dx3-control,
            .css-q5i2ns-control,
            .react-select__menu-list,
            .css-1wmipuf-option,
            .css-1irrqr3-option,
            .css-1hys26h-singleValue,
            .css-1ebjif1-option,
            .react-select__menu-list:active,
            .css-1wmipuf-option:active,
            .css-1ebjif1-option:active,
            .css-kpwi51-option:active,
            .css-kpwi51-option,
            .css-1wmipuf-option{
                background-color: ${theme.colors.INPUT_BACKGROUND} !important;
            } 
        }
    `}
`;

const Styled = { Wrapper};

export default Styled;
