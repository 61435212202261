import styled from 'styled-components';

const NotFoundWrapper = styled.div`
  ${({ theme }) => `
  min-height: calc(100% - 98px);
  color: ${theme.colors.PRIMARY_CONTRAST};
  marign: auto;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 2%;
  h1 {
    text-align: center;
  }
  p {
    margin-top: 0;
    font-size: 20px;
  }
  .here {
    padding-left: 5px;
    text-decoration: none;
    color: ${theme.colors.SECONDARY_MAIN};
  }

  `}
`;

const Styled = {
  NotFoundWrapper
};

export default Styled;
