import styled from 'styled-components';
import Parent from '../Base/style';

const MenuWrapper = styled(Parent.MenuWrapper)`
.MuiMenuItem-root {
    white-space: normal;
}
`;
const MenuToolTip = styled(Parent.MenuToolTip)``;
const Wrapper = { MenuWrapper, MenuToolTip };

export default Wrapper;
