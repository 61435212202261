import React from 'react';
import Chart from 'chart.js';
import { fade } from '@material-ui/core/styles/colorManipulator';

export default class LineGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  chartRef = React.createRef();

  componentDidMount() {
    const history = this.props.history;
    const pageurl = this.props.pageUrls;
    const noHyperLink = this.props?.noHyperLink;
    const dataItems = this.props;
    const myChartRef = this.chartRef.current.getContext('2d');
    const average = (arr) => arr.reduce((p, c) => p + c, 0) / arr.length;
    const chart = new Chart(myChartRef, {
      type: 'line',
      data: {
        labels: this.props.labels,
        datasets: [
          {
            label: 'Event Count',
            data: this.props.data,
            backgroundColor: fade(this.props.theme.colors.SECONDARY_MAIN, 0.5),
            borderColor: this.props.theme.colors.SECONDARY_MAIN,
            borderWidth: 1,
            lineTension: 0,
            fill: this.props.areagraph ? true : false
          }
        ]
      },
      options: {
        onClick: function (e, c) {
          // Route to new page
          // history.push(pageurl);
          if(noHyperLink === false) {
          window.open(pageurl, '_blank')
          }
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                display: true,
                color: this.props.theme.colors.PRIMARY_BORDER
              },
              ticks: {
                beginAtZero: true,
                steps: 5,
                stepValue: 5,
                max: Math.max.apply(null, this.props.data) + 4,
                precision: 0
              }
            }
          ],
          xAxes: [
            {
              gridLines: {
                display: true,
                color: this.props.theme.colors.PRIMARY_BORDER
              },
              ticks: {
                fontSize: 10
              }
            }
          ]
        },
        legend: {
          display: this.props.displayLegend ? this.props.displayLegend : false,
          position: 'top',
          onClick: function (e, legendItem) {
            let index = legendItem.datasetIndex;
            let ci = chart;
            if (ci.getDatasetMeta(index).hidden === null) {
              ci.getDatasetMeta(index).hidden = false;
            }
            ci.getDatasetMeta(index).hidden = !ci.getDatasetMeta(index).hidden;
            ci.update();
          },
          labels: {
            generateLabels: function (chart) {
              let data = chart.data;
              return Chart.helpers.isArray(data.datasets)
                ? data.datasets.map(function (dataset, i) {
                    return {
                      text: dataItems.average
                        ? dataset.label +
                          ' (Avg: ' +
                          average(dataset.data).toFixed(2) +
                          ')'
                        : dataset.label,
                      fillStyle: !Chart.helpers.isArray(dataset.borderColor)
                        ? dataset.borderColor
                        : dataset.borderColor[0],
                      lineWidth: 0,
                      hidden: null,
                      datasetIndex: i
                    };
                  }, this)
                : [];
            },
            fontColor: 'white',
            boxWidth: 10
          }
        },
        title: {
          display: true,
          text: this.props.title,
          fontFamily: 'Yanone Kaffeesatz',
          fontSize: 18
        }
      }
    });
  }
  render() {
    return (
      <div>
         <canvas id="lineChart" width={this.props.width} height={this.props.height} ref={this.chartRef} />
      </div>
    );
  }
}
