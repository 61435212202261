import React, { useEffect, useState, lazy } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import ReactDatePicker from 'react-datepicker';

import {
  UI_DATE_LOWER_FORMAT,
  UI_DATE_FORMAT
} from '../../../config/constants/static';
import { hasValue, isdvirOnly, hasRestrictedRole, getDateLocale, getFilterLocaleValues, isInsuracePortalUser, hasEnabledFeature } from '../../../utils/utils';
import moment from 'moment';
import {
  ROLES
} from '../../../config/constants/keys';
// import Styled from './style';

const layout = window?.$environment?.CURRENT_LAYOUT;
const serviceProvider = window?.$environment?.SERVICE_PROVIDER;
const Input = lazy(() => import(`../../../components/UI/Input/${layout}`));
const Label = lazy(() => import(`../../../components/UI/FormLabel/${layout}`));
const Button = lazy(() => import(`../../../components/UI/Button/${layout}`));
const Message = lazy(() => import(`../../../components/UI/Message/${layout}`));
const Select = lazy(() => import(`../../../components/UI/Select/${layout}`));
const Department = lazy(() => import(`../../../components/modules/Department`));
const UploadList = lazy(() => import(`../DocumentUploadList`));
const RadioButton = lazy(() => import(`@/components/UI/Radio/${layout}`));
const SubHeader = lazy(() => import(`../../../components/SubHeader/${layout}`));
const FormStyle = require(`../../../components/UI/Form/${layout}/serverStyle`);
const RenderComponent = ({
  values,
  handleEdit,
  errors,
  isNotValid,
  validateForm,
  customProps,
  submitAction,
  cancelAction
}) => {
  const { i18n } = useTranslation();
  const insuracePortalUserRole = isInsuracePortalUser()
  const {
    terminals,
    departments,
    subDepartments,
    disabledCriteria,
    documentsList,
    regions,
    Styled,
    getRegions,
    recallUpdate
  } = customProps;
  useEffect(() => {
    if (documentsList) {
      let otherDocs = [];
      let trailerDocs = [];
      for (let i = 0; i < documentsList.length; i++) {
        const item = documentsList[i];
        if (item.doctype === 'TRAILER') {
          trailerDocs.push(item);
        } else {
          otherDocs.push(item);
        }
      }
      setUploadList({
        otherDocs,
        trailerDocs,
        currentDocs: [...trailerDocs, ...otherDocs]
      });
    }
  }, [documentsList]);

  const [uploadList, setUploadList] = useState({ currentDocs: [] });
  const isDVIROnlyFlagEnabled = isdvirOnly();
  const isRiskTheoryIsFLmanagar = window?.$environment?.SERVICE_PROVIDER === 'riskTheory' && hasRestrictedRole(['FLMANAGER'])
  const isWebfleet = window?.$environment?.SERVICE_PROVIDER === 'webfleet';

  const showError = (field, validationErrors, isNotValid, apiError) => {
    if (validationErrors[field] && !isNotValid) {
      return <Message type="error" message={errors[field]} />;
    }
    if (apiError?.[field]) {
      return <Message type="error" message={apiError[field]} />;
    }
  };
  return (
    <>
      <Styled.Wrapper className="vehicleWrap">
        <Styled.LeftWrapper>
          <div className={window?.$environment?.clutchNewUI ? 'section-wrapper' : ''}>
            <SubHeader
              text={i18n.t('common.trailerInformation')}
              className="subheaderSection trailerHeading"
              variant="h1"
            />
            <div className="formWrap">
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1">{i18n.t('trailers.trailerType')}</Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="type"
                    onChange={(event) => {
                      customProps.setInitialValues({
                        ...values,
                        type: event
                      });
                      handleEdit(event, { field: 'type' });
                    }}
                    as={Select}
                    value={getFilterLocaleValues([values['type']], i18n)}
                    suggestions={getFilterLocaleValues(customProps.trailerTypes, i18n)}
                    disabled={isRiskTheoryIsFLmanagar}
                  />
                  {showError(
                    'type',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1">{i18n.t('vehicles.unitId')}</Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="name"
                    handleOnChange={(event) => {
                      customProps.setInitialValues({
                        ...values,
                        name: event
                      });
                      handleEdit(event, { field: 'name' });
                    }}
                    as={Input}
                    placeholderText={i18n.t('common.unitId')}
                    value={values['name']}
                    type="text"
                  />
                  {showError(
                    'name',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              {window.$environment?.SERVICE_PROVIDER === 'riskTheory' &&
                <FormStyle.default.FieldsWrapper width="100%">
                  <Label variant="body1">{i18n.t('vehicles.wlId')}</Label>
                  <FormStyle.default.TextWrapper>
                    <Field
                      name="wlId"
                      handleOnChange={(event) => {
                        customProps.setInitialValues({
                          ...values,
                          wlId: event
                        });
                        handleEdit(event, { field: 'wlId' });
                      }}
                      as={Input}
                      value={values['wlId']}
                      type="text"
                      disabled={isRiskTheoryIsFLmanagar}
                    />
                    {showError(
                      'wlId',
                      errors,
                      isNotValid,
                      customProps.hasApiStatus?.errorMessage
                    )}
                  </FormStyle.default.TextWrapper>
                </FormStyle.default.FieldsWrapper>}
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1">{i18n.t('vehicles.vin')}</Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="vin"
                    handleOnChange={(event) => {
                      customProps.setInitialValues({
                        ...values,
                        vin: event
                      });
                      handleEdit(event, { field: 'vin' });
                    }}
                    onBlur={(event) =>
                      customProps.isValidVin({
                        apiId: 'isValidVIN',
                        data: {
                          vin: event,
                          idPub: values?.idPub,
                          isEdit: values?.isEdit,
                          companyId: values?.companyId
                        }
                      })
                    }
                    as={Input}
                    placeholderText={i18n.t('common.vin')}
                    value={values['vin']}
                    type="text"
                    disabled={isRiskTheoryIsFLmanagar}
                  />
                  {showError(
                    'vin',
                    errors,
                    isNotValid,
                    hasValue(customProps.apiStatus.status, 'api', 'isValidVIN')
                      ?.errorMessage
                  )}
                  {values['vin'] && showError(
                    'vinError',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1">{i18n.t('vehicles.year')}</Label>
                <FormStyle.default.TextWrapper className="yearSelect">
                  <Field
                    name="year"
                    onChange={(event) => {
                      customProps.setInitialValues({
                        ...values,
                        year: event
                      });
                      handleEdit(event, { field: 'year' });
                    }}
                    as={Select}
                    value={values['year']}
                    suggestions={customProps.years}
                    disabled={isRiskTheoryIsFLmanagar}
                  />
                  {showError(
                    'year',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1">{i18n.t('common.make')}</Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="make"
                    handleOnChange={(event) => {
                      customProps.setInitialValues({
                        ...values,
                        make: event
                      });
                      handleEdit(event, { field: 'make' });
                    }}
                    as={Input}
                    placeholderText={i18n.t('common.make')}
                    value={values['make']}
                    type="text"
                    disabled={isRiskTheoryIsFLmanagar}
                  />
                  {showError(
                    'make',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1">{i18n.t('vehicles.model')}</Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="model"
                    handleOnChange={(event) => {
                      customProps.setInitialValues({
                        ...values,
                        model: event
                      });
                      handleEdit(event, { field: 'model' });
                    }}
                    as={Input}
                    placeholderText={i18n.t('common.model')}
                    value={values['model']}
                    type="text"
                    disabled={isRiskTheoryIsFLmanagar}
                  />
                  {showError(
                    'model',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1">{i18n.t('common.plateNumber')}</Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="plateNumber"
                    handleOnChange={(event) => {
                      customProps.setInitialValues({
                        ...values,
                        plateNumber: event
                      });
                      handleEdit(event, { field: 'plateNumber' });
                    }}
                    as={Input}
                    placeholderText={i18n.t('common.plateNumber')}
                    value={values['plateNumber']}
                    type="text"
                  />
                  {showError(
                    'plateNumber',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              {!isDVIROnlyFlagEnabled &&
                (
                  <>
                    <FormStyle.default.FieldsWrapper width="100%">
                      <Label variant="body1">{i18n.t('common.gvw')}</Label>
                      <FormStyle.default.TextWrapper>
                        <Field
                          name="gvw"
                          handleOnChange={(event) => {
                            customProps.setInitialValues({
                              ...values,
                              gvw: event
                            });
                            handleEdit(event, { field: 'gvw' });
                          }}
                          as={Input}
                          placeholderText={i18n.t('common.gvw')}
                          value={values['gvw']}
                          type="text"
                        />
                        {showError(
                          'gvw',
                          errors,
                          isNotValid,
                          customProps.hasApiStatus?.errorMessage
                        )}
                      </FormStyle.default.TextWrapper>
                    </FormStyle.default.FieldsWrapper>

                    {insuracePortalUserRole &&
                      <FormStyle.default.FieldsWrapper width="100%">
                        <Label variant="body1">{i18n.t('policyHolder')}</Label>
                        <FormStyle.default.TextWrapper>
                          <Field
                            name="company"
                            onChange={(event) => {
                              customProps.setInitialValues({
                                ...values,
                                company: event
                              });
                              handleEdit(event, { field: 'company' });
                              getRegions(event.value)
                              recallUpdate(event.value)
                            }}
                            as={Select}
                            disabled={window.location.href.includes('edit')}
                            placeholder={i18n.t('common.pleaseSelect')}
                            value={values['company']}
                            suggestions={customProps.companies}
                          />
                          {showError(
                            'company',
                            errors,
                            isNotValid,
                            customProps.hasApiStatus?.errorMessage
                          )}
                        </FormStyle.default.TextWrapper>
                      </FormStyle.default.FieldsWrapper>
                    }

                    <FormStyle.default.FieldsWrapper width="100%">
                      <Label variant="body1">{i18n.t('common.assignedVehicle')}</Label>
                      <FormStyle.default.TextWrapper>
                        <Field
                          name="vehicle"
                          onChange={(event) => {
                            customProps.setInitialValues({
                              ...values,
                              vehicleIdPub: event
                            });

                            handleEdit(event, { field: 'vehicleIdPub' });
                          }}
                          as={Select}
                          placeholder={i18n.t('common.pleaseSelect')}
                          value={values['vehicleIdPub']}
                          suggestions={customProps.vehicleList}
                          isClearable
                        />
                        {showError(
                          'vehicleIdPub',
                          errors,
                          isNotValid,
                          customProps.hasApiStatus?.errorMessage
                        )}
                      </FormStyle.default.TextWrapper>
                    </FormStyle.default.FieldsWrapper>
                  </>)}
              {window.$environment?.SERVICE_PROVIDER !== 'clutch' && window.$environment?.SERVICE_PROVIDER !== 'riskTheory' && window.$environment?.SERVICE_PROVIDER !== 'webfleet' && !isDVIROnlyFlagEnabled && (
                <FormStyle.default.FieldsWrapper width="100%">
                  <Label variant="body1">
                    {i18n.t('common.nextMaintenanceDate')}
                  </Label>
                  <FormStyle.default.TextWrapper className="yearSelect">
                    <Field
                      name="nextMaintenanceDate"
                      onChange={(event) => {
                        customProps.setInitialValues({
                          ...values,
                          next_maintenance_date: moment(event).format(
                            UI_DATE_FORMAT
                          )
                        });

                        handleEdit(event, {
                          field: 'next_maintenance_date',
                          type: 'date'
                        });
                      }}
                      locale={getDateLocale()}
                      as={ReactDatePicker}
                      placeholderText={UI_DATE_LOWER_FORMAT}
                      value={values['next_maintenance_date']}
                      type="date"
                      showPopperArrow={false}
                      showTimeSelect={false}
                      minDate={new Date()}
                      showDisabledMonthNavigation
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      isClearable={true}
                    />
                    {showError(
                      'next_maintenance_date',
                      errors,
                      isNotValid,
                      customProps.hasApiStatus?.errorMessage
                    )}
                  </FormStyle.default.TextWrapper>
                </FormStyle.default.FieldsWrapper>
              )}
              {!isDVIROnlyFlagEnabled &&
                (
                  <>
                    <FormStyle.default.FieldsWrapper width="100%">
                      <Label variant="body1">{i18n.t('common.notes')}</Label>
                      <FormStyle.default.TextareaWrapper>
                        <Field
                          name="notes"
                          handleOnChange={(event) => {
                            customProps.setInitialValues({
                              ...values,
                              notes: event
                            });

                            handleEdit(event, { field: 'notes' });
                          }}
                          as={Input}
                          multiLine
                          value={values['notes']}
                          type="text"
                        />

                        {showError(
                          'notes',
                          errors,
                          isNotValid,
                          customProps.hasApiStatus?.errorMessage
                        )}
                      </FormStyle.default.TextareaWrapper>
                    </FormStyle.default.FieldsWrapper>

                    {window.$environment.SERVICE_PROVIDER == 'patriot' ? <FormStyle.default.FieldsWrapper width="100%">
                      <Label variant="body1">{i18n.t('Tracking Device')}</Label>
                      <FormStyle.default.TextWrapper>
                        <Field
                          name="trackingDevice"
                          onChange={(event) => {
                            handleEdit(event, { field: 'trackingDevice' });
                            customProps.setInitialValues({
                              ...values,
                              trackingDevice: event
                            });
                          }}
                          as={Select}
                          value={getFilterLocaleValues([values['trackingDevice']], i18n)}
                          suggestions={getFilterLocaleValues(customProps.trackingDeviceList, i18n)}
                        />
                        {showError(
                          'trackingDevice',
                          errors,
                          isNotValid,
                          customProps.hasApiStatus?.errorMessage
                        )}
                      </FormStyle.default.TextWrapper>
                    </FormStyle.default.FieldsWrapper> : null}

                    <FormStyle.default.FieldsWrapper width="100%">
                      <Label variant="body1">{i18n.t('common.deviceSn')}</Label>
                      <FormStyle.default.TextWrapper>
                        <Field
                          name="deviceSn"
                          handleOnChange={(event) => {
                            customProps.setInitialValues({
                              ...values,
                              esn: event
                            });

                            handleEdit(event, { field: 'esn' });
                          }}
                          as={Input}
                          placeholderText={i18n.t('common.deviceSn')}
                          value={values['esn']}
                          type="text"
                        />
                        {showError(
                          'esn',
                          errors,
                          isNotValid,
                          customProps.hasApiStatus?.errorMessage
                        )}
                      </FormStyle.default.TextWrapper>
                    </FormStyle.default.FieldsWrapper>
                  </>)}
              {(layout !== 'Clutch'
                // || serviceProvider === 'clutch'
              ) && (
                  <FormStyle.default.FieldsWrapper width="100%">
                    <Label variant="body1">{i18n.t('common.mechanic')}</Label>
                    <FormStyle.default.TextWrapper>
                      <Field
                        name="mechanic"
                        onChange={(event) => {
                          customProps.setInitialValues({
                            ...values,
                            mechanicId: event
                          });

                          handleEdit(event, { field: 'mechanicId' });
                        }}
                        as={Select}
                        placeholder={i18n.t('common.pleaseSelect')}
                        value={values['mechanicId']}
                        suggestions={customProps.mechanicList}
                        isClearable
                      />
                      {showError(
                        'mechanicId',
                        errors,
                        isNotValid,
                        customProps.hasApiStatus?.errorMessage
                      )}
                    </FormStyle.default.TextWrapper>
                  </FormStyle.default.FieldsWrapper>
                )}
              {!isDVIROnlyFlagEnabled && serviceProvider !== 'webfleet' && (
                <Department
                  values={values}
                  handleEdit={handleEdit}
                  i18n={i18n}
                  fetchTerminals={customProps.fetchTerminals}
                  fetchDepartments={customProps.fetchDepartments}
                  fetchSubDepartments={customProps.fetchSubDepartments}
                  regions={regions}
                  terminals={terminals}
                  departments={departments}
                  subDepartments={subDepartments}
                  disabledCriteria={disabledCriteria}
                />
              )}
              {hasRestrictedRole(['FLMANAGER']) &&
                hasEnabledFeature('customDvirEnabled') && (
                  <FormStyle.default.FieldsWrapper width="100%">
                    <Label variant="body1">
                      {i18n.t('vehicles.inspectionForm')}
                    </Label>
                    <FormStyle.default.TextWrapper>
                      <Field
                        name="customDvirFormId"
                        onChange={(event) => {
                          customProps.setInitialValues({
                            ...values,
                            customDvirFormId: event
                          });

                          handleEdit(event, { field: 'customDvirFormId' });
                        }}
                        isClearable
                        as={Select}
                        placeholder={i18n.t('common.pleaseSelect')}
                        value={values['customDvirFormId']}
                        suggestions={customProps.forms}
                        disabled={isWebfleet}
                      />
                    </FormStyle.default.TextWrapper>
                  </FormStyle.default.FieldsWrapper>
                )}
            </div>
          </div>
        </Styled.LeftWrapper>
        {window.$environment?.SERVICE_PROVIDER !== 'simplex' && <Styled.RightWrapper>
          {isDVIROnlyFlagEnabled &&
            (<Styled.DVIRRightWrapper>
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1">{i18n.t('common.assignedVehicle')}</Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="vehicle"
                    onChange={(event) => {
                      customProps.setInitialValues({
                        ...values,
                        vehicleIdPub: event
                      });

                      handleEdit(event, { field: 'vehicleIdPub' });
                    }}
                    as={Select}
                    placeholder={i18n.t('common.pleaseSelect')}
                    value={values['vehicleIdPub']}
                    suggestions={customProps.vehicleList}
                    isClearable
                  />
                  {showError(
                    'vehicleIdPub',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              {!hasRestrictedRole(['FLMANAGER']) && (
                <FormStyle.default.FieldsWrapper width="100%">
                  <Label variant="body1">{i18n.t('common.mechanic')}</Label>
                  <FormStyle.default.TextWrapper>
                    <Field
                      name="mechanic"
                      onChange={(event) => {
                        customProps.setInitialValues({
                          ...values,
                          mechanicId: event
                        });

                        handleEdit(event, { field: 'mechanicId' });
                      }}
                      as={Select}
                      placeholder={i18n.t('common.pleaseSelect')}
                      value={values['mechanicId']}
                      suggestions={customProps.mechanicList}
                      isClearable
                    />
                    {showError(
                      'mechanicId',
                      errors,
                      isNotValid,
                      customProps.hasApiStatus?.errorMessage
                    )}
                  </FormStyle.default.TextWrapper>
                </FormStyle.default.FieldsWrapper>)
              }
              {!hasRestrictedRole([ROLES.SERVICE_MANAGER]) &&
                <Department
                  values={values}
                  handleEdit={handleEdit}
                  i18n={i18n}
                  fetchTerminals={customProps.fetchTerminals}
                  fetchDepartments={customProps.fetchDepartments}
                  fetchSubDepartments={customProps.fetchSubDepartments}
                  regions={regions}
                  terminals={terminals}
                  departments={departments}
                  subDepartments={subDepartments}
                  disabledCriteria={disabledCriteria}
                />}
            </Styled.DVIRRightWrapper>)}
          {!isDVIROnlyFlagEnabled && (
            <div className={window?.$environment?.clutchNewUI ? 'section-wrapper' : ''}>
              <SubHeader
                text={i18n.t('common.documents')}
                className="subheaderSection documentsHeader"
                variant="h1"
              />
              <Styled.documentWrapper>
                <UploadList
                  trailerIdPub={values['idPub']}
                  isEdit={values['isEdit']}
                  list={uploadList.currentDocs}
                  saveDocument={customProps.saveDocument}
                  deleteDocument={customProps.deleteDocument}
                  apiStatus={customProps.apiStatus}
                  callback={customProps.callback}
                  logBook={customProps.logBook}
                  downloadDocument={customProps.downloadDocument}
                />
              </Styled.documentWrapper>
            </div>
          )}
        </Styled.RightWrapper>}
      </Styled.Wrapper>
      {typeof customProps.hasApiStatus?.errorMessage === 'string' && (
        <Message
          type="error"
          asTags
          message={i18n.t(customProps.hasApiStatus.errorMessage)}
        />
      )}
      <Styled.submitUser>
        <FormStyle.default.ButtonsWrapper className="submitSection">
          <Button
            className="buttonStyle"
            type="reset"
            label={i18n.t('common.cancel')}
            onClick={(e) => {
              cancelAction();
            }}
          />
          <Button
            type="submit"
            className="buttonStyle"
            label={i18n.t('common.save')}
            onClick={(e, d) => {
              const validateResponse = validateForm();
              validateResponse.then((data) => {
                submitAction(data);
              });
            }}
          />
        </FormStyle.default.ButtonsWrapper>
      </Styled.submitUser>
    </>
  );
};

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  customProps: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};

export default RenderComponent;
