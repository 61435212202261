import {
    deleteReportSchedulingApi,
    fetchDownloadCenterListApi, fetchReportTypes, fetchSchedulingListApi, editSchedulingAPI
} from '../../../config/apis';

const initialState = {
    list: {},
    reportTypes: [],
    schedulingList: {}
};

export const downloadCenter = {
    state: { ...initialState },
    reducers: {
        listSuccess(state, payload) {
            return { ...state, ...payload };
        },
        listFailed(state) {
            return { ...state, list: {} };
        },
        schedulingListSuccess(state, payload) {
            return { ...state, ...payload };
        },
        schedulingListFailed(state) {
            return { ...state, schedulingList: {} };
        }
    },
    effects: (dispatch) => ({
        async fetchData(payload) {
            try {
                const response = await dispatch.asyncRequests.handleAsyncRequests({
                    payload: {
                        api: fetchDownloadCenterListApi,
                        data: payload.data?.request
                    },
                    id: payload.apiId
                });
                response && this.listSuccess({ list: response });
            } catch (error) {
                this.listFailed();
            }
        },
        async fetchSchedulingList(payload) {
            try {
                const response = await dispatch.asyncRequests.handleAsyncRequests({
                    payload: {
                        api: fetchSchedulingListApi,
                        data: payload.data?.request
                    },
                    id: payload.apiId
                });
                response && this.schedulingListSuccess({ schedulingList: response });
                if(payload.callbackFn) payload.callbackFn()
            } catch (error) {
                this.schedulingListFailed();
            }
        },
        async deleteScheduling(payload) {
            try {
                const response = await dispatch.asyncRequests.handleAsyncRequests({
                    payload: {
                        api: {
                            ...deleteReportSchedulingApi,
                            url: deleteReportSchedulingApi.url.replace(
                                '{id}',
                                payload.data.id
                            )
                        }
                    },
                    callback: payload.callback,
                    id: payload.apiId
                });
                if (response) {
                    this.fetchSchedulingList({
                        ...payload.data.callbackPayload, callbackFn: () => {
                            payload.callback('success', 'Successfully deleted.');
                        }
                    })
                }
            } catch (error) {
            }
        },
        async editScheduling(payload) {
            try {
                const response = await dispatch.asyncRequests.handleAsyncRequests({
                    payload: {
                        api: editSchedulingAPI,
                        data: payload.data
                    },
                    id: payload.apiId
                });
                if(response) {
                    this.fetchSchedulingList({
                        ...payload.callbackPayload, callbackFn: () => {
                            payload.callback(true)
                        }
                    })                    
                } 
            } catch (error) {
                payload.callback(false)
            }
        },
        async fetchReportTypes(payload) {
            try {
                const response = await dispatch.asyncRequests.handleAsyncRequests({
                    payload: {
                        api: fetchReportTypes
                    },
                    id: payload.apiId
                });
                response && this.listSuccess({ reportTypes: response });
            }
            catch (error) {
            }
        }
    })
};
