import React, { useEffect, lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import RenderComponent from '@/components/modules/DynamicRenderPage';

import ParentStyled from './style';
import { loadStyle, loadPageConfig, getQueryStringKeys } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'pages/web/Vehicles');

const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.vehicleList[0].name}`));
const Content = lazy(() => import(`@/${config.vehicleList[1].name}`));
const TopActions =
  config.vehicleList[0].children &&
  lazy(() => import(`${config.vehicleList[0].children.name}`));

const Vehicles = (parentProps) => {
  const {
    fetchVehicles,
    getFilters,
    vehiclePageMap,
    fetchVehiclePageMap,
    getDocumentToken,
    history,
    i18n,
    theme,
    fetchDriverStatusTableHistoryMap,
    fetchFleetCompanies
  } = parentProps;
  const match = useParams();
  const commonParentProps = { history, i18n, Styled, theme };

  useEffect(() => {
    const breadcrumbHistoryId = getQueryStringKeys(history.location.search, 'id');
    fetchVehicles({
      apiId: 'fetchVehicles'
    });
    // !vehiclePageMap.length &&
    fetchFleetCompanies({
      apiId: 'fetchFleetCompanies',
    });

    getFilters({ apiId: 'getFilters' });
    getDocumentToken({ apiId: 'getDocToken' });

    if (breadcrumbHistoryId)
      fetchDriverStatusTableHistoryMap({
        data: { id: breadcrumbHistoryId },
        apiId: 'getHistory'
      })
    else
      fetchVehiclePageMap({
        apiId: 'vehicleMap'
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [pagination, setPagination] = useState(false);

  return (
    <>
      <RenderComponent
        {...config.vehicleList[0]}
        parentProps={{
          ...parentProps,
          pagination,
          setPagination
        }}
        commonParentProps={commonParentProps}
        component={Title}
        childComponent={TopActions}
      />
      <RenderComponent
        {...config.vehicleList[1]}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        component={Content}
        pagination={pagination}
        setPagination={setPagination}
      />
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    vehicles: state.vehicles,
    vehiclePageMap: state.vehiclePageMap,
    filters: state.filters,
    logBook: state.logBook,
    fleetCompanies: state.fleetCompanyList.list,
    videoUrl: state.camera.videoUrl,
    cameraStatus: state.camera.cameraStatus
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchVehicles: (data) => dispatch.vehicles.fetchData(data),
    fetchVehicleRadius: (data) => dispatch.vehicles.fetchRadiusData(data),
    handleDeleteAlert: (data) => dispatch.vehicles.handleDeleteAlert(data),
    filterSuccess: (data) => dispatch.vehicles.filterSuccess(data),
    filterBoth: (data) => dispatch.vehicles.filterBoth(data),
    trailerFilterSuccess: (data) => dispatch.vehicles.trailerFilterSuccess(data),
    handleExportVehicle: (data) => dispatch.vehicles.handleExportVehicle(data),
    handleExportTrailer: (data) => dispatch.trailers.handleExportTrailer(data),
    handleImportVehicle: (data) => dispatch.vehicles.handleImportVehicle(data),
    handleImportTrailer: (data) => dispatch.vehicles.handleImportTrailer(data),
    handleImportVehiclePost: (data) =>
      dispatch.vehicles.handleImportVehiclePost(data),
    handleImportTrailerPost: (data) =>
      dispatch.vehicles.handleImportTrailerPost(data),
    fetchVehiclePageHistoryMap: (data) =>
      dispatch.vehiclePageMap.fetchVehiclePageHistoryMap(data),
    fetchVehiclePageMap: (data) =>
      dispatch.vehiclePageMap.fetchVehiclePageMap(data),
    openDocument: (data) => dispatch.documents.openDocument(data),
    getFilters: (data) => dispatch.filters.fetchData(data),
    getDrivers: (data) => dispatch.vehicles.getDriverNames(data),
    getDocumentToken: (data) => dispatch.logBook.documentToken(data),
    handleTrailerDeleteAlert: (data) =>
      dispatch.vehicles.handleTrailerDeleteAlert(data),
    fetchDriverStatusTableHistoryMap: (data) => dispatch.vehiclePageMap.fetchDriverStatusTableHistoryMap(data),
    fetchFleetCompanies: (data) => dispatch.fleetCompanyList.fetchData(data),
    fetchLiveUrl: (data) => dispatch.camera.fetchLiveUrl(data),
    fetchCameraStatus: (data) => dispatch.camera.fetchCameraStatus(data),
    clearFetchLiveUrlTimeout: () => dispatch.camera.clearFetchLiveUrlTimeout()
  };
};

export default connect(mapState, mapDispatch)(Vehicles);

Vehicles.propTypes = {
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  fetchVehicles: PropTypes.func.isRequired,
  handleDeleteAlert: PropTypes.func.isRequired,
  filterSuccess: PropTypes.func.isRequired,
  filterBoth: PropTypes.func.isRequired,
  handleExportVehicle: PropTypes.func.isRequired,
  handleExportTrailer: PropTypes.func.isRequired,
  handleImportVehicle: PropTypes.func.isRequired,
  handleImportTrailer: PropTypes.func.isRequired,
  handleImportVehiclePost: PropTypes.func.isRequired,
  handleImportTrailerPost: PropTypes.func.isRequired,
  fetchVehiclePageHistoryMap: PropTypes.func.isRequired,
  fetchVehiclePageMap: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  getFilters: PropTypes.func.isRequired,
  fetchLiveUrl: PropTypes.func.isRequired,
  fetchCameraStatus: PropTypes.func.isRequired,
  getDrivers: PropTypes.func.isRequired
};
