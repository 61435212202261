import { fetchBreadCrumbForMapApi } from '../../../../config/apis';

const initialState = [];

export const breadCrumbForMap = {
  state: [...initialState],
  reducers: {
    fetchSuccess(state, payload) {
      return [...payload];
    },
    fetchFailed() {
      return [...initialState];
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...fetchBreadCrumbForMapApi
            },
            data: { ...payload.data }
          },
          id: payload.apiId
        });
        this.fetchSuccess(response);
      } catch (error) {
        this.fetchFailed();
      }
    }
  })
};
