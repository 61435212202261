import base from './base';

export default {
  ...base,
  common: {
    ...base.common,
    en: 'EN',
    sp: 'SP'
  },
  footer: {
    address: {
      one: 'Business Address',
      two: 'telefono / Fax',
      three: 'Online',
      onedetails: '2828 North Central Ave., 7th Floor Phoenix, AZ 85004',
      twodetails: '757-941-7806',
      threedetails: 'eldsupport@truckerpath.com'
    },
    patent: 'Patent pending',
    copyright: '2020 Trucker Path All Rights Reserved',
    PrivacyLegal: 'Privacy Policy'
  }
};
