import base from './base';

export default {
  ...base,
  common: {
    ...base.common,
    en: 'EN',
    sp: 'SP'
  },
  footer: {
    address: {
      one: 'Business Address',
     
      two: 'número de teléfono',
      three: 'email',
      onedetails: ' Houston, TX',
      twodetails: ' P. 844-636-1360 F. 888-512-1328',
      threedetails: 'support@trackonfleet.com'
    },
    patent: 'Patent pending',
    copyright: '2020 Trackon-ELD All Rights Reserved',
    PrivacyLegal: 'Privacy Policy'
  }
};
