import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';

import { useHistory } from 'react-router-dom';
import useTheme from '@material-ui/core/styles/useTheme';
import { useTranslation } from 'react-i18next';

import ParentStyled from './style';
import Content from './Component';
import { loadStyle } from '@/utils/utils';
const Title = lazy(() => import(`@/components/Title`));

let Styled = loadStyle(ParentStyled, 'pages/web/Feedback');
const Feedback = ({ apiStatus, ...rest }) => {
  const history = useHistory();
  const theme = useTheme();
  const i18n = useTranslation();
  const contentProps = {
    Styled,
    i18n,
    history,
    ...rest
  };
  return (
    <>
      <Title header={'leftMenu.feedback'} i18n={i18n} />
      <Box style={{ ...theme.palette.contentStyle }}>
        <Content {...contentProps} />
      </Box>
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests
  };
};
const mapDispatch = (dispatch) => {
  return {
    sendFeedback: (data) => dispatch.feedback.sendFeedback(data)
  };
};

export default connect(mapState, mapDispatch)(Feedback);
Feedback.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};
