import React, { lazy, useEffect, useRef, useState } from 'react';
import GaugeComponent from 'react-gauge-component';
import Styled from './style';

const layout = window?.$environment?.CURRENT_LAYOUT;

const Label = lazy(() => import(`@/components/UI/Label/${layout}`));

const GaugeInfoChart = ({
    value,
    textClassName,
    hideScore = false,
}) => {
    const wrapperRef = useRef(null);
    const gaugeRef = useRef(null);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        setIsReady(true);
    }, []);

    return (
        <Styled.Wrapper ref={wrapperRef}>
            {isReady && (
                <GaugeComponent
                    ref={gaugeRef} 
                    type="semicircle"
                    id="guage"
                    className={`gauge-component-class ${isReady ? 'hide-arrows rotate-text' : ''}`}
                    arc={{
                        colorArray: ['red', 'orange', 'yellow', 'green'],
                        subArcs: [
                            { limit: 25, color: 'red' },
                            { limit: 50, color: 'orange' },
                            { limit: 75, color: 'yellow' },
                            { limit: 100, color: 'green' },
                        ],
                        padding: 0.02,
                        width: 0.4
                    }}
                    hideText={true}
                    pointer={{
                        color: '#fff',
                        length: 0.90,
                        width: 16,
                        animate: true,
                        elastic: true,
                        animationDelay: 200,
                    }}
                    labels={{
                        valueLabel: { hide: true },
                        tickLabels: {
                            type: 'outer',
                            hideMinMax: true,
                            defaultTickValueConfig: {
                                formatTextValue: (value) => {
                                    let text = value === 12.5 ? "0-70" : 
                                               value === 37.5 ? "70-79" : 
                                               value === 62.5 ? "80-89" : "90-100";
                                    return text;
                                },
                                style: {
                                    fontSize: "0.875rem", fill: "#eaeaea", fontWeight: 'bolder',
                                    textAnchor: "middle",
                                },
                                defaultTickLineConfig: { hide: true }
                            },
                            ticks: [
                                { value: 12.5 },
                                { value: 37.5 },
                                { value: 62.5 },
                                { value: 87.5 }
                            ],
                        }
                    }}
                    value={value}
                    minValue={0}
                    maxValue={100}
                />
            )}
            {hideScore && <Label className="label" variant="h1">{value}</Label>}
        </Styled.Wrapper>
    );
};

export default GaugeInfoChart;
