import styled from 'styled-components';
import Parent from './style';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Parent.Wrapper)`
position: relative;
.CSAForms .vertical-button .MuiButtonBase-root {
    margin-top: 33px;
}
`;
const TopWrapper = styled(Parent.TopWrapper)`
 
`;
const TableWrapper = styled(Parent.TableWrapper)`
 
`;

const Content = styled(Box)`
${({theme}) => `
.logwrapper > header{
  ${theme.colors.NEW_THEME ? `
  .Mui-selected {
    :hover{
      color: #fff;
      .MuiTab-wrapper {
        color: #fff;
      }
    }
  }
  ` : ''}
}
  .logwrapper {
    overflow: hidden;
    display: flex;
    // height: 100vh;
    header.MuiAppBar-positionStatic {
      width: 16%;
      border-right: 1px solid #2c3249;
      padding-top: 3%;
    }
    div[role='tabpanel'].MuiTypography-root {
      width: 85%;
    }
    div[role='tabpanel'].MuiTypography-root {
      .MuiPaper-root.MuiPaper-rounded{
        
          overflow: hidden;
        
      }
    }
    .filterdetails {
      padding-left: 2%;
    }
    .MuiTab-wrapper {
      align-items: normal;
      text-align: left;
    }
    .MuiButtonBase-root.MuiTab-root {
      border-right: 0;
      padding-left: 8%;
      text-transform: capitalize !important;
      border-bottom: 1px solid ${theme.colors.SECONDARY_BORDER};
      background: ${theme.colors.PRIMARY_MAIN};
      margin-right: 0 !important;
      :hover {
        border-bottom: 1px solid #2c3249 !important;
        color: ${theme.colors.TAB_HIGHLIGHT};
      }
    }
    .MuiTabs-flexContainer .Mui-selected {
      background-color: ${theme.colors.BACKGROUND_MENU};
      border-bottom: 0 !important;
      font-weight: 700;
      color: ${theme.colors.PRIMARY_CONTRAST};      
    }
    .exportContainer {
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        flex: 0 0 11%;
      }
    }
  }`}`;
const Styled = { Wrapper, TopWrapper, TableWrapper, Content };


export default Styled;
