import React, { useState, useEffect, lazy } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import RenderComponent from './RenderComponent';

import { objectArrayFromDiffFields, getLastQuaters } from '@/utils/utils';
import { UI_DATE_FORMAT } from '@/config/constants/static';
import { useLocation } from 'react-router-dom';
import { values } from 'lodash';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));

const ViewForm = ({
  hasApiStatus,
  callback,
  vehicles,
  states,
  driverListDropDown,
  fetchIFTAStates,
  changeRequest,
  setPagination,
  apiIftaMileage,
  tableData,
  commonProps,
  i18n
}) => {
  const { state } = useLocation();
  const activeVehicles = objectArrayFromDiffFields(
    vehicles?.vehicles,
    'id',
    'unit_id'
  );
  const deActivatedVehicles = objectArrayFromDiffFields(
    vehicles?.deactivatedVehicles,
    'id',
    'unit_id'
  );

  const stateList = states?.map(e => ({ label: e, value: e }))

  const lastQuater = getLastQuaters(true)
  const [initialValues, setIntialValues] = useState({
    id: state?.id ? state?.id : { label: i18n.t('users.All'), value: '' },
    dateRange: state?.dateRange ? state?.dateRange : [lastQuater[0].format(UI_DATE_FORMAT), lastQuater[1].format(UI_DATE_FORMAT)],
    dateTimeFrom: state?.dateTimeFrom ? state?.dateTimeFrom : lastQuater[0].format(UI_DATE_FORMAT),
    dateTimeTo: state?.dateTimeTo ? state?.dateTimeTo : lastQuater[1].format(UI_DATE_FORMAT),
    state: { label: i18n.t('users.All'), value: '' },
  });

  return (
    <>
      <Form
        type="server"
        direction="row"
        initialValues={{ ...initialValues }}
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {
          const payload = {
              displayLength: 15,
              displayStart: 1,
              ...(fields?.dateTimeFrom ? { fromDate: moment(new Date(fields?.dateTimeFrom)).format('YYYY-MM-DD') } : {}),
              ...(fields?.dateTimeTo ? { toDate: moment(new Date(fields?.dateTimeTo)).format('YYYY-MM-DD') } : {}),
              ...(fields?.iftaMileageId ? { fromDate: moment(new Date(fields?.dateTimeFrom)).format('YYYY-MM-DD') } : {}),
              ...(fields?.state?.value !== "" ? { state: fields?.state?.value } : {}),
              ...(fields?.id?.value !== "" ? { vehicleIdPub: fields?.id?.value } : {}),
          }
          setPagination(true);
          changeRequest(payload);
          tableData(fields);
        }}
        customProps={{
          callback,
          hasApiStatus,
          driverListDropDown,
          commonProps,
          activeVehicles,
          deActivatedVehicles,
          stateList,
        }}
      />
    </>
  );
};

ViewForm.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired
};

export default ViewForm;
