import styled from 'styled-components';

export const ModalWrapper = styled.div`
  ${({ theme, width }) => `
  #modal-title {
    text-align: center;
  }
  .MuiButtonBase-root .menuItem span:hover {
    color: ${theme.colors.SECONDARY_MAIN};
  }
  .MuiButtonBase-root:hover {
    .menuItem span {
      color: ${theme.colors.SECONDARY_MAIN};
    }
  }
  .scheduleForm{
    overflow: auto;
    max-height: 600px;
    .itemWrapper{
      margin-bottom: 10px;
      display: -webkit-inline-box;
      width: 100%;
      .label {
        width: 18%;
      }
      .value {
        font-weight: 600;
      }
    }
    form {
      align-items: center;
    }
    .scheduleDateWrapper{
      width: 50%;
      .fieldWrapper{
        display: block !important;
      }
    }
    padding: 0 100px;
    .fullWidth{
      width:100%;
  }
  .radioWrapper{
      width:100%;
      margin-bottom: 10px !important;
      .label{
          align-items: center;
          display: flex;
          flex: 0 0 18% !important;
          color: ${theme.colors.PRIMARY_CONTRAST};
      }
      .recurrenceIntervalBox {
          display: block !important;
      }
      .infoText{
          color: ${theme.colors.SECONDARY_MAIN};
      }
  }
  .recurrenceIntervalBoxLabel{
      padding-bottom: 25px;;
  }
  }
  
  .departmentConfirm {
    text-align: center;
  }
  .MuiTouchRipple-root {
    display: none;
  }
  .MuiButtonBase-root .menuItem span {
    margin-left: 15px;
  }
  .MuiButtonBase-root .menuItem svg {
    margin-top: 4px;
  }
  li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root {
    padding-top: 7px;
    padding-bottom: 0px;
  }
  li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root:last-child {
    padding-bottom: 5px;
  }
  .MuiButton-root {
    font-size: 23px;
  }
  .carousel-root {
    .carousel.carousel-slider {
      padding: 15px;
      .control-arrow{
        top: 50%;
        width: 36px;
        height: 36px;
        border: 2px solid #fff;
        border-radius: 50%;
        background: #1b1b1b !important;
        &:hover {
          background: none;
        }
      }
      .control-arrow:before{
        top: -4px;
        left: -2px;
      }
      .control-prev.control-arrow{
        left: 19px;
      }
    }
    .legend {
      opacity: 1;
      bottom: -11px;
    }
  }
  .deleteParent {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    margin-top: 8px;
    h2 {
      text-align: center;
    }
    .imageSection {
      width: 100%;
      text-align: center;
      img {
        width: 120px;
      }
    }
    .MuiSvgIcon-root {
      fill: #f8bb86;
      width: 110px;
      height: auto;
      margin: 0 auto;
    }
    h1 {
      font-size: 30px;
    }
    h2 {
      color: ${theme.colors.MODAL_HEADING};
      font-size: 30px;
      text-align: center;
      font-weight: 600;
      text-transform: none;
      position: relative;
      margin-top: 5px;
      margin-bottom: 25px;
      padding: 0;
      line-height: 40px;
      display: block;
      width: 100%;
    }
    p {
      color: ${theme.colors.MODAL_TEXT};
      margin: 0;
    }
    .deletePopupBtn {
      display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
      justify-content: center;
      .deletebtn,
      .cancelBtn {
        margin: 25px 5px 0 5px;
      }
      .cancelBtn #button,
      .deletebtn #button {
        font-size: 23px;
        padding: 0 30px;
        @media (max-width: ${theme.breakpoints.values.md}px) {
          font-size: 20px;
        }
      }
    }
  }

  .vehicleModal {
    height: auto;
    padding: 10px;
    width: ${width ? width : '150px'};
    ul {
      list-style-type: none;
      padding-inline-start: 10px;
      margin-block-start: 0em;
      margin-block-end: 0em;
      padding-left: 0;
      li {
        padding-bottom: 7px;
      }
    }
    &::before {
      content: '';
      position: absolute;
      width: 0;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
      border-bottom: 6px solid ${theme.colors.LIGHT_BORDER};
      top: -8px;
      right: 7%;
    }
  }

  #closeBtn .iconContainer {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
    justify-content: flex-end;
  }
  .descModal {
    .headWrapper {
      font-size: 18px;
      color: #fff;
      font-weight: 600;
      text-align: left;
      margin: 15px 0;
    }
  }

  .driverNameModal {
    ul {
      list-style-type: none;
      padding-inline-start: 10px;
      margin-block-start: 0em;
      margin-block-end: 0em;
      padding-left: 0;
      padding-bottom: 30px;
    }
    li {
      border-bottom: 0.5px solid ${theme.colors.LIGHT_BORDER};
    }
    .headWrapper {
      font-size: 28px;
      text-transform: uppercase;
      color: ${theme.colors.LINK_COLOR};
      font-weight: 400;
      text-align: left;
      margin: 15px 0;
    }
  }
  `}
`;

export const ModalWrapper2 = styled.div`
  ${({ theme }) => `
  .departmentConfirm {
    text-align: center;
  }
  .errorSectionlist {
    .tcSection {
      height: 200px;
      overflow-y: scroll;
    }
    .scrollbar {
      min-height: 300px;
      color: ${theme.colors.PRIMARY_CONTRAST};
      padding-right: 4%;
    }
    #scroll::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: rgba(216, 216, 216, 0.08);
      opacity: 0.08;
    }

    #scroll::-webkit-scrollbar {
      display: block;
      width: 10px;
      background-color: rgba(216, 216, 216, 0.08);
    }

    #scroll::-webkit-scrollbar-thumb {
      height: 54px;
      background-color: ${window.$environment.WHITE_THEME ? '#5e5f67' : '#d8d8d8'};
    }
    ul {
      margin: 0;
      padding: 0;
      list-style-position: inside;
    }
  }
    .connectionTypeWrapper ul {
    list-style: none;
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
    li {

    background-color: ${theme.colors.PRIMARY_MAIN};
    }
  }
  .deletedRow + .MuiBox-root,
  .deletedRow {
    text-align: center;
  }

  .countreg {
    display: flex;
    padding-bottom: 14px;
    p:first-child {
        flex: 0 0 64%;
    }
}
.totalAmountreg {
    padding-top: 10px;
    border-top: 1px solid ${theme.colors.BLACK};
    display: flex;
    padding-bottom: 20px;
    p:first-child {
      flex: 0 0 64%;
  }
}
.carousel-root {
  .carousel.carousel-slider {
    padding: 15px;
    .control-arrow{
      top: 50%;
      width: 36px;
      height: 36px;
      border: 2px solid #fff;
      border-radius: 50%;
      background: #1b1b1b !important;
      &:hover {
        background: none;
      }
    }
    .control-arrow:before{
      top: -4px;
      left: -2px;
      position: relative;
    }
    .control-next.control-arrow:before{
      left:0;
    }
    .control-prev.control-arrow {
      left: 19px;
    }
    .control-next.control-arrow {
      right: 19px;
    }
  }
  .slide .legend {
    opacity: 1;
    bottom: -11px;
  }
}
.registrationTerms{
  .termsCondition {
    flex: 0 0 100%;
    color: ${theme.colors.PRIMARY_CONTRAST};
    font-size: 15px;
    padding-bottom: 10px;
    padding-bottom: 13px;
    line-height: 1.3;
    text-transform: capitalize;
  }
  .termsAgreeSection{
    flex-direction: column;
  }
  .links{
    color: ${theme.colors.LINK_COLOR};
    padding-left: 3px;
    padding-right: 4px;
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
  }
  ol {
    margin: 0;
    padding: 0;
    list-style-position: inside;
  }
  .subHeading{
    flex: 0 0 100%;
    color: ${theme.colors.PRIMARY_CONTRAST};
    font-size: 12px;
    font-weight: bold;
    text-transform: capitalize;
    font-family: tahoma;
  }
  .tcSection {
    height: 260px;
    overflow-y: scroll;
  }
  .scrollbar {
    min-height: 400px;
    color: #fff;
    padding-right: 4%;
  }
  #scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: rgba(216, 216, 216, 0.08);
    opacity: 0.08;
  }

  #scroll::-webkit-scrollbar {
    display: block;
    width: 10px;
    background-color: rgba(216, 216, 216, 0.08);
  }

  #scroll::-webkit-scrollbar-thumb {
    height: 54px;
    background-color: ${window.$environment.WHITE_THEME ? '#5e5f67' : '#d8d8d8'};
  }
  .buttonWrapper {
    display: flex;
    justify-content: center;
    .cancel {
      margin-right: 7%;
    }
    .MuiButton-root {
      min-width: 210px;
      margin-top:0;
    }
  }
  .headerResgistration {
    padding-left: 0 !important;
    margin-bottom: 0!important;
  }
  .normalCheckbox label{
    color: ${theme.colors.PRIMARY_CONTRAST} !important;
  }
}
.companyDataCard {
  display: flex;
  justify-content: center;
}
.paymentRegistration {
  padding-left: 0 !important;
  margin-bottom: 10px !important;
}
.paymentForm{
  .MuiButtonBase-root {
    padding: 0px 28px !important;
  }
}
  .successpopup {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flexbox;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    h2 {
      color: ${theme.colors.MODAL_HEADING};
      font-size: 30px;
      max-height: 350px;
      overflow: auto;
      text-align: center;
      font-weight: 600;
      text-transform: none;
      position: relative;
      margin-top: 5px;
      margin-bottom: 25px;
      padding: 0;
      line-height: 40px;
      display: block;
      width: 100%;
    }
    #button {
      margin-top: 20px;
    }
    .warningPopup {
      color: #575757;
    font-size: 30px;
    text-align: center;
    font-weight: 600;
    text-transform: none;
    position: relative;
    margin: 25px 0;
    padding: 0;
    line-height: 40px;
    display: block;
    }
    .warningPopupMessage {
      font-weight: 300;
    position: relative;
    text-align: inherit;
    float: none;
    margin: 0;
    padding: 0;
    line-height: normal;
    color: #797979;
    font-size: 18px;
    padding-bottom: 22px;
    }
  }
  .imageSection {
    width: 100%;
    text-align: center;
    img {
      width: 120px;
    }
  }
  .deletePopupBtn {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
    justify-content: center;
    .deletebtn,
    .cancelBtn {
      margin: 5px 5px 0 5px;
    }
    .cancelBtn #button,
    .deletebtn #button {
      font-size: 23px;
      padding: 0 30px;
      @media (max-width: ${theme.breakpoints.values.md}px) {
        font-size: 20px;
      }
    }
  }
  #closeBtn .iconContainer {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
    justify-content: flex-end;
  }

  .suspendedCompanyPay .amountCompanyPay{
    text-align:end;
  }
  `}
`;

export const ParentWrapper = styled.div`
  .modalParentWrapper div:first-child {
    background-color: transparent !important;
  }
`;
