import React, { lazy } from 'react';

import { pageUrls } from '@/config/constants/keys';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));

const AdvancedDvir = ({ className, label, history }) => {
  return (
    <Button
      type="submit"
      className={className}
      label={label}
      onClick={() => {
        history.push(pageUrls.advancedDvirEnabled);
      }}
    />
  );
};

export default AdvancedDvir;
