import styled from 'styled-components';
import Parent from '../Base/style';
import { getImagePath } from '../../../../utils/utils';
const CheckboxWrapper = styled(Parent)`
${({ theme }) => `
.normalCheckbox {
    svg {
        width: 16px;
        height: 16px;
        background: #efefef url(${getImagePath('uncheckedBackground.gif')});
    }
    rect.square {
      stroke: transparent;
      fill: transparent;
        rx: 2;
        ry: 2;
    }
    label {
      font-size: 12px;
      font-weight: bold;
    }
    .Checkbox .tick {
      stroke: transparent;
    }
    .Checkbox.checked .tick{
      stroke-dashoffset: -3;
    }
    .Checkbox.checked svg {
      background: #efefef url(${getImagePath('checkedBackground.gif')});
    }
    .Checkbox.checked .square {
        // fill-opacity: 1;
        // fill: #f8f8f7;
        // background: #efefef url(${getImagePath('checkedBackground.gif')});
        // stroke: #9A9B9B;
    }
  }
  `}
`;
export default CheckboxWrapper;
