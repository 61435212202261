import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
    ${({ theme }) => `
    `}
`;
const LeftWrapper = styled(Parent.LeftWrapper)`
    ${({ theme }) => `
    .subheaderSection {
        padding: 11px 6%;
        text-transform: capitalize;
      }
    .active-region{
        ${window?.$environment?.clutchNewUI && `
        background-color: #fff;
        font-weight: bold;
        color: #bdd731;
        border: 1px solid;`}
    }
    `}
`;
const TerminalWrapper = styled(Parent.TerminalWrapper)`
    ${({ theme }) => `
    .Nodata {
        font-size: 14px;
    }
    `}
`;
const RegionWrapper = styled(Parent.RegionWrapper)`
    ${({ theme }) => `
    font-size: 14px;
    box-shadow: 0 3px 15px rgba(0,0,0,0.02);
    background-color: ${theme.colors.ACCORDION_BACKGROUND};
    :hover {
        ${window?.$environment?.clutchNewUI && `background-color: #fff;`}
      }
    `}
`;
const RegionListWrapper = styled(Parent.RegionListWrapper)`
    ${({ theme }) => `
    `}
`;
const TerminalListWrapper = styled(Parent.TerminalListWrapper)`
    ${({ theme }) => `
    `}
`;
const Department = styled(Parent.Department)`
    ${({ theme }) => `
    // padding: 2%;
    // padding-top: 0;
    .AddButton {
        right: 0;
      }
    `}
`;
const RightWrapper = styled(Parent.RightWrapper)`
    ${({ theme }) => `
    .subheaderSection {
        text-transform: capitalize;
    }
    `}
`;
const Styled = {
    Wrapper,
    LeftWrapper,
    RightWrapper,
    RegionListWrapper,
    TerminalListWrapper,
    RegionWrapper,
    TerminalWrapper,
    Department
  };
  export default Styled;
