import styled from 'styled-components';
import Parent from '../Base/style';

const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
    .MuiPaper-root {
        background-color: ${theme.colors.ACCORDION_BACKGROUND};
    }
    .MuiExpansionPanelDetails-root {
        padding: 0;
        box-shadow: 0 3px 15px rgba(0,0,0,0.02);
        background-color: ${theme.colors.ACCORDION_BACKGROUND};
        border-bottom: .5px solid ${theme.colors.LIGHT_BORDER};
    }
    .MuiExpansionPanelSummary-root.Mui-expanded {
        height: 40px;
    }
    .MuiIconButton-edgeEnd .MuiSvgIcon-root {
        fill: ${theme.colors.LINK_COLOR};
    }
    .MuiButtonBase-root:active,
    .MuiButtonBase-root:hover {
      ${window?.$environment?.clutchNewUI && `background-color: #fff;`}
    }
`}
`;
const Header = styled(Parent.Header)``;
const Content = styled(Parent.Content)`
height: 40px;
`;

const Accordion = { Wrapper, Header, Content };

export default Accordion;
