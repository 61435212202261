import React, { lazy, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import RenderComponent from './RenderComponent';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));

const AddDVIRForm = ({
  hasApiStatus,
  callback,
  history,
  category,
  updateAdvancedConfig,
  setSelected
}) => {
  const { i18n } = useTranslation();
  const initialValues = {
    categoryType: '',
    vehicleSectionName: null
  };
  return (
    <>
      <Form
        type="server"
        direction="row"
        initialValues={{ ...initialValues }}
        className="dvirForms"
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {
          let finalData = {
            categoryType: fields.categoryType.value,
            vehicleSectionName: fields.vehicleSectionName,
            inspectionEnabled: false
          };
          updateAdvancedConfig({
            data: { data: finalData },
            callback: setSelected(fields.categoryType.value)
          });
          updateAdvancedConfig(finalData);
          setSelected(fields.categoryType.value);
        }}
        handleOnCancel={(fields) => {}}
        validationSchema={Yup.object().shape({
          categoryType: Yup.string().required(
            i18n.t('inspections.dvirError')
          ),
          vehicleSectionName: Yup.string().required(
            i18n.t('inspections.dvirError')
          )
        })}
        customProps={{
          history,
          callback,
          hasApiStatus,
          category
        }}
      />
    </>
  );
};

AddDVIRForm.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default AddDVIRForm;
