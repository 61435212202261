export const getLocalStorage = (key) => {
  const data = localStorage.getItem(
    window.$environment?.SERVICE_PROVIDER + key
  );
  return data;
};

export const setLocalStorage = (key, value) => {
  localStorage.setItem(window.$environment?.SERVICE_PROVIDER + key, value);
};

export const removeLocalStorage = (key) => {
  localStorage.removeItem(window.$environment?.SERVICE_PROVIDER + key);
};
export const clearLocalStorage = () => {
  localStorage.clear();
};
