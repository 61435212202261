import styled from 'styled-components';

const Container = styled.div`
  width: 50px;
  height: 50px;
  border: 4px solid #f27474;
  border-radius: 50%;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  align-items: center;
  justify-content: center;
  margin: 10px auto 25px;
`;

const LeftStick = styled.span`
  transform: rotate(45deg);
  position: relative;
  width: 25px !important;
  height: 5px;
  background-color: #f27474;
  left: 12px;
  border-radius: 2px;
`;

const RightStick = styled.span`
  transform: rotate(-45deg);
  position: relative;
  width: 25px !important;
  height: 5px;
  background-color: #f27474;
  left: -10px;
  border-radius: 2px;
`;

const ErrorIconComponent = { Container, LeftStick, RightStick };
export default ErrorIconComponent;
