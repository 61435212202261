import { ROLES } from '@/config/constants/keys';

const currentLayout = window.$environment.CURRENT_LAYOUT;
const baseUrl = currentLayout === 'SpireonNew' ? '/spireon' : '';

const routeConfigs = [
  {
    url: baseUrl + '/user/profile',
    component: 'Profile',
    roles: ['FLMANAGER', 'DRIVER', ROLES.VIEW_ONLY_FM],
    module: 'profile',
    title: 'Edit Profile'
  },
  {
    url: baseUrl + '/',
    component: 'InitialLoad',
    roles: ['FLMANAGER', 'ADMIN', 'DRIVER', 'MECHANIC', ROLES.VIEW_ONLY_FM],
    module: 'root',
    title: 'Dashboard'
  },
  {
    url: '/manager/login',
    component: 'InitialLoad',
    roles: ['FLMANAGER', 'ADMIN', 'DRIVER', 'MECHANIC'],
    module: 'root',
    title: 'Dashboard'
  },
  {
    url: baseUrl + '/downloadCenter/list',
    component: 'DownloadCenter',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'downloadCenterEnabled',
    title: 'Download Center'
  },
  {
    url: baseUrl + '/scheduling/list',
    component: 'ManageScheduling',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'manageScheduling',
    title: 'Manage Scheduling'
  },
  {
    url: '/logs/add',
    component: 'AddLogs',
    roles: ['FLMANAGER'],
    module: 'logsAdd',
    title: 'Logs'
  },
  {
    url: baseUrl + '/summary',
    component: 'Dashboard',
    roles: ['FLMANAGER', 'ADMIN', ROLES.VIEW_ONLY_FM],
    module: 'dashboard',
    title: 'Dashboard',
    subscriptions: 'wDashboard'
  },

  {
    url: baseUrl + '/logs/logbook/:id',
    component: 'LogBook',
    roles: ['FLMANAGER', 'DRIVER', ROLES.VIEW_ONLY_FM],
    module: 'logBook',
    title: 'Log Book'
  },
  {
    url: baseUrl + '/logs/history/:id',
    component: 'LogHistory',
    roles: ['FLMANAGER', ROLES.VIEW_ONLY_FM],
    module: 'logsHistory',
    title: 'Log History'
  },
  {
    url: baseUrl + '/logs/ud_list',
    component: 'UndefinedDriverList',
    roles: ['FLMANAGER', 'ADMIN', ROLES.VIEW_ONLY_FM],
    module: 'logsUdList',
    title: 'Logs'
  },
  {
    url: baseUrl + '/logs/edit/:id',
    component: 'EditDailyLog',
    roles: ['FLMANAGER', 'DRIVER'],
    module: 'logsEdit',
    title: 'Logs'
  },
  {
    url: baseUrl + '/logs/archive_list',
    component: 'ArchiveLogs',
    roles: ['FLMANAGER', 'ADMIN', 'DRIVER'],
    subscriptions: '',
    module: 'archiveLogsList',
    title: 'Logs'
  },
  {
    url: baseUrl + '/logs/view/:id',
    component: 'EditDailyLog',
    roles: ['FLMANAGER', 'ADMIN', ROLES.VIEW_ONLY_FM],
    module: 'logsView',
    title: 'Logs'
  },
  {
    url: baseUrl + '/logs/signed/view/:id',
    component: 'EditDailyLog',
    roles: ['FLMANAGER', 'ADMIN', ROLES.VIEW_ONLY_FM],
    module: 'signedLogsView',
    title: 'Logs'
  },
  {
    url: baseUrl + '/driver-status',
    component: 'DriverStatusViewMore',
    roles: ['FLMANAGER', 'ADMIN', ROLES.VIEW_ONLY_FM],
    module: 'driverStatusViewMore',
    title: 'Driver Status View More'
  },
  {
    url: baseUrl + '/driverStatus',
    component: 'DriverStatusViewMore',
    roles: ['FLMANAGER', 'ADMIN', ROLES.VIEW_ONLY_FM],
    module: 'driverStatusViewMore',
    title: 'Driver Status View More'
  },
  {
    url: baseUrl + '/driverStatus/more',
    component: 'DriverStatusViewMore',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'driverStatusViewMore',
    title: 'Driver Status View More'
  },
  {
    url: baseUrl +'/inspections/customdvir',
    component: 'CustomDvir',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'customDvirEnabled',
    title: 'Custom DVIR',
    dependency: ['customDvirEnabled', 'dvirOnly']

  },
  {
    url: baseUrl + '/inspections/customdvir/edit/:id',
    component: 'AddOrEditNewForm',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'AddOrEditNewFormTrailer',
    title: 'Edit Custom DVIR',
    dependency: ['customDvirEnabled', 'dvirOnly']
  },

  {
    url: baseUrl + '/inspections/customdvir/add',
    component: 'AddOrEditNewForm',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'AddOrEditNewForm',
    title: 'Add Custom DVIR',
    dependency: ['customDvirEnabled', 'dvirOnly']
  },
  {
    url: baseUrl +'/inspections/customdvir/addTrailer',
    component: 'AddOrEditNewFormTrailer',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'AddOrEditNewFormTrailer',
    title: 'Add Custom DVIR',
    dependency: ['customDvirEnabled', 'dvirOnly']
  },
  {
    url: baseUrl + '/inspections/customdvir/editTrailer/:id',
    component: 'AddOrEditNewFormTrailer',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'AddOrEditNewFormTrailer',
    title: 'Edit Custom DVIR',
    dependency: ['customDvirEnabled', 'dvirOnly']
  },
  {
    url: baseUrl + '/dailyDocuments/list',
    component: 'DailyDocuments',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'dailyDocuments',
    title: 'DailyDocuments',
    subscriptions: 'wDailyDocuments'
  },

  {
    url: baseUrl + '/logs',
    component: 'ManageLogs',
    roles: ['FLMANAGER', 'ADMIN', ROLES.VIEW_ONLY_FM],
    module: 'logsList',
    title: 'Logs',
    subscriptions: 'wLogs'
  },
  {
    url: baseUrl + '/logs/list',
    component: 'ManageLogs',
    roles: ['FLMANAGER', 'ADMIN', 'DRIVER', ROLES.VIEW_ONLY_FM],
    module: 'logsList',
    title: 'Logs',
    subscriptions: 'wLogs'
  },
  {
    url: baseUrl + '/logs/realTimeList',
    component: 'RealTimeLogs',
    roles: ['FLMANAGER', 'ADMIN', 'DRIVER', ROLES.VIEW_ONLY_FM],
    module: 'realTimeLogsList',
    title: 'Logs',
    subscriptions: 'wLogs'
  },
  {
    url: baseUrl + '/report/mileage',
    component: 'IFTAMileage',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'reportMileage',
    title: 'IFTA Mileage',
    subscriptions: 'wIFTAReport'
  },
  {
    url: '/report/ifta/list',
    component: 'IFTAList',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'iftaList',
    title: 'IFTA Mileage',
    dependency: ['taxableMilesViewEnabled']
  },
  {
    url: '/report/state/add',
    component: 'AddState',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'addState',
    title: 'Add State',
    dependency: ['taxableMilesViewEnabled']
  },
  {
    url: '/report/state/edit/:id',
    component: 'EditState',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'editState',
    title: 'Edit State',
    dependency: ['taxableMilesViewEnabled']
  },
  {
    url: baseUrl + '/report',
    component: 'Report',
    roles: ['FLMANAGER', 'ADMIN', ROLES.VIEW_ONLY_FM],
    module: 'reportsEnabled',
    title: 'Reports'
  },

  {
    url: baseUrl + '/logs/fmcsaLogs',
    component: 'DotAudit',
    roles: ['FLMANAGER', 'ADMIN', ROLES.VIEW_ONLY_FM],
    module: 'fmcsaLogs',
    title: 'DOT Audit'
  },
  {
    url: baseUrl + '/alerts',
    component: 'Alert',
    roles: ['FLMANAGER', 'ADMIN', ROLES.VIEW_ONLY_FM],
    module: 'alertViewMore',
    title: 'Alerts',
    subscriptions: 'wAlerts'
  },
  {
    url: baseUrl + '/alerts/list',
    component: 'Alert',
    roles: ['FLMANAGER', 'ADMIN', ROLES.VIEW_ONLY_FM],
    module: 'alertViewMore',
    title: 'Alerts',
    subscriptions: 'wAlerts'
  },
  {
    url: baseUrl + '/document/open/:id',
    component: 'Document',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'documentUrl',
    title: 'Document'
  },
  {
    url: baseUrl + '/user/add',
    component: 'AddUser',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'addUser',
    title: 'Add User'
  },
  {
    url: baseUrl + '/mechanic/add',
    component: 'AddUser',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'addMechanic',
    title: 'Add User'
  },
  {
    url: baseUrl + '/user/edit/:id',
    component: 'EditUser',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'editUser',
    title: 'User details',
    subscriptions: 'wUserDetails'
  },
  {
    url: baseUrl + '/mechanic/edit/:id',
    component: 'EditUser',
    roles: ['FLMANAGER', 'ADMIN', ROLES.VIEW_ONLY_FM],
    module: 'editMechanic',
    title: 'User details',
    subscriptions: 'wUserDetails'
  },
  {
    url: baseUrl + '/user/view/:id',
    component: 'EditUser',
    roles: ['FLMANAGER', 'ADMIN', ROLES.VIEW_ONLY_FM],
    module: 'viewUser',
    title: 'User details',
    subscriptions: 'wUserDetails'
  },
  {
    url: baseUrl + '/user/all',
    component: 'Users',
    roles: ['FLMANAGER', 'ADMIN', ROLES.VIEW_ONLY_FM],
    module: 'userList',
    title: 'Manage Users',
    subscriptions: 'wUsersList'
  },
  {
    url: baseUrl + '/mechanic/list',
    component: 'Users',
    roles: ['FLMANAGER', 'ADMIN', ROLES.VIEW_ONLY_FM],
    module: 'mechanicList',
    title: 'Manage Users',
    subscriptions: 'wUsersList'
  },
  {
    url: baseUrl + '/vehicle/list',
    component: 'Vehicles',
    roles: ['FLMANAGER', 'ADMIN', 'MECHANIC'],
    module: 'vehicleList',
    title: 'Manage Vehicles',
    subscriptions: 'wVehiclesList'
  },

  {
    url: baseUrl + '/trailer/add',
    component: 'AddTrailer',
    roles: ['FLMANAGER', 'ADMIN', 'MECHANIC'],
    module: 'addTrailer',
    title: 'Add Trailer'
  },
  {
    url: baseUrl + '/trailer/edit/:id',
    component: 'EditTrailer',
    roles: ['FLMANAGER', 'ADMIN', 'MECHANIC'],
    module: 'editTrailer',
    title: 'Edit Trailer'
  },
  {
    url: baseUrl + '/vehicle/add',
    component: 'AddVehicle',
    roles: ['FLMANAGER', 'ADMIN', 'MECHANIC'],
    module: 'addVehicle',
    title: 'Add vehicle'
  },
  {
    url: baseUrl + '/vehicle/edit/:id',
    component: 'EditVehicle',
    roles: ['FLMANAGER', 'ADMIN', 'MECHANIC'],
    module: 'editVehicle',
    title: 'Edit vehicle',
    subscriptions: 'wVehicleDetails'
  },
  {
    url: baseUrl + '/accidents/list',
    component: 'Accidents',
    roles: ['FLMANAGER', 'ADMIN', 'MECHANIC'],
    module: 'accidentsList',
    title: 'Accidents',
    subscriptions: 'wAccidents'
  },
  {
    url: baseUrl + '/violations',
    component: 'PotentialViolations',
    roles: ['FLMANAGER', 'ADMIN', ROLES.VIEW_ONLY_FM],
    module: 'potentialViolationsViewMore',
    title: 'Violations'
  },
  {
    url: baseUrl + '/violations/list',
    component: 'PotentialViolations',
    roles: ['FLMANAGER', 'ADMIN', ROLES.VIEW_ONLY_FM],
    module: 'potentialViolationsViewMore',
    title: 'Violations'
  },
  {
    url: baseUrl + '/vehicle/notInUse/INSHOP',
    component: 'NotInUseVehicles',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'vehicleNotInUse',
    title: 'Manage Vehicles'
  },
  {
    url: baseUrl + '/users/driverStatus/:id',
    component: 'DriverStatus',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'userDriverStatus',
    title: 'Manage Users'
  },
  {
    url: baseUrl + '/driverStatus/history',
    component: 'DriverStatusHistory',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'driverStatusHistory',
    title: 'History of Driver'
  },

  {
    url: baseUrl + '/company/document/edit',
    component: 'Documents',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'companyDocument',
    title: 'Company documents',
    subscriptions: 'wCompanyDocuments'
  },
  {
    url: baseUrl + '/driverStatus/list',
    component: 'DriverStatusViewMore',
    roles: ['FLMANAGER', 'ADMIN', ROLES.VIEW_ONLY_FM],
    module: 'driverStatusViewMore',
    title: 'Driver Status'
  },
  {
    url: baseUrl + '/vehicle/history',
    component: 'Vehicles',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'vehicleHistory',
    title: 'Manage Vehicles'
  },
  {
    url: baseUrl + '/alerts/requests',
    component: 'DeveloperRequests',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'alertsRequests',
    title: 'Developer Permission Requests'
  },
  {
    url: baseUrl + '/dashboard/geometris/hardDecceleration/detail',
    component: 'HardDeceleration',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'hardDeceleration',
    title: 'Vehicle Status',
    dependency: ['geometrisEnabled']
  },
  {
    url: baseUrl + '/vehicle/dtc',
    component: 'ViewDCA',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'viewDCA',
    title: 'Diagnostic Code Alerts',
  },
  {
    url: baseUrl + '/dashboard/geometris/hardAcceleration/detail',
    component: 'HardAcceleration',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'hardAcceleration',
    title: 'Vehicle Status',
    dependency: ['geometrisEnabled']
  },
  {
    url: baseUrl + '/dashboard/geometris/speeding/detail',
    component: 'Speeding',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'speeding',
    title: 'Vehicle Status',
    dependency: ['geometrisEnabled']
  },
  {
    url: baseUrl + '/dashboard/geometris/driveHrsExceeding/detail',
    component: 'DriveHrsExceeding',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'driveHrsExceeding',
    title: 'Vehicle Status',
    dependency: ['geometrisEnabled']
  },
  {
    url: baseUrl + '/dashboard/geometris/fuelEconomy/detail',
    component: 'FuelEconomy',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'fuelEconomy',
    title: 'Vehicle Status',
    dependency: ['geometrisEnabled']
  },
  {
    url: baseUrl + '/dashboard/geometris/seatBeltWarning/detail',
    component: 'SeatBeltWarning',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'seatBeltWarning',
    title: 'Vehicle Status',
    dependency: ['geometrisEnabled']
  },
  {
    url: baseUrl + '/dashboard/geometris/hardTurn/detail',
    component: 'HardTurn',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'hardTurn',
    title: 'Vehicle Status',
    dependency: ['geometrisEnabled']
  },
  {
    url: baseUrl + '/vehicle/status/list',
    component: 'VehicleStatusList',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'vehicleStatusViewMore',
    title: 'Vehicle Status',
    dependency: ['geometrisEnabled']
  },
  {
    url: baseUrl + '/department/list/:id',
    component: 'Department',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'departmentsList',
    title: 'Add Department'
  },
  {
    url: baseUrl + '/policy',
    component: 'Policies',
    roles: ['FLMANAGER', 'ADMIN', ROLES.VIEW_ONLY_FM],
    module: 'policyEdit',
    title: 'Policies and Procedures',
    subscriptions: 'wPoliciesAndProcedures'
  },
  {
    url: baseUrl + '/policy/edit',
    component: 'Policies',
    roles: ['FLMANAGER', 'ADMIN', ROLES.VIEW_ONLY_FM],
    module: 'policyEdit',
    title: 'Policies and Procedures',
    subscriptions: 'wPoliciesAndProcedures'
  },
  {
    url: baseUrl + '/csa/violation',
    component: 'CSAViolation',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'csaViolationFieldWaitingEnabled',
    title: 'CSA Violations'
  },

  {
    url: baseUrl + '/fuel/history/:id',
    component: 'RefillsHistory',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'refillsHistory',
    title: 'History of Refills'
  },
  {
    url: baseUrl + '/fuel/list',
    component: 'FuelConsumptionReport',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'fuelConsumptionReport',
    title: 'Fuel Track List',
    subscriptions: 'wFuelConsumptionReport'
  },
  {
    url: baseUrl + '/inspections/advanceConfig',
    component: 'AdvancedConfig',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'advancedDvirEnabled',
    title: 'Advance Config'
  },
  {
    url: baseUrl + '/company/edit',
    component: 'CompanyData',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'companyDataEdit',
    title: 'Company Data'
  },
  {
    url: baseUrl + '/usdot/management',
    component: 'UsDotNoManagement',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'usDotManagementenabled',
    title: 'USDOT No. Management Page'
  },
  {
    url: baseUrl + '/logs/edit/0/:id',
    component: 'EditDailyLog',
    roles: ['FLMANAGER'],
    module: 'editLog',
    title: 'Logs',
    dependency: ['udBulkEditEnabled']
  },
  {
    url: '/logs/edit/1/:id',
    component: 'EditDailyLog',
    roles: ['FLMANAGER'],
    module: 'editLog1',
    title: 'Logs',
    dependency: ['udBulkEditEnabled']
  },
  {
    url: baseUrl + '/inspections',
    component: 'Inspections',
    roles: ['FLMANAGER', 'ADMIN', ROLES.VIEW_ONLY_FM],
    subscriptions: 'wInspections',
    module: 'inspections',
    title: 'Inspections'
  },

  {
    url: baseUrl + '/inspections/list',
    component: 'Inspections',
    roles: ['FLMANAGER', 'ADMIN'],
    subscriptions: 'wInspections',
    module: 'inspections',
    title: 'Inspections'
  },
  {
    url: baseUrl + '/workorders/list',
    component: 'WorkOrders',
    roles: ['FLMANAGER', 'ADMIN', 'MECHANIC', ROLES.VIEW_ONLY_FM],
    subscriptions: 'wReportAndMaintenance',
    module: 'workOrders',
    title: 'Work Orders'
  },
  {
    url: baseUrl + '/payments/list',
    component: 'PaymentInformation',
    roles: ['FLMANAGER'],
    module: 'paymentInformation',
    title: 'Payment History'
  },
  {
    url: baseUrl + '/suspended',
    component: 'Suspended',
    roles: ['FLMANAGER'],
    module: 'suspended',
    title: 'Update Card'
  },
  {
    url: baseUrl + '/parts/list',
    component: 'Parts',
    roles: ['FLMANAGER', 'MECHANIC', 'ADMIN', ROLES.VIEW_ONLY_FM],
    module: 'partsInventoryEnabled',
    title: 'Parts'
  },
  {
    url: baseUrl + '/workorders/add',
    component: 'AddWorkOrders',
    roles: ['FLMANAGER', 'ADMIN', 'MECHANIC'],
    module: 'addWorkOrder',
    title: 'Work Order Details'
  },
  {
    url: baseUrl + '/workorders/edit/:id',
    component: 'EditWorkOrders',
    roles: ['FLMANAGER', 'MECHANIC'],
    module: 'editWorkOrder',
    title: 'Work Order Details'
  },
  {
    url: baseUrl + '/workorders/view/:id',
    component: 'EditWorkOrders',
    roles: ['FLMANAGER', ROLES.VIEW_ONLY_FM],
    module: 'viewWorkOrder',
    title: 'Work Order Details'
  },
  {
    url: baseUrl + '/vehicle/notInUse/OUTOFSERVICE',
    component: 'OutOfService',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'outOfService',
    title: 'Manage Vehicles'
  },
  {
    url: baseUrl + '/configuration/notificationEnable/:id',
    component: 'AlertMessage',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'alertMessage',
    title: 'Alerts'
  },
  {
    url: baseUrl + '/filter/list/:id',
    component: 'Department',
    roles: ['FLMANAGER', 'ADMIN', ROLES.VIEW_ONLY_FM],
    module: 'departmentsList',
    title: 'Add Filters'
  },
];

export default routeConfigs;
