import React, { useState, lazy } from 'react';
import PropTypes from 'prop-types';
import RenderComponent from './RenderComponent';
import { behaviourList } from '@/config/constants/keys';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));

const Filters = ({
  hasApiStatus,
  callback,
  filterList,
  handleReset,
  monthList,
  values, setInitialValues
}) => {

  const [validationError, setValidationError] = useState('');

  return (
    <>
      <Form
        type="server"
        direction="row"
        initialValues={{ ...values }}
        className="CSAForms"
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {
          setValidationError('');
          const finalData = { ...fields, month: fields.month?.value };
          Object.keys(finalData).forEach(
            (key) => !finalData[key] && delete finalData[key]
          );
          filterList(finalData);

        }}
        handleOnCancel={(fields) => {
          handleReset();
          setInitialValues({
            month: null
          });
          setValidationError('');
        }}
        customProps={{
          callback,
          hasApiStatus,
          monthList,
          validationError,
          setInitialValues
        }}
      />
    </>
  );
};

Filters.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired
};

export default Filters;
