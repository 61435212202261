import React, { useState } from 'react';
import { object } from 'yup';
import { Formik } from 'formik';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';

import { UI_DATE_FORMAT } from '../../../../config/constants/static';

const generateAndValidateForm = ({
  initialValues,
  validationSchema,
  ...rest
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema || object().shape({})}
      enableReinitialize
      onSubmit={() => {}}
    >
      {(props) => {
        return <Form {...props} {...rest} />;
      }}
    </Formik>
  );
};

export default generateAndValidateForm;

const Form = ({
  initialValues,
  errors,
  validateForm,
  values,
  handleOnSubmit,
  direction,
  setFieldValue,
  setValues,
  RenderComponent,
  className,
  customProps,
  handleOnCancel,
  Styled,
  ...rest
}) => {
  const [isNotValid, setIsNotvalid] = useState(true);
  const handleEdit = (event, source) => {
    customProps.hasApiStatus &&
      customProps.clearApiStatus &&
      customProps.clearApiStatus();
    let value = event;
    if (source?.type === 'date') {
      value = source.isTimeNeeded
        ? value
        : moment(event).format(UI_DATE_FORMAT);
    } else if (source?.type === 'file') {
      value = event[0];
    }

    if (source?.callback) {
      source.callback({ ...source, value });
    }
    setFieldValue(source?.field, value);
  };

  const getFieldValues = (source) => {
    const result = {};
    Object.keys(source).map((key) => {
      return (result[key] = source[key]);
    });
    return result;
  };

  return (
    <Styled.FormWrapper direction={direction} className={className}>
      <RenderComponent
        values={values}
        handleEdit={handleEdit}
        errors={errors}
        isNotValid={isNotValid}
        validateForm={validateForm}
        {...rest}
        onClear={() => {
          setValues({ ...initialValues });
        }}
        cancelAction={() => handleOnCancel()}
        customProps={customProps}
        submitAction={(data) => {
          if (Object.keys(data).length === 0) {
            setIsNotvalid(true);

            handleOnSubmit(getFieldValues(values));
          } else {
            setIsNotvalid(false);
          }
        }}
      />
    </Styled.FormWrapper>
  );
};
