import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
  ${({ theme }) => `
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 0fr auto;
  @media (max-width: ${theme.breakpoints.values.lg}px) {
    grid-template-rows: 0fr auto;
  }
  @media (max-width: ${theme.breakpoints.values.md}px) {
    grid-template-rows: 1fr 1fr auto auto;
    grid-template-columns: 1fr 1fr;
  }
  }
  .radius-table {
    grid-column-start: 1;
    grid-column-end: 6;
    background-color: ${theme.colors.HEADER_COLOR};
    padding: 30px;
    border-radius: 5px;
    tr {
      background-color: ${theme.colors.HEADER_COLOR} !important;
    }
    tr:hover {
      background-color: ${theme.colors.HEADER_COLOR} !important;
    }
    tr:nth-child(even) {
      background-color: ${theme.colors.PAGINATION_BACKGROUND} !important;
    }
  }
  grid-gap: 20px;
  padding: 2%;
  height: 100%;
  .grid-item {
    background-color: ${theme.colors.HEADER_COLOR};
    border-radius: 5px;
  }
  .quickStatsHeadingWrapper{
    display: flex;
    justify-content: space-between;
    .choose-period{
      min-width: 200px;
    }
  }
  .reportIconWrapper{
    display: flex;
    align-items: baseline;
    .statsHeading{
      white-space: nowrap;
    }
    img {
      margin-left: 10px;
      cursor: pointer;
      width: 30px;
    }

  }
  .count {
    display: flex;
    padding: 15px;
    .icon-wrapper {
      width: 45px;
      background-color: ${theme.colors.SECONDARY_MAIN};
      border-radius: 50%;
      height: 45px;
      position: relative;
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .summary-wrapper {
      display: flex;
      flex-direction: column;
      padding-left: 10px;
      flex: 0 0 80%;
      .summary-header {
        color: ${theme.colors.LIST_TEXT};
        font-weight: bold;
        text-transform: uppercase;
      }
      .summary-content {
        font-size: 15px;
        color: ${theme.colors.HEADER_TEXT};
      }
    }
  }
  .left {
    grid-column-start: 1;
    grid-column-end: 5;
    background-color: ${theme.colors.PRIMARY_MAIN};
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    @media (max-width: ${theme.breakpoints.values.md}px) {
      grid-column-end: 3;
      grid-template-columns: auto;
    }
    .grid-item {
      background-color: ${theme.colors.HEADER_COLOR};
      padding: 4%;
      #myDiv{
        margin-top: 95px;
      }
    }
    .teritory-details{
      display: grid;
      grid-template-columns: 2fr 7fr 1fr;
      grid-row-gap: 25%;
      grid-column-gap: 1%;
      padding-top: 5%;
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        grid-template-columns: 2fr 4fr 1fr;
        grid-row-gap: 10%;
      }
      @media (max-width: ${theme.breakpoints.values.md}px) {
        grid-template-columns: 2fr 7fr 1fr;
      }
    span{
      text-align: right;
      font-size: 16px;
      color: ${theme.colors.HEADER_TEXT};
    }
    div + span{
      text-align: left;
    }
    }
    .quick-stats-wrapper{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 30px;
      padding-top: 5%;
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        grid-gap: 20px 10px;
      }
      .quick-stats-item{
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        span{
          text-align: center;
          font-size: 16px;
          color: ${theme.colors.HEADER_TEXT};
          padding-top: 5%;
        }
      }
  }
}
    .statsHeading {
      font-weight: 600;
      text-transform: capitalize;
      font-size: 20px !important;
      padding: 0 !important;
    }
  }
  .right{
    padding: 6%;
    @media (max-width: ${theme.breakpoints.values.md}px) {
      grid-column-start: 1;
      grid-column-end: 3;
    }
    .fleet-details{
      display: grid;
      grid-template-columns: 1.7fr 1.7fr 1.5fr;
      color: ${theme.colors.HEADER_TEXT};
      font-size: 14px;
      padding-top: 5%;
      grid-row-gap: 42px;
      &-value{
        font-weight: bold;
      }
    }
  }
  `}
`;

const TableWrapper = styled(Box)`
  ${({ theme, type }) => `

  padding-top: 2%;

  .CSAForms {
    justify-content: flex-start;
    .MuiFormLabel-root{
      padding-bottom: 0px !important;
    }
    .vertical-button {
      .MuiBox-root:first-child {
        margin-right: 14px;
      }
      .MuiButtonBase-root {
        margin-top: 24px;
        padding-left: 0;
        padding-right: 0;
        .MuiButton-label {
          padding: 2px 22px;
        }
      }
    }
  }
  `}
`;

const Styled = {
  Wrapper,
  TableWrapper
};
export default Styled;
