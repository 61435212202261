const initialState = [];

export const timezone = {
  state: [...initialState],
  reducers: {
    setFetchSuccess(state, payload) {
      return [...payload];
    },
    setFetchFailed() {
      return [...initialState];
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload) {
      try {
        const getApi = await import(
          `../../../../config/apis/${window.$environment.SERVICE_PROVIDER}`
        );
        const getTimezoneApi = getApi.default('getTimezoneApi');
        if (getTimezoneApi) {
          const response = await dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              api: getTimezoneApi
            },
            id: payload.id
          });
          response && this.setFetchSuccess(response);
        }
      } catch (error) {
        this.setFetchFailed();
      }
    }
  })
};
