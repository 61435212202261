import { logsHistoryApi } from '../../../../config/apis';
import { pageUrls } from '@/config/constants/keys';

const initialState = { dailyLogs: [], day: '', driverName: '' };

export const logsHistory = {
  state: { ...initialState },
  reducers: {
    listSuccess(state, payload) {
      return { ...payload };
    },
    listFailed() {
      return { ...initialState };
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...logsHistoryApi,
              url: logsHistoryApi.url + payload.data.id
            }
          },
          id: payload.apiId,
          callback: (type, message) => {
            payload.callback(
              type,
              message,
              () => payload.history && payload.history.push(pageUrls.logsList)
            );
          }
        });

        this.listSuccess(response);
      } catch (error) {
        this.listFailed();
      }
    }
  })
};
