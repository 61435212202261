import React, { lazy, useState, useEffect } from 'react';
import ParentStyled from './style';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import underscore from 'underscore';
import {
  loadStyle
} from '@/utils/utils';
const layout = window?.$environment?.CURRENT_LAYOUT;

const LineChart = lazy(() =>
  import(`@/components/UI/Graph/LineGraphCSA/${layout}`)
);
const BarChart = lazy(() =>
  import(`@/components/UI/Graph/BarGraphCSA/${layout}`)
);
let Styled = loadStyle(ParentStyled, 'pages/web/Dashboard/CSASnapshot');

const Component = ({ csasnapshot, ...rest }) => {
  let data = csasnapshot.basicMeasures.series;
  let driverDataFinal = csasnapshot.driverDetails;
  const theme = useTheme();
  const i18n = useTranslation();
  const formatDataForCSA = (data, type) => {
    if (data) {
      var datalist = underscore.find(
        csasnapshot.basicMeasures.series,
        function (v) {
          return v.type === type;
        }
      );
      return datalist && datalist.values;
    } else return [];
  };

  const avgForCSA = (data, type) => {
    if (data) {
      var datalist = underscore.find(
        csasnapshot.basicMeasures.series,
        function (v) {
          return v.type === type;
        }
      );
      return datalist && datalist.average;
    } else return '';
  };

  const labelFinder = (label, data, filter) => {
    let avg = avgForCSA(data, filter);

    if (avg) return `${label}(Avg: ${avg})`;
    else return label;
  };
  let datasets1 = [
    {
      data: formatDataForCSA(data, 'HOS'),
      label: labelFinder('HOS', data, 'HOS'),
      backgroundColor: 'rgba(50, 125, 255, 0.2)',
      borderColor: '#224f9c',
      average: avgForCSA(data, 'HOS')
      // label: `HOS`
    },
    {
      data: formatDataForCSA(data, 'Vehicle Maintenance'),
      label: labelFinder('VM', data, 'Vehicle Maintenance'),
      backgroundColor: 'rgba(245, 166, 35, 0.2)',
      borderColor: '#f5a623',
      average: avgForCSA(data, 'Vehicle Maintenance')
      // label: `VM`
    },
    {
      data: formatDataForCSA(data, 'HM'),
      label: labelFinder('HM', data, 'HM'),
      backgroundColor: 'rgba(229, 51, 145, 0.2)',
      borderColor: '#a32668',
      average: avgForCSA(data, 'HM')
      // label: `HM`
    }
  ];

  let datasets2 = [
    {
      data: formatDataForCSA(data, 'Unsafe Driving'),
      label: labelFinder('UD', data, 'Unsafe Driving'),
      borderColor: '#ec2d2d',
      backgroundColor: 'rgba(236, 45, 45, 0.2)',
      average: avgForCSA(data, 'Unsafe Driving')
    },
    {
      data: formatDataForCSA(data, 'Driver Fitness'),
      label: labelFinder('DF', data, 'Driver Fitness'),
      borderColor: '#f5a623',
      backgroundColor: 'rgba(245, 166, 35, 0.2)',
      average: avgForCSA(data, 'Driver Fitness')
    },
    {
      data: formatDataForCSA(data, 'cs'),
      label: labelFinder('CS', data, 'cs'),
      borderColor: '#224f9c',
      backgroundColor: 'rgba(114, 111, 246, 0.2)',
      average: avgForCSA(data, 'cs')
    },
    {
      data: formatDataForCSA(data, 'Crash '),
      label: labelFinder('Crash', data, 'Crash '),
      borderColor: '#158f43',
      backgroundColor: 'rgba(53, 195, 106, 0.2)',
      average: avgForCSA(data, 'Crash ')
    }
  ];
  const formatDataForCrash = (data) => {
    let tempData = {
      labels: [],
      dot: [],
      tow: [],
      injuries: [],
      fatalities: [],
      citations: []
    };

    if (data) {
      data.forEach(function (item) {
        tempData['labels'].push(item.monthsBefore + 'months');
        tempData['dot'].push(item.federalRecordable);
        tempData['tow'].push(item.towAway);
        tempData['injuries'].push(item.injuries);
        tempData['fatalities'].push(item.fatalities);
        tempData['citations'].push(item.citationsIssued);
      });
    }
    return tempData;
  };
  let crashSummaryChart = formatDataForCrash(csasnapshot?.crashSummmary);
  let datasets3 = [
    {
      label: 'DOT Recordable',
      backgroundColor: '#1812b9',
      data: crashSummaryChart.dot
    },
    {
      label: 'Tow Away',
      backgroundColor: '#a32668',
      data: crashSummaryChart.tow
    },
    {
      label: 'Injuries',
      backgroundColor: '#158f43',
      data: crashSummaryChart.injuries
    },
    {
      label: 'Fatalities',
      backgroundColor: '#f54523',
      data: crashSummaryChart.fatalities
    },
    {
      label: 'With Citations',
      backgroundColor: '#f5a623',
      data: crashSummaryChart.citations
    }
  ];
  const formatDate = (input) => {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + '/' + month + '/' + year;
  };

  let totalWithin =
    driverDataFinal?.Total_Drivers_Interstate_Less_100_Miles +
    driverDataFinal?.Total_Drivers_Intrastate_Less_100_Miles;
  let totalBeyond =
    driverDataFinal?.Total_Drivers_Interstate_More_100_Miles +
    driverDataFinal?.Total_Drivers_Intrastate_More_100_Miles;
  let totalDriver =
    driverDataFinal?.Total_Drivers_Interstate +
    driverDataFinal?.Total_Drivers_Intrastate;
  let driverDate = driverDataFinal?.MCS150_Date;
  let dateTostring = driverDate?.toString();
  var dateFormat =
    dateTostring &&
    [
      dateTostring.slice(0, 4),
      '/',
      dateTostring.slice(4, 6),
      '/',
      dateTostring.slice(6, 8)
    ].join('');
  var finalDate = dateFormat && formatDate(dateFormat);
  return (
    <Styled.Wrapper>
      <div className="basicMeasures dashboard_csa_wrapper">
        <div className="panelHeading">Basic Measures</div>
        <div className="panel-content measures">
          <div className="chartContainer">
            {data && data.length !== 0 && (
              <LineChart
                borderColor={'white'}
                width={'450'}
                height={'350'}
                labels={csasnapshot?.basicMeasures?.Dates}
                areagraph
                theme={theme}
                data={datasets1}
                maindata={data}
                id={'legend11'}
              ></LineChart>
            )}
            {data && data.length === 0 && (
              <LineChart
                borderColor={'white'}
                width={'450'}
                height={'350'}
                labels={csasnapshot?.basicMeasures?.Dates}
                areagraph
                theme={theme}
                data={datasets1}
                maindata={data}
                id={'legend11'}
              ></LineChart>
            )}
          </div>
          <div className="chartContainer">
            {data && data.length !== 0 && (
              <LineChart
                borderColor={'white'}
                width={'450px'}
                height={'350px'}
                labels={csasnapshot?.basicMeasures?.Dates}
                data={datasets2}
                areagraph
                theme={theme}
                maindata={data}
                id={'legend21'}
              ></LineChart>
            )}
            {data && data.length === 0 && (
              <LineChart
                borderColor={'white'}
                width={'450px'}
                height={'350px'}
                labels={csasnapshot?.basicMeasures?.Dates}
                data={datasets2}
                areagraph
                theme={theme}
                maindata={data}
                id={'legend21'}
              ></LineChart>
            )}
          </div>
        </div>
      </div>
      <div className="middleWrapper crashSummary">
        <div className="section">
          <div className="panelHeading">
            Crash Summary
            <span className="sub-heading basicheading-description">
              (based on Inspection results for the past 36 months)
            </span>
          </div>

          <div className="chartContainerMiddle">
            <BarChart
              borderColor={'white'}
              width={'450px'}
              height={'350px'}
              labels={crashSummaryChart.labels}
              data={datasets3}
              theme={theme}
            ></BarChart>
          </div>
        </div>
        <div className="section">
          <div className="panelHeading"> Driver Details
          <span className="sub-heading basicheading-description">
          {finalDate && `(based on MCS-150 dated ${ finalDate})` }
            </span>

          </div>
          <div className="chartContainerMiddle TableWrapper">
            {driverDataFinal && Object.keys(driverDataFinal).length === 0 && (
              <label>No data available</label>
            )}

            {driverDataFinal && Object.keys(driverDataFinal).length !== 0 && (
              <table
                id="csadriver_details"
                className="display table"
                cellSpacing="0"
                width="100%"
              >
                <thead>
                  <tr>
                    <th></th>
                    <th>Within 100 mi</th>
                    <th>Beyond 100 mi</th>
                    <th>Total</th>
                  </tr>
                </thead>

                <tbody>
                  <tr className="driver-interstate">
                    <td># Drivers Interstate</td>
                    <td align="center">
                      {driverDataFinal.Total_Drivers_Interstate_Less_100_Miles}
                    </td>
                    <td align="center">
                      {driverDataFinal.Total_Drivers_Interstate_More_100_Miles}
                    </td>
                    <td align="center">
                      {driverDataFinal.Total_Drivers_Interstate}
                    </td>
                  </tr>
                  <tr className="driver-intrastate">
                    <td># Drivers Intrastate</td>
                    <td align="center">
                      {driverDataFinal.Total_Drivers_Intrastate_Less_100_Miles}
                    </td>
                    <td align="center">
                      {driverDataFinal.Total_Drivers_Intrastate_More_100_Miles}
                    </td>
                    <td align="center">
                      {driverDataFinal.Total_Drivers_Intrastate}
                    </td>
                  </tr>
                  <tr className="driver-total">
                    <td>TOTAL</td>

                    <td align="center">{totalWithin}</td>
                    <td align="center">{totalBeyond}</td>
                    <td align="center">{totalDriver}</td>
                  </tr>
                  <tr className="trip-lease">
                    <td>
                      # CDL Drivers:{' '}
                      {driverDataFinal.Total_Drivers_Commercialy_Licensed}
                    </td>
                    <td align="center">
                      # Drivers Total: {driverDataFinal.Total_Drivers}
                    </td>
                    <td colspan="2" align="center">
                      Avg. # Trip Lease Drivers/Mo:{' '}
                      {driverDataFinal.Average_Trip_Leased_Drivers_Per_Month}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      <div className="bottomWrapper dashboard_csa_wrapper inspections">
        <div className="panelHeading">
          Inspections Summary
          <span className="sub-heading inspection-description">
            (based on 24 months prior to:{' '}
            {new Date().getDate() -
              1 +
              '/' +
              (new Date().getMonth() + 1 < 10
                ? '0' + (new Date().getMonth() + 1)
                : new Date().getMonth() + 1) +
              '/' +
              new Date().getFullYear()}
            )
          </span>
        </div>
        <div className="panel-content bottomBoxWrapper inspection-summary">
          {csasnapshot?.inspectionSummary.map((item, index) => {
            return (
              <div className="inspection">
                <div className="overall-inspection">{item.type}</div>
                <div className="inspection-content">
                  <p>Insp: {item.inspections}</p>
                  <p>OOS: {item.oos}</p>
                  <br />
                  <br />
                  <p>% OOS: {item.oosPercent.toFixed(2)}%</p>
                  <p>Nat'l Avg: {item.nationalAverage.toFixed(2)}%</p>
                  <p>Diff: {item.difference.toFixed(2)}%</p>
                </div>
              </div>
            );
          })}
          {csasnapshot?.inspectionSummary.length === 0 && (
            <label>{i18n.t('common.noDataToPlot')}</label>
          )}
        </div>
      </div>
    </Styled.Wrapper>
  );
};

export default Component;
