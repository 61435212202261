import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
  ${({ theme }) => `
  padding: 3%;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
  justify-content: space-between;
  @media (max-width: ${theme.breakpoints.values.lg}px) {
    flex-direction: column;
  }
  .vehicleWrap {
    padding: 0;
  }
  form {
    width: 100%;
    .MuiTypography-body1 {
      font-size: 18px;
    }
  }
  ul {
    border: 2px solid ${theme.colors.LIGHT_BORDER};
    padding-left: 0;
    li {
      border-bottom: 0.5px solid ${theme.colors.PRIMARY_MAIN};
    }
  }
  .spireonNewonly {
    display: none;
  }
  .vehicleidMechanic .select__menu {
    height: 110px;
    overflow: auto;
    .select__menu-list {
      height: 210px;
      max-height: 100%;
    }
  }
  .subTitle {
    margin: 0 0 16px 0;
  }
  .userForm {
    input:disabled {
      color: #c0bbb7;
    }
  }
  `}
`;

const LeftWrapper = styled(Box)`
  ${({ theme }) => `
  flex: 0 0 48.5%;
  .userHeading {
    margin-top: 0;
  }

  .subheaderSection {
    padding: 11px 4%;
  }
  .normalCheckbox {
    padding-right: 9px;
    .Checkbox {
      top: 3px;
    }
    span {
      font-size: 18px;
      padding-left: 12px;
    }
  }
  .driverCheckbox {
    padding-left: 25%;
    width: 100%;
    .normalCheckbox {
      margin: 15px 2% 15px 0;
      width: 100%;
      span {
        width: 100%;
      }
    }
  }
  @media (max-width: ${theme.breakpoints.values.lg}px) {
    flex: 0 0 100%;
  }
  `}
`;
const RightWrapper = styled(Box)`
  ${({ theme }) => `
  flex: 0 0 48.5%;
  .documentsHeader {
    @media (min-width: ${theme.breakpoints.values.lg}px) {
      margin-top: 0;
    }
  }
  .subheaderSection {
    padding: 11px 4%;
  }
  @media (max-width: ${theme.breakpoints.values.md}px) {
    flex: 0 0 100%;
  }
  `}
`;

const SubmitUser = styled(Box)`
  .MuiBox-root {
    flex: 0 0 8%;
  }
  .MuiBox-root .MuiBox-root:first-child {
    margin-right: 2%;
  }
`;

const Styled = {
  Wrapper,
  LeftWrapper,
  RightWrapper,
  SubmitUser
};
export default Styled;
