import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)``;
const SearchWrapper = styled(Parent.SearchWrapper)``;
const TopWrapper = styled(Parent.TopWrapper)``;
const TableWrapper = styled(Parent.TableWrapper)`
${({ theme }) => `
    padding: 0;
    .tableWrapper {
        padding-top: 1%;
        padding: 0 2%;
        border-bottom: 2px solid #000;
        padding-bottom: 40px;
    }
    .quickFilter {
        background: ${theme.colors.LINK_COLOR} none repeat scroll 0 0;
        color: #f4f6f9;
        padding: 10px 3%;
    }
`}
`;
const LabelWrap = styled(Parent.LabelWrap)``; 

const Styled = {
    Wrapper,
    SearchWrapper,
    TopWrapper,
    TableWrapper,
    LabelWrap
};
export default Styled;
