import React from 'react';

import PrivacyWrapper from './style';
import { Helmet } from 'react-helmet';

const Index = ({ pageTitle }) => {
  const baseUrl = `${process.env.CDN_URL || ''}/clutch`;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="manifest" href={`${baseUrl}/manifest.json`} />
        <link rel="icon" type="image/x-icon" href={`${baseUrl}/favicon.ico`} />
      </Helmet>
      <PrivacyWrapper>
        <h1> GORILLA SAFETY TERMS OF SERVICE </h1>
        <div className="contentWrapper">
          <p>
            PLEASE READ THE TERMS AND CONDITIONS CAREFULLY. BY CLICKING “ACCEPT”
            YOU AGREE TO BE BOUND BY THESE TERMS. IF YOU DO NOT AGREE TO BE
            BOUND BY THESE TERMS DO NOT CLICK “ACCEPT”. AS USED HEREIN THE TERM
            “USER”, “YOU” AND “YOUR” MEANS THE NATURAL PERSON OR ENTITY THAT IS
            AGREEING TO BE BOUND BY THIS AGREEMENT, YOUR EMPLOYEES AND ANY THIRD
            PARTY AGENTS THAT PROVIDE SERVICES TO YOU. YOU SHALL BE LIABILE FOR
            ANY FAILURE BY SUCH EMPLOYEES OR THIRD PARTY AGENTS TO COMPLY WITH
            THESE TERMS. THESE TERMS AND CONDITIONS ARE SUBJECT TO CHANGE AT
            ANYTIME.
          </p>

          <p>
            Gorillasafety.com (“Site”) and our mobile device application ("App")
            and the services provided by Gorilla Fleet Safety LLC (“Gorilla
            Safety”) in conjunction with the Site and App are collectively
            called the "Services”.
          </p>

          <b>Grant of License and Rights to Use</b>

          <p>
            Gorillas Safety grants you a limited, non-transferable, revocable,
            non-exclusive, non-sublicensable license to access and use the
            Services. You may not use the Services in any way that is against
            the law. You expressly agree that you shall not use the Services for
            creating a product, service or software that is directly or
            indirectly competitive with the Services provided by Gorilla Safety.
            Gorilla Safety retains all right, title and interest in and to the
            Services, as well as any and all related copyrights, patents,
            trademarks, trade secrets and other intellectual property and
            proprietary rights.
          </p>

          <b>Registration and Your Information</b>

          <p>
            To access and use the Services you will be required to create an
            account ("Account").
          </p>

          <p>
            It's important that you provide us with accurate, complete and
            up-to-date information for your Account and you agree to update such
            information to keep it accurate, complete and up-to- date. You are
            solely maintaining the confidentiality and security of your Account.
            You are responsible for all activities that occur under your
            Account, and you agree to immediately notify us of any unauthorized
            use of your Account or any other br/each of security. Gorilla Safety
            is not responsible for any losses arising out of unauthorized use of
            your Account.
          </p>

          <b>The Services</b>

          <p>
            The Services enable Users to connect with each other (to "Connect"
            or establish a "Connection"), allowing Users to seamlessly share
            information. A User may upload information and the Services will
            collect data including current, past and future locations and
            activities of the User and/or equipment. Data may be uploaded and
            stored and will be accessible for future needs as long as the User
            has an Account in good standing with Gorilla Safety.
          </p>

          <b>Maintenance and Ownership of Equipment and Software</b>

          <p>
            “Gorilla Equipment” means any equipment provided by Gorilla
            including the Vehicle Network Adapter (“VNA2-ELD”) and vehicle
            cable. Gorilla Equipment also includes any software, firmware, or
            other programs contained within the VNA2-ELD. You agree that all
            Gorilla Equipment belongs to us. You agree to use Gorilla Equipment
            only for the Services pursuant to this Agreement. You may not sell,
            lease, abandon, or give away the Gorilla Equipment. We will repair
            and maintain the Gorilla Equipment at our expense, unless such
            repair or maintenance is made necessary due to misuse, abuse or
            intentional damage to the Gorilla Equipment, in which case you will
            be financially responsible for the repair or replacement of the
            damaged Gorilla Equipment. You also agree that the Gorilla Equipment
            will not be serviced by anyone other than our employees or our
            designated agents or representatives. You further agree not to
            tamper with or otherwise harm the Gorilla Equipment, and that you
            will not copy, modify, reverse compile or reverse engineer any
            Gorilla Equipment, software or firmware provided by us in connection
            with the Services. You agree that upon termination of this agreement
            you will do the following: (i) you will immediately cease all use of
            the Services and all Gorilla Equipment; (ii) you will pay in full
            for your use of the Services up to the date that this Agreement has
            been terminated and the Services are disconnected; and (iii) within
            ten (10) days of the date on which Services are disconnected, you
            will return all Gorilla Equipment in working order, normal wear and
            tear excepted. Otherwise, you will be charged $114.95 for each
            VNA2-ELD unit and $14.95 for each vehicle cable that were provided
            to you as part of the Services.
          </p>

          <b>Third Parties</b>

          <p>
            You understand that by using the Services, you may encounter data,
            information, applications, materials and other content from third
            parties, including carriers and other Users. Gorilla Safety is not a
            party to any relationships or agreements between you and any third
            parties for the performance of any duties between the parties, and
            does not have control over and disclaims all liability for the
            quality, timing, legality, failure to provide, or any other aspect
            whatsoever of any duties performed by any other Users or third
            parties. In the event that you have a dispute with one or more other
            Users or other third parties, you release Gorilla Safety from any
            and all claims, demands, or damages of every kind and nature, known
            and unknown, suspected and unsuspected, disclosed and undisclosed,
            arising out of or in any way connected with such dispute.
          </p>

          <b>Payment Terms</b>

          <p>
            In consideration of the license granted herein and the Services
            provided by Gorilla Safety, User agrees to pay Gorilla Safety as
            stipulated in the terms of the applicable Sales Agreement between
            User and Gorilla Safety.
          </p>

          <b>Feedback</b>

          <p>
            We welcome feedback, comments and suggestions for improvements to
            the Services ("Feedback"). You can submit Feedback by emailing us at
            feedback@gorillasafety.com. Feedback is not confidential and Gorilla
            Safety may use or not use the Feedback in any manner that it desires
            at Gorilla Safety’s sole discretion with no obligation to you. By
            submitting Feedback to Gorilla Safety you agree to an assignment of
            all rights, title and interest to the Feedback, including all
            intellectual property rights. If you believe the Feedback includes
            novel material you may want to consider filing a patent application
            prior to submitting the Feedback to us.
          </p>

          <b>Privacy Policy</b>

          <p>
            Please refer to our
            <a
              href="http://www.gorillasafety.com/privacypolicy"
              target="_blank"
            >
              Privacy Policy
            </a>
            for information on how we collect, use and disclose information from
            our Users.
          </p>

          <b>User Content Rights</b>

          <p>
            Gorilla Safety may enable certain Services to allow you to upload
            certain content ("User Content") created by you for the purpose of
            sharing the User Content with other Users of the Services.
          </p>

          <p>
            All User Content is your sole responsibility. You are responsible
            for protecting any intellectual property rights in User Content, and
            Gorilla Safety shall not have any responsibility for protecting
            them. Notwithstanding the foregoing, you hereby grant Gorilla Safety
            a worldwide, non-exclusive, perpetual, royalty-free license to the
            User Content to reproduce, adapt, distribute and publish such User
            Content delivered to or generated using the Service that: i)
            pertains to the technical and operational functionality of the
            Services; ii) is necessary or useful in assisting Gorilla Safety in
            the evaluation of the Services or any improvements, upgrades or
            enhancements thereto, or the compilation of statistical or
            performance information; or iii) is accumulated by Gorilla Safety on
            an aggregated basis. Gorilla Safety may review and remove any User
            Content that it feels violates this Agreement, applicable laws,
            rules or regulations, though Gorilla Safety won’t be obligated to do
            so.
          </p>

          <p>
            You hereby warrant that any and all of your User Content shall not
            infringe on the intellectual property or other rights of any other
            person or entity and shall comply with all intellectual property and
            similar laws. You further warrant that any such User Content shall
            not contain any elements which may reasonably be considered
            offensive or immoral, or which would violate applicable laws, rules
            or regulations. You further warrant that you shall not upload any
            User Content which contains any elements which may be dangerous or
            harmful to Gorilla Safety other users, or any of such parties’
            hardware, servers or software.
          </p>

          <p>
            <b>RIGHTS AND TERMS FOR APP</b>
          </p>

          <b>General Prohibitions</b>
          <p>
            IT IS STRICTLY FORBIDDEN TO USE THE GORILLA SAFETY APP WHILE
            DRIVING.
          </p>

          <p>You further agree not to do any of the following:</p>

          <ol start="1">
            <li>
              Post, upload, publish, submit or transmit any User Content that:
              (in) infringes, misappropriates or violates a third party's
              patent, copyright, trademark, trade secret, moral rights or other
              intellectual property rights, or rights of publicity or privacy;
              (ii) violates, or encourages any conduct that would violate, any
              applicable law or regulation or would give rise to civil
              liability; (iii) is fraudulent, false, misleading or deceptive;
              (iv) is defamatory, obscene, pornographic, vulgar or offensive;
              (v) promotes discrimination, bigotry, racism, hatred, harassment
              or harm against any individual or group; (vi) is violent or
              threatening or promotes violence or actions that are threatening
              to any person or entity; or (vii) promotes illegal or harmful
              activities or substances.
            </li>
            <li>
              Use, display, mirror or frame the Services, or any individual
              element within the Services, Gorilla Safety's name, any Gorilla
              Safety trademark, logo or other proprietary information, or the
              layout and design of any page or form contained on a page, without
              Gorilla Safety's express written consent;
            </li>
            <li>
              Access, tamper with, or use non-public areas of the Services,
              Gorilla Safety's computer systems, or the technical delivery
              systems of Gorilla Safety's providers;
            </li>
            <li>
              Attempt to probe, scan, or test the vulnerability of any Gorilla
              Safety system or network or br/each any security or authentication
              measures;
            </li>
            <li>
              Avoid, bypass, remove, deactivate, impair, descramble or otherwise
              circumvent any technological measure implemented by Gorilla Safety
              or any of Gorilla Safety's providers or any other third party
              (including another user) to protect the Services or User Content;
            </li>
            <li>
              Attempt to access or search the Services or User Content or
              download User Content from the Services through the use of any
              engine, software, tool, agent, device or mechanism (including
              spiders, robots, crawlers, data mining tools or the like) other
              than the software and/or search agents provided by Gorilla Safety
              or other generally available third party web br/owsers;
            </li>
            <li>
              Send any unsolicited or unauthorized advertising, promotional
              materials, email, junk mail, spam, chain letters or other form of
              solicitation;
            </li>
            <li>
              Use any meta tags or other hidden text or metadata utilizing a
              Gorilla Safety trademark, logo, URL or product name without
              Gorilla Safety's express written consent;
            </li>
            <li>
              Use the Services or User Content for the benefit of any third
              party or in any manner not permitted by these Terms;
            </li>
            <li>
              Forge any TCP/IP packet header or any part of the header
              information in any email or newsgroup posting, or in any way use
              the Services or User Content to send altered, deceptive or false
              source-identifying information;
            </li>
            <li>
              Attempt to decipher, decompile, disassemble or reverse engineer
              any of the software used to provide the Services or User Content;
            </li>
            <li>
              Interfere with, or attempt to interfere with, the access of any
              user, host or network, including, without limitation, sending a
              virus, overloading, flooding, spamming, or mail-bombing the
              Services;
            </li>
            <li>
              Collect or store any personally identifiable information from the
              Services from other users of the Services without their express
              permission;
            </li>
            <li>
              Impersonate or misrepresent your affiliation with any person or
              entity;
            </li>
            <li>Violate any applicable law or regulation; or</li>
            <li>
              Encourage or enable any other individual to do any of the
              foregoing.
            </li>
          </ol>

          <p>
            Although we're not obligated to monitor access to or use of the
            Services or User Content or to review or edit any User Content, we
            have the right to do so for the purpose of operating the Services,
            to ensure compliance with these Terms, or to comply with applicable
            law or other legal requirements. We reserve the right, but are not
            obligated, to remove or disable access to any User Content, at any
            time and without notice, including, but not limited to, if we, at
            our sole discretion, consider any User Content to be objectionable
            or in violation of these Terms. We have the right to investigate
            violations of these Terms or conduct that affects the Services. We
            may also consult and cooperate with law enforcement authorities to
            prosecute users who violate the law.
          </p>

          <b>Termination</b>

          <p>
            Gorilla Safety may terminate your access to and use of the Services,
            at our sole discretion, at any time and without notice to you. You
            may cancel your Account at any time by sending an email to us at
            support@gorillasafety.com. Upon any termination, discontinuation or
            cancellation of Services or your Account, the following provisions
            will survive: Third Parties; User Content Rights; No Warranty;
            Indemnity; Limitation of Liability; Choice of Law; Arbitration; and
            General Terms. At the time of cancellation you will be responsible
            for any obligations agreed to under the applicable Sales Agreement,
            to the extent they are validly existing.
          </p>

          <b>No Warranty</b>

          <p>
            THE SERVICES AND USER CONTENT ARE PROVIDED "AS IS" WITHOUT WARRANTY
            OF ANY KIND. WITHOUT LIMITING THE FOREGOING, GORILLA SAFETY
            EXPLICITLY DISCLAIMS ANY IMPLIED WARRANTIES OF MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT AND
            NON-INFRINGEMENT AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING
            OR USAGE OF TRADE. GORILLA SAFETY EXPLICITLY DISCLAIMS ALL LIABILITY
            FOR ANY ACT OR OMISSION OF ANY USER OR OTHER THIRD PARTY. WITHOUT
            LIMITING THE FOREGOING, GORILLA SAFETY MAKES NO WARRANTY THAT (I)
            THE SERVICES WILL MEET YOUR REQUIREMENTS OR WILL BE UNINTERRUPTED,
            TIMELY, SECURE OR ERROR-FREE; (II) THE RESULTS THAT MAY BE OBTAINED
            FROM THE USE OF THE SERVICES WILL BE EFFECTIVE, ACCURATE OR
            RELIABLE; (III) THE QUALITY OF THE SERVICES WILL MEET YOUR
            EXPECTATIONS; OR THAT (IV) ANY ERRORS OR DEFECTS IN THE SERVICES
            WILL BE CORRECTED. NO ADVICE OR INFORMATION, WHETHER ORAL OR
            WRITTEN, OBTAINED BY YOU FROM GORILLA SAFETY OR THROUGH OR FROM USE
            OF THE SERVICES SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN
            THE TERMS.
          </p>

          <p>
            GORILLA SAFETY IS NOT RESPONSIBLE FOR THE CONDUCT OF ANY USER. YOUR
            USE OF THE SERVICES IS AT YOUR SOLE RISK AND DISCRETION AND GORILLA
            SAFETY HEREBY DISCLAIMS ANY AND ALL LIABILITY TO YOU OR ANY THIRD
            PARTY RELATING THERETO.
          </p>

          <b>Indemnity</b>

          <p>
            You agree to indemnify, defend and hold harmless Gorilla Safety and
            its officers, directors, employees and agents, from and against, all
            claims, disputes, demands, liabilities, damages, losses, and costs
            and expenses, including, without limitation, reasonable legal and
            accounting fees, arising out of or in any way connected with (i)
            your access to or use of the Services; (ii) your User Content; (iii)
            your performance of or participation in connection with your use of
            the Services; or (iv) your violation of these Terms.
          </p>

          <b>Limitation of Liability</b>

          <p>
            NEITHER GORILLA SAFETY NOR ANY OTHER PARTY INVOLVED IN CREATING,
            PRODUCING, OR DELIVERING THE SERVICES OR USER CONTENT WILL BE LIABLE
            FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES,
            INCLUDING LOST PROFITS, LOSS OF DATA OR GOODWILL, SERVICE
            INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE OR THE COST OF
            SUBSTITUTE SERVICES ARISING OUT OF OR IN CONNECTION WITH THESE TERMS
            OR FROM THE USE OF OR INABILITY TO USE THE SERVICES OR USER CONTENT,
            WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE),
            PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT
            GORILLA SAFETY HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE,
            EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF
            ITS ESSENTIAL PURPOSE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION
            OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES,
            SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
          </p>

          <p>
            GORILLA SAFETY WILL NOT BE LIABLE TO YOU UNDER ANY CIRCUMSTANCES
            ARISING OUT OF THE MISUSE OF YOUR USER CONTENT BY THIRD PARTIES
            INCLUDING, WITHOUT LIMITATION, OTHER USERS.
          </p>

          <p>
            In no event will Gorilla Safety's total liability arising out of or
            in connection with these terms or from the use of or inability to
            use the Services or User Content exceed one hundred dollars ($100),
            or the amount paid by you to Gorilla Safety during the preceding six
            (6) month period for use of the Services, whichever is greater.
          </p>

          <b>Choice of Law; Arbitration</b>

          <p>
            This Agreement is subject to the law of the State of Texas, United
            States, without regard to choice or conflicts of law principles.
            Further, you agree to the exclusive jurisdiction of the state and
            federal courts in Harris County, Texas to resolve any dispute, claim
            or controversy that arises in connection with this Agreement.
          </p>

          <p>
            The following mandatory arbitration provisions also apply to you:{' '}
          </p>

          <ol start="1">
            <li>
              You and Gorilla Safety agree that any dispute, claim or
              controversy arising out of or relating in any way to the Gorilla
              Safety Service or your use thereof, including our Agreements,
              shall be determined by mandatory binding arbitration. You and
              Gorilla Safety are each waiving the right to a trial by jury and
              the right to participate in a class or multi-party action. This
              arbitration provision shall survive termination of these
              Agreements and the termination of your account with Gorilla
              Safety. The arbitration will be governed by the American
              Arbitration Association (the “AAA Rules”), as modified by this
              Agreement, and as administered by the AAA. You and Gorilla Safety
              agree that this Agreement may involve interstate commerce and may
              be subject to the Federal Arbitration Act.
            </li>
            <li>
              Any arbitration must be commenced by filing a demand for
              arbitration with the AAA within ONE (1) YEAR after the date the
              party asserting the claim first knows or reasonably should know of
              the act, omission or default giving rise to the claim; and there
              shall be no right to any remedy for any claim not asserted within
              that time period. If applicable law prohibits a one-year
              limitations period for asserting claims, any claim must be
              asserted within the shortest time period permitted by applicable
              law. The findings of the arbitrator shall be final and binding on
              the parties, and may be entered in any court of competent
              jurisdiction for enforcement. Notwithstanding the law of Texas,
              legal fees shall be awarded to the prevailing party in the
              arbitration.
            </li>
          </ol>

          <b>General Terms</b>

          <p>
            These Terms constitute the entire and exclusive understanding and
            agreement between Gorilla Safety and you regarding the Services and
            User Content, and these Terms supersede and replace any and all
            prior oral or written understandings or agreements between Gorilla
            Safety and you regarding the Services and User Content. If for any
            reason a court of competent jurisdiction finds any provision of
            these Terms invalid or unenforceable, that provision will be
            enforced to the maximum extent permissible and the other provisions
            of these Terms will remain in full force and effect.
          </p>

          <p>
            You may not assign or transfer these Terms, by operation of law or
            otherwise, without Gorilla Safety's prior written consent. Any
            attempt by you to assign or transfer these Terms, without such
            consent, will be null and of no effect. Gorilla Safety may freely
            assign or transfer these Terms without restriction. Subject to the
            foregoing, these Terms will bind and inure to the benefit of the
            parties, their successors and permitted assigns.
          </p>

          <p>
            Any notices or other communications provided by Gorilla Safety under
            these Terms, including those regarding modifications to these Terms,
            will be given by Gorilla Safety via email. For notices made by
            e-mail, the date of receipt will be deemed the date on which such
            notice is transmitted.
          </p>

          <p>
            Gorilla Safety's failure to enforce any right or provision of these
            Terms will not be considered a waiver of those rights. The waiver of
            any such right or provision will be effective only if in writing and
            signed by a duly authorized representative of Gorilla Safety. Except
            as expressly set forth in these Terms, the exercise by either party
            of any of its remedies under these Terms will be without prejudice
            to its other remedies under these Terms or otherwise.
          </p>
        </div>
      </PrivacyWrapper>
    </>
  );
};

export default Index;
