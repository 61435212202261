import React, { useState, lazy, useEffect } from 'react';
import filterListForm from './Form';
import { hasRestrictedRole } from '@/utils/utils';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));

const TopActions = ({
  filterSuccess,
  i18n,
  needFilter,
  list,
  setPagination,
  Styled,
  selectedCompanyList,
  fleetCompanyList
}) => {
  const [values, setValues] = useState({
    companyId: null
  });
  const [selectedFlg, setSelectedFlg] = useState(true)
  useEffect(() => {
    if (selectedCompanyList[0]?.value && selectedFlg) {
      setValues({ companyId: selectedCompanyList[0] })
      setSelectedFlg(false)
    }
  }, [selectedCompanyList])

  const [errorMessage, setErrorMessage] = useState('');

  return (
    <Styled.Wrapper>
      {needFilter && !hasRestrictedRole(['FLMANAGER']) && (
        <div className="filterWrapper">
          {filterListForm(
            values,
            list,
            filterSuccess,
            setValues,
            i18n,
            setPagination,
            fleetCompanyList,
            setErrorMessage
          )}
          <Message type="error" message={errorMessage} />
        </div>
      )}
    </Styled.Wrapper>
  );
};
export default TopActions;
