import Box from '@material-ui/core/Box';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { lazy, useState } from 'react';
import Filters from './Filters';


const layout = window?.$environment?.CURRENT_LAYOUT;

const Breadcrumb = lazy(() => import(`../../../components/Breadcrumb`));
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Label = lazy(() => import(`../../../components/UI/Label/${layout}/style`));
const Link = lazy(() => import(`@/components/UI/Link`));
const Message = lazy(() => import(`../../../components/UI/Message/${layout}`));

const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Breadcrumb filters={filters} />
      <Component {...contentProps} filters={filters} />
    </Box>
  );
};
export default Index;

const Component = ({
  callback,
  Styled,
  i18n,
  pagination,
  changeRequest,
  request,
  eventData,
  hasSaveApiStatus,
  setPagination }) => {


  const columns = [
    {
      id: 'event_type',
      numeric: false,
      disablePadding: false,
      label: i18n.t('cameraEvents.event_type'),
      needSorting: true
    },
    {
      id: 'count',
      numeric: false,
      disablePadding: false,
      label: i18n.t('cameraEvents.event_count'),
      needSorting: true
    },
    {
      id: 'last_occurence',
      numeric: false,
      disablePadding: false,
      label: i18n.t('cameraEvents.last_occurrence'),
      needSorting: true
    },
  ];

  const generateRows = () => {
    return eventData?.eventInfoList?.map(e => {
      return {
        event_type: e?.eventType,
        count: e?.eventCount,
        last_occurence: moment(e?.lastOccurrenceTime).format('DD-MM-YYYY HH:MM:SS A'),
      }
    })  || []
  };

  const information = i18n.t('common.tableEmptyMessage');
  const [values, setInitialValues] = useState({
    eventType: null
  });

  return (
    <>
      <Styled.Wrapper>
        <Styled.BottomWrapper>
          <div>
            <Label>Vehicle Name: <span style={{ fontWeight: "800", fontSize: "larger"}}>{eventData?.eventInfoList?.[0]?.vehicleName || ''}</span></Label>
          </div>
        <Styled.TableWrapper>
          <Filters
            handleReset={() => {
              changeRequest({})
              setInitialValues({
                eventType: null
              })
            }}
            values={values}
            filterList={(e) => {
              changeRequest(e)
            }}
            setInitialValues={setInitialValues}
          />
          <Table
            type="server"
            defaultSortBy={{ sortBy: 'event_type', order: 'asc' }}
            title=""
            changeRequest={changeRequest}
            baseRequest={request}
            totalRecords={eventData?.totalElements}
            columns={[...columns]}
            data={generateRows()}
            emptyMessage={information}
            itemsPerPage={10}
            actions={[]}
            defaultSort={'event_type'}
            i18n={i18n}
            pageinationReset={pagination}
            pagenationClicked={(e) => {
              setPagination(!e);
            }}
          />
        </Styled.TableWrapper>
        {typeof hasSaveApiStatus?.errorMessage === 'string' && (
              <Message
                type="error"
                asTags
                message={i18n.t(hasSaveApiStatus.errorMessage)}
              />
            )}
        </Styled.BottomWrapper>
      </Styled.Wrapper>
    </>
  );
};

Component.propTypes = {
  callback: PropTypes.func.isRequired,
  vehicles: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  filterSuccess: PropTypes.func.isRequired,
};
