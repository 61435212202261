import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import ButtonComponent from '@material-ui/core/Button';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import GroupAddIcon from '@material-ui/icons/GroupAdd';

const Button = ({
  size,
  id,
  key,
  dataValue,
  className,
  type,
  onClick,
  label,
  addPersonIcon,
  addGroupIcon,
  variant,
  ButtonContainer,
  disabled,
  tooltipTxt
}) => {
  const handleOnClick = (e) => {
    onClick(e);
  };
  const idGenerator = (min, max) => {
    return min + Math.floor(Math.random() * (max - min + 1));
  }

  const getComponent = () => {
    return <ButtonComponent
      disabled={disabled || false}
      type={type || 'button'}
      size={size || 'small'}
      variant={variant || 'outlined'}
      disableripple="true"
      onClick={handleOnClick}
      id={id ? id : `button_${idGenerator(5000, 1)}`}
      key={key}
      data-value={dataValue}
      className={type === 'reset' ? 'reset' : className}
      startIcon={
        addPersonIcon ? (
          <PersonAddIcon />
        ) : addGroupIcon ? (
          <GroupAddIcon />
        ) : (
          ''
        )
      }
    >
      {label}
    </ButtonComponent>
  }

  return (
    <ButtonContainer>
      {tooltipTxt ? <Tooltip id="custom-tooltip" title={<span style={{ fontSize: '16px', padding: '10px' }}>{tooltipTxt}</span>} arrow placement='top'>
        {getComponent()}
      </Tooltip> : getComponent()}
    </ButtonContainer>
  );
};

Button.propTypes = {
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  size: PropTypes.string,
  id: PropTypes.string,
  key: PropTypes.string,
  dataValue: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  addPersonIcon: PropTypes.bool,
  addGroupIcon: PropTypes.bool
};

Button.defaultProps = {
  size: '',
  id: '',
  key: '',
  dataValue: '',
  className: '',
  type: '',
  addPersonIcon: false,
  addGroupIcon: false,
  label: '',
  onClick: () => { }
};

export default Button;
