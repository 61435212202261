import React, { useState, lazy } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { UI_LOGBOOK_DATE } from '@/config/constants/static';

import RenderComponent from './RenderComponent';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));

const ViewForm = ({
  fetchTableData,
  hasApiStatus,
  callback,
  history,
  driverList,
  vehicleListDropDown,
  fetchChartData,
  Styled,
  fetchDriverBehaviorDetails
}) => {
  const [values, setValues] = useState({
    vehicleId: '',
    endDate: '',
    startDate: '',
    id: ''
  });

  return (
    <Styled.Wrapper>
      <Form
        type="server"
        direction="row"
        initialValues={{ ...values }}
        className="CSAForms"
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {
          fields.id = fields?.id?.value || '';
          fields.vehicleId = fields?.vehicleId?.value || '';
          fields.startDate = fields.startDate
            ? moment(fields.startDate).format(UI_LOGBOOK_DATE)
            : '';
          fields.endDate = fields.endDate
            ? moment(fields.endDate).format(UI_LOGBOOK_DATE)
            : '';
          fetchTableData({
            apiId: 'fetchTableData',
            data: fields,
            type: 'speeding'
          });

          fetchChartData({
            apiId: 'fetchTableData',
            data: fields,
            type: 'speeding'
          });
        }}
        handleOnCancel={(fields) => {
          fetchDriverBehaviorDetails({
            apiId: 'fetchDriverDetails'
          });
          fetchTableData({
            apiId: 'fetchTableData',
            data: {
              endDate: '',
              startDate: '',
              id: ''
            },
            type: 'speeding'
          });
        }}
        customProps={{
          history,
          callback,
          hasApiStatus,
          driverList,
          vehicleListDropDown
        }}
      />
    </Styled.Wrapper>
  );
};

ViewForm.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default ViewForm;
