import styled from 'styled-components';

const Wrapper = styled.ul`
  ${({ theme, columns }) => `
  flex-direction: column;
  display: grid;
  grid-template-columns: ${columns.map((item) => 'auto').join(' ')};

  padding: 0px;
  margin-top: 10px;
  @media (max-width: ${theme.breakpoints.values.lg}px) {
    overflow-y: auto;
  }
  border: solid 2px
    ${
      theme.palette.primary.border
        ? theme.palette.primary.border
        : theme.colors.PRIMARY_BORDER
    };
  li:first-child,
  li:nth-child(2),
  li:nth-child(3),
  li:nth-child(4),
  li:nth-child(5),
  li:nth-child(6),
  li:nth-child(7),
  li:nth-child(8) {
    color: colors.PRIMARY_CONTRAST;
    font-size: 20px;
    padding: 0;
    padding-left: 20px;
    line-height: 0.8;
    p {
      padding-top: 15px !important;
    }
  }
  .actions {
    padding-top: 16px;
    .MuiAvatar-root {
      width: 25px;
      height: 25px;
    }
  }
  `}
`;
const Header = styled.ul`
  ${({ theme }) => `
  display: grid;
  grid-template-columns: 30px 200px auto 100px;
  padding: 5px;
  background-color: ${
    theme.palette.primary.main ? theme.palette.primary.main : theme.PRIMARY_MAIN
  };
  list-style-type: none;
  margin: 0px;
  border-bottom: solid 1px
    ${
      theme.palette.primary.border
        ? theme.palette.primary.border
        : theme.PRIMARY_BORDER
    };
        `}
`;
const HeaderCell = styled.li`
  ${({ theme }) => `
  background-color: ${
    theme.palette.primary.main ? theme.palette.primary.main : theme.PRIMARY_MAIN
  };
  border-bottom: solid 1px
    ${
      theme.palette.primary.border
        ? theme.palette.primary.border
        : theme.PRIMARY_BORDER
    };
  color: ${
    theme.palette.secondary.main
      ? theme.palette.secondary.main
      : theme.SECONDARY_MAIN
  };
  list-style: none;
  font-size: 17px;
  padding: 5px;
  span {
    text-align: right;
    color: ${
      theme.palette.primary.contrastText
        ? theme.palette.primary.contrastText
        : theme.PRIMARY_CONTRAST
    };
    margin-right: 5px;
  }
  .MuiIcon-root {
  }
  .unSort {
    width: 11px;
    padding-top: 10px;
    path {
      fill: ${
        theme.palette.primary.contrastText
          ? theme.palette.primary.contrastText
          : theme.PRIMARY_CONTRAST
      };
    }
  }
  `}
`;
const Row = styled.ul`
  ${({ theme }) => `
  display: grid;
  grid-template-columns: 30px 200px auto 100px;
  padding: 5px;
  border-top: solid 1px
    ${
      theme.palette.primary.border
        ? theme.palette.primary.border
        : theme.PRIMARY_BORDER
    };
  list-style-type: none;
  width: 100%;
  margin: 0px;
  border-top: solid 1px
    ${
      theme.palette.primary.border
        ? theme.palette.primary.border
        : theme.PRIMARY_BORDER
    };
        `}
`;
const RowCell = styled.li`
  ${({ theme }) => `
  border-top: solid 1px
    ${
      theme.palette.primary.border
        ? theme.palette.primary.border
        : theme.PRIMARY_BORDER
    };
  padding: 7px 5px;
  color: ${
    theme.palette.primary.contrastText
      ? theme.palette.primary.contrastText
      : theme.PRIMARY_CONTRAST
  };
  list-style: none;
  font-size: 16px;
  a {
    color: ${theme.palette.secondary.main};
  }
  .MuiAvatar-img {
    width: 25px !important;
    height: 25px !important;
  }
  `}
`;

const PaginationWrapper = styled.div`
  ${({ theme }) => `
  justify-content: center;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  li.page.disabled {
    color: ${theme.palette.secondary.main} !important;
  }
  .react-pagination-js-default .page,
  .react-pagination-js-default .is-active {
    font-family: ${theme.palette.fonts};
    font-size: 24px;
    font-weight: bold;
    color: ${theme.palette.secondary.main} !important;
    border-radius: 0px;
    border: 0px;
    background: none;
    box-shadow: none;
    font-weight: bold;
    text-decoration: none;
    text-shadow: none;
  }
  .pagination ul a:last {
    color: ${theme.palette.secondary.main};
  }
  .react-pagination-js-default .is-active {
    color: ${theme.palette.primary.contrastText} !important;
  }
  .react-pagination-js-default .page:hover,
  .page.gradient:hover {
    background-color: transparent;
  }
  `}
`;

const Styled = { Wrapper, Header, HeaderCell, Row, RowCell, PaginationWrapper };

export default Styled;
