import styled from 'styled-components';
import Box from '@material-ui/core/Box';

import { getStyle } from '../../../../utils/utils';

const ExportContainer = styled(Box)`
  ${({ theme, newUI }) => `
  position: relative;
  align-items: center;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  .tooltiptext {
    visibility: hidden;
    width: 120px;
    background: ${getStyle(
      '',
      (theme.palette.primary || {}).main,
      theme.colors.PRIMARY_MAIN
    )};
    color: ${theme.colors.WHITE};
    text-align: center;
    padding: 2px 0;
    border-radius: 0px;
    border: solid 1px ${theme.colors.WHITE};
    position: absolute;
    z-index: 1;
    right: -30px;
    top: 70px;
  }

  :hover .tooltiptext {
    visibility: visible;
  }
  .exportWrapper {
    display: flex;
    border: 1px solid ${theme.colors.ACTION_BORDER};
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    .MuiGrid-container {
      width: auto !important;
    }
    .MuiAvatar-root {
      width: 16px;
      height: 16px;
    }
    .exportText {
      opacity: ${window.$environment?.clutchNewUI? '1': '0.66'};
      color: ${theme.colors.PRIMARY_CONTRAST};
      font-size: ${window.$environment?.clutchNewUI? '14px': '12px'};
      font-weight: ${window.$environment?.clutchNewUI? 'bold': '400'};
      text-transform: ${window.$environment?.clutchNewUI? 'capitalize': 'uppercase'};
      padding-right: 8px;
    }
  }
  `}
`;

export default ExportContainer;
