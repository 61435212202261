import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import CircularProgress from '@material-ui/core/CircularProgress';

import Label from '@/components/UI/FormLabel/Clutch';
import Message from '@/components/UI/Message/Clutch';
import Button from '@/components/UI/Button/Clutch';
import SubHeader from '@/components/SubHeader/Clutch';
import {pageUrls, imageUrls} from '@/config/constants/keys'
import {getLogo} from '@/utils/utils'

const layout = window.$environment?.CURRENT_LAYOUT;
const Input = lazy(() => import(`@/components/UI/Input/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`).default;
const RenderComponent = ({
  values,
  handleEdit,
  errors,
  isNotValid,
  validateForm,
  customProps,
  submitAction
}) => {
  const { i18n } = useTranslation();
  const NewUI = window.$environment.SERVICE_PROVIDER === 'webfleet'
  return (
    <>
      <customProps.Styled.LoginHeader>
        <img alt="logo" src={getLogo(imageUrls.clutchLogo)} className="ClutchLogo"></img>
        {!NewUI && (
        <>
          <SubHeader
            variant="h2"
            text={i18n.t('Multi Factor Authentication')}
            mode="light"
            className="loginHeader"
          />
        </>
        )}
      </customProps.Styled.LoginHeader>
      <customProps.Styled.LoginContent newUI={NewUI}>
      {NewUI && (
        <>
          <SubHeader
            variant="h2"
            text={i18n.t('Multi Factor Authentication')}
            mode="dark"
            className="loginHeader"
          />
        </>
        )}
      {customProps?.hasApiStatus?.errorMessage && (
        <customProps.Styled.LoginError>
          <Message
            type="error"
            message={i18n.t(customProps.hasApiStatus.errorMessage)}
          />
        </customProps.Styled.LoginError>
      )}
      <customProps.Styled.userNameWrapper>
        <FormStyle.FieldsWrapper width="100%">
          <Label variant="body1">{i18n.t('common.mfacode')}</Label>
          <FormStyle.TextWrapper>
            <Field
              name="mfacode"
              handleOnChange={(event) =>
              { 
                if(event.length > 6) {
                  return;
                } 
                handleEdit(event, { field: 'mfacode', type: 'mfacode' })}
              }
              type="number"
              size="6"
              as={Input}
              placeholder={i18n.t('MFA Code')}
              value={values.mfacode}
              mode="light"
            />
            {errors['mfacode'] && !isNotValid && (
              <Message type="error" message={errors['mfacode']} />
            )}
          </FormStyle.TextWrapper>
        </FormStyle.FieldsWrapper>
      </customProps.Styled.userNameWrapper>
      <customProps.Styled.LoginButtonWrapper newUI={NewUI}>
        <FormStyle.ButtonsWrapper>
          <Button
            type="submit"
            label={
              customProps?.hasApiStatus?.isLoading ? (
                <CircularProgress color="inherit" />
              ) : (
                i18n.t('common.authenticate')
              )
            }
            onClick={() => {
              const validateResponse = validateForm();
              validateResponse.then((data) => submitAction(data));
            }}
            id="forgotPasswordButton"
          />
        </FormStyle.ButtonsWrapper>
      </customProps.Styled.LoginButtonWrapper>
      </customProps.Styled.LoginContent>
      {NewUI && (<span className="patentText">{i18n.t('footer.copyright')}</span>)}
    </>
  );
};

export default RenderComponent;

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  customProps: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};
