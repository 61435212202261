
import styled from 'styled-components';
import Parent from './style';

const DocumentSection = styled(Parent.DocumentSection)`
padding: 0;
padding-bottom: 2%;
`;
const LeftWrapper = styled(Parent.LeftWrapper)`
.documentTitle{
    margin-bottom: 0 !important;
  }
  ul{
    margin-top:0;
  }
.finish {
    justify-content: flex-start;
    padding-left: 1%;
    button{
      min-width: 210px;
    }
}
`;
const Styled = { DocumentSection, LeftWrapper };

export default Styled;