import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import React, { lazy, useState, useEffect, useRef } from 'react';
import { ClickAwayListener  } from '@material-ui/core';


const layout = window?.$environment?.CURRENT_LAYOUT;
const Label = lazy(() => import(`@/components/UI/Label/${layout}`));
const ToolTip = lazy(() => import(`../ToolTip`));

const TitleToolTip = ({
    title, info, className, small
  }) => {
    const [open, setOpen] = useState(false);
    const [cliked, setClicked] = useState(false);
    const tooltipRef = useRef(null);
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        event.stopPropagation();
        if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
          setOpen(false);
        }
      };
  
      document.addEventListener('click', handleClickOutside);
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, [open]);
  
    const handleClick = event => {
      setTimeout(() => {
        event.stopPropagation();
        setClicked(prev => !prev)
        setOpen(false);
      }, 200);
    }
  
    const onMouseEnter = event => {
      setOpen(true)
    }
  
    const handleMouseLeave = (event) => {
      setTimeout(() => { setOpen(false) }, 200);
    }
  
    const onClose = () => {
      setOpen(false);
    }
  
    const onOpen = () => {
        if (!open) {
          setOpen(true);
        }
    }
  
    return (
    <div ref={tooltipRef}>
      <Label {...small ? { className: className } : { variant: "body1", className: `statsHeading ${className}`  }}>
        {title}
        <ToolTip
        ref={tooltipRef}
          id="tooltips"
          arrow
          title={(
            <ClickAwayListener onClickAway={() => {
              cliked && setClicked(false)
            }}>
            <p style={{ zIndex: '1500',
              pointerEvents: 'auto',
              cursor: 'text' }}>
            {info}
          </p>
          </ClickAwayListener >
          )}
          placement='top'
          followCursor
          open={cliked ?  cliked : open}
          onClose={onClose}
          onOpen={onOpen}
          disableHoverListener
        >
          <span onClick={handleClick} onMouseEnter={onMouseEnter} onMouseLeave={handleMouseLeave} style={{ cursor: 'pointer' }}>
            <InfoOutlinedIcon  />
          </span>
        </ToolTip>
      </Label>
    </div>
    ); 
  }

  export default TitleToolTip;