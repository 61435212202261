import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import { getImagePath } from '../../../utils/utils';
const isEs = () => localStorage.getItem(window.$environment?.SERVICE_PROVIDER + 'Language') == 'es'

const LogBookContainer = styled(Box)`
  ${({ theme, includeRealtimeLog }) => `
  .uppercase {
    text-transform: uppercase;
  }
  .logsInfoDisplay {
    font-size: 20px;
    padding-top: 4px;
    padding-bottom: 10px;
  }
  #regeneratePdf-wrapper {
    position: relative;
    #regeneratePdf {
      color: ${theme.colors.LINK_COLOR};
      position: absolute;
      right: 0;
      background: none !important;
      text-decoration: none; 
      padding: 5px !important;
    }
    
    #regeneratePdf:hover {
      text-decoration: underline; 
    }
  }
  a {
    background-color: ${
      (theme.palette.primary || {}).main || theme.colors.PRIMARY_MAIN
    };
    color: ${
      (theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN
    };
    padding: 5px 30px;
    font-size: 20px;
  }
  a:hover {
    text-decoration: none;
  }
  form {
    flex: 0 0 50%;
    @media (max-width: ${theme.breakpoints.values.md}px) {
    }
    @media (min-width: ${theme.breakpoints.values.mlg}px) and (max-width: ${
    theme.breakpoints.values.xl
  }px) {
      flex: 0 0 58%;
    }
    @media (max-width: ${theme.breakpoints.values.mlg - 1}px) {
      flex: 0 0 70%;
    }
    justify-content: flex-start;
    .vertical {
      flex: 0 0 35%;
      @media (max-width: ${theme.breakpoints.values.md - 1}px) {
        flex: 0 0 100%;
      }
      .select__control {
        margin-bottom: 0;
      }
    }
    .css-8r3dx3-control,
    .css-q5i2ns-control,
    .react-select__menu-list,
    .css-1wmipuf-option,
    .css-1irrqr3-option,
    .css-1hys26h-singleValue,
    .css-1ebjif1-option,
    .react-select__menu-list:active,
    .css-1wmipuf-option:active,
    .css-1ebjif1-option:active,
    .css-kpwi51-option:active,
    .css-kpwi51-option,
    .css-1wmipuf-option{
      background: ${theme.colors.INPUT_BACKGROUND} !important;
    }
    .react-datepicker-wrapper input{
      background-color: ${theme.colors.INPUT_BACKGROUND} !important;
    }
    .vertical-button {
      flex: 0 0 20%;
      justify-content: flex-start;
      .MuiButtonBase-root {
        padding: 1px 0;
        margin-top: 18px;
      }
    }
  }
  form + .MuiBox-root {
    flex: 0 0 30%;
  }
  .ItemsContainer {
    flex: 0 0 85%;
  }
  .ButtonHolder {
    align-items: flex-end;
    padding-bottom: 16px;
    justify-content: flex-start;
    span {
      padding: 2px 15px;
    }
  }
  .viewContainer {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    justify-content: flex-start;
    .MuiBox-root-277 {
      display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
      align-items: flex-end;
      padding-bottom: 16px;
      .MuiButton-outlined {
        padding: 2px 15px;
      }
      .MuiButton-root:hover {
        background-color: ${
          (theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN
        };
      }
    }
    @media (max-width: ${theme.breakpoints.values.md}px) {
      flex-direction: column;
    }
    .vertical-button .MuiBox-root {
      margin-bottom: 20px;
      margin-top: 0px;
      .MuiButtonBase-root {
        font-size: 12px;
        background: ${theme.colors.SECONDARY_MAIN};
        border-radius: 4px;
        text-transform: uppercase;
      }
    }
    .react-datepicker__day:not(.react-datepicker__day--highlighted):not(.react-datepicker__day--disabled) {
      ${includeRealtimeLog && `border-bottom: 2px solid #0379fc !important;
      color: ${theme.colors.PRIMARY_CONTRAST};
      `}
    }
    .react-datepicker__day--highlighted{
      border-bottom: 2px solid #f7b946 !important;
      color: ${theme.colors.PRIMARY_CONTRAST} !important;
    }
  }

  .displayContainer {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flexbox;
    display: flex; 
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: ${theme.breakpoints.values.md}px) {
      flex-direction: column;
    }
    @media (min-width: ${theme.breakpoints.values.md}px) and (max-width: ${
    theme.breakpoints.values.lg
  }px) {
      flex-direction: column;
    }
  }
  .leftWrapper {
    flex: 0 0 70%;
    padding: 0 2%;
    padding-bottom: 3%;
    margin-right: 20px;
  }
  .leftWrapper,
  .rightSide {
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.02);
    background-color: ${theme.colors.HEADER_COLOR};
    border-radius: 7px;
  }
  .rightSide {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flexbox;
    display: flex; 
    flex-direction: column;
    flex: 0 0 30%;
    .dashboardViolation {
      flex: 0 0 100%;
      .headingsWrapper{
        padding-left: 16px !important;
      }
    }
    div[type='info'] p {
      text-align: center;
      padding: 10px 0;
    }
  }
  .pdfViolationsContainer {
    display: flex;
  }
  .violationsContainer {
    // max-height: 720px;
    margin-top: 80px;
    margin-left: 20px
    flex: 0 0 30%;
  }
  .pdfLinkContainer {
    flex: 0 0 70%;
    max-width: 830px;
  }
  .linkContainer {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flexbox;
    display: flex; 
    margin-top: 25px;
    padding-bottom: 2%;
    flex: 0 0 60%;
    justify-content: space-around;
    .prev,
    .next {
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        margin-left: 0;
      }
    }
    .MuiLink-root {
      @media (min-width: ${
        theme.breakpoints.values.lg + 1
      }px) and (max-width: ${theme.breakpoints.values.xl - 1}px) {
        padding: 5px 20px;
      }
    }
    div {
      display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
      display: -ms-flexbox;  /* TWEENER - IE 10 */
      display: -webkit-flexbox;
      display: flex; 
      margin-left: 10px;
      @media (max-width: ${theme.breakpoints.values.md}px) {
        margin-left: 4px;
      }
    }
    
    @media (max-width: ${theme.breakpoints.values.md}px) {
      justify-content: flex-start;
      a#button {
        padding: 6px;
        font-size: 12px;
      }
    }
    @media (min-width: ${theme.breakpoints.values.md}px) and (max-width: ${
    theme.breakpoints.values.lg
  }px) {
      justify-content: flex-start;
    }
  }
 
  .next {
    justify-content: flex-end;
    flex: 0 0 30%;
    #button {
      background: ${theme.colors.RESET_BUTTON}  ${isEs() ? '' : `url(${getImagePath(
        'icons/leftArrow.svg'
      )}) no-repeat
      right center`};
      color: rgba(255, 255, 255, 0.66);
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.6px;
      height: 40px;
      border-radius: 4px;
      padding-top: 12px;
      padding-bottom: 10px;
      padding-left: 15px;
      ${isEs() ? 'padding-right: 15px' : ''}
      background-position: 76px;
      min-width: 100px;
    }
  }
  .prev {
    justify-content: flex-end;
    flex: 0 0 30%;
    #button {
      color: rgba(255, 255, 255, 0.66);
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.6px;
      background: ${theme.colors.RESET_BUTTON} ${isEs() ? '' :`url(${getImagePath(
        'icons/arrowRight.svg'
      )}) no-repeat
      left center`};
      height: 40px;
      border-radius: 4px;
      background-position: 13px;
      padding-top: 12px;
      padding-bottom: 10px;
      padding-right: 15px
      padding-left: ${isEs() ? '16px' :'38px'};
    }
    
  }
  `}
`;
const PdfContainer = styled(Box)`
  ${({ theme }) => `
  .events{
    margin-top: 40px;
    display: grid;
    column-gap: 15px;
    row-gap: 15px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .eventContainer { 
    border: 2px solid ${theme.colors.PRIMARY_BORDER};
  }
  width: 100%;
  .dailylogSection {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  }
  .dailyLogs{
    flex: 0 0 40%;
    align-self: center;
  }
  .dailyLogs {
    color: ${theme.colors.HEADER_TEXT};
    font-size: 24px;
    font-weight: 700;
  }
  .dateLabel {
    color: ${theme.colors.HEADER_TEXT};
    font-size: 16px;
    font-weight: 400;
    align-self: center;
  }
  .pdfWrap {
    margin-top: 15px;
    @media (max-width: ${theme.breakpoints.values.md}px) {
      iframe {
        height: 350px;
      }
    }
  }
  `}
`;
const MapContainer = styled(Box)`
  flex: 0 0 48%;
`;
const MapWrapper = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 17px;
  margin-bottom: 10px;
`;
const ViolationContainer = styled(Box)`
  ${({ theme }) => `
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  border: 0;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  flex-direction: column;
  .heading {
    padding: 5px;
    color: #ffffff;
    font-size: 24px;
    font-weight: 700;
    background-color: transparent;
    padding-left: 3%;
  }
  p {
    color: ${
      (theme.palette.primary || {}).contrastText ||
      theme.colors.PRIMARY_CONTRAST
    } !important;
  }
  `}
`;

const LogbookWrap = styled(Box)`
  ${({ theme }) => `
  overflow: hidden;
  position: relative;
  .logbookTitle {
    flex: 0 0 50%;
    margin-top: -7px;
  }
  #container h1 {
    padding-left: 0 !important;
  }
  .uppercase {
    text-transform: uppercase;
  }
  .commonLinks {
      display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
      display: -ms-flexbox;  /* TWEENER - IE 10 */
      display: -webkit-flexbox;
      display: flex; 
      flex: 0 0 50%;
      justify-content: flex-end;
      align-items: center;
    a {
      font-size: 12px !important;
      border: 1px solid ${theme.colors.ACTION_BORDER};
      color: ${theme.colors.PRIMARY_BUTTON_TEXT};
      border-radius: 4px;
      padding: 10px 16px 10px 34px;
      text-decoration: none;
      ${window?.$environment?.clutchNewUI && `font-weight: bold`}
      ${window?.$environment?.clutchNewUI && `text-transform: capitalize`}

    }
    div {
      margin-right: 2%;
      :last-child {
        margin-right: 0;
      }
    }
    .dailyDocument {
      ${window.$environment.WHITE_THEME ? 'color: #000' :''}
      background: url(${getImagePath('icons/viewDocument.svg')}) no-repeat
      left center;
      background-position: 10px;
    }
    .history {
      ${window.$environment.WHITE_THEME ? 'color: #000' :''}
      background: url(${getImagePath('icons/historyLogs.svg')}) no-repeat
      left center;
      background-position: 10px;
    }
    .logEdit {
      ${window.$environment.WHITE_THEME ? 'color: #000' :''}
      background: url(${getImagePath('icons/logEdit.svg')}) no-repeat
      left center;
      background-position: 10px;
    }
    .logView {
      ${window.$environment.WHITE_THEME ? 'color: #000' :''}
      background: url(${getImagePath('icons/viewLogs.svg')}) no-repeat
      left center;
      background-position: 5px;
    }
    .buttonBulkDownload {
      padding: 2px 30px !important;
      ${window.$environment.WHITE_THEME ? 'color: #000' :''}
      margin-top: 5px;
      min-height: 40px;
      font-size: 12px !important;
      border: 1px solid ${theme.colors.ACTION_BORDER};
      background: url(${getImagePath('icons/download.svg')}) no-repeat
      left center !important;
      background-position: 10px !important;
      padding-left: 40px !important;
      @media (max-width: ${theme.breakpoints.values.mlg}px) {
        padding: 2px 22px !important;
        font-size: 21px;
      }
      @media (max-width: ${theme.breakpoints.values.md - 1}px) {
        padding: 2px 20px !important;
        font-size: 21px;
        margin-top: 0;
      }
    }
  }
  .logbookButtons {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flexbox;
    display: flex; 
    border-bottom: 1.5px solid ${theme.colors.SECONDARY_BORDER};
    padding: 0.5% 2%;
  }
  `}
`;
const Styled = {
  LogBookContainer,
  PdfContainer,
  MapContainer,
  MapWrapper,
  ViolationContainer,
  LogbookWrap
};

export default Styled;
