import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
padding: 2%;
`;
const ChartWrapper = styled(Box)`
  ${({ theme }) => `
width: 100%;
.quickFilter {
  background: ${theme.colors.LINK_COLOR} none repeat scroll 0 0;
  color: #f4f6f9;
  padding: 10px 3%;
  margin-top: 15px;
}
  .svg-container {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    position: relative;
    width: 100%;
    margin-top: 2%;
    padding-bottom: 26%;
    vertical-align: top;
  }
  .select__control{
    background: #efefef;
    border: 0;
  }
  .editmodeNotification {
    width: 53%;
    background-color: #bdbdbd;
    color: #fff;
    font-size: 18px;
    text-align: center;
    margin: 0 auto;
    margin-top: 15px;
    // font-weight: bold;
  }
  .svg-content {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    position: absolute;
    top: 0;
    left: 0;
    rect.square {
        fill: #fff;
    }
    text {
        font-size: 3px !important;
        fill: #000;
        font-family: Arial, "Geneva CY", sans-serif;
    }
    .overlay .selectedArea {
        fill: ${theme.colors.SELECTED_AREA}!important;
    }
    rect.leftChip,
    rect.rightChip{
      fill: #fff;
      stroke: ${theme.colors.LINK_COLOR};
    }
  }
  .updateEventsection {
    flex-wrap: wrap;
    padding-right: 2%;
    @media (max-width: ${theme.breakpoints.values.lg}px) {
      justify-content: center;
      padding-top: 12px;
      .MuiBox-root {
        margin-bottom: 5px;
      }
    }
    .button--disabled:hover {
      background: #ebebeb;
      box-shadow: none;
    }
    .button-status{
      width:95%;
      // font-family: Arial, "Geneva CY", sans-serif;
    }
    .MuiButton-outlinedSizeSmall.button-update {
      font-size: 22px !important;
      font-weight: normal;
    }
    .MuiButton-outlinedSizeSmall {
      padding: 3px 14px !important;
      background-color: #ebebeb;
      color: #6b6b6b;
      font-weight: 600;
      font-size: 16px !important;
      cursor: pointer;
      border: 2px solid ${theme.colors.LINK_COLOR};
    }
    #update_graph + div {
      #button {
        border: none;
      }
    }
  }
  #update_graph {
    padding-right: 10px;
    padding-left: 30px;
    .MuiButton-outlinedSizeSmall{
      border: 1px solid #ddd;
      font-size: 22px !important;
    }
    :hover {
      // background: #ebebeb;
      box-shadow: none;
    }
  }
  .button--disabled: hover {
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.primary.contrastText};
    cursor: inherit;
  }
  .button--status-normal: hover,
  .button--update-normal: hover,
  .button--update-normal,
  .button-add {
    color: ${theme.palette.secondary.contrastText + '!important'};
    background-color: ${theme.palette.secondary.main + '!important'};
    cursor: pointer;
  }
  .button-add {
    font-size: ${theme.palette.buttonFont} !important;
    text-transform: capitalize;
  }

  .active {
    background-color: ${theme.palette.secondary.main}!important ;
    color: ${theme.palette.secondary.contrastText}!important ;
  }

  .button-status {
    margin-right: 4px;
    padding-top: 0px;
    padding-bottom: 0px;
    color: ${theme.palette.primary.contrastText};
    background-color: ${theme.palette.primary.main};
    font-size: 17px;
    border: ${`1px solid ${theme.palette.primary.border}`};
    @media (max-width: ${theme.breakpoints.values.md}px) {
      font-size: 15px;
    }
    @media (max-width: ${theme.breakpoints.values.sm}px) {
      padding: 0;
    }
  }
#update_graph {

}
  #addNewRow {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    justify-content: flex-end;
    .MuiBox-root:first-child {
      width: 100%;
    }
  }
  .cancelSubmitwrapper {
    width:100%;
  }
  .viewIconWrapper {
    padding-top:13px;
    .MuiAvatar-root{
    height:21px !important;
    width: auto !important;
    border-radius:0px !important;
  }
  `}
`;
const LabelWrap = styled(Box)`
  p {
    font-size: 21px;
    margin-bottom: 20px;
  }
`;
const StatusModifier = styled(Box)`
 ${({ theme }) => `
  .status {
    min-width: 98px;
  }
  .MuiPaper-root .MuiBox-root:first-child {
    overflow-x: inherit;
  }
  .MuiTableHead-root .MuiTableRow-root {
    .MuiTableCell-root:first-child {
      min-width: 112px;
    }
    .MuiTableCell-root:nth-child(6),
    .MuiTableCell-root:nth-child(2) {
      min-width: 150px;
    }
  }
  .MuiInputBase-root.Mui-disabled,
  .MuiInputBase-input.Mui-disabled{
    cursor: no-drop;
  }
  .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root:first-child {
    min-width: 147px;
  }
  .select__control,
  .react-datepicker-wrapper input,
  input {
    background: #fff none repeat scroll 0 0 !important;
    border: 1px solid #d0d0d0 !important;
  }
  .select__single-value {
    background: #fff none repeat scroll 0 0 !important;
    border:0;
  }
  .select__control:hover{
    border: 1px solid #d0d0d0 !important;
  }
  .MuiTableBody-root tr:nth-child(even):hover,
  .MuiTableBody-root tr:nth-child(odd):hover,
  .MuiTableBody-root tr:nth-child(odd) {
    background: ${theme.colors.WHITE} !important;
    
    }
  .stateModifier {
    min-width: 120px;
  }
  .driverId,
  .stateModifier {
    .select__placeholder {
      font-size: 16px;
    }
    .select__dropdown-indicator {
      padding-left: 0;
      padding: 3px;
    }
  }
  
  .react-datepicker--time-only {
    border: 2px solid colors.PRIMARY_BORDER;
    color: colors.PRIMARY_CONTRAST;
    font-family: Yanone Kaffeesatz, tahoma;
    font-size: 14px;
    font-weight: normal;
    .react-datepicker__time-list {
      padding-top: 6px;
    }
    // .react-datepicker__time-list::-webkit-scrollbar {
    //   width: 0;
    // }
    .react-datepicker__time-list-item {
      border-top: none !important;
    }
    .react-datepicker__header--time {
      display: none;
    }
  }
  .dailyLogDateError {
    border: 2px solid red !important;
  }
  `}
`;
const CancelSaveWrapper = styled(Box)`
  ${({ theme }) => `

  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  justify-content: flex-end;
  @media (max-width: ${theme.breakpoints.values.sm}px) {
    justify-content: center;
  }
  width:100%;
  &:first-child {
    margin-right:3px;
  }
  .MuiBox-root:first-child {
    margin-right: 3px;
  }
  .MuiBox-root {
    width: auto;
    min-width: 117px;
    margin-right:0;
  }
  #button {
    padding: 4px 30px;
  }
  `}
`;
const Styled = { Wrapper, ChartWrapper, LabelWrap, StatusModifier, CancelSaveWrapper };
export default Styled;
