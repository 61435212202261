import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import * as Yup from 'yup';

import { hasValue } from '../../../../utils/utils';
import { imageUrls } from '../../../../config/constants/keys';
import RenderComponent from './RenderComponent';

const layout = window?.$environment?.CURRENT_LAYOUT;
const CloseButton = lazy(() =>
  import(`../../../../components/CloseButton/${layout}`)
);
const Message = lazy(() =>
  import(`../../../../components/UI/Message/${layout}`)
);
const Form = lazy(() => import(`../../../../components/UI/Form/${layout}`));

const DatePopup = ({
  isOpen,
  setIsOpen,
  exportHandler,
  apiStatus,
  clearApiStatus,
  formId,
  data,
  params,
  submit,
  noValidation,
  Styled,
  callback,
  inspections,
  drivers,
  downloadTypes
}) => {
  const handleExport = (fields) => {
    exportHandler({ data: { ...fields }, apiId: formId });
  };

  const [setTransition, setTransitionFn] = React.useState('fromRight');
  React.useEffect(() => {
    if (setTransition === 'toRight') {
      setTimeout(() => {
        setTransitionFn('fromRight');
      }, 1500);
    }
  }, [setTransition]);

  const ExportComponent = ({ handleExport }) => {
    const { i18n } = useTranslation();
    const formId = 'exportLogs';
    return (
      <div>
        <Form
          type="server"
          direction="row"
          className={inspections ? 'bulkSection' : ''}
          initialValues={{
            startDate: '',
            endDate: '',
            driverId: (drivers && drivers[0]) || '',
            downloadType: (downloadTypes && downloadTypes[0]) || ''
          }}
          RenderComponent={RenderComponent}
          handleOnSubmit={(fields) => {
            let data = { ...fields };
            if (inspections) {
              data = {
                ...fields,
                driverId: fields.driverId?.value,
                type: fields.downloadType?.value,
                downloadType: fields.downloadType?.value,
                driverName: fields.driverId?.label
              };
              submit({ data: { data: { ...data } }, callback });
            } else {
              if (submit && params) {
                let finalParams = { ...params };
                if (fields.endDate) finalParams.endDate = fields.endDate;
                if (fields.startDate) finalParams.startDate = fields.startDate;
                submit({ data: { data: { ...finalParams } }, callback });
              } else handleExport(data, formId, callback);
            }
          }}
          customProps={{
            hasExportApiStatus,
            inspections,
            drivers,
            downloadTypes
          }}
          validationSchema={
            noValidation
              ? Yup.object().shape({})
              : Yup.object().shape({
                  startDate: Yup.string().required(
                    i18n.t('This field is required')
                  ),
                  endDate: Yup.string().required(
                    i18n.t('This field is required')
                  )
                })
          }
        />
      </div>
    );
  };

  ExportComponent.propTypes = {
    handleExport: PropTypes.func.isRequired
  };
  const hasExportApiStatus = hasValue(
    apiStatus && apiStatus.status,
    'api',
    formId
  );
  const setTransitionValue = () => {
    setTransitionFn('toRight');
    setTimeout(() => {
      setIsOpen(false);
    }, 1500);
  };
  return (
    <>
      {isOpen && (
        <Styled.ExportWrapper className="datepopup" transition={setTransition}>
            <CloseButton
              className="exportClose"
              icon={imageUrls.closeIcon}
              onClick={() => {
                setTransitionValue();
                clearApiStatus();
              }}
            />
          {hasExportApiStatus && hasExportApiStatus.errorMessage && (
            <Message type="error" message={hasExportApiStatus.errorMessage} />
          )}
          <ExportComponent handleExport={handleExport} />
        </Styled.ExportWrapper>
      )}
    </>
  );
};

export default DatePopup;

DatePopup.defaultProps = {
  drivers: {},
  regions: {}
};

DatePopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired
};
