import styled from 'styled-components';
import Parent from '../Base/style';
const TabWrapper = styled(Parent)`
${({ theme }) => `
.MuiTab-wrapper{
    font-size: 14px;
    font-weight: 700;
}
.MuiTab-root:hover {
    border-bottom: 2px solid ${theme.colors.SECONDARY_MAIN} !important;
    .MuiTab-wrapper {
        color: #000;
    }
}
.MuiTabs-flexContainer .Mui-selected {
    border-bottom: 2px solid ${theme.colors.SECONDARY_MAIN};
}
.Mui-selected .MuiTab-wrapper {
    color: #000;
}
`}
`;
export default TabWrapper;
