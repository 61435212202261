import React, { useEffect, useState, lazy } from 'react';
import PropTypes from 'prop-types';

import Form from './Form';
import Styled from './style';
import Subscriptions from '../../../../admin/Subscriptions/index';
// import Radio from '../../../../../components/UI/Radio';
// import Payment from '../../../../../components/Payment';
import { hasValue } from '../../../../../utils/utils';

import CircularProgress from '@material-ui/core/CircularProgress';
// import {FormStyle}  from '../../../../../components/UI/Form/Clutch/serverStyle';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Button = lazy(() =>
  import(`../../../../../components/UI/Button/${layout}`)
);
const Checkbox = lazy(() =>
  import(`../../../../../components/UI/Checkbox/${layout}`)
);

const FormStyle = require(`../../../../../components/UI/Form/${layout}/serverStyle`);
const Component = ({
  apiStatus,
  requestForgotPassword,
  history,
  clearApiStatus,
  states,
  addCompanyInfo,
  getCompanyInfoApi,
  getCompanyInfo,
  checkoutDetails,
  getFleetManagerInfo,
  addCheckout,
  callback,
  companyInfo,
  addMoreTrucks,
  clearCompanyInfoValues,
  clearFleetInfoValues,
  clearTruckInfoValues
}) => {
  const apiId = 'addCompany';
  const hasApiStatus = hasValue(apiStatus.status, 'api', apiId);
  const [differentAddress, setdifferentAddress] = useState(false);
  const [subPerCost, setSubPerCost] = useState(null);
  const [subFilterOption, setSubFilterOption] = useState('Yearly');
  // const [totalCost, setTotalCost] = useState(null);
  const [totalBill, setTotalBill] = useState(null);
  const [strikeOut, setStrikeOut] = useState(null);
  const differentAddressFn = (event) => {
    setdifferentAddress(event);
  };
  useEffect(() => {
    !differentAddress
      ? (document.getElementById('shipppingAddress').style.display = 'none')
      : (document.getElementById('shipppingAddress').style.display = 'block');
  }, [differentAddress]);

  useEffect(() => {
    if (subFilterOption === 'Yearly') {
      setSubPerCost(checkoutDetails.subscriptionFeePerYear);
      // setTotalCost(checkoutDetails.currentVehicleCount * (5*12));
      setTotalBill(checkoutDetails.totalBillYearly);
      setStrikeOut(
        checkoutDetails.totalBillYearlyBasedOnMonthlySubscriptionFee
      );
    } else {
      setSubPerCost(checkoutDetails.subscriptionFeePerMonth);
      // setTotalCost(checkoutDetails.currentVehicleCount * 7);
      setTotalBill(checkoutDetails.totalBillMonthly);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subFilterOption]);
  const subFilter = (type, i) => {
    setButtons(
      buttons.map((items, index) => {
        if (index === i) return { ...items, active: true };
        else return { ...items, active: false };
      })
    );
    setSubFilterOption(type);
  };
  const addMoreFromCheckout = () => {
    addMoreTrucks();
    clearCompanyInfoValues({
      id: 'clearCompanyInfoValues'
    });
    clearFleetInfoValues({
      id: 'clearFleetInfoValues'
    });
    clearTruckInfoValues({
      id: ' clearTruckInfoValues'
    });
  };
  const [buttons, setButtons] = useState([
    {
      label: 'Monthly',
      active: false
    },
    {
      label: 'Yearly',
      active: true
    }
  ]);
  return (
    <Styled.Wrapper>
      <h1 className="stepTitle">Checkout</h1>
      <div className="mainWrapper">
        <div className="leftWrapper">
          <Styled.SubWrapper>
            <div className="selectSub">
              <div className="selectSubscription">Select Subscription</div>
              <div className="subBtnWrapper" id={'subBtnWrapper'}>
                <div className="buttonToggle">
                  {buttons.map((items, index) => {
                    return (
                      <Button
                        label={items.label}
                        className={`subFilterButton ${
                          items.active ? 'active' : ''
                        }`}
                        onClick={() => subFilter(items.label, index)}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="vehicleCount">
                <span className="countList">
                  Current Vehicle Count :{' '}
                  <span className="count">
                    {checkoutDetails.currentVehicleCount}
                  </span>
                </span>
                <Button
                  className="addMore"
                  label={'Add more'}
                  onClick={() => addMoreFromCheckout()}
                ></Button>
              </div>
              <Styled.FeeWrapper>
                <div className="perYearWrapper">
                  {' '}
                  <div>
                    {subFilterOption === 'Yearly'
                      ? 'Subscription Fee (per year) '
                      : 'Subscription Fee (per month) '}
                  </div>
                  <div className="yearSub">${subPerCost?.toFixed(2)}</div>
                </div>
                {subFilterOption === 'Yearly' ? (
                  <div className="setupWrapper">
                    <span className="settepText">Setup Fee</span>
                    <span className="setupFee yearly">
                      ${checkoutDetails?.setupFee?.toFixed(2)}
                    </span>
                  </div>
                ) : (
                  <div className="setupWrapper monthlyFee">
                    Setup Fee
                    <span className="setupFee">
                      ${checkoutDetails?.setupFee?.toFixed(2)}
                    </span>
                  </div>
                )}
              </Styled.FeeWrapper>
            </div>
          </Styled.SubWrapper>

          <Styled.ShippingWrapper>
            <div className="subHeading">Shipping Options</div>
            <div className="radioButtons">
              <Checkbox checked={true} onClick={() => {}} />
              <span className="checkbox-label">
                Standard shipping & Handling -{' '}
                <span>${checkoutDetails?.shippingFee?.toFixed(2)}</span> (3-5
                Business Days)
              </span>
            </div>
            <Checkbox
              type="NormalWhite"
              label={
                'Check here if shipping address is different than billing address'
              }
              checked={differentAddress}
              type="NormalWhite"
              onClick={(event) => {
                differentAddressFn(event);
              }}
              id="selectedAddress"
            />
          </Styled.ShippingWrapper>
          <div id="shipppingAddress">
            <Form
              apiStatus={apiStatus}
              requestForgotPassword={requestForgotPassword}
              history={history}
              clearApiStatus={clearApiStatus}
              states={states}
              addCompanyInfo={addCompanyInfo}
              getCompanyInfoApi={getCompanyInfoApi}
              getCompanyInfo={getCompanyInfo}
              getFleetManagerInfo={getFleetManagerInfo}
              addCheckout={addCheckout}
              differentAddress={differentAddress}
              totalBill={totalBill}
              subFilterOption={subFilterOption}
              checkoutDetails={checkoutDetails}
              callback={callback}
              companyInfo={companyInfo}
            />
          </div>
        </div>
        <div className="rightWrapper">
          <div className="paymentWrapper">
            <ul className="calculator">
              <li className="subscription">
                {' '}
                <div>
                  <span>
                    {subFilterOption === 'Yearly'
                      ? 'Yearly Subscription '
                      : 'Monthly Subscription '}
                  </span>
                  <span className="year">
                    <span>x</span>
                    {checkoutDetails.currentVehicleCount}
                  </span>
                </div>{' '}
                <span className="count">
                  $
                  {subFilterOption === 'Yearly'
                    ? checkoutDetails?.yearlySubscriptionFeeAllTrucks?.toFixed(
                        2
                      )
                    : checkoutDetails?.monthlySubscriptionFeeAllTrucks?.toFixed(
                        2
                      )}
                </span>
              </li>
              {subFilterOption === 'Monthly' && checkoutDetails.taxesMonthly ? (
                <li>
                  Taxes{' '}
                  <span className="count">
                    $ {checkoutDetails?.taxesMonthly?.toFixed(2)}
                  </span>
                </li>
              ) : subFilterOption === 'Yearly' &&
                checkoutDetails.taxesYearly ? (
                <li>
                  Taxes{' '}
                  <span className="count">
                    $ {checkoutDetails?.taxesYearly?.toFixed(2)}
                  </span>
                </li>
              ) : (
                ''
              )}
              <li>
                Shipping{' '}
                <span className="count">
                  ${checkoutDetails?.shippingFee?.toFixed(2)}
                </span>
              </li>
              <li className="setWrapper">
                {' '}
                <span
                  className={subFilterOption === 'Monthly' ? '.nodim' : 'dim'}
                >
                  Setup Fee
                </span>
                {subFilterOption === 'Monthly' ? (
                  <span className="setupFee">
                    {' '}
                    ${checkoutDetails?.setupFee?.toFixed(2)}
                  </span>
                ) : (
                  <span className="setupFee yearly">
                    ${checkoutDetails?.setupFee?.toFixed(2)}
                  </span>
                )}
              </li>
            </ul>
            {subFilterOption === 'Yearly' ? (
              <ul className="strikeOut">
                {' '}
                <span className="count">${strikeOut?.toFixed(2)}</span>
              </ul>
            ) : (
              ''
            )}
            <ul className="totalCalculator">
              <span>Total due today</span>{' '}
              <span className="amountTotal">$ {totalBill?.toFixed(2)}</span>
            </ul>
            {/* <Payment buttonLabel= {'Pay Now'} updateCard= {(data) => cardDetails(data)}/> */}
            <FormStyle.default.ButtonsWrapper className="registerButton">
              <Button
                type="submit"
                label={
                  hasApiStatus?.isLoading ? (
                    <CircularProgress color="inherit" />
                  ) : (
                    'Register'
                  )
                }
                onClick={() => {
                  document.getElementById('checkOutSubmit').click();
                }}
              />
            </FormStyle.default.ButtonsWrapper>
          </div>
        </div>
      </div>
    </Styled.Wrapper>
  );
};

export default Component;

Component.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  requestForgotPassword: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  clearApiStatus: PropTypes.func.isRequired
};
