import * as Base from '../base';
export const dashboard = [
  {
    name: 'pages/web/Dashboard/Header',
    props: ['dashboard'],
    commonProps: ['i18n', 'history', 'Styled'],
    headerText: 'dashboard.header.text'
  },
  {
    name: 'pages/web/Dashboard/Component',
    props: [
      'dashboard',
      'getDriverStatus',
      'refreshVehicleStatus',
      'refreshDriverStatus',
      'enableNotification',
      'clearAllAlerts',
      'clearAll',
      'getVehicleStatus',
      'refreshDriverStatusMap',
      'handleDeleteAlert',
      'viewReport'
    ],
    commonProps: ['Styled', 'i18n', 'history'],
    alternateComponent: false,
    mapRequired: false,
    iconsAsLink: true,
    accordion: true,

    dataList: [
      {
        id: 'alerts',
        nameKey: 'Compliance Notifications',
        value: null,
        defaultExpanded: true,
        disabled: true
      },
      {
        id: 'driverStatus',
        nameKey: 'dashboard.tabs.driverStatus.heading',
        value: null,
        dependency: '',
        defaultExpanded: true,
        disabled: true
      },
      {
        id: 'dailyLogs',
        nameKey: 'dashboard.tabs.dailyLogs.heading',
        value: null,
        defaultExpanded: true,
        disabled: true
      },
      {
        id: 'potentialViolations',
        nameKey: 'dashboard.tabs.potentialViolations.heading',
        value: null,
        dependency: '',
        defaultExpanded: true,
        disabled: true
      }
    ],
    driverStatusColumn: [
      {
        id: 'fullName',
        disablePadding: false,
        label: 'vehicles.fullName'
      },
      {
        id: 'lastKnownLocation',
        numeric: false,
        disablePadding: false,
        label: 'dashboard.tabs.driverStatus.lastKnownLocation'
      },
  
      {
        id: 'unitName',
        numeric: true,
        disablePadding: false,
        label: 'dashboard.tabs.driverStatus.unit'
      },
      {
        id: 'driveTimeRemaining',
        disablePadding: false,
        label: 'dashboard.tabs.driverStatus.driveTimeRemaining'
      },
      {
        id: 'timeUntilBreak',
        disablePadding: false,
        label: 'dashboard.tabs.driverStatus.timeUntilBreak'
      },
      {
        id: 'onDutyTimeRemaining',
        numeric: false,
        disablePadding: false,
        label: 'dashboard.tabs.driverStatus.onDutyTimeRemaining'
      },
  
      {
        id: 'dutyCycleRemaining',
        numeric: true,
        disablePadding: false,
        label: 'dashboard.tabs.driverStatus.dutyCycleRemaining'
      },
      {
        id: 'currentStatus',
        disablePadding: false,
        label: 'dashboard.tabs.driverStatus.currentStatus'
      },
      {
        id: 'lastUpdated',
        numeric: true,
        disablePadding: false,
        label: 'dashboard.tabs.driverStatus.lastUpdated'
      },
      {
        id: 'eld',
        numeric: false,
        disablePadding: false,
        label: 'dashboard.tabs.driverStatus.eld'
      }
    ]
  }
];
export const archiveLogsList = Base.archiveLogsList;

export const userList = Base.userList;
export const vehicleDTCView = Base.vehicleDTCView;
export const MFAPage = Base.MFAPage;
export const recovery = Base.recovery;
export const driverStatusViewMore = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'dashboard.tabs.driverStatus.heading'
  },
  {
    name: 'pages/web/DriverStatusViewMore/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'fetchDriverStatus',
      'list',
      'driverStatusList',
      'getDriverStatus',
      'refreshDriverStatusMap',
      'driverStatusFilteredList',
      'filterSuccess',
      'changeRequest',
      'pagination',
      'setPagination'
    ],
    commonProps: ['Styled', 'i18n', 'history', 'theme'],
    title: 'component',
    topAction: true,
    mapRequired: false
  }
];
export const potentialViolationsViewMore = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'potentialViolations.pageHeading',
    vertical: true,
    children: {
      name: 'components/modules/DataTableWithSearchExport/TopActions',
      props: [
        'filterSuccess',
        'columns',
        'exportAllData',
        'list',
        'exportCsvData',
        'exportPdfData',
        'logBook',
        'pagination',
        'setPagination'
      ],
      commonProps: ['i18n'],
      title: 'topActions',
      apiIds: {
        export: 'exportAllPotentialViolationsData',
        list: 'potentialViolations',
        delete: 'deletePotentialViolations'
      },
      fileName: 'Violations.pdf',
      needSearch: false,
      needFilter: true,
      needExportAll: true,
      isOldView: true
    }
  },
  {
    name: 'components/modules/DataTableWithSearchExport/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'list',
      'callback',
      'filterSuccess',
      'exportAllData',
      'exportCsvData',
      'exportPdfData',
      'deleteData',
      'logBook',
      'getDocumentToken',
      'columns',
      'pagination',
      'setPagination',
      'noAction'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled', 'emProps'],
    title: 'component',
    apiIds: {
      export: 'exportAllPotentialViolationsData',
      list: 'potentialViolations',
      delete: 'deletePotentialViolations'
    },
    fileName: 'Violations.pdf',
    needSearch: false,
    needFilter: true,
    needExportAll: true,
    isOldView: true,
    isspOld: true
  }
];
export const alertViewMore = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'common.alerts',
    vertical: true,
    children: {
      name: 'components/modules/DataTableWithSearchExport/TopActions',
      props: [
        'filterSuccess',
        'columns',
        'exportAllData',
        'list',
        'exportCsvData',
        'exportPdfData',
        'logBook',
        'getDocumentToken',
        'setPagination'
      ],
      commonProps: ['i18n'],
      title: 'topActions',
      needSearch: false,
      needFilter: true,
      needExportAll: true,
      fileName: 'Alerts.pdf',
      isOldView: true,
      apiIds: {
        export: 'exportAllAlertData',
        list: 'alertList',
        delete: 'deleteAlerts'
      }
    }
  },
  {
    name: 'components/modules/DataTableWithSearchExport/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'list',
      'callback',
      'columns',
      'logbook',
      'pagination',
      'setPagination',
      'deleteData',
      'noAction'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled', 'emAlerts'],
    title: 'component',
    apiIds: {
      export: 'exportAllAlertData',
      list: 'alertList',
      delete: 'deleteAlerts'
    },
    fileName: 'Alerts.pdf',
    needSearch: false,
    needFilter: true,
    needExportAll: true,
    topAction: false,
    isOldView: true
  }
];
// export const alertViewMore = Base.alertViewMore;
export const logsList = Base.logsList;
export const logsUdList = Base.logsUdList;

export const logsHistory = Base.logsHistory;
export const unhandled = Base.unhandled;
export const subscriptionMessage = Base.subscriptionMessage;
export const logsEdit = Base.logsEdit;
export const login = Base.login;
export const policyEdit = Base.policyEdit;
export const customDvirEnabled = Base.customDvirEnabled;
export const addOrEditNewForm = Base.addOrEditNewForm;
export const addOrEditNewFormTrailer = Base.addOrEditNewFormTrailer;
// export const potentialViolationsViewMore = Base.potentialViolationsViewMore;

export const inspections = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'inspections.listHeading'
  },
  {
    name: 'pages/web/Inspections/Component',
    props: [
      'apiStatus',
      'callback',
      'fetchInspections',
      'bulkDownloadReport',
      'inspections',
      'list',
      'changeRequest',
      'isAdvancedDviRequired',
      'isCustomDviRequired',
      'logBook',
      'clearApiStatus',
      'getDocumentToken',
      'filters',
      'viewReport',
      'request',
      'setPagination',
      'pagination'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    type: 'inspections',
    topAction: true,
    needSort: true
  }
];

export const logBook = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'logs.logBook'
  },
  {
    name: 'pages/web/LogBook/Component',
    props: [
      'apiStatus',
      'logBook',
      'callback',
      'handleLogBookStatus',
      'clearApiStatus',
      'breadCrumbForMap',
      'getLogbookEvents',
      'fetchLogBookView',
      'updateShowEvents',
      'dailyLogDownloadData',
      'changedDate',
      'fetchLogbookBreadcrumb',
      'getDocumentToken',
      'formValues',
      'setFormValues',
      'currentDriver',
      'dates',
      'unSignedDates',
      'drivers',
      'newDate',
      'setNewDate',
      'regeneratePdf'
    ],
    title: 'component',
    topAction: true,
    commonProps: ['Styled', 'i18n', 'history', 'theme']
  }
];
export const vehicleList = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'vehicles.listHeading',
    children: {
      name: './TopActions',
      props: [
        'vehicles',
        'callback',
        'filterSuccess',
        'filterBoth',
        'handleExportTrailer',
        'handleExportVehicle',
        'handleImportVehiclePost',
        'handleImportTrailerPost',
        'logBook',
        'setPagination',
        'filters'
      ],
      commonProps: ['Styled', 'history', 'i18n'],
      title: 'topActions'
    }
  },
  {
    name: 'pages/web/Vehicles/OverRideComponent',
    props: [
      'vehicles',
      'apiStatus',
      'fetchVehicles',
      'handleDeleteAlert',
      'callback',
      'filterSuccess',
      'filterBoth',
      'handleExportTrailer',
      'handleExportVehicle',
      'handleImportVehicle',
      'handleImportTrailer',
      'handleImportVehiclePost',
      'handleImportTrailerPost',
      'fetchVehiclePageHistoryMap',
      'vehiclePageMap',
      'openDocument',
      'getDrivers',
      'trailers',
      'fetchTrailers',
      'fetchVehiclePageMap',
      'logBook',
      'handleTrailerDeleteAlert',
      'filters'
    ],
    commonProps: ['Styled', 'i18n', 'history', 'theme'],
    title: 'component',

    columns: [
      {
        id: 'full_name',
        numeric: false,
        disablePadding: true,
        label: 'vehicles.fullName',
        needSorting: true
      },
      {
        id: 'role',
        numeric: false,
        disablePadding: false,
        label: 'vehicles.role',
        needSorting: false
      },
      {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: 'vehicles.email',
        needSorting: true
      },
      {
        id: 'empId',
        numeric: true,
        disablePadding: false,
        label: 'vehicles.empId',
        needSorting: true
      },
      {
        id: 'phone',
        numeric: true,
        disablePadding: false,
        label: 'common.phone',
        needSorting: false
      },
      {
        id: 'assigned_vehicles',
        numeric: false,
        disablePadding: false,
        label: 'vehicles.assignedVehicles',
        needSorting: false
      },
      {
        id: 'document',
        label: 'common.documents'
      },
      {
        id: 'last_login',
        numeric: false,
        disablePadding: false,
        label: 'vehicles.lastLogin',
        needSorting: false
      },
      {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
        needSorting: true
      },
      {
        id: 'eld',
        numeric: false,
        disablePadding: false,
        label: 'ELD',
        needSorting: false
      },
      {
        id: 'actions',
        label: 'Actions'
      }
    ]
  }
];
export const accidentsList = Base.accidentsList;
export const advancedDvirEnabled = Base.advancedDvirEnabled;
export const editUser = Base.editUser;
export const addUser = Base.addUser;
export const driverStatusHistory = Base.driverStatusHistory;
export const editVehicle = Base.editVehicle;
export const addVehicle = Base.addVehicle;
export const companyDataEdit = Base.companyDataEdit;
export const profile = Base.profile;
export const reportsEnabled = Base.reportsEnabled;
export const privacyPolicyScreen = Base.privacyPolicyScreen;
export const resetPassword = Base.resetPassword;
export const viewUser = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'users.editHeading'
  },
  {
    name: 'components/modules/UserManagement/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'fetchUserData',
      'user',
      'saveDocument',
      'saveUserData',
      'deleteDocument',
      'handleDownloadDoc',
      'downloadDocument',
      'logBook',
      'getDocumentToken',
      'accessLevel',
      'fetchDepartments',
      'fetchSubDepartments',
      'fetchRegions',
      'fetchTerminals',
      'handleLogout',
      'removeApiStatus',
      'getDrivers',
      'downloadUrl',
      'receipt'
    ],
    commonProps: ['Styled', 'i18n', 'history', 'theme'],
    title: 'component',
    type: 'edit',
    viewOnly: true
  }
];

export const logsView = [
  {
    name: 'components/Title',
    props: ['apiStatus', 'dailyLogDetails'],
    commonProps: ['i18n', 'Styled'],
    header: 'Edit',
    children: {
      name: '',
      props: [],
      commonProps: ['i18n'],
      title: 'topActions'
    }
  },
  {
    name: 'pages/web/EditDailyLog/Component',
    props: [
      'apiStatus',
      'dailyLogDetails',
      'setDailyLogEntries',
      'saveDailyLogDetails'
    ],
    commonProps: ['Styled', 'i18n', 'history'],
    viewOnly: true
  }
];

export const signedLogsView = [
  {
    name: 'components/Title',
    props: ['apiStatus', 'dailyLogDetails'],
    commonProps: ['i18n', 'Styled'],
    header: 'Edit',
    children: {
      name: '',
      props: [],
      commonProps: ['i18n'],
      title: 'topActions'
    }
  },
  {
    name: 'pages/web/EditDailyLog/Component',
    props: [
      'apiStatus',
      'dailyLogDetails',
      'setDailyLogEntries',
      'saveDailyLogDetails'
    ],
    commonProps: ['Styled', 'i18n', 'history'],
    viewOnly: true
  }
];
