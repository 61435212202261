import styled from 'styled-components';

const Wrapper = styled.div`
  ${({ theme, selectedindex, leftPosition, selectedType }) => `
  position: absolute;

  background: ${
    (theme.palette.primary || {}).main || theme.colors.PRIMARY_MAIN
  };
  border: solid 2px
    ${(theme.palette.primary || {}).border || theme.colors.PRIMARY_BORDER};
  top: ${selectedindex ? selectedindex + '%' : '15.2%'};
  left: ${leftPosition ? leftPosition : '280px'};
  li {
    list-style: none;
    margin: 0 0 5px;
  }
  a {
    color: ${theme.colors.GREEN_TWO} !important;
    font-size: 23px;
    text-transform: capitalize;
  }
  .ToolsOptionClose {
    color: ${
      selectedType === 'full' ? theme.colors.GREEN_TWO : theme.colors.RED_TWO
    } !important;
    margin-top: 10px;
    width: 100%;
    background-position: right;
    right: 10px;
  }

  ul {
    min-height: 35px;
    padding-inline-start: 15px;
    width: 300px;
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding: 0 6% 6% 6%;

    @media (max-width: ${theme.breakpoints.values.md}px) {
      width: 150px;
    }
  }
  cursor: pointer;
  audio {
    width: 90%;
    height: 30px;
  }
  p {
    color: ${theme.palette.secondary.main};
  }
  `}
`;

const Styled = { Wrapper };

export default Styled;
