export const authApi = {
  url: 'api/login',
  type: 'post'
};

export const getCompaniesApi = {
  url: 'api/users?page=2',
  type: 'get'
};
export const searchApi = {
  url: 'api/unknown',
  type: 'get'
};

export const getSubscriptions1 = {
  url: 'getSubscriptions',
  type: 'get'
};
