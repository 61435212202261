import React, { lazy } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';
import { getDateLocale } from '../../../../utils/utils'
import {
  UI_DATE_FORMAT,
  UI_DATE_FORMAT_WITH_TIME
} from '../../../../config/constants/static';
import CalendarToday from '@material-ui/icons/CalendarToday';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Input = lazy(() => import(`../../Input/${layout}`));
const Label = lazy(() => import(`../../Label/${layout}`));
const Checkbox = lazy(() => import(`../../Toggle/${layout}`));
const Button = lazy(() => import(`../../Button/${layout}`));
const Select = lazy(() => import(`../../Select/${layout}`));
const Form = ({
  direction,
  handleOnSubmit,
  setValues,
  handleOnCancel,
  fields,
  values,
  className,
  Styled
}) => {
  const [isDate, setIsDate] = React.useState({});
  //Handle text and date input data from UI
  const handleEdit = (event, field) => {
    let value = event;
    if (event !== null && typeof event === 'object' && !event.value) {
      value = field.isTimeNeeded
        ? moment(event).format(UI_DATE_FORMAT_WITH_TIME)
        : moment(event).format(UI_DATE_FORMAT);
    }
    setValues({ ...values, [field]: value });
    let obj = isDate;
    obj[field] = event;
    setIsDate(obj);
  };
  const handleEditDropDown = (event, field) => {
    // setFieldValue(field, event);
    setValues({ ...values, [field]: event });
  };

  const generateFields = (value) => {
    let html = [];
    const items = (
      <Styled.FieldsWrapper
        key={value.field}
        type={value.type}
        width={value.width ? value.width : 'auto'}
      >
        {value.type !== 'checkbox' &&
          value.type !== 'reset' &&
          value.type !== 'submit' && (
            <Label variant="body1">{value.label}</Label>
          )}
        {value.type === 'text' ? (
          <Input
            name={value.field}
            handleOnChange={(event) => handleEdit(event, value.field)}
            placeholder={value.placeholder ? value.placeholder : ''}
            value={value.value ? value.value : values[value.field]}
            type={value.type}
            autocomplete="off"
            disabled={value.disabled}
            id={value.id}
          />
        ) : value.type === 'checkbox' ? (
          <div className="checkboxWrapper">
            <Checkbox label={value.label} />
          </div>
        ) : value.type === 'select' ? (
          <Select
            name={value.field}
            onChange={(event) => handleEditDropDown(event, value.field)}
            as={Select}
            placeholder={value.placeholder ? value.placeholder : ''}
            value={values[value.field]}
            type={value.type}
            suggestions={value.suggestions}
            isMulti={value.multiple}
            isClearable={true}
            allowSelectAll={value.allowSelectAll ? value.allowSelectAll : false}
          />
        ) : value.type === 'date' ? (
          <>
            <DatePicker
              name={value.field}
              onChange={(event) => {
                handleEdit(event, value.field);
              }}
              // onKeyDown={(event) => {
              //   const c = event.key;
              //   handleEdit(c, value.field);
              // }}
              as={DatePicker}
              locale={getDateLocale()}
              placeholderText={value.placeholder ? value.placeholder : ''}
              value={values[value.field]}
              type={value.type}
              showPopperArrow={false}
              showTimeSelect={value.isTimeNeeded}
              minDate={
                value.field === 'dateTo' && isDate.dateFrom && isDate.dateFrom
              }
              maxDate={
                value.field === 'dateFrom' && isDate.dateTo
                  ? isDate.dateTo
                  : new Date()
              }
              showDisabledMonthNavigation
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              isClearable={(values[value.field] && window.$environment.SERVICE_PROVIDER !== 'spireon') ? true : false}
              selected={isDate[value.field] ? isDate[value.field] : null}
            />
            {window.$environment.CURRENT_LAYOUT === 'SpireonNew' && (
              <CalendarToday />
            )}
          </>
        ) : value.type === 'submit' || value.type === 'reset' ? (
          <Button
            type={value.type}
            label={value.value}
            onClick={(e) => {
              if (value.type === 'submit') {
                handleOnSubmit();
              } else if (value.type === 'reset') {
                const datePicker = document.getElementsByClassName(
                  'react-datepicker__close-icon'
                );

                for (let i = 0; i < datePicker.length; i++) {
                  const item = datePicker[i];
                  item.click();
                }
                setIsDate({});
                handleOnCancel();
              }
            }}
          />
        ) : null}
      </Styled.FieldsWrapper>
    );
    html.push(items);

    return html;
  };
  return (
    <Styled.FormWrapper direction={direction} className={className}>
      {fields.map((data) => generateFields(data))}
    </Styled.FormWrapper>
  );
};

export default Form;
