import styled from 'styled-components';
import Parent from './style';
const Wrapper = styled(Parent.Wrapper)`

`;
const TopWrapper = styled(Parent.TopWrapper)` 
`;
const IconWrapper = styled(Parent.IconWrapper)`
${({ theme }) => `
   
`}
`;

const ImpExpWrapper = styled(Parent.ImpExpWrapper)`
`;

const SearchWrapper = styled(Parent.SearchWrapper)`

`;

const ButtonsWrapper = styled(Parent.ButtonsWrapper)`
${({ theme }) => `
.userBtnWrapper {
    padding-left: 22px;
    .MuiButton-root{
        font-size: 11px !important;
    }
}
`}
`;

const DocContainer = styled(Parent.DocContainer)`

`;
const TableWrapper = styled(Parent.TableWrapper)`
.MuiTable-root .MuiTableBody-root .MuiTableCell-root:last-child {
    padding-left: 0 !important;
    .iconContainer .MuiAvatar-root {
        width: 15px !important;
        height: 18px !important;
    }
}
`;
const Styled = {
  Wrapper,
  IconWrapper,
  ImpExpWrapper,
  SearchWrapper,
  TopWrapper,
  ButtonsWrapper,
  TableWrapper,
  DocContainer
};
export default Styled;
