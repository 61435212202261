const isEs = () => localStorage.getItem(window.$environment?.SERVICE_PROVIDER + 'Language') == 'es'

export const UI_DATE_LOWER_FORMAT =
  window?.$environment?.CURRENT_LAYOUT === 'Clutch'
    ? isEs () ? 'MM/DD/AAAA' :'MM/DD/YYYY'
    : 'mm/dd/yyyy';
export const UI_DATE_FORMAT = 'MM/DD/YYYY';
export const BACKEND_DATE_FORMAT = 'YYYY-MM-DD';
export const UI_DATE_FORMAT_WITH_TIME_24 = 'MM/DD/YYYY HH:mm:ss';
export const UI_DATE_FORMAT_WITH_TIME = 'MM/DD/YYYY HH:mm';
export const UI_DATE_FORMAT_WITH_TIME_12 = 'MM/DD/YYYY hh:mm a';
export const UI_DATE_FORMAT_WITH_TIME_12_V = 'MM/dd/yyyy hh:mm a';
export const UI_LOGBOOK_DATE = 'YYYY-MM-DD';
export const FORGOT_PASSWORD_MESSAGE = 'If your email address is registered with us, you will receive a password recovery link at your email address.';
export const PASSWORD_RESET_MESSAGE = 'Your profile was saved';
export const UI_DATE_FORMAT_WITH_TIME_UTC = 'MM/DD/YYYY hh:mm A';
