import React, { lazy, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { imageUrls, pageUrls, ROLES } from '@/config/constants/keys';
import TopActions from './TopActions';
import { getImagePath, hasRestrictedRole } from '@/utils/utils';
const layout = window?.$environment?.CURRENT_LAYOUT;

const Modal = require(`../../../components/UI/Modal/${layout}`).default;
const Breadcrumb = lazy(() => import(`../../../components/Breadcrumb`));
const GoogleMap = lazy(() => import(`@/components/GoogleMap/${layout}`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Link = lazy(() => import(`../../../components/UI/Link`));

const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Breadcrumb filters={filters} />
      <Component {...contentProps} />
    </Box>
  );
};
export default Index;

const Component = ({
  list,
  changeRequest,
  i18n,
  fetchDriverStatus,
  mapRequired,
  Styled,
  getDriverStatus,
  getLatestLog,
  latestLogUrlFlg,
  driverStatusList,
  driverStatusFilteredList,
  topAction,
  history,
  filterSuccess,
  pagination,
  setPagination,
  request,
  setNumberPerPage,
  numberPerPage
}) => {
  // React.useEffect(() => {
  //   getDriverStatus({
  //     apiId: 'getDriverStatus',
  //     data: { isDashboard: false }
  //   });
  // }, []);
  const [modal, setModal] = useState(false)
  const columns = [
    {
      id: 'name',
      disablePadding: false,
      label: i18n.t('vehicles.fullName')
    },
    {
      id: 'lastKnownLocation',
      numeric: false,
      disablePadding: false,
      label: i18n.t('dashboard.tabs.driverStatus.lastKnownLocation')
    },

    {
      id: 'unitName',
      numeric: true,
      disablePadding: false,
      label: i18n.t('dashboard.tabs.driverStatus.unit')
    },
    {
      id: 'driveTimeRemaining',
      disablePadding: false,
      label: i18n.t('dashboard.tabs.driverStatus.driveTimeRemaining')
    },
    {
      id: 'timeUntilBreak',
      disablePadding: false,
      label: i18n.t('dashboard.tabs.driverStatus.timeUntilBreak')
    },
    {
      id: 'onDutyTimeRemaining',
      numeric: false,
      disablePadding: false,
      label: i18n.t('dashboard.tabs.driverStatus.onDutyTimeRemaining')
    },

    {
      id: 'dutyCycleRemaining',
      numeric: true,
      disablePadding: false,
      label: i18n.t('dashboard.tabs.driverStatus.dutyCycleRemaining')
    },
    {
      id: 'currentStatus',
      disablePadding: false,
      needSorting: layout === 'SpireonNew' ? true : false,
      label: i18n.t('dashboard.tabs.driverStatus.currentStatus')
    },
    {
      id: 'engineSpeed',
      numeric: false,
      disablePadding: false,
      label: i18n.t('dashboard.tabs.driverStatus.engineSpeed')
    },

    {
      id: 'lastUpdated',
      numeric: true,
      disablePadding: false,
      label: i18n.t('dashboard.tabs.driverStatus.lastUpdated')
    },
    ...(window.$environment.SERVICE_PROVIDER === 'spireon' ? [{
      id: 'eld',
      numeric: false,
      disablePadding: false,
      label: i18n.t('ELD'),
      needSorting: false
    }] : [])
  ];
  const serverProvider = window.$environment.SERVICE_PROVIDER;
  const information = i18n.t('common.tableEmptyMessage');
  const isMapRequired = mapRequired === undefined || mapRequired;
  const getELDStatusIcon = (data) => {
    if (data === 'NOT_STARTED' || data === 'TAMPERED' || data === 'DISCONNECTED') {
      return "eld-inActive";
    } else if (data === 'CONNECTED') {
      return "eld-active";
    } else if (data === '') {
      return "eld-loggedOut"
    } else {
      return ""
    }
  }
  const generateRows = () => {
    const updatedList = driverStatusFilteredList.map((item, index) => {
      const rowItem = { ...item };
      if (window.$environment.SERVICE_PROVIDER === 'spireon') {
        const operation = hasRestrictedRole([ROLES.VIEW_ONLY_FM]) ? 'view' : 'edit';
        rowItem.name = (
          <Link
            href={window.location.origin + (window.$environment.CURRENT_LAYOUT === 'SpireonOld' ? '' : '/spireon') + `/user/${operation}/` + rowItem.driverId}
          >
            {rowItem.name}
          </Link>
        );
        rowItem.eld = window.$environment.CURRENT_LAYOUT === 'SpireonOld' ? <div className={getELDStatusIcon(rowItem.eldState)} /> :
          <a onClick={() => {
            if (rowItem.dailyLogUrl)
              history.push(rowItem.dailyLogUrl.replace('.', '/spireon'))
            else setModal(true)
          }}><div className={getELDStatusIcon(rowItem.eldState)} /></a>;
      }
      else {
        rowItem.name = (
          <Link
            href={window.location.origin + '/user/edit/' + rowItem.driverId}
            newPage
          >
            {rowItem.name}
          </Link>
        );
      }
      rowItem.lastKnownLocation =
        rowItem.unitId && window.$environment.SERVICE_PROVIDER !== 'spireon' ? (
          <Link
            href={
              window.location.origin + '/vehicle/history?id=' + rowItem.unitId
            }
            newPage
          >
            {rowItem.lastKnownLocation}
          </Link>
        ) : (
          rowItem.lastKnownLocation
        );
      rowItem.unitName =
        rowItem.unitId && window.$environment.SERVICE_PROVIDER !== 'spireon' ? (
          <Link
            href={window.location.origin + '/vehicle/edit/' + rowItem.unitId}
            newPage
          >
            {rowItem.unitName}
          </Link>
        ) : (
          rowItem.unitName
        );

      rowItem.currentStatus =
        rowItem.dailyLogId &&
          window.$environment.SERVICE_PROVIDER !== 'spireon' ? (
          <Link
            href={window.location.origin + '/logs/view/' + rowItem.dailyLogId}
            newPage
          >
            {rowItem.currentStatus}
          </Link>
        ) : (
          rowItem.currentStatus
        );

      rowItem.engineSpeed =
        rowItem.engineSpeed === '0.0' || rowItem.engineSpeed === '0' ? (
          window.$environment.SERVICE_PROVIDER !== 'spireon' ? (
            <Link
              href={
                window.location.origin +
                '/driverStatus/history?id=' +
                rowItem.driverId
              }
              newPage
            >
              IDLE
            </Link>
          ) : (
            'IDLE'
          )
        ) : (
          rowItem.engineSpeed
        );

      return rowItem;
    });
    return updatedList;
  };
  return (
    <>
    <Modal
      open={modal}
      setOpen={() => setModal(m => !m)}
      withCloseIcon
      alignItemsLeft
      noDataPopup
      data={{
        header: '',
        content: () => <div >No Logs available</div>
      }}
    />
      <Styled.Wrapper>
        {topAction && window.$environment.CURRENT_LAYOUT !== 'SpireonNew' && (
          <TopActions
            {...{
              Styled,
              driverStatusList,
              changeRequest,
              filterSuccess,
              setPagination,
              request,
              i18n
            }}
          />
        )}
        <Styled.MapWrapper>
          {isMapRequired && (
            <GoogleMap
              type={'driverStatusMap'}
              height={'400px'}
              data={driverStatusList}
            />
          )}
        </Styled.MapWrapper>
        <Styled.TableWrapper>
          <div className="driverstatusTitle">
            <span className="spireonOldOnly">
              {i18n.t('dashboard.tabs.driverStatus.heading')}
            </span>
            <Icon
              src={getImagePath(imageUrls.refreshIcon)}
              onClick={() =>
                changeRequest({ ...request, displayLength: numberPerPage })
              }
            />
            <span className="tooltiptext">{i18n.t('common.refresh')}</span>
          </div>
          <Table
            type="server"
            changeRequest={changeRequest}
            baseRequest={request}
            title=""
            data={generateRows()}
            columns={serverProvider === 'spireon' ? columns.filter((item) => item.id !== 'engineSpeed') : [...columns]}
            emptyMessage={information}
            totalRecords={list?.totalElements}
            actions={[]}
            i18n={i18n}
            pageinationReset={pagination}
            pagenationClicked={(e) => {
              setPagination(!e);
            }}
            itemsPerPage={parseInt(numberPerPage, 10)}
            outPageCountValue={setNumberPerPage}
          />
        </Styled.TableWrapper>
      </Styled.Wrapper>
    </>
  );
};

Component.propTypes = {
  list: PropTypes.object.isRequired,
  filterSuccess: PropTypes.func.isRequired
};
