import { lazy } from 'react';
import styled from 'styled-components';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Label = lazy(() => import(`../UI/Label/${layout}/style`));

const Wrapper = styled.div`
  ${({ theme }) => `
  .MuiPaper-root {
    background: ${theme.palette.primary.main}
      none repeat scroll 0 0;
    border: 2px solid
      ${theme.palette.primary.light};
    margin-bottom: 15px;
    border: none;
  }
  .MuiCollapse-wrapperInner {
    background-color: ${theme.colors.BLACK};
  }
  .MuiButtonBase-root.MuiAccordionSummary-root,
  .MuiExpansionPanelSummary-root.Mui-expanded, .MuiAccordionSummary-content {
    height: 40px;
  }
  .MuiExpansionPanelSummary-root.Mui-expanded, h2 {
    margin-top: 7px;
  }
  .MuiExpansionPanelSummary-root,
  .MuiExpansionPanelSummary-root.Mui-expanded {
    min-height: initial;
  }
  .MuiExpansionPanelSummary-root {
    width: 100%;
    background: ${theme.palette.secondary.main}
      none repeat scroll 0 0;
    border: 2px solid
      ${theme.palette.primary.light};
      opacity: 1 !important;
  }
  .MuiExpansionPanelDetails-root {
    background: ${theme.colors.WHITE}
      none repeat scroll 0 0;
    border: 2px solid
      ${theme.palette.primary.light};
  }
  `}
`;

const Header = styled(Label)`
  ${({ theme, variant }) => `
  variant: ${variant || 'h2'};
  color: ${theme.palette.secondary.contrastText}!important
  `}
`;

const Content = styled.div`
  ${({ theme }) => `
  color: white;
  width: 100%;
  .MuiPaper-root {
    background: ${theme.palette.primary.main}
      none repeat scroll 0 0;
    border: 2px solid
      ${theme.palette.primary.light};
  }
  `}
`;

const Accordion = { Wrapper, Header, Content };
export default Accordion;
