import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
  form {
    padding: 0;
    .MuiInputBase-input.Mui-disabled{
      color: rgb(69, 85, 96);
      opacity: 1;
    }
    .select--is-disabled{
      opacity:1;
    }
    .normalCheckbox {
        span{
            ${window?.$environment?.clutchNewUI && 'color: #455560 !important;'}
        }
      svg {
        width: ${window?.$environment?.clutchNewUI ? '26px' : '19px'};
        height: ${window?.$environment?.clutchNewUI ? '26px' : '19px'};
      }
    }
    .normalCheckbox .Checkbox.unchecked .square {
      ${window?.$environment?.clutchNewUI &&
      `
        fill-opacity: 1;
        fill: #f0f2f2;
        stroke: #d1d3d6;
        `}
    }
    .normalCheckbox .Checkbox.checked .square {
      ${window?.$environment?.clutchNewUI &&
      `
        fill-opacity: 1;
        fill: #bdd731;
        stroke: #d1d3d6;`}
    }
    .normalCheckbox svg path {
      ${window?.$environment?.clutchNewUI &&
      `
        stroke: #fff;`}
    }
  }
  #companyInfoSubmit {
    min-width: 118px;
  }
  .companyTitle {
    padding: 11px 20px !important;
    text-transform: capitalize;
  }
  .sub-section-header{
    background: none !important;
    padding-left: 0 !important;
    font-size: 14px !important;
    font-weight: bold;
  }
  .buttonSection {
    display: flex;
    justify-content: flex-end;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS styles go here */
      padding-bottom: 1%;
    }
  }
  .companyInfoSubmit {
    margin-right: 1%;
  }
  .section-wrapper,
  .cycleruleSection {
    ${window?.$environment?.clutchNewUI &&
    `
        background-color: #fff;
        padding: 2% 3%;
        margin-bottom: 30px;
        border-radius: 5px;
    `}
    .subheaderSection {
      ${window?.$environment?.clutchNewUI && `padding-left: 0 !important;`}
    }
  }
`;
const leftSection = styled(Parent.leftSection)``;
const rightSection = styled(Parent.rightSection)``;
const TextWrapper = styled(Parent.TextWrapper)`
  ${({ theme }) => `
    font-size: 16px;
    color: ${theme.colors.WHITE};
    .MuiInputBase-root{
      pointer-events: none;
    }
`}
`;

const FieldsWrapper = styled(Parent.FieldsWrapper)`
  padding-bottom: 2%;
  .MuiFormLabel-root {
    font-size: 14px;
    line-height: 1.4;
  }
`;
const wrapperSection = styled(Parent.wrapperSection)`
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    min-height: 100%;
  }
`;

const InsuranceCompanyWrapper= styled(Parent.InsuranceCompanyWrapper)`
`;

const Styled = {
  Wrapper,
  TextWrapper,
  FieldsWrapper,
  leftSection,
  rightSection,
  wrapperSection,
  InsuranceCompanyWrapper
};

export default Styled;
