import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import React, { lazy, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ViewForm from './Form';

import { imageUrls, pageUrls } from '@/config/constants/keys';
import { UI_LOGBOOK_DATE } from '@/config/constants/static';
import { getImagePath, getLastQuaters, hasValue } from '@/utils/utils';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Link = lazy(() => import(`../../../components/UI/Link`));

const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Icon = lazy(() => import(`../../../components/UI/Icon/${layout}`));
const Modal = require(`@/components/UI/Modal/${layout}`).default;
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));

const Index = ({ theme, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Component {...contentProps} />
    </Box>
  );
};
export default Index;

const Component = ({
  apiStatus,
  history,
  Styled,
  fetchIFTAStates,
  iftaList,
  vehicles,
  callback,
  states,
  apiIftaMileage,
  commonProps,
  getDocumentToken,
  getDocumentTokenApiId,
  handleDeleteAlert,
  request,
  changeRequest,
  ...rest
}) => {
  const { i18n } = useTranslation();

  // state 
  const [hideStates, setHideStates] = useState(true);
  const [pagination, setPagination] = useState(false);

  const hasApiStatus = hasValue(apiStatus.status, 'api', fetchIFTAStates);

  const information =
    hasApiStatus && hasApiStatus.errorMessage ? (
      <Message type="error" s message={i18n.t(hasApiStatus.errorMessage)} />
    ) : (
      i18n.t('common.tableEmptyMessage')
    );
  useEffect(() => {
    // filterList(hideStates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideStates]);
  const lastQuater = getLastQuaters(true);

  const [values, setValues] = useState({
    id: '',
    fromOdometer: 'true',
    dateTimeFrom: lastQuater[0].format(UI_LOGBOOK_DATE),
    dateTimeTo: lastQuater[1].format(UI_LOGBOOK_DATE)
  });

  const columns = [
    {
      id: 'date',
      numeric: false,
      disablePadding: true,
      label: i18n.t('common.date'),
      needSorting: true
    },
    {
      id: 'unitId',
      numeric: false,
      disablePadding: true,
      label: i18n.t('vehicles.unitId'),
      needSorting: false
    },
    {
      id: 'vin',
      numeric: false,
      disablePadding: true,
      label: i18n.t('vehicles.vin'),
      needSorting: false
    },
    {
      id: 'gpsStart',
      numeric: false,
      disablePadding: true,
      label: i18n.t('iftaMileage.gpsBegins'),
      needSorting: false
    },
    {
      id: 'gpsEnd',
      numeric: false,
      disablePadding: true,
      label: i18n.t('iftaMileage.gpsEnds'),
      needSorting: false,
    },
    {
      id: 'state',
      numeric: false,
      disablePadding: true,
      label: i18n.t('iftaMileage.Jurisdiction'),
      needSorting: false
    },
    {
      id: 'city',
      numeric: false,
      disablePadding: true,
      label: i18n.t('common.city'),
      needSorting: false
    },
    {
      id: 'startMeter',
      numeric: false,
      disablePadding: true,
      label: i18n.t('iftaMileage.startMeter'),
      needSorting: false
    },
    {
      id: 'endMeter',
      numeric: false,
      disablePadding: true,
      label: i18n.t('iftaMileage.endMeter'),
      needSorting: false
    },
    {
      id: 'distance',
      numeric: true,
      disablePadding: false,
      label: i18n.t('common.distance'),
      needSorting: false
    },
    { id: 'actions', label: i18n.t('common.actions') }
  ];


  const generateRows = () => {
    const gpsHref = (lat, lng) => {
      if (!lat && !lng) {
        return '-'
      }
      return (
        <Link
          newPage
          href={`https://maps.google.com/?q=${lat},${lng}`}
        >
          {`${lat || 0} , ${lng || 0}`}
        </Link>
      );
    }
    let updatedList = iftaList && iftaList.list ? iftaList?.list?.map((each) => {
      return {
        ...each,
        unitId: each?.vehicleName,
        gpsStart: gpsHref(each?.latLngBeg?.lat, each?.latLngBeg?.lng),
        gpsEnd: gpsHref(each?.latLngEnd?.lat, each?.latLngEnd?.lng),
        endMeter: each?.endOdometerReading,
        startMeter: each?.startOdometerReading,
        distance: each?.distanceTravelled,
      }
    }) : []
    return updatedList || [];
  };

  const tableData = (fields) => {
    setValues({ ...fields });
  };

  const [open, setOpen] = useState(false);

  const closeModal = (event) => {
    setOpen(false);
  };
  const clickedModal = (e) => {
    setOpen(true);
  };
  const [ModalListObject, setModalListObject] = useState({
    header: '',
    content: () => { }
  });
  return (
    <>
      <Styled.Wrapper>
        <Styled.TopWrapper>
          <ViewForm
            apiStatus={apiStatus}
            history={history}
            callback={callback}
            Styled={Styled}
            hasApiStatus={hasApiStatus}
            vehicles={vehicles}
            states={states}
            apiIftaMileage={apiIftaMileage}
            fetchIFTAStates={(data) => {
              setHideStates(true)
              fetchIFTAStates(data)
            }}
            tableData={(fields) => tableData(fields)}
            commonProps={commonProps}
            i18n={i18n}
            setPagination={setPagination}
            changeRequest={changeRequest}
          />
        </Styled.TopWrapper>
        <Styled.TableWrapper>
          <div className="quickFilters"></div>
          <Table
            type="server"
            defaultSortBy={{ sortBy: 'state', order: 'asc' }}
            title=""
            columns={[...columns]}
            changeRequest={changeRequest}
            apiStatus={apiStatus}
            baseRequest={request}
            data={generateRows()}
            emptyMessage={information}
            totalRecords={iftaList?.totalRecords}
            itemsPerPage={15}
            i18n={i18n}
            pageinationReset={pagination}
            pagenationClicked={(e) => {
              setPagination(!e);
            }}
            actions={[
              {
                icon: (row, index) => {
                  return (
                    <Icon
                      tooltip={i18n.t('common.edit')}
                      src={getImagePath(imageUrls.edit)}
                      onClick={() => {
                        history.push(pageUrls?.editState.replace(':id', row?.iftaMileageId))
                      }}
                    />
                  );
                }
              },
              {
                icon: (row, index) => {
                  return (
                    <Icon
                      tooltip={i18n.t('common.delete')}
                      src={getImagePath(imageUrls.deleteIcon)}
                      onClick={() => {
                        setModalListObject({
                          content: () =>
                            <div className="deleteParent">
                              <div className="imageSection">
                                <img
                                  src={getImagePath(imageUrls.confirmIcon)}
                                  alt="Are you sure"
                                />
                              </div>
                              <h2> {i18n.t('common.sureConfirm')} </h2>
                              <p>{i18n.t('common.deleteMessage')} </p>
                              <div className="deletePopupBtn">
                                <div className="cancelBtn">
                                  <Button
                                    label={i18n.t('Cancel')}
                                    type={'reset'}
                                    onClick={() => {
                                      closeModal();
                                    }}
                                  ></Button>
                                </div>
                                <div className="deletebtn">
                                  <Button
                                    label={i18n.t('common.deletePopup')}
                                    onClick={() => {
                                      handleDeleteAlert({
                                        id: 'deleteStateAlert',
                                        data: { id: row?.iftaMileageId },
                                        callback: callback,
                                      });
                                      closeModal();
                                    }}
                                  ></Button>
                                </div>
                              </div>
                            </div>
                        })
                        clickedModal();
                      }}
                    />
                  );
                }
              }
            ]}
          />
        </Styled.TableWrapper>
      </Styled.Wrapper>
      <Modal open={open} setOpen={setOpen} data={ModalListObject} deletePopup />
    </>
  );
};

Component.propTypes = {
  history: PropTypes.object.isRequired,
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  fetchIFTAStates: PropTypes.func.isRequired,
  handleDeleteAlert: PropTypes.func.isRequired
};
