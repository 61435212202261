import React, {
  lazy,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { filterList } from '../../../../utils/utils';
import { imageUrls } from '../../../../config/constants/keys';
import { getImagePath } from '../../../../utils/utils';

const layout = window?.$environment?.CURRENT_LAYOUT;
const TextInput = lazy(() => import(`../../Input/${layout}`));

const Search = forwardRef((props, ref) => {
  const i18n = useTranslation();
  const { seachIconClickable } = props;
  useEffect(() => {
    if (props?.onRemove && props?.onRemove === 'clear') setValue('');
  }, [props?.onRemove]);
  let parentValue = props.default;
  const { SearchWrapper } = props;
  const [value, setValue] = React.useState('');
  const [showText, setShowText] = React.useState(
    seachIconClickable ? false : true
  );

  const inputref = useRef();
  useImperativeHandle(ref, () => ({
    // clearSearchInput() {
    //   inputref.current.clearInputField();
    //   // setValue ('')
    // }
  }));
  const handleChange = (event) => {
    setValue(event);
    if (!props.fromData) {
      // Api.searchList(event).then((res) => {
      props.onChange(event);
      // });
    } else if (typeof props.default == 'object' && !Array.isArray(props.default)) {
      let temp = {...props.default};
      if (event && event.length > 0) {
        const finalData = {};
        Object.keys(temp).forEach(ele => {
          finalData[ele] = filterList(temp[ele], props.query, event);
        })
        props.onChange(finalData);
      } else {
        props.onChange(parentValue);
      }
    } else {
      let temp = [...props.default];
      if (event && event.length > 0) {
        let a;
        if (props.username) {
          a = temp.filter((name) =>
            name.username.toUpperCase().includes(event.toUpperCase())
          );
          props.onChange(a);
        } else if (props.displayName) {
          a = temp.filter((name) =>
            name.displayName.toUpperCase().includes(event.toUpperCase())
          );
          props.onChange(a);
        } else if (props.vehicleStatus) {
          a = temp.filter((name) =>
            name.infoWithLink[0].item
              .toUpperCase()
              .includes(event.toUpperCase())
          );
          props.onChange(a);
        } else {
          const arrayList = [...parentValue];
          const val = filterList(arrayList, props.query, event);
          props.onChange(val);
        }
      } else {
        props.onChange(parentValue);
      }
    }
  };

  const idGenerator = (min, max) => {
    return min + Math.floor(Math.random() * (max - min + 1));
  };
  const hideAndShowSearch = () => {
    if (seachIconClickable) {
      setShowText((value) => !value);
    }
  };
  return (
    <SearchWrapper width={props.width} id="simpleSearchWrapper">
      {showText && (
        <TextInput
          type={'text'}
          placeholder={props.placeHolder ? props.placeHolder : i18n.t('chat.search')}
          handleOnChange={handleChange}
          onChange={(e) => setValue(e)}
          // ref={inputref}
          value={value}
          className="searchInput"
          id={props.id || `searchField_${idGenerator(5000, 1)}`}
        />
      )}
      <img
        className="clear spireonImg"
        src={getImagePath(imageUrls.searchIcon)}
        alt="SearchIcon"
        onClick={hideAndShowSearch}
      />
    </SearchWrapper>
  );
});

Search.propTypes = {
  onChange: PropTypes.func
};

export default Search;
