import React, { lazy, useEffect } from 'react';
import { connect } from 'react-redux';

import {
  getNewObject,
  loadPageConfig,
} from '@/utils/utils';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.editState[0].name}`));
const Content = lazy(() => import(`@/${config.editState[1].name}`));

const EditState = (parentProps) => {
  const {
    fetchVehicles,
    getStates,
    getDrivers,
    fetchStateDetails,
    iftaDetails,
    validateOdometer,
  } = parentProps;

  const history = useHistory();
  const i18n = useTranslation();
  const theme = useTheme();
  const params = useParams();

  const commonParentProps = { history, i18n, theme };

  const apiId = 'fetchVehicles';
  const fetchStateApiId = 'getStatesApi';
  const driverAPIID = 'getDrivers';
  const stateDetailsApi = '';

  const payload = {
    ...params
  };

  useEffect(() => {
    getStates(fetchStateApiId);
    getDrivers(driverAPIID);
    fetchVehicles(apiId);
    fetchStateDetails({ stateDetailsApi, data: { ...payload } });
  }, []);

  return (
    <>
      <RenderComponent
        {...config.editState[0]}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        component={Title}
      />
      <RenderComponent
        {...config.editState[1]}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        component={Content}
        iftaDetails={iftaDetails}
        validateOdometer={validateOdometer}
      />
    </>
  );
};

const RenderComponent = ({
  name,
  props,
  commonProps,
  title,
  parentProps,
  commonParentProps,
  component,
  ...rest
}) => {
  const Component = component;
  let newProps = props?.length ? getNewObject(parentProps, props) : {};
  newProps = {
    ...newProps,
    ...(commonProps?.length ? getNewObject(commonParentProps, commonProps) : {})
  };
  return <Component {...rest} {...newProps} />;
};

const mapState = (state) => {
  return {
    iftaDetails: state?.iftaDetails,
    apiStatus: state.asyncRequests,
    vehicles: state.iftaVehicleInfo,
    states: state.getStates,
    drivers: state.drivers,
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchStateDetails: (data) => dispatch.iftaDetails.fetchData(data),
    fetchVehicles: (data) => dispatch.iftaVehicleInfo.fetchData(data),
    getStates: (data) => dispatch.getStates.getStates(data),
    getDrivers: () => dispatch.drivers.getDrivers(),
    addUpdateIFTAState: (data) => dispatch.iftaDetails.editIftaState(data),
    fetchLatLong: (data) => dispatch.iftaDetails.fetchLatLong(data),
    validateOdometer: (data) => dispatch.iftaDetails.validateOdometer(data),
  };
};

export default connect(mapState, mapDispatch)(EditState);
EditState.propTypes = {
};
