import { Link as LinkLi } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { ROLES, imageUrls, pageUrls } from '../../../config/constants/keys';
import { findListItem, getImagePath, hasRestrictedRole } from '../../../utils/utils';
import Styled from './style';

const Header = ({
  header,
  handleLogout,
  callback,
  isRestrictedMode,
  apiStatus,
  children,
  showFilterPopup,
  auth
}) => {
  const history = useHistory();
  const i18n = useTranslation();
  const [value] = useState(0);
  const [menuValues, setMenuValues] = useState([
    ...(window?.$environment?.SERVICE_PROVIDER !== 'riskTheory' ? [
      {
      name: i18n.t('leftMenu.inspections'),
      value: null,
      url: hasRestrictedRole([ROLES.SERVICE_MANAGER])? pageUrls.unassignedInspections: pageUrls.pendingMechanicInspections,
      img: getImagePath(imageUrls.inspections),
      selected: window.location.href.includes('inspections') ? true : false,
      selectedimg: getImagePath(imageUrls.inspectionIconSelected),
      roles: ['FLMANAGER', 'ADMIN', ROLES.SERVICE_MANAGER, 'MECHANIC', 'DRIVER'],
      dvirOnly: true,
      dependency: ['dvirOnly', 'dvirSpecific']
    }]: []),
    {
      name: i18n.t('leftMenu.companies'),
      value: null,
      url: pageUrls.companyList,
      img: getImagePath(imageUrls.companyList),
      selected: window.location.href.includes('companies') ? true : false,
      selectedimg: getImagePath(imageUrls.companyListSelected),
      roles: [ ROLES.SERVICE_MANAGER ],
      dvirOnly: true,
      dependency: ['dvirOnly']
    },
    {
      name: 'Dashboard',
      value: null,
      url: pageUrls.dashboard,
      img: getImagePath(imageUrls.dashboardIcon),
      selected:
        window.location.href.includes('dashboard') ||
        window.location.href.includes('violations') ||
        window.location.href.includes('driverStatus')
          ? true
          : false,
      selectedimg: getImagePath(imageUrls.dashboardSelectedIcon),
      roles: ['FLMANAGER', 'ADMIN'],
      module: 'dashboard'
    },
    {
      name: i18n.t('Logs'),
      value: null,
      url: pageUrls.logsList,
      img: getImagePath(imageUrls.logsIcon),
      selected:
        window.location.href.includes('/logs') &&
        !window.location.href.includes('fmcsaLogs')
          ? true
          : false,
      selectedimg: getImagePath(imageUrls.logsSelectedIcon),
      roles: ['FLMANAGER', 'ADMIN'],
      module: 'logsList'
    },
    {
      name: i18n.t('leftMenu.users'),
      value: <div></div>,
      url: pageUrls.userList,
      img: getImagePath(imageUrls.userIcon),
      selected: window.location.href.includes('user') ? true : false,
      selectedimg: getImagePath(imageUrls.userIconSelected),
      roles: ['FLMANAGER', 'ADMIN', ROLES.SERVICE_MANAGER],
      module: 'userList',
      dependency: ['dvirOnly']
    },
    {
      name: i18n.t('leftMenu.vehicles'),
      value: <div></div>,
      url: pageUrls.vehicleList,
      img: getImagePath(imageUrls.vehiclesIcon),
      selected:
        window.location.href.includes('vehicle') ||
        window.location.href.includes('trailer')
          ? true
          : false,
      selectedimg: getImagePath(imageUrls.vehiclesIconSelected),
      roles: ['FLMANAGER', 'ADMIN'],
      module: 'vehicleList',
      dependency: ['dvirOnly']
    },
    {
      name: i18n.t('leftMenu.reports'),
      value: <div></div>,
      url: pageUrls.reportsEnabled,
      img: getImagePath(imageUrls.reportsIcon),
      selected: window.location.href.includes('report') ? true : false,
      selectedimg: getImagePath(imageUrls.reportsIconSelected),
      roles: ['FLMANAGER', 'ADMIN'],
      module: 'reportsEnabled'
    },
    {
      name: i18n.t('leftMenu.department'),
      value: <div></div>,
      url: pageUrls.departmentsList.replace(':id', 'default'),
      img: getImagePath(imageUrls.departments),
      selected: window.location.href.includes('department') ? true : false,
      selectedimg: getImagePath(imageUrls.departmentSelected),
      roles: ['FLMANAGER', 'ADMIN'],
      module: 'departmentsList',
      dependency: ['dvirOnly', 'dvirSpecific'],
      dvirOnly: true
    },
    {
      name: i18n.t('leftMenu.companyData'),
      value: <div></div>,
      url: pageUrls.companyDataEdit,
      img: getImagePath(imageUrls.companyIcon),
      selected: window.location.href.includes('company') ? true : false,
      selectedimg: getImagePath(imageUrls.companyIconSelected),
      roles: ['FLMANAGER', 'ADMIN', ROLES.SERVICE_MANAGER],
      module: 'companyDataEdit',
      dependency: ['dvirOnly']
    },
    ...(window?.$environment?.SERVICE_PROVIDER !== 'riskTheory' ? [
      {
        name: i18n.t('leftMenu.DOTAudit'),
        value: <div></div>,
        url: pageUrls.fmcsaLogs,
        img: getImagePath(imageUrls.dotAuditIcon),
        selected:
          window.location.href.includes('logs') &&
          window.location.href.includes('fmcsaLogs')
            ? true
            : false,
        selectedimg: getImagePath(imageUrls.dotAuditIconSelected),
        roles: ['FLMANAGER', 'ADMIN'],
        module: 'fmcsaLogs'
      },
    ] : []),
    {
      name: i18n.t('Support'),
      value: <div></div>,
      img: getImagePath(imageUrls.supportIcon),
      selected: false,
      selectedimg: getImagePath(imageUrls.supportIconSelected),
      roles: ['FLMANAGER', 'ADMIN', 'DRIVER', ROLES.SERVICE_MANAGER, 'MECHANIC'],
      dependency: ['dvirOnly']
    }
  ]);

  // const ListMenu = () => {
  //   document.getElementById('ListMenus').style.display = 'none';
  //   return showFilterPopup;
  // };
  const handleOnClick = (url, index) => {
    setMenuValues(
      menuValues.map((key, i) => {
        if (i === index) return { ...key, selected: true };
        else return { ...key, selected: false };
      })
    );
    if (index === menuValues.length - 1) {
      let dv = document.getElementById('ListMenus');
      let el = document.getElementById('supportDrop');
      if (dv.style.display === 'block') {
        dv.style.display = 'none';
        el.classList.remove('support');
      } else {
        dv.style.display = 'block';
        el.classList.add('support');
      }
    } else {
      let dv = document.getElementById('ListMenus');
      dv.style.display = 'none';
      history.push(url);
    }
  };
  const filterPopupClick = () => {
    showFilterPopup();
    let dv = document.getElementById('ListMenus');
    let el = document.getElementById('supportDrop');
    dv.style.display = 'none';
    el.classList.remove('support');
    if (
      window.location.href.includes('dashboard') ||
      window.location.href.includes('violations') ||
      window.location.href.includes('driverStatus')
    ) {
      setMenuValues(
        menuValues.map((item, index) => {
          if (index === 0) return { ...item, selected: true };
          else return { ...item, selected: false };
        })
      );
    }
    if (
      window.location.href.includes('dashboard') ||
      window.location.href.includes('violations') ||
      window.location.href.includes('driverStatus')
    ) {
      setMenuValues(
        menuValues.map((item, index) => {
          if (index === 0) return { ...item, selected: true };
          else return { ...item, selected: false };
        })
      );
    } else if (
      window.location.href.includes('logs') &&
      !window.location.href.includes('fmcsaLogs')
    ) {
      setMenuValues(
        menuValues.map((item, index) => {
          if (index === 1) return { ...item, selected: true };
          else return { ...item, selected: false };
        })
      );
    } else if (window.location.href.includes('user')) {
      setMenuValues(
        menuValues.map((item, index) => {
          if (index === 2) return { ...item, selected: true };
          else return { ...item, selected: false };
        })
      );
    } else if (window.location.href.includes('vehicle')) {
      setMenuValues(
        menuValues.map((item, index) => {
          if (index === 3) return { ...item, selected: true };
          else return { ...item, selected: false };
        })
      );
    } else if (window.location.href.includes('report')) {
      setMenuValues(
        menuValues.map((item, index) => {
          if (index === 4) return { ...item, selected: true };
          else return { ...item, selected: false };
        })
      );
    } else if (window.location.href.includes('company')) {
      setMenuValues(
        menuValues.map((item, index) => {
          if (index === 5) return { ...item, selected: true };
          else return { ...item, selected: false };
        })
      );
    } else if (
      window.location.href.includes('logs') &&
      window.location.href.includes('fmcsaLogs')
    ) {
      setMenuValues(
        menuValues.map((item, index) => {
          if (index === 6) return { ...item, selected: true };
          else return { ...item, selected: false };
        })
      );
    }
  };

  const menuItems = [
    {
      url: pageUrls.inspections,
      label: i18n.t('leftMenu.inspections'),
      roles: ['FLMANAGER', 'ADMIN'],
      module: 'inspections'
    },
    {
      url: pageUrls.departmentsList.replace(':id', 'default'),
      label: i18n.t('leftMenu.department'),
      roles: ['FLMANAGER', 'ADMIN'],
      module: 'departmentsList'
    },
    ...(window?.$environment?.SERVICE_PROVIDER !== 'riskTheory' ? [
      {
        url: pageUrls.reportMileage,
        label: i18n.t('leftMenu.IFTAReport'),
        roles: ['FLMANAGER', 'ADMIN'],
        module: 'reportMileage'
      }
    ] : []),
    {
    url: pageUrls.dailyDocuments,
    label: i18n.t('leftMenu.dailyDocuments'),
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'dailyDocuments'
  },
    // ...(window.$environment.SERVICE_PROVIDER === 'clutch'
    //   ? [
    //     {
    //       label: i18n.t('leftMenu.fuelReport'),
    //       url: pageUrls.fuelConsumptionReport,
    //       roles: ['FLMANAGER', 'ADMIN'],
    //       module: 'fuelConsumptionReport'
    //     },
    //     {
    //       label: i18n.t('leftMenu.repairMaintenance'),
    //       url: pageUrls.workOrders,
    //       roles: ['FLMANAGER', 'ADMIN'],
    //       module: 'workOrders'
    //     }
    //   ]
    //   : []
    //   ),
    {
      url: pageUrls.alertsRequests,
      label: i18n.t('leftMenu.developerRequests'),
      roles: ['FLMANAGER', 'ADMIN'],
      module: 'alertsRequests',
    },
    {
      url: pageUrls.feedback,
      label: i18n.t('leftMenu.feedback'),
      roles: ['FLMANAGER', 'ADMIN', 'DRIVER', ROLES.SERVICE_MANAGER, 'MECHANIC'],
      module: 'feedback',
      dependency: ['dvirOnly']
    }
  ]

  const [supportMenuValues, setSupportMenuValues] = useState(menuItems);
  return (
    <Styled.HeaderContainer>
      <MenuList>
        {menuValues.map((items, index) => {
          let isAuthorized = findListItem(
            items.roles ? items.roles : [],
            auth?.roles,
            [items.module, ...(items.dependency || '')],
            auth?.enterpriseFeatureDto
          );
          return isAuthorized ? (
            <MenuItem
              component={index !== menuValues.length - 1 ? Link : ''}
              to={index !== menuValues.length - 1 ? items.url : ''}
              onClick={() => handleOnClick(items.url, index)}
              key={value.name}
              className={
                items.selected
                  ? 'selectedDashboardItem dashboardList'
                  : 'dashboardList'
              }
            >
              <ListItemIcon>
                <img
                  src={items.selected ? items.selectedimg : items.img}
                  alt="close"
                ></img>
              </ListItemIcon>
              <a className="menuLabel">{items.name}</a>
              {index + 1 === menuValues.length ? (
                <>
                  <span className="arrowChange" id="supportDrop"></span>
                  <ul className="otherMenulist" id="ListMenus">
                    {(auth?.roles?.includes('FLMANAGER') ||
                      auth?.roles?.includes('ADMIN'))
                     ? (
                      <li
                        className="menuItems"
                        onClick={() => filterPopupClick()}
                      >
                        {i18n.t('leftMenu.filters')}
                      </li>
                    ) : null}
                    {supportMenuValues.map((items, index) => {
                      let isAuthorized = findListItem(
                        items.roles,
                        auth.roles,
                        [items.module, ...(items.dependency || '')],
                        auth.enterpriseFeatureDto
                      );
                      
                      return isAuthorized ? (
                        <li className="menuItems" onClick={() => history.push(items.url)}>
                          <LinkLi
                            component={Link}
                            onClick={() => history.push(items.url)}
                            to={items.url}
                          >
                            {items.label}
                          </LinkLi>
                        </li>
                      ) : null;
                    })}
                  </ul>
                </>
              ) : (
                ''
              )}
            </MenuItem>
          ) : null;
        })}
      </MenuList>
    </Styled.HeaderContainer>
  );
};

export default Header;
Header.defaultProps = {
  header: '',

  handleLogout: () => {},
  callback: () => {}
};
Header.propTypes = {
  header: PropTypes.string,
  history: PropTypes.object.isRequired,
  handleLogout: PropTypes.func,
  callback: PropTypes.func
};
