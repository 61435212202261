import React, { lazy, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import RenderComponent from './RenderComponent';
import { hasValue } from '@/utils/utils';
import { UI_DATE_FORMAT } from '@/config/constants/static';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));

const ViewForm = ({
  apiStatus,
  callback,
  drivers,
  currentDriver,
  currentDate,
  history,
  filterByDriverAndDate,
  setPagination
}) => {
  const { i18n } = useTranslation();
  const viewApiId = 'fetchDailyDocuments';
  const hasApiStatus = hasValue(apiStatus.status, 'api', viewApiId);

  const [values, setValues] = useState({});
  const [isCancel, setIsCancel] = useState(false);
  useEffect(() => {
    !isCancel &&
      setValues({
        driver: drivers?.filter((item) => item.value == currentDriver)
          ? drivers?.filter((item) => item.value == currentDriver)[0]
          : null,
        day: currentDate || ''
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDriver, drivers]);

  return (
    <>
      <Form
        type="server"
        direction="row"
        initialValues={{ ...values }}
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {
          setPagination(true);
          let finalData = {
            driver: fields.driver?.value || null,
            day: fields.day || null
          };
          filterByDriverAndDate(finalData);
        }}
        handleOnCancel={(fields) => {
          setPagination(true);
          setIsCancel(true);
          setValues({ driver: '', day: '' });
          filterByDriverAndDate({ driver: '', day: '' });
        }}
        customProps={{
          hasApiStatus,
          drivers,
          history,
          currentDate,
          callback
        }}
      />
    </>
  );
};

ViewForm.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  fetchDailyDocuments: PropTypes.func.isRequired,
  drivers: PropTypes.array.isRequired,
  currentDriver: PropTypes.object,
  currentDate: PropTypes.string,
  history: PropTypes.object.isRequired
};

export default ViewForm;
