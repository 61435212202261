import styled from 'styled-components';

import Box from '@material-ui/core/Box';

import { getImagePath, getStyle } from '../../../utils/utils';
import { imageUrls } from '../../../config/constants/keys';

const Wrapper = styled.main``;

export const HeaderContainer = styled.div`
  ${({ theme, isLogin }) => `
  h1 {
    text-transform: uppercase;
  }
  #container {
    flex-direction: column;
    display: flex;
    padding: 4px 1%;
    padding-left: 0;
    padding-bottom: 0;
    background-color:  ${theme?.colors?.PUBLIC_HEADERCOLOR};
    font-size: 24px;
    h1 {
      padding-left: 3%;
      line-height: 41px;
      flex: 1;
    }
    img {
      width: 170px;
      height: auto;
      margin-top: 22px;
    }
  }
  .not-authenticated {
    flex-direction: row !important;
    height: 73px;
    background-color: ${theme?.colors?.BLACK};
  }
  `}
`;

const Content = styled(Box)`
  background-image: url(${getImagePath(imageUrls.bg_image)});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: calc(100vh - 77px);
  position: relative;
`;
const IconWrapper = styled(Box)`
  ${({ theme }) => `
  text-align: left;
  padding-left: 10%;
  position: absolute;
  bottom: 3px;
  .MuiTypography-colorPrimary:first-child {
    margin-right: 60px;
  }
  img {
    @media (max-width: ${theme?.breakpoints?.values?.md}px) {
      width: 30%;
    }
  }
  @media (max-height: 720px) {
    display: none;
  }
  `}
`;

export const LanguageContainer = styled.div`
  ${({ theme }) => `
 right:4%;
 position: absolute;
  cursor: pointer;
  text-align: right;
  align-self: center;
  top: 13px;
  padding-top: 0;
  color: ${getStyle(
    '',
    (theme.palette.secondary || {}).main,
    theme.colors.SECONDARY_MAIN
  )}};
  #languages {
    font-size: 19px;
    color: ${theme.colors.LANGUAGE_SWITCH};
    background: none !important;
    outline: 0;
    cursor: pointer;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    option {
      color: ${theme.colors.BLACK};
    }
  }
 
  @media (max-width: 375px) {
    font-size: 15px;
  }
  @media (min-width: 375px) and (max-width: 768px) {
    font-size: 15px;
  }
  `}
`;
const Styled = {
  Wrapper,
  Content,
  IconWrapper,
  HeaderContainer,
  LanguageContainer
};

export default Styled;
