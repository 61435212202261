import { getSubscriptions, saveSubscriptions } from '../../../config/apis';

const initialState = {
  data: {}
};

export const subscriptions = {
  state: { ...initialState },
  reducers: {
    handleGetScriptions: (state, data) => {
      return {
        //Mock data
        ...state,
        data: { ...data }
      };
      // return { ...state, data };
    }
  },
  effects: (dispatch) => ({
    async getSubscriptions(payload) {
      const response = await dispatch.asyncRequests.handleAsyncRequests({
        payload: {
          data: null,
          api: getSubscriptions
        },
        callback: null,
        id: 'getSubscriptions'
      });
      if (response) {
        this.handleGetScriptions();
      } else {
        if (payload === 'gorilla') {
          this.handleGetScriptions({
            createdDate: 1581696674166,
            domain: 'gorilla',
            endDate: null,
            id: '5e46c6a2306ad04899ab1d87',
            isProcessed: true,
            stdard: {
              mobileFeatures: {
                accidents: true,
                dailyDocuments: true,
                dailyLog: true,
                dotInspection: true,
                dvirInspection: true,
                fuelTracking: true,
                gpsTracking: true,
                mechanic: true,
                policiesAndProcedures: true,
                vnaSupport: true
              },
              webFeatures: {
                accidents: false,
                alerts: true,
                companyDocuments: true,
                companySummary: true,
                dailyDocuments: true,
                dashboard: true,
                fuelConsumptionReport: true,
                iBeacon: true,
                iftaReport: true,
                inspections: true,
                logs: true,
                mechanicLogin: true,
                policiesAndProcedures: true,
                repairAndMaintenance: true,
                userDetails: true,
                userList: true,
                vehicleDetails: true,
                vehicleList: true
              }
            },
            pro: {
              mobileFeatures: {
                accidents: false,
                dailyDocuments: true,
                dailyLog: true,
                dotInspection: true,
                dvirInspection: true,
                fuelTracking: true,
                gpsTracking: true,
                mechanic: true,
                policiesAndProcedures: true,
                vnaSupport: true
              },
              webFeatures: {
                accidents: true,
                alerts: true,
                companyDocuments: true,
                companySummary: true,
                dailyDocuments: true,
                dashboard: true,
                fuelConsumptionReport: true,
                iBeacon: true,
                iftaReport: true,
                inspections: true,
                logs: true,
                mechanicLogin: true,
                policiesAndProcedures: true,
                repairAndMaintenance: true,
                userDetails: true,
                userList: true,
                vehicleDetails: true,
                vehicleList: true
              }
            },
            lite: {
              mobileFeatures: {
                accidents: true,
                dailyDocuments: true,
                dailyLog: true,
                dotInspection: true,
                dvirInspection: true,
                fuelTracking: true,
                gpsTracking: true,
                mechanic: true,
                policiesAndProcedures: true,
                vnaSupport: true
              },
              webFeatures: {
                accidents: true,
                alerts: true,
                companyDocuments: true,
                companySummary: true,
                dailyDocuments: true,
                dashboard: true,
                fuelConsumptionReport: true,
                iBeacon: true,
                iftaReport: true,
                inspections: true,
                logs: true,
                mechanicLogin: true,
                policiesAndProcedures: true,
                repairAndMaintenance: true,
                userDetails: true,
                userList: true,
                vehicleDetails: true,
                vehicleList: true
              }
            }
          });
        } else {
          this.handleGetScriptions({
            createdDate: 1581696674166,
            domain: 'trackon',
            endDate: null,
            id: '5e46c6a2306ad04899ab1d87',
            isProcessed: true,
            stdard: {
              mobileFeatures: {
                accidents: true,
                dailyDocuments: true,
                dailyLog: true,
                dotInspection: true,
                dvirInspection: true,
                fuelTracking: true,
                gpsTracking: true,
                mechanic: true,
                policiesAndProcedures: true,
                vnaSupport: true
              },
              webFeatures: {
                accidents: false,
                alerts: true,
                companyDocuments: true,
                companySummary: true,
                dailyDocuments: true,
                dashboard: true,
                fuelConsumptionReport: true,
                iBeacon: true,
                iftaReport: true,
                inspections: true,
                logs: true,
                mechanicLogin: true,
                policiesAndProcedures: true,
                repairAndMaintenance: true,
                userDetails: true,
                userList: true,
                vehicleDetails: true,
                vehicleList: true
              }
            },
            pro: {
              mobileFeatures: {
                accidents: false,
                dailyDocuments: true,
                dailyLog: true,
                dotInspection: true,
                dvirInspection: true,
                fuelTracking: true,
                gpsTracking: true,
                mechanic: true,
                policiesAndProcedures: true,
                vnaSupport: true
              },
              webFeatures: {
                accidents: false,
                alerts: true,
                companyDocuments: true,
                companySummary: true,
                dailyDocuments: true,
                dashboard: true,
                fuelConsumptionReport: true,
                iBeacon: true,
                iftaReport: true,
                inspections: true,
                logs: true,
                mechanicLogin: true,
                policiesAndProcedures: true,
                repairAndMaintenance: true,
                userDetails: true,
                userList: true,
                vehicleDetails: true,
                vehicleList: true
              }
            },
            lite: {
              mobileFeatures: {
                accidents: true,
                dailyDocuments: true,
                dailyLog: true,
                dotInspection: true,
                dvirInspection: true,
                fuelTracking: true,
                gpsTracking: true,
                mechanic: true,
                policiesAndProcedures: true,
                vnaSupport: true
              },
              webFeatures: {
                accidents: false,
                alerts: true,
                companyDocuments: true,
                companySummary: true,
                dailyDocuments: true,
                dashboard: true,
                fuelConsumptionReport: true,
                iBeacon: true,
                iftaReport: true,
                inspections: true,
                logs: true,
                mechanicLogin: true,
                policiesAndProcedures: true,
                repairAndMaintenance: true,
                userDetails: true,
                userList: true,
                vehicleDetails: true,
                vehicleList: true
              }
            }
          });
        }
      }
    },
    async saveSubscriptions(payload) {
      const response = await dispatch.asyncRequests.handleAsyncRequests({
        payload: {
          data: null,
          api: saveSubscriptions
        },
        callback: null,
        id: 'saveSubscriptions'
      });
      if (response) {
        this.handleGetScriptions();
      } else {
        if (payload === 'gorilla') {
          this.handleGetScriptions({
            createdDate: 1581696674166,
            domain: 'gorilla',
            endDate: null,
            id: '5e46c6a2306ad04899ab1d87',
            isProcessed: true,
            stdard: {
              mobileFeatures: {
                accidents: true,
                dailyDocuments: true,
                dailyLog: true,
                dotInspection: true,
                dvirInspection: true,
                fuelTracking: true,
                gpsTracking: true,
                mechanic: true,
                policiesAndProcedures: true,
                vnaSupport: true
              },
              webFeatures: {
                accidents: false,
                alerts: true,
                companyDocuments: true,
                companySummary: true,
                dailyDocuments: true,
                dashboard: true,
                fuelConsumptionReport: true,
                iBeacon: true,
                iftaReport: true,
                inspections: true,
                logs: true,
                mechanicLogin: true,
                policiesAndProcedures: true,
                repairAndMaintenance: true,
                userDetails: true,
                userList: true,
                vehicleDetails: true,
                vehicleList: true
              }
            },
            pro: {
              mobileFeatures: {
                accidents: false,
                dailyDocuments: true,
                dailyLog: true,
                dotInspection: true,
                dvirInspection: true,
                fuelTracking: true,
                gpsTracking: true,
                mechanic: true,
                policiesAndProcedures: true,
                vnaSupport: true
              },
              webFeatures: {
                accidents: true,
                alerts: true,
                companyDocuments: true,
                companySummary: true,
                dailyDocuments: true,
                dashboard: true,
                fuelConsumptionReport: true,
                iBeacon: true,
                iftaReport: true,
                inspections: true,
                logs: true,
                mechanicLogin: true,
                policiesAndProcedures: true,
                repairAndMaintenance: true,
                userDetails: true,
                userList: true,
                vehicleDetails: true,
                vehicleList: true
              }
            },
            lite: {
              mobileFeatures: {
                accidents: true,
                dailyDocuments: true,
                dailyLog: true,
                dotInspection: true,
                dvirInspection: true,
                fuelTracking: true,
                gpsTracking: true,
                mechanic: true,
                policiesAndProcedures: true,
                vnaSupport: true
              },
              webFeatures: {
                accidents: true,
                alerts: true,
                companyDocuments: true,
                companySummary: true,
                dailyDocuments: true,
                dashboard: true,
                fuelConsumptionReport: true,
                iBeacon: true,
                iftaReport: true,
                inspections: true,
                logs: true,
                mechanicLogin: true,
                policiesAndProcedures: true,
                repairAndMaintenance: true,
                userDetails: true,
                userList: true,
                vehicleDetails: true,
                vehicleList: true
              }
            }
          });
        } else {
          this.handleGetScriptions({
            createdDate: 1581696674166,
            domain: 'trackon',
            endDate: null,
            id: '5e46c6a2306ad04899ab1d87',
            isProcessed: true,
            stdard: {
              mobileFeatures: {
                accidents: true,
                dailyDocuments: true,
                dailyLog: true,
                dotInspection: true,
                dvirInspection: true,
                fuelTracking: true,
                gpsTracking: true,
                mechanic: true,
                policiesAndProcedures: true,
                vnaSupport: true
              },
              webFeatures: {
                accidents: false,
                alerts: true,
                companyDocuments: true,
                companySummary: true,
                dailyDocuments: true,
                dashboard: true,
                fuelConsumptionReport: true,
                iBeacon: true,
                iftaReport: true,
                inspections: true,
                logs: true,
                mechanicLogin: true,
                policiesAndProcedures: true,
                repairAndMaintenance: true,
                userDetails: true,
                userList: true,
                vehicleDetails: true,
                vehicleList: true
              }
            },
            pro: {
              mobileFeatures: {
                accidents: false,
                dailyDocuments: true,
                dailyLog: true,
                dotInspection: true,
                dvirInspection: true,
                fuelTracking: true,
                gpsTracking: true,
                mechanic: true,
                policiesAndProcedures: true,
                vnaSupport: true
              },
              webFeatures: {
                accidents: false,
                alerts: true,
                companyDocuments: true,
                companySummary: true,
                dailyDocuments: true,
                dashboard: true,
                fuelConsumptionReport: true,
                iBeacon: true,
                iftaReport: true,
                inspections: true,
                logs: true,
                mechanicLogin: true,
                policiesAndProcedures: true,
                repairAndMaintenance: true,
                userDetails: true,
                userList: true,
                vehicleDetails: true,
                vehicleList: true
              }
            },
            lite: {
              mobileFeatures: {
                accidents: true,
                dailyDocuments: true,
                dailyLog: true,
                dotInspection: true,
                dvirInspection: true,
                fuelTracking: true,
                gpsTracking: true,
                mechanic: true,
                policiesAndProcedures: true,
                vnaSupport: true
              },
              webFeatures: {
                accidents: false,
                alerts: true,
                companyDocuments: true,
                companySummary: true,
                dailyDocuments: true,
                dashboard: true,
                fuelConsumptionReport: true,
                iBeacon: true,
                iftaReport: true,
                inspections: true,
                logs: true,
                mechanicLogin: true,
                policiesAndProcedures: true,
                repairAndMaintenance: true,
                userDetails: true,
                userList: true,
                vehicleDetails: true,
                vehicleList: true
              }
            }
          });
        }
      }
    }
  })
};
