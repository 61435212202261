import styled from 'styled-components';
import Parent from '../Base/style';

const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
.MuiButtonBase-root:active,
  .MuiButtonBase-root:hover {
    background-color: ${theme.colors.BACKGROUND};
  }`}

`;
const Header = styled(Parent.Header)``;
const Content = styled(Parent.Content)``;

const Accordion = { Wrapper, Header, Content };

export default Accordion;
