import React, { lazy, useState } from 'react';
import PropTypes, { array } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { find } from 'lodash';

import CategoryItem from './Categorytem';
import { stackOffsetDiverging } from 'd3';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);

const RenderComponent = ({
  validateForm,
  submitAction,
  values,
  customProps
}) => {
  const { i18n } = useTranslation();
  const handleDeleteItem = (item, index) => {
    customProps.initialValues[item.categoryType.replace(/_/g, ' ')].splice(index, 1);
    let array = [];
    let a = Object.entries(customProps.initialValues)?.map(function ([key, value]) {
      value && array.push(...value);
    });
    customProps.updateAdvancedConfig(array);
  };
  const changeInspectionEnabled = (item, index, flag) => {
    customProps.initialValues[item.categoryType.replace(/_/g, ' ')][
      index
    ].inspectionEnabled = flag;
    let array = [];
    let a = Object.entries(customProps.initialValues)?.map(function ([key, value]) {
      value && array.push(...value);
    });
    customProps.updateAdvancedConfig(array);
  };
  return (
    <>
      <div className="btn-wrapper">
        <div class="leftWrapper">
          {Object.entries(customProps.initialValues)?.map(([key1, value1]) => (
            <>
              <FormStyle.default.ButtonsWrapper
                className={
                  customProps.selected === key1.replace(/ /g, '_')
                    ? `partsName dvir-btn-active`
                    : `partsName dvir-btn`
                }
                onClick={() => customProps.setSelected(key1.replace(/ /g, '_'))}
              >
                {key1}
              </FormStyle.default.ButtonsWrapper>
            </>
          ))}
        </div>
        <div class="rightWrapper">
          {Object.entries(customProps.initialValues)?.map(([key2, value2]) => (
            <div
              className={
                customProps.selected === key2.replace(/ /g, '_')
                  ? `dvir-content-item-active`
                  : `dvir-content-item`
              }
            >
              {value2?.map((item, index) => (
                <CategoryItem
                  categoryItem={item}
                  i18n={i18n}
                  changeInspectionEnabled={changeInspectionEnabled}
                  handleDeleteItem={handleDeleteItem}
                  index={index}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
      <FormStyle.default.ButtonsWrapper className="saveChanges">
        <Button
          type="submit"
          className="buttonStyle"
          label={i18n.t('common.saveChanges')}
          onClick={() => {
            const validateResponse = validateForm();
            validateResponse.then((data) => {
              submitAction(data);
            });
          }}
        />
      </FormStyle.default.ButtonsWrapper>
    </>
  );
};
export default RenderComponent;

RenderComponent.propTypes = {
  history: PropTypes.object.isRequired,
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  fetchAdvancedConfig: PropTypes.func.isRequired,
  advancedConfig: PropTypes.object.isRequired
};
