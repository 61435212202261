import React, { useEffect, lazy } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useHistory } from 'react-router-dom';
import useTheme from '@material-ui/core/styles/useTheme';
import { useTranslation } from 'react-i18next';

import ParentStyled from './style';
import { loadStyle, getNewObject, loadPageConfig } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'pages/web/Documents');
const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.companyDocument[0].name}`));
const Content = lazy(() => import(`@/${config.companyDocument[1].name}`));

const Documents = (parentProps) => {
  const { fetchDocuments } = parentProps;

  const history = useHistory();
  const theme = useTheme();
  const i18n = useTranslation();

  const apiId = 'fetchDocuments';
  const commonParentProps = { history, i18n, Styled, theme };

  useEffect(() => {
    fetchDocuments({
      apiId
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <RenderComponent
        {...config.companyDocument[0]}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        component={Title}
      />
      <RenderComponent
        {...config.companyDocument[1]}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        component={Content}
      />
    </>
  );
};

const RenderComponent = ({
  name,
  props,
  commonProps,
  title,
  parentProps,
  commonParentProps,
  component,
  ...rest
}) => {
  const Component = component;
  let newProps = props?.length ? getNewObject(parentProps, props) : {};
  newProps = {
    ...newProps,
    ...(commonProps?.length ? getNewObject(commonParentProps, commonProps) : {})
  };
  return <Component {...rest} {...newProps} />;
};
const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    documents: state.documentList,
    receipt: state.viewReport
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchDocuments: (data) => dispatch.documentList.fetchData(data),
    saveDocument: (data) => dispatch.documentList.saveData(data),
    deleteDocument: (data) => dispatch.documentList.deleteData(data),
    downloadDocument: (data) => dispatch.viewReport.downloadReport(data),
    clearState: (data) => dispatch.viewReport.clearReceipt()
  };
};

export default connect(mapState, mapDispatch)(Documents);
Documents.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  documents: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  fetchDocuments: PropTypes.func.isRequired
};
