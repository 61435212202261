import styled from 'styled-components';
import Parent from '../Base/style';
const Container = styled(Parent.Container)`
${({ theme}) => `
a {
    color: ${theme.colors.LINK_COLOR}; 
    text-decoration: none;
    &:hover {
        text-decoration: underline !important;
    }
}
.active {
    background-color: ${theme.colors. ACTIVE_ROW}!important;
    border: 0 !important;
}
.MuiTable-root {
    border: 0;
}
.MuiPaper-root {
    box-shadow: none;
}
.MuiTableSortLabel-active .MuiTableSortLabel-iconDirectionAsc,
  .MuiTableSortLabel-active .MuiTableSortLabel-iconDirectionDesc {
    left: -4px;
    top: -4px;
  }
.MuiTableSortLabel-active .MuiTableSortLabel-iconDirectionAsc,
.MuiTableSortLabel-active .MuiTableSortLabel-iconDirectionDesc {
  svg {
    width: 1.9rem;
    height: 1.6rem;
    color: #8A8A8A;
    top: -2px;
  }
}
.MuiTableHead-root .MuiTableCell-head,
.MuiTableBody-root .MuiTableCell-root {
    font-family: ${theme.palette.tableFont};
}
.MuiTableSortLabel svg {
    position: relative;
    top: 1px;
}
#sort {
    fill: #DCDCDC;
}
.MuiTableHead-root {
    .MuiTableRow-root:hover {
        background-color: #ffffff;
    }
    .MuiTableCell-head {
        font-size: 14px;
        padding: 10px 15px 10px 25px;
        color: #787e96 !important;
        .MuiTableSortLabel-root {
            color: #787e96 !important;
        }
    }
    .MuiTableCell-root {
        border-bottom: 0;
    }
}
.MuiTableSortLabel-iconDirectionDesc {
    color: #DCDCDC !important;
}
.MuiTableBody-root{
    td {
        color: #222;
    }
    tr:hover {
        background-color: #dadada !important;
    }
    tr:nth-child(even) {
        background-color: #ffffff;
    }
    tr:nth-child(odd) {
        background-color: #efefef;
    }
    .MuiTableCell-root {
        border: 0;
    }
} 
`}
`;
const TableContainer = styled(Parent.TableContainer)`
overflow-x: auto;
`;
const PaginationContainer = styled(Parent.PaginationContainer)`
    
`;

const Styled = { Container, TableContainer, PaginationContainer };

export default Styled;
