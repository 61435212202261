import React, { lazy } from 'react';
import PropTypes from 'prop-types';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Table = lazy(() => import(`../../../components/UI/Table/${layout}`));
const Link = lazy(() => import(`../../../components/UI/Link`));
const RecentAccidents = ({ recentAccidents, i18n, viewReport }) => {
  const columns = [
    {
      id: 'fullName',
      numeric: false,
      disablePadding: false,
      label: i18n.t('vehicles.fullName')
    },
    {
      id: 'phone',
      numeric: true,
      disablePadding: false,
      label: i18n.t('common.phone')
    },
    {
      id: 'report',
      disablePadding: false,
      label: i18n.t('accidents.accidentReport')
    }
  ];
  const generateRows = () => {
    const updatedList = recentAccidents?.map((item) => {
      var result = {};
      result.fullName = item.items[0];
      result.phone = item.items[1];
      result.report = (
        <a
          onClick={() =>
            viewReport({
              apiId: 'viewReportApi',
              data: item.link?.replace('./document/open/', '')
            })
          }
          className="viewReport viewReportNewTab"
          target="_blank"
        >
        {i18n.t('accidents.viewReport')}
        </a>
      );
      return result;
    });
    return updatedList;
  };
  const information = i18n.t('common.tableEmptyMessage');
  return (
    <>
      <Table
        type="client"
        title=""
        columns={[...columns]}
        data={generateRows()}
        emptyMessage={information}
        itemsPerPage={10}
        actions={[]}
        i18n={i18n}
        noPagination
      />
    </>
  );
};

export default RecentAccidents;

RecentAccidents.defaultProps = {};

RecentAccidents.propTypes = {
  recentAccidents: PropTypes.array.isRequired
};
