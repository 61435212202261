import styled from 'styled-components';
import Parent from './style';
const Wrapper = styled(Parent.Wrapper)`
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  flex-direction: column;
  padding: 0;
  padding-bottom: 0;
  overflow: hidden;
 
`;
const TopWrapper = styled(Parent.TopWrapper)`
${({ theme }) => `
flex: 0 0 68%;
.breadcrumbClass {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-left: 0;
  left: 5%;
}
`}
`;
const IconWrapper = styled(Parent.IconWrapper)`
  .MuiAvatar-root {
    width: 27px !important;
  }
`;

const ImpExpWrapper = styled(Parent.ImpExpWrapper)`
${({ theme }) => `
flex-direction: row;
.importNew,
.addNew {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-end;
  .MuiButtonBase-root {
    font-size: ${theme.palette.addbuttonFont} !important;
    padding:0;
    padding-top: 2px;
    min-width: 150px;
  }
}
.importNew,
.addNew {
  max-width: 150px;
}
.addNew {
  margin-right: 1%;
  .importWrap{
    flex-direction: column;
    justify-content: flex-start;
    .columnSection{
      display: flex;
      justify-content: flex-start;
      .importsection{
        width: 80%;
      }
    }
  }
}
.importWrap {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  .columnSection{

  }
}
.importNew {
  flex: 0 0 31%;
  .importsection {
    width: 73%;
  }
  .importWrap {
    // padding-left: 0;
    // justify-content: flex-start;
  }
    
}
.importsection {
  width: 27%;
}
.iconContainer {
  flex: 0 0 10%;
  justify-content: end;
}
.iconContainer .MuiAvatar-root {
  width: 20px !important;
  height: 20px !important;
}
.importVehicleCsvReaderInput{
  font-family: tahoma;
}
.importVehicleCsvReaderInput label {
  font-size: 14px !important;
  color: ${theme.colors.LINK_COLOR};
}
#addUsers.vehicleModal{
 right: 27%;
  &::before {
    left: 14%;
  }
}
#importItems.vehicleModal{
  right: 20%;
  &::before {
    left: 20%;
  }
}
#ImportVehicles.vehicleModal {
  right: 7%;
  &::before {
    right: 70%;
  }
}
.importUserTemplateContainer .iconContainer .MuiAvatar-root,
.exportWrapper .iconContainer .MuiAvatar-root,
.exportContainer .iconContainer .MuiAvatar-root {
  width: 16px !important;
  height: 16px !important;
}
.exportContainer {
  color: ${theme.colors.LINK_COLOR};
  font-size: 14px;
}
.exportContainer .exportWrapper {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    width: 155px;
  }
}
 .MuiAvatar-root {
  width: 35px !important;
  height: auto !important;
}
.importUserTemplateContainer,
.importUserContainer {
  margin-right: 10px;
}
`}
`;

const SearchWrapper = styled(Parent.SearchWrapper)`

`;

const ButtonsWrapper = styled(Parent.ButtonsWrapper)`
 
`;
const TableWrapper = styled(Parent.TableWrapper)`
${({ theme }) => `
padding-top: 0;
width: 55%;
@media (max-width: ${theme.breakpoints.values.xxl}px) {
  width: 60%;
}
.vehicleTable{
  text-transform: none;
}
.vehicleTable {
  padding: 0 2%;
  table {
    min-width: auto;
    .MuiTableCell-head {
      padding-right: 0 !important;
    }
  }
}
.userHeading {
  margin-bottom: 0 !important;
}
`}
`;
const DocContainer = styled(Parent.DocContainer)`
`;
const MiddleWrapper = styled(Parent.MiddleWrapper)`
${({ theme }) => `
  padding: 0;
  .mapContainer {
    flex: 0 0 45%;
    width: 44%;
    @media (max-width: ${theme.breakpoints.values.xxl}px) {
      flex: 0 0 40%;
    }
    #map {
      border: 0;
    }
  }
`}
`;
const MapWrapper = styled(Parent.MapWrapper)`
flex: 0 0 40%;
`;
const TrackWrapper = styled(Parent.TrackWrapper)`
${({ theme }) => `


@media (max-width: ${theme.breakpoints.values.md - 1}px) {
  margin-top: 4%;
}
flex: 0 0 29%;
width: auto;
border:0;
form {
  flex-direction: row;
}
.trackVehicleForm {
  padding: 15px 1%;
  padding-left: 2%;
}
`}

`;
const BottomWrapper = styled(Parent.BottomWrapper)`
${({ theme }) => `
background: #f3f3f3;
display: flex;
justify-content: center;
border-top: 2px solid ${theme.colors.BLACK};
padding: 1% 0;
.vehicleLists {
  background: #fff none repeat scroll 0 0;
  width: 44%;
  border: 1px solid #d9d9d9;
  position: relative;
  margin-right: 1%;
  padding: 1%;
  .title {
    margin: 0;
    color: ${theme.colors.LINK_COLOR};
    font-size: 22px;
  }
}
table {
  min-width: auto !important;
}
.vehicleTabs {
  header{
    box-shadow: none;
  }
  .viewAll{
    position: absolute;
    top: 15px;
    right: 3px;
    .MuiGrid-container .MuiAvatar-root {
      width: 24px !important;
      height: 16px !important;
    }
  }
  .MuiTabs-flexContainer {
    border-top: 0;
    background-color: transparent;
  }
  .MuiTabs-root {
    .MuiButtonBase-root.MuiTab-root {
      border-right: 0;
      border-bottom: 4px solid transparent;
    }
    .MuiTab-wrapper {
      font-size: 16px;
    }
    .Mui-selected .MuiTab-wrapper {
      color: ${theme.colors.LINK_COLOR};
    }
  }
}
`}
`;
const FormWrapper = styled(Parent.FormWrapper)`
${({ theme }) => `
width: 100%;
.formWrap {
  display: flex;
  .vehicleTrack {
    margin-right: 1%;
  }
  @media (max-width: ${theme.breakpoints.values.xxl}px) {
    flex-direction: column;
  }
}

.dropdownWrapper {
  flex: 0 0 84%;
  display: flex;
  @media (max-width: ${theme.breakpoints.values.xxl}px) {
    flex-direction: column;
  }
}
.buttonWrapper {
  flex: 0 0 16%;
  .MuiButtonBase-root {
    margin-top: 28px;
    @media (max-width: ${theme.breakpoints.values.xxl}px) {
      width: 35%;
      margin-top: 10px;
    }
  }
}
.dropdownWrapper .vehicleTrack {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    margin-bottom: 63px;
  }
  .MuiFormLabel-root {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS styles go here */
      padding-bottom: 18px;
    }
  }
  .MuiFormLabel-root + .MuiBox-root {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS styles go here */
      height: 40px;
    }
  }
}
`}
`;
const OptionsWrapper = styled(Parent.OptionsWrapper)`
`;

const Styled = {
    Wrapper,
    IconWrapper,
    ImpExpWrapper,
    SearchWrapper,
    TopWrapper,
    ButtonsWrapper,
    TableWrapper,
    DocContainer,
    MiddleWrapper,
    MapWrapper,
    TrackWrapper,
    BottomWrapper,
    FormWrapper,
    OptionsWrapper
  };
  export default Styled;