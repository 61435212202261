import React, { useState, lazy, useEffect } from 'react';
import moment from 'moment';
import { UI_LOGBOOK_DATE } from '@/config/constants/static';
import Violations from '../Dashboard/Notfications';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Link = lazy(() => import(`@/components/UI/Link`));
const beBaseUrl = window?.$environment?.BE_BASE_URL;
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));

const PdfView = ({
  list,
  i18n,
  date,
  driver,
  documentToken,
  Styled,
  fetchLogBookView,
  getLogbookEvents,
  updateShowEvents,
  getDocumentToken,
  selectedDailyLog,
  regeneratePdf,
  eventsData
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const getDocumentTokenApiId = 'getDocToken';
  const getIndex = () => {
    const formattedDate = date?.replace('/', '-');
    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      if (element.day === formattedDate) {
        return index;
      }
    }
    return 0;
  };
  useEffect(() => {
    if (date && date.includes('-'))
      getDocumentToken({ apiId: getDocumentTokenApiId, callback: tokenPdfShow });
  }, [date, driver]);

  const tokenPdfShow = (type, data) => {
    const index = getIndex();
    setCurrentIndex(index);
    setDocLiink(
      beBaseUrl +
      '/document/open/' +
      selectedDailyLog?.document?.id +
      '?token=' +
      data +
      '&output=embed'
    );
  }
  const [docLink, setDocLiink] = useState('');

  return (
    <Styled.PdfContainer>
      <div className="dailylogSection">
        <span className="dailyLogs">{i18n.t('Daily Log')}</span>
        <div className="linkContainer">
          <div className="prev">
            <Link
              className="uppercase"
              onClick={() => {
                if (currentIndex < list.length - 1) {
                  setCurrentIndex(currentIndex + 1);
                  fetchLogBookView(list[currentIndex + 1]?.day || date);
                }
              }}
            >
              {i18n.t('common.previous')}
            </Link>
          </div>
          <label className="dateLabel">
            {moment(list[currentIndex]?.day || date).format(UI_LOGBOOK_DATE)}
          </label>
          <div className="next">
            <Link
              className="uppercase"
              onClick={() => {
                if (currentIndex > 0) {
                  setCurrentIndex(currentIndex - 1);
                  fetchLogBookView(list[currentIndex - 1]?.day || date);
                }
              }}
            >
              {i18n.t('common.next')}
            </Link>
          </div>
        </div>
      </div>
      <div className="pdfWrap">
        <iframe
          src={docLink}
          width="100%"
          height="720px"
          id="logBookIframe"
          target="_parent"
        ></iframe>
        <div id="regeneratePdf-wrapper">
          <Link id="regeneratePdf"
            onClick={() => regeneratePdf({
              date: (list[currentIndex - 1]?.day || date), callbackFuntion: () => {
                if (date && date.includes('-')) getDocumentToken({ apiId: getDocumentTokenApiId, callback: tokenPdfShow });
              }
            })}
          >
            {i18n.t('common.regeneratePdf')}
          </Link>
        </div>
      </div>
      <Button
        label={eventsData?.showEvents ? i18n.t('common.viewLess') : i18n.t('common.viewMore')}
        onClick={(e, d) => {
          if (eventsData?.showEvents) updateShowEvents(false)
          else {
            if (Boolean(eventsData?.data?.engineEvents)) updateShowEvents(true)
            else getLogbookEvents(selectedDailyLog?.idPub || '')
          }
        }}
      />
      {eventsData?.showEvents ?
        <div className='events'>
          <div className='eventContainer'>
            <Violations
              potentialViolations={eventsData?.data?.engineEvents?.map(ele => ({ label: ele.status, value: ele.start })) || []}
              other
              heading={'Engine Events'}
            />
          </div>
          <div className='eventContainer'>
            <Violations
              potentialViolations={eventsData?.data?.loginEvents?.map(ele => ({ label: ele.status, value: ele.start })) || []}
              other
              heading={'Login Events'}
            />
          </div>

        </div> : null}

    </Styled.PdfContainer>
  );
};

export default PdfView;
