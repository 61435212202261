import styled from 'styled-components';
import Parent from './style';
const Wrapper = styled(Parent.Wrapper)`
    ${({ theme }) => `
        .alertMessage  {
            display: block;
            width: 100%;
            text-align: center;
            padding-top: 20px;
        }
    `}
`;

const Styled = { Wrapper};

export default Styled;
