import * as parent from '../variables';

export const colors = {
  ...parent.colors,
  SELECTED_MENU_BG: '#181c2d',
  PRIMARY_MAIN: '#181c2d',
  HEADER_COUNT_TEXT: '#fff',
  PRIMARY_CONTRAST: '#fff',
  PRIMARY_BORDER: '#2C3249',
  GREY: '#999',
  DAILY_LOG_GRAPH_BORDER: '#919191',
  NORMAL_LINK: '#b0b0b0',
  MODAL_TEXT: '#797979',
  MODAL_HEADING: '#fff',
  MODAL_BACKGROUND: '#181c2d',
  LIST_TEXT: '#889ccc',
  HEADER_LIST_TEXT: '#889ccc',
  LIGHT_BORDER: '#2d2b2b',
  SECONDARY_MAIN: '#20b1e8',
  WHITE: '#fff',
  INPUT_BACKGROUND: '#181c2d',
  RED: '#ff0000',
  BLACK: '#000',
  BACKGROUND: '#1b1b1b',
  BLACK_THREE: '#373737',
  RED_TWO: '#d34343',
  GREEN: '#32cd32',
  GREEN_TWO: '#97AE46',
  GREY_ELEVEN: '#777070',
  GREY_TWELVE: '#c3c3c3',
  BLACK_FOUR: '#333333',
  GRAPH_BLACK: '#212121',
  CHART_BLUE: '#00e2ff',
  WARNING: '#ffff00',
  GREY_DATA: '#696969',
  SELECTED_TAB_COLOR: '#20b1e8',
  LINK_COLOR: '#20b1e8',
  HEADER_COLOR: '#20263a',
  DOCUMENT_LINE: '#2C3249',
  INPUT_BORDER: 'rgba(255,255,255,0.3)',
  DIABLED_COLOR: '#889ccc',
  RADIO_BACKGROUND: '#181c2d',
  FORM_COLOR: '#dbdbdb',
  SUGGESTED_COLOR: '#00b7a0',
  BODY_BACKGROUND: '#181c2d',
  CHECKBOX_TICK: '#20b1e8',
  PAGINATION_BACKGROUND: '#181c2d',
  PAGINATION_COLOR: '#fff',
  SELECTED_AREA: 'rgba(32, 177, 232, 0.6)',
  TAB_HIGHLIGHT_BACKGROUND: '#2e3650',
  BACKGROUND_MENU: '#2e3650',
  MAP: {
    LIGHT_BLACK: '#424D66',
    LIGHTER_BLACK: '#424D66',
    BLACK: '#424D66',
    GREY: '#d1d1d1',
    LIGHT_GREY: '#d1d1d1',
    LIGHTER_GREY: '#d1d1d1',
    WATER: '#2B364F',
    ROAD: '#37425C'
  },
  LOGIN_BUTTON: '#1ea7db',
  TITLE_COLOR: '#fff',
  INPUT_COLOR: '#fff',
  INPUT_FOCUS: '#5ab8f9',
  TAB_LABEL: '#455560',
  BACKGROUND_MENU: '#2e3650',
  PAGINATION_ALTERNATE_BACKGROUND: '#222639',
  TABLE_ROW_COLOR: 'rgba(222, 231, 255, 0.75)',
  SORT_ICON: '#fff',
  LOGOUT_BUTTON: '#181c2d',
  PAGINATION_HEADER: '#889ccc',
  HEADER_TEXT: '#fff',
  PAGINATION_BORDER: '#2c3249',
  RESET_BUTTON: 'rgba(0, 0, 0, 0.3)',
  ACTION_BORDER: 'rgba(255, 255, 255, 0.66)',
  PRIMARY_BUTTON_TEXT: '#fff',
  FORM_INPUT_BG: '#181c2d',
  FORM_BACKGROUND: '#181c2d',
  CHECKBOX_BORDER: 'rgba(255,255,255,0.3)',
  SELECT_ACTIONS: '#fff',
  SECONDARY_BORDER: '#2C3249',
  MULTISELECT_BACKGROUND: '#20263a',
  MULTISELECT_LABEL: '#fff',
  TAB_HIGHLIGHT: '#fff',
  TAB_HIGHLIGHT_BACKGROUND: '#2e3650',
  TAB_BOTTOM_BORDER: '#4cb2e8',
  ACCORDION_BACKGROUND: '#20263a',
  MENU_TITLE: 'rgba(255, 255, 255, 0.8)',
  SELECTED_MENU_TITLE: '#fff',
  MENU_BORDER_COLOR: '#4cb2e8',
  MENU_BACKGROUND:'#20263a',
  USER_LOGO_BACKGROUND: '#4cb2e8',
  USER_LOGO_COLOR: '#ffffff',
  RANGE_PICKER: {
    CELL_BG: '#585858',
    CELL_HOVER_BG: '#333333'
  }
};
export default colors;
export const fonts = ['Open Sans', 'sans-serif'].join(',');

