import styled from 'styled-components';
import Parent from '../Base/style';
const CheckboxWrapper = styled(Parent)`
${({ theme}) => `
.normalCheckbox svg path {
    stroke: #000 !important;
}
.normalCheckbox label {
    font-size: ${theme.palette.checkboxFont};
    font-family: ${theme.palette.tableFont};
}
.square {
  fill: #fff;
}
.normalCheckbox .unchecked.disabledCheck .square {
    fill-opacity: 1 !important;
    fill: #ddd;
    stroke: #bbb;
  }

  .normalCheckbox .unchecked.disabledCheck + label {
    color: #aaa !important
  }
`}
`;
export default CheckboxWrapper;
