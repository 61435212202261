import styled from 'styled-components';

const Wrapper = styled.div`
  ${({ theme }) => `
  .MuiPaper-root {
    background: ${theme.colors.PRIMARY_MAIN}
      none repeat scroll 0 0;
          border-bottom: 2px solid
          ${(theme.palette.primary || {}).border || theme.colors.LIGHT_BORDER};
          
    border: 0;
    border-radius: 0 !important;
    width: 100%;
  }
  .MuiExpansionPanelDetails-root {
    padding: 0;
    background: ${theme.colors.PRIMARY_MAIN}
    none repeat scroll 0 0;
    border-bottom: .5px solid ${theme.colors.PRIMARY_BORDER} !important;
    flex-direction: column;
  }
  .MuiCollapse-wrapperInner {
    background-color: ${theme.colors.BLACK};
  }
  .MuiButtonBase-root:active,
  .MuiButtonBase-root:hover {
    background-color: ${theme.colors.PRIMARY_BORDER};
  }
  .MuiButtonBase-root,
  .MuiExpansionPanelSummary-root.Mui-expanded {
    height: 45px;
    border-bottom: 1.5px solid ${theme.colors.PRIMARY_BORDER};
  }
  .MuiExpansionPanelSummary-root,
  .MuiExpansionPanelSummary-root.Mui-expanded {
    min-height: initial;
  }
  .MuiIconButton-edgeEnd {
    padding:0;
    color: ${theme.colors.PRIMARY_CONTRAST};
    height: 39px;
    border-bottom: 0;
  }
  .MuiGrid-container {
    width: auto;
    padding-right: 12px;
  }
  .MuiGrid-container:last-child {
    padding-right: 0;
  }
  .MuiExpansionPanel-root.Mui-expanded {
    margin:0;
  }
  `}
`;

const Header = styled.div`
${({ theme }) => `
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  width: 100%;
  variant: ${(props) => (props.variant ? props.variant : 'h2')};
  color: ${theme.colors.PRIMARY_CONTRAST};
  .MuiPaper-root {
    width: 100%;
    background: ${theme.colors.PRIMARY_MAIN}
      none repeat scroll 0 0;
    border-bottom: 0.5px solid ${theme.colors.PRIMARY_BORDER};
  }
  .MuiCollapse-wrapper {
    background: ${theme.colors.PRIMARY_MAIN}
    none repeat scroll 0 0;
  }
  .terminal {
    width: 70%;
    color: ${theme.colors.PRIMARY_CONTRAST};
  }
  .department {
    padding-left: 8px;
  }
  .iconTerminal {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    width: 30%;
    justify-content: flex-end;
    .MuiGrid-container {
      width: auto;
      padding-right: 12px;
    }
    .MuiGrid-container:last-child {
      padding-right: 0;
    }
  }
  .MuiExpansionPanelSummary-content {
    margin: 0;
  }
  `}
`;

const Content = styled.div`
  ${({ theme }) => `
  color: ${theme.colors.PRIMARY_CONTRAST};
  width: 100%;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  height: 45px;
  padding: 9px 37px;
  .MuiPaper-root {
    background: ${theme.colors.PRIMARY_MAIN}
      none repeat scroll 0 0;
    border: 2px solid ${theme.colors.LIGHT_BORDER};
  }
  .terminal {
    width: 70%;
  }
  .iconTerminal {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    width: 30%;
    justify-content: flex-end;
    .MuiGrid-container {
      width: auto;
      padding-right: 12px;
    }
    .MuiGrid-container:last-child {
      padding-right: 0;
    }
  }
  .MuiExpansionPanelSummary-content {
    margin: 0;
  }
  `}
`;

const Accordion = { Wrapper, Header, Content };
export default Accordion;
