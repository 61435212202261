import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { getImagePath } from '../../../../utils/utils';

const { Parser } = require('json2csv');
const layout = window?.$environment?.CURRENT_LAYOUT;
const Icon = lazy(() => import(`../../Icon/${layout}`));
const Link = lazy(() => import(`@/components/UI/Link`));
const ErrorIcon = lazy(() => import(`../../../ErrorIcon/${layout}`));
const Modal = require(`../../Modal/${layout}`).default;
const Button = lazy(() => import(`../../Button/${layout}`));

const Export = ({
  fields,
  data,
  filename,
  onClick,
  className,
  id,
  src,
  toolTip,
  ExportContainer,
  displayText,
  isOpened,
  needLinkIcon
}) => {
  const i18n = useTranslation();
  const [openCallback, setOpenCallback] = React.useState(false);
  const [ModalListObject, setModalListObject] = React.useState({
    header: '',
    content: () => (
      <div className="successpopup">
        <ErrorIcon />
        <h2> {i18n.t('common.noDataAvailable')} </h2>
        <Button
          type="submit"
          label={i18n.t('common.ok')}
          onClick={() => setOpenCallback(false)}
        />
      </div>
    )
  });
  const click = (e) => {
    if (data && data.length > 0) {
      const field = fields;
      const json2csvParser = new Parser({ field });
      const csv = json2csvParser.parse(data);
      var element = document.createElement('a');
      element.setAttribute(
        'href',
        'data:text/plain;charset=utf-8,' + encodeURIComponent(csv)
      );
      element.setAttribute('download', filename + '.csv');
      element.style.display = 'none';
      if (typeof element.download != 'undefined') {
        //browser has support - process the download
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      } else {
        //browser does not support - alert the user
        alert(
          'This functionality is not supported by the current browser, recommend trying with Google Chrome instead.'
        );
      }
    } else {
      setOpenCallback(true);
    }
  };
  return (
    <>
      <ExportContainer className={className}>
        {displayText ? (
          <Link onClick={onClick || click} id={id || ''}>
            {displayText}
            {needLinkIcon && (
              <ArrowDropDownIcon
                style={
                  isOpened
                    ? { transform: `rotate(180deg)` }
                    : { transform: `rotate(360deg)` }
                }
              />
            )}
          </Link>
        ) : (
          <Icon
            src={getImagePath(src)}
            onClick={onClick || click}
            id={id || ''}
          />
        )}
        <span className="tooltiptext">{toolTip}</span>
      </ExportContainer>
      <Modal
        open={openCallback}
        setOpen={setOpenCallback}
        data={ModalListObject}
        isSuccess
      />
    </>
  );
};

Export.propTypes = {
  fields: PropTypes.array,
  data: PropTypes.array,
  filename: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string
};

Export.defaultProps = {
  fields: [],
  data: [],
  filename: '',
  className: ''
};

export default Export;
