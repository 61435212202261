import styled from 'styled-components';
import Parent from '../Base/style';

const Container = styled(Parent.Container)`
img {
    width: 58px;
  }
`;

const SuccessIconComponent = { Container };
export default SuccessIconComponent;
