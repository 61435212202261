import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
${({ theme }) => `
  display: flex;
  flex-direction: column;
  p {
    color: ${theme.colors.PRIMARY_CONTRAST};
    font-size: 20px;
  }
  .date {
    font-weight: bold;
    padding-left: 4px;
  }
  .payCard {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 2%;
    .advancedDVIR {
      margin-right: 1%;
    }
    button {
      margin-top: 0;
    }
  }
  `}
`;

const Styled = { Wrapper };

export default Styled;
