import React from 'react';
import generateAndValidateForm from './serverForm';
import ClientForm from './ClientForm';
const Form = ({ type, serverStyle, clientStyle, ...rest }) => {
  if (type === 'server') {
    return generateAndValidateForm({ Styled: serverStyle, ...rest });
  } else {
    return <ClientForm Styled={clientStyle} {...rest} />;
  }
};

export default Form;
