import styled from 'styled-components';
import Parent from '../Base/style';
const SearchWrapper = styled(Parent)`
.clear {
    right: -12px;
    width: 24px;
    height: 24px;
}
`;
export default SearchWrapper;
