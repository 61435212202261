import React, { lazy } from 'react';
import PropTypes from 'prop-types';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Table = lazy(() => import(`../../../components/UI/Table/${layout}`));
const DailyLogs = ({ data, i18n, viewReport }) => {
  const columns = [
    {
      id: 'fullName',
      numeric: false,
      disablePadding: false,
      label: i18n.t('common.fullName')
    },
    {
      id: 'hoursDriving',
      disablePadding: false,
      label: i18n.t('common.hoursDriving')
    },
    {
      id: 'phone',
      numeric: true,
      disablePadding: false,
      label: i18n.t('common.phone')
    },

    {
      id: 'hours',
      disablePadding: false,
      label: i18n.t('common.hours')
    }
  ];
  const generateRows = () => {
    const updatedList = data?.map((item) => {
      var result = {};
      result.fullName = item.items[0];
      result.hoursDriving = item.items[1];
      result.phone = item.items[2];
      result.hours = (
        item && item.link ?
        <a
          className="viewReportNewTab"
          onClick={() => {
            viewReport({
              apiId: 'viewReportApi',
              data: item.link.replace('./document/open/', '')
            });
          }}
        >
          {i18n.t('accidents.viewReport')}
        </a>
        : '-'
      );
      return result;
    });
    return updatedList;
  };
  const information = i18n.t('common.tableEmptyMessage');
  return (
    <>
      <Table
        type="client"
        title=""
        columns={[...columns]}
        data={generateRows()}
        emptyMessage={information}
        itemsPerPage={10}
        actions={[]}
        i18n={i18n}
        noPagination
      />
    </>
  );
};

export default DailyLogs;

DailyLogs.defaultProps = {};

DailyLogs.propTypes = {};
