import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Form from './Form';
import Styled from './style';

const Component = ({
  apiStatus,
  requestForgotPassword,
  history,
  clearApiStatus,
  addCompanyInfo,
  getCompanyInfoApi,
  getCompanyInfo,
  states,
  addTruck
}) => {
  return (
    <Styled.Wrapper>
      <h1 className="stepTitle">Company Info</h1>
      <div className="loginformwrap">
        <Form
          apiStatus={apiStatus}
          requestForgotPassword={requestForgotPassword}
          history={history}
          clearApiStatus={clearApiStatus}
          states={states}
          addCompanyInfo={addCompanyInfo}
          getCompanyInfoApi={getCompanyInfoApi}
          getCompanyInfo={getCompanyInfo}
          addTruck={addTruck}
        />
      </div>
    </Styled.Wrapper>
  );
};

export default Component;

Component.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  requestForgotPassword: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  clearApiStatus: PropTypes.func.isRequired
};
