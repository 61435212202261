import React, { useEffect, lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import {
  getNewObject,
  generateDropdownOptions,
  loadPageConfig,
  isInsuracePortalUser
} from '@/utils/utils';

const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.addUser[0].name}`));
const Content = lazy(() => import(`@/${config.addUser[1].name}`));

const AddUser = (parentProps) => {
  const {
    fetchUserData,
    getRegions,
    getFeatureFlags,
    fetchVehicleList,
    getDocumentToken,
    regions,
    user,
    fetchCompanyList,
    removeApiStatus,
    clearReceipt,
    module
  } = parentProps;

  const history = useHistory();
  const i18n = useTranslation();
  const theme = useTheme();

  const commonParentProps = { history, i18n, theme };
  const fetchUserDataApi = 'fetchUserData';
  const getDocumentTokenApiId = 'getDocToken';

  useEffect(() => {
    fetchUserData({ apiId: fetchUserDataApi });
    getDocumentToken({ apiId: getDocumentTokenApiId });
    removeApiStatus();
    clearReceipt();
    fetchCompanyList({ apiId: 'fetchFleetCompanies' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (window.$environment.CURRENT_LAYOUT !== 'SpireonNew') {
      let object = { ...disabledCriteria };
      if (user?.accessLevel < 1) {
        object.regionId = true;
        object.terminalId = true;
        object.departmentId = true;
        object.subDepartmentId = true;
      } else if (user?.accessLevel < 2) {
        object.regionId = true;
        object.terminalId = true;
        object.departmentId = true;
        object.subDepartmentId = false;
      } else if (user?.accessLevel < 3) {
        object.regionId = true;
        object.terminalId = true;
        object.departmentId = false;
        object.subDepartmentId = false;
      } else if (user?.accessLevel < 4) {
        object.regionId = true;
        object.terminalId = false;
        object.departmentId = false;
        object.subDepartmentId = false;
      } else {
        object.regionId = false;
        object.terminalId = false;
        object.departmentId = false;
        object.subDepartmentId = false;
      }
      setDisabledCriteria(object);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.accessLevel]);
  const [disabledCriteria, setDisabledCriteria] = useState({
    regionId: false,
    terminalId: false,
    departmentId: false,
    subDepartmentId: false
  });

  const onFleetChange = (company) => {
    getRegions(company)
    fetchVehicleList({
      data: {
        id: company
      }
    })
    getFeatureFlags({apiId: 'featureFlagApi', data: company})
  }
  return (
    <>
      <RenderComponent
        {...config.addUser[0]}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        component={Title}
      />
      <RenderComponent
        {...config.addUser[1]}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        onFleetChange={onFleetChange}
        component={Content}
        regions={generateDropdownOptions(
          isInsuracePortalUser() && regions?.data?.regions?.length
            ? regions.data.regions
            : user.regions,
          'regionName',
          'id'
        )}
        terminals={generateDropdownOptions(
          regions?.data?.terminals?.length
            ? regions.data.terminals
            : user.terminals,
          'terminalName',
          'terminalId'
        )}
        departments={generateDropdownOptions(
          regions?.data?.departments?.length
            ? regions.data.departments
            : !regions?.data?.departments?.length &&
              regions?.data?.terminals?.length
              ? []
              : user.departments,
          'departmentName',
          'departmentId'
        )}
        subDepartments={generateDropdownOptions(
          regions?.data?.subDepartments?.length
            ? regions.data.subDepartments
            : !regions?.data?.subDepartments?.length &&
              regions?.data?.departments?.length
              ? []
              : user.subDepartments,
          'subDepartmentName',
          'subDepartmentId'
        )}
        disabledCriteria={disabledCriteria}
        userRole={{ mechanic: module === 'addMechanic' }}
      />
    </>
  );
};

const RenderComponent = ({
  name,
  props,
  commonProps,
  title,
  parentProps,
  commonParentProps,
  component,
  ...rest
}) => {
  const Component = component;
  let newProps = props?.length ? getNewObject(parentProps, props) : {};
  newProps = {
    ...newProps,
    ...(commonProps?.length ? getNewObject(commonParentProps, commonProps) : {})
  };
  return <Component {...rest} {...newProps} />;
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    regions: state.regions,
    documents: state.documents,
    user: state.user,
    logBook: state.logBook,
    accessLevel: state.accessLevel,
    fleetCompanies: state.fleetCompanyList.companyList,
    downloadUrl: state.viewReport,
    vehicleList: state.vehicles.fleetVehicleList
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchTerminals: (data) => dispatch.regions.fetchTerminals(data),
    fetchDepartments: (data) => dispatch.regions.fetchDepartments(data),
    fetchSubDepartments: (data) => dispatch.regions.fetchSubDepartments(data),
    fetchUserData: (data) => dispatch.user.fetchData(data),
    fetchVehicleList: (data) => dispatch.vehicles.fetchFleetData(data),
    saveUserData: (data) => dispatch.user.saveData(data),
    saveDocument: (data) => dispatch.user.saveDocument(data),
    deleteDocument: (data) => dispatch.user.deleteDocument(data),
    fetchCountData: (data) => dispatch.dashboard.fetchCountData(data),
    downloadDocument: (data) => dispatch.viewReport.downloadReport(data),
    getDocumentToken: (data) => dispatch.logBook.documentToken(data),
    getRegions: (data) => dispatch.regions.fetchRegionsByCompany(data),
    getFeatureFlags: (data) => dispatch.user.fetchFeatureFlags(data),
    fetchRegions: (data) => dispatch.regions.fetchRegions(data),
    fetchCompanyList: (data) => dispatch.fleetCompanyList.fetchCompanyList(data),
    fetchFilters: (data) => dispatch.filters.fetchData(data),
    fetchAccessLevel: (data) => dispatch.accessLevel.fetchData(data),
    handleLogout: (data) => dispatch.login.handleLogout(data),
    removeApiStatus: () => dispatch.user.removeApiStatus(),
    getDrivers: (data) => dispatch.chat.getDrivers(data),
    clearReceipt: () => dispatch.viewReport.clearReceipt()
  };
};

export default connect(mapState, mapDispatch)(AddUser);
AddUser.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  fetchTerminals: PropTypes.func.isRequired,
  fetchCompanyList: PropTypes.func.isRequired,
  fetchVehicleList: PropTypes.func.isRequired,
  fetchDepartments: PropTypes.func.isRequired,
  getRegions: PropTypes.func.isRequired,
  getFeatureFlags: PropTypes.func.isRequired,
  saveDocument: PropTypes.func.isRequired,
  deleteDocument: PropTypes.func.isRequired,
  fetchUserData: PropTypes.func.isRequired,
  saveUserData: PropTypes.func.isRequired,
  fetchSubDepartments: PropTypes.func.isRequired
};
