import React, { lazy } from 'react';
import PropTypes from 'prop-types';

import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { useTranslation } from 'react-i18next';

import { pageUrls } from '../../../config/constants/keys';
import { getImagePath, getAuthDetails } from '../../../utils/utils';
import { imageUrls } from '../../../config/constants/keys';
import Styled from './style';
import { removeLocalStorage } from '@/services/storage';

const layout = window?.$environment?.CURRENT_LAYOUT;
const SvgIcon = lazy(() => import(`../../SvgIcon/${layout}`));
const Link = lazy(() => import(`../../UI/Link`));

const Header = ({
  history,
  handleLogout,
  callback,
  isRestrictedMode,
  keepi18nTextAsSame,
  headerMenu,
  menuList,
  showFilterPopup
}) => {
  const { i18n } = useTranslation();

  const handleOnLogout = () => {
    const auth = getAuthDetails(false);
    if (auth) {
      handleLogout({
        apiId: 'logout',
        callback: (type, message) => {
          if (type === 'error') {
            callback(type, message);
          } else {
            removeLocalStorage('currentLayout');
            // window.location.reload();
            window.location.href = pageUrls.login;
          }
        }
      });
    } else {
    }
  };
  const options = [
    {
      icon: imageUrls.logout,
      label: i18n.t('avatarMenu.logout'),
      handleOnClick: handleOnLogout
    }
  ];

  const generateMenuItem = (value, index) => {
    return (
      <MenuItem
        onClick={value.handleOnClick}
        key={`${value.label}Label${index}`}
      >
        {value.icon ? (
          <ListItemIcon className="menuItem">
            {value.icon.indexOf('.svg') > -1 ? (
              <SvgIcon value={value.icon} />
            ) : (
              <img
                alt={'optionIcon' + value.label}
                src={getImagePath(value.icon)}
              />
            )}
          </ListItemIcon>
        ) : (
          <Link
            href={value.path || '#'}
            onClick={() => {
              if (value.isFilter) {
                showFilterPopup();
                return false;
              } else if (value.handleOnClick) {
                value.handleOnClick();
                return false;
              }
            }}
          >
            {i18n.t(value.label)}
          </Link>
        )}
      </MenuItem>
    );
  };

  return <></>;
};

export default Header;
Header.defaultProps = {
  header: '',
  handleLogout: () => {},
  callback: () => {},
  keepi18nTextAsSame: false,
  headerMenu: false,
  menuList: {}
};
Header.propTypes = {
  header: PropTypes.string,
  history: PropTypes.object.isRequired,
  handleLogout: PropTypes.func,
  callback: PropTypes.func,
  keepi18nTextAsSame: PropTypes.bool,
  menuList: PropTypes.object,
  headerMenu: PropTypes.bool
};
