import React, { useState } from 'react';
import PropTypes from 'prop-types';

// import Form from '../../../components/UI/Form';
// import * as Yup from 'yup';
// import Accordion from '../../../components/Accordion/index';
// import Modal from '../../../components/UI/Modal';
// import Button from '../../../components/UI/Button';
// import ErrorIcon from '../../../components/ErrorIcon';
// import SuccessIcon from '../../../components/SuccessIcon';
import { UI_DATE_FORMAT } from '../../../config/constants/static';
import { hasValue } from '../../../utils/utils';

const Component = ({
  apiStatus,
  dutyStatusReport,
  driverPerformanceReport,
  editedDataReport,
  drivingEventReport,
  eldDisconnectReport,
  odometerJumpReport,
  malfunctionReport,
  unassignedDrivingReport,
  violationReport,
  hosViolationsReport,
  rejectedEditsReport
}) => {
  return <>under maintenance</>;
};

export default Component;

Component.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  dutyStatusReport: PropTypes.func.isRequired,
  driverPerformanceReport: PropTypes.func.isRequired,
  editedDataReport: PropTypes.func.isRequired,
  drivingEventReport: PropTypes.func.isRequired,
  eldDisconnectReport: PropTypes.func.isRequired,
  odometerJumpReport: PropTypes.func.isRequired,
  malfunctionReport: PropTypes.func.isRequired,
  unassignedDrivingReport: PropTypes.func.isRequired,
  violationReport: PropTypes.func.isRequired,
  hosViolationsReport: PropTypes.func.isRequired,
  rejectedEditsReport: PropTypes.func.isRequired
};
