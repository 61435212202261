import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';

// import Title from '../../../components/Title';
import Component from './Component';

const Companies = ({ auth, theme, i18n, history, ...rest }) => {
  return (
    <>
      {/* <Title header={'MANAGE COMPANIES'} /> */}
      <Box style={{ ...theme.palette.contentStyle }}>
        <Component {...rest} theme={theme} i18n={i18n} />
      </Box>
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    companies: state.companies
  };
};
const mapDispatch = (dispatch) => {
  return {
    getCompanies: (data) => dispatch.companies.getCompanies(data)
  };
};

export default connect(mapState, mapDispatch)(Companies);

Companies.defaultTypes = {
  companies: {}
};

Companies.propTypes = {
  auth: PropTypes.object.isRequired,
  apiStatus: PropTypes.object.isRequired,
  companies: PropTypes.object,
  getCompanies: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};
