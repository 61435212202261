import styled from 'styled-components';

import { imageUrls } from '../../../config/constants/keys';
import { getImagePath, getStyle } from '../../../utils/utils';
const isSimplex = window?.$environment?.SERVICE_PROVIDER === 'simplex'

export const MapWrapper = styled.div`
  ${({ theme, height }) => `
  #map {
    height: ${height ? height : '500px'};
    width: 100%;
    border: 2px solid ${theme.colors.PRIMARY_BORDER};
  }
  #content {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
  }
  .alignCenter{
    align-items: center;
  }
.gm-style .gm-style-iw-tc::after {
  height: 6px !important;
  left: 5px !important;
  width: 14px !important;
  top: 3px !important;
}
  .gm-style .gm-style-iw-t::after {
    background: ${getStyle(
  '',
  (theme.palette.primary || {}).main,
  theme.colors.PRIMARY_MAIN
)};
    box-shadow: -1px 1px 0px 0
      ${(theme.palette.primary || {}).contrastText ||
    theme.colors.PRIMARY_CONTRAST
    };
    top: -2px;
  }
  .gm-style-iw #content, .label-infoWindow {
    font-family: ${(theme.palette || {}).fontFamily} !important;
    font-size: 13px !important;
  }

  .checkBoxStyle {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .gm-style-iw-chr {
    height: 0px;
  }

  .gm-style-iw #content a {
    font-family: ${(theme.palette || {}).fontFamily};
    color: ${(theme.palette.primary || {}).contrastText ||
    theme.colors.PRIMARY_CONTRAST
    } !important;
    font-weight: normal;
    font-size: 15px;
    padding: 0;
    &:hover {
      text-decoration: underline;
    }
  }
  .gm-style .gm-style-iw-c {
    background: transparent;
    padding: 0 !important;
    top: 4px !important;
  }

  .gm-style-iw-t::before {
    border-top: 12px solid
      ${(theme.palette.primary || {}).contrastText ||
    theme.colors.PRIMARY_CONTRAST
    } !important;
  }

  .nameLabel{
    display: none;
  }
  .infoImg {
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }

  .gm-style-iw #content {
    padding-bottom: 5px;
  }

  .gm-style-iw #content:first-child a {
    position: relative;
    top: 2px;
  }
  .gm-ui-hover-effect img {
    display: none !important;
  }
  .gm-ui-hover-effect {
    background: url(${getImagePath(imageUrls.closeMap)}) no-repeat !important;
    top: ${isSimplex ? '-6px' : '6px'} ${window.$environment.SERVICE_PROVIDER == 'ft' ? '' : '!important'};
    right: ${isSimplex ? '0' : '-6px'} !important;
    width: 22px ${window.$environment.SERVICE_PROVIDER == 'ft' ? '' : '!important'};
  }
  .infoWindow-data {
    font-family: Yanone Kaffeesatz;
    font-size: 14px;
    text-decoration: none;
    color: ${isSimplex ? theme.colors.BLACK : theme.colors.WHITE};
  }
  .gm-style-mtc div {
    border: 1px solid ${theme.colors.BLACK_FOUR} !important;
    background-color: ${theme.colors.GRAPH_BLACK} !important;
    color: ${theme.colors.GREY_TWELVE} !important;
    height: 30px !important;
    font-size: 14px !important;
  }
  .gmnoprint .gm-style-mtc {
    margin-left: 4px;
  }
  .gm-control-active.gm-fullscreen-control {
    height: 30px !important;
    width: 30px !important;
    margin-right: 21px !important;
    border: 1px solid ${theme.colors.BLACK_FOUR} !important;
    background-color: ${theme.colors.GRAPH_BLACK} !important;
  }
  .gm-svpc {
    width: 25px !important;
    border: 1px solid ${theme.colors.BLACK_FOUR} !important;
    background-color: ${theme.colors.GRAPH_BLACK} !important;
  }
  .gm-bundled-control .gmnoprint div {
    border: 1px solid ${theme.colors.BLACK_FOUR} !important;
    background-color: ${theme.colors.GRAPH_BLACK} !important;
  }
  .gm-bundled-control-on-bottom .gmnoprint:first-child div:first-child {
    width: 25px !important;
    height: 50px !important;
  }
  .gm-bundled-control-on-bottom .gmnoprint:first-child div:nth-child(2) {
    width: 18px !important;
    margin: 0 3px !important;
  }
  .gm-bundled-control-on-bottom .gm-control-active img {
    height: 14px !important;
    width: 14px !important;
    margin: 0px 5px 9px !important;
  }
  .gm-bundled-control-on-bottom button.gm-control-active {
    width: 25px !important;
    height: 25px !important;
    left: -5px !important;
  }

  .gm-style-cc div {
    color: ${theme.colors.GREY_TWELVE} !important;
    border: 1px solid ${theme.colors.BLACK_FOUR} !important;
    background-color: ${theme.colors.GRAPH_BLACK} !important;
  }
  a[href^="https://maps.google.com/maps"] img
  {
    opacity: 0.3;
    width: 52px !important;
    height: 20px !important;
  }

  .gm-style a {
    padding: 0px 6px;
    font-size: 10px;
    color: ${theme.colors.GREY_TWELVE} !important;
  }
  .gm-style .gm-style-iw-d {
    overflow: hidden !important;
  }
  #content_tooltip {
    #content {
      margin-right: 8px;
      margin-left: 4px;
    }
    display: flex;
    flex-direction: column;
    opacity: 0.7;
    padding: 2px !important;
    background-color: ${getStyle(
      '',
      (theme.palette.primary || {}).main,
      theme.colors.PRIMARY_MAIN
    )};
    border: 1px solid
      ${(theme.palette.primary || {}).contrastText ||
    theme.colors.PRIMARY_CONTRAST
    };
      border-radius: 8px

    .tooltip__container--top {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      #content { 
        flex-direction: row !important;
      }
    }

    .infoImg {
      margin-right: 5px;
      display: block !important;
    }
  }
  .infoWindowWrapper{
    padding: 14px !important;
    background-color: ${getStyle(
      '',
      (theme.palette.primary || {}).main,
      theme.colors.PRIMARY_MAIN
    )};
    border: 1px solid
      ${(theme.palette.primary || {}).contrastText ||
    theme.colors.PRIMARY_CONTRAST
    };
      border-radius: 8px
  }

  #cotent__container {
    display: flex;
    flex-direction: column;
    min-width: 200px;
    padding: 4px !important;
    background-color: ${getStyle(
      '',
      (theme.palette.primary || {}).main,
      theme.colors.PRIMARY_MAIN
    )};
    border: 1px solid
      ${(theme.palette.primary || {}).contrastText ||
    theme.colors.PRIMARY_CONTRAST
    };
      border-radius: 8px

    .infoImg {
      margin-right: 5px;
      display: block !important;
    }

    #content {
      display: flex;
      flex-direction: column
      align-items: center;
      justify-content: space-between;
      min-height: 50px;
      margin: 0 10px

      .vehicleId {
        font-weight: bold;
        font-size: 15px !important;
      }  

      .infoWindow__span {
        text-decoration: none !important;
        top: 0px !important;
        max-width: 100px;
      }
    }
  }
  .cotent__container--top {
    background-color:  ${theme.colors.MAP.WATER} !important;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    #content { 
      flex-direction: row !important;
    }

    .lastUpdatedTime {
      align-items: end !important;
      font-style: italic !important;
      a {
        font-size: 10px !important;
      }
    }
  }
  .cotent__container--bottom {
    display: flex
    justify-content: space-between;
    align-items: center;
  }
  .cotent__container--middle {
    #content { 
      flex-direction: row !important;
      justify-content: start !important;

      .infoWindow__span--location {
        flex: 1;
        max-width: 200px !important;
        max-height: 55px !important;
        text-decoration: none !important;
        overflow: auto;
      }

      .infoWindow__span--location::-webkit-scrollbar-track
      {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
      }

      .infoWindow__span--location::-webkit-scrollbar
      {
        width: 6px;
        background-color: #F5F5F5;
      }

      .infoWindow__span--location::-webkit-scrollbar-thumb
      {
        background-color: #000000;
      }
    }
  }
  `}
`;
