const initialState = {};
export const registration = {
  state: { ...initialState },
  reducers: {
    setFetchSuccess(state, payload) {
      return { ...payload };
    },
    setFetchFailed(state) {
      return { ...initialState };
    },
    setUpdateSuccess(state, payload) {
      return { ...payload };
    },
    setUpdatehFailed(state) {
      return { ...initialState };
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload) {
      try {
        const getApi = await import(
          `@/config/apis/${window.$environment.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('getRegistrationApi');

        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject
          },
          id: payload.apiId
        });
        response && this.setFetchSuccess(response);
      } catch (error) {
        this.setFetchFailed();
      }
    },
    async saveData(payload) {
      try {
        const getApi = await import(
          `@/config/apis/${window.$environment.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('saveRegistrationApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject,
            data: payload.data.data
          },
          id: payload.apiId
        });
        payload.callback && payload.callback('success', response);
      } catch (error) {
        payload.callback && payload.callback('error', error.message, payload);
      }
    }
  })
};
