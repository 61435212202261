import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import RenderComponent from './RenderComponent';
import { UI_LOGBOOK_DATE, UI_DATE_FORMAT } from '@/config/constants/static';
import { hasValue } from '@/utils/utils';
const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));


const ViewForm = ({
  apiStatus,
  fetchLogBookView,
  callback,
  drivers,
  dates,
  unSignedDates,
  currentDriver,
  currentDate,
  fetchLogbookBreadcrumb,
  fetchLogbookBreadcrumbApiId,
  history,
  setFormValues,
  formValues,
  changedDate,
  getDocumentToken,
  regeneratePdf
}) => {
  const match = useParams();
  const { i18n } = useTranslation();
  const viewApiId = 'fetchLogBookByForm';
  const hasApiStatus = hasValue(apiStatus.status, 'api', viewApiId);
  const getDocumentTokenApiId = 'getDocToken';
  return (
    <>
      <Form
        type="server"
        direction="row"
        initialValues={{
          driverId: { ...currentDriver },
          //date: match.date ?  moment(match.date).format(UI_DATE_FORMAT) :  ''
          date: ''
        }}
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {
          fetchLogBookView({
            apiId: viewApiId,
            callback: callback,
            data: { data: { ...fields, driverId: fields.driverId.value } }
          });

          fetchLogbookBreadcrumb &&
            fetchLogbookBreadcrumb({
              apiId: fetchLogbookBreadcrumbApiId,
              data: {
                id: fields.driverId.vehicleId,
                day: moment(fields.date).format(UI_LOGBOOK_DATE)
              }
            });
          changedDate && changedDate(fields.date);
        }}
        customProps={{
          hasApiStatus,
          drivers,
          dates,
          unSignedDates,
          history,
          fetchLogBookView,
          fetchLogbookBreadcrumb,
          fetchLogbookBreadcrumbApiId,
          currentDate,
          setFormValues,
          formValues,
          regeneratePdf
        }}
        validationSchema={Yup.object().shape({
          driverId: Yup.string().required(i18n.t('common.fieldRequiredMessage'))
        })}
      />
    </>
  );
};

ViewForm.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  sendFmcsa: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired
};

export default ViewForm;
