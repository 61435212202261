
import styled from 'styled-components';
import Parent from './style';
import Box from '@material-ui/core/Box';
const LogBookContainer = styled(Parent.LogBookContainer)`
${({ theme }) => `
padding: 0;
flex: 0 0 73%;
.logsInfoDisplay {
  background: ${theme.colors.LINK_COLOR};
  color: ${theme.colors.HEADER_TEXT};
  padding-left:2%;
  font-size: ${theme.palette.titleLabel};
}
form .vertical{
  align-self: flex-start;
}
.viewContainer{
  .buttonBulkDownload {
    padding: 2px 26px !important;
  }
  .buttonBulkDownload,
  .vertical-button button {
    margin-top: 0 !important;
  }
}
.datepopup{
  margin-left: 50%;
}
.heading {
  padding: 10px !important;
  font-size: 18px;
}
.viewContainrer {
  padding-left:2%;
}
.displayContainer { 
  padding : 0 2%;
  padding-bottom: 1%;
}
.linkContainer {
  margin-top: 10px;
}
.linkContainer a {
  background: ${theme.colors.LINK_COLOR};
  color: ${theme.colors.HEADER_TEXT};
  font-size: ${theme.palette.subTitle};
}
.rightSide div[type='info'] p {
  font-size: 22px;
  padding-top: 39px;
}
`}
`;
const PdfContainer = styled(Parent.PdfContainer)`
${({ theme }) => `
width: 100%;
.heading {
  background: ${theme.colors.LINK_COLOR};
  color: ${theme.colors.HEADER_TEXT};
}
.events{
  margin-top: 10px;
  display: grid;
  column-gap: 15px;
  row-gap: 15px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.eventContainer { 
  border: 2px solid ${theme.colors.PRIMARY_BORDER};
  ul {
    list-style: none;
    border-bottom: 1px solid ${theme.colors.PRIMARY_BORDER};
    margin: 0;
    padding: 5px 0;
    padding-left: 10px;
  }
  ul:last-child {
    border-bottom: none;
  }
}
.name {
  color: ${theme.colors.PRIMARY_CONTRAST};
  font-size: 16px;
  font-weight: 600;
  padding-right: 6px;
}
.message {
  width: 100%;
  color: ${theme.colors.PRIMARY_CONTRAST};
  font-size: 14px;
  font-weight: 400;
  display: block;
}
.pdfWrap {
  margin-top: 15px;
  margin-bottom: 40px;
  @media (max-width: ${theme.breakpoints.values.md}px) {
    iframe {
      height: 350px;
    }
  }
}
`}
`;
const MapContainer = styled(Parent.MapContainer)``;
const MapWrapper = styled(Parent.MapWrapper)``;
const ViolationContainer = styled(Parent.ViolationContainer)`
${({ theme }) => `
border: 0;
.heading {
  background: ${theme.colors.LINK_COLOR};
  color: ${theme.colors.HEADER_TEXT};
}
`}
`;
const LogbookWrap = styled(Parent.LogbookWrap)``;
const Styled = {
    LogBookContainer,
    PdfContainer,
    MapContainer,
    MapWrapper,
    ViolationContainer,
    LogbookWrap
  };
  
  export default Styled;