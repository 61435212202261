import styled from 'styled-components';
import Parent from '../style';
import { getImagePath } from '../../../../utils/utils';
const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
  position: relative;
  button:hover {
    background-color: transparent;
  }
  .MuiStepLabel-labelContainer .MuiTypography-root {
    color: #fff;
    opacity: 0.64;
    font-size: 14px;
    font-weight: 400;
    padding-left: 12px;
  }
  .leftMenuWrapper {
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.02);
    background-color: #20263a;
    width: 20%;
    padding-top: 4%;
    position: relative;
  }
  .MuiStepper-root {
    padding: 30% 19%;
    // margin-top: 15%;
  }
  .MuiStepLabel-labelContainer{
    @media (max-width: ${theme.breakpoints.values.md}px) {
      display: none;
     }
  }

  .contentWrapper {
    background-color: #181c2d;
    width: 80%;
    padding:0;
    min-height: 100vh;
    height auto;
    padding-left: 3%;
    .contentWrap {
      height: 100%;
      position: relative;
    }
    .MuiTypography-body1 {
      color: #dbdbdb;
      font-size: 14px;
      font-weight: 400;
    }
    .MuiCircularProgress-root {
      width: 25px !important;
      height: 25px !important;
    }
  }
  .stepTitle {
    font-size: 36px;
    color: #fff;
    font-family: 'SF Pro Display - Semibold';
    font-weight: 600;
    padding-bottom: 2%;
  }
  .MuiStepIcon-root {
    display: block;
    background: #20263a;
    width: 32px;
    height: 32px;
    border: 2px solid rgba(206, 212, 218, 0.6);
    // opacity: 0.6;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    color: transparent;
    .MuiStepIcon-text {
      opacity: 0.63;
      color: #ffffff;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .MuiStepIcon-root.MuiStepIcon-active {
    border: 2px solid #1ea7db;
    color: transparent;
    opacity: 1;
    .MuiStepIcon-text {
      color: #fff;
      font-weight: 400;
      opacity: 1;
    }
  }
  .MuiStepLabel-label.MuiStepLabel-active {
    opacity: 1;
    color: #fff;
    font-weight: 500;
  }
  .MuiStepIcon-root.MuiStepIcon-completed {
    color: #1ea7db;
    border: 0;
  }
  .MuiStepLabel-label.MuiStepLabel-completed{
    opacity: 1;
  }
  .MuiStepConnector-completed .MuiStepConnector-line,
  .MuiStepConnector-active .MuiStepConnector-line {
    border-color: #1ea7db;
    border-left-width: 2px;
  }
  .MuiFormLabel-root {
    color: #dbdbdb;
    font-size: 14px;
  }
  .MuiStepConnector-vertical {
    padding: 0;
    margin-left: 15px;
    height: 40px;
  }
  .MuiStepConnector-line {
    border-color: #ced4da;
    opacity: 0.64;
    height: 100%;
    padding: 0;
  }
  .companyWrapper,
  .addressWrapper,
  .bottomWrapper {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flexbox;
    display: flex;
    padding-bottom: 3%;
    //   justify-content: space-between;
    .MuiBox-root {
      flex-direction: column;
    }
  }
  .referenceGuide {
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    background:  url(${getImagePath('icons/download.svg')}) no-repeat
      left center;
      background-position: 21px;
      padding: 12px 19px;
    border-radius: 4px;
    border:0;
    text-align: right;
    min-width: 160px;
    border: 1px solid #fff;
    height: 45px;
    margin-right: 3%;
    .MuiButton-label {
      padding-left: 34px;
      a {
        color: #fff;
        text-decoration: none;
      }
    }
  }
  .shareVideo {
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    background:  url(${getImagePath('icons/share.svg')}) no-repeat
      left center;
    background-position: 14px;
    padding: 12px 18px;
    border-radius: 4px;
    border:0;
    text-align: right;
    min-width: 160px;
    border: 1px solid #fff;
    height: 45px;
    margin-right: 2%;
  }
  .hardwareButton {
    position: absolute;
    right: 168px;
    text-align: right;
    width: 60%;
    button:hover {
      background-color: transparent;
    }
  }
  .logout {
    color: #fff;
    font-size: 14px;
    padding: 0 15%;
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flexbox;
    display: flex;
    position: absolute;
    bottom: 2%;
    .content {
      padding-left: 4%;
      .nameSection {
        white-space: nowrap;
        width: 116px;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: ${theme.breakpoints.values.md}px) {
          width: 40px;
        }
      }
      span {
        display: block;
      }
    }
    img {
      @media (max-width: ${theme.breakpoints.values.md}px) {
        display: none;
      }
    }
    .labelLogout {
      color: #1ea7db;
      background:  url(${getImagePath('icons/logoutIcon.png')}) no-repeat right;
      background-position: 48px;
      width: 93px;
      cursor: pointer;
      @media (max-width: ${theme.breakpoints.values.md}px) {
        background: none;
      }
    }

  }

  .buttonSection {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flexbox;
    display: flex;
    justify-content: space-between;
    width: 97%;
    height:45px;
    position: relative;
    margin-bottom: 30px;
    margin-top: 30px;
    @media (max-width: ${theme.breakpoints.values.md - 1}px) {
      height: 100px;
     }
    #backButtonStep {
      position: absolute;
      left: 0;
      @media (max-width: ${theme.breakpoints.values.md - 1}px) {
        left: 0;

       }
    }
    #nextButtonStep {
      position: absolute;
      right: 0;
      @media (max-width: ${theme.breakpoints.values.md - 1}px) {
        left: 0;
        bottom: 0;
       }
    }
  }
  .hardareWrapper {
    @media (max-width: ${theme.breakpoints.values.md - 1}px) {
      height: 207px;
      flex-direction: column;
      .hardwareButton {
        text-align: left;
        top: 50px;
        left: 0;
        .referenceGuide{
          margin-bottom: 10px;
          min-width: 240px;
          padding: 12px 11px;
          text-align: left;
        }
      }
     }

  }
  .appDownload {
    @media (max-width: ${theme.breakpoints.values.md - 1}px) {
      height: 150px;
      flex-direction: column;
      .hardwareButton {
        text-align: left;
        top: 50px;
        left: 0;
      }
     }
  }
  .termsButton {
    width: 100%;
    padding-top: 30px;
  }

  #backButtonStep {
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    background: rgba(0, 0, 0, 0.3) url(${getImagePath('icons/arrowRight.svg')}) no-repeat
      left center;
    background-position: 14px;
    padding: 12px 18px;
    border-radius: 4px;
    border:0;
    text-align: right;
    min-width: 160px;
    .MuiButton-label {
      opacity: 0.66;
    }
  }

  #nextButtonStep {
    color: #ffffff;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    background: #1ea7db url(${getImagePath('icons/leftArrow.svg')}) no-repeat
      right center;
    background-position: 127px;
    padding: 12px 18px;
    border-radius: 4px;
    border:0;
    text-align: left;
    min-width: 160px;
    .MuiButton-label {
      justify-content: flex-start;
    }
  }
  .topbutton #nextButtonStep {
    left:0;
    background-position: 187px;
    min-width: 200px;
    width: 224px;
    .MuiButton-label {
      padding-right: 20px;
    }
  }
  div[type='error'] p {
    color: #ff0000 !important;
    padding-top: 0;
    margin-top: -13px;
    font-size: 13px;
  }
  `}
`;

const LogoWrapper = styled.div`
${({ theme }) => `
  background: url(${getImagePath('icons/clutch_logo.png')}) center no-repeat;
  @media (max-width: ${theme.breakpoints.values.md - 1}px) {
    background: url(${getImagePath('icons/logo_mobile.png')}) center no-repeat;
    background-size: contain;
  }
  height: 73px;
  `}
`;
const FieldsWrapper = styled.div`
${({ theme }) => `
display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
  flex-direction: column;
  padding-right: 4%;
  `}
`;

const Styled = { Wrapper, LogoWrapper, FieldsWrapper };

export default Styled;
