
import styled from 'styled-components';
import Parent from './style';

const HeaderContainer = styled(Parent.HeaderContainer)`
${({ theme, vertical, withBackground, withBackgroundPolicy }) => `
margin-top: ${(withBackground || withBackgroundPolicy) ? '-12px': '0'};
#container{
  position: relative;
  padding: ${(withBackground || withBackgroundPolicy) ? '9px 9px 9px 43px': '4px 1%'};
  flex-direction: ${(withBackground || withBackgroundPolicy) ? 'row': 'column'};
  border-radius: ${(withBackground || withBackgroundPolicy) ? '2px': '0'};
  background: ${(withBackground || withBackgroundPolicy) ? '#343e48': 'transparent'};
  align-items: ${(withBackground || withBackgroundPolicy) ? 'center': ''};
  min-height: ${(withBackground || withBackgroundPolicy) ? '56px': ''};
  .hideGoBack {
    display: none;
  }
  .hideGoBack + .searchWrapper h1 {
    padding-left: 0;
    padding-bottom: 27px;
  }
 .goBack {
    position: absolute;
    top: ${(withBackground || withBackgroundPolicy) ? '13px': '10px'};
    left: 11px;
    cursor: pointer;
    svg path {
      fill: ${(withBackground || withBackgroundPolicy) ? '#fff': '#000'};
    }
  }
  .searchWrapper {
    display: flex;
    .spireonImg {
      top: 7px;
      right: 15px;
    }
  }
  h1 {
    flex: 1 0 auto;
    padding-left: ${(withBackground || withBackgroundPolicy) ? '0': '3%'};
    padding-right: ${(withBackground || withBackgroundPolicy) ? '10px': '13px'};
    text-transform: capitalize;
    flex: ${withBackground ? '0 0 45px': '1 0 auto'};
    font-size: 20px;
    color: ${(withBackground || withBackgroundPolicy) ? '#fff': '#000'};
  }
  .logsInfoDisplay {
    font-size: ${(withBackground || withBackgroundPolicy) ? '20px': '18px'};
    color: ${(withBackground || withBackgroundPolicy) ? '#fff': '#000'};
  }
  #simpleSearchWrapper {
    padding-left: 10px;
    div:first-child {
      width: auto !important;
    }
    .MuiInputBase-root {
      min-width: 280px;
    }
  }
}
 
`}
`;
const Styled = {
  HeaderContainer
};
export default Styled;


