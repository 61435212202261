import styled from 'styled-components';
import { getImagePath } from '../../../../utils/utils';

const CheckboxWrapper = styled.div`
  ${({ theme }) => `
  .MuiTypography-body1 {
    font-size: 20px !important;
  }
  .MuiSvgIcon-root {
    border: 2px solid ${theme.colors.LIGHT_BORDER};

    background: colors.BLACK;
  }

  .MuiIconButton-root:hover {
    background-color: transparent;
  }
  .normalCheckbox svg {
    width: 16px;
    height: 16px;
    background: #efefef url(${getImagePath('uncheckedBackground.gif')});
  }
  .Checkbox.checked svg {
    background: #efefef url(${getImagePath('checkedBackground.gif')});
  }
  .normalCheckbox svg rect {
    rx: 0;
    ry: 0;
  }
  .Checkbox .tick{
    stroke: transparent;
  }
  .normalCheckbox .Checkbox.checked .square {
    fill-opacity: 0;
  }
  .normalCheckbox .unchecked.disabledCheck .square {
    fill-opacity: 1 !important;
  }
  .normalCheckbox .Checkbox.unchecked .square {
    fill-opacity: 0;
  }
  .normalCheckbox .unchecked.disabledCheck + span {
    color: #373737 !important;
  }
  `}
`;

export default CheckboxWrapper;
