import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import TopActions from './TopActions';
import { getImagePath } from '@/utils/utils';
import { imageUrls } from '@/config/constants/keys';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Breadcrumb = lazy(() => import(`@/components/Breadcrumb`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));

const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Breadcrumb filters={filters} />
      <Component {...contentProps} />
    </Box>
  );
};
export default Index;

const Component = ({
  driverStatus,
  fetchDriverStatus,
  changeRequest,
  Styled,
  columns,
  i18n,
  topAction,
  pagination,
  setPagination,
  request
}) => {
  const generateRows = () => {
    const updatedList = driverStatus?.aaData?.map((item) => {
      var result = { ...item };
      result.joined =
        item.joined == 'true' ? (
          <Icon src={getImagePath(imageUrls.undelete)} />
        ) : (
          ''
        );
      return result;
    });
    return updatedList || [];
  };

  const information = i18n.t('common.tableEmptyMessage');
  return (
    <>
      <Styled.Wrapper>
        {topAction && (
          <TopActions
            {...{
              changeRequest,
              Styled,
              i18n,
              setPagination,
              request
            }}
          />
        )}
        <Styled.TableWrapper>
          <Table
            type="server"
            defaultSortBy={{ sortBy: 'full_name', order: 'asc' }}
            title=""
            changeRequest={changeRequest}
            baseRequest={request}
            columns={[...columns]}
            data={generateRows()}
            emptyMessage={information}
            actions={[]}
            itemsPerPage={15}
            totalRecords={driverStatus?.iTotalRecords}
            i18n={i18n}
            pageinationReset={pagination}
            pagenationClicked={(e) => {
              setPagination(!e);
            }}
          />
        </Styled.TableWrapper>
      </Styled.Wrapper>
    </>
  );
};

Component.propTypes = {
  driverStatus: PropTypes.object
};
