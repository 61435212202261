import {
  documentUploadApi,
  documentDeleteApi,
  downloadDocumentApi,
  downloadDocApi
} from '../../../../config/apis';

const initialState = {};

export const documents = {
  state: { ...initialState },
  reducers: {
    saveSuccess(state, payload) {
      return {};
    },
    saveFailed() {
      return { ...initialState };
    },
    deleteSuccess(state, payload) {
      return {};
    },
    deleteFailed() {
      return { ...initialState };
    }
  },
  effects: (dispatch) => ({
    async saveData(payload) {
      try {
        const data = payload.data.data;
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: documentUploadApi,
            data: data
          },
          id: payload.apiId,
          // callback: payload.callback
        });
        payload.callback && payload.callback()
      } catch (error) {
        this.saveFailed();
      }
    },
    async deleteData(payload) {
      try {
        const url = documentDeleteApi.url.split('{')[0];
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: { ...documentDeleteApi, url: url + payload.data.id }
          },
          id: payload.apiId
        });
      } catch (error) {
        this.deleteFailed();
      }
    },
    async handleDownloadDoc(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...downloadDocApi,
              url: downloadDocApi.url.replace('{id}', payload.data)
            }
          },
          id: payload.id
        });
        //remove item
        payload.callback('success', response);
      } catch (error) {
        // this.listFailed();
      }
    }
  })
};
