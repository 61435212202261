import styled from 'styled-components';
import Parent from './style';
const Wrapper = styled(Parent.Wrapper)``;
const TableWrapper = styled(Parent.TableWrapper)``;
const Inspectionview = styled(Parent.Inspectionview)`
${({ theme }) => `
.viewAll {
    position: absolute;
    right: 15px;
    top: 18px;
    .roadDetails {
        display: none;
    }
    .viewmore {
        display: block;
        color: ${theme.colors.SECONDARY_MAIN};
        text-decoration: none;
        font-size: 14px;
        font-family: tahoma;
        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
}
`}
`;
const Styled = {
    Wrapper,
    TableWrapper,
    Inspectionview
  };
  export default Styled;