import styled from 'styled-components';

const Wrapper = styled.div`
  ${({ theme }) => `
  .table {
    border: solid 1px ${theme.colors.LIGHT_BORDER};
  }
  .PrivateSwitchBase-root-251 {
    padding: 2px;
  }
  ul {
    list-style: none;
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  }
  ,
  li {
    list-style: none;
    color: white;
    flex: 0 0 25%;
  }
  .choose-portal {
    flex: 0 0 20%;
  }
  p {
    flex: 0 0 8%;
  }
  .header {
    flex-direction: row;
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    padding: 0px;
    border-bottom: solid 1px ${theme.colors.LIGHT_BORDER};
    margin: 0px;
  }
  .headerItem {
    background-color: colors.BACKGROUND;
    color: #f58220;
    list-style: none;
    font-size: 17px;
    padding: 5px;
  }
  .row {
    flex-direction: row;
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    padding: 0px;
    margin: 0px;
    align-items: center;
  }
  .rowItem {
    border-bottom: solid 1px ${theme.colors.LIGHT_BORDER};
    color: #f58220;
    list-style: none;
    font-size: 17px;
    padding: 5px;
  }
  .check {
    text-decoration: underline;
  }
  .subHeader {
    border-bottom: solid 1px ${theme.colors.LIGHT_BORDER};
    padding: 5px;
  }
  `}
`;

export default Wrapper;
