import React, { lazy, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ParentStyled from './style';
import { loadStyle, getNewObject, loadPageConfig } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'pages/web/Report');

const Title = lazy(() => import(`@/components/Title`));
const config = loadPageConfig();
const Content = lazy(() => import(`@/${config.reportsEnabled[0].name}`));

const Report = (parentProps) => {
  useEffect(() => {
    let apiId = 'fetchRegions';
    fetchRegions({ apiId });
    window.$environment.SERVICE_PROVIDER === 'edvir' && fetchCompanies({ apiId: 'fetchCompanies' })
    fetchUsers({
      apiId: 'fetchUsersList'
    });
    !vehicles.vehicleList.length &&
      fetchVehicles({
        apiId: 'fetchVehicles'
      });
    !drivers.data.length && getDrivers({ apiId: 'getDrivers' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { i18n } = useTranslation();
  const {
    history,
    fetchRegions,
    drivers,
    getDrivers,
    fetchUsers,
    vehicles,
    companies,
    fetchVehicles,
    fetchCompanies
  } = parentProps;
  const commonParentProps = { history, i18n, Styled };
  return (
    <>
      <Title header="reports.downloadReports" i18n={i18n} />
      <RenderComponent
        {...config.reportsEnabled[0]}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        component={Content}
      />
    </>
  );
};

const RenderComponent = ({
  name,
  props,
  commonProps,
  title,
  parentProps,
  commonParentProps,
  component,
  ...rest
}) => {
  const Component = component;
  let newProps = props?.length ? getNewObject(parentProps, props) : {};
  newProps = {
    ...newProps,
    ...(commonProps?.length ? getNewObject(commonParentProps, commonProps) : {})
  };
  return <Component {...rest} {...newProps} />;
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    drivers: state.drivers,
    regions: state.regions,
    users: state.users,
    vehicles: state.vehicles,
    companies: state.companies,
  };
};
const mapDispatch = (dispatch) => {
  return {
    dutyStatusReport: (data) => dispatch.reports.dutyStatusReport(data),
    driverPerformanceReport: (data) =>
      dispatch.reports.driverPerformanceReport(data),
    editedDataReport: (data) => dispatch.reports.editedDataReport(data),
    drivingEventReport: (data) => dispatch.reports.drivingEventReport(data),
    eldDisconnectReport: (data) => dispatch.reports.eldDisconnectReport(data),
    odometerJumpReport: (data) => dispatch.reports.odometerJumpReport(data),
    malfunctionReport: (data) => dispatch.reports.malfunctionReport(data),
    billOfLadingReport:(data) => dispatch.reports.billOfLadingReport(data),
    dailyHosViolationReport:(data) => dispatch.reports.dailyHosViolationReport(data),
    dailyUnsignedLogReport:(data) => dispatch.reports.dailyUnsignedLogReport(data),
    dailyFailedInspectionReport:(data) => dispatch.reports.dailyFailedInspectionReport(data),
    dailyUnidentifiedDrivingReport:(data) => dispatch.reports.dailyUnidentifiedDrivingReport(data),
    unassignedDrivingReport: (data) =>
      dispatch.reports.unassignedDrivingReport(data),
    violationReport: (data) => dispatch.reports.violationReport(data),
    downloadReport: (data) => dispatch.reports.downloadReport(data),
    fetchCompanies: (data) => dispatch.companies.fetchData(data),
    hosViolationsReport: (data) => dispatch.reports.hosViolationsReport(data),
    rejectedEditsReport: (data) => dispatch.reports.rejectedEditsReport(data),
    getDrivers: () => dispatch.drivers.getDrivers(),
    fetchRegions: (data) => dispatch.regions.fetchRegions(data),
    fetchTerminals: (data) => dispatch.regions.fetchTerminals(data),
    fetchDepartments: (data) => dispatch.regions.fetchDepartments(data),
    fetchLocations: (data) => dispatch.regions.fetchLocations(data),
    fetchSubDepartments: (data) => dispatch.regions.fetchSubDepartments(data),
    fetchUsers: (data) => dispatch.users.fetchListData(data),
    fetchVehicles: (data) => dispatch.vehicles.fetchData(data)
  };
};

export default connect(mapState, mapDispatch)(Report);
Report.defaultTypes = {
  drivers: {},
  regions: {}
};
Report.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  drivers: PropTypes.object,
  regions: PropTypes.object,
  dutyStatusReport: PropTypes.func.isRequired,
  driverPerformanceReport: PropTypes.func.isRequired,
  editedDataReport: PropTypes.func.isRequired,
  drivingEventReport: PropTypes.func.isRequired,
  eldDisconnectReport: PropTypes.func.isRequired,
  odometerJumpReport: PropTypes.func.isRequired,
  malfunctionReport: PropTypes.func.isRequired,
  unassignedDrivingReport: PropTypes.func.isRequired,
  violationReport: PropTypes.func.isRequired,
  downloadReport: PropTypes.func.isRequired,
  fetchCompanies: PropTypes.func.isRequired,
  hosViolationsReport: PropTypes.func.isRequired,
  rejectedEditsReport: PropTypes.func.isRequired,
  getDrivers: PropTypes.func.isRequired,
  fetchRegions: PropTypes.func.isRequired,
  fetchTerminals: PropTypes.func.isRequired,
  fetchDepartments: PropTypes.func.isRequired,
  fetchLocations: PropTypes.func.isRequired
};
