import Box from '@material-ui/core/Box';
import styled from 'styled-components';
const MainWrapper = styled(Box)`
.cardBox {
    position: relative;
    height: fit-content;
    margin-left: 10px;
}

.beta-banner {
    top: 10px;
    left: 10px;
    background-color: #ffcc00;
    color: #333;
    padding: 5px 10px;
    border-radius: 4px;
    font-weight: bold;
    font-size: 0.9em;
    z-index: 1;
    cursor: default;
}

  padding: 2%;
  .noDataFound {
      grid-column: 1 / 29;
      background-color: #181c2d;
      display: grid;
      grid-gap: 20px;
      grid-template-rows: 1fr;
      text-align: center;
      padding: 2rem;
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 10rem;\
      .icon {
        width: 60px;
        height: 60px;
      }
        .text {
            TEXT-TRANSFORM: capitalize;
        }
  }
  .titleSmall {
    font-size: 16px;
    display: flex;
    gap: 10px;
  }
        .label {
                font-weight: 800;
                text-align: center;
                font-size: 32px;
        }
  .subHeadTooltip {
    display: flex;
    gap: 10px;
  }
    .graph-container {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
    .infoPosition {
        display: flex;
    justify-content: end;

    }
  .card {
    width: 18rem;
    min-height: 5rem;
    height: 100%;
    padding: 6px;
    border-radius: 4px;
    border: solid 1px #979797;
    background-color: #181c2d;
    display: flex;
    .text {
      font-size: 13px;
      color: #eaeaea;
    },
    .count {
      font-size: 30px;
      font-weight: 600;
      color: #eaeaea;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const DIV40 = styled.div`
  ${({ theme, isBottomAlign }) => `
        width: 40%;
        box-sizing: border-box;
        ${isBottomAlign ? `
        align-content: flex-end;
        margin-bottom: 12px;
        ` : `
        align-content: center;
        `}
  `}
`;
const Wrapper = styled(Box)`
  ${({ theme, isBottomAlign }) => `
    display: grid;
    grid-template-columns: repeat(28, 1fr); 
    grid-template-rows: auto;
    grid-gap: 20px;
    height: fit-content !important;
  .divider-style {
    margin-top: 4rem;
    margin-bottom: 4rem;
    background-color: #757575;
    height: 2px;
  }
  .divider-style2 {
    background-color: #757575;
    height: auto;
    margin: 0px 20px;
    width: 2px;
  }
  .smallGraph {
    margin-top: 6px !important;
  }
  .fleet-score-graph {
    display: flex;
    gap: 12px;
    width: 100%;
    .div40 {
      width: 50%;
      box-sizing: border-box;
      ${isBottomAlign ? `
      align-content: flex-end;
      margin-bottom: 12px;
      ` : `
      align-content: center;
      `}
    }
    .div60 {
        width: 50%;
        box-sizing: border-box;
    }
    .totalScore {
        text-align: center;
        font-weight: 800;
        font-size: 32px;
        margin-top: 12px;
    }
  }
  .fleet-score-graph3 {
    display: flex;
    gap: 18px;
    margin-top: 3rem;
    justify-content: center;
    width: 100%;
    .div40 {
      width: 25%;
      box-sizing: border-box;
      align-content: flex-end;
      margin-bottom: 24px;
    }
    .div60 {
        width: 68%;
        box-sizing: border-box;
    }
    .totalScore {
        text-align: center;
        font-weight: 800;
        font-size: 32px;
        margin-top: 12px;
    }
  }
    .fleet-score-graph2 {
    display: flex;
    margin-top: 1rem;
    gap: 2rem;
    width: 100%;
    .div40 {
      width: 40%;
      box-sizing: border-box;
      ${isBottomAlign ? `
      align-content: flex-end;
      margin-bottom: 12px;
      ` : `
      align-content: center;
      `}
    }
    .div60 {
        width: 60%;
        box-sizing: border-box;
    }
    .totalScore {
        text-align: center;
        font-weight: 800;
        font-size: 32px;
        margin-top: 12px;
    }
  }
   .filter {
      // grid-column-start: 1;
      // grid-column-end: 8;
      display: flex;
      justify-content: space-between;
    .horizontal {
      display: block;
    }
  }
  @media (max-width: ${theme.breakpoints.values.lg}px) {
    grid-template-rows: 0fr auto;
  }
  @media (max-width: ${theme.breakpoints.values.md}px) {
    grid-template-rows: 1fr 1fr auto auto;
    grid-template-columns: 1fr 1fr;
  }
  }
  .radius-table {
    grid-column-start: 1;
    grid-column-end: 6;
    background-color: ${theme.colors.HEADER_COLOR};
    padding: 30px;
    border-radius: 5px;
    tr {
      background-color: ${theme.colors.HEADER_COLOR} !important;
    }
    tr:hover {
      background-color: ${theme.colors.HEADER_COLOR} !important;
    }
    tr:nth-child(even) {
      background-color: ${theme.colors.PAGINATION_BACKGROUND} !important;
    }
  }
  grid-gap: 20px;
  height: 100%;
  .grid-item {
    background-color: ${theme.colors.HEADER_COLOR};
    border-radius: 5px;
  }
  .quickStatsHeadingWrapper{
    display: flex;
    justify-content: space-between;
    .choose-period{
      min-width: 200px;
    }
  }
  .reportIconWrapper{
    display: flex;
    align-items: baseline;
    .statsHeading{
      white-space: nowrap;
    }
    img {
      margin-left: 10px;
      cursor: pointer;
      width: 30px;
    }

  }
  .count {
    display: flex;
    padding: 8px;
    .icon-wrapper {
      width: 45px;
      background-color: ${theme.colors.SECONDARY_MAIN};
      border-radius: 50%;
      height: 45px;
      position: relative;
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .summary-wrapper {
      display: flex;
      flex-direction: column;
      padding-left: 10px;
      flex: 0 0 80%;
      .summary-header {
        color: ${theme.colors.LIST_TEXT};
        font-weight: bold;
        text-transform: uppercase;
      }
      .summary-content {
        font-size: 15px;
        color: ${theme.colors.HEADER_TEXT};
      }
    }
  }
  .left {
    grid-column: 1 / 11;
    background-color: ${theme.colors.PRIMARY_MAIN};
    display: grid;
    grid-gap: 20px;

    // grid-column-start: 1;
    // grid-column-end: 6;
    // height: fit-content;
    // background-color: ${theme.colors.PRIMARY_MAIN};
    // display: grid;
    // grid-gap: 20px;
    // grid-template-rows: 1fr;
    // @media (max-width: ${theme.breakpoints.values.md}px) {
    //   grid-column-end: 3;
    //   grid-template-columns: auto;
    // }
    .grid-item {
      background-color: ${theme.colors.HEADER_COLOR};
      padding: 24px;
      #myDiv{
        margin-top: 95px;
      }
    }
    .teritory-details{
      display: grid;
      grid-template-columns: 2fr 7fr 1fr;
      grid-row-gap: 25%;
      grid-column-gap: 1%;
      padding-top: 5%;
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        grid-template-columns: 2fr 4fr 1fr;
        grid-row-gap: 10%;
      }
      @media (max-width: ${theme.breakpoints.values.md}px) {
        grid-template-columns: 2fr 7fr 1fr;
      }
    span{
      text-align: right;
      font-size: 16px;
      color: ${theme.colors.HEADER_TEXT};
    }
    div + span{
      text-align: left;
    }
    }
    .quick-stats-wrapper{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 30px;
      padding-top: 5%;
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        grid-gap: 20px 10px;
      }
      .quick-stats-item{
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        span{
          text-align: center;
          font-size: 16px;
          color: ${theme.colors.HEADER_TEXT};
          padding-top: 5%;
        }
      }
  }
}
    .statsHeading {
      font-weight: 600;
      text-transform: capitalize;
      font-size: 20px !important;
      padding: 0 !important;
      display: flex;
      gap: 8px;
    }
  }
    .center {
    padding: 6%;
    grid-column-start: 4;
      grid-column-end: 6;
    @media (max-width: ${theme.breakpoints.values.md}px) {
      grid-column-start: 1;
      grid-column-end: 3;
    }
    }
  .right{
    grid-column: 11 / 30;
    // grid-column-start: 6;
    // grid-column-end: 15;
    // height: fit-content;
    // @media (max-width: ${theme.breakpoints.values.md}px) {
    //   grid-column-start: 1;
    //   grid-column-end: 3;
    // }
    .grid-item {
      background-color: #20263a;
      padding: 24px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      height: calc(50% - 10px);
      .part1 {
        width: 50%;
        align-content: end;
      }
      .part2 {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        gap: 10px;
    height: auto;
        .row1 {
          width: 100%;
          display: flex;
          justify-content: space-around;
          .col1 {
              width: 50%;
          }
          .col2 {
              width: 50%;
          }
        }
        .row2 {
          display: flex;
          .col1 {
            width: 50%;
          }
        }
      }
      .subCharts {
        display: flex;
      }
      .r1 {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 8;
      }
      .r2 {
        width: 50%;
      }
      .r3 {
        width: 50%;
      }
      .r4 {
        width: 50%;
      }
    }
    .grid-item2 {
      background-color: #20263a;
      padding: 24px;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      height: calc(50% - 10px);
      .part1 {
          width: 49%;
      }
      .part2 {
          width: 49%;
      }
    }
      .topMargin {
      margin-top: 20px;
      }
    .fleet-details{
      display: grid;
      grid-template-columns: 1fr 1fr 2fr;
      color: ${theme.colors.HEADER_TEXT};
    font-size: 16px;
    align-items: baseline;
      &-value{
        font-weight: bold;
      }
    }
  }
  `}
`;

const TableWrapper = styled(Box)`
${({ theme, type }) => `
padding-top: 2%;
.CSAForms {
  justify-content: flex-start;
  .MuiFormLabel-root{
    padding-bottom: 0px !important;
  }
  .vertical-button {
    .MuiBox-root:first-child {
      margin-right: 14px;
    }
    .MuiButtonBase-root {
      margin-top: 24px;
      padding-left: 0;
      padding-right: 0;
      .MuiButton-label {
        padding: 2px 22px;
      }
    }
  }
}
`}
`;

const FilterWrapper = styled.div`
      display: flex;
      justify-content: space-between;
      grid-gap: 20px;
      .horizontal {
        display: block;
      }
      .filter {
          min-width: 26rem;
      }
      .info {
      }

`;

const Styled = {
  Wrapper,
  TableWrapper,
  FilterWrapper,
  MainWrapper,
  DIV40
};
export default Styled;
