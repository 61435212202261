import React, { useEffect, lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RenderComponent from '@/components/modules/DynamicRenderPage';

import ParentStyled from './style';
import { loadStyle, hasEnabledFeature, loadPageConfig, getNewObject, hasRestrictedRole } from '@/utils/utils';
import { pageUrls, COOKIE_AUTH_DETAILS } from '../../../config/constants/keys';
import { getCookie } from '@/services/cookie';
let Styled = loadStyle(ParentStyled, 'pages/web/NoDefectInspections');
const layout = window?.$environment?.CURRENT_LAYOUT;

const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.noDefectInspections[0].name}`));
const Content = lazy(() => import(`@/${config.noDefectInspections[1].name}`));
const TopActions =
  config.inspections[0].children &&
  lazy(() => import(`${config.noDefectInspections[0].children.name}`));
const Tab = lazy(() => import(`../../../components/UI/Tab/${layout}`));

const NoDefectInspections = (parentProps) => {
  const {
    fetchInspections,
    getFilters,
    fetchDriverUserListing,
    getDocumentToken,
    history,
    i18n,
    theme
  } = parentProps;
  const commonParentProps = { history, i18n, Styled, theme };

  const basicRequest = {
    displayLength:
      window.$environment.SERVICE_PROVIDER === 'spireon' ? '10' : '15',
    displayStart: '1',
    isNonDefective: 1
  };
  const [pagination, setPagination] = useState(false);

  const [request, setRequest] = useState({
    ...basicRequest
  });

  const selectChange = (e) => {
    changeRequest({ search: e });
    setPagination(true);
  };

  const changeRequest = (fields) => {
    setRequest({ ...basicRequest, ...fields, isNonDefective: 1 });
  };

  const searchConfig = {
    setPagination,
    searchPlaceholder: 'Search Inspection Logs',
    changeRequest,
    fromData: false
  };

  useEffect(() => {
    const user = getCookie(COOKIE_AUTH_DETAILS);
    let requestToModify = {...request}
    if(hasRestrictedRole(['MECHANIC'])){
      requestToModify = {...request, mechanicId: user?.userId } 
    }
    else if(hasRestrictedRole(['DRIVER'])){
      requestToModify = {...request, userId: user?.userId } 
    }
    fetchInspections({
      apiId: 'fetchInspections',
      data: { request: requestToModify }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request]);

  useEffect(() => {
    fetchDriverUserListing({
      listApiId: 'fetchDriverUser'
    });
    getFilters({ apiId: 'getFilters' });
    getDocumentToken({ apiId: 'getDocToken' });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const isCustomDviRequired = hasEnabledFeature('customDvirEnabled');
  const isAdvancedDviRequired = hasEnabledFeature('advancedDvirEnabled');
  return (
     <RenderComponentTab
        {...config.noDefectInspections[1]}
        parentProps={{
          ...parentProps,
          isAdvancedDviRequired,
          isCustomDviRequired,
          changeRequest,
          request,
          pagination,
          setPagination
        }}
        commonParentProps={commonParentProps}
        component={Content}
        history={history}
        i18n={i18n}
        pagination={pagination}
        setPagination={setPagination}
      />
  );
};

const RenderComponentTab = ({
  name,
  props,
  commonProps,
  title,
  parentProps,
  commonParentProps,
  component,
  history,
  i18n,
  verticalTab,
  selectedIndex,
  setSelectedIndexfn,
  pagination,
  setPagination,
  ...rest
}) => {
  const Component = component;
  const handleChange = (event, newValue) => {
    history.push(tabValues[newValue].url);
  };

  let newProps = props?.length ? getNewObject(parentProps, props) : {};
  newProps = {
    ...newProps,
    ...(commonProps?.length
      ? getNewObject(commonParentProps, commonProps)
      : {}),
    ...rest
  };

  const tabValues = [
    {
      name: 'Pending Mechanic',
      value: (
        <Component
          {...newProps}
          // setIsToolsClicked={setIsToolsClicked}
          selectedIndex={selectedIndex}
          pagination={pagination}
          setPagination={setPagination}
        />
      ),
      url: pageUrls.pendingMechanicInspections
    },
    {
      name: 'Pending Driver',
      value: (
        <Component
          {...newProps}
          // setIsToolsClicked={setIsToolsClicked}
          selectedIndex={selectedIndex}
          pagination={pagination}
          setPagination={setPagination}
        />
      ),
      url: pageUrls.pendingDriverInspections
    },
    {
      name: 'Completed',
      value: (
        <Component
          {...newProps}
          // setIsToolsClicked={setIsToolsClicked}
          selectedIndex={selectedIndex}
          pagination={pagination}
          setPagination={setPagination}
        />
      ),
      url: pageUrls.completedInspections
    },
    {
      name: 'No Defect',
      value: (
        <Component
          {...newProps}
          // setIsToolsClicked={setIsToolsClicked}
          selectedIndex={selectedIndex}
          pagination={pagination}
          setPagination={setPagination}
        />
      ),
      url: pageUrls.noDefectInspections
    }
  ];
  return (
    <Styled.Content>
      <Tab
        data={tabValues}
        className="logwrapper"
        currentTab={3}
        onChange={handleChange}
        vertical={true}
      />
    </Styled.Content>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    inspections: state.inspectionList,
    list: state.inspections,
    filters: state.filters,
    logBook: state.logBook
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchInspections: (data) => dispatch.inspectionList.fetchData(data),
    bulkDownloadReport: (data) => dispatch.logBook.downloadData(data),
    fetchDriverUserListing: (data) => dispatch.inspections.fetchData(data),
    getFilters: (data) => dispatch.filters.fetchData(data),
    getDocumentToken: (data) => dispatch.logBook.documentToken(data),
    clearApiStatus: () => dispatch.asyncRequests.clearApiStatus(),
    viewReport: (data) => dispatch.viewReport.viewReportDocument(data)
  };
};

export default connect(mapState, mapDispatch)(NoDefectInspections);
NoDefectInspections.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  fetchInspections: PropTypes.func.isRequired
};
