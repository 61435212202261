import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
${({theme})=>`
  .filterForm {
    flex: 0 0 83%;
    input{
      background: ${theme.colors.INPUT_BACKGROUND};
    }
    .react-datepicker-wrapper input{
      background-color: ${theme.colors.INPUT_BACKGROUND};
    }
  }
  .exportContainer {
    min-width: 92px;
    .exportWrapper {
      width: 100%;
    }
  }
`}
`;
const TableWrapper = styled(Parent.TableWrapper)``;

const Styled = { Wrapper, TableWrapper };

export default Styled;
