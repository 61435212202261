import styled from 'styled-components';
import Parent from '../Base/style';
const Container = styled(Parent.Container)`
${({ theme}) => `
a {
    color: #425061 !important;
    text-decoration: none;
    &:hover {
      color: #76828b !important;
        text-decoration: underline !important;
    }
}
.MuiTableRow-root{
    background: #fff;
    border-bottom: 0.5px solid #e1e1e1;
}
.active {
    background-color: transparent !important;
    border: 0 !important;
}
.MuiTable-root {
    border: 0;
}
.MuiPaper-root {
    box-shadow: none;
}
.MuiTableSortLabel-active {
    position: inherit;
    padding-left: 0;
}
.MuiTableSortLabel-active .MuiTableSortLabel-iconDirectionAsc {
    left: -25px;
    top: -11px !important;
}
  .MuiTableSortLabel-active .MuiTableSortLabel-iconDirectionDesc {
    left: -25px;
    top: -7px !important;
  }
.MuiTableSortLabel-active .MuiTableSortLabel-iconDirectionAsc,
.MuiTableSortLabel-active .MuiTableSortLabel-iconDirectionDesc {
  svg {
    width: 1.9rem;
    height: 1.6rem;
    color: #8A8A8A;
    top: -2px;
  }
}
.MuiTableSortLabel svg {
    position: relative;
    top: 1px;
}
#sort {
    fill: #DCDCDC;
}
.MuiTableHead-root {
    .MuiTableRow-root:hover {
        background-color: #ffffff;
    }
    .MuiTableCell-head {
        font-size: 12px;
        padding: 10px 15px 10px 25px;
        color: #000 !important;
        font-weight: 700;
        opacity: 0.54;
        .MuiTableSortLabel-root {
            color: #000 !important;
            font-weight: 700;
        }
    }
    .MuiTableCell-root {
        border-bottom: 0;
        font-size: 12px;
        padding: 15px 22px !important;
        position: relative;
    }
    .material-icons.MuiIcon-root.MuiTableSortLabel {
        position: absolute;
        left: -17px;
    }
}
.MuiTableSortLabel-iconDirectionDesc {
    color: #DCDCDC !important;
}
.MuiTableBody-root{
    td {
        color: #222;
    }
    tr:hover {
        background-color: #ffffff !important;
    }
    tr:nth-child(even) {
        background-color: #ffffff;
    }
    tr:nth-child(odd) {
        background-color: #ffffff;
    }
    .MuiTableCell-root {
        border: 0;
        font-size: 13px;
        padding: 15px 22px !important;
    }
} 
`}
`;
const TableContainer = styled(Parent.TableContainer)`
overflow-x: auto;
`;
const PaginationContainer = styled(Parent.PaginationContainer)`
.MuiTablePagination-root{
    .MuiTablePagination-caption {
        font-size: 12px;
    opacity: 0.54;
    }
    .MuiTablePagination-actions {
        .MuiButtonBase-root {
            padding: 0;
        }
        svg.MuiSvgIcon-root {
            width: 30px;
            height: 30px;
        }
    }
    .MuiTablePagination-selectRoot{
        .MuiSelect-root.MuiSelect-select{
            color:#000;
            opacity: 0.54;
            font-size: 12px;
            padding-top: 6px;
            padding-right: 29px;
        }
        svg{
            width: 30px;
            height: 30px;
            top: -1px;
        }
    }
    .MuiSelect-select:focus{
        background-color: transparent;
    }
}
`;

const Styled = { Container, TableContainer, PaginationContainer };

export default Styled;
