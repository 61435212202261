import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getDateLocale } from '@/utils/utils';
import { Field } from 'formik';
import ReactDatePicker from 'react-datepicker';
import CalendarToday from '@material-ui/icons/CalendarToday';

import { UI_DATE_LOWER_FORMAT } from '@/config/constants/static';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Input = lazy(() => import(`@/components/UI/Input/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));

const RenderComponent = ({
  values,
  handleEdit,
  validateForm,
  customProps,
  submitAction,
  cancelAction,
  errors,
  isNotValid
}) => {
  const { i18n } = useTranslation();

  return (
    <>
      <FormStyle.default.FieldsWrapper className="csafield vertical">
        <Label variant="body1" htmlFor="companyName">
          {i18n.t('inspections.searchBycompany')}
        </Label>
        <FormStyle.default.TextWrapper>
        <Field
            name="companyName"
                    handleOnChange={(event) =>
                      handleEdit(event, { field: 'companyName' })
                    }
                    as={Input}
                    type="text"
                  />
          <Message type="error" message={customProps.validationError} />
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.FieldsWrapper className="csafield vertical">
        <Label variant="body1" htmlFor="startDate">
          {i18n.t('common.dateFrom')}
        </Label>
        <FormStyle.default.TextWrapper>
          <Field
            id="startDate"
            name="startDate"
            onChange={(event) => {
              customProps.setDateValidationError('')
              handleEdit(event, { field: 'startDate', type: 'date' });
            }}
            as={ReactDatePicker}
            locale={getDateLocale()}
            placeholderText={UI_DATE_LOWER_FORMAT}
            value={values.startDate}
            showPopperArrow={false}
            showTimeSelect={false}
            maxDate={new Date()}
            showDisabledMonthNavigation
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            isClearable
          />
          {window.$environment.CURRENT_LAYOUT === 'SpireonNew' && (
            <CalendarToday />
          )}
           <Message type="error" message={errors?.startDate} />
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.FieldsWrapper className="csafield vertical">
        <Label variant="body1" htmlFor="endDate">
          {i18n.t('common.dateTo')}
        </Label>
        <FormStyle.default.TextWrapper>
          <Field
            id="endDate"
            name="endDate"
            onChange={(event) => {
              customProps.setDateValidationError('')
              handleEdit(event, { field: 'endDate', type: 'date' });
            }}
            as={ReactDatePicker}
            locale={getDateLocale()}
            placeholderText={UI_DATE_LOWER_FORMAT}
            value={values.endDate}
            showPopperArrow={false}
            showTimeSelect={false}
            maxDate={new Date()}
            showDisabledMonthNavigation
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            isClearable
          />
          <Message type="error" message={customProps.dateValidationError} />
          {window.$environment.CURRENT_LAYOUT === 'SpireonNew' && (
            <CalendarToday />
          )}
           <Message type="error" message={errors?.endDate} />
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.ButtonsWrapper className="vertical-button">
        <Button
          type="submit"
          label={i18n.t('common.view')}
          onClick={(e, d) => {
            const validateResponse = validateForm();
            validateResponse.then((data) => {
              submitAction(data);
            });
          }}
        />
        <Button
          className=""
          type={'reset'}
          label={i18n.t('common.clear')}
          onClick={() => {
            cancelAction();
          }}
        />
      </FormStyle.default.ButtonsWrapper>
    </>
  );
};

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  hasApiStatus: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};

export default RenderComponent;
