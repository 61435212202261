import React from 'react';
import PropTypes from 'prop-types';
import FeedbackForm from './Form';

const Component = ({ apiStatus, callback, history, Styled, sendFeedback, i18n}) => {

  return (
    <Styled.Wrapper>
      <span className="oldOnly">{i18n.t('leftMenu.feedback')}</span>
      <FeedbackForm
        apiStatus={apiStatus}
        history={history}
        callback={callback}
        Styled={Styled}
        sendFeedback={sendFeedback}
      />
    </Styled.Wrapper>
  );
};
export default Component;

Component.propTypes = {
  history: PropTypes.object.isRequired,
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired
};
