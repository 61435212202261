import { lazy } from 'react';
import styled from 'styled-components';
import { getStyle } from '../../../utils/utils';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Label = lazy(() => import(`../../UI/Label/${layout}`));

const Container = styled(Label)`

             font-size: ${({ fontSize }) => fontSize || '20px'};
  variant: ${({ variant }) => variant || 'h2'};
  flex: ${({ style, customStyle }) => (customStyle || {}).flex || ''};
  justify-content:${({ justifyContent }) => justifyContent || ''};
  color: ${({ style, theme, mode }) => {
    return mode === 'light'
      ? theme.colors.WHITE + '!important'
      : getStyle(
          (style || {}).color,
          (theme.palette.secondary || {}).main,
          theme.colors.SECONDARY_MAIN
        ) + '!important';
  }};
  padding: 11px 20px !important;
  width: 100%;
  background: ${({ style, theme, mode }) =>
    mode === 'light'
      ? 'transparent'
      : getStyle(
          (style || {}).backgroundColor,
          (theme.palette.primary || {}).main,
          theme.colors.PRIMARY_MAIN
        ) + '!important'} ; 
    none repeat scroll 0 0;
  margin-bottom: 30px !important;
`;

export default Container;
