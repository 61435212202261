import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
  ${({ theme }) => `
  display: flex;
  flex-direction: column;
  position: relative;
  .CSAForms {
    justify-content: flex-start;
    .vertical-button {
      .MuiBox-root:first-child {
        margin-right: 14px;
      }
      .MuiButtonBase-root {
        margin-top: 29px;
        padding-left: 0;
        padding-right: 0;
        .MuiButton-label {
          padding: 2px 22px;
        }
      }
    }
  }
  .viewReport{
    color: ${theme.colors.LINK_COLOR};
   text-decoration:none;
   cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .buttonWrapper {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 2%;
    .advancedDVIR {
      margin-right: 1%;
    }
    button {
      margin-top: 0;
    }
  }
  .quickFilter{
    display: none;
  }
  .circle_red, .circle_yellow, .circle_orange, .circle_green{
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
  .circle_red {
    background: ${theme.colors.RED};
}
.circle_yellow {
  background: ${theme.colors.WARNING};
}
.circle_orange {
  background: ${theme.colors.LINK_COLOR};
}
.circle_green {
  background: ${theme.colors.GREEN_THREE};
}
  `}
`;
const TopWrapper = styled(Box)`
  ${({ theme }) => `
  display: flex;
  justify-content: space-between;
  .exportContainer .MuiAvatar-root {
    width: 30px;
    height: 30px;
  }
  @media (max-width: ${theme.breakpoints.values.smd}px)  {
    flex-direction: column;
  }
  `}
`;

const TableWrapper = styled(Box)`
  padding-top: 2.5%;
  .driverstatusTitle{
    display: none;
  }
  .noPointer{
    .MuiAvatar-img {
      cursor: unset !important;
    }
  }
  .overdue-flag{
    background: #652631;
    padding: 2px 7px;
    border-radius: 3px;
    font-size: 11px;
    font-weight: 600;
    color: #f7070a;
  }

  table {
    .MuiTableBody-root .MuiTableCell-root .MuiGrid-container.iconContainer {
      justify-content: center;
    }
    .MuiTable-root .MuiTableHead-root .MuiTableRow-root .MuiTableCell-head {
      padding: 10px 8px 10px 7px !important;
    }
    .MuiTableSortLabel-root .MuiTableSortLabel{
      position: unset !important;
      padding: 0 4px;
      display: inline-block;
    }
    .MuiTableSortLabel-root{
      align-items: flex-start;
    }
    .MuiIcon-root MuiTableSortLabel-iconDirectionAsc{
      display: inline-flex;
    }
    .MuiTableSortLabel-active .MuiTableSortLabel-iconDirectionAsc{
      position: unset; 
      display: flex;
      margin-bottom: -7px;

    }
    .MuiTableSortLabel-active .MuiTableSortLabel-iconDirectionDesc{
      position:unset;
      margin-top: -7px;
      display:flex;
    }
  }
`;

const Styled = { Wrapper, TopWrapper, TableWrapper };

export default Styled;
