import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import { imageUrls } from '../../../config/constants/keys';
import { getImagePath,getLogo, getStyle } from '../../../utils/utils';

const Wrapper = styled(Box)`
  ${({ theme }) => `
  .tabs {
    padding: 2%;
    .MuiTab-wrapper {
      flex-direction: row;
      .iconContainer{
        flex-basis: 5%;
      }
      svg{
        margin-right: 10px;
      }
      @media (max-width: ${theme.breakpoints.values.mlg - 1}px) {
        display: none;
      }
    }
    .Mui-selected  .MuiTab-wrapper {
      color: ${theme.colors.ORANGE};
    }
    .MuiButtonBase-root.MuiTab-root {
      width:12.5%;
      padding: 15px;
     
    }
    .MuiTabs-flexContainer{
      justify-content: center;
    }
    header{
      background-color: ${theme.colors.HEADER_COLOR};
      border-radius: 7px 7px 0 0;
      border-top: 1px solid ${theme.colors.HEADER_COLOR};
      border-bottom: 2px solid ${theme.colors.PRIMARY_BORDER};
      box-shadow: none;
      .MuiTabs-flexContainer{
        box-shadow: none;
        border: none;
      }
    }
    .MuiButtonBase-root.MuiTab-root{
      width: 16%;
      padding: 10px 0px;
      border-left: 1px solid ${theme.colors.PRIMARY_BORDER};
      border-right: 1px solid ${theme.colors.PRIMARY_BORDER};
      margin: 0;
    }
    .MuiTabs-flexContainer .Mui-selected{
      background-color: ${theme.colors.HEADER_COLOR};
    }
    .Mui-selected .MuiTab-wrapper{
      color: ${theme.colors.INPUT_COLOR};
      font-weight: bold;
      svg path{
        stroke: ${theme.colors.SECONDARY_MAIN} !important;
        fill: ${theme.colors.SECONDARY_MAIN};
      }
    }
    .MuiTab-root:hover .MuiTab-wrapper{
      color: ${theme.colors.INPUT_COLOR};
    }
    .severity-wrapper {
      width: 150px;
      height: 10px;
      display: flex;
      .addBorder {
        border-right: 0.1px solid black;
      }
      .leftCurve{
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
      }
      .rightCurve {
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
        border-right: none !important;
      }
      .green-wrapper{
        background: #00ff00;
        height: 100%;        
      }
      .yellow-wrapper {
        background: #ffff00;
        height: 100%;
      }
      .red-wrapper{
        height: 100%;
        background: #ff0000;
      }
    }
  }
  .tooltiptext {
    visibility: hidden;
    width: 100px;
    background: ${getStyle(
      '',
      (theme.palette.primary || {}).main,
      theme.colors.GREY_ONE
    )};
    color: ${theme.colors.WHITE};
    text-align: center;
    padding: 2px 0;
    border-radius: 0px;
    border: solid 1px ${theme.colors.WHITE};
    position: absolute;
    z-index: 1;
    right: 0;
    top: 35px !important;
    font-size: 13px;
  }

  .iconContainer .MuiAvatar-root {
    // width: 15px;
    // height: auto;
  }
  .iconContainer .MuiAvatar-rounded {
    border-radius: 0px;
  }
  .iconContainer:hover + .tooltiptext {
      visibility: visible;
    }
  }
  .iconContainer:first-child {
    margin-right: 8px;
  }
  .mapSection {
    padding: 2% 2% 0 2%;
    #map {
      border: 0;
      border-radius: 7px;
    }
  }
  `}
`;

const HeaderWrapper = styled.ul`
  ${({ theme }) => `
  list-style: none;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  flex-wrap: wrap;
  margin-top: 0;
  width: 100%;
  margin-bottom: 0;
  border-left: 1px solid ${theme.colors.LIGHT_BORDER};
  padding: 0.4% 0.9%;
  padding-right: 0;
  li {
    border-right: 1px solid ${theme.colors.LIGHT_BORDER};
    padding: 0 16px;
    flex: 0 0 11.1%;
    @media (max-width: ${theme.breakpoints.values.md - 1}px) {
      flex: 0 0 20%;
    }
    div:first-child {
      color: ${theme.colors.GREY};
      font-size: 16px;
      font-weight: normal;
      @media (max-width: ${theme.breakpoints.values.md - 1}px) {
        font-size: 12px;
      }
    }
    div:last-child {
      color: ${theme.colors.WHITE};
      font-size: 26px;
      font-weight: bold;
      @media (max-width: ${theme.breakpoints.values.md - 1}px) {
        font-size: 14px;
      }
    }
  }
  a:hover {
    text-decoration: none;
  }

  .total {
    color: ${theme.colors.SECONDARY_MAIN} !important;
  }
  .noLink {
    color: ${theme.colors.PRIMARY_CONTRAST} !important;
  }
  `}
`;

const MapWrapper = styled.div``;
const ClutchHeader = styled.div`
  ${({ theme }) => `
display: flex;
box-shadow: 0 2px 10px rgba(0, 0, 0, 0.03);
background-color: #20263a;
padding: 1% 2%;
justify-content: space-between;
flex-wrap: wrap;
.clutchLogo {
  flex: 0 0 54%;
  background:  url(${getLogo(imageUrls.innerLogo)}) no-repeat left;
  @media (max-width: ${theme.breakpoints.values.lg}px) {
    flex: 0 0 30%;
  }
  @media (max-width: ${theme.breakpoints.values.md}px) {
    flex: 0 0 20%;
  }
  @media (max-width: ${theme.breakpoints.values.smd}px) {
    background:  url(${getImagePath(
      'icons/mobileInnerlogo.png'
    )}) no-repeat left;
    background-size: 26px;
    flex: 0 0 10%;
  }
}
.userprofile {
  color: #fff;
  font-size: 14px;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  flex: 0 0 10%;
  justify-content: flex-end;
  .userdetails {
    border-radius: 50%;
    margin-right: 5%;
    @media (max-width: ${theme.breakpoints.values.md}px) {
      display: none;
    }
  }
  .nameSection {
    white-space: nowrap;
    width: 95px;
    text-align: right;
    padding-right: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    align-self: center;
    @media (max-width: ${theme.breakpoints.values.md}px) {
      display: none;
    }
  }

  .labelLogout {
    color: #1ea7db;
    background:  url(${getImagePath('icons/logoutIcon.png')}) no-repeat center;
    width: 34px;
    cursor: pointer;
    background-color: #181c2d;
    height: 34px;
    border-radius: 3px;
    align-self: center;
    &:hover {
      opacity: 0.5;
      border: 1px solid #fff;
    }
  }

}
.countWrapper {
  flex: 0 0 25%;
  display: flex;
  justify-content: flex-end;
  @media (max-width: ${theme.breakpoints.values.lg}px) {
    flex: 0 0 40%;
  }
  @media (max-width: ${theme.breakpoints.values.md}px) {
    flex: 0 0 20%;
  }
}
.countItem:last-child {
  padding-left: 15px;
  flex: 0 0 42%;
}
.countItem {
  flex: 0 0 39%;
  position: relative;
  display: flex;
  &:after {
    content: "";
    position: absolute;
    background: #2C3249;
    right: 0;
    top: 6px;
    height: 29px;
    border: 0.1px solid #2C3249;
  }
  .count  {
    color: #fff;
    font-size: 30px;
    text-decoration: none;
    font-weight: 600;
  }
  img {
    padding-right: 10px;
  }
  .countText {
    color: ${theme.colors.HEADER_LIST_TEXT};
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    width: 70px;
    align-self: center;
    padding-left: 8px;
  }
}
`}
`;
const DriverDashboard = styled.div`
  ${({ theme, newUI, fullWidth }) => `
  display: flex;
  justify-content: space-between;
  ${(!window?.$environment?.clutchNewUI && window.$environment?.SERVICE_PROVIDER !== 'riskTheory' && window.$environment?.SERVICE_PROVIDER !== 'azuga') ? `padding: 2%;`: '' }
  flex-wrap: wrap;
  @media (max-width: ${theme.breakpoints.values.lg + 1}px) {
   flex-direction: column;
  }
  .driverBehaviour .MuiBox-root {
    background-color: ${theme.colors.HEADER_COLOR} !important;
  }
  .descPopUp{
    color: ${theme.colors.SECONDARY_MAIN};
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
  .dashboardComponents {
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.02);
    background-color: ${theme.colors.HEADER_COLOR};
    ${(!window?.$environment?.clutchNewUI && window.$environment?.SERVICE_PROVIDER !== 'riskTheory' && window.$environment?.SERVICE_PROVIDER !== 'azuga')? `border-radius: 7px`: ''};
    .headingsWrapper {
      padding: 20px;
      padding-left: 20px;
      padding-bottom: 10px;
      display: flex;
      justify-content: space-between;
    }
    .mainHeading {
      color: ${theme.colors.TITLE_COLOR};
      font-size: 25px;
      font-weight: 700;
    }
    .viewAll {
      color: ${theme.colors.LINK_COLOR};
      font-size: 12px;
      font-weight: 600;
      padding-left: 36px;
      background: url(${getImagePath(
        'icons/viewArrow.svg'
      )}) no-repeat right center;
      background-position: 85px 11px;
      min-width: 110px;
      align-self: center;
      padding-top: 8px;
      cursor: pointer;
      text-decoration: none;
    }
  }
.dashboardDriver {
  flex: ${window.$environment?.clutchNewUI || window.$environment?.SERVICE_PROVIDER === 'riskTheory' || window.$environment?.SERVICE_PROVIDER === 'azuga' || fullWidth ? '0 0 100%' :'0 0 70%' };
  @media (max-width: ${theme.breakpoints.values.lg + 1}px) {
    flex: 0 0 100%;
    width: 100%;
  }
  table {
    .MuiTableRow-head {
      border-top: 0;
      border-bottom: 1px solid #dfe3eb;
    }
    .MuiTableBody-root .MuiTableCell-root {
      font-size: 13px;
    }
    .MuiTableRow-root {
      background-color: ${theme.colors.HEADER_COLOR};
    }
    .MuiTableBody-root tr:nth-child(odd) {
      background: ${theme.colors.HEADER_COLOR};
    }
    .MuiTableRow-root:hover {
    background-color: ${theme.colors.HEADER_COLOR};
    }
    // .MuiTableBody-root tr:nth-child(even) {
    //     background-color: #292F46;
    // }
    .MuiTableCell-head {
      font-size: 10px;
      padding: 15px 11px 10px 8 !important;
    }
    .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:last-child {
      padding-left: 10px !important;
      .iconContainer .MuiAvatar-root {
        // width: 12px !important;
      }
    }
  }
}

.dashboardDriverBehaviour{
  flex: 0 0 100%;
}
.otherHeading {
    width: 100%;
    border-bottom: 2px solid ${theme.colors.PRIMARY_BORDER};
}
.dashboardViolation {
  flex: 0 0 29%;
  @media (max-width: ${theme.breakpoints.values.lg + 1}px) {
    flex: 0 0 100%;
    margin-top: 24px;
    width: 100%;
  }
  .headingsWrapper {
    padding-left: 27px !important;
  }
}
ul {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
}
li {
  padding-left: 20px;
  border-bottom: 1px solid #2C3249;
  padding-bottom: 10px;
  line-height: 1.6;
}
li:last-child {
  border-bottom: 0;
}
.noDatavioltion {
  color: ${theme.colors.HEADER_TEXT};
  font-size: 14px;
  text-align: center;
  width: 100%;
  padding-top: 2%;
  text-transform: capitalize;
}
.name {
  color: ${newUI? '#000': '#ffffff'};
  font-size: 12px;
  font-weight: 600;
  padding-right: 6px;
}
.id {
  color: #ffffff;
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
}
.message {
  width: 100%;
  opacity: 0.6;
  color: ${(newUI || window.$environment.WHITE_THEME)? '#000':  '#dee7ff'};
  font-size: 13px;
  font-weight: 300;
  display: block;
}
`}
`;

const Styled = {
  Wrapper,
  HeaderWrapper,
  MapWrapper,
  ClutchHeader,
  DriverDashboard
};

export default Styled;
