import React, { lazy, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useTheme from '@material-ui/core/styles/useTheme';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import ParentStyled from './style';
import { loadStyle, getNewObject, loadPageConfig } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'pages/web/AddOrEditNewForm');
const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.addOrEditNewFormTrailer[0].name}`));
const Content = lazy(() => import(`@/${config.addOrEditNewFormTrailer[1].name}`));

const CustomDvir = (parentProps) => {
  const {
    callback,
    getCustomDvirForm,
    listAllVehicles,
    fetchCustomDvir,
  } = parentProps;
  const match = useParams();
  const apiId = 'getCustomDvirForm';

  const history = useHistory();
  const theme = useTheme();
  const i18n = useTranslation();
  const commonParentProps = { history, i18n, Styled, theme };

  useEffect(() => {
    fetchCustomDvir({
      apiId: 'fetchCustomDvir'
    });
    listAllVehicles({ apiId: 'listAllVehicles' });
    if (match.id) {
      getCustomDvirForm({
        apiId,
        data: {
          id: parseInt(match.id, 10)
        },
        callback: callback
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <RenderComponent
        {...config.addOrEditNewForm[0]}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        component={Title}
        header={
          match.id
            ? i18n.t('inspections.editForm')
            : i18n.t('inspections.addForm')
        }
      />
      <RenderComponent
        {...config.addOrEditNewForm[1]}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        component={Content}
      />
    </>
  );
};

const RenderComponent = ({
  name,
  props,
  commonProps,
  title,
  parentProps,
  commonParentProps,
  component,
  ...rest
}) => {
  const Component = component;
  let newProps = props?.length ? getNewObject(parentProps, props) : {};
  newProps = {
    ...newProps,
    ...(commonProps?.length ? getNewObject(commonParentProps, commonProps) : {})
  };
  return <Component {...rest} {...newProps} />;
};
const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    customDvir: state.customDvir,
    filters: state.filters
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchCustomDvir: (data) => dispatch.customDvir.fetchData(data),
    listAllVehicles: (data) => dispatch.customDvir.listAllTrailers(data),
    getCustomDvirForm: (data) => dispatch.customDvir.getCustomDvirForm(data),
    postCustomDvirForm: (data) => dispatch.customDvir.postCustomDvirForm(data)
  };
};

export default connect(mapState, mapDispatch)(CustomDvir);
CustomDvir.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  getCustomDvirForm: PropTypes.func.isRequired
};
