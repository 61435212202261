import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Styled from '../style';
const layout = window?.$environment?.CURRENT_LAYOUT;
const Table = lazy(() => import(`../../../../components/UI/Table/${layout}`));
const PotentialViolations = ({ potentialViolations, heading }) => {
  const { i18n } = useTranslation();
  const monthArray = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  const formatTime = (data) => {
    let date = new Date(data);
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = ('0' + date.getDate()).slice(-2);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = `${monthArray[month]} ${day}, ${year} ${hours}:${minutes} ${ampm}`;
    return strTime;
  };
  return (
    <Styled.DriverDashboard>
      {heading ? (
        <div className="dashboardComponents dashboardViolation">
          <div className="headingsWrapper">
            <span className="mainHeading">{i18n.t(heading)}</span>
          </div>
        </div>
      ) : (
        ''
      )}
      {!potentialViolations.length ? (
        <span className="noDatavioltion">{i18n.t('No data Available')}</span>
      ) : (
        <ul>
          {potentialViolations?.map((item) => {
            return item.items ? (
              <li>
                <span className="name">{item.items[0]}</span>
                <span className="id"> (ID:{item.items[1]})</span>
                <span className="message">{item.items[3]}</span>
              </li>
            ) : (
              <li>
                <span className="name">{formatTime(item.date)}</span>
                <span className="message">{item.description}</span>
              </li>
            );
          })}
        </ul>
      )}
    </Styled.DriverDashboard>
  );
};

export default PotentialViolations;

PotentialViolations.defaultProps = {};

PotentialViolations.propTypes = {};
