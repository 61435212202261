import React, { lazy } from 'react';

const layout = window?.$environment?.CURRENT_LAYOUT;

const Title = lazy(() => import(`../../../../components/Title/${layout}`));
const Test = ({ auth, history, ...rest }) => {
  return (
    <>
      <Title header={'Test'} />
    </>
  );
};

export default Test;
