import { getVehicles } from '../../../config/apis';

const initialState = {};

export const vehicles = {
  state: { ...initialState },
  reducers: {
    setSuccess: (state, data) => {
      return {
        //Mock data
        ...state,
        data: {
          ...state.data,
          regions: [
            {
              label: 'Region1',
              value: '1'
            },
            {
              label: 'Region2',
              value: '2'
            },
            {
              label: 'Region3',
              value: '3'
            }
          ]
        }
      };
      // return { ...state, data };
    },
    setFailed: (state, data) => {
      return {
        //Mock data
        ...state,
        data: {
          ...state.data,
          terminals: [
            {
              label: 'Terminal1',
              value: '1'
            },
            {
              label: 'Terminal2',
              value: '2'
            },
            {
              label: 'Terminal3',
              value: '3'
            }
          ]
        }
      };
      // return { ...state, data };
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload) {
      const response = await dispatch.asyncRequests.handleAsyncRequests({
        payload: {
          api: getVehicles
        },
        id: payload.apiId
      });
      if (response) {
        this.setSuccess();
      } else {
        this.setFailed();
      }
    }
  })
};
