/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import useTheme from '@material-ui/core/styles/useTheme';
import PropTypes from 'prop-types';
import { hasEnabledFeature } from '@/utils/utils'
import Component from './Component';
import Styled from './style';

const DriverBehavior = ({
  apiStatus,
  history,
  callback,
  fetchDriverBehaviorDetails,
  driverbehavior,
  fetchFuelEconomy,
  ...rest
}) => {
  const theme = useTheme();
  const apiId = 'fetchDriverDetails';
  const [hardDeccelerationData, sethardDeccelerationData] = useState(null);
  const [hardAccelerationData, sethardAccelerationData] = useState(null);
  const [speedingData, setspeedingData] = useState(null);
  const [driveHrsExceedingData, setdriveHrsExceedingData] = useState(null);
  const [fuelEconomyData, setfuelEconomyData] = useState(null);
  const [seatBeltWarningData, setseatBeltWarningData] = useState(null);
  const [hardTurnData, sethardTurnData] = useState(null);

  useEffect(() => {
    // if (Object.keys(driverbehavior.data).length === 0) {
    if (hasEnabledFeature('geometrisEnabled') || hasEnabledFeature('cameraIntegrationEnabled')) {
      fetchDriverBehaviorDetails({
        apiId
      });
      fetchFuelEconomy({
        apiId: 'fetchFuelEconomy'
      })
    }
    // }
  }, []);

  useEffect(() => {
    // if (Object.keys(driverbehavior.data).length !== 0) {
    sethardDeccelerationData(
      formatDataForChart(driverbehavior.hardDeceleration.chart)
    );
    sethardAccelerationData(
      formatDataForChart(driverbehavior.hardAcceleration.chart)
    );
    setspeedingData(formatDataForChart(driverbehavior.speeding.chart));
    setdriveHrsExceedingData(
      formatDataForChart(driverbehavior.driveHrsExceeding.chart)
    );
    setfuelEconomyData(formatDataForChart(driverbehavior.fuelEconomy.chart));
    setseatBeltWarningData(
      formatDataForChart(driverbehavior.seatBeltWarning.chart)
    );
    sethardTurnData(formatDataForChart(driverbehavior.hardTurn.chart));
    // }
  }, [driverbehavior]);
  const formatDataForChart = (responseData) => {
    let tempData = {
      labels: [],
      data: []
    };
    if (responseData) {
      responseData.forEach(function (item) {
        tempData['labels'].push(item.date);
        tempData['data'].push(item.count);
      });
    }
    return tempData;
  };
  return (
    <>
      <Box style={{ ...theme.palette.contentStyle, ...(window.$environment.WHITE_THEME ? { backgroundColor: 'rgb(255 255 255)' } : {}) }}>
        <Component
          {...rest}
          history={history}
          apiStatus={apiStatus}
          callback={callback}
          driverbehavior={driverbehavior}
          hardDeccelerationData={hardDeccelerationData}
          hardAccelerationData={hardAccelerationData}
          speedingData={speedingData}
          driveHrsExceedingData={driveHrsExceedingData}
          fuelEconomyData={fuelEconomyData}
          seatBeltWarningData={seatBeltWarningData}
          hardTurnData={hardTurnData}
          Styled={Styled}
          theme={theme}
        />
      </Box>
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    driverbehavior: state.driverbehavior
  };
};

const mapDispatch = (dispatch) => {
  return {
    fetchDriverBehaviorDetails: (data) =>
      dispatch.driverbehavior.fetchDriverBehaviorDetails(data),
    fetchFuelEconomy: (data) => dispatch.driverbehavior.fetchFuelEconomy(data)
  };
};

export default connect(mapState, mapDispatch)(DriverBehavior);

DriverBehavior.propTypes = {
  callback: PropTypes.func.isRequired,
  apiStatus: PropTypes.object.isRequired,
  fetchDriverBehaviorDetails: PropTypes.func.isRequired,
  driverbehavior: PropTypes.object.isRequired
};
