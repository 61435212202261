import React, { useState, lazy } from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import ParentStyled from './style';
import { loadStyle, hasRestrictedRole } from '@/utils/utils';
import { COOKIE_AUTH_DETAILS } from '@/config/constants/keys';
import { getCookie } from '@/services/cookie';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Modal = require(`../UI/Modal/${layout}`).default;
const ErrorIcon = lazy(() => import(`../ErrorIcon/${layout}`));
const SuccessIcon = lazy(() => import(`../SuccessIcon/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const token = getCookie(COOKIE_AUTH_DETAILS);
let Styled = loadStyle(ParentStyled, 'components/Breadcrumb');

const Breadcrumb = ({ filters, saveFilters, fetchCountData }) => {
  const [ModalListObject, setModalListObject] = useState({
    header: '',
    content: () => ''
  });
  const i18n = useTranslation();
  const saveFiltersApi = 'saveFilters';
  const [openCallback, setOpenCallback] = useState(false);
  return filters?.regionName && window.$environment.SERVICE_PROVIDER !== 'spireon' ? (
    <Styled.Wrapper className="filterdetails">
      <span className="appliedBreadcrumb">{i18n.t('common.breadcrumb')}</span>
      {filters.regionName && (
        <span
          className="breadCrumbTag"
          onClick={() =>
            (token?.accessLevel > 2 || hasRestrictedRole(['OWNER'])) &&
            saveFilters({
              apiId: saveFiltersApi,
              data: { data: { regionId: filters.regionId }, isReset: false },
              callback: (type, message) => {
                setModalListObject({
                  header: '',
                  content: () => (
                    <div className="successpopup">
                      {type === 'error' ? <ErrorIcon /> : <SuccessIcon />}
                      <span className="warningPopupMessage">
                        {i18n.t(message)}
                      </span>
                      <div className="deletePopupBtn">
                        <Button
                          type="submit"
                          label={i18n.t('common.ok')}
                          onClick={() => {
                            if (type === 'success' && layout === 'Clutch') {
                              fetchCountData({
                                apiId: 'fetchCountData'
                              });
                            }
                            setOpenCallback(false);
                            window.location.reload();
                          }}
                        />
                      </div>
                    </div>
                  )
                });
                setOpenCallback(true);
              }
            })
          }
        >
          {filters.regionName}
        </span>
      )}
      {filters.terminalName && (
        <span
          className="breadCrumbTag"
          onClick={() =>
            (token?.accessLevel > 1 || hasRestrictedRole(['OWNER'])) &&
            saveFilters({
              apiId: saveFiltersApi,
              data: {
                data: {
                  regionId: filters.regionId,
                  terminalId: filters.terminalId
                },
                isReset: false
              },
              callback: (type, message) => {
                setModalListObject({
                  header: '',
                  content: () => (
                    <div className="successpopup">
                      {type === 'error' ? <ErrorIcon /> : <SuccessIcon />}
                      <span className="warningPopupMessage">
                        {i18n.t(message)}
                      </span>
                      <div className="deletePopupBtn">
                        <Button
                          type="submit"
                          label={i18n.t('common.ok')}
                          onClick={() => {
                            if (type === 'success' && layout === 'Clutch') {
                              fetchCountData({
                                apiId: 'fetchCountData'
                              });
                            }
                            setOpenCallback(false);
                            window.location.reload();
                          }}
                        />
                      </div>
                    </div>
                  )
                });
                setOpenCallback(true);
              }
            })
          }
        >
          <span className="arrow">&gt;</span>
          {filters.terminalName}
        </span>
      )}
      {filters.departmentName && (
        <span
          className="breadCrumbTag"
          onClick={() =>
            (token?.accessLevel > 0 || hasRestrictedRole(['OWNER'])) &&
            saveFilters({
              apiId: saveFiltersApi,
              data: {
                data: {
                  regionId: filters.regionId,
                  terminalId: filters.terminalId,
                  departmentId: filters.departmentId
                },
                isReset: false
              },
              callback: (type, message) => {
                setModalListObject({
                  header: '',
                  content: () => (
                    <div className="successpopup">
                      {type === 'error' ? <ErrorIcon /> : <SuccessIcon />}
                      <span className="warningPopupMessage">
                        {i18n.t(message)}
                      </span>
                      <div className="deletePopupBtn">
                        <Button
                          type="submit"
                          label={i18n.t('common.ok')}
                          onClick={() => {
                            if (type === 'success' && layout === 'Clutch') {
                              fetchCountData({
                                apiId: 'fetchCountData'
                              });
                            }
                            setOpenCallback(false);
                            window.location.reload();
                          }}
                        />
                      </div>
                    </div>
                  )
                });
                setOpenCallback(true);
              }
            })
          }
        >
          <span className="arrow">&gt;</span>
          {filters.departmentName}
        </span>
      )}
      {filters.subDepartmentName && (
        <span
          className="breadCrumbTag"
          onClick={() =>
            saveFilters({
              apiId: saveFiltersApi,
              data: {
                data: {
                  regionId: filters.regionId,
                  terminalId: filters.terminalId,
                  departmentId: filters.departmentId,
                  subDepartmentId: filters.subDepartmentId
                },
                isReset: false
              },
              callback: (type, message) => {
                setModalListObject({
                  header: '',
                  content: () => (
                    <div className="successpopup">
                      {type === 'error' ? <ErrorIcon /> : <SuccessIcon />}
                      <span className="warningPopupMessage">
                        {i18n.t(message)}
                      </span>
                      <div className="deletePopupBtn">
                        <Button
                          type="submit"
                          label={i18n.t('common.ok')}
                          onClick={() => {
                            if (type === 'success' && layout === 'Clutch') {
                              fetchCountData({
                                apiId: 'fetchCountData'
                              });
                            }
                            setOpenCallback(false);
                            // action();
                            // fetchFilters({ apiId: 'fetchFilters' });
                            window.location.reload();
                          }}
                        />
                      </div>
                    </div>
                  )
                });
                setOpenCallback(true);
              }
            })
          }
        >
          <span className="arrow">&gt;</span>
          {filters.subDepartmentName}
        </span>
      )}
      <Modal
        open={openCallback}
        setOpen={setOpenCallback}
        data={ModalListObject}
        isSuccess
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
      />
    </Styled.Wrapper>
  ) : null;
};

const mapState = (state) => {
  return {};
};
const mapDispatch = (dispatch) => {
  return {
    saveFilters: (data) => dispatch.filters.saveData(data),
    fetchCountData: (data) => dispatch.dashboard.fetchCountData(data)
  };
};

export default connect(mapState, mapDispatch)(Breadcrumb);

Breadcrumb.propTypes = {
  filters: PropTypes.object
};
