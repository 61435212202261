import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
  ${({ theme }) => `
  .MuiTypography-body1 {
    font-size: 18px;
  }
  .filepond--wrapper {
    .filepond--drop-label label {
      color: ${theme.colors.PRIMARY_CONTRAST};
      opacity: 1;
    }
  }
  form {
    div:last-child {
      justify-content: center;
      #button {
        width: 210px;
        margin-top: 0;
      }
    }
  }
  div[type='error'] p {
    font-size: 16px;
  }
  .MuiTypography-h1{
    ${window?.$environment?.clutchNewUI && `
    margin-bottom: 10px !important;
    padding-left: 0 !important;`}
    
}
  }
  `}
`;
const UploadWrapper = styled(Box)`
 .react-datepicker-wrapper, .filepond--wrapper {
  margin-bottom: 13px !important;
 }
  .uploadFields {
    display: flex !important;
    justify-content: space-around !important;

    .uploadField__left, .uploadField__right {
      min-width: 300px !important;
    }
  }
`;
const PopupUpload = styled(Box)`
${window?.$environment?.clutchNewUI? `
background: #fff;
border-radius: 7px;
margin: 2%;
padding: 0 2%;`: ''
}`;

const Styled = { Wrapper, UploadWrapper, PopupUpload };

export default Styled;
