import React, { useEffect, useState, lazy } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import RenderComponent from './RenderComponent';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as _ from 'lodash'
import {
  hasValue,
  getLogsInfoDisplay,
  hasEnabledFeature,
  isDriver
} from '../../../utils/utils';
import { pageUrls } from '../../../config/constants/keys';
const layout = window?.$environment?.CURRENT_LAYOUT;
const Label = lazy(() => import(`../../../components/UI/Label/${layout}`));
const Form = lazy(() => import(`../../../components/UI/Form/${layout}`));
const Message = lazy(() => import(`../../../components/UI/Message/${layout}`));

const Component = ({
  dailyLogDetails,
  saveDailyLogDetails,
  apiStatus,
  id,
  clearUpdateDailyLogDetails,
  setDailyLogEntries,
  history,
  viewOnly,
  Styled,
  fetchDailyLogDetailsApiId,
  udEdit,
  getDailyLogDetails
}) => {
  const { i18n } = useTranslation();
  const [finalListForUpdate, setFinalListForUpdate] = useState([]);
  const [providerMode, setProviderMode] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');

  const [yData, setYData] = useState([]);
  const [assignedDriver, setAssignedDriver] = useState({});
  const [drivers, setDrivers] = useState([]);
  useEffect(() => {
    const driversList = Object.keys(
      dailyLogDetails.originalData.dailyLog.companyDrivers || {}
    ).map((key) => {
      const item = dailyLogDetails.originalData.dailyLog.companyDrivers[key];
      return {
        label: item,
        value: key
      };
    });

    setDrivers(driversList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyLogDetails]);
  useEffect(() => {
    const enableEditOW =
      hasEnabledFeature('editOilFieldWaitingEnabled') &&
      dailyLogDetails?.originalData?.enableEditOW === true;
    if (viewOnly) {
      setYData([
        { value: 'OFF', label: 'OFF_DUTY' },
        { value: 'SB', label: 'SLEEPER_BERTH' },
        { value: 'DR', label: 'DRIVING' },
        { value: 'ON', label: 'ON_DUTY' },
        { value: 'OW', label: 'OIL_FIELD_WAITING' }
      ]);
    } else {
      if (enableEditOW) {
        setYData([
          { value: 'OFF', label: 'OFF_DUTY' },
          { value: 'SB', label: 'SLEEPER_BERTH' },
          { value: 'DR', label: 'DRIVING' },
          { value: 'ON', label: 'ON_DUTY' },
          { value: 'OW', label: 'OIL_FIELD_WAITING' }
        ]);
      } else {
        setYData([
          { value: 'OFF', label: 'OFF_DUTY' },
          { value: 'SB', label: 'SLEEPER_BERTH' },
          { value: 'DR', label: 'DRIVING' },
          { value: 'ON', label: 'ON_DUTY' }
        ]);
      }
    }
    const object = dailyLogDetails?.originalData?.dailyLog;
    const getDriverIdFromName = (list) => {
      const source = Object.keys(list);
      for (let index = 0; index < source.length; index++) {
        const element = list[source[index]];
        if (element === 'Unidentified Driver') return source[index];
      }
    };
    setAssignedDriver(
      udEdit
        ? {
            value: getDriverIdFromName(
              dailyLogDetails.originalData.dailyLog.companyDrivers
            ),
            label: 'Unidentified Driver'
          }
        : {
            label: object?.driverName,
            value: object?.driverId
          }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyLogDetails?.originalData]);

  const xData = [
    'M',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    'N',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    'M'
  ];
  const hasFetchDailyLogDetails = hasValue(
    apiStatus.status,
    'api',
    fetchDailyLogDetailsApiId
  );
  const saveDailyLogDetailsApiId = 'saveDailyLogDetails';
  const hasUpdateDailyLogDetails = hasValue(
    apiStatus.status,
    'api',
    saveDailyLogDetailsApiId
  );
  const updateFinalList = (source) => {
    let newList = [];
    newList = dailyLogDetails.finalData.dailyLogEntries.map((item) => {
      const parentItem = source.filter((sourceItem) => {
        return sourceItem.key === item.id;
      });
      const { key, ...updatedItem } = parentItem[0] || {};
      return parentItem[0] ? { ...updatedItem } : item;
    });
    return newList;
  };
  // const updateFinalList = (source) => {
  //   let newList = [];
  //   source.map((parentItem) => {
  //     newList = dailyLogDetails.finalData.dailyLogEntries.map((item) => {
  //       const { key, ...updatedItem } = parentItem;
  //       return parentItem.key === item.id && parentItem.location
  //         ? { ...updatedItem }
  //         : item;
  //     });
  //   });
  //   return newList;
  // };

  const checkIfEdited = (source) => {
    let temp = [];
    for (let index = 0; index < source.length; index++) {
      let point = source[index];
      point.isLogEntryEdited = false;
      let isEdited = point.time.includes('edited') > 0;
      if (isEdited) {
        point.isLogEntryEdited = true;
        const time = point.time.replace('edited', '');
        point = {
          ...point,
          time: time
        };
        temp.push(point);
      } else if (point.status.edited) {
        point.isLogEntryEdited = true;
        point = {
          ...point,
          status: { value: point.status.value, label: point.status.label }
        };
        temp.push(point);
      } else if (point.deleted) {
        point.isLogEntryEdited = true;
        if (source[index + 1]?.deleted) {
          if (index === 0) {
            temp[0] = {
              ...source[2],
              x: point.x,
              time: point.time
            };
            temp[index + 1] = {
              ...source[index ? index : 3]
            };
          } else if (index === source.length - 2) {
            temp[index - 1] = {
              ...temp[index - 1],
              x: source[index + 1].x,
              time: source[index + 1].time
            };
          } else {
            temp[index - 1] = {
              ...temp[index - 1],
              x: source[index + 2].x,
              time: source[index + 2].time
            };
          }
        }
      } else {
        temp.push(point);
      }
    }
    return temp;
  };
  const getTranslatedValue = (value) => {
    let data = ['OFF_DUTY', 'SLEEPER_BERTH', 'DRIVING', 'ON_DUTY', 'OIL_FIELD_WAITING' ]
    let finalVal = ''
    if(!data.includes(value)){
      data.forEach(ele => {
        if(i18n.t(ele) == value) finalVal = ele
      })
      return finalVal
    } else {
      return value
    }
    
  }

  const resetDataList = (source) => {
    let result = [];
    const getMinuteFromTime = (time, index) => {
      const hr = time.split(':')[1].includes('am')
        ? parseInt(time.split(':')[0], 10) === 12
          ? 0
          : time.split(':')[0]
        : parseInt(time.split(':')[0], 10) === 12
        ? 12
        : parseInt(time.split(':')[0], 10) + 12;
      const min = parseInt(time.split(':')[1].slice(0, -2), 10);
      return time === '12:00am' && index !== 0 ? 1440 : hr * 60 + min;
    };
    for (let i = 0; i < source.length; i++) {
      const element = source[i];
      if (i % 2 === 0) {
        delete element.noEdit;
        result[result.length] = {
          annotation: '',
          coDriverId: '',
          driverFullName: '',
          eldData: false,
          id: udEdit ? element.key : i,
          isAOBRDEdit: false,
          unIdentifiedLogStatus: udEdit
            ? dailyLogDetails.originalData.dailyLog.companyDrivers[
                parseInt(element?.driverId?.value, 10)
              ] === 'Unidentified Driver'
              ? 'ASSIGNED'
              : 'UNASSIGNED'
            : 'UNASSIGNED',
          status: getTranslatedValue(element.status.label),
          note: element.note || '',
          stateModifier:
            element?.stateModifier?.value !== 'please'
              ? element?.stateModifier?.value || ''
              : '',
          oilFieldWaiting:
            element?.stateModifier !== '' &&
            element?.stateModifier?.value !== 'please'
              ? true
              : false,
          location: element.location || '',
          driverId: parseInt(element?.driverId?.value, 10) || 0,
          time_from: element.time,
          time_to: source[i + 1].time,
          readOnly: element.readOnly,
          key: element.key,
          minutes_from: getMinuteFromTime(element.time, i),
          minutes_to: getMinuteFromTime(source[i + 1].time, i),
          isLogEntryEdited: element?.isLogEntryEdited || source[i + 1]?.isLogEntryEdited
        };
      }
    }

    return result;
  };
  return (
    <Styled.Wrapper viewOnly={viewOnly}>
      <Styled.LabelWrap>
        {window.$environment.CURRENT_LAYOUT !== 'SpireonNew' && (
          <Label className="logsInfoDisplay">
            {getLogsInfoDisplay(
              i18n,
              dailyLogDetails?.originalData?.dailyLog?.driverName,
              dailyLogDetails?.originalData?.dailyLog?.day
            )}
          </Label>
        )}
        <>
          {hasFetchDailyLogDetails && hasFetchDailyLogDetails.errorMessage ? (
            <Message
              type="error"
              message={i18n.t(hasFetchDailyLogDetails.errorMessage)}
            />
          ) : null}
        </>
      </Styled.LabelWrap>
      <Form
        type="server"
        direction="row"
        initialValues={{
          ...dailyLogDetails.finalData
        }}
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {
          if (udEdit) {
            let validity = true;
            for (let i = 0; i < finalListForUpdate.length; i++) {
              const item = finalListForUpdate[i];
              if (!item.driverId || !item.note) {
                validity = false;
                setValidationMessage(
                  i18n.t('Please fill in atleast one assigned driver and its corresponding annotation')
                );
              }
              //This may be uncommented in future.
              // else if (
              //   item.note.length &&
              //   item.note.length < 4 &&
              //   !item.udReadOnly
              // ) {
              //   //To check if the dailylog row entries note field has min 4 characters

              //   validity = false;
              // }
            }
            if (!validity) {
              return;
            }
          }
          const dataForm = [];
          dataForm.push({
            name: 'driverUserId',
            value:
              fields.driverId || dailyLogDetails.originalData.dailyLog.driverId
          });
          dataForm.push({
            name: 'driverName',
            value:
              fields.driverName ||
              dailyLogDetails.originalData.dailyLog.driverName
          });
          dataForm.push({
            name: 'codriver',
            value:
              fields.codriverName ||
              dailyLogDetails.originalData.dailyLog.codriverName
          });
          dataForm.push({
            name: 'carrier',
            value:
              fields.carrierName ||
              dailyLogDetails.originalData.dailyLog.carrierName
          });
          dataForm.push({
            name: 'vehicles',
            value:
              fields.vehilceName ||
              dailyLogDetails.originalData.dailyLog.vehilceName
          });
          dataForm.push({
            name: 'trailers',
            value:
              fields.trailerName ||
              dailyLogDetails.originalData.dailyLog.trailerName
          });
          dataForm.push({
            name: 'distance',
            value:
              fields.distance || dailyLogDetails.originalData.dailyLog.distance
          });
          dataForm.push({
            name: 'from1',
            value:
              fields.fromLocation ||
              dailyLogDetails.originalData.dailyLog.fromLocation
          });
          dataForm.push({
            name: 'to1',
            value:
              fields.toLocation ||
              dailyLogDetails.originalData.dailyLog.toLocation
          });
          dataForm.push({
            name: 'main_address',
            value:
              fields.mainOfficeLocation ||
              dailyLogDetails.originalData.dailyLog.mainOfficeLocation
          });
          dataForm.push({
            name: 'home_address',
            value:
              fields.terminal || dailyLogDetails.originalData.dailyLog.terminal
          });
          dataForm.push({
            name: 'ship_docs',
            value:
              fields.shippingDocs ||
              dailyLogDetails.originalData.dailyLog.shippingDocs
          });
          dataForm.push({
            name: 'notes',
            value: fields.note || dailyLogDetails.originalData.dailyLog.note
          });
          dataForm.push({
            name: 'logSigningLocation',
            value:
              fields.logSigningLocation ||
              dailyLogDetails.originalData.dailyLog.logSigningLocation
          });
          dataForm.push({
            name: 'comments',
            value:
              fields.comment || dailyLogDetails.originalData.dailyLog.comment
          });
          let temp = checkIfEdited(finalListForUpdate);
          const logsEntries = resetDataList([...temp]);
          temp = udEdit ? updateFinalList(logsEntries) : logsEntries;

          let dataTable = _.cloneDeep(temp)
          dataTable = dataTable.map(u => ({minutes_from: u.minutes_from, stateModifier: u.stateModifier, localtion: u.location, driverId: u.driverId, note: u.note}));
          let initialData = _.cloneDeep(dailyLogDetails?.finalData?.dailyLogEntries);
          initialData = initialData.map(u => ({minutes_from: u.minutes_from, stateModifier: u.stateModifier, localtion: u.location, driverId: u.driverId, note: u.note})
          );
          const differenceData = _.differenceWith(initialData, dataTable, _.isEqual)
          if(differenceData.length){
            differenceData.forEach( o=> {
              const idx = temp.findIndex(item=> item.minutes_from === o.minutes_from);
              if (idx > -1) temp[idx].isLogEntryEdited = true;
            })
          }
          //This may be uncommented in future.
          // if (!udEdit) {
          //   //To check if the dailylog row entries note field has min 4 characters
          //   for (let index = 0; index < logsEntries.length; index++) {
          //     const element = logsEntries[index];
          //     if (
          //       element.note.length &&
          //       element.note.length < 4 &&
          //       !element?.readOnly &&
          //       !viewOnly
          //     ) {
          //       return;
          //     }
          //   }
          // }

          let editedEvents = []
          let initialLogs = dailyLogDetails.originalData.dailyLog.dailyLogEntries
          temp.map((ele, index) => {
           if(ele.isLogEntryEdited && ele.key && ele.eventRecordStatus != '2') {
              let originalData;
              originalData = initialLogs.filter(item => item.id == ele.key)[0]
              originalData.eventRecord = '2'
              originalData.editedEntryId = null
              originalData.eventReference = editedEvents.length + 1
              editedEvents.push(originalData)
           }
          })
          temp.forEach(ele => {
            ele.editedEntryId = []
            editedEvents.forEach(item => {
              if( (ele.minutes_from >= item.minutes_from && ele.minutes_to <= item.minutes_to) || (ele.key == item.id)) ele.editedEntryId.push(item.eventReference)
            })
          })
          
          const finalData = {
            dataTbl: { ...temp },
            dataForm,
            editedEvents,
            driverName: dailyLogDetails.originalData.dailyLog.driverName,
            idPub: id,
            comment: fields.comment
          };
          let elements = document.getElementsByClassName('time_wrapper');
          for (let i = 0, len = elements.length; i < len; i++) {
            elements[i].classList.remove('dailyLogDateError');
          }
          let editedDataList = logsEntries;

          for (let index = 0; index < editedDataList.length; index++) {
            if (index !== editedDataList.length - 1) {
              let v = document.getElementById(
                `timeToDailyLog${index + index + 1}`
              );
              const nextTime1 = editedDataList[index + 1]['time_from'].replace(
                'edited',
                ''
              );

              const current1 = editedDataList[index]['time_from'].replace(
                'edited',
                ''
              );

              const nextTime = moment(nextTime1, 'h:mma').toDate();
              const currentTime = moment(current1, 'h:mma').toDate();

              if (
                !udEdit &&
                moment(nextTime).unix() < moment(currentTime).unix()
              ) {
                v.classList.add('dailyLogDateError');
                return false;
              }
            }
          }

          let errorClass = document.getElementsByClassName('dailyLogDateError');
          if (errorClass?.length === 0) {
            saveDailyLogDetails({
              apiId: saveDailyLogDetailsApiId,
              data: { data: finalData, id: id },
              udEdit: udEdit,
              providerMode: providerMode,
              callback: () => {
                history.push(pageUrls.logsList);
              }
            });
          }
        }}
        customProps={{
          viewOnly: isDriver() ? true : viewOnly,
          hasUpdateDailyLogDetails,
          yData,
          xData,
          setDailyLogEntries,
          assignedDriver: assignedDriver,
          Styled,
          history,
          i18n,
          setFinalListForUpdate,
          udEdit: udEdit || false,
          finalListForUpdate,
          drivers,
          providerMode,
          setProviderMode,
          getDailyLogDetails,
          originalData: dailyLogDetails.originalData.dailyLog,
          unformattedDriverList:
            dailyLogDetails.originalData.dailyLog.companyDrivers,
          enableEditOW:
            hasEnabledFeature('editOilFieldWaitingEnabled') &&
            dailyLogDetails?.originalData?.enableEditOW === true,
          dailyLogDetails
        }}
        handleOnClear={clearUpdateDailyLogDetails}
        validationSchema={Yup.object().shape({
          note:
            window.$environment.SERVICE_PROVIDER !== 'spireon'
              ? Yup.string()
                  .nullable()
                  .required(i18n.t('common.fieldRequiredMessage'))
                  .min(4, i18n.t('Please enter atleast 4 characters'))
              : '',
          comment: Yup.string()
            .nullable()
            .required(i18n.t('common.fieldRequiredMessage'))
            .min(4, i18n.t('Please enter atleast 4 characters')),
          distance: Yup.number().typeError(i18n.t('common.validNumberMessage'))
        })}
      />
      {validationMessage && (
        <Message type="error" message={validationMessage} />
      )}
    </Styled.Wrapper>
  );
};

export default Component;
Component.defaultTypes = {};
Component.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  manageLogList: PropTypes.object.isRequired,
  fetchManageLogList: PropTypes.func.isRequired,
  setSelectedManageLogItem: PropTypes.func.isRequired,
  saveDailyLogDetails: PropTypes.func.isRequired,
  getDailyLogDetails: PropTypes.func.isRequired,
  clearUpdateDailyLogDetails: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  getFilters: PropTypes.func.isRequired
};
