import styled from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => `
  `}
`;

const Styled = {
  Wrapper
};
export default Styled;
