import {
  fetchDocumentsApi,
  documentUploadApi,
  documentDeleteApi
} from '../../../../config/apis';

const initialState = {
  documentList: []
};

export const documentList = {
  state: { ...initialState },
  reducers: {
    listSuccess(state, payload) {
      return { ...state, documentList: [...payload] };
    },
    listFailed(state) {
      return { ...state };
    },
    deleteFailed(state) {
      return { ...state };
    },
    saveFailed(state) {
      return { ...state };
    },
    updateDocumentList(state, response) {
      const documents = state.documentList.map((item) => {
        if (item.type.name === response?.type?.name) {
          return { ...response };
        } else if (item.id === response?.id) {
          if (response?.isDelete) {
            return {
              ...item,
              id: null,
              name: null,
              url: null,
              uploaded: null,
              expiration_date: null
            };
          } else {
            return { ...item };
          }
        } else {
          return { ...item };
        }
      });
      return { ...state, documentList: [...documents] };
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: fetchDocumentsApi,
            data: { ...payload.data }
          },
          id: payload.apiId
        });
        this.listSuccess(response);
      } catch (error) {
        this.listFailed();
      }
    },
    async saveData(payload) {
      try {
        const data = payload.data.data;
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: documentUploadApi,
            data: data
          },
          id: payload.apiId,
          callback: payload.callback
        });

        response && this.updateDocumentList(response);
      } catch (error) {
        this.saveFailed();
      }
    },
    async deleteData(payload) {
      try {
        const url = documentDeleteApi.url.split('{')[0];
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: { ...documentDeleteApi, url: url + payload.data.id }
          },
          id: payload.apiId
        });
        response &&
          this.updateDocumentList({
            isDelete: true,
            id: payload.data.id
          });
      } catch (error) {
        this.deleteFailed();
      }
    }
  })
};
