import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
padding: 0;
`;

const SearchWrapper = styled(Parent.SearchWrapper)`
flex: 0 0 68%;
`;

const MapWrapper = styled(Parent.MapWrapper)`
padding: 0;
`;
const TableWrapper = styled(Parent.TableWrapper)`
  ${({ theme }) => `
  padding-top: 2%;
.iconContainer  {
   display: none;
}
`}
`;
const Styled = { Wrapper, SearchWrapper, MapWrapper, TableWrapper };

export default Styled;
