import styled from 'styled-components';
import Box from '@material-ui/core/Box';

import { getStyle } from '../../../../utils/utils';

const ButtonContainer = styled(Box)`
  ${({ theme }) => `
.MuiButton-root {
  font-size: 23px;
  background: ${getStyle(
    '',
    (theme.palette.secondary || {}).main,
    theme.colors.PRIMARY_MAIN
  )};
  text-transform: none;
  white-space: nowrap;
  color: ${getStyle(
    '',
    (theme.palette.secondary || {}).contrastText,
    theme.colors.SECONDARY_MAIN
  )}
  border-radius: 0px;
  padding-left: 30px;
  padding-right: 30px; 
  cursor: pointer; 
}
.MuiButton-root: hover {
  background: ${getStyle(
    '',
    (theme.palette.secondary || {}).main,
    theme.colors.PRIMARY_MAIN
  )}
}
.reset, .reset: hover {
  background: ${theme.colors.WHITE}!important
  color: ${theme.colors.BLACK} !important
}

text-transform: none !important;
color: white;
border-radius: 0px;
`}
`;

export default ButtonContainer;
