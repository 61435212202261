import React, { useState, lazy, useEffect } from 'react';
import PropTypes from 'prop-types';

import PotentialViolations from './PotentialViolations';
import DriverStatus from './DriverStatus';
import Alerts from './Alerts';
import VehicleStatus from './VehicleStatus';
import DriverBehavior from './DriverBehavior';
import RecentAccidents from './RecentAccidents';
import DailyLogComponent from './DailyLogs';
import DeveloperRequest from './DeveloperRequest';

import { pageUrls, imageUrls } from '../../../config/constants/keys';
import {
  getImagePath,
  hasEnabledFeature,
  findAndDeleteParentInJson,
  hasRestrictedRole
} from '../../../utils/utils';

const layout = window?.$environment?.CURRENT_LAYOUT;
const GoogleMap = lazy(() => import(`../../../components/GoogleMap/${layout}`));
const Modal = lazy(() => import(`../../../components/UI/Modal/${layout}`));
const Link = lazy(() => import(`@/components/UI/Link`));
const Tab = lazy(() => import(`../../../components/UI/Tab/${layout}`));
const Icon = lazy(() => import(`../../../components/UI/Icon/${layout}`));
const CheckBox = lazy(() => import(`../../../components/UI/Toggle/${layout}`));
const Accordion = lazy(() => import(`../../../components/Accordion`));
const CsaViolation = lazy(() => import(`./CSASnapshot`));
const Component = ({
  history,
  dashboard,
  clearAll,
  clearAllRecentAccidents,
  clearAllDailyLogs,
  refreshDriverStatus,
  refreshVehicleStatus,
  handleDeleteAlert,
  handleAcceptPermissionRequest,
  handleDeletePermissionRequest,
  callback,
  clearAllAlerts,
  enableNotification,
  Styled,
  accordion,
  dataList,
  iconsAsLink,
  mapRequired,
  i18n,
  getDriverStatus,
  getLatestLog,
  fetchDashboardData,
  viewReport,
  refreshDriverStatusMap,
  getVehicleStatus,
  driverStatusColumn
}) => {
  const [modal, setModal] = useState(false)
  const [notificationEnabled, setNotificationEnabled] = useState(false);

  useEffect(() => {
    if (!hasEnabledFeature('smoothTrackingEnabled')) {
      if (!hasEnabledFeature('geometrisEnabled')) {
        getDriverStatus({
          apiId: 'getDriverStatus',
          data: { isDashboard: true }
        });
      } else {
        getVehicleStatus({ apiId: 'getVehicleStatus' });
      }
      const interval = setInterval(() => {
        if (!hasEnabledFeature('geometrisEnabled')) {
          getDriverStatus({
            apiId: 'refreshDashboardMap',
            data: { isDashboard: true }
          });
        } else {
          getVehicleStatus({ apiId: 'refreshDashboardMap' });
        }
      }, 120000);
      return () => clearInterval(interval);
    }
  }, []);
  const [value, setValue] = useState(0);
  const [sourceToDisplay, setSourceToDisplay] = useState([]);
  const [sourceList, setSourceList] = useState(dataList);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    let data = dataList;
    if (!hasEnabledFeature('csaViolationFieldWaitingEnabled')) {
      data = findAndDeleteParentInJson(
        data,
        'id',
        'csaViolationFieldWaitingEnabled'
      );
    }
    if (!hasEnabledFeature('geometrisEnabled')) {
      data = findAndDeleteParentInJson(data, 'dependency', 'geometrisEnabled');
    }
    setSourceList(data);
  }, []);
  const driverBehavior = () => (
    <DriverBehavior i18n={i18n} history={history} callback={callback} />
  );
  const vehicleStatus = () => (
    <>
      <div className="viewAll statusIcon">
        <Icon
          src={getImagePath(imageUrls.refreshIcon)}
          onClick={() =>
            refreshVehicleStatus({
              apiId: 'refreshVehicleStatus',
              data: { isVehicleStatusPageFlag: false }
            })
          }
          tooltip={i18n.t('common.refresh')}
        />

        <Icon
          src={getImagePath(imageUrls.viewMoreIcon)}
          onClick={() => history.push(pageUrls.vehicleStatusViewMore)}
          asLink={iconsAsLink}
          text={i18n.t('common.viewMore')}
          tooltip={i18n.t('common.viewMore')}
        />
      </div>
      <VehicleStatus
        i18n={i18n}
        vehicleStatus={dashboard?.data?.vehicleStatus?.infoItems || []}
        viewOnMap={(e) => viewOnMapClicked(e)}
        noPagination
        noSort
      />
    </>
  );
  const [viewOnMapFlg, setviewOnMapFlg] = useState(false);
  const viewOnMapClicked = (e) => {
    setviewOnMap(e);
    setviewOnMapFlg(prevFlg => {
      return !prevFlg
    })
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  const [viewOnMap, setviewOnMap] = useState(null);
  const driverStatus = () => (
    <>
      <div className="viewAll statusIcon">
        <Icon
          src={getImagePath(imageUrls.refreshIcon)}
          onClick={() =>
            refreshDriverStatusMap({
              apiId: 'refreshDriverStatus'
            })
          }
          tooltip={i18n.t('common.refresh')}
        />

        <Icon
          src={getImagePath(imageUrls.viewMoreIcon)}
          onClick={() => history.push(pageUrls.driverStatusViewMore)}
          asLink={iconsAsLink}
          text={i18n.t('common.viewMore')}
          tooltip={i18n.t('common.viewMore')}
        />
      </div>
      <DriverStatus
        i18n={i18n}
        pageUrls={pageUrls}
        getLatestLog={getLatestLog}
        history={history}
        driverStatus={dashboard?.data?.driversOnDuty?.infoItems || []}
        columns={driverStatusColumn || null}
      />
    </>
  );
  useEffect(() => {
    localStorage.setItem('daltnot', dashboard?.data?.enableNotification);
  }, [dashboard?.data?.enableNotification]);

  const getIdValue = (event) => {
    setNotificationEnabled(event);
    if (dashboard.data.enableNotification === false) {
      localStorage.setItem('daltnot', event);
      return true;
    } else if (
      dashboard.data.enableNotification === true &&
      localStorage.getItem('daltnot') === 'true'
    ) {
      localStorage.setItem('daltnot', event);
      return false;
    } else {
      localStorage.setItem('daltnot', event);
      return true;
    }
  };
  const alerts = () => (
    <>
      <div className="viewAll">
        {hasRestrictedRole(['OWNER']) &&
          window.$environment.SERVICE_PROVIDER !== 'spireon' && (
            <>
              <CheckBox
                id="alertenableNotification"
                type="NormalGrey"
                label={i18n.t('dashboard.tabs.alerts.enableNotification')}
                checked={notificationEnabled}
                onClick={(event) => {
                  enableNotification({
                    apiId: 'enableNotification',
                    data: {
                      id: getIdValue(event)
                    },
                    callback: callback
                  });
                }}
                className="enable"
              />
              <Icon
                src={getImagePath(imageUrls.clearAll)}
                onClick={() =>
                  clearAllAlerts({
                    apiId: 'clearAllAlerts',
                    callback: setValue(0)
                  })
                }
                className="clearAll"
                asLink={iconsAsLink}
                text={i18n.t('common.clearAll')}
                tooltip={i18n.t('common.clearAll')}
              />
            </>
          )}
        <Icon
          src={getImagePath(imageUrls.viewMoreIcon)}
          onClick={() => history.push(pageUrls.alertViewMore)}
          asLink={iconsAsLink}
          text={i18n.t('common.viewMore')}
          tooltip={i18n.t('common.viewMore')}
        />
      </div>
      <Alerts
        list={dashboard.data?.alerts?.dashboardAlerts || []}
        handleDeleteAlert={handleDeleteAlert}
        callback={callback}
        i18n={i18n}
      />
    </>
  );
  const csaViolationFieldWaitingEnabled = () => <CsaViolation />;
  const potentialViolations = () => (
    <>
      <div className="viewAll">
        {window.$environment.SERVICE_PROVIDER !== 'spireon' && (
          <Icon
            src={getImagePath(imageUrls.clearAll)}
            onClick={() =>
              clearAll({
                apiId: 'clearAllPotentialData',
                callback: setValue(0)
              })
            }
            asLink={iconsAsLink}
            text={i18n.t('common.clearAll')}
            tooltip={i18n.t('common.clearAll')}
          />
        )}
        <Icon
          src={getImagePath(imageUrls.viewMoreIcon)}
          onClick={() => history.push(pageUrls.potentialViolationsViewMore)}
          tooltip={i18n.t('common.viewMore')}
          asLink={iconsAsLink}
          text={i18n.t('common.viewMore')}
        />
      </div>
      <PotentialViolations
        i18n={i18n}
        potentialViolations={dashboard?.data?.driversAtRisk?.infoItems || []}
      />
    </>
  );
  const recentAccidents = () => (
    <>
      <div className="viewAll">
        <Icon
          src={getImagePath(imageUrls.clearAll)}
          onClick={() => {
            clearAllRecentAccidents({
              apiId: 'clearAllAccidentsData',
              callback: setValue(0)
            });
          }}
          asLink={iconsAsLink}
          text={i18n.t('common.clearAll')}
          tooltip={i18n.t('common.clearAll')}
        />

        <Icon
          src={getImagePath(imageUrls.viewMoreIcon)}
          onClick={() => history.push(pageUrls.accidentsList)}
          tooltip={i18n.t('common.viewMore')}
          asLink={iconsAsLink}
          text={i18n.t('common.viewMore')}
        />
      </div>
      <RecentAccidents
        i18n={i18n}
        recentAccidents={dashboard?.data?.recentAccidents?.infoItems || []}
        viewReport={viewReport}
      />
    </>
  );
  const dailyLogs = () => (
    <>
      <div className="viewAll">
        {window.$environment.SERVICE_PROVIDER !== 'spireon' && (
          <Icon
            src={getImagePath(imageUrls.clearAll)}
            onClick={() =>
              clearAllDailyLogs({
                apiId: 'clearAllDailyLogs',
                callback: setValue(0)
              })
            }
            asLink={iconsAsLink}
            text={i18n.t('common.clearAll')}
            tooltip={i18n.t('common.clearAll')}
          />
        )}
        <Icon
          src={getImagePath(imageUrls.viewMoreIcon)}
          onClick={() => history.push(pageUrls.logsList)}
          tooltip={i18n.t('common.viewMore')}
          asLink={iconsAsLink}
          text={i18n.t('common.viewMore')}
        />
      </div>
      <DailyLogComponent
        i18n={i18n}
        data={dashboard?.data?.dailyLogs?.infoItems || []}
        viewReport={viewReport}
      />
    </>
  );
  const developerRequests = () => (
    <>
      <div className="viewAll">
        {/* <Icon
          src={getImagePath(imageUrls.clearAll)}
          onClick={() =>
            clearAllAlerts({
              apiId: 'clearAllDeveloperRequest',
              callback: setValue(0)
            })
          }
          className="clearAll"
          asLink={iconsAsLink}
          text={i18n.t('common.clearAll')}
          tooltip={i18n.t('common.clearAll')}
        /> */}
        <Icon
          src={getImagePath(imageUrls.viewMoreIcon)}
          onClick={() => history.push(pageUrls.alertsRequests)}
          asLink={iconsAsLink}
          text={i18n.t('common.viewMore')}
          tooltip={i18n.t('common.viewMore')}
        />
      </div>
      <DeveloperRequest
        list={dashboard?.data?.permissionAlerts?.dashboardAlerts || []}
        handleDeletePermissionRequest={handleDeletePermissionRequest}
        handleAcceptPermissionRequest={handleAcceptPermissionRequest}
        callback={callback}
        i18n={i18n}
      />
    </>
  );

  useEffect(() => {
    setNotificationEnabled(dashboard?.data?.enableNotification);
    const sourceToDisplay = sourceList.map((item) => {
      item.value = eval([item.id] + '()');
      item.name = i18n.t(item.nameKey);
      return item;
    });
    setSourceToDisplay(sourceToDisplay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dashboard.data,
    sourceList,
    dashboard?.data?.dailyLogs?.infoItems,
    dashboard?.data?.driversAtRisk?.infoItems,
    dashboard?.data?.recentAccidents?.infoItems,
    dashboard?.data?.enableNotification,
    notificationEnabled
  ]);
  const isMapRequired = mapRequired === undefined || mapRequired;
  return (
    <>
      <Styled.Wrapper>
        <Styled.MapWrapper>
          {isMapRequired && (
            <GoogleMap
              type={'dashboardMap'}
              liveTracking={hasEnabledFeature('smoothTrackingEnabled')}
              height={'400px'}
              data={
                hasEnabledFeature('geometrisEnabled')
                  ? dashboard?.vehicleStatusList
                  : dashboard?.driverStatusList
              }
              getDriverStatus={getDriverStatus}
              dashboard={dashboard}
              viewOnMap={viewOnMap}
              viewOnMapFlg={viewOnMapFlg}
            />
          )}
        </Styled.MapWrapper>
        {accordion ? (
          <Accordion data={sourceToDisplay} />
        ) : (
          <Tab
            data={sourceToDisplay}
            currentTab={value}
            onChange={handleChange}
            className="tabs"
          />
        )}
      </Styled.Wrapper>
    </>
  );
};

Component.defaultProps = {
  accordion: false
};
Component.propTypes = {
  history: PropTypes.object.isRequired,
  dashboard: PropTypes.object.isRequired,
  clearAll: PropTypes.func.isRequired,
  clearAllRecentAccidents: PropTypes.func.isRequired,
  refreshDriverStatus: PropTypes.func.isRequired,
  refreshVehicleStatus: PropTypes.func.isRequired,
  handleDeleteAlert: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  clearAllAlerts: PropTypes.func.isRequired,
  enableNotification: PropTypes.func.isRequired,
  Styled: PropTypes.object.isRequired,
  accordion: PropTypes.bool
};
export default Component;
