import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
padding: 2%;
`;
const TopWrapper = styled(Parent.TopWrapper)`
 
`;
const TableWrapper = styled(Parent.TableWrapper)`
 
`;
const Styled = { Wrapper, TopWrapper, TableWrapper };

export default Styled;

