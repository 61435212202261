import {
  getDataResetPasswordApi,
  setDataResetPasswordApi
} from '../../../config/apis';
import { PASSWORD_RESET_MESSAGE } from '../../../config/constants/static';

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  cellPhone: '',
  password: '',
  confirmPassword: ''
};

export const resetPassword = {
  state: { ...initialState },
  reducers: {
    setFetchSuccess(state, payload) {
      return {
        ...payload
      };
    },
    setFetchFailed() {
      return { ...initialState };
    },
    setUpdateSuccess(state, payload) {
      return {
        ...payload
      };
    },
    setUpdateFailed() {
      return { ...initialState };
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            data: { ...payload.data },
            api: getDataResetPasswordApi
          },
          id: payload.apiId
        });
        this.setFetchSuccess(response);
      } catch (error) {
        this.setFetchFailed();
      }
    },
    async updateData(payload, rootState) {
      try {
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            data: { ...payload.data },
            api: setDataResetPasswordApi
          },
          id: payload.apiId
        });
        payload.callback('success', PASSWORD_RESET_MESSAGE);
      } catch (error) {
        this.setUpdateFailed();
      }
    }
  })
};
