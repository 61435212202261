import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
#root {
  height: 100%;
}
.MuiPopover-root {
    .MuiMenu-paper.MuiPopover-paper{
      box-shadow:none;
      border-radius: 0;
    }
    .MuiList-root.MuiMenu-list.MuiList-padding{
      padding-top: 0;
      padding-bottom: 0;
      border-radius: 0 !important;
      border: 1px solid #000;
      background: #fff;
      width: 44px;
      margin-top: 19px;
      .MuiListItem-root.Mui-selected{
        background: #fff;
      }
      .MuiListItem-button:hover{
        background-color: #007aff;
        color: #fff;
      }
    }
    .MuiList-root .MuiButtonBase-root {
      font-size: 12px;
      padding: 0;
      padding-left: 5px;
    }
  }
/* cyrillic */
    @font-face {
      font-family: 'Yanone Kaffeesatz';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url(https://fonts.gstatic.com/s/yanonekaffeesatz/v14/3y9I6aknfjLm_3lMKjiMgmUUYBs04aUXNxt9gW2LIfto9tWZc2GMKHD0VTtItvI.woff)
        format('woff');
      unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    }
    /* vietnamese */
    @font-face {
      font-family: 'Yanone Kaffeesatz';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url(https://fonts.gstatic.com/s/yanonekaffeesatz/v14/3y9I6aknfjLm_3lMKjiMgmUUYBs04aUXNxt9gW2LIfto9tWZeGGMKHD0VTtItvI.woff)
        format('woff');
      unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
        U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
    }
    /* latin-ext */
    @font-face {
      font-family: 'Yanone Kaffeesatz';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url(https://fonts.gstatic.com/s/yanonekaffeesatz/v14/3y9I6aknfjLm_3lMKjiMgmUUYBs04aUXNxt9gW2LIfto9tWZeWGMKHD0VTtItvI.woff)
        format('woff');
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    /* latin */
    @font-face {
      font-family: 'Yanone Kaffeesatz';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url(https://fonts.gstatic.com/s/yanonekaffeesatz/v14/3y9I6aknfjLm_3lMKjiMgmUUYBs04aUXNxt9gW2LIfto9tWZd2GMKHD0VTtI.woff)
        format('woff');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
        U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
        U+2212, U+2215, U+FEFF, U+FFFD;
    }
`;

export default GlobalStyle;
