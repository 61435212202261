import styled from 'styled-components';

import Box from '@material-ui/core/Box';

import { getImagePath, getStyle } from '../../../utils/utils';
import { imageUrls } from '../../../config/constants/keys';

const Wrapper = styled.main``;

export const HeaderContainer = styled.div`
  ${({ theme, isLogin }) => `
  h1 {
    text-transform: uppercase;
  }
  #container {
    flex-direction: column;
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    padding: 4px 1%;
    padding-left: 0;
    padding-bottom: 0;
    background-color: ${
      isLogin ? theme?.colors?.BLACK : theme?.colors?.PRIMARY_MAIN
    };
    font-size: 24px;

    h1 {
      padding-left: 3%;
      line-height: 41px;
      flex: 1;
    }
  }
  .not-authenticated {
    flex-direction: row !important;
    height: 73px;
    background-color: ${theme?.colors?.BLACK};
  }
  `}
`;

const Content = styled(Box)`
  background-image: url(${getImagePath(imageUrls.bg_image)});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: calc(100vh - 77px);
  position: relative;
`;
const IconWrapper = styled(Box)`
  ${({ theme }) => `
  text-align: left;
  padding-left: 10%;
  padding-top: 5%;
  position: absolute;
  bottom: 3px;
  .MuiTypography-colorPrimary:first-child {
    margin-right: 60px;
  }
  img {
    @media (max-width: ${theme?.breakpoints?.values?.md}px) {
      width: 30%;
    }
  }
  @media (max-height: 720px) {
    display: none;
  }
  `}
`;

export const LanguageContainer = styled.div`
  ${({ theme }) => `
 right:14%;
 position: absolute;
  cursor: pointer;
  text-align: right;
  align-self: center;
  color: ${getStyle(
    '',
    (theme.palette.secondary || {}).main,
    theme.colors.SECONDARY_MAIN
  )}};
  padding-top: 5px;
  font-size: 25px;
  @media (max-width: 375px) {
    font-size: 15px;
  }
  @media (min-width: 375px) and (max-width: 768px) {
    font-size: 15px;
  }
  `}
`;
const Styled = {
  Wrapper,
  Content,
  IconWrapper,
  HeaderContainer,
  LanguageContainer
};

export default Styled;
