import styled from 'styled-components';
import Parent from '../Base/style';

const InputWrapper = styled(Parent.InputWrapper)`
${({ style, theme, mode }) => `
    background:  ${theme.colors.BACKGROUND} none repeat scroll 0 0; 
    border: 0;
    font-family: ${theme.palette.tableFont};
    font-size: ${theme.palette.inputFont};
    height: 42px;
    .MuiInputBase-input {
    color: ${theme.colors.PRIMARY_CONTRAST} !important;
    padding: 10px 15px;
    }
`}
`;
const TextAreaWrapper = styled(Parent.TextAreaWrapper)`
${({ style, theme, mode }) => `
    background: ${theme.colors.BACKGROUND} none repeat scroll 0 0; 
    border: 0;
    font-family: ${theme.palette.tableFont};
    font-size: ${theme.palette.inputFont};
`}
`;
const MainWrapper = styled(Parent.MainWrapper)`
${({theme}) => `
input,
.MuiInputBase-root {
    background: ${theme.colors.BACKGROUND} none repeat scroll 0 0; 
    border: 0;
    font-family: ${theme.palette.tableFont};
    font-size: ${theme.palette.inputFont};
}
input:focus,
.MuiInputBase-root:focus {
    border: 1.5px solid ${theme.colors.BLACK};
    border-radius: 2px;
    font-family: ${theme.palette.tableFont};
}
/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px #efefef inset !important;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
    -webkit-text-fill-color: ${theme.colors.PRIMARY_CONTRAST} !important;
}
`}
`;
const Styled = { InputWrapper, TextAreaWrapper, MainWrapper };
export default Styled;
