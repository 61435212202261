import {
  documentUploadApi,
  documentDeleteApi,
  editWorkOrdersApi,
  getServiceCenterList
} from '@/config/apis';
import { pageUrls } from '@/config/constants/keys';

const initialState = {
  list: [],
  filteredList: [],
  getData: {},
  documentsList: [],
  serviceCenters: [],
};

export const workOrdersEdit = {
  state: { ...initialState },
  reducers: {
    listSuccess(state, payload) {
      return {
        ...state,
        list: [...payload],
        filteredList: [
          ...payload.filter((item) => item?.status?.name !== 'Closed')
        ]
      };
    },
    listFailed() {
      return { ...initialState };
    },
    filterSuccess(state, list) {
      return { ...state, filteredList: [...list] };
    },
    listAddSuccess(state, payload) {
      return {
        ...state,
        getData: { ...payload },
        documentsList: payload.workOrderEditDto?.receipts || []
      };
    },
    updateLocalDocumentList(state, response, data) {
      let reqData
      if(data && data.request) 
      reqData = JSON.parse(data?.request)
      let edited = false;
      let woAddObject = {
        id: null,
        userId: null,
        vehicleIdPub: null,
        trailerIdPub: null,
        companyId: null,
        workOrderIdPub: null,
        file: null,
        errorMessage: null,
        editUpload: null,
        type: {
          name: 'WORK_ORDER_IMAGE',
          order: 600,
          fullName: 'Work Order Image',
          documentGroup: 'WORK_ORDER'
        },
        name: null,
        url: null,
        uploaded: null,
        expiration_date: null,
        doctype: 'WORK_ORDER'
      };

      const isWoEmpty = (currentValue) =>
        currentValue?.id === null &&
        currentValue?.type?.name === 'WORK_ORDER_IMAGE';

      const documents = state.documentsList
        ? state.documentsList.map((item) => {
            if (item && Object.keys(item).length > 0) {
              delete item?.filter;
              if (item?.id !== null && item.id === reqData?.id) {
                edited = true;
                return { ...response.response};
              }
              else if (
                item?.type?.name === 'WORK_ORDER_IMAGE' &&
                item?.id === null &&
                !response?.isDelete &&
                item.type.name === response?.response?.type?.name
                && !edited
              ) {
                return { ...response.response };
              } else if (
                item?.type?.name === 'PARTS_INVOICE' &&
                item.type.name === response?.response?.type?.name &&
                !response?.isDelete
              ) {
                return { ...response.response };
              } else if (
                item?.id === response?.id &&
                item?.type?.name === 'WORK_ORDER_IMAGE' &&
                state.documentsList.some(isWoEmpty)
              ) {
                if (response?.isDelete) {
                  return {
                    ...item,
                    id: null,
                    name: null,
                    url: null,
                    uploaded: null,
                    expiration_date: null,
                    filter: 'remove'
                  };
                } else {
                  return { ...item };
                }
              } else if (item?.id === response?.id) {
                if (response?.isDelete) {
                  return {
                    ...item,
                    id: null,
                    name: null,
                    url: null,
                    uploaded: null,
                    expiration_date: null
                  };
                } else {
                  return { ...item };
                }
              } else {
                return { ...item };
              }
            }
          })
        : [{ ...response.response }];

      const isLoaded = (currentValue) => currentValue?.id !== null;
      if (documents.every(isLoaded)) documents.push(woAddObject);

      return {
        ...state,
        documentsList: [
          ...documents.filter(
            (value, index) =>
              Object.keys(value).length > 0 && value?.filter !== 'remove'
          )
        ]
      };
    },
    srviceCenterListSuccess(state, payload) {
      return {
        ...state,
        serviceCenters: payload
      };
    },
    srviceCenterListFailed(state) {
      return { ...state, serviceCenters: [] };
    },
  },
  effects: (dispatch) => ({
    async fetchEditWorkOrderData(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...editWorkOrdersApi,
              url: editWorkOrdersApi.url.replace(':id', payload.data.id)
            }
          },
          id: payload.apiId,
          redirectionUrl: payload.redirectionUrl,
          callback: payload.callback,
          history: payload.history
        });
        response  &&  response.workOrderEditDto?.defects &&response.workOrderEditDto.defects.map((item) => {
          const name = item?.sectionName?.split('.');
          item['sectionName'] = name.length > 1? name[1].replaceAll('_', ' '): item?.sectionName
        });
        response && this.listAddSuccess(response);
      } catch (error) {
        // payload.callback && payload.callback('error', 'Something went wrong!');
        this.listFailed();
      }
    },
    async saveAddData(payload, rootState) {
      try {
        const getApi = await import(
          `../../../config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('saveAddWorkOrdersApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject,
            data: payload.data
          },
          id: payload.apiId
        });
        payload.callback &&
          payload.callback(
            'success',
            payload.successMessage,
            () => payload.history && payload.history.push(payload.url)
          );
      } catch (error) {
        // this.listFailed();
        payload.callback && payload.callback('error', error.message);
      }
    },
    async saveDocument(payload) {
      try {
        const data = payload.data.data;
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: documentUploadApi,
            data: data
          },
          id: payload.apiId
          // callback: payload.callback
        });
        response && this.updateLocalDocumentList({ response }, data);
      } catch (error) {
        // this.saveFailed();
      }
    },
    async deleteDocument(payload) {
      try {
        const url = documentDeleteApi.url.split('{')[0];
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: { ...documentDeleteApi, url: url + payload.data.id }
          },
          id: payload.apiId
        });
        this.updateLocalDocumentList({
          isDelete: true,
          id: payload.data.id
        });
      } catch (error) {
        // this.deleteFailed();
      }
    },
    async fetchServiceCenterList(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...getServiceCenterList,
              url: getServiceCenterList.url.replace(':search', '')
            }
          },
          id: payload.apiId
        });
        response && this.srviceCenterListSuccess(response);
      } catch (error) {
        this.srviceCenterListFailed();
      }
    },
    async reAssignrServiceCenter(payload, rootState) {
      try {
        const getApi = await import(
          `../../../config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('reAssignServiceCenterApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject,
            data: payload.data
          },
          id: payload.apiId
        });
        payload.callback &&
          payload.callback(
            'success',
            'Service repair request reassigned successfully',
            () => payload.history && payload.history.push(payload.url)
          );
      } catch (error) {
        // this.listFailed();
        payload.callback && payload.callback('warning', error.message);
      }
    },
  })
};
