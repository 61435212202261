import styled from 'styled-components';
import { getImagePath, getStyle } from '../../../utils/utils';
import Parent from '../Base/style';

const IconContainer = styled(Parent.IconContainer)`
`;
const HeaderContainer = styled(Parent.HeaderContainer)`
${({ theme, isCRMSuspended }) => `
position: relative;
#ListMenus {
  display: none;
}

.otherMenulist {
  position: absolute;
  left: 0;
  top: 67px;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  color: ${theme.colors.MENU_TITLE};
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize !important;
  border: 2px solid ${theme.colors.PRIMARY_BORDER};
  white-space: normal;
 
  box-shadow: 0 2px 10px rgba(0,0,0,0.03);
  background-color: ${theme.colors.MENU_BACKGROUND};
  z-index: 100;
  .menuItems {
    padding-top: 10px;
    padding-bottom: 10px
    padding-left: 14px;
    border-bottom: 1px solid ${theme.colors.PRIMARY_BORDER};
    cursor: pointer;
    :hover {
      background-color: ${theme.colors.NEW_THEME? theme.colors.SELECTED_MENU_BG:theme.colors.PRIMARY_MAIN};
    }
    a {
      color: ${theme.colors.MENU_TITLE};
      text-decoration: none;
    }
  }
}
.MuiList-padding {
    display: flex;
    justify-content: flex-start;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.03);
    background-color: ${theme.colors.MENU_BACKGROUND};
    ${theme.colors.HIDE_MENU_TOP_BORDER ? '' : `border-top: 1px solid ${theme.colors.PRIMARY_BORDER};`}
    flex-wrap: wrap;
    padding: 0;
    @media (max-width: ${theme.breakpoints.values.md - 1}px) {
      flex-direction: row;
      text-align: -webkit-center;
    }
    .dashboardList {
      border-right: 1px solid ${theme.colors.PRIMARY_BORDER};
      color: ${theme.colors.MENU_TITLE} ;
      font-size: 16px;
      font-weight: 400;
      text-transform: capitalize !important;
      // width:12.5%;
      min-width: 0;
      flex: 1;
      padding: 15px;
      border-bottom: 4px solid  transparent;
      text-decoration: none;
      &:hover {
        border-bottom: 4px solid  ${theme.colors.MENU_BORDER_COLOR} !important;
        background-color: ${theme.colors.SELECTED_MENU_BG};
        color: ${theme.colors.SELECTED_MENU_TITLE};
      }
      .support {
        transform: rotate(180deg);
        position: relative;
        left: 25px;
      }
        #supportDrop {
          background: url(${getImagePath('icons/downarrowIcon.svg')}) right center no-repeat;
          width: 40px;
          height: 40px;
        }
      @media only screen and (min-width: ${theme.breakpoints.values.mlg}px) and (max-width: ${theme.breakpoints.values.xl}px) {
        font-size: 13px;
      }
      .MuiListItemIcon-root {
        min-width: 35px;
      }
      .menuLabel {
        @media (max-width: ${theme.breakpoints.values.mlg - 1}px) {
          display: none;
        }
      }
    }
      .opacity-40 {
        img {
          opacity: 40%;
        }
      }
    .selectedDashboardItem {
      background-color: ${theme.colors.SELECTED_MENU_BG};
      color: ${theme.colors.SELECTED_MENU_TITLE};
      border-bottom: 4px solid ${ theme.colors.MENU_BORDER_COLOR} !important;;
      font-weight: 700;
    }
    .width-adjust{
        flex:none;
        width:20%;
        }
    ${!isCRMSuspended ? `
      .dashboardList:last-child.selectedDashboardItem {
      border-bottom: 0 !important;
      background-color: transparent;
      color: ${theme.colors.MENU_TITLE};
      font-weight: 400;
      overflow: visible;
    }
      ` : ``}
      
    .MuiTouchRipple-root {
      display:none;
    }
}
.tooltiptext {
  visibility: hidden;
  width: 100px;
  background: ${getStyle(
    '',
    (theme.palette.primary || {}).main,
    theme.colors.GREY_ONE
  )};
  color: ${theme.colors.WHITE};
  text-align: center;
  padding: 2px 0;
  border-radius: 0px;
  border: solid 1px ${theme.colors.WHITE};
  position: absolute;
  z-index: 1;
  right: 0;
  top: 35px !important;
  font-size: 13px;
}
.iconContainer:hover + .tooltiptext {
    visibility: visible;
  }
}
.iconContainer:first-child {
  margin-right: 8px;
}
.mapSection {
  padding: 2%;
  #map {
    border: 0;
    border-radius: 7px;
  }
}
`}
`;
const LanguageContainer = styled(Parent.LanguageContainer)`
  
`;
const ModalContainer = styled(Parent.ModalContainer)`
  position: absolute;
  top: 0;
  right: 0;
`;
const OptionContainer = styled(Parent.OptionContainer)`
  ${({ theme }) => `
.MuiMenuItem-root {
  font-size: 16px;
}

.MuiListItemIcon-root {
  color: ${getStyle(
    '',
    (theme.palette.primary || {}).contrastText,
    theme.colors.PRIMARY_CONTRAST
  )}};
  font-size: 25px;
}
`}
`;
const Styled = {
  IconContainer,
  HeaderContainer,
  LanguageContainer,
  ModalContainer,
  OptionContainer
};
export default Styled;
