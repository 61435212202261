import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import { getImagePath } from '../../../../../utils/utils';
const Wrapper = styled(Box)`
${({ theme }) => `
.welcomeText {
  padding-top: 3%;
  padding-right:3%;
}
.details {
  opacity: 0.67;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  padding-top: 20px;
  padding-bottom: 100px;
  .linkColor {
    color: ${theme.colors.LINK_COLOR};
    text-decoration: none;
  }
}
.iframeWrapper {
  position: relative;
  overflow: hidden;
  padding-top: 37.25%;
  border-radius: 4px;
}
.respIframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
  `}
`;

const Styled = { Wrapper };

export default Styled;