import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Field } from 'formik';
import ReactDatePicker from 'react-datepicker';

import { UI_DATE_LOWER_FORMAT } from '../../../../config/constants/static';
import { ROLES } from '../../../../config/constants/keys';

import CalendarToday from '@material-ui/icons/CalendarToday';
import { hasRestrictedRole, getDateLocale, getFilterLocaleValues } from '@/utils/utils'
const layout = window?.$environment?.CURRENT_LAYOUT;
const Button = lazy(() => import(`../../../../components/UI/Button/${layout}`));
const Label = lazy(() =>
  import(`../../../../components/UI/FormLabel/${layout}`)
);
const Message = lazy(() =>
  import(`../../../../components/UI/Message/${layout}`)
);
const FormStyle = require(`../../../../components/UI/Form/${layout}/serverStyle`);
const Select = lazy(() => import(`@/components/UI/Select/${layout}`));
const RenderComponent = ({
  values,
  handleEdit,
  errors,
  isNotValid,
  validateForm,
  customProps,
  submitAction,
  inspections
}) => {
  const { i18n } = useTranslation();
  //Date format to display date in UI from config
  const dateFormat = UI_DATE_LOWER_FORMAT;
  const fields = {
    startDate: {
      type: 'date',
      value: '',
      field: 'startDate',
      placeholder: dateFormat,
      label: i18n.t('common.dateFrom'),
      maxValue: new Date()
    },
    endDate: {
      type: 'date',
      value: '',
      field: 'endDate',
      placeholder: dateFormat,
      label: i18n.t('common.dateTo'),
      minValue: 'startDate',
      maxValue: new Date()
    },
    driverId: {
      value: '',
      field: 'driverId'
    },
    downloadType: {
      value: '',
      field: 'downloadType'
    }
  };
  return (
    <>
      <FormStyle.default.FieldsWrapper className="vertical">
        <Label variant="body1">{fields.startDate.label}</Label>
        <FormStyle.default.TextWrapper>
          <Field
            name={fields.startDate.field}
            onChange={(event) => handleEdit(event, fields.startDate)}
            locale={getDateLocale()}
            as={ReactDatePicker}
            placeholderText={
              fields.startDate.placeholder ? fields.startDate.placeholder : ''
            }
            value={values.startDate}
            type={fields.startDate.label}
            showPopperArrow={false}
            showTimeSelect={fields.startDate.isTimeNeeded}
            maxDate={new Date()}
            showDisabledMonthNavigation
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
          {window.$environment.CURRENT_LAYOUT === 'SpireonNew' && (
            <CalendarToday />
          )}
          {errors['startDate'] && !isNotValid && (
            <Message type="error" message={errors['startDate']} />
          )}
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>

      <FormStyle.default.FieldsWrapper className="vertical">
        <Label variant="body1">{fields.endDate.label}</Label>
        <FormStyle.default.TextWrapper>
          <Field
            name={fields.endDate.field}
            onChange={(event) => handleEdit(event, fields.endDate)}
            locale={getDateLocale()}
            as={ReactDatePicker}
            placeholderText={
              fields.endDate.placeholder ? fields.endDate.placeholder : ''
            }
            value={values.endDate}
            type={fields.endDate.tye}
            showPopperArrow={false}
            showTimeSelect={fields.endDate.isTimeNeeded}
            minDate={
              fields.endDate.minValue && values[fields.endDate.minValue]
                ? new Date(values[fields.endDate.minValue])
                : new Date('01/01/2000')
            }
            maxDate={new Date()}
            showDisabledMonthNavigation
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
          {window.$environment.CURRENT_LAYOUT === 'SpireonNew' && (
            <CalendarToday />
          )}
          {errors['endDate'] && !isNotValid && (
            <Message type="error" message={errors['endDate']} />
          )}
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      {customProps.inspections && (
        <>
          {!hasRestrictedRole([ROLES.SERVICE_MANAGER]) &&(
          <FormStyle.default.FieldsWrapper className="vertical">
            <FormStyle.default.TextWrapper>
              <Field
                name={fields.driverId.field}
                onChange={(event) => handleEdit(event, fields.driverId)}
                as={Select}
                value={values['driverId']}
                suggestions={customProps?.drivers}
              />
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>)
          }
          <FormStyle.default.FieldsWrapper className="vertical">
            <FormStyle.default.TextWrapper>
              <Field
                name={fields.downloadType.field}
                onChange={(event) => handleEdit(event, fields.downloadType)}
                as={Select}
                value={getFilterLocaleValues([values['downloadType']], i18n)}
                suggestions={getFilterLocaleValues(customProps?.downloadTypes, i18n)}
              />
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
        </>
      )}

      <FormStyle.default.ButtonsWrapper className="vertical-button">
        <Button
          type="submit"
          label={i18n.t('common.submit')}
          onClick={() => {
            const validateResponse = validateForm();
            validateResponse.then((data) => submitAction(data));
          }}
        />
      </FormStyle.default.ButtonsWrapper>
    </>
  );
};

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  customProps: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};

export default RenderComponent;
