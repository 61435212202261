import React, { useEffect, lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RenderComponent from '@/components/modules/DynamicRenderPage';

import ParentStyled from './style';
import { loadStyle, loadPageConfig } from '@/utils/utils';
import { COOKIE_AUTH_DETAILS } from '@/config/constants/keys';
import { getCookie } from '../../../services/cookie';
let Styled = loadStyle(ParentStyled, 'pages/web/Subscriptions');

const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.subscriptionLink[0].name}`));
const Content = lazy(() => import(`@/${config.subscriptionLink[1].name}`));
const TopActions =
  config.subscriptionLink[0].children &&
  lazy(() => import(`${config.subscriptionLink[0].children.name}`));

const Subscriptions = (parentProps) => {
  const {
    fetchSubscriptions,
    history,
    i18n,
    theme,
    getCompanyData,
    companyData,
    subscriptions
  } = parentProps;
  const commonParentProps = { history, i18n, Styled, theme };
  const apiId = 'upgradeApi';
  const [subscriptionTier, setSubscription] = useState('');
  const token = getCookie(COOKIE_AUTH_DETAILS);
  useEffect(() => {
    fetchSubscriptions({
      apiId: 'fetchSubscriptions'
    });
    token &&
      getCompanyData({
        id: 'getCompanyData',
        data: {
          error: ''
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSubscription(companyData?.data?.companyDto?.subscriptionTier);
  }, [companyData?.data?.companyDto]);
  return (
    <>
      <RenderComponent
        {...config.subscriptionLink[0]}
        parentProps={{
          ...parentProps,
          subscriptionTier,
          setSubscription,
          apiId
        }}
        commonParentProps={commonParentProps}
        component={Title}
        childComponent={TopActions}
      />
      <RenderComponent
        {...config.subscriptionLink[1]}
        parentProps={{
          ...parentProps,
          subscriptionTier,
          setSubscription,
          apiId
        }}
        commonParentProps={commonParentProps}
        component={Content}
      />
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    subscriptions: state.subscriptionTier,
    companyData: state.companyData
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchSubscriptions: (data) => dispatch.subscriptionTier.fetchData(data),
    upgrade: (data) => dispatch.subscriptionTier.upgrade(data),
    getCompanyData: (data) => dispatch.companyData.fetchData(data)
  };
};

export default connect(mapState, mapDispatch)(Subscriptions);
Subscriptions.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  fetchSubscriptions: PropTypes.func.isRequired
};
