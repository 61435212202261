import React, { lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { imageUrls } from '@/config/constants/keys';
import DailyLog from '../../../components/modules/DailyLog';
import { pageUrls } from '@/config/constants/keys';
import { getImagePath } from '@/utils/utils'

const layout = window?.$environment?.CURRENT_LAYOUT;
const Input = lazy(() => import(`../../../components/UI/Input/${layout}`));
const Label = lazy(() => import(`../../../components/UI/FormLabel/${layout}`));
const Message = lazy(() => import(`../../../components/UI/Message/${layout}`));
const Button = lazy(() => import(`../../../components/UI/Button/${layout}`));
const FormStyle = require(`../../../components/UI/Form/${layout}/serverStyle`);
const Modal = require(`@/components/UI/Modal/${layout}`);

const RenderComponent = ({
  values,
  handleEdit,
  errors,
  isNotValid,
  validateForm,
  customProps,
  submitAction,
  onClear
}) => {
  const { history, i18n, udEdit, finalListForUpdate } = customProps;
  const [open, setOpen] = useState(false);
  const [ModalListObject, setModalListObject] = useState({
    header: '',
    content: () => { }
  });

  return (
    <>
      <FormStyle.default.FieldsWrapper width="45%" className="editForms">
        <Label variant="body1">{i18n.t('common.driver')}</Label>
        <FormStyle.default.TextWrapper>
          <Field
            name="driverName"
            handleOnChange={(event) =>
              handleEdit(event, { field: 'driverName' })
            }
            as={Input}
            placeholder={i18n.t('common.driver')}
            disabled
            value={values.driverName || ''}
            type="text"
          />
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.FieldsWrapper width="45%" className="editForms">
        <Label variant="body1">{i18n.t('Co-Driver')}</Label>
        <FormStyle.default.TextWrapper>
          <Field
            name="codriverName"
            handleOnChange={(event) =>
              handleEdit(event, { field: 'codriverName' })
            }
            as={Input}
            disabled={customProps.viewOnly || false}
            placeholder={i18n.t('logs.codriver')}
            value={values.codriverName || ''}
            type="text"
          />
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.FieldsWrapper width="45%" className="editForms">
        <Label variant="body1">{i18n.t('common.distance')}</Label>
        <FormStyle.default.TextWrapper>
          <Field
            name="distance"
            handleOnChange={(event) => handleEdit(event, { field: 'distance' })}
            as={Input}
            disabled={customProps.viewOnly || udEdit || false}
            placeholder={i18n.t('common.driver')}
            value={values.distance || '0.0'}
            type="text"
          />
          {errors['distance'] && !isNotValid && (
            <Message type="error" message={errors['distance']} />
          )}
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.FieldsWrapper width="45%" className="editForms">
        <Label variant="body1">{i18n.t('common.carrierName')}</Label>
        <FormStyle.default.TextWrapper>
          <Field
            name="carrierName"
            handleOnChange={(event) =>
              handleEdit(event, { field: 'carrierName' })
            }
            as={Input}
            disabled={customProps.viewOnly || false}
            placeholder={i18n.t('common.carrierName')}
            value={values.carrierName || ''}
            type="text"
          />
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.FieldsWrapper width="45%" className="editForms">
        <Label variant="body1">{i18n.t('common.vehicles')}</Label>
        <FormStyle.default.TextWrapper>
          <Field
            name="vehilceName"
            handleOnChange={(event) =>
              handleEdit(event, { field: 'vehilceName' })
            }
            as={Input}
            disabled={customProps.viewOnly || false}
            placeholder={i18n.t('common.vehicles')}
            value={values.vehilceName || ''}
            type="text"
          />
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.FieldsWrapper width="45%" className="editForms">
        <Label variant="body1">{i18n.t('common.trailerName')}</Label>
        <FormStyle.default.TextWrapper>
          <Field
            name="trailerName"
            handleOnChange={(event) =>
              handleEdit(event, { field: 'trailerName' })
            }
            as={Input}
            disabled={customProps.viewOnly || false}
            placeholder={i18n.t('common.trailerName')}
            value={values.trailerName || ''}
            type="text"
          />
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.FieldsWrapper width="45%" className="editForms">
        <Label variant="body1">{i18n.t('common.fromLocation')}</Label>
        <FormStyle.default.TextWrapper>
          <Field
            name="fromLocation"
            handleOnChange={(event) =>
              handleEdit(event, { field: 'fromLocation' })
            }
            as={Input}
            disabled={customProps.viewOnly || false}
            placeholder={i18n.t('common.fromLocation')}
            value={values.fromLocation || ''}
            type="text"
          />
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.FieldsWrapper width="45%" className="editForms">
        <Label variant="body1">{i18n.t('common.toLocation')}</Label>
        <FormStyle.default.TextWrapper>
          <Field
            name="toLocation"
            handleOnChange={(event) =>
              handleEdit(event, { field: 'toLocation' })
            }
            as={Input}
            disabled={customProps.viewOnly || false}
            placeholder={i18n.t('common.toLocation')}
            value={values.toLocation || ''}
            type="text"
          />
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.FieldsWrapper width="45%" className="editForms">
        <Label variant="body1">{i18n.t('common.mainAddress')}</Label>
        <FormStyle.default.TextWrapper>
          <Field
            name="mainOfficeLocation"
            handleOnChange={(event) =>
              handleEdit(event, { field: 'mainOfficeLocation' })
            }
            as={Input}
            disabled={customProps.viewOnly || false}
            placeholder={i18n.t('common.mainAddress')}
            value={values.mainOfficeLocation || ''}
            type="text"
          />
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.FieldsWrapper width="45%" className="editForms">
        <Label variant="body1">{i18n.t('common.terminalAddress')}</Label>
        <FormStyle.default.TextWrapper>
          <Field
            name="terminal"
            handleOnChange={(event) => handleEdit(event, { field: 'terminal' })}
            as={Input}
            disabled={customProps.viewOnly || false}
            placeholder={i18n.t('common.terminalAddress')}
            value={values.terminal || ''}
            type="text"
          />
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.FieldsWrapper width="45%" className="editForms">
        <Label variant="body1">{i18n.t('common.shipDocs')}</Label>
        <FormStyle.default.TextWrapper>
          <Field
            name="shippingDocs"
            handleOnChange={(event) =>
              handleEdit(event, { field: 'shippingDocs' })
            }
            as={Input}
            disabled={customProps.viewOnly || false}
            placeholder={i18n.t('common.shipDocs')}
            value={values.shippingDocs || ''}
            type="text"
          />
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.FieldsWrapper width="45%" className="editForms">
        <Label variant="body1">{i18n.t('common.notes')}</Label>
        <FormStyle.default.TextWrapper>
          <Field
            name="note"
            handleOnChange={(event) => handleEdit(event, { field: 'note' })}
            as={Input}
            disabled={customProps.viewOnly || false}
            placeholder={i18n.t('common.notes')}
            value={values.note || ''}
            type="text"
          />

          {errors['note'] && !isNotValid && (
            <Message type="error" message={errors['note']} />
          )}
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.FieldsWrapper width="45%" className="editForms">
        <Label variant="body1">{i18n.t('common.timeZoneOffsetFromUTC')}</Label>
        <FormStyle.default.TextWrapper>
          <Field
            name="timeZoneOffsetFromUtc"
            handleOnChange={(event) =>
              handleEdit(event, { field: 'timeZoneOffsetFromUtc' })
            }
            as={Input}
            placeholder={i18n.t('common.timeZoneOffsetFromUTC')}
            value={values.timeZoneOffsetFromUtc || ''}
            type="text"
            disabled
          />
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.FieldsWrapper width="45%" className="editForms">
        <Label variant="body1">{i18n.t('common.locationOfLogSigning')}</Label>
        <FormStyle.default.TextWrapper>
          <Field
            name="logSigningLocation"
            handleOnChange={(event) =>
              handleEdit(event, { field: 'logSigningLocation' })
            }
            as={Input}
            disabled={customProps.viewOnly || false}
            placeholder={i18n.t('logs.logLocation')}
            value={values.logSigningLocation || ''}
            type="text"
          />

          {errors['logSigningLocation'] && !isNotValid && (
            <Message type="error" message={errors['logSigningLocation']} />
          )}
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.FieldsWrapper width="100%">
        <DailyLog
          yData={customProps.yData}
          xData={customProps.xData}
          handleEdit={handleEdit}
          dataList={values.dailyLogEntries || []}
          setDailyLogEntries={customProps.setDailyLogEntries}
          originalData={customProps.originalData}
          providerMode={customProps.providerMode}
          setProviderMode={customProps.setProviderMode}
          getDailyLogDetails={customProps.getDailyLogDetails}
          enableEditOW={customProps.enableEditOW}
          assignedDriver={customProps.assignedDriver}
          viewOnly={customProps.viewOnly}
          setFinalListForUpdate={customProps.setFinalListForUpdate}
          i18n={i18n}
          udEdit={udEdit}
          finalListForUpdate={finalListForUpdate}
          drivers={customProps.drivers}
          unformattedDriverList={customProps.unformattedDriverList}
          dailyLogDetails={customProps.dailyLogDetails}
        />
      </FormStyle.default.FieldsWrapper>
      {!customProps.viewOnly && (
        <>
          <FormStyle.default.FieldsWrapper
            width="100%"
            className="annotationField"
          >
            <FormStyle.default.TextareaWrapper>
              <Field
                name="comment"
                handleOnChange={(event) =>
                  handleEdit(event, { field: 'comment' })
                }
                as={Input}
                disabled={customProps.viewOnly || false}
                placeholder={i18n.t('common.annotation')}
                value={values.comment || ''}
                multiLine
                type="text"
              />

              {errors['comment'] && !isNotValid && (
                <Message type="error" message={errors['comment']} />
              )}
            </FormStyle.default.TextareaWrapper>
          </FormStyle.default.FieldsWrapper>
          <>
            {customProps.hasUpdateDailyLogDetails?.errorMessage ? (
              <Message
                type="error"
                message={i18n.t(
                  customProps.hasUpdateDailyLogDetails.errorMessage
                )}
              />
            ) : null}
          </>
          <customProps.Styled.CancelSaveWrapper>
            <FormStyle.default.ButtonsWrapper>
              <Button
                type="reset"
                label={i18n.t('Cancel')}
                onClick={() => {
                  onClear();
                  history.push(pageUrls.logsList);
                  //  clearUpdateDailyLogDetails();
                }}
              />
            </FormStyle.default.ButtonsWrapper>
            <FormStyle.default.ButtonsWrapper>
              <Button
                type="submit"
                label={i18n.t('logs.Submit')}
                onClick={() => {
                  let errorClass = document.getElementsByClassName(
                    'dailyLogDateError'
                  );
                  if (errorClass?.length === 0) {
                    const validateResponse = validateForm();
                    validateResponse.then((data) => {
                      if (customProps.providerMode) {                        
                        setModalListObject({
                          content: () => (
                            <div className="successpopup">
                              <div className="imageSection">
                                <img
                                  src={getImagePath(imageUrls.confirmIcon)}
                                  alt="Are you sure"
                                />
                              </div>
                              <h2>{i18n.t('Saving as Provider mode will update log without history, Are you sure to delete pending/previous edits?')}</h2>
                              <div className="deletePopupBtn">
                                <div className="cancelBtn">
                                  <Button
                                    label={i18n.t('common.cancel')}
                                    type={'reset'}
                                    onClick={() => {
                                      setOpen(false)
                                    }}
                                  ></Button>
                                </div>
                                <div className="deletebtn">
                                  <Button
                                    label={i18n.t('Yes, proceed!')}
                                    onClick={() => {
                                      submitAction(data);
                                      setOpen(false);
                                    }}
                                  ></Button>
                                </div>
                              </div>
                            </div>
                          )
                        });
                        setOpen(true)
                      } else {
                        submitAction(data);
                      }
                    });
                  }
                }}
              />
            </FormStyle.default.ButtonsWrapper>
          </customProps.Styled.CancelSaveWrapper>
        </>
      )}
      <Modal.default
        open={open}
        setOpen={setOpen}
        data={ModalListObject}
        isSuccess
      />
    </>
  );
};

export default RenderComponent;

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  customProps: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};
