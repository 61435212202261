import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Field } from 'formik';
import ReactDatePicker from 'react-datepicker';
import { getDateLocale } from '@/utils/utils';

import { UI_DATE_LOWER_FORMAT } from '@/config/constants/static';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Select = lazy(() => import(`@/components/UI/Select/${layout}`));
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const RenderComponent = ({
  values,
  handleEdit,
  errors,
  isNotValid,
  validateForm,
  customProps,
  submitAction,
  cancelAction
}) => {
  const { i18n } = useTranslation();

  return (
    <>
      <FormStyle.default.FieldsWrapper className="vertical" width="32%">
        <Label variant="body1">{i18n.t('common.driver')}</Label>
        <FormStyle.default.TextWrapper>
          <Field
            name="driver"
            onChange={(event) => {
              handleEdit(event, { field: 'driver' });
            }}
            as={Select}
            placeholder={i18n.t('common.pleaseSelect')}
            value={values.driver}
            suggestions={customProps.drivers}
            isClearable
          />

          {errors['driverId'] && !isNotValid && (
            <Message type="error" message={errors['driverId']} />
          )}
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>

      <FormStyle.default.FieldsWrapper className="vertical" width="32%">
        <Label variant="body1">{i18n.t('common.date')}</Label>
        <FormStyle.default.TextWrapper>
          <Field
            name="date"
            onChange={(event) => {
              handleEdit(event, { field: 'day', type: 'date' });
            }}
            locale={getDateLocale()}
            as={ReactDatePicker}
            placeholderText={i18n.t(UI_DATE_LOWER_FORMAT)}
            value={values.day}
            showPopperArrow={false}
            showTimeSelect={false}
            maxDate={new Date()}
            showDisabledMonthNavigation
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            isClearable={true}
          />
          {errors['date'] && !isNotValid && (
            <Message type="error" message={errors['date']} />
          )}
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>

      <FormStyle.default.ButtonsWrapper className="vertical-button">
        <Button
          type="submit"
          label={i18n.t('common.view')}
          onClick={(e, d) => {
            const validateResponse = validateForm();
            validateResponse.then((data) => {
              submitAction(data);
            });
          }}
        />
        <Button
          className=""
          type={'reset'}
          label={i18n.t('common.clear')}
          onClick={() => {
            cancelAction();
          }}
        />
      </FormStyle.default.ButtonsWrapper>
    </>
  );
};

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  hasApiStatus: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};

export default RenderComponent;
