import React, { useState, lazy, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

import { hasValue } from '@/utils/utils';
import { useTheme } from '@material-ui/core/styles';
import TopActions from './TopActions';

const layout = window?.$environment?.CURRENT_LAYOUT;

const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Modal = require(`@/components/UI/Modal/${layout}`).default;

const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Component {...contentProps} filters={filters} />
    </Box>
  );
};
export default Index;

const Component = ({
  history,
  companies,
  apiStatus,
  fetchCompanies,
  callback,
  filterSuccess,
  Styled,
  i18n,
  topAction,
  pagination,
  setPagination,
  filters,
  setremoveData,
  removeData
}) => {

  useEffect(() => {
        setTableData(companies.filteredList);
  }, [companies.filteredList]);

  const theme = useTheme();

  const columns = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: i18n.t('companies.companyName'),
      needSorting: true
    },
    {
      id: 'dotNumber',
      numeric: true,
      disablePadding: false,
      label: i18n.t('companies.dot'),
      needSorting: true
    },
    {
      id: 'activeTrucksNumber',
      numeric: true,
      disablePadding: false,
      label: i18n.t('companies.activeTrucks'),
      needSorting: true
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: i18n.t('companies.email'),
      needSorting: true
    },
    {
      id: 'phone',
      numeric: false,
      disablePadding: false,
      label: i18n.t('companies.phone'),
      needSorting: true
    },
    {
      id: 'manager',
      numeric: false,
      disablePadding: false,
      label: i18n.t('companies.fleetManager'),
      needSorting: true
    }
  ];

  const [tableData, setTableData] = React.useState(companies.filteredList);

  const generateRows = () => {
    const updatedList = tableData.map((item, index) => {
      return item;
    });
    return updatedList;
  };

  const hasUserListApiStatus = hasValue(apiStatus.status, 'api', fetchCompanies);
  const information =
    hasUserListApiStatus && hasUserListApiStatus.errorMessage ? (
      <Message
        type="error"
        message={i18n.t(hasUserListApiStatus.errorMessage)}
      />
    ) : (
      i18n.t('common.tableEmptyMessage')
    );

  return (
    <>
      <Styled.Wrapper>
        {topAction && (
          <TopActions
            {...{
              history,
              companies,
              callback,
              filterSuccess,
              Styled,
              i18n,
              setPagination,
              filters,
              setremoveData,
              removeData
            }}
          />
        )}

        <Styled.TableWrapper>
          <Table
            type="client"
            defaultSortBy={{ sortBy: 'full_name', order: 'asc' }}
            title=""
            columns={[...columns]}
            data={generateRows()}
            emptyMessage={information}
            itemsPerPage={10}
            actions={[]}
            pageinationReset={pagination}
            pagenationClicked={(e) => {
              setPagination(!e);
            }}
            i18n={i18n}
          />
        </Styled.TableWrapper>
      </Styled.Wrapper>
    </>
  );
};

Component.propTypes = {
  history: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  companies: PropTypes.object.isRequired,
  fetchCompanies: PropTypes.func.isRequired,
  handleDeleteAlert: PropTypes.func.isRequired,
  handleRestoreAlert: PropTypes.func.isRequired,
  filterSuccess: PropTypes.func.isRequired,
  handleExportUser: PropTypes.func.isRequired,
  handleImportUser: PropTypes.func.isRequired,
  handleExportUserTemplate: PropTypes.func.isRequired
};
