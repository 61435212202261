import styled from 'styled-components';
import Parent from '../Base/style';
const TabWrapper = styled(Parent)`
.Mui-selected {
    border-bottom: 2px solid #f58220;
    .MuiTab-wrapper {
      color: #f58220 !important;
    }
  }
`;
export default TabWrapper;
