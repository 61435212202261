import styled from 'styled-components';
import Parent from '../Base/style';
const RadioWrapper = styled(Parent)`
${({ theme, color, textColor }) => `
.MuiIconButton-label {
    border: 1px solid #999;
    background: ${theme.colors.WHITE};
    width: 13px;
    height: 13px;
    border-radius: 50%;
}
.MuiButtonBase-root.Mui-checked  .MuiIconButton-label{
  border: 1px solid ${theme.colors.LINK_COLOR};
}
.MuiFormControlLabel-root.Mui-disabled {
  .MuiIconButton-label{
    background: #efefef;
  }
}
.MuiIconButton-label {
    input:checked + div {
        left: 2.4px;
        width: 8px;
        height: 8px;
        top: 2.5px;
        border-radius: 50%;
    }
}
.MuiFormControlLabel-root {
    .MuiFormControlLabel-label {
      padding-top: 0 !important;
    }
  }
.MuiRadio-colorSecondary.Mui-checked {
  color: ${theme.colors.INPUT_BORDER};
}
span.MuiFormControlLabel-label {
  color: ${
    textColor
      ? textColor
      : theme.palette.secondary.contrastText
      ? theme.palette.secondary.contrastText
      : theme.colors.PRIMARY_CONTRAST
  };
  font-size: 20px !important;
}
`}
`;
export default RadioWrapper;
