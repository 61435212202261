import * as Base from '../base';
export const privacyPolicyScreen = Base.privacyPolicyScreen;
export const usDotManagementenabled = Base.usDotManagementenabled;
export const downloadCenter = Base.downloadCenter;
export const manageScheduling = Base.manageScheduling;
export const vehicleDTCView = Base.vehicleDTCView;


export const dashboard = [
  {
    name: 'pages/web/Dashboard/Header',
    props: ['dashboard'],
    commonProps: ['i18n', 'history', 'Styled']
  },
  {
    name: 'pages/web/Dashboard/Component',
    props: [
      'dashboard',
      'getDriverStatus',
      'getLatestLog',
      'refreshVehicleStatus',
      'refreshDriverStatus',
      'enableNotification',
      'clearAllAlerts',
      'clearAll',
      'getVehicleStatus',
      'refreshDriverStatusMap',
      'handleDeleteAlert',
      'viewReport'
    ],
    commonProps: ['Styled', 'i18n', 'history'],
    alternateComponent: false,
    mapRequired: false,
    iconsAsLink: true,
    accordion: true,

    dataList: [
      {
        id: 'alerts',
        nameKey: 'Compliance Notifications',
        value: null,
        defaultExpanded: true,
        disabled: true
      },
      {
        id: 'driverStatus',
        nameKey: 'dashboard.tabs.driverStatus.heading',
        value: null,
        dependency: '',
        defaultExpanded: true,
        disabled: true
      },
      {
        id: 'dailyLogs',
        nameKey: 'dashboard.tabs.dailyLogs.heading',
        value: null,
        defaultExpanded: true,
        disabled: true
      },
      {
        id: 'potentialViolations',
        nameKey: 'dashboard.tabs.potentialViolations.heading',
        value: null,
        dependency: '',
        defaultExpanded: true,
        disabled: true
      }
    ],
    driverStatusColumn: [
      {
        id: 'fullName',
        disablePadding: false,
        label: 'vehicles.fullName'
      },
      {
        id: 'lastKnownLocation',
        numeric: false,
        disablePadding: false,
        label: 'dashboard.tabs.driverStatus.lastKnownLocation'
      },
  
      {
        id: 'unitName',
        numeric: true,
        disablePadding: false,
        label: 'dashboard.tabs.driverStatus.unit'
      },
      {
        id: 'driveTimeRemaining',
        disablePadding: false,
        label: 'dashboard.tabs.driverStatus.driveTimeRemaining'
      },
      {
        id: 'timeUntilBreak',
        disablePadding: false,
        label: 'dashboard.tabs.driverStatus.timeUntilBreak'
      },
      {
        id: 'onDutyTimeRemaining',
        numeric: false,
        disablePadding: false,
        label: 'dashboard.tabs.driverStatus.onDutyTimeRemaining'
      },
  
      {
        id: 'dutyCycleRemaining',
        numeric: true,
        disablePadding: false,
        label: 'dashboard.tabs.driverStatus.dutyCycleRemaining'
      },
      {
        id: 'currentStatus',
        disablePadding: false,
        needSorting: true,
        label: 'dashboard.tabs.driverStatus.currentStatus'
      },
      {
        id: 'lastUpdated',
        numeric: true,
        disablePadding: false,
        label: 'dashboard.tabs.driverStatus.lastUpdated'
      },
      {
        id: 'eld',
        numeric: false,
        disablePadding: false,
        label: 'dashboard.tabs.driverStatus.eld'
      }
    ]
  }
];
export const userList = Base.userList;
export const MFAPage = Base.MFAPage;
export const recovery = Base.recovery;
export const driverStatusViewMore = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'dashboard.tabs.driverStatus.heading'
  },
  {
    name: 'pages/web/DriverStatusViewMore/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'fetchDriverStatus',
      'list',
      'driverStatusList',
      'getDriverStatus',
      'refreshDriverStatusMap',
      'driverStatusFilteredList',
      'filterSuccess',
      'changeRequest',
      'pagination',
      'setPagination'
    ],
    commonProps: ['Styled', 'i18n', 'history', 'theme'],
    title: 'component',
    topAction: true,
    mapRequired: false
  }
];
export const alertViewMore = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'common.alertSp',
    vertical: true,
    children: {
      name: 'components/modules/DataTableWithSearchExport/TopActions',
      props: [
        'filterSuccess',
        'columns',
        'exportAllData',
        'list',
        'exportCsvData',
        'exportPdfData',
        'logBook',
        'getDocumentToken',
        'setPagination'
      ],
      commonProps: ['i18n'],
      title: 'topActions',
      needSearch: false,
      needFilter: true,
      needExportAll: true,
      fileName: 'Alerts.pdf',
      isOldView: true,
      apiIds: {
        export: 'exportAllAlertData',
        list: 'alertList',
        delete: 'deleteAlerts'
      }
    }
  },
  {
    name: 'components/modules/DataTableWithSearchExport/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'list',
      'callback',
      'columns',
      'logbook',
      'pagination',
      'setPagination',
      'deleteData',
      'noAction'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled', 'emAlerts'],
    title: 'component',
    apiIds: {
      export: 'exportAllAlertData',
      list: 'alertList',
      delete: 'deleteAlerts'
    },
    fileName: 'Alerts.pdf',
    needSearch: false,
    needFilter: true,
    needExportAll: true,
    topAction: false,
    isOldView: true
  }
];
export const potentialViolationsViewMore = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'potentialViolations.heading',
    vertical: true,
    children: {
      name: 'components/modules/DataTableWithSearchExport/TopActions',
      props: [
        'filterSuccess',
        'columns',
        'exportAllData',
        'list',
        'exportCsvData',
        'exportPdfData',
        'logBook',
        'pagination',
        'setPagination'
      ],
      commonProps: ['i18n'],
      title: 'topActions',
      apiIds: {
        export: 'exportAllPotentialViolationsData',
        list: 'potentialViolations',
        delete: 'deletePotentialViolations'
      },
      fileName: 'Violations.pdf',
      needSearch: false,
      needFilter: true,
      needExportAll: true,
      isOldView: true
    }
  },
  {
    name: 'components/modules/DataTableWithSearchExport/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'list',
      'callback',
      'filterSuccess',
      'exportAllData',
      'exportCsvData',
      'exportPdfData',
      'deleteData',
      'logBook',
      'getDocumentToken',
      'columns',
      'pagination',
      'setPagination',
      'noAction'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled', 'emProps'],
    title: 'component',
    apiIds: {
      export: 'exportAllPotentialViolationsData',
      list: 'potentialViolations',
      delete: 'deletePotentialViolations'
    },
    fileName: 'Violations.pdf',
    needSearch: false,
    needFilter: true,
    needExportAll: true,
    isOldView: true
  }
];
// export const alertViewMore = Base.alertViewMore;
export const logsList = Base.logsList;
export const logsUdList = Base.logsUdList;

export const unhandled = Base.unhandled;
export const subscriptionMessage = Base.subscriptionMessage;

export const logsEdit = [
  {
    name: 'components/Title',
    props: ['apiStatus', 'dailyLogDetails'],
    commonProps: ['i18n', 'Styled'],
    header: 'Edit',
    children: {
      name: '',
      props: [],
      commonProps: ['i18n'],
      title: 'topActions'
    }
  },
  {
    name: 'pages/web/EditDailyLog/Component',
    props: [
      'apiStatus',
      'dailyLogDetails',
      'setDailyLogEntries',
      'saveDailyLogDetails'
    ],
    commonProps: ['Styled', 'i18n', 'history'],
    viewOnly: false
  }
];
export const login = Base.login;
export const policyEdit = Base.policyEdit;
// export const potentialViolationsViewMore = Base.potentialViolationsViewMore;
// export const inspections = Base.inspections;
export const inspections = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'inspections.listHeading1'
  },
  {
    name: 'pages/web/Inspections/Component',
    props: [
      'apiStatus',
      'callback',
      'fetchInspections',
      'bulkDownloadReport',
      'inspections',
      'list',
      'changeRequest',
      'isAdvancedDviRequired',
      'isCustomDviRequired',
      'logBook',
      'clearApiStatus',
      'getDocumentToken',
      'filters',
      'viewReport',
      'request',
      'setPagination',
      'pagination'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    type: 'inspections',
    topAction: true,
    needSort: false
  }
];
export const logBook = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: '',
    children: {
      name: '',
      props: [],
      commonProps: ['i18n'],
      title: 'topActions'
    }
  },
  {
    name: 'pages/web/LogBook/Component',
    props: [
      'apiStatus',
      'logBook',
      'callback',
      'handleLogBookStatus',
      'clearApiStatus',
      'breadCrumbForMap',
      'getLogbookEvents',
      'fetchLogBookView',
      'updateShowEvents',
      'dailyLogDownloadData',
      'changedDate',
      'fetchLogbookBreadcrumb',
      'getDocumentToken',
      'formValues',
      'setFormValues',
      'currentDriver',
      'dates',
      'unSignedDates',
      'drivers',
      'newDate',
      'setNewDate',
      'regeneratePdf'
    ],
    title: 'component',
    topAction: true,
    commonProps: ['Styled', 'i18n', 'history', 'theme']
  }
];
export const accidentsList = Base.accidentsList;
export const advancedDvirEnabled = Base.advancedDvirEnabled;
export const logsView = [
  {
    name: 'components/Title',
    props: ['apiStatus', 'dailyLogDetails'],
    commonProps: ['i18n', 'Styled'],
    header: 'Edit',
    children: {
      name: '',
      props: [],
      commonProps: ['i18n'],
      title: 'topActions'
    }
  },
  {
    name: 'pages/web/EditDailyLog/Component',
    props: [
      'apiStatus',
      'dailyLogDetails',
      'setDailyLogEntries',
      'saveDailyLogDetails'
    ],
    commonProps: ['Styled', 'i18n', 'history'],
    viewOnly: true
  }
];

export const signedLogsView = [
  {
    name: 'components/Title',
    props: ['apiStatus', 'dailyLogDetails'],
    commonProps: ['i18n', 'Styled'],
    header: 'Edit',
    children: {
      name: '',
      props: [],
      commonProps: ['i18n'],
      title: 'topActions'
    }
  },
  {
    name: 'pages/web/EditDailyLog/Component',
    props: [
      'apiStatus',
      'dailyLogDetails',
      'setDailyLogEntries',
      'saveDailyLogDetails'
    ],
    commonProps: ['Styled', 'i18n', 'history'],
    viewOnly: true
  }
];

export const editLog = [
  {
    name: 'components/Title',
    props: ['apiStatus', 'dailyLogDetails'],
    commonProps: ['i18n', 'Styled'],
    header: 'Edit',
    children: {
      name: '',
      props: [],
      commonProps: ['i18n'],
      title: 'topActions'
    }
  },
  {
    name: 'pages/web/EditDailyLog/Component',
    props: [
      'apiStatus',
      'dailyLogDetails',
      'setDailyLogEntries',
      'saveDailyLogDetails'
    ],
    commonProps: ['Styled', 'i18n', 'history'],
    udEdit: true
  }
];

export const editLog1 = [
  {
    name: 'components/Title',
    props: ['apiStatus', 'dailyLogDetails'],
    commonProps: ['i18n', 'Styled'],
    header: 'Edit',
    children: {
      name: '',
      props: [],
      commonProps: ['i18n'],
      title: 'topActions'
    }
  },
  {
    name: 'pages/web/EditDailyLog/Component',
    props: [
      'apiStatus',
      'dailyLogDetails',
      'setDailyLogEntries',
      'saveDailyLogDetails'
    ],
    commonProps: ['Styled', 'i18n', 'history'],
    udEdit: true
  }
];

export const logsHistory = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: '',
    children: {
      name: '',
      props: [],
      commonProps: ['i18n'],
      title: 'topActions'
    }
  },
  {
    name: 'pages/web/LogHistory/Component',
    props: ['apiStatus', 'logsHistory', 'documentToken', 'viewReport'],
    commonProps: ['Styled', 'i18n', 'theme', 'history']
  }
];

export const departmentsList = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'departments.filter'
  },
  {
    name: 'pages/web/Department/Component',
    props: [
      'apiStatus',
      'callback',
      'fetchTerminals',
      'data',
      'saveRegion',
      'updateRegion',
      'deleteRegion',
      'saveTerminal',
      'deleteTerminal',
      'updateTerminal',
      'saveDepartment',
      'updateDepartment',
      'deleteDepartment',
      'saveSubDepartment',
      'updateSubDepartment',
      'deleteSubDepartment',
      'setAddEditModalObject',
      'clickedAddEditModal',
      'closeAddEditModal',

      'isTermCrudRestricted',
      'getTmezonePopup',
      'isCrudRestricted',
      'isDeptCrudRestricted',
      'isLocCrudRestricted',
      'showFilterPopup'

    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    topAction: true,
    isButtonStyle: false,
    isShowDotNumber: true,
    neglectAccessLevel: true
  }
];
;
export const archiveLogsList = Base.archiveLogsList;

export const editUser = Base.editUser;
export const editMechanic = Base.editUser;
export const viewUser = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'users.editHeading'
  },
  {
    name: 'components/modules/UserManagement/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'fetchUserData',
      'user',
      'saveDocument',
      'saveUserData',
      'deleteDocument',
      'handleDownloadDoc',
      'downloadDocument',
      'logBook',
      'getDocumentToken',
      'accessLevel',
      'fetchDepartments',
      'fetchSubDepartments',
      'fetchRegions',
      'fetchTerminals',
      'handleLogout',
      'removeApiStatus',
      'getDrivers',
      'downloadUrl',
      'receipt'
    ],
    commonProps: ['Styled', 'i18n', 'history', 'theme'],
    title: 'component',
    type: 'edit',
    viewOnly: true
  }
];

export const addUser = Base.addUser;
export const addMechanic = Base.addUser;
export const driverStatusHistory = Base.driverStatusHistory;
export const editVehicle = Base.editVehicle;
export const addVehicle = Base.addVehicle;
export const companyDataEdit = Base.companyDataEdit;
export const profile = Base.profile;
export const reportsEnabled = Base.reportsEnabled;
export const workOrders = Base.workOrders;
export const addWorkOrders = Base.addWorkOrders;
export const editWorkOrder = Base.editWorkOrder;
export const partsInventoryEnabled = Base.partsInventoryEnabled;
export const resetPassword = Base.resetPassword;
export const customDvirEnabled = Base.customDvirEnabled;
export const addOrEditNewForm = Base.addOrEditNewForm;
export const addOrEditNewFormTrailer = Base.addOrEditNewFormTrailer;
export const viewWorkOrder = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'workOrders.addHeading'
  },
  {
    name: 'components/modules/WorkOrdersManagement/Component',
    props: [
      'workOrders',
      'apiStatus',
      'hasFetchApiStatus',
      'fetchUserData',
      'user',
      'saveDocument',
      'saveUserData',
      'deleteDocument',
      'handleDownloadDoc',
      'fetchCountData',
      'downloadDocument',
      'logBook',
      'getDocumentToken',
      'documents',
      'accessLevel',
      'fetchDepartments',
      'fetchSubDepartments',
      'fetchRegions',
      'fetchTerminals',
      'handleLogout',
      'workOrders',
      'fetchAddWorkOrders',
      'saveAddWorkOrders',
      'callback',
      'viewReport',
      'receipt',
      'clearState'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    type: 'edit',
    viewOnly: true,
  },
  {
    name: 'components/modules/WorkOrdersManagement/defectList',
    props: [
      'workOrders',
      'apiStatus',
      'hasFetchApiStatus',
      'saveDocument',
      'deleteDocument',
      'handleDownloadDoc',
      'downloadDocument',
      'getDocumentToken',,
      'workOrders',
      'callback',
      'viewReport',
      'clearState'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    type: 'edit'
  }
];

export const mechanicList = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'users.listHeading'
  },
  {
    name: 'pages/web/Users/Component',
    topAction: false,
    props: [
      'apiStatus',
      'users',
      'handleImportUser',
      'handleExportUser',
      'filterSuccess',
      'handleRestoreAlert',
      'handleDeleteAlert',
      'handleResetPassword',
      'fetchUsers',
      'callback',
      'handleExportUserTemplate',
      'filters',
      'logBook'
    ],
    commonProps: ['Styled', 'i18n', 'history', 'theme'],
    title: 'component',

    columns: [
      {
        id: 'full_name',
        numeric: false,
        disablePadding: true,
        label: 'vehicles.fullName',
        needSorting: true
      },
      {
        id: 'role',
        numeric: false,
        disablePadding: false,
        label: 'vehicles.role',
        needSorting: false
      },
      {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: 'vehicles.email',
        needSorting: true
      },
      {
        id: 'empId',
        numeric: true,
        disablePadding: false,
        label: 'vehicles.empId',
        needSorting: true
      },
      {
        id: 'phone',
        numeric: true,
        disablePadding: false,
        label: 'common.phone',
        needSorting: false
      },
      {
        id: 'assigned_vehicles',
        numeric: false,
        disablePadding: false,
        label: 'vehicles.assignedVehicles',
        needSorting: false
      },
      {
        id: 'document',
        label: 'common.documents'
      },
      {
        id: 'last_login',
        numeric: false,
        disablePadding: false,
        label: 'vehicles.lastLogin',
        needSorting: false
      },
      {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
        needSorting: true
      },
      {
        id: 'eld',
        numeric: false,
        disablePadding: false,
        label: 'ELD',
        needSorting: false
      },
      {
        id: 'actions',
        label: ''
      }
    ]
  }
];
