import React, { useEffect, lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RenderComponent from '@/components/modules/DynamicRenderPage';

import ParentStyled from './style';
import { loadStyle, loadPageConfig } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'pages/web/SeatBeltWarning');

const config = loadPageConfig()
const Title = lazy(() => import(`@/${config.seatBeltWarning[0].name}`));
const Content = lazy(() => import(`@/${config.seatBeltWarning[1].name}`));
const TopActions =
  config.seatBeltWarning[0].children &&
  lazy(() => import(`${config.seatBeltWarning[0].children.name}`));
const SeatBeltWarning = (parentProps) => {
  const {
    history,
    i18n,
    theme,
    getDrivers,
    getVehicleListDropDown,
    driverbehavior,
    fetchDriverBehaviorDetails,
    fetchTableData,
    chat,
    vehicleListDropDown
  } = parentProps;

  useEffect(() => {
    getDrivers({ apiId: 'getDrivers' });
    getVehicleListDropDown({ apiId: 'getVehicleListDropDown' });
    fetchDriverBehaviorDetails({
      apiId: 'fetchDriverDetails'
    });
    fetchTableData({
      apiId: 'fetchTableData',
      data: {
        endDate: '',
        startDate: '',
        id: ''
      },
      type: 'seatBeltWarning'
    });
  }, []);

  const commonParentProps = { history, i18n, Styled, theme };

  const formatDataForChart = (responseData) => {
    let tempData = {
      labels: [],
      data: []
    };
    if (responseData) {
      responseData.forEach(function (item) {
        tempData['labels'].push(item.date);
        tempData['data'].push(item.count);
      });
    }
    return tempData;
  };

  const seatBeltWarningData = formatDataForChart(
    driverbehavior.seatBeltWarning.chart
  );

  const objectArrayFromDiffFields = (list, value, field1, field2, callback) => {
    return list?.length
      ? list.map((item) => {
          if (callback) return callback(item, field1, field2, value);
          else
            return {
              label: field2 ? `${item[field1]} ${item[field2]}` : item[field1],
              value: item[value]
            };
        })
      : [];
  };

  const driverList = objectArrayFromDiffFields(
    chat?.drivers,
    'userId',
    'firstName',
    'lastName'
  );

    const vehicleListDropDownDF = objectArrayFromDiffFields(
    vehicleListDropDown.filter(el => !!el.esn || !!el.cameraSerialNo),
    'vehicleId',
    'name'
  )

  return (
    <>
      <RenderComponent
        {...config.seatBeltWarning[0]}
        parentProps={{
          ...parentProps,
          driverList
        }}
        commonParentProps={commonParentProps}
        component={Title}
        childComponent={TopActions}
      />
      <RenderComponent
        {...config.seatBeltWarning[1]}
        parentProps={{ ...parentProps, driverList, vehicleListDropDown: vehicleListDropDownDF, vehicleList: vehicleListDropDown }}
        commonParentProps={commonParentProps}
        component={Content}
        chartData={seatBeltWarningData}
        tableData = {driverbehavior.seatBeltWarning.table}
        driverbehavior={driverbehavior}
      />
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    chat: state.chat,
    driverbehavior: state.driverbehavior,
    vehicleListDropDown: state?.vehicles?.vehicleListDropDown
  };
};
const mapDispatch = (dispatch) => {
  return {
    clearApiStatus: () => dispatch.asyncRequests.clearApiStatus(),
    getVehicleListDropDown: (data) => dispatch.vehicles.fetchVehicleListDropDown(data),
    getDrivers: (data) => dispatch.chat.getDrivers(data),
    fetchDriverBehaviorDetails: (data) =>
      dispatch.driverbehavior.fetchDriverBehaviorDetails(data),
    fetchTableData: (data) => dispatch.driverbehavior.fetchTableData(data),
    fetchChartData: (data) => dispatch.driverbehavior.fetchChartData(data)
  };
};

export default connect(mapState, mapDispatch)(SeatBeltWarning);
SeatBeltWarning.propTypes = {
  apiStatus: PropTypes.object.isRequired
};
