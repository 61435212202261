import {
  getTerminalDepartmentLocation,
  addRegionApi,
  updateRegionApi,
  regionDeleteApi,
  addTerminalApi,
  updateTerminalApi,
  terminalDeleteApi,
  addDepartmentApi,
  updateDepartmentApi,
  departmentDeleteApi,
  addSubDepartmentApi,
  updateSubDepartmentApi,
  subDepartmentDeleteApi
} from '../../../config/apis';
import { deleteJsonArrayItemByField } from '../../../utils/utils';

import { find } from 'lodash';

const initialState = {
  data: { selectedRegion: null }
};

export const departments = {
  state: { ...initialState },
  reducers: {
    listSuccess: (state, payload, id) => {
      return { data: { ...payload, selectedRegion: id } };
    },
    saveSuccess: (state, payload) => {
      return {
        data: {
          ...state.data,
          regionsDtos: [...state.data.regionsDtos, payload]
        }
      };
    },
    updateSuccess: (state, payload) => {
      const index = state.data.regionsDtos.findIndex(
        (item) => item.id === payload.id
      );
      state.data.regionsDtos[index] = payload;
      return {
        data: {
          ...state.data,
          regionsDtos: [...state.data.regionsDtos]
        }
      };
    },
    deleteSuccess: (state, payload) => {
      return {
        data: {
          ...state.data,
          regionsDtos: [...payload]
        }
      };
    },
    saveTerminalSuccess: (state, payload) => {
      return {
        data:
          payload?.regionId == state.data.selectedRegion
            ? {
                ...state.data,
                terminalDtos: [...state.data.terminalDtos, payload]
              }
            : {
                ...state.data
              }
      };
    },
    updateTerminalSuccess: (state, payload) => {
      const index = state.data.terminalDtos.findIndex(
        (item) => item.id === payload.id
      );
      state.data.terminalDtos[index] = payload;
      return {
        data:
          payload?.regionId == state.data.selectedRegion
            ? {
                ...state.data,
                terminalDtos: [...state.data.terminalDtos]
              }
            : {
                ...state.data
              }
      };
    },
    deleteTerminalSuccess: (state, payload) => {
      return {
        data:
          payload?.regionId == state.data.selectedRegion
            ? {
                ...state.data,
                terminalDtos: [...payload]
              }
            : {
                ...state.data
              }
      };
    },
    saveDepartmentSuccess: (state, payload) => {
      let terminalObj = find(state.data.terminalDtos, {
        terminalId: payload?.terminalId
      });
      terminalObj['departmentList'] = [...terminalObj.departmentList, payload];
      return {
        data: {
          ...state.data,
          terminalDtos: [...state.data.terminalDtos, terminalObj]
        }
      };
    },
    updateDepartmentSuccess: (state, payload) => {
      let terminalObj = find(state.data.terminalDtos, {
        terminalId: payload?.terminalId
      });
      terminalObj['departmentList'] = [
        ...terminalObj.departmentList.filter(
          (item) => item.departmentId != payload.departmentId
        ),
        payload
      ];
      return {
        data: {
          ...state.data,
          terminalDtos: [...state.data.terminalDtos, terminalObj]
        }
      };
    },
    deleteDepartmentSuccess: (state, payload) => {
      let currentTerminalObj =
        find(state.data.terminalDtos, {
          regionId: parseInt(state.data.selectedRegion)
        }) || {};
      currentTerminalObj[
        'departmentList'
      ] = currentTerminalObj.departmentList.filter(
        (item) => item.departmentId != payload.data.data.id
      );
      return {
        data: {
          ...state.data,
          terminalDtos: [...state.data.terminalDtos, currentTerminalObj]
        }
      };
    },
    saveFailed: (state) => {
      return { ...state };
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload) {
      try {
        const url = getTerminalDepartmentLocation.url.split('{')[0];

        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...getTerminalDepartmentLocation,
              url: url + payload.data.id
            }
          },
          id: payload.apiId
        });
        if (response) {
          this.listSuccess(response, payload.data.id);
        }
      } catch (error) {
        this.saveFailed();
      }
    },
    async saveRegion(payload, root) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: addRegionApi,
            data: payload.data
          },
          id: payload.apiId,
          callback: payload.callback
        });
        dispatch.departments.fetchData({
          data: { id: root.departments.data.selectedRegion }
        });
        payload.callback('success', payload.i18n.t('common.saveRegion'));
      } catch (error) {
        this.saveFailed();
      }
    },
    async updateRegion(payload, root) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: updateRegionApi,
            data: payload.data
          },
          id: payload.apiId,
          callback: payload.callback
        });
        dispatch.departments.fetchData({
          data: { id: root.departments.data.selectedRegion }
        });
        payload.callback('success', payload.i18n.t('common.updateRegion'));
      } catch (error) {
        this.saveFailed();
      }
    },
    async deleteRegion(payload, root) {
      try {
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...regionDeleteApi,
              url: regionDeleteApi.url.replace('{id}', payload.data.data.id)
            }
          },
          callback: payload.callback,
          id: payload.apiId
        });
        dispatch.departments.fetchData({
          data: { id: root.departments.data.selectedRegion }
        });
        payload.callback('success', payload.i18n.t('common.deleteRegion'));
      } catch (error) {}
    },
    async saveTerminal(payload, root) {
      try {
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: addTerminalApi,
            data: payload.data
          },
          id: payload.apiId
        });
        dispatch.departments.fetchData({
          data: { id: root.departments.data.selectedRegion }
        });
        payload.callback('success', payload.i18n.t('common.terminalAdd'));
      } catch (error) {
        payload.callback('error', error.message, payload);
      }
    },
    async updateTerminal(payload, root) {
      try {
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: updateTerminalApi,
            data: payload.data
          },
          id: payload.apiId,
          callback: payload.callback
        });
        dispatch.departments.fetchData({
          data: { id: root.departments.data.selectedRegion }
        });
        payload.callback('success', payload.i18n.t('common.terminalUpdate'));
      } catch (error) {
        payload.callback('error', error.message, payload);
      }
    },
    async deleteTerminal(payload, root) {
      try {
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...terminalDeleteApi,
              url: terminalDeleteApi.url.replace('{id}', payload.data.data.id)
            }
          },
          callback: payload.callback,
          id: payload.apiId
        });
        dispatch.departments.fetchData({
          data: { id: root.departments.data.selectedRegion }
        });
        payload.callback('success', payload.i18n.t('common.deleteTerminal'));
      } catch (error) {}
    },
    async saveDepartment(payload, root) {
      try {
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: addDepartmentApi,
            data: payload.data
          },
          id: payload.apiId
        });
        dispatch.departments.fetchData({
          data: { id: root.departments.data.selectedRegion }
        });
        payload.callback('success', payload.i18n.t('common.departmentAdded'));
      } catch (error) {
        payload.callback('error', error.message);
      }
    },
    async updateDepartment(payload, root) {
      try {
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: updateDepartmentApi,
            data: payload.data
          },
          id: payload.apiId,
          callback: payload.callback
        });
        dispatch.departments.fetchData({
          data: { id: root.departments.data.selectedRegion }
        });
        payload.callback('success', payload.i18n.t('common.departmentUpdated'));
      } catch (error) {
        payload.callback('error', error.message);
      }
    },
    async deleteDepartment(payload, root) {
      try {
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...departmentDeleteApi,
              url: departmentDeleteApi.url.replace('{id}', payload.data.data.id)
            }
          },
          callback: payload.callback,
          id: payload.apiId
        });
        dispatch.departments.fetchData({
          data: { id: root.departments.data.selectedRegion }
        });
        payload.callback('success', payload.i18n.t('common.deleteDepartment'));
      } catch (error) {}
    },
    async saveSubDepartment(payload, root) {
      try {
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: addSubDepartmentApi,
            data: payload.data
          },
          id: payload.apiId
        });
        dispatch.departments.fetchData({
          data: { id: root.departments.data.selectedRegion }
        });

        payload.callback('success', payload.i18n.t('common.locationAdded'));
      } catch (error) {
        payload.callback('error', error.message, payload);
      }
    },
    async updateSubDepartment(payload, root) {
      try {
        delete payload?.data?.selectedRegion;
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: updateSubDepartmentApi,
            data: payload.data
          },
          id: payload.apiId
        });
        dispatch.departments.fetchData({
          data: { id: root.departments.data.selectedRegion }
        });

        payload.callback('success', payload.i18n.t('common.locationUpdated'));
      } catch (error) {
        payload.callback('error', error.message, payload);
      }
    },
    async deleteSubDepartment(payload, root) {
      try {
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...subDepartmentDeleteApi,
              url: subDepartmentDeleteApi.url.replace(
                '{id}',
                payload.data.data.id
              )
            }
          },
          callback: payload.callback,
          id: payload.apiId
        });
        dispatch.departments.fetchData({
          data: { id: root.departments.data.selectedRegion }
        });
        payload.callback('success', payload.i18n.t('common.locationDeleted'));
      } catch (error) {}
    }
  })
};
