import {
  getEditUserApi,
  editUserApi,
  documentUploadApi,
  documentDeleteApi
} from '../../../../config/apis';

const initialState = {};

export const userEdit = {
  state: { ...initialState },
  reducers: {
    editSuccess(state, payload) {
      return {};
    },
    editFailed() {
      return { ...initialState };
    },
    getSuccess(state, payload) {
      return { ...payload };
    },
    getFailed() {
      return { ...initialState };
    },
    updateLocalDocumentList(state, response) {
      const documents = state.documentsList.map((item) => {
        if (item.type.name === response?.response?.type?.name) {
          return { ...response.response };
        } else if (item.id === response?.id) {
          if (response?.isDelete) {
            return {
              ...item,
              id: null,
              name: null,
              url: null,
              uploaded: null,
              expiration_date: null
            };
          } else {
            return { ...item };
          }
        } else {
          return { ...item };
        }
      });
      return { ...state, documentsList: [...documents] };
    }
  },
  effects: (dispatch) => ({
    async saveData(payload, rootState) {
      try {
        const response =await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: editUserApi,
            data: payload.data.data
          },
          id: payload.apiId
        });
        // response && this.addSuccess(response);
        payload.callback && payload.callback(payload.data.data);
      } catch (error) {
        // this.editFailed();
      }
    },
    async fetchData(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...getEditUserApi,
              url: getEditUserApi.url.replace('{id}', payload.data.id)
            }
          },
          id: payload.apiId,
          redirectionUrl: payload.redirectionUrl,
          callback: payload.callback,
          history: payload.history
        });
        dispatch.regions.updateAll({});
        this.getSuccess(response);
      } catch (error) {
        this.getFailed();
      }
    },
    async saveDocument(payload) {
      try {
        const data = payload.data.data;
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: documentUploadApi,
            data: data
          },
          id: payload.apiId,
          callback: payload.callback
        });
        response && this.updateLocalDocumentList({ response });
      } catch (error) {
        // this.saveFailed();
      }
    },
    async deleteDocument(payload) {
      try {
        const url = documentDeleteApi.url.split('{')[0];
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: { ...documentDeleteApi, url: url + payload.data.id }
          },
          id: payload.apiId
        });
        this.updateLocalDocumentList({
          isDelete: true,
          id: payload.data.id
        });
      } catch (error) {
        // this.deleteFailed();
      }
    },
    async removeApiStatus(){
      try {
        await dispatch.asyncRequests.clearApiStatus();
      } catch (error) {}
    }
  })
};
