import {
    addFleetManagerApi,
    getFleetManagerApi,
    addTermsCheckApi
  } from '../../../../config/apis';

  const initialState = {
    fleetmanagerInfoData: {},
    states: [],
    selectedRow: { index: -1, row: {} },
    getfleetmanagerInfoData: {},
    signeeName: '',
    signed: false
  };

  export const fleetmanagerInfo = {
    state: { ...initialState },
    reducers: {
      listSuccess(state, payload) {
        return { ...state, fleetmanagerInfoData: {...payload}};
      },
      listFailed(state) {
        return { ...initialState, getfleetmanagerInfoData: {...state.getfleetmanagerInfoData}, signeeName: state.signeeName};
      },
      getSuccess(state, payload) {
        return { ...state, getfleetmanagerInfoData: {...payload}, signeeName: state.signeeName};
      },
      saveName(state, payload) {
        return {...state, signeeName: payload.data, signed: true}
      },
      clearPost(state) {
        return {...state, fleetmanagerInfoData:{}, signed: false}
      }

    },
    effects: (dispatch) => ({
      async addFleetManager(payload) {
        try {
          const response = await dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              api: addFleetManagerApi,
              data: payload.data
            },
            id: payload.id,
            callback: payload.callback
          });
          response && this.listSuccess(response);
        } catch (error) {
          this.listFailed();
        }
      },
      async getFleetManager(payload) {
        try {
          const response = await dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              api: getFleetManagerApi,
              data: payload.data
            },
            id: payload.id,
            callback: payload.callback
          });
          response && this.getSuccess(response);
        } catch (error) {
          this.listFailed();
        }
      },
      async addSigneeName(payload) {
        try {
          const response = await dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              api: addTermsCheckApi,
              data: payload.companyId
            },
            id: payload.id,
          });
          this.saveName(payload)
        } catch (error) {
          this.saveName(payload)
         }
      },
      async clearValues() {
        this.clearPost()
      }
    })
  };
