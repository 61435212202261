import React from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import Link from '@material-ui/core/Link';
import { getImagePath } from '../../../utils/utils';

import { externalUrls, imageUrls } from '../../../config/constants/keys';
import Styled from './publicStyle';

const PublicWrapper = ({ children }) => {
  return (
    <Styled.Wrapper>
      <Styled.Content>
        {children}
      </Styled.Content>
    </Styled.Wrapper>
  );
};

export default withRouter(PublicWrapper);
PublicWrapper.defaultProps = {
  children: null
};
PublicWrapper.propTypes = {
  children: PropTypes.node
};
