import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const isSpireon = window.$environment?.SERVICE_PROVIDER === 'spireon'

const Wrapper = styled(Box)`
${({ theme }) => `
    .policyLine {
        display: flex;
        padding-top: 30px;
        padding-bottom: 15px;
        .policyText {
            color: ${theme.colors.PRIMARY_CONTRAST};
            padding-right: 20px;
            font-size: 20px;
            p {
                margin: 0;
            }
            p:last-child {
                margin-top: 5px;
                color: ${theme.colors.UPLOAD_LABEL};
            }
        }
        .filepond--drop-label.filepond--drop-label label {
            text-align: center;
            width: 100%;
        }
        .filepond--drop-label {
            padding-left: 0;
        }
        .filepond--list {
            height: 100%;
            width: 100%;
            left: 0;
            right: 0;
        }
        .filepond--wrapper {
            height: 120px;
            width: 120px;
        }
        .filepond--image-preview-overlay-idle {
            opacity: 0 !important;
        }
        .filepond--item {
            margin: 0;
        }
        .filepond--image-preview-overlay,
        .filepond--item,
        .filepond--root,
        .filepond--root .filepond--drop-label,
        .filepond--browser.filepond--browser,
        .filepond--list-scroller {
            height: 100% !important;
            overflow:hidden !important
        }
        .filepond--image-canvas-wrapper {
            width: 412px !important;
            height: 421px !important;
        }
        .filepond--image-preview-overlay svg {
            width: 120px !important;
            height: 120px !important;
        }
        .filepond--file-info,
        .filepond--action-remove-item{
            position: absolute;
            top: 80px;
        }
        .filepond--image-bitmap,
        .filepond--image-clip {
            width: 100% !important;
            height: 100% !important;
        }
    }
    .selectedCheckbox {
        display: flex;
        flex-wrap: wrap;
        padding-top: 5px;
    }
    #submitPolicy {
        min-width: 210px;
    }
    .customPolicy {
        width: 100% !important;
        .customPolicyTableContainer {
            max-width: 800px;
            // To Fix Pagination Style issue
            ${!isSpireon   ? `.MuiBox-root {
                display: flex !important;
                justify-content: end !important;
            }
            .MuiFlatPagination-root {
                display: flex !important;
                justify-content: end !important;
            }` : `.MuiBox-root {}`}

            ${isSpireon ? `.MuiButtonBase-root {
                flex: 0 !important;
            }` : `.MuiButtonBase-root {}`}
        }
        .uploadButtonContainer {
            max-width: 15rem;
            margin: 20px 0 20px 0;
        }
    }
    ${isSpireon ? `.MuiTablePagination-actions {
        display: flex !important;
       }` : `.MuiTablePagination-actions {}`
    }

    .submitPolicyButtonContainer {
        margin-left: auto;
        margin-top: 60px;
        margin-right: 100px !important;

        .bcYFWx {
            margin: 0 !important;
        }
    }
    .driverstatusTitle{
        display: block;
        color: #42505a;
        font: bold 12px "Helvetica Neue", Helvetica, Arial, sans-serif;
        padding: 10px;
        padding-left: 10px;
        background: -webkit-linear-gradient(top, #d0d8db 0%, #b3bec3 100%);
        align-items: center;
    }
    .customPolicyTemplate {
        width: 100% !important;
        marigin-bottom: 20px !important;
        .customPolicyTemplateButton {
            margin: 10px 0 10px 0;
            max-width: 22rem;
        }
        .customPolicyTemplateLink {
            underline: none !important;
            text-decoration: none !important;
        }
    }
`}
`;


const Styled = { Wrapper };

export default Styled;
