import {
  getEditTrailerApi,
  getEditTrailerInsuranceApi,
  addTrailerApi,
  documentUploadApi,
  documentDeleteApi
} from '../../../../config/apis';

const initialState = {};

export const trailerEdit = {
  state: { ...initialState },
  reducers: {
    editSuccess(state, payload) {
      return {};
    },
    editFailed() {
      return { ...initialState };
    },
    getSuccess(state, payload) {
      return { ...payload };
    },
    getRecallSuccess(state, payload) {
      return { ...payload, trailerDetails: state.trailerDetails }
    },
    getFailed() {
      return { ...initialState };
    },
    updateLocalDocumentList(state, response) {
      const documents = state.documents.map((item) => {
        if (item.type.name === response?.response?.type?.name) {
          return { ...response.response };
        } else if (item.id === response?.id) {
          if (response?.isDelete) {
            return {
              ...item,
              id: null,
              name: null,
              url: null,
              uploaded: null,
              expiration_date: null
            };
          } else {
            return { ...item };
          }
        } else {
          return { ...item };
        }
      });
      return { ...state, documents: [...documents] };
    }
  },
  effects: (dispatch) => ({
    async saveData(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: addTrailerApi,
            data: payload.data.data
          },
          id: payload.apiId
        });
        payload.callback && payload.callback();
        response && dispatch.vehicles.updateListResponse(response);
      } catch (error) {
        // this.editFailed();
      }
    },
    async fetchData(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...getEditTrailerApi,
              url: getEditTrailerApi.url.replace('{id}', payload.data.id)
            }
          },
          id: payload.apiId
        });
        this.getSuccess(response);
      } catch (error) {
        this.getFailed();
      }
    },
    async fetchInsuranceData(payload, rootState) {
      try {
        let queryParam = '&'
        if (payload?.data?.company) queryParam += `companyId=${payload?.data?.company}`
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...getEditTrailerInsuranceApi,
              url: getEditTrailerInsuranceApi.url.replace('{id}', payload.data.id) + `${queryParam.length > 1 ? queryParam : ''}`
            }
          },
          id: payload.apiId
        });
        if (payload?.data?.recall) this.getRecallSuccess(response)
        else this.getSuccess(response)
      } catch (error) {
        this.getFailed();
      }
    },
    async saveDocument(payload) {
      try {
        const data = payload.data.data;
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: documentUploadApi,
            data: data
          },
          id: payload.apiId,
          callback: payload.callback
        });
        response && this.updateLocalDocumentList({ response });
      } catch (error) {
        this.saveFailed();
      }
    },
    async deleteDocument(payload) {
      try {
        const url = documentDeleteApi.url.split('{')[0];
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: { ...documentDeleteApi, url: url + payload.data.id }
          },
          id: payload.apiId
        });
        this.updateLocalDocumentList({
          isDelete: true,
          id: payload.data.id
        });
      } catch (error) {
        this.deleteFailed();
      }
    }
  })
};
