import styled from 'styled-components';
import Parent from './style';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Parent.Wrapper)`
    ${({ theme }) => `
    form {
        padding-top: 20px;
        padding-left: 25px;
        align-items: flex-end;
        .MuiFormLabel-root {
            font-size: 20px;
        }
        button.MuiButtonBase-root {
            margin-top: -48px;
            .MuiButton-label{
                padding: 0 15px;
            }
        }
        .vertical {
            position: relative;
            .MuiSvgIcon-root {
                position: absolute;
                right: 0;
                width: 18px;
                height: 18px;
                color: #767676;
                top: 42px;
            }
        }
        .vertical:nth-child(2),
        .vertical:nth-child(3) {
            width: 10%;
            .react-datepicker-wrapper input {
                height: 36px !important;
            }
        }
        .vertical:nth-child(4) {
            width: 12%;
        }
    } 
    div[type="info"] {
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
        p {
            color: #FFA500;
            font-family: ${theme.palette.titleFont};
            padding-left: 25px;
            padding-bottom: 16px;
        }
    }
    div[type="error"] p {
        font-size: 12px !important;
        font-family: tahoma;
    }
    `}
`;
const Content = styled(Box)`
${({ theme }) => `
position: relative;
    .goBack {
        position: absolute;
        top: 11px;
        left: 25px;
    }
   header {
       box-shadow: none;
       padding-left: 25px;
   }
`}
`;
const Styled = { Wrapper, Content};

export default Styled;
