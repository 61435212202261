import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import { getDateLocale } from '@/utils/utils';
import ReactDatePicker from 'react-datepicker';
import { UI_DATE_LOWER_FORMAT } from '@/config/constants/static';
// import Styled from './style';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Input = lazy(() => import(`@/components/UI/Input/${layout}`));
const Upload = lazy(() => import(`@/components/FileUpload/${layout}`));
const SubHeader = lazy(() => import(`@/components/SubHeader/${layout}`));
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Select = lazy(() => import(`@/components/UI/Select/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const RenderComponent = ({
  id,
  values,
  handleEdit,
  errors,
  isNotValid,
  validateForm,
  customProps,
  submitAction
}) => {
  const { i18n } = useTranslation();
  return (
    <customProps.Styled.PopupUpload>
      <SubHeader text={values?.id ? i18n.t('Edit') : i18n.t('Add')} variant="h1" className="uploadHeading" />
      <customProps.Styled.UploadWrapper>
        <div className="uploadFields">
          <div className='uploadField__left'>
            <FormStyle.default.FieldsWrapper width="100%" flexDirection="column">
              <Label variant="body1">{i18n.t('common.vehicle')}</Label>
              <FormStyle.default.TextWrapper>
                <Field
                  maxHeight="50px"
                  name="vehicle"
                  onChange={(event) => {
                     handleEdit(event, { field: 'vehicle' })
                    }
                  }
                  as={Select}
                  value={values.vehicle}
                  suggestions={customProps.vehicleList}
                  disabled={!!values?.id}
                />
                {errors['vehicle'] && !isNotValid && (
                  <Message type="error" message={i18n.t('common.fieldRequiredMessage')} />
                )}
              </FormStyle.default.TextWrapper>
            </FormStyle.default.FieldsWrapper>

            <FormStyle.default.FieldsWrapper width="100%" flexDirection="column">
              <Label variant="body1">{i18n.t('common.driver')}</Label>
              <FormStyle.default.TextWrapper>
                <Field
                  name="driver"
                  onChange={(event) => {
                      handleEdit(event, { field: 'driver' })
                    }
                  }
                  as={Select}
                  value={values.driver}
                  suggestions={customProps.driverList}
                  disabled={!!values?.id}
                />
                {errors['driver'] && !isNotValid && (
                  <Message type="error" message={i18n.t('common.fieldRequiredMessage')} />
                )}
             </FormStyle.default.TextWrapper>
             </FormStyle.default.FieldsWrapper>

             <FormStyle.default.FieldsWrapper width="100%" flexDirection="column">
                  <Label variant="body1">{i18n.t('refillsHistory.fuelAmount')}</Label>
                  <FormStyle.default.TextWrapper>
                    <Field
                      name="amount"
                      handleOnChange={(event) => {
                        if(event >= 0) {
                          handleEdit(event, { field: 'amount' })
                        }
                        }
                      }
                      placeholder={i18n.t('refillsHistory.fuelAmount')}
                      as={Input}
                      type="number"
                      min="0"
                    />

                {errors['amount'] && !isNotValid && (
                  <Message type="error" message={errors['amount']} />
                )}
                  </FormStyle.default.TextWrapper>
                </FormStyle.default.FieldsWrapper>

                <FormStyle.default.FieldsWrapper width="100%" flexDirection="column">
                    <Label variant="body1">{i18n.t('refillsHistory.odometer')}</Label>
                    <FormStyle.default.TextWrapper>
                      <Field
                        name="odometer"
                        handleOnChange={(event) => {
                          if(event >= 0) {
                            handleEdit(event, { field: 'odometer' })
                          }
                        }
                        }
                        placeholder={i18n.t('refillsHistory.odometer')}
                        as={Input}
                        type="number"
                        min="0"
                      />

                  {errors['odometer'] && !isNotValid && (
                    <Message type="error" message={errors['odometer']} />
                  )}
                    </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
          </div>


          <div className='uploadField__right'>
              {((!values?.jurisdiction?.value && values?.id) || (values.location)) ? 
                <FormStyle.default.FieldsWrapper width="100%" flexDirection="column">
                <Label variant="body1">{window.$environment?.SERVICE_PROVIDER == 'tp' ? i18n.t('fuelConsumption.JurisdictionLocation') : i18n.t('fuelConsumption.Jurisdiction')}</Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="location"
                    handleOnChange={(event) => {
                      handleEdit(event, { field: 'location' })
                     }
                    }
                    placeholder={window.$environment?.SERVICE_PROVIDER == 'tp' ? i18n.t('fuelConsumption.JurisdictionLocation') : i18n.t('fuelConsumption.Jurisdiction')}
                    as={Input}
                    type="text"
                  />

              {errors['cost'] && !isNotValid && (
                <Message type="error" message={errors['cost']} />
              )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              : 
              <FormStyle.default.FieldsWrapper width="100%" flexDirection="column">
                  <Label variant="body1">{window.$environment?.SERVICE_PROVIDER == 'tp' ? i18n.t('fuelConsumption.JurisdictionLocation') : i18n.t('fuelConsumption.Jurisdiction')}</Label>
                  <FormStyle.default.TextWrapper>
                    <Field
                      name="jurisdiction"
                      onChange={(event) =>
                        handleEdit(event, { field: 'jurisdiction' })
                      }
                      placeholder={window.$environment?.SERVICE_PROVIDER == 'tp' ? i18n.t('fuelConsumption.JurisdictionLocation') : i18n.t('fuelConsumption.Jurisdiction')}
                      as={Select}
                      value={values.jurisdiction}
                      suggestions={customProps.stateList}
                    />
                    {errors['jurisdiction'] && !isNotValid && (
                      <Message type="error" message={i18n.t('common.fieldRequiredMessage')} />
                    )}
                  </FormStyle.default.TextWrapper>
                </FormStyle.default.FieldsWrapper>}

                <FormStyle.default.FieldsWrapper width="100%" flexDirection="column">
                  <Label variant="body1">{i18n.t('refillsHistory.cost')}</Label>
                  <FormStyle.default.TextWrapper>
                    <Field
                      name="cost"
                      handleOnChange={(event) => {
                        if(event >= 0) {
                          handleEdit(event, { field: 'cost' })
                        }
                      }
                      }
                      placeholder={i18n.t('refillsHistory.cost')}
                      as={Input}
                      type="number"
                      min="0"
                    />

                {errors['cost'] && !isNotValid && (
                  <Message type="error" message={errors['cost']} />
                )}
                  </FormStyle.default.TextWrapper>
                </FormStyle.default.FieldsWrapper>
          

              <FormStyle.default.FieldsWrapper width="100%" flexDirection="column">
                  <Label variant="body1">{i18n.t('common.date')}</Label>
                  <FormStyle.default.TextWrapper>
                  <Field
                      id="fuelReportdate"
                      name="date"
                      onChange={(event) => {
                        // customProps.setDateValidationError('')
                        handleEdit(event, { field: 'date', type: 'date' });
                      }}
                      as={ReactDatePicker}
                      locale={getDateLocale()}
                      placeholderText={UI_DATE_LOWER_FORMAT}
                      value={values.date}
                      showPopperArrow={false}
                      showTimeSelect={false}
                      maxDate={new Date()}
                      showDisabledMonthNavigation
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      isClearable
                    />
                {errors['date'] && !isNotValid && (
                  <Message type="error" message={errors['date']} />
                )}
                  </FormStyle.default.TextWrapper>
                </FormStyle.default.FieldsWrapper>

              <FormStyle.default.FieldsWrapper width="100%" className="vertical">
              <Label variant="body1">{i18n.t('users.selectFileToUpload')}</Label>
              <FormStyle.default.TextWrapper>
              <Field
                  name="upload"
                  onUpload={(event) => {
                    handleEdit(event, { field: 'upload', type: 'file' });
                  }}
                  as={Upload}
                  allowMultiple={false}
                  totalFiles={1}
                  placeholderText=""
                  value={values.upload}
                  type="file"
                  normal
                  acceptedFileTypes={[
                    '.pdf',
                    '.PDF',
                    'application/pdf',
                    'image/jpeg',
                    'image/png',
                  ]}
                  labelFileTypeNotAllowed={'Only PDF, JPEG, and PNG files are allowed.'}
                  allowFileTypeValidation
                />

                {errors['upload'] && !isNotValid && (
                  <Message type="error" message={errors['upload']} />
                )}
              </FormStyle.default.TextWrapper>
            </FormStyle.default.FieldsWrapper>
          </div>
        </div>

        <FormStyle.default.ButtonsWrapper>
          <Button
            type="submit"
            className="buttonStyle"
            label={i18n.t('common.save')}
            onClick={(e, d) => {
              const validateResponse = validateForm();
              validateResponse.then((data) => {
                submitAction(data);
              });
            }}
            id="docUploadButton"
          />
        </FormStyle.default.ButtonsWrapper>
      </customProps.Styled.UploadWrapper>
    </customProps.Styled.PopupUpload>
  );
};

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  customProps: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};

export default RenderComponent;
