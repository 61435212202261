import React, { useEffect, lazy } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useHistory } from 'react-router-dom';
import useTheme from '@material-ui/core/styles/useTheme';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ParentStyled from './style';
import { loadStyle, getNewObject, loadPageConfig } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'pages/web/RefillsHistory');
const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.refillsHistory[0].name}`));
const Content = lazy(() => import(`@/${config.refillsHistory[1].name}`));

const RefilsHistory = (parentProps) => {
  const { fetchRefilsHistory,  
    vehicleListDropDown,
    driverListDropDown, 
  } = parentProps;

  const history = useHistory();
  const theme = useTheme();
  const match = useParams();
  const i18n = useTranslation();

  const apiId = 'fetchRefilsHistory';
  const commonParentProps = { history, i18n, Styled, theme };

  useEffect(() => {
    fetchRefilsHistory({
      apiId: apiId,
      data: { id: match.id }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const objectArrayFromDiffFields = (list, value, field1, field2, callback) => {
    return list?.length
      ? list.map((item) => {
          if (callback) return callback(item, field1, field2, value);
          else
            return {
              label: field2 ? `${item[field1]} ${item[field2]}` : item[field1],
              value: item[value]
            };
        })
      : [];
  };

  const vehicleList = objectArrayFromDiffFields(
    vehicleListDropDown,
    'vehicleId',
    'name'
  );

  return (
    <>
      <RenderComponent
        {...config.refillsHistory[0]}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        component={Title}
      />
      <RenderComponent
        {...config.refillsHistory[1]}
        parentProps={{...parentProps, vehicleList, driverList: driverListDropDown?.data}}
        commonParentProps={commonParentProps}
        component={Content}
      />
    </>
  );
};

const RenderComponent = ({
  name,
  props,
  commonProps,
  title,
  parentProps,
  commonParentProps,
  component,
  ...rest
}) => {
  const Component = component;
  let newProps = props?.length ? getNewObject(parentProps, props) : {};
  newProps = {
    ...newProps,
    ...(commonProps?.length ? getNewObject(commonParentProps, commonProps) : {})
  };
  return <Component {...rest} {...newProps} />;
};
const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    refillsHistory: state.refillsHistory,

    fuelConsumption: state?.fuelConsumption,
    vehicleListDropDown: state?.vehicles?.vehicleListDropDown,
    driverListDropDown: state?.drivers,
    stateListData: state?.fuelConsumption,
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchRefilsHistory: (data) => dispatch.refillsHistory.fetchData(data),
    viewReport:(data)=>dispatch.viewReport.viewReportDocument(data),
    getStateListData: (data) => dispatch.fuelConsumption.fetchStateListData(data),
    getVehicleListDropDown: (data) => dispatch.vehicles.fetchVehicleListDropDown(data),
    getDrivers: () => dispatch.drivers.getDrivers(),
    addEditFuelData: (data) => dispatch.refillsHistory.saveData(data),
  };
};

export default connect(mapState, mapDispatch)(RefilsHistory);
RefilsHistory.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  fetchRefilsHistory: PropTypes.func.isRequired
};
