import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import Form from './Form';

import ParentStyled from './style';
import { loadStyle } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'components/modules/WorkOrdersManagement');

const Breadcrumb = lazy(() => import(`@/components/Breadcrumb`));

const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <>
      <Breadcrumb filters={filters} />
      <Component {...contentProps} Styled={Styled} />
    </>
  );
};
export default Index;

const Component = (props) => {
  return (
    <Styled.Wrapper>
      <Form {...props} />
    </Styled.Wrapper>
  );
};

Component.propTypes = {
  history: PropTypes.object.isRequired
};
