const initialState = [];
const path = `../../../config/apis/${window.$environment.SERVICE_PROVIDER}`;
export const policies = {
  state: [...initialState],
  reducers: {
    getSuccess(state, payload) {
      return { ...payload };
    },
    getFailed(state) {
      return [...initialState];
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload) {
      try {
        const getApi = await import(
          `@/config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('getPolicyDetailsApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject
          },
          id: payload.apiId
        });
        response && this.getSuccess(response);
      } catch (error) {
        this.getFailed();
      }
    },
    async saveData(payload) {
      try {
        const getApi = await import(
          `@/config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('postPolicyDetailsApi');
        delete payload?.data?.file;
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject,
            data: payload.data
          },
          id: payload.apiId
        });
        payload.callback && payload.callback(payload.fields)
        // window.location.reload();
        // response &&
        //   dispatch.policies.fetchData({ apiId: 'fetchPoliciesAndProcedures' });
      } catch (error) {
        // this.addFailed();
      }
    }
  })
};
