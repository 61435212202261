import React, { useEffect, lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RenderComponent from '@/components/modules/DynamicRenderPage';
import { loadPageConfig } from '@/utils/utils';
import { ROLES } from '../../../config/constants/keys';
import { hasRestrictedRole } from '../../../utils/utils';

const config = loadPageConfig();
const Title = lazy(() =>
  import(`@/${config.potentialViolationsViewMore[0].name}`)
);
const Content = lazy(() =>
  import(`@/${config.potentialViolationsViewMore[1].name}`)
);
const TopActions =
  config.potentialViolationsViewMore[0].children &&
  lazy(() =>
    import(`@/${config.potentialViolationsViewMore[0].children.name}`)
  );

const PotentialViolations = (parentProps) => {
  const {
    fetchPotentialViolations,
    getDocumentToken,
    history,
    i18n,
    theme
  } = parentProps;

  const [pagination, setPagination] = useState(false);
  const commonParentProps = { history, i18n, theme };
  const viewOnly = hasRestrictedRole([ROLES.VIEW_ONLY_FM])

  useEffect(() => {
    fetchPotentialViolations({ apiId: 'potentialViolations' });
    getDocumentToken({ apiId: 'getDocToken' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const columns = [
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: i18n.t('common.description'),
      needSorting: window.$environment.CURRENT_LAYOUT === 'SpireonOld' ? false : true
    },
    {
      id: 'driver',
      numeric: false,
      disablePadding: true,
      label: i18n.t('common.driverName'),
      needSorting: window.$environment.CURRENT_LAYOUT === 'SpireonOld' ? false : true
    },
    {
      id: 'date',
      numeric: false,
      disablePadding: false,
      label: i18n.t('common.date'),
      needSorting: window.$environment.CURRENT_LAYOUT === 'SpireonOld' ? false : true
    },
    {
      id: 'vehicle',
      numeric: false,
      disablePadding: false,
      label: i18n.t('common.vehicle'),
      needSorting: window.$environment.CURRENT_LAYOUT === 'SpireonOld' ? false : true
    },

    {
      id: 'phone',
      numeric: true,
      disablePadding: false,
      label: i18n.t('common.driverPhone'),
      needSorting: window.$environment.CURRENT_LAYOUT === 'SpireonOld' ? false : true
    },

    ...(!viewOnly?[{
      id: 'actions',
      label: 'common.delete'
    }]: [])
  ];
  return (
    <>
      <RenderComponent
        {...config.potentialViolationsViewMore[0]}
        parentProps={{ ...parentProps, columns, pagination, setPagination }}
        commonParentProps={commonParentProps}
        component={Title}
        childComponent={TopActions}
      />
      <RenderComponent
        {...config.potentialViolationsViewMore[1]}
        parentProps={{ ...parentProps, columns, pagination, setPagination, noAction: viewOnly }}
        commonParentProps={commonParentProps}
        component={Content}
      />
    </>
  );
};
const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    list: state.potentialViolations,
    logBook: state.logBook
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchPotentialViolations: (data) =>
      dispatch.potentialViolations.fetchData(data),
    filterSuccess: (data) => dispatch.potentialViolations.filterSuccess(data),
    exportAllData: (data) => dispatch.potentialViolations.exportAllData(data),
    deleteData: (data) => dispatch.potentialViolations.deleteData(data),
    exportCsvData: (data) => dispatch.potentialViolations.exportCsvData(data),
    exportPdfData: (data) => dispatch.potentialViolations.exportPdfData(data),
    getDocumentToken: (data) => dispatch.logBook.documentToken(data)
  };
};

export default connect(mapState, mapDispatch)(PotentialViolations);
PotentialViolations.defaultTypes = {};
PotentialViolations.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  fetchPotentialViolations: PropTypes.func.isRequired,
  exportAllData: PropTypes.func.isRequired,
  filterSuccess: PropTypes.func.isRequired,
  deleteData: PropTypes.func.isRequired,
  exportCsvData: PropTypes.func.isRequired,
  exportPdfData: PropTypes.func.isRequired
};
