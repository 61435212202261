import styled from 'styled-components';
import Parent from './style';

const Container = styled(Parent.Container)`
  .resetHeading {
    color: #575757;
  }
`;
const Styled = { Container };
export default Styled;
