import styled from "styled-components";

const Wrapper = styled.div`
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: start;
        gap: 1rem;
`;

const Items = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
`;
const Title = styled.div`
    display: flex;
    justify-content: space-between;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 1rem;
    .title {
        font-size: 16px;
        display: flex;
        gap: 10px;
    }
`;

const Styled = { Items, Wrapper, Title }
export default Styled;
