import * as Base from './base';
import {
  addOrEditNewForm as baseAddOrEditNewForm,
  addOrEditNewFormTrailer as baseAddOrEditNewFormTrailer,
  addState as baseAddState,
  addUser as baseAddUser,
  addVehicle as baseAddVehicle,
  addWorkOrders as baseAddWorkOrders,
  advancedDvirEnabled as baseAdvancedDvirEnabled,
  archiveLogsList as baseArchiveLogsList,
  companyDataEdit as baseCompanyDataEdit,
  companyList as baseCompanyList,
  csaViolation as baseCsaViolation,
  customDvirEnabled as baseCustomDvirEnabled,
  downloadCenter as baseDownloadCenter,
  driverStatusHistory as baseDriverStatusHistory,
  editLog as baseEditLog,
  editLog1 as baseEditLog1,
  editState as baseEditState,
  editUser as baseEditUser,
  editVehicle as baseEditVehicle,
  editWorkOrder as baseEditWorkOrders,
  iftaList as baseIFTAList,
  inspections as baseInspections,
  logsEdit as baseLogsEdit,
  logsHistory as baseLogsHistory,
  logsView as baseLogsView,
  manageScheduling as baseManageScheduling,
  outOfService as baseOutOfService,
  policyEdit as basePolicyEdit,
  potentialViolationsViewMore as basePotentialViolationsViewMore,
  privacyPolicyScreen as basePrivacyPolicyScreen,
  profile as baseProfile,
  reportMileage as baseReportMileage,
  reportsEnabled as baseReportsEnabled,
  resetPassword as baseResetPassword,
  subscriptionMessage as baseSubscriptionMessage,
  unassignedInspections as baseUnassignedInspections,
  userList as baseUserList,
  vehicleList as baseVehicleList,
  vehicleNotInUse as baseVehicleNotInUse,
  vehicleStatusViewMore as baseVehicleStatusViewMore,
  workOrders as baseWorkOrders,
  usDotManagementenabled as usDotManagement
} from './base';

export const downloadCenter = baseDownloadCenter;
export const manageScheduling = baseManageScheduling;
export const vehicleDTCView = Base.vehicleDTCView;

export const privacyPolicyScreen = basePrivacyPolicyScreen;
export const archiveLogsList = baseArchiveLogsList;

export const dashboard = [
  {
    name: 'pages/web/Dashboard/OverrideComponent',
    props: ['dashboard', 'getDriverStatus', 'getVehicleStatus'],
    commonProps: ['Styled', 'i18n', 'history'],
    alternateComponent: true
  }
];

export const login = [
  {
    name: 'pages/web/Login/Component',
    props: ['apiStatus', 'login', 'fetchLogin'],
    commonProps: ['Styled', 'i18n', 'history'],
    hasRegistration: true,
    innerComponent: 'pages/web/Login/OverrideRenderComponent'
  }
];

export const userList = baseUserList;
export const editUser = baseEditUser;
export const addUser = baseAddUser;
export const vehicleStatusViewMore = baseVehicleStatusViewMore;
export const driverStatusViewMore = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'dashboard.tabs.driverStatus.heading'
  },
  {
    name: 'pages/web/DriverStatusViewMore/OverrideComponent',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'driverStatus',
      'driverStatusList',
      'filterSuccess',
      'driverStatusFilteredList',
      'getDriverStatus',
      'refreshDriverStatusMap',
      'changeRequest',
      'request',
      'totalElements',
      'pagination',
      'setPagination'
    ],
    commonProps: ['Styled', 'i18n', 'history', 'theme'],
    title: 'component',
    topAction: true,
    mapRequired: true
  }
];

export const vehicleList = baseVehicleList;
export const addVehicle = [
  { ...baseAddVehicle[0] },
  {
    ...baseAddVehicle[1],
    props: [...baseAddVehicle[1].props, 'customDvir', 'fetchCustomDvir']
  }
];

export const editVehicle = [
  { ...baseEditVehicle[0] },
  {
    ...baseEditVehicle[1],
    props: [...baseEditVehicle[1].props, 'customDvir', 'fetchCustomDvir']
  }
];

export const potentialViolationsViewMore = basePotentialViolationsViewMore;

export const companyDataEdit = baseCompanyDataEdit;

export const privacyPolicy = [
  {
    name: 'pages/web/PrivacyPolicy/clutchIndex',
    props: [],
    commonProps: [],
    title: 'component'
  }
];

export const recovery = [
  {
    name: 'pages/web/ForgotPassword/Component',
    props: ['history', 'requestForgotPassword', 'clearApiStatus', 'apiStatus'],
    commonProps: ['Styled', 'i18n'],
    title: 'component',
    innerComponent: 'pages/web/ForgotPassword/OverrideComponent'
  }
];
export const MFAPage = [
  {
    name: 'pages/web/MFA/Component',
    props: ['history', 'authenticteOtp', 'clearApiStatus', 'apiStatus'],
    commonProps: ['Styled', 'i18n'],
    title: 'component',
    innerComponent: 'pages/web/MFA/OverrideComponent'
  }
]

export const registration = [
  {
    name: 'pages/web/Registration/Clutch/Component',
    props: [
      'apiStatus',
      'truckInfo',
      'getTruckTypes',
      'fetchRegistration',
      'registration',
      'getTruckMake',
      'getTruckModel',
      'make',
      'addCheckout',
      'getCheckout',
      'addSigneeName',
      'getStates',
      'companyInfo',
      'addCompanyInfo',
      'getCompanyInfo',
      'addFleetManagerInfo',
      'fleetmanagerInfo',
      'getFleetManagerInfo',
      'addTruckInfo',
      'getTruckInfo',
      'pageTitle',
      'getAllConnectionType',
      'clearValues',
      'clearCompanyInfoValues',
      'clearFleetInfoValues',
      'clearTruckInfoValues'
    ],
    commonProps: ['history', 'i18n', 'Styled', 'theme'],
    title: 'component'
  }
];

export const driverStatusHistory = baseDriverStatusHistory;
export const logsView = baseLogsView;
export const logsEdit = baseLogsEdit;
export const logBook = [
  {
    name: 'pages/web/LogBook/OverrideIndex',
    props: [
  'apiStatus',
  'logBook',
  'callback',
  'handleLogBookStatus',
  'clearApiStatus',
  'breadCrumbForMap',
        'unSignedDates',
        'getLogbookEvents',
  'updateShowEvents',
  'fetchLogBookView',
  'dailyLogDownloadData',
  'regeneratePdf'
],
    title: 'component',

    commonProps: ['Styled', 'i18n', 'history']
  }
];
export const logsHistory = baseLogsHistory;
export const logsList = [
  {
    name: 'components/modules/LogsManagement/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'list',
      'callback',
      'filters',
      'filterSuccess',
      'handleLogBookStatus',
      'clearApiStatus',
      'setPagination',
      'pagination'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    apiIds: {
      export: 'exportAllPotentialViolationsData',
      list: 'potentialViolations',
      delete: 'deletePotentialViolations'
    },
    fileName: 'Violations.pdf',
    needSearch: false,
    needFilter: true,
    needExportAll: true,
    header: 'logs.manageLogsHeader',
    verticalTab: true,
    columns: [
      {
        id: 'driver_name',
        numeric: false,
        disablePadding: true,
        label: 'Driver Name',
        needSorting: true
      },
      {
        id: 'vehicle_name',
        numeric: false,
        disablePadding: false,
        label: 'common.vehicle',
        needSorting: true
      },
      {
        id: 'date',
        numeric: false,
        disablePadding: false,
        label: 'common.date',
        needSorting: true
      },
      {
        id: 'hours',
        numeric: true,
        disablePadding: false,
        label: 'common.hoursWorked',
        needSorting: true
      },
      {
        id: 'distance',
        numeric: true,
        disablePadding: false,
        label: 'common.distance',
        needSorting: true
      },
      {
        id: 'cycleRule',
        numeric: false,
        disablePadding: false,
        label: 'common.cycleRule',
        needSorting: true
      },

      {
        id: 'actions',
        label: 'Actions'
      }
    ]
  },
  {
    name: 'components/modules/LogsManagement/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'list',
      'callback',
      'filters',
      'filterSuccess',
      'handleLogBookStatus',
      'clearApiStatus',
      'setPagination',
      'pagination'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    apiIds: {
      export: 'exportAllPotentialViolationsData',
      list: 'potentialViolations',
      delete: 'deletePotentialViolations'
    },
    fileName: 'Violations.pdf',
    needSearch: false,
    needFilter: true,
    needExportAll: true,
    header: 'logs.manageLogsHeader',
    verticalTab: false,
    columns: [
      {
        id: 'driver_name',
        numeric: false,
        disablePadding: true,
        label: 'common.driver',
        needSorting: true
      },
      {
        id: 'vehicle_name',
        numeric: false,
        disablePadding: false,
        label: 'common.vehicle',
        needSorting: true
      },
      {
        id: 'date',
        numeric: false,
        disablePadding: false,
        label: 'common.date',
        needSorting: true
      },
      {
        id: 'hours',
        numeric: true,
        disablePadding: false,
        label: 'common.hoursWorked',
        needSorting: true
      },
      {
        id: 'distance',
        numeric: true,
        disablePadding: false,
        label: 'common.distance',
        needSorting: true
      },
      {
        id: 'cycleRule',
        numeric: false,
        disablePadding: false,
        label: 'common.cycleRule',
        needSorting: true
      }
    ]
  }
];

export const logsUdList = [
  {
    name: 'pages/web/UndefinedDriverList/OverrideComponent',
    props: [
      'apiStatus',
      'udLogList',
      'unAssignedEntries',
      'fetchUdLogList',
      'fetchUnAssignedEntries',
      'clearUdAssignEntryListById',
      'setUdAssignEntryList',
      'saveUnAssignedEntries',
      'callback',
      'udFilterSuccess',
      'filters',
      'clearAll'
    ],
    commonProps: ['history', 'i18n', 'Styled'],
    title: 'component',
    verticalTab: true
  }
];

export const reportsEnabled = baseReportsEnabled;
export const resetPassword = baseResetPassword;
export const profile = baseProfile;
export const unhandled = [
  {
    name: 'pages/web/Unhandled/OverrideComponent',
    props: [],
    commonProps: ['Styled', 'i18n', 'history']
  }
];
export const subscriptionMessage = baseSubscriptionMessage;
export const editLog = baseEditLog;
export const editLog1 = baseEditLog1;
export const policyEdit = basePolicyEdit;
export const advancedDvirEnabled = baseAdvancedDvirEnabled;
export const customDvirEnabled = baseCustomDvirEnabled;
export const addOrEditNewForm = baseAddOrEditNewForm;
export const addOrEditNewFormTrailer = baseAddOrEditNewFormTrailer;
export const reportMileage = baseReportMileage;
export const iftaList = baseIFTAList;
export const addState = baseAddState;
export const editState = baseEditState;
export const departmentsList = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'common.departments'
  },
  {
    name: 'pages/web/Department/Component',
    props: [
      'apiStatus',
      'callback',
      'fetchTerminals',
      'data',
      'saveRegion',
      'updateRegion',
      'deleteRegion',
      'saveTerminal',
      'deleteTerminal',
      'updateTerminal',
      'saveDepartment',
      'updateDepartment',
      'deleteDepartment',
      'saveSubDepartment',
      'updateSubDepartment',
      'deleteSubDepartment',
      'setAddEditModalObject',
      'clickedAddEditModal',
      'closeAddEditModal',

      'isTermCrudRestricted',
      'getTmezonePopup',
      'isCrudRestricted',
      'isDeptCrudRestricted',
      'isLocCrudRestricted'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    topAction: true,
    isButtonStyle: false,
    title: 'component',
    isShowDotNumber: false
  }
];
export const usDotManagementenabled = usDotManagement;

export const pendingDriverInspections = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'inspections.listHeading'
  },
  {
    name: 'pages/web/PendingDriverInspections/Component',
    props: [
      'apiStatus',
      'callback',
      'fetchInspections',
      'bulkDownloadReport',
      'inspections',
      'list',
      'changeRequest',
      'isAdvancedDviRequired',
      'isCustomDviRequired',
      'logBook',
      'clearApiStatus',
      'getDocumentToken',
      'filters',
      'viewReport',
      'request',
      'setPagination',
      'pagination'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    type: 'inspections',
    topAction: true
  }
];

export const completedInspections = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'inspections.listHeading'
  },
  {
    name: 'pages/web/CompletedInspections/Component',
    props: [
      'apiStatus',
      'callback',
      'fetchInspections',
      'bulkDownloadReport',
      'inspections',
      'list',
      'changeRequest',
      'isAdvancedDviRequired',
      'isCustomDviRequired',
      'logBook',
      'clearApiStatus',
      'getDocumentToken',
      'filters',
      'viewReport',
      'request',
      'setPagination',
      'pagination'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    type: 'inspections',
    topAction: true
  }
];

export const pendingMechanicInspections = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'inspections.listHeading'
  },
  {
    name: 'pages/web/PendingMechanicInspections/Component',
    props: [
      'apiStatus',
      'callback',
      'fetchInspections',
      'bulkDownloadReport',
      'inspections',
      'list',
      'changeRequest',
      'isAdvancedDviRequired',
      'isCustomDviRequired',
      'logBook',
      'clearApiStatus',
      'getDocumentToken',
      'filters',
      'viewReport',
      'request',
      'setPagination',
      'pagination'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    type: 'inspections',
    topAction: true
  }
];

  export const noDefectInspections = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'inspections.listHeading'
  },
  {
    name: 'pages/web/NoDefectInspections/Component',
    props: [
      'apiStatus',
      'callback',
      'fetchInspections',
      'bulkDownloadReport',
      'inspections',
      'list',
      'changeRequest',
      'isAdvancedDviRequired',
      'isCustomDviRequired',
      'logBook',
      'clearApiStatus',
      'getDocumentToken',
      'filters',
      'viewReport',
      'request',
      'setPagination',
      'pagination'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    type: 'inspections',
    topAction: true
  }
];

export const inspections = baseInspections;
export const csaViolation = baseCsaViolation;
export const editWorkOrder = baseEditWorkOrders;
export const workOrders = baseWorkOrders;
export const addWorkOrders = baseAddWorkOrders;
export const vehicleNotInUse = baseVehicleNotInUse;
export const outOfService = baseOutOfService;
export const unassignedInspections = baseUnassignedInspections;
export const companyList = baseCompanyList;
