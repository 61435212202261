import styled from 'styled-components';
import Parent from './style';
const Wrapper = styled(Parent.Wrapper)`
    ${({ theme, permissionDenied }) => `
    min-height: ${ permissionDenied ? 'calc(100vh - 77px)': 'auto'};
    `}
`;

const Styled = { Wrapper};

export default Styled;
