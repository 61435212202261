import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Parent from './style';
const Wrapper = styled(Parent.Wrapper)`
padding: 2%;
padding-bottom: 4%;
.spireonNewonly {
  display: none;
}
.section-wrapper{
  background-color: #fff;
  padding: 2% 3%;
  margin-bottom: 30px;
  border-radius: 5px;
  .subheaderSection{
    padding-left: 0 !important;
    font-weight: 700;
  }
}
`;
const LeftWrapper = styled(Parent.LeftWrapper)`
 
`;
const RightWrapper = styled(Parent.RightWrapper)`
`;
const documentWrapper = styled(Parent.documentWrapper)`
${({ theme }) => `
  ul {
    li {
      .MuiGrid-container .MuiAvatar-rounded {
        width: 22px;
        height: 22px;
      }
    }
  }
  `}
`;
const submitUser = styled(Parent.submitUser)`
`;
const Styled = {
  Wrapper,
  RightWrapper,
  LeftWrapper,
  documentWrapper,
  submitUser
};
export default Styled;
