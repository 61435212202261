import React, { lazy, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';
import AuthContext from '@/services/auth';

import ParentStyled from '../Login/style';
import { loadStyle, getNewObject, loadPageConfig } from '@/utils/utils';
import { pageUrls } from '@/config/constants/keys';
let Styled = loadStyle(ParentStyled, 'pages/web/Login');
const config = loadPageConfig();
const Content = lazy(() => import(`@/${config.recovery[0].name}`));

const ForgotPassword = (parentProps) => {
  const i18n = useTranslation();
  const commonParentProps = { i18n, Styled };
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (auth?.auth) {
      parentProps.history.push(pageUrls.dashboard);
    }
  }, []);
  return (
    <RenderComponent
      {...config.recovery[0]}
      parentProps={parentProps}
      commonParentProps={commonParentProps}
      component={Content}
    />
  );
};

const RenderComponent = ({
  name,
  props,
  commonProps,
  title,
  parentProps,
  commonParentProps,
  component,
  ...rest
}) => {
  const Component = component;
  let newProps = props?.length ? getNewObject(parentProps, props) : {};
  newProps = {
    ...newProps,
    ...(commonProps?.length ? getNewObject(commonParentProps, commonProps) : {})
  };
  return <Component {...rest} {...newProps} />;
};
const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests
  };
};
const mapDispatch = (dispatch) => {
  return {
    requestForgotPassword: (data) =>
      dispatch.forgotPassword.requestForgotPassword(data),
    clearApiStatus: () => dispatch.asyncRequests.clearApiStatus()
  };
};

export default connect(mapState, mapDispatch)(ForgotPassword);

ForgotPassword.propTypes = {
  requestForgotPassword: PropTypes.func,
  apiStatus: PropTypes.object.isRequired,
  clearApiStatus: PropTypes.func.isRequired
};
