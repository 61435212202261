import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
  ${({ theme }) => `
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  @media (max-width: ${theme.breakpoints.values.md - 1}px) {
    justify-content: flex-start;
  }
  .filterForm {
    flex: 0 0 92%;
    margin-right: 0 !important;
    @media (min-width: ${theme.breakpoints.values.md}px) and (max-width: ${
    theme.breakpoints.values.mlg
  }px) {
      flex: 0 0 90%;
    }
    @media (max-width: ${theme.breakpoints.values.md - 1}px) {
      flex: 0 0 100% !important;
    }
    .MuiTypography-body1 {
      font-size: 20px;
    }
    .MuiButtonBase-root {
      margin-top: 30px;
    }
    div[type='date'],
    div[type='text'] {
      width: 19%;
      margin-right: 1%;
      flex-direction: column;
      @media (min-width: ${theme.breakpoints.values.md}px) and (max-width: ${
    theme.breakpoints.values.mlg
  }px) {
        width: 18%;
      }
      @media (min-width: ${theme.breakpoints.values.smd}px) and (max-width: ${
    theme.breakpoints.values.md - 1
  }px) {
        width: 32%;
      }
      @media (max-width: ${theme.breakpoints.values.smd}px) {
        width: 100%;
      }
    }
    div[type='submit'],
    div[type='reset'] {
      margin-right: 0;
      @media (max-width: ${theme.breakpoints.values.md - 1}px) {
        padding-top: 8%;
      }
    }
  }
  .exportHistory {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .export .iconContainer {
    margin-right: -8px;
  }
  .exportContainer {
    margin-right: 0 !important;
    align-items: flex-end;
    @media (max-width: ${theme.breakpoints.values.md - 1}px) {
      padding: 5% 0 5% 3%;
    }
  }
  .tooltiptext {
    width: 80px;
    font-size: 15px;
    top: 83px !important;
  }
  .MuiAvatar-root {
    width: 30px;
    height: auto;
  }
  .MuiBox-root {
    margin-right: 15px;
  }
  `}
`;
const ExportOptionWrapper = styled.div`
  ${({ theme }) => `
  position: absolute;
  top: 0;
  left: 0;
  width: 168px;
  padding: 8px 8px 4px 8px;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.4);
  background-color: white;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  .MuiBox-root {
    background: ${theme.colors.LINK_COLOR};
    margin-bottom: 3px;
    border: 1px solid ${theme.colors.GREY};
    border-radius: 2px;
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    a {
      padding: 0.5em 1em;
      font-family: Arial, 'Geneva CY', sans-serif;
      font-size: 10.5px !important;
    }
  }
  .MuiBox-root:hover {
    background: colors.WHITE;
  }
  .MuiBox-root a {
    cursor: pointer;
    font-size: 0.88em;
    color: black;
    white-space: nowrap;
    overflow: hidden;
    user-select: none;
    text-decoration: none;
    outline: none;
    width: 100%;
    padding-left: 5px;
    padding-top: 5px;
  }
  `}
`;

const SearchWrapper = styled.div`
  ${({ theme }) => `
  flex: 0 0 40%;
  @media (max-width: ${theme.breakpoints.values.mlg}px) {
    flex: 0 0 38%;
  }
  #clearSearch {
    display: none;
  }
  `}
`;
const TableWrapper = styled.div`
  padding-top: 4.5%;
  .spireonOnly,
  .quickFilter {
    display: none;
  }
`;

const Styled = { Wrapper, ExportOptionWrapper, TableWrapper, SearchWrapper };

export default Styled;
