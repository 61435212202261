import styled from 'styled-components';

const RadioWrapper = styled.div`
  ${({ theme, color, textColor }) => `
  .MuiIconButton-label {
    border: 2px solid ${theme.colors.INPUT_BORDER};
    background: ${theme.colors.RADIO_BACKGROUND};
    width: 24px;
    height: 24px;
    svg {
      display: none;
    }
    input:checked + div {
      display: block;
      background-color: ${theme.colors.LINK_COLOR};
      position: absolute;
      left: 7px;
      position: absolute;
      width: 10px;
      height: 10px;
      top: 7px;
    }
  }
  .MuiFormGroup-root {
    flex-direction: row;
  }
  .MuiFormControlLabel-root {
    margin-left: 0;
    margin-right: 0;
    .MuiButtonBase-root {
      width: auto;
      padding: 0;
    }
  }
  .MuiFormControlLabel-root {
    .MuiFormControlLabel-label {
      color: ${theme.colors.PRIMARY_CONTRAST};
      padding-left: 10px;
      padding-top: 12px;
      padding-right: 10px;
      min-width: 40px;
      font-size: 18px;
    }
  }
  .MuiFormControlLabel-root.Mui-disabled {
    .MuiTypography-root.MuiFormControlLabel-label {
      padding-right: 14px;
      color: ${theme.colors.DIABLED_COLOR};
    }
  }
  .MuiSvgIcon-root {
    border: 2px solid
      ${
        theme.palette.primary ? theme.palette.primary.light : theme.colors.GREY
      };
    background: ${theme.colors.RADIO_BACKGROUND};
  }

  .MuiRadio-colorSecondary.Mui-checked {
    color: ${
      color
        ? color
        : theme.palette.secondary.main
        ? theme.palette.secondary.main
        : theme.colors.PRIMARY_CONTRAST
    };
    border-radius: 0%;
  }
  span.MuiFormControlLabel-label {
    color: ${
      textColor
        ? textColor
        : theme.palette.secondary.contrastText
        ? theme.palette.secondary.contrastText
        : theme.colors.PRIMARY_CONTRAST
    };
  }
  `}
`;

export default RadioWrapper;