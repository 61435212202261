import React, { useEffect, useState, lazy } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import ReactDatePicker from 'react-datepicker';
import  {getFilterLocaleValues} from '@/utils/utils'
import { UI_DATE_LOWER_FORMAT } from '@/config/constants/static';
import { hasValue, getDateLocale } from '@/utils/utils';

import Styled from '../style';

const layout = window.$environment?.CURRENT_LAYOUT;
const Input = lazy(() => import(`@/components/UI/Input/${layout}`));
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Select = lazy(() => import(`@/components/UI/Select/${layout}`));
const Department = lazy(() => import(`@/components/modules/Department`));
const UploadList = lazy(() =>
  import(`@/components/modules/DocumentUploadList`)
);
const SubHeader = lazy(() => import(`@/components/SubHeader/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const RenderComponent = ({
  values,
  handleEdit,
  errors,
  isNotValid,
  validateForm,
  customProps,
  submitAction,
  cancelAction
}) => {
  const { i18n } = customProps;

  useEffect(() => {
    if (values?.documentsList) {
      let otherDocs = [];
      let trailerDocs = [];
      for (let i = 0; i < values.documentsList.length; i++) {
        const item = values.documentsList[i];
        if (item.doctype === 'TRAILER') {
          trailerDocs.push(item);
        } else {
          otherDocs.push(item);
        }
      }
      setUploadList({
        otherDocs,
        trailerDocs,
        currentDocs: [...trailerDocs, ...otherDocs]
      });
    }
  }, [values]);

  const [uploadList, setUploadList] = useState({ currentDocs: [] });

  const showError = (field, validationErrors, isNotValid, apiError) => {
    if (validationErrors[field] && !isNotValid) {
      return <Message type="error" message={errors[field]} />;
    }
    if (apiError?.[field]) {
      return <Message type="error" message={apiError[field]} />;
    }
  };
  return (
    <>
      <Styled.Wrapper>
        <Styled.LeftWrapper>
          <SubHeader
            text={i18n.t('common.trailerInformation')}
            className="subheaderSection trailerHeading"
            variant="h1"
          />
          <FormStyle.default.FieldsWrapper width="100%">
            <Label variant="body1">{i18n.t('trailers.trailerType')}</Label>
            <FormStyle.default.TextWrapper>
              <Field
                name="type"
                onChange={(event) => handleEdit(event, { field: 'type' })}
                as={Select}
                value={getFilterLocaleValues([values['type']], i18n)}
                suggestions={getFilterLocaleValues(customProps.trailerTypes, i18n)}
              />
              {showError(
                'type',
                errors,
                isNotValid,
                customProps.hasApiStatus?.errorMessage
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper width="100%">
            <Label variant="body1">{i18n.t('vehicles.unitId')}</Label>
            <FormStyle.default.TextWrapper>
              <Field
                name="name"
                handleOnChange={(event) => handleEdit(event, { field: 'name' })}
                as={Input}
                placeholderText={i18n.t('common.unitId')}
                value={values['name']}
                type="text"
              />
              {showError(
                'name',
                errors,
                isNotValid,
                customProps.hasApiStatus?.errorMessage
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper width="100%">
            <Label variant="body1">{i18n.t('vehicles.vin')}</Label>
            <FormStyle.default.TextWrapper>
              <Field
                name="vin"
                handleOnChange={(event) => handleEdit(event, { field: 'vin' })}
                onBlur={(event) =>
                  customProps.isValidVin({
                    apiId: 'isValidVIN',
                    data: {
                      vin: event,
                      idPub: values?.idPub,
                      isEdit: values?.isEdit,
                      companyId: values?.companyId
                    }
                  })
                }
                as={Input}
                placeholderText={i18n.t('common.vin')}
                value={values['vin']}
                type="text"
              />
              {showError(
                'vin',
                errors,
                isNotValid,
                customProps.hasApiStatus?.errorMessage ||
                  hasValue(customProps.apiStatus.status, 'api', 'isValidVIN')
                    ?.errorMessage
              )}
              {showError(
                'vinError',
                errors,
                isNotValid,
                customProps.hasApiStatus?.errorMessage
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper width="100%">
            <Label variant="body1">{i18n.t('vehicles.year')}</Label>
            <FormStyle.default.TextWrapper className="yearSelect">
              <Field
                name="year"
                onChange={(event) => handleEdit(event, { field: 'year' })}
                as={Select}
                value={values['year']}
                suggestions={customProps.years}
              />
              {showError(
                'year',
                errors,
                isNotValid,
                customProps.hasApiStatus?.errorMessage
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper width="100%">
            <Label variant="body1">{i18n.t('common.make')}</Label>
            <FormStyle.default.TextWrapper>
              <Field
                name="make"
                handleOnChange={(event) => handleEdit(event, { field: 'make' })}
                as={Input}
                placeholderText={i18n.t('common.make')}
                value={values['make']}
                type="text"
              />
              {showError(
                'make',
                errors,
                isNotValid,
                customProps.hasApiStatus?.errorMessage
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper width="100%">
            <Label variant="body1">{i18n.t('vehicles.model')}</Label>
            <FormStyle.default.TextWrapper>
              <Field
                name="model"
                handleOnChange={(event) =>
                  handleEdit(event, { field: 'model' })
                }
                as={Input}
                placeholderText={i18n.t('common.model')}
                value={values['model']}
                type="text"
              />
              {showError(
                'model',
                errors,
                isNotValid,
                customProps.hasApiStatus?.errorMessage
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper width="100%">
            <Label variant="body1">{i18n.t('common.plateNumber')}</Label>
            <FormStyle.default.TextWrapper>
              <Field
                name="plateNumber"
                handleOnChange={(event) =>
                  handleEdit(event, { field: 'plateNumber' })
                }
                as={Input}
                placeholderText={i18n.t('common.plateNumber')}
                value={values['plateNumber']}
                type="text"
              />
              {showError(
                'plateNumber',
                errors,
                isNotValid,
                customProps.hasApiStatus?.errorMessage
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper width="100%">
            <Label variant="body1">{i18n.t('common.gvw')}</Label>
            <FormStyle.default.TextWrapper>
              <Field
                name="gvw"
                handleOnChange={(event) => handleEdit(event, { field: 'gvw' })}
                as={Input}
                placeholderText={i18n.t('common.gvw')}
                value={values['gvw']}
                type="text"
              />
              {showError(
                'gvw',
                errors,
                isNotValid,
                customProps.hasApiStatus?.errorMessage
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper width="100%">
            <Label variant="body1">{i18n.t('common.assignedVehicle')}</Label>
            <FormStyle.default.TextWrapper>
              <Field
                name="vehicle"
                onChange={(event) =>
                  handleEdit(event, { field: 'vehicleIdPub' })
                }
                as={Select}
                placeholder={i18n.t('common.pleaseSelect')}
                value={values['vehicleIdPub']}
                suggestions={customProps.vehicleList}
                isClearable
              />
              {showError(
                'vehicleIdPub',
                errors,
                isNotValid,
                customProps.hasApiStatus?.errorMessage
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper width="100%">
            <Label variant="body1">
              {i18n.t('common.nextMaintenanceDate')}
            </Label>
            <FormStyle.default.TextWrapper className="yearSelect">
              <Field
                name="nextMaintenanceDate"
                onChange={(event) =>
                  handleEdit(event, {
                    field: 'next_maintenance_date',
                    type: 'date'
                  })
                }
                as={ReactDatePicker}
                locale={getDateLocale()}
                placeholderText={UI_DATE_LOWER_FORMAT}
                value={values['next_maintenance_date']}
                type="date"
                showPopperArrow={false}
                showTimeSelect={false}
                minDate={new Date()}
                showDisabledMonthNavigation
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                isClearable={true}
              />
              {showError(
                'next_maintenance_date',
                errors,
                isNotValid,
                customProps.hasApiStatus?.errorMessage
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper width="100%">
            <Label variant="body1">{i18n.t('common.notes')}</Label>
            <FormStyle.default.TextareaWrapper>
              <Field
                name="notes"
                handleOnChange={(event) =>
                  handleEdit(event, { field: 'notes' })
                }
                as={Input}
                multiLine
                value={values['notes']}
                type="text"
              />

              {showError(
                'notes',
                errors,
                isNotValid,
                customProps.hasApiStatus?.errorMessage
              )}
            </FormStyle.default.TextareaWrapper>
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper width="100%">
            <Label variant="body1">{i18n.t('common.deviceSn')}</Label>
            <FormStyle.default.TextWrapper>
              <Field
                name="deviceSn"
                handleOnChange={(event) => handleEdit(event, { field: 'esn' })}
                as={Input}
                placeholderText={i18n.t('common.deviceSn')}
                value={values['esn']}
                type="text"
              />
              {showError(
                'esn',
                errors,
                isNotValid,
                customProps.hasApiStatus?.errorMessage
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper width="100%">
            <Label variant="body1">{i18n.t('common.mechanic')}</Label>
            <FormStyle.default.TextWrapper>
              <Field
                name="mechanic"
                onChange={(event) => handleEdit(event, { field: 'mechanicId' })}
                as={Select}
                placeholder={i18n.t('common.pleaseSelect')}
                value={values['mechanicId']}
                suggestions={customProps.mechanicList}
                isClearable
              />
              {showError(
                'mechanicId',
                errors,
                isNotValid,
                customProps.hasApiStatus?.errorMessage
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <Department
            values={values}
            handleEdit={handleEdit}
            i18n={i18n}
            fetchTerminals={customProps.fetchTerminals}
            fetchDepartments={customProps.fetchDepartments}
            fetchSubDepartments={customProps.fetchSubDepartments}
            regions={customProps.regionList}
            terminals={customProps.terminalList}
            departments={customProps.departmentList}
            subDepartments={customProps.subDepartmentList}
          />
        </Styled.LeftWrapper>
        <Styled.RightWrapper>
          <SubHeader
            text={i18n.t('common.documents')}
            className="subheaderSection documentsHeader"
            variant="h1"
          />
          <Styled.documentWrapper>
            <UploadList
              trailerIdPub={values['idPub']}
              isEdit={values['isEdit']}
              list={uploadList.currentDocs}
              saveDocument={customProps.saveDocument}
              deleteDocument={customProps.deleteDocument}
              apiStatus={customProps.apiStatus}
              callback={customProps.callback}
            />
          </Styled.documentWrapper>
        </Styled.RightWrapper>
      </Styled.Wrapper>
      {typeof customProps.hasApiStatus?.errorMessage === 'string' && (
        <Message
          type="error"
          asTags
          message={i18n.t(customProps.hasApiStatus.errorMessage)}
        />
      )}
      <Styled.submitUser>
        <FormStyle.default.ButtonsWrapper>
          <Button
            className="buttonStyle"
            type="reset"
            label={i18n.t('common.cancel')}
            onClick={(e) => {
              cancelAction();
            }}
          />
          <Button
            type="submit"
            className="buttonStyle"
            label={i18n.t('common.save')}
            onClick={(e, d) => {
              const validateResponse = validateForm();
              validateResponse.then((data) => {
                submitAction(data);
              });
            }}
          />
        </FormStyle.default.ButtonsWrapper>
      </Styled.submitUser>
    </>
  );
};

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  customProps: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};

export default RenderComponent;
