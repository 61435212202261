import React, { lazy, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import FleetInfo from './FleetInfo';
import OperatingTerritory from './OpertaingTerritory';
import QuickStats from './QuickStats';
import moment from 'moment';
import { isInsuracePortalUser } from '@/utils/utils';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import Filters from './RadiusFilters';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const SvgIcon = lazy(() => import(`../../../components/SvgIcon/${layout}`));
const SubHeader = lazy(() => import(`@/components/SubHeader/${layout}`));

const fleetIconMapping = {
  'Tractor Day Cab': 'tractorDayCab',
  'Tractor Sleeper': 'tractorSleeper',
  Bobtail: 'bobTail',
  'Straight Truck': 'straightTruck',
  'Box Truck': 'boxTruck',
  Pickup: 'pickup',
  'Private Passenger': 'privatePassenger',
  Vacuum: 'vacuumTrailer',
  Van: 'van',
  'Other Trailers': 'otherTrailer',
  Flatbed: 'flatBed',
  'Dry Van': 'dryVan',
  Reefer: 'reeferTrailer',
  Tanker: 'tankerTrailer',
  Dump: 'dump',
  Pneumatic: 'pneumaticTrailer',
  'Other Vehicles': 'otherVehicle',
  'Other': 'otherTrailer',

};

const scoreCardItemDescription = {
  fleet: 'Fleet',
  address: 'Address',
  dotNumber: 'DOT Number',
  xDate: 'Insurance expiry date',
  reportDate: 'Report date',
  operatingTerritory: {
    overageStatus: 'Percentage of radius violations in each radius boundaries',
    isftaStatus: 'Mileage of vehicles per state'
  },
  quickStatus: {
    fleetManagementScore: 'A look at your company',
    vehicleInspectionScore: 'Quick look at your dvir history',
    consistentEquipment: 'How long you have used the same vehicles',
    vehicleComposition: 'Type of vehicles in your fleet',
    driverTurnOver: 'This is calculated by dividing employment length by the total number of drivers',
    driverTenure: 'This is calculated by the total number of drivers hired in the current year (Decided based on the hire date) divided by the total number of drivers'
  },
  fleetManagement: 'Sum of active vehicles/trailers and inactive vehicle/tailer for the insurance period',
  radiusCompliance: 'List of radius compliance violations'
}

const Component = ({ Styled, scoreCard, i18n, radiusData, companyId, getVehicles, fetchRadiusDetails, fetchQuickStatsDetails }) => {

  const [values, setInitialValues] = useState({
    severityId: null
  });
  const [pagination, setPagination] = useState(false);


  const insuracePortalUserRole = isInsuracePortalUser()
  const columns = [
    {
      id: 'driverName',
      disablePadding: false,
      label: i18n.t('scoreCard.radiusTable.driverName'),
      needSorting: true
    },
    {
      id: 'vehicleName',
      disablePadding: false,
      label: i18n.t('scoreCard.radiusTable.vehicle'),
      needSorting: true
    },
    {
      id: 'radiusCompliance',
      disablePadding: false,
      label: i18n.t('scoreCard.radiusTable.radius'),
      needSorting: true
    },
    {
      id: 'radiusExperienced',
      disablePadding: false,
      label: i18n.t('scoreCard.radiusTable.radiusExperience'),
      needSorting: true
    },
    {
      id: 'overage',
      disablePadding: false,
      label: i18n.t('scoreCard.radiusTable.overage'),
      needSorting: true
    },
    {
      id: 'date',
      disablePadding: false,
      label: i18n.t('scoreCard.radiusTable.date'),
      needSorting: true
    },
    {
      id: 'severityName',
      disablePadding: false,
      label: i18n.t('Severity'),
      needSorting: true
    },
  ];

  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    if (radiusData?.radiusViolationDtos) setTableData(radiusData?.radiusViolationDtos)
  }, [radiusData])
  const generateRows = () => {
    tableData.map(ele => {
      ele.date = ele.date ? moment(new Date(ele.date)).format('MM/DD/YYYY') : '';
    })
    return tableData;
  };
  const information = i18n.t('common.tableEmptyMessage');

  const convertToTitleCase = (inputString) => {
    // Split the input string into words based on underscores
    const words = inputString.split('_');

    // Capitalize the first letter of each word and join them with a space
    const result = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');

    return result;
  }

  return (
    <Styled.Wrapper widthOfProgress={90}>
      <FleetInfo
        description={scoreCardItemDescription.fleet}
        title={insuracePortalUserRole ? i18n.t('policyHolder') : i18n.t('scoreCard.fleet')}
        value={scoreCard?.companyData?.companyName}
        icon={'fleet'}
      />
      <FleetInfo
        description={scoreCardItemDescription.address}
        title={i18n.t('scoreCard.address')}
        value={scoreCard?.companyData?.address}
        icon={'location'}
      />
      <FleetInfo
        description={scoreCardItemDescription.dotNumber}
        title={i18n.t('scoreCard.dotNumber')}
        value={scoreCard?.companyData?.dotNumber}
        icon={'dot'}
      />
      <FleetInfo
        description={scoreCardItemDescription.xDate}
        title={i18n.t('scoreCard.xDate')}
        value={
          scoreCard?.companyData?.insuranceExpDate
            ? moment(scoreCard?.companyData?.insuranceExpDate).format(
              'MMM DD, YYYY'
            )
            : ''
        }
        icon={'date'}
      />
      <FleetInfo
        description={scoreCardItemDescription.reportDate}
        title={i18n.t('scoreCard.date')}
        value={moment().format('MMM DD, YYYY')}
        icon={'date'}
      />
      <div className="grid-item left">
        <OperatingTerritory
          iftaStats={scoreCard?.iftaStats}
          radiusOverages={scoreCard?.dailyRadiusStats?.radiusData || []}
          i18n={i18n}
          getVehicles={getVehicles}
          companyId={companyId}
          operatingTerritoryDescription={scoreCardItemDescription.operatingTerritory}
        />
        <QuickStats fetchQuickStatsDetails={fetchQuickStatsDetails} companyId={companyId} quickStatsdescription={scoreCardItemDescription.quickStatus} quickStats={scoreCard?.quickStatsData} i18n={i18n} />
      </div>

      <Tooltip mouseEnterDelay={0.5} placement='top' overlayStyle={{ maxWidth: "300px", opacity: 1 }} overlay={<span>{scoreCardItemDescription.fleetManagement || 'NA'}</span>}>
        <div className="grid-item right">
          <SubHeader
            variant="h1"
            className="statsHeading"
            text={insuracePortalUserRole ? i18n.t('scoreCard.policyHolderManagement') : i18n.t('scoreCard.fleetManagement')}
          />
          <div class="fleet-details">
            {scoreCard?.fleetStats?.vehicles.map((vehicle) => (
              <>
                <div className="fleet-details-icon">
                  <SvgIcon
                    value={
                      fleetIconMapping[vehicle?.type]
                        ? fleetIconMapping[vehicle?.type]
                        : 'bobTail'
                    }
                  />
                </div>
                <div className="fleet-details-name">{vehicle?.type}</div>
                <div className="fleet-details-value">{`${vehicle?.count} trucks`}</div>
              </>
            ))}
            {scoreCard?.fleetStats?.trailers.map((trailer) => (
              <>
                <div className="fleet-details-icon">
                  <SvgIcon
                    value={
                      fleetIconMapping[trailer?.type]
                        ? fleetIconMapping[trailer?.type]
                        : 'bobTail'
                    }
                  />
                </div>
                <div className="fleet-details-name">{trailer?.type}</div>
                <div className="fleet-details-value">{`${trailer?.count} trailers`}</div>
              </>
            ))}
          </div>
        </div>
      </Tooltip>
      <Tooltip mouseEnterDelay={0.5} placement='top' overlayStyle={{ maxWidth: "300px", opacity: 1 }} overlay={<span>{scoreCardItemDescription.radiusCompliance || 'NA'}</span>}>
        <div className="radius-table">
          <SubHeader
            variant="h1"
            className="statsHeading"
            text={i18n.t('scoreCard.radiusCompliance')}
          />
          <Styled.TableWrapper>
            <Filters
              severityList={[
                {
                  label: 'Less than 33%',
                  value: 'LESS_THAN_33_PERCENTAGE'
                },
                {
                  label: 'Between 33% to 67%',
                  value: 'BETWEEN_33_TO_67_PERCENTAGE'
                },
                {
                  label: 'Greater than 67%',
                  value: 'GREATER_THAN_67_PERCENTAGE'
                }
              ]}
              filterList={(data) => {
                setPagination(true)
                if (data.severityId) setTableData(radiusData?.radiusViolationDtos.filter(ele => ele.severity == data.severityId))
                else setTableData(radiusData?.radiusViolationDtos)
              }}
              handleReset={() => {
                setPagination(true)
                setTableData(radiusData?.radiusViolationDtos)
              }}
              values={values}
              setInitialValues={setInitialValues}
            />

            <Table
              type="client"
              defaultSortBy={{ sortBy: 'date', order: 'desc' }}
              title=""
              columns={[...columns]}
              data={generateRows()}
              emptyMessage={information}
              itemsPerPage={5}
              id="dashBoardAlerts"
              setPagination={setPagination}
              pageinationReset={pagination}
              actions={[]}
              i18n={i18n}
            />
          </Styled.TableWrapper>


        </div>
      </Tooltip>
    </Styled.Wrapper>
  );
};

export default Component;

Component.propTypes = {
  history: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  scoreCard: PropTypes.object.isRequired
};
