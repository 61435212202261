import { pageUrls } from '@/config/constants/keys';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import React, { lazy, useState } from 'react';
import Filters from './Filters';


const layout = window?.$environment?.CURRENT_LAYOUT;

const Breadcrumb = lazy(() => import(`../../../components/Breadcrumb`));
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Label = lazy(() => import(`../../../components/UI/Label/${layout}/style`));
const Link = lazy(() => import(`@/components/UI/Link`));
const Message = lazy(() => import(`../../../components/UI/Message/${layout}`));

const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Breadcrumb filters={filters} />
      <Component {...contentProps} filters={filters} />
    </Box>
  );
};
export default Index;

const Component = ({
  callback,
  Styled,
  i18n,
  history,
  pagination,
  changeRequest,
  request,
  vehicleList,
  allEventData,
  hasSaveApiStatus,
  setPagination }) => {


  const columns = [
    {
      id: 'vehicle',
      numeric: false,
      disablePadding: true,
      label: i18n.t('camera.vehicle'),
      needSorting: true
    },
    {
      id: 'count',
      numeric: false,
      disablePadding: false,
      label: i18n.t('cameraEvents.totalcounts'),
      needSorting: true
    },
  ];

  const generateRows = () => {
    const link = (string, id) => (
      <Link
      onClick={() => {
        history.push(pageUrls?.cameraVehicleEvents.replace(':id', id))
      }}
    >
      {string}
    </Link>
    )
    return allEventData?.eventInfoList.map(each => {
      return {
        vehicle: link(each?.vehicleName, each?.vehicleId),
        count: each?.eventCount
      }
    }) || []
  };

  const information = i18n.t('common.tableEmptyMessage');
  const [values, setInitialValues] = useState({
    vehicle: null
  });

  return (
    <>
      <Styled.Wrapper>
        <Styled.BottomWrapper>
          <div>
            <Label>Policy Holder: <span style={{ fontWeight: "800", fontSize: "larger"}}>{allEventData?.companyName}</span></Label>
          </div>
        <Styled.TableWrapper>
          <Filters
            handleReset={() => {
              changeRequest({})
              setInitialValues({
                vehicle: null
              })
            }}
            values={values}
            filterList={(e) => {
              changeRequest(e)
            }}
            setInitialValues={setInitialValues}
            vehicleList={vehicleList.map(e => ({ label: e?.name, value: e?.vehicleId }))}
          />
          <Table
            type="server"
            defaultSortBy={{ sortBy: 'vehicle', order: 'asc' }}
            title=""
            changeRequest={changeRequest}
            baseRequest={request}
            totalRecords={allEventData?.totalElements}
            columns={[...columns]}
            data={generateRows()}
            emptyMessage={information}
            itemsPerPage={10}
            actions={[]}
            defaultSort={'vehicle'}
            i18n={i18n}
            pageinationReset={pagination}
            pagenationClicked={(e) => {
              setPagination(!e);
            }}
          />
           {typeof hasSaveApiStatus?.errorMessage === 'string' && (
              <Message
                type="error"
                asTags
                message={i18n.t(hasSaveApiStatus.errorMessage)}
              />
            )}
        </Styled.TableWrapper>
        </Styled.BottomWrapper>
      </Styled.Wrapper>
    </>
  );
};

Component.propTypes = {
  callback: PropTypes.func.isRequired,
  vehicles: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  filterSuccess: PropTypes.func.isRequired,
};
