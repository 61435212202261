import styled from 'styled-components';
import Parent from './style';

export const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
padding-left: 20%;
    padding-bottom: 1%;
  .breadCrumbTag,
  .appliedBreadcrumb {
    font: ${theme.palette.filterFont} !important;
    font: 12px/1.428 Arial, "Geneva CY", sans-serif;
  }
  .breadCrumbTag {
    border: 0;
    padding: 0;
    .arrow {
      padding: 0 10px;
      display: inline-block;
      color: #222;
    }
    ::before,
    ::after {
        display: none;
    }
  }
  `}
`;

const Styled = {
  Wrapper
};
export default Styled;
