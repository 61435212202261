import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled.div`
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  justify-content: 'flex-start';
  padding-top: 20px;
  // height: 74vh;
  height: 84vh;
  .MuiTypography-h1 {
    align-items: center;
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    padding: 0px;
  }
`;
const LoginWrapper = styled.div`
  flex: 0 0 35%;
  // flex: 0 0 45%;
  margin-left: 62px;
  margin-top: 42px;
`;
const IconWrapper = styled(Box)`
  text-align: right;
  margin-top: 40%;
  img {
    padding-right: 5%;
    width: 40%;
  }
`;
const Styled = { Wrapper, LoginWrapper, IconWrapper };
export default Styled;
