import * as parent from '../../variables';

export const colors = {
  ...parent.colors,
  PRIMARY_MAIN: '#fff',
  BACKGROUND: '#efefef',
  HEADER_COUNT_TEXT: '#222',
  PRIMARY_CONTRAST: '#222',
  SECONDARY_MAIN: '#f58220',
  PUBLIC_HEADERCOLOR: '#000',
  LANGUAGE_SWITCH: '#f58220',
  HEADER_TEXT: '#f4f6f9',
  BODY_BACKGROUND: '#fff',
  MODAL_BACKGROUND: '#fff',
  MODAL_BORDER: '#d9d9d9',
  MODAL_LINK: '#f58220',
  LINK_COLOR: '#f58220',
  HEADER_COLOR: '#f58220',
  ACTIVE_ROW:'rgba(254, 236, 222, 0.7)',
  PAGINATION_BACKGROUND: '#fff',
  PAGINATION_COLOR: '#000',
  BACKGROUND_MENU:'#fff',
  RANGE_PICKER: {
    CELL_BG: '#dfdfdf',
    CELL_HOVER_BG: '#bbbbbb'
  },
  INPUT_BORDER: '#ccc',
};
export const fonts = ['Yanone Kaffeesatz', 'tahoma',].join(',');
export const secondaryFont = 'Permanent Marker';
export const tableFont = 'tahoma';
export const headerFont = '24px';
export const subTitle = '22px';
export const titleLabel= '24px';
export const formlabelFont = '24px';
export const buttonFont = '23px';
export const inputFont = '16px';
export const checkboxFont = '17px';
export const addbuttonFont = '23px';
export const companyDataFont = '28px';
export const iftaReport = '16px';
export const inspectionInput = '24px';
export const inspectionresolution = '20px';
export const companyDashboard = '20px';