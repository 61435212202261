export const colors = {
  PRIMARY_MAIN: '#1b1b1b',
  HEADER_COUNT_TEXT: '#c0bbb7',
  PRIMARY_CONTRAST: '#c0bbb7',
  PRIMARY_BORDER: '#2b2b2b',
  GREY: '#999',
  DAILY_LOG_GRAPH_BORDER: '#919191',
  NORMAL_LINK: '#b0b0b0',
  MODAL_TEXT: '#797979',
  MODAL_HEADING: '#575757',
  LIST_TEXT: '#6d6d6d',
  LIGHT_BORDER: '#2d2b2b',
  SECONDARY_MAIN: '#f58220',
  WHITE: '#fff',
  INPUT_BACKGROUND: '#efefef',
  RED: '#ff0000',
  BLACK: '#000',
  BACKGROUND: '#1b1b1b',
  BLACK_THREE: '#373737',
  RED_TWO: '#d34343',
  GREEN: '#32cd32',
  GREEN_TWO: '#97AE46',
  GREY_ELEVEN: '#777070',
  GREY_TWELVE: '#c3c3c3',
  BLACK_FOUR: '#333333',
  GRAPH_BLACK: '#212121',
  CHART_BLUE: '#00e2ff',
  WARNING: '#ffff00',
  GREY_DATA: '#696969'
};
export default colors;
export const fonts = ['Yanone Kaffeesatz', 'tahoma'].join(',');
