import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
form {
  padding: 2%;
  padding-top: 0;
}
.creditCardWrapper {
  display: none;
  padding-right: 2%;
  #creditCardUpdate {
    padding: 0 10px !important;
  }
}
#subscriptionTier {
  color: #848c96 !important;
  font-size: 14px !important;
}
.buttonSection {
  display: flex;
  justify-content: center;
  .companyInfoSubmit{
    order: 2;
    margin-left: 1%;
  }
  #subCancelButton {
    padding: 0 10px !important;
  }
}
.cycleruleSection .basic-multi-select .select__control {
    height: auto !important;
    .select__value-container {
     height: auto !important;
    }
    .select__multi-value {
       position: relative;
       background: #fff;
      .select__multi-value__label{
       padding-left: 13px;
      }
      .select__multi-value__remove {
       color: #999;
       position: absolute;
       top: 3px;
       padding-left: 0;
       padding-right: 0;
      }
    }
 }
`}
`;
const leftSection = styled(Parent.leftSection)`
${({ theme }) => `

`}
`;
const rightSection = styled(Parent.rightSection)`

`;
const TextWrapper = styled(Parent.TextWrapper)`
`;

const FieldsWrapper = styled(Parent.FieldsWrapper)`

`;
const wrapperSection = styled(Parent.wrapperSection)`

`;

const InsuranceCompanyWrapper = styled(Parent.InsuranceCompanyWrapper)`
`;

const Styled = {
  Wrapper,
  TextWrapper,
  FieldsWrapper,
  leftSection,
  rightSection,
  wrapperSection,
  InsuranceCompanyWrapper
};

export default Styled;
