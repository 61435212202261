import styled from 'styled-components';
import Parent, { FormStyle as ParentStyle } from '../Base/serverStyle';
import { getImagePath } from '../../../../utils/utils';
const TitleWrapper = styled(ParentStyle.TitleWrapper)``;
const ButtonsWrapper = styled(ParentStyle.ButtonsWrapper)`
${({ theme }) => `
#button {
  @media (min-width: ${theme.breakpoints.values.md}px) {
    min-width: 117px;
    padding: 1px 20px;
  }
  .MuiButton-label {
    padding: 0;
  }
}
`}
`;
const FieldsWrapper = styled(ParentStyle.FieldsWrapper)`
${({theme})=>`
  .MuiFormLabel-root {
    font-size: 14px !important;
    padding-bottom: 8px !important;
    padding-right: 10px
  }
  div[type="error"] p {
    font-size: 14px;
  }
  .react-datepicker-wrapper {
    min-height: 40px;
    height: 40px;
    input {
      border: 1px solid ${theme.colors.INPUT_BORDER} !important;
      height: 40px !important;
      background: ${theme.colors.FORM_INPUT_BG} url(${getImagePath('icons/calanderIcon.svg')})
        no-repeat right 14px center !important;
      padding: 9px 15px;
      font-size: 16px !important;
      border-radius: 4px;
    }
  }
  .react-datepicker__close-icon::after {
    position: absolute;
    right: 45px;
    top: 1px;
    background-color: ${theme.colors.SECONDARY_MAIN};
  }
  .react-datepicker__day--today {
    color: ${theme.colors.SECONDARY_MAIN} !important;
  }
  .react-datepicker__week .red_marker {
    border-bottom: 2px solid #f50707 !important;
  }
  .react-datepicker__week .yellow_marker {
    border-bottom: 2px solid #fadf14 !important;
  }
  .react-datepicker__week .green_marker {
    border-bottom: 2px solid #6cf205 !important;
  }
  .react-datepicker__day--highlighted{
    border-bottom: 2px solid #f7b946 !important;
  }
  .react-datepicker__day--highlighted{
    border-radius: 0 !important;
    border: 2px solid #d3d3d3 !important;
    border-bottom: 2px solid #f7b946 !important;
    color: #555555;
  }
  button[type='submit'] {
    background-color: #20b1e8;
    height: 40px;
    border-radius: 4px;
    color: #ffffff;
    font-size: 12px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    :hover {
      background-color: #20b1e8;
    }
  }
  button[type='reset'] {
    background-color: rgba(0, 0, 0, 0.3) !important;
    height: 40px;
    border-radius: 4px;
    color: rgba(255, 255, 255, 0.66) !important;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    :hover {
      background-color: rgba(0, 0, 0, 0.3) !important;
      color: rgba(255, 255, 255, 0.66) !important;
    }
  }
  `}`;
const TextWrapper = styled(ParentStyle.TextWrapper)``;
const TextareaWrapper = styled(ParentStyle.TextareaWrapper)``;
const CheckBoxWrapper = styled(ParentStyle.CheckBoxWrapper)``;
const FormWrapper = styled(Parent.FormWrapper)`
${({theme})=>`
.MuiButtonBase-root{
  ${window.$environment?.clutchNewUI && `border: none;`}
}
.select__multi-value{
  background: ${theme.colors.MULTISELECT_BACKGROUND};
  color: ${theme.colors.MULTISELECT_LABEL};
}
`}
`;
const FormStyle = {
  TitleWrapper,
  ButtonsWrapper,
  FieldsWrapper,
  TextWrapper,
  TextareaWrapper,
  CheckBoxWrapper
};

export const Styled = {
  FormWrapper
};
export default FormStyle;
