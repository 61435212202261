import React, { lazy, useState } from 'react';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Link from '@material-ui/core/Link';

import { useTranslation } from 'react-i18next';

import { getImagePath } from '../../../utils/utils';
import { imageUrls } from '../../../config/constants/keys';

const CloseButton = lazy(() =>
  import(`../../../components/CloseButton/${layout}`)
);

const layout = window?.$environment?.CURRENT_LAYOUT;
const Icon = lazy(() => import(`../../UI/Icon/${layout}`));

const NestedAccordion = (props) => {
  const i18n = useTranslation();
  const { data, AccordionStyle, isButtonStyle, Styled } = props; //Need Clarification
  const [isToolsClicked, setIsToolsClicked] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isTermToolsClicked, setIsTermToolsClicked] = useState(false);
  const [termIndex, setIndex] = useState(0);
  const iconGroup = (head, obj) => {
    return (
      <div className="iconTerminal">
        {(head === 'terminal' && (props?.neglectAccessLevel || props?.isDeptCrudRestricted) ) ||
        (head === 'department' && (props?.neglectAccessLevel || props?.isLocCrudRestricted) ) ? (
          <Icon
            src={getImagePath(imageUrls.addOrange)}
            onClick={() => props?.add(head, obj)}
          />
        ) : (
          ''
        )}
        {(head === 'terminal' && (props?.neglectAccessLevel || props?.isTermCrudRestricted) ) ||
        (head === 'department' && (props?.neglectAccessLevel || props?.isDeptCrudRestricted) ) ? (
          <>
            <Icon
              src={getImagePath(imageUrls.edit)}
              onClick={() => props?.edit(head, obj)}
            />
            <Icon
              src={getImagePath(imageUrls.deleteIcon)}
              onClick={() => props?.delete(head, obj)}
            />
          </>
        ) : (
          ''
        )}
      </div>
    );
  };
  return (
    <AccordionStyle.Wrapper>
      {data?.map((item, index) => {
        return (
          <ExpansionPanel key={item.terminalId} >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="wrapperdetails"
            >
              <AccordionStyle.Header >
                <div className="terminal">{item?.terminalName}</div>
                {props.isDeptCrudRestricted && isButtonStyle ? (
                  <img
                    src={getImagePath(imageUrls.departmentTool)}
                    onClick={(event) => {
                      event.stopPropagation();
                      setIsTermToolsClicked(true);
                      setIsToolsClicked(false);
                      setIndex(index);
                    }}
                    className="toolsDepartment"
                  />
                ) : (
                  iconGroup('terminal', item)
                )}
              </AccordionStyle.Header>
              {isTermToolsClicked && termIndex == index && (
                <Styled.ToolsContainer className="departmentToolsection">
                  <CloseButton
                    className="ToolsOptionClose"
                    icon={imageUrls.popupClose}
                    onClick={() => {
                      setIsTermToolsClicked(false);
                    }}
                  />
                  <ul>
                    {( props.isDeptCrudRestricted || props?.neglectAccessLevel )&& (
                      <li>
                        <Link
                          variant="body2"
                          onClick={() => props.add('terminal', item)}
                        >
                          {i18n.t('departments.addDepartment')}
                        </Link>
                      </li>
                    )}
                    {( props.isTermCrudRestricted || props?.neglectAccessLevel )  && (
                      <>
                        <li>
                          <Link
                            variant="body2"
                            onClick={() => props.edit('terminal', item)}
                          >
                            {i18n.t('departments.editTerminal')}
                          </Link>
                        </li>
                        <li>
                          <Link
                            variant="body2"
                            onClick={() => props.delete('terminal', item)}
                          >
                            {i18n.t('departments.deleteTerminal')}
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </Styled.ToolsContainer>
              )}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {!item?.departmentList?.length && (
                <ExpansionPanel>
                  <ExpansionPanelSummary>
                    <AccordionStyle.Header
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="wrapperdetails"
                    >
                      <div className="terminal">
                        {i18n.t('departments.noDepartments')}
                      </div>
                    </AccordionStyle.Header>
                  </ExpansionPanelSummary>
                </ExpansionPanel>
              )}
              {item?.departmentList?.map((departments, index) => {
                return (
                  <ExpansionPanel key={departments.departmentId}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <AccordionStyle.Header>
                        <div className="terminal department">
                          {departments?.departmentName}
                        </div>
                        {props.isLocCrudRestricted && isButtonStyle ? (
                          <img
                            src={getImagePath(imageUrls.departmentTool)}
                            onClick={(event) => {
                              event.stopPropagation();
                              setIsToolsClicked(true);
                              setIsTermToolsClicked(false);
                              setSelectedIndex(index);
                            }}
                            className="toolsDepartment"
                          />
                        ) : (
                          iconGroup('department', departments)
                        )}
                      </AccordionStyle.Header>
                      {isToolsClicked && selectedIndex == index && (
                        <Styled.ToolsContainer className="locationDetails departmentToolsection">
                          <CloseButton
                            className="ToolsOptionClose"
                            icon={imageUrls.popupClose}
                            onClick={() => {
                              setIsToolsClicked(false);
                            }}
                          />
                          <ul>
                            {props.isLocCrudRestricted && (
                              <li>
                                <Link
                                  variant="body2"
                                  onClick={() =>
                                    props.add('department', departments)
                                  }
                                >
                                  {i18n.t('departments.addLocation')}
                                </Link>
                              </li>
                            )}
                            {props.isDeptCrudRestricted && (
                              <>
                                <li>
                                  <Link
                                    variant="body2"
                                    onClick={() =>
                                      props.edit('department', departments)
                                    }
                                  >
                                    {i18n.t('departments.editDepartment')}
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    variant="body2"
                                    onClick={() =>
                                      props.delete('department', departments)
                                    }
                                  >
                                    {i18n.t('departments.deleteDepartment')}
                                  </Link>
                                </li>
                              </>
                            )}
                          </ul>
                        </Styled.ToolsContainer>
                      )}
                    </ExpansionPanelSummary>
                    {!departments?.subDepartmentList?.length && (
                      <ExpansionPanelDetails>
                        <AccordionStyle.Content>
                          <div className="terminal">
                            {i18n.t('departments.noLocations')}
                          </div>
                        </AccordionStyle.Content>
                      </ExpansionPanelDetails>
                    )}
                    {departments?.subDepartmentList?.map((location) => {
                      return (
                        <ExpansionPanelDetails key={location.subDepartmentId}>
                          <AccordionStyle.Content>
                            <div className="terminal">
                              {location?.subDepartmentName}
                            </div>
                            {( props?.isLocCrudRestricted || props?.neglectAccessLevel) && (
                              <div className="iconTerminal">
                                <Icon
                                  src={getImagePath(imageUrls.editDepartment)}
                                  onClick={() =>
                                    props.edit('location', location)
                                  }
                                />
                                <Icon
                                  src={getImagePath(imageUrls.deleteDepartment)}
                                  onClick={() =>
                                    props.delete('location', location)
                                  }
                                />
                              </div>
                            )}
                          </AccordionStyle.Content>
                        </ExpansionPanelDetails>
                      );
                    })}
                  </ExpansionPanel>
                );
              })}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      })}
    </AccordionStyle.Wrapper>
  );
};

export default NestedAccordion;
