import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import ReactDatePicker from 'react-datepicker';

import { UI_DATE_LOWER_FORMAT } from '@/config/constants/static';
import { pageUrls } from '@/config/constants/keys';
import { getDateLocale } from '@/utils/utils';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Input = lazy(() => import(`@/components/UI/Input/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));
const Select = lazy(() => import(`@/components/UI/Select/${layout}`));
const Upload = lazy(() => import(`@/components/FileUpload/${layout}`));
const SubHeader = lazy(() => import(`@/components/SubHeader/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const RenderComponent = ({
  values,
  handleEdit,
  errors,
  isNotValid,
  validateForm,
  customProps,
  submitAction,
  onClear
}) => {
  const { history, i18n } = customProps;
  //Date format to display date in UI from config
  const dateFormat = UI_DATE_LOWER_FORMAT;
  const fields = {
    driverId: {
      type: 'select',
      value: '',
      field: 'driverId',
      placeholder: i18n.t('common.pleaseSelect'),
      label: i18n.t('common.driverName')
    },
    date: {
      type: 'date',
      value: '',
      field: 'date',
      placeholder: dateFormat,
      label: i18n.t('common.date')
    },
    vehicleName: {
      type: 'text',
      value: '',
      field: 'vehicleName',
      placeholder: i18n.t('common.vehicleName'),
      label: i18n.t('common.vehicleName')
    },

    logData: {
      type: 'file',
      value: '',
      field: 'logData',
      placeholder: i18n.t('common.upload'),
      label: i18n.t('common.upload')
    }
  };
  const addToMonth = (months) => {
    var d = new Date();
    d.setMonth(d.getMonth() - (months || 0), d.getDate());
    return d;
  };
  let previousDate = new Date();
  previousDate.setDate(previousDate.getDate() - 1);

  React.useEffect(()=>{
    if(customProps?.removeValues === 'true') {
      onClear();
    }
  },[customProps?.removeValues])
  return (
    <>
      {customProps.hasApiStatus?.errorMessage && (
        <Message
          type="error"
          message={i18n.t(customProps.hasApiStatus.errorMessage)}
        />
      )}
      <SubHeader
        text={i18n.t('common.userInformation')}
        className="addLogTitle"
      />
      <FormStyle.default.FieldsWrapper className="formWrapper">
        <Label htmlFor="driverId">{fields.driverId.label}</Label>
        <FormStyle.default.TextWrapper>
          <Field
            id="driverId"
            name={fields.driverId.field}
            onChange={(event) => handleEdit(event, fields.driverId)}
            as={Select}
            placeholder={
              fields.driverId.placeholder ? fields.driverId.placeholder : ''
            }
            value={values.driverId || ''}
            type={fields.driverId.label}
            suggestions={customProps.drivers.data}
            isClearable
          />

          {errors['driverId'] && !isNotValid && (
            <Message type="error" message={errors['driverId']} />
          )}
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>

      <FormStyle.default.FieldsWrapper className="formWrapper">
        <Label htmlFor="vehicleName">{fields.vehicleName.label}</Label>
        <FormStyle.default.TextWrapper>
        <Field
            id="vehicleName"
            name={fields.vehicleName.field}
            onChange={(event) => handleEdit(event, fields.vehicleName)}
            as={Select}
            placeholder={
              fields.vehicleName.placeholder
                ? fields.vehicleName.placeholder
                : ''
            }
            value={values.vehicleName}
            type={fields.vehicleName.label}
            suggestions={customProps.fleetVehicleList}
            isClearable
          />

          {errors['vehicleName'] && !isNotValid && (
            <Message type="error" message={errors['vehicleName']} />
          )}
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>

      <FormStyle.default.FieldsWrapper className="formWrapper">
        <Label htmlFor="date">{fields.date.label}</Label>
        <FormStyle.default.TextWrapper>
          <Field
            id="date"
            name={fields.date.field}
            onChange={(event) => handleEdit(event, fields.date)}
            locale={getDateLocale()}
            as={ReactDatePicker}
            placeholderText={
              fields.date.placeholder ? fields.date.placeholder : ''
            }
            value={values.date}
            type={fields.date.label}
            showPopperArrow={false}
            showTimeSelect={fields.date.isTimeNeeded}
            maxDate={previousDate}
            showDisabledMonthNavigation
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            minDate={addToMonth(1)}
          />
          {errors['date'] && !isNotValid && (
            <Message type="error" message={errors['date']} />
          )}
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.FieldsWrapper className="formWrapper">
        <Label htmlFor="logData">{fields.logData.label}</Label>
        <FormStyle.default.TextWrapper>
          <Field
            id="logData"
            name={fields.logData.field}
            onUpload={(event) =>handleEdit(event, { field: 'logData', type: 'file' })}
            as={Upload}
            normal
            allowMultiple={false}
            totalFiles={1}
            placeholderText={
              fields.logData.placeholder ? fields.logData.placeholder : ''
            }
            value={values.logData || ''}
            type={fields.logData.label}
            acceptedFileTypes={[
              'application/pdf'
            ]}
            fileValidateTypeLabelExpectedTypesMap={{
              'application/pdf': '.pdf'
            }}
            labelFileTypeNotAllowed={'Only PDF file is allowed.'}
            allowFileTypeValidation
            fileChosen = {(event) => {
              if(event && event.length ===0) {
                handleEdit(event, { field: 'logData', type: 'file' })
              }
            }}
            removeValues= {customProps?.removeValues}
          />

          {errors['logData'] && !isNotValid && (
                <Message type="error" message={errors['logData']} />
          )}
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.ButtonsWrapper>
        <Button
          className="buttonStyle"
          type="reset"
          label={i18n.t('common.cancel')}
          onClick={(e) => {
            onClear();
            history.push(pageUrls.logsList);
          }}
        />
        <Button
          type="submit"
          className="buttonStyle"
          label={i18n.t('common.save')}
          onClick={(e, d) => {
            const validateResponse = validateForm();
            validateResponse.then((data) => {
              submitAction(data);
            });
          }}
        />
      </FormStyle.default.ButtonsWrapper>
    </>
  );
};

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  customProps: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};

export default RenderComponent;
