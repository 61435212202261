import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import { getStyle } from '../../../../utils/utils';

const ExportWrapper = styled(Box)`
  ${({ theme, style, transition }) => `

  .exportClose {
    position: absolute;
    top: 10px;
    right: 0px;
    padding-right: 45px;
    // display: none;
  }
  border: 1px solid #ddd;
  background-color: ${
    (getStyle((style || {}).border, theme.palette.primary || {}).main,
    theme.colors.PRIMARY_MAIN)
  };
  padding: 5px;
  padding-top: 11px;
  width: 390px;
  z-index: 10;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  justify-content: flex-end;
  flex-direction: column;
  position: relative;
  right: ${({ right }) => right || '30px'};
  @media (max-width: ${theme.breakpoints.values.sm}px) {
    width: 240px;
    form {
      flex-direction: column;
      #button {
        margin-top: 2px;
      }
    }
  }
  animation: ${transition || 'fromRight'}
  1.5s forwards ease-in-out;
@keyframes fromRight {
  from {
    right: -450px;
  }
  to {
    right: 3px;
  }
}
   form {
    justify-content: flex-start;
    .vertical {
      flex: 0 0 30%;
    }
   }

   @keyframes toRight {
    from {
      right: 3px;
    }
    to {
      right: -450px;
    }
  }
  .bulkSection {
    padding: 2%;
    padding-top: 3%;
    .vertical {
      flex: 0 0 48%;
    }
    .vertical-button {
      justify-content: center;
      display: flex;
      .MuiButtonBase-root{
        margin-top: 0 !important;
      }
      // width: 100%;
      // .MuiBox-root {
      //   width: 30% !important;
      // }
      // .MuiButtonBase-root {
      //   margin-top: -14px;
      // }
    }
  }
 
  .vertical-button {
    .MuiBox-root {
      width: 100% !important;
      margin-right: 0px !important;
    }
    .MuiButton-label{
      padding:0 !important;
    }
  }
  .react-datepicker-wrapper input,
  .react-datepicker-wrapper {
    height: 35px !important;
    padding-left: 4px !important;
    min-height: auto;
  }
  .react-datepicker__day:not(.react-datepicker__day--highlighted):not(.react-datepicker__day--disabled) {
    background: #e6e6e6 !important;
    border: 2px solid #d3d3d3 !important;
    color: #555555;
  }
  
  .MuiButtonBase-root {
    height: 35px !important;
    font-size: 14px;
    margin-top: 24px !important; 
  }
  .MuiButton-label {
    height: 35px !important;
  }
  .vertical {
    position: relative;
    width: 10%;
    .MuiSvgIcon-root {
        position: absolute;
        right: 0;
        width: 18px;
        height: 18px;
        color: #767676;
        bottom: 4px;
    }
  }
  `}
`;

const Header = styled(Box)`
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
`;

const Styled = { ExportWrapper, Header };

export default Styled;
