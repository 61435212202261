import React from 'react';
import PropTypes from 'prop-types';

import Form from './Form';
import Styled from './style';

const Component = ({
  apiStatus,
  history,
  clearApiStatus,
  addFleetManagerInfo,
  getCompanyInfoApi,
  getFleetManagerInfo
}) => {

  return (
    <Styled.Wrapper>
      <h1 className="stepTitle">Fleet Manager Information</h1>
      <div className="loginformwrap">
        <Form
          apiStatus={apiStatus}
          history={history}
          clearApiStatus={clearApiStatus}
          addFleetManagerInfo = {addFleetManagerInfo}
          getCompanyInfoApi= {getCompanyInfoApi}
          getFleetManagerInfo= {getFleetManagerInfo}
        />
      </div>
    </Styled.Wrapper>
  );
};

export default Component;

Component.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  requestForgotPassword: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  clearApiStatus: PropTypes.func.isRequired
};
