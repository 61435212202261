import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const DailyLogContainer = styled(Box)`
  ${({ theme }) => `
  table {
    min-width: auto !important;
    .MuiTableRow-root .MuiTableCell-root:first-child .MuiAvatar-root{
      width:47px;
      height: 57px;
    }
  }
  .other{
    margin-bottom: 0 !important;
  }
  .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root:nth-child(2) {
    min-width: 172px;
  }
  .driver {
    font-size: 18px;
    margin-top:0 !important;
    margin-bottom:0;
    color:#222;
  }
  .date {
    font-size: 15.5px;
    color: #6d6d6d;
    margin-top:0 !important;
    margin-bottom:0;
  }
  .mainContainer{
    .MuiPaper-root{
      padding-left: 3%;
    }
  }
  .linkContainer {
    padding-right: 3%;
  }
  a {
    background-color: ${
      (theme.palette.primary || {}).main || theme.colors.PRIMARY_MAIN
    };
    color: ${
      (theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN
    };
    padding: 5px 30px;
    font-size: 20px;
  }
  a:hover {
    text-decoration: none;
  }
  form {
    width: 70%;
    justify-content: flex-start;
    .vertical-button {
      width: 24%;
      justify-content: flex-start;
      .MuiBox-root {
        margin-right: 4%;
      }
      .MuiButtonBase-root {
        margin-top: 28px;
      }
      .MuiButton-label {
        padding:0 !important;
      }
    }
  }
  .ItemsContainer {
    flex: 0 0 70%;
  }
  .ButtonHolder {
    align-items: flex-end;
    padding-bottom: 16px;
    justify-content: flex-start;
    display: flex;
    flex: 0 0 20%;
    span {
      padding: 2px 15px;
    }
  }
  .viewContainer {
    display: flex;
    justify-content: space-between;
    padding: 1%;
    padding-bottom: 0;
    padding-left:21%;
    .buttonBulkUploadDoc {
      margin-top: 2%;
      height:45px;
      padding: 1px 10px;
      position: absolute;
      right: 2%;
      top: 0;
    }
    .MuiBox-root-278 {
      padding-bottom: 16px;
      flex: 0 0 49%;
      justify-content: flex-end;
      display: flex;
      align-items: flex-end;
      .MuiButton-outlined {
        padding: 2px 15px;
      }
      .MuiButton-root:hover {
        background-color: ${
          (theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN
        };
      }
    }
  }
  .displayContainer {
    display: flex;
    justify-content: space-between;
    @media (max-width: ${theme.breakpoints.values.lg}px) {
      flex-direction: column;
    }
    .MuiBox-root p {
      margin-top: 3%;
      margin-bottom: 0 !important;
    }
    .eldList {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 4% 0 2%;
      padding-top: 1%;
      .MuiBox-root {
        flex: 0 0 22%;
        margin-top: 2px;
        .MuiGrid-container {
          justify-content: center;
          .MuiAvatar-img {
            width: 100px;
            height: 102px;
          }
        }
      }
      .MuiAvatar-root {
        width: 110px;
        height: 110px;
      }
    }
  }
  .rightSide {
    display: flex;
    flex-direction: column;
    flex: 0 0 49%;
  }
  .linkContainer {
    display: flex;
    justify-content: flex-end;
  }
  .next {
    justify-content: flex-end;
    flex: 0 0 50%;
  }
  .prev {
    justify-content: flex-start;
    flex: 0 0 50%;
  }
  `}
`;
const EldContainer = styled(Box)`
  ${({ theme }) => `
  flex: 0 0 49%;
  @media (min-width: ${theme.breakpoints.values.lg + 1}px) and (max-width: ${
    theme.breakpoints.values.xl
  }px) {
    flex: 0 0 45%;
  }
  .mainContainer {
    border-top: 1px solid ${theme.colors.BLACK};
    margin-top: 20px;
    .MuiTableHead-root .MuiTableCell-head {
      padding: 25px 5px 10px 7px !important;
      font-size: 17px;
    }
    .MuiTableBody-root .MuiTableCell-root {
      padding: 8px 10px;
    }
  }
  `}
`;
const OtherContainer = styled(Box)`
  ${({ theme }) => `
  flex: 0 0 50%;
  @media (min-width: ${theme.breakpoints.values.lg + 1}px) and (max-width: ${
    theme.breakpoints.values.xl
  }px) {
    flex: 0 0 45%;
  }
  .linkContainer {
    padding-top: 0;
    .MuiTableHead-root .MuiTableCell-head {
      padding: 17px 15px 17px 7px !important;
      font-size: 17px;
    }
    .MuiTableBody-root .MuiTableCell-root {
      padding: 8px 10px;
    }
  }
  `}
`;
const ViolationContainer = styled(Box)`
  ${({ theme }) => `
  display: flex;
  border: 2px solid
    ${(theme.palette.primary || {}).border || theme.colors.PRIMARY_BORDER};
  display: flex;
  flex-direction: column;
  .heading {
    padding: 5px;
    color: ${
      (theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN
    };
    background-color: ${
      (theme.palette.primary || {}).main || theme.colors.PRIMARY_MAIN
    };
  }
  `}
`;
const Styled = {
  DailyLogContainer,
  EldContainer,
  OtherContainer,
  ViolationContainer
};

export default Styled;
