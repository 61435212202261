import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
${({ theme }) => `
  display: flex;
  flex-direction: column;
  .dvirForms {
    justify-content: flex-start;
    width: 100%;
    .vertical-button {
      .MuiBox-root:first-child {
        margin-right: 14px;
      }
      .MuiButtonBase-root {
        margin-top: 17px;
        padding-left: 0;
        padding-right: 0;
        .MuiButton-label {
          padding: 2px 22px;
        }
      }
    }
  }
  .saveForms {
    flex-direction: column;
  }
  .btn-wrapper {
    display: flex;
    width: 100%;
  }
  .leftWrapper{
    width: 16%;
    .dvir-btn-active {
      border: 2px solid ${theme.colors.PRIMARY_BORDER};
      border-right: 0;
    }
  }
  .rightWrapper {
    width: 84%;
    border: 2px solid ${theme.colors.PRIMARY_BORDER};
    border-left: 0;
    padding: 1%;
    .dvir-content-item-active {
      display: flex;
      .partdetails {
        width: auto;
        border: 1px solid ${theme.colors.LINK_COLOR};
        padding: 4px 8px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        margin-right: 1%;
        
      }
      .normalCheckbox svg {
        width: 20px;
        height: 20px;
      }
      .MuiFormLabel-root {
        flex: 0 0 auto;
        padding-right: 10px;
        padding-bottom: 0;
        align-self: center;
        font-size: 16px;
        padding-bottom: 0 !important;
      }
      .roleWrapper {
        align-items: center;
        .Checkbox {
          top: 2px;
        }
        .MuiGrid-root {
          width: auto;
        }
        span {
          padding-left: 0px;
          padding-right: 4px;
          top: -6px;
          position: relative;
        }
      }
    }
  }
  .partsName {
    justify-content flex-start;
    width: 100%;
    border: 0;
    border-right: 2px solid ${theme.colors.PRIMARY_BORDER};
    color: ${theme.colors.PRIMARY_CONTRAST};
    padding: 10px;
    text-align: left;
    font-size: 20px;
    outline: none;
    text-transform: uppercase;
    cursor: pointer;
  }
  .saveChanges {
    padding-top: 1%;
  }
  .MuiButtonBase-root {
    margin-top: 0 !important;
  }
  .buttonWrapper {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 2%;
    .advancedDVIR {
      margin-right: 1%;
    }
    button {
      margin-top: 0;
    }
  }
  .dvir-content-item-active {
    display: block;
  }
  .dvir-content-item {
    display: none;
  }
  `}
`;
const TopWrapper = styled(Box)`
  ${({ theme }) => `
  display: flex;
  justify-content: space-between;
  .exportContainer .MuiAvatar-root {
    width: 30px;
    height: 30px;
  }
  @media (max-width: ${theme.breakpoints.values.smd}px)  {
    flex-direction: column;
  }
  `}
`;

const TableWrapper = styled(Box)`
  padding-top: 2.5%;
`;

const Styled = { Wrapper, TopWrapper, TableWrapper };

export default Styled;
