import { deleteJsonArrayItemByField } from '../../../../utils/utils';
const initialState = {
  data: {},
  insuranceData: {},
  driverStatusList: [],
  statsCount: {
    fleetDrivers: '',
    fleetVehicles: '',
    companyCount: '',
    renewalCount: ''
  },
  driverStatusFilteredList: [],
  vehicleStatusList: [],
  vehicleStatusFilteredList: [],
  totalElements: 0
};
const isEs = () => localStorage.getItem(window.$environment?.SERVICE_PROVIDER + 'Language') == 'es'
const getApi = require(`../../../../config/apis/${window.$environment.SERVICE_PROVIDER}`)
  .default;
export const dashboard = {
  state: { ...initialState },
  reducers: {
    insuranceListSuccess(state, payload) {
      return {
        ...state,
        insuranceData: { ...payload },
      }
    },
    listSuccess(state, payload) {
      return {
        ...state,
        data: { ...payload },
        driverStatusList: [...state.driverStatusList],
        latestLogUrlFlg: false,
        statsCount: {
          fleetDrivers: state.statsCount.fleetDrivers,
          fleetVehicles: state.statsCount.fleetVehicles,
          companyCount: state.statsCount?.companyCount,
          renewalCount: state.statsCount?.renewalCount,
          fleetTrailers: state.statsCount.fleetTrailers

        },
        totalElements: state.totalElements,
        driverStatusFilteredList: [...state.driverStatusFilteredList],
        vehicleStatusList: [...state.vehicleStatusList],
        vehicleStatusFilteredList: [...state.vehicleStatusFilteredList]
      };
    },
    listFailed(state) {
      return {
        ...initialState,
        statsCount: { ...state.statsCount },
        driverStatusList: [...state.driverStatusList],
        totalElements: state.totalElements,
        driverStatusFilteredList: [...state.driverStatusFilteredList],
        vehicleStatusList: [...state.vehicleStatusList],
        vehicleStatusFilteredList: [...state.vehicleStatusFilteredList]
      };
    },
    clearPotentialViolationData(state) {
      let a = { ...state }
      a.data.driversAtRisk.infoItems = [];
      return { ...a };
    },
    clearRecentAccidentsData(state) {
      let a = { ...state }
      a.data.recentAccidents.infoItems = [];
      return { ...a };
    },
    updateDriverStatus(state, payload) {
      return {
        ...state,
        driverStatusList: payload.content ? [...payload.content] : [],
        driverStatusFilteredList: payload.content ? [...payload.content] : [],
        totalElements: payload.totalElements || 0
      };
    },
    updateLatestLogFlg(state, payload) {
      return {
        ...state,
        latestLogUrlFlg: payload
      }
    },
    updateVehicleStatus(state, payload) {
      return {
        ...state,
        vehicleStatusList: payload.infoItems ? [...payload.infoItems] : [],
        vehicleStatusFilteredList: payload.infoItems ? [...payload.infoItems] : []
      };
    },
    countSuccess(state, payload) {
      return {
        ...state,
        statsCount: {
          fleetDrivers: payload.driverCount,
          fleetVehicles: payload.vehicleCount,
          companyCount: payload.companyCount,
          renewalCount: payload.renewalCount,
          fleetTrailers: payload.trailerCount
        }
      };
    },
    filterSuccess(state, list) {
      return { ...state, driverStatusFilteredList: [...list] };
    },
    listMapFailed(state) {
      return { ...initialState, statsCount: { ...state.statsCount } };
    },
    updateDriverStatusRefresh(state, payload) {
      return {
        ...state,
        driverStatusList: payload.content ? [...payload.content] : [],
        driverStatusFilteredList: payload.content ? [...payload.content] : [],
        totalElements: payload.totalElements || 0
      };
    },
    clearDailyLogs(state, payload) {
      let a = { ...state }
      a.data.dailyLogs.infoItems = [];
      return { ...a };
    }
  },
  effects: (dispatch) => ({
    async fetchInsuranceData(payload, rootState) {
      try {
        const getApi = await import(
          `../../../../config/apis/${window.$environment.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('insuranceDashboardApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject
          },
          id: payload.apiId
        });
        response && this.insuranceListSuccess(response);
      } catch (error) {
        // this.insuranceListFailed();
      }
    },
    async fetchData(payload, rootState) {
      try {
        const getApi = await import(
          `../../../../config/apis/${window.$environment.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('dashboardApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject
          },
          id: payload.apiId
        });
        response && this.listSuccess(response);
      } catch (error) {
        this.listFailed();
      }
    },
    async handleDeleteAlert(payload, rootState) {
      try {
        const getApi = await import(
          `../../../../config/apis/${window.$environment.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('alertDeleteApi');
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...apiObject,
              url: apiObject.url.replace('{id}', payload.data.data.id)
            }
          },
          callback: payload.callback,
          id: payload.apiId
        });

        const alerts = deleteJsonArrayItemByField(
          rootState.dashboard.data.alerts.dashboardAlerts,
          'id',
          payload.data.data.id
        );
        this.listSuccess({
          ...rootState.dashboard.data,
          alerts: { dashboardAlerts: [...alerts] }
        });
        payload.callback('success', isEs() ? 'Alerta ha sido borrada' :'Alert has been deleted');
      } catch (error) { }
    },
    async clearAllAlerts(payload, rootState) {
      try {
        const getApi = await import(
          `../../../../config/apis/${window.$environment.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('clearAllAlerts');
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject
          },
          callback: payload.callback,
          id: payload.apiId
        });
        this.listSuccess({
          ...rootState.dashboard.data,
          alerts: { dashboardAlerts: [] }
        });
        payload.callback('success', 'Alert has been cleared');
      } catch (error) { }
    },
    async enableNotification(payload) {
      try {
        const getApi = await import(
          `../../../../config/apis/${window.$environment.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('enableNotification');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...apiObject,
              url: apiObject.url.replace('{id}', payload.data.id)
            }
          },
          id: payload.apiId
        });
        payload.callback('success', response);
      } catch (error) { }
    },
    async getDriverStatus(payload, rootState) {
      try {
        const getApi = await import(
          `../../../../config/apis/${window.$environment.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('driverStatusDashboardApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject,
            data: payload?.data
          },
          id: payload.apiId
        });
        response && this.updateDriverStatus(response);
      } catch (error) { }
    },
    async handleDeletePermissionRequest(payload, rootState) {
      try {
        const getApi = await import(
          `../../../../config/apis/${window.$environment.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('alertDeleteApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...apiObject,
              url: apiObject.url.replace('{id}', payload.data.data.id)
            }
          },
          callback: payload.callback,
          id: payload.apiId
        });

        if (response) {
          const permissionAlerts = deleteJsonArrayItemByField(
            rootState.dashboard.data.permissionAlerts.dashboardAlerts,
            'id',
            payload.data.data.id
          );
          this.listSuccess({
            ...rootState.dashboard.data,
            permissionAlerts: { dashboardAlerts: [...permissionAlerts] }
          });
          payload.callback('success', isEs() ? 'Eliminado' :'Deleted');
        }
      } catch (error) { }
    },
    async handleAcceptPermissionRequest(payload, rootState) {
      try {
        const getApi = await import(
          `../../../../config/apis/${window.$environment.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('acceptDevRequestApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...apiObject,
              url: apiObject.url.replace('{id}', payload.data.data.id)
            }
          },
          callback: payload.callback,
          id: payload.apiId
        });

        if (response) {
          const permissionAlerts = deleteJsonArrayItemByField(
            rootState.dashboard.data.permissionAlerts.dashboardAlerts,
            'id',
            payload.data.data.id
          );
          this.listSuccess({
            ...rootState.dashboard.data,
            permissionAlerts: { dashboardAlerts: [...permissionAlerts] }
          });
          payload.callback('success', 'Accepted');
        }
      } catch (error) { }
    },
    async fetchCountData(payload, rootState) {
      try {
        const dashboardcountApi = getApi('driverAndFleetCountApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: dashboardcountApi
          },
          id: payload.apiId
        });
        response && this.countSuccess(response);
      } catch (error) {
        this.listFailed();
      }
    },
    async refreshDriverStatusMap(payload, rootState) {
      try {
        const driverStatusRefreshApi = getApi('refreshDriverStatusMap');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: driverStatusRefreshApi,
            data: {
              isDashboard: false
            }
          },
          id: payload.apiId
        });
        response && this.updateDriverStatusRefresh(response);
      } catch (error) {
        this.listMapFailed();
      }
    },
    async getVehicleStatus(payload, rootState) {
      try {
        const getApi = await import(
          `../../../../config/apis/${window.$environment.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('vehicleStatusDashboardApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject,
            data: payload?.data
          },
          id: payload.apiId
        });
        response && this.updateVehicleStatus(response);
      } catch (error) { }
    },
    async clearAllDailyLogs(payload, rootState) {
      try {
        const getApi = await import(
          `../../../../config/apis/${window.$environment.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('clearAllDailyLogApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject,
            data: payload?.data
          },
          id: payload.apiId
        });
        this.clearDailyLogs();
      } catch (error) { }
    }
  })
};
