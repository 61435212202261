import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
ul{
    margin: 0;
}
li {
    border-bottom: 0 !important;
    .uploadSection{
        font-size: ${theme.palette.titleLabel} !important;
        align-self: center;
        padding-bottom: 0;
    }
    .iconContainer {
        align-items: center;
        .MuiAvatar-root {
            width: 27px;
            height: 27px;
        }
    }
    .imageUploadsectionsp {
        display: none;
    }
    .downloadImage{
        font-size: 17.5px;
        font-family: ${theme.palette.tableFont};
    }
    .uploadSection + .MuiFormLabel-root{
        font-size: ${theme.palette.titleLabel};
        align-self: center;
        padding-bottom: 0;
    }
}
li:nth-child(even):hover,
li:nth-child(even) {
    background: #efefef;
}
`}
`;
const UpdateWrapper = styled(Parent.UpdateWrapper)`
${({ theme }) => `
.uploadSection,
.details {
    font-size: ${theme.palette.titleLabel} !important;
}

.uploadDetails {
    font-family: tahoma;
    font-size: 15px !important;
}
`}
`;
const ItemUrl = styled(Parent.ItemUrl)`

`;

const Styled = { Wrapper, UpdateWrapper, ItemUrl };
export default Styled;
