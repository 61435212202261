import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import { getStyle } from '../../../utils/utils';
import Parent from './style';

const ExportWrapper = styled(Box)`
  border: solid 2px
    ${(({ style, theme }) =>
      getStyle((style || {}).border, theme.palette.primary || {}).border,
    '')};
  background-color: ${(({ style, theme }) =>
    getStyle((style || {}).border, theme.palette.primary || {}).main,
  '')};
  width: 450px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  position: absolute;
  transition-property: left;
  transition-duration: 2s;
  right: ${({ right }) => right || '0px'};
`;

const Header = styled(Parent.Header)`
  ${({ theme }) => `
flex-direction: column;
padding-left: 20px;
padding-top: 15px;
.filterForm {
  p {
    color: ${theme.colors.PRIMARY_CONTRAST} !important;
    font-size: 14px !important;
    font-family: tahoma;
  }
}
.exportContainer{
  padding-top: 20px;
  .exportWrapper {
    color: #2d3942;
    font-size: 12px;
    font-weight: bold;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
`}
`;
const ToolsContainer = styled(Parent.ToolsContainer)`
  ${({ theme }) => `
  border: 1px solid #ddd;
  background: ${theme.colors.WHITE};
  right: 0%;
  padding-bottom: 10px;
  a {
    font-weight: bold;
    font-size: 14px;
  }
`}
`;
const tableWrapper = styled(Parent.tableWrapper)`
  ${({ theme }) => `
  .spireonNewOnly{
    display none;
  }
.spireonOldOnly {
  display: block;
  color: #42505a;
  font: bold 12px "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 10px;
  padding-left: 10px;
  background: -webkit-linear-gradient(top, #d0d8db 0%, #b3bec3 100%);
  align-items: center;
}
padding: 0;
.totalSeperatorsp {
  border-top: 2px solid #000;
padding-top: 0;
padding-right: 13px;
.seperator {
  justify-content: flex-end;
  flex-direction: column;
  p:first-child {
    color: #848c96;
    font-size: 14px;
    font-family: tahoma;
    text-align: right;
  }
  :first-child {
    padding-top: 0;
  }
}
p:last-child {
  font-size: 18px;
}
}
`}
`;
const Content = styled(Parent.Content)`
  ${({ theme }) => `
.logwrapper {
  .MuiPaper-root{
    padding-bottom: 0;
  }
  .MuiTab-root {
      min-width: 100px;
      min-height: 23px;
  }
  .MuiButtonBase-root.MuiTab-root{
    padding: 0;
    margin-left: 20px;
    line-height: 1;
    margin-right: 1%;
  }
  .MuiTabs-fixed{
    padding-top: 15px;
    padding-bottom: 10px;
  }
}
header {
  border-bottom: 2px solid ${theme.colors.BLACK};
  box-shadow: none;
}
`}
`;

const Styled = { Header, ToolsContainer, tableWrapper, Content };

export default Styled;
