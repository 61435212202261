import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
 ${({ theme }) => `
 .select__control{
  margin-bottom: 5px !important;
 }
  display: flex;
  flex-direction: column;
  width: 100%;
  // padding: 3%;
  .CSAForms {
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    .csafield{
      width: 18.5%;
    }
    .vertical-button {
      .MuiBox-root:first-child {
        margin-right: 16px;
      }
      .MuiButtonBase-root {
        margin-top: 11px;
        padding-left: 0;
        padding-right: 0;
        .MuiButton-label {
          padding: 2px 22px;
        }
      }
    }
  }
  .basicMeasures {
    ${window.$environment.WHITE_THEME ? `background: white;` : ''}
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  padding: 2%;
  justify-content: center;
  #noData #msgWrap{
    height: 314px;
    border-bottom: 1px solid ${theme.colors.PRIMARY_BORDER};
    border-left: 1px solid ${theme.colors.PRIMARY_BORDER};
    width: auto;
    text-align: center;
  }
  #noData #nameWrap {
    text-align: center;
    color: #4C4C4C;
    // color: ${theme.colors.PRIMARY_BORDER};
    font-weight: bold;
  }
  #msgWrap p {
    padding-top: 95px;
    font-size: 16px;
    color: ${theme.colors.PRIMARY_CONTRAST};
}
.chartContainer {
  cursor: pointer;
  width: 70%;
}
  }
  }
  `}
`;
const TopWrapper = styled(Box)`
  ${({ theme }) => `
  display: flex;
  justify-content: space-between;
  .exportContainer .MuiAvatar-root {
    width: 30px;
    height: 30px;
  }
  @media (max-width: ${theme.breakpoints.values.smd}px)  {
    flex-direction: column;
  }
  `}
`;

const TableWrapper = styled(Box)`
  padding-top: 2.5%;
`;

const Styled = { Wrapper, TopWrapper, TableWrapper };

export default Styled;
