import PropTypes from 'prop-types';
import React, { lazy } from 'react';

import Avatar from '@material-ui/core/Avatar';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Link = lazy(() => import(`../../Link`));

const Icon = ({
  onClick,
  link,
  src,
  variant,
  addFilter,
  tableicon,
  className,
  tooltip,
  IconContainer,
  id,
  asLink,
  style,
  text
}) => {
  const handleClick = (e) => {
    onClick(e);
  };
  return (
    <IconContainer
      container
      justify="flex-start"
      alignItems="center"
      tableicon={tableicon}
      style={style}
      className={className ? className : 'iconContainer'}
      id={id || ''}
    >
      {link ? (
        <a href={link}>
          <Avatar alt="icon" src={src} variant={variant} />
        </a>
      ) : asLink ? (
        <Link onClick={handleClick}>{text}</Link>
      ) : (
        <Avatar
          alt="icon"
          className={addFilter?"addFilter":''}
          src={src}
          onClick={handleClick}
          variant={variant}
          title={tooltip ? tooltip : ''}
        />
      )}
      {tooltip ? <span className="tooltiptext">{tooltip}</span> : ''}
    </IconContainer>
  );
};

Icon.defaultProps = {
  link: '',
  src: '',
  onClick: () => {},
  variant: 'rounded'
};

Icon.propTypes = {
  link: PropTypes.string,
  src: PropTypes.string,
  onClick: PropTypes.func,
  variant: PropTypes.string
};

export default Icon;
