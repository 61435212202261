import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import { getStyle } from '../../../utils/utils';
import Parent from './style';

const ExportWrapper = styled(Box)`
  border: solid 2px
    ${(({ style, theme }) =>
      getStyle((style || {}).border, theme.palette.primary || {}).border,
    '')};
  background-color: ${(({ style, theme }) =>
    getStyle((style || {}).border, theme.palette.primary || {}).main,
  '')};
  width: 450px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  position: absolute;
  transition-property: left;
  transition-duration: 2s;
  right: ${({ right }) => right || '0px'};
`;

const Header = styled(Parent.Header)`
  ${({ theme }) => `
flex-direction: column;
padding: 10px 2% 50px 20%;
.filterForm p {
  color: ${theme.colors.PRIMARY_CONTRAST} !important;
  font-size: ${theme.palette.headerFont} !important;
}
.exportContainer{
  padding-top: 20px;
  .exportWrapper {
    font-size: 18px;
  }
}
.breadcrumbClass{
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 60px;
}
`}
`;
const ToolsContainer = styled(Parent.ToolsContainer)`
  ${({ theme }) => `
  border: 1px solid #ddd;
  background: ${theme.colors.WHITE};
  right: 2%;
`}
`;
const tableWrapper = styled(Parent.tableWrapper)`
  ${({ theme }) => `
  .spireonNewOnly,
.spireonOldOnly{
  display: none;
}
// padding: 0;
padding: 0 2%;
border-top: 2px solid ${theme.colors.BLACK};
`}
`;
const Content = styled(Parent.Content)`
  ${({ theme }) => `
.logwrapper {
  min-height: calc(100vh - 77px);
  .MuiPaper-root{
    padding-bottom: 0;
  }
  .MuiTab-root {
      min-width: 100px;
  }
  // .MuiTab-wrapper{
  //   border: 3px solid transparent;
  // }
  .MuiButtonBase-root.MuiTab-root{
    padding: 0;
    line-height: 1.4;
    margin-right: 1%;
  }
  .MuiTabs-fixed{
    padding-bottom: 5px;
  }
}
header {
  margin-left: 20%;
  padding-top: 21px;
  padding-bottom: 8px;
  border-bottom: 2px solid ${theme.colors.BLACK};
}
`}
`;

const Styled = { Header, ToolsContainer, tableWrapper, Content };

export default Styled;
