import styled from 'styled-components';
import InputLabel from '@material-ui/core/InputLabel';
import { getStyle } from '../../../../utils/utils';

const Wrapper = styled(InputLabel)`
  padding: ${({ style }) => getStyle((style || {}).padding, '', '0px')};
  variant: ${({ style }) => getStyle((style || {}).variant, '', 'standard')};
  color: ${({ style, theme, mode }) => {
    return mode === 'light'
      ? theme.colors.WHITE + '!important'
      : getStyle(
          (style || {}).color,
          (theme.palette.primary || {}).main,
          theme.colors.PRIMARY_MAIN
        );
  }};
  text-transform: uppercase;
  flex: ${(props) => getStyle((props.style || {}).flex, '0 0 25%', '')};
  font-size: 20px;
  line-height: 25px !important;
`;

export default Wrapper;
