import React, { lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import useTheme from '@material-ui/core/styles/useTheme';

import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Tooltip from '@material-ui/core/Tooltip';

import { getImagePath } from '../../../utils/utils';
import { getCookie } from '../../../services/cookie';
import {
  COOKIE_AUTH_DETAILS,
} from '@/config/constants/keys';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Modal = require(`../../UI/Modal/${layout}`).default;
const SvgIcon = lazy(() => import(`../../SvgIcon/${layout}`));
const Menu = lazy(() => import(`../../UI/Menu/${layout}`));
const Label = lazy(() => import(`../../UI/Label/${layout}`));

const LeftMenu = ({
  styles,
  needToolTip,
  noAuthCheck,
  onClick,
  showFilterPopup,
  leftMenuList,
  Wrapper,
  horizontal,
  cameraUrl,
  collapsible,
  open
}) => {
  // const classes = useStyles();
  const theme = useTheme();
  const { i18n } = useTranslation();
  const [selectedPath, setSelectedPath] = useState(window.location.pathname);
  const [modal, setModal] = useState(false)
  const [modalMessage, setModalMessage] = useState('common.cameraUrlNotFound')
  const token = getCookie(COOKIE_AUTH_DETAILS);

  const handleOnClick = (e) => {
    onClick(e);
  };

  const handleCameraUrl = (value) => {
   if(value){
    var win = window.open(value, '_blank');
    win.focus();
   }else{
    setModal(true);
   }
  }

  const generateMenuItem = (value, index) => {
    return (
      <Tooltip title={i18n.t(value.label)} disableHoverListener={(collapsible && open) || !collapsible} arrow placement='right'>
      <MenuItem
        onClick={handleOnClick}
        key={value.label}
        className="menuList"
        id={'list' + (index+1)}
        selected={value?.feature? selectedPath?.includes(value.feature): selectedPath === value.path}
      >
        <Link
          style={theme.palette.leftMenuText}
          to={value.path === "/fleetops" ?  selectedPath :  value.path || '#'}
          onClick={(e) => {
            if(value.path === "/fleetops"){
              e.preventDefault()
              setModalMessage('common.fleetopsUrlNotFound')
              handleCameraUrl(`${window.$environment?.FLEETOPS_URL}?token=${token.token}&partnerId=${window.$environment?.DOMAIN}`);
              // e.preventDefault()
            }else{
              setSelectedPath(value.path);
            }
            if (value.isFilter) {
              showFilterPopup();
              return false;
            }
          }}
        >
          {value.icon && (
            <ListItemIcon className="menuItem">
              <Wrapper.MenuToolTip index={index} needToolTip={needToolTip}>
                {value.icon.indexOf('.svg') > -1 ? (
                  <SvgIcon value={value.icon} />
                ) : (
                  <img
                    style={{ ...styles }}
                    alt="listIcon"
                    src={getImagePath(value.icon)}
                  />
                )}

                <span>{i18n.t(value.label)}</span>
              </Wrapper.MenuToolTip>
            </ListItemIcon>
          )}

          {(!needToolTip || horizontal) && (
            <Label variant="body1"> {i18n.t(value.label)}</Label>
          )}
        </Link>
      </MenuItem>
      </Tooltip>
    );
  };

  return (
    <Box>
      {leftMenuList?.length > 0 && (
        <Wrapper.MenuWrapper collapsible={collapsible} open={open}>
        <Modal
            open={modal}
            setOpen={()=>setModal(m => !m)}
            withCloseIcon
            alignItemsLeft
            data={{
              header: '',
              content: () => <div >{i18n.t(modalMessage)}</div>
            }}
        />
          <Menu
            list={leftMenuList}
            component={generateMenuItem}
            noAuthCheck={noAuthCheck}
          />
        </Wrapper.MenuWrapper>
      )}
    </Box>
  );
};

LeftMenu.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  horizontal: PropTypes.bool
};

LeftMenu.defaultProps = {
  label: '',
  onClick: () => {},
  horizontal: false
};

export default LeftMenu;
