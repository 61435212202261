import { getDateLocale } from '@/utils/utils';
import CalendarToday from '@material-ui/icons/CalendarToday';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import React, { lazy } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Styled from './style';

import ReactDatePicker from 'react-datepicker';

import { UI_DATE_LOWER_FORMAT } from '@/config/constants/static';
  
  const layout = window?.$environment?.CURRENT_LAYOUT;
  const Search = React.lazy(() => import(`@/components/UI/SimpleSearch/${layout}`));
  const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
  const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
  const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
  const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));
  
  
  const RenderComponent = ({
    values,
    handleEdit,
    validateForm,
    setSearch,
    customProps,
    submitAction,
    cancelAction
  }) => {
    const { i18n } = useTranslation();
    const history = useHistory();
    return (
      <>
        <Styled.SearchWrapper>
            <Search
                onChange={(event) => {
                  setSearch(event);
                }}
                placeHolder={i18n.t('dca.search')}
                id='search'
                default={customProps?.search}
                onRemove={customProps?.search === "" ? "clear" : ""}
            ></Search>
        </Styled.SearchWrapper>
        <Styled.FilterWrap>
        <div className='filter-section'>
        <div className='inputs'>
        <FormStyle.default.FieldsWrapper className="vertical">
          <Label variant="body1" htmlFor="fromDate">
            {i18n.t('common.dateFrom')}
          </Label>
          <FormStyle.default.TextWrapper>
            <Field
               id="fromDate"
               name="fromDate"
               onChange={(event) => {
                 handleEdit(event, { field: 'fromDate', type: 'date' });
               }}
               locale={getDateLocale()}
               as={ReactDatePicker}
               placeholderText={UI_DATE_LOWER_FORMAT}
               value={values.fromDate}
               showPopperArrow={false}
               showTimeSelect={false}
               {...values?.toDate ? { maxDate: new Date(values?.toDate) } : { maxDate: new Date() }}
               showDisabledMonthNavigation
               showMonthDropdown
               showYearDropdown
               dropdownMode="select"
               isClearable
            />
            {window.$environment.CURRENT_LAYOUT === 'SpireonNew' && (
              <CalendarToday />
            )}
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.FieldsWrapper className="vertical">
        <Label variant="body1" htmlFor="toDate">
          {i18n.t('common.dateTo')}
        </Label>
        <FormStyle.default.TextWrapper>
          <Field
            id="toDate"
            name="toDate"
            onChange={(event) => {
              handleEdit(event, { field: 'toDate', type: 'date' });
            }}
            locale={getDateLocale()}
            as={ReactDatePicker}
            placeholderText={UI_DATE_LOWER_FORMAT}
            value={values.toDate}
            showPopperArrow={false}
            showTimeSelect={false}
            maxDate={new Date()}
            {...values?.fromDate ? { minDate: new Date(values?.fromDate) } : {}}
            showDisabledMonthNavigation
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            isClearable
          />
          {window.$environment.CURRENT_LAYOUT === 'SpireonNew' && (
            <CalendarToday />
          )}
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
        </div>
        <div className='buttons'>
        <FormStyle.default.ButtonsWrapper className="vertical-button reportBtn">
            <Button
              label={i18n.t('common.view')}
              onClick={(e, d) => {
                const validateResponse = validateForm();
                validateResponse.then((data) => {
                  submitAction(data);
                });
              }}
            />
          </FormStyle.default.ButtonsWrapper>
          <FormStyle.default.ButtonsWrapper className="vertical-button reportBtn">
            <Button
              label={i18n.t('common.clear')}
              type={'reset'}
              onClick={(e, d) => {
                cancelAction();
              }}
            />
          </FormStyle.default.ButtonsWrapper>
        </div>
        </div>
        </Styled.FilterWrap>
      </>
    );
  };
  
  RenderComponent.propTypes = {
    values: PropTypes.object.isRequired,
    handleEdit: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    isNotValid: PropTypes.bool.isRequired,
    hasApiStatus: PropTypes.object,
    validateForm: PropTypes.func.isRequired,
    submitAction: PropTypes.func.isRequired
  };
  
  export default RenderComponent;
  