import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
  .heading {
    text-transform: uppercase;
  }
`;
const Profile = styled(Box)`
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  .vertical {
    width: 24%;
    margin-right: 1%;
    align-self: flex-start;
  }
  .vertical-button button {
    min-width: 210px;
  }
  .MFAWrapper{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .section-wrapper{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
  }
`;
const Styled = {
  Wrapper,
  Profile
};
export default Styled;
