import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
  display: flex;
  flex-direction: column;
  padding: 2%;
  form {
    flex-direction: column;
    .feedbackWrapper {
        width: 100%;
        flex-direction: row;
        margin-bottom: 0;
        textarea {
          font-size: 20px;
        }
    }
    .vertical-button {
        justify-content: center;
        button {
            min-width: 210px !important;
            border: 0;
            .MuiButton-label {
                padding: 0;
            }
        }
    }
  }
  .oldOnly {
    display: block;
    font-size: 20px;
    font-weight: normal;
    text-transform: uppercase;
    padding-bottom: 25px;
    color: ${theme.colors.LINK_COLOR};
  }
  `}
`;

const Styled = { Wrapper };

export default Styled;
