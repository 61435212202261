import React, { lazy } from 'react';

import { filterList, filterWithDateRange } from '@/utils/utils';

import { UI_DATE_LOWER_FORMAT } from '@/config/constants/static';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));

const filterAlertListForm = (
  values,
  list,
  filterSuccess,
  setValues,
  i18n,
  setPagination
) => {
  return (
    <Form
      type="client"
      direction="row"
      values={{ ...values }}
      className="filterForm"
      handleOnSubmit={() => {
        setPagination(true);
        const filters = values;
        Object.keys(filters).map((key) => {
          filters[key] === null || (filters[key] === '' && delete filters[key]);
        });
        let result = values.driver
          ? filterList(list.list, 'driver', values.driver)
          : list.list;
        if (values.dateFrom || values.dateTo) {
          result = filterWithDateRange(result, values.dateFrom, values.dateTo);
        }
        filterSuccess(result);
      }}
      handleOnCancel={(fields) => {
        setPagination(true);
        setValues({ driver: '', dateFrom: '', dateTo: '' });
        filterSuccess(list.list);
      }}
      setValues={setValues}
      fields={[
        {
          type: 'text',
          value: '',
          field: 'driver',
          placeholder: i18n.t('Driver'),
          label: i18n.t('common.driver')
        },
        {
          type: 'date',
          value: '',
          field: 'dateFrom',
          placeholder: UI_DATE_LOWER_FORMAT,
          label: i18n.t('common.dateFrom'),
          isTimeNeeded: false
        },
        {
          type: 'date',
          value: '',
          field: 'dateTo',
          placeholder: UI_DATE_LOWER_FORMAT,
          label: i18n.t('common.dateTo'),
          isTimeNeeded: false
        },
        { type: 'submit', value: i18n.t('common.view') },
        { type: 'reset', value: i18n.t('common.clear') }
      ]}
    />
  );
};

export default filterAlertListForm;
