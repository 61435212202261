import React from 'react';
import PropTypes from 'prop-types';

import LoginForm from './Form';

const Component = ({ Styled, ...rest }) => {
  return (
    <Styled.Wrapper>
      <div className="loginformwrap">
        <LoginForm {...rest} Styled={Styled} />
      </div>
    </Styled.Wrapper>
  );
};

export default Component;
Component.defaultProps = { hasRegistration: true };
Component.propTypes = {
  Styled: PropTypes.object.isRequired
};
