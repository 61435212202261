import styled from 'styled-components';
import { getImagePath, getStyle } from '../../../../utils/utils';
import { imageUrls } from '../../../../config/constants/keys';
import Parent from '../Base/style';

const ImportWrapper = styled(Parent)`
${({ theme, userImport, importVehicle }) => `
.csv-reader-input {
    background:  url(${
        userImport
          ? getImagePath(imageUrls.importUser)
          : getImagePath(imageUrls.import)
      }) no-repeat right 11px center;
      height: 38px;
      width: 128px;
      border-radius: 3px;
      border: 1px solid ${theme.colors.ACTION_BORDER};
      padding: 19px 10px;
      border-radius: 4px;
      z-index: 0;
      cursor: pointer;
      .tooltiptext {
        // opacity: 0.66;
        color: ${theme.colors.ACTION_BORDER};
        font-family: ${theme.palette.fontFamily};
        font-size: 12px;
        font-weight: 400;
        text-transform: uppercase;
        border: 0;
        visibility: visible;
        width: 95px;
        background: transparent;
        position: absolute;
        z-index: 1;
        top: 10px;
        left: 10px;
        text-align: left;
        :hover {
            visibility: visible;   
        }
      }
      input#fileSelect {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        cursor: pointer;
      }
}
`}
`;

export default ImportWrapper;
