import styled from 'styled-components';

const SearchWrapper = styled.div`
  ${({ theme, width }) => `
  width: ${width || '100%'};
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  .icon-wrapper {
    position: relative;
    left: 25px;
    top: 9px;
    color: ${theme.colors.PRIMARY_CONTRAST};
    z-index: 999;
  }
  .clear {
    color: ${theme.colors.PRIMARY_CONTRAST};
    position: relative;
    font-size: 18px;
    margin-top: 6px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    right: 29px;
    top: 6px;
  }
  `}
`;

export default SearchWrapper;
