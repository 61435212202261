import React, { useEffect, lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import RenderComponent from '@/components/modules/DynamicRenderPage';
import { isInsuracePortalUser, loadPageConfig } from '@/utils/utils';
const config = loadPageConfig();
const Title = lazy(() =>
  import(`@/${config.radiusComplianceAlertsViewMore[0].name}`)
);
const Content = lazy(() =>
  import(`@/${config.radiusComplianceAlertsViewMore[1].name}`)
);
const TopActions =
  config.radiusComplianceAlertsViewMore[0].children &&
  lazy(() =>
    import(`@/${config.radiusComplianceAlertsViewMore[0].children.name}`)
  );

const Alert = (parentProps) => {
  const {
    fetchAlertList,
    list,
    history,
    i18n,
    theme,
    filterSuccess,
    fetchFleetCompanies,
    fleetCompanies
  } = parentProps;
  const [pagination, setPagination] = useState(false);
  const match = useParams();
  const companyId = match.id;
  const insuracePortalUserRole = isInsuracePortalUser()
  const commonParentProps = { history, i18n, theme };
  const [selectedCompany, setSelectedCompany] = useState([])

  useEffect(() => {
    !list?.list.length && fetchAlertList({ apiId: 'radiusComplianceAlerts' });
    fetchFleetCompanies({
      apiId: 'fetchFleetCompanies'
    });
    return () => {
      filterSuccess(list.list)
    }
  }, []);
  useEffect(() => {
    let params = window.location.href.split('/')
    if (fleetCompanies.length > 0 && list?.list?.length > 0 && (params[params.length - 1] !== 'all')) {
      let company = fleetCompanies.filter(ele => ele?.id == companyId)
      if (company.length > 0) {
        const result = list?.list.filter((item) => {
          return item?.companyName
            ? item?.companyName === company[0].name
            : '';
        });
        filterSuccess(result);
        setSelectedCompany(company)
        window.history.pushState('', 'Alerts', '/alerts/all')
      }
    }
  }, [fleetCompanies, list])

  const columns = [
    ...(insuracePortalUserRole ? [{
      id: 'companyName',
      disablePadding: false,
      label: i18n.t('dashboard.tabs.radiusComplianceAlerts.company'),
      needSorting: true
    },
    {
      id: 'type',
      disablePadding: false,
      label: i18n.t('dashboard.tabs.radiusComplianceAlerts.type'),
      needSorting: true
    }] : []),
    {
      id: 'description',
      disablePadding: false,
      label: i18n.t('dashboard.tabs.radiusComplianceAlerts.description'),
      needSorting: true
    },
    {
      id: 'date',
      disablePadding: false,
      label: i18n.t('dashboard.tabs.radiusComplianceAlerts.date'),
      needSorting: true
    },
    ...(insuracePortalUserRole ? [{
      id: 'lossControl',
      disablePadding: false,
      label: i18n.t('dashboard.tabs.radiusComplianceAlerts.lossControl'),
      needSorting: true
    },
    {
      id: 'underwriter',
      disablePadding: false,
      label: i18n.t('dashboard.tabs.radiusComplianceAlerts.underwriter'),
      needSorting: true
    }] : [])
  ];

  return (
    <>
      <RenderComponent
        {...config.radiusComplianceAlertsViewMore[0]}
        parentProps={{ ...parentProps, columns, pagination, setPagination }}
        commonParentProps={commonParentProps}
        component={Title}
        childComponent={TopActions}
      />
      <RenderComponent
        {...config.radiusComplianceAlertsViewMore[1]}
        parentProps={{ ...parentProps, columns, pagination, setPagination, selectedCompany }}
        commonParentProps={commonParentProps}
        component={Content}
      />
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    list: state.radiusComplianceAlert,
    fleetCompanies: state.fleetCompanyList.list
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchAlertList: (data) => dispatch.radiusComplianceAlert.fetchData(data),
    filterSuccess: (data) => dispatch.radiusComplianceAlert.filterSuccess(data),
    fetchFleetCompanies: (data) => dispatch.fleetCompanyList.fetchData(data)
  };
};

export default connect(mapState, mapDispatch)(Alert);
Alert.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
  getFilters: PropTypes.func.isRequired,
  fetchAlertList: PropTypes.func.isRequired,
  filterSuccess: PropTypes.func.isRequired
};
