import styled from 'styled-components';
import Parent from '../Base/style';

const InputWrapper = styled(Parent.InputWrapper)``;
const TextAreaWrapper = styled(Parent.TextAreaWrapper)`
${({theme})=>`
    border: 1px solid ${theme.colors.INPUT_BORDER} !important;
    font-size: 16px;
`}`;
const MainWrapper = styled(Parent.MainWrapper)`
${({theme})=>`
/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px ${theme.colors.FORM_INPUT_BG} inset !important;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
    -webkit-text-fill-color: ${theme.colors.INPUT_COLOR} !important;
}
.MuiInputBase-root {
    padding: 0;
}
.MuiInputBase-input {
    padding: 9px 11px;
}
// .MuiInputBase-input:active,
// .MuiInputBase-input:focus {
//     outline-color: blue;
//     outline-style: solid;
// }
`}`;
const Styled = { InputWrapper, TextAreaWrapper, MainWrapper };
export default Styled;
