import React, { useState, useEffect, lazy } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import { Link as LinkLi } from '@material-ui/core';

// import Styled from './style';
import { imageUrls, pageUrls, ROLES } from '@/config/constants/keys';
import FilterLogsListForm from './FilterForm';
import UdLogTable from './UdLogTable';
import { getImagePath, hasEnabledFeature, hasRestrictedRole } from '@/utils/utils';
import HistoryIcon from '@material-ui/icons/History';
const layout = window?.$environment?.CURRENT_LAYOUT;
const CloseButton = lazy(() => import(`@/components/CloseButton/${layout}`));
const Label = lazy(() => import(`@/components/UI/Label/${layout}`));
const Export = lazy(() => import(`@/components/UI/Export/${layout}`));
const Checkbox = lazy(() => import(`@/components/UI/Checkbox/${layout}`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const Breadcrumb = lazy(() => import(`@/components/Breadcrumb`));
const Search = lazy(() =>
  import(`../../../components/UI/SimpleSearch/${layout}`)
);
const Input = lazy(() => import(`@/components/UI/Input/${layout}`));

const UndefinedDriverLogs = ({
  i18n,
  udLogList,
  udFilterSuccess,
  apiStatus,
  fetchUdLogList,
  udSetSelectedRow,
  fetchUnAssignedEntries,
  unAssignedEntries,
  drivers,
  saveUnAssignedEntries,
  setUdAssignEntryList,
  clearUdAssignEntryListById,
  callback,
  exportData,
  udLogApiId,
  Styled,
  filters,
  history,
  showExtraOptions,
  pagination,
  setPagination,
  clearAll
}) => {
  //form values for the filter form
  const [values, setValues] = useState({
    driver_name: '',
    dateFrom: '',
    dateTo: ''
  });
  const [isToolsClicked, setIsToolsClicked] = useState(false);

  const [totalData, setTotalData] = useState({ hours: 0, distance: 0 });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedDriverProfile, setSelectedDriverProfile] = useState(false);
  const [selectedDriverTime, setSelectedDriverTime] = useState(true);
  const [saveIconClicked, setSaveIconClicked] = useState(false);
  const [selectedId, setSelectedId] = useState(false);
  const [errorMsg, setError] = useState('');
  useEffect(() => {
    setPagination(true);
    setValues({
      driver_name: '',
      dateFrom: '',
      dateTo: ''
    });
    if (document.getElementsByClassName('reset')) { let a = document.getElementsByClassName('reset')[0]?.click() };
  }, [selectedDriverProfile]);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const md = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  let heightSet = sm ? 585 : md ? 342 : 280;
  const setSelectedIndexfn = (index) => {
    let rowLen =
      window.$environment.SERVICE_PROVIDER === 'spireon'
        ? 36
        : window.$environment.APP_LAYOUT === 'NewUi'
          ? 40
          : 39;
    let value = rowLen * index;
    let wl =
      window.$environment.SERVICE_PROVIDER === 'spireon'
        ? 104
        : window.$environment.APP_LAYOUT === 'NewUi'
          ? 82
          : 74;
    let changeValue = wl + value;
    setSelectedIndex(changeValue);
  };
  const columns = [
    {
      id: 'driver_name',
      numeric: false,
      disablePadding: true,
      label: i18n.t('common.driver'),
      needSorting: true
    },
    {
      id: 'vehicle_name',
      numeric: false,
      disablePadding: false,
      label: i18n.t('common.vehicle'),
      needSorting: true
    },
    {
      id: 'date',
      numeric: false,
      disablePadding: false,
      label: i18n.t('common.date'),
      needSorting: true
    },
    {
      id: 'hours',
      numeric: true,
      disablePadding: false,
      label: i18n.t('common.hoursWorked'),
      needSorting: true
    },
    {
      id: 'distance',
      numeric: true,
      disablePadding: false,
      label: i18n.t('common.distance'),
      needSorting: true
    },

    {
      id: 'actions',
      label: 'common.tools'
    }
  ];
  const actions = [
    {
      icon: (row, index) => {
        if (window.$environment.CURRENT_LAYOUT === 'SpireonNew') {
          return (
            <> <LinkLi
              component={Link}
              to={pageUrls.logsHistory.replace(':id', row.idPub)}
            >
              <HistoryIcon className="historyIcon" />
            </LinkLi>
              {!hasRestrictedRole([ROLES.VIEW_ONLY_FM]) &&
                (<a onClick={() => {
                  setError('')
                  setSelectedId(row.idPub)
                  setSaveIconClicked(true)
                }}
                >
                  <img src={getImagePath(imageUrls.saveUdlog)} className="saveIcon" alt="saveIcon" />
                </a>)
              }</>
          );
        } else
          return (
            <Icon
              src={getImagePath(imageUrls.toolsIcon)}
              onClick={() => {
                setIsToolsClicked(true);
                udSetSelectedRow({ index, row });
                setSelectedIndexfn(index);
              }}
            />
          );
      }
    }
  ];
  useEffect(() => {
    if (exportData.length === 0) {
      udLogList.filteredList.map((item) => {
        return exportData.push({
          [i18n.t('common.driver')]: item.driver_name,
          [i18n.t('common.vehicle')]: item.vehicle_name,
          [i18n.t('common.date')]: item.date,
          [i18n.t('common.hoursWorked')]: item.hours,
          [i18n.t('common.distance')]: item.distance,
          [i18n.t('common.cycleRule')]: item.cycleRule
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportData]);
  const selectChange = (e) => {
    udFilterSuccess(e);
  };
  const [clearAllClicked, setClearAllClicked] = useState(false)
  const handleClear = () => {
    setClearAllClicked(true)
  }
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      {layout !== 'OldUi' && <Breadcrumb filters={filters} />}
      <Styled.Header>
        <>
          <FilterLogsListForm
            values={values}
            udLogList={udLogList}
            handleClear={handleClear}
            udFilterSuccess={udFilterSuccess}
            setValues={setValues}
            i18n={i18n}
            setPagination={setPagination}
            Styled={Styled}
            selectedDriverProfile={selectedDriverProfile}
          />
        </>
      </Styled.Header>
      <Styled.Header>
        <div className="udcheckWrapper">
          <Checkbox
            label={i18n.t('logs.unassignedDriveTime')}
            checked={selectedDriverTime}
            onClick={() => {
              if (!selectedDriverTime) {
                fetchUdLogList({
                  apiId: udLogApiId,
                  data: { showUnidentifiedDriver: false }
                });

                setSelectedDriverTime(true);
                setSelectedDriverProfile(false);
              }
            }}
            id="selectedDriverTime"
          />
          <Checkbox
            label={i18n.t('logs.unidentifiedDriverProfile')}
            checked={selectedDriverProfile}
            onClick={() => {
              if (!selectedDriverProfile) {
                fetchUdLogList({
                  apiId: udLogApiId,
                  data: { showUnidentifiedDriver: true }
                });
                setSelectedDriverTime(false);
                setSelectedDriverProfile(true);
              }
            }}
            id="selectedDriverProfile"
          />
        </div>
        <div className="exportWrapper">
          <Export
            src={imageUrls.exportIcon}
            displayText={
              window.$environment.CURRENT_LAYOUT === 'SpireonNew'
                ? 'EXPORT'
                : ''
            }
            data={exportData}
            filename={i18n.t('logs.logs')}
            toolTip={i18n.t('common.export')}
            className="exportContainer"
            buttonName={i18n.t('Export Data')}
          />
        </div>
        <div className="breadcrumbClass">
          {layout === 'OldUi' && <Breadcrumb filters={filters} />}
        </div>
      </Styled.Header>
      <Styled.UdlogTable>
        <div className="spireonNewOnly">
          <span>Unidentified Driver Profile Logs</span>
          <Search
            id={'logSearch'}
            default={udLogList.filteredList}
            onChange={(e) => selectChange(e)}
            width="25%"
            fromData
            query={['driver']}
            placeHolder={'Search Unidentified Driver Profile Logs'}
          />
        </div>
        <div className="spireonOldOnly">{i18n.t('logs.spireonTitle')}</div>
        <UdLogTable
          udLogList={udLogList}
          fetchUnAssignedEntries={fetchUnAssignedEntries}
          i18n={i18n}
          clearAllClicked={clearAllClicked}
          setClearAllClicked={setClearAllClicked}
          unAssignedEntries={unAssignedEntries}
          drivers={drivers}
          apiStatus={apiStatus}
          columns={columns}
          setIsToolsClicked={setIsToolsClicked}
          udSetSelectedRow={udSetSelectedRow}
          setSelectedIndexfn={setSelectedIndexfn}
          saveUnAssignedEntries={saveUnAssignedEntries}
          setUdAssignEntryList={setUdAssignEntryList}
          clearUdAssignEntryListById={clearUdAssignEntryListById}
          clearAll={clearAll}
          callback={callback}
          Styled={Styled}
          actions={actions}
          pageinationReset={pagination}
          pagenationClicked={(e) => {
            setPagination(!e);
          }}
          selectedDriverProfile={selectedDriverProfile}
          saveIconClicked={saveIconClicked}
          setSaveIconClicked
          ={setSaveIconClicked}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          errorMsg={errorMsg}
          setError={setError}
        />

        {isToolsClicked && (
          <Styled.ToolsContainer selectedindex={selectedIndex}>
            <CloseButton
              className="ToolsOptionClose"
              icon={imageUrls.popupClose}
              onClick={() => {
                setIsToolsClicked(false);
              }}
            />
            <ul>
              {!hasEnabledFeature('udBulkEditEnabled') &&
                window.$environment.CURRENT_LAYOUT !== 'SpireonOld' && (
                  <li>
                    <LinkLi
                      component={Link}
                      to={
                        selectedDriverProfile
                          ? pageUrls.editLog1.replace(
                            ':id',
                            udLogList.selectedRow.row.idPub
                          )
                          : pageUrls.editLog.replace(
                            ':id',
                            udLogList.selectedRow.row.idPub
                          )
                      }
                    >
                      {i18n.t('common.edit')}
                    </LinkLi>
                  </li>
                )}
              <li>
                <LinkLi
                  component={Link}
                  to={pageUrls.logsHistory.replace(
                    ':id',
                    udLogList.selectedRow.row.idPub
                  )}
                >
                  {i18n.t('common.history')}
                </LinkLi>
              </li>
              {showExtraOptions && (
                <li>
                  <LinkLi
                    component={Link}
                    to={`${pageUrls.dailyDocuments}?driver=${udLogList.selectedRow.row.driverId}&day=${udLogList.selectedRow.row.date}`}
                  >
                    {i18n.t('logs.viewDocument')}
                  </LinkLi>
                </li>
              )}
            </ul>
          </Styled.ToolsContainer>
        )}
        <Styled.InfoContainer>
          <p className="seperator">
            <Label>{i18n.t('common.totalHoursWorked')}</Label>
            {window.$environment.CURRENT_LAYOUT !== 'NewUi' ? (
              <Input
                value={udLogList?.totalHours?.toFixed(2)}
                disabled={true}
              />
            ) : (
              <Label>{udLogList?.totalHours?.toFixed(2)}</Label>
            )}
          </p>
          <p className="seperator">
            <Label>{i18n.t('common.totalDistance')}</Label>
            {window.$environment.CURRENT_LAYOUT !== 'NewUi' ? (
              <Input value={udLogList.totalDistance} disabled={true} />
            ) : (
              <Label>{udLogList.totalDistance}</Label>
            )}
          </p>
        </Styled.InfoContainer>
      </Styled.UdlogTable>
    </Box>
  );
};

export default UndefinedDriverLogs;

UndefinedDriverLogs.defaultProps = {};

UndefinedDriverLogs.propTypes = {
  i18n: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  udLogList: PropTypes.object.isRequired,
  udFilterSuccess: PropTypes.func.isRequired,
  apiStatus: PropTypes.object.isRequired,
  clearApiStatus: PropTypes.func.isRequired,
  fetchUdLogList: PropTypes.func.isRequired,
  fetchUnAssignedEntries: PropTypes.func.isRequired,
  unAssignedEntries: PropTypes.object.isRequired,
  saveUnAssignedEntries: PropTypes.func.isRequired,
  setUdAssignEntryList: PropTypes.func.isRequired,
  clearUdAssignEntryListById: PropTypes.func.isRequired
};
