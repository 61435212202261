import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
.MuiExpansionPanelSummary-root { 
  h2 {
    text-transform: capitalize;
  }
}
.MuiPaper-root {
  box-shadow: none;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.26);
  border: 0;
  margin-bottom: 0;
  background: #fff;
}
.addNewParts{
  color: #fff;
}
.MuiAccordionSummary-content.Mui-expanded h2 {
  text-transform: capitalize;
  font-size: 20px !important;
  color: #000 !important;
}
.MuiAccordionSummary-root.Mui-expanded {
  min-height: 24px;
  opacity: 1;
  padding-left: 22px;
}
.MuiAccordion-root.Mui-expanded{
  margin: 0;
  margin-bottom: 10px;
}
.MuiAccordionDetails-root {
  padding: 30px;
}
.MuiCollapse-wrapperInner {
  background-color: #fff;
}
.MuiExpansionPanelDetails-root {
  position: relative;
  padding-top: 0px;
}
.viewAll {
  position: absolute;
  top: 5px;
  width: 50%;
  right: 20px;
  top: 10px;
  width: 50%;
  right: 0;
  padding-bottom:0;
  padding-top: 0;
  font-size: 15px;
  opacity: 0.87;
  .normalCheckbox {
    svg {
      width: 22px;
      height: 22px;
      background: #f8f8f8;
    }
    .Checkbox {
      top: 0;
      rect.square {
        stroke: #fff;
      }
    }
    label {
      color: #fff !important;
      position: relative;
      font-size: 14px;
      top: -3px;
    }
  }
}
.statusIcon .iconContainer:first-child {
  order:2;
  img {
    width: 20px;
    height: 20px;
    margin-left: 8px;
  }
}
`}
`;
const Header = styled(Parent.Header)``;
const Content = styled(Parent.Content)`
.MuiPaper-root {
  padding: 0;
  border-radius: 0;
  box-shadow: none;
  border: 0;
}
`;

const Accordion = { Wrapper, Header, Content  };

export default Accordion;
