import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';

import Styled from './style';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Input = lazy(() => import(`@/components/UI/Input/${layout}`));
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const SubHeader = lazy(() => import(`@/components/SubHeader/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const RenderComponent = ({
  values,
  handleEdit,
  validateForm,
  customProps,
  submitAction,
  errors,
  isNotValid
}) => {
  const { i18n } = useTranslation();
  const showError = (field, validationErrors, isNotValid, apiError) => {
    if (validationErrors[field] && !isNotValid) {
      return <Message type="error" message={errors[field]} />;
    }
    if (apiError?.[field]) {
      return <Message type="error" message={apiError[field]} />;
    }
  };
  return (
    <Styled.PopupUpload>
      <SubHeader text={i18n.t('parts.partTitle')} variant="h2" />
      <Styled.UploadWrapper>
        <div className="uploadFields">
          <FormStyle.default.FieldsWrapper width="100%"  className="fieldSection">
            <Label variant="body1" htmlFor="partName">
              {i18n.t('parts.partName')}
            </Label>
            <FormStyle.default.TextWrapper>
              <Field
                id="partName"
                name="partName"
                handleOnChange={(event) =>
                  handleEdit(event, { field: 'partName' })
                }
                value={values?.partName}
                as={Input}
                type="text"
              />
              {showError(
                'partName',
                errors,
                isNotValid,
                customProps.hasApiStatus?.errorMessage
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper width="100%" className="fieldSection">
            <Label variant="body1" htmlFor="partsInventory">
              {i18n.t('parts.partsInventory')}
            </Label>
            <FormStyle.default.TextWrapper>
              <Field
                id="partsInInventory"
                name="partsInInventory"
                handleOnChange={(event) =>
                  handleEdit(event, { field: 'partsInInventory' })
                }
                value={values?.partsInInventory}
                as={Input}
                type="number"
                updateValue = {(event)=>{
                  if(event){
                  return event.replace(/\D/g,'')
                  }
                }}
              />
              {showError(
                'partsInInventory',
                errors,
                isNotValid,
                customProps.hasApiStatus?.errorMessage
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper width="100%" className="fieldSection">
            <Label variant="body1" htmlFor="cost">
              {i18n.t('parts.cost')}
            </Label>
            <FormStyle.default.TextWrapper>
              <Field
                id="cost"
                name="cost"
                handleOnChange={(event) => handleEdit(event, { field: 'cost' })}
                value={values?.cost}
                as={Input}
                type="text"
                disabled={values?.id ? true : false}
              />
              {showError(
                'cost',
                errors,
                isNotValid,
                customProps.hasApiStatus?.errorMessage
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>

          <FormStyle.default.ButtonsWrapper className="saveAction">
            <Button
              type="submit"
              className="buttonStyle"
              label={i18n.t('common.save')}
              onClick={() => {
                const validateResponse = validateForm();
                validateResponse.then((data) => {
                  submitAction(data);
                });
              }}
            />
          </FormStyle.default.ButtonsWrapper>
        </div>
      </Styled.UploadWrapper>
    </Styled.PopupUpload>
  );
};

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  customProps: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};

export default RenderComponent;
