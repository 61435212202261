import React, { lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import { pageUrls } from '../../../../config/constants/keys';
import { phoneNumberValidation } from '../../../../utils/utils';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Input = lazy(() => import(`../../../../components/UI/Input/${layout}`));
const Label = lazy(() =>
  import(`../../../../components/UI/FormLabel/${layout}`)
);
const Message = lazy(() =>
  import(`../../../../components/UI/Message/${layout}`)
);
const Button = lazy(() => import(`../../../../components/UI/Button/${layout}`));
const RadioButton = lazy(() =>
  import(`../../../../components/UI/Radio/${layout}`)
);
const CheckBox = lazy(() =>
  import(`../../../../components/UI/Toggle/${layout}`)
);
const Link = lazy(() => import(`../../../../components/UI/Link`));
const Select = lazy(() => import(`../../../../components/UI/Select/${layout}`));
const Payment = lazy(() => import(`../../../../components/Payment/${layout}`));
const SubHeader = lazy(() =>
  import(`../../../../components/SubHeader/${layout}`)
);
const FormStyle = require(`../../../../components/UI/Form/${layout}/serverStyle`);
const RenderComponent = ({
  values,
  handleEdit,
  errors,
  isNotValid,
  validateForm,
  customProps,
  submitAction
}) => {
  const {
    i18n,
    registration,
    selectedItem,
    setSelectedItem,
    showPaymentInfo,
    getCaptcha,
    captcha
  } = customProps;
  const {
    costForLite,
    costForPro,
    costForStandard,
    salexTax: salesTax,
    termsOfUse
  } = registration;
  const [actualSalesTax, setActualSalesTax] = useState('0.00');
  const [captchaValidation, setCapchaValidation] = useState();
  const maintenancePeriod = [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
    { label: 6, value: 6 },
    { label: 7, value: 7 },
    { label: 8, value: 8 },
    { label: 9, value: 9 },
    { label: 10, value: 10 },
    { label: 11, value: 11 },
    { label: 12, value: 12 }
  ];
  const cardDetails = (data) => {
    handleEdit(data.stripeToken, { field: 'brainTreeToken' });
    handleEdit(data.cardHolderName, { field: 'cardHolderName' });
    const validateResponse = validateForm();
    validateResponse.then((validateData) => {
      if (!Object.keys(validateData).length) {
        showPaymentInfo({
          ...values,
          brainTreeToken: data.stripeToken,
          cardHolderName: data.cardHolderName
        });
      }
    });
  };
  const calculateAmount = (type, salesTax) => {
    const calculate = (cost) => {
      const amount = parseInt(values.initialTrucks, 10) * (cost / 100) || 0;
      return {
        totalAmount: (amount + amount * salesTax).toFixed(2),
        cost: cost / 100,
        salesTax: (amount * salesTax).toFixed(2)
      };
    };
    switch (type) {
      case 'LITE':
        return calculate(costForLite);
      case 'STANDARD':
        return calculate(costForStandard);
      case 'PRO':
        return calculate(costForPro);
      default:
        break;
    }
  };
  return (
    <>
      <FormStyle.default.TitleWrapper>
        <SubHeader
          variant="h2"
          text={i18n.t('registration.companyInfoHeading')}
        />
      </FormStyle.default.TitleWrapper>
      <div className="registrationWrapper">
        <div className="companyDetails">
          <FormStyle.default.FieldsWrapper width="100%">
            <Label htmlFor="company">{i18n.t('common.company')}</Label>
            <FormStyle.default.TextWrapper>
              <Field
                name="company"
                id="company"
                handleOnChange={(event) =>
                  handleEdit(event, { field: 'company', type: 'text' })
                }
                as={Input}
                placeholder={i18n.t('common.company')}
                value={values.company}
                type="text"
              />
              {errors['company'] && !isNotValid && (
                <Message type="error" message={errors['company']} />
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper>
            <Label htmlFor="dotNumber">{i18n.t('common.dotNumber')}</Label>
            <FormStyle.default.TextWrapper>
              <Field
                name="dotNumber"
                id="dotNumber"
                handleOnChange={(event) =>
                  handleEdit(event, { field: 'dotNumber', type: 'text' })
                }
                as={Input}
                placeholder={i18n.t('common.dotNumber')}
                value={values.dotNumber}
                type="text"
              />
              {errors['dotNumber'] && !isNotValid && (
                <Message type="error" message={errors['dotNumber']} />
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper>
            <Label htmlFor="initialTrucks">
              {i18n.t('registration.initialTrucks')}
            </Label>
            <FormStyle.default.TextWrapper>
              <Field
                name="initialTrucks"
                id="initialTrucks"
                handleOnChange={(event) =>
                  handleEdit(event, {
                    field: 'initialTrucks',
                    type: 'text'
                  })
                }
                as={Input}
                placeholder={i18n.t('registration.initialTrucks')}
                value={values.initialTrucks}
                type="text"
                onBlur={(event) =>
                  setSelectedItem(
                    calculateAmount(values.subscriptionTier, actualSalesTax)
                  )
                }
              />
              {errors['initialTrucks'] && !isNotValid && (
                <Message type="error" message={errors['initialTrucks']} />
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper>
            <Label htmlFor="maintenancePeriod">
              {i18n.t('registration.maintenancePeriod')}
            </Label>
            <FormStyle.default.TextWrapper className="halfWidth">
              <Field
                name={'maintenancePeriod'}
                id={'maintenancePeriod'}
                onChange={(event) => {
                  handleEdit(event, {
                    field: 'maintenancePeriod',
                    type: 'text'
                  });
                }}
                as={Select}
                placeholderText={i18n.t('registration.maintenancePeriod')}
                value={values.maintenancePeriod}
                type={'select'}
                suggestions={maintenancePeriod}
              />

              {errors['maintenancePeriod'] && !isNotValid && (
                <Message type="error" message={errors['maintenancePeriod']} />
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper>
            <Label htmlFor="maintenanceMileage">
              {i18n.t('registration.maintenanceMileage')}
            </Label>
            <FormStyle.default.TextWrapper className="halfWidth">
              <Field
                name="maintenanceMileage"
                id="maintenanceMileage"
                handleOnChange={(event) =>
                  handleEdit(event, {
                    field: 'maintenanceMileage',
                    type: 'text'
                  })
                }
                as={Input}
                placeholder={i18n.t('registration.maintenanceMileage')}
                value={values.maintenanceMileage}
                type="text"
              />
              {errors['maintenanceMileage'] && !isNotValid && (
                <Message type="error" message={errors['maintenanceMileage']} />
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper className="subscriptionField">
            <Link
              href={pageUrls.subscriptionLink}
              id="subscriptionTier"
              newPage
            >
              {i18n.t('registration.subscriptionTier')}
            </Link>
            <FormStyle.default.CheckBoxWrapper direction="row">
              <RadioButton
                aria-labelledby="subscriptionTier"
                value={values.subscriptionTier}
                options={[
                  { label: 'Prime8 ELD', value: 'LITE' },
                  { label: 'Fleet Xpress', value: 'STANDARD' },
                  { label: 'Xtreme', value: 'PRO' }
                ]}
                onClick={(event) => {
                  setSelectedItem(calculateAmount(event, actualSalesTax));
                  handleEdit(event, {
                    field: 'subscriptionTier',
                    type: 'radio'
                  });
                }}
              />
            </FormStyle.default.CheckBoxWrapper>
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper>
            <Label htmlFor="contractLength">
              {i18n.t('registration.contractLength')}
            </Label>
            <FormStyle.default.TextWrapper className="halfWidth">
              <Field
                name="contractLength"
                id="contractLength"
                handleOnChange={(event) =>
                  handleEdit(event, { field: 'contractLength', type: 'text' })
                }
                as={Input}
                placeholder={i18n.t('registration.contractLength')}
                value={values.contractLength}
                disabled
                type="text"
              />
              {errors['contractLength'] && !isNotValid && (
                <Message type="error" message={errors['contractLength']} />
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
        </div>
        <div className="companyDetails">
          <FormStyle.default.FieldsWrapper width="100%">
            <Label htmlFor="address">{i18n.t('common.address')}</Label>
            <FormStyle.default.TextWrapper>
              <Field
                name="address"
                id="address"
                handleOnChange={(event) =>
                  handleEdit(event, { field: 'address', type: 'text' })
                }
                as={Input}
                placeholder={i18n.t('common.address')}
                value={values.address}
                type="text"
              />
              {errors['address'] && !isNotValid && (
                <Message type="error" message={errors['address']} />
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper width="100%">
            <Label htmlFor="city">{i18n.t('common.city')}</Label>
            <FormStyle.default.TextWrapper>
              <Field
                name="city"
                id="city"
                handleOnChange={(event) =>
                  handleEdit(event, { field: 'city', type: 'text' })
                }
                as={Input}
                placeholder={i18n.t('common.city')}
                value={values.city}
                type="text"
              />
              {errors['city'] && !isNotValid && (
                <Message type="error" message={errors['city']} />
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper width="100%">
            <Label htmlFor="state">{i18n.t('common.state')}</Label>
            <FormStyle.default.TextWrapper>
              <Field
                name={'state'}
                id={'state'}
                onChange={(event) => {
                  if (event.label === 'Texas') {
                    setActualSalesTax(salesTax);
                    setSelectedItem(
                      calculateAmount(values.subscriptionTier, salesTax)
                    );
                  } else {
                    setActualSalesTax('0.00');
                    setSelectedItem(
                      calculateAmount(values.subscriptionTier, 0)
                    );
                  }

                  handleEdit(event, { field: 'state', type: 'select' });
                }}
                as={Select}
                placeholderText={i18n.t('common.state')}
                value={values.state}
                type={'select'}
                suggestions={customProps.states}
              />

              {errors['state'] && !isNotValid && (
                <Message type="error" message={errors['state']} />
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper width="100%">
            <Label htmlFor="zipCode">{i18n.t('common.zipCode')}</Label>
            <FormStyle.default.TextWrapper>
              <Field
                name="zipCode"
                id="zipCode"
                handleOnChange={(event) =>
                  handleEdit(event, { field: 'zipCode', type: 'text' })
                }
                as={Input}
                placeholder={i18n.t('common.zipCode')}
                value={values.zipCode}
                type="text"
              />
              {errors['zipCode'] && !isNotValid && (
                <Message type="error" message={errors['zipCode']} />
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper width="100%">
            <Label htmlFor="companyPhone">{i18n.t('common.phone')}</Label>
            <FormStyle.default.TextWrapper>
              <Field
                name="companyPhone"
                id="companyPhone"
                handleOnChange={(event) =>
                  handleEdit(event, { field: 'companyPhone', type: 'text' })
                }
                as={Input}
                placeholder={i18n.t('common.phone')}
                value={values.companyPhone}
                type="text"
                updateValue={phoneNumberValidation}
              />
              {errors['companyPhone'] && !isNotValid && (
                <Message type="error" message={errors['companyPhone']} />
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
        </div>
      </div>
      <FormStyle.default.TitleWrapper>
        <SubHeader variant="h2" text={i18n.t('registration.userInfoHeading')} />
      </FormStyle.default.TitleWrapper>
      <div className="registrationWrapper">
        <div className="companyDetails">
          <FormStyle.default.FieldsWrapper width="100%">
            <Label htmlFor="firstName">{i18n.t('common.firstName')}</Label>
            <FormStyle.default.TextWrapper>
              <Field
                name="firstName"
                id="firstName"
                handleOnChange={(event) =>
                  handleEdit(event, { field: 'firstName', type: 'text' })
                }
                as={Input}
                placeholder={i18n.t('common.firstName')}
                value={values.firstName}
                type="text"
              />
              {errors['firstName'] && !isNotValid && (
                <Message type="error" message={errors['firstName']} />
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper>
            <Label htmlFor="lastName">{i18n.t('common.lastName')}</Label>
            <FormStyle.default.TextWrapper>
              <Field
                name="lastName"
                id="lastName"
                handleOnChange={(event) =>
                  handleEdit(event, { field: 'lastName', type: 'text' })
                }
                as={Input}
                placeholder={i18n.t('common.lastName')}
                value={values.lastName}
                type="text"
              />
              {errors['lastName'] && !isNotValid && (
                <Message type="error" message={errors['lastName']} />
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper>
            <Label htmlFor="email">{i18n.t('common.email')}</Label>
            <FormStyle.default.TextWrapper>
              <Field
                name="email"
                id="email"
                handleOnChange={(event) =>
                  handleEdit(event, { field: 'email', type: 'email' })
                }
                as={Input}
                placeholder={i18n.t('mail@gmail.com')}
                value={values.email}
                type="email"
              />
              {errors['email'] && !isNotValid && (
                <Message type="error" message={errors['email']} />
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper>
            <Label htmlFor="userPhone">{i18n.t('common.phone')}</Label>
            <FormStyle.default.TextWrapper>
              <Field
                name="userPhone"
                id="userPhone"
                handleOnChange={(event) =>
                  handleEdit(event, { field: 'userPhone', type: 'text' })
                }
                as={Input}
                placeholder={i18n.t('common.phone')}
                value={values.userPhone}
                type="text"
                updateValue={phoneNumberValidation}
              />
              {errors['userPhone'] && !isNotValid && (
                <Message type="error" message={errors['userPhone']} />
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper>
            <Label htmlFor="password">{i18n.t('common.password')}</Label>
            <FormStyle.default.TextWrapper>
              <Field
                name="password"
                id="password"
                handleOnChange={(event) =>
                  handleEdit(event, { field: 'password', type: 'password' })
                }
                as={Input}
                placeholder={i18n.t('common.password')}
                value={values.password}
                type="password"
              />
              {errors['password'] && !isNotValid && (
                <Message type="error" message={errors['password']} />
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper>
            <Label htmlFor="confirm_password">
              {i18n.t('common.confirmPassword')}
            </Label>
            <FormStyle.default.TextWrapper>
              <Field
                name="confirm_password"
                id="confirm_password"
                handleOnChange={(event) =>
                  handleEdit(event, {
                    field: 'confirm_password',
                    type: 'password'
                  })
                }
                as={Input}
                placeholder={i18n.t('common.confirmPassword')}
                value={values.confirm_password}
                type="password"
              />
              {errors['confirm_password'] && !isNotValid && (
                <Message type="error" message={errors['confirm_password']} />
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
        </div>
        <div className="companyDetails">
          <FormStyle.default.FieldsWrapper
            width="100%"
            className="paymentSection"
          >
            <Payment
              ref={customProps.paymentRef}
              theme={customProps.theme}
              buttonLabel={'Pay Now'}
              validationMessage={i18n.t('common.fieldRequiredMessage')}
              updateCard={(data) => cardDetails(data)}
              handleOnChange={(event, type) => { }}
              getCaptcha={getCaptcha}
              captchaValidation={captchaValidation}
            />
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper
            width="100%"
            className="AmountSection"
          >
            {i18n.t('registration.totalAmount')}
            {''} {selectedItem.totalAmount}
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper width="100%" id="agree">
            <div className="agreeCheckbox">
              <CheckBox
                aria-labelledby="agree"
                type="NormalGrey"
                checked={values.agree}
                onClick={(event) =>
                  handleEdit(event, {
                    field: 'agree',
                    type: 'checkbox'
                  })
                }
              />
              <div className="agreeLabel">
                <span>{i18n.t('registration.iAgree')}</span>
                <Link href={termsOfUse} className="link">
                  {i18n.t('registration.termsOfUse')}
                </Link>
              </div>
            </div>
            {errors['agree'] && !isNotValid && (
              <Message type="error" message={errors['agree']} />
            )}
          </FormStyle.default.FieldsWrapper>
          <div className="createWrapper">
            <FormStyle.default.FieldsWrapper width="100%" id="producerCode">
              <FormStyle.default.TextWrapper>
                <Field
                  aria-labelledby="producerCode"
                  name="producerCode"
                  handleOnChange={(event) =>
                    handleEdit(event, {
                      field: 'producerCode',
                      type: 'text'
                    })
                  }
                  as={Input}
                  placeholder={i18n.t('registration.producerCode')}
                  value={values.producerCode}
                  type="text"
                />
                {errors['producerCode'] && !isNotValid && (
                  <Message type="error" message={errors['producerCode']} />
                )}
              </FormStyle.default.TextWrapper>
            </FormStyle.default.FieldsWrapper>
            <FormStyle.default.ButtonsWrapper>
              <Button
                type="submit"
                label={i18n.t('registration.createAccount')}
                onClick={() => {
                  if (captcha) { setCapchaValidation() } else setCapchaValidation('This field is required')
                  const validateResponse = validateForm();
                  validateResponse.then((data) => {
                    customProps.paymentRef.current.handleSubmit();
                    submitAction(data);
                  });
                }}
              />
            </FormStyle.default.ButtonsWrapper>
          </div>
        </div>
      </div>
    </>
  );
};

export default RenderComponent;

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  customProps: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};
