import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
// padding: 2%;
.total {
    font-size: 16px;
    .amount {
      font-size: 18px;
    }
  }
  .searchTitle {
    display: none;
    font-weight: normal;
  }
  .vertical-button {
    width: auto;
    .MuiBox-root {
      width: auto;
    }
    button {
      margin-top: 33px;
      min-width: 117px;
      .MuiButton-label {
        padding: 0 !important;
      }
    }
  }
  .exportContainer {
    .MuiAvatar-root {
      width: 20px;
      height: 20px;
    }
  }
`;
const TopWrapper = styled(Parent.TopWrapper)`
 
`;
const TableWrapper = styled(Parent.TableWrapper)`
 
`;
const Styled = { Wrapper, TopWrapper, TableWrapper };

export default Styled;
