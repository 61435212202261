import React from 'react';
import Chart from 'chart.js';
import { fade } from '@material-ui/core/styles/colorManipulator';

export default class PieChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  chartRef = React.createRef();

  componentDidMount() {
    const pageurl = this.props.pageUrls;
    const noHyperLink = this.props?.noHyperLink;
    const myChartRef = this.chartRef.current.getContext('2d');
    new Chart(myChartRef, {
      type: 'pie',
      data: {
        labels: this.props.labels,
        datasets: this.props.data,
      },
      options: {
        onClick: function (e, c) {
          // Route to new page
          // history.push(pageurl);
          if (noHyperLink === false) {
            window.open(pageurl, '_blank')
          }
        },
        legend: {
          display: true,
          labels: {
            boxWidth: 10
          },
          position: 'bottom'
        },
        title: {
          display: true,
          text: this.props.title,
          fontFamily: 'Yanone Kaffeesatz',
          fontSize: 18
        }
      }
    });
  }
  render() {
    return (
      <div>
        <canvas id="barChart" width={this.props.width} height={this.props.height} ref={this.chartRef} />
      </div>
    );
  }
}
