import React from 'react';

const PercentageBarGraph = ({ Styled, label, percentage, count, onClick, smallSize=false, isFlexed = false }) => {
  return (
    <>
      <span>{label || ''}</span>
      <Styled.Wrapper isFlexed={isFlexed} smallSize={smallSize} onClick={onClick}>
        <Styled.Graph percentage={percentage} />
      </Styled.Wrapper>
      <span>{count}</span>
    </>
  );
};

export default PercentageBarGraph;
