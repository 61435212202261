import React, { lazy } from 'react';
import PropTypes from 'prop-types';

const layout = window?.$environment?.CURRENT_LAYOUT;

const Layout = lazy(() => import(`../${layout}`));

const AppLayout = ({ children, ...rest }) => {
  return <Layout {...rest}>{children}</Layout>;
};

export default AppLayout;

AppLayout.defaultProps = {
  children: null
};
AppLayout.propTypes = {
  children: PropTypes.element
};
