import * as apis from '../';
const localApis = {
  getTimezoneApi: {
    url: '/registration/timeZones',
    type: 'get',
    isPublic: true
  },
  acceptDeveloperPermissionApi: {
    url: 'alerts/accept/{id}',
    type: 'post'
  }
};
const getApi = (api) => {
  return localApis[api] || apis[api];
};
export default getApi;
