import React, { useEffect, lazy, useState } from 'react';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import RenderComponent from '@/components/modules/DynamicRenderPage';

import ParentStyled from './style';
import { loadStyle, loadPageConfig, isInsuracePortalUser, getQueryStringKeys } from '@/utils/utils';
import { chartTypes } from '@/config/constants/keys';
let Styled = loadStyle(ParentStyled, 'pages/web/QuickStats');

const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.scorecardReport[0].name}`));
const Content = lazy(() => import(`@/${config.scorecardReport[1].name}`));

const ScorecardReport = (parentProps) => {
  const insuracePortalUserRole = isInsuracePortalUser();
  const { fetchReportList, fetchReportChart, vehicles, fetchVehicles } = parentProps;
  const [pagination, setPagination] = useState(false);
  const basicRequest = {
    displayLength: '10',
    displayStart: '1'
  };
  const history = useHistory();

  const [request, setRequest] = useState({
    ...basicRequest,
  });
  const theme = useTheme();
  const i18n = useTranslation();
  const commonParentProps = { history, i18n, Styled, theme };
  const fetchPaginateApi = 'fetchReportsListApi'

  const sortColumnMapping = {
    unitId: "vehicleName",
    work_order_id: "idPub",
    type: "unitType",
    reported_date: "createDate",
    scheduled_date: "beginDate",
    due_date: "endDate",
    workOrderType: "type",
    status: "state"
  }
  const changeRequest = (fields) => {
    if (fields?.sortCol) {
      const type = fields.sortCol
      fields.sortCol = sortColumnMapping[type] ? sortColumnMapping[type] : fields.sortCol
    }
    setRequest({ ...basicRequest, ...request, ...fields });
  };

  useEffect(() => {
    !vehicles.vehicleList.length &&
      fetchVehicles({
        apiId: 'fetchVehicles'
      });
  }, [])

  useEffect(() => {
    let chartReq = JSON.parse(JSON.stringify(request))
    delete chartReq.displayLength
    delete chartReq.displayStart
    delete chartReq.sortCol
    delete chartReq.sortDir
    Object.keys(chartTypes).forEach(ele => {
      fetchReportChart({
        apiId: `${ele}_API`,
        data: {
          request: {
            ...chartReq,
            period: request.period || getQueryStringKeys(history.location.search, 'period'),
            companyId: Number(getQueryStringKeys(history.location.search, 'companyId')),
            chartType: ele
          }
        }
      })
    })
    fetchReportList({
      apiId: fetchPaginateApi,
      data: {
        request: {
          ...request,
          period: request.period || getQueryStringKeys(history.location.search, 'period'),
          companyId: getQueryStringKeys(history.location.search, 'companyId')
        },
      }
    });
  }, [request]);

  return (
    <>
      <RenderComponent
        {...config.scorecardReport[0]}
        parentProps={{
          ...parentProps,
        }}
        commonParentProps={commonParentProps}
        component={Title}
      />
      <RenderComponent
        {...config.scorecardReport[1]}
        parentProps={{
          ...parentProps,
          changeRequest,
          request,
          pagination,
          setPagination,
          companyId: getQueryStringKeys(history.location.search, 'companyId')
        }}
        commonParentProps={commonParentProps}
        component={Content}
        pagination={pagination}
        setPagination={setPagination}
      />
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    paginateList: state.scoreCard.reportList,
    chartsData: state.scoreCard.chartsData,
    vehicles: state.vehicles,
    filters: state.filters,
  };
};

const mapDispatch = (dispatch) => {
  return {
    fetchVehicles: (data) => dispatch.vehicles.fetchData(data),
    fetchReportList: (data) => dispatch.scoreCard.fetchReportList(data),
    fetchReportChart: (data) => dispatch.scoreCard.fetchReportChart(data),
    downloadDocument: (data) => dispatch.reports.downloadReport(data),
  };
};

export default connect(mapState, mapDispatch)(ScorecardReport);
