import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`

`;
const SearchWrapper = styled(Parent.SearchWrapper)`

`;
const TopWrapper = styled(Parent.TopWrapper)`
flex: 0 0 60%;
position: relative;
.breadcrumbClass{
    position: absolute;
    bottom: -39px;
    width: 100%;
    left: -45%;
  };

  .MuiAvatar-root.MuiAvatar-rounded {
    margin-left: 40px !important;
  };
`;
const TableWrapper = styled(Parent.TableWrapper)`
${({ theme }) => `
.quickFilter{
    display: block;
    height: 20px;
    background: ${theme.colors.LINK_COLOR};
}
padding: 0 ;
.MuiPaper-root {
    padding: 0 3%;
}

`}
`;

const LabelWrap = styled(Parent.LabelWrap)`

`;
const Styled = {
    Wrapper,
    SearchWrapper,
    TopWrapper,
    TableWrapper,
    LabelWrap,
  };
  export default Styled;
