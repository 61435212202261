import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Parent from './style';
const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
  .editInspection .addedValues .values {
        color: rgba(0,0,0,0.6) !important;
        word-break: break-word;
        white-space: pre-wrap;
        min-width: auto;
        padding-bottom: 8px;
      }
    form {
      h2{
        font-size: 18px !important;
      }
      .react-datepicker__input-container input{
        padding-left: 0 ;
      }
      .basic-multi-select.select--is-disabled{
        opacity:1;
         .select__control{
        border-bottom: none !important;
        .select__indicators{
          display: none;
        }
      }
      }
      .MuiInputBase-root.Mui-disabled{
        border-bottom: none !important;
      }
      .MuiInputBase-input.Mui-disabled {
        opacity: 0.6;
    }
      textarea {
        padding-left: 0;
      }
    }
  `}
`;
const LeftWrapper = styled(Parent.LeftWrapper)`
.partsWrapper .addPartsPlus {
  color:  #000;
}
`;
const RightWrapper = styled(Parent.RightWrapper)`

`;
const documentWrapper = styled(Parent.documentWrapper)`
${({ theme }) => `

`}
`;
const submitUser = styled(Parent.submitUser)`
${({ theme }) => `

`}
`;
const PolicyProcedure = styled(Parent.PolicyProcedure)`
`;
const Styled = {
  Wrapper,
  RightWrapper,
  LeftWrapper,
  submitUser,
  documentWrapper,
  PolicyProcedure
};
export default Styled;
