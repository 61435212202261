import {
    customPolicyUploadApi,
    fetchCustomPolicyApi,
    customPolicyDeleteApi,
    customPolicyEditApi
  } from '../../../../config/apis';
  const initialState = {
    customPolicyList: [],
  };
  const isEs = () =>
    localStorage.getItem(window.$environment?.SERVICE_PROVIDER + 'Language') ===
    'es';
  export const customPolicy = {
    state: { ...initialState },
    reducers: {
      listSuccess(state, payload) {
        return {
          ...state,
          customPolicyList: payload,
        };
      },
      saveSuccess(state, payload) {
        return { ...state };
      },
      saveFailed(state) {
        return { ...state };
      },
      listFailed() {
        return { ...initialState };
      },
      updateDocumetList(state, response) {
        if (response?.isDelete) {
          return {
                ...state,
                customPolicyList: state.customPolicyList.filter(
                  (item) => item.id !== response?.id
                )
            }
        }
      },
    },
    effects: (dispatch) => ({
      async fetchData(payload) {
        try {
          const response = await dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              api: fetchCustomPolicyApi,
              data: { ...payload.data }
            },
            id: payload.apiId
          });
          response && this.listSuccess(response);
        } catch (error) {
          this.listFailed();
        }
      },
      async saveData(payload) {
        try {
          const response = await dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              api: {
                 url:  `${customPolicyUploadApi?.url}?language=${payload?.param?.language || ''}&name=${payload?.param?.name || ''}`,
                 type: customPolicyUploadApi.type,
                 isFormData: customPolicyUploadApi.isFormData
              },
              data: payload.data.data
            },
            id: payload.apiId,
            callback: payload.callback
          });
          response['name'] = payload?.data?.param?.name || null;
          response && this.fetchData({ data: {} });
        } catch (error) {
          this.saveFailed();
        }
      },
      async deleteData(payload, rootState) {
        try {
          const response = await dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              api: {
                ...customPolicyDeleteApi,
                url: customPolicyDeleteApi.url.replace('{id}', payload.data.id)
              }
            },
            callback: payload.callback,
            id: payload.id
          });
          response && payload.callback('success', isEs() ? 'Eliminado' :'Deleted');
          this.updateDocumetList({
            isDelete: true,
            id: payload.data.id,
            type: payload.data.type
          });
          //remove item
        } catch (error) {
          //handle error
        }
      },
      async editData(payload) {
        try {
          const response = await dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              api: {
                 url:  `${customPolicyEditApi?.url}?id=${payload?.param?.id || ''}&language=${payload?.param?.language || ''}&name=${payload?.param?.name || ''}`,
                 type: customPolicyEditApi.type,
                 isFormData: !!payload?.data?.data
              },
              data: payload.data.data
            },
            id: payload.apiId,
            callback: payload.callback
          });
          response['name'] = payload?.data?.param?.name || null;
          response && this.fetchData({ data: {} });
        } catch (error) {
          this.saveFailed();
        }
      },
      async downloadCustomPolicy(payload) {
        try {
          const getApi = await import(
            `../../../../config/apis/${window.$environment.SERVICE_PROVIDER}`
          );
          const apiObject = getApi.default('getDocumentTokenApi');
          const response = await dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              api: apiObject
            },
            id: payload.apiId
          });
          response && window.open(`${window.$environment.BE_BASE_URL}/document/open/${payload.data}?token=${response}`);
        } catch (error) { }
      }
    })
  };
  