import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
.filterWrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  form {
    width: 100% !important;
  }
}
    position: relative;
    .CSAForms {
        justify-content: flex-start;
        .MuiFormLabel-root{
          padding-bottom: 0px !important;
        }
        .vertical-button {
          .MuiBox-root:first-child {
            margin-right: 14px;
          }
          .MuiButtonBase-root {
            margin-top: 24px;
            padding-left: 0;
            padding-right: 0;
            .MuiButton-label {
              padding: 2px 22px;
            }
          }
        }
        .MuiBox-root {
          padding-top: 10px;
        }
      }
`;

const Styled = { Wrapper };

export default Styled;
