import styled from 'styled-components';
import Box from '@material-ui/core/Box';
const ParentWrapper = styled(Box)``
const Wrapper = styled(Box)`
padding: 0;
form {
  padding-bottom: 2%;
 padding-top: 1%;
  .MuiFormLabel-root {
   color: #373737 !important;
   font: bold 12px "Helvetica Neue", Helvetica, Arial, sans-serif!important;
  }
  .editForms {
    margin-bottom: 30px;
    padding: 0 1%;
  }
  .annotationField{
    padding: 0 1%;
  }
}
`;
const ChartWrapper = styled(Box)`
  ${({ theme }) => `
  `}
`;
const LabelWrap = styled(Box)`
${({ theme }) => `
  p {
    font-size: 12px;
    margin-bottom: 0;
    background: -webkit-linear-gradient(top, #d0d8db 0%, #b3bec3 100%);
    padding: 8px 30px;
    padding-left: 1%;
    border-top: 2px solid #000;
    font-weight: bold;
    color: #42505a;
  }
  `}
`;
const StatusModifier = styled(Box)`
`;
const CancelSaveWrapper = styled(Box)`
  ${({ theme }) => `
  padding: 0 1%;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex; 
  justify-content: flex-end;
  @media (max-width: ${theme.breakpoints.values.sm}px) {
    justify-content: center;
  }
  .MuiBox-root .MuiButtonBase-root[type="reset"]{
      height: 43px !important;
      background: ${theme.colors.WHITE} none repeat scroll 0 0!important;
      border: 2px solid;
      color: #343E48 !important;
      font-family: ${theme.palette.titleFont};
      font-size: 23px !important;
      border-radius: 0;
  }
  .MuiBox-root .MuiButtonBase-root[type="submit"]{
    height: 45px !important;
    background: #C92100 none repeat scroll 0 0!important;
    border: 2px solid;
    font-family: ${theme.palette.titleFont};
    font-size: 23px !important;
    border-radius: 0;
}

  width:100%;
  &:first-child {
    margin-right: 8px;
  }
  .MuiBox-root:first-child {
    margin-right: 3px;
  }
  .MuiBox-root {
    width: auto;
    min-width: 117px;
    margin-right:0;
  }
  `}
`;
const Editnodata = styled(Box)`
  ${({ theme }) => `
  padding-left: 21%;
  .messageSection{
    color: ${theme.colors.LINK_COLOR};
    padding-bottom: 11px;
    padding-top: 10px;
    font-size: 20px;
  }
  
  `}
`;
const Styled = { Wrapper, ChartWrapper, LabelWrap, StatusModifier, CancelSaveWrapper, Editnodata, ParentWrapper };
export default Styled;
