import React from 'react';
import FileUpload from '../Base';
import { FooterContainer, FooterContacts, FooterCopy } from './style';

const Index = (props) => {
  return (
    <FileUpload
      {...props}
      FooterContainer={FooterContainer}
      FooterContacts={FooterContacts}
      FooterCopy={FooterCopy}
    />
  );
};

export default Index;
