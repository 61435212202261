import React, { lazy, useState } from 'react';
import ViewForm from './Form';
import { hasValue, hasEnabledFeature } from '@/utils/utils';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));

const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const DatePopup = lazy(() =>
  import(`@/components/modules/DatePopup/${layout}`)
);
const TopActions = ({
  apiStatus,
  fetchLogBookView,
  i18n,
  viewApiId,
  history,
  callback,
  drivers,
  dates,
  unSignedDates,
  logBook,
  currentDriver,
  fetchLogbookBreadcrumb,
  fetchLogbookBreadcrumbApiId,
  setFormValues,
  formValues,
  setNewDate,
  getDocumentToken,
  handleLogBookStatus,
  clearApiStatus,
  dailyLogDownloadData,
  Styled
}) => {
  const [isBulkDownloadClicked, setIsBulkDownloadClicked] = useState(false);

  const hasLogBookApiStatus = hasValue(apiStatus.status, 'api', viewApiId);

  return (
    <Styled.LogBookContainer>
      {hasLogBookApiStatus && hasLogBookApiStatus.errorMessage && (
        <Message
          type="error"
          message={i18n.t(hasLogBookApiStatus.errorMessage)}
        />
      )}
      <div className="viewContainer">
        <div style={{flex: '0 0 52%'}}>
        <ViewForm
          apiStatus={apiStatus}
          fetchLogBookView={fetchLogBookView}
          history={history}
          callback={callback}
          drivers={drivers}
          dates={dates}
          unSignedDates={unSignedDates}
          currentDate={logBook?.selectedDailyLog?.day || ''}
          currentDriver={currentDriver}
          fetchLogbookBreadcrumb={fetchLogbookBreadcrumb}
          fetchLogbookBreadcrumbApiId={fetchLogbookBreadcrumbApiId}
          setFormValues={setFormValues}
          formValues={formValues}
          changedDate={(e) => setNewDate(e)}
          getDocumentToken={getDocumentToken}
        />
        </div>
        {hasEnabledFeature('bulkDownloadEnabled') && (
          <Button
            className="buttonBulkDownload"
            type={'submit'}
            label={i18n.t('common.bulkDownloadReport')}
            onClick={() => {
              if (window.$environment.SERVICE_PROVIDER === 'spireon') {
                setIsBulkDownloadClicked(!isBulkDownloadClicked);
              } else {
                setIsBulkDownloadClicked(true);
              }
            }}
          />
        )}
      </div>
      <DatePopup
        formId="bulkDownloadForm"
        isOpen={isBulkDownloadClicked}
        setIsOpen={setIsBulkDownloadClicked}
        exportHandler={handleLogBookStatus}
        apiStatus={apiStatus}
        clearApiStatus={clearApiStatus}
        data={logBook.violationsListDto}
        submit={dailyLogDownloadData}
        params={{ ...formValues }}
        noValidation
        callback={callback}
      />
    </Styled.LogBookContainer>
  );
};
export default TopActions;
