import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
  .panelHeading {
    color: #fff;
  }
`;

const Styled = {
  Wrapper
};
export default Styled;
