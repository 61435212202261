import styled from 'styled-components';

const Container = styled.div`
  text-align: center;
  img {
    width: 120px;
  }
`;

const SuccessIconComponent = { Container };
export default SuccessIconComponent;
