import React, { useEffect, lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import {
  getNewObject,
  generateDropdownOptions,
  loadPageConfig,
  isInsuracePortalUser,
  objectArrayFromSameField
} from '@/utils/utils';
const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.addVehicle[0].name}`));
const Content = lazy(() => import(`@/${config.addVehicle[1].name}`));
const AddVehicle = (parentProps) => {
  const {
    fetchVehicleData,
    fetchInsuranceVehicleData,
    getDocumentToken,
    regions,
    vehicle,
    removeApiStatus,
    clearReceipt,
    fetchCustomDvir,
    fetchCompanyList
  } = parentProps;

  const history = useHistory();
  const i18n = useTranslation();
  const theme = useTheme();

  const commonParentProps = { history, i18n, theme };
  const fetchVehicleDataApi = 'fetchVehicleData';
  const fetchInsuraceVehicleDataApi = 'fetchInsuranceVehicleData';
  const getDocumentTokenApiId = 'getDocToken';

  useEffect(() => {
    fetchCustomDvir({
      apiId: 'fetchCustomDvir'
    });
    isInsuracePortalUser() ? fetchInsuranceVehicleData({ apiId: fetchInsuraceVehicleDataApi }) : fetchVehicleData({ apiId: fetchVehicleDataApi });
    getDocumentToken({ apiId: getDocumentTokenApiId });
    fetchCompanyList({ apiId: 'fetchFleetCompanies' });
    removeApiStatus();
    clearReceipt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let object = { ...disabledCriteria };
    if (vehicle?.accessLevel < 1) {
      object.regionId = true;
      object.terminalId = true;
      object.departmentId = true;
      object.subDepartmentId = true;
    } else if (vehicle?.accessLevel < 2) {
      object.regionId = true;
      object.terminalId = true;
      object.departmentId = true;
      object.subDepartmentId = false;
    } else if (vehicle?.accessLevel < 3) {
      object.regionId = true;
      object.terminalId = true;
      object.departmentId = false;
      object.subDepartmentId = false;
    } else if (vehicle?.accessLevel < 4) {
      object.regionId = true;
      object.terminalId = false;
      object.departmentId = false;
      object.subDepartmentId = false;
    } else {
      object.regionId = false;
      object.terminalId = false;
      object.departmentId = false;
      object.subDepartmentId = false;
    }
    setDisabledCriteria(object);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicle?.accessLevel]);

  const [disabledCriteria, setDisabledCriteria] = useState({
    regionId: false,
    terminalId: false,
    departmentId: false,
    subDepartmentId: false
  });
  const recallUpdate = (company) => {
    fetchInsuranceVehicleData({ apiId: fetchInsuraceVehicleDataApi, data: {
      company,
      recall: true
    } });
  }
  return (
    <>
      <RenderComponent
        {...config.addVehicle[0]}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        component={Title}
      />
      <RenderComponent
        {...config.addVehicle[1]}
        parentProps={parentProps}
        recallUpdate={recallUpdate}
        commonParentProps={commonParentProps}
        component={Content}
        radiusCompliances = {objectArrayFromSameField(vehicle?.radiusCompliances )}
        regions={generateDropdownOptions(
          isInsuracePortalUser() && regions?.data?.regions?.length
            ? regions.data.regions
            : vehicle.regions,
          'regionName',
          'id'
        )}
        states={objectArrayFromSameField(vehicle?.states)}
        terminals={generateDropdownOptions(
          regions?.data?.terminals?.length
            ? regions.data.terminals
            : vehicle.terminals,
          'terminalName',
          'terminalId'
        )}
        departments={generateDropdownOptions(
          regions?.data?.departments?.length
            ? regions.data.departments
            : !regions?.data?.departments?.length &&
              regions?.data?.terminals?.length
            ? []
            : vehicle.departments,
          'departmentName',
          'departmentId'
        )}
        subDepartments={generateDropdownOptions(
          regions?.data?.subDepartments?.length
            ? regions.data.subDepartments
            : !regions?.data?.subDepartments?.length &&
              regions?.data?.departments?.length
            ? []
            : vehicle.subDepartments,
          'subDepartmentName',
          'subDepartmentId'
        )}
        mechanics={generateDropdownOptions(
          vehicle.mechanics,
          'firstName',
          'id'
        )}
        disabledCriteria={disabledCriteria}
      />
    </>
  );
};

const RenderComponent = ({
  name,
  props,
  commonProps,
  title,
  parentProps,
  commonParentProps,
  component,
  ...rest
}) => {
  const Component = component;
  let newProps = props?.length ? getNewObject(parentProps, props) : {};
  newProps = {
    ...newProps,
    ...(commonProps?.length ? getNewObject(commonParentProps, commonProps) : {})
  };
  return <Component {...rest} {...newProps} />;
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    regions: state.regions,
    documents: state.documents,
    vehicle: state.vehicleAdd,
    logBook: state.logBook,
    receipt: state.viewReport,
    fleetCompanies: state.fleetCompanyList.companyList,
    customDvir: state.customDvir,

  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchTerminals: (data) => dispatch.regions.fetchTerminals(data),
    fetchDepartments: (data) => dispatch.regions.fetchDepartments(data),
    fetchSubDepartments: (data) => dispatch.regions.fetchSubDepartments(data),
    fetchVehicleData: (data) => dispatch.vehicleAdd.fetchData(data),
    fetchInsuranceVehicleData: (data) => dispatch.vehicleAdd.fetchDataInsuranceUser(data),
    saveVehicleData: (data) => dispatch.vehicleAdd.saveData(data),
    getLatLongAndSave: (data) => dispatch.vehicleAdd.fetchLatLongSave(data),
    isValidVin: (data) => dispatch.vehicleAdd.isValidVin(data),
    saveDocument: (data) => dispatch.vehicleAdd.saveDocument(data),
    deleteDocument: (data) => dispatch.vehicleAdd.deleteDocument(data),
    getRegions: (data) => dispatch.regions.fetchRegionsByCompany(data),
    fetchCompanyList: (data) => dispatch.fleetCompanyList.fetchCompanyList(data),
    fetchCountData: (data) => dispatch.dashboard.fetchCountData(data),
    downloadDocument: (data) => dispatch.viewReport.downloadReport(data),
    getDocumentToken: (data) => dispatch.logBook.documentToken(data),
    removeApiStatus: () => dispatch.vehicleAdd.removeApiStatus(),
    clearReceipt: () => dispatch.viewReport.clearReceipt(),
    fetchCustomDvir: (data) => dispatch.customDvir.fetchData(data),

  };
};

export default connect(mapState, mapDispatch)(AddVehicle);
AddVehicle.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  getRegions: PropTypes.func.isRequired,
  fetchCompanyList: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  fetchRegions: PropTypes.func.isRequired,
  fetchTerminals: PropTypes.func.isRequired,
  fetchDepartments: PropTypes.func.isRequired,
  saveDocument: PropTypes.func.isRequired,
  deleteDocument: PropTypes.func.isRequired,
  fetchVehicleData: PropTypes.func.isRequired,
  fetchInsuranceVehicleData: PropTypes.func.isRequired,
  saveVehicleData: PropTypes.func.isRequired,
  getLatLongAndSave: PropTypes.func.isRequired,
  fetchSubDepartments: PropTypes.func.isRequired
};
