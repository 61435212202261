import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import { getImagePath } from '../../../../../utils/utils';
const Wrapper = styled(Box)`
${({ theme }) => `
  background: url(${getImagePath('onBoarding.png')}) right top no-repeat;
  color: #fff;
  height: 100%;
  .welcomeText {
      width: 40%;
      padding-top: 24%;
      padding-bottom: 5%;
      @media (max-width: ${theme.breakpoints.values.md}px) {
        width: 70%;
        padding-top: 10%;
      }
      .loginDetails {
        padding-top: 30px;
        padding-bottom: 30px;
      }
      .fieldWrapper:first-child {
        padding-bottom: 10px;
      }
      h1 {
        color: #ffffff;
        font-size: 60px;
        font-weight: 700;
      }
      h2 {
        color: #ffffff;
        font-size: 36px;
        font-weight: 300;
        line-height: 40px;
        span {
            // font-family: "SF Pro Display - Bold";
            font-weight: 700;
            padding-right: 10px;
        }
      }
      h1, h2 {
          margin-top: 0;
          margin-bottom: 0;
      }
      .fieldWrapper {
        display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
        display: -ms-flexbox;  /* TWEENER - IE 10 */
        display: -webkit-flexbox;
        display: flex;
        .label {
            min-width: 130px;
        }
      }
      .password span {
        width:7px;
        height: 8px;
      }
      .fieldWrapper,
      .details {
        color: rgba(255, 255, 255, 0.67);
        font-size: 16px;
        font-weight: 300;
      }
      .info {
          color: #fff;
          padding-left: 10px;
          img {
            padding-left: 10px;
          }
      }
  }
  `}
`;

const Styled = { Wrapper };

export default Styled;