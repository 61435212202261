import styled from 'styled-components';
import Parent from './style';

const FilterSubmit = styled(Parent.FilterSubmit)`
`;
const Filter = styled(Parent.Filter)`
.filterFields {
    margin-bottom: 5px;
}
`;
const Department = styled(Parent.Department)`
`;

const Styled = { FilterSubmit, Filter };
export default Styled;
