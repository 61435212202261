import React from 'react';
import PropTypes from 'prop-types';

const CloseButton = ({ onClick, icon, className, CloseWrapper }) => {
  return (
    <CloseWrapper
      className={className}
      onClick={() => {
        onClick();
      }}
      icon={icon}
    />
  );
};

CloseButton.defaultProps = {
  className: ''
};
CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default CloseButton;
