import * as parent from '../variables';

export const colors = {
  ...parent.colors,
  PRIMARY_MAIN: '#fff',
  BACKGROUND: '#efefef',
  HEADER_COUNT_TEXT: '#373737',
  PRIMARY_CONTRAST: '#373737',
  SECONDARY_MAIN: '#1589ee',
  PUBLIC_HEADERCOLOR: '#061c3f',
  LANGUAGE_SWITCH: '#1589ee',
  HEADER_TEXT: '#f4f6f9',
  BODY_BACKGROUND: '#fff',
  MODAL_BACKGROUND: '#fff',
  MODAL_BORDER: '#d9d9d9',
  MODAL_LINK: '#1589ee',
  LINK_COLOR: '#1589ee',
  HEADER_COLOR: '#1589ee',
  CHECKBOX_TICK: '#000',
  ACTIVE_ROW:'rgba(21,137,238,0.31)',
  PAGINATION_BACKGROUND: '#fff',
  PAGINATION_COLOR: '#000',
  SELECTED_AREA: 'rgba(32, 177, 232, 0.3)',
  BACKGROUND_MENU:'#f4f6f9',
  RANGE_PICKER: {
    CELL_BG: '#dfdfdf',
    CELL_HOVER_BG: '#bbbbbb'
  },
  INPUT_BORDER: '#ccc',

  USER_LOGO_BACKGROUND: "#fff",
  USER_LOGO_COLOR: "#1589ee"

};
export const fonts = ['Source Sans Pro', 'sans-serif'].join(',');
export const filterFont = '14px/1.428 Source Sans Pro';
export const headerFont = '14px';
export const formlabelFont = '18px';
export const subTitle = '14px';
export const titleLabel= '20px';
export const buttonFont = '22px';
export const inputFont = '18px';
export const checkboxFont = '18px';
export const addbuttonFont = '20px';
export const companyDataFont = '20px';
export const iftaReport = '15px';
export const inspectionInput = '17px';
export const inspectionresolution = '14px';