import React, { useState, lazy } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import AddEditRegion from './AddEditRegion';

import { getImagePath } from '@/utils/utils';
import { imageUrls, pageUrls } from '@/config/constants/keys';

const layout = window?.$environment?.CURRENT_LAYOUT;

const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));

const Modal = require(`@/components/UI/Modal/${layout}`);

const Region = ({
  region,
  history,
  callback,
  fleetManagers,
  serviceCenters,
  updateRegion,
  deleteRegion,
  Styled,
  isCrudRestricted,
  neglectAccessLevel
}) => {
  const { i18n } = useTranslation();
  const match = useParams();
  const [addOpen, setAddOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [AddEditModalObject, setAddEditModalObject] = useState({
    header: '',
    content: () => null
  });
  const clickedAddEditModal = (e) => {
    setAddOpen(true);
  };
  const closeAddEditModal = (event) => {
    setAddOpen(false);
  };
  const [DeleteModalObject, setDeleteModalObject] = useState({
    header: '',
    content: () => null
  });
  const clickedDeleteModal = (e) => {
    setDeleteOpen(true);
  };
  const closeDeleteModal = (event) => {
    setDeleteOpen(false);
  };
  const editRegion = (region) => {
    setAddEditModalObject({
      header: '',
      content: () => {
        return (
          <AddEditRegion
            closeModal={closeAddEditModal}
            callback={callback}
            saveRegion={updateRegion}
            fleetManagers={fleetManagers}
            serviceCenters={serviceCenters}
            currentItem={region}
            isEdit
            isCrudRestricted={isCrudRestricted}
            neglectAccessLevel={neglectAccessLevel}
          />
        );
      }
    });
    clickedAddEditModal();
  };
  return (
    <Styled.RegionWrapper
      className={match.id == region.id ? 'active-region' : ''}
    >
      <div
        className="region"
        onClick={() =>
          history.push(pageUrls.departmentsList.replace(':id', region.id))
        }
      >
        {region?.regionName}
      </div>
      {(isCrudRestricted || neglectAccessLevel) && (
        <div className="iconSection">
          <Icon
            src={getImagePath(imageUrls.editDepartment)}
            onClick={() => editRegion(region)}
          />
          <Icon
            src={getImagePath(imageUrls.deleteDepartment)}
            onClick={() => {
              setDeleteModalObject({
                content: () => (
                  <div className="deleteParent">
                    <div className="imageSection">
                      <img
                        src={getImagePath(imageUrls.confirmIcon)}
                        alt="Are you sure"
                      />
                    </div>
                    <h2 className="departmentConfirm">
                      {' '}
                      {i18n.t('common.sureConfirm')}{' '}
                    </h2>
                    <div className="deletePopupBtn">
                      <div className="cancelBtn">
                        <Button
                          label={i18n.t('common.cancel')}
                          type={'reset'}
                          onClick={() => {
                            closeDeleteModal();
                          }}
                        ></Button>
                      </div>
                      <div className="deletebtn">
                        <Button
                          label={i18n.t('common.deletePopup')}
                          onClick={() => {
                            deleteRegion({
                              apiId: 'deleteRegion',
                              data: { data: { id: region.id } },
                              callback: callback,
                              i18n: i18n
                            });
                            closeDeleteModal();
                          }}
                        ></Button>
                      </div>
                    </div>
                  </div>
                )
              });
              clickedDeleteModal();
            }}
          />
        </div>
      )}
      <Modal.default
        open={addOpen}
        setOpen={setAddOpen}
        data={AddEditModalObject}
        onCloseModal={closeAddEditModal}
        background="black"
        withCloseIcon
      />
      <Modal.default
        open={deleteOpen}
        setOpen={setDeleteOpen}
        data={DeleteModalObject}
        onCloseModal={closeDeleteModal}
        // background="black"
        isSuccess
        // withCloseIcon
      />
    </Styled.RegionWrapper>
  );
};

export default Region;

Region.propTypes = {
  region: PropTypes.object.isRequired
};
