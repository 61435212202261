import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Parent from './style';
const Wrapper = styled(Parent.Wrapper)`
padding-bottom: 4%;
.section-wrapper{
  background-color: #fff;
  padding: 2% 3%;
  margin-bottom: 30px;
  border-radius: 5px;
  .subheaderSection{
    padding-left: 0 !important;
  }
}
`;
const LeftWrapper = styled(Parent.LeftWrapper)`
${window.$environment.WHITE_THEME &&  `
background-color: #fff;
        padding: 2% 3%;
        margin-bottom: 30px;
        border-radius: 5px;
        .subheaderSection {
          padding-left: 0 !important;
          font-weight: 600;
        }
`}

 
`;
const RightWrapper = styled(Parent.RightWrapper)`

${window.$environment.WHITE_THEME &&  `
background-color: #fff;
        padding: 2% 3%;
        margin-bottom: 30px;
        border-radius: 5px;
        .subheaderSection {
          padding-left: 0 !important;
          font-weight: 600;
        }
`}
`;
const documentWrapper = styled(Parent.documentWrapper)`
${({ theme }) => `
  ul {
    li {
      .MuiGrid-container .MuiAvatar-rounded {
        width: 22px;
        height: 22px;
      }
    }
  }
  `}
`;
const submitUser = styled(Parent.submitUser)`
`;
const DVIRRightWrapper = styled.div`
margin-top: 72px;
`;
const Styled = {
  Wrapper,
  RightWrapper,
  LeftWrapper,
  documentWrapper,
  submitUser,
  DVIRRightWrapper
};
export default Styled;
