import React, { lazy } from 'react';
import { getAuthDetails, hasRestrictedRole } from '@/utils/utils';

const layout = window?.$environment?.CURRENT_LAYOUT;
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));

const TopActions = ({
  i18n,
  subscriptionTier,
  callback,
  apiId,
  Styled,
  upgrade
}) => {
  return (
    <>
      {getAuthDetails() && !hasRestrictedRole(['ADMIN']) && (
        <FormStyle.default.ButtonsWrapper>
          <Button
            type="submit"
            className="buttonStyle"
            label={i18n.t('common.upgrade')}
            onClick={() =>
              upgrade({ apiId, data: { subscriptionTier }, callback: callback })
            }
          />
        </FormStyle.default.ButtonsWrapper>
      )}
    </>
  );
};
export default TopActions;
