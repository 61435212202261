import styled from 'styled-components';
import Parent from './style';
const Wrapper = styled(Parent.Wrapper)``;
const LoginWrapper = styled(Parent.LoginWrapper)`
  ${({ theme }) => `
.forgotPassword {
  .MuiButtonBase-root {
    border: none;
    height: 43px!important;
    font-size: 23px !important;
    background: #C92100 !important;
    font-family: ${theme.palette.titleFont};
    padding: 5px 15px !important;
  }
  .MuiFormLabel-root {
    flex: 0 0 24%;
    color: ${theme.colors.WHITE} !important;
  }
  .MuiBox-root:first-child {
   .MuiFormLabel-root {
      flex: 0 0 24%;
      color: ${theme.colors.WHITE};
    }
    MuiFormLabel-root + div {
      flex: 0 0 71%;
    }
  }
  form {
    margin-top: 1%;
    margin-left: 3%;
    .MuiBox-root:last-child {
      justify-content: center;
    }
    input,
    .MuiInputBase-root {
      background-color: ${theme.colors.INPUT_BACKGROUND} !important;
    }
    button {
      margin-top: 13px;
      padding: 2px 44px;
    }
  }
  .MuiButton-root:hover {
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);
    outline: none;
    cursor: pointer;
  }
}
`}
`;
const LoginHeader = styled(Parent.LoginHeader)`
  ${({ theme }) => `
  justify-content: flex-start;
  padding-left: 25%;
  h1 {
  font-family: ${theme.palette.titleFont};
  }
  .loginHeader {
    background: transparent !important;
    padding-left: 0 !important;
    font-size: 30px !important;
  }
  .registrationHeader{
    flex: 0 0 71%;
  }
  `}
`;
const PasswordHandler = styled(Parent.PasswordHandler)`
${({ theme }) => `
.MuiTypography-colorPrimary {
    font-family: tahoma;
}
.Checkbox{
    top: 1px !important;
}
.remember {
    margin-top: 0px;
    label {
        font-family: tahoma;
        font-size: 18px !important;
        font-weight: normal !important;
    }
}
.normalCheckbox {
    svg {
    width: 20px!important;
    height: 20px!important;
    background: transparent !important;
    border: 2px solid #fff;
    }
    .Checkbox.checked .tick{
        stroke: #fff !important;
        stroke-width: 3;
    }
}
`}
`;
const userNameWrapper = styled(Parent.userNameWrapper)`
  ${({ theme }) => `
  .MuiFormLabel-root{
    font-size: 12px !important;
    color: ${theme.colors.WHITE} !important;
    font-family: ${(theme.palette || {}).fontFamily} !important;
  }
`}
`;
const passwordWrapper = styled(Parent.passwordWrapper)`
  ${({ theme }) => `
.MuiInputBase-input {
  background-color: transparent !important;
}
.MuiFormLabel-root{
    font-size: 12px !important;
    color: ${theme.colors.WHITE} !important;
    font-family: ${(theme.palette || {}).fontFamily};
  }
`}
`;
const LoginButtonWrapper = styled(Parent.LoginButtonWrapper)`
${({ theme }) => `
.MuiBox-root {
    .MuiButtonBase-root,
    .MuiButton-root{
        height: 43px!important;
        font-size: 23px !important;
        background: #C92100 !important;
        font-family: ${theme.palette.titleFont};
    }
}
`}
`;
const mainWrapper = styled(Parent.mainWrapper)``;
const LoginError = styled(Parent.LoginError)``;

const Styled = {
  Wrapper,
  LoginWrapper,
  LoginHeader,
  PasswordHandler,
  userNameWrapper,
  passwordWrapper,
  LoginButtonWrapper,
  mainWrapper,
  LoginError
};

export default Styled;
