import PropTypes from 'prop-types';
import React, { cloneElement, lazy, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';

import { removeLocalStorage } from '@/services/storage';
import {
  COOKIE_AUTH_DETAILS,
  COOKIE_CLIENT_AUTH_DETAILS,
  imageUrls,
  pageUrls
} from '../../../config/constants/keys';
import { deleteCookie } from '../../../services/cookie';
import { getAuthDetails, getImagePath } from '../../../utils/utils';
import Styled from './privateStyle';

const layout = window?.$environment?.CURRENT_LAYOUT;

const ErrorIcon = lazy(() => import(`../../ErrorIcon/${layout}`));
const SuccessIcon = lazy(() => import(`../../SuccessIcon/${layout}`));
const Button = lazy(() => import(`../../UI/Button/${layout}`));
const Header = lazy(() => import(`../../Header/${layout}`));
const LeftMenu = lazy(() => import(`../../LeftMenu/${layout}`));
const Loader = require(`../../Loader`);
const Modal = require(`../../UI/Modal/${layout}`).default;

const PrivateWrapper = ({
  children,
  layout,
  apiStatus,
  clearRedux,
  setAuth,
  showFilterPopup,
  folderPath,
  handleLogout
}) => {
  const theme = useTheme();
  const [open] = useState(true);
  const [leftMenuList, setLeftMenuList] = useState([]);
  const history = useHistory();
  const [loaderPopupOpen, setLoaderPopupOpen] = React.useState(false);
  const [openCallback, setOpenCallback] = React.useState(false);
  const i18n = useTranslation();

  const [ModalListObject, setModalListObject] = useState({
    header: '',
    content: () => (
      <Button
        type="submit"
        label={i18n.t('common.ok')}
        onClick={() => setOpenCallback(false)}
      />
    )
  });
  const [isError, setIsError] = React.useState(false);
  //Callback function to be invoked after an api call
  const callback = (type, message, callbackFunction, errors, optionButton, isListErr = false) => {
    if (message) {
      let keys = Object.keys(message);
      let items = Object.values(message);
      if (typeof message !== 'string' && keys.length > 1) {
        setIsError(true);
      } else {
        setIsError(false);
      }
      setModalListObject({
        header: '',
        content: () => (
          <div className="successpopup">
            {type === 'error' ? (
              <ErrorIcon />
            ) : type === 'warningfmcsa' ? (
              <img
                src={getImagePath(imageUrls.confirmIcon)}
                alt="Are you sure"
              />
            ) : (
                  <SuccessIcon />
                )}
            {typeof message === 'string' ? (
              <h2> {i18n.t(message)} </h2>
            ) : keys.length === 1 && items[0].length === 1 && isListErr ? (
              <div className="errorSectionlist">
                {keys.map((item, index) => {
                  return (
                    <div className="listItem">
                      <span>Line No:{item}</span>
                      <ul>
                        {message[item].map((err, index) => {
                          return <li>{err}</li>;
                        })}
                      </ul>
                    </div>
                  );
                })}
              </div>
            ) : keys.length === 1 && items[0].length === 1 ? (
              <h2> {i18n.t(items)} </h2>
            ) : keys.length === 1 && items[0].length !== 1 ? (
              <div className="errorSectionlist">
                {keys.map((item, index) => {
                  return (
                    <div className="listItem">
                      <span>Line No:{item}</span>
                      <ul>
                        {message[item].map((err, index) => {
                          return <li>{err}</li>;
                        })}
                      </ul>
                    </div>
                  );
                })}
              </div>
            ) : type !== 'error' && message?.error?.length === 0 ? (
              <h2> {i18n.t('common.dataImportSuccess')} </h2>
            ) : (
                      <div className="errorSectionlist">
                        {keys.map((item, index) => {
                          return (
                            <div className="listItem">
                              <span>Line No:{item}</span>
                              <ul>
                                {message[item].map((err, index) => {
                                  return <li>{err}</li>;
                                })}
                              </ul>
                            </div>
                          );
                        })}
                      </div>
                    )}
                 {
                optionButton ? <div className="deletePopupBtn">
                <div className="cancelBtn">
                  <Button
                    label={i18n.t('Cancel')}
                    type="submit"
                    onClick={() => {
                      setOpenCallback(false);
                    }}
                  ></Button>
                </div>
                <div className="deletebtn">
                  <Button
                    type="submit"
                    label={i18n.t('common.deletePopup')}
                    onClick={() => {
                        callbackFunction()
                      }}
                    ></Button>
                  </div>
                </div> 
                :        
                <div className="deletePopupBtn">
                  <Button
                    type="submit"
                    label={i18n.t('common.ok')}
                    onClick={() => {
                      setOpenCallback(false);
                      callbackFunction && callbackFunction();
                      if (
                        type !== 'error' &&
                        window.location.href.includes('user') &&
                        window.location.href.includes('resetPassword') &&
                        message === 'Your profile was saved'
                      ) {
                        handleOnLogout();
                      }
                    }}
                  />
                </div>
              }
          </div>
        )
      });
      setOpenCallback(true);
    }
  };
  useEffect(() => {
    let isPageLoading = false;
    for (let index = 0; index < apiStatus.status.length; index++) {
      const element = apiStatus.status[index];
      if (element.isLoading) {
        isPageLoading = true;
      }
      if (element.errorMessage === 'Access denied') {
        setLoaderPopupOpen(false);
        deleteCookie(COOKIE_CLIENT_AUTH_DETAILS);
        deleteCookie(COOKIE_AUTH_DETAILS);
        clearRedux();
        setAuth(null);
        removeLocalStorage('currentLayout');
        window.location = pageUrls.login;
        return;
      }
    }
    isPageLoading ? setLoaderPopupOpen(true) : setLoaderPopupOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiStatus.status]);

  useEffect(() => {
    const basePath = `config/menu${folderPath}${window.$environment.SERVICE_PROVIDER}/${window.$environment.CURRENT_LAYOUT}`;
    let path = layout === 'authenticatedStyle'
      ? require(`@/${basePath}/resetPassword`):
      window.$environment.BASE_URL.includes(
      'https://spireoneld.gorillasafety'
    )
      ? require(`../../../${basePath}/leftMenu`)
      : require(`../../../${basePath}/production`);

      let reportsEnabled = getAuthDetails()?.enterpriseFeatureDto.reportsEnabled;
      let spireonTabs = getAuthDetails()?.enterpriseFeatureDto.spireonReportsEnabled;
      if(!reportsEnabled && !spireonTabs)path.default = path.default.filter(menu => menu.path !== "/report")
    setLeftMenuList(path.default);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderPath]);

  const bigDrawerWidth = '15.1%';
  const bigDrawerWidthNoUnit = '15.1';
  const smDrawerWidth = '6.1%';
  const smDrawerWidthNoUnit = '6.1';

  const sm = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const md = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const mdWidth = md ? smDrawerWidth : bigDrawerWidth;
  let width = sm ? bigDrawerWidth : mdWidth;
  const mdWidthNoUnit = md ? smDrawerWidthNoUnit : bigDrawerWidthNoUnit;
  let widthNoUnit = sm ? bigDrawerWidthNoUnit : mdWidthNoUnit;

  width = open ? width : '4.3%';
  widthNoUnit = open ? widthNoUnit : '4.3';

  const image = getImagePath(imageUrls.bg_image);
  const handleOnLogout = () => {
    const auth = getAuthDetails(false);
    if (auth) {
      handleLogout({
        apiId: 'logout',
        callback: (type, message) => {
          if (type === 'error') {
            callback(type, message);
          } else {
            setAuth(null);
            // history.push(pageUrls.login);
            window.location.href = pageUrls.login;
          }
        }
      });
    }else {
      window.location.href = pageUrls.login;
    }
  };
  const isRestrictedMode = window.location.pathname === pageUrls.resetPassword;
  return (
    <Styled.Wrapper>
      <Styled.LeftDrawer drawerwidth={width} widthnounit={widthNoUnit}>
        <Box className="menuItems">
          <Box id="header">Compliance</Box>

          {layout !== 'authenticatedWithoutMenu' && !window.location.href.includes('user/profile')&&(
            <LeftMenu
              needToolTip={!open}
              showFilterPopup={showFilterPopup}
              leftMenuList={leftMenuList}
              noAuthCheck={layout === 'authenticatedStyle'}
            />
          )}
        </Box>
        <Box id="poweredBy">
          <span>Powered by:</span>
          <img src={getImagePath(imageUrls.poweredBy)} alt="logo" />
        </Box>
      </Styled.LeftDrawer>

      <Styled.Main widthnounit={widthNoUnit} drawerwidth={width} image={image}>
        {layout !== 'authenticatedWithoutMenu' &&
          window.$environment.BASE_URL.includes(
            'https://spireoneld.gorillasafety'
          ) && (
            <Header
              history={history}
              handleLogout={handleLogout}
              callback={callback}
              isRestrictedMode={isRestrictedMode}
            />
          )}
        <Styled.Content>{cloneElement(children, { callback })}</Styled.Content>
      </Styled.Main>
      <Styled.FooterWrapper drawerwidth={width}></Styled.FooterWrapper>
      <Modal
        open={loaderPopupOpen}
        setOpen={setLoaderPopupOpen}
        data={{
          header: '',
          content: () => <Loader.default />
        }}
        isLoader
      />
      <Modal
        open={openCallback}
        setOpen={setOpenCallback}
        data={ModalListObject}
        isSuccess
        isError={isError}
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
      />
    </Styled.Wrapper>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests
  };
};
const mapDispatch = (dispatch) => {
  return {
    clearRedux: () => dispatch({ type: 'RESET_APP' }),
    handleLogout: (data) => dispatch.login.handleLogout(data)
  };
};

export default connect(mapState, mapDispatch)(PrivateWrapper);

PrivateWrapper.defaultProps = {
  children: null,
  auth: null,
  layout: ''
};
PrivateWrapper.propTypes = {
  children: PropTypes.element,
  auth: PropTypes.object,
  layout: PropTypes.string,
  clearRedux: PropTypes.func.isRequired,
  setAuth: PropTypes.func.isRequired
};
