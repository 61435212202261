import styled from 'styled-components';
import Parent from './style';
const Wrapper = styled(Parent.Wrapper)``;
const LoginWrapper = styled(Parent.LoginWrapper)`
  ${({ theme }) => `
.forgotPassword {
  .MuiButtonBase-root {
    border: none;
  }
  .MuiFormLabel-root {
    flex: 0 0 24%;
    color: ${theme.colors.WHITE};
  }
  .MuiBox-root:first-child {
   .MuiFormLabel-root {
      flex: 0 0 24%;
      color: ${theme.colors.WHITE};
    }
    MuiFormLabel-root + div {
      flex: 0 0 71%;
    }
  }
  form {
    margin-top: 1%;
    margin-left: 3%;
    .MuiBox-root:last-child {
      justify-content: center;
    }
    input,
    .MuiInputBase-root {
      background-color: ${theme.colors.INPUT_BACKGROUND} !important;
    }
    button {
      margin-top: 13px;
      padding: 2px 44px;
    }
  }
  .MuiButton-root:hover {
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);
    outline: none;
    cursor: pointer;
  }
}
.MuiFormLabel-root {
  font-size:  ${theme.palette.formlabelFont} !important;
}

`}
`;
const LoginHeader = styled(Parent.LoginHeader)`
  ${({ theme }) => `
  justify-content: flex-start;
  padding-left: 25%;
  .loginHeader {
    background-color: transparent !important;
    padding-left: 0 !important;
    white-space: nowrap;
  }
  .registrationHeader .registrationHeaderlink {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  `}
`;
const PasswordHandler = styled(Parent.PasswordHandler)`
a {
  white-space: nowrap;
}
.MuiBox-root {
  flex: 0 0 55% !important;
}
.normalCheckbox .tick {
  stroke: #fff !important;
}
.remember {
  flex: 0 0 44%;
}
`;
const userNameWrapper = styled(Parent.userNameWrapper)`
  ${({ theme }) => `
`}
`;
const passwordWrapper = styled(Parent.passwordWrapper)`
  ${({ theme }) => `
.MuiInputBase-input {
  background-color: transparent !important;
}
`}
`;
const LoginButtonWrapper = styled(Parent.LoginButtonWrapper)``;
const mainWrapper = styled(Parent.mainWrapper)``;
const LoginError = styled(Parent.LoginError)``;

const Styled = {
  Wrapper,
  LoginWrapper,
  LoginHeader,
  PasswordHandler,
  userNameWrapper,
  passwordWrapper,
  LoginButtonWrapper,
  mainWrapper,
  LoginError
};

export default Styled;
