import styled from 'styled-components';

const FilterSubmit = styled.div`
  .submitWrapper {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flexbox;
    display: flex; 
    justify-content: center;
    padding-bottom: 12px;
    .filtersave {
      margin-right: 15px;
    }
    #button {
      min-width: 116px;
    }
  }
`;
const Filter = styled.div`
  .filterLabel {
    flex: 0 0 40%;
  }
  .filterField {
    flex: 0 0 60%;
  }
  .filterFields {
    margin-bottom: 16px;
  }
`;
const Department = styled.div`
`;
const Styled = { FilterSubmit, Filter };

export default Styled;
