import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
padding: 2%;
border-top: 2px solid #000;
.partsName {
    font-size: 12px;
}
.rightWrapper .dvir-content-item-active .normalCheckbox svg{
    width: 16px !important;
    height: 16px !important;
}
.saveChanges .MuiButtonBase-root {
    padding: 0 10px !important;
}
.vertical {
    justify-content: flex-end;
    padding-top: 2px;
}
.MuiButtonBase-root {
    margin-top: 4px !important;
}
`;
const TopWrapper = styled(Parent.TopWrapper)`
 
`;
const TableWrapper = styled(Parent.TableWrapper)`
 
`;
const Styled = { Wrapper, TopWrapper, TableWrapper };

export default Styled;

