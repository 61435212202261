import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
  ${({ theme }) => `
  // padding: 3%;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  justify-content: space-between;
  @media (max-width: ${theme.breakpoints.values.lg}px) {
    flex-direction: column;
  }
  .vehicleWrap {
    padding: 0;
  }
  form {
    width: 100%;
    .MuiTypography-body1 {
      font-size: 18px;
    }
    .MuiInputBase-multiline {
      height: auto;
      padding: 0;
    }
    .MuiInputBase-inputMultiline {
      background-color: colors.BACKGROUND;
      border: none;
      padding: 5px;
    }
    .MuiFormGroup-root {
      flex-direction: row;
      .MuiFormControlLabel-root {
        margin-left: 0;
        margin-right: 0;
        .MuiButtonBase-root {
          width: auto;
          padding: 0;
        }
        .MuiTypography-root {
          padding-left: 6px;
          padding-top: 12px;
          min-width: 40px;
          font-size: 20px;
        }
      }
      svg.MuiSvgIcon-root:first-child {
        color: colors.BLACK;
      }
      .MuiSvgIcon-root {
        font-size: 100%;
      }
    }
  }
  ul {
    border: 2px solid ${theme.colors.LIGHT_BORDER};
    padding-left: 0;
    li {
      border-bottom: 0.5px solid ${theme.colors.PRIMARY_MAIN};
    }
  }
  `}
`;
const LeftWrapper = styled(Box)`
  ${({ theme }) => `
  flex: 0 0 48.5%;
  .trailerHeading {
    margin-top: 0;
  }
  .subheaderSection {
    padding: 11px 4%;
  }
  @media (max-width: ${theme.breakpoints.values.lg}px) {
    flex: 0 0 100%;
  }
  `}
`;
const RightWrapper = styled(Box)`
  ${({ theme }) => `
  flex: 0 0 48.5%;
  .documentsHeader {
    @media (min-width: ${theme.breakpoints.values.lg}px) {
      margin-top: 0;
    }
  }
  .subheaderSection {
    padding: 11px 4%;
  }
  @media (max-width: ${theme.breakpoints.values.md}px) {
    flex: 0 0 100%;
  }
  `}
`;

const documentWrapper = styled.div`
${({ theme }) => `
  ul {
    border: none;
    li {
      .MuiGrid-container .MuiAvatar-rounded {
        width: 25px;
        height: 25px;
      }
      a {
        width: 15%;
        padding-left: 8px;
        color: ${theme.colors.LINK_COLOR};
        text-transform: capitalize;
        cursor: pointer;
      }
      &:hover {
        background: none;
      }
    }
  }
  `}
`;
const submitUser = styled(Box)`
  .MuiBox-root {
    flex: 0 0 8%;
  }
  .MuiBox-root .MuiBox-root:first-child {
    margin-right: 2%;
  }
`;
const Styled = {
  Wrapper,
  RightWrapper,
  LeftWrapper,
  documentWrapper,
  submitUser
};
export default Styled;
