import React, { lazy } from 'react';
import PropTypes from 'prop-types';
// import Styled from './style';
import moment from 'moment'
const layout = window?.$environment?.CURRENT_LAYOUT;
const Label = lazy(() => import(`@/components/UI/Label/${layout}`));
const Link = lazy(() => import(`@/components/UI/Link`));

const Policies = ({ list, handleDownloadDoc, Styled }) => {
  return (
    <Styled.PolicyProcedure>
      <ul className="policyProcedure">
        {list.map((item) => (
          <Row item={item} handleDownloadDoc={handleDownloadDoc} />
        ))}
      </ul>
    </Styled.PolicyProcedure>
  );
};
const Row = ({ item, handleDownloadDoc }) => {
  const downloadDoc = (id) => {
    let str = id;
    let n = str.lastIndexOf('/');
    let result = str.substring(n + 1);
    handleDownloadDoc({
      id: 'downloadPolicies',
      data: result
    });
  };
  return (
   // <li>
   //   <Label variant="body2"> {item.date ? 'Signed' : 'Non Signed'}</Label>
    //  {item.date ? (
    //    <Link onClick={() => downloadDoc(item.url)}> {item.name}</Link>
    //  ) : (
    //    <Label variant="body1"> {item.name}</Label>
    //  )}
  //  </li>

      <li>
      {item.date ? (
        <Label>
        <Link onClick={() => downloadDoc(item.url)}> View </Link>
        </Label>
        ) : (
          <Label variant="body2"> Non Signed</Label>
          )}
      {item.date ? (
        <span className='signedWrapper'>
        <Label variant="body1"> {item.name}</Label>
        <Label>Signed {moment(item.date).format('MM/DD/YYYY')}</Label>
        </span>
     ) : (
       <Label variant="body1"> {item.name}</Label>
     )}
    </li>
  );
};

export default Policies;
Policies.propTypes = {
  list: PropTypes.array
};
