import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
.formLogin{
    padding: 0 20%;
    padding-bottom: 2%;
}
.policyLine {
    justify-content: flex-start;
    padding: 0;
    padding-left: 16%;
    .policyText p:first-child {
        font-size: 24px;
    }
    .policyText p:last-child {
        color: #777;
    }
}
.selectedCheckbox {
    flex-direction: column;
    padding-left: 16%;
}
.policySubmit {
    justify-content: center;
    padding-bottom: 2%;
}
`;

const Styled = { Wrapper };

export default Styled;
