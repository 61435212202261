import React, { lazy } from 'react';

import { filterList, filterWithDateRange } from '../../../utils/utils';
import moment from 'moment';
import { BACKEND_DATE_FORMAT } from '../../../config/constants/static';
const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`../../UI/Form/${layout}`));
const filterLogsListForm = (
  values,
  list,
  filterSuccess,
  setValues,
  fields,
  isServer,
  filterLogsList,
  i18n,
  setPagination,
  setIsFilterClicked
) => {
  return (
    <Form
      type="client"
      direction="row"
      values={{ ...values }}
      className="filterForm"
      handleOnSubmit={() => {
        if (isServer) {
          let data = {
            startDate: values.dateFrom ? moment(values.dateFrom).format(BACKEND_DATE_FORMAT) : null,
            endDate: values.dateTo ? moment(values.dateTo).format(BACKEND_DATE_FORMAT) : null,
            driverId: values.driver_name?.value    
          }
          filterLogsList(data)
        } else {
          const filters = values;
          Object.keys(filters).map((key) => {
            filters[key] === null || (filters[key] === '' && delete filters[key]);
          });
          let result = values.driver_name
            ? filterList(list.list, 'driver_name', values.driver_name)
            : list.list;
          if (values.dateFrom || values.dateTo) {
            result = filterWithDateRange(result, values.dateFrom, values.dateTo);
          }
          filterSuccess(result);
        }
        setPagination(true);
        setIsFilterClicked(true);
      }}
      handleOnCancel={() => {
        if (isServer) {
          filterLogsList({
            startDate: null,
            endDate: null,
            driverId: null
          })
        } else {
          filterSuccess(list.list);
        }    
        setValues({ driver_name: '', dateFrom: '', dateTo: '' });
        setPagination(true);
        setIsFilterClicked(false);
      }}
      setValues={setValues}
      fields={[
        ...fields,
        { type: 'submit', value: i18n.t('common.view') },
        { type: 'reset', value: i18n.t('common.clear') }
      ]}
    />
  );
};
export default filterLogsListForm;
