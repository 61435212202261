import styled from 'styled-components';

const Wrapper = styled.div`
  ${({ theme, permissionDenied }) => `
  color: ${theme.colors.PRIMARY_CONTRAST};
  marign: auto;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 2%;
  height: ${ permissionDenied ? '100%': 'auto'};
  a,
  .unauthorized{
    color: ${theme.colors.LINK_COLOR} !important;
    font-size: 22px;
  }
  h1 {
    text-align: center;
  }
  p {
    margin-top: 0;
    font-size: 20px;
  }
  .here {
    padding-left: 5px;
    color: ${theme.colors.SECONDARY_MAIN};
    text-decoration: none;
  }
  .PermissionChanges{
    .resetHeading{
    font-size: 40px;
    }
  }
  `}
`;
const Styled = { Wrapper };

export default Styled;
