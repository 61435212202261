import React, { lazy, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import TopActions from './TopActions';
import ParentStyled from './style';
import { loadStyle, hasValue, objectArrayFromDiffFields } from '@/utils/utils';
let Styled = loadStyle(
  ParentStyled,
  'pages/web/RadiusComplianceAlerts/Component'
);
const layout = window?.$environment?.CURRENT_LAYOUT;
const Breadcrumb = lazy(() => import(`@/components/Breadcrumb`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Modal = require(`@/components/UI/Modal/${layout}`).default;
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));

const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Breadcrumb filters={filters} />
      <Component {...contentProps} />
    </Box>
  );
};
export default Index;

const Component = ({
  list,
  filterSuccess,
  selectedCompany,
  apiStatus,
  apiIds,
  needFilter,
  i18n,
  columns,
  topAction,
  defaultSortBy,
  pagination,
  setPagination,
  fleetCompanies
}) => {
  const [openModalDesc, setModalDesc] = useState(false);
  const [ModalDescData, setModalDescData] = useState({
    header: '',
    content: () => {
      return <> </>;
    }
  });
  const hasApiStatus = hasValue(apiStatus?.status, 'api', apiIds.list);
  const information =
    hasApiStatus && hasApiStatus.errorMessage ? (
      <Message type="error" message={hasApiStatus.errorMessage} />
    ) : (
      i18n.t('No data available')
    );
  const fleetCompanyList = objectArrayFromDiffFields(
    fleetCompanies,
    'id',
    'name'
  );
  const selectedCompanyList = objectArrayFromDiffFields(
    selectedCompany,
    'id',
    'name'
  );
  const descColumns = [
    {
      id: 'description',
      disablePadding: false,
      label: i18n.t('dashboard.tabs.radiusComplianceAlerts.description'),
      needSorting: true
    },
    {
      id: 'date',
      disablePadding: false,
      label: i18n.t('dashboard.tabs.radiusComplianceAlerts.date'),
      needSorting: true
    },
  ]
  const generateRows = () => {
    let finalList = list.filteredList
    finalList.map(ele => {
      if (ele.descriptions.length > 1) {
        ele.description = <div className="descPopUp" onClick={() => handleDecPopup(ele)}>
          {ele.type.toLowerCase().includes('radius') ? i18n.t('multipleRadiusAlert') : ele.type.toLowerCase().includes('accident') ? i18n.t('multipleAccidentAlert') : ele.type.toLowerCase().includes('dvir') ? i18n.t('multipleDvirAlert') : i18n.t('multipleAlert')}
        </div>
      }else if(ele.descriptions.length == 1) {
        ele.description = ele.descriptions[0]?.description
      }
    })
    return finalList;
  };
  const generateDescRows = (list) => {
    list.map(ele => {
      let date = new Date(ele.date)
      date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()))
      ele.date = moment(date).format(
        'MM/DD/YYYY HH:mm:ss'
      )
    })
    return list
  }
  const handleDecPopup = (data) => {
    setModalDesc(true)
    setModalDescData({
      content: () => {
        return (
          <div className="descModal">
            <div className="headWrapper">{data.companyName}</div>
            <Table
              type="client"
              defaultSortBy={{ sortBy: 'date', order: 'desc' }}
              title=""
              columns={[...descColumns]}
              data={generateDescRows(data.descriptions)}
              emptyMessage={information}
              itemsPerPage={5}
              id="dashBoardAlertsDescriptionPopUp"
              actions={[]}
              i18n={i18n}
            />
          </div>
        )
      }
    })
  }
  return (
    <>
      {topAction && (
        <TopActions
          {...{
            filterSuccess,
            selectedCompanyList,
            i18n,
            needFilter,
            list,
            setPagination,
            Styled,
            fleetCompanyList
          }}
        />
      )}
      <Styled.TableWrapper>
        <Table
          type="client"
          defaultSortBy={
            defaultSortBy || {
              sortBy: 'date',
              order: 'desc'
            }
          }
          title=""
          columns={[...columns]}
          data={generateRows()}
          emptyMessage={information}
          itemsPerPage={15}
          actions={[]}
          i18n={i18n}
          pageinationReset={pagination}
          pagenationClicked={(e) => {
            setPagination(!e);
          }}
        />
      </Styled.TableWrapper>
      <Modal
        open={openModalDesc}
        setOpen={setModalDesc}
        data={ModalDescData}
        deletePopup
        withCloseIcon
        width={'800px'}
        alignItemsLeft
      />
    </>
  );
};

Component.propTypes = {
  list: PropTypes.object.isRequired,
  filterSuccess: PropTypes.func.isRequired,
  apiStatus: PropTypes.object.isRequired,
  exportAllData: PropTypes.func.isRequired,
  deleteData: PropTypes.func.isRequired
};
