
import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
    padding: 0;
    .tableWrapper {
        padding: 0 2%;
    }
    .quickFilter {
        background: ${theme.colors.LINK_COLOR} none repeat scroll 0 0;
        color: #f4f6f9;
        padding: 10px 3%;
    }
`}
`;
const Styled = { Wrapper };

export default Styled;