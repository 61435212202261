import React, { useEffect, lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RenderComponent from '@/components/modules/DynamicRenderPage';
import AddEdit from './AddEdit';

import ParentStyled from './style';
import { loadStyle, loadPageConfig } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'pages/web/UsDotNoManagement');
const layout = window?.$environment?.CURRENT_LAYOUT;

const Modal = require(`@/components/UI/Modal/${layout}`).default;
const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.usDotManagementenabled[0].name}`));
const Content = lazy(() =>
  import(`@/${config.usDotManagementenabled[1].name}`)
);
const TopActions =
  config.usDotManagementenabled[0].children &&
  lazy(() => import(`${config.usDotManagementenabled[0].children.name}`));

const USDotManagement = (parentProps) => {
  const {
    fetchUsDotManagement,
    filterSuccess,
    usDotManagement,
    i18n,
    theme,
    history,
    callback,
    updateDot,
    saveDot,
    apiStatus,
    states,
    getStates
  } = parentProps;
  const commonParentProps = { history, i18n, Styled, theme };
  const [pagination, setPagination] = useState(false);

  const fetchStateApiId = 'getStatesApi';
  useEffect(() => {
    fetchUsDotManagement({
      apiId: 'fetchUsDotManagement'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    !states.length &&
      getStates({
        fetchStateApiId
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [states]);

  const [open, setOpen] = useState(false);
  const closeModal = (event) => {
    setOpen(false);
  };
  const clickedModal = (e) => {
    setOpen(true);
  };
  const [withCloseIcon, setwithCloseIcon] = useState(true);
  const [backgroundModal, setbackgroundModal] = useState(false);
  const [ModalListObject, setModalListObject] = useState({
    header: '',
    content: () => {}
  });
  const options = (data) => {
    clickedModal();
    setwithCloseIcon(true);
    setbackgroundModal(false);
    setModalListObject({
      content: () => {
        return (
          <AddEdit
            closeModal={closeModal}
            callback={callback}
            saveDot={data ? updateDot : saveDot}
            currentItem={data}
            states={states}
            apiStatus={apiStatus}
          />
        );
      }
    });
  };

  const searchConfig = {
    setPagination,
    searchPlaceholder: i18n.t('usDotManagement.search'),
    filterSuccess: filterSuccess,
    data: usDotManagement?.list,
    isSpireonNew: true,
    fromData: true,
    query: [
      'dotNumber',
      'carrierName',
      'mainAddress',
      'state',
      'city',
      'zipCode'
    ]
  };

  return (
    <>
      <RenderComponent
        {...config.usDotManagementenabled[0]}
        parentProps={{ ...parentProps, options, pagination, setPagination }}
        commonParentProps={commonParentProps}
        component={Title}
        childComponent={TopActions}
        pagination={pagination}
        setPagination={setPagination}
        noBackbutton={true}
        searchConfig={
          window.$environment.CURRENT_LAYOUT === 'SpireonNew'
            ? searchConfig
            : null
        }
      />
      <RenderComponent
        {...config.usDotManagementenabled[1]}
        parentProps={{ ...parentProps, options, pagination, setPagination }}
        commonParentProps={commonParentProps}
        component={Content}
        setModalListObject={setModalListObject}
        clickedModal={clickedModal}
        closeModal={closeModal}
        setwithCloseIcon={setwithCloseIcon}
        setbackgroundModal={setbackgroundModal}
        pagination={pagination}
        setPagination={setPagination}
      />
      <Modal
        open={open}
        setOpen={setOpen}
        data={ModalListObject}
        onCloseModal={closeModal}
        background="black"
        withCloseIcon={withCloseIcon}
        backgroundModal={backgroundModal}
        deletePopup
      />
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    usDotManagement: state.usDotManagement,
    states: state.getStates
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchUsDotManagement: (data) => dispatch.usDotManagement.fetchData(data),
    filterSuccess: (data) => dispatch.usDotManagement.filterSuccess(data),
    saveDot: (data) => dispatch.usDotManagement.saveDot(data),
    updateDot: (data) => dispatch.usDotManagement.updateDot(data),
    handleDeleteAlert: (data) =>
      dispatch.usDotManagement.handleDeleteAlert(data),
    getStates: (data) => dispatch.getStates.getStates(data)
  };
};

export default connect(mapState, mapDispatch)(USDotManagement);
USDotManagement.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  fetchUsDotManagement: PropTypes.func.isRequired,
  filterSuccess: PropTypes.func.isRequired,
  usDotManagement: PropTypes.array.isRequired
};
