import { viewReportApi } from '../../../config/apis';

const initialState = {};

export const roadsideInspection = {
  state: { ...initialState },
  reducers: {
    saveSuccess(state, payload) {
      return {};
    },
    saveFailed() {
      return { ...initialState };
    }
  },
  effects: (dispatch) => ({
    async viewReport(payload) {
      try {
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...viewReportApi,
              url: viewReportApi.url.replace('{id}', payload.data)
            }
          },
          id: payload.apiId
        });
      } catch (error) {
        this.saveFailed();
      }
    }
  })
};
