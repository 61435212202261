import React, { lazy, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useTheme from '@material-ui/core/styles/useTheme';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import ParentStyled from './style';
import { loadStyle, getNewObject, loadPageConfig } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'pages/web/CustomDvir');
const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.customDvirEnabled[0].name}`));
const Content = lazy(() => import(`@/${config.customDvirEnabled[1].name}`));

const CustomDvir = (parentProps) => {
  const { fetchCustomDvir, newFormData } = parentProps;

  const apiId = 'fetchCustomDvir';

  const history = useHistory();
  const theme = useTheme();
  const i18n = useTranslation();
  const commonParentProps = { history, i18n, Styled, theme };

  useEffect(() => {
    newFormData();
    fetchCustomDvir({
      apiId
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <RenderComponent
        {...config.customDvirEnabled[0]}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        component={Title}
      />
      <RenderComponent
        {...config.customDvirEnabled[1]}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        component={Content}
      />
    </>
  );
};

const RenderComponent = ({
  name,
  props,
  commonProps,
  title,
  parentProps,
  commonParentProps,
  component,
  ...rest
}) => {
  const Component = component;
  let newProps = props?.length ? getNewObject(parentProps, props) : {};
  newProps = {
    ...newProps,
    ...(commonProps?.length ? getNewObject(commonParentProps, commonProps) : {})
  };
  return <Component {...rest} {...newProps} />;
};
const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    customDvir: state.customDvir
  };
};
const mapDispatch = (dispatch) => {
  return {
    newFormData: (data) => dispatch.customDvir.newFormData(data),
    fetchCustomDvir: (data) => dispatch.customDvir.fetchData(data),
    deleteCustomDvir: (data) => dispatch.customDvir.deleteCustomDvir(data),
    enableCustomDvir: (data) => dispatch.customDvir.enableCustomDvir(data)
  };
};

export default connect(mapState, mapDispatch)(CustomDvir);
CustomDvir.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  fetchCustomDvir: PropTypes.func.isRequired
};
