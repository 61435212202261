import React, { useState, lazy, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import Box from '@material-ui/core/Box';
import { hasValue, isdvirOnly } from '@/utils/utils';
import { pageUrls } from '@/config/constants/keys';
import { useParams } from 'react-router-dom';
import RenderComponent from './RenderComponent';
const layout = window?.$environment?.CURRENT_LAYOUT;

const Breadcrumb = lazy(() => import(`@/components/Breadcrumb`));
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));

const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Breadcrumb filters={filters} />
      <Component {...contentProps} />
    </Box>
  );
};
export default Index;
const Component = ({
  apiStatus,
  callback,
  history,
  Styled,
  postCustomDvirForm,
  i18n,
  customDvir,
  clearApiStatus
}) => {
  const hasApiStatus = hasValue(apiStatus.status, 'api', postCustomDvirForm);

  const [initialValues, setInitialValues] = useState({
    name: '',
    description: '',
    pages: [],
    vehicles: []
  });
  const isDvirOnlyFeatureEnabled = isdvirOnly();

  useEffect(() => {
    const getVehicleList = () => {
      return customDvir?.vehicleList.length && customDvir?.currentItem?.vehicleIds && customDvir?.currentItem?.vehicleIds.map((source) => {
        const result =
          customDvir.vehicleList.filter((e) => e.value === source)[0];

        return result;
      });
    };

    const vehicleIdsList = getVehicleList() || [];

    setInitialValues({
      name: customDvir.currentItem.name || '',
      description: customDvir.currentItem.description || '',
      pages: customDvir.currentItem.pages || [],
      vehicles: [...vehicleIdsList]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customDvir.vehicleList.length, customDvir.currentItem]);
  
  const match = useParams();

  
  return (
    <Styled.Wrapper>
      <Form
        type="server"
        direction="column"
        initialValues={{ ...initialValues }}
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {
          const { vehicles, pages, ...finalRequest } = fields;
          let changedPages = pages.map((item) => {
            const { isEditing, key, isOpen, isTrailerSection, ...rest } = item;

            return { 
              ...rest, 
              isTrailerSection: String(isTrailerSection) === 'true'
          };
          });
          const vehicleIds = vehicles.map((item) => {
            return item.value;
          });

          postCustomDvirForm({
            apiId: 'postCustomDvirForm',
            callback: {
              successCallback: () => {
                history.push(pageUrls.customDvirEnabled);
              },
              callback
            },
            data: match.id
              ? {
                  ...finalRequest,
                  pages: changedPages,
                  vehicleIds,
                  unitType: 'VEHICLE',
                  id: match.id
                }
              : { ...finalRequest, pages: changedPages, vehicleIds, unitType: 'VEHICLE', }
          });
        }}
        customProps={{
          hasApiStatus,
          clearApiStatus,
          setInitialValues,
          Styled,
          vehicleList: customDvir.vehicleList,
          formsList: customDvir.data,
          id: match.id,
          selectedVehicles: customDvir.currentItem.vehicleIds
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(i18n.t('common.fieldRequiredMessage'))
        })}
      />
    </Styled.Wrapper>
  );
};

Component.propTypes = {
  history: PropTypes.object.isRequired,
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired
};
