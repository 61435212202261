import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

import ParentStyled from './style';
import { loadStyle, getNewObject, loadPageConfig } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'pages/web/Unhandled');

const config = loadPageConfig();
const Content = lazy(() => import(`@/${config.unhandled[0].name}`));
const Unhandled = (parentProps) => {
  const { history, theme } = parentProps;
  const { i18n } = useTranslation();
  const commonParentProps = { history, i18n, Styled, theme };
  return (
    <>
      <RenderComponent
        {...config.unhandled[0]}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        component={Content}
      />
    </>
  );
};

const RenderComponent = ({
  name,
  props,
  commonProps,
  title,
  parentProps,
  commonParentProps,
  component,
  ...rest
}) => {
  const Component = component;
  let newProps = props?.length ? getNewObject(parentProps, props) : {};
  newProps = {
    ...parentProps,
    ...newProps,
    ...(commonProps?.length ? getNewObject(commonParentProps, commonProps) : {})
  };
  return <Component {...rest} {...newProps} />;
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    driverStatusHistory: state.driverStatusHistory
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchDriverStatusHistory: (data) =>
      dispatch.driverStatusHistory.fetchData(data),
    exportCsvData: (data) => dispatch.driverStatusHistory.exportCsvData(data),
    exportPdfData: (data) => dispatch.driverStatusHistory.exportPdfData(data)
  };
};
export default connect(mapState, mapDispatch)(Unhandled);

Unhandled.propTypes = {
  message: PropTypes.string.isRequired,
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};
