import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import { getStyle } from '@/utils/utils';

const ExportWrapper = styled(Box)`
  ${({ theme, style }) => `

  border: solid 2px
    ${
      (getStyle((style || {}).border, theme.palette.primary || {}).border,
      theme.colors.PRIMARY_BORDER)
    };
  background-color: ${
    (getStyle((style || {}).border, theme.palette.primary || {}).main,
    theme.colors.PRIMARY_MAIN)
  };
  width: 450px;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  justify-content: flex-end;
  flex-direction: column;
  position: absolute;
  transition-property: left;
  transition-duration: 2s;
  right: ${({ right }) => right || '0px'};
  `}
`;
const Header = styled(Box)`
  ${({ theme }) => `

  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  // padding: 0 3%;
  justify-content: space-between;
  .filterForm {
    flex-wrap: wrap;
    width: 100%;
    padding-top: 0;
    div[type='text'],
    div[type='date'] {
      width: 17%;
      margin-right: 1%;
      flex-direction: column;
       p {
        color: ${theme.colors.PRIMARY_CONTRAST} !important;
      }
      @media (max-width: ${theme.breakpoints.values.md}px) {
        width: auto;
        margin-bottom: 16px;
      }
    }
    div[type='submit'] {
      margin-right: 1%;
    }
    div[type='submit'],
    div[type='reset'] {
      align-items: flex-end;
      @media (max-width: ${theme.breakpoints.values.md}px) {
        margin-bottom: 16px;
      }
    }
  }

  .MuiAvatar-root {
    width: 30px;
    height: auto;
  }
  .tooltiptext {
    top: 57px;
    right: 0;
    width: 50px;
    font-size: 14px;
  }
  .udcheckWrapper {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    flex: 0 0 95%;
    padding: 1.5% 0;
    .MuiFormControlLabel-root {
      margin-left: 0;
      white-space: nowrap;
      .PrivateSwitchBase-root-362 {
        padding-left: 0;
      }
    }
  }
  .udcheckWrapper {
    @media (max-width: ${theme.breakpoints.values.smd}px) {
      flex-direction: column;
    }
    .MuiFormControlLabel-root {
      min-width: 203px;
      @media (max-width: ${theme.breakpoints.values.md - 1}px) {
        min-width: 150px;
      }
      span.MuiButtonBase-root {
        padding-left: 0;
      }
      .MuiFormControlLabel-label {
        font-size: 18px !important;
        @media (max-width: ${theme.breakpoints.values.md}px) {
          font-size: 15px !important;
        }
      }
    }
  }
  .exportWrapper {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    flex: 0 0 4%;
    justify-content: flex-end;
    .MuiAvatar-root {
      width: 30px !important;
      height: auto !important;
    }
  }
  `}
`;

const InfoContainer = styled(Box)`
  ${({ theme }) => `

  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 2%;
  @media (max-width: ${theme.breakpoints.values.md}px) {
    justify-content: flex-start;
  }
  .seperator {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    flex: 0 0 20%;
    justify-content: flex-end;
    p:first-child {
      padding-top: 6px;
      font-size: 20px;
    }
  }
   .seperator:first-child {
    padding-top: 6px;
   }
  p {
    padding-left: 10px;
    text-transform: capitalize;
    color: ${
      (theme.palette.primary || {}).contrastText ||
      theme.colors.PRIMARY_CONTRAST
    };
    :last-child {
      color: ${
        (theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN
      };
      font-size: 25px;
    }
  }
  `}
`;

const ToolsContainer = styled(Box)`
  ${({ theme, selectedindex }) => `

  position: absolute;
  background: ${
    (theme.palette.primary || {}).main || theme.colors.PRIMARY_MAIN
  };
  border: solid 2px
    ${(theme.palette.primary || {}).border || theme.colors.PRIMARY_BORDER};
  top: ${selectedindex ? selectedindex + 'px' : '280px'};
  right: 0;
  z-index: 3;
  li {
    list-style: none;
  }
  a {
    color: ${
      (theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN
    };
    font-size: 20px;
  }
  .ToolsOptionClose {
    color: ${
      (theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN
    };
    position: absolute;
    margin-top: 10px;
    margin-right: 8px;
  }

  ul {
    margin-top: 20px;
    width: 175px;
    height: auto;
    padding-inline-start: 25px;
  }
  `}
`;

const UdLists = styled(Box)`
  .MuiTable-root {
    border: 0;
  }
  .MuiTableRow-root,
  .MuiTableRow-root:hover {
    background-color: colors.BLACK;
  }
  .MuiTableCell-head {
    border-bottom: none;
  }
  .MuiInput-root {
    height: 27px;
    padding: 0;
    overflow: hidden;
  }
  .MuiInputBase-input {
    padding: 0;
  }
  .css-1wa3eu0-placeholder {
    font-size: 16px !important;
    top: 40%;
  }
  .css-1hys26h-singleValue{
    top: 43%;
  }
  .select__indicator.select__dropdown-indicator {
    padding-top: 0;
  }
  .Selectclass .css-8r3dx3-control,
  .Selectclass .select__control--is-focused,
  .Selectclass .select__control {
    height: 27px;
    min-height: 27px !important;
    max-height: 27px;
    overflow: hidden !important;
  }
  .select__value-container {
    padding: 0;
    height: 27px;
  }
  .MuiTableCell-root {
    padding-top: 8px;
  }
`;
const Arrowdown = styled(Box)`
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  justify-content: space-between;
  span {
    width: 90%;
  }
  .MuiGrid-root {
    width: 10%;
    img {
      width: 17px;
      height: 10px;
    }
  }
  .udlogExpandedtable{
    position: absolute;
    top: 36.5px;
    left: 0;
    z-index: 1;
    border-top: 2px solid #2d2b2b;
    form {
      padding: 1px 5%;
    }
    button.MuiButtonBase-root {
      margin-top: 9px;
      font-size: 20px;
      height: 39px;
    }
  }
`;
const UdlogTable = styled(Box)`
  // padding: 0 3%;
  .spireonNewOnly,
  .spireonOldOnly{
    display: none;
  }
  position: relative;
  table tbody tr:hover {
    background: transparent !important;
    
  }
`;
const UnidentifiedSection = styled.div`
  ${({ theme }) => `
  .MuiTable-root .MuiTableRow-root .MuiTableCell-root .MuiBox-root {
    margin-bottom: 0;
  }
  .MuiInputBase-input.Mui-disabled{
    color: #c0bbb7 !important;
  }
  input {
    padding: 0 !important;
    font-size: 16px;
  }
  .MuiTableHead-root .MuiTableCell-root {
    border-bottom: 0 !important;
    font-size: 17px !important;
  }
  .MuiTableBody-root .MuiTableCell-root{
    padding-left: 5px;
  }
  .MuiTable-root .MuiTableRow-root .MuiTableCell-root .MuiBox-root .MuiBox-root {
    flex: 0 0 100%;
  }
  // .MuiTableHead-root .MuiTableCell-root:nth-child(4){
  //   width: 120px;
  // }
  .MuiTableHead-root .MuiTableCell-root:nth-child(6) {
    min-width: 100px;
  }
  .select--is-disabled{
    pointer-events: auto;
    opacity: 1 !important;
  }
  .MuiInputBase-root.Mui-disabled {
    opacity: 1;
    cursor: no-drop;
  }
  .select--is-disabled,
  .select__single-value--is-disabled:hover,
  .select__control.select__control--is-disabled:hover {
    cursor: no-drop;
  }
  .MuiInputBase-input.Mui-disabled{
    opacity: 1 !important;
    :hover {
      cursor: no-drop;
    }
  }
  .select__menu {
    height: 107px;
    overflow: auto;
    .select__menu-list {
      height: 210px;
      max-height: 100%;
    }
  }
  input[name='note'] {
    max-width: 180px;
  }
  .floatingTable::-webkit-scrollbar {
    display: none;
  }
  .floatingTable {
  overflow: visible;
    top: 36.5px;
    display: block;
    position: absolute;
    left: 20px;
    z-index: 1;
    width: 728px;
    .example::-webkit-scrollbar {
      display: none;
    }
    @media (min-width: ${theme.breakpoints.values.xl}px) {
      width: 965px;
    }
    .MuiTableRow-head th {
      border-bottom: 0;
      font-size: 17px;
    }
    @media (min-width: ${theme.breakpoints.values.xl}px) {
      input[name='location'] {
        max-width: 145px;
        padding-left: 5px;
      }
      input[name='note'] {
        max-width: 180px;
      }
      .MuiTableBody-root .MuiTableRow-root td:first-child,
      .MuiTableBody-root .MuiTableRow-root td:nth-child(2) {
        min-width: 90px;
      }
    }

    @media (min-width: ${theme.breakpoints.values.xl + 1}px) {
      left: -216px;
    }
    .saveButton{
      .MuiButtonBase-root{
        height: 37px;
        font-size: 20px;
      }
    }
    input {
      font-size: 16px;
      height: auto;
      padding-left: 5px;
    }
    .select__menu {
      height: 140px;
      overflow: auto;
      .select__menu-list {
        height: 210px;
        max-height: 100%;
      }
    }
    .MuiTableCell-body .MuiBox-root {
      margin-bottom: 0;
      .MuiBox-root {
        flex: 0 0 100%;
      }
    }
    .Selectclass {
      @media (min-width: ${theme.breakpoints.values.xl + 1}px) {
        min-width: 156px;
      }
      .select--is-disabled {
        opacity: 0.5;
      }
    }
    #button {
      margin-top: 9px;
    }
  }
  @media (min-width: ${theme.breakpoints.values.xl + 1}px) {
    position: relative;
    width: 100%;
  }
  `}
`;
const Content = styled(Box)`
  .logwrapper {
    // overflow: hidden;
    .MuiTabs-root {
      padding-left: 3%;
    }
  }
`;
const Styled = {
  ExportWrapper,
  Header,
  InfoContainer,
  ToolsContainer,
  UdLists,
  Arrowdown,
  UdlogTable,
  UnidentifiedSection,
  Content
};

export default Styled;
