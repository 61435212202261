import React, { useState, lazy } from 'react';
import { useTranslation } from 'react-i18next';

import { getImagePath, hasValue } from '@/utils/utils';
import { imageUrls } from '@/config/constants/keys';
import moment from 'moment';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const Link = lazy(() => import(`@/components/UI/Link`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Modal = require(`@/components/UI/Modal/${layout}`).default;

const TableComponent = ({
  list,
  apiStatus,
  deleteData,
  callback,
  columns,
  apiIds,
  noAction,
  defaultSortBy,
  pagination,
  setPagination
}) => {
  const i18n = useTranslation();

  /* Create a popup starts */
  const [ModalListObject, setModalListObject] = useState({
    header: '',
    content: () => {}
  });
  const [open, setOpen] = useState(false);
  /* Create a popup ends*/

  const generateRows = () => {
    const updatedList = list.filteredList.map((item) => {
      item.description = item.url ? (
        <Link href={item.url}>{item.description}</Link>
      ) : (
        item.description
      );
      item.date = moment(new Date(item.date)).format('MM/DD/YYYY HH:mm:ss');
      return item;
    });
    return updatedList;
  };

  const hasApiStatus = hasValue(apiStatus.status, 'api', apiIds.list);
  const information =
    hasApiStatus && hasApiStatus.errorMessage ? (
      <Message type="error" message={hasApiStatus.errorMessage} />
    ) : (
      i18n.t('No data available')
    );
  return (
    <>
      <Table
        type="client"
        defaultSortBy={defaultSortBy || { sortBy: window.$environment.SERVICE_PROVIDER === 'spireon' ? 'description' : 'date', order: 'desc' }}
        title=""
        columns={[...columns]}
        data={generateRows()}
        emptyMessage={information}
        itemsPerPage={
          window.$environment.SERVICE_PROVIDER === 'spireon' ? 10 : 15
        }
        actions={
          noAction
            ? []
            : [
                {
                  icon: (row, index) => {
                    return (
                      <Icon
                        src={getImagePath(imageUrls.deleteIcon)}
                        onClick={() => {
                          setModalListObject({
                            content: () => (
                              <div className="deleteParent">
                                <div className="imageSection">
                                  <img
                                    src={getImagePath(imageUrls.confirmIcon)}
                                    alt="Are you sure"
                                  />
                                </div>
                                <h2> {i18n.t('common.sureConfirm')} </h2>
                                <div className="deletePopupBtn">
                                  <div className="cancelBtn">
                                    <Button
                                      label={i18n.t('common.cancel')}
                                      type={'reset'}
                                      onClick={() => {
                                        setOpen(false);
                                      }}
                                    ></Button>
                                  </div>
                                  <div className="deletebtn">
                                    <Button
                                      label={i18n.t('common.deletePopup')}
                                      onClick={() => {
                                        deleteData({
                                          apiId: apiIds.delete,
                                          data: { data: { id: row.id } },
                                          callback: callback
                                        });
                                        setOpen(false);
                                      }}
                                    ></Button>
                                  </div>
                                </div>
                              </div>
                            )
                          });
                          setOpen(true);
                        }}
                      />
                    );
                  }
                }
              ]
        }
        i18n={i18n}
        pageinationReset={pagination}
        pagenationClicked={(e) => {
          setPagination(!e);
        }}
      />

      <Modal open={open} setOpen={setOpen} data={ModalListObject} deletePopup />
    </>
  );
};

export default TableComponent;
