import styled from 'styled-components';

import { getStyle } from '../../../../utils/utils';
const TabWrapper = styled.div`
  ${({ theme }) => `
  .MuiTabs-flexContainer {
    .Mui-selected {
      border-bottom: 2px solid #f58220;
    }
    @media (max-width: ${theme.breakpoints.values.md - 1}px) {
      flex-direction: column;
      text-align: -webkit-center;
    }
  }
  .MuiTab-root {
    max-width: 100%;
    margin-right: 3px;
    border-bottom: 2px solid transparent;
    &:hover {
      border-bottom: 2px solid #f58220 !important;
      .MuiTab-wrapper {
        color: ${theme.colors.SECONDARY_MAIN};
      }
    }
  }
  .MuiTab-wrapper {
    color: ${theme.colors.PRIMARY_CONTRAST};
    font-size: 24px;
  }
  .Mui-selected  .MuiTab-wrapper {
    color: ${theme.colors.SECONDARY_MAIN};
  }
  .MuiTabs-indicator {
    display: none;
  }
  .PrivateTabIndicator-root-331 {
    height: 3px;
  }
  .MuiTab-textColorInherit {
    opacity: 1;
  }
  .MuiAppBar-colorPrimary {
    background: ${getStyle(
      '',
      (theme.palette.primary || {}).main,
      theme.colors.PRIMARY_MAIN
    )};
  }
  `}
`;

export default TabWrapper;
