import React, { lazy } from 'react';

import { filterList } from '@/utils/utils';
const layout = window?.$environment?.CURRENT_LAYOUT;

const Search = lazy(() => import(`@/components/UI/SimpleSearch/${layout}`));
const Breadcrumb = lazy(() => import(`@/components/Breadcrumb`));

const TopActions = ({
  companies,
  callback,
  filterSuccess,
  Styled,
  i18n,
  setPagination,
  history,
  filters,
  removeData,
  setremoveData
}) => {

  const selectChange = (e) => {
    const arrayList = [...companies.companiesOfServiceCenter];
    const val = filterList(arrayList, ['name', 'email', 'manager'], e);
    filterSuccess(val);
    setPagination(true);
    setremoveData('false')
  };

  return (
    <Styled.Wrapper>
      {layout !== 'OldUi' && <Breadcrumb filters={filters} />}
      <Styled.TopWrapper>
        <Styled.SearchWrapper>
          <Search
            id={'clearSearch'}
            onChange={(e) => selectChange(e)}
            default={[...companies.companiesOfServiceCenter]}
            placeHolder={i18n.t('companies.searchCompanies')}
            query={['name', 'email', 'manager']}
            id='searchUser'
            onRemove={removeData}
          ></Search>
        </Styled.SearchWrapper>

      </Styled.TopWrapper>
      <div className="breadcrumbClass">
        {layout === 'OldUi' && <Breadcrumb  filters={filters} />}
      </div>
    </Styled.Wrapper>
  );
};
export default TopActions;
