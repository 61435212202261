import { dotAuditApi } from '../../../config/apis';

const initialState = {};

export const dotAudit = {
  state: { ...initialState },
  reducers: {
    auditSuccess(state, payload) {
      return {
        ...payload
      };
    },
    auditFailed() {
      return { ...initialState };
    }
  },
  effects: (dispatch) => ({
    async sendData(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            data: { ...payload.data },
            api: dotAuditApi,
            callback: payload.callback
          },
          id: payload.apiId
        });
        this.auditSuccess(response);
        payload.callback('success', response);
      } catch (error) {
        payload.callback('warningfmcsa', error.message);
        this.auditFailed();
      }
    }
  })
};
