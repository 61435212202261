
import {
  deleteIftaStateApi
} from '../../../../config/apis';
const initialState = { list: [], filteredList: [], csvPayload: null, };

export const iftaList = {
    state: { ...initialState },
    reducers: {
        getSuccess(state, payload) {
            let response = payload.content;
            return {
                ...state,
                totalRecords: payload.totalElements,
                list: [...response],
            };
        },
        setErrorFlg(state, payload) {
          return state;
        },
        getFailed(state) {
          return { ...initialState };
        },
        filterSuccess(state, status) {
          let iftaListResponse = [ ...state.list ];
          if (status) {
            iftaListResponse = iftaListResponse.filter(ele => ele.iftaMileageId)
          }
          return {
            ...state,
            list: [ ...state.list ],
            filteredList: [ ...iftaListResponse ]
            
          };
        },
        savePayload(state, payload) {
          return { ...state, csvPayload: payload}
        },
    },
    effects: (dispatch) => ({
      async fetchData(payload, rootState) {
        this.setErrorFlg(false);
        this.savePayload(payload?.data);
        try {
          const getApi = await import(
            `../../../../config/apis/${window?.$environment?.SERVICE_PROVIDER}`
          );
          const apiId = payload?.apiId;
          const apiObject = getApi.default(apiId);
          const response = await dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              api: apiObject,
              data: payload.data
            },
            callback: payload.callback,
            id: payload.id
          });
          if (response?.content)  {
            this.setErrorFlg(response.iftaErrorExists)
            this.getSuccess(response);
          }
        } catch (error) {
          this.setErrorFlg(false);
          // this.getFailed();
        }
      },
      async handleDeleteAlert(payload, rootState) {
        try {
          const response = await dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              api: {
                ...deleteIftaStateApi,
                url: deleteIftaStateApi.url.replace('{id}', payload.data.id)
              }
            },
            callback: payload.callback,
            id: payload.id
          });
          payload.callback('success', response, () => { 
            if (response) dispatch.iftaList.fetchData({apiId: 'listIftaRefreshApi', data: rootState?.iftaList?.csvPayload});
          });
        } catch (error) {
          // this.listFailed();
        }
      },
      filterList(status) {
        this.filterSuccess(status);
      }
    })
};
