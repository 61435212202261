import React, { lazy } from 'react';
import ViewForm from './Form';
import { imageUrls } from '@/config/constants/keys';
const layout = window?.$environment?.CURRENT_LAYOUT;

const Export = lazy(() => import(`@/components/UI/Export/${layout}`));

const TopActions = ({
  Styled,
  apiStatus,
  history,
  callback,
  hasApiStatus,
  changeRequest,
  i18n,
  handleExportCsaViolations,
  setPagination
}) => {
  const exportCsaViolations = () => {
    handleExportCsaViolations({
      id: 'exportCsaViolation'
    });
  };

  return (
    <Styled.TopWrapper>
      <ViewForm
        apiStatus={apiStatus}
        history={history}
        callback={callback}
        Styled={Styled}
        hasApiStatus={hasApiStatus}
        changeRequest={changeRequest}
        setPagination={setPagination}
      />
      <Export
        src={imageUrls.importUserTemplate}
        filename={'users'}
        toolTip={i18n.t('Export')}
        className="exportContainer"
        onClick={() => exportCsaViolations()}
      />
    </Styled.TopWrapper>
  );
};
export default TopActions;
