import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
${({ theme }) => `
  display: flex;
  flex-direction: column;
  form {
    flex-direction: column;
    .feedbackWrapper {
      .MuiFormLabel-root{
        flex: 0 0 10%;
      }
      .textareaSection {
        flex: 0 0 90%;
      }
    }
  }
  .oldOnly {
    display: none;
  }
  textarea {
    font-family: ${(theme.palette || {}).fontFamily};
    height: 180px !important;
    font-size: 20px;
  }
  `}
`;

const Styled = { Wrapper };

export default Styled;
