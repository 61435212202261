import React from 'react';
import SimpleSearch from '../Base';
import SearchWrapper from './style';

const Index = (props) => {
  return (
    <SimpleSearch {...props} SearchWrapper={SearchWrapper} seachIconClickable />
  );
};

export default Index;
