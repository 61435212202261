import React, { lazy, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';

import Styled from './style';
import { hasEnabledFeature } from '@/utils/utils';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Input = lazy(() => import(`@/components/UI/Input/${layout}`));
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Select = lazy(() => import(`@/components/UI/Select/${layout}`));
const SubHeader = lazy(() => import(`@/components/SubHeader/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const RenderComponent = ({
  values,
  handleEdit,
  validateForm,
  customProps,
  submitAction,
  errors,
  isNotValid
}) => {
  const [stateOffset, setStateOffset] = useState(0);
  const [fleetOffset, setFleetOffset] = useState(0);
  const { i18n } = useTranslation();
  const showError = (field, validationErrors, isNotValid, apiError) => {
    if (validationErrors[field] && !isNotValid) {
      return <Message type="error" message={errors[field]} />;
    }
    if (apiError?.[field]) {
      return <Message type="error" message={apiError[field]} />;
    }
  };
  useEffect(() => {
    let ele = document.querySelectorAll('#terminalState');
    if (ele.length !== 0) {
      let terminalState = document.querySelectorAll('#terminalState')[0]
        .offsetTop;
      let uploadFields = document.querySelectorAll('.uploadFields')[0]
        .offsetTop;
      setStateOffset(terminalState - uploadFields - 50);
    }
  }, [document.querySelectorAll('#terminalState')]);

  useEffect(() => {
    let ele = document.querySelectorAll('#fleetManager');
    if (ele.length !== 0) {
      let fleetManager = document.querySelectorAll('#fleetManager')[0]
        .offsetTop;
      let uploadFields = document.querySelectorAll('.uploadFields')[0]
        .offsetTop;
      setFleetOffset(fleetManager - uploadFields - 50);
    }
  }, [document.querySelectorAll('#fleetManager')]);
  return (
    <Styled.PopupUpload>
      <SubHeader
        text={
          customProps.isEdit
            ? i18n.t('departments.editLocation')
            : i18n.t('departments.addLocation')
        }
        className="subheaderSection"
        variant="h2"
      />
      <Styled.UploadWrapper stateOffset={stateOffset} fleetOffset={fleetOffset}>
        <div className="tcSection" id="scroll">
          <div className="scrollbar">
            <div className="uploadFields">
              <FormStyle.default.FieldsWrapper
                width="100%"
                className="fieldSection"
              >
                <Label htmlFor="departmentName" className="departmentName" variant="body1">
                  {i18n.t('common.department')}
                </Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="departmentName"
                    value={values?.departmentName}
                    as={Input}
                    type="text"
                    disabled
                  />
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              <FormStyle.default.FieldsWrapper
                width="100%"
                className="fieldSection"
              >
                <Label htmlFor="subDepartmentName" variant="body1">
                  {i18n.t('common.location')}
                </Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="subDepartmentName"
                    value={values?.subDepartmentName}
                    handleOnChange={(event) =>
                      handleEdit(event, { field: 'subDepartmentName' })
                    }
                    as={Input}
                    type="text"
                  />
                  {showError(
                    'subDepartmentName',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              {(customProps?.isShowDotNumber || hasEnabledFeature('usDotManagementenabled')) && (
                <FormStyle.default.FieldsWrapper
                  width="100%"
                  className="fieldSection"
                >
                  <Label htmlFor="dotNumber" variant="body1">
                    {i18n.t('departments.dot')}
                  </Label>
                  <FormStyle.default.TextWrapper>
                    <Field
                      name="dotNumber"
                      onChange={(event) =>
                        handleEdit(event, { field: 'dotNumber' })
                      }
                      value={values?.dotNumber}
                      as={Select}
                      placeholder={i18n.t('common.pleaseSelect')}
                      suggestions={customProps.dotNumbers}
                      isClearable
                    />
                  </FormStyle.default.TextWrapper>
                </FormStyle.default.FieldsWrapper>
              )}
              <FormStyle.default.FieldsWrapper
                width="100%"
                className="fieldSection"
              >
                <Label htmlFor="address" variant="body1">
                  {i18n.t('common.address')}
                </Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="address"
                    handleOnChange={(event) =>
                      handleEdit(event, { field: 'address' })
                    }
                    value={values?.address}
                    as={Input}
                    type="text"
                  />
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              {customProps?.isEdit && (
                <FormStyle.default.FieldsWrapper
                  width="100%"
                  className="fieldSection"
                >
                  <Label htmlFor="timeZone" variant="body1">
                    {i18n.t('common.timzone')}
                  </Label>
                  <FormStyle.default.TextWrapper>
                    <Field
                      name="timeZone"
                      value={values?.timeZone}
                      as={Input}
                      disabled
                      type="text"
                    />
                  </FormStyle.default.TextWrapper>
                </FormStyle.default.FieldsWrapper>
              )}
              <FormStyle.default.FieldsWrapper
                width="100%"
                className="fieldSection"
              >
                <Label htmlFor="city" variant="body1">
                  {i18n.t('common.city')}
                </Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="city"
                    handleOnChange={(event) =>
                      handleEdit(event, { field: 'city' })
                    }
                    value={values?.city}
                    as={Input}
                    type="text"
                  />
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              <FormStyle.default.FieldsWrapper
                width="100%"
                className="fieldSection"
              >
                <Label htmlFor="state" variant="body1">
                  {i18n.t('common.state')}
                </Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="state"
                    onChange={(event) => handleEdit(event, { field: 'state' })}
                    value={values?.state}
                    as={Select}
                    placeholder={i18n.t('common.pleaseSelect')}
                    suggestions={customProps.states}
                    id="terminalState"
                  />
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              <FormStyle.default.FieldsWrapper
                width="100%"
                className="fieldSection"
              >
                <Label htmlFor="zipCode" variant="body1">
                  {i18n.t('common.zipCode')}
                </Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="zipCode"
                    handleOnChange={(event) =>
                      handleEdit(event, { field: 'zipCode' })
                    }
                    value={values?.zipCode}
                    as={Input}
                    type="text"
                  />
                  {showError(
                    'zipCode',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              {customProps?.isCrudRestricted && !customProps?.neglectAccessLevel && (
                <FormStyle.default.FieldsWrapper
                  width="100%"
                  className="fieldSection"
                >
                  <Label htmlFor="fleetManagerIds" variant="body1">
                    {i18n.t('departments.fleetManagers')}
                  </Label>
                  <FormStyle.default.TextWrapper>
                    <Field
                      name="fleetManagerIds"
                      onChange={(event) =>
                        handleEdit(event, { field: 'fleetManagerIds' })
                      }
                      value={values?.fleetManagerIds}
                      as={Select}
                      placeholder={i18n.t('common.pleaseSelect')}
                      isMulti
                      suggestions={customProps.fleetManagerList}
                      id="fleetManager"
                    />
                  </FormStyle.default.TextWrapper>
                </FormStyle.default.FieldsWrapper>
              )}

              <FormStyle.default.ButtonsWrapper>
                <Button
                  type="submit"
                  className="buttonStyle"
                  label={
                    customProps.isEdit
                      ? i18n.t('common.update')
                      : i18n.t('common.add')
                  }
                  onClick={() => {
                    const validateResponse = validateForm();
                    validateResponse.then((data) => {
                      submitAction(data);
                    });
                  }}
                />
              </FormStyle.default.ButtonsWrapper>
            </div>
          </div>
        </div>
      </Styled.UploadWrapper>
    </Styled.PopupUpload>
  );
};

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  customProps: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};

export default RenderComponent;
