import React, { useEffect, lazy } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
// import Styled from './style';
import ParentStyled from './style';
import { loadStyle, getNewObject, loadPageConfig } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'pages/web/AlertMessage');
const Title = lazy(() => import(`@/components/Title`));

const AlertMessage = (parentProps) => {
  const { fetchAlertMessageData, alertMessage, theme, i18n } = parentProps;
  const match = useParams();
  useEffect(() => {
    fetchAlertMessageData({
      apiId: 'fetchAlertMessageData',
      data: { id: match.id }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Styled.Wrapper>
      <Title header="alerts" i18n={i18n} />
      <Box style={{ ...theme.palette.contentStyle }}><span className="alertMessage">{alertMessage}</span></Box>
    </Styled.Wrapper>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    alertMessage: state.alertList.alertMessage
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchAlertMessageData: (data) =>
      dispatch.alertList.fetchAlertMessageData(data)
  };
};

export default connect(mapState, mapDispatch)(AlertMessage);
AlertMessage.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  alertMessage: PropTypes.array.isRequired,
  fetchAlertMessageData: PropTypes.func.isRequired
};
