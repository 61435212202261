import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import { getStyle } from '../../../utils/utils';

const Wrapper = styled(Box)`
  ${({ theme }) => `
  min-height: calc(100vh - 77px);
  .tabs {
    .MuiTab-wrapper {
      font-size: 22px;
      text-transform: capitalize !important;
    }
    .MuiPaper-root.MuiAppBar-positionStatic {
      padding: 0px 1%;
      padding-top: 20px;
      background: transparent;
    }
    .MuiButtonBase-root.MuiTab-root {
      width:13%;
    }
  }
  .notSubscriped {
    min-height: ${theme.palette.companyDashboard};
  }
  .tooltiptext {
    visibility: hidden;
    width: 100px;
    background: ${getStyle(
      '',
      (theme.palette.primary || {}).main,
      theme.colors.GREY_ONE
    )};
    color: ${theme.colors.WHITE};
    text-align: center;
    padding: 2px 0;
    border-radius: 0px;
    border: solid 1px ${theme.colors.WHITE};
    position: absolute;
    z-index: 1;
    right: 0;
    top: 35px !important;
    font-size: 15px;
  }
  .iconContainer:hover + .tooltiptext {
      visibility: visible;
    }
  }
  .iconContainer:first-child {
    margin-right: 8px;
  }
  .viewAll{
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
    justify-content: flex-end;
    position: relative;
    padding-bottom: 10px;
    .MuiGrid-container {
      width: auto;
      .MuiAvatar-root{
        width:32px
        height: 32px;

      }
    }
    .normalCheckbox span {
      padding: 0 5%;
    }
    .clearAll {
      margin-right: 10px;
    }
    .enable {
      width: auto;
      text-align: right;
    }
  }
  .seatbeathStatus {
    padding: 5px 18px;
    width: 156px;
    display: inline-block;
    text-align: center;
    font-size: 13px !important;
    color: ${theme.colors.WHITE};
  }
  .noData {
    background: ${theme.colors.GREY_DATA};
  }
  .notBuckled {
    background: ${theme.colors.RED};
  }
  .buckled {
    background: ${theme.colors.BUCKLED_GREEN};
  }
  .viewReportNewTab {
    cursor:pointer;
  }
  `}
`;

const HeaderWrapper = styled.ul`
  ${({ theme }) => `
  list-style: none;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
  flex-wrap: wrap;
  margin-top: 0;
  width: 100%;
  margin-bottom: 0;
  border-left: 1px solid ${theme.colors.LIGHT_BORDER};
  padding: 0.4% 0.9%;
  padding-right: 3%;
  justify-content: flex-end;
  .clutchOnly {
    display: block;
    align-self: center;
    // font-size: 14px;
    font-size: ${theme.palette.headerFont};
  }
  @media (max-width: ${theme.breakpoints.values.xxl + 20}px) {
    padding-right: 0;
  }
  li {
    border-right: 0;
    padding: 0 16px;
    flex: 0 0 auto;
    color: ${theme.colors.BLACK};
    text-align: center;
    align-self: baseline;
    @media (max-width: ${theme.breakpoints.values.xxl + 20}px) {
      padding: 0 9px;
    }
    :last-child {
      display: flex;
      flex-direction: column-reverse;
    }
    .totalAmount {
      color: ${theme.colors.SECONDARY_MAIN} !important;
      font-size: 22px !important;
    }
    .noLink {
      color: ${theme.colors.BLACK} !important;
    }
    .total {
      font-size: 42px !important;
    }
    div:first-child {
      color: ${theme.colors.BLACK};
      font-size: ${theme.palette.subTitle};
      font-weight: normal;
      @media (max-width: ${theme.breakpoints.values.md - 1}px) {
        font-size: 12px;
      }
    }
    div:last-child {
      color: ${theme.colors.BLACK};
      font-size: 32px;
      font-weight: bold;
      @media (max-width: ${theme.breakpoints.values.md - 1}px) {
        font-size: 14px;
      }
    }
  }
  a{
    display: flex;
    flex-direction: column-reverse;
    :hover {
      text-decoration: none;
    }
  }
  .total {
    color: ${theme.colors.SECONDARY_MAIN} !important;
  }
  .noLink {
    color: ${theme.colors.PRIMARY_CONTRAST} !important;
  }
  `}
`;

const MapWrapper = styled.div``;
const SummarySection = styled.div`
  .spireonOnly {
    display: none;
  }
`;
const Styled = { Wrapper, HeaderWrapper, MapWrapper, SummarySection };

export default Styled;
