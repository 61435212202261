import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
  ${({ theme }) => `
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex; 
  .filterWrapper{
    padding-bottom: 3%;
    width: 100%;
  }
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  @media (max-width: ${theme.breakpoints.values.md - 1}px) {
    justify-content: flex-start;
  }
  .filterForm {
    flex: 0 0 92%;
    margin-right: 0 !important;
    @media (min-width: ${theme.breakpoints.values.md}px) and (max-width: ${
    theme.breakpoints.values.mlg
  }px) {
      flex: 0 0 90%;
    }
    @media (max-width: ${theme.breakpoints.values.md - 1}px) {
      flex: 0 0 100% !important;
    }
    .MuiTypography-body1 {
      font-size: 20px;
    }
    .MuiButtonBase-root {
      margin-top: 30px;
    }
    .select__control{
      margin-bottom: 0px;
    }
    div[type='date'],
    div[type='select'] {
      width: 19%;
      margin-right: 1%;
      flex-direction: column;
      @media (min-width: ${theme.breakpoints.values.md}px) and (max-width: ${
    theme.breakpoints.values.mlg
  }px) {
        width: 18%;
      }
      @media (min-width: ${theme.breakpoints.values.smd}px) and (max-width: ${
    theme.breakpoints.values.md - 1
  }px) {
        width: 32%;
      }
      @media (max-width: ${theme.breakpoints.values.smd}px) {
        width: 100%;
      }
    }
    div[type='submit'],
    div[type='reset'] {
      margin-right: 0;
      @media (max-width: ${theme.breakpoints.values.md - 1}px) {
        padding-top: 8%;
      }
    }
  }

  .MuiAvatar-root {
    width: 30px;
    height: auto;
  }
  .MuiBox-root {
    margin-right: 15px;
  }
  `}
`;

const TableWrapper = styled.div`${({ theme }) => `
  .quickFilter {
    display: none;
  }
  .descPopUp{
    color: ${theme.colors.SECONDARY_MAIN};
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
`}`;

const Styled = { Wrapper, TableWrapper };

export default Styled;
