import {
  addUserApi,
  getUserDataApi,
  documentUploadApi,
  documentDeleteApi,
  getFeatureFlagsApi
} from '../../../../config/apis';

const initialState = {};

export const user = {
  state: { ...initialState },
  reducers: {
    addSuccess(state, payload) {
      return {};
    },
    addFailed() {
      return { ...initialState };
    },
    getSuccess(state, payload) {
      return { ...state, ...payload };
    },
    getFailed() {
      return { ...initialState };
    },
    addFailed() {
      return { ...initialState };
    },
    saveFeatureFlags(state, payload) {
      return {
        ...state,
        featureFlags: { ...payload}
      }
    },
    updateLocalDocumentList(state, response) {
      const documents = state.documentsList
        ? state.documentsList.map((item) => {
            if (item.type.name === response?.response?.type?.name) {
              return { ...response.response };
            } else if (item.id === response?.id) {
              if (response?.isDelete) {
                return {
                  ...item,
                  id: null,
                  name: null,
                  url: null,
                  uploaded: null,
                  expiration_date: null
                };
              } else {
                return { ...item };
              }
            } else {
              return { ...item };
            }
          })
        : [{ ...response.response }];
      return { ...state, documentsList: [...documents] };
    },
    saveFailed() {
      return { ...initialState };
    }
  },
  effects: (dispatch) => ({
    async saveData(payload, rootState) {
      try {
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: addUserApi,
            data: payload.data.data
          },
          id: payload.apiId
        });
        // response && this.addSuccess(response);
        payload.callback && payload.callback(payload.data.data);
      } catch (error) {
        // this.addFailed();
      }
    },
    async fetchData(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: getUserDataApi
          },
          id: payload.apiId
        });
        this.getSuccess(response);
      } catch (error) {
        this.getFailed();
      }
    },
    async fetchFeatureFlags(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...getFeatureFlagsApi,
              url: `${getFeatureFlagsApi.url}?companyId=${payload.data}`
            }
          },
          id: payload.apiId
        });
        this.saveFeatureFlags(response);
      } catch (error) {
        // this.getFailed();
      }
    },
    async saveDocument(payload) {
      try {
        const data = payload.data.data;
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: documentUploadApi,
            data: data
          },
          id: payload.apiId,
          // callback: payload.callback
        });
        response && this.updateLocalDocumentList({ response });
      } catch (error) {
        // this.saveFailed();
      }
    },
    async deleteDocument(payload) {
      try {
        const url = documentDeleteApi.url.split('{')[0];
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: { ...documentDeleteApi, url: url + payload.data.id }
          },
          id: payload.apiId
        });
        this.updateLocalDocumentList({
          isDelete: true,
          id: payload.data.id
        });
      } catch (error) {
        // this.deleteFailed();
      }
    },
    async removeApiStatus(){
      try {
        await dispatch.asyncRequests.clearApiStatus();
      } catch (error) {}
    }
  })
};
