import styled from 'styled-components';
import Box from '@material-ui/core/Grid';
import { getStyle } from '../../../../utils/utils';

const IconContainer = styled(Box)`
  ${({ theme, tableicon }) => `
  .MuiAvatar-img {
    cursor: pointer;
  }
  .addFilter{
    img{
      filter: invert(1);
    }
  }
  .MuiAvatar-rounded {
    border-radius: ${tableicon ? '0px' : '4px'};
  }
  .MuiAvatar-root {
    width: ${tableicon ? '15px !important' : '20px'};
    height: ${tableicon ? 'auto !important' : '20px'};
  }
  .tooltiptext {
    visibility: hidden;
    width: 50px;
    background: ${getStyle(
      '',
      (theme.palette.primary || {}).main,
      theme.colors.PRIMARY_MAIN
    )};
    color: ${theme.colors.WHITE};
    text-align: center;
    padding: 2px 0;
    border-radius: 0px;
    border: solid 1px ${theme.colors.WHITE};
    position: absolute;
    z-index: 1;
    right: 170px !important;
    top: 140px !important;
  }

  .MuiAvatar-root:hover {
    .tooltiptext {
      visibility: visible;
    }
  }
  `}
`;

export default IconContainer;
