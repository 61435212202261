import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import TopActions from './TopActions';
const layout = window?.$environment?.CURRENT_LAYOUT;
const Label = lazy(() => import(`@/components/UI/Label/${layout}`));
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Breadcrumb = lazy(() => import(`@/components/Breadcrumb`));

const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Breadcrumb filters={filters} />
      <Component {...contentProps} />
    </Box>
  );
};
export default Index;

const Component = ({
  notInUseVehicles,
  filterSuccess,
  Styled,
  i18n,
  topAction,
  pagination,
  setPagination
}) => {
  const match = useParams();
  const columns = [
    {
      id: 'unit_id',
      numeric: false,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('vehicles.unitId')
    },
    {
      id: 'driversCount',
      numeric: false,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('vehicles.drivers')
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('vehicles.type')
    },
    {
      id: 'vin',
      numeric: false,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('vehicles.vin')
    },
    {
      id: 'year',
      numeric: true,
      disablePadding: false,
      label: i18n.t('vehicles.year'),
      needSorting: true
    },
    {
      id: 'manufact',
      numeric: false,
      disablePadding: false,
      label: i18n.t('vehicles.manufacturer'),
      needSorting: true
    },
    {
      id: 'model',
      numeric: false,
      disablePadding: false,
      label: i18n.t('vehicles.model'),
      needSorting: true
    }
  ];
  const generateRows = () => {
    return notInUseVehicles?.notInUseVehiclesFilteredList || [];
  };

  const information = i18n.t('common.tableEmptyMessage');
  return (
    <>
      <Styled.Wrapper>
        {topAction && (
          <TopActions
            {...{
              filterSuccess,
              Styled,
              i18n,
              notInUseVehicles,
              setPagination
            }}
          />
        )}
        <Styled.TableWrapper>
          <Styled.LabelWrap>
            <Label className="logsInfoDisplay">
              {i18n.t(`dashboard.notInUseVehicles.listHeading.INSHOP`)}
            </Label>
          </Styled.LabelWrap>
          <Table
            type="client"
            title=""
            columns={[...columns]}
            data={generateRows()}
            emptyMessage={information}
            actions={[]}
            defaultSortBy={{ sortBy: 'unit_id', order: 'asc' }}
            itemsPerPage={10}
            i18n={i18n}
            pageinationReset={pagination}
            pagenationClicked={(e) => {
              setPagination(!e);
            }}
          />
        </Styled.TableWrapper>
      </Styled.Wrapper>
    </>
  );
};

Component.propTypes = {
  vehicles: PropTypes.array.isRequired,
  filterSuccess: PropTypes.func.isRequired
};
