
  const initialState = { list: [], error: null };
  
  export const dcaList = {
      state: { ...initialState },
      reducers: {
          getSuccess(state, payload) {
              let response = payload.content;
              return {
                  ...state,
                  totalRecords: payload.totalElements,
                  list: [...response],
              };
          },
          setErrorFlg(state, payload) {
            return state;
          },
          getFailed(state) {
            return { ...initialState };
          },
          savePayload(state, payload) {
            return { ...state, dtaPayload: payload}
          },
      },
      effects: (dispatch) => ({
        async fetchData(payload, rootState) {
          this.setErrorFlg(false);
          this.savePayload(payload?.data);
          try {
            const getApi = await import(
              `../../../config/apis/${window?.$environment?.SERVICE_PROVIDER}`
            );
            const apiId = payload?.apiId;
            const apiObject = getApi.default(apiId);
            const response = await dispatch.asyncRequests.handleAsyncRequests({
              payload: {
                api: apiObject,
                data: payload.data
              },
              callback: payload.callback,
            });
            if (response?.content)  {
              this.setErrorFlg(response.iftaErrorExists)
              this.getSuccess(response);
            }
          } catch (error) {
            this.setErrorFlg(false);
          }
        },
      })
  };
  