import {
    addTruckInfoApi,
    getTruckInfoApi,
    getTruckTypesApi,
    getModelApi,
    getMakeApi,
    getAllTrucksApi,
    updateAllTrucksApi,
    connectionTypeApi
  } from '../../../../config/apis';

  const initialState = {
    addTruckInfoData: {},
    states: [],
    selectedRow: { index: -1, row: {} },
    getTruckInfoData: [],
    truckTypes: [],
    make: [],
    model: {},
    onboardingTrucks: [],
    updateSuccess: null,
    connectionType: []
  };

  export const truckInfo = {
    state: { ...initialState },
    reducers: {
      listSuccess(state, payload) {
        return { ...state, addTruckInfoData: {...payload}};
      },
      listFailed(state) {
        return { ...initialState, getTruckInfoData: [...state.getTruckInfoData]};
      },
      getSuccess(state, payload) {
        return { ...state, getTruckInfoData: [...payload]};
      },
      getTruckSuccess(state, payload ) {
        return { ...state, truckTypes: [...payload]};
      },
      getTruckModelSuccess(state, payload ) {
        return { ...state, model: {...payload}};
      },
      getTruckMakeSuccess(state, payload ) {
        return { ...state, make: [...payload]};
      },
      getAllTrucksSuccess(state, payload) {
        return { ...state, onboardingTrucks: [...payload]};
      },
      updateAllTrucksSuccess(state, payload) {
        return { ...state, onboardingTrucks: [...state.onboardingTrucks], updateSuccess: payload};
      },
      getAllConnectionTypeSuccess(state, payload) {
        return { ...state, connectionType: [...payload]};
      },
      clearPost(state) {     
        return {...state, addTruckInfoData:{}}
      }
    },
    effects: (dispatch) => ({
      async addTruckInfo(payload) {
        try {
          const response = await dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              api: addTruckInfoApi,
              data: payload.data
            },
            id: payload.id
          });
          response && this.listSuccess(response);
        } catch (error) {
          this.listFailed();
        }
      },
      async getTruckInfoData(payload) {
        try {
          const response = await dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              api: getTruckInfoApi,
              data: payload.data
            },
            id: payload.id
          });
          response && this.getSuccess(response);
        } catch (error) {
          this.listFailed();
        }
      },
      async getTruckTypesData(payload) {
        try {
          const response = await dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              api: getTruckTypesApi
            },
            id: payload.id
          });
          response && this.getTruckSuccess(response);
        } catch (error) {
          this.listFailed();
        }
      },
      async getTruckModelData(payload) {
        try {
          const response = await dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              api: getModelApi
            },
            id: payload.id
          });
          response && this.getTruckModelSuccess(response);
        } catch (error) {
          this.listFailed();
        }
      },
      async getTruckMakeData(payload) {
        try {
          const response = await dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              api: getMakeApi
            },
            id: payload.id
          });
          response && this.getTruckMakeSuccess(response);
        } catch (error) {
          this.listFailed();
        }
      },
      async getAllTruckData(payload) {
        try {
          const response = await dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              api: getAllTrucksApi
            },
            id: payload.id
          });
          response && this.getAllTrucksSuccess(response);
        } catch (error) {
          this.listFailed();
        }
      },
      async updateAllTruckData(payload) {
        try {
          const response = await dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              api: updateAllTrucksApi,
              data: payload.data
            },
            id: payload.id
          });
          response && this.updateAllTrucksSuccess(response);
        } catch (error) {
          this.listFailed();
        }
      },
      async getAllConnectionType(payload) {
        try {
          const response = await dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              api: connectionTypeApi
            },
            id: payload.id
          });
          response && this.getAllConnectionTypeSuccess(response);
        } catch (error) {
          this.listFailed();
        }
      },
      async clearValues() {
        this.clearPost()
      }
    })
  };
