import styled from 'styled-components';
import Parent from './style';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
 padding-left: 1%;
.status-wrapper {
  .buttonStyle{
    background-color: #fff
    font-size: 14px;
    &:hover {
      background: ${theme.colors.BACKGROUND_MENU} !important;
    }
  }
  // padding-left: 1%;
}
`}
`;
const SearchWrapper = styled(Parent.SearchWrapper)`

`;


const TableWrapper = styled(Parent.TableWrapper)`
..MuiTableBody-root .MuiTableRow-root .MuiTableCell-root{
    padding: 15px 7px !important;
}
.MuiTable-root .MuiTableHead-root .MuiTableRow-root .MuiTableCell-head {
  padding: 15px 22px !important;
}
.MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
  .historyIcon {
    width: 20px;
    height: 20px;
    color: #757575;
  }
}
`;
const TopWrapper = styled(Parent.TopWrapper)`
 justify-content: flex-end !important;
 .status-btn-wrapper .MuiButton-root {
  // text-transform: capitalize;
  font-size: 14px !important;
}
`;
const LabelWrap = styled(Parent.LabelWrap)`
 
`;



const Styled = {
    Wrapper,
    SearchWrapper,
    TopWrapper,
    TableWrapper,
    LabelWrap
  };

export default Styled;
