const initialState = {};

export const driverStatus = {
  state: { ...initialState },
  reducers: {
    listSuccess(state, payload) {
      return { ...payload };
    },
    listFailed() {
      return { ...initialState };
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload, rootState) {
      try {
        const getApi = await import(
          `../../../config/apis/${window.$environment.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('driverStatusApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...apiObject,
              url: apiObject.url.replace(
                '{driverState}',
                payload?.data?.status
              )
            },
            data: payload?.data?.request
          },
          id: payload.apiId
        });
        response && this.listSuccess(response);
      } catch (error) {
        this.listFailed();
      }
    }
  })
};
