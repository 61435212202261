import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';

import Styled from './style';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Input = lazy(() => import(`@/components/UI/Input/${layout}`));
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Select = lazy(() => import(`@/components/UI/Select/${layout}`));
const SubHeader = lazy(() => import(`@/components/SubHeader/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const RenderComponent = ({
  values,
  handleEdit,
  validateForm,
  customProps,
  submitAction,
  errors,
  isNotValid
}) => {
  const { i18n } = useTranslation();
  const showError = (field, validationErrors, isNotValid, apiError) => {
    if (validationErrors[field] && !isNotValid) {
      return <Message type="error" message={errors[field]} />;
    }
    if (apiError?.[field]) {
      return <Message type="error" message={apiError[field]} />;
    }
  };
  return (
    <Styled.PopupUpload>
      <SubHeader
        text={
          customProps.isEdit
            ? i18n.t('departments.editDepartment')
            : i18n.t('departments.addDepartment')
        }
        className="subheaderSection"
        variant="h2"
      />
      <Styled.UploadWrapper>
        <div className="uploadFields">
          <FormStyle.default.FieldsWrapper
            width="100%"
            className="fieldSection"
          >
            <Label htmlFor="terminal" variant="body1">
              {i18n.t('common.terminal')}
            </Label>
            <FormStyle.default.TextWrapper>
              <Field
                name="terminal"
                handleOnChange={(event) =>
                  handleEdit(event, { field: 'terminal' })
                }
                value={values?.terminal}
                disabled
                as={Input}
                type="text"
              />
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper
            width="100%"
            className="fieldSection"
          >
            <Label htmlFor="departmentName" className="departmentName" variant="body1">
              {i18n.t('common.department')}
            </Label>
            <FormStyle.default.TextWrapper>
              <Field
                name="departmentName"
                handleOnChange={(event) =>
                  handleEdit(event, { field: 'departmentName' })
                }
                value={values?.departmentName}
                as={Input}
                type="text"
              />
              {showError(
                'departmentName',
                errors,
                isNotValid,
                customProps.hasApiStatus?.errorMessage
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          {customProps?.isCrudRestricted && !customProps?.neglectAccessLevel && (
            <FormStyle.default.FieldsWrapper
              width="100%"
              className="fieldSection"
            >
              <Label htmlFor="fleetManagerIds" variant="body1">
                {i18n.t('departments.fleetManagers')}
              </Label>
              <FormStyle.default.TextWrapper>
                <Field
                  name="fleetManagerIds"
                  onChange={(event) =>
                    handleEdit(event, { field: 'fleetManagerIds' })
                  }
                  value={values?.fleetManagerIds}
                  as={Select}
                  placeholder={i18n.t('common.pleaseSelect')}
                  isMulti
                  suggestions={customProps.fleetManagerList}
                />
              </FormStyle.default.TextWrapper>
            </FormStyle.default.FieldsWrapper>
          )}

          <FormStyle.default.ButtonsWrapper>
            <Button
              type="submit"
              className="buttonStyle"
              label={
                customProps.isEdit
                  ? i18n.t('common.update')
                  : i18n.t('common.add')
              }
              onClick={() => {
                const validateResponse = validateForm();
                validateResponse.then((data) => {
                  submitAction(data);
                });
              }}
            />
          </FormStyle.default.ButtonsWrapper>
        </div>
      </Styled.UploadWrapper>
    </Styled.PopupUpload>
  );
};

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  customProps: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};

export default RenderComponent;
