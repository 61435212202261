import { cameraEventsByCount, cameraEventsByTotalCount } from '../../../config/apis';

const initialState = {
    cameraTotalEventData: null,
    eventData: null,
};

export const cameraEvents = {
  state: { ...initialState },
  reducers: {
    saveCameraEventSuccess: (state, payload) => {
      return { ...state, cameraTotalEventData: payload };
    },
    saveEventSuccess: (state, payload) => {
        return { ...state, eventData: payload };
      },
    getFailed: () => {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    async getCameraEventsByTotalCount(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: cameraEventsByTotalCount,
            data: payload.data
          },
          id: payload.apiId
        });
        if (response) {
          this.saveCameraEventSuccess(response);
        }
      } catch (error) {
        this.getFailed();
      }
    },
    async getCameraEventsByCount(payload) {
        try {
          const response = await dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              api: cameraEventsByCount,
              data: payload.data

            },
            id: payload.apiId
          });
          if (response) {
            this.saveEventSuccess(response);
          }
        } catch (error) {
            this.getFailed();
        }
      },
  })
};
