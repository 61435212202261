import React, { useEffect, lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';

import { useHistory, useParams } from 'react-router-dom';
import useTheme from '@material-ui/core/styles/useTheme';
import { useTranslation } from 'react-i18next';

import { hasValue, isInsuracePortalUser, generateDropdownOptions } from '@/utils/utils';
const Title = lazy(() => import(`@/components/Title`));
const Content = lazy(() =>
  import(`@/components/modules/TrailerManagement/Component`)
);

const EditTrailer = ({ apiStatus, fetchCompanyList, fetchEditTrailerInsuranceData, fetchEditTrailerData, getDocumentToken, trailer, regions, clearReceipt, ...rest }) => {
  const history = useHistory();
  const theme = useTheme();
  const i18n = useTranslation();
  const match = useParams();

  const getDocumentTokenApiId = 'getDocToken';
  const trailerId = match.id;
  const fetchEditTrailerDataApi = 'fetchEditTrailerData';
  const fetchEditTrailerInsuranceDataApi = 'fetchEditTrailerInsuranceData';

  const hasFetchApiStatus = hasValue(
    apiStatus.status,
    'api',
    fetchEditTrailerDataApi
  );

  useEffect(() => {
    isInsuracePortalUser() ? fetchEditTrailerInsuranceData({
      data: { id: trailerId },
      apiId: fetchEditTrailerInsuranceDataApi
    }) : fetchEditTrailerData({
      data: { id: trailerId },
      apiId: fetchEditTrailerDataApi
    }) 
    getDocumentToken({ apiId: getDocumentTokenApiId })
    fetchCompanyList({ apiId: 'fetchFleetCompanies' });
    clearReceipt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let object = { ...disabledCriteria };
    if (trailer?.accessLevel < 1) {
      object.regionId = true;
      object.terminalId = true;
      object.departmentId = true;
      object.subDepartmentId = true;
    } else if (trailer?.accessLevel < 2) {
      object.regionId = true;
      object.terminalId = true;
      object.departmentId = true;
      object.subDepartmentId = false;
    } else if (trailer?.accessLevel < 3) {
      object.regionId = true;
      object.terminalId = true;
      object.departmentId = false;
      object.subDepartmentId = false;
    } else if (trailer?.accessLevel < 4) {
      object.regionId = true;
      object.terminalId = false;
      object.departmentId = false;
      object.subDepartmentId = false;
    } else {
      object.regionId = false;
      object.terminalId = false;
      object.departmentId = false;
      object.subDepartmentId = false;
    }
    setDisabledCriteria(object);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trailer?.accessLevel]);

  const [disabledCriteria, setDisabledCriteria] = useState({
    regionId: false,
    terminalId: false,
    departmentId: false,
    subDepartmentId: false
  });

  const recallUpdate = (company) => {
    fetchEditTrailerInsuranceData({
      apiId: fetchEditTrailerInsuranceDataApi, data: {
        id: trailerId,
        company,
        recall: true
      }
    });
  }
  return (
    <>
      <Title header={'trailers.editHeading'} i18n={i18n} />
      <Box style={{ ...theme.palette.contentStyle }}>
        <Content
          {...rest}
          recallUpdate={recallUpdate}
          apiStatus={apiStatus}
          fetchEditTrailerData={fetchEditTrailerData}
          regions={generateDropdownOptions(
            isInsuracePortalUser() && regions?.data?.regions?.length
              ? regions.data.regions
              : trailer.regions,
            'regionName',
            'id'
          )}
          terminals={generateDropdownOptions(
            regions?.data?.terminals?.length ? regions.data.terminals : trailer.terminals,
            'terminalName',
            'terminalId'
          )}
          departments={generateDropdownOptions(
            regions?.data?.departments?.length ? regions.data.departments :
              !regions?.data?.departments?.length && regions?.data?.terminals?.length ? [] :
                trailer.departments,
            'departmentName',
            'departmentId'
          )}
          subDepartments={generateDropdownOptions(
            regions?.data?.subDepartments?.length ? regions.data.subDepartments :
              !regions?.data?.subDepartments?.length && regions?.data?.departments?.length ? [] :
                trailer.subDepartments,
            'subDepartmentName',
            'subDepartmentId'
          )}
          disabledCriteria={disabledCriteria}
          trailer={trailer}
        />
      </Box>
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    regions: state.regions,
    documents: state.documents,
    trailer: state.trailerEdit,
    fleetCompanies: state.fleetCompanyList.companyList,
    logBook: state.logBook,
    receipt: state.viewReport
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchTerminals: (data) => dispatch.regions.fetchTerminals(data),
    fetchDepartments: (data) => dispatch.regions.fetchDepartments(data),
    fetchSubDepartments: (data) => dispatch.regions.fetchSubDepartments(data),
    fetchEditTrailerData: (data) => dispatch.trailerEdit.fetchData(data),
    saveTrailerData: (data) => dispatch.trailerEdit.saveData(data),
    isValidVin: (data) => dispatch.trailers.isValidVin(data),
    getRegions: (data) => dispatch.regions.fetchRegionsByCompany(data),
    fetchEditTrailerInsuranceData: (data) => dispatch.trailerEdit.fetchInsuranceData(data),
    fetchCompanyList: (data) => dispatch.fleetCompanyList.fetchCompanyList(data),
    saveDocument: (data) => dispatch.trailerEdit.saveDocument(data),
    deleteDocument: (data) => dispatch.trailerEdit.deleteDocument(data),
    getDocumentToken: (data) => dispatch.logBook.documentToken(data),
    downloadDocument: (data) => dispatch.viewReport.downloadReport(data),
    clearReceipt: () => dispatch.viewReport.clearReceipt()
  };
};

export default connect(mapState, mapDispatch)(EditTrailer);
EditTrailer.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  fetchTerminals: PropTypes.func.isRequired,
  fetchDepartments: PropTypes.func.isRequired,
  saveDocument: PropTypes.func.isRequired,
  fetchEditTrailerInsuranceData: PropTypes.func.isRequired,
  deleteDocument: PropTypes.func.isRequired,
  getRegions: PropTypes.func.isRequired,
  fetchCompanyList: PropTypes.func.isRequired,
  fetchEditTrailerData: PropTypes.func.isRequired,
  saveTrailerData: PropTypes.func.isRequired,
  fetchSubDepartments: PropTypes.func.isRequired
};
