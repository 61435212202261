import React from 'react';
import PropTypes from 'prop-types';
import { pageUrls } from '@/config/constants/keys';
import Box from '@material-ui/core/Box';

const Component = ({ Styled, theme }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Styled.Wrapper>
        <a href={pageUrls.subscriptionLink}>
          UPGRADE SUBSCRIPTION TO ACCESS THIS FEATURE
        </a>
      </Styled.Wrapper>
    </Box>
  );
};

export default Component;

Component.propTypes = {
  message: PropTypes.string.isRequired
};
