import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)``;
const SearchWrapper = styled(Parent.SearchWrapper)``;
const TopWrapper = styled(Parent.TopWrapper)`
  flex: 0 0 55%;
`;
const TableWrapper = styled(Parent.TableWrapper)`
  ${({ theme }) => `
.quickFilter{
    display: block;
    height: 20px;
    background: ${theme.colors.LINK_COLOR};
}
padding: 0;
.MuiPaper-root {
    padding: 0 3%;
}

`}
`;
const LabelWrap = styled(Parent.LabelWrap)``;

const AddWrapper = styled.div`
  right: 50px;
  position: absolute;
`;
const Styled = {
  Wrapper,
  SearchWrapper,
  TopWrapper,
  TableWrapper,
  LabelWrap,
  AddWrapper
};
export default Styled;
