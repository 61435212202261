import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';

import RenderComponent from './RenderComponent';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));

const Component = ({
  closeModal,
  saveParts,
  callback,
  currentItem,
  isEdit,
  Styled
}) => {
  const match = useParams();
  const { i18n } = useTranslation();
  const apiId = 'saveParts';

  return (
    <Styled.Wrapper>
      <Form
        type="server"
        direction="column"
        initialValues={{
          id: currentItem?.id,
          partName: currentItem?.partName,
          partsInInventory: currentItem?.partsInInventory,
          cost: currentItem?.cost
        }}
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {
          let finalData = {
            ...fields,
            partsInInventory: fields?.partsInInventory? +fields.partsInInventory: ''
          };
          saveParts({
            apiId,
            data: {
              ...finalData
            },
            callback: callback,
            i18n: i18n
          });
          closeModal();
        }}
        customProps={{
          isEdit
        }}
        handleClear={() => {}}
        validationSchema={Yup.object().shape({
          partName: Yup.string().required(
            i18n.t('common.fieldRequiredMessage')
          ),
          partsInInventory: Yup.string().required(
            i18n.t('common.fieldRequiredMessage')
          ),
          cost: Yup.string()
            .matches(/^\d*(\.\d+)?$/, i18n.t('parts.costError'))
            .required(i18n.t('common.fieldRequiredMessage'))
        })}
      />
    </Styled.Wrapper>
  );
};

export default Component;

Component.propTypes = {
  apiStatus: PropTypes.object,
  callback: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  saveParts: PropTypes.func,
  currentItem: PropTypes.object
};
