import {
  fetchDailyDocumentsApi,
  dailyDocumentsUploadApi,
  dailyDocumentsDeleteApi
} from '../../../../config/apis';
import moment from 'moment';
import { UI_DATE_FORMAT } from '@/config/constants/static';
const initialState = {
  dailyDocumentsList: [],
  dailyDocumentsFilteredList: [],
  dailyDocumentsOtherList: [],
  dailyDocumentsOtherFilteredList: [],
  documentTypesList: [],
  driversList: []
};
const isEs = () => localStorage.getItem(window.$environment?.SERVICE_PROVIDER + 'Language') == 'es'
export const dailyDocuments = {
  state: { ...initialState },
  reducers: {
    listSuccess(state, payload) {
      return {
        ...state,
        dailyDocumentsList: [...payload.dailyDocumentDtos],
        dailyDocumentsOtherList: [...payload.dailyDocumentOtherDtos],
        dailyDocumentsFilteredList: [...payload.dailyDocumentDtos],
        dailyDocumentsOtherFilteredList: [...payload.dailyDocumentOtherDtos],
        documentTypesList: [...payload.documentTypes],
        driversList: [...payload.drivers]
      };
    },
    filterDriverDateSuccess(state, payload) {
      return {
        ...state,
        dailyDocumentsList: [...payload.dailyDocumentDtos],
        dailyDocumentsOtherList: [...payload.dailyDocumentOtherDtos],
        dailyDocumentsFilteredList: [...payload.dailyDocumentsFilteredList],
        dailyDocumentsOtherFilteredList: [
          ...payload.dailyDocumentsOtherFilteredList
        ],
        documentTypesList: [...payload.documentTypes],
        driversList: [...payload.drivers]
      };
    },
    listFailed() {
      return { ...initialState };
    },
    saveSuccess(state, payload) {
      return { ...state };
    },
    saveFailed(state) {
      return { ...state };
    },
    filterListSuccess(state, list) {
      return {
        ...state,
        dailyDocumentsFilteredList: [...list]
      };
    },
    filterByParams(state, data) {
      return {
        ...state,
        dailyDocumentsFilteredList: [...data?.filtered],
        dailyDocumentsOtherFilteredList: [...data?.filteredOther]
      };
    },
    deleteSuccess(state, payload) {
      return { ...state };
    },
    updateDocumetList(state, response) {
      if (response?.isDelete) {
        return response?.type === 'Other'
          ? {
              ...state,
              dailyDocumentsOtherFilteredList: state.dailyDocumentsOtherFilteredList.filter(
                (item) => item.documentId !== response?.id
              )
            }
          : {
              ...state,
              dailyDocumentsFilteredList: state.dailyDocumentsFilteredList.filter(
                (item) => item.documentId !== response?.id
              ),
              dailyDocumentsList: state.dailyDocumentsList.filter(
                (item) => item.documentId !== response?.id
              )
            };
      } else {
        const newDoc = [
          {
            date: moment(response?.uploaded).valueOf(),
            documentId: response?.id,
            driver:
              state.driversList.filter(
                (item) => item.id === response?.userId
              )[0]?.firstName +
              ' ' +
              state.driversList.filter(
                (item) => item.id === response?.userId
              )[0]?.lastName,
            type: response?.type?.fullName,
            location: response?.location,
            customerName: response?.customerName,
            customerJob: response?.customerJob
          }
        ];
        return newDoc[0].type === 'Other'
          ? {
              ...state,
              dailyDocumentsOtherFilteredList: [
                ...newDoc,
                ...state.dailyDocumentsOtherFilteredList
              ],
              dailyDocumentsOtherList: [...newDoc, ...state.dailyDocumentsOtherList]
            }
          : {
              ...state,
              dailyDocumentsFilteredList: [
                ...newDoc,
                ...state.dailyDocumentsFilteredList
              ],
              dailyDocumentsList: [...newDoc, ...state.dailyDocumentsList]
            };
      }
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: fetchDailyDocumentsApi,
            data: { ...payload.data }
          },
          id: payload.apiId
        });
        if (payload.data?.day || payload.data?.driver) {
          let dailyDocumentResponse = {
            ...response
          };
          let driverName =
            payload.data?.driver &&
            `${
              response?.drivers?.find((x) => x.id == payload.data?.driver)
                ?.firstName
            } ${
              response.drivers.find((x) => x.id == payload.data?.driver)
                ?.lastName
            }`;
          if (payload.data?.day && driverName) {
            dailyDocumentResponse[
              'dailyDocumentsFilteredList'
            ] = response.dailyDocumentDtos.filter(
              (item) =>
                item.driver == driverName &&
                moment(item.date).format(UI_DATE_FORMAT) == payload.data?.day
            );
            dailyDocumentResponse[
              'dailyDocumentsOtherFilteredList'
            ] = response.dailyDocumentOtherDtos.filter(
              (item) =>
                item.driver == driverName &&
                moment(item.date).format(UI_DATE_FORMAT) == payload.data?.day
            );
          } else if (driverName) {
            dailyDocumentResponse[
              'dailyDocumentsFilteredList'
            ] = response.dailyDocumentDtos.filter(
              (item) => item.driver == driverName
            );
            dailyDocumentResponse[
              'dailyDocumentsOtherFilteredList'
            ] = response.dailyDocumentOtherDtos.filter(
              (item) => item.driver == driverName
            );
          } else {
            dailyDocumentResponse[
              'dailyDocumentsFilteredList'
            ] = response.dailyDocumentDtos.filter(
              (item) =>
                moment(item.date).format(UI_DATE_FORMAT) == payload.data?.day
            );
            dailyDocumentResponse[
              'dailyDocumentsOtherFilteredList'
            ] = response.dailyDocumentOtherDtos.filter(
              (item) =>
                moment(item.date).format(UI_DATE_FORMAT) == payload.data?.day
            );
          }
          this.filterDriverDateSuccess(dailyDocumentResponse);
        } else this.listSuccess(response);
      } catch (error) {
        this.listFailed();
      }
    },
    async saveData(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: dailyDocumentsUploadApi,
            data: payload.data.data
          },
          id: payload.apiId,
          callback: payload.callback
        });
        response['customerName'] =
          JSON.parse(payload.data.data?.request)?.customerName || null;
        response['customerJob'] =
          JSON.parse(payload.data.data?.request)?.customerJob || null;
        response['location'] =
          JSON.parse(payload.data.data?.request)?.location || null;
        response && this.fetchData({data:{}});
      } catch (error) {
        this.saveFailed();
      }
    },
    async deleteData(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...dailyDocumentsDeleteApi,
              url: dailyDocumentsDeleteApi.url.replace('{id}', payload.data.id)
            }
          },
          callback: payload.callback,
          id: payload.id
        });
        response && payload.callback('success', isEs() ? 'Eliminado' :'Deleted');
        this.updateDocumetList({
          isDelete: true,
          id: payload.data.id,
          type: payload.data.type
        });
        //remove item
      } catch (error) {
        //handle error
      }
    },
    filterByDriverAndDate(fields, rootState) {
      let data = {
        filtered: rootState.dailyDocuments?.dailyDocumentsList || [],
        filteredOther: rootState.dailyDocuments?.dailyDocumentsOtherList || []
      };
      let driverObj = rootState.dailyDocuments.driversList.filter(
        (item) => item.id == fields.driver
      );
      let driverName =
        driverObj.length &&
        driverObj[0]?.firstName.trim() + ' ' + driverObj[0]?.lastName.trim();
      if (driverName && fields.day) {
        data.filtered = rootState.dailyDocuments?.dailyDocumentsList.filter(
          (item) =>
            item.driver == driverName &&
            moment(parseInt(item.date)).format(UI_DATE_FORMAT) == fields.day
        );
        data.filteredOther = rootState.dailyDocuments?.dailyDocumentsOtherList.filter(
          (item) =>
            item.driver == driverName &&
            moment(parseInt(item.date)).format(UI_DATE_FORMAT) == fields.day
        );
      } else if (driverName) {
        data.filtered = rootState.dailyDocuments?.dailyDocumentsList.filter(
          (item) => item.driver == driverName
        );
        data.filteredOther = rootState.dailyDocuments?.dailyDocumentsOtherList.filter(
          (item) => item.driver == driverName
        );
      } else if (fields.day) {
        data.filtered = rootState.dailyDocuments?.dailyDocumentsList.filter(
          (item) =>
            moment(parseInt(item.date)).format(UI_DATE_FORMAT) == fields.day
        );
        data.filteredOther = rootState.dailyDocuments?.dailyDocumentsOtherList.filter(
          (item) =>
            moment(parseInt(item.date)).format(UI_DATE_FORMAT) == fields.day
        );
      }
      if(fields.eld != 'all'){
        data.filtered = data.filtered.filter((item) => item.type === fields.i18n.t(`eld.${fields.eld}`))
      }      
      this.filterByParams(data);
    }
  })
};
