import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
.fieldWrapper {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        margin-bottom: 73px;
      }
}
.MuiPaper-root {
    margin-bottom: 0px !important;
    margin-top: 15px !important;
    box-shadow: none;
    background: -webkit-linear-gradient(top,#d0d8db 0%,#b3bec3 100%) !important;
    .MuiAccordionSummary-content h2 {
        text-transform: capitalize;
        font-size: 12px !important;
        color: #42505a !important;
        font-weight: bold;
    }
    .MuiIconButton-label {
        .MuiSvgIcon-root {
            fill: #000;
        }
    }
}

.MuiAccordionDetails-root form .buttonWrapper button {
    margin-top: 22px !important;
}

.select__value-container{
    padding: 0px !important;
}

.select__value-container{
    height: auto !important;
}

.select__control{
    height: auto !important;
}

.select__value-container--is-multi div{
    padding: 0px !important;
}

.select__dropdown-indicator svg{
    height: 15px !important;
}

.select__indicators .select__indicator{
    margin-top: 2px;
 }

 .select__indicators .select__indicator svg{
    height: 15px;
 }

 .select__value-container.select__value-container--has-value{
    height: 18px !important;
 }
 .select__value-container.select__value-container--is-multi{
    height: auto !important;
 }
`}
`;

const Styled = { Wrapper };

export default Styled;
