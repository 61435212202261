import React, { lazy, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import {
  getNewObject,
  loadPageConfig,
} from '@/utils/utils';
import { validateOdometer } from '@/config/apis';

const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.addState[0].name}`));
const Content = lazy(() => import(`@/${config.addState[1].name}`));

const AddState = (parentProps) => {
  const {
    fetchVehicles,
    getStates,
    getDrivers,
    addUpdateIFTAState,
    validateOdometer
  } = parentProps;

  const history = useHistory();
  const i18n = useTranslation();
  const theme = useTheme();

  const commonParentProps = { history, i18n, theme };

  const apiId = 'fetchVehicles';
  const fetchStateApiId = 'getStatesApi';
  const driverAPIID = 'getDrivers';

  useEffect(() => {
    getStates(fetchStateApiId);
    getDrivers(driverAPIID);
    fetchVehicles(apiId);
  }, []);

  return (
    <>
      <RenderComponent
        {...config.addState[0]}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        component={Title}
      />
      <RenderComponent
        {...config.addState[1]}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        component={Content}
        addUpdateIFTAState={addUpdateIFTAState}
        validateOdometer={validateOdometer}
      />
    </>
  );
};

const RenderComponent = ({
  name,
  props,
  commonProps,
  title,
  parentProps,
  commonParentProps,
  component,
  ...rest
}) => {
  const Component = component;
  let newProps = props?.length ? getNewObject(parentProps, props) : {};
  newProps = {
    ...newProps,
    ...(commonProps?.length ? getNewObject(commonParentProps, commonProps) : {})
  };
  return <Component {...rest} {...newProps} />;
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    vehicles: state.iftaVehicleInfo,
    states: state.getStates,
    drivers: state.drivers,
  };
};
const mapDispatch = (dispatch) => {
  return {
    addUpdateIFTAState: (data) => dispatch.iftaDetails.addIftaState(data),
    getStates: (data) => dispatch.getStates.getStates(data),
    fetchVehicles: (data) => dispatch.iftaVehicleInfo.fetchData(data),
    getDrivers: () => dispatch.drivers.getDrivers(),
    fetchLatLong: (data) => dispatch.iftaDetails.fetchLatLong(data),
    validateOdometer: (data) => dispatch.iftaDetails.validateOdometer(data),
  };
};

export default connect(mapState, mapDispatch)(AddState);

AddState.propTypes = {
  callback: PropTypes.func.isRequired,
};
