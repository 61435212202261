import {
  radiusComplianceAlertApi,
} from '../../../config/apis';

const initialState = {
  list: [],
  filteredList: [],
  selectedRow: { index: -1, row: {} },
};

export const radiusComplianceAlert = {
  state: { ...initialState },
  reducers: {
    listSuccess(state, payload) {
      return { ...state, list: [...payload], filteredList: [...payload] };
    },
    listFailed() {
      return { ...initialState };
    },
    filterSuccess(state, list) {
      return { ...state, filteredList: [...list] };
    },
    setSelectedItem(state, data) {
      return { ...state, selectedRow: { ...data } };
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: radiusComplianceAlertApi
          },
          id: payload.apiId
        });
        response && this.listSuccess(response.alerts);
      } catch (error) {
        this.listFailed();
      }
    },
  })
};
