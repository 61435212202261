import styled from 'styled-components';
import * as Parent from '../Base/style';

export const PaymentWrapper = styled(Parent.PaymentWrapper)`
.suspended{
    margin-top:0;
}
`;
export const NameWrapper = styled(Parent.NameWrapper)`
#nameOnCard {
    border-radius:24px;
    height:28px;

  }
`;
export const InputWrapper = styled(Parent.InputWrapper)`
 .MuiInputBase-root {
    height:50px;
  }
 `;
