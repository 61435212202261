import React from 'react';
import { getImagePath } from '../../../utils/utils';
import { imageUrls } from '../../../config/constants/keys';

const SuccessIcon = ({ SuccessIconComponent }) => {
  return (
    <SuccessIconComponent.Container>
      <img src={getImagePath(imageUrls.deletePopup)} alt="deleted" />
    </SuccessIconComponent.Container>
  );
};

export default SuccessIcon;
