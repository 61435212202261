import React, { lazy, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { date, number, object, string } from 'yup';


import { pageUrls } from '@/config/constants/keys';
import { BACKEND_DATE_FORMAT } from '@/config/constants/static';
import { objectArrayFromDiffFields } from '@/utils/utils';
import { isEqual } from 'lodash';
import moment from 'moment';
import RenderComponent from './RenderComponent';
const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));

const StateForm = ({
  Styled,
  isEdit = false,
  history,
  apiStatus,
  vehicles,
  states,
  drivers,
  callback,
  addUpdateIFTAState,
  validateOdometer,
  iftaDetails,
  fetchLatLong,
}) => {
  const { i18n } = useTranslation();

  const activeVehicles = objectArrayFromDiffFields(
    vehicles?.vehicles,
    'id',
    'unit_id'
  );
  const deActivatedVehicles = objectArrayFromDiffFields(
    vehicles?.deactivatedVehicles,
    'id',
    'unit_id'
  );

  const stateList = states?.map(e => ({ label: e, value: e }));
  const driversList = drivers?.data;

  const [latLng, setLatLng] = useState(null);

  const [initialValues, setInitialValues] = useState({
    taxable: 'inactive',
  });
  const [error, setError] = useState('');
  const [validations, setValidationsList] = React.useState({
    totalMiles: number(),
    state: object().required(i18n.t('common.fieldRequiredMessage')).test(
      'is-not-unknown',
      i18n.t('common.customStateErrorMessage'),
      (value, ctx) => value?.value !== 'Unknown',
    ),
    vehicle: string().required(i18n.t('common.fieldRequiredMessage')),
    driver: string().required(i18n.t('common.fieldRequiredMessage')),
    date: date().required(i18n.t('common.fieldRequiredMessage')),
    taxable: string().required(),
    addressFrom: string().required(i18n.t('common.fieldRequiredMessage')),
    addressTo: string().required(i18n.t('common.fieldRequiredMessage')),
    startMeter: number().typeError('you must specify a number').required(i18n.t('common.fieldRequiredMessage')).moreThan(-1, i18n.t('iftaMileage.notLessThanZero')),
    endMeter: number().typeError('you must specify a number').required(i18n.t('common.fieldRequiredMessage')).moreThan(0, i18n.t('iftaMileage.notLessThanZero')).when('startMeter', (startMeter, schema) => {
      return schema.test({
        test: (endMeter) => {
          if (!endMeter) return true;
          return startMeter < endMeter;
        },
        message: "end meter should be greater than start meter"
      })
    }),
    startCity: string().required(i18n.t('common.fieldRequiredMessage')),
    endCity: string().required(i18n.t('common.fieldRequiredMessage')),
  });

  useEffect(() => {
    if (iftaDetails?.iftaMileageId) {
      setInitialValues({
        date: iftaDetails?.date,
        totalMiles: iftaDetails?.distanceTravelled,
        driver: driversList.find(e => e?.value === iftaDetails?.driverId),
        endMeter: iftaDetails?.endOdometerReading || 0,
        startMeter: iftaDetails?.startOdometerReading || 0,
        state: { label: iftaDetails?.state, value: iftaDetails?.state },
        taxable: iftaDetails?.taxable ? 'active' : 'inactive',
        vehicle: { label: iftaDetails?.vehicleName, value: iftaDetails?.vehicleId }
      });
      setValidationsList({
        taxable: string().required(),
        startMeter: number().typeError('you must specify a number').required(i18n.t('common.fieldRequiredMessage')),
        endMeter: number().typeError('you must specify a number').required(i18n.t('common.fieldRequiredMessage')).when('startMeter', (startMeter, schema) => {
          return schema.test({
            test: (endMeter) => {
              if (!endMeter) return true;
              return startMeter < endMeter;
            },
            message: i18n.t('iftaMileage.endMeterError')
          })
        }),
        ...(iftaDetails?.isUnknownState ? {
          state: object().required(i18n.t('common.fieldRequiredMessage')).test(
            'is-not-unknown',
            i18n.t('common.customStateErrorMessage'),
            (value, ctx) => value?.value !== 'Unknown',
          ),
          addressFrom: string().required(i18n.t('common.fieldRequiredMessage')),
          addressTo: string().required(i18n.t('common.fieldRequiredMessage')),
          startCity: string().required(i18n.t('common.fieldRequiredMessage')),
          endCity: string().required(i18n.t('common.fieldRequiredMessage')),
        } : {})

      })
    }
  }, [iftaDetails, driversList]);

  const handleSubmit = async (values, { isEdit, iftaDetails }) => {
    setError('');
    if ((!latLng || latLng?.error || latLng?.errors) && (!isEdit || isEdit && iftaDetails?.isUnknownState)) {
      setError(i18n.t('iftaMileage.addressError'));
      return;
    }
    try {
      const validateRequest = {
        "date": moment(values?.date).format(BACKEND_DATE_FORMAT),
        "endOdometerReading": values?.endMeter,
        "startOdometerReading": values?.startMeter,
        "vehicleIdPub": values?.vehicle?.value,
        "iftaMileageId": iftaDetails?.iftaMileageId,
      };
      let validatePayload = {
        apiId: 'validateOdometer',
        param: validateRequest,
      }
      const prevData = {
        endMeter: iftaDetails?.endOdometerReading,
        startMeter: iftaDetails?.startOdometerReading
      }
      const nextData = {
        endMeter: values?.endMeter,
        startMeter: values?.startMeter
      }
      if ((isEdit && !isEqual(prevData, nextData)) || !isEdit) {
        const validateOdometerResult = await validateOdometer(validatePayload);
        if (!validateOdometerResult?.data) {
          setError(validateOdometerResult?.error?.message);
          return;
        }
      }

      let requestData = {
        "date": moment(values?.date).format(BACKEND_DATE_FORMAT),
        "distanceTravelled": values?.totalMiles,
        "driverId": values?.driver?.value,
        "endOdometerReading": values?.endMeter,
        "startOdometerReading": values?.startMeter,
        "state": values?.state?.value,
        "taxable": values?.taxable === "active" ? true : false,
        "vehicleId": values?.vehicle?.value,
        "city": values?.endCity,
      }

      if (isEdit) {
        if (iftaDetails?.isUnknownState) {
          requestData = {
            ...requestData,
            "distanceTravelled": values?.totalMiles,
            "endOdometerReading": values?.endMeter,
            "startOdometerReading": values?.startMeter,
            "iftaMileageId": iftaDetails?.iftaMileageId,
            "state": values?.state?.value,
            "taxable": values?.taxable === "active" ? true : false,
          }
        } else {
          requestData = {
            "date": moment(values?.date).format(BACKEND_DATE_FORMAT),
            "driverId": values?.driver?.value,
            "state": values?.state?.value,
            "distanceTravelled": values?.totalMiles,
            "vehicleId": values?.vehicle?.value,
            "endOdometerReading": values?.endMeter,
            "iftaMileageId": iftaDetails?.iftaMileageId,
            "startOdometerReading": values?.startMeter,
            "taxable": values?.taxable === "active" ? true : false,
            "city": values?.endCity,
          };
        }
      }
      if ((isEdit && iftaDetails?.isUnknownState) || (!isEdit && !iftaDetails)) {
        requestData = {
          ...requestData,
          ...latLng,
        }
      }

      const addIftaApi = 'addIftaStateApi';
      const editIftaApi = 'editIftaStateApi';
      let payloadData = {
        apiId: isEdit ? editIftaApi : addIftaApi,
        param: requestData,
        data: {},
        callback: callback,
      }
      addUpdateIFTAState(payloadData).then((res) => {
        if (res) {
          callback('success', res, () => {
            history.push(pageUrls?.iftaList)
          });
          return;
        }
        throw new Error('This operation failed.');
      });
    } catch (error) {
      setError(error?.message || '');
    }
  }
  return (
    <>
      <Form
        type="server"
        direction="column"
        initialValues={{ ...initialValues }}
        RenderComponent={RenderComponent}
        vehicleList={[...activeVehicles, ...deActivatedVehicles]}
        stateList={stateList}
        drivers={driversList}
        callback={callback}
        handleOnSubmit={(values) => handleSubmit(values, { isEdit, iftaDetails })}
        customProps={{
          Styled,
          isEdit: isEdit,
          setInitialValues,
          history,
          fetchLatLong,
          setError,
          setLatLng,
          errorMessage: error || '',
          ...(iftaDetails ? { isUnknownState: iftaDetails?.isUnknownState } : { isUnknownState: true }),
        }}
        handleOnCancel={() => {
          history.push(pageUrls?.reportMileageList);
        }}
        validationSchema={object().shape({
          ...validations
        })}
      />
    </>
  );
};

StateForm.propTypes = {
};

export default StateForm;
