import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import ReactDatePicker from 'react-datepicker';
import CalendarToday from '@material-ui/icons/CalendarToday';
import { getDateLocale, getFilterLocaleValues } from '../../../utils/utils'
import { UI_DATE_LOWER_FORMAT } from '@/config/constants/static';
// import Styled from './style';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Input = lazy(() => import(`@/components/UI/Input/${layout}`));
const Select = lazy(() => import(`@/components/UI/Select/${layout}`));
const Upload = lazy(() => import(`@/components/FileUpload/${layout}`));
const SubHeader = lazy(() => import(`@/components/SubHeader/${layout}`));
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const RenderComponent = ({
  values,
  handleEdit,
  errors,
  isNotValid,
  validateForm,
  customProps,
  submitAction
}) => {
  const { i18n } = useTranslation();
  return (
    <customProps.Styled.PopupUpload>
      <SubHeader text={i18n.t('Upload')} variant="h1" className="uploadHeading" />
      <customProps.Styled.UploadWrapper>
        <div className="uploadFields">
          <FormStyle.default.FieldsWrapper width="100%" className="vertical">
            <Label variant="body1">{i18n.t('users.selectFileToUpload')}</Label>
            <FormStyle.default.TextWrapper>
              <Field
                name="upload"
                onUpload={(event) => {
                  handleEdit(event, { field: 'upload', type: 'file' });
                }}
                as={Upload}
                allowMultiple={false}
                totalFiles={1}
                placeholderText=""
                value={values.upload}
                type="file"
                normal
                acceptedFileTypes={[
                  '.csv',
                  'application/pdf',
                  'image/*',
                  '.txt',
                  '.docx',
                  '.xlsx'
                ]}
                // labelFileTypeNotAllowed={'Cannot upload audio and video'}
                allowFileTypeValidation
              />

              {errors['upload'] && !isNotValid && (
                <Message type="error" message={errors['upload']} />
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>

          {customProps.parent === 'dailyDocuments' ? (
            <>
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1">{i18n.t('common.type')}</Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="type"
                    onChange={(event) => handleEdit(event, { field: 'type' })}
                    value={getFilterLocaleValues([values.type], i18n)}
                    as={Select}
                    suggestions={getFilterLocaleValues(customProps.types, i18n)}
                  />
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1">{i18n.t('common.driverName')}</Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="driver"
                    placeholder={i18n.t('selectWithDot')}
                    onChange={(event) =>
                      handleEdit(event, { field: 'driverId' })
                    }
                    as={Select}
                    value={values.driverId}
                    suggestions={customProps.drivers}
                  />
                  {errors['driverId'] && !isNotValid && (
                    <Message type="error" message={i18n.t('common.fieldRequiredMessage')} />
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>

              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1">{i18n.t('common.location')}</Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="location"
                    handleOnChange={(event) =>
                      handleEdit(event, { field: 'location' })
                    }
                    as={Input}
                    type="text"
                  />
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              {values?.type?.value === 'DELIVERY_RECEIPT' && (
                <>
                  <FormStyle.default.FieldsWrapper width="100%">
                    <Label variant="body1">
                      {i18n.t('common.customerName')}
                    </Label>
                    <FormStyle.default.TextWrapper>
                      <Field
                        name="customerName"
                        handleOnChange={(event) =>
                          handleEdit(event, { field: 'customerName' })
                        }
                        as={Input}
                        type="text"
                      />
                    </FormStyle.default.TextWrapper>
                  </FormStyle.default.FieldsWrapper>
                  <FormStyle.default.FieldsWrapper width="100%">
                    <Label variant="body1">
                      {i18n.t('common.driverNamejobName')}
                    </Label>
                    <FormStyle.default.TextWrapper>
                      <Field
                        name="customerJob"
                        handleOnChange={(event) =>
                          handleEdit(event, { field: 'customerJob' })
                        }
                        as={Input}
                        type="text"
                      />
                    </FormStyle.default.TextWrapper>
                  </FormStyle.default.FieldsWrapper>
                </>
              )}
            </>
          ) : (
            <FormStyle.default.FieldsWrapper width="100%" className="vertical verticalExp">
              <Label variant="body1">{i18n.t('users.expirationDate')}</Label>
              <FormStyle.default.TextWrapper>
                <Field
                  name="date"
                  onChange={(event) =>
                    handleEdit(event, { field: 'date', type: 'date' })
                  }
                  locale={getDateLocale()}
                  as={ReactDatePicker}
                  placeholderText={UI_DATE_LOWER_FORMAT}
                  value={values.date}
                  type="date"
                  showPopperArrow={false}
                  showTimeSelect={false}
                  minDate={new Date()}
                  showDisabledMonthNavigation
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
                {window.$environment.CURRENT_LAYOUT === 'SpireonNew' && (
                  <CalendarToday />
                )}
              </FormStyle.default.TextWrapper>
            </FormStyle.default.FieldsWrapper>
          )}
          <FormStyle.default.ButtonsWrapper>
            <Button
              type="submit"
              className="buttonStyle"
              label={i18n.t('common.save')}
              onClick={(e, d) => {
                const validateResponse = validateForm();
                validateResponse.then((data) => {
                  submitAction(data);
                });
              }}
              id="docUploadButton"
            />
          </FormStyle.default.ButtonsWrapper>
        </div>
      </customProps.Styled.UploadWrapper>
    </customProps.Styled.PopupUpload>
  );
};

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  customProps: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};

export default RenderComponent;
