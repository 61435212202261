import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {getFilterLocaleValues} from '@/utils/utils'
import { Field } from 'formik';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Select = lazy(() => import(`@/components/UI/Select/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Input = lazy(() => import(`@/components/UI/Input/${layout}`));

const RenderComponent = ({
  values,
  handleEdit,
  validateForm,
  customProps,
  submitAction,
  errors,
  isNotValid
}) => {
  const { i18n } = useTranslation();
  const showError = (field, validationErrors, isNotValid, apiError) => {
    if (validationErrors[field] && !isNotValid) {
      return <Message type="error" message={errors[field]} />;
    }
    if (apiError?.[field]) {
      return <Message type="error" message={apiError[field]} />;
    }
  };

  return (
    <>
      <FormStyle.default.FieldsWrapper className="csafield vertical">
        <FormStyle.default.TextWrapper>
          <Field
            name="categoryType"
            onChange={(event) => {
              handleEdit(event, { field: 'categoryType' });
            }}
            id="categoryType"
            as={Select}
            placeholder={i18n.t('common.pleaseSelect')}
            value={getFilterLocaleValues([values.categoryType], i18n)}
            suggestions={getFilterLocaleValues(customProps.category, i18n)}
          />
          {showError(
            'categoryType',
            errors,
            isNotValid,
            customProps.hasApiStatus?.errorMessage
          ) ||
            showError(
              'vehicleSectionName',
              errors,
              isNotValid,
              customProps.hasApiStatus?.errorMessage
            )}
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.FieldsWrapper className="csafield vertical">
        <FormStyle.default.TextWrapper>
          <Field
            name="vehicleSectionName"
            handleOnChange={(event) => {
              handleEdit(event, { field: 'vehicleSectionName' });
            }}
            id="vehicleSectionName"
            placeholder={i18n.t('inspections.advancedInput')}
            as={Input}
            value={values.vehicleSectionName}
          />
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.ButtonsWrapper>
        <Button
          type="submit"
          className="buttonStyle"
          label={i18n.t('common.add')}
          onClick={() => {
            const validateResponse = validateForm();
            validateResponse.then((data) => {
              submitAction(data);
              handleEdit('', { field: 'vehicleSectionName' });
              handleEdit('', { field: 'categoryType' });
            });
          }}
        />
      </FormStyle.default.ButtonsWrapper>
    </>
  );
};

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  hasApiStatus: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};

export default RenderComponent;
