import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
  .partsName {
    font-size: 16px;
  }
  .deleteParent h2 {
    text-align: center;
  }
`;
const TopWrapper = styled(Parent.TopWrapper)``;
const LinkWrapper = styled(Parent.LinkWrapper)``;
const TableWrapper = styled(Parent.TableWrapper)``;
const Styled = { Wrapper, TopWrapper, TableWrapper, LinkWrapper };

export default Styled;
