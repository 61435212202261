import styled from 'styled-components';
import Parent from '../Base/style';
const SelectWrapper = styled(Parent.SelectWrapper)`
${({ theme }) => `
.css-8r3dx3-control:hover {
    border: 0 !important;
}
.select__control--is-focused .select__dropdown-indicator,
.select__indicator:hover,
.select__indicator{
    color: #444 !important;
}
.select__placeholder{
    font-size: 17px !important;
}
.select__input input,
.select__control,
.select__option,
.select__single-value{
    font-family: ${theme.palette.tableFont} !important;
    font-size: 16px;
}
.select__multi-value__remove:hover,
.select__multi-value{
    background: #fff !important;
    font-family: ${theme.palette.tableFont} !important;
}
.select__control:hover{
    border: 0 !important
}
.select__placeholder{
    color: #444;
}
.select__control--is-disabled{
    background: ${theme.colors.BACKGROUND};
    border:0;
}
.css-8r3dx3-control,
.css-q5i2ns-control,
.react-select__menu-list,
.css-1wmipuf-option,
.css-1irrqr3-option,
.css-1hys26h-singleValue,
.css-1ebjif1-option,
.react-select__menu-list:active,
.css-1wmipuf-option:active,
.css-1ebjif1-option:active,
.css-kpwi51-option:active,
.css-kpwi51-option,
.css-1wmipuf-option {
    font-family: ${(theme.palette || {}).fontFamily};
    background-color: ${theme.colors.BACKGROUND}!important;
    color: ${theme.colors.BLACK};
    border: 1px solid ${theme.colors.BACKGROUND};
}
.select__menu {
    background: ${theme.colors.WHITE};
    border: 0;
     color: ${theme.colors.BLACK};
    border: 1px solid ${theme.colors.BACKGROUND};
}

`}
`;
const Styled = { SelectWrapper };
export default Styled;
