import styled from 'styled-components';
import Box from '@material-ui/core/Box';
const Wrapper = styled(Box)`
  ${({ theme }) => `
  :hover {
    cursor: pointer;
  }
  .normalLink {
    color: ${theme?.colors?.LINK_COLOR} !important;
  }
  .MuiTypography-colorPrimary {
    color: ${theme?.colors?.LINK_COLOR};
  }
  `}
`;

export default Wrapper;
