import React, { lazy, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';

import { pageUrls } from '@/config/constants/keys';
import AddLogs from './Form';

import ParentStyled from './style';
import { loadStyle, getCompanyId } from '@/utils/utils';
const Styled = loadStyle(ParentStyled, 'pages/web/AddLogs');

const layout = window?.$environment?.CURRENT_LAYOUT;
const Tab = lazy(() => import(`@/components/UI/Tab/${layout}`));

const Index = ({ getDrivers, getVehicles, vehicleList, drivers, ...rest }) => {
  const history = useHistory();
  const i18n = useTranslation();
  const theme = useTheme();

  const handleChange = (event, newValue) => {
    history.push(tabValues[newValue].url);
  };

  useEffect(() => {
    let id = getCompanyId()
    !drivers.data.length && getDrivers({ apiId: 'getDrivers' });
    !vehicleList.length && getVehicles({ apiId: 'getVehicles', data: { id } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabValues = [
    {
      name: i18n.t('logs.manageLogsHeader'),
      value: null,
      url: pageUrls.logsList
    },
    {
      name: i18n.t('logs.undefinedDriverHeader'),
      value: null,
      url: pageUrls.logsUdList
    },
    ...(window.$environment.SERVICE_PROVIDER !== 'spireon' ? [
    {
      name: i18n.t('logs.addLogHeader'),
      value: (
        <AddLogs
          {...rest}
          Styled={Styled}
          theme={theme}
          history={history}
          i18n={i18n}
          drivers={drivers}
          vehicleList={vehicleList}
        />
      ),
      url: pageUrls.logsAdd
    },
    {
      name: i18n.t('logs.archiveLogsHeader'),
      value: null,
      url: pageUrls.archiveLogsList
    }] : []),
    ...(window.$environment.SERVICE_PROVIDER === 'spireon' ? [
      {
        name: i18n.t('leftMenu.DOTAudit'),
        value: null,
        url: pageUrls.fmcsaLogs
      }] : [])
  ];
  return (
    <Styled.Content>
      <Tab
        data={tabValues}
        className="logwrapper"
        currentTab={2}
        onChange={handleChange}
      />
    </Styled.Content>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    drivers: state.drivers,
    vehicleList: state.vehicles.fleetVehicleList
  };
};
const mapDispatch = (dispatch) => {
  return {
    getDrivers: () => dispatch.drivers.getDrivers(),
    getVehicles: (data) => dispatch.vehicles.fetchFleetData(data),
    handleAddLog: (data) => dispatch.addLog.handleAddLog(data)
  };
};

export default connect(mapState, mapDispatch)(Index);
Index.defaultTypes = {};
Index.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  drivers: PropTypes.object.isRequired,
  getDrivers: PropTypes.func.isRequired,
  getVehicles: PropTypes.func.isRequired,
  handleAddLog: PropTypes.func.isRequired
};
