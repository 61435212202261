import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
.bulkDownload button,
.advancedDVIR button {
    padding: 0 12px !important;
}
.viewReport{
    color: ${theme.colors.SECONDARY_MAIN};
}
.CSAForms {
    padding-left: 20px;
    .vertical-button .MuiButtonBase-root {
        margin-top: 19px;
        .MuiButton-label{
            padding: 0;
        }
    }
    .vertical:first-child label,
    .vertical:nth-child(2) label{
        padding-bottom: 7px;
    }
}
.datepopup {
    position: absolute !important;
    right: 0;
    top: 35px;
}
.buttonWrapper {
    padding-bottom: 0;
    padding-right: 10px;
}

form div.csafield label{
    padding-bottom: 4px !important;
}


`}
`;
const TopWrapper = styled(Parent.TopWrapper)`
 
`;
const TableWrapper = styled(Parent.TableWrapper)`
padding: 0;
.driverstatusTitle{
    display: block;
    color: #42505a;
    font: bold 12px "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding: 10px;
    padding-left: 10px;
    background: -webkit-linear-gradient(top, #d0d8db 0%, #b3bec3 100%);
    align-items: center;
}
`;
const Styled = { Wrapper, TopWrapper, TableWrapper };

export default Styled;
