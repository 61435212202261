import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import useTheme from '@material-ui/core/styles/useTheme';
import PropTypes from 'prop-types';

import Component from './Component';

const CSASnapshot = ({ apiStatus, history, callback, fetchBasicMeasures, csasnapshot,fetchCrashSummary, fetchInspectionSummary,fetchDriverDetails, ...rest }) => {
  const theme = useTheme();
  useEffect(()=>{
    fetchBasicMeasures({
      apiId: 'fetchBasicMeasures'
    })
    fetchCrashSummary({
      apiId: 'fetchCrashSummary'
    })
    fetchInspectionSummary({
      apiId: 'fetchInspectionSummary'
    })
    fetchDriverDetails({
      apiId: 'fetchDriverDetails'
    })
  },[])
  return (
    <>
      <Box style={{ ...theme.palette.contentStyle, ...(window?.$environment?.APP_LAYOUT === 'Clutch'? {background: theme.colors.HEADER_COLOR}: {}) }}>
        <Component
          {...rest}
          history={history}
          apiStatus={apiStatus}
          callback={callback}
          csasnapshot={csasnapshot}
        />
      </Box>
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    csasnapshot: state.CSASnapshot
  };
};

const mapDispatch = (dispatch) => {
  return {
    fetchBasicMeasures: (data)=> dispatch.CSASnapshot.fetchBasicMeasures(data),
    fetchCrashSummary: (data)=> dispatch.CSASnapshot.fetchCrashSummary(data),
    fetchInspectionSummary: (data)=> dispatch.CSASnapshot.fetchInspectionSummary(data),
    fetchDriverDetails: (data)=> dispatch.CSASnapshot.fetchDriverDetails(data)
  };
};

export default connect(mapState, mapDispatch)(CSASnapshot);

CSASnapshot.propTypes = {
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  apiStatus: PropTypes.object.isRequired
};
