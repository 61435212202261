import FormGroup from '@material-ui/core/FormGroup';
import useTheme from '@material-ui/core/styles/useTheme';
import React from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import SimpleCheckbox from 'react-simple-checkbox';
import CheckboxWrapper from './style';

const CheckboxLabels = (props) => {
  const theme = useTheme();

  const useStyles = makeStyles({
    icon: {
      width: 24,
      height: 24,
      backgroundColor: theme.colors.BLACK,
      border: `2px solid ${theme.colors.LIGHT_BORDER}`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    checkedIcon: {
      '&:after': {
        content: '""',
        width: '50%',
        height: '50%',
        backgroundColor: theme.colors.SECONDARY_MAIN
      },
      '&:hover': {
        backgroundColor: 'transparent'
      }
    }
  });

  let defaultState = {
    checked: false
  }
  if (props?.defaultValue) {
    defaultState = {
      checked: props?.checked ? props?.checked : false
    }
  }
  const [state, setState] = React.useState(defaultState);

  const handleChange = (name) => (event) => {
    if (props.type === 'NormalWhite' || props.type === 'NormalGrey') {
      setState({ ...state, [name]: event });
      props.onClick(!state[name]);
    } else {
      setState({ name: event.target.checked });
      props.onClick(event.target.checked);
    }
  };
  const classes = useStyles();
  return (
    <CheckboxWrapper className={props.className || ''}>
      {props.type === 'NormalWhite' || props.type === 'NormalGrey' ? (
        <div className="normalCheckbox">
          <SimpleCheckbox
            disabled={props.disabled || null}
            checked={props.checked ? props.checked : state.checked}
            onChange={!props.disabled && handleChange('checked')}
            value="checked"
            size={3}
            borderThickness={2}
            className={props.disabled ? 'disabledCheck' : ''}
            color={{
              backgroundColor: props.disabled
                ? theme.colors.BLACK_THREE
                : 'transparent',
              borderColor:
                props.type === 'NormalWhite'
                  ? theme.colors.WHITE
                  : props.type === 'NormalGrey'
                    ? theme.colors.LIGHT_BORDER
                    : theme.colors.WHITE,
              uncheckedBorderColor:
                props.type === 'NormalWhite'
                  ? theme.colors.WHITE
                  : props.type === 'NormalGrey'
                    ? theme.colors.LIGHT_BORDER
                    : theme.colors.WHITE,
              tickColor: theme.colors.WHITE
            }}
            tickSize={2}
            tickAnimationDuration={0}
            backAnimationDuration={0}
          />
          <span
            style={{
              color:
                props.type === 'NormalWhite'
                  ? theme.colors.WHITE
                  : props.type === 'NormalGrey'
                    ? theme.palette.primary.contrastText
                    : theme.colors.WHITE
            }}
            onClick={!props.disabled && handleChange('checked')}
          >
            {props.label}
          </span>
        </div>
      ) : (
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                disabled={props.disabled || null}
                className={classes.root}
                checked={props.checked ? props.checked : state.checked}
                onChange={handleChange('checked')}
                value="checked"
                color="primary"
                checkedIcon={
                  <span className={clsx(classes.icon, classes.checkedIcon)} />
                }
                icon={<span className={classes.icon} />}
                iconStyle={{ fill: 'red' }}
                disableripple="true"
                id={props.id ? props.id : 'checkbox'}
              />
            }
            label={props.label}
          />
        </FormGroup>
      )}
    </CheckboxWrapper>
  );
};

CheckboxLabels.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func
};

CheckboxLabels.defaultProps = {
  label: ''
};

export default CheckboxLabels;
