import styled from 'styled-components';
import Parent from '../Base/style';

const Wrapper = styled(Parent.Wrapper)`
  ${({ theme }) => `
  .MuiBreadcrumbs-ol {
    padding: 0.5% 2%;
    border-bottom: 1.5px solid ${theme.colors.SECONDARY_BORDER};
  }
.MuiBreadcrumbs-li {
  font-size: 16px;
  font-weight: 300;
    a {
      color: ${theme.colors.LINK_COLOR};
    }
    span {
      color: ${theme.colors.TITLE_COLOR};
    }
}
.MuiBreadcrumbs-separator .MuiSvgIcon-root {
    fill: ${theme.colors.LINK_COLOR};
}
`}
`;
const Styled = { Wrapper };
export default Styled;
