const initialState = {};
export const policyDownload = {
  state: { ...initialState },
  reducers: {
    setdownloadUrl(state, payload) {
      return payload;
    }
  },
  effects: (dispatch) => ({
    async downloadDocument(payload, rootState) {
      try {
        const getApi = await import(
          `@/config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('getDocumentTokenApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject
          },
          id: payload.apiId
        });
        response &&
          this.setdownloadUrl(
            `document/open/resource/${payload.data}.docx/POLICY?token=${response}`
          );
      } catch (error) {}
    },
    async clearState () {
       this.setdownloadUrl({})
    }
  })
};
