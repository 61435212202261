import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import Private from './Private';
import Public from './Public';

const OldUi = ({
  children,
  layout,
  setAuth,
  pageTitle,
  folderPath,
  showFilterPopup,
  callback
}) => {
  const privateRootProps = {
    layout,
    setAuth,
    folderPath,
    showFilterPopup,
    callback
  };
  const baseUrl =
    window.$environment?.SERVICE_PROVIDER === 'em' || window.$environment?.SERVICE_PROVIDER === 'tp'
    || window.$environment?.SERVICE_PROVIDER === 'trackon'
      ? `${process.env.CDN_URL || ''}/${window.$environment?.SERVICE_PROVIDER}`
      : `${process.env.CDN_URL || ''}/${
          window.$environment?.SERVICE_PROVIDER
        }/${window.$environment.CURRENT_LAYOUT}`;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="manifest" href={`${baseUrl}/manifest.json`} />
        <link rel="icon" type="image/x-icon" href={`${baseUrl}/favicon.ico`} />
      </Helmet>
      {layout === 'login' || layout === 'noi18n' ? (
        <Public isI18nNeeded={layout !== 'noi18n'} layout={layout}>{children}</Public>
      ) : (
        <Private {...privateRootProps}>{children}</Private>
      )}
    </>
  );
};

export default OldUi;
OldUi.defaultProps = {
  children: null,
  layout: '',
  setAuth: () => {}
};
OldUi.propTypes = {
  children: PropTypes.node,
  layout: PropTypes.string,
  setAuth: PropTypes.func
};
