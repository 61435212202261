import React, { lazy, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

import { pageUrls } from '@/config/constants/keys';
const layout = window?.$environment?.CURRENT_LAYOUT;
const UploadList = lazy(() =>
  import(`@/components/modules/DocumentUploadList`)
);
const SubHeader = lazy(() => import(`@/components/SubHeader/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);

const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <>
      <Component {...contentProps} />
    </>
  );
};
export default Index;
const Component = ({
  documents,
  saveDocument,
  deleteDocument,
  apiStatus,
  callback,
  downloadDocument,
  history,
  Styled,
  receipt,
  clearState
}) => {
  const { i18n } = useTranslation();
  useEffect(() => {
    return () => {
      clearState();
    };
  }, []);
  useEffect(() => {
    let url = receipt ? receipt : '';
    if (typeof url === 'string')
      document.getElementById(`downloadDocument`).src = url;
  }, [receipt]);
  return (
    <Styled.DocumentSection>
      <Styled.LeftWrapper>
        <SubHeader
          variant="h1"
          className="documentTitle"
          text={i18n.t('documents.listHeading')}
        />
        <UploadList
          list={documents?.documentList}
          saveDocument={saveDocument}
          deleteDocument={deleteDocument}
          downloadDocument={downloadDocument}
          apiStatus={apiStatus}
          callback={callback}
        />
        <FormStyle.default.ButtonsWrapper className="finish">
          <Button
            type="submit"
            className="buttonStyle"
            label={i18n.t('common.finish')}
            onClick={() => {
              history.push(pageUrls.dashboard);
            }}
          />
        </FormStyle.default.ButtonsWrapper>
      </Styled.LeftWrapper>
      <iframe id="downloadDocument" style={{ display: 'none' }}></iframe>
    </Styled.DocumentSection>
  );
};

Component.propTypes = {
  history: PropTypes.object.isRequired,
  documents: PropTypes.object.isRequired,
  saveDocument: PropTypes.func.isRequired,
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  downloadDocument: PropTypes.func.isRequired
};
