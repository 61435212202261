import styled from 'styled-components';
import Parent from '../Base/style';
const SearchWrapper = styled(Parent)`
.MuiInputBase-root{
    border-bottom: 1px solid #000 !important;
}
.clear {
    width: 18px;
    height: 18px;
}
.spireonImg {
    top: -2px;
    right: 14px;
}
`;
export default SearchWrapper;
