import styled from "styled-components";


const InfoStyle = styled.div`
    text-align: end;
    gap: 14px;
    display: flex;
    flex-direction: column;
    .text {
        font-size: 20px;
        font-weight: 600;
    }
    .content {
            align-items: end;
            gap: 10px;
            display: flex;
            justify-content: flex-end;
    }
    .count {
        font-size: 30px;
        font-weight: 600;
            line-height: 1;
    }
    .label {
        color: #798bb7;
            font-size: 15px;
        font-weight: 600;
            line-height: 1;
    }
`;

export default InfoStyle;