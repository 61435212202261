import PropTypes from 'prop-types';
import React, { lazy } from 'react';

import { Link as LinkLi } from '@material-ui/core';
import { Link } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Field } from 'formik';

import useTheme from '@material-ui/core/styles/useTheme';
import { pageUrls } from '../../../config/constants/keys';

const layout = window.$environment?.CURRENT_LAYOUT;
const serviceProvider = window.$environment?.SERVICE_PROVIDER;
const env = process.env.REACT_APP_MODE;
const Input = lazy(() => import(`../../../components/UI/Input/${layout}`));
const Label = lazy(() => import(`../../../components/UI/FormLabel/${layout}`));
const CheckBox = lazy(() => import(`../../../components/UI/Toggle/${layout}`));
const Message = lazy(() => import(`../../../components/UI/Message/${layout}`));
const LinkTo = lazy(() => import(`../../../components/UI/Link`));
const Button = lazy(() => import(`../../../components/UI/Button/${layout}`));
const SubHeader = lazy(() => import(`../../../components/SubHeader/${layout}`));
const FormStyle = require(`../../../components/UI/Form/${layout}/serverStyle`)
  .default;
const RenderComponent = ({
  values,
  handleEdit,
  errors,
  isNotValid,
  validateForm,
  customProps: { i18n, Styled, history, hasLoginApiStatus, hasRegistration },
  submitAction
}) => {
  const theme = useTheme();
  return (
    <>
      <Styled.LoginHeader style={{ ...theme.palette.registrationtrackon }}>
        <SubHeader
          variant="h1"
          text={i18n.t('LOG IN')}
          mode="light"
          className="loginHeader"
        />
        {hasRegistration && serviceProvider === 'gs' && (
          <h1 className="registrationHeader">
            <div className="linkWrap">
            <LinkLi component={Link} to={pageUrls.registration} className="registrationHeaderlink">{
                  i18n.t('REGISTRATION')
              }
            </LinkLi>
            </div>
          </h1>
        )}
        {hasRegistration && serviceProvider === 'trackon' && (
           <h1 className="registrationHeader">
            <div className="linkWrap">
                <a className="registrationHeaderlink" href={window.$environment?.REG_LINK}>{
                      i18n.t('REGISTRATION')}
                </a>
            </div>
          </h1>
        )}
      </Styled.LoginHeader>
      {hasLoginApiStatus?.errorMessage && (
        <Styled.LoginError>
          <Message type="error" className="errorMessage" message={hasLoginApiStatus.errorMessage} />
        </Styled.LoginError>
      )}
      <Styled.userNameWrapper>
        <FormStyle.FieldsWrapper width="100%">
          <Label mode="light" className="labelSize">
            {i18n.t('Email')}
          </Label>
          <FormStyle.TextWrapper mode="light">
            <Field
              name="username"
              handleOnChange={(event) =>
                handleEdit(event, { field: 'username', type: 'username' })
              }
              as={Input}
              mode="light"
              placeholder={i18n.t('Email or Employee Id')}
              value={values.username}
              type='text'
              isNotDebounced
            />
            {errors['username'] && !isNotValid && (
              <Message type="error" message={errors['username']} />
            )}
          </FormStyle.TextWrapper>
        </FormStyle.FieldsWrapper>
      </Styled.userNameWrapper>
      <Styled.passwordWrapper>
        <FormStyle.FieldsWrapper width="100%">
          <Label mode="light">{i18n.t('common.password')}</Label>
          <FormStyle.TextWrapper>
            <Field
              name="password"
              handleOnChange={(event) =>
                handleEdit(event, { field: 'password', type: 'password' })
              }
              as={Input}
              mode="light"
              isNotDebounced
              placeholder={i18n.t('common.password')}
              value={values.password}
              type="password"
            />
            {errors['password'] && !isNotValid && (
              <Message type="error" message={errors['password']} />
            )}
          </FormStyle.TextWrapper>
        </FormStyle.FieldsWrapper>
      </Styled.passwordWrapper>
      <Styled.PasswordHandler>
        <LinkTo href={pageUrls.recovery}>{i18n.t('Forgot password?')}</LinkTo>
        <CheckBox
          type="NormalWhite"
          checked={values.rememberMe}
          className="remember"
          label={i18n.t('Remember me')}
          onClick={(event) =>
            handleEdit(event, { field: 'rememberMe', type: 'checkbox' })
          }
        />
      </Styled.PasswordHandler>
      <Styled.LoginButtonWrapper>
        <FormStyle.ButtonsWrapper>
          <Button
            type="submit"
            label={
              hasLoginApiStatus?.isLoading ? (
                <CircularProgress color="inherit" />
              ) : (
                i18n.t('Log in')
              )
            }
            onClick={() => {
                const validateResponse = validateForm();
                validateResponse.then((data) => {
                  submitAction(data);
                });
            }}
          />
        </FormStyle.ButtonsWrapper>
      </Styled.LoginButtonWrapper>
    </>
  );
};

export default RenderComponent;

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  customProps: PropTypes.object.isRequired,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};
