import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { object, string } from 'yup';
import RenderComponent from './RenderComponent';
// import Styled from './style';
import ParentStyled from './style';
import { loadStyle } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'components/modules/DocumentUpload');
const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));
const Component = ({
  closeModal,
  saveDocument,
  uploadApi,
  callback,
  parent,
  types,
  languages,
  currentData
}) => {
  const { i18n } = useTranslation();
  let instialState = {};
  if(currentData) {
    instialState = {
      id: currentData?.id || '',
      name: currentData?.name || '',
      language: currentData?.language ? languages.filter(el => {
        return el.value === currentData.language
      })[0] : {},
      policyDocId: currentData?.policyDocId || ''
    }
  }
  return (
    <Styled.Wrapper>
      <Form
        type="server"
        direction="column"
        initialValues={instialState}
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {
          const requestData =
            {
              name: fields?.name ||'',
              language: fields?.language?.value || '',
              id: currentData?.id || ''
            }
          if (
            document?.querySelectorAll('span.filepond--file-status-main')[0]
              ?.innerText === '' || currentData?.policyDocId
          ) {
            let payloadData = {
              apiId: uploadApi,
              param: requestData,
              data: {},
              callback
            }
            if(fields?.upload?.[0]) {
             payloadData = {
              apiId: uploadApi,
              param: requestData,
              data: {
                data: {
                  policyDoc: fields?.upload?.[0]
                }
              },
              callback
             }
            }
            saveDocument(payloadData);
            closeModal();
          }
        }}
        customProps={{ parent, types, Styled, languages }}
        handleClear={() => {}}
        validationSchema={object().shape({
          ...(!currentData?.policyDocId ? {upload: string().required(i18n.t('common.fieldRequiredMessage'))} : {}),
          name: string().required(i18n.t('common.fieldRequiredMessage')),
          language: string().required(i18n.t('common.fieldRequiredMessage'))
        })}
      />
    </Styled.Wrapper>
  );
};

export default Component;

Component.propTypes = {
  apiStatus: PropTypes.object,
  callback: PropTypes.func.isRequired
};
