import React, { useEffect, cloneElement, lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { deleteCookie, getCookie } from '../../../services/cookie';
import {
  getImagePath,
  hasEnabledFeature,
  getAuthDetails,
  findListItem,
  hasRestrictedRole
} from '../../../utils/utils';

import {
  imageUrls,
  COOKIE_AUTH_DETAILS,
  pageUrls
} from '../../../config/constants/keys';
import Styled from './privateStyle';
import { removeLocalStorage } from '@/services/storage';

const layout = window?.$environment?.CURRENT_LAYOUT;
const CautionIcon = lazy(() => import(`@/components/CautionIcon`));
const ErrorIcon = lazy(() => import(`../../ErrorIcon/${layout}`));
const SuccessIcon = lazy(() => import(`../../SuccessIcon/${layout}`));
const Button = lazy(() => import(`../../UI/Button/${layout}`));
const Header = lazy(() => import(`../../Header/${layout}`));
const Footer = lazy(() => import(`../../Footer/${layout}`));
const Logo = lazy(() => import(`../../Logo`));
const Loader = require(`../../Loader`);
const Modal = require(`../../UI/Modal/${layout}`).default;
const Chat = lazy(() => import(`../../Chat/${layout}`));

const PrivateWrapper = ({
  children,
  layout,
  apiStatus,
  clearRedux,
  setAuth,
  showFilterPopup,
  folderPath,
  handleLogout,
  createChatToken,
  getDrivers
}) => {
  const token = getCookie(COOKIE_AUTH_DETAILS);
  useEffect(()=>{
    if(hasEnabledFeature('chatEnabled')){
    createChatToken({
      apiId: 'createChatToken'
    });
    getDrivers({ apiId: 'getDrivers' });
  }
  },[])
  const history = useHistory();
  const [loaderPopupOpen, setLoaderPopupOpen] = React.useState(false);
  const [chatPopupOpen, setChatPopupOpen] = useState(false);
  const i18n = useTranslation();
  const [open, setOpen] = React.useState(false);

  const [ModalListObject, setModalListObject] = useState({
    header: '',
    content: () => (
      <Button
        type="submit"
        label={i18n.t('common.ok')}
        onClick={() => setOpen(false)}
      />
    )
  });
  const [isError, setIsError] = React.useState(false);

  const handleOnLogout = () => {
    const auth = getAuthDetails(false);
    if (auth) {
      handleLogout({
        apiId: 'logout',
        callback: (type, message) => {
          if (type === 'error') {
            callback(type, message);
          } else {
            removeLocalStorage('currentLayout');
            window.location.href = pageUrls.login;
          }
        }
      });
    }
  };

  //Callback function to be invoked after an api call
  const callback = (type, message, callbackFunction,  optionButton) => {
    if (message) {
      let keys = Object.keys(message);
      let items = Object.values(message);
      if (typeof message !== 'string' && keys.length > 1) {
        setIsError(true);
      } else {
        setIsError(false);
      }
      setModalListObject({
        header: '',
        content: () => (
          <div className="successpopup">
            {type === 'error' ? (
              <ErrorIcon />
            ) : type === 'warning' || type === 'warningfmcsa' ? (
              <img
                src={getImagePath(imageUrls.confirmIcon)}
                alt="Are you sure"
              />
            )
            : (
              <SuccessIcon />
            )}
            {type === 'warning' ? (
              <span className="warningPopup">Warning</span>
            ) : (
              ''
            )}
            {typeof message === 'string' ? (
              type === 'warning' ? (
                <span className="warningPopupMessage">{i18n.t(message)}</span>
              ) : (
                <h2> {i18n.t(message)} </h2>
              )
            ) : keys.length === 1 && items[0].length === 1 ? (
              <h2> {i18n.t(items)} </h2>
            ) : keys.length === 1 && items[0].length !== 1 ? (
              <div className="errorSectionlist">
                <div className="tcSection" id="scroll">
                  <div className="scrollbar">
                    {keys.map((item, index) => {
                      return (
                        <div className="listItem">
                          <span>Line No:{item}</span>
                          <ul>
                            {message[item].map((err, index) => {
                              return <li>{err}</li>;
                            })}
                          </ul>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : type !== 'error' && message?.error?.length === 0 ? (
              <h2> {i18n.t('common.dataImportSuccess')} </h2>
            ) : (
              <div className="errorSectionlist">
                <div className="tcSection" id="scroll">
                  <div className="scrollbar">
                    {keys.map((item, index) => {
                      return (
                        <div className="listItem">
                          <span>Line No:{item}</span>
                          <ul>
                            {message[item].map((err, index) => {
                              return <li>{err}</li>;
                            })}
                          </ul>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
            {
              optionButton ? <div className="deletePopupBtn">
              <div className="cancelBtn">
                <Button
                  label={i18n.t('Cancel')}
                  type="submit"
                  onClick={() => {
                    setOpen(false);
                  }}
                ></Button>
              </div>
              <div className="deletebtn">
                <Button
                  type="submit"
                  label={i18n.t('common.deletePopup')}
                  onClick={() => {
                      callbackFunction()
                    }}
                  ></Button>
                </div>
              </div> 
              : 
              <div className="deletePopupBtn">
              <Button
                type="submit"
                label={i18n.t('common.ok')}
                onClick={() => {
                  setOpen(false);
                  callbackFunction && callbackFunction();
                  if (
                    type !== 'error' &&
                    message?.type === 'Users' &&
                    window.location.href.includes('user')
                  )
                    history.push(pageUrls.userList);
                  else if (
                    type !== 'error' &&
                    (message?.type === 'Vehicles' ||
                      message?.type === 'Trailers') &&
                    window.location.href.includes('vehicle')
                  )
                    history.push(pageUrls.vehicleList);
                  else if (
                    type !== 'error' &&
                    window.location.href.includes('alerts') &&
                    window.location.href.includes('requests')
                  )
                    history.push(pageUrls.alertsRequests);
                  else if (
                    type === 'mailIdChanged' &&
                    window.location.href.includes('user') &&
                    window.location.href.includes('profile') &&
                    message === 'Your profile was saved'
                  ) {
                    handleOnLogout();
                  } else if (
                    type !== 'error' &&
                    window.location.href.includes('user') &&
                    window.location.href.includes('resetPassword') &&
                    message === 'Your profile was saved'
                  ) {
                    // handleOnLogout();
                    window.location.href = pageUrls.login;
                    // history.push(pageUrls.login);
                  } else if (
                    type !== 'error' &&
                    // window.location.href.includes('dashboard') &&
                    (message ===
                      'A verification email has been sent to your email. Please click on the link to enable email notifications' ||
                      message === 'You have disabled email notifications')
                  ) {
                    let btn = document.getElementById(
                      'checkbox_Enable Notification'
                    );
                    if (btn && btn.parentNode.classList.contains('checked')) {
                      btn && btn.parentNode.classList.remove('checked');
                      btn && btn.parentNode.classList.add('unchecked');
                    }
                  }
                }}
              />
            </div>
            }
          </div>
        )
      });
      setOpen(true);
    }
  };
  useEffect(() => {
    if (document.getElementById('messaging')) {
      if (chatPopupOpen === true) {
        document.getElementById('messaging').style.display = 'none';
      } else {
        document.getElementById('messaging').style.display = 'block';
      }
    }
  }, [chatPopupOpen]);

  useEffect(() => {
    if (token?.previousPaymentFailed) {
      if (
        !window.location.href.includes('feedback') &&
        !window.location.href.includes('profile')
      )
        history.push({
          pathname: pageUrls.suspended,
          state: {
            contractFinishDate: token?.contractFinishDate,
            contractStartDate: token?.contractStartDate,
            amount: token?.amountToBePaid
          }
        });
    }
  }, [folderPath]);

  useEffect(() => {
    let isPageLoading = false;
    for (let index = 0; index < apiStatus.status.length; index++) {
      const element = apiStatus.status[index];
      if (element.isLoading) {
        isPageLoading = true;
      }
      if (element.errorMessage === 'Access denied') {
        setLoaderPopupOpen(false);

        deleteCookie(COOKIE_AUTH_DETAILS);
        clearRedux();
        setAuth(null);
        removeLocalStorage('currentLayout');
        window.location = pageUrls.login;
        return;
      }
    }
    isPageLoading ? setLoaderPopupOpen(true) : setLoaderPopupOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiStatus.status]);
  const getMenu = (file) => {
    if (window.$environment.CURRENT_LAYOUT === 'OldUi') {
      try {
        const menu = require(`@/config/menu${folderPath}${window.$environment.SERVICE_PROVIDER}/${window.$environment.CURRENT_LAYOUT}/${file}`);
        return menu.default;
      } catch (error) {
        const menu =
          require(`@/config/menu${folderPath}${window.$environment.SERVICE_PROVIDER}/${file}`)
            .default || [];
        return menu;
      }
    }
  };
  const getAuthorizedUrls = (hamburgerMenu) => {
    let result = [];
    const auth = getAuthDetails(true);
    result = hamburgerMenu.map((item) => {
      const isAuthorized = findListItem(item.roles, auth?.auth, [], {});
      return isAuthorized ? item : null;
    });

    result = result.filter((item) => {
      return item !== null;
    });
    return result;
  };

  let suspendedMenuItems = [
    {
      path: '/user/profile',
      label: 'Account',
      roles: ['FLMANAGER', 'ADMIN', 'DRIVER', 'MECHANIC', 'OWNER']
    },
    {
      label: 'leftMenu.feedback',
      path: '/feedback',
      roles: ['FLMANAGER', 'ADMIN', 'DRIVER', 'MECHANIC']
    }
  ];

  let hamburgerMenu =
    token?.previousPaymentFailed || token?.adminSuspended
      ? suspendedMenuItems
      : layout === 'authenticatedStyle'
      ? getMenu('resetPassword')
      : getMenu('hamburgerMenu');

  hamburgerMenu = getAuthorizedUrls(hamburgerMenu);
  const horizontalMenu =
    token?.previousPaymentFailed || token?.adminSuspended
      ? []
      : getMenu('horizontalMenu');
  const menuList = {
    horizontalMenu: horizontalMenu,
    hamburgerMenu: hamburgerMenu
  };

  const isRestrictedMode = window.location.pathname === pageUrls.resetPassword;

  return (
    <Styled.ParentWrapper>
      <div id="udNotification" style={{ display: 'none' }}></div>
      <Styled.Wrapper>
        <Styled.Main>
          <Styled.HeaderInner>
            <Logo history={history} src={getImagePath(imageUrls.logo)} />
            {layout !== 'authenticatedWithoutMenu' && (
              <Header
                history={history}
                handleLogout={handleLogout}
                callback={callback}
                isRestrictedMode={isRestrictedMode}
                keepi18nTextAsSame
                headerMenu
                menuList={menuList}
                className="header"
                showFilterPopup={showFilterPopup}
              />
            )}
          </Styled.HeaderInner>
          <Styled.Content>
            {cloneElement(children, { callback })}
          </Styled.Content>
          <Styled.FooterWrapper>
            <Footer />
          </Styled.FooterWrapper>
        </Styled.Main>

        <Modal
          open={loaderPopupOpen}
          setOpen={setLoaderPopupOpen}
          data={{
            header: '',
            content: () => <Loader.default />
          }}
          isLoader
        />
        <Modal
          open={open}
          setOpen={setOpen}
          data={ModalListObject}
          isSuccess
          isError={isError}
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
        />

        {hasEnabledFeature('chatEnabled') &&
          !hasRestrictedRole(['DRIVER', 'MECHANIC']) && (
            <Styled.ModalContainer>
              <span
                onClick={() => {
                  setChatPopupOpen(true);
                }}
                className="chatWrapper"
                id="messaging"
              >
                <img
                  src={getImagePath(imageUrls.chatIcon)}
                  alt="chat Icon"
                  className="chatIcon"
                />
              </span>
            </Styled.ModalContainer>
          )}
        <Chat open={chatPopupOpen} setChatPopupOpen={setChatPopupOpen} />
      </Styled.Wrapper>
    </Styled.ParentWrapper>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests
  };
};
const mapDispatch = (dispatch) => {
  return {
    clearRedux: () => dispatch({ type: 'RESET_APP' }),
    handleLogout: (data) => dispatch.login.handleLogout(data),
    createChatToken: (data) => dispatch.chat.createToken(data),
    getDrivers: (data) => dispatch.chat.getDrivers(data)
  };
};

export default connect(mapState, mapDispatch)(PrivateWrapper);

PrivateWrapper.defaultProps = {
  children: null,
  auth: null,
  layout: ''
};
PrivateWrapper.propTypes = {
  children: PropTypes.element,
  auth: PropTypes.object,
  layout: PropTypes.string,
  clearRedux: PropTypes.func.isRequired,
  setAuth: PropTypes.func.isRequired
};
