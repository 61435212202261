import React, { useEffect, lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RenderComponent from '@/components/modules/DynamicRenderPage';

import ParentStyled from './style';
import { loadStyle, hasEnabledFeature, loadPageConfig, getNewObject, hasRestrictedRole } from '@/utils/utils';
import { pageUrls, COOKIE_AUTH_DETAILS, ROLES } from '../../../config/constants/keys';
import { getCookie } from '@/services/cookie';

let Styled = loadStyle(ParentStyled, 'pages/web/CompletedInspections');
const layout = window?.$environment?.CURRENT_LAYOUT;

const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.completedInspections[0].name}`));
const Content = lazy(() => import(`@/${config.completedInspections[1].name}`));
const TopActions =
  config.completedInspections[0].children &&
  lazy(() => import(`${config.completedInspections[0].children.name}`));
const Tab = lazy(() => import(`../../../components/UI/Tab/${layout}`));
const isServiceManager = hasRestrictedRole([ROLES.SERVICE_MANAGER]);

const Inspections = (parentProps) => {
  const {
    fetchInspections,
    getFilters,
    fetchDriverUserListing,
    getDocumentToken,
    history,
    i18n,
    theme
  } = parentProps;
  const commonParentProps = { history, i18n, Styled, theme };

  const basicRequest = {
    displayLength:
      window.$environment.SERVICE_PROVIDER === 'spireon' ? '10' : '15',
    displayStart: '1'
  };
  const [pagination, setPagination] = useState(false);
  const [auth, setAuth] = useState(null);

  useEffect(() => {
    const token = getCookie(COOKIE_AUTH_DETAILS);
    if (token) {
      setAuth(token);
    }
  }, []);

  const [request, setRequest] = useState({
    ...basicRequest
  });

  const selectChange = (e) => {
    changeRequest({ search: e });
    setPagination(true);
  };

  const sortColumnMapping = {
    unitId: "vehicleName",
    work_order_id: "idPub",
    type: "unitType",
    reported_date: "createDate",
    scheduled_date: "beginDate",
    due_date: "endDate",
    workOrderType: "type",
    status: "state"
  }
  
  const changeRequest = (fields) => {
    if(fields?.sortCol){
      const type = fields.sortCol
      fields.sortCol = sortColumnMapping[type]? sortColumnMapping[type]: fields.sortCol 
    }
    setRequest({ ...basicRequest, ...fields });
  };

  const searchConfig = {
    setPagination,
    searchPlaceholder: 'Search Inspection Logs',
    changeRequest,
    fromData: false
  };


  useEffect(() => {
    const user = getCookie(COOKIE_AUTH_DETAILS);
    let requestToModify = {...request}
    if(hasRestrictedRole(['MECHANIC'])){
      requestToModify = {...request, mechanicId: user?.userId } 
    }
    else if(hasRestrictedRole(['DRIVER'])){
      requestToModify = {...request, driverId: user?.userId } 
    }
    fetchInspections({
      apiId: 'fetchPendingMechanicInspections',
      data: { request: requestToModify, state: 'CLOSED' },
    });
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request]);
  useEffect(() => {
    fetchDriverUserListing({
      listApiId: 'fetchDriverUser'
    });
    getFilters({ apiId: 'getFilters' });
    getDocumentToken({ apiId: 'getDocToken' });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const isCustomDviRequired = hasEnabledFeature('customDvirEnabled') && !isServiceManager;
  const isAdvancedDviRequired = hasEnabledFeature('advancedDvirEnabled') && !isServiceManager;
  return (
    <>
    {!auth?.enterpriseFeatureDto?.dvirOnlyEnabled?
      <RenderComponent
        {...config.inspections[0]}
        parentProps={{
          ...parentProps,
          isAdvancedDviRequired,
          isCustomDviRequired,
          changeRequest,
          request,
          pagination,
          setPagination
        }}
        commonParentProps={commonParentProps}
        component={Title}
        childComponent={TopActions}
        searchConfig={
          window.$environment.CURRENT_LAYOUT === 'SpireonNew'
            ? searchConfig
            : null
        }
        noBackbutton={true}
      />: null }
      {auth?.enterpriseFeatureDto?.dvirOnlyEnabled? 
      <RenderComponentTab
        {...config.inspections[1]}
        parentProps={{
          ...parentProps,
          isAdvancedDviRequired,
          isCustomDviRequired,
          changeRequest,
          request,
          pagination,
          setPagination
        }}
        commonParentProps={commonParentProps}
        component={Content}
        history={history}
        i18n={i18n}
        pagination={pagination}
        setPagination={setPagination}
      />: (     <RenderComponent
        {...config.inspections[1]}
        parentProps={{
          ...parentProps,
          isAdvancedDviRequired,
          isCustomDviRequired,
          changeRequest,
          request,
          pagination,
          setPagination
        }}
        commonParentProps={commonParentProps}
        component={Content}
      />) }
    </>
  );
};

const RenderComponentTab = ({
  name,
  props,
  commonProps,
  title,
  parentProps,
  commonParentProps,
  component,
  history,
  i18n,
  verticalTab,
  selectedIndex,
  setSelectedIndexfn,
  pagination,
  setPagination,
  ...rest
}) => {
  const Component = component;
  const handleChange = (event, newValue) => {
    history.push(tabValues[newValue].url);
  };

  let newProps = props?.length ? getNewObject(parentProps, props) : {};
  newProps = {
    ...newProps,
    ...(commonProps?.length
      ? getNewObject(commonParentProps, commonProps)
      : {}),
    ...rest
  };

  const tabValues = [
    {
      name: 'Unassigned',
      value: (
        <Component
          {...newProps}
          // setIsToolsClicked={setIsToolsClicked}
          selectedIndex={selectedIndex}
          pagination={pagination}
          setPagination={setPagination}
        />
      ),
      url: pageUrls.unassignedInspections
    },
    {
      name: 'Pending Mechanic',
      value: (
        <Component
          {...newProps}
          // setIsToolsClicked={setIsToolsClicked}
          selectedIndex={selectedIndex}
          pagination={pagination}
          setPagination={setPagination}
        />
      ),
      url: pageUrls.pendingMechanicInspections
    },
    {
      name: 'Pending Driver',
      value: (
        <Component
          {...newProps}
          // setIsToolsClicked={setIsToolsClicked}
          selectedIndex={selectedIndex}
          pagination={pagination}
          setPagination={setPagination}
        />
      ),
      url: pageUrls.pendingDriverInspections
    },
    {
      name: 'Completed',
      value: (
        <Component
          {...newProps}
          // setIsToolsClicked={setIsToolsClicked}
          selectedIndex={selectedIndex}
          pagination={pagination}
          setPagination={setPagination}
        />
      ),
      url: pageUrls.completedInspections
    },
    {
      name: 'No Defect',
      value: (
        <Component
          {...newProps}
          // setIsToolsClicked={setIsToolsClicked}
          selectedIndex={selectedIndex}
          pagination={pagination}
          setPagination={setPagination}
        />
      ),
      url: pageUrls.noDefectInspections
    }
  ];
  if(isServiceManager || hasRestrictedRole(['MECHANIC'])){
    tabValues.pop()
  }else{
    tabValues.shift()
  }
  if(hasRestrictedRole(['MECHANIC'])){
    tabValues.shift()
  }
  return (
    <Styled.Content>
      <Tab
        data={tabValues}
        className="logwrapper"
        currentTab={isServiceManager? 3: 2}
        onChange={handleChange}
        vertical={true}
      />
    </Styled.Content>
  );
};
const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    inspections: state.pendingMechanicInspections,
    list: state.inspections,
    filters: state.filters,
    logBook: state.logBook
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchInspections: (data) => dispatch.pendingMechanicInspections.fetchData(data),
    bulkDownloadReport: (data) => dispatch.logBook.downloadData(data),
    fetchDriverUserListing: (data) => dispatch.inspections.fetchData(data),
    getFilters: (data) => dispatch.filters.fetchData(data),
    getDocumentToken: (data) => dispatch.logBook.documentToken(data),
    clearApiStatus: () => dispatch.asyncRequests.clearApiStatus(),
    viewReport: (data) => dispatch.viewReport.viewReportDocument(data)
  };
};

export default connect(mapState, mapDispatch)(Inspections);
Inspections.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  fetchInspections: PropTypes.func.isRequired
};
