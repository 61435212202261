import React, { lazy } from 'react';
const layout = window?.$environment?.CURRENT_LAYOUT;
const Table = lazy(() => import(`../../../components/UI/Table/${layout}`));
const Link = lazy(() => import(`../../../components/UI/Link`));

const Overview = ({
  list,
  i18n
}) => {

  const columns = [
    {
      id: 'companyName',
      disablePadding: false,
      label: i18n.t('dashboard.tabs.radiusComplianceAlerts.company'),
      needSorting: true
    },
    {
      id: 'radius',
      disablePadding: false,
      label: i18n.t('dashboard.tabs.radius'),
      needSorting: true
    },
    {
      id: 'severityGraph',
      disablePadding: false,
      label: i18n.t('dashboard.tabs.severity'),
      needSorting: true
    },
    {
      id: 'lossControl',
      disablePadding: false,
      label: i18n.t('dashboard.tabs.radiusComplianceAlerts.lossControl'),
      needSorting: true
    },
    {
      id: 'underwriter',
      disablePadding: false,
      label: i18n.t('dashboard.tabs.radiusComplianceAlerts.underwriter'),
      needSorting: true
    }
  ];
  const generateRows = () => {
    list.map(ele => {
      let leftCurve = ele.severity?.green ? 'green' : ele.severity?.yellow ? 'yellow' : 'red'
      let rightCurve = ele.severity?.red ? 'red' : ele.severity?.yellow ? 'yellow' : 'green'
      ele.severityGraph = ele.severity ? (<div className="severity-wrapper">
            <div style={{width: `${ele.severity?.green}%`}} className={`green-wrapper ${leftCurve == 'green' ? 'leftCurve addBorder' : ''} ${rightCurve == 'green' ? 'rightCurve' : ''}`}></div>
            <div style={{width: `${ele.severity?.yellow}%`}} className={`yellow-wrapper ${ele.severity?.yellow ? 'addBorder' : ''} ${leftCurve == 'yellow' ? 'leftCurve' : ''} ${rightCurve == 'yellow' ? 'rightCurve' : ''}`}></div>
            <div style={{width: `${ele.severity?.red}%`}} className={`red-wrapper ${leftCurve == 'red' ? 'leftCurve' : ''} ${rightCurve == 'red' ? 'rightCurve' : ''}`}></div>
      </div>) : (
        <div className="severity-wrapper">
          <div style={{width: `100%`}} className={`green-wrapper leftCurve addBorder rightCurve`}></div>
      </div>
      );
      // ele.companyName = <Link href={pageUrls?.scoreCard?.replace(':id', ele.companyId)}>{ele.companyName}</Link>
    })
    return list;
  };
  const information = i18n.t('common.tableEmptyMessage');
  return (
    <>
      <Table
        type="client"
        defaultSortBy={{ sortBy: 'date', order: 'desc' }}
        title=""
        columns={[...columns]}
        data={generateRows()}
        emptyMessage={information}
        itemsPerPage={10}
        id="dashBoardAlerts"
        noPagination
        actions={[]}
        i18n={i18n}
      />
    </>
  );
};

export default Overview;

Overview.defaultProps = {};
