import styled from 'styled-components';
import Parent from '../Base/style';

const IconContainer = styled(Parent.IconContainer)``;
const HeaderContainer = styled(Parent.HeaderContainer)``;
const LanguageContainer = styled(Parent.LanguageContainer)``;
const ModalContainer = styled(Parent.ModalContainer)``;
const OptionContainer = styled(Parent.OptionContainer)``;
const Styled = {
  IconContainer,
  HeaderContainer,
  LanguageContainer,
  ModalContainer,
  OptionContainer
};
export default Styled;
