import { vehicleNotInUseApi } from '../../../config/apis';

const initialState = {
  notInUseVehicles: [],
  notInUseVehiclesFilteredList: []
};

export const notInUseVehicles = {
  state: { ...initialState },
  reducers: {
    listSuccess(state, payload) {
      return {
        ...state,
        notInUseVehicles: [...payload],
        notInUseVehiclesFilteredList: [...payload]
      };
    },
    listFailed() {
      return { ...initialState };
    },
    filterSuccess(state, list) {
      return { ...state, notInUseVehiclesFilteredList: [...list] };
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...vehicleNotInUseApi,
              url: vehicleNotInUseApi.url.replace('{reason}', payload?.data)
            }
          },
          id: payload.apiId
        });
        response && this.listSuccess(response);
      } catch (error) {
        this.listFailed();
      }
    }
  })
};
