import styled from 'styled-components';
import Container from '../Base/style';

export default styled(Container)`
${({ theme }) => `
// box-shadow: 0 2px 10px rgba(0,0,0,0.03);
background-color: ${theme.colors.HEADER_COLOR} !important;
color: ${theme.colors.HEADER_TEXT} !important;
border-radius: 4px;
font-size: ${window.$environment?.clutchNewUI? `20px !important`: `18px !important`};
text-transform: capitalize;
${window.$environment?.clutchNewUI&& `padding-left: 0 !important;`}
`}

`;
