import styled from 'styled-components';

const PolicyWrapper = styled.div`
.viewSample {
    display: none;
}
`;
const SelectedPolicy = styled.div`
`;
const Styled = { SelectedPolicy, PolicyWrapper };
export default Styled;
