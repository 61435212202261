const routeConfigs = [
  {
    url: '/admin/dashboard',
    component: 'Dashboard',
    roles: ['ADMIN']
  },
  {
    url: '/admin',
    component: 'Dashboard',
    roles: ['ADMIN']
  },
  {
    url: '/admin/companies',
    component: 'Companies',
    roles: ['ADMIN']
  },
  {
    url: '/admin/companies/new',
    component: 'Companies/NewCompany',
    roles: ['ADMIN']
  },
  {
    url: '/admin/users',
    component: 'Users',
    roles: ['ADMIN']
  },
  {
    url: '/admin/subscriptions',
    component: 'Subscriptions',
    roles: ['ADMIN']
  }
];

export default routeConfigs;
