import React, { lazy, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useHistory } from 'react-router-dom';
import useTheme from '@material-ui/core/styles/useTheme';
import { useTranslation } from 'react-i18next';
import AuthContext from '@/services/auth';

import ParentStyled from './style';
import {
  loadStyle,
  getNewObject,
  loadPageConfig,
  objectArrayFromSameField
} from '@/utils/utils';
import { pageUrls } from '@/config/constants/keys';
let Styled = loadStyle(ParentStyled, 'pages/web/Registration');
const config = loadPageConfig();
const Content = lazy(() => import(`@/${config.registration[0].name}`));
const Registration = (parentProps) => {
  const {
    truckInfo,
    getTruckTypes,
    fetchRegistration,
    states,
    getTruckMake,
    getTruckModel,
    getCheckout,
    getStates,
    companyInfo,
    getCompanyInfo,
    fleetmanagerInfo,
    getFleetManagerInfo,
    getTruckInfo,
    getAllConnectionType,
    pageTitle
  } = parentProps;
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (auth?.auth) {
      history.push(pageUrls.dashboard);
    }
  }, []);
  const fetchRegistrationApiId = 'fetchRegistration';
  const fetchStateApiId = 'getStatesApi';
  const [validation] = useState(false);

  useEffect(() => {
    fetchRegistration({ apiId: fetchRegistrationApiId });
    !states.length &&
      getStates({
        fetchStateApiId
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [states]);

  useEffect(() => {
    if (!truckInfo.truckTypes.length) {
      getTruckTypes({
        id: 'fetchTruckTypes'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [truckInfo.truckTypes]);

  useEffect(() => {
    if (!truckInfo.make.length) {
      getTruckMake({
        id: 'fetchTruckMake'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [truckInfo.make]);
  useEffect(() => {
    if (!truckInfo.connectionType.length) {
      getAllConnectionType({
        id: 'fetchConnectionTypes'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [truckInfo.connectionType]);
  useEffect(() => {
    if (!Object.keys(truckInfo.model).length) {
      getTruckModel({
        id: 'fetchTruckModel'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [truckInfo.model]);

  useEffect(() => {
    if (!Object.keys(truckInfo.model).length) {
      getTruckModel({
        id: 'fetchTruckModel'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [truckInfo.model]);
  useEffect(() => {
    if (Object.keys(companyInfo.companyInfoData).length) {
      getCompanyInfo({
        id: 'getCompany',
        data: {
          companyId: companyInfo.companyInfoData.companyId
        }
      });

      getCheckout({
        id: 'getCheckoutDetails',
        data: {
          companyId: companyInfo.companyInfoData.companyId
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyInfo?.companyInfoData]);

  useEffect(() => {
    if (Object.keys(fleetmanagerInfo.fleetmanagerInfoData).length) {
      getFleetManagerInfo({
        id: 'getFleetManger',
        data: {
          userId: fleetmanagerInfo.fleetmanagerInfoData.userId
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fleetmanagerInfo.fleetmanagerInfoData]);

  useEffect(() => {
    if (Object.keys(truckInfo.addTruckInfoData).length) {
      getTruckInfo({
        id: 'getTruckInfo',
        data: {
          companyId: companyInfo.companyInfoData.companyId
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [truckInfo.addTruckInfoData]);

  const history = useHistory();
  const theme = useTheme();
  const i18n = useTranslation();
  const commonParentProps = { history, i18n, Styled, theme };

  // const stateToDisplay = objectArrayFromSameField(states);
  return (
    <RenderComponent
      {...config.registration[0]}
      parentProps={parentProps}
      commonParentProps={commonParentProps}
      component={Content}
      displayStates={objectArrayFromSameField(states)}
      setValidationSuccess={validation}
      pageTitle={pageTitle}
    />
  );
};

const RenderComponent = ({
  name,
  props,
  commonProps,
  title,
  parentProps,
  commonParentProps,
  component,
  displayStates,
  pageTitle,
  ...rest
}) => {
  const Component = component;
  let newProps = props?.length ? getNewObject(parentProps, props) : {};
  newProps = {
    ...newProps,
    ...(commonProps?.length ? getNewObject(commonParentProps, commonProps) : {})
  };
  return (
    <Component
      {...rest}
      {...newProps}
      states={displayStates}
      pageTitle={pageTitle}
    />
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    companyInfo: state.companyInfo,
    fleetmanagerInfo: state.fleetmanagerInfo,
    truckInfo: state.truckInfo,
    registration: state.registration,
    states: state.getStates,
    timezone: state.timezone
  };
};

const mapDispatch = (dispatch) => {
  return {
    handleLogout: (data) => dispatch.login.handleLogout(data),
    addCompanyInfo: (data) => dispatch.companyInfo.addCompany(data),
    getCompanyInfo: (data) => dispatch.companyInfo.getCompany(data),
    addFleetManagerInfo: (data) =>
      dispatch.fleetmanagerInfo.addFleetManager(data),
    getFleetManagerInfo: (data) =>
      dispatch.fleetmanagerInfo.getFleetManager(data),
    addTruckInfo: (data) => dispatch.truckInfo.addTruckInfo(data),
    getTruckInfo: (data) => dispatch.truckInfo.getTruckInfoData(data),
    getTruckTypes: (data) => dispatch.truckInfo.getTruckTypesData(data),
    fetchRegistration: (data) => dispatch.registration.fetchData(data),
    saveRegistration: (data) => dispatch.registration.saveData(data),
    getStates: (data) => dispatch.getStates.getStates(data),
    getTruckMake: (data) => dispatch.truckInfo.getTruckMakeData(data),
    getTruckModel: (data) => dispatch.truckInfo.getTruckModelData(data),
    addCheckout: (data) => dispatch.companyInfo.addCheckout(data),
    getCheckout: (data) => dispatch.companyInfo.getCheckout(data),
    addSigneeName: (data) => dispatch.fleetmanagerInfo.addSigneeName(data),
    timezone: (data) => dispatch.timezone.fetchData(data),
    getAllConnectionType: (data) =>
      dispatch.truckInfo.getAllConnectionType(data),
    clearCompanyInfoValues: (data) => dispatch.companyInfo.clearValues(data),
    clearFleetInfoValues: (data) => dispatch.fleetmanagerInfo.clearValues(data),
    clearTruckInfoValues: (data) => dispatch.truckInfo.clearValues(data)
  };
};

export default connect(mapState, mapDispatch)(Registration);

Registration.defaultTypes = {};
Registration.propTypes = {
  apiStatus: PropTypes.object,
  handleLogout: PropTypes.func,
  addCompanyInfo: PropTypes.func,
  getStates: PropTypes.func,
  addFleetManagerInfo: PropTypes.func,
  addTruckInfo: PropTypes.func,
  getTruckInfo: PropTypes.func,
  getTruckTypes: PropTypes.func
};
