import { useTheme } from '@material-ui/core';
import React, { lazy } from 'react';

const layout = window?.$environment?.CURRENT_LAYOUT;

const DonutChart = lazy(() => import(`@/components/UI/Graph/DonutChart/${layout}`));

const DonutGraphInfo = ({
    backgroundColor = null,
    borderColor= null,
    data = null,
    centerTextData = null,
    small = false,
    labels = null,
    onClick = null,
    hideHover= false,
    ...rest
}) => {
    const theme = useTheme();
    return (
            <DonutChart
            {...rest}
            width={small ? "120px" : "250px"}
            height={small ? "120px" : "250px"}
            backgroundColor={backgroundColor}
            borderColor={borderColor || backgroundColor}
            data={data}
            hideHover={hideHover}
            areagraph
            title={''}
            theme={theme}
            onClick={onClick}
            labels={labels}
            small={small}
            centerTextData={centerTextData}
            noHyperLink>
        </DonutChart>
    );
};


DonutGraphInfo.propTypes = {

};


export default DonutGraphInfo;
