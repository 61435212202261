import { getDriversApi } from '../../../config/apis';

const initialState = {
  data: [],
  selectedItems: [],
  originalData: []
};

export const drivers = {
  state: { ...initialState },
  reducers: {
    handleGetDrivers: (state, data) => {
      return {
        //Mock data
        ...state,
        data: [...data]
      };
      // return { ...state, data };
    },
    getDriversFailed: (state) => {
      return {
        ...initialState
      };
    }
  },
  effects: (dispatch) => ({
    async getDrivers(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            data: null,
            api: getDriversApi
          },
          id: 'getDrivers'
        });
        const drivers = response.map((item) => {
          return {
            label: `${item.firstName} ${item.lastName}`,
            value: item.id
          };
        });
        this.handleGetDrivers(drivers);
      } catch (error) {
        this.getDriversFailed();
      }
    }
  })
};
