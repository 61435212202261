import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import { getStyle } from '../../../utils/utils';

const HeaderContainer = styled.div`
  ${({ theme }) => `
  #container {
    flex-direction: column;
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
    padding: 4px 1%;
    padding-left: 0;
    padding-bottom: 0;
    background-color: ${theme.colors.PUBLIC_HEADERCOLOR};
    font-size: 24px;

    h1 {
      padding-left: 3%;
      line-height: 41px;
      flex: 1;
    }
  }
  #menu {
    .MuiListItem-root:hover .MuiListItemIcon-root {
      color: ${theme.colors.LINK_COLOR};
    }
  }
  .not-authenticated {
    flex-direction: row !important;
    height: 73px;
    background-color: ${theme.colors.BLACK};
  }
  `}
`;

const IconContainer = styled.div`
${({ theme }) => `
.profileIconLogo {
  padding-top: 3px;
  float: right;
  padding-right: 3px;
  cursor: pointer;
  width: 36px;
  height: 36px;
  justify-content: space-around;
  display: flex;
  align-items: center;
  border-radius: 50%;
  background: ${theme.colors.USER_LOGO_BACKGROUND};
  color: ${theme.colors.USER_LOGO_COLOR};
  text-transform: uppercase;
  margin-right: 10px;
  font-size: 18px;
  font-weight: bold;
}
  width: 100%;
  .MuiGrid-container {
    float: right;
    width: auto;
  }
  .MuiAvatar-root {
    width: 25px !important;
    height: 25px !important;
    border-radius: 50% !important;
    cursor: pointer;
  }
`}`;

const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
const LanguageContainer = styled.div`
  ${({ theme }) => `
 right:14%;
 position: absolute;
  cursor: pointer;
  text-align: right;
  align-self: center;
  color: ${getStyle(
    '',
    (theme.palette.secondary || {}).main,
    theme.colors.SECONDARY_MAIN
  )}};
  padding-top: 5px;
  font-size: 25px;
  @media (max-width: 375px) {
    font-size: 15px;
  }
  @media (min-width: 375px) and (max-width: 768px) {
    font-size: 15px;
  }
  `}
`;

const OptionContainer = styled(Box)`
  ${({ theme }) => `
    padding: 10px 12px;
    width: 165px;
    position: absolute;
    z-index: 20;
    top: 45px;
    background-color: ${theme.colors.BLACK};
    border: 2px solid ${theme.colors. PRIMARY_BORDER};
    right: 8px;
    .MuiMenuItem-gutters {
      padding-left: 0;
      .menuItem span {
        padding-left: 9px;
        margin-top: -3px;
      }
    }
  .MuiMenuItem-root {
    font-size: 16px;
  }

  .MuiListItemIcon-root {
    color: ${getStyle(
      '',
      (theme.palette.primary || {}).contrastText,
      theme.colors.PRIMARY_CONTRAST
    )}};
    font-size: 25px;
  }
  `}
`;

const Styled = {
  IconContainer,
  HeaderContainer,
  LanguageContainer,
  ModalContainer,
  OptionContainer
};
export default Styled;
