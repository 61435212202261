import React, { lazy } from 'react';
import PropTypes from 'prop-types';

import { getAuthDetails } from '@/utils/utils';
import TopActions from './TopActions';
const layout = window?.$environment?.CURRENT_LAYOUT;
const RadioButton = lazy(() => import(`@/components/UI/Radio/${layout}`));

const Component = ({
  Styled,
  subscriptions,
  i18n,
  upgrade,
  callback,
  apiId,
  topAction,
  subscriptionTier,
  setSubscription,
  isPrimeNeeded
}) => {
  const formatString = (text) => {
    return text.split("_").map(word => word[0].toUpperCase() + word.slice(1).toLowerCase()).join(" ");
  }
  return (
    <Styled.Wrapper>
      {/* {topAction && (
        <TopActions {...{ i18n, subscriptionTier, callback, apiId, upgrade }} />
      )} */}
      <Styled.TableWrapper>
        <table className="test-table">
          <thead>
            <td></td>
            {subscriptions?.subscriptionTiers?.map((subscriptionTiers) => {
              return <td>{subscriptionTiers?.displayName}</td>;
            })}
          </thead>
          <tbody>
            <tr>
              {getAuthDetails() && subscriptionTier && (
                <>
                  <td className="title">Subscriptions</td>
                  {subscriptions?.subscriptionTiers?.map((subscriptionTiers) => {
                    return <td>
                            <RadioButton
                              disabled
                              options={[{ value: subscriptionTiers?.name }]}
                              // onClick={(e) => {
                              //   setSubscription(e);
                              // }}
                              value={subscriptionTier}
                            />
                         </td>;
                   })}
                </>
              )}
            </tr>
            {subscriptions && subscriptions?.features && Object.keys(subscriptions?.features).map((group) => {
              return (
                <>
                  <tr>
                    <td className="title" colSpan="4">
                      {formatString(group)}
                    </td>
                  </tr>
                  {subscriptions.features[group].map((feature) => {
                    return (
                      <tr>
                        <td>{feature?.featureName}</td>
                        {Object.keys(feature.subscriptionTiers).map(tier => (
                          <td
                            className={
                              feature.subscriptionTiers[tier]
                                ? 'subscription-tick'
                                : 'subscription-cross'
                            }
                          ></td>
                        ))}
                      </tr>
                    );
                  })}
                </>
              );
            })}
          </tbody>
        </table>
      </Styled.TableWrapper>
    </Styled.Wrapper>
  );
};
export default Component;

Component.propTypes = {
  history: PropTypes.object.isRequired,
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired
};
