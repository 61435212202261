import styled from 'styled-components';
import Parent from './style';
const Wrapper = styled(Parent.Wrapper)`

`;
const SearchWrapper = styled(Parent.SearchWrapper)`
    
`;
const TopWrapper = styled(Parent.TopWrapper)`
flex: 0 0 60%;
`;
const TableWrapper = styled(Parent.TableWrapper)`
padding-top: 0;
`;
const LabelWrap = styled(Parent.LabelWrap)`
${({ theme }) => `
    background: ${theme.colors.LINK_COLOR};
    height: 49px;
    .logsInfoDisplay{
        padding-top: 10px;
        padding-left: 2%;
        color: #fff;
    }
`}
`;
const Styled = {
    Wrapper,
    SearchWrapper,
    TopWrapper,
    TableWrapper,
    LabelWrap
  };
  export default Styled;
