import React, { useEffect } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import { useTranslation } from 'react-i18next';

import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

registerPlugin(FilePondPluginFileValidateSize);
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginImageResize);

const App = (props) => {
  useEffect(() => {
    if (props?.docLink) setFiles([props?.docLink]);
  }, [props.docLink]);
  const i18n = useTranslation();
  const { UploadWrapper } = props;
  const [renderFiles, setFiles] = React.useState([]);
  const [fileChosen, setfileChosen] = React.useState(false);
  useEffect(() => {
    if (props?.removeValues && props.removeValues === 'true') {
      setFiles([]);
    }
  }, [props.removeValues]);
  useEffect(() => {
    if (renderFiles && renderFiles.length === 0) {
      setfileChosen(false);
    }
  }, [renderFiles]);
  const handleFileUpload = (fileItems) => {
    props.fileChosen && props.fileChosen(fileItems);
    setFiles(fileItems);
    if (fileItems.length) {
      const files = [];
      files.push(fileItems.map((fileItem) => fileItem.file));
      props.onUpload(files);
      setfileChosen(true);
    }
  };
  props.imageUpload && registerPlugin(FilePondPluginImagePreview);
  return (
    <UploadWrapper
      allowMultiple={props.allowMultiple}
      normal={props.normal}
      fileChosen={props.normal ? fileChosen : ''}
    >
      <FilePond
        files={renderFiles}
        allowMultiple={props.allowMultiple}
        maxFiles={props.totalFiles}
        maxFileSize={props.maxFileSize ? props.maxFileSize : '10MB'}
        onupdatefiles={(e) => handleFileUpload(e)}
        labelIdle={
          props.imageUpload
            ? i18n.t('Upload')
            : props.allowMultiple
            ? `Drop atmost ${props.totalFiles} files here or click to upload`
            : i18n.t('Drop a file here or click to upload')
        }
        labelMaxFileSizeExceeded={`Max size allowed is ${
          props.maxFileSize ? props.maxFileSize : '10MB'
        }`}
        labelMaxFileSize=""
        acceptedFileTypes={props.acceptedFileTypes || []}
        fileValidateTypeDetectType={(source, type) =>
          new Promise((resolve, reject) => {
            resolve(type);
          })
        }
        allowFileTypeValidation={props.allowFileTypeValidation || false}
        fileValidateTypeLabelExpectedTypesMap={
          props.fileValidateTypeLabelExpectedTypesMap || {}
        }
        labelFileTypeNotAllowed={props.labelFileTypeNotAllowed || ''}
        imageResizeUpscale={true}
        imageResizeMode={'contain'}
        allowImageResize={true}
        imagePreviewUpscale={true}
        disabled={props?.disabled || false}
      />
    </UploadWrapper>
  );
};

export default App;
