import styled from 'styled-components';
import Parent from './style';
const Wrapper = styled(Parent.Wrapper)`

`;
const TopWrapper = styled(Parent.TopWrapper)`
    justify-content: flex-end;
    margin-right: 1%;
`;
const IconWrapper = styled(Parent.IconWrapper)`
${({ theme }) => `
   margin-right: 10px;
`}
`;

const ImpExpWrapper = styled(Parent.ImpExpWrapper)`
flex: 0;
`;

const SearchWrapper = styled(Parent.SearchWrapper)`

`;

const ButtonsWrapper = styled(Parent.ButtonsWrapper)`
${({ theme }) => `
.userBtnWrapper {
    padding-left: 22px;
    .MuiButton-root{
        font-size: 11px !important;
    }
}
`}
`;

const DocContainer = styled(Parent.DocContainer)`

`;
const TableWrapper = styled(Parent.TableWrapper)`
.MuiTable-root .MuiTableBody-root .MuiTableCell-root:last-child {
    padding-left: 0 !important;
    .iconContainer .MuiAvatar-root {
        width: 18px !important;
        height: 18px !important;
    }
}
table .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root:nth-child(7) {
    text-align: left !important;
  }
`;
const Styled = {
  Wrapper,
  IconWrapper,
  ImpExpWrapper,
  SearchWrapper,
  TopWrapper,
  ButtonsWrapper,
  TableWrapper,
  DocContainer
};
export default Styled;
