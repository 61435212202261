import styled from 'styled-components';
import Container from '../Base/style';

export default styled(Container)`
${({ theme }) => `
font-size: 12px !important;
background: -webkit-linear-gradient(top, #d0d8db 0%, #b3bec3 100%) !important;
color: #42505a !important;
`}
`;
