import React, { lazy, useState } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { Link as LinkLi } from '@material-ui/core';
import filterLogsListForm from './Form';
import { imageUrls, pageUrls } from '../../../config/constants/keys';
import { hasValue, hasRestrictedRole, isDriver } from '../../../utils/utils';
import { ROLES } from '@/config/constants/keys';

const layout = window?.$environment?.CURRENT_LAYOUT;
const CloseButton = lazy(() =>
  import(`@/components/CloseButton/${layout}`)
);
const Breadcrumb = lazy(() => import(`@/components/Breadcrumb`));
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Export = lazy(() => import(`@/components/UI/Export/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Label = lazy(() => import(`@/components/UI/Label/${layout}`));
const Input = lazy(() => import(`@/components/UI/Input/${layout}`));
const DatePopup = lazy(() =>
  import(`@/components/modules/DatePopup/${layout}`)
);
const Search = lazy(() =>
  import(`@/components/UI/SimpleSearch/${layout}`)
);

const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentlogsStyle }}>
      {layout !== 'OldUi' && <Breadcrumb filters={filters} />}
      <ManageLogs {...contentProps} filters={filters} />
    </Box>
  );
};
export default Index;
const ManageLogs = ({
  i18n,
  fields,
  isServer,
  filterLogsList,
  changeRequest,
  request,
  list,
  filterSuccess,
  apiStatus,
  handleLogBookStatus,
  clearApiStatus,
  selectedRow,
  fetchLoglistApiId,
  columns,
  actions,
  restrictRowClick,
  Styled,
  callback,
  isToolsClicked,
  showExport,
  isRealTimeLogs,
  setIsToolsClicked,
  selectedIndex,
  filters,
  showExtraOptions,
  viewReport,
  tableTotalCount,
  pagination,
  setPagination
}) => {
  //form values for the filter form
  const [values, setValues] = useState({
    driver_name: '',
    dateFrom: '',
    dateTo: ''
  });
  const [isExportClicked, setIsExportClicked] = useState(false);
  const [isFilterClicked, setIsFilterClicked] = useState(false);

  const generateRows = () => {
    return list.filteredList;
  };

  const hasLogListApiStatus = hasValue(
    apiStatus.status,
    'api',
    fetchLoglistApiId
  );
  const information =
    hasLogListApiStatus && hasLogListApiStatus.errorMessage ? (
      <Message
        type="error"
        message={i18n.t(hasLogListApiStatus.errorMessage)}
      />
    ) : (
        i18n.t('common.tableEmptyMessage')
      );
  const selectChange = (e) => {
    filterSuccess(e);
  };


  if(isDriver()){
    columns = columns.filter(col => col.id !== "driver_name");
  }
  
  return (
    <>
      <Styled.Header>
      {!isDriver() &&
        <>
          {filterLogsListForm(
            values,
            list,
            filterSuccess,
            setValues,
            fields,
            isServer,
            filterLogsList,
            i18n,
            setPagination,
            setIsFilterClicked
          )}
          {showExport && <Export
            src={imageUrls.exportIcon}
            displayText={
              window.$environment.SERVICE_PROVIDER === 'spireon'
                ? 'EXPORT DAILYLOG SIGNED STATUS'
                : ''
            }
            isOpened={isExportClicked}
            needLinkIcon={true}
            data={[...columns]}
            filename={'export_dailylog_signed_status'}
            toolTip={i18n.t('logs.dailyLogExportMessage')}
            className="exportContainer"
            buttonName={i18n.t('Export Data')}
            onClick={() => {
              if (window.$environment.SERVICE_PROVIDER === 'spireon') {
                setIsExportClicked(!isExportClicked);
              } else setIsExportClicked(true);
            }}
          />}
        </>}
        <DatePopup
          formId="bulkDownloadForm"
          isOpen={isExportClicked}
          setIsOpen={setIsExportClicked}
          exportHandler={handleLogBookStatus}
          apiStatus={apiStatus}
          clearApiStatus={clearApiStatus}
          data={list.list}
          callback={callback}
        />
        <div className="breadcrumbClass">
          {layout === 'OldUi' && !isDriver() && <Breadcrumb filters={filters} />}
        </div>
      </Styled.Header>
      <Styled.tableWrapper>
        <div className="spireonNewOnly">
          <span>{i18n.t('leftMenu.logs')}</span>
          <Search
            id={'logSearch'}
            default={list.list}
            onChange={(e) => selectChange(e)}
            width="25%"
            fromData
            query={['driver']}
            placeHolder={'Search Logs'}
          />
        </div>
        <div className="spireonOldOnly">{i18n.t('logs.spireonTitle')}</div>
        <Table
          type={isServer ? "server" :"client"}
          title=""
          changeRequest={changeRequest}
          baseRequest={request}
          defaultSortBy={{ sortBy: 'driver_name', order: 'asc' }}
          columns={[...columns]}
          data={generateRows()}
          totalRecords={tableTotalCount}
          emptyMessage={information}
          itemsPerPage={
            window.$environment.SERVICE_PROVIDER === 'spireon' ? 10 : 15
          }
          actions={actions}
          i18n={i18n}
          clickedRow={(row, id) =>
          (window.$environment.CURRENT_LAYOUT === 'Clutch' && !restrictRowClick) &&
            selectedRow(row)
          }
          actionRestricted={hasRestrictedRole(['ADMIN'])}
          pageinationReset={pagination}
          pagenationClicked={(e) => {
            setPagination(!e);
          }}
        />
        {window.$environment.SERVICE_PROVIDER === 'spireon' && isFilterClicked && (
          <Styled.InfoContainer className="totalSeperatorsp">
            <p className="seperator">
              <Label>{i18n.t('common.totalHoursWorked')}</Label>
              <Input value={list?.totalHours?.toFixed(2)} disabled={true} />
            </p>
            <p className="seperator">
              <Label>{i18n.t('common.totalDistance')}</Label>
              <Input value={list?.totalDistance} disabled={true} />
            </p>
          </Styled.InfoContainer>
        )}
        {isToolsClicked && (
          <Styled.ToolsContainer selectedindex={selectedIndex}>
            <CloseButton
              className="ToolsOptionClose"
              icon={imageUrls.popupClose}
              onClick={() => {
                setIsToolsClicked(false);
              }}
            />
            <ul>
              <li>
                <LinkLi
                  component={Link}
                  to={window.$environment.SERVICE_PROVIDER === 'spireon' ?  
                  `${pageUrls.logBook.replace(':id', list.selectedRow.row.idPub)}?driverId=${list.selectedRow.row.driverId}&logDay=`
                  :pageUrls.logBook.replace(
                    ':id',
                    list.selectedRow.row.idPub
                  )}
                >
                  {i18n.t('logs.logBook')}
                </LinkLi>
              </li>
              {showExtraOptions && (
                <li>
                  <LinkLi
                    component={Link}
                    onClick={() =>
                      viewReport({
                        apiId: 'viewReportApi',
                        data: list.selectedRow.row.dailylog
                      })
                    }
                  >
                    {i18n.t('PDF')}
                  </LinkLi>
                </li>
              )}
              { !isRealTimeLogs && <><li>
                <LinkLi
                  component={Link}
                  newPage
                  to={ hasRestrictedRole([ROLES.VIEW_ONLY_FM])? 
                    pageUrls.signedLogsView.replace(
                      ':id',
                      list.selectedRow.row.idPub
                    )
                    :pageUrls.logsEdit.replace(
                    ':id',
                    list.selectedRow.row.idPub
                  )}
                >
                  {!hasRestrictedRole([ROLES.VIEW_ONLY_FM])? i18n.t('common.edit') :i18n.t('common.view')}
                </LinkLi>
              </li>
              <li>
                <LinkLi
                  component={Link}
                  newPage
                  to={pageUrls.logsHistory.replace(
                    ':id',
                    list.selectedRow.row.idPub
                  )}
                >
                  {i18n.t('common.history')}
                </LinkLi>
              </li> </>}
              { !isDriver() && showExtraOptions && (
                <li>
                  <LinkLi
                    component={Link}
                    to={`${pageUrls.dailyDocuments}?driver=${list.selectedRow.row.driverId}&day=${list.selectedRow.row.date}`}
                  >
                    {i18n.t('logs.viewDocument')}
                  </LinkLi>
                </li>
              )}
            </ul>
          </Styled.ToolsContainer>
        )}
      </Styled.tableWrapper>
    </>
  );
};

ManageLogs.defaultProps = {};

ManageLogs.propTypes = {
  fields: PropTypes.array.isRequired,
  isServer: PropTypes.bool.isRequired,
  isDriverLogs: PropTypes.bool.isRequired,
  i18n: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  list: PropTypes.object.isRequired,
  filterSuccess: PropTypes.func.isRequired,
  apiStatus: PropTypes.object.isRequired,
  clearApiStatus: PropTypes.func.isRequired,
  handleLogBookStatus: PropTypes.func.isRequired
};
