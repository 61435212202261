import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
${({ theme }) => `
// padding: 3%;
  display: flex;
  flex-direction: column;
  form {
    flex-direction: column;
    width: 100%;
  }
  .quickFilters,
  .emOnly {
    display: none;
  }
  .searchTitle {
    color: ${theme.colors.PRIMARY_CONTRAST}; 
    text-transform: uppercase;
    font-size: 22px;
    margin: 0;
    padding-bottom: 6px;
  }
  .total {
    text-align: right;
    color: ${theme.colors.PRIMARY_CONTRAST};
    font-size: 20px;
    .amount {
      color: ${theme.colors.LINK_COLOR};
      font-size: 23px;
      padding-left: 10px;
    }
  }
  .exportContainer {
    .MuiAvatar-root {
      width: 35px;
      height: 35px;
    }
    .tooltiptext {
      top: 48px;
      width: 177px;
    }
  }
  .vertical {
    width: 20%;
    margin-right: 1% !important;
  }
  .MuiFormControlLabel-root{
    align-items: flex-end;
  }
  .vertical-button {
    width: 20%;
    justify-content: flex-start;
    .MuiBox-root {
      width: 100% !important;
    }
      button {
        margin-top: 29px;
    }
  }
  .radiobuttonWrapper {
    height: 47px;
    .checkWrapper {
      width: 41%;
      margin-right: 1%;
      margin-bottom: 0;
      .radio {
        width: 100%;
      }
      .MuiFormControlLabel-root {
        width: 50%;
        align-items: flex-start;
        .MuiFormControlLabel-label {
          padding-top: 0;
        }
      }
    }
  }
  .dateFieldwrapper {
    padding-top: 1%;
  }
  .radiobuttonWrapper,
  .dateFieldwrapper,
  .exportSection {
    display: flex;
  }
 
  .bounce-right {
    -webkit-animation: bounce-right 0.8s both;
            animation: bounce-right 0.8s both;
  }
@-webkit-keyframes bounce-right {
  0% {
    -webkit-transform: translateX(48px);
            transform: translateX(48px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateX(26px);
            transform: translateX(26px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateX(13px);
            transform: translateX(13px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateX(6.5px);
            transform: translateX(6.5px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateX(4px);
            transform: translateX(4px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87%,
  98% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
}
@keyframes bounce-right {
  0% {
    -webkit-transform: translateX(48px);
            transform: translateX(48px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateX(26px);
            transform: translateX(26px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateX(13px);
            transform: translateX(13px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateX(6.5px);
            transform: translateX(6.5px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateX(4px);
            transform: translateX(4px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87%,
  98% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
}

  .iftaErrorsSection{
    height: 35px;
    color: ${theme.colors.PRIMARY_CONTRAST};
    display: none;
    overflow: hidden;
    align-items: center;
    width: fit-content;
    padding-left: 10px;
    padding-right: 10px;
    border: none;
    border: 1px solid ${theme.colors.SECONDARY_MAIN};
    background-color: ${theme.colors.PRIMARY_MAIN};
    img {
      width: 20px;
      margin-right: 3px;
    }
    a {
      color: #4679ee;
      cursor: pointer;
      text-decoration: underline;
      margin-left: 3px;
    }
  }
  .showIftaError {
    display: flex;
    animation: 0.8s ease 0s 1 normal both running bounce-right;
  }
  .reportBtn{
    margin-left: 15px;
  }
  .exportSection {
    padding-bottom: 1%;
    justify-content: flex-end;
    .enable {
      align-self: flex-end;
    }
  }
  `}
`;
const TopWrapper = styled(Box)`
  ${({ theme }) => `
  display: flex;
  @media (max-width: ${theme.breakpoints.values.smd}px)  {
    flex-direction: column;
  }
  `}
`;

const TableWrapper = styled(Box)`
  padding-top: 1%;
`;

const Styled = { Wrapper, TopWrapper, TableWrapper };

export default Styled;
