import styled from 'styled-components';
import Parent from '../Base/style';
const TabWrapper = styled(Parent)`
${({ theme }) => `
.MuiTab-root:hover,
.MuiTabs-flexContainer .Mui-selected {
    border-bottom: 0;
}
.MuiAppBar-positionStatic {
    box-shadow: none;
}
.MuiTab-root:hover{
    border-bottom: 0 !important;
}
.Mui-selected .MuiTab-wrapper {
    color:  ${theme.colors.PRIMARY_CONTRAST};
    border-bottom: 3px solid ${theme.colors.SECONDARY_MAIN};
}
.MuiTab-wrapper {
    font-size: ${theme.palette.titleLabel};
    &:hover {
        border-bottom: 3px solid ${theme.colors.SECONDARY_MAIN};
    }
}
`}
`;
export default TabWrapper;
