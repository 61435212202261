
import styled from 'styled-components';
import Parent from './style';
import Box from '@material-ui/core/Box';
const LogBookContainer = styled(Parent.LogBookContainer)`
${({ theme }) => `
padding: 0;
flex: 0 0 73%;
padding-bottom: 6px;
.viewContainer{
    form {
        padding-left: 20px;
        flex: 0 0 auto;
        width: 39%;
        .vertical{
            align-self: flex-start;
            min-width: 180px;
        }
        .vertical-button{
            flex: 0 0 10%;
            button .MuiButton-label {
                padding: 0;
            }
        } 
        .basic-multi-select  {
            padding-top: 4px;
        }
        .react-datepicker__day:not(.react-datepicker__day--highlighted):not(.react-datepicker__day--disabled) {
            background: #ffffff !important;
            border: 2px solid #d3d3d3 !important;
            border-bottom: 2px solid #0379fc !important;
            color: #555555;
          }
    }
}
.datepopup{
    width: 350px;
    .vertical {
        flex: 0 0 35%;
    }
    .vertical-button {
        flex: 0 0 27%;
    }
    .MuiButtonBase-root {
        margin-top: 0 !important;
    }
    .react-datepicker-wrapper{
        padding-left: 0;
    }
}
.logsInfoDisplay {
    color: #42505a;
    font: bold 12px "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding: 10px;
    background: -webkit-linear-gradient(top, #d0d8db 0%, #b3bec3 100%);
}
.dailyDocument{
    display: none;
}
.buttonBulkDownload {
    min-height: 20px;
    padding: 0 15px !important;
}
.viewContainer{

.buttonBulkDownload {
    min-height: 20px;
    padding: 0 15px !important;
}
  .buttonBulkDownload,
  .vertical-button button {
    margin-top: 0 !important;
  }
}
.datepopup{
  margin-left: 39%;
}
.heading {
  padding: 10px !important;
  font-size: 22px;
}
.viewContainrer {
  padding-left:2%;
}
.displayContainer { 
  padding : 0 20px;
  flex-direction: column;
  width: 100%;
}
.leftWrapper {
    width: 98%;
  }
.linkContainer a {
  margin-top: 20px;
  background:#2d3942;
  color: ${theme.colors.WHITE};
  padding-top: 2px;
  font-size: 12px;
  height: 23px;
  text-transform: capitalize;
  border-radius: 3px;
}
.linkContainer div {
    margin-left: 3px;
}
.rightSide {
    width: 98%;
    .linkContainer {
        justify-content: flex-end;
        padding-bottom: 30px;
        a {
            text-transform: uppercase;
            padding-top: 3px;
            padding: 4px 15px !important;
        }
    }
    div[type='info'] p {
        text-align: left;
        font-size: 12px;
        color: #222 !important;
        padding-top: 10px;
        font-weight: bold;
    }
}
.MuiPaper-root{
    overflow-y: scroll;
    max-height: 164px;
}
`}
`;
const PdfContainer = styled(Parent.PdfContainer)``;
const MapContainer = styled(Parent.MapContainer)`
max-height: 100px !important;
`;
const MapWrapper = styled(Parent.MapWrapper)`
    display: none;
`;
const ViolationContainer = styled(Parent.ViolationContainer)`
${({ theme }) => `
border: 0;
.heading {
    color: #42505a;
    font: bold 12px "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding: 10px;
    background: -webkit-linear-gradient(top, #d0d8db 0%, #b3bec3 100%);
}
`}
`;
const LogbookWrap = styled(Parent.LogbookWrap)``;
const Styled = {
    LogBookContainer,
    PdfContainer,
    MapContainer,
    MapWrapper,
    ViolationContainer,
    LogbookWrap
  };
  
  export default Styled;