import { asyncRequests } from './asyncRequests';
import { accessLevel } from './modules/accessLevel';
import { accidents } from './modules/accidents';
import { addOrEditNewForm } from './modules/addOrEditNewForm';
import { advancedConfig } from './modules/advancedConfig';
import { alertList } from './modules/alert';
import { login } from './modules/auth';
import { camera } from './modules/camera/index';
import { cameraEvents } from './modules/cameraEvents';
import { cameraUrl } from './modules/cameraUrl';
import { chat } from './modules/chat';
import { companies } from './modules/companies';
import { companyData } from './modules/companyData';
import { CSASnapshot } from './modules/csaSnapshot';
import { csaViolations } from './modules/csaViolations';
import { customDvir } from './modules/customDvir';
import { customPolicy } from './modules/customPolicy/customPolicy';
import { dailyDocuments } from './modules/dailyDocuments/dailyDocumentList';
import { dashboard } from './modules/dashboard';
import { potentialViolations } from './modules/dashboard/potentialViolations';
import { dcaList } from './modules/dcaList';
import { departments } from './modules/departments';
import { developerRequest } from './modules/developerRequest';
import { documentList } from './modules/document/documents';
import { documents } from './modules/document/upload';
import { dotAudit } from './modules/dotAudit';
import { downloadCenter } from './modules/downloadCenter';
import { driverStatus } from './modules/driverStatus';
import { driverStatusList } from './modules/driverStatusList';
import { driverbehavior } from './modules/driverbehavior';
import { drivers } from './modules/drivers';
import { feedback } from './modules/feedback';
import { filters } from './modules/filters';
import { fleetCompanyList } from './modules/fleetCompanies';
import { forgotPassword } from './modules/forgotPassword';
import { fuelConsumption } from './modules/fuelConsumption';
import { iftaDetails } from './modules/iftaMileage/iftaDetails';
import { iftaList } from './modules/iftaMileage/iftaList';
import { iftaMileage } from './modules/iftaMileage/iftaMileage';
import { iftaVehicleInfo } from './modules/iftaMileage/vehicleInfo';
import { inspectionList } from './modules/inspectionList';
import { inspections } from './modules/inspections';
import { logBookStatus } from './modules/logBookStatus';
import { addLog } from './modules/manageLogs/addLog';
import { breadCrumbForMap } from './modules/manageLogs/breadCrumbForMap';
import { dailyLogDetails } from './modules/manageLogs/dailyLogDetails';
import { logsHistory } from './modules/manageLogs/history';
import { logBook } from './modules/manageLogs/logBook';
import { manageLogList } from './modules/manageLogs/manageLogList';
import { udLogList } from './modules/manageLogs/udLogList';
import { unAssignedEntries } from './modules/manageLogs/unAssignedEntries';
import { newScoreCard } from './modules/newScoreCard';
import { notInUseVehicles } from './modules/notInUseVehicles';
import { partsList } from './modules/partsList';
import { paymentInformation } from './modules/paymentInformation';
import { pendingMechanicInspections } from './modules/pendingMechanicInspections';
import { policies } from './modules/policies';
import { policyDownload } from './modules/policyDownload';
import { profile } from './modules/profile';
import { radiusComplianceAlert } from './modules/radiusComplianceAlert';
import { refillsHistory } from './modules/refilsHistory';
import { regions } from './modules/regions';
import { registration } from './modules/registration';
import { companyInfo } from './modules/registration/companyInfo';
import { fleetmanagerInfo } from './modules/registration/fleetmanagerInfo';
import { timezone } from './modules/registration/timezone';
import { truckInfo } from './modules/registration/truckInfo';
import { reports } from './modules/reports';
import { resetPassword } from './modules/resetPassword';
import { roadsideInspection } from './modules/roadsideInspection';
import { scoreCard } from './modules/scoreCard';
import { getStates } from './modules/states';
import { subscriptionTier } from './modules/subscriptionTier';
import { subscriptions } from './modules/subscriptions';
import { trailerEdit } from './modules/trailers/trailerEdit';
import { trailers } from './modules/trailers/trailers';
import { usDotManagement } from './modules/usDotManagement';
import { driverStatusHistory } from './modules/users/driverStatusHistory';
import { states } from './modules/users/states';
import { user } from './modules/users/user';
import { userEdit } from './modules/users/userEdit';
import { users } from './modules/users/userList';
import { vehiclePageMap } from './modules/vehiclePageMap';
import { vehicleStatusList } from './modules/vehicleStatusList';
import { vehicleAdd } from './modules/vehicles/vehicleAdd';
import { vehicleEdit } from './modules/vehicles/vehicleEdit';
import { vehicles } from './modules/vehicles/vehicleList';
import { viewReport } from './modules/viewReport';
import { workOrdersEdit } from './modules/workOrderEdit';
import { workOrders } from './modules/workOrders';

export {
  CSASnapshot, accessLevel, accidents, addLog, addOrEditNewForm, advancedConfig, alertList, asyncRequests, breadCrumbForMap, camera, cameraEvents, cameraUrl, chat, companies, companyData, companyInfo, csaViolations, customDvir, customPolicy, dailyDocuments, dailyLogDetails, dashboard, dcaList, departments, developerRequest, documentList, documents, dotAudit, downloadCenter, driverStatus, driverStatusHistory, driverStatusList, driverbehavior, drivers, feedback, filters, fleetCompanyList, fleetmanagerInfo, forgotPassword, fuelConsumption, getStates, iftaDetails, iftaList, iftaMileage, iftaVehicleInfo, inspectionList,
  inspections, logBook, logBookStatus, login, logsHistory, manageLogList, newScoreCard, notInUseVehicles, partsList, paymentInformation, pendingMechanicInspections, policies, policyDownload, potentialViolations, profile, radiusComplianceAlert, refillsHistory, regions, registration, reports, resetPassword, roadsideInspection, scoreCard, states, subscriptionTier, subscriptions, timezone, trailerEdit, trailers, truckInfo, udLogList,
  unAssignedEntries, usDotManagement, user, userEdit, users, vehicleAdd,
  vehicleEdit, vehiclePageMap, vehicleStatusList, vehicles, viewReport, workOrders, workOrdersEdit
};

