import React from 'react';
import PropTypes from 'prop-types';

const Label = ({ children, Wrapper, ...rest }) => {
  return <Wrapper {...rest}>{children}</Wrapper>;
};

Label.propTypes = {
  style: PropTypes.object
};

export default Label;
