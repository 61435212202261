import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import ReactDatePicker from 'react-datepicker';
import { getDateLocale } from '@/utils/utils';
import ScheduleComponent from '@/components/modules/ScheduleComponent';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));
const Select = lazy(() => import(`@/components/UI/Select/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const RenderComponent = ({
  values,
  handleEdit,
  errors,
  isNotValid,
  validateForm,
  submitAction,
  customProps: {
    i18n,
    drivers
  }
}) => {
  return (
    <>
      <FormStyle.default.FieldsWrapper className="fieldWrapper">
        <Label htmlFor="driverIds">{i18n.t('common.driver')}</Label>
        <FormStyle.default.TextWrapper>
          <Field
            name="driverIds"
            onChange={(event) => {
              handleEdit(event, { field: 'driverIds' });
            }}
            as={Select}
            // disabled={disabledCriteria?.regionId}
            placeholder={i18n.t('common.pleaseSelect')}
            value={values.driverIds}
            suggestions={drivers.data}
            isClearable
            isMulti
            allowSelectAll
          />
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      {values.recurrenceType == 'RECURRING' ? null : <>

        <FormStyle.default.FieldsWrapper className="fieldWrapper">
          <Label htmlFor="startDate">{i18n.t('common.dateFrom')}</Label>
          <FormStyle.default.TextWrapper>
            <Field
              name="startDate"
              onChange={(event) => {
                handleEdit(event, { field: 'startDate', type: 'date' });
              }}
              as={ReactDatePicker}
              locale={getDateLocale()}
              placeholderText={i18n.t('common.datePicker')}
              value={values.startDate}
              showPopperArrow={false}
              showTimeSelect={false}
              maxDate={values.endDate ? new Date(values.endDate) : new Date()}
              showDisabledMonthNavigation
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            {errors['startDate'] && !isNotValid && (
              <Message type="error" message={errors['startDate']} />
            )}
          </FormStyle.default.TextWrapper>
        </FormStyle.default.FieldsWrapper>
        <FormStyle.default.FieldsWrapper className="fieldWrapper">
          <Label htmlFor="endDate">{i18n.t('common.dateTo')}</Label>
          <FormStyle.default.TextWrapper>
            <Field
              name="endDate"
              onChange={(event) => {
                handleEdit(event, { field: 'endDate', type: 'date' });
              }}
              as={ReactDatePicker}
              locale={getDateLocale()}
              placeholderText={i18n.t('common.datePicker')}
              value={values.endDate}
              showPopperArrow={false}
              showTimeSelect={false}
              maxDate={new Date()}
              showDisabledMonthNavigation
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              minDate={
                values.startDate ? new Date(values.startDate) : new Date('01/01/2000')
              }
            />
            {errors['endDate'] && !isNotValid && (
              <Message type="error" message={errors['endDate']} />
            )}
          </FormStyle.default.TextWrapper>
        </FormStyle.default.FieldsWrapper>
      </>}
      <ScheduleComponent
        values={values}
        handleEdit={handleEdit}
        validateForm={validateForm}
        submitAction={submitAction}
        customProps={
          { i18n }
        }
      />
    </>
  );
};

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  customProps: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};

export default RenderComponent;
