const leftMenu = [
  {
    label: 'leftMenu.logs',
    roles: ['FLMANAGER', 'ADMIN', 'DRIVER'],
    path: '/logs/list'
  },
  {
    label: 'leftMenu.users',
    roles: ['FLMANAGER', 'ADMIN'],
    path: '/user/list'
  },
  {
    label: 'leftMenu.vehicles',
    roles: ['FLMANAGER', 'ADMIN', 'MECHANIC'],
    path: '/vehicle/list'
  },
  {
    label: 'leftMenu.accidents',
    roles: ['FLMANAGER', 'ADMIN', 'MECHANIC'],
    path: '/accidents/list'
  },
  {
    label: 'leftMenu.repairMaintenance',
    roles: ['FLMANAGER', 'ADMIN', 'MECHANIC'],
    path: '/workorders/list'
  },
  {
    label: 'leftMenu.inspections',
    roles: ['FLMANAGER', 'ADMIN'],
    path: '/inspections/list'
  },
  {
    label: 'leftMenu.filters',
    roles: ['FLMANAGER'],
    isFilter: true
  }
];
export default leftMenu;
