import styled from 'styled-components';
import Parent from '../Base/clientStyle';
const ButtonWrapper = styled(Parent.ButtonWrapper)``;
const FieldsWrapper = styled(Parent.FieldsWrapper)``;
const FormWrapper = styled(Parent.FormWrapper)`
${({ theme }) => `
.react-datepicker-wrapper input {
  background: ${theme.colors.BACKGROUND} none repeat scroll 0 0;
  border: 0;
  font-family: ${theme.palette.tableFont};
  font-size:16px;
}
.react-datepicker__close-icon{
  top: 0;
}
.react-datepicker__navigation--next{
  border-left-color: #000;
}
.react-datepicker__day{
  line-height: 1.6em;
}
.react-datepicker__day--disabled {
  pointer-events: none;
}
li.react-datepicker__time-list-item {
  font: 12px/1.428 Arial, "Geneva CY", sans-serif;
}
.react-datepicker__month-select,
.react-datepicker__year-select,
.react-datepicker__current-month {
  background: #cccccc !important;
}
.react-datepicker__navigation--previous {
  border-right-color: #000;
}
.react-datepicker__navigation--next--disabled,
.react-datepicker__navigation--next--disabled:hover {
  border-left-color: #222;
}
.react-datepicker__day:hover {
  background: #e6e6e6 !important;
  border: 1px solid #d3d3d3 !important;
  color: #555555;
  opacity: 0.7;
}
.react-datepicker__day {
  background: #e6e6e6 !important;
  border: 1px solid #d3d3d3 !important;
  color: #555555;
}

.react-datepicker__day--today {
  border: 1px solid #fcefa1!important;
  background: #fbf9ee!important;
  color: #363636!important;
  font-weight: normal;
}
`}
`;
const Styled = {
  ButtonWrapper,
  FieldsWrapper,
  FormWrapper
};

export default Styled;
