import React, { lazy, useState } from 'react';

import { getImagePath, hasRestrictedRole } from '@/utils/utils';

import AddEditRegion from './AddEditRegion';
import AddEditTerminal from './AddEditTerminal';
import { imageUrls, ROLES } from '@/config/constants/keys';
const layout = window?.$environment?.CURRENT_LAYOUT;
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`).default;
const Link = lazy(() => import(`@/components/UI/Link`));
const Modal = require(`@/components/UI/Modal/${layout}`).default;

const TopActions = ({
  isTermCrudRestricted,
  callback,
  setAddEditModalObject,
  closeAddEditModal,
  i18n,
  saveTerminal,
  data,
  getTmezonePopup,
  isCrudRestricted,
  isButtonStyle,
  clickedAddEditModal,
  isShowDotNumber,
  saveRegion,
  Styled,
  showFilterPopup,
  neglectAccessLevel
}) => {
  const [profileOpen, setProfileOpen] = useState(false);

  const clickedModal = (e) => {
    setProfileOpen(true);
  };
  const [ModalListObject, setModalListObject] = useState({
    header: '',
    content: () => {
      return null;
    }
  });
  const closeModal = (event) => {
    setProfileOpen(false);
  };
  const options = () => {
    clickedModal();
    setModalListObject({
      content: () => (
        <div className="vehicleModal">
          <ul>
            {(isTermCrudRestricted || neglectAccessLevel) && (
              <li>
                <Link
                  variant="body2"
                  onClick={() => {
                    setAddEditModalObject({
                      header: '',
                      content: () => {
                        return (
                          <AddEditTerminal
                            closeModal={closeAddEditModal}
                            callback={callback}
                            saveTerminal={saveTerminal}
                            data={data}
                            getTmezonePopup={getTmezonePopup}
                            isCrudRestricted={isCrudRestricted}
                            isButtonStyle={isButtonStyle}
                            isShowDotNumber={isShowDotNumber}
                            neglectAccessLevel={neglectAccessLevel}
                          />
                        );
                      }
                    });
                    clickedAddEditModal();
                    closeModal();
                  }}
                >
                  {i18n.t('departments.addTerminal')}
                </Link>
              </li>
            )}
            {(isCrudRestricted || neglectAccessLevel) && (
              <li>
                <Link
                  variant="body2"
                  onClick={() => {
                    setAddEditModalObject({
                      header: '',
                      content: () => {
                        return (
                          <AddEditRegion
                            closeModal={closeAddEditModal}
                            callback={callback}
                            saveRegion={saveRegion}
                            fleetManagers={data?.data?.fleetManagers}
                            serviceCenters={data?.data?.serviceCenters}
                            isCrudRestricted={isCrudRestricted}
                            neglectAccessLevel={neglectAccessLevel}
                          />
                        );
                      }
                    });
                    clickedAddEditModal();
                    closeModal();
                  }}
                >
                  {i18n.t('departments.addRegion')}
                </Link>
              </li>
            )}
          </ul>
        </div>
      )
    });
  };
  return (
    <Styled.Department>
      {isButtonStyle ? (
        <div className="buttonSection">
          {isCrudRestricted && (
            <FormStyle.ButtonsWrapper>
              <Button
                type="submit"
                className="buttonStyle addRegion"
                label={i18n.t('departments.addRegion')}
                onClick={() => {
                  setAddEditModalObject({
                    header: '',
                    content: () => {
                      return (
                        <AddEditRegion
                          closeModal={closeAddEditModal}
                          callback={callback}
                          saveRegion={saveRegion}
                          fleetManagers={data?.data?.fleetManagers}
                          serviceCenters={data?.data?.serviceCenters}
                        />
                      );
                    }
                  });
                  clickedAddEditModal();
                  closeModal();
                }}
              />
            </FormStyle.ButtonsWrapper>
          )}
          {isTermCrudRestricted && (
            <FormStyle.ButtonsWrapper>
              <Button
                type="submit"
                className="buttonStyle"
                label={i18n.t('departments.addTerminal')}
                onClick={() => {
                  setAddEditModalObject({
                    header: '',
                    content: () => {
                      return (
                        <AddEditTerminal
                          closeModal={closeAddEditModal}
                          callback={callback}
                          saveTerminal={saveTerminal}
                          data={data}
                          getTmezonePopup={getTmezonePopup}
                          isCrudRestricted={isCrudRestricted}
                          isButtonStyle={isButtonStyle}
                          isShowDotNumber={isShowDotNumber}
                        />
                      );
                    }
                  });
                  clickedAddEditModal();
                  closeModal();
                }}
              />
            </FormStyle.ButtonsWrapper>
          )}
        </div>
      ) : 
      (isTermCrudRestricted || neglectAccessLevel)? (
        <Icon
          src={getImagePath(imageUrls.addVehicle)}
          onClick={() => options()}
          tooltip={i18n.t('common.add')}
          className="AddButton"
        />
      ): ('')}
      <Modal
        open={profileOpen}
        setOpen={setProfileOpen}
        data={ModalListObject}
        isUserProfile
        optionListPopup
        isdepartmentModal
        addRegion
        top={100}
        right={2}
      />
    </Styled.Department>
  );
};
export default TopActions;
