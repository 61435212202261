import {
  editVehicleApi,
  editInsuranceVehicleApi,
  addVehicleApi,
  fetchLatLong,
  isValidVinVehicleApi,
  documentUploadApi,
  documentDeleteApi,
  isValidVinVehicleApiForClutch
} from '../../../../config/apis';
const initialState = {};

export const vehicleAdd = {
  state: { ...initialState },
  reducers: {
    addSuccess(state, payload) {
      return {};
    },
    addFailed() {
      return { ...initialState };
    },
    getSuccess(state, payload) {
      return { ...payload };
    },
    getRecallSuccess(state, payload) {
      return { ...payload, vehicleDetails: payload.vehicleDetails }
    },
    getFailed() {
      return { ...initialState };
    },
    updateLocalDocumentList(state, response) {
      const documents = state.documents.map((item) => {
        if (item.type.name === response?.response?.type?.name) {
          return { ...response.response };
        } else if (item.id === response?.id) {
          if (response?.isDelete) {
            return {
              ...item,
              id: null,
              name: null,
              url: null,
              uploaded: null,
              expiration_date: null
            };
          } else {
            return { ...item };
          }
        } else {
          return { ...item };
        }
      });
      return { ...state, documents: [...documents] };
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: editVehicleApi
          },
          id: payload.apiId
        });
        if(response && response.fuelTypes) {
          let idx = response.fuelTypes.indexOf('Special Diesel')
          response.fuelTypes.splice(idx, 1)
          response.fuelTypes.splice(0, 0, 'Special Diesel')
        }
        this.getSuccess(response)
      } catch (error) {
        this.getFailed();
      }
    },

    async fetchDataInsuranceUser(payload, rootState) {
      try {
        let queryParam = '?'
        if (payload?.data?.company) queryParam += `companyId=${payload?.data?.company}`
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...editInsuranceVehicleApi,
              url: editInsuranceVehicleApi.url + `${queryParam.length > 1 ? queryParam : ''}`
            }
          },
          id: payload.apiId
        });
        if (payload?.data?.recall) this.getRecallSuccess(response)
        else this.getSuccess(response)
      } catch (error) {
        this.getFailed();
      }
    },
    async fetchLatLongSave(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...fetchLatLong,
              url: fetchLatLong.url + `?address=${payload.address}`
            },
          },
          id: payload.latLongApiId
        });
        if (response && response?.lat) {
          let finalData = payload.data
          finalData.data.longitude = response?.lng
          finalData.data.latitude = response?.lat
          this.saveData({
            data: finalData,
            apiId: payload.apiId,
            callback: payload.callback
          })
        }else {
          payload.latLongCallback('error', payload.i18n.t('companyData.invalidGaragingAddress'));
        }
      } catch (error) {
        payload.latLongCallback('error', payload.i18n.t('companyData.invalidGaragingAddress'));
      }
    },
    async saveData(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: addVehicleApi,
            data: payload.data.data
          },
          id: payload.apiId
        });
        if(response) {
          (response.status != 207) && dispatch.vehicles.updateListResponse(response);
          payload.callback && payload.callback(response && response.status == 207, response.data?.errors);
        }
      } catch (error) {
        // this.addFailed();
      }
    },
    async isValidVin(payload, rootState) {
      try {
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: isValidVinVehicleApi,
            data: payload.data
          },
          id: payload.apiId
        });
      } catch (error) { }
    },
    async saveDocument(payload) {
      try {
        const data = payload.data.data;
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: documentUploadApi,
            data: data
          },
          id: payload.apiId,
          callback: payload.callback
        });
        response && this.updateLocalDocumentList({ response });
      } catch (error) {
        // this.saveFailed();
      }
    },
    async deleteDocument(payload) {
      try {
        const url = documentDeleteApi.url.split('{')[0];
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: { ...documentDeleteApi, url: url + payload.data.id }
          },
          id: payload.apiId
        });
        this.updateLocalDocumentList({
          isDelete: true,
          id: payload.data.id
        });
      } catch (error) {
        this.deleteFailed();
      }
    },
    async isValidVinForClutch(payload, rootState) {
      try {
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: isValidVinVehicleApiForClutch,
            data: payload.data
          },
          id: payload.apiId
        });
      } catch (error) { }
    },
    async removeApiStatus() {
      try {
        await dispatch.asyncRequests.clearApiStatus();
      } catch (error) { }
    }
  })
};
