import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { debounce } from 'lodash';
import RenderComponent from './RenderComponent';

import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = React.lazy(() => import(`@/components/UI/Form/${layout}`));

const ViewForm = ({
  hasApiStatus,
  callback,
  changeRequest,
  request,
  setPagination,
  tableData,
  commonProps,
  i18n,
  basicRequest
}) => {
  const history = useHistory();
  const location = useLocation();
  const [search, setSearch] = useState('');
  const [isMounted, setIsMounted] = useState(false);
  const fetchData = React.useCallback(debounce(async () => {
    changeRequest(search ? { ...request, search, displayLength: 15, displayStart: 1 } : {})
  }, 500), [search]);

  React.useEffect(() => {
    if (isMounted) {
    fetchData();
    return () => fetchData.cancel();
    } else {
      setIsMounted(true);
    }
  }, [fetchData])

  return (
      <Form
        type="server"
        direction="column"
        initialValues={{ ...basicRequest }}
        RenderComponent={RenderComponent}
        client
        handleOnSubmit={(fields) => {
          setPagination(true);
          const payload = {
            ...(search ? { search } : {}),
            ...(fields?.fromDate ? { fromDate: moment(new Date(fields?.fromDate)).format('YYYY-MM-DD') } : {}),
            ...(fields?.toDate ? { toDate: moment(new Date(fields?.toDate)).format('YYYY-MM-DD') } : {}),
        }
          changeRequest(payload)
          tableData({
            ...(fields?.fromDate ? { fromDate: moment(new Date(fields?.fromDate)).format('YYYY-MM-DD') } : {}),
            ...(fields?.toDate ? { toDate: moment(new Date(fields?.toDate)).format('YYYY-MM-DD') } : {}),
          });
        }}
        handleOnCancel={() => {
          history.replace(`${location?.pathname}${location?.search}`)
        }}
        setSearch={(e) => setSearch(e)}
        customProps={{
          callback,
          hasApiStatus,
          commonProps,
          search,
          i18n,
        }}
      />
  );
};

ViewForm.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired
};

export default ViewForm;
