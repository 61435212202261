import React, { lazy, useEffect } from 'react';
import PropTypes from 'prop-types';

import RenderComponent from './RenderComponent';

import { hasValue } from '../../../utils/utils';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`../../../components/UI/Form/${layout}`));
const PoliciesForm = ({
  apiStatus,
  submitPoliciesAndProcedures,
  history,
  Styled,
  policies,
  uploadLogo,
  downloadDocument,
  policyDownload,
  logBook,
  deleteLogo,
  clearState,
  saveCustomPolicy,
  editCustomPolicy,
  customPolicy,
  fetchCustomPolicy,
  handleDeleteCustomPolicyAlert,
  downloadCustomPolicy,
  callback,
}) => {
  const apiId = 'policyform';
  const uploadApiId = 'uploadLogo';
  const deleteApiId = 'deleteLogo';
  //Check whether the api is calling
  const hasApiStatus = hasValue(apiStatus.status, 'api', apiId);
  const beBaseUrl = window?.$environment?.BE_BASE_URL;
  useEffect(()=>{
    return () => {
      clearState()
    }
  },[])
  useEffect(() => {
    let url = policyDownload ? `${beBaseUrl}/${policyDownload}` : '';
    if (typeof policyDownload === 'string')
      document.getElementById(`policyDocument`).src = url;
  }, [policyDownload]);

  let policiesIds = policies.policies?.reduce(function (prev, curr) {
    prev[curr.policyType] = curr.id;
    return prev;
  }, {});
  let policyTypes = policies?.policies?.map(
    (item) => item.id && item.policyType
  );
  const initialValues = {
    file: '',
    policyTypes: policyTypes?.filter((item) => item !== null),
    policiesIds: policiesIds,
    policyParameters: {
      NUMBER_OF_ACCIDENTS_ALLOWED:
        (policies?.policies?.filter(
          (item) => item?.policyType === 'DRIVER_SELECTION_MVR'
        )[0]?.parameters &&
          policies?.policies?.filter(
            (item) => item?.policyType === 'DRIVER_SELECTION_MVR'
          )[0]?.parameters &&
          policies?.policies?.filter(
            (item) => item?.policyType === 'DRIVER_SELECTION_MVR'
          )[0]?.parameters &&
          policies?.policies?.filter(
            (item) => item?.policyType === 'DRIVER_SELECTION_MVR'
          )[0]?.parameters['NUMBER_OF_ACCIDENTS_ALLOWED']) ||
        null,
      NUMBER_OF_CITATIONS_ALLOWED:
        (policies?.policies?.filter(
          (item) => item?.policyType === 'DRIVER_SELECTION_MVR'
        )[0]?.parameters &&
          policies?.policies?.filter(
            (item) => item?.policyType === 'DRIVER_SELECTION_MVR'
          )[0]?.parameters['NUMBER_OF_CITATIONS_ALLOWED']) ||
        null,
      MIN_DRIVER_AGE:
        (policies?.policies?.filter(
          (item) => item?.policyType === 'DRIVER_SELECTION_MVR'
        )[0]?.parameters &&
          policies?.policies?.filter(
            (item) => item?.policyType === 'DRIVER_SELECTION_MVR'
          )[0]?.parameters['MIN_DRIVER_AGE']) ||
        null,
      MIN_YEARS_ON_SIMILAR_EQUIPMENT:
        (policies?.policies?.filter(
          (item) => item?.policyType === 'DRIVER_SELECTION_MVR'
        )[0]?.parameters &&
          policies?.policies?.filter(
            (item) => item?.policyType === 'DRIVER_SELECTION_MVR'
          )[0]?.parameters['MIN_YEARS_ON_SIMILAR_EQUIPMENT']) ||
        null,
      TRAINING_DAYS:
        (policies?.policies?.filter(
          (item) => item?.policyType === 'DRIVING_TRAINING'
        )[0]?.parameters &&
          policies?.policies?.filter(
            (item) => item?.policyType === 'DRIVING_TRAINING'
          )[0]?.parameters['TRAINING_DAYS']) ||
        null,
      MIN_HOURS:
        (policies?.policies?.filter(
          (item) => item?.policyType === 'DRIVING_TRAINING'
        )[0]?.parameters &&
          policies?.policies?.filter(
            (item) => item?.policyType === 'DRIVING_TRAINING'
          )[0]?.parameters['MIN_HOURS']) ||
        null,
      SAFETY_PERSON_FULL_NAME:
        (policies?.policies?.filter(
          (item) => item?.policyType === 'ACCIDENT_INVESTIGATION'
        )[0]?.parameters &&
          policies?.policies?.filter(
            (item) => item?.policyType === 'ACCIDENT_INVESTIGATION'
          )[0]?.parameters['SAFETY_PERSON_FULL_NAME']) ||
        null,
      ALLOW_USING_PHONES:
        policies?.policies?.filter(
          (item) => item?.policyType === 'DISTRACTION_FREE_DRIVING'
        )[0]?.parameters &&
        policies?.policies?.filter(
          (item) => item?.policyType === 'DISTRACTION_FREE_DRIVING'
        )[0]?.parameters['ALLOW_USING_PHONES']
          ? true
          : false
    },
    DRESS_CODE: policyTypes?.includes('DRESS_CODE'),
    INSPECTION: policyTypes?.includes('INSPECTION'),
    PSP_USAGE: policyTypes?.includes('PSP_USAGE'),
    LOSS_CONTROL_MANUAL_POLICY: policyTypes?.includes(
      'LOSS_CONTROL_MANUAL_POLICY'
    ),
    DRIVER_SELECTION_MVR: policyTypes?.includes('DRIVER_SELECTION_MVR'),
    DRIVING_TRAINING: policyTypes?.includes('DRIVING_TRAINING'),
    ACCIDENT_INVESTIGATION: policyTypes?.includes('ACCIDENT_INVESTIGATION'),
    DISTRACTION_FREE_DRIVING: policyTypes?.includes('DISTRACTION_FREE_DRIVING')
  };

  const fileUpload = (fields) => {

    if(fields.file === undefined) {
      deleteLogo({
        data: {
          id: policies.fileId
        },
        deleteApiId
      })
    }else {
      if (fields.file && fields.file[0].name !== policies.fileName) {
        const request = {
          type: 'COMPANY_LOGOTYPE',
          editUpload: false,
          name: fields.file[0].name,
          doctype: 'LOGOTYPE',
          id: policies.fileId
        };
        uploadLogo({
          data: {
            data: {
              file: fields.file[0],
              request: JSON.stringify(request)
            }
          },
          uploadApiId,
          callback: policyCallback
        });
      } 
    }    
  };

  const policyCallback = () => {
    window.location.reload();
  };
  return (
    <Styled.Wrapper>
      <div className="formLogin">
        <Form
          type="server"
          direction="column"
          RenderComponent={RenderComponent}
          initialValues={initialValues}
          handleOnSubmit={(fields) => {
            fileUpload(fields)
            submitPoliciesAndProcedures({
              data: {
                policyTypes: fields.policyTypes,
                policiesIds: fields.policiesIds,
                policyParameters: {
                  ...fields.policyParameters,
                  ALLOW_USING_PHONES: fields.policyParameters.ALLOW_USING_PHONES
                    ? 'ALLOW_USING_PHONES'
                    : null
                }
              },
              apiId,
              callback: (fields.file === undefined) || (fields.file && fields.file[0].name !== policies.fileName) ? null : () => window.location.reload() ,
              fields: fields
            });
          }}
          customProps={{
            hasApiStatus,
            history,
            Styled,
            policies,
            downloadDocument,
            logBook,
            saveCustomPolicy,
            editCustomPolicy,
            customPolicy,
            handleDeleteCustomPolicyAlert,
            callback,
            fetchCustomPolicy,
            downloadCustomPolicy
          }}
        />
      </div>
      <iframe id="policyDocument" style={{ display: 'none' }}></iframe>
    </Styled.Wrapper>
  );
};
export default PoliciesForm;

PoliciesForm.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  submitPoliciesAndProcedures: PropTypes.func.isRequired,
  history: PropTypes.object
};
