import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`${({ theme }) => `
  form .section-wrapper {
    background-color: ${theme.colors.POLICIES_BACKGROUND ? theme.colors.POLICIES_BACKGROUND : '#fff'};
    padding: 2% 3%;
    margin-bottom: 30px;
    border-radius: 5px;
    .titlePolicy {
      padding-left: 0 !important;
    }
    .customPolicy {
      padding-left: 0 !important;
    }
  }

  .policyLine {
    padding-top: 1px;
    .filepond--wrapper {
      height: 120px !important;
    }
    .policyText {
      p:last-child {
        font-size: 12px;
      }
    }
  }
`}
`;

const Styled = { Wrapper };

export default Styled;
