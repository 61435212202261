import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
  ${({ theme }) => `
  display: flex;
  flex-direction: column;
  .viewReport {
    color: ${theme.colors.LINK_COLOR};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  `}
`;
const TopWrapper = styled(Box)`
  ${({ theme }) => `

  display: flex;
  @media (max-width: ${theme.breakpoints.values.smd}px) {
    flex-direction: column;
  }
  `}
`;

const SearchWrapper = styled.div`
  ${({ theme }) => `

  flex: 0 0 40%;
  @media (max-width: ${theme.breakpoints.values.mlg}px) {
    flex: 0 0 38%;
  }
  #clearSearch {
    display: none;
  }
  `}
`;

const TableWrapper = styled(Box)`
  padding-top: 2.5%;
`;

const LabelWrap = styled(Box)`
  p {
    font-size: 21px;
    margin-bottom: 20px;
  }
`;
const Styled = {
  Wrapper,
  SearchWrapper,
  TopWrapper,
  TableWrapper,
  LabelWrap
};
export default Styled;
