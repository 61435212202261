import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

const styles = theme => ({
  tooltip: {
    backgroundColor: '#29324f',
    color: 'white',
    fontSize: '0.75rem',
    border: '1px solid white',
    borderRadius: '4px',
    padding: '1rem'
  },
  arrow: {
    "&:before": {
      border: "1px solid white"
    },
    color: '#29324f'
  },
});

const CustomTooltip = withStyles(styles, { name: 'CustomTooltip' })(Tooltip);

const ChartToolTip = ({ children, ...rest }) => {
  return (
    <CustomTooltip {...rest} arrow>
      {children}
    </CustomTooltip>
  );
};

export default ChartToolTip;
