import React, { useEffect, lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { UI_LOGBOOK_DATE } from '@/config/constants/static';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  getImagePath,
  loadStyle,
  hasRestrictedRole,
  getNewObject,
  loadPageConfig,
  isDriver
} from '@/utils/utils';
import { UI_DATE_LOWER_FORMAT } from '@/config/constants/static';
import ParentStyled from '@/components/modules/LogsManagement/style';
import { imageUrls, pageUrls } from '@/config/constants/keys';
import { useTheme } from '@material-ui/core/styles';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const Tab = lazy(() => import(`@/components/UI/Tab/${layout}`));
const config = loadPageConfig();
const Component = lazy(() => import(`@/${config.archiveLogsList[0].name}`));
const SvgIcon = lazy(() => import(`@/components/SvgIcon/${layout}`));

let Styled = loadStyle(ParentStyled, 'components/modules/LogsManagement');
const Index = (parentProps) => {
  const { manageLogList, fetchLogList, downloadLogPdf, tableTotalCount, getFilters, fetchDrivers, drivers } = parentProps;
  const [pagination, setPagination] = useState(false);
  const history = useHistory();
  const i18n = useTranslation();
  const theme = useTheme();
  const commonParentProps = { history, i18n, Styled, theme };
  const [selectedIndex, setSelectedIndex] = useState(0);
  const setSelectedIndexfn = (index) => {
    let rowLen =
      window.$environment.SERVICE_PROVIDER === 'spireon'
        ? 36
        : window.$environment.APP_LAYOUT === 'NewUi'
          ? 40
          : 39;
    let value = rowLen * index;
    let wl =
      window.$environment.SERVICE_PROVIDER === 'spireon'
        ? 104
        : window.$environment.APP_LAYOUT === 'NewUi'
          ? 82
          : 74;
    let changeValue = wl + value;
    setSelectedIndex(changeValue);
  };

  useEffect(() => {
    getFilters({ apiId: 'fetchFilters' });
    fetchDrivers({ apiId: 'fetchDrivers' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (document.getElementById('list1')) {
      const isSelected = document
        .getElementById('list1')
        ?.className.includes('Mui-selected');
      document.getElementById('list1').className = !isSelected
        ? document.getElementById('list1').className + ' Mui-selected'
        : document.getElementById('list1').className;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.getElementById('list1')]);

  return (
    <RenderComponent
      {...config.archiveLogsList[0]}
      parentProps={parentProps}
      drivers={drivers}
      commonParentProps={commonParentProps}
      component={Component}
      history={history}
      i18n={i18n}
      fetchLogList={fetchLogList}
      downloadLogPdf={downloadLogPdf}
      tableTotalCount={tableTotalCount}
      list={manageLogList}
      selectedIndex={selectedIndex}
      setSelectedIndexfn={setSelectedIndexfn}
      pagination={pagination}
      setPagination={setPagination}
    />
  );
};

const RenderComponent = ({
  name,
  props,
  commonProps,
  title,
  parentProps,
  commonParentProps,
  component,
  history,
  drivers,
  i18n,
  fetchLogList,
  downloadLogPdf,
  tableTotalCount,
  verticalTab,
  selectedIndex,
  setSelectedIndexfn,
  pagination,
  setPagination,
  ...rest
}) => {
  const Component = component;
  const handleChange = (event, newValue) => {
    history.push(tabValues[newValue].url);
  };
  const basicRequest = {
    displayLength: '15',
    displayStart: '1'
  };
  const [request, setRequest] = useState({
    ...basicRequest
  });
  const [isToolsClicked, setIsToolsClicked] = useState(false);
  const sortColumnMapping = {
    vehicle_name: "vehicleName",
    driver_name: "driverName",
    date: "day",
  }
  useEffect(() => {
    fetchLogList({
      apiId: 'fetchArchiveLoglistApiId',
      data: { ...request }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request]);

  const actions = [
    {
      icon: (row, index) => {
        return (
          <Icon
            src={getImagePath(imageUrls.downloadIcon)}
            tooltip={i18n.t('Download')}
            onClick={(e) => {
              e.stopPropagation();
              downloadLogPdf({ apiId: "downloadLogPdf", data: row.dailylog })
            }}
          />
        );
      }
    }
  ]

  let newProps = props?.length ? getNewObject(parentProps, props) : {};
  newProps = {
    ...newProps,
    ...(commonProps?.length
      ? getNewObject(commonParentProps, commonProps)
      : {}),
    ...rest
  };
  const filterLogsList = (data) => {
    setRequest({ ...request, ...basicRequest, ...data })
  }
  const changeRequest = (fields) => {
    if (fields?.sortCol) {
      const type = fields.sortCol
      fields.sortCol = sortColumnMapping[type] ? sortColumnMapping[type] : fields.sortCol
    }
    setRequest({ ...request, ...basicRequest, ...fields });
  };

  const selectedRow = (row) => {
    let day = moment(new Date(row.date)).format(UI_LOGBOOK_DATE)
    !hasRestrictedRole(['ADMIN']) &&
      history.push(`${pageUrls.logBook.replace(':id', row.idPub)}?driverId=${row.driverId}&logDay=${day}`);
  };

  const tabValues = [
    {
      name: i18n.t('logs.manageLogsHeader'),
      value: null,
      url: pageUrls.logsList
    },
    {
      name: i18n.t('logs.undefinedDriverHeader'),
      value: null,
      url: pageUrls.logsUdList
    },
    ...(window.$environment.SERVICE_PROVIDER !== 'spireon' ? [
    {
      name: i18n.t('logs.addLogHeader'),
      value: null,
      url: pageUrls.logsAdd
    },
    { 
      name: i18n.t('logs.archiveLogsHeader'),
      value: (
        <>
          <Component
            selectedRow={(row, id) => selectedRow(row)}
            isDriverLogs={false}
            {...newProps}
            actions={actions}
            isServer={true}
            restrictRowClick
            filterLogsList={filterLogsList}
            changeRequest={changeRequest}
            request
            isToolsClicked={isToolsClicked}
            setIsToolsClicked={setIsToolsClicked}
            selectedIndex={selectedIndex}
            pagination={pagination}
            setPagination={setPagination}
            tableTotalCount={tableTotalCount}
            fields={[
              {
                type: 'select',
                suggestions: drivers.data,
                value: '',
                field: 'driver_name',
                placeholder: i18n.t('common.driver'),
                label: i18n.t('common.driver')
              },
              {
                type: 'date',
                value: '',
                field: 'dateFrom',
                placeholder: UI_DATE_LOWER_FORMAT,
                label: i18n.t('common.dateFrom'),
                isTimeNeeded: false,
                isClearable: true
              },
              {
                type: 'date',
                value: '',
                field: 'dateTo',
                placeholder: UI_DATE_LOWER_FORMAT,
                label: i18n.t('common.dateTo'),
                isTimeNeeded: false,
                minValue: 'dateFrom'
              }
            ]}
          />
        </>
      ),
      url: pageUrls.archiveLogsList
    }] : []),
    ...(window.$environment.SERVICE_PROVIDER == 'spireon' ? [
      {
        name: i18n.t('leftMenu.DOTAudit'),
        value: null,
        url: pageUrls.fmcsaLogs
      }] : [])
  ];
  return (
    <Styled.Content>
      <div className="goBack">
        <SvgIcon
          value={'pageBackButton.svg'}
          onClick={() => history.goBack()}
        />
      </div>
      <Tab
        data={tabValues}
        className="logwrapper"
        currentTab={3}
        onChange={handleChange}
        vertical={verticalTab}
      />
    </Styled.Content>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    manageLogList: state.manageLogList,
    tableTotalCount: state.manageLogList.totalCount,
    filters: state.filters,
    drivers: state.drivers,
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchLogList: (data) => dispatch.manageLogList.fetchArchiveData(data),
    downloadLogPdf: (data) => dispatch.manageLogList.fetchPDF(data),
    filterSuccess: (data) => dispatch.manageLogList.filterSuccess(data),
    fetchDrivers: (data) => dispatch.drivers.getDrivers(data),
    setSelectedManageLogItem: (data) =>
      dispatch.manageLogList.setSelectedItem(data),
    handleLogBookStatus: (data) =>
      dispatch.logBookStatus.handleLogBookStatus(data),
    clearApiStatus: () => dispatch.asyncRequests.clearApiStatus(),
    getFilters: (data) => dispatch.filters.fetchData(data),
    viewReport: (data) => dispatch.viewReport.viewReportDocument(data)
  };
};

export default connect(mapState, mapDispatch)(Index);
Index.defaultTypes = {};
Index.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  manageLogList: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  filterSuccess: PropTypes.func.isRequired,
  fetchDrivers: PropTypes.func.isRequired,
  handleLogBookStatus: PropTypes.func.isRequired,
  clearApiStatus: PropTypes.func.isRequired,
  setSelectedManageLogItem: PropTypes.func.isRequired,
  fetchUdLogList: PropTypes.func.isRequired,
  udFilterSuccess: PropTypes.func.isRequired,
  fetchUnAssignedEntries: PropTypes.func.isRequired,
  unAssignedEntries: PropTypes.object.isRequired,
  saveUnAssignedEntries: PropTypes.func.isRequired,
  setUdAssignEntryList: PropTypes.func.isRequired,
  clearUdAssignEntryListById: PropTypes.func.isRequired
};
