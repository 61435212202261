import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
  ${({ theme }) => `
  .seatbeathStatus {
    padding: 5px 18px;
    width: 156px;
    display: inline-block;
    text-align: center;
    font-size: 13px !important;
    color: ${theme.colors.WHITE};
  }
  .noData {
    background: ${theme.colors.GREY_DATA};
  }
  .notBuckled {
    background: ${theme.colors.RED};
  }
  .buckled {
    background: ${theme.colors.BUCKLED_GREEN};
  }
`}
`;

const SearchWrapper = styled.div`
  ${({ theme }) => `
  .seatbeathStatus {
    padding: 5px 18px;
    width: 156px;
    display: inline-block;
    text-align: center;
    font-size: 13px !important;
    color: ${theme.colors.WHITE};
  }
  .noData {
    background: ${theme.colors.GREY_DATA};
  }
  .notBuckled {
    background: ${theme.colors.RED};
  }
  .buckled {
    background: ${theme.colors.BUCKLED_GREEN};
  }
  `}
`;

const MapWrapper = styled.div`
  padding: 2% 0;
  .quickFilter {
    display: none;
  }
`;
const TableWrapper = styled.div``;
const Styled = { Wrapper, SearchWrapper, MapWrapper, TableWrapper };

export default Styled;
