import { vehicleStatusRefreshApi } from '../../../config/apis';

const initialState = {
  vehicleStatus: [],
  vehicleFilteredList: []
};

export const vehicleStatusList = {
  state: { ...initialState },
  reducers: {
    listSuccess(state, payload) {
      return {
        ...state,
        vehicleStatus: [...payload?.vehicleStatus?.infoItems],
        vehicleFilteredList: [...payload?.vehicleStatus?.infoItems]
      };
    },
    listFailed() {
      return { ...initialState };
    },
    filterSuccess(state, list) {
      return { ...state, vehicleFilteredList: [...list] };
    },
  },
  effects: (dispatch) => ({
    async refreshData(payload, rootState) {
      try {
        const getApi = await import(
          `../../../config/apis/${window.$environment.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('vehicleStatusRefreshApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...apiObject,
              url: apiObject.url.replace(
                '{isVehicleStatusPageFlag}',
                payload?.data?.isVehicleStatusPageFlag
              )
            }
          },
          id: payload.apiId
        });

        response &&
          this.listSuccess({
            vehicleStatus: response
          });
      } catch (error) {
        this.listFailed();
      }
    }
  })
};
