import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
  ${({ theme }) => `
.react-datepicker__day--disabled {
    pointer-events: none;
}

`}`
const ShowWrapper = styled(Box)`
  ${({ theme }) => `
.showBtn{
  cursor: pointer;
  color: ${theme.colors.SECONDARY_MAIN};
  text-decoration: underline;
  font-size: 1rem;
}

`}`

const Styled = {
    Wrapper, ShowWrapper
  };
  export default Styled;