import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
padding: 0;
  position: relative;
  .logsInfoDisplay {
    background: ${theme.colors.LINK_COLOR};
    color: ${theme.colors.HEADER_TEXT};
    padding: 8px 30px;
    padding-left:2%;
  }
  table .MuiTableCell-alignCenter {
    text-align: left;
  }
  .tableWrapper {
    padding-top: 1%;
    padding: 0 2%;
    border-bottom: 2px solid #000;
    padding-bottom: 40px;
  }
  `}
`;

const Styled = { Wrapper };

export default Styled;
