import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)``;

const SearchWrapper = styled(Parent.SearchWrapper)``;

const MapWrapper = styled(Parent.MapWrapper)`
`;
const TableWrapper = styled(Parent.TableWrapper)`
  ${({ theme }) => `
.iconContainer  {
    justify-content: flex-end;
    .MuiAvatar-root {
        width: 20px;
        height: 21px;
    }
}
.MuiTable-root .MuiTableCell-root:last-child {
    text-align: center !important;
    .iconContainer  {
        justify-content: center;
    }
}
`}
`;
const Styled = { Wrapper, SearchWrapper, MapWrapper, TableWrapper };

export default Styled;
