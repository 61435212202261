import React, { useState, lazy } from 'react';
import PropTypes from 'prop-types';
import RenderComponent from './RenderComponent';
import { periodList } from '@/config/constants/keys';
import { getQueryStringKeys } from '@/utils/utils';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));

const Filters = ({
  hasApiStatus,
  callback,
  history,
  filterList,
  handleReset,
  vehicleList,
  values, setInitialValues
}) => {

  const [validationError, setValidationError] = useState('');
  const queryPeriod = getQueryStringKeys(history.location.search, 'period');

  return (
    <>
      <Form
        type="server"
        direction="row"
        initialValues={{ ...values }}
        className="CSAForms"
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {
          setValidationError('');
          const finalData = JSON.parse(JSON.stringify(fields));
          Object.keys(finalData).forEach((key) => {
            finalData[key] = finalData[key]?.value || null
          });
          filterList(finalData);
        }}
        handleOnCancel={(fields) => {
          handleReset();
          setInitialValues({
            period: periodList.filter(ele => ele.value == queryPeriod)[0],
            behavior: null,
            severity: null,
            vehicleId: null
          });
          setValidationError('');
        }}
        customProps={{
          history,
          callback,
          hasApiStatus,
          validationError,
          vehicleList,
          setInitialValues
        }}
      />
    </>
  );
};

Filters.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default Filters;
