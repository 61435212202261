import React, { useEffect, lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RenderComponent from '@/components/modules/DynamicRenderPage';

import { loadPageConfig } from '@/utils/utils';
const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.driverStatusHistory[0].name}`));
const Content = lazy(() => import(`@/${config.driverStatusHistory[1].name}`));
const TopActions =
  config.driverStatusHistory[0].children &&
  lazy(() => import(`@/${config.driverStatusHistory[0].children.name}`));

const DriverStatusHistory = (parentProps) => {
  const {
    fetchDriverStatusHistory,
    list,
    history,
    i18n,
    theme,
    getDocumentToken
  } = parentProps;
  const [pagination, setPagination] = useState(false);

  const commonParentProps = { history, i18n, theme };

  const query = new URLSearchParams(history.location.search);

  useEffect(() => {
    !list.length &&
      fetchDriverStatusHistory({
        apiId: 'driverStatusHistory',
        data: { id: query.get('id') }
      });
    getDocumentToken({ apiId: 'getDocToken' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <RenderComponent
        {...config.driverStatusHistory[0]}
        parentProps={{ ...parentProps, pagination, setPagination }}
        commonParentProps={commonParentProps}
        component={Title}
        childComponent={TopActions}
      />
      <RenderComponent
        {...config.driverStatusHistory[1]}
        parentProps={{ ...parentProps, pagination, setPagination }}
        commonParentProps={commonParentProps}
        component={Content}
        parentStyle
      />
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    logBook: state.logBook,
    list: state.driverStatusHistory
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchDriverStatusHistory: (data) =>
      dispatch.driverStatusHistory.fetchData(data),
    exportCsvData: (data) => dispatch.driverStatusHistory.exportCsvData(data),
    exportPdfData: (data) => dispatch.driverStatusHistory.exportPdfData(data),
    filterSuccess: (data) => dispatch.driverStatusHistory.filterSuccess(data),
    getDocumentToken: (data) => dispatch.logBook.documentToken(data)
  };
};

export default connect(mapState, mapDispatch)(DriverStatusHistory);
DriverStatusHistory.defaultTypes = {};
DriverStatusHistory.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  fetchDriverStatusHistory: PropTypes.func.isRequired,
  exportCsvData: PropTypes.func.isRequired,
  exportPdfData: PropTypes.func.isRequired
};
