import React from 'react';
import PropTypes from 'prop-types';
import Form from './Form';
import ParentStyled from './style';
import { loadStyle } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'components/modules/TrailerManagement');
const Component = (props) => {
  return (
    <Styled.Wrapper>
      <Form {...props} Styled={Styled} />
    </Styled.Wrapper>
  );
};

export default Component;

Component.propTypes = {
  history: PropTypes.object.isRequired
};
