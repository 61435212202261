import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import { getImagePath } from '../../../../../utils/utils';
const Wrapper = styled(Box)`
${({ theme }) => `
.welcomeText {
  padding-top: 3%;
  padding-right: 3%;
}
.listLabel {
  opacity: 0.69;
  color: #dbdbdb;
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 8px;
}
.itemValue {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
}
.headerSection {
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  padding-top: 14px;
  h2 {
    color: #ffffff;
    font-size: 25px;
    font-weight: 700;
  }
}
.index {
  margin-top: 19px;
  margin-left: 20px;
  background-color: rgba(255, 255, 255, .15);
  font-size: 16px;
  height: 39px;
  min-width: 65px;
  padding: 6px;
  border-radius: 3px;
  text-align: center;
}
.indexNo {
  font-size: 16px;
  color: #fff !important;
  font-family: "SF Pro Display - Semibold";
  font-weight: 600;
  padding-right: 4px;

}
.nextHalf {
  opacity: 0.76;
  color: #ffffff;
  font-size: 16px;
  font-weight: 300;
  span {
    padding-right: 4px;
  }
}
.infoWrapper {
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  padding-top: 40px;
}
.listItems {
  flex-direction: column;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
    width: 30%;
}
.vinWrapper {
  width: 20%;
  position: relative;
  .apiProgress,
  .validVinIcon {
    position: absolute;
    right: -35px;
    bottom: 14px;
    svg {
      width: 30px;
      height: 30px;
    }
  }
}
.infoFirst {
  padding-top: 30px;
}
.truckInfoitems {
  padding-bottom: 14px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
}
.gotoWrapper {
  position: absolute;
  bottom: 33px;
  right: 3%;
  z-index: 10;
  height: 45px;
  @media (max-width: ${theme.breakpoints.values.md - 1}px) {
   left: 0;
   }
  #goToDashboard {
    color: #ffffff;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    background: #1ea7db url(${getImagePath('icons/leftArrow.svg')}) no-repeat
      right center;
    background-position: 148px;
    padding: 12px 26px;
    padding-left: 15px;
    border-radius: 4px;
    border: 0;
    text-align: left;
    min-width: 160px;
    .MuiButton-label {
      padding-right: 12px;
    }
  }
}
  `}
`;

const Styled = { Wrapper };

export default Styled;