import styled from 'styled-components';

const Wrapper = styled.div`
  ${({ theme }) => `
  .contentWrap {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flexbox;
    display: flex; 
    max-width: 450px;
    margin: 0 auto;
    min-height: 100vh;
    position: relative;
    .patentText{
      font-size: 12px;
      color: #b6bec3;
      position: absolute;
      text-align: center !important;
      width: 100%;
      bottom: 3vh;
      display: flex;
      justify-content: center;
    }    .loginForm {
      width: 100%;
    }
  }
  div[type="error"] p {
    font-size: 14px;
  }
  .MuiInputBase-root,
  input {
    background-color: ${theme.colors.HEADER_COLOR} !important;
    color: ${theme.colors.INPUT_COLOR} !important;
    ${window?.$environment?.clutchNewUI && `border: 1px solid #f0f2f2 !important;`}
  }
  .registrationLink {
    color: ${theme.colors.WHITE};
  }
  .registrationheader: hover {
    text-decoration: none !important;
  }
  .MuiFormLabel-root {
    color: #dbdbdb;
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 10px;
  }
  .passwordField {
    position: relative;
    img {
      position: absolute;
      top: 16px;
      right: 10px;
      cursor: pointer;
    }
  }
  `}
`;
const ContentWrapper = styled.div`
  ${({ theme, newUI }) => `
  width: 100%;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex; 
  flex-direction: column;
  justify-content: center;
  .wrapBox {
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.02);
    ${!newUI && 
      `background-color: #fff;
      padding: 11% 14%;`
    }
    border-radius: 3px;
  }
  .ContentHeader {
    text-transform: initial;
    text-align: center;
    font-size: 25px !important;
    font-weight: 600;
    letter-spacing: 1px;
    padding-top: 24px !important;
    padding-bottom: 32px !important;
    margin-bottom: 0 !important;
    box-shadow: none;
    color: #000 !important;
  }
  
  `}
`;
const Styled = {
  Wrapper,
  ContentWrapper,
};
export default Styled;
