import { getFilters, saveFilters } from '../../../config/apis';

const initialState = {
  companyId: -1,
  departmentId: 0,
  filterId: 0,
  regionId: 0,
  subDepartmentId: 0,
  terminalId: 0,
  userId: 0,
  savedData: {}
};

export const filters = {
  state: { ...initialState },
  reducers: {
    getSuccess: (state, data) => {
      return { ...data };
    },
    getFailed: () => {
      return { ...initialState };
    },
    setSuccess: (state, data) => {
      return { ...state, data };
    },
    setFailed: (state) => {
      return { ...initialState };
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: getFilters
          },
          id: payload.apiId
        });
        if (response) {
          this.getSuccess({ ...response });
        }
      } catch (error) {
        this.getFailed();
      }
    },

    async saveData(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: saveFilters,
            data: payload.data.data
          },
          id: payload.apiId,
          callback: payload.callback
        });

        if (response) {
          this.setSuccess({ savedData: response });
          payload.callback(
            'success',
            payload.data?.isReset
              ? 'Filter reset successfully'
              : 'Filter saved successfully'
          );
        }
      } catch (error) {
        this.getFailed();
      }
    }
  })
};
