import styled from 'styled-components';

const Wrapper = styled.div`
  ${({ theme }) => `
  position: relative;
  flex-direction: column;
  .loginformwrap {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    width: 100%;
  }
  .registrationLink {
    color: ${theme.colors.WHITE};
  }
  .registrationheader: hover {
    text-decoration: none !important;
  }
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  .MuiTypography-h1 {
    align-items: center;
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    padding: 0px;
  }
  `}
`;
const LoginWrapper = styled.div`
  ${({ theme }) => `
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  flex-direction: column;
  flex: 0 0 33%;
  margin-left: 5%;
  margin-top: 42px;
  .formLogin {
    flex: 0 0 85%;
    padding-bottom: 20px;
    @media only screen and (min-width: ${theme.breakpoints.values.lg}px) and (max-height: ${theme.breakpoints.values.xxl}px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      flex: 0 0 55%;
    }
  }
  .MuiTypography-colorPrimary {
    flex: 0 0 2% !important;
  }
  .buttonWrapper {
    margin-top: 1%;
    width: 50% !important;
    margin-left: 37% !important;
  }
  div[type='error'] p {
    font-size: 17px;
  }
  @media (max-width: ${theme.breakpoints.values.sm}px) {
    flex: 0 0 89% !important;
    margin-left: 20px;
  }

  @media (min-width: ${theme.breakpoints.values.md}px) and (max-width: ${theme.breakpoints.values.lg}px) {
    flex: 0 0 75% !important;
  }
  @media (max-width: ${theme.breakpoints.values.lg}px) {
    margin-top: 34px;
  }
  @media (max-width: ${theme.breakpoints.values.sm}px) {
    margin-top: 24px;
  }
  @media (max-width: ${theme.breakpoints.values.xxl}px) and (min-width: ${theme.breakpoints.values.xl}px) {
    margin-top: 345px;
  }
  @media all and (orientation: landscape) {
    flex: 0 0 75%;
  }

  @media (min-width: ${theme.breakpoints.values.sm}px) and (max-width: ${theme.breakpoints.values.md}px) and (orientation: landscape) {
    margin-top: 20px;
    flex: 0 0 50%;
  }
  @media (min-width: ${theme.breakpoints.values.lg}px) and (orientation: landscape) {
    margin-top: 90px;
    flex: 0 0 39.9%;
  }
  /* Ipad pro */
  @media only screen and (min-width: ${theme.breakpoints.values.lg}px) and (max-height: ${theme.breakpoints.values.xxl}px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    margin-top: 335px;
  }
  @media (max-width: ${theme.breakpoints.values.md}px) {
    flex: 0 0 75%;
  }
  .loginForm {
    flex: 0 0 85%;
    @media only screen and (min-width: ${theme.breakpoints.values.lg}px) and (max-height: ${theme.breakpoints.values.xxl}px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      flex: 0 0 55%;
    }
  }
  .forgotPassword {
    .MuiButtonBase-root {
      border: none;
    }
    .MuiBox-root:first-child {
      p {
        flex: 0 0 24%;
      }
      p + div {
        flex: 0 0 71%;
      }
    }
    form {
      margin-top: 1%;
      margin-left: 3%;
      .MuiBox-root:last-child {
        justify-content: center;
      }
      input,
      .MuiInputBase-root {
        background-color: ${theme.colors.INPUT_BACKGROUND} !important;
      }
      button {
        margin-top: 13px;
        padding: 2px 44px;
      }
    }
    .MuiButton-root:hover {
      box-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);
      outline: none;
      cursor: pointer;
    }
  }
  `}
`;
const Styled = {
  Wrapper,
  LoginWrapper
};
export default Styled;
