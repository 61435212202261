import {
    getStateListData,
    fuelAddEditApi
  } from '../../../config/apis';

const initialState = {
  list: [],
  filteredList: [],
  stateListData: null
};

export const fuelConsumption = {
  state: { ...initialState },
  reducers: {
    listSuccess(state, payload) {
      return {
        ...state,
        list: [...payload],
        filteredList: [...payload]
      };
    },
    stateDataSuccess(state, payload) {
      return {
        ...state,
        stateListData: payload
      };
    },
    listFailed() {
      return { ...initialState };
    },
    filterSuccess(state, list) {
      return { ...state, filteredList: [...list] };
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload, rootState) {
      try {
        const getApi = await import(
          `../../../config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('getFuelConsumptionApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject
          },
          id: payload.apiId
        });
        response &&
          response.map((item) => {
            item['vehicleName'] = item?.vehicle?.name;
            item['milesPerGallon'] =
              item['milesPerGallon'] == 0 || item['milesPerGallon'] == null
                ? '0.00'
                : item['milesPerGallon']?.toFixed(2);
          });
        response && this.listSuccess(response);
      } catch (error) {
        this.listFailed();
      }
    },

    async fetchStateListData(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
               url: payload?.data?.idPub ? `${getStateListData?.url}?idPub=${payload?.data?.idPub}` : `${getStateListData?.url}`,
               type: getStateListData.type,
            },
          },
          id: payload.apiId,
        });
        response && this.stateDataSuccess(response);
      } catch (error) {
        // this.listFailed();
      }
    },

    async saveData(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
               url:  `${fuelAddEditApi?.url}?fuelDto=${encodeURIComponent(JSON.stringify(payload?.param)) || ''}`,
               type: fuelAddEditApi.type,
               isFormData: fuelAddEditApi.isFormData
            },
            data: payload?.data?.data
          },
          id: payload.apiId,
          callback: payload.callback
        });
        response && this.fetchData({ apiId: 'fetchFuelConsumptionReport'});
      } catch (error) {
        // this.saveFailed();
      }
    },
  })
};
