import styled from 'styled-components';
import Parent from './style';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Parent.Wrapper)`
    ${({ theme }) => `
    form {
        padding: 15px;
        button.MuiButtonBase-root {
        margin-top: 21px;
            .MuiButton-label{
                padding: 0;
            }
        }
    }
    div[type="info"] p {
        color: #FFA500;
        font-family: ${theme.palette.titleFont};
        padding-left: 15px;
    }
    div[type="error"] p {
        font-size: 14px;
        font-family: tahoma;
    }
    .driverId .driverTextbox {
        padding-top: 1px;
    }
    `}
`;
const Content = styled(Box)`
${({ theme }) => `
    .MuiPaper-root{
      padding-bottom: 0;
    }
    .MuiTabs-root{
        padding-left: 0;
    }
    .MuiTab-root {
        min-width: 100px;
        min-height: 23px;
    }
    .MuiButtonBase-root.MuiTab-root{
      padding: 0;
      margin-left: 20px;
      line-height: 1;
      margin-right: 1%;
    }
    .MuiTabs-fixed{
      padding-top: 15px;
      padding-bottom: 10px;
    }
    header {
        border-bottom: 2px solid ${theme.colors.BLACK};
        box-shadow: none;
    }
`}
`;
const Styled = { Wrapper, Content};

export default Styled;
