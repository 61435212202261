import styled from 'styled-components';
import Parent from '../Base/clientStyle';
const ButtonWrapper = styled(Parent.ButtonWrapper)``;
const FieldsWrapper = styled(Parent.FieldsWrapper)`

`;
const FormWrapper = styled(Parent.FormWrapper)`
.react-datepicker-wrapper{
  min-height: 36px !important;
  height: 36px !important;
  input {
    border:0 !important;
    border-bottom: 1px solid rgb(211, 215, 219) !important;
    font-size: 16px !important;
    outline: none;
    height: 36px !important;
  }
  input::placeholder {
    font-size: 14px;
  }
}
.MuiButtonBase-root {
  padding: 0 !important 
}
.react-datepicker {
  .react-datepicker__navigation--next {
    border-left-color: #000;
  }
  .react-datepicker__navigation--previous{
    border-right-color: #000;
  }
  button {
  height: 10px !important;
  background: none !important;
  border-radius: 0;
  border: 0.45rem solid transparent;
  padding: 0 !important;
  }
}
.react-datepicker__input-container {
  height: 36px !important;
}
.react-datepicker__close-icon{
  top: 3px;
  right: 20px;
  color: #767676;
  &::after {
    background-color: #767676;
  }
}
.react-datepicker__navigation--next{
  border-left-color: #000;
}
.react-datepicker__day{
  line-height: 1.6em;
}
.react-datepicker__day--disabled {
  pointer-events: none;
}
li.react-datepicker__time-list-item {
  font: 12px/1.428 Arial, "Geneva CY", sans-serif;
}
.react-datepicker__month-select,
.react-datepicker__year-select,
.react-datepicker__current-month {
  background: #cccccc !important;
}
.react-datepicker__navigation--previous {
  border-right-color: #000;
}
.react-datepicker__navigation--next--disabled,
.react-datepicker__navigation--next--disabled:hover {
  border-left-color: #222;
}
.react-datepicker__day:hover {
  background: #e6e6e6 !important;
  border: 1px solid #d3d3d3 !important;
  color: #555555;
  opacity: 0.7;
}
.react-datepicker__day {
  background: #e6e6e6 !important;
  border: 1px solid #d3d3d3 !important;
  color: #555555;
}

.react-datepicker__day--today {
  border: 1px solid #fcefa1!important;
  background: #fbf9ee!important;
  color: #363636!important;
  font-weight: normal;
}
`;
const Styled = {
  ButtonWrapper,
  FieldsWrapper,
  FormWrapper
};

export default Styled;
