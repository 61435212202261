import {
  getUnAssignedEntriesApi,
  setUnAssignedEntriesApi
} from '../../../../config/apis';

const initialState = [];

export const unAssignedEntries = {
  state: [...initialState],
  reducers: {
    listSuccess(state, payload) {
      return { ...state, [payload.id]: { ...payload.response }, day: payload.day };
    },
    listFailed() {
      return [...initialState];
    },
    setUdAssignEntryList(state, payload) {
      let source = [...state[payload.id].dailyLogEntries];
      payload.list.map((parentItem) => {
        source = source.map((item) =>
          parentItem.key === item.id ? parentItem : item
        );
      });
      return {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          dailyLogEntries: [...source]
        }
      };
    },
    clearUdAssignEntryListById(state, payload) {
      let data = { ...state };
      delete data[payload.logId];
      return {
        ...data
      };
    },
    clearAll(state, payload) {
      return [...initialState];
    }
  },
  effects: (dispatch) => ({
    async fetchUnAssignedEntries(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...getUnAssignedEntriesApi,
              url: getUnAssignedEntriesApi.url.replace('{id}', payload.data.id)
            }
          },
          id: payload.apiId
        });
        if (response) {
          this.listSuccess({
            response: {
              dailyLogEntries: payload.data.tab
                ? response.dailyLogEntries.filter(
                  (item) =>
                    item.status === 'DRIVING' &&
                    response.companyDrivers[item.driverId] ===
                      'Unidentified Driver'
                )
                : response.dailyLogEntries.filter(
                    (item) =>
                      item.status === 'DRIVING' &&
                      response.companyDrivers[item.driverId] !==
                        'Unidentified Driver'
                  ),
              companyDrivers: response.companyDrivers
            },
            id: payload.data.id,
            day: response.day
          });
        }
      } catch (error) {
        this.listFailed();
      }
    },
    async saveData(payload, rootState) {
      try {
        const source = rootState.unAssignedEntries[
          payload.data.id
        ].dailyLogEntries.filter((item) => item.status === 'DRIVING');
        const formattedSource = source.map((item) => {
          const { key, ...filteredItem } = item;
          filteredItem.driverFullName =
            filteredItem?.driverId?.label || filteredItem.driverFullName;
          filteredItem.driverId =
            typeof filteredItem.driverId === 'object'
              ? filteredItem.driverId?.value
              : filteredItem.driverId;
          filteredItem.coDriverId =
            filteredItem.coDriverId?.value || filteredItem.coDriverId;
          filteredItem.unIdentifiedLogStatus =
            filteredItem.driverFullName === 'Unidentified Driver'
              ? 'ASSIGNED'
              : 'UNASSIGNED';
          return filteredItem;
        });
        const finalPayload = {
          dailyLogEntries: [...formattedSource],
          idPub: payload.data.id,
          day: rootState.unAssignedEntries.day
        };
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...setUnAssignedEntriesApi,
              url: setUnAssignedEntriesApi.url.replace('{id}', payload.data.id)
            },
            data: { ...finalPayload },
            callback: payload.callback
          },
          id: payload.apiId
        });

        // this.listSuccess({
        //   response: {
        //     ...rootState.unAssignedEntries[payload.data.id],
        //     isViewOnly: true
        //   },
        //   id: payload.data.id
        // });
        response &&
          dispatch.udLogList
            .fetchData({
              apiId: 'udLogList',
              data: { showUnidentifiedDriver: payload.data.tab }
            })
            // .then(
            //   dispatch.unAssignedEntries.fetchUnAssignedEntries({
            //     apiId: 'fetchUnAssignedEntries',
            //     data: { id: payload.data.id }
            //   })
            // );
        // response &&
        //   dispatch.unAssignedEntries.fetchUnAssignedEntries({
        //     apiId: 'fetchUnAssignedEntries',
        //     data: { id: payload.data.id }
        //   });
        if(response.status == '200') payload.callback('success', response.value);
        else payload.callback('error', response.value);
      } catch (error) {
        this.listFailed();
      }
    }
  })
};
