const routeConfigs = [
  {
    url: '/auth/login',
    component: 'Login',
    module: 'login',
    title: 'Login',
    layout: 'login'
  },
  {
    url: '/privacy-policy',
    component: 'PrivacyPolicyScreen',
    layout: 'plain',
    module: 'privacyPolicyScreen',
    title: 'Privacy Policy'
  },
  {
    url: '/auth/recovery',
    component: 'ForgotPassword',
    module: 'recovery',
    title: 'Reset Password',
    layout: 'login'
  },
  {
    url: '/user/resetPassword',
    component: 'ResetPassword',
    layout: 'authenticatedStyle',
    module: 'resetPassword',
    title: 'Reset Password'
  },
  {
    url: '/user/mfa',
    component: 'MFA',
    layout: 'login',
    module: 'MFAPage',
    title: 'MFA'
  }
];

export default routeConfigs;
