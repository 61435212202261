import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import { getDateLocale } from '@/utils/utils';
import useTheme from '@material-ui/core/styles/useTheme';
import { getCookie } from '@/services/cookie';
import { COOKIE_AUTH_DETAILS } from '@/config/constants/keys';
import { UI_DATE_FORMAT_WITH_TIME_12_V } from '@/config/constants/static';
const layout = window?.$environment?.CURRENT_LAYOUT;
const Select = lazy(() => import(`@/components/UI/Select/${layout}`));
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const RenderComponent = ({
  values,
  handleEdit,
  errors,
  isNotValid,
  validateForm,
  customProps,
  submitAction
}) => {
  const { i18n } = customProps;
  const theme = useTheme();
  const token = getCookie(COOKIE_AUTH_DETAILS);

  const addDayClass = (date) => {
    let radiusClasses = {
      'GREATER_THAN_67_PERCENTAGE': 'red_marker',
      'BETWEEN_33_TO_67_PERCENTAGE': 'yellow_marker',
      'LESS_THAN_33_PERCENTAGE': 'green_marker'
    }
    let radiusData = customProps.vehicles.fleetVehicleRadiusList?.filter(ele => ele.day == moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD'))
    if (radiusData.length) {
      return radiusClasses[radiusData[0].severity]
    }
    return ''
  }
  
  return (
    <customProps.Styled.FormWrapper>
      {customProps?.hasApiStatus?.errorMessage && (
        <Message
          type="error"
          message={i18n.t(customProps.hasApiStatus.errorMessage)}
        />
      )}
      <div className="header">{i18n.t('vehicles.trackVehicle')}</div>
      <customProps.Styled.OptionsWrapper className="formWrap">
        <div className="dropdownWrapper">
          {customProps.insuracePortalUserRole &&
            (<FormStyle.default.FieldsWrapper
              width="100%"
              className="vehicleTrack"
            >
              <Label variant="body1">{i18n.t('policyHolder')}</Label>
              <FormStyle.default.TextWrapper>
                <Field
                  name="companyId"
                  onChange={(event) => {
                    if (customProps.insuracePortalUserRole) {
                      const filteredList = customProps?.vehicles?.vehicleList.filter(vehicle => vehicle.companyId === event?.value)
                      customProps.driverArray(filteredList)
                    }
                    handleEdit(event?.value, { field: 'companyId' })
                  }}
                  as={Select}
                  placeholder={i18n.t('common.pleaseSelect')}
                  value={values?.companyId?.value}
                  type="select"
                  suggestions={customProps?.fleetCompanyList}
                  isClearable
                />
                {errors['companyId'] && !isNotValid && (
                  <Message type="error" message={errors['companyId']} />
                )}
              </FormStyle.default.TextWrapper>
            </FormStyle.default.FieldsWrapper>)
          }
          <FormStyle.default.FieldsWrapper
            width="100%"
            className="vehicleTrack"
          >
            <Label variant="body1">{i18n.t('vehicles.unitId')}</Label>
            <FormStyle.default.TextWrapper>
              <Field
                name="id"
                onChange={(event) => {
                  if (window?.$environment?.SERVICE_PROVIDER === 'riskTheory') {
                    customProps.fetchVehicleRadius({
                      apiId: 'fetchVehicleRadius',
                      data: {
                        vehicleId: event?.value || '',
                        companyId: customProps.insuracePortalUserRole ? values?.companyId : token?.companyId
                      }
                    })
                  }
                  handleEdit(event?.value, { field: 'id' })
                }}
                as={Select}
                placeholder={i18n.t('common.pleaseSelect')}
                value={values?.id?.value}
                type="select"
                suggestions={customProps?.driverIdSuggestion}
                isClearable
              />
              {errors['id'] && !isNotValid && (
                <Message type="error" message={errors['id']} />
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper
            width="100%"
            className="vehicleTrack"
          >
            <Label variant="body1">{i18n.t('vehicles.dateTimeFrom')}</Label>
            <FormStyle.default.TextWrapper>
              <Field
                name="dateTimeFrom"
                onChange={(event) => {
                  handleEdit(event, {
                    field: 'dateTimeFrom',
                    type: 'date',
                    isTimeNeeded: true
                  });
                }}
                as={ReactDatePicker}
                dayClassName={addDayClass}
                locale={getDateLocale()}
                placeholder={i18n.t('common.datePicker')}
                selected={values.dateTimeFrom}
                showPopperArrow={false}
                showTimeSelect={true}
                maxDate={new Date()}
                showDisabledMonthNavigation
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                dateFormat={UI_DATE_FORMAT_WITH_TIME_12_V}
              />
              {errors['dateTimeFrom'] && !isNotValid && (
                <Message type="error" message={errors['dateTimeFrom']} />
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>

          <FormStyle.default.FieldsWrapper
            width="100%"
            className="vehicleTrack"
          >
            <Label variant="body1">{i18n.t('vehicles.dateTimeTo')}</Label>
            <FormStyle.default.TextWrapper>
              <Field
                name="dateTimeTo"
                onChange={(event) => {
                  handleEdit(event, {
                    field: 'dateTimeTo',
                    type: 'date',
                    isTimeNeeded: true
                  });
                }}
                as={ReactDatePicker}
                dayClassName={addDayClass}
                locale={getDateLocale()}
                placeholder={i18n.t('common.datePicker')}
                selected={values.dateTimeTo}
                showPopperArrow={false}
                showTimeSelect={true}
                maxDate={new Date()}
                showDisabledMonthNavigation
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                minDate={new Date(values?.dateTimeFrom)}
                dateFormat={UI_DATE_FORMAT_WITH_TIME_12_V}
              />
              {errors['dateTimeTo'] && !isNotValid && (
                <Message type="error" message={errors['dateTimeTo']} />
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
        </div>
        <div className="buttonWrapper">
          <FormStyle.default.ButtonsWrapper>
            <Button
              type="submit"
              label={i18n.t('common.view')}
              onClick={() => {
                const validateResponse = validateForm();
                validateResponse.then((data) => submitAction(data));
              }}
            />
          </FormStyle.default.ButtonsWrapper>
        </div>
      </customProps.Styled.OptionsWrapper>
    </customProps.Styled.FormWrapper>
  );
};

export default RenderComponent;

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  customProps: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};
