import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
${({theme})=>`
  display: flex;
  flex-direction: column;
  form {
    flex-direction: column;
    .feedbackWrapper {
        width: 70%;
        flex-direction: column;
        margin-bottom: 0;
        textarea {
          height: 300px !important;
          font-size: 20px;
          background-color: ${theme.colors.INPUT_BACKGROUND}
        }
    }
    .MuiFormLabel-root {
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        padding-bottom: 18px;
      }
    }
    .vertical-button {
        justify-content: flex-start;
        button {
            min-width: 117px !important;
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
              /* IE10+ CSS styles go here */
              margin-top: 330px;
            }
            .MuiButton-label {
                padding: 0;
            }
        }
    }
  }
`}
`;

const Styled = { Wrapper };

export default Styled;
