import React, { lazy } from 'react';

import Box from '@material-ui/core/Box';

import WithData from '..';
import Content from '../Component';

import Styled from '../style';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Title = lazy(() => import(`../../../../components/Title/${layout}`));

const Index = ({ callback }) => {
  return (
    <WithData callback={callback}>
      <Component />
    </WithData>
  );
};

export default Index;

const Component = ({
  history,
  hasFetchApiStatus,
  callback,
  match,
  i18n,
  theme,
  ...rest
}) => {
  const contentProps = {
    Styled,
    hasFetchApiStatus,
    i18n,
    callback,
    ...rest
  };
  return (
    <>
      <Title header={i18n.t(i18n.t('refillsHistory.pageHeading'))} />
      <Box style={{ ...theme.palette.contentStyle }}>
        <Content {...contentProps} />
      </Box>
    </>
  );
};
