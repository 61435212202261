import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { COOKIE_AUTH_DETAILS, pageUrls, ROLES } from '../../../config/constants/keys';
import { getCookie } from '../../../services/cookie';
import { hasRestrictedRole, isdvirOnly, getQueryStringKeys } from '@/utils/utils';

const InitialLoad = ({ history, clearRedux, fetchLogin }) => {
  useEffect(() => {
    const token = getCookie(COOKIE_AUTH_DETAILS);
    const isDVIROnlyFlagEnabled = isdvirOnly()
    const redirectionURL = getQueryStringKeys(
      history.location?.search,
      'redirect'
    );
    if (!token || (redirectionURL && redirectionURL.includes(window.$environment?.FLEETOPS_URL))) {
      history.push(pageUrls.login);
    } else if (hasRestrictedRole(['OWNER'])) {
      if (window.$environment.SERVICE_PROVIDER === 'clutch') {
        if(isDVIROnlyFlagEnabled){
          if(hasRestrictedRole([ROLES.SERVICE_MANAGER])){
            history.push(pageUrls.unassignedInspections);
          }
          else{
            history.push(pageUrls.pendingMechanicInspections);
          }
        }
        else if (token && token.onboardCompleted) {
          history.push(pageUrls.dashboard);
        } else if (token && !token.onboardCompleted) {
          history.push(pageUrls.welcome);
        }
      }
      else if(isDVIROnlyFlagEnabled){
        if(hasRestrictedRole([ROLES.SERVICE_MANAGER])){
          history.push(pageUrls.unassignedInspections);
        }
        else{
          history.push(pageUrls.pendingMechanicInspections);
        }
      } else {
        history.push(pageUrls.dashboard);
      }
    } else if (isDVIROnlyFlagEnabled) {
      if(hasRestrictedRole([ROLES.SERVICE_MANAGER])){
        history.push(pageUrls.unassignedInspections);
      }
      else{
        history.push(pageUrls.pendingMechanicInspections);
      }
    } else if (hasRestrictedRole(['FLMANAGER'])) {
      history.push(pageUrls.dashboard);
    } else if (hasRestrictedRole(['MECHANIC'])) {
      history.push(pageUrls.workOrders);
    } else if (hasRestrictedRole(['DRIVER'])) {
      if(window.$environment.SERVICE_PROVIDER === 'spireon') history.push(pageUrls.profile);
      else history.push(pageUrls.logsList)
    } else {
      history.push(pageUrls.dashboard);
    }
  }, []);
  return <> </>;
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    login: state.login
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchLogin: (data) => dispatch.login.fetchData(data),
    clearRedux: () => dispatch({ type: 'RESET_APP' })
  };
};

export default connect(mapState, mapDispatch)(InitialLoad);

InitialLoad.defaultTypes = {};
InitialLoad.propTypes = {};
