import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
padding:0;
padding-bottom: 2%;
.buttonStyle {
    margin-top: 0 !important;
}
.test-table {
    border: 0;
    tbody tr:nth-child(2n + 1) {
        background: #efefef;
    }
    thead td {
        font-size: 18px;
    }
    tr {
        border-bottom:0;
        td {
            font-size: 20px;
        }
    }
    .title {
        color: ${theme.colors.PRIMARY_CONTRAST};
        font-weight: 600;
    }
}
`}
`;
const TableWrapper = styled(Parent.TableWrapper)`
padding: 0 2%;
`;
const Styled = { Wrapper, TableWrapper };

export default Styled;
