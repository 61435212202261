import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const TopWrapper = styled(Box)`
  ${({ theme }) => `

  display: flex;
  @media (max-width: ${theme.breakpoints.values.smd}px) {
    flex-direction: column;
  }
  `}
`;

const SearchWrapper = styled.div`
  ${({ theme }) => `

  flex: 0 0 40%;
  @media (max-width: ${theme.breakpoints.values.mlg}px) {
    flex: 0 0 38%;
  }
  #clearSearch {
    display: none;
  }
  `}
`;

const TableWrapper = styled(Box)`
  padding-top: 2.5%;
  .MuiTableHead-root .MuiTableRow-head .MuiTableCell-root:nth-child(4){
    text-align: left !important;
  }
  .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:nth-child(4) {
    display: none;
  }
  .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:nth-child(5) {
    width: 32px;
    .iconContainer.MuiGrid-container{
      justify-content: flex-end;
    }
  }
`;

const LabelWrap = styled(Box)`
  p {
    font-size: 21px;
    margin-bottom: 20px;
  }
`;
const Styled = {
  Wrapper,
  SearchWrapper,
  TopWrapper,
  TableWrapper,
  LabelWrap
};
export default Styled;
