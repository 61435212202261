import { useTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import React, { lazy, useEffect, useState } from 'react';

const layout = window?.$environment?.CURRENT_LAYOUT;
const PieChart = lazy(() => import(`@/components/UI/Graph/PieChart/${layout}`));

const PieGraph = ({
    data
}) => {

    const theme = useTheme();
    const [chartData, setChartData] = useState([])
    const [chartFlg, setChartFlag] = useState(true)

    useEffect(() => {
        if (data) {
            setChartFlag(false)
            setChartData(data)
            setTimeout(() => {
                setChartFlag(true)
            })
        }
    }, [data])

    const checkData = () => {
        return chartData[0].accelerationScore || chartData[0].accidentScore || chartData[0].brakingScore || chartData[0].radiusScore || chartData[0].speedingScore || chartData[0].turningScore
    }


    return (
        <div className='graphContainer'>
            {chartData.length && chartFlg ? checkData() ? <PieChart
                width={'470px'}
                height={'230px'}
                data={[{
                    data: [chartData[0].accelerationScore, chartData[0].accidentScore, chartData[0].brakingScore, chartData[0].radiusScore, chartData[0].turningScore],
                    backgroundColor: [fade(theme.colors.CHART_BLUE, 0.5), fade(theme.colors.CHART_PURPLE, 0.5), fade(theme.colors.CHART_GREEN, 0.5), fade(theme.colors.CHART_PINK, 0.5), fade(theme.colors.CHART_LIGHT_BLUE, 0.5)],
                    borderColor: [theme.colors.CHART_BLUE, theme.colors.CHART_PURPLE, theme.colors.CHART_GREEN, theme.colors.CHART_PINK, theme.colors.CHART_LIGHT_BLUE],
                    borderWidth: 1,
                    lineTension: 0
                }]}
                labels={['Sum of Acceleration Score', 'Sum of Accident Score', 'Sum of Braking Score', 'Sum of Radius Score', 'Sum of Turning Score']}
                areagraph
                title={''}
                theme={theme}
                noHyperLink
            ></PieChart>: <div className='noData'>
                No data available
            </div> : null}

        </div>
    )
}

export default PieGraph