import React, { lazy, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { ROLES } from '@/config/constants/keys'
import {
  hasValue,
  hasEnabledFeature,
  hasRestrictedRole,
  findAndDeleteParentInJson,
  getAuthDetails
} from '@/utils/utils';

import Accordion from '@/components/Accordion';
import DutyStatusRenderComponent from './DutyStatusRenderComponent';
import DriverPerformanceRenderComponent from './DriverPerformanceRenderComponent';
import EditedDataRenderComponent from './EditedDataRenderComponent';
import DrivingEventRenderComponent from './DrivingEventRenderComponent';
import VehicleReportRenderComponent from './VehicleReportRenderComponent';
import MalFunctionReportRenderComponent from './MalFunctionReportRenderComponent';
import DateReportFormRenderComponent from './DateReportFormRenderComponent';
import DailyHOSDriverViolationsFormRenderComponent from "./ExtraReports/DailyHOSDriverViolations";
import CompanyFormRenderComponent from "./ExtraReports/CompanyFormRenderComponent";
import DailyUnsignedLogReportRenderComponent from "./ExtraReports/DailyUnsignedLogReport";
import DailyFailedInspectionReportFormRenderComponent from "./ExtraReports/DailyFailedinspectionreport";
import DailyUnidentifiedDrivingReportFormRenderComponent from "./ExtraReports/DailyUnidentifieddrivingreport";
import MonthlyHOSDriverViolationsReportFormRenderComponent from "./ExtraReports/MonthlyHOSDriverViolationsReport";
import MonthlyUnsignedLogReportFormRenderComponent from "./ExtraReports/MonthlyUnsignedLogReport";
import DutyStatusRenderComponentSpireon from './ExtraReports/DutyStatusRenderComponentSpireon';

// import Styled from './style';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));

const Component = ({
  apiStatus,
  dutyStatusReport,
  driverPerformanceReport,
  editedDataReport,
  drivingEventReport,
  eldDisconnectReport,
  odometerJumpReport,
  malfunctionReport,
  billOfLadingReport,
  unassignedDrivingReport,
  violationReport,
  downloadReport,
  hosViolationsReport,
  rejectedEditsReport,
  dailyHosViolationReport,
  dailyUnsignedLogReport,
  dailyFailedInspectionReport,
  dailyUnidentifiedDrivingReport,
  drivers,
  companies,
  regions,
  fetchTerminals,
  fetchDepartments,
  fetchLocations,
  fetchSubDepartments,
  callback,
  history,
  users,
  vehicles,
  Styled,
  expandedNotNeeded
}) => {
  const [dutyStatusInitialValues, setDutyStatusInitialValues] = useState({
    startDate: '',
    endDate: '',
    regionId: '',
    terminalId: '',
    departmentId: '',
    subDepartmentId: ''
  });
  const { i18n } = useTranslation();
  const DutyStatusReportForm = () => {
    let apiId = 'dutyStatusReportForm';
    let hasDutyStatusApiStatus = hasValue(apiStatus.status, 'api', apiId);
    return (
      <>
        <Form
          type="server"
          direction="column"
          initialValues={{ ...dutyStatusInitialValues }}
          RenderComponent={DutyStatusRenderComponent}
          handleOnSubmit={(fields) => {
            let data = {
              ...fields,
              departmentId: fields.departmentId?.value || '',
              regionId: fields.regionId?.value || '',
              subDepartmentId: fields.subDepartmentId?.value || '',
              terminalId: fields.terminalId?.value || ''
            }
            dutyStatusReport({ data, apiId, callback: callback });
          }}
          customProps={{
            hasDutyStatusApiStatus,
            history,
            Styled,
            i18n,
            regions,
            fetchTerminals,
            fetchDepartments,
            fetchSubDepartments,
            setDutyStatusInitialValues
          }}
          validationSchema={Yup.object().shape({
            startDate: Yup.string().required(
              i18n.t('common.fieldRequiredMessage')
            ),
            endDate: Yup.string().required(
              i18n.t('common.fieldRequiredMessage')
            )
          })}
        />
      </>
    );
  };
  const DutyStatusReportFormSpireon = () => {
    let apiId = 'dutyStatusReportForm';
    let hasDutyStatusApiStatus = hasValue(apiStatus.status, 'api', apiId);
    return (
      <>
        <Form
          type="server"
          direction="column"
          initialValues={{ ...dutyStatusInitialValues }}
          RenderComponent={DutyStatusRenderComponentSpireon}
          handleOnSubmit={(fields) => {
            dutyStatusReport({ data: fields, apiId, callback: callback });
          }}
          customProps={{
            hasDutyStatusApiStatus,
            history,
            Styled,
            i18n,
            regions,
            fetchTerminals,
            fetchDepartments,
            fetchSubDepartments,
            setDutyStatusInitialValues
          }}
          validationSchema={Yup.object().shape({
            startDate: Yup.string().required(
              i18n.t('common.fieldRequiredMessage')
            ),
            endDate: Yup.string().required(
              i18n.t('common.fieldRequiredMessage')
            )
          })}
        />
      </>
    );
  };


  const getSchedulingFields = (fields) => {
    return hasEnabledFeature('downloadCenterEnabled') ? {
      recurrenceType: fields.recurrenceType,
      ...(fields.recurrenceType != 'NONE' ? {
        ...(fields.recurrenceType == 'RECURRING' ? { recurrenceInterval: fields.recurrenceInterval } : {}),
        scheduleEndDate: fields.scheduleEndDate ? new Date(fields.scheduleEndDate).getTime() : null
      } : {})
    } : {}
  }

  const getValidationSchema = () => {
    return hasEnabledFeature('downloadCenterEnabled') ? {
      startDate: Yup.string().when('recurrenceType', {
        is: (value) => value === 'RECURRING',
        then: Yup.string(),
        otherwise: Yup.string().required(i18n.t('common.fieldRequiredMessage')),
      }),
      endDate: Yup.string().when('recurrenceType', {
        is: (value) => value === 'RECURRING',
        then: Yup.string(),
        otherwise: Yup.string().required(i18n.t('common.fieldRequiredMessage')),
      })
    } : {
      startDate: Yup.string().required(
        i18n.t('common.fieldRequiredMessage')
      ),
      endDate: Yup.string().required(
        i18n.t('common.fieldRequiredMessage')
      )
    }
  }


  const DriverWithDateReportForm = ({ submitApi }) => {
    let apiId = 'driverPerformanceReport';
    return (
      <>
        <Form
          type="server"
          direction="column"
          initialValues={{
            startDate: '',
            endDate: '',
            driverIds: [],
            recurrenceType: 'NONE',
            recurrenceInterval: 'MONTHLY',
            scheduleEndDate: ''
          }}
          RenderComponent={DriverPerformanceRenderComponent}
          handleOnSubmit={(fields) => {
            let data = {
              endDate: fields.endDate,
              startDate: fields.startDate,
              driverIds: [],
              ...(getSchedulingFields(fields))
            };
            fields.driverIds.forEach((item) => {
              data.driverIds.push(item.value);
            });
            submitApi({ data: data, apiId, callback: callback });
          }}
          customProps={{
            history,
            Styled,
            i18n,
            drivers
          }}
          validationSchema={Yup.object().shape({
            ...(getValidationSchema())
          })}
        />
      </>
    );
  };
  const EditedDataReportForm = () => {
    let apiId = 'editedDataReport';
    return (
      <>
        <Form
          type="server"
          direction="column"
          initialValues={{
            startDate: '',
            endDate: '',
            driverIds: [],
            recurrenceType: 'NONE',
            recurrenceInterval: 'MONTHLY',
            scheduleEndDate: ''
          }}
          RenderComponent={EditedDataRenderComponent}
          handleOnSubmit={(fields) => {
            let data = {
              endDate: fields.endDate,
              startDate: fields.startDate,
              driverIds: [],
              ...(getSchedulingFields(fields))
            };
            fields.driverIds.forEach((item) => {
              data.driverIds.push(item.value);
            });
            editedDataReport({ data: data, apiId, callback: callback });
          }}
          customProps={{
            history,
            Styled,
            i18n,
            users
          }}
          validationSchema={Yup.object().shape({
            ...(getValidationSchema())
          })}
        />
      </>
    );
  };

  const DrivingEventReportForm = () => {
    let apiId = 'drivingEventReport';
    return (
      <>
        <Form
          type="server"
          direction="column"
          initialValues={{
            startDate: '',
            endDate: '',
            drivingState: { label: 'All', value: 'ALL' },
            driverIds: [],
            recurrenceType: 'NONE',
            recurrenceInterval: 'MONTHLY',
            scheduleEndDate: ''
          }}
          RenderComponent={DrivingEventRenderComponent}
          handleOnSubmit={(fields) => {
            let data = {
              endDate: fields.endDate,
              startDate: fields.startDate,
              drivingState: fields?.drivingState?.value || 'ALL',
              driverIds: [],
              ...(getSchedulingFields(fields))
            };
            fields.driverIds.forEach((item) => {
              data.driverIds.push(item.value);
            });
            drivingEventReport({ data: data, apiId, callback: callback });
          }}
          customProps={{
            history,
            Styled,
            i18n,
            drivers
          }}
          validationSchema={Yup.object().shape({
            ...(getValidationSchema())
          })}
        />
      </>
    );
  };

  const VehicleReportForm = ({ submitApi }) => {
    let apiId = 'vehicleReport';
    return (
      <>
        <Form
          type="server"
          direction="column"
          initialValues={{
            startDate: '',
            endDate: '',
            vehicleIds: [],
            recurrenceType: 'NONE',
            recurrenceInterval: 'MONTHLY',
            scheduleEndDate: ''
          }}
          RenderComponent={VehicleReportRenderComponent}
          handleOnSubmit={(fields) => {
            let data = {
              endDate: fields.endDate,
              startDate: fields.startDate,
              vehicleIds: [],
              ...(getSchedulingFields(fields))
            };
            fields.vehicleIds.forEach((item) => {
              data.vehicleIds.push(item.value);
            });
            submitApi({ data: data, apiId, callback: callback });
          }}
          customProps={{
            history,
            Styled,
            i18n,
            vehicles
          }}
          validationSchema={Yup.object().shape({
            ...(getValidationSchema())
          })}
        />
      </>
    );
  };

  const MalfunctionReportForm = ({ submitApi }) => {
    let apiId = 'malFunctionReport';
    return (
      <>
        <Form
          type="server"
          direction="column"
          initialValues={{
            startDate: '',
            endDate: '',
            vehicleIds: [],
            driverIds: [],
            recurrenceType: 'NONE',
            recurrenceInterval: 'MONTHLY',
            scheduleEndDate: ''
          }}
          RenderComponent={MalFunctionReportRenderComponent}
          handleOnSubmit={(fields) => {
            let data = {
              endDate: fields.endDate,
              startDate: fields.startDate,
              vehicleIds: [],
              driverIds: [],
              ...(getSchedulingFields(fields))
            };
            if ((fields && fields.driverIds && fields.driverIds.length > 0) || (fields && fields.vehicleIds && fields.vehicleIds.length > 0)) {
              if (fields.vehicleIds && fields.vehicleIds.length > 0) {
                fields.vehicleIds.forEach((item) => {
                  data.vehicleIds.push(item.value);
                });
              }
              if (fields.driverIds && fields.driverIds.length > 0) {
                fields.driverIds.forEach((item) => {
                  data.driverIds.push(item.value);
                });
              }
            }
            submitApi({ data: data, apiId, callback: callback });
          }}
          customProps={{
            history,
            Styled,
            i18n,
            vehicles,
            drivers
          }}
          validationSchema={Yup.object().shape({
            ...(getValidationSchema())
          })}
        />
      </>
    );
  };

  const DateReportForm = ({ submitApi }) => {
    let apiId = 'DateReportForm';
    return (
      <>
        <Form
          type="server"
          direction="column"
          initialValues={{
            startDate: '',
            endDate: '',
            recurrenceType: 'NONE',
            recurrenceInterval: 'MONTHLY',
            scheduleEndDate: ''
          }}
          RenderComponent={DateReportFormRenderComponent}
          handleOnSubmit={(fields) => {
            let data = {
              endDate: fields.endDate,
              startDate: fields.startDate,
              ...(getSchedulingFields(fields))
            };
            submitApi({ data: data, apiId, callback: callback });
          }}
          customProps={{
            history,
            Styled,
            i18n
          }}
          validationSchema={Yup.object().shape({
            ...(getValidationSchema())
          })}
        />
      </>
    );
  };


  //Extra Reports
  const CompanyFormReport = ({ submitApi }) => {
    let apiId = 'companyFormReport';
    return (
      <>
        <Form
          type="server"
          direction="column"
          initialValues={{
            startDate: '',
            endDate: '',
            companyIds: [],
            recurrenceType: 'NONE',
            recurrenceInterval: 'MONTHLY',
            scheduleEndDate: ''
          }}
          RenderComponent={CompanyFormRenderComponent}
          handleOnSubmit={(fields) => {
            let data = {
              endDate: fields.endDate,
              startDate: fields.startDate,
              companyIds: [],
              ...(getSchedulingFields(fields))
            };
            fields.companyIds.forEach((item) => {
              data.companyIds.push(item.value);
            });
            submitApi({ data: data, apiId, callback: callback });
          }}
          customProps={{
            history,
            Styled,
            i18n,
            companies
          }}
          validationSchema={Yup.object().shape({
            ...(getValidationSchema())
          })}
        />
      </>
    );
  };
  const DailyHOSDriverViolations = ({ submitApi }) => {
    let apiId = 'dailyHosViolationReportApi';
    return (
      <>
        <Form
          type="server"
          direction="column"
          initialValues={{
            startDate: '',
            endDate: '',
            driverIds: []
          }}
          RenderComponent={DailyHOSDriverViolationsFormRenderComponent}
          handleOnSubmit={(fields) => {
            let data = {
              endDate: fields.endDate,
              startDate: fields.startDate,
              driverIds: []
            };
            fields.driverIds.forEach((item) => {
              data.driverIds.push(item.value);
            });
            submitApi({ data: data, apiId, callback: callback });
          }}
          customProps={{
            history,
            Styled,
            i18n,
            drivers
          }}
          validationSchema={Yup.object().shape({
            driverIds: Yup.array().min(1, i18n.t('common.fieldRequiredMessage'))
          })}
        />
      </>
    );
  };

  const DailyUnsignedLogReport = ({ submitApi }) => {
    let apiId = 'dailyUnsignedLogReportApi';
    return (
      <>
        <Form
          type="server"
          direction="column"
          initialValues={{
            startDate: '',
            endDate: '',
            driverIds: []
          }}
          RenderComponent={DailyUnsignedLogReportRenderComponent}
          handleOnSubmit={(fields) => {
            let data = {
              endDate: fields.endDate,
              startDate: fields.startDate,
              driverIds: []
            };
            fields.driverIds.forEach((item) => {
              data.driverIds.push(item.value);
            });
            submitApi({ data: data, apiId, callback: callback });
          }}
          customProps={{
            history,
            Styled,
            i18n,
            drivers
          }}
          validationSchema={Yup.object().shape({
            driverIds: Yup.array().min(1, i18n.t('common.fieldRequiredMessage'))
          })}
        />
      </>
    );
  };



  const DailyFailedInspectionReport = ({ submitApi }) => {
    let apiId = 'dailyFailedInspectionReportApi';
    return (
      <>
        <Form
          type="server"
          direction="column"
          initialValues={{
            startDate: '',
            endDate: '',
            driverIds: []
          }}
          RenderComponent={DailyFailedInspectionReportFormRenderComponent}
          handleOnSubmit={(fields) => {
            let data = {
              endDate: fields.endDate,
              startDate: fields.startDate,
              driverIds: []
            };
            fields.driverIds.forEach((item) => {
              data.driverIds.push(item.value);
            });
            submitApi({ data: data, apiId, callback: callback });
          }}
          customProps={{
            history,
            Styled,
            i18n,
            drivers
          }}
          validationSchema={Yup.object().shape({
            driverIds: Yup.array().min(1, i18n.t('common.fieldRequiredMessage'))
          })}
        />
      </>
    );
  };
  const DailyUnidentifiedDrivingReport = ({ submitApi }) => {
    let apiId = 'dailyUnidentifiedDrivingReportApi';
    return (
      <>
        <Form
          type="server"
          direction="column"
          initialValues={{
            startDate: '',
            endDate: '',
            driverIds: []
          }}
          RenderComponent={DailyUnidentifiedDrivingReportFormRenderComponent}
          handleOnSubmit={(fields) => {
            let data = {
              endDate: fields.endDate,
              startDate: fields.startDate,
              driverIds: []
            };
            fields.driverIds.forEach((item) => {
              data.driverIds.push(item.value);
            });
            submitApi({ data: data, apiId, callback: callback });
          }}
          customProps={{
            history,
            Styled,
            i18n,
            drivers
          }}
          validationSchema={Yup.object().shape({})}
        />
      </>
    );
  };


  const MonthlyHOSDriverViolationsReport = ({ submitApi }) => {
    let apiId = 'driverPerformanceReport';
    return (
      <>
        <Form
          type="server"
          direction="column"
          initialValues={{
            startDate: '',
            endDate: '',
            driverIds: []
          }}
          RenderComponent={MonthlyHOSDriverViolationsReportFormRenderComponent}
          handleOnSubmit={(fields) => {
            let data = {
              endDate: fields.endDate,
              startDate: fields.startDate,
              driverIds: []
            };
            fields.driverIds.forEach((item) => {
              data.driverIds.push(item.value);
            });
            submitApi({ data: data, apiId, callback: callback });
          }}
          customProps={{
            history,
            Styled,
            i18n,
            drivers
          }}
          validationSchema={Yup.object().shape({
            startDate: Yup.string().required(
              i18n.t('common.fieldRequiredMessage')
            ),
            endDate: Yup.string().required(
              i18n.t('common.fieldRequiredMessage')
            )
          })}
        />
      </>
    );
  };

  const MonthlyUnsignedLogReport = ({ submitApi }) => {
    let apiId = 'driverPerformanceReport';
    return (
      <>
        <Form
          type="server"
          direction="column"
          initialValues={{
            startDate: '',
            endDate: '',
            driverIds: []
          }}
          RenderComponent={MonthlyUnsignedLogReportFormRenderComponent}
          handleOnSubmit={(fields) => {
            let data = {
              endDate: fields.endDate,
              startDate: fields.startDate,
              driverIds: []
            };
            fields.driverIds.forEach((item) => {
              data.driverIds.push(item.value);
            });
            submitApi({ data: data, apiId, callback: callback });
          }}
          customProps={{
            history,
            Styled,
            i18n,
            drivers
          }}
          validationSchema={Yup.object().shape({
            startDate: Yup.string().required(
              i18n.t('common.fieldRequiredMessage')
            ),
            endDate: Yup.string().required(
              i18n.t('common.fieldRequiredMessage')
            )
          })}
        />
      </>
    );
  };

  // Accordion data list
  let accordionListArray = [
    ...(window.$environment.SERVICE_PROVIDER !== 'edvir' ? [{
      id: 'DUTY_STATUS',
      name: i18n.t('reportPage.dutyStatus'),
      value: window.$environment.SERVICE_PROVIDER === 'spireon' ? <DutyStatusReportFormSpireon /> : <DutyStatusReportForm />,
      dependency: ''
    },
    ...(window?.$environment?.SERVICE_PROVIDER !== 'webfleet' ? [{
      id: 'DRIVER_PERFORMANCE_REPORT',
      name: i18n.t('reportPage.driverPerf'),
      value: <DriverWithDateReportForm submitApi={driverPerformanceReport} />,
      dependency: 'geometrisEnabled'
    }] : []),
    {
      id: 'EDITED_DATA_REPORT',
      name: i18n.t('reportPage.editedData'),
      value: <EditedDataReportForm />,
      dependency: ''
    },
    {
      id: 'REJECTED_EDITS',
      name: i18n.t('reportPage.rejectedEdits'),
      value: <DriverWithDateReportForm submitApi={rejectedEditsReport} />,
      dependency: ''
    },
    {
      id: 'DRIVING_EVENT_REPORT',
      name: i18n.t('reportPage.drivingEvents'),
      value: <DrivingEventReportForm />,
      dependency: ''
    },
    {
      id: 'ELD_DISCONNECT_REPORT',
      name: i18n.t('reportPage.eldDisconn'),
      value: <VehicleReportForm submitApi={eldDisconnectReport} />,
      dependency: 'geometrisEnabled'
    },
    {
      id: 'ODOMETER_JUMP_REPORT',
      name: i18n.t('reportPage.odoJump'),
      value: <VehicleReportForm submitApi={odometerJumpReport} />,
      dependency: 'geometrisEnabled'
    },
    {
      id: 'MALFUNCTION_REPORT',
      name: i18n.t('reportPage.malFunc'),
      value: <MalfunctionReportForm submitApi={malfunctionReport} />,
      dependency: ''
    },
    {
      id: 'UNASSIGNED_DRIVING_REPORT',
      name: i18n.t('reportPage.unassigned'),
      value: <DateReportForm submitApi={unassignedDrivingReport} />,
      dependency: ''
    },
    {
      id: 'VIOLATION_REPORT',
      name: i18n.t('reportPage.violation'),
      value: <DateReportForm submitApi={violationReport} />,
      dependency: ''
    },
    {
      id: 'HOS_VIOLATIONS_DETAIL',
      name: i18n.t('reportPage.hos'),
      value: <DriverWithDateReportForm submitApi={hosViolationsReport} />,
      dependency: ''
    }] : []),
    ...(window.$environment.SERVICE_PROVIDER === 'edvir' && hasRestrictedRole([ROLES.SERVICE_MANAGER]) ? [{
      id: 'PENDING_MECHANIC',
      name: i18n.t('reportPage.pendingMechanic'),
      value: <CompanyFormReport submitApi={(value) => getReports(value, 'DVIR_PENDING_MECHANIC')} />,
      dependency: ''
    },
    {
      id: 'NO_OF_REQUESTS_PER_DAY',
      name: i18n.t('reportPage.noOfRequestsPerDay'),
      value: <CompanyFormReport submitApi={(value) => getReports(value, 'DVIR_REQUESTS_PER_DAY')} />,
      dependency: ''
    },
    {
      id: 'PAST_DUE_SERVICE_REQUEST',
      name: i18n.t('reportPage.pastDueServiceRequests'),
      value: <CompanyFormReport submitApi={(value) => getReports(value, 'DVIR_PAST_DUE_REQUESTS')} />,
      dependency: ''
    }] : []),
    ...(window.$environment.SERVICE_PROVIDER === 'edvir' && hasRestrictedRole(['FLMANAGER']) ? [{
      id: 'PAST_DUE_SERVICE_REQUEST',
      name: i18n.t('reportPage.pastDueServiceRequests'),
      value: <DateReportForm submitApi={(value) => getReports(value, 'DVIR_PAST_DUE_REQUESTS')} />,
      dependency: ''
    },
    {
      id: 'PENDING_DRIVER_DVIR',
      name: i18n.t('reportPage.pendingDriverDVIR'),
      value: <DateReportForm submitApi={(value) => getReports(value, 'DVIR_PENDING_DRIVER')} />,
      dependency: ''
    }] : []),
    ...(window.$environment.SERVICE_PROVIDER == 'cyntrx' ? [{
      id: 'BORDER_STATES',
      name: i18n.t('reportPage.borderStates'),
      value: <DriverWithDateReportForm submitApi={(value) => getReports(value, 'DOCUMENT_TIME')} />,
      dependency: 'borderStatesReportEnabled'
    }] : []),
    ...(window.$environment.SERVICE_PROVIDER !== 'edvir' ? [{
      id: 'BAY_INDUSTRIES_PAYROLL',
      name: i18n.t('reportPage.bayIndustriesPayroll'),
      value: <DriverWithDateReportForm submitApi={(value) => getReports(value, 'PAYROLL')} />,
      dependency: 'bayIndustriesPayrollReportEnabled'
    },
    {
      id: 'ALL_DUTY_TIME_TRACKER',
      name: i18n.t('reportPage.allDutyTimeTracker'),
      value: <DriverWithDateReportForm submitApi={(value) => getReports(value, 'ALL_DUTY_TIME_TRACKER')} />,
      dependency: 'allDutyTimeTrackerReportEnabled'
    }] : []),
    {
      id: 'DTC',
      name: i18n.t('reportPage.dtc'),
      value: <VehicleReportForm submitApi={(value) => getReports(value, 'DTC')} />,
      dependency: ''
    }
  ];
  const getReports = (data, type) => {
    let finalData = {
      ...data,
      data: {
        ...data.data,
        type
      }
    }
    downloadReport(finalData)
  }
  const setItems = () => {
    if (!hasEnabledFeature('geometrisEnabled')) {
      accordionListArray = findAndDeleteParentInJson(
        accordionListArray,
        'dependency',
        'geometrisEnabled'
      );
    }
    accordionListArray.forEach(ele => {
      if (ele.dependency && !hasEnabledFeature(ele.dependency)) {
        accordionListArray = findAndDeleteParentInJson(
          accordionListArray,
          'dependency',
          ele.dependency
        )
      }
    })
    if (window.$environment.SERVICE_PROVIDER === 'cyntrx' || window.$environment.SERVICE_PROVIDER === 'webfleet') {
      accordionListArray.push({
        id: 'BILL_OF_LADING_REPORT',
        name: i18n.t('reportPage.billOfLading'),
        value: <MalfunctionReportForm submitApi={billOfLadingReport} />,
        dependency: ''
      })
    }
    const spireonReportsEnabled = getAuthDetails()?.enterpriseFeatureDto?.spireonReportsEnabled;
    let reportTabs = getAuthDetails()?.enterpriseFeatureDto?.reportsEnabled;
    if (window.$environment.SERVICE_PROVIDER === 'spireon' && !reportTabs) accordionListArray = [];
    if (window.$environment.SERVICE_PROVIDER === 'spireon' && spireonReportsEnabled) {
      let spireonExtraTabs = [
        {
          id: 'Daily_HOS_Driver_Violations_Report',
          name: i18n.t('reportPage.DailyHocDriverViolation'),
          value: <DailyHOSDriverViolations submitApi={dailyHosViolationReport} />,
          dependency: ''
        },
        {
          id: 'Daily_Unsigned_Log_Report',
          name: i18n.t('reportPage.dailyUnsignedLogReport'),
          value: <DailyUnsignedLogReport submitApi={dailyUnsignedLogReport} />,
          dependency: ''
        },
        {
          id: 'Daily_Failed_inspection_report',
          name: i18n.t('reportPage.DailyFailedInspectionReport'),
          value: <DailyFailedInspectionReport submitApi={dailyFailedInspectionReport} />,
          dependency: ''
        },
        {
          id: 'Daily_Unidentified_driving_report',
          name: i18n.t('reportPage.DailyUnidentifiedDrivingReport'),
          value: <DailyUnidentifiedDrivingReport submitApi={dailyUnidentifiedDrivingReport} />,
          dependency: ''
        },
        // {
        //   id: 'Monthly_HOS_Driver_Violations_Report',
        //   name: i18n.t('reportPage.MonthlyHOSDriverViolationsReport'),
        //   value: <MonthlyHOSDriverViolationsReport submitApi={billOfLadingReport} />,
        //   dependency: ''
        // },
        // {
        //   id: 'Monthly_Unsigned_Log_Report',
        //   name: i18n.t('reportPage.MonthlyUnsignedLogReport'),
        //   value: <MonthlyUnsignedLogReport submitApi={billOfLadingReport} />,
        //   dependency: ''
        // }
      ]
      accordionListArray = accordionListArray.filter(list => list.id !== "HOS_VIOLATIONS_DETAIL" && list.id !== "UNASSIGNED_DRIVING_REPORT")
      accordionListArray = [...accordionListArray, ...spireonExtraTabs]
    }


  };
  setItems();
  return (
    <Styled.Wrapper>
      {hasEnabledFeature('downloadCenterEnabled') ? <div className='manageSchedulingWrapper'>
        <Button
          type="submit"
          label={i18n.t('Manage Scheduling')}
          onClick={(e, d) => {
            history.push('/scheduling/list')
          }}
        />
      </div> : null}
      <Accordion
        data={
          (window.$environment.CURRENT_LAYOUT === 'OldUi' || expandedNotNeeded) ?
            accordionListArray.map((item) => {
              item.defaultExpanded = true;
              item.disabled = true;
              return item;
            })
            : accordionListArray}
        className="accordionWrapper"
      ></Accordion>
    </Styled.Wrapper>
  );
};

export default Component;

Component.defaultProps = {
  drivers: {},
  regions: {}
};

Component.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  dutyStatusReport: PropTypes.func.isRequired,
  driverPerformanceReport: PropTypes.func.isRequired,
  editedDataReport: PropTypes.func.isRequired,
  drivingEventReport: PropTypes.func.isRequired,
  eldDisconnectReport: PropTypes.func.isRequired,
  odometerJumpReport: PropTypes.func.isRequired,
  malfunctionReport: PropTypes.func.isRequired,
  unassignedDrivingReport: PropTypes.func.isRequired,
  violationReport: PropTypes.func.isRequired,
  downloadReport: PropTypes.func.isRequired,
  hosViolationsReport: PropTypes.func.isRequired,
  rejectedEditsReport: PropTypes.func.isRequired,
  drivers: PropTypes.object,
  regions: PropTypes.object,
  fetchTerminals: PropTypes.func.isRequired,
  fetchDepartments: PropTypes.func.isRequired,
  fetchLocations: PropTypes.func.isRequired
};
