import React, { lazy, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { string, object, number } from 'yup';
import underscore from 'underscore';
import { isdvirOnly } from '../../../utils/utils'
import RenderComponent from './RenderComponent';
import { pageUrls } from '@/config/constants/keys';
import { emailRegExp, phoneRegExp } from '@/utils/constants';
const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));

const WorkOrderForm = ({
  apiStatus,
  history,
  saveDocument,
  deleteDocument,
  callback,
  i18n,
  type,
  handleDownloadDoc,
  downloadDocument,
  logBook,
  handleLogout,
  Styled,
  workOrders,
  saveAddWorkOrders,
  getDocumentToken,
  viewReport,
  receipt,
  clearState,
  setOpenPhoto,
  ModalObjectPhoto,
  setModalObjectPhoto,
  viewOnly
}) => {
  useEffect(() => {
    return () => {
      clearState();
    };
  }, []);
  useEffect(() => {
    let url = receipt ? receipt : '';
    if (typeof (url) === 'string')
      document.getElementById(`downloadDocument`).src = url;
  }, [receipt]);
  const [validationlist, setValidationlist] = useState({
    unitType: string().required(i18n.t('common.fieldRequiredMessage')),
    type: string().required(i18n.t('common.fieldRequiredMessage')),
    beginDate: string().required(i18n.t('common.fieldRequiredMessage')),
    endDate: string().required(i18n.t('common.fieldRequiredMessage')),
    mileage: number()
      .typeError(i18n.t('common.validNumberMessage'))
      .positive(i18n.t('common.positiveNumberMessage'))
  });
  const [initialValues, setInitialValues] = useState({});
  useEffect(() => {
    let data = workOrders?.getData?.workOrderEditDto;
    setInitialValues({
      idPub: data?.idPub || null,
      reportedDate: data?.reportedDate || '',
      receiptsUploaded: data?.receiptsUploaded || '',
      state: data?.state || 'NOT_STARTED',
      concern: data?.concern || '',
      cause: data?.cause || '',
      correction: data?.correction || '',
      labourCost: data?.labourCost || '0.00',
      cost: data?.cost || '0.00',
      partCost: data?.partCost || '0.00',
      fuel: data?.dailyLiquids ? data.dailyLiquids.filter(ele => ele.liquidType == 'Fuel')[0].quantity : '',
      engineOil: data?.dailyLiquids ? data.dailyLiquids.filter(ele => ele.liquidType == 'Engine Oil')[0].quantity : '',
      hydraulicOil: data?.dailyLiquids ? data.dailyLiquids.filter(ele => ele.liquidType == 'Hydraulic Oil')[0].quantity : '',
      windShieldFluid: data?.dailyLiquids ? data.dailyLiquids.filter(ele => ele.liquidType == 'Windshield Washer Fluid')[0].quantity : '',
      beginDate:
        (data?.beginDate &&
          data?.beginDate.substr(0, data?.beginDate.indexOf(' '))) ||
        '',
      endDate:
        (data?.endDate &&
          data?.endDate.substr(0, data?.endDate.indexOf(' '))) ||
        '',
      note: data?.note || '',
      parts: data?.parts || [],
      unitId: data?.vehicleIdPub || '',
      mileage: data?.mileage,
      partsArray: data?.parts || [],
      isEdit: type === 'edit' ? true : false,
      mechanicId:
        vehicleMechanicList.filter(
          (mech) => mech.value === data?.mechanicId
        )[0] || '',
      reportId: data?.inspectionDto?.reportId || null,
      pictureId: data?.inspectionDto?.inspectionConditions || []
    });
  }, [workOrders]);

  const objectArrayFromDiffFields = (list, field, value, callback) => {
    return list?.length
      ? list.map((item) => {
        if (callback) return callback(item, field, value);
        else
          return {
            label: item[value],
            value: item[field],
            vin: item['vin'] || ''
          };
      })
      : [];
  };

  const partExists = (id) => {
    if (workOrders?.getData?.workOrderEditDto?.parts) {
      return workOrders?.getData?.workOrderEditDto?.parts.some(function (el) {
        return el.id === id;
      });
    } else return false;
  };
  const partsListCallback = (item, field, value) => {
    return {
      label: item[value],
      value: item[field],
      cost: item['cost'],
      partsInInventory: item['partsInInventory'],
      isDisabled:
        partExists(item['id']) || item.deleted || item.partsInInventory === 0
          ? true
          : false,
      selectedValue: {},
      company: item['company'],
      cost: item['cost'],
      id: item['id'],
      orderedQty: item['orderedQty'] || 1,
      partName: item['partName']
    };
  };

  const partsList = objectArrayFromDiffFields(
    workOrders?.getData?.parts,
    'id',
    'partName',
    partsListCallback
  );
  const trailerList = objectArrayFromDiffFields(
    workOrders?.getData?.workOrderEditDto?.trailers,
    'trailerId',
    'name'
  );

  const vehicleList = objectArrayFromDiffFields(
    workOrders?.getData?.workOrderEditDto?.vehicles,
    'vehicleId',
    'name'
  );

  const objectArrayFromDiffFieldsMech = (
    list,
    value,
    field1,
    field2,
    callback
  ) => {
    return list?.length
      ? list.map((item) => {
        if (callback) return callback(item, field1, field2, value);
        else
          return {
            label: field2 ? `${item[field1]} ${item[field2]}` : item[field1],
            value: item[value]
          };
      })
      : [];
  };

  const vehicleMechanicList = objectArrayFromDiffFieldsMech(
    workOrders?.getData?.workOrderEditDto?.allMechanics,
    'id',
    'firstName',
    'lastName'
  );

  return (
    <>
      <Form
        type="server"
        direction="column"
        initialValues={{ ...initialValues }}
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {
          let dailyLiquids = [{
            liquidType: 'Fuel',
            quantity: fields.fuel,
            unit:''
          }, {
            liquidType: 'Engine Oil',
            quantity: fields.engineOil,
            unit:''
          }, {
            liquidType: 'Hydraulic Oil',
            quantity: fields.hydraulicOil,
            unit:''
          }, {
            liquidType: 'Windshield Washer Fluid',
            quantity: fields.windShieldFluid,
            unit:''
          }]

          let doc = [];
          for (let i = 0; i < workOrders.documentsList.length; i++) {
            if (workOrders.documentsList[i].id !== null) {
              doc.push(workOrders.documentsList[i].id);
            }
          }
          let a = [];
          fields.partsArray.forEach((e) => {
            let tempItem = Object.assign({}, e);
            delete tempItem.isDisabled;
            delete tempItem.label;
            delete tempItem.partsInInventory;
            delete tempItem.selectedValue;
            delete tempItem.value;
            tempItem.orderedQty = Number(tempItem.orderedQty);

            a.push(tempItem);
          });

          let today = new Date();
          let dd = String(today.getDate()).padStart(2, '0');
          let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
          let yyyy = today.getFullYear();
          today = mm + '/' + dd + '/' + yyyy;

          let apiId = 'saveAddWorkOrders';
          let dataToSend = {
            beginDate: fields.beginDate,
            dailyLiquids,
            cause: fields.cause,
            concern: fields.concern,
            correction: fields.correction,
            cost: Number((Math.round(fields.cost * 100) / 100).toFixed(2)),
            endDate: fields.endDate,
            idPub: fields.idPub,
            labourCost: Number(
              (Math.round(fields.labourCost * 100) / 100).toFixed(2)
            ),
            mechanicId: fields?.mechanicId?.value || '',
            mileage: fields.mileage,
            note: fields.note,
            partCost: Number(
              (Math.round(fields.partCost * 100) / 100).toFixed(2)
            ),
            parts: [...a],
            receiptsUploaded: doc.join(','),
            reportedDate: today,
            state: fields.state,
            type: type === 'add' ? fields.type.value : fields.type,
            unitType: type === 'add' ? fields.unitType.value : fields.unitType,
            vehicleIdPub: fields.unitId.value
          };

          {
            /* let dumJson = JSON.stringify(dataToSend) */
          }
          let url = pageUrls.workOrders
          let successMessage = i18n.t('workOrders.workOrderEditSuccess')
          if (isdvirOnly()) {
            url = pageUrls.pendingMechanicInspections;
            successMessage = i18n.t('workOrders.serviceRequestEditSuccess')
            if (fields.state && ["COMPLETED", 'APPROVED'].includes(fields?.state)) url = pageUrls.pendingDriverInspections;
            if (fields.state && ["CLOSED"].includes(fields?.state)) url = pageUrls.completedInspections;
          }
          saveAddWorkOrders({
            data: dataToSend,
            apiId,
            callback: callback,
            history,
            url,
            successMessage
          });
        }}
        customProps={{
          apiStatus,
          callback,
          Styled,
          workOrders,
          partsList,
          saveDocument,
          deleteDocument,
          setInitialValues,
          setValidationlist,
          downloadDocument,
          vehicleMechanicList,
          trailerList,
          vehicleList,
          logBook,
          getDocumentToken,
          viewReport,
          i18n,
          setOpenPhoto,
          ModalObjectPhoto,
          setModalObjectPhoto,
          viewOnly
        }}
        validationSchema={
          type === 'add'
            ? object().shape({
              ...validationlist
            })
            : null
        }
        handleOnCancel={() => {
          let url = pageUrls.workOrders
          const state = workOrders.getData?.workOrderEditDto?.state
          if (isdvirOnly()) {
            url = pageUrls.pendingMechanicInspections;
            if (state && ["COMPLETED"].includes(state)) url = pageUrls.pendingDriverInspections;
            if (state && ["CLOSED", "APPROVED"].includes(state)) url = pageUrls.completedInspections;
          }
          history.push(url);
        }}
      />
      <iframe id="downloadDocument" style={{ display: 'none' }}></iframe>
    </>
  );
};

WorkOrderForm.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  saveUserData: PropTypes.func.isRequired,
  saveDocument: PropTypes.func.isRequired,
  deleteDocument: PropTypes.func.isRequired,
  fetchTerminals: PropTypes.func.isRequired,
  fetchDepartments: PropTypes.func.isRequired,
  fetchSubDepartments: PropTypes.func.isRequired,
  regions: PropTypes.array.isRequired,
  types: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  handleDownloadDoc: PropTypes.func.isRequired
};

export default WorkOrderForm;
