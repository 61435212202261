import React, { lazy } from 'react';
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Label = lazy(() => import(`../../../UI/Label/${layout}`));

const TabPanel = ({ children, value, index, noPadding, ...other }) => (
  <Label
    component="div"
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    <Box p={noPadding ? 0 : 3}>{children}</Box>
  </Label>
);

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
};

const SimpleTabs = ({
  currentTab,
  onChange,
  data,
  className,
  TabWrapper,
  vertical,
  noPadding
}) => (
  <TabWrapper className={className || ''}>
    <AppBar position="static">
      <Tabs
        value={currentTab}
        onChange={onChange}
        aria-label="simple tabs example"
        orientation={vertical ? 'vertical' : ''}
      >
        {data.map((item, i) => {
          return <Tab label={item.name} {...a11yProps(i)} key={i} />;
        })}
      </Tabs>
    </AppBar>

    {data.map((item, i) => {
      return (
        <TabPanel value={currentTab} index={i} key={i} noPadding>
          {item.value}
        </TabPanel>
      );
    })}
  </TabWrapper>
);

export default SimpleTabs;

SimpleTabs.propTypes = {
  currentTab: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired
};
