import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
position: initial;
.buttonWrapper {
    position: absolute;
    right: 2%;
    top: 0;
    padding-top: 2%;
    
    .advancedDVIR{
        button {
            min-width: 162px;
            padding: 0;
        }
    }
    .bulkDownload{
        button {
            min-width: 256px;
            padding: 0 12px;
        }
    }
}
.datepopup{
    position: absolute !important;
    right: 2%;
}
.CSAForms {
    padding-left: 21%;
    padding-top: 24px;
    .vertical-button {
        .MuiButtonBase-root {
            margin-top: 28px;
        }
    }
    .MuiFormLabel-root {
        font-size: ${theme.palette.inspectionInput};
        @media (max-width: ${theme.breakpoints.values.xxl}px) {
            font-size: ${theme.palette.inspectionresolution};
        }
    }
}
.quickFilter {
    display: block;
    background: ${theme.colors.LINK_COLOR};
    height: 20px;
}
.breadcrumbClass{
    padding-left: 6%;
    position: absolute;
    bottom: 0;
    width: 100%;
}
`}
`;
const TopWrapper = styled(Parent.TopWrapper)`
 
`;
const TableWrapper = styled(Parent.TableWrapper)`
padding: 0 2%;
`;
const Styled = { Wrapper, TopWrapper, TableWrapper };

export default Styled;
