import React from 'react';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import useTheme from '@material-ui/core/styles/useTheme';
import PropTypes from 'prop-types';

import Component from './Component';
// import Styled from './style';
import ParentStyled from './style';
import { loadStyle, getNewObject, hasValue } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'pages/web/Vehicles/RoadsideInspections');
const RoadsideInspection = ({
  apiStatus,
  history,
  callback,
  infoBlockItems,
  openDocumentApi,
  viewReport,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <>
      {/* <Box style={{ ...theme.palette.contentStyle }}> */}
        <Component
          {...rest}
          history={history}
          infoBlockItems={infoBlockItems}
          apiStatus={apiStatus}
          callback={callback}
          openDocumentApi={openDocumentApi}
          viewReport={viewReport}
          Styled={Styled}
        />
      {/* </Box> */}
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    infoBlockItems: state.vehicles.infoBlockItemsFilteredList,
    logBook: state.logBook
  };
};

const mapDispatch = (dispatch) => {
  return {
    viewReport: (data) => dispatch.roadsideInspection.viewReport(data)
  };
};

export default connect(mapState, mapDispatch)(RoadsideInspection);

RoadsideInspection.propTypes = {
  callback: PropTypes.func.isRequired,
  infoBlockItems: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  apiStatus: PropTypes.object.isRequired
};
