import React, { lazy, useState } from 'react';
import { getImagePath } from '@/utils/utils';
import { Link as LinkLi } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { imageUrls, pageUrls } from '@/config/constants/keys';
const layout = window?.$environment?.CURRENT_LAYOUT;
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Modal = require(`@/components/UI/Modal/${layout}`);

const Breadcrumb = lazy(() => import(`@/components/Breadcrumb`));
const Search = lazy(() => import(`@/components/UI/SimpleSearch/${layout}`));
const FuelComsumptionAddEdit = lazy(() => import(`@/components/modules/FuelComsumptionAddEdit`));

const TopActions = ({
  Styled,
  fuelConsumption,
  i18n,
  filterSuccess,
  filters,
  setPagination,
  callback,
  vehicleList,
  driverList,
  getVehicleListDropDown,
  getDrivers,
  getStateListData,
  addEditFuelData
}) => {
  const selectChange = (e) => {
    filterSuccess(e);
    setPagination(true);
  };

  const uploadApi = 'fuelAddEditApi';
  const [open, setOpen] = useState(false);
  const [withCloseIcon, setwithCloseIcon] = useState(true);
  const [backgroundModal, setbackgroundModal] = useState(false);
  const closeModal = (event) => {
    setOpen(false);
  };
  const clickedModal = (e) => {
    setOpen(true);
  };

  const modalDataHandler = () => {
    getVehicleListDropDown({ apiId: 'getVehicleListDropDown' });
    getDrivers({ apiId: 'getDrivers' })
    getStateListData({ apiId: 'getStateListData' })
  }

  return (
    <>
      {layout !== 'OldUi' && <Breadcrumb filters={filters} />}
      <Styled.TopWrapper>
        <Styled.SearchWrapper>
          <Search
            id={'clearSearch'}
            onChange={(e) => selectChange(e)}
            default={fuelConsumption?.list}
            fromData
            placeHolder={i18n.t('fuelConsumption.search')}
            query={['drivers', 'vehicleName']}
            id='fuel_consumption'
          ></Search>
        </Styled.SearchWrapper>
        <Styled.IconWrapper>
          <LinkLi  className="userNew"     
              onClick={() => {
                  modalDataHandler()
                  setbackgroundModal(false);
                  setwithCloseIcon(true);
                  clickedModal();
                }}>
            <Icon src={getImagePath(imageUrls.addVehicle)} />
          </LinkLi>
          <LinkLi
            component={Link}
            to={pageUrls.addUser}
            className="addUserButtonhover userOld"
          >
            <Button
              // component={Link}
              variant="body2"
              className="linkStyle addUser"
              label={i18n.t('users.addNewUser')}
            ></Button>
          </LinkLi>
        </Styled.IconWrapper>
        <div className="breadcrumbClass">
        {layout === 'OldUi' && <Breadcrumb filters={filters} />}
        </div>
      </Styled.TopWrapper>
      {(driverList?.length > 0) && (vehicleList?.length > 0) && fuelConsumption?.stateListData && <Modal.default
          open={open}
          width="700px"
          setOpen={setOpen}
          data={
            {
              header: '',
              content: () => {
                return (
                  <FuelComsumptionAddEdit
                    // currentData={currentItem}
                    saveDocument={addEditFuelData}
                    uploadApi={uploadApi}
                    stateListData={fuelConsumption?.stateListData}
                    driverList={driverList}
                    vehicleList={vehicleList}
                    closeModal={closeModal}
                    callback={callback}
                    parent="FuelComsumptionReport"
                  />
                  );
                }
            }
          }
          onCloseModal={closeModal}
          background="black"
          withCloseIcon={withCloseIcon}
          backgroundModal={backgroundModal}
          deletePopup
        />
        }
    </>
  );
};
export default TopActions;
