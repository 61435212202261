import React, { useEffect, lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import {
  getNewObject,
  generateDropdownOptions,
  loadPageConfig,
  isInsuracePortalUser
} from '@/utils/utils';
import { useParams } from 'react-router-dom';
import { pageUrls } from '@/config/constants/keys';

const Unhandled = lazy(() => import(`@/pages/web/Unhandled`))

const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.editUser[0].name}`));
const Content = lazy(() => import(`@/${config.editUser[1].name}`));

const EditUser = (parentProps) => {
  const {
    getDocumentToken,
    regions,
    getRegions,
    fetchVehicleList,
    fetchEditUserData,
    fetchCompanyList,
    user,
    removeApiStatus,
    clearReceipt,
    callback,
    module
  } = parentProps;

  const history = useHistory();
  const i18n = useTranslation();
  const theme = useTheme();
  const match = useParams();
  const insuracePortalUserRole = isInsuracePortalUser()
  const commonParentProps = { history, i18n, theme };
  const getDocumentTokenApiId = 'getDocToken';
  const fetchEditUserDataApi = 'getData';

  useEffect(() => {
    fetchEditUserData({ data: { id: match.id }, apiId: fetchEditUserDataApi, callback, history, redirectionUrl: pageUrls.userList });
    // fetchUserData({ apiId: fetchUserDataApi });
    getDocumentToken({ apiId: getDocumentTokenApiId });
    removeApiStatus();
    clearReceipt();
    fetchCompanyList({ apiId: 'fetchFleetCompanies' });
    // fetchAccessLevel({ apiId: 'fetchAccessLevel' });
    // fetchRegions({ apiId: 'fetchRegions' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (window.$environment.CURRENT_LAYOUT !== 'SpireonNew') {
      let object = { ...disabledCriteria };
      if (user?.accessLevel < 1) {
        object.regionId = true;
        object.terminalId = true;
        object.departmentId = true;
        object.subDepartmentId = true;
      } else if (user?.accessLevel < 2) {
        object.regionId = true;
        object.terminalId = true;
        object.departmentId = true;
        object.subDepartmentId = false;
      } else if (user?.accessLevel < 3) {
        object.regionId = true;
        object.terminalId = true;
        object.departmentId = false;
        object.subDepartmentId = false;
      } else if (user?.accessLevel < 4) {
        object.regionId = true;
        object.terminalId = false;
        object.departmentId = false;
        object.subDepartmentId = false;
      } else {
        object.regionId = false;
        object.terminalId = false;
        object.departmentId = false;
        object.subDepartmentId = false;
      }
      setDisabledCriteria(object);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.accessLevel]);
  const [disabledCriteria, setDisabledCriteria] = useState({
    regionId: false,
    terminalId: false,
    departmentId: false,
    subDepartmentId: false
  });
  const onFleetChange = (company) => {
    getRegions(company)
    fetchVehicleList({
      data: {
        id: company
      }
    })
  }
  return (
    <>
      {((insuracePortalUserRole && (user?.userEditDto?.allRolesMap?.MECHANIC || user?.userEditDto?.allRolesMap?.FLMANAGER))) ? <Unhandled />
        : <>
          <RenderComponent
            {...config[module][0]}
            parentProps={parentProps}
            commonParentProps={commonParentProps}
            component={Title}
            withBackgroundPolicy={window.$environment.CURRENT_LAYOUT === 'SpireonNew'}
          />
          <RenderComponent
            {...config[module][1]}
            parentProps={parentProps}
            commonParentProps={commonParentProps}
            onFleetChange={onFleetChange}
            component={Content}
            notificationList={generateDropdownOptions(
              user?.notificationList || [], 'value', 'id'
            )}
            regions={generateDropdownOptions(
              insuracePortalUserRole && regions?.data?.regions?.length
                ? regions.data.regions
                : user.regions,
              'regionName',
              'id'
            )}
            terminals={generateDropdownOptions(
              regions?.data?.terminals?.length
                ? regions.data.terminals
                : user.terminals,
              'terminalName',
              'terminalId'
            )}
            departments={generateDropdownOptions(
              regions?.data?.departments?.length
                ? regions.data.departments
                : !regions?.data?.departments?.length &&
                  regions?.data?.terminals?.length
                  ? []
                  : user.departments,
              'departmentName',
              'departmentId'
            )}
            subDepartments={generateDropdownOptions(
              regions?.data?.subDepartments?.length
                ? regions.data.subDepartments
                : !regions?.data?.subDepartments?.length &&
                  regions?.data?.departments?.length
                  ? []
                  : user.subDepartments,
              'subDepartmentName',
              'subDepartmentId'
            )}
            disabledCriteria={disabledCriteria}
            userRole={{ mechanic: module === 'addMechanic' }}
          /> </>}
    </>
  );
};

const RenderComponent = ({
  name,
  props,
  commonProps,
  title,
  parentProps,
  commonParentProps,
  component,
  ...rest
}) => {
  const Component = component;
  let newProps = props?.length ? getNewObject(parentProps, props) : {};
  newProps = {
    ...newProps,
    ...(commonProps?.length ? getNewObject(commonParentProps, commonProps) : {})
  };
  return <Component {...rest} {...newProps} />;
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    regions: state.regions,
    documents: state.documents,
    user: state.userEdit,
    logBook: state.logBook,
    accessLevel: state.accessLevel,
    downloadUrl: state.viewReport,
    fleetCompanies: state.fleetCompanyList.companyList,
    vehicleList: state.vehicles.fleetVehicleList
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchTerminals: (data) => dispatch.regions.fetchTerminals(data),
    fetchDepartments: (data) => dispatch.regions.fetchDepartments(data),
    fetchSubDepartments: (data) => dispatch.regions.fetchSubDepartments(data),
    fetchUserData: (data) => dispatch.userEdit.fetchData(data),
    fetchVehicleList: (data) => dispatch.vehicles.fetchFleetData(data),
    saveUserData: (data) => dispatch.userEdit.saveData(data),
    saveDocument: (data) => dispatch.userEdit.saveDocument(data),
    deleteDocument: (data) => dispatch.userEdit.deleteDocument(data),
    fetchCountData: (data) => dispatch.dashboard.fetchCountData(data),
    downloadDocument: (data) => dispatch.viewReport.downloadReport(data),
    getDocumentToken: (data) => dispatch.logBook.documentToken(data),
    fetchRegions: (data) => dispatch.regions.fetchRegions(data),
    getRegions: (data) => dispatch.regions.fetchRegionsByCompany(data),
    fetchFilters: (data) => dispatch.filters.fetchData(data),
    fetchAccessLevel: (data) => dispatch.accessLevel.fetchData(data),
    fetchEditUserData: (data) => dispatch.userEdit.fetchData(data),
    handleDownloadDoc: (data) => dispatch.viewReport.downloadReport(data),
    fetchCompanyList: (data) => dispatch.fleetCompanyList.fetchCompanyList(data),
    handleLogout: (data) => dispatch.login.handleLogout(data),
    removeApiStatus: () => dispatch.userEdit.removeApiStatus(),
    getDrivers: (data) => dispatch.chat.getDrivers(data),
    clearReceipt: () => dispatch.viewReport.clearReceipt()
  };
};

export default connect(mapState, mapDispatch)(EditUser);
EditUser.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  fetchTerminals: PropTypes.func.isRequired,
  fetchVehicleList: PropTypes.func.isRequired,
  fetchDepartments: PropTypes.func.isRequired,
  getRegions: PropTypes.func.isRequired,
  saveDocument: PropTypes.func.isRequired,
  deleteDocument: PropTypes.func.isRequired,
  fetchUserData: PropTypes.func.isRequired,
  fetchCompanyList: PropTypes.func.isRequired,
  saveUserData: PropTypes.func.isRequired,
  fetchSubDepartments: PropTypes.func.isRequired
};
