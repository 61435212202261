import styled from 'styled-components';
const LogoContainer = styled.div`
  ${({ theme, isWeblogin, imgWidth }) => `

  left: ${isWeblogin ? '3.2%' : ''};
  top: -14px;
  position: ${isWeblogin ? 'absolute' : ''};
  img {
    width: ${imgWidth || 'auto'};
    position: relative;
    margin-top: ${isWeblogin ? '' : '8px'};
    height: ${isWeblogin ? '128px' : '112px'};
    @media only screen and (min-device-width: ${
      theme.breakpoints.values.xl
    }px) and (max-device-width: ${
    theme.breakpoints.values.xxl
  }px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
      width: 205px;
      height: 102px;
    }
  }
  a: {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  }
  `}
`;

export default LogoContainer;
