import React, { useState, useEffect, lazy } from 'react';
import PropTypes from 'prop-types';

import Toggle from './Toggle';
import moment from 'moment';
import Box from '@material-ui/core/Box';

import {
  removeListItem,
  getImagePath,
  objectArrayFromMultipleFields
} from '@/utils/utils';
import { imageUrls } from '@/config/constants/keys';
import TopActions from './TopActions';
import { UI_DATE_FORMAT } from '@/config/constants/static';

const layout = window?.$environment?.CURRENT_LAYOUT;

const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const Modal = require(`@/components/UI/Modal/${layout}`);

const SubHeader = lazy(() => import(`@/components/SubHeader/${layout}`));

const Index = ({ theme, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Component {...contentProps} />
    </Box>
  );
};
export default Index;

const Component = ({
  apiStatus,
  callback,
  fetchDailyDocuments,
  saveDocument,
  i18n,
  history,
  dailyDocuments,
  handleDeleteAlert,
  filterEldSuccess,
  Styled,
  setCurrentDriver,
  topAction,
  currentDriver,
  setModalObject,
  ModalObject,
  closeModal,
  clickedModal,
  open,
  setOpen,
  filterByParams,
  viewReport,
  filterByDriverAndDate,
  setbackgroundModal,
  withCloseIcon,
  backgroundModal,
  setwithCloseIcon,
  pagination,
  setPagination
}) => {
  const [currentEld, setCurrentEld] = useState('all');
  useEffect(() => {
    const filteredDtos =
      currentEld !== 'all'
        ? dailyDocuments?.dailyDocumentsList?.filter((item) => {
          return item.type === i18n.t(`eld.${currentEld}`);
        })
        : dailyDocuments?.dailyDocumentsList;
    filterEldSuccess(filteredDtos, currentEld);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEld]);

  const drivers = objectArrayFromMultipleFields(
    dailyDocuments?.driversList,
    'id',
    ['firstName', 'lastName']
  );

  const generateRows = (data) => {
    const updatedList = data?.map((item) => {
      var result = { ...item };
      result.receipt = (
        <Icon
          variant="square"
          src={getImagePath(imageUrls.eldIcon)}
          onClick={() =>
            window.open(item.link, '_blank')
          }
        />
      );
      result.driver = (
        <>
          <p className="driver">{item.driver}</p>
          <p className="date">{`${i18n.t('Uploaded')} ${moment(item.date).format(
            UI_DATE_FORMAT
          )}`}</p>
        </>
      );
      result.date = moment(item.date).format(UI_DATE_FORMAT);
      result.customer = (
        <>
          <p>{item.customerName}</p>
          <p>{item.customerJob}</p>
        </>
      );
      return result;
    });
    return updatedList || [];
  };
  const columns = [
    {
      id: 'receipt',
      numeric: false,
      disablePadding: true,
      label: i18n.t('dailyDocuments.receipt'),
      needSorting: false
    },
    {
      id: 'driver',
      numeric: false,
      disablePadding: false,
      label: i18n.t('dailyDocuments.users'),
      needSorting: false
    },
    {
      id: 'location',
      numeric: false,
      disablePadding: true,
      label: i18n.t('common.location'),
      needSorting: false
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: i18n.t('dailyDocuments.documentType'),
      needSorting: false
    },
    {
      id: 'customer',
      numeric: false,
      disablePadding: true,
      label: i18n.t('dailyDocuments.cusName'),
      needSorting: false
    },
    {
      id: 'actions'
    }
  ];
  const otherItems = removeListItem([...columns], 'id', 'customer');
  const eldList = [
    'all',
    'delivery_receipts',
    'dispatch_records',
    'bills_of_landing',
    'receipts',
    'weigh_tickets',
    'communication_records',
    'other_documents'
  ];

  const checkEs = () => {
    let isEs = localStorage.getItem(window.$environment?.SERVICE_PROVIDER + 'Language') == 'es'
    if (window.$environment?.SERVICE_PROVIDER == 'webfleet' && isEs) return '_es'
    else return ''
  }

  return (
    <Styled.DailyLogContainer>
      {topAction && (
        <TopActions
          {...{
            history,
            apiStatus,
            fetchDailyDocuments,
            callback,
            drivers,
            currentDriver,
            Styled,
            i18n,
            saveDocument,
            setModalObject,
            ModalObject,
            closeModal,
            clickedModal,
            open,
            setOpen,
            dailyDocuments,
            filterByDriverAndDate,
            setwithCloseIcon,
            setbackgroundModal,
            withCloseIcon,
            backgroundModal,
            pagination,
            setPagination
          }}
        />
      )}
      <div className="displayContainer">
        <Styled.EldContainer>
          <SubHeader text={i18n.t('ELD')} variant="h2" />
          <div className="eldList">
            {eldList.map((item, index) => (
              <Toggle
                src={
                  currentEld === item
                    ? item + `_active${checkEs()}.png`
                    : item + `_inActive${checkEs()}.png`
                }
                setCurrentEld={(data) => {
                  setCurrentEld(data)
                  setPagination(true)
                }}
                item={item}
                i18n={i18n}
              />
            ))}
          </div>
          <div className="mainContainer">
            <Table
              type="client"
              defaultSortBy={{ sortBy: 'date', order: 'desc' }}
              title=""
              columns={[...columns]}
              itemsPerPage={5}
              data={generateRows(dailyDocuments?.dailyDocumentsFilteredList)}
              emptyMessage={i18n.t('common.tableEmptyMessage')}
              isPaginationNeeded="true"
              pageinationReset={pagination}
              pagenationClicked={(e) => {
                setPagination(!e);
              }}
              actions={[
                {
                  icon: (row, index) => {
                    return (
                      <Icon
                        src={getImagePath(imageUrls.deleteIcon)}
                        onClick={() => {
                          setwithCloseIcon(false);
                          setbackgroundModal(true);
                          setModalObject({
                            content: () => (
                              <div className="deleteParent">
                                <div className="imageSection">
                                  <img
                                    src={getImagePath(imageUrls.confirmIcon)}
                                    alt="Are you sure"
                                  />
                                </div>
                                <h2> {i18n.t('common.sureConfirm')} </h2>
                                <div className="deletePopupBtn">
                                  <div className="cancelBtn">
                                    <Button
                                      label={i18n.t('Cancel')}
                                      type={'reset'}
                                      onClick={() => {
                                        closeModal();
                                      }}
                                    ></Button>
                                  </div>
                                  <div className="deletebtn">
                                    <Button
                                      label={i18n.t('common.deletePopup')}
                                      onClick={() => {
                                        handleDeleteAlert({
                                          id: 'deleteAlert',
                                          data: {
                                            id: row?.documentId,
                                            type: row?.type
                                          },
                                          callback: callback
                                        });
                                        closeModal();
                                      }}
                                    ></Button>
                                  </div>
                                </div>
                              </div>
                            )
                          });
                          clickedModal();
                        }}
                      />
                    );
                  }
                }
              ]}
              i18n={i18n}
            />
          </div>
        </Styled.EldContainer>
        <div className="rightSide">
          <Styled.OtherContainer>
            <SubHeader text={i18n.t('OTHER')} className="other" variant="h2" />

            <div className="linkContainer">
              <Table
                type="client"
                defaultSortBy={{ sortBy: 'date', order: 'desc' }}
                title=""
                columns={[...otherItems]}
                itemsPerPage={5}
                pageinationReset={pagination}
                pagenationClicked={(e) => {
                  setPagination(!e);
                }}
                data={generateRows(
                  dailyDocuments.dailyDocumentsOtherFilteredList
                )}
                emptyMessage={i18n.t('common.tableEmptyMessage')}
                isPaginationNeeded="true"
                actions={[
                  {
                    icon: (row, index) => {
                      return (
                        <Icon
                          src={getImagePath(imageUrls.deleteIcon)}
                          onClick={() => {
                            setbackgroundModal(true);
                            setwithCloseIcon(false);
                            setModalObject({
                              content: () => (
                                <div className="deleteParent">
                                  <div className="imageSection">
                                    <img
                                      src={getImagePath(imageUrls.confirmIcon)}
                                      alt="Are you sure"
                                    />
                                  </div>
                                  <h2> {i18n.t('common.sureConfirm')} </h2>
                                  <div className="deletePopupBtn">
                                    <div className="cancelBtn">
                                      <Button
                                        label={i18n.t('Cancel')}
                                        type={'reset'}
                                        onClick={() => {
                                          closeModal();
                                        }}
                                      ></Button>
                                    </div>
                                    <div className="deletebtn">
                                      <Button
                                        label={i18n.t('trailers.deleteIt')}
                                        onClick={() => {
                                          handleDeleteAlert({
                                            id: 'deleteAlert',
                                            data: {
                                              id: row?.documentId,
                                              type: row?.type
                                            },
                                            callback: callback
                                          });
                                          closeModal();
                                        }}
                                      ></Button>
                                    </div>
                                  </div>
                                </div>
                              )
                            });
                            clickedModal();
                          }}
                        />
                      );
                    }
                  }
                ]}
                i18n={i18n}
              />
            </div>
          </Styled.OtherContainer>
        </div>
      </div>
      <Modal.default
        open={open}
        setOpen={setOpen}
        data={
          ModalObject || {
            header: '',
            content: () => { }
          }
        }
        onCloseModal={closeModal}
        background="black"
        withCloseIcon={withCloseIcon}
        backgroundModal={backgroundModal}
        deletePopup
      />
    </Styled.DailyLogContainer>
  );
};

Component.defaultProps = {
  drivers: {},
  regions: {}
};

Component.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};
