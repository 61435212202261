const currentLayout = window.$environment.CURRENT_LAYOUT;
const baseUrl = currentLayout === 'SpireonNew' ? '/spireon' : '';

const leftMenu = [
  {
    icon: 'logs.svg',
    label: 'leftMenu.logs',
    roles: ['FLMANAGER', 'ADMIN', 'DRIVER'],
    path: baseUrl + '/logs'
  },
  {
    icon: 'user.svg',
    label: 'Drivers Status',
    roles: ['FLMANAGER', 'ADMIN'],
    path: baseUrl + '/driverStatus/more'
  },

  {
    icon: 'inspection.svg',
    label: 'leftMenu.inspections',
    roles: ['FLMANAGER', 'ADMIN'],
    path: baseUrl + '/inspections'
  },
  {
    icon: 'reports.svg',
    label: 'leftMenu.reports',
    roles: ['FLMANAGER', 'ADMIN'],
    path: baseUrl +'/report'
  },
  {
    icon: 'policy.svg',
    label: 'leftMenu.policiesProcedures',
    roles: ['FLMANAGER', 'ADMIN'],
    path: baseUrl + '/policy/edit'
  }
];
export default leftMenu;
