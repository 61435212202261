import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

import LocationTracker from '@/pages/web/Dashboard/locationTracker';
import { useTheme } from '@material-ui/core/styles';
import OverlappingMarkerSpiderfier from "overlapping-marker-spiderfier";
import cookie from 'react-cookies';
import { useTranslation } from 'react-i18next';
import { googleMapMarkerDataUpdate } from '../../../config/apis';
import { AUTH_TOKEN, COOKIE_AUTH_DETAILS, imageUrls } from '../../../config/constants/keys';
import { getCookie } from '../../../services/cookie';
import { getImagePath, hasEnabledFeature, isInsuracePortalUser } from '../../../utils/utils';

// const gMapApiKey = window.$environment?.GMAP_KEY;
const beBaseUrl = window?.$environment?.BE_BASE_URL;

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: '1s',
    ss: '%ss',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1mth',
    MM: '%dmth',
    y: '1y',
    yy: '%dy'
  }
});

var google;
var MarkerClusterer;
var prev_infowindow_dash = false;
var prev_tooltip = false;
var markers = [];
var showOldView = false;
var markerCluster;
var clusterStyles = [
  {
    textColor: 'white',
    url: getImagePath(imageUrls.mapCluster),
    height: 50,
    width: 50
  }
];
var clusterOptions = {
  maxZoom: 13,
  styles: clusterStyles,
  gridSize: 20,
};
var map;
let oms;
const fleetIconMapping = {
  'Tractor Day Cab': 'tractorDayCab',
  'Tractor Sleeper': 'tractorSleeper',
  Bobtail: 'bobTail',
  'Straight Truck': 'straightTruck',
  'Box Truck': 'boxTruck',
  Pickup: 'pickup',
  'Private Passenger': 'privatePassenger',
  Vacuum: 'vacuumTrailer',
  Van: 'van',
  'Other Trailers': 'otherTrailer',
  Flatbed: 'flatBed',
  'Dry Van': 'dryVan',
  Reefer: 'reeferTrailer',
  Tanker: 'tankerTrailer',
  Dump: 'dump',
  Pneumatic: 'pneumaticTrailer',
  'Other Vehicles': 'otherVehicle',
  'Other': 'otherTrailer',
};

let driverStatusMap;
const MyMapComponent = (props) => {
  const insuracePortalUserRole = isInsuracePortalUser()
  const theme = useTheme();
  const i18n = useTranslation();

  var nightMode = [
    {
      elementType: 'geometry',
      stylers: [
        {
          color: theme.colors.MAP.LIGHT_BLACK
        }
      ]
    },
    {
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: theme.colors.MAP.GREY
        }
      ]
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: theme.colors.MAP.LIGHT_BLACK
        }
      ]
    },
    {
      featureType: 'administrative',
      elementType: 'geometry',
      stylers: [
        {
          color: theme.colors.MAP.GREY
        }
      ]
    },
    {
      featureType: 'administrative.country',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: theme.colors.MAP.LIGHT_GREY
        }
      ]
    },
    {
      featureType: 'administrative.land_parcel',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'administrative.locality',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: theme.colors.MAP.LIGHTER_GREY
        }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: theme.colors.MAP.GREY
        }
      ]
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          color: theme.colors.MAP.BLACK
        }
      ]
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [
        {
          // color: '#616161'
          color: theme.colors.MAP.GREY
        }
      ]
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: theme.colors.PRIMARY_MAIN
        }
      ]
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          // color: '#2c2c2c'
          color: theme.colors.MAP.ROAD
        }
      ]
    },
    {
      featureType: 'road',
      elementType: 'labels.text.fill',
      stylers: [
        {
          // color: '#8a8a8a'
          color: theme.colors.MAP.LIGHTER_GREY
        }
      ]
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry',
      stylers: [
        {
          // color: '#373737'
          color: theme.colors.MAP.BLACK
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        {
          color: theme.colors.MAP.ROAD
        }
      ]
    },
    {
      featureType: 'road.highway.controlled_access',
      elementType: 'geometry',
      stylers: [
        {
          // color: '#4e4e4e'
          color: theme.colors.MAP.ROAD
        }
      ]
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161'
          // color: theme.colors.MAP.ROAD
          // color: theme.colors.MAP.GREY
        }
      ]
    },
    {
      featureType: 'transit',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: theme.colors.MAP.GREY
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: theme.colors.MAP.WATER
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          // color: '#3d3d3d'
          color: theme.colors.MAP.LIGHTER_BLACK
        }
      ]
    }
  ];
  const { MapWrapper } = props;
  React.useEffect(() => {
    if (props.type === 'driverStatusMap') {
      driverStatusMap = props.type
    }
    if (props.type === 'dashboardMap' && props.viewOnMap === null)
      initialLoadfunction();
    else if (
      (props.type === 'dashboardMap' || props.type === 'vehicleStatusMap') &&
      props.viewOnMap !== null
    )
      viewOnMapClicked();
    else initialLoadfunction();

    return () => {
      map = null;
    }
  }, [props.type, props.viewOnMap, props.viewOnMapFlg, initialLoadfunction, driverStatusMap]);
  // }, [initialLoadfunction, props.data, props.type, props.viewOnMap]);

  const initialLoadfunction = () => {
    if (props.type === 'dashboardMap') {
      // if(props.viewOnMap !== null) {
      //   viewOnMapClicked(props.viewOnMap)
      // }else {
      initDashboardMap();
      // }
    } else if (props.type === 'vehicleStatusMap') {
      initVehicleStatusMap();
    } else if (props.type === 'logBookBreadCrumbMap') {
      logBookBreadCrumb(props.data);
    } else if (props.type === 'driverStatusMap') {
      initDashboardMap();
    } else if (props.type === 'vehicleMap') {
      let obj = {
        data: props.data,
        type: props.type
      };
      vehiclePageMap(obj);
    } else if (props.type === 'vehicleHistoryMap') {
      let obj = {
        data: props.data,
        type: props.type
      };
      vehiclePageMap(obj);
    }
  };

  const dashboardVechileListPopUperHandler = async (popUpData) => {
    if (!popUpData?.location && (popUpData?.lat || popUpData?.lng)) {
      const lat = popUpData?.lat;
      const lng = popUpData?.lng;
      const apiUrl = (`${beBaseUrl}${googleMapMarkerDataUpdate.url}`).replace('{latValue}', lat).replace('{lngValue}', lng);
      const token = getCookie(COOKIE_AUTH_DETAILS)?.[AUTH_TOKEN];
      try {
        const response = await fetch(apiUrl, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        });

        if (response.status === 200) {
          const responseData = await response.json();

          if (typeof responseData?.data === "string") {
            popUpData.location = responseData?.data;
          } else if (typeof responseData?.data === "object" && typeof responseData?.data?.data === "string") {
            popUpData.location = responseData?.data?.data;
          } else {
            popUpData.location = "Na";
          }
        } else {
          popUpData.location = "Na";
        }
      } catch (error) {
        popUpData.location = 'NA';
      }
    }

    if (!popUpData?.location) {
      popUpData.location = 'NA';
    }
    const vehicleSvg = fleetIconMapping[popUpData?.type] ? `${fleetIconMapping[popUpData?.type]}.svg` : 'bobTail.svg'
    return `
    <div id="cotent__container">
       <div class="cotent__container--top">
          <div id="content">
            <img class="infoImg" src=${getImagePath('icons/' + vehicleSvg)} alt="name"/>
            <a class="infoWindow-data vehicleId" href=${popUpData?.vehiclelink} target="_blank">
            ${popUpData?.vehiclename} 
            <a>
          </div>

          <div id="content" class="lastUpdatedTime">
            <a class="infoWindow-data infoWindow__span">${i18n.t('common.lastUpdated')}:&nbsp;</a>
            <a class="infoWindow-data infoWindow__span">${popUpData?.lastUpdatedTime}</a>
         </div>  
       </div>

       <div class="cotent__container--middle">
          <div id="content">
          <img class="infoImg" src=${getImagePath(
      'icons/locationMap.svg'
    )} alt="location"/>
          <a class="infoWindow-data infoWindow__span--location">${popUpData?.location}</a>
          </div>
       </div>

       <div class="cotent__container--bottom">
          <div id="content">
          <img class="infoImg" src=${getImagePath(
      'icons/direction.svg'
    )} alt="direction"/>
          <a class="infoWindow-data infoWindow__span">${popUpData?.direction}</a>
          </div>
          <div id="content">
          <img class="infoImg" src=${getImagePath(
      'icons/speedometer.svg'
    )} alt="speed"/>
          <a class="infoWindow-data infoWindow__span">${!isNaN(Number(popUpData?.speed)) && popUpData?.speed ? Number(popUpData?.speed).toFixed(2) : 'NA'}</a>
          </div>
          ${popUpData?.dtcPresent && popUpData?.vehiclelink ? `<div id="content">
          <img class="infoImg viewImg" src=${getImagePath('icons/dtc.svg')} alt="DTC"/>
          <a class="infoWindow-data" href=${`vehicle/dtc/${popUpData?.vehiclelink.split('/')[3]}?name=${(popUpData?.vehiclename || '').replace(` `, `*`)}`}>DTC</a>
        </div>` : ''}
          <div id="content">
          <img class="infoImg viewImg" src=${getImagePath(
      'icons/viewallMap.svg'
    )} alt="view all"/>
          <a class="infoWindow-data" href=${'vehicle/list'} target="_blank">
          ${i18n.t('common.viewAll')}</a>
          </div>
       </div>
       ${props.liveTracking ? `<div class="checkBoxStyle">
          <input ${popUpData.isChecked ? 'checked' : ''} id="followVehicle" type="checkbox" name="followVehicle" value="followVehicleChecked">
          <label class="infoWindow-data label-infoWindow" for="followVehicle">${i18n.t('common.followVehicle')}</label>
       </div>` : ''}
    </div>`
  }

  const viewOnMapClicked = () => {
    // if(props.type === 'driverStatusMap') {
    //   mainData =  {
    //     data: props.data,
    //     type: 'driverStatusMap'
    //   };
    // }
    initDashboardMap();
    var mapCordinates = props.viewOnMap;
    var pos_segs = mapCordinates.slice(1, -1).split(',');
    var pos_lat = pos_segs[0];
    var pos_long = pos_segs[1];
    var latlng = new window.google.maps.LatLng(pos_lat, pos_long);

    map.setCenter(latlng);
    map.setZoom(18); // set map center to marker position
    for (var i = 0; i < markers.length; i++) {
      const markerLat = parseFloat(markers[i].position.lat()).toFixed(5);
      const markerLon = parseFloat(markers[i].position.lng()).toFixed(5);
      const currentLat = parseFloat(pos_lat).toFixed(5);
      const currentLon = parseFloat(pos_long).toFixed(5);
      if (markerLat == currentLat && markerLon == currentLon) {
        window.google.maps.event.trigger(markers[i], 'click');
        break;
      }
    }
  };

  const initDashboardMap = () => {
    // let dataDash = data ? data : dashboardData;
    var mapOptions = {
      center: new window.google.maps.LatLng(39.0119, -98.4842),
      zoom: 4,
      //maxZoom: 20,
      scrollwheel: false,
      minZoom: 3
    };
    // The map, centered at Uluru
    map = new window.google.maps.Map(
      document.getElementById('map'),
      mapOptions
    );
    clearMarkers();
  };

  const timeConverterHandler = (time) => {
    const utcTime = time;
    const timezone = moment.tz.guess(); // get the user's timezone
    const localTime = moment.utc(utcTime).tz(timezone); // convert UTC time to local time
    return localTime.fromNow();
  }
  const [dashboardData, setDashboardData] = useState([])

  useEffect(() => {
    if (map && !props.liveTracking) {
      if (oms) {
        oms.clearMarkers();
      }
      let mainData = {
        data: props.data,
        type: props.type
      };
      if (props.type === 'dashboardMap' || props.type === 'driverStatusMap') {
        const options = {
          keepSpiderfied: true,
          nearbyDistance: 30
        };
        oms = new OverlappingMarkerSpiderfier(map, options);
        // The marker, positioned at Uluru
        // var marker = new window.google.maps.Marker({position: location, map: map});
        if (!showOldView && !(window?.$environment?.CURRENT_LAYOUT === 'OldUi')) {
          map.setOptions({ styles: nightMode });
        }
        clearMarkers();
        if (mainData.data) {
          const dashboardMarkers = () => {
            let dataDash = mainData?.data || [];
            var dataArr = [],
              zoom = map.getZoom(),
              center = map.getCenter();
            if (
              props.type === 'dashboardMap' &&
              (hasEnabledFeature('geometrisEnabled') || insuracePortalUserRole)
            ) {
              dataDash.forEach((value) => {
                var dataObj = {};
                dataObj['infoWithLink'] = {};
                if (value && (value.locNew || value.locOld)) {
                  if (value.locNew) {
                    dataObj['lat'] = value.locNew['y'];
                    dataObj['lng'] = value.locNew['x'];
                  } else if (value.locOld) {
                    dataObj['lat'] = value.locOld['y'];
                    dataObj['lng'] = value.locOld['x'];
                  }
                  const timeConverterHandler = (time) => {
                    const utcTime = time;
                    const timezone = moment.tz.guess(); // get the user's timezone
                    const localTime = moment.utc(utcTime).tz(timezone); // convert UTC time to local time
                    return localTime.fromNow();
                  }
                  dataObj['infoWithLink'] = {
                    lat: value.locNew['y'] || value.locOld['y'],
                    lng: value.locNew['x'] || value.locOld['x'],
                    drivername: value?.infoWithLink[0].item,
                    driverlink: value?.infoWithLink[0].link.replace('.', ''),
                    vehiclename: value?.infoWithLink[0].item,
                    vehiclelink: value?.infoWithLink[0].link.replace('.', '').replace('?id=', '/'),
                    currentStatus: value?.currentStatus,
                    speed: value?.speed || "NA",
                    location: value?.location,
                    direction: value?.direction || "NA",
                    lastUpdatedTime: value?.lastUpdatedTime ? timeConverterHandler(value?.lastUpdatedTime) : "NA",
                    type: value?.type,
                    dtcPresent: value?.dtcPresent
                  };
                  dataObj['tier'] = value.tier;
                  dataArr.push(dataObj);
                }
              });
            } else {
              dataDash.forEach((value) => {
                var dataObj = {};
                if (value && (value.locNew || value.locOld)) {
                  if (value.locNew) {
                    dataObj['lat'] = value.locNew['y'];
                    dataObj['lng'] = value.locNew['x'];
                  } else if (value.locOld) {
                    dataObj['lat'] = value.locOld['y'];
                    dataObj['lng'] = value.locOld['x'];
                  }
                  dataObj['infoWithLink'] = {
                    lat: value.locNew['y'] || value.locOld['y'],
                    lng: value.locNew['x'] || value.locOld['x'],
                    lastUpdatedTime: value?.lastUpdated ? timeConverterHandler(value?.lastUpdated) : "NA",
                    location: value.lastKnownLocation,
                    speed: value.engineSpeed || "NA",
                    direction: value?.direction || "NA",

                    drivername: value.name,
                    driverlink: `/user/edit?id=${value.driverId}`,
                    vehiclename: value.unitName,
                    vehiclelink: `/vehicle/edit/${value.unitId}`,
                    currentStatus: value.currentStatus
                  };
                  dataObj['tier'] = value.tier;
                  dataArr.push(dataObj);
                }
              });
            }
            new window.google.maps.InfoWindow({
              content: ''
            });
            var prev_infowindow = false;
            // Initialize the marker clusterer first
            markerCluster = new window.MarkerClusterer(map, [], clusterOptions);
            dataArr.forEach((data) => {
              var bounds = new window.google.maps.LatLngBounds(),
                first = true;
              // clearMarkers();
              if (!first) {
                map.getZoom();
                map.getCenter();
              }
              var tmpLatLng = new window.google.maps.LatLng(data.lat, data.lng);
              var marker = new window.google.maps.Marker({
                position: tmpLatLng,
                map: map,
                icon: (function () {
                  if (props.type === 'driverStatusMap' || !(hasEnabledFeature('geometrisEnabled') || insuracePortalUserRole)) {
                    if (data?.infoWithLink?.currentStatus === 'OFF DUTY') {
                      return getImagePath(imageUrls.mapRed);
                    } else {
                      return data?.infoWithLink?.currentStatus === 'DRIVING'
                        ? getImagePath(imageUrls.mapGreen)
                        : getImagePath(imageUrls.mapGrey);
                    }
                  } else {
                    return getImagePath(imageUrls.mapGreen);
                  }
                })()
              });
              marker.addListener('click', async function (event) {
                var contentString = '';
                if (mainData.type === 'dashboardMap') {
                  contentString = await dashboardVechileListPopUperHandler(data?.infoWithLink);
                } else {
                  contentString =
                    '<div class="infoWindowWrapper">' +
                    '<div id="content">' +
                    `<img class="infoImg" src=${getImagePath(
                      'icons/user.svg'
                    )} alt="name"/>` +
                    `<span class="nameLabel">` +
                    `Name:</span>` +
                    `<span class="infoWindow-data">` +
                    data?.infoWithLink?.drivername +
                    '<span>' +
                    '</div>' +
                    '<div id="content">' +
                    `<img class="infoImg" src=${getImagePath(
                      'icons/vehicle.svg'
                    )} alt="view all"/>` +
                    `<span class="nameLabel">` +
                    `Vehicle Id:</span>` +
                    `<span class="infoWindow-data">` +
                    data?.infoWithLink?.vehiclename +
                    '</span>' +
                    '</div>' + '</div>';
                }
                var infowindow = new window.google.maps.InfoWindow({
                  content: contentString
                });
                if (prev_infowindow) {
                  prev_infowindow.close(); // close current info window if exists
                }
                prev_infowindow = infowindow;
                infowindow.open(map, marker);
                // map.setCenter(marker.getPosition()); // set map center to marker position
                smoothZoom(map, 0, map.getZoom(0));
              });
              bounds.extend(tmpLatLng);
              markerCluster.addMarker(marker); // Add the marker to the marker clusterer
              oms.addMarker(marker);
              markers.push(marker);
            });
          };
          dashboardMarkers()
        }
      };

      if (props.type === 'vehicleStatusMap') {
        const fetchPlacesVehicle = (data) => {
          clearMarkers();
          var infowindow = new window.google.maps.InfoWindow({
            content: ''
          });
          var dataArr = [];
          data.forEach((value) => {
            var dataObj = {};
            if (value && (value.locNew || value.locOld)) {
              if (value.locNew) {
                dataObj['lat'] = value.locNew['y'];
                dataObj['lng'] = value.locNew['x'];
              } else if (value.locOld) {
                dataObj['lat'] = value.locOld['y'];
                dataObj['lng'] = value.locOld['x'];
              }
              dataObj['infoWithLink'] = value.infoWithLink;
              dataObj['tier'] = value.tier;
              dataArr.push(dataObj);
            }
          });
          new window.google.maps.InfoWindow({
            content: ''
          });
          var prev_infowindow = false;
          dataArr.forEach(function (data, i) {
            var bounds = new window.google.maps.LatLngBounds(),
              first = true;
            if (!first) {
              map.getZoom();
              map.getCenter();
            }
            var tmpLatLng = new window.google.maps.LatLng(data.lat, data.lng);
            var marker = new window.google.maps.Marker({
              position: tmpLatLng,
              map: map,
              icon: (function () {
                return getImagePath(imageUrls.mapGreen);
              })()
            });
            marker.addListener('click', function (event) {
              var contentString = '';
              if (prev_infowindow) {
                prev_infowindow.close();
              }
              var modifiedLink = '/' + data.infoWithLink[0].link.replace('./', '');
              if (!showOldView) {

                contentString =
                  '<div class="infoWindowWrapper">' +
                  '<div id="content">' +
                  `<img class="infoImg" src=${getImagePath(
                    'icons/vehicle.svg'
                  )} alt="name">` +
                  `<span class="nameLabel">` +
                  `Name:</span>` +
                  '<a class="infoWindow-data" target="_blank" href=' +
                  modifiedLink +
                  '>' +
                  data.infoWithLink[0].item +
                  '</a>' +
                  '</div>' + '</div>';
              } else {
                contentString =
                  '<div class="infoWindowWrapper">' +
                  '<div id="content">' +
                  '<span class="infoWindow-label">Name: </span>' +
                  '<a class="infoWindow-data" target="_blank" href=' +
                  modifiedLink +
                  '>' +
                  data.infoWithLink[0].item +
                  '</a>' +
                  '</div>' + '</div>';
              }
              infowindow = new window.google.maps.InfoWindow({
                content: contentString
              });
              prev_infowindow = infowindow;
              infowindow.open(map, marker);
              // map.setCenter(marker.getPosition()); // set map center to marker position
              smoothZoom(map, 0, map.getZoom(0));
            });
            bounds.extend(tmpLatLng);
            // return marker;

            markers.push(marker);
          });

          new window.MarkerClusterer(map, markers, clusterOptions);
        };

        fetchPlacesVehicle(mainData.data);
      }
      if (props.type === "vehicleMap") {
        fetchPlaces(mainData.data);
      }
      if (props.type === "vehicleHistoryMap") {
        drawVehicleLocationHistory(mainData.data);
      }

      if (props.type === 'logBookBreadCrumbMap') {
        fetchDaysVehicleHistory(mainData);
      }
    };

    return () => {
      if (oms) {
        oms.clearMarkers();
      }
      if (markerCluster) {
        markerCluster.clearMarkers();
      }
    };
  }, [props.data, props.type, props.viewOnMap])

  const initVehicleStatusMap = (data) => {
    data = props;
    clearMarkers();
    // set some default map details, initial center point, zoom and style
    var mapOptions = {
      center: new window.google.maps.LatLng(39.0119, -98.4842),
      zoom: 4,
      //maxZoom: 20,
      scrollwheel: false,
      minZoom: 3
    };
    // map = new window.google.maps.Map(document.getElementById("vehicleStatusMap"), mapOptions);

    // create the map and reference the div#map-canvas container
    map = new window.google.maps.Map(
      document.getElementById('map'),
      mapOptions
    );
    if (!showOldView && !(window?.$environment?.CURRENT_LAYOUT === 'OldUi')) {
      map.setOptions({ styles: nightMode });
    }
    window.google.maps.event.addListener(map, 'click', function (event) {
      map.setOptions({ scrollwheel: true });
    });
    // fetch the existing places (ajax)
    // and put them on the map
  };

  const clearMarkers = () => {
    for (var i = 0; i < markers.length; i++) {
      markers[i].setMap(null);
    }
    markers = [];
    setVehicleMarkers({})
  };
  const smoothZoom = (map, max, cnt) => {
    if (cnt >= max) {
      return;
    } else {
      var z;
      z = window.google.maps.event.addListener(map, 'zoom_changed', function (
        event
      ) {
        window.google.maps.event.removeListener(z);
        smoothZoom(map, max, cnt + 1);
      });
      setTimeout(function () {
        map.setZoom(cnt);
      }, 80);
    }
  };

  const logBookBreadCrumb = (data) => {
    var mapOptions = {
      center: new window.google.maps.LatLng(40.74649, -74.0094),
      zoom: 8,
      maxZoom: 19,
      scrollwheel: false,
      minZoom: 3
    };
    // create the map and reference the div#map-canvas container
    map = new window.google.maps.Map(
      document.getElementById('map'),
      mapOptions
    );
    if (!showOldView && !(window?.$environment?.CURRENT_LAYOUT === 'OldUi')) {
      map.setOptions({ styles: nightMode });
    }
    window.google.maps.event.addListener(map, 'click', function (event) {
      map.setOptions({ scrollwheel: true });
    });
    // fetch the history of vehicle (ajax)
    // and put them on the map
  };

  const fetchDaysVehicleHistory = (data) => {
    new window.google.maps.InfoWindow({
      content: ''
    });
    new window.google.maps.LatLngBounds();
    var first = true;

    (function updateHistory() {
      clearMarkers();
      if (!first) {
        map.getZoom();
        map.getCenter();
      }

      drawLocationHistory(data); //commented for map zoom in feature
    })();
  };
  function drawLocationHistory(data) {
    let newData = data.data ? data.data : data;
    clearMarkers();
    var snappedPolyline;
    var snappedCoordinates = [];
    var bounds = new window.google.maps.LatLngBounds();
    var frequency = Math.ceil(newData.length / 100);

    // loop through places and add markers
    for (var i = 0; i < newData.length; i++) {
      //create gmap latlng obj
      var tmpLatLng = new window.google.maps.LatLng(
        newData[i].locNew.coordinates[1],
        newData[i].locNew.coordinates[0]
      );
      snappedCoordinates.push(tmpLatLng);
      if (i % frequency === 0) {
        var marker = new window.google.maps.Marker({
          map: map,
          position: tmpLatLng,
          title: formatDateTime(newData[i].date),
          icon: {
            path: window.google.maps.SymbolPath.CIRCLE,
            scale: 4,
            strokeColor: theme.colors.SECONDARY_MAIN,
            strokeWeight: 3
          },
          latLngOffset: {
            lat: (Math.random() / 25000),
            lng: (Math.random() / 25000)
          }
        });
        markers.push(marker);
      }
      bounds.extend(tmpLatLng);
    }
    if (snappedPolyline != null) {
      snappedPolyline.setMap(null);
    }
    snappedPolyline = new window.google.maps.Polyline({
      path: snappedCoordinates,
      strokeColor: 'blue',
      strokeWeight: 3
    });
    if (map) {
      snappedPolyline.setMap(map);
      map.fitBounds(bounds);
    }
  }

  const vehiclePageMap = (data) => {
    if (data.type === 'vehicleMap') {
      var mapOptions = {
        center: new window.google.maps.LatLng(40.74649, -74.0094),
        zoom: 3,
        maxZoom: 19,
        scrollwheel: false,
        minZoom: 3
      };

      // create the map and reference the div#map-canvas container
      map = new window.google.maps.Map(
        document.getElementById('map'),
        mapOptions
      );
      if (!showOldView && !(window?.$environment?.CURRENT_LAYOUT === 'OldUi') && !(window?.$environment?.CURRENT_LAYOUT === 'SpireonOld')) {
        map.setOptions({ styles: nightMode });
      }
      // map.setOptions({styles: nightMode});
      window.google.maps.event.addListener(map, 'click', function (event) {
        map.setOptions({ scrollwheel: true });
      });
      // fetch the existing places (ajax)
      // and put them on the map
      // fetchPlaces(data.data);
    } else if (data.type === 'vehicleHistoryMap') {
      var mapOptions = {
        center: new window.google.maps.LatLng(40.74649, -74.0094),
        zoom: 10,
        maxZoom: 19,
        scrollwheel: false,
        minZoom: 3
      };
      map = new window.google.maps.Map(
        document.getElementById('map'),
        mapOptions
      );
      var showOldView = false;
      if (!showOldView && !(window?.$environment?.CURRENT_LAYOUT === 'OldUi')) {
        map.setOptions({ styles: nightMode });
      }
      // map.setOptions({ styles: nightMode });
      window.google.maps.event.addListener(map, 'click', function (event) {
        map.setOptions({ scrollwheel: true });
      });
      // drawVehicleLocationHistory(data.data);
    }
  };

  const fetchPlaces = (data) => {
    let infowindow = new window.google.maps.InfoWindow({
      content: ''
    });
    let bounds = new window.google.maps.LatLngBounds();
    let contentString = '';
    let first = true;

    (function update() {
      clearMarkers();
      if (!first) {
        var zoom = map.getZoom();
        var center = map.getCenter();
      }
      let newData = data.data;
      for (let i = 0; i < newData.length; i++) {
        if (newData[i].vehicle !== null) {
          let tmpLatLng = new window.google.maps.LatLng(
            newData[i].locNew.coordinates[1],
            newData[i].locNew.coordinates[0]
          );
          let marker = new window.google.maps.Marker({
            id: newData[i].vehicle.idPub,
            map: map,
            position: tmpLatLng,
            title: newData[i].vehicle.name,
            icon: (function () {
              if (newData[i].driverState === 'OFF DUTY') {
                return getImagePath(imageUrls.mapRed);
              } else {
                return newData[i].driverState === 'DRIVING'
                  ? getImagePath(imageUrls.mapGreen)
                  : getImagePath(imageUrls.mapGrey);
              }
            })()
          });
          markers.push(marker);
          let showOldView = false;
          if (!showOldView) {
            contentString =
              '<div class="infoWindowWrapper">' +
              '<div id="content">' +
              '<a class="infoWindow-data" target="_blank" href=/vehicle/edit/' +
              newData[i].vehicle.vehicleId +
              '>' +
              newData[i].vehicle.name +
              '</a>' +
              '</div>' +
              '</div>' + '</div>';
          } else {
            contentString =
              '<div class="infoWindowWrapper">' +
              '<div id="content">' +
              '<span class="infoWindow-label">Link: </span>' +
              '<a class="infoWindow-data" target="_blank" href=/vehicle/edit/' +
              newData[i].vehicle.vehicleId +
              '>' +
              newData[i].vehicle.name +
              '</a>' +
              '</div>' +
              '</div>' + '</div>';
          }

          bindInfoWindow(marker, map, infowindow, contentString);
          bounds.extend(tmpLatLng);
        }
      }
      new window.MarkerClusterer(map, markers, clusterOptions);
      if (first) {
        // map.fitBounds(bounds);
        first = false;
        map.setCenter(new window.google.maps.LatLng(40.74649, -74.0094));
      } else {
        map.setZoom(zoom);
        map.setCenter(center);
      }
    })();
  };

  const bindInfoWindow = (marker, map, infowindow, html) => {
    window.google.maps.event.addListener(marker, 'click', function () {
      infowindow.setContent(html);
      infowindow.open(map, marker);
      // map.setCenter(marker.getPosition()); // set map center to marker position
      smoothZoom(map, 0, map.getZoom()); // 10
    });
  };

  const drawVehicleLocationHistory = (data) => {
    drawLocationHistory(data);
  };

  const formatDateTime = (data) => {
    var date = new Date(data);
    var year = date.getFullYear();
    var monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    var month = monthNames[date.getMonth()];
    var day = date.getDate();
    var hours = pad(date.getHours());
    var min = pad(date.getMinutes());
    var sec = pad(date.getSeconds());
    var weekday = ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat'];
    weekday = weekday[date.getDay()];

    //var zone = date.toString().match(/\(([A-Za-z\s].*)\)/)[1];

    var dateStr = new Date(data).toString();
    var zone =
      dateStr.indexOf('(') > -1
        ? dateStr
          .match(/\([^\)]+\)/)[0]
          .match(/[A-Z]/g)
          .join('')
        : dateStr.match(/[A-Z]{3,4}/)[0];
    if (zone === 'UTC' && /(UTC\W*\d{4})/.test(dateStr)) {
      zone = RegExp.$1;
    }

    var dateFormat =
      weekday +
      ' ' +
      month +
      ' ' +
      day +
      ', ' +
      year +
      ' ' +
      hours +
      ':' +
      min +
      ':' +
      sec +
      ' ' +
      zone;
    return dateFormat;
  };

  const pad = (n) => {
    return ('0' + n).slice(-2);
  };


  const removeDuplicateCoordinates = (data, prevData) => {
    const result = [];
    for (const vehicle of data) {
      if (Array.isArray(vehicle.data)) {
        const uniqueData = [];
        const uniqueCoordinates = {};
        let prevCoordinates = []
        let prevVehicle = prevData?.filter(ele => ele.vehicleId == vehicle.vehicleId)[0]
        if (Array.isArray(prevVehicle?.data)) {
          if (prevVehicle.data[prevVehicle.data?.length - 1]) uniqueData.push(prevVehicle.data[prevVehicle.data?.length - 1]);
          for (const entry of prevVehicle.data) {
            prevCoordinates.push(`${entry.latitude.toFixed(5)}_${entry.longitude.toFixed(5)}`)
          }
        }
        for (const entry of vehicle.data.reverse()) {  // reverse array as 0th element has the latest coordinates
          const coordinateKey = `${entry.latitude.toFixed(5)}_${entry.longitude.toFixed(5)}`;
          if (!uniqueCoordinates[coordinateKey] &&
            !prevCoordinates.includes(coordinateKey) &&
            ((uniqueData.length && entry.time > uniqueData[0].time) || uniqueData.length == 0)
          ) {
            uniqueCoordinates[coordinateKey] = true;
            uniqueData.push(entry);
          }
        }
        vehicle.data = uniqueData;
        result.push(vehicle);
      }
    }
    return result;
  }

  const getBearing = (loc1, loc2) => {
    let lat1 = loc1.latitude, lng1 = loc1.longitude, lat2 = loc2.latitude, lng2 = loc2.longitude
    const dLon = lng2 - lng1;
    const y = Math.sin(dLon) * Math.cos(lat2);
    const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
    const radians = Math.atan2(y, x);
    const degrees = radians * (180 / Math.PI);
    // Adjust the result to be in the range [0, 360]
    const bearing = (degrees + 360) % 360;
    return bearing + 90;
  }

  const moveMarker = (marker, coordinates) => {

    let currentCoordinateIndex = 0;
    let duration = 13000 / coordinates.length

    function animateMarker() {
      const currentCoordinate = coordinates[currentCoordinateIndex];

      marker.set("vehicleData", {
        type: currentCoordinate.vehicleType,
        direction: currentCoordinate.direction || 'NA',
        speed: currentCoordinate.speed || 'NA',
        lastUpdatedTime: currentCoordinate.time ? timeConverterHandler(currentCoordinate.time) : 'NA',
        vehicleId: currentCoordinate.vehicleId
      })

      if (currentCoordinateIndex < coordinates.length - 1) {
        const nextCoordinate = coordinates[currentCoordinateIndex + 1];
        const latDiff = nextCoordinate.latitude - currentCoordinate.latitude;
        const lngDiff = nextCoordinate.longitude - currentCoordinate.longitude;
        const steps = 100; // Adjust the number of steps for smoother animation
        const delay = duration / steps; // Adjust the delay between steps for animation speed

        const isMoving = ((nextCoordinate.latitude.toFixed(5) - currentCoordinate.latitude.toFixed(5)) != 0) ||
          ((nextCoordinate.longitude.toFixed(5) - currentCoordinate.longitude.toFixed(5)) != 0)
        marker.setIcon({
          // Set the marker's icon with the updated rotation angle
          path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          scale: 5,
          fillColor: getMarkerColor(isMoving ? 1 : currentCoordinate.speed, currentCoordinate.vehicleId),
          fillOpacity: 1,
          strokeColor: "black",
          strokeWeight: 2,
          rotation: currentCoordinate.heading || getAngle(currentCoordinate.direction) || getBearing(currentCoordinate, nextCoordinate)
        });
        for (let i = 0; i <= steps; i++) {
          setTimeout(() => {
            const interpolateLat = currentCoordinate.latitude + (latDiff / steps) * i;
            const interpolateLng = currentCoordinate.longitude + (lngDiff / steps) * i;
            marker.setPosition(new window.google.maps.LatLng(interpolateLat, interpolateLng));
            if (cookie.load('selectedVehicle') && marker.get('vehicleData').vehicleId === cookie.load('selectedVehicle').vehicleId) {
              const bounds = map.getBounds(); // Get the current viewport bounds
              const markerPosition = marker.getPosition(); // Get the marker's position
              if (!bounds.contains(markerPosition)) {
                // If the marker is not in the viewport, recenter the map to include the marker
                map.setCenter(marker.getPosition());
              }
            }
          }, i * delay);
        }
        currentCoordinateIndex++;
        setTimeout(animateMarker, duration); // Recursively call animateMarker for the next segment
      } else if (coordinates.length == 1) {
        marker.setIcon({
          // Set the marker's icon with the updated rotation angle
          path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          scale: 5,
          fillColor: getMarkerColor(0, currentCoordinate.vehicleId),
          fillOpacity: 1,
          strokeColor: "black",
          strokeWeight: 2,
          rotation: currentCoordinate.heading || getAngle(currentCoordinate.direction)
        });
      }
    }
    animateMarker();
  }

  const [vehicleMarkers, setVehicleMarkers] = useState({})
  const handleDashData = (data) => {
    let prevData = JSON.parse(JSON.stringify(dashboardData))
    setDashboardData(removeDuplicateCoordinates(data, prevData))
  }

  const getAngle = (direction) => {
    let angles = {
      'N': 0,
      'S': 180,
      'E': 90,
      'W': 270,
      'NE': 45,
      'NW': 315,
      'SE': 135,
      'SW': 225,
    }
    return angles[direction]
  }

  useEffect(() => {
    if (props.type === 'dashboardMap' && props.liveTracking && Object.values(vehicleMarkers).length) {
      if (MarkerClusterer) {
        if (MarkerClusterer['first']) {
          MarkerClusterer = {
            ...MarkerClusterer,
            'second': new window.MarkerClusterer(map, Object.values(vehicleMarkers), clusterOptions)
          };
          MarkerClusterer['first'].clearMarkers()
          MarkerClusterer['first'] = null
        } else if (MarkerClusterer['second']) {
          MarkerClusterer = {
            ...MarkerClusterer,
            'first': new window.MarkerClusterer(map, Object.values(vehicleMarkers), clusterOptions)
          };
          MarkerClusterer['second'].clearMarkers()
          MarkerClusterer['second'] = null
        }
      } else {
        MarkerClusterer = {
          'first': new window.MarkerClusterer(map, Object.values(vehicleMarkers), clusterOptions)
        };
      }
    }
  }, [vehicleMarkers])

  const getMarkerColor = (speed, vehicleId) => {
    if (cookie.load('selectedVehicle') && vehicleId && vehicleId == cookie.load('selectedVehicle').vehicleId) return 'blue'
    else if (speed > 0) return '#78EF92';
    else return 'red'
  }
  const [followChanged, setFollowChanged] = useState(0)

  useEffect(() => {
    if (props.liveTracking) {
      Object.values(vehicleMarkers).forEach(marker => {
        let vehicleData = marker.get('vehicleData')
        marker.setIcon({
          path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          scale: 5,
          fillColor: getMarkerColor(vehicleData.speed, vehicleData.vehicleId),
          fillOpacity: 1,
          strokeColor: "black",
          strokeWeight: 2,
          rotation: vehicleData.heading || getAngle(vehicleData.direction)
        });
      })
    }
  }, [followChanged, vehicleMarkers])

  useEffect(() => {
    if (map) {
      if (props.type === 'dashboardMap' && props.liveTracking) {
        if (!showOldView && !(window?.$environment?.CURRENT_LAYOUT === 'OldUi')) {
          map.setOptions({ styles: nightMode });
        }
        const dashboardMarkers = () => {
          new window.google.maps.InfoWindow({
            content: ''
          });
          if (Array.isArray(dashboardData)) {
            let tmpMarkers = {};
            dashboardData.forEach(vehicleItem => {
              let marker;
              let ele = vehicleItem.data[0]
              if (vehicleMarkers[vehicleItem.vehicleId]) {
                marker = vehicleMarkers[vehicleItem.vehicleId]
                let destination = new window.google.maps.LatLng(ele.latitude, ele.longitude);
                marker.setPosition(destination);
                tmpMarkers[vehicleItem.vehicleId] = marker
                moveMarker(marker, vehicleItem.data)
              } else {
                let bounds = new window.google.maps.LatLngBounds()
                let tmpLatLng = new window.google.maps.LatLng(ele.latitude, ele.longitude);
                marker = new window.google.maps.Marker({
                  position: tmpLatLng,
                  map: map,
                  icon: {
                    path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                    scale: 5,
                    fillColor: getMarkerColor(ele.speed, ele.vehicleId),
                    fillOpacity: 1,
                    strokeColor: "black",
                    strokeWeight: 2,
                    rotation: ele.heading || getAngle(ele.direction)
                  }
                });
                marker.addListener('click', async (event) => {
                  let contentString = '';
                  let vehileData = marker?.get("vehicleData")
                  let followFlag = cookie.load('selectedVehicle')?.vehicleId == ele.vehicleId ? true : false
                  contentString = await dashboardVechileListPopUperHandler({
                    lat: marker.getPosition().lat(),
                    lng: marker.getPosition().lng(),
                    type: vehileData.type,
                    vehiclename: ele.vehicleName,
                    lastUpdatedTime: vehileData.lastUpdatedTime,
                    direction: vehileData.direction || 'NA',
                    speed: vehileData.speed,
                    vehiclelink: `/vehicle/edit/${ele.vehicleId}`,
                    isChecked: followFlag,
                    dtcPresent: ele?.dtcPresent,
                  });
                  let infowindow = new window.google.maps.InfoWindow({
                    content: contentString
                  });
                  if (followFlag && prev_tooltip) {
                    prev_tooltip.close()
                  }
                  if (prev_infowindow_dash) {
                    prev_infowindow_dash.close(); // close current info window if exists
                  }
                  prev_infowindow_dash = infowindow;
                  infowindow.open(map, marker);
                  window.google.maps.event.addListener(infowindow, "closeclick", () => {
                    // Perform your desired action when the InfoWindow is closed
                    let followFlag = cookie.load('selectedVehicle')?.vehicleId == ele.vehicleId ? true : false
                    if (followFlag && prev_tooltip) {
                      prev_tooltip.open(map, marker)
                    }
                  });
                  window.google.maps.event.addListener(infowindow, "domready", () => {
                    const followVehicle = document.getElementById("followVehicle");
                    followVehicle.addEventListener("change", (e) => {
                      if (followVehicle.checked) {
                        cookie.save('selectedVehicle', marker.get('vehicleData'), { path: '/' })
                        infowindow.close()
                        if (prev_tooltip) {
                          prev_tooltip.close()
                        }
                        const vehicleSvg = fleetIconMapping[ele?.type] ? `${fleetIconMapping[ele?.type]}.svg` : 'bobTail.svg'
                        let infowindowNew = new window.google.maps.InfoWindow({
                          content: `
                          <div id="content_tooltip">
                             <div class="tooltip__container--top">
                                <div id="content" class="alignCenter">
                                <img class="infoImg" src=${getImagePath('icons/' + vehicleSvg)} alt="name"/>
                                <a class="infoWindow-data vehicleId" href=${`/vehicle/edit/${ele.vehicleId}`} target="_blank">
                                ${ele?.vehicleName} 
                                <a>
                                </div>
                             </div>                    
                          </div>`
                        });
                        infowindowNew.open(map, marker);
                        prev_tooltip = infowindowNew
                      }
                      else {
                        cookie.save('selectedVehicle', null, { path: '/' })
                      }
                      setFollowChanged(Math.random() + Math.random())

                    });
                  });
                  smoothZoom(map, 0, map.getZoom(0));
                })
                bounds.extend(tmpLatLng);
                tmpMarkers[vehicleItem.vehicleId] = marker
                moveMarker(marker, vehicleItem.data)
              }
            })
            setVehicleMarkers(tmpMarkers)
          }
        }
        dashboardMarkers()
      }
    }
  }, [dashboardData])

  return (
    <MapWrapper height={props.height}>
      {props.type == 'dashboardMap' && props.liveTracking ? <LocationTracker onResponse={handleDashData} /> : null}
      <div id="map"></div>
    </MapWrapper>
  );
};

export default MyMapComponent;
