import React, { lazy, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import CircularProgress from '@material-ui/core/CircularProgress';

import Label from '../../../../../components/UI/FormLabel/Clutch';
import { phoneNumberValidation } from '../../../../../utils/utils';
import Styled from './style';
import { useTranslation } from 'react-i18next';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Input = lazy(() =>
  import(`../../../../../components/UI/Input/${layout}`)
);
const Message = lazy(() =>
  import(`../../../../../components/UI/Message/${layout}`)
);
const Button = lazy(() =>
  import(`../../../../../components/UI/Button/${layout}`)
);
const Select = lazy(() =>
  import(`../../../../../components/UI/Select/${layout}`)
);
const FormStyle = require(`../../../../../components/UI/Form/${layout}/serverStyle`);
const RenderComponent = ({
  values,
  handleEdit,
  errors,
  isNotValid,
  validateForm,
  customProps,
  submitAction
}) => {
  useEffect(() => {
    const isEmpty = !Object.values(values).some((x) => x !== null && x !== '');
    if (!isEmpty) {
      window.onbeforeunload = closeIt;
    }
  }, [values]);

  useEffect(() => {
    return () => {
      window.onbeforeunload = null;
    };
  }, []);
  const closeIt = () => {
    return 'Are you sure you want to leave?';
  };
  const { i18n } = useTranslation();
  const showError = (field, validationErrors, isNotValid, apiError) => {
    if (validationErrors[field] && !isNotValid) {
      return <Message type="error" message={errors[field]} />;
    }
    if (apiError?.[field]) {
      return <Message type="error" message={apiError[field]} />;
    }
  };
  const dotUpdation = (value) => {
    return value.substring(0, 9);
  };
  const trucksUpdation = (value) => {
    return value.substring(0, 3);
  };
  return (
    <Styled.Wrapper>
      <div className="companyWrapper">
        <Styled.FieldsWrapper>
          <Label variant="body1">Company Name</Label>
          <Styled.TextWrapper>
            <Field
              name="companyName"
              handleOnChange={(event) =>
                handleEdit(event, { field: 'companyName' })
              }
              as={Input}
              placeholder="Company Name"
              value={values['companyName']}
              type="text"
              className="borderStyle"
              id="companyInfoId"
              onLoadFocus={values['companyName'] ? false : true}
            />
            {showError(
              'companyName',
              errors,
              isNotValid,
              customProps.hasApiStatus?.errorMessage
            )}
          </Styled.TextWrapper>
        </Styled.FieldsWrapper>
      </div>

      <div className="addressWrapper">
        <div className="addressRow">
          <Styled.FieldsWrapper>
            <Label variant="body1">Address</Label>
            <Styled.TextWrapper>
              <Field
                name="address"
                handleOnChange={(event) =>
                  handleEdit(event, { field: 'address' })
                }
                as={Input}
                placeholder="Type Address"
                value={values['address']}
                type="text"
              />
              {showError(
                'address',
                errors,
                isNotValid,
                customProps.hasApiStatus?.errorMessage
              )}
            </Styled.TextWrapper>
          </Styled.FieldsWrapper>
          <Styled.FieldsWrapper className="stateSelect">
            <Label variant="body1">State</Label>
            <Styled.TextWrapper className="dropdown">
              <Field
                name="state"
                onChange={(event) => {
                  handleEdit(event, { field: 'state' });
                }}
                as={Select}
                placeholder="Select State"
                value={values['state']}
                suggestions={customProps?.states}
              />
              {showError(
                'state',
                errors,
                isNotValid,
                customProps.hasApiStatus?.errorMessage
              )}
            </Styled.TextWrapper>
          </Styled.FieldsWrapper>
        </div>
        <div className="addressRow">
          <Styled.FieldsWrapper>
            <Label variant="body1">City</Label>
            <Styled.TextWrapper>
              <Field
                name="city"
                handleOnChange={(event) => handleEdit(event, { field: 'city' })}
                as={Input}
                placeholder="Type City"
                value={values['city']}
                type="text"
              />
              {showError(
                'city',
                errors,
                isNotValid,
                customProps.hasApiStatus?.errorMessage
              )}
            </Styled.TextWrapper>
          </Styled.FieldsWrapper>
          <Styled.FieldsWrapper>
            <Label variant="body1">Zip Code</Label>
            <Styled.TextWrapper>
              <Field
                name="zipCode"
                handleOnChange={(event) =>
                  handleEdit(event, { field: 'zipCode' })
                }
                as={Input}
                placeholder="Enter Zip Code"
                value={values['zipCode']}
                type="text"
              />
              {showError(
                'zipCode',
                errors,
                isNotValid,
                customProps.hasApiStatus?.errorMessage
              )}
            </Styled.TextWrapper>
          </Styled.FieldsWrapper>
        </div>
      </div>
      <div className="phoneWrapper">
        <Styled.FieldsWrapper>
          <Label variant="body1">Phone #</Label>
          <Styled.TextWrapper>
            <Field
              name="companyPhone"
              handleOnChange={(event) =>
                handleEdit(event, { field: 'companyPhone' })
              }
              as={Input}
              placeholder="Type Phone Number"
              value={values['companyPhone']}
              type="text"
              updateValue={phoneNumberValidation}
            />
            {showError(
              'companyPhone',
              errors,
              isNotValid,
              customProps.hasApiStatus?.errorMessage
            )}
          </Styled.TextWrapper>
        </Styled.FieldsWrapper>
      </div>
      <div className="bottomWrapper">
        <Styled.FieldsWrapper>
          <Label variant="body1">DOT Number</Label>
          <Styled.TextWrapper>
            <Field
              name="dotNumber"
              handleOnChange={(event) =>
                handleEdit(event, { field: 'dotNumber' })
              }
              as={Input}
              placeholder="Type DOT Number"
              value={values['dotNumber']}
              type="text"
              updateValue={(e) => dotUpdation(e)}
            />
            {showError(
              'dotNumber',
              errors,
              isNotValid,
              customProps.hasApiStatus?.errorMessage
            )}
          </Styled.TextWrapper>
        </Styled.FieldsWrapper>
        <Styled.FieldsWrapper>
          <Label variant="body1"># Of Trucks</Label>
          <Styled.TextWrapper>
            <Field
              name="initialTrucks"
              handleOnChange={(event) =>
                handleEdit(event, { field: 'initialTrucks' })
              }
              as={Input}
              placeholder="Type Number of Trucks"
              value={values['initialTrucks']}
              type="text"
              updateValue={(e) => trucksUpdation(e)}
              id="initialTrucks"
              onLoadFocus={values['companyName'] ? true : false}
            />
            {errors['initialTrucks'] && !isNotValid && (
              <Message type="error" message={errors['initialTrucks']} />
            )}
          </Styled.TextWrapper>
        </Styled.FieldsWrapper>
      </div>

      <FormStyle.default.ButtonsWrapper>
        <Button
          id="companyInfoSubmit"
          type="submit"
          label={
            customProps?.hasApiStatus?.isLoading ? (
              <CircularProgress color="inherit" />
            ) : (
              'Next'
            )
          }
          onClick={() => {
            const validateResponse = validateForm();
            validateResponse.then((data) => submitAction(data));
          }}
        />
      </FormStyle.default.ButtonsWrapper>
    </Styled.Wrapper>
  );
};

export default RenderComponent;

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  customProps: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};
