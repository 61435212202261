import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CalendarToday from '@material-ui/icons/CalendarToday';
import { Field } from 'formik';
import ReactDatePicker from 'react-datepicker';
import { isDriver, getDateLocale } from "@/utils/utils";
import { UI_DATE_LOWER_FORMAT } from '@/config/constants/static';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Select = lazy(() => import(`@/components/UI/Select/${layout}`));
const Label = lazy(() => import(`@/components/UI/Label/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const RenderComponent = ({
  values,
  handleEdit,
  errors,
  isNotValid,
  validateForm,
  customProps,
  submitAction
}) => {
  const { i18n } = useTranslation();
  const fields = {
    driverId: {
      type: 'select',
      value: '',
      field: 'driverId',
      placeholder: i18n.t('common.driver'),
      label: window.$environment.CURRENT_LAYOUT === 'SpireonNew' ? 'Select User' : ''
    },

    date: {
      type: 'date',
      value: '',
      field: 'date',
      placeholder: UI_DATE_LOWER_FORMAT,
      label: window.$environment.CURRENT_LAYOUT === 'SpireonNew' ? 'Date' : ''
    }
  };
  return (
    <>
      {customProps.hasApiStatus?.errorMessage && (
        <Message
          type="error"
          message={i18n.t(customProps.hasApiStatus.errorMessage)}
        />
      )}
     {!isDriver() && 
      <FormStyle.default.FieldsWrapper className="vertical" width="32%">
        <Label variant="body1">{fields.driverId.label}</Label>
        <FormStyle.default.TextWrapper>
          <Field
            name={fields.driverId.field}
            onChange={(event) => {
              handleEdit(event, fields.driverId);
              customProps.setFormValues({
                ...customProps.formValues,
                driverId: event.value,
                driverName: event.label
              });
              customProps.fetchLogBookView({
                apiId: 'fetch',
                data: { data: { driverId: event.value } }
              });

              if(event.vehicleId)
               customProps.fetchLogbookBreadcrumb({
                 apiId: customProps.fetchLogbookBreadcrumbApiId,
                 data: {
                   id: event.vehicleId,
                   day: values.date || customProps.currentDate
                 }
               });
            }}
            as={Select}
            placeholderText={
              fields.driverId.placeholder ? fields.driverId.placeholder : ''
            }
            value={values.driverId}
            type={fields.driverId.label}
            suggestions={customProps.drivers}
          />

          {errors['driverId'] && !isNotValid && (
            <Message type="error" message={errors['driverId']} />
          )}
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>}

      <FormStyle.default.FieldsWrapper className="vertical verticalCal" width="32%">
        <Label variant="body1">{fields.date.label}</Label>
        <FormStyle.default.TextWrapper>
          <Field
            name={fields.date.field}
            onChange={(event) => handleEdit(event, fields.date)}
            as={ReactDatePicker}
            locale={getDateLocale()}
            placeholderText={
              fields.date.placeholder ? fields.date.placeholder : ''
            }
            value={values.date}
            type={fields.date.label}
            showPopperArrow={false}
            showTimeSelect={fields.date.isTimeNeeded}
            // maxDate={new Date()}
            showDisabledMonthNavigation
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            includeDates={customProps.dates}
            highlightDates={customProps.unSignedDates}
          />
           {window.$environment.CURRENT_LAYOUT === 'SpireonNew' && (
            <CalendarToday />
          )}
          {errors['date'] && !isNotValid && (
            <Message type="error" message={errors['date']} />
          )}
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>

      <FormStyle.default.ButtonsWrapper className="vertical-button">
        <Button
          type="submit"
          label={i18n.t('common.view')}
          onClick={(e, d) => {
            const validateResponse = validateForm();
            validateResponse.then((data) => {
              submitAction(data);
            });
          }}
        />
      </FormStyle.default.ButtonsWrapper>
    </>
  );
};

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  hasApiStatus: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default RenderComponent;
