import styled from 'styled-components';
import Parent from './style';

const ExportWrapper = styled(Parent.ExportWrapper)``;
const Header = styled(Parent.Header)`
${({ theme }) => `
flex-direction: row;
padding-left: 15px;
padding-top: 15px;
position: relative;
justify-content: flex-start;
.udcheckWrapper{
  flex: 0 0 20%;
  padding: 0;
  padding-bottom: 0 !important;
  .MuiFormControlLabel-root{
    min-width: 223px;
    .MuiFormControlLabel-label {
      font-size: 12px !important;
      color: #848c96;
    }
  } 
}
.filterForm{
    padding-top: 0;
    p {
        color: ${theme.colors.PRIMARY_CONTRAST} !important;
        font-size: 14px !important;
        font-family: tahoma;
    }
} 
div[type='text'],
div[type='date'] {
  width: 19% !important;
}
.exportWrapper {
  padding-top: 3px;
  padding-bottom: 30px !important;
  justify-content: flex-start;
  .exportWrapper {
    color: #2d3942;
    font-size: 12px;
    font-weight: bold;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
`}
`;
const InfoContainer = styled(Parent.InfoContainer)`
border-top: 2px solid #000;
padding-top: 0;
padding-right: 13px;
.seperator {
  justify-content: flex-end;
  flex-direction: column;
  p:first-child {
    color: #848c96;
    font-size: 14px;
    font-family: tahoma;
    text-align: right;
  }
  :first-child {
    padding-top: 0;
  }
}
p:last-child {
  font-size: 18px;
}
`;
const ToolsContainer = styled(Parent.ToolsContainer)`
${({ theme }) => `
border: 1px solid #ddd;
background: ${theme.colors.WHITE};
right: 0%;
padding-bottom: 10px;
a {
  font-weight: bold;
  font-size: 14px;
}
`}
`;
const UdLists = styled(Parent.UdLists)``;
const Arrowdown = styled(Parent.Arrowdown)`
.udlogExpandedtable{
  border-top: 1px solid #ddd;
  background: #fff;
  form{
    padding: 0;
  }
  button.MuiButtonBase-root{
    font-size: 12px;
    padding-left:0;
  }
  .MuiTableRow-head {
      border-bottom: 1px solid #ddd;
    .MuiTableCell-root {
        border-width: 0 1px 1px 0;
        vertical-align: middle;
        border-color: #ededed;
        border-style: solid;
      }
  }
  .MuiTableHead-root .MuiTableRow-root,
  .MuiTableBody-root tr:nth-child(odd){
    background-color: #fff;
  }
}


`;
const UdlogTable = styled(Parent.UdlogTable)`
${({ theme }) => `
#udLogTable {
  .expanded img {
    transform: rotate(360deg) !important
  }
    .MuiTableRow-root td:first-child {
        span {
            padding-left: 28px;
        }
        .MuiGrid-root {
            position: absolute;
            left: 10px;
            background-color: #4a4a4a;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            opacity: 0.54;
            img {
                width: 12px;
                height: 10px;
                object-fit: contain;
                object-position: bottom;
                transform: rotate(-90deg);
            }
        }
    }
    .MuiButtonBase-root span {
      padding-left: 0 !important;
    }
}
.spireonOldOnly {
    display: block;
    color: #42505a;
    font: bold 12px "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding: 10px;
    padding-left: 10px;
    background: -webkit-linear-gradient(top, #d0d8db 0%, #b3bec3 100%);
    align-items: center;
}
.MuiTable-root .MuiTableBody-root tr:hover, 
.MuiTable-root .MuiTableBody-root tr {
//   background-color: ${theme.colors.WHITE} !important;
}
`}
`;
const UnidentifiedSection = styled(Parent.UnidentifiedSection)`
${({ theme }) => `
.MuiTableHead-root .MuiTableCell-root {
  font-size: 12px !important;
}
  .MuiPaper-root .MuiBox-root:first-child {
    overflow-x: initial;
  }
  .select__option{
    font-size: 12px !important;
  }
  .Selectclass .select__control {
    color: #373737 !important;
    border: 0;
    height: 20px;
    min-height: 20px !important;
    max-height: 20px;
  }
  .Selectclass,
  .MuiInputBase-root{
    border: 1px solid #d0d0d0 !important;
  }
  input {
    padding-left: 2px !important;
  }
  .saveButton{
    justify-content: flex-start;
    border-top: 1px solid #ddd;
    border-bottom: 2px solid #ddd;
    padding-bottom: 4px;
    padding-left: 5px;
  }
  #button{
    padding: 0;
  }
  .select--is-disabled{
    pointer-events: auto;
  }
  .MuiInputBase-root.Mui-disabled{
    opacity: 0.8;
    cursor: no-drop;
  }
  .select__control.select__control--is-disabled:hover {
    cursor: no-drop;
  }
  .MuiInputBase-input.Mui-disabled{
    opacity: 1 !important;
    :hover {
      cursor: no-drop;
    }
  }
  input:focus{
    border: 0 !important; 
  }
  input,
  .MuiInputBase-root {
    font-size: 12px !important;
  }
.Selectclass{
  .select__control .select__single-value {
    font-size: 12px;
    top: 47%;
  }
  .select__placeholder{
    top: 43%;
    font-size: 12px !important;
  }
}
`}
`;
const Content = styled(Parent.Content)`
${({ theme }) => `
.logwrapper {
    .MuiPaper-root{
      padding-bottom: 0;
    }
    .MuiTabs-root{
        padding-left: 0;
    }
    .MuiTab-root {
        min-width: 100px;
        min-height: 23px;
    }
    .MuiButtonBase-root.MuiTab-root{
      padding: 0;
      margin-left: 20px;
      line-height: 1;
      margin-right: 1%;
    }
    .MuiTabs-fixed{
      padding-top: 15px;
      padding-bottom: 10px;
    }
  }
  header {
    border-bottom: 2px solid ${theme.colors.BLACK};
    box-shadow: none;
  }
`}
`;

const Styled = {
    ExportWrapper,
    Header,
    InfoContainer,
    ToolsContainer,
    UdLists,
    Arrowdown,
    UdlogTable,
    UnidentifiedSection,
    Content
  };
  
  export default Styled;