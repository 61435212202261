import styled from 'styled-components';
import Parent from '../Base/style';

const InputWrapper = styled(Parent.InputWrapper)`
border:0;
border-bottom: 1px solid rgb(211, 215, 219) !important;
height: 40px;
:focus,
:active {
    border-bottom: 1px solid #000 !important;
}
.MuiInputBase-input {
    padding-left: 0;
    font-size: 16px !important;
    :focus,
    :active {
        border-bottom: 1px solid #000 !important;
        height: 26px;
    }
}
`;
const TextAreaWrapper = styled(Parent.TextAreaWrapper)`
border:0;
border-bottom: 1px solid rgb(211, 215, 219) !important;
`;
const MainWrapper = styled(Parent.MainWrapper)``;
const Styled = { InputWrapper, TextAreaWrapper, MainWrapper };
export default Styled;
