import {
  potentialViolationsListApi,
  exportAllpotentialViolationsApi,
  deletepotentialViolationsApi,
  potentialViolationsClearApi,
  exportCsvViolationApi,
  exportPdfViolationApi
} from '../../../../config/apis';
import { deleteJsonArrayItemByField } from '../../../../utils/utils';

const initialState = {
  list: [],
  filteredList: [],
  selectedRow: { index: -1, row: {} }
};
const isEs = () => localStorage.getItem(window.$environment?.SERVICE_PROVIDER + 'Language') == 'es'
export const potentialViolations = {
  state: { ...initialState },
  reducers: {
    listSuccess(state, payload) {
      return { ...state, list: [...payload], filteredList: [...payload] };
    },
    listFailed() {
      return { ...initialState };
    },
    filterSuccess(state, list) {
      return { ...state, filteredList: [...list] };
    },
    setSelectedItem(state, data) {
      return { ...state, selectedRow: { ...data } };
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: potentialViolationsListApi
          },
          id: payload.apiId
        });
        response && this.listSuccess(response);
      } catch (error) {
        this.listFailed();
      }
    },

    async exportAllData(payload, rootState) {
      try {
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: exportAllpotentialViolationsApi
          },
          id: payload.apiId
        });
      } catch (error) {}
    },
    async deleteData(payload, rootState) {
      try {
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...deletepotentialViolationsApi,
              url: deletepotentialViolationsApi.url.replace(
                '{id}',
                payload.data.data.id
              )
            }
          },
          callback: payload.callback,
          id: payload.apiId
        });

        this.listSuccess(
          deleteJsonArrayItemByField(
            rootState.potentialViolations.list,
            'id',
            payload.data.data.id
          )
        );
        //remove item
        payload.callback('success', isEs() ? 'Infracción ha sido borrada' : 'Violation has been deleted.');
      } catch (error) {}
    },
    async clearAll(payload, rootState) {
      try {
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: potentialViolationsClearApi
          },
          callback: payload.callback,
          id: payload.apiId
        });
        dispatch.dashboard.clearPotentialViolationData();
      } catch (error) {
        this.listFailed();
      }
    },
    async exportCsvData(payload, rootState) {
      try {
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: exportCsvViolationApi
          },
          id: payload.id
        });
      } catch (error) {}
    },
    async exportPdfData(payload, rootState) {
      try {
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...exportPdfViolationApi,
              url: exportPdfViolationApi.url.replace(
                '{docType}',
                payload.docType
              )
            }
          },
          id: payload.id
        });
      } catch (error) {}
    }
  })
};
