import React, { lazy } from 'react';

import { getImagePath } from '@/utils/utils';
import { imageUrls, pageUrls } from '@/config/constants/keys';
import { Link as LinkLi } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { hasRestrictedRole } from '@/utils/utils';
import { ROLES } from '@/config/constants/keys';
const layout = window?.$environment?.CURRENT_LAYOUT;

const Search = lazy(() => import(`@/components/UI/SimpleSearch/${layout}`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const Breadcrumb = lazy(() => import(`@/components/Breadcrumb`));

const TopActions = ({
  fleetCompanies,
  filterSuccess,
  Styled,
  i18n,
  setPagination,
  history,
  filters,
  removeData,
  setremoveData,
  viewExportOptions
}) => {

  const selectChange = (e) => {
    filterSuccess(e);
    setPagination(true);
    setremoveData('false');
  };

  return (
    <Styled.Wrapper>
      {layout !== 'OldUi' && <Breadcrumb filters={filters} />}
      <Styled.TopWrapper alignRight={!viewExportOptions}>
        <Styled.SearchWrapper>
          <Search
            id={'clearSearch'}
            onChange={(e) => selectChange(e)}
            default={[...fleetCompanies.list]}
            fromData
            placeHolder={i18n.t('companies.searchCompanies')}
            query={'name'}
            id="searchUser"
            onRemove={removeData}
          ></Search>
        </Styled.SearchWrapper>
        <Styled.IconWrapper>
          <LinkLi component={Link} to= {pageUrls.addCompanies} className="userNew">
            <Icon src={getImagePath(imageUrls.addVehicle)} />
          </LinkLi>
        </Styled.IconWrapper>)
      </Styled.TopWrapper>
    </Styled.Wrapper>
  );
};
export default TopActions;
