import React, { useEffect, lazy, useState } from 'react';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import RenderComponent from '@/components/modules/DynamicRenderPage';

import ParentStyled from './style';
import { loadStyle, loadPageConfig, isInsuracePortalUser, getQueryStringKeys } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'pages/web/QuickStats');

const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.quickStats[0].name}`));
const Content = lazy(() => import(`@/${config.quickStats[1].name}`));

const QuickStats = (parentProps) => {
  const insuracePortalUserRole = isInsuracePortalUser();
  const { fetchQuickStats } = parentProps;
  const [pagination, setPagination] = useState(false);
  const basicRequest = {
    displayLength: '15',
    displayStart: '1'
  };
  const history = useHistory();

  const [request, setRequest] = useState({
    ...basicRequest,
  });
  const theme = useTheme();
  const i18n = useTranslation();
  const commonParentProps = { history, i18n, Styled, theme };
  const fetchPaginateApi = 'fetchQuickStatsApi'

  const sortColumnMapping = {
    unitId: "vehicleName",
    work_order_id: "idPub",
    type: "unitType",
    reported_date: "createDate",
    scheduled_date: "beginDate",
    due_date: "endDate",
    workOrderType: "type",
    status: "state"
  }
  const changeRequest = (fields) => {
    if (fields?.sortCol) {
      const type = fields.sortCol
      fields.sortCol = sortColumnMapping[type] ? sortColumnMapping[type] : fields.sortCol
    }
    setRequest({ ...basicRequest, ...request, ...fields });
  };

  useEffect(() => {
    let req = JSON.parse(JSON.stringify(request))
    delete req.period
    fetchQuickStats({
      apiId: fetchPaginateApi,
      data: {
        request: req,
        period: request.period || getQueryStringKeys(history.location.search, 'period'),
        event: getQueryStringKeys(history.location.search, 'event'),
        companyId: getQueryStringKeys(history.location.search, 'companyId')
      }
    });
  }, [request]);

  return (
    <>
      <RenderComponent
        {...config.quickStats[0]}
        parentProps={{
          ...parentProps,
        }}
        commonParentProps={commonParentProps}
        component={Title}
      />
      <RenderComponent
        {...config.quickStats[1]}
        parentProps={{
          ...parentProps,
          changeRequest,
          request,
          pagination,
          setPagination
        }}
        commonParentProps={commonParentProps}
        component={Content}
        pagination={pagination}
        setPagination={setPagination}
      />
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    paginateList: state.scoreCard.quickStatsList,
    filters: state.filters,
  };
};

const mapDispatch = (dispatch) => {
  return {
    fetchQuickStats: (data) => dispatch.scoreCard.fetchQuickStatsList(data)
  };
};

export default connect(mapState, mapDispatch)(QuickStats);
