import styled from 'styled-components';
import * as Parent from '../Base/style';

export const ChatWrapper = styled(Parent.ChatWrapper)`
${({ theme }) => `
.talk-bubble.sb1:before {
    content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid #1b1b1b;
  border-right: 7px solid transparent;
  border-top: 7px solid #1b1b1b;
  border-bottom: 10px solid transparent;
  right: -8px;
  top: 18px;
  transform: rotate(133deg);
  z-index: 10;
}
`}
`;
