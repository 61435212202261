import styled from 'styled-components';
import Parent from '../Base/style';
const Container = styled(Parent.Container)`
.MuiTableSortLabel-active .MuiTableSortLabel-iconDirectionAsc,
  .MuiTableSortLabel-active .MuiTableSortLabel-iconDirectionDesc {
    left: -8px;
  
  }
`;
const TableContainer = styled(Parent.TableContainer)``;
const PaginationContainer = styled(Parent.PaginationContainer)``;

const Styled = { Container, TableContainer, PaginationContainer };

export default Styled;
