import * as Base from '../base';

export const dashboard = Base.dashboard;
export const login = Base.login;
export const userList = Base.userList;
export const userDriverStatus = Base.userDriverStatus;
export const addUser = Base.addUser;
export const logsView = Base.logsView;
export const editUser = Base.editUser;
export const vehicleStatusViewMore = Base.vehicleStatusViewMore;
export const vehicleList = Base.vehicleList;
export const cameraList = Base.cameraList;
export const addVehicle = Base.addVehicle;
export const editVehicle = Base.editVehicle;
export const driverStatusViewMore = Base.driverStatusViewMore;
export const companyDataEdit = Base.companyDataEdit;
export const profile = Base.profile;
export const resetPassword = Base.resetPassword;
export const vehicleNotInUse = Base.vehicleNotInUse;
export const alertViewMore = Base.alertViewMore;
export const potentialViolationsViewMore = Base.potentialViolationsViewMore;
export const accidentsList = Base.accidentsList;
export const downloadCenter = Base.downloadCenter;
export const manageScheduling = Base.manageScheduling;
export const driverStatusHistory = Base.driverStatusHistory;
export const recovery = Base.recovery;
export const MFAPage = Base.MFAPage;
export const registration = Base.registration;
export const logsList = Base.logsList;
export const logsUdList = Base.logsUdList;
export const logBook = Base.logBook;
export const logsHistory = Base.logsHistory;
export const reportsEnabled = Base.reportsEnabled;
export const unhandled = Base.unhandled;
export const subscriptionMessage = Base.subscriptionMessage;
export const logsEdit = Base.logsEdit;
export const policyEdit = Base.policyEdit;
export const dailyDocuments = Base.dailyDocuments;
export const workOrders = Base.workOrders;
export const advancedDvirEnabled = Base.advancedDvirEnabled;
export const fuelConsumptionReport = Base.fuelConsumptionReport;
export const refillsHistory = Base.refillsHistory;
export const companyDocument = Base.companyDocument;
export const usDotManagementenabled = Base.usDotManagementenabled;
export const privacyPolicy = Base.privacyPolicy;
export const addWorkOrders = Base.addWorkOrders;
export const reportMileage = Base.reportMileage;
export const iftaList = Base.iftaList;
export const addState = Base.addState;
export const editState = Base.editState;
export const departmentsList = Base.departmentsList;
export const editLog = Base.editLog;
export const editLog1 = Base.editLog1;
export const editWorkOrder = Base.editWorkOrder;
export const inspections = Base.inspections;
export const subscriptionLink = Base.subscriptionLink;
export const hardAcceleration = Base.hardAcceleration;
export const hardDeceleration = Base.hardDeceleration;
export const speeding = Base.speeding;
export const driveHrsExceeding = Base.driveHrsExceeding;
export const fuelEconomy = Base.fuelEconomy;
export const seatBeltWarning = Base.seatBeltWarning;
export const hardTurn = Base.hardTurn;
export const outOfService = Base.outOfService;
export const csaViolation = Base.csaViolation;
export const paymentInformation = Base.paymentInformation;
export const partsInventoryEnabled = Base.partsInventoryEnabled;
export const archiveLogsList = Base.archiveLogsList;
export const customDvirEnabled = Base.customDvirEnabled;
export const addOrEditNewForm = Base.addOrEditNewForm;
export const addOrEditNewFormTrailer = Base.addOrEditNewFormTrailer;
export const privacyPolicyScreen = Base.privacyPolicyScreen;
export const vehicleDTCView = Base.vehicleDTCView;

