import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import RenderComponent from './RenderComponent';

import {
  objectArrayFromMultipleFields,
  isdvirOnly
} from '@/utils/utils';
import { pageUrls } from '@/config/constants/keys';

import Styled from './style';
const layout = window?.$environment?.CURRENT_LAYOUT;

const Form = lazy(() => import(`@/components/UI/Form/${layout}`));

const Component = ({
    callback,
    currentItemId,
    serviceCenters,
    reAssignrServiceCenter,
    setOpenAssignWOForm,
    history,
    currentItemState
}) => {
  const { i18n } = useTranslation();
  const serviceCenterCallback = (item, value, field) => {
      return {
        label: item[field],
        value: item[value]
      };
  };
  const serviceCenterList = objectArrayFromMultipleFields(
    serviceCenters,     
    'id',
    'name',
    serviceCenterCallback
  );

  return (
    <Styled.Wrapper>
      <Form
        type="server"
        direction="column"
        initialValues={{
          workOrderId: currentItemId || '',
          serviceCenterId: '',
        }}
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {
          let finalData = {
            ...fields
          };
          finalData.serviceCenterId = fields.serviceCenterId.value
          let url = pageUrls.workOrders
          if (isdvirOnly()){
            url = pageUrls.pendingMechanicInspections;
            if(currentItemState && ["COMPLETED"].includes(currentItemState)) url = pageUrls.pendingDriverInspections;
            if(currentItemState && ["CLOSED", "APPROVED"].includes(currentItemState)) url = pageUrls.completedInspections;
            }
          reAssignrServiceCenter({
            apiId: '',
            data: {
              ...finalData
            },
            i18n: i18n,
            callback,
            history,
            url
          });
          setOpenAssignWOForm(false)
        }}
        customProps={{serviceCenterList }}
        handleClear={() => {}}
        validationSchema={Yup.object().shape({
            serviceCenterId: Yup.string().required(
            i18n.t('common.fieldRequiredMessage')
          )
        })}
      />
    </Styled.Wrapper>
  );
};

export default Component;

Component.propTypes = {
  apiStatus: PropTypes.object,
  callback: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  saveRegion: PropTypes.func,
  currentItemId: PropTypes.object
};
