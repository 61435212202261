import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Parent from './style';
const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
padding: 0;
padding-bottom: 3%;
.Forem {
    display: block;
}
.Forgs {
    display: none;
}
.deleteParts {
  padding-right: 4.5%;
}
.noteSection {
    // flex: 0 0 100%;
    flex-direction: row;
  }
  .paddingLeft{
    padding-left: 3%;
  }
  .woNew{
    display: none;
  }
  .woOld{
    display: flex;
  }
  .workOrderIdWrapper{
    padding-right: 2%;
  }
  .woInspection {
    color: #6d6d6d !important;
    border: 2px solid #ccc;
    background: #efefef none repeat scroll 0 0 !important;
    margin-bottom: 16px;
    width: 60%;
    margin: 0 auto;
    padding:0 !important;
    margin-bottom: 10px;
  }
  .editInspection {
    border: 0;
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
    .detailSection {
      width: 49%;
      padding-left: 0;
      padding-top: 0;
      border-right: 0;
    }
    .inspectionView {
      width: 49%;
      align-self: flex-start;
      .iconContainer {
        justify-content: center;
        padding-bottom: 14px;
        .MuiAvatar-root {
          width: 30px;
          height: 30px;
        }
      }
    }
    .addedValues {
      display : flex;
      padding-left: 6%;
      padding-bottom: 10px;
      .labels {
        flex: 0 0 54%;
        font-size: ${theme.palette.titleLabel};
      }
      .values {
        color: ${theme.colors.DIABLED_COLOR};
        font-size: 18px;
        flex: 0 0 auto;
      }
    }
    .viewworeport{
      margin-top: 25%;
      padding: 3px 13px !important;
      line-height: 1.3;
    }
  }
  .mechanic_disable {
    pointer-events:none;
  }
  `}
`;
const LeftWrapper = styled(Parent.LeftWrapper)`
flex: 0 0 49.5%;
.statusHeader{
  margin-bottom: 0 !important;
}
.statusSection {
    justify-content: flex-start;
    padding: 2% 0 1%;
    ul{
      padding: 0 2%;
    }
}
`;
const RightWrapper = styled(Parent.RightWrapper)`
flex: 0 0 49.5%;
.Formechanic{
  display: flex;
}
.detailSection {
    display: flex;
    flex-direction: row;
    padding-right: 4%;
    .fieldWrapper {
      flex-direction: row;
      flex: 0 0 100%;
    }
  }
`;
const documentWrapper = styled(Parent.documentWrapper)`
${({ theme }) => `

`}
`;
const submitUser = styled(Parent.submitUser)`
${({ theme }) => `
.submitSection {
  justify-content: center;
  padding-top: 1%;
  button {
    min-width: 210px;
  }
}
`}
`;
const PolicyProcedure = styled(Parent.PolicyProcedure)`
`;
const Styled = {
  Wrapper,
  RightWrapper,
  LeftWrapper,
  submitUser,
  documentWrapper,
  PolicyProcedure
};
export default Styled;
