import styled from 'styled-components';
import Parent from '../Base/style';

const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
.MuiExpansionPanelDetails-root,
.MuiPaper-root {
    background: #CACACA;
    border-bottom: 1px solid ${theme.colors.PRIMARY_MAIN} !important;
}
.MuiButtonBase-root:active,
  .MuiButtonBase-root:hover {
    background-color: ${theme.colors.LINK_COLOR};
  }
  
.MuiButtonBase-root,
  .MuiExpansionPanelSummary-root.Mui-expanded {
    border-bottom: 1px solid  ${theme.colors.PRIMARY_MAIN};
  }
  .MuiExpansionPanelSummary-expandIcon {
    border-bottom: 0;
    position: absolute;
    right: 51px;
    }
  .MuiExpansionPanelSummary-content div {
    justify-content: space-between;
    align-items: center;
  }
  .wrapperdetails .MuiExpansionPanelSummary-content{
    position: relative;
  }
  .toolsDepartment{
    width: 20px;
    height: 20px;
  }
  
  .departmentToolsection{
    position: absolute;
    z-index: 3;
    top: 39px;
    right: -15px;
    border: 1px solid #ddd;
    min-width: 200px;
    ul {
      border:0;
      margin:0 !important;
      padding: 25px;
      height: auto;
      width: auto;
      a{
        font-size: 22px;
      }
    }
  }
  .locationDetails{
    right: 0;
  }
`}
`;
const Header = styled(Parent.Header)`
.terminal {
  font-size: 22px;
}
`;
const Content = styled(Parent.Content)`
  padding-right: 16px;
  font-size: 22px;
  background-color: #e8e8e8;
`;
const Accordion = { Wrapper, Header, Content };

export default Accordion;
