import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import {colors, fonts, secondaryFont, headerFont, subTitle, tableFont, titleLabel, formlabelFont, buttonFont, checkboxFont, inputFont, addbuttonFont, companyDataFont, iftaReport, inspectionInput, inspectionresolution} from './variables';

import parentTheme from '../';
const theme = createMuiTheme({
  ...parentTheme({ colors, fonts, secondaryFont, headerFont, subTitle, tableFont, titleLabel, formlabelFont, buttonFont, checkboxFont, inputFont, addbuttonFont, companyDataFont, iftaReport, inspectionInput, inspectionresolution }),
  palette: {
    ...parentTheme({ colors, fonts, secondaryFont, headerFont, subTitle, tableFont, titleLabel, formlabelFont, buttonFont, checkboxFont, inputFont, addbuttonFont, companyDataFont, iftaReport, inspectionInput, inspectionresolution }).palette,
    contentStyle: {
      minHeight: 'calc(100vh - 181px)',
      padding: '0',
      backgroundColor: colors.BODY_BACKGROUND
    },
    contentlogsStyle: {
      minHeight: 'calc(100vh - 181px)',
      padding: '0',
      backgroundColor: colors.BODY_BACKGROUND
    },
    footerTheme: {
      flexDirection: 'column',
      minHeight: '130px',
      height: 'auto',
      justifyContent:'flex-start'
    },
    footerPadding: {
      paddingLeft: '0',
      padding: '20px 0',
      marginTop: '0',
      justifyContent:'center',
      fontFamily: 'tahoma'
    },
    footerAlign: {
      paddingRight: '10px',
      textAlign: 'left'
    },
    footerWidth: {
      justifyContent: 'center',
      width: '100%',
      fontSize: '16px',
      fontFamily: 'tahoma'
    },
    oldLogo: {
      top:'-5px',
      width: 'auto',
      maxWidth: '100%',
      left: '3%'
    },
    registrationtrackon: {
      justifyContent:'space-between'
    }
  }

});

export default theme;
