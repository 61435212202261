import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
  ${({ theme }) => `
  .MuiTypography-body1 {
    font-size: 18px;
  }
  .filepond--wrapper {
    .filepond--drop-label label {
      color: ${theme.colors.PRIMARY_CONTRAST};
      opacity: 1;
    }
  }
  form {
    div:last-child {
      justify-content: center;
      #button {
        width: 210px;
        margin-top: 0;
      }
    }
  }
  div[type='error'] p {
    font-size: 16px;
  }
  `}
`;
const UploadWrapper = styled(Box)``;
const PopupUpload = styled(Box)``;
const Styled = { Wrapper, UploadWrapper, PopupUpload };

export default Styled;
