import { isCRMImport } from '@/utils/utils';
import i18next from 'i18next';
import { get } from 'lodash';
import {
  crmImportVehicleApi,
  crmImportVehicleApiPost,
  crmImportVehicleBulkDelete,
  exportVehicleApi,
  fleetVehiclesApi,
  fleetVehiclesRadiusApi,
  getDriverNamesApi,
  importTrailerApi,
  importTrailerApiPost,
  importVehicleApi,
  importVehicleApiPost,
  trailerDeleteApi,
  vehicleDeleteApi,
  vehicleListApi,
  vehicleListDropDownApi
} from '../../../../config/apis';

const initialState = {
  vehicleList: [],
  vehicleListDropDown: [],
  vehicleFilteredList: [],
  trailerList: [],
  fleetVehicleList: [],
  fleetVehicleRadiusList: [],
  trailerFilteredList: [],
  infoBlockItemsList: [],
  infoBlockItemsFilteredList: [],
  driverNames: [],
  selectedRow: { index: -1, row: {} }
};

export const vehicles = {
  state: { ...initialState },
  reducers: {
    listSuccess(state, payload) {
      return {
        ...state,
        vehicleList: [...payload.vehicles],
        vehicleFilteredList: [...payload.vehicles],
        trailerList: [...payload.trailers],
        trailerFilteredList: [...payload.trailers],
        infoBlockItemsList: [...(payload.infoBlockItems ? payload.infoBlockItems : [])],
        infoBlockItemsFilteredList: [...(payload?.infoBlockItems ? payload?.infoBlockItems : [])]
      };
    },
    fleetListSuccess(state, payload) {
      return {
        ...state,
        fleetVehicleList: [...payload]
      }
    },
    fleetRadiusListSuccess(state, payload) {
      return {
        ...state,
        fleetVehicleRadiusList: [...payload]
      }
    },
    clearRadiusList(state, payload) {
      return {
        ...state,
        fleetVehicleRadiusList: []
      }
    },
    listFailed() {
      return { ...initialState };
    },
    filterSuccess(state, list) {
      return { ...state, vehicleFilteredList: [...list] };
    },
    filterBoth(state, list) {
      return { ...state, ...list };
    },
    trailerFilterSuccess(state, list) {
      return { ...state, trailerFilteredList: [...list] };
    },
    setSelectedItem(state, data) {
      return { ...state, selectedRow: { ...data } };
    },
    listUpdate(state, list) {
      return {
        ...state,
        trailerList: [...list],
        trailerFilteredList: [...list]
      };
    },
    updateListResponse(state, payload) {
      return {
        ...state,
        vehicleList: payload?.vehicles ? [...payload?.vehicles] : [],
        vehicleFilteredList: payload?.vehicles ? [...payload?.vehicles] : [],
        trailerList: payload?.trailers ? [...payload?.trailers] : [],
        trailerFilteredList: payload?.trailers ? [...payload?.trailers] : [],
        infoBlockItemsList: payload?.infoBlockItems ? [...payload?.infoBlockItems] : [],
        infoBlockItemsFilteredList: payload?.infoBlockItems ? [...payload?.infoBlockItems] : []
      };
    },
    driverName(state, payload) {
      return {
        ...state,
        driverNames: payload.response
      };
    },
    updateVehicleListWithDeletedItem(state, id) {
      const updatedVehicleList = state.vehicleFilteredList.filter(
        (item) => item.id !== id
      );
      return {
        ...state,
        vehicleFilteredList: updatedVehicleList,
      }
    },
    getVehicleListDropDownSuccess(state, payload) {
      return { ...state, vehicleListDropDown: [...payload] };
    },
  },
  effects: (dispatch) => ({
    async fetchData(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: vehicleListApi
          },
          id: payload.apiId
        });
        response && this.listSuccess(response);
      } catch (error) {
        this.listFailed();
      }
    },
    async fetchFleetData(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...fleetVehiclesApi,
              url: fleetVehiclesApi.url.replace('{id}', payload.data.id)
            }
          },
          id: payload.apiId
        });
        response && this.fleetListSuccess(response);
      } catch (error) {
        // this.listFailed();
      }
    },
    async fetchRadiusData(payload) {
      if (payload.data.vehicleId) {
        try {
          const response = await dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              api: {
                ...fleetVehiclesRadiusApi,
                url: fleetVehiclesRadiusApi.url + `?companyId=${payload.data.companyId}&vehicleId=${payload.data.vehicleId}`
              }
            },
            id: payload.apiId
          });
          response && this.fleetRadiusListSuccess(response);
        } catch (error) {
          this.clearRadiusList();
        }
      } else this.clearRadiusList()
    },
    async handleDeleteAlert(payload, rootState, forceDelete) {
      try {
        let response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...vehicleDeleteApi,
              url: forceDelete ? `${vehicleDeleteApi.url.replace('{id}', payload.data.id)}?forceDelete=true` : vehicleDeleteApi.url.replace('{id}', payload.data.id)
            }
          },
          callback: payload.callback,
          id: payload.id
        });
        //remove item
        payload.callback('success', response);
        if(response) {
          this.fetchData({apiId:'fetchVehiclesList'});
          dispatch.dashboard.fetchCountData({
            apiId: 'fetchCountData'
          });
        }
        // this.updateVehicleListWithDeletedItem(payload.data.id)
      } catch (error) {
        if (error.message === 'There are active service requests existing for this vehicle') {
          payload.callback('warning', 'There are active service requests existing for this vehicle are you sure want to delete it', () => { this.handleDeleteAlert(payload, rootState, true) }, false, true);
          return;
        }
        // this.listFailed();
      }
    },
    async handleExportVehicle(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: exportVehicleApi
          },
          id: payload.apiId,
          callback: payload.callback
        });
        //remove item
        payload.callback('success', response);
      } catch (error) {
        // this.listFailed();
      }
    },
    async handleImportVehicle(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: isCRMImport() ? crmImportVehicleApi : importVehicleApi,
          },
          id: payload.apiId,
          callback: payload.callback
        });
        //remove item
        payload.callback('success', response);
      } catch (error) {
        // this.listFailed();
      }
    },
    async handleImportTrailer(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: importTrailerApi
          },
          id: payload.apiId,
          callback: payload.callback
        });
        //remove item
        payload.callback('success', response);
      } catch (error) {
        // this.listFailed();
      }
    },
    async handleImportVehiclePost(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: isCRMImport() ? crmImportVehicleApiPost : importVehicleApiPost,
            data: payload.data
          },
          id: payload.apiId,
          callback: payload.callback
        });
        
        const fetchUpdate = () => {
          dispatch.dashboard.fetchCountData({
            apiId: 'fetchCountData'
          });
          this.fetchData({apiId:'fetchVehiclesList'});
        }

        const onSuccess = (direct = false, isDelete = false, d_Response = null) => {
          if (response?.success && response.success.length > 0 && (get(d_Response, 'length') > 0) && isDelete) {
            payload.callback('success', direct ? `${i18next.t('bulkDelete.imported')}` : `${i18next.t('bulkDelete.allSuccess')} ${i18next.t('bulkDelete.deleted', { count: get(d_Response, 'length') })}`, () => {
              fetchUpdate();
            }, d_Response);
            return;
          }
          if (response?.success && response.success.length > 0) {
             payload.callback('success', direct ? i18next.t('bulkDelete.imported') : i18next.t('bulkDelete.allSuccess'), () => {
              fetchUpdate()
             });
             return;
          }
          const messageHandler = ((string, data) => {
            let messageString = string;
            for (let i = 0; i < data.length; i++) {
              messageString += `${data[i].name} `
            }
            return messageString;
          })
          if (response?.error && response.error.length > 0 && response.success.length > 0) payload.callback('success', messageHandler('Upload successful. Except Following ', response?.error));
          if (response?.error && response.error.length > 0 && response.success.length === 0) payload.callback('error', "Some error occurred while calling WiredRhino API for the Vehicles");
        }
        const hadItemsToDelete = (direct = false) => {
          payload.callback('success', `${direct ? i18next.t('bulkDelete.imported'): ''} ${i18next.t('bulkDelete.deleteVehicle', {
            count: response?.itemsToDelete?.length
          })}`, async () => {
            try {
              const deleteResponse = await dispatch.asyncRequests.handleAsyncRequests({
                payload: {
                  api: crmImportVehicleBulkDelete,
                  data: response?.itemsToDelete,
                },
                id: payload.apiId,
              });
              if (deleteResponse) {
                onSuccess(false, true, deleteResponse);
              }
            } catch (error) {
              payload.callback('error', error?.message || i18next.t('bulkDelete.deleteError') );
            }
          }, response?.itemsToDelete?.length ? response?.itemsToDelete : [], true)
          
          return;
        }

        const hadAlerts = () => {
            const onHadAlertClick = () => {
              if (response?.itemsToDelete?.length) {
                hadItemsToDelete();
              }
              fetchUpdate()
            }
            payload.callback('success', i18next.t('bulkDelete.alertSuccess'), onHadAlertClick, response?.alerts?.length > 0 ? response?.alerts : [])
        }
        
        if (isCRMImport() && response?.success && response.success.length > 0) {
          if (response?.alerts?.length > 0) {
            hadAlerts();
            return;
          } else if (response?.itemsToDelete?.length) {
            fetchUpdate()
            hadItemsToDelete(true);
            return;
          } else {
            onSuccess(true);
          }
        } else {
          onSuccess(true);
        }
        //remove item
      } catch (error) {
        // this.listFailed();
      }
    },
    async handleImportTrailerPost(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: importTrailerApiPost,
            data: payload.data
          },
          id: payload.apiId,
          callback: payload.callback
        });
        //remove item
        payload.callback('success', response);
      } catch (error) {
        // this.listFailed();
      }
    },
    updateTrailerList(id, state) {
      let filtered = state.vehicles.trailerFilteredList.filter(
        (item) => item.id !== id
      );
      this.listUpdate(filtered);
    },
    async getDriverNames(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: getDriverNamesApi,
            data: payload.data
          },
          id: payload.apiId,
          callback: payload.callback,
        });
        //remove item
        // response && this.driverName(response);
        payload.callback('success', response, payload);
      } catch (error) { }
    },
    async handleTrailerDeleteAlert(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...trailerDeleteApi,
              url: trailerDeleteApi.url.replace('{id}', payload.data.id)
            }
          },
          callback: payload.callback,
          id: payload.id
        });
        //remove item
        payload.callback('success', response);
        response && dispatch.vehicles.updateTrailerList(payload.data.id);
      } catch (error) {
        // this.listFailed();
      }
    },
    async fetchVehicleListDropDown(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: vehicleListDropDownApi
          },
          id: payload.apiId
        });
        response && this.getVehicleListDropDownSuccess(response);
      } catch (error) {
        // this.listFailed();
      }
    },
  })
};
