import styled from 'styled-components';

const Wrapper = styled.ul`
  ${({ theme }) => `
  list-style: none;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex; 
  padding-left: 0;
  flex-direction: column;
  .MuiGrid-container {
    width: auto;
  }
  li {
    list-style: none;
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flexbox;
    display: flex; 
    flex-direction: row;
    padding: 10px 1%;
    border-bottom: 0.5px solid ${theme.colors.PRIMARY_BORDER} !important;
    align-items: center;
    .iconContainer{
      .MuiAvatar-root {
        width: 27px;
        height: 27px;
      }
    }
    .imageUploadsectionsp {
      display: none;
    }
  }
  a {
    .uploadText{
      text-transform: none;
    }
    width: 15%;
    padding-left: 8px;
    color: ${theme.colors.LINK_COLOR};
    text-transform: capitalize;
    cursor: pointer;
    align-self: auto;
    @media (max-width: ${theme.breakpoints.values.md}px) {
      font-size: 12px;
    }
  }
  .MuiGrid-align-items-xs-center {
    align-items: initial;
  }
  @media (max-width: ${theme.breakpoints.values.md}px) {
    p {
      font-size: 12px !important;
    }
  }
  `}
`;
const UpdateWrapper = styled.div`
  ${({ theme }) => `
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  flex-direction: column;
  width: 40%;
  @media (max-width: ${theme.breakpoints.values.md}px) {
    width: 35%;
    padding-left: 4px;
  }
  .details {
    color: ${theme.colors.PRIMARY_CONTRAST};
    padding-bottom: 0 !important;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS styles go here */
      padding-bottom: 5px;
  }
  }
  .uploadDetails {
    font-size: 16px !important;
    line-height: 1.2 !important;
    padding-bottom: 0 !important;
    color: ${theme.colors.LIST_TEXT};
    @media (max-width: ${theme.breakpoints.values.md}px) {
      font-size: 12px !important;
    }
  }
  @media (max-width: ${theme.breakpoints.values.md}px) {
    p {
      font-size: 14px;
    }
  }
`}
`;
const ItemUrl = styled.div`
  ${({ theme }) => `
  width: 35%;
  padding-right: 8px;
  align-self: center;
  @media (max-width: ${theme.breakpoints.values.md}px) {
    width: 45%;
  }
  a {
    width: 100% !important;
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    padding-left: 0 !important;
    word-break: break-all;
    padding-right: 3px;
    .iconContainer {
      padding-right: 6px;
    }
  }
  `}
`;
const Styled = { Wrapper, UpdateWrapper, ItemUrl };

export default Styled;
