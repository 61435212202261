import { isInsuracePortalUser } from "@/utils/utils";
const IsInsurancePortalUser = isInsuracePortalUser();
const initialState = [];
export const paymentInformation = {
  state: [...initialState],
  reducers: {
    getSuccess(state, payload) {
      return { ...payload };
    },
    getFailed(state) {
      return { ...initialState };
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload) {
      try {
        const getApi = await import(
          `../../../config/apis/${window.$environment.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('getPaymentInformationApi');
        const modifiedApiObject= IsInsurancePortalUser?{...apiObject ,url: apiObject.url+`?companyId=${payload.data.id}`}: apiObject;
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: modifiedApiObject,
          },
          id: payload.apiId
        });
        response && this.getSuccess(response);
      } catch (error) {
        this.getFailed();
      }
    }
  })
};
