import {
  developerRequestApi,
  acceptDevRequestApi,
  rejectDevRequestApi
} from '../../../config/apis';

const initialState = [];
const isEs = () => localStorage.getItem(window.$environment?.SERVICE_PROVIDER + 'Language') == 'es'

export const developerRequest = {
  state: [...initialState],
  reducers: {
    listSuccess(state, payload) {
      return [...payload];
    },
    listFailed() {
      return [...initialState];
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: developerRequestApi
          },
          id: payload.apiId
        });
        response && this.listSuccess(response);
      } catch (error) {
        this.listFailed();
      }
    },
    async approveData(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...acceptDevRequestApi,
              url: acceptDevRequestApi.url.replace('{id}', payload.data.id)
            }
          },
          id: payload.apiId,
          callback: payload.callback
        });
        payload.callback('success', 'Accepted');
        // response && this.listSuccess(response);
      } catch (error) {
        // this.listFailed();
      }
    },
    async denyData(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
            ...rejectDevRequestApi,
            url: rejectDevRequestApi.url.replace('{id}', payload.data.id)
            }
          },
          id: payload.apiId,
          callback: payload.callback
        });
        payload.callback('success', isEs() ? 'Eliminado' : 'Deleted');

        // response && this.listSuccess(response);
      } catch (error) {
        // this.listFailed();
      }
    }
  })
};
