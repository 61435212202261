import React, { useState, lazy } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Filters from './Filters';
import { getImagePath, objectArrayFromDiffFields } from '@/utils/utils';
import { imageUrls, recurrenceIntervals, recurrenceTypes } from '@/config/constants/keys';
import * as Yup from 'yup';
import ScheduleComponent from '@/components/modules/ScheduleComponent';
import moment from 'moment';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Modal = require(`@/components/UI/Modal/${layout}`).default;
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Breadcrumb = lazy(() => import(`@/components/Breadcrumb`));
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));

const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Breadcrumb filters={filters} />
      <Component {...contentProps} />
    </Box>
  );
};
export default Index;
const Component = ({
  paginateList,
  hasFetchApiStatus,
  Styled,
  history,
  i18n,
  callback,
  handleDelete,
  reportTypes,
  changeRequest,
  request,
  pagination,
  setPagination,
  handleEdit,
  drivers,
  vehicles
}) => {
  const [values, setInitialValues] = useState({
    type: null
  });

  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [ModalListObject, setModalListObject] = useState({
    header: '',
    content: () => { }
  });

  const reportTypesList = objectArrayFromDiffFields(
    reportTypes,
    'key',
    'displayName',
  );
  const columns = [
    {
      id: 'reportType',
      numeric: false,
      disablePadding: false,
      label: i18n.t('Type'),
      needSorting: true
    },
    {
      id: 'requestDateKey',
      numeric: false,
      disablePadding: false,
      label: i18n.t('Request Date (UTC)'),
      needSorting: true
    },
    {
      id: 'recurrenceType',
      numeric: false,
      disablePadding: false,
      label: i18n.t('Scheduler Type'),
      needSorting: true
    },
    {
      id: 'recurrenceInterval',
      numeric: false,
      disablePadding: false,
      label: i18n.t('Recurring Type'),
      needSorting: true
    },
    {
      id: 'nextFireDate',
      numeric: true,
      disablePadding: false,
      label: i18n.t('Next Fire Date (UTC)'),
      needSorting: true
    },
    {
      id: 'action',
      numeric: false,
      disablePadding: false,
      label: i18n.t('Actions'),
      needSorting: false
    }
  ];

  const formatTime = (data, isUTC) => {
    const monthArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let date = new Date(data);
    let year = isUTC ? date.getUTCFullYear() : date.getFullYear();
    let month = isUTC ? date.getUTCMonth() : date.getMonth();
    let day = ('0' + (isUTC ? date.getUTCDate() : date.getDate())).slice(-2);
    let hours = isUTC ? date.getUTCHours() : date.getHours();
    let minutes = isUTC ? date.getUTCMinutes() : date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = `${monthArray[month]} ${day}, ${year} ${hours}:${minutes} ${ampm}`;
    return strTime;
  }

  const generateRows = (tableData) => {
    return tableData.map((item, index) => {
      return {
        ...item,
        reportType: reportTypesList.filter(ele => ele.value == item.type)[0]?.label || item.type,
        recurrenceType: recurrenceTypes.filter(ele => ele.value == item.recurrenceType)[0]?.label || item.recurrenceType,
        recurrenceInterval: recurrenceIntervals.filter(ele => ele.value == item.recurrenceInterval)[0]?.label || item.recurrenceInterval,
        requestDateKey: formatTime(item.requestDate, true),
        nextFireDate: formatTime(item.nextFireDate, true),
        action: <div className='actionBox'>
          <Icon
            src={getImagePath(imageUrls.edit)}
            tooltip={i18n.t('common.edit')}
            onClick={() => {
              setEdit(true)
              setModalListObject({
                content: () => (
                  <>
                    <div className='headWrapper' style={{ fontWeight: 'unset', marginBottom: '15px', marginLeft: '10px' }}>
                      {`Report Type - `}<span style={{ fontWeight: '600' }}>{reportTypesList.filter(ele => ele.value == item.type)[0]?.label || item.type}</span>
                    </div>
                    <div className='scheduleForm'>
                      <Form
                        type="server"
                        direction="column"
                        initialValues={{
                          recurrenceType: item.recurrenceType,
                          recurrenceInterval: item.recurrenceInterval,
                          scheduleEndDate: moment(new Date(item.scheduleEndDate)).format('MM/DD/YYYY') || '',
                          driverIds: item.driverIds || [],
                          vehicleIds: item.vehicleIds || []
                        }}
                        RenderComponent={ScheduleComponent}
                        handleOnSubmit={(fields) => {
                          let data = {
                            recurrenceType: fields.recurrenceType,
                            id: item.id,
                            requestDate: item.requestDate,
                            type: item.type,
                            ...(fields.recurrenceType != 'NONE' ? {
                              recurrenceInterval: fields.recurrenceInterval,
                              scheduleEndDate: fields.scheduleEndDate ? new Date(fields.scheduleEndDate).getTime() : null
                            } : {})

                          };
                          handleEdit({
                            data: data, apiId: 'editSchedulingAPI', callback: (success) => {
                              if (success) {
                                callback('success', 'Successfully edited.')
                                setOpen(false)
                              }
                              else {
                                callback('error', 'Something happened! Please try again later.')
                              }
                            }
                          });
                        }}
                        customProps={{
                          history,
                          Styled,
                          i18n,
                          drivers,
                          vehicles,
                          editPage: true
                        }}
                        validationSchema={Yup.object().shape({
                        })}
                      />
                    </div>
                  </>

                )
              })
              setOpen(true);
            }}
          />
          <Icon
            src={getImagePath(imageUrls.delete)}
            tooltip={i18n.t('common.delete')}
            onClick={() => {
              setEdit(false)
              setModalListObject({
                content: () => (
                  <div className="deleteParent">
                    <div className="imageSection">
                      <img
                        src={getImagePath(imageUrls.confirmIcon)}
                        alt="Are you sure"
                      />
                    </div>
                    <h2> {i18n.t('Are you sure you want to delete this scheduled report? \nPlease confirm.')} </h2>
                    <div className="deletePopupBtn">
                      <div className="cancelBtn">
                        <Button
                          label={i18n.t('Cancel')}
                          type={'reset'}
                          onClick={() => {
                            setOpen(false);
                          }}
                        ></Button>
                      </div>
                      <div className="deletebtn">
                        <Button
                          label={i18n.t('common.deletePopup')}
                          onClick={() => {
                            handleDelete(item.id)
                            setOpen(false);
                          }}
                        ></Button>
                      </div>
                    </div>
                  </div>
                )
              })
              setOpen(true);
            }}
          />
        </div>
      };
    });
  };

  const information = hasFetchApiStatus?.errorMessage ? (
    <Message type="error" message={hasFetchApiStatus.errorMessage} />
  ) : (
    'common.tableEmptyMessage'
  );

  return (
    <Styled.Wrapper>
      <div className="quickFilter"></div>
      <div className="tableWrapper">
        <Filters
          reportTypesList={reportTypesList}
          filterList={(data) => {
            changeRequest(data)
          }}
          handleReset={() => {
            changeRequest({
              type: null
            })
          }}
          values={values}
          setInitialValues={setInitialValues}
        />
        <Table
          type="server"
          defaultSortBy={{ sortBy: 'full_name', order: 'desc' }}
          title=""
          changeRequest={changeRequest}
          baseRequest={request}
          totalRecords={paginateList?.totalElements}
          columns={[...columns]}
          data={generateRows(paginateList?.content || [])}
          emptyMessage={information}
          itemsPerPage={15}
          pageinationReset={pagination}
          actions={[]}
          i18n={i18n}
          pagenationClicked={(e) => {
            setPagination(!e);
          }}
        />
      </div>
      <Modal width={edit ? '650px' : ''} exportDisplay={edit ? 'block' : 'flex'} open={open} setOpen={setOpen} deletePopup data={ModalListObject} withCloseIcon={edit} />
    </Styled.Wrapper>
  );
};

Component.propTypes = {
  list: PropTypes.array.isRequired,
  hasFetchApiStatus: PropTypes.object.isRequired,
  Styled: PropTypes.object.isRequired,
  i18n: PropTypes.array.isRequired
};
