import React, { lazy } from 'react';
import PropTypes from 'prop-types';

import { pageUrls } from '@/config/constants/keys';
import { getImagePath } from '@/utils/utils';
import { UI_DATE_FORMAT } from '@/config/constants/static';
import moment from 'moment';
import { imageUrls } from '@/config/constants/keys';
const layout = window?.$environment?.CURRENT_LAYOUT;
const beBaseUrl = window?.$environment?.BE_BASE_URL;

const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));

const Component = ({
  infoBlockItems,
  history,
  viewReport,
  Styled,
  i18n,
  logBook
}) => {
  const apiId = 'viewReportApi';

  const columns = [
    {
      id: 'driver',
      disablePadding: false,
      label: i18n.t('Driver Name'),
      needSorting: true
    },
    {
      id: 'driverid',
      numeric: true,
      disablePadding: false,
      label: i18n.t('roadInspection.driverId'),
      needSorting: true
    },

    {
      id: 'date',
      numeric: true,
      disablePadding: false,
      label: i18n.t('common.date'),
      needSorting: true
    },
    {
      id: 'report',
      numeric: true,
      disablePadding: false,
      label: i18n.t('roadInspection.defectFound'),
      needSorting: true
    }
  ];
  const generateRows = () => {
    const updatedList = infoBlockItems.map((item) => {
      item.report = (
        <a
          href={`${beBaseUrl}/document/open/${item?.reportid}?token=${logBook?.documentToken}`}
          className="viewReport"
          target="_blank"
        >
        {i18n.t('accidents.viewReport')}
        </a>
      );
      item.date = moment(item.date).format(UI_DATE_FORMAT);
      return item;
    });
    return updatedList;
  };
  const information = i18n.t('common.tableEmptyMessage');
  return (
    <>
      <Styled.Inspectionview>
        <div className="viewAll">
          <Icon
            src={getImagePath(imageUrls.viewMoreIcon)}
            onClick={() => history.push(pageUrls.inspections)}
            tooltip={i18n.t('common.viewMore')}
            className="roadDetails"
          />
          <span className="viewmore" onClick={() => history.push(pageUrls.inspections)}>{i18n.t('common.viewMore')}</span>
        </div>
      </Styled.Inspectionview>
      <Styled.TableWrapper>
        <Table
          type="client"
          defaultSortBy={{ sortBy: 'date', order: 'desc' }}
          title=""
          columns={[...columns]}
          data={generateRows()}
          emptyMessage={information}
          itemsPerPage={window.$environment.CURRENT_LAYOUT === 'OldUi' ? 5 : 10}
          actions={[]}
          defaultSort={'date'}
          i18n={i18n}
        />
      </Styled.TableWrapper>
    </>
  );
};

export default Component;

Component.propTypes = {
  history: PropTypes.object.isRequired
};
