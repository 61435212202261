import styled from 'styled-components';
import Parent from '../Base/clientStyle';
import { getImagePath } from '../../../../utils/utils';
const FormWrapper = styled(Parent.FormWrapper)`
.MuiButtonBase-root{
  ${window.$environment?.clutchNewUI && `border: none;`}
}
`;
const ButtonWrapper = styled(Parent.ButtonWrapper)`

`;
const FieldsWrapper = styled(Parent.FieldsWrapper)`
${({theme})=>`
flex-direction: column;
.MuiTypography-body1 {
  color: ${theme.colors.FORM_COLOR} !important;
  font-family: ${theme.palette.fontFamily};
  font-size: 14px !important;
  font-weight: 400;
  padding-bottom: 8px;
}
.MuiFormLabel-root {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    padding-bottom: 18px;
  }
}
.react-datepicker-wrapper {
  min-height: 40px;
  height: 40px;
  input {
    border: 1px solid ${theme.colors.INPUT_BORDER}!important;
    height: 40px;
    background:  ${theme.colors.FORM_INPUT_BG} url(${getImagePath('icons/calanderIcon.svg')}) no-repeat right 14px center;
    padding: 9px 15px;
    font-size: 16px !important;
    border-radius: 4px;
  }
}
.react-datepicker__close-icon::after {
  position: absolute;
  right: 45px;
  top: 1px;
  background-color: ${theme.colors.SECONDARY_MAIN};
}
.react-datepicker__day--today {
  color: ${theme.colors.SECONDARY_MAIN} !important;
}
.MuiInput-root {
  margin-bottom: 0;
}
${({ theme }) => `button[type="submit"]{
  background-color: ${theme.colors.LINK_COLOR};
  height: 40px;
  border-radius: 4px;
  color: #ffffff;
  font-size: 12px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  :hover {
    background-color: ${theme.colors.LINK_COLOR};
  }
}`}
button[type="reset"]{
  background-color: ${theme.colors.RESET_BUTTON} !important;
  height: 40px;
  border-radius: 4px;
  color: ${theme.colors.RESET_COLOR} !important;
  font-size: 12px;
  font-weight: ${window?.$environment?.clutchNewUI? 'bold': '400'};
  text-transform: uppercase;
  :hover {
    background-color: ${theme.colors.RESET_BUTTON} !important;
    color: ${theme.colors.RESET_COLOR} !important;
  }
}
`}
`;

const Styled = {
  FormWrapper,
  ButtonWrapper,
  FieldsWrapper
};

export default Styled;
