import React, { useEffect, lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useHistory } from 'react-router-dom';
import useTheme from '@material-ui/core/styles/useTheme';
import { useTranslation } from 'react-i18next';
import {
  hasValue,
  getNewObject,
  getQueryStringKeys,
  loadPageConfig
} from '@/utils/utils';
import { pageUrls } from '@/config/constants/keys';
const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.resetPassword[0].name}`));
const Content = lazy(() => import(`@/${config.resetPassword[1].name}`));
const ResetPassword = (parentProps) => {
  const { apiStatus, fetchResetPassword } = parentProps;

  const history = useHistory();
  const theme = useTheme();
  const i18n = useTranslation();
  const commonParentProps = { history, i18n, theme };

  const apiId = 'fetchResetPassword';
  const token = getQueryStringKeys(history.location.search, 'token');
  const [showApologies, setShow] = useState(false);
  useEffect(() => {
    const token = getQueryStringKeys(history.location.search, 'token');
    if (token) {
      fetchResetPassword({
        data: { token },
        apiId
      });
    } else {
      history.push(pageUrls.login);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const hasGetApiStatus = hasValue(apiStatus.status, 'api', apiId);
    if (hasGetApiStatus && hasGetApiStatus.errorMessage === 'Invalid token') {
      history.push(pageUrls.apologies);
      // setShow(true);
      window.history.pushState(
        '',
        'Page Not Found',
        `/user/resetPassword?token=${token}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiStatus.status]);
  return (
    <>
          <RenderComponent
            {...config.resetPassword[0]}
            parentProps={parentProps}
            commonParentProps={commonParentProps}
            component={Title}
          />

          <RenderComponent
            {...config.resetPassword[1]}
            parentProps={parentProps}
            commonParentProps={commonParentProps}
            component={Content}
            token={token}
          />
        </>
  );
};

const RenderComponent = ({
  name,
  props,
  commonProps,
  title,
  parentProps,
  commonParentProps,
  component,
  ...rest
}) => {
  const Component = component;
  let newProps = props?.length ? getNewObject(parentProps, props) : {};
  newProps = {
    ...newProps,
    ...(commonProps?.length ? getNewObject(commonParentProps, commonProps) : {})
  };
  return <Component {...rest} {...newProps} />;
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    profile: state.resetPassword
  };
};
const mapDispatch = (dispatch) => {
  return {
    updateProfile: (data) => dispatch.resetPassword.updateData(data),
    fetchResetPassword: (data) => dispatch.resetPassword.fetchData(data)
  };
};

export default connect(mapState, mapDispatch)(ResetPassword);

ResetPassword.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  resetPassword: PropTypes.object.isRequired,
  fetchResetPassword: PropTypes.func.isRequired,
  updateResetPassword: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired
};
