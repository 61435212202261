import React, { useEffect, lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { COOKIE_AUTH_DETAILS, pageUrls } from '@/config/constants/keys';
import { getCookie } from '@/services/cookie';
import cookie from 'react-cookies'

import ParentStyled from './style';
import {
  loadStyle,
  getNewObject,
  loadPageConfig,
  hasRestrictedRole,
  isInsuracePortalUser
} from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'pages/web/Dashboard');

const config = loadPageConfig();
const Header =
  config.dashboard[0] && lazy(() => import(`@/${config.dashboard[0].name}`));
const Content =
  config.dashboard[1] && lazy(() => import(`@/${config.dashboard[1].name}`));
const Dashboard = (parentProps) => {

  useEffect(() => {
    cookie.remove('selectedVehicle')
  }, [])

  const { fetchDashboardData, dashboard, fetchInsuranceDashboardData, refreshDriverStatusMap } = parentProps;
  const history = useHistory();
  const i18n = useTranslation();

  const fetchApi = 'dashboardApi';
  const fetchInsuranceApi = 'insuranceDashboardApi'
  const commonParentProps = { history, i18n, Styled };

  const [showDash, setShowDash] = useState(true);
  const token = getCookie(COOKIE_AUTH_DETAILS);
  useEffect(() => {
    if (hasRestrictedRole(['OWNER'])) {
      if (token?.onboardCompleted) {
        setShowDash(true);
      } else {
        setShowDash(false);
      }
    } else {
      setShowDash(true);
    }

    if (!isInsuracePortalUser()) fetchDashboardData({ fetchApi });
    else fetchInsuranceDashboardData({ fetchInsuranceApi })

    if (window.$environment.SERVICE_PROVIDER === 'spireon' || window.$environment.SERVICE_PROVIDER === 'cyntrx') {
      let interval = setInterval(() => {
        fetchDashboardData({ apiId: 'updateDriverStatus' });
      }, 60000);

      document.addEventListener(
        'visibilitychange',
        () => {
          if (document.visibilityState === 'hidden') {
            clearInterval(interval);
          } else {
            interval = setInterval(() => {
              fetchDashboardData({ apiId: 'updateDriverStatus' });
            }, 60000);
          }
        },
        false
      );
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (dashboard?.data?.udLogsCount && dashboard?.data?.udLogsCount !== 0) {
      const udNotificationContainer = document.getElementById('udNotification');
      if (udNotificationContainer) {
        if (udNotificationContainer.childNodes.length)
          udNotificationContainer.removeChild(
            udNotificationContainer.firstChild
          );
        var aTag = document.createElement('a');
        aTag.setAttribute('href', pageUrls.logsUdList);
        aTag.innerText = i18n.t('dashboard.udNotification');
        udNotificationContainer.appendChild(aTag);
      }
      setTimeout(() => {
        if (udNotificationContainer) {
          udNotificationContainer.style.display = 'block';
        }
      }, 6000);
      setTimeout(() => {
        if (udNotificationContainer) {
          udNotificationContainer.style.display = 'none';
        }
      }, 10000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard?.data]);

  return (
    <Styled.Wrapper>
      {!token?.adminSuspended && Header && (
        <RenderComponent
          {...config.dashboard[0]}
          parentProps={parentProps}
          commonParentProps={commonParentProps}
          component={Header}
          showDash={showDash}
          i18n={i18n}
        />
      )}
      {!token?.adminSuspended && Content && (
        <RenderComponent
          {...config.dashboard[1]}
          parentProps={parentProps}
          commonParentProps={commonParentProps}
          component={Content}
          showDash={showDash}
          i18n={i18n}
        />
      )}
    </Styled.Wrapper>
  );
};

const RenderComponent = ({
  name,
  props,
  commonProps,
  title,
  parentProps,
  commonParentProps,
  component,
  showDash,
  alternateComponent,
  i18n,
  ...rest
}) => {
  const Component = component;
  let newProps = props?.length ? getNewObject(parentProps, props) : {};

  newProps = {
    ...newProps,
    ...(commonProps?.length ? getNewObject(commonParentProps, commonProps) : {})
  };
  return (showDash && alternateComponent) || !alternateComponent || window.$environment?.SERVICE_PROVIDER !== 'clutch' ? (
    <Component {...rest} {...newProps} />
  ) : (
    <Styled.NotFoundWrapper>
      <h1>{i18n.t('apology')}...</h1>
      <p>{i18n.t('page moved')}</p>
      <p>
        {i18n.t('Click')}
        <a href="/welcome" className="here">
          {i18n.t('here')}{' '}
        </a>
        {i18n.t('to go back to onboarding')}
      </p>
    </Styled.NotFoundWrapper>
  );
};
const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    dashboard: state.dashboard,
    receipt: state.viewReport
  };
};
const mapDispatch = (dispatch) => {
  return {
    clearAll: (data) => dispatch.potentialViolations.clearAll(data),
    fetchDashboardData: (data) => dispatch.dashboard.fetchData(data),
    fetchInsuranceDashboardData: (data) => dispatch.dashboard.fetchInsuranceData(data),
    refreshDriverStatus: (data) => dispatch.driverStatusList.refreshData(data),
    refreshVehicleStatus: (data) =>
      dispatch.vehicleStatusList.refreshData(data),
    handleDeleteAlert: (data) => dispatch.dashboard.handleDeleteAlert(data),
    clearAllAlerts: (data) => dispatch.dashboard.clearAllAlerts(data),
    enableNotification: (data) => dispatch.dashboard.enableNotification(data),
    clearAllRecentAccidents: (data) =>
      dispatch.accidents.clearAllRecentAccidents(data),
    handleAcceptPermissionRequest: (data) =>
      dispatch.dashboard.handleAcceptPermissionRequest(data),
    handleDeletePermissionRequest: (data) =>
      dispatch.dashboard.handleDeletePermissionRequest(data),
    getDriverStatus: (data) => dispatch.dashboard.getDriverStatus(data),
    getLatestLog: (data) => dispatch.dashboard.getLatestLog(data),
    viewReport: (data) => dispatch.viewReport.viewReportDocument(data),
    refreshDriverStatusMap: (data) =>
      dispatch.dashboard.refreshDriverStatusMap(data),
    getVehicleStatus: (data) => dispatch.dashboard.getVehicleStatus(data),
    clearAllDailyLogs: (data) => dispatch.dashboard.clearAllDailyLogs(data)
  };
};

export default connect(mapState, mapDispatch)(Dashboard);

Dashboard.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  clearAll: PropTypes.func.isRequired,
  refreshDriverStatus: PropTypes.func.isRequired,
  refreshVehicleStatus: PropTypes.func.isRequired,
  fetchDashboardData: PropTypes.func.isRequired,
  fetchInsuranceDashboardData: PropTypes.func.isRequired,
  handleDeleteAlert: PropTypes.func.isRequired,
  clearAllAlerts: PropTypes.func.isRequired,
  enableNotification: PropTypes.func.isRequired,
  clearAllRecentAccidents: PropTypes.func.isRequired
};
