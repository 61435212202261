import React from 'react';

const ErrorIcon = ({ ErrorIconComponent }) => {
  return (
    <ErrorIconComponent.Container>
      <ErrorIconComponent.LeftStick />
      <ErrorIconComponent.RightStick />
    </ErrorIconComponent.Container>
  );
};

export default ErrorIcon;
