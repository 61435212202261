import React, { lazy } from 'react';

const layout = window?.$environment?.CURRENT_LAYOUT;

const Search = lazy(() => import(`@/components/UI/SimpleSearch/${layout}`));

const TopActions = ({ changeRequest, Styled, i18n, setPagination }) => {
  const selectChange = (e) => {
    changeRequest({ search: e });
    setPagination(true);
  };
  return (
    <>
      <Styled.TopWrapper>
        <Styled.SearchWrapper>
          <Search
            id={'clearSearch'}
            onChange={(e) => selectChange(e)}
            default={[]}
            placeHolder={i18n.t('vehicles.searchUser')}
            id='driver_Status_1'
          ></Search>
        </Styled.SearchWrapper>
      </Styled.TopWrapper>
    </>
  );
};
export default TopActions;
