import styled from 'styled-components';
import Parent from '../Base/style';

const UploadWrapper = styled(Parent)`
${({ theme, allowMultiple, normal, fileChosen }) => `
  font-family: "Open Sans";
  .filepond--root {
    height: ${allowMultiple ? 'fit-content' : normal ? '37px' : '140px'};
    font-family: "Open Sans";
  }
  .filepond--wrapper {
    border: 1px solid ${theme.colors.INPUT_BORDER}!important;
    height: 40px !important; 
    background-color: ${theme.colors.PRIMARY_MAIN} !important;
    border-radius: 4px;
  }
  .filepond--drop-label {
    color: #aaaaaa;
    font: 16px "Open Sans";
    z-index: 3 !important;
  }
  .filepond--drop-label label {
    line-height: 1;
  }
  .filepond--label-action {
    text-decoration: none;
  }
  .filepond--file-info {
    margin: ${
      allowMultiple ? '0px 3.5em 0 0 !important;' : ' 0 -1.5em 0 0.5em !important'
    };
  }
  .filepond--file-status {
    display: 'block';
    top: 40px;
    position: relative;
  }
  .filepond--panel-root {
    border-radius: 4px !important;
    color: #aaaaaa;
    background-color: ${theme.colors.PRIMARY_MAIN} !important;
  }
  .filepond--item {
    top: ${allowMultiple || normal ? '0px' : '30px'};
  }
  .filepond--list-scroller {
    margin-top: ${normal ? '0px !important' : '1em'};
    height: ${normal ? '38px !important' : '7px'};
    top: 4px;
    z-index: 3!important;
  }
  .filepond--file-wrapper.filepond--file-wrapper>legend {
      font-size: 14px !important;
  }
  .filepond--file-action-button {
    background-color: ${theme.colors.SECONDARY_MAIN};
  }
  .filepond--item-panel {
    background-color: transparent;
    border: none;
  }
  .filepond--file {
    padding: ${normal ? '0' : '.5625em'};
  }
  .filepond--root .filepond--drop-label {
    min-height: ${normal ? '37px !important' : '4.75em'};
  }
  .filepond--browser.filepond--browser {
    z-index: ${!fileChosen ? '4' : '0'};
    height: 37px;
  }
  .filepond--drop-label.filepond--drop-label label {
    padding: ${normal ? '5px !important' : '.5em'};
    opacity: 0.5;
    font-size: 14px;
  }
  .filepond--file-info .filepond--file-info-main {
    font-family: "Open Sans";
    font-size: 15px;
    color: #73787d;
  }
  .filepond--list.filepond--list {
    left:0;
  }
  .filepond--file-status-main {
    font-size: 12px !important;
    margin-top: -26px;
    color: ${
      theme.palette.error.main ? theme.palette.error.main : theme.colors.RED
    };
  }
  [data-filepond-item-state*='invalid'] .filepond--item-panel {
    background-color: transparent;
  }
  `}
`;
export default UploadWrapper;
