import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  flex-direction: column;
`;
const TableWrapper = styled(Box)`
${({ insuracePortalUserRole }) => `
.MuiGrid-align-items-xs-center {
  justify-content: center;
}
  padding-top: 2%;
  /* padding-left: 20px; */
  .CSAForms {
    justify-content: flex-start;
    .MuiFormLabel-root{
      padding-bottom: 0px !important;
    }
    .vertical-button {
      .MuiBox-root:first-child {
        margin-right: 14px;
      }
      .MuiButtonBase-root {
        margin-top: 24px;
        padding-left: 0;
        padding-right: 0;
        .MuiButton-label {
          padding: 2px 22px;
        }
      }
    }
  }

table{
  .MuiTableBody-root .MuiTableCell-root:nth-child(8) .MuiGrid-container {
    justify-content: center !important;
  }
  .MuiTableHead-root .MuiTableRow-head .MuiTableCell-sizeSmall:last-child{
    max-width: 60px;
    // ${!insuracePortalUserRole && 'padding-left: 38px !important;'} 
  }
}
`}
`;
const Styled = {
  Wrapper,
  TableWrapper,
};
export default Styled;
