import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
  padding: 2%;
`;
const AreasListkWrapper = styled(Parent.AreasListkWrapper)`
.addNewParts {
  color: #fff !important;
}
.accordinIcon {
  filter: brightness(15);
}`;
const TopWrapper = styled(Parent.TopWrapper)``;
const Styled = { Wrapper, TopWrapper, AreasListkWrapper };

export default Styled;
