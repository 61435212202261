import React from 'react';
import Files from 'react-files';

const onFilesChange = (files) => {};

const onFilesError = (error, file) => {};

const App = (props) => {
  return (
    <div className="files">
      <Files
        className="files-dropzone"
        onChange={onFilesChange}
        onError={onFilesError}
        accepts={['image/png', '.pdf', 'audio/*']}
        multiple
        maxFiles={3}
        maxFileSize={10000000}
        minFileSize={0}
        clickable
      >
        Drop files here or click to upload
      </Files>
    </div>
  );
};

export default App;
