import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
  position: relative;

  .logsInfoDisplay {
    font-size: 16px;
    padding-bottom: 30px;
  }
  table .MuiTableCell-alignCenter {
    text-align: left;
  }
`;

const Styled = { Wrapper };

export default Styled;
