import { getCompanies, getCompaniesOfServiceCenterApi } from '../../../config/apis';

const initialState = {
  data: [],
  selectedItems: [],
  companiesOfServiceCenter: [],
  filteredList: [],
};

export const companies = {
  state: { ...initialState },
  reducers: {
    handleGetCompanies: (state, data) => {
      return {
        //Mock data
        ...state,
        data: [...data]
      };
      // return { ...state, data };
    },
    getSuccess: (state, data) => {
      return {
        ...state,
        companiesOfServiceCenter: data? [...data]: [],
        filteredList: data? [...data]: []
      }
    },
    getFailed: (state, data) => {
      return {
        ...state,
        companiesOfServiceCenter: [],
        filteredList: []
      }
    },
    filterSuccess(state, list) {
      return { ...state, filteredList: [...list] };
    },
  },
  effects: (dispatch) => ({
    async getCompanies(payload) {
      const response = await dispatch.asyncRequests.handleAsyncRequests({
        payload: {
          data: null,
          api: getCompanies
        },
        callback: null,
        id: 'getCompanies'
      });
      if (response) {
        this.handleGetCompanies([
          {
            companyId: 2190,
            companyName: 'AR Dev testing',
            dot: '33232223',
            activeTrucks: 0,
            contractLength: 2,
            contractExpiration: '2022/01/28',
            phone: '123-213-2132',
            email: 'aravinda+fm15@qburst.com',
            registrationDate: '2020/01/28',
            status: 'Active',
            producerCode: '',
            producerName: '',
            commissionAmt: 0,
            subscriptionTier: 'PRO',
            discount: 0.0,
            costPerTruck: 3699.0,
            stripeKeyExist: false,
            isSuspended: false,
            stripeId: ''
          },
          {
            companyId: 2196,
            companyName: 'asdfsd',
            dot: '67888',
            activeTrucks: 0,
            contractLength: 1,
            contractExpiration: '2021/01/28',
            phone: '',
            email: 'asdf@sdf.adsf',
            registrationDate: '2020/01/28',
            status: 'Active',
            producerCode: '',
            producerName: '',
            commissionAmt: 0,
            subscriptionTier: 'PRO',
            discount: 2.0,
            costPerTruck: 3699.0,
            stripeKeyExist: false,
            isSuspended: false,
            stripeId: ''
          },
          {
            companyId: 2216,
            companyName: 'asdfsf',
            dot: '000999887',
            activeTrucks: 0,
            contractLength: 3,
            contractExpiration: '2023/02/04',
            phone: '111-222-1234',
            email: '1232123@asdsad.com',
            registrationDate: '2020/02/04',
            status: 'Active',
            producerCode: '',
            producerName: '',
            commissionAmt: 0,
            subscriptionTier: 'PRO',
            discount: 0.0,
            costPerTruck: 3699.0,
            stripeKeyExist: true,
            isSuspended: false,
            stripeId: '516273243'
          },
          {
            companyId: 2189,
            companyName: 'Dev testing 3',
            dot: '19635741',
            activeTrucks: 2,
            contractLength: 2,
            contractExpiration: '2022/01/28',
            phone: '',
            email: 'varshah+dev+test1@qburst.com',
            registrationDate: '2020/01/28',
            status: 'Active',
            producerCode: '',
            producerName: '',
            commissionAmt: 0,
            subscriptionTier: 'PRO',
            discount: 0.0,
            costPerTruck: 3699.0,
            stripeKeyExist: false,
            isSuspended: false,
            stripeId: ''
          },
          {
            companyId: 2203,
            companyName: 'Dev testing 4',
            dot: '136524',
            activeTrucks: 0,
            contractLength: 2,
            contractExpiration: '2022/01/31',
            phone: '',
            email: 'varshah+dev+test2@qburst.com',
            registrationDate: '2020/01/31',
            status: 'Active',
            producerCode: '',
            producerName: '',
            commissionAmt: 0,
            subscriptionTier: 'PRO',
            discount: 0.0,
            costPerTruck: 3699.0,
            stripeKeyExist: false,
            isSuspended: false,
            stripeId: ''
          },
          {
            companyId: 2201,
            companyName: 'Gorilla Safety Demo',
            dot: '123123123',
            activeTrucks: 1,
            contractLength: 3,
            contractExpiration: '2023/01/29',
            phone: '123-444-5555',
            email: 'alberteinstein@email.com',
            registrationDate: '2020/01/29',
            status: 'Active',
            producerCode: '',
            producerName: '',
            commissionAmt: 0,
            subscriptionTier: 'PRO',
            discount: 100.0,
            costPerTruck: 3699.0,
            stripeKeyExist: false,
            isSuspended: false,
            stripeId: ''
          },
          {
            companyId: 2268,
            companyName: 'gorillatest',
            dot: '1231',
            activeTrucks: 3,
            contractLength: 3,
            contractExpiration: '2023/02/19',
            phone: '012-122-1121',
            email: 'matester6@gmail.com',
            registrationDate: '2020/02/19',
            status: 'Active',
            producerCode: '',
            producerName: '',
            commissionAmt: 0,
            subscriptionTier: 'PRO',
            discount: 0.0,
            costPerTruck: 3699.0,
            stripeKeyExist: true,
            isSuspended: false,
            stripeId: '255851943'
          },
          {
            companyId: 2267,
            companyName: 'gorillatest',
            dot: '54536352',
            activeTrucks: 5,
            contractLength: 3,
            contractExpiration: '2023/02/18',
            phone: '111-111-1111',
            email: 'sajantha+testcost@qburst.com',
            registrationDate: '2020/02/18',
            status: 'Active',
            producerCode: '',
            producerName: '',
            commissionAmt: 0,
            subscriptionTier: 'LITE',
            discount: 10.0,
            costPerTruck: 1999.0,
            stripeKeyExist: false,
            isSuspended: false,
            stripeId: ''
          },
          {
            companyId: 2205,
            companyName: 'gorillatest',
            dot: '32',
            activeTrucks: 0,
            contractLength: 3,
            contractExpiration: '2023/02/03',
            phone: '012-122-1121',
            email: 'testzoho@test.com',
            registrationDate: '2020/02/03',
            status: 'Active',
            producerCode: '',
            producerName: '',
            commissionAmt: 0,
            subscriptionTier: 'PRO',
            discount: 0.0,
            costPerTruck: 3699.0,
            stripeKeyExist: true,
            isSuspended: false,
            stripeId: '534229629'
          },
          {
            companyId: 2210,
            companyName: 'gorillatest',
            dot: '33',
            activeTrucks: 0,
            contractLength: 3,
            contractExpiration: '2023/02/03',
            phone: '012-122-1121',
            email: 'testzoho3@test.com',
            registrationDate: '2020/02/03',
            status: 'Active',
            producerCode: '',
            producerName: '',
            commissionAmt: 0,
            subscriptionTier: 'PRO',
            discount: 0.0,
            costPerTruck: 3699.0,
            stripeKeyExist: true,
            isSuspended: false,
            stripeId: '191255726'
          },
          {
            companyId: 2266,
            companyName: 'gorillatest',
            dot: '21342134',
            activeTrucks: 1,
            contractLength: 2,
            contractExpiration: '2022/02/18',
            phone: '111-111-1111',
            email: 'sajantha+testtierstg@qburst.com',
            registrationDate: '2020/02/18',
            status: 'Active',
            producerCode: '',
            producerName: '',
            commissionAmt: 0,
            subscriptionTier: 'STANDARD',
            discount: 21.0,
            costPerTruck: 2699.0,
            stripeKeyExist: false,
            isSuspended: false,
            stripeId: ''
          },
          {
            companyId: 2204,
            companyName: 'gorillatest',
            dot: '23234',
            activeTrucks: 1,
            contractLength: 3,
            contractExpiration: '2019/12/05',
            phone: '012-122-1121',
            email: 'sajantha+sub@qburst.com',
            registrationDate: '2020/01/31',
            status: 'Active',
            producerCode: '',
            producerName: '',
            commissionAmt: 0,
            subscriptionTier: 'PRO',
            discount: 0.0,
            costPerTruck: 2699.0,
            stripeKeyExist: true,
            isSuspended: false,
            stripeId: '288305304'
          },
          {
            companyId: 2219,
            companyName: 'gorillatest',
            dot: '673636',
            activeTrucks: 0,
            contractLength: 2,
            contractExpiration: '2022/02/07',
            phone: '',
            email: 'testt@sda.com',
            registrationDate: '2020/02/07',
            status: 'Active',
            producerCode: '',
            producerName: '',
            commissionAmt: 0,
            subscriptionTier: 'PRO',
            discount: 33.0,
            costPerTruck: 3699.0,
            stripeKeyExist: false,
            isSuspended: false,
            stripeId: ''
          },
          {
            companyId: 2197,
            companyName: 'gorillaTest2',
            dot: '21312',
            activeTrucks: 1,
            contractLength: 3,
            contractExpiration: '2023/01/29',
            phone: '122-222-2222',
            email: 'sajantha+gtss@qburst.com',
            registrationDate: '2020/01/29',
            status: 'Active',
            producerCode: '',
            producerName: '',
            commissionAmt: 0,
            subscriptionTier: 'PRO',
            discount: 0.0,
            costPerTruck: 3699.0,
            stripeKeyExist: true,
            isSuspended: false,
            stripeId: '136029554'
          },
          {
            companyId: 2209,
            companyName: 'gorillatestzoho2',
            dot: '3421413',
            activeTrucks: 0,
            contractLength: 3,
            contractExpiration: '2023/02/03',
            phone: '012-122-1121',
            email: 'testzoho2@test.com',
            registrationDate: '2020/02/03',
            status: 'Active',
            producerCode: '',
            producerName: '',
            commissionAmt: 0,
            subscriptionTier: 'PRO',
            discount: 0.0,
            costPerTruck: 3699.0,
            stripeKeyExist: true,
            isSuspended: false,
            stripeId: '296074843'
          }
        ]);
      } else {
        this.handleGetCompanies([
          {
            companyId: 2190,
            companyName: 'AR Dev testing',
            dot: '33232223',
            activeTrucks: 0,
            contractLength: 2,
            contractExpiration: '2022/01/28',
            phone: '123-213-2132',
            email: 'aravinda+fm15@qburst.com',
            registrationDate: '2020/01/28',
            status: 'Active',
            producerCode: '',
            producerName: '',
            commissionAmt: 0,
            subscriptionTier: 'PRO',
            discount: 0.0,
            costPerTruck: 3699.0,
            stripeKeyExist: false,
            isSuspended: false,
            stripeId: ''
          },
          {
            companyId: 2196,
            companyName: 'asdfsd',
            dot: '67888',
            activeTrucks: 0,
            contractLength: 1,
            contractExpiration: '2021/01/28',
            phone: '',
            email: 'asdf@sdf.adsf',
            registrationDate: '2020/01/28',
            status: 'Active',
            producerCode: '',
            producerName: '',
            commissionAmt: 0,
            subscriptionTier: 'PRO',
            discount: 2.0,
            costPerTruck: 3699.0,
            stripeKeyExist: false,
            isSuspended: false,
            stripeId: ''
          },
          {
            companyId: 2216,
            companyName: 'asdfsf',
            dot: '000999887',
            activeTrucks: 0,
            contractLength: 3,
            contractExpiration: '2023/02/04',
            phone: '111-222-1234',
            email: '1232123@asdsad.com',
            registrationDate: '2020/02/04',
            status: 'Active',
            producerCode: '',
            producerName: '',
            commissionAmt: 0,
            subscriptionTier: 'PRO',
            discount: 0.0,
            costPerTruck: 3699.0,
            stripeKeyExist: true,
            isSuspended: false,
            stripeId: '516273243'
          },
          {
            companyId: 2189,
            companyName: 'Dev testing 3',
            dot: '19635741',
            activeTrucks: 2,
            contractLength: 2,
            contractExpiration: '2022/01/28',
            phone: '',
            email: 'varshah+dev+test1@qburst.com',
            registrationDate: '2020/01/28',
            status: 'Active',
            producerCode: '',
            producerName: '',
            commissionAmt: 0,
            subscriptionTier: 'PRO',
            discount: 0.0,
            costPerTruck: 3699.0,
            stripeKeyExist: false,
            isSuspended: false,
            stripeId: ''
          },
          {
            companyId: 2203,
            companyName: 'Dev testing 4',
            dot: '136524',
            activeTrucks: 0,
            contractLength: 2,
            contractExpiration: '2022/01/31',
            phone: '',
            email: 'varshah+dev+test2@qburst.com',
            registrationDate: '2020/01/31',
            status: 'Active',
            producerCode: '',
            producerName: '',
            commissionAmt: 0,
            subscriptionTier: 'PRO',
            discount: 0.0,
            costPerTruck: 3699.0,
            stripeKeyExist: false,
            isSuspended: false,
            stripeId: ''
          },
          {
            companyId: 2201,
            companyName: 'Gorilla Safety Demo',
            dot: '123123123',
            activeTrucks: 1,
            contractLength: 3,
            contractExpiration: '2023/01/29',
            phone: '123-444-5555',
            email: 'alberteinstein@email.com',
            registrationDate: '2020/01/29',
            status: 'Active',
            producerCode: '',
            producerName: '',
            commissionAmt: 0,
            subscriptionTier: 'PRO',
            discount: 100.0,
            costPerTruck: 3699.0,
            stripeKeyExist: false,
            isSuspended: false,
            stripeId: ''
          },
          {
            companyId: 2268,
            companyName: 'gorillatest',
            dot: '1231',
            activeTrucks: 3,
            contractLength: 3,
            contractExpiration: '2023/02/19',
            phone: '012-122-1121',
            email: 'matester6@gmail.com',
            registrationDate: '2020/02/19',
            status: 'Active',
            producerCode: '',
            producerName: '',
            commissionAmt: 0,
            subscriptionTier: 'PRO',
            discount: 0.0,
            costPerTruck: 3699.0,
            stripeKeyExist: true,
            isSuspended: false,
            stripeId: '255851943'
          },
          {
            companyId: 2267,
            companyName: 'gorillatest',
            dot: '54536352',
            activeTrucks: 5,
            contractLength: 3,
            contractExpiration: '2023/02/18',
            phone: '111-111-1111',
            email: 'sajantha+testcost@qburst.com',
            registrationDate: '2020/02/18',
            status: 'Active',
            producerCode: '',
            producerName: '',
            commissionAmt: 0,
            subscriptionTier: 'LITE',
            discount: 10.0,
            costPerTruck: 1999.0,
            stripeKeyExist: false,
            isSuspended: false,
            stripeId: ''
          },
          {
            companyId: 2205,
            companyName: 'gorillatest',
            dot: '32',
            activeTrucks: 0,
            contractLength: 3,
            contractExpiration: '2023/02/03',
            phone: '012-122-1121',
            email: 'testzoho@test.com',
            registrationDate: '2020/02/03',
            status: 'Active',
            producerCode: '',
            producerName: '',
            commissionAmt: 0,
            subscriptionTier: 'PRO',
            discount: 0.0,
            costPerTruck: 3699.0,
            stripeKeyExist: true,
            isSuspended: false,
            stripeId: '534229629'
          },
          {
            companyId: 2210,
            companyName: 'gorillatest',
            dot: '33',
            activeTrucks: 0,
            contractLength: 3,
            contractExpiration: '2023/02/03',
            phone: '012-122-1121',
            email: 'testzoho3@test.com',
            registrationDate: '2020/02/03',
            status: 'Active',
            producerCode: '',
            producerName: '',
            commissionAmt: 0,
            subscriptionTier: 'PRO',
            discount: 0.0,
            costPerTruck: 3699.0,
            stripeKeyExist: true,
            isSuspended: false,
            stripeId: '191255726'
          },
          {
            companyId: 2266,
            companyName: 'gorillatest',
            dot: '21342134',
            activeTrucks: 1,
            contractLength: 2,
            contractExpiration: '2022/02/18',
            phone: '111-111-1111',
            email: 'sajantha+testtierstg@qburst.com',
            registrationDate: '2020/02/18',
            status: 'Active',
            producerCode: '',
            producerName: '',
            commissionAmt: 0,
            subscriptionTier: 'STANDARD',
            discount: 21.0,
            costPerTruck: 2699.0,
            stripeKeyExist: false,
            isSuspended: false,
            stripeId: ''
          },
          {
            companyId: 2204,
            companyName: 'gorillatest',
            dot: '23234',
            activeTrucks: 1,
            contractLength: 3,
            contractExpiration: '2019/12/05',
            phone: '012-122-1121',
            email: 'sajantha+sub@qburst.com',
            registrationDate: '2020/01/31',
            status: 'Active',
            producerCode: '',
            producerName: '',
            commissionAmt: 0,
            subscriptionTier: 'PRO',
            discount: 0.0,
            costPerTruck: 2699.0,
            stripeKeyExist: true,
            isSuspended: false,
            stripeId: '288305304'
          },
          {
            companyId: 2219,
            companyName: 'gorillatest',
            dot: '673636',
            activeTrucks: 0,
            contractLength: 2,
            contractExpiration: '2022/02/07',
            phone: '',
            email: 'testt@sda.com',
            registrationDate: '2020/02/07',
            status: 'Active',
            producerCode: '',
            producerName: '',
            commissionAmt: 0,
            subscriptionTier: 'PRO',
            discount: 33.0,
            costPerTruck: 3699.0,
            stripeKeyExist: false,
            isSuspended: false,
            stripeId: ''
          },
          {
            companyId: 2197,
            companyName: 'gorillaTest2',
            dot: '21312',
            activeTrucks: 1,
            contractLength: 3,
            contractExpiration: '2023/01/29',
            phone: '122-222-2222',
            email: 'sajantha+gtss@qburst.com',
            registrationDate: '2020/01/29',
            status: 'Active',
            producerCode: '',
            producerName: '',
            commissionAmt: 0,
            subscriptionTier: 'PRO',
            discount: 0.0,
            costPerTruck: 3699.0,
            stripeKeyExist: true,
            isSuspended: false,
            stripeId: '136029554'
          },
          {
            companyId: 2209,
            companyName: 'gorillatestzoho2',
            dot: '3421413',
            activeTrucks: 0,
            contractLength: 3,
            contractExpiration: '2023/02/03',
            phone: '012-122-1121',
            email: 'testzoho2@test.com',
            registrationDate: '2020/02/03',
            status: 'Active',
            producerCode: '',
            producerName: '',
            commissionAmt: 0,
            subscriptionTier: 'PRO',
            discount: 0.0,
            costPerTruck: 3699.0,
            stripeKeyExist: true,
            isSuspended: false,
            stripeId: '296074843'
          }
        ]);
      }
    },
    async fetchData(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: getCompaniesOfServiceCenterApi
          },
          id: payload.apiId
        });
        this.getSuccess(response);
      } catch (error) {
        this.getFailed();
      }
    },
  })
};
