const initialState = {
  list: [],
  companyList: [],
  filteredList: [],
  selectedRow: { index: -1, row: {} },
  data: {},
};

export const fleetCompanyList = {
  state: { ...initialState },
  reducers: {
    listSuccess(state, payload) {
      return { ...state, list: [...payload], filteredList: [...payload] };
    },
    companyListSuccess(state, payload) {
      return { ...state, companyList: [...payload] }
    },
    listFailed() {
      return { ...initialState };
    },
    filterSuccess(state, list) {
      return { ...state, filteredList: [...list] };
    },
    setSuccess(state, data) {
      return {
        ...state,
        data: { ...data }
      };
    },
    setFailed(state) {
      return {
        ...state,
        data: {}
      };
    },
  },
  effects: (dispatch) => ({
    async fetchData(payload) {
      try {
        const getApi = await import(
          `../../../../config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('fleetCompanies');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...apiObject,
              url: `${apiObject.url}?isRenewal=${window.location.href.includes('upcomingRenewals')}`
            }
          },
          id: payload.id
        });
        response && this.listSuccess(response);
      } catch (error) {
        this.listFailed();
      }
    },
    async fetchCompanyList(payload) {
      try {
        const getApi = await import(
          `../../../../config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('companyList');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...apiObject,
              url: `${apiObject.url}`
            }
          },
          id: payload.id
        });
        response && this.companyListSuccess(response);
      } catch (error) {
        this.listFailed();
      }
    },
    async fetchCompanyData(payload) {
      try {
        const getApi = await import(
          `@/config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        let apiObject = null
        if (payload.type === 'add') {
          apiObject = getApi.default('getFleetCompanyDefaultsApi');
        } else {
          apiObject = getApi.default('getFleetCompanyDetailsApi');
        }
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject,
            data: payload.data
          },
          id: payload.apiId,
          callback: payload.callback,
        });
        response && this.setSuccess(response);
      } catch (error) {
        this.setFailed();
      }
    },
    async fetchScores(payload) {
      try {
        const getApi = await import(
          `@/config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        let apiObject = getApi.default('getScoresApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...apiObject,
              url: apiObject.url.replace('{id}', payload.data)
            }
          },
          id: payload.apiId
        });
        payload.callback(response)
      } catch (error) {

      }
    },

    async updateScores(payload, rootState) {
      try {
        const getApi = await import(
          `@/config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        let apiObject = getApi.default('updateScoresApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject,
            data: payload.data
          },
          id: payload.apiId
        });
        if (response) payload.callback()
      } catch (error) {

      }
    },

    async saveData(payload, rootState) {
      try {
        const getApi = await import(
          `@/config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        let apiObject = null
        if (payload.type === 'add') {
          apiObject = getApi.default('addFleetCompanyApi');
        } else {
          apiObject = getApi.default('editFleetCompanyApi');
        }
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject,
            data: payload.data
          },
          id: payload.apiId
        });
        payload.successCallback && payload.successCallback();
      } catch (error) {
        if (payload.type === 'edit') {
          payload.callback('error', error.message, payload);
        }
        // this.addFailed();
      }
    },
  })
};
