import styled from 'styled-components';

const DocWrapper = styled.div`
  a {
    text-decoration: none;
    color: ${(props) =>
      props.color
        ? props.color
        : props.theme.palette.secondary.main
        ? props.theme.palette.secondary.main
        : 'colors.PRIMARY_CONTRAST'};
    cursor: pointer;
  }
`;

export default DocWrapper;
