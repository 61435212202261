import { eventTypes } from '@/utils/constants';
import PropTypes from 'prop-types';
import React, { lazy, useState } from 'react';
import RenderComponent from './RenderComponent';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));

const Filters = ({
  hasApiStatus,
  callback,
  history,
  fleetCompanyList,
  filterList,
  handleReset,
  values,
  setInitialValues
}) => {

  const [validationError, setValidationError] = useState('');

  return (
    <>
      <Form
        type="server"
        direction="row"
        initialValues={{ ...values }}
        className="CSAForms"
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {
          setValidationError('');
          const finalData = { ...fields, eventType: fields.eventType?.value };
          filterList(finalData);
        }}
        handleOnCancel={(fields) => {
          handleReset();
          setInitialValues({
            eventType: null
          });
          setValidationError('');
        }}
        customProps={{
          history,
          callback,
          hasApiStatus,
          validationError,
          fleetCompanyList,
          setInitialValues,
          eventTypes
        }}
      />
    </>
  );
};

Filters.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default Filters;
