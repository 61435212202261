
const initialState = {
  events: {},
  dataUsageList: {},
  videoUrl: '',
  cameraStatus: {},
  fetchLiveUrlNoOfCalls: 0,
  eventTypes: null,
};

let timeoutId = null;
export const camera = {
  state: {...initialState},
  reducers: {
    listSuccess(state, payload) {
      return {...state, ...payload};
    },
    listFailed() {
      return {...initialState};
    },
    updateFetchLiveUrlNoOfCalls(state) {
      return {...state, fetchLiveUrlNoOfCalls: state.fetchLiveUrlNoOfCalls + 1 }
    },
    resetFetchLiveUrlNoOfCalls(state) {
      return {...state, fetchLiveUrlNoOfCalls: 0 }
    },
    listEvents(state, payload) {
      return {...state, eventTypes: payload}
    },
    failedEvents(state) {
      return {...state, eventTypes: []}
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              url: `${window.$environment?.BASE_CAMERA_URL}events`,
              type: 'post'
            },
            data: payload?.data
          },
          id: payload.apiId
        });
        if(response) {
          this.listSuccess({events: {
            data: response?.content,
            total: response?.totalElements
          }});
        } 
      } catch (error) {
        
      }
    },
    async fetchDataUsage(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              url: `${window.$environment?.BASE_CAMERA_URL}/data-consumption`,
              type: 'post'
            },
            data: payload?.data
          },
          id: payload.apiId
        });
        if(response) {
          this.listSuccess({dataUsageList: {
            data: response?.content,
            total: response?.totalElements
          }});
        } 
      } catch (error) {

      }
    },
    async fetchLiveUrl(payload, rootState) {
      try {
        if(!payload?.fromSetTimeOut) {
          this.resetFetchLiveUrlNoOfCalls();
        }
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              url: `${window.$environment?.BASE_CAMERA_URL}camera/live-streaming?cameraSN=${payload.cameraSerialNo}`,
              type: 'get'
            },
          },
          id: payload.apiId
        });
        let errorMessage = '';
        if(rootState?.camera?.fetchLiveUrlNoOfCalls >= 3) {
            errorMessage = "Failed to start the live stream. please try again after some time."
            this.resetFetchLiveUrlNoOfCalls();
            payload.callback('error', "Failed to start the live stream. please try again after some time.")
        }
        if((response?.status === "waitForAwake" || response?.status === "waitForPublish") && rootState?.camera?.fetchLiveUrlNoOfCalls < 3) {
          timeoutId = setTimeout(() => {
            this.updateFetchLiveUrlNoOfCalls();
            this.fetchLiveUrl({...payload, fromSetTimeOut: true});
          }, 10000); 
        }
        if(response) {
          this.listSuccess({videoUrl: {...response, errorMessage: errorMessage }});
          errorMessage = '';
        } 
      } catch (error) {
        this.listSuccess({videoUrl: ''});
      }
    },
    async clearFetchLiveUrlTimeout() {
      if(timeoutId) {
        clearTimeout(timeoutId)
      }
    },
    async fetchCameraStatus(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              url: `${window.$environment?.BASE_CAMERA_URL}camera-status?cameraSN=${payload.cameraSerialNo}`,
              type: 'get'
            }
          },
          id: payload.apiId
        });
        if(response) {
          this.listSuccess({cameraStatus: response});
        } 
      } catch (error) {
        this.listSuccess({cameraStatus: {}});
      }
    },
    async fetchVideoUrl(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              url: `${window.$environment?.BASE_CAMERA_URL}events/clip?clipID=${payload?.data?.clipID}&isOld=${payload?.data?.isOld}`,
              type: 'get'
            }
          },
          id: payload.apiId
        });
        if(typeof response === 'string') {
          payload.callbackSecond('success', response);
        } else {
          payload.callbackFirst(response?.mp4Url)
        }
      } catch (error) {
         payload.callbackSecond('error', error.message);
      }
    },
    async fetchAllEvents(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              url: `${window.$environment?.BASE_CAMERA_URL}events/types`,
              type: 'get'
            }
          },
          id: payload.apiId
        });
        if(response) {
          this.listEvents(response)
        }
      } catch (error) {
        this.failedEvents()
      }
    },
  })
};
