import React from 'react';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import PropTypes from 'prop-types';

const { forwardRef, useImperativeHandle } = React;

const Popper = forwardRef((props, ref) => {
  const { PopperContainer, ModalHeader, ModalContent, DocClose } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  useImperativeHandle(ref, () => ({
    handlePopper(event) {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    }
  }));
  const handleClose = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <div>
      <PopperContainer id={id} open={open} anchorEl={anchorEl} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ModalHeader>
                {props.closeDoc ? (
                  <DocClose onClick={handleClose}></DocClose>
                ) : (
                  ''
                )}
              </ModalHeader>
              <ModalContent> {props.data} </ModalContent>
            </Paper>
          </Fade>
        )}
      </PopperContainer>
    </div>
  );
});

Popper.propTypes = {
  data: PropTypes.any
};

export default Popper;
