import React, { lazy, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { pageUrls } from '@/config/constants/keys';

import ParentStyled from './style';
import { loadStyle, getNewObject, loadPageConfig } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'pages/web/UndefinedDriverList');
const SvgIcon = lazy(() => import(`@/components/SvgIcon/${layout}`));

const layout = window?.$environment?.CURRENT_LAYOUT;
const Tab = lazy(() => import(`@/components/UI/Tab/${layout}`));
const config = loadPageConfig();
const Component = lazy(() => import(`@/${config.logsUdList[0].name}`));
const Index = (parentProps) => {
  const history = useHistory();
  const i18n = useTranslation();
  const { udLogList, fetchUdLogList, getFilters } = parentProps;
  const commonParentProps = { history, i18n, Styled };
  const [totalData, setTotalData] = useState({ hours: 0, distance: 0 });
  const [pagination, setPagination] = useState(false);

  useEffect(() => {
    if (udLogList.filteredList.length) {
      const total = udLogList.filteredList.reduce((sum, item) => ({
        distance: sum.distance + item.distance,
        hours: sum.hours + item.hours
      }));
      setTotalData({ ...total });
    }
  }, [udLogList.filteredList]);
  const udLogApiId = 'udLogList';

  useEffect(() => {
    fetchUdLogList({
      apiId: udLogApiId,
      data: { showUnidentifiedDriver: false }
    });
    getFilters({ apiId: 'fetchFilters' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (document.getElementById('list1')) {
      const isSelected = document
        .getElementById('list1')
        ?.className.includes('Mui-selected');
      document.getElementById('list1').className = !isSelected
        ? document.getElementById('list1').className + ' Mui-selected'
        : document.getElementById('list1').className;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.getElementById('list1')]);

  return (
    <RenderComponent
      {...config.logsUdList[0]}
      parentProps={parentProps}
      commonParentProps={commonParentProps}
      component={Component}
      history={history}
      i18n={i18n}
      udLogList={udLogList}
      udLogApiId={udLogApiId}
      totalData={totalData}
      pagination={pagination}
      setPagination={setPagination}
    />
  );
};

const RenderComponent = ({
  name,
  props,
  commonProps,
  title,
  parentProps,
  commonParentProps,
  component,
  history,
  i18n,
  udLogList,
  verticalTab,
  pagination,
  setPagination,
  ...rest
}) => {
  const Component = component;
  const handleChange = (event, newValue) => {
    history.push(tabValues[newValue].url);
  };
  let exportData = [];
  useEffect(() => {
    udLogList.filteredList.map((item) => {
      return exportData.push({
        Driver: item.driver_name,
        Vehicle: item.vehicle_name,
        Date: item.date,
        'Hours Worked': item.hours,
        Distance: item.distance,
        'Cycle Rule': item.cycleRule
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [udLogList.filteredList]);

  let newProps = props?.length ? getNewObject(parentProps, props) : {};
  newProps = {
    ...newProps,
    ...(commonProps?.length
      ? getNewObject(commonParentProps, commonProps)
      : {}),
    ...rest
  };
  const tabValues = [
    {
      name: i18n.t('logs.manageLogsHeader'),
      value: null,
      url: pageUrls.logsList
    },
    {
      name: i18n.t('logs.undefinedDriverHeader'),
      value: (
        <Component
          {...newProps}
          exportData={exportData}
          setPagination={setPagination}
          pagination={pagination}
        />
      ),
      url: pageUrls.logsUdList
    },
    ...(window.$environment.SERVICE_PROVIDER !== 'spireon' ? [
    {
      name: i18n.t('logs.addLogHeader'),
      value: null,
      url: pageUrls.logsAdd
    },{
      name: i18n.t('logs.archiveLogsHeader'),
      value: null,
      url: pageUrls.archiveLogsList
    }] : []),
    ...(window.$environment.SERVICE_PROVIDER === 'spireon' ? [{
      name: i18n.t('leftMenu.DOTAudit'),
      value: null,
      url: pageUrls.fmcsaLogs
    }] : []),
  ];
  return (
    <Styled.Content>
      <div className="goBack">
        <SvgIcon
          value={'pageBackButton.svg'}
          onClick={() => history.goBack()}
        />
      </div>
      <Tab
        data={tabValues}
        className="logwrapper"
        currentTab={1}
        onChange={handleChange}
        vertical={verticalTab}
      />
    </Styled.Content>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    udLogList: state.udLogList,
    unAssignedEntries: state.unAssignedEntries,
    filters: state.filters
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchUdLogList: (data) => dispatch.udLogList.fetchData(data),
    udFilterSuccess: (data) => dispatch.udLogList.filterSuccess(data),
    udSetSelectedRow: (data) => dispatch.udLogList.setSelectedItem(data),
    fetchUnAssignedEntries: (data) =>
      dispatch.unAssignedEntries.fetchUnAssignedEntries(data),
    saveUnAssignedEntries: (data) => dispatch.unAssignedEntries.saveData(data),
    setUdAssignEntryList: (data) =>
      dispatch.unAssignedEntries.setUdAssignEntryList(data),
    clearUdAssignEntryListById: (data) =>
      dispatch.unAssignedEntries.clearUdAssignEntryListById(data),
    getFilters: (data) => dispatch.filters.fetchData(data),
    clearAll: () =>
      dispatch.unAssignedEntries.clearAll(),
  };
};

export default connect(mapState, mapDispatch)(Index);
