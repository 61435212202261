import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Parent from './style';
const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
padding: 0;
.formWrap {
  padding: 30px 5% 0 20px;
  .MuiFormLabel-root {
    font: bold 12px "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    color: #222 !important;
  }
}
.policyHeader {
  margin-bottom: 18px !important;
}
.userHeading{
  margin-bottom: 0 !important;
}
.spireonNewonly,
.roleSection {
  display: none;
}
.filterField,
.userForm {
  flex: 0 0 50%;
}
.spnocycle {
  display: none;
}
.userForm .basic-multi-select .select__control{
   height: auto !important;
   .select__value-container {
    height: auto !important;
   }
   .select__multi-value {
      position: relative;
      background: #fff;
     .select__multi-value__label{
      padding-left: 13px;
     }
     .select__multi-value__remove {
      color: #999;
      position: absolute;
      top: 3px;
      padding-left: 0;
      padding-right: 0;
     }
   }
}

.licenceNumber {
  border-top: 2px solid #343E48;
  padding-top: 10px;
}
.stateCode{
  border-bottom: 2px solid #343E48;
  padding-bottom: 10px;
}
.spireonDriver {
    color: ${theme.colors.WHITE} !important;
    background: #77838c none repeat scroll 0 0 !important;
    font-size: 15px !important;
    font-weight: bold !important;
}
.spireonDriver.spireonVehicle {
  color: #42505a!important;
  margin: 30px 0 20px;
  padding: 0!important;
  font-size: 12px !important;
  background: transparent!important;
  text-transform: capitalize !important;
}
`}
`;
const LeftWrapper = styled(Parent.LeftWrapper)`
flex: 0 0 49.5%;
.driverCheckbox .normalCheckbox {
  margin: 1px 2% 0 0;
  label{
    font-weight: normal;
  }
}
`;
const RightWrapper = styled(Parent.RightWrapper)`
${({ theme }) => `
flex: 0 0 49.5%;
.documentsHeader{
  margin-bottom: 0 !important;
  color: ${theme.colors.WHITE} !important;
  ul {
    margin-top:0;
  }
}
`}
`;
const documentWrapper = styled(Parent.documentWrapper)`
${({ theme }) => `
padding-right: 5%;
ul {
 margin: 0;
  li {
    .MuiGrid-container .MuiAvatar-rounded {
      width: 27px;
      height: 27px;
    }

  }
}
`}
`;
const submitUser = styled(Parent.submitUser)`
${({ theme }) => `
.submitSection {
  justify-content: center;
  padding: 2% 0;
  .MuiButtonBase-root {
    margin-top: 0;
  }
}
`}

`;
const PolicyProcedure = styled(Parent.PolicyProcedure)`
${({ theme }) => `
padding-right: 5%;
ul {
  border: 0 !important;
  margin-top: 0;
}
li {
  border-bottom:0 !important;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  padding: 8px 10px;
  &:hover {
    background-color: ${theme.colors.BACKGROUND};
  }
  p:first-child {
   align-self: center;
   color: #6d6d6d;
   font-size: 12px;
   a{
    color: #C92100;
   }
  }
  p:last-child {
    color: #222;
    font-size: 12px
  }
}
.signedWrapper {
    p {
    text-align: left !important;
  }
  p:first-child {
    color: #222;
    font-size: 12px;
    }
    p:last-child {
      font-size: 12px;
      color: ${theme.colors.LIST_TEXT};
    }
}
li:nth-child(odd):hover,
li:nth-child(odd) {
    background: #efefef;
}
`}
`;
const Styled = {
  Wrapper,
  RightWrapper,
  LeftWrapper,
  documentWrapper,
  submitUser,
  PolicyProcedure
};
export default Styled;
