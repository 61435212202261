import styled from 'styled-components';
import Parent from '../Base/style';

const IconContainer = styled(Parent.IconContainer)`
.MuiAvatar-root {
  border-radius: 0 !important;
}
`;
const HeaderContainer = styled(Parent.HeaderContainer)`
${({ theme }) => `
display: flex;
flex: 0 0 80%;
justify-content: space-between;
align-self: center;
.MuiBox-root:first-child {
  flex: 0 0 90%;
}
.MuiList-root.MuiList-padding {
  justify-content: flex-start;
  padding-left: 20px;
}
#container {
  padding: 0;
  align-self: center;
  padding-right: 1%;
  padding-right: 2%;
}
#languages {
  font-size: 19px;
  color: ${theme.colors.LANGUAGE_SWITCH};
  background: none !important;
  outline: 0;
  cursor: pointer;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  font-family: ${theme.palette.secondaryFont};
  flex: 0 0 3%;
}
  option {
    color: ${theme.colors.BLACK};
    &:hover {
      background: ${theme.colors.LINK_COLOR};
    }
  }
}
#menu {
  .MuiBox-root:first-child{
    flex: 0 0 100%;
  }
}
`}
`;
const LanguageContainer = styled(Parent.LanguageContainer)`
  
 

`;
const ModalContainer = styled(Parent.ModalContainer)``;
const OptionContainer = styled(Parent.OptionContainer)`
${({ theme }) => `
background: ${theme.colors.BACKGROUND_MENU} none repeat scroll 0 0;
border: 1px solid ${theme.colors.MODAL_BORDER};
padding: 9px 25px;
position: absolute;
right: 19px;
top: 71px;
width: 220px;
z-index: 20;
color: #1589ee;
.MuiMenuItem-root {
  font-size: 20px;
  padding: 0;
  white-space: pre-wrap !important;
  &:hover {
    background-color: transparent;
  }
}
:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 14px 14px 14px;
  border-color: transparent transparent #f4f6f9 transparent;
  top: -14px;
  right: 0px;
}
`}
`;
const Styled = {
  IconContainer,
  HeaderContainer,
  LanguageContainer,
  ModalContainer,
  OptionContainer
};
export default Styled;
