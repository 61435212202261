import React, { useState, lazy } from 'react';
import PropTypes from 'prop-types';

import AddDVIR from './AddForm/Form';
import SaveDVIR from './SaveForm/Form';
import Box from '@material-ui/core/Box';

import { hasValue } from '@/utils/utils';
const Breadcrumb = lazy(() => import(`@/components/Breadcrumb`));

const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Breadcrumb filters={filters} />
      <Component {...contentProps} />
    </Box>
  );
};
export default Index;
const Component = ({
  apiStatus,
  callback,
  history,
  Styled,
  fetchAdvancedConfig,
  advancedConfig,
  updateAdvancedConfigData,
  updateAdvancedConfig,
  i18n,
  saveData
}) => {
  const hasApiStatus = hasValue(apiStatus.status, 'api', fetchAdvancedConfig);
  const [selected, setSelected] = useState('FRONT');
  const category = [
    'FRONT',
    'CURBSIDE FRONT AXLE',
    'CURBSIDE REAR AXLE',
    'REAR',
    'DRIVER REAR AXLE',
    'DRIVER FRONT AXLE',
    'CAB'
  ];
  const objectArray = (list) => {
    return list?.length
      ? list.map((item) => {
          return {
            label: item,
            value: item.replace(/ /g, '_')
          };
        })
      : [];
  };
  const categoryList = objectArray(category);
  return (
    <Styled.Wrapper>
      <Styled.TopWrapper>
        <AddDVIR
          apiStatus={apiStatus}
          history={history}
          callback={callback}
          Styled={Styled}
          hasApiStatus={hasApiStatus}
          updateAdvancedConfig={updateAdvancedConfig}
          category={categoryList}
          selected={selected}
          setSelected={setSelected}
        />
      </Styled.TopWrapper>
      <SaveDVIR
        category={categoryList}
        Styled={Styled}
        hasApiStatus={hasApiStatus}
        apiStatus={apiStatus}
        advancedConfig={advancedConfig}
        i18n={i18n}
        selected={selected}
        setSelected={setSelected}
        saveData={saveData}
        updateAdvancedConfig={updateAdvancedConfigData}
        history={history}
      />
    </Styled.Wrapper>
  );
};

Component.propTypes = {
  history: PropTypes.object.isRequired,
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  fetchAdvancedConfig: PropTypes.func.isRequired,
  advancedConfig: PropTypes.object.isRequired
};
