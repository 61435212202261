import React, { forwardRef } from 'react';
import Payment from '../Base';
import { PaymentWrapper, NameWrapper, InputWrapper } from './style';

const Index = forwardRef((props, ref) => {
  return (
    <Payment
      {...props}
      ref={ref}
      PaymentWrapper={PaymentWrapper}
      NameWrapper={NameWrapper}
      InputWrapper={InputWrapper}
    />
  );
});

export default Index;
