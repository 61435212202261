import React, { lazy, useState } from 'react';
import PropTypes from 'prop-types';

import {
  hasRestrictedRole,
} from '@/utils/utils';
import { ROLES } from '@/config/constants/keys';
const Modal = lazy(() => import(`../../../components/UI/Modal/${layout}`));
const layout = window?.$environment?.CURRENT_LAYOUT;
const Table = lazy(() => import(`../../../components/UI/Table/${layout}`));
const Link = lazy(() => import(`../../../components/UI/Link`));
const DriverStatus = ({ driverStatus, i18n, columns, generateRow, history, pageUrls }) => {
  const [modal, setModal] = useState(false)
  const defaultColumns = columns || [
    {
      id: 'fullName',
      disablePadding: false,
      label: i18n.t('vehicles.fullName')
    },
    {
      id: 'lastKnownLocation',
      numeric: false,
      disablePadding: false,
      label: i18n.t('dashboard.tabs.driverStatus.lastKnownLocation')
    },
    {
      id: 'unitName',
      numeric: true,
      disablePadding: false,
      label: i18n.t('dashboard.tabs.driverStatus.unit')
    },
    {
      id: 'driveTimeRemaining',
      disablePadding: false,
      label: i18n.t('dashboard.tabs.driverStatus.driveTimeRemaining')
    },
    {
      id: 'timeUntilBreak',
      disablePadding: false,
      label: i18n.t('dashboard.tabs.driverStatus.timeUntilBreak')
    },
    {
      id: 'onDutyTimeRemaining',
      numeric: false,
      disablePadding: false,
      label: i18n.t('dashboard.tabs.driverStatus.onDutyTimeRemaining')
    },

    {
      id: 'dutyCycleRemaining',
      numeric: true,
      disablePadding: false,
      label: i18n.t('dashboard.tabs.driverStatus.dutyCycleRemaining')
    },
    {
      id: 'currentStatus',
      disablePadding: false,
      label: i18n.t('dashboard.tabs.driverStatus.currentStatus')
    },
    {
      id: 'engineSpeed',
      numeric: false,
      disablePadding: false,
      label: i18n.t('dashboard.tabs.driverStatus.engineSpeed')
    },
    {
      id: 'lastUpdated',
      numeric: true,
      disablePadding: false,
      label: i18n.t('dashboard.tabs.driverStatus.lastUpdated')
    }
  ];

  const getELDStatusIcon = (data) => {
    if (data === 'NOT_STARTED' || data === 'TAMPERED' || data === 'DISCONNECTED') {
      return "eld-inActive";
    } else if (data === 'CONNECTED') {
      return "eld-active";
    } else if (data === '') {
      return "eld-loggedOut"
    } else {
      return ""
    }
  }
  const generateRows = () => {
    const updatedList = driverStatus?.map((item) => {
      const row = {};
      for (let i = 0; i < item.infoWithLink.length; i++) {
        const rowItem = item.infoWithLink[i];
        if (window.$environment.SERVICE_PROVIDER === 'spireon') {
          if (i === 0) {

            row[defaultColumns[0].id] = rowItem.link ? (
              <Link href={window.location.origin + rowItem.link.replace('.', window.$environment.CURRENT_LAYOUT === 'SpireonOld' ? '' : '/spireon').replace('edit', hasRestrictedRole([ROLES.VIEW_ONLY_FM]) ? 'view' : 'edit' )}>
                {rowItem.item}
              </Link>
            ) : (
              rowItem.item
            );
          } else if (i === item.infoWithLink.length - 1) {
            row[defaultColumns[i].id] = window.$environment.CURRENT_LAYOUT === 'SpireonOld' ? <div className={getELDStatusIcon(rowItem.item)} /> :
              <a onClick={() => {
                if (rowItem.link) {
                  history.push(rowItem.link.replace('.', '/spireon'))
                } else {
                  setModal(true)
                }
              }} ><div className={getELDStatusIcon(rowItem.item)} /></a>
          }
          else {
            row[defaultColumns[i].id] = rowItem.item
          }
        }
        else {
          row[defaultColumns[i].id] = rowItem.link ? (
            <Link href={window.location.origin + rowItem.link.replace('.', '')} newPage>
              {rowItem.item}
            </Link>
          ) : (
            rowItem.item
          );
        }
      }

      return row;
    });
    return updatedList;
  };
  const information = i18n.t('common.tableEmptyMessage');
  return (
    <>
      <Modal
        open={modal}
        setOpen={() => setModal(m => !m)}
        withCloseIcon
        alignItemsLeft
        noDataPopup
        data={{
          header: '',
          content: () => <div >No Logs available</div>
        }}
      />
      <Table
        type="client"
        title=""
        columns={[...defaultColumns]}
        data={generateRow ? generateRow() : generateRows()}
        emptyMessage={information}
        itemsPerPage={10}
        actions={[]}
        i18n={i18n}
        noPagination
      />
    </>
  );
};

export default DriverStatus;

DriverStatus.defaultProps = {};

DriverStatus.propTypes = {
  driverStatus: PropTypes.array.isRequired
};
