import { logBookStatusApi } from '../../../config/apis';

const initialState = {};

export const logBookStatus = {
  state: { ...initialState },
  reducers: {
    logBookStatusSuccess(state, payload) {
      return {
        ...payload
      };
    },
    logBookStatusFailed() {
      return { ...initialState };
    }
  },
  effects: (dispatch) => ({
    async handleLogBookStatus(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            data: { ...payload.data },
            api: logBookStatusApi
          },
          id: payload.apiId
        });
        this.logBookStatusSuccess(response);
      } catch (error) {
        this.logBookStatusFailed();
      }
    }
  })
};
