import React, { lazy } from 'react';
import PropTypes from 'prop-types';

import { getImagePath } from '@/utils/utils';
import Styled from './style';
const layout = window?.$environment?.CURRENT_LAYOUT;

const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));

const Toggle = ({ item, setCurrentEld, src }) => {
  return (
    <Styled.ToggleContainer>
      <Icon
        src={getImagePath(src)}
        variant="square"
        onClick={() => {
          setCurrentEld(item);
        }}
      />
    </Styled.ToggleContainer>
  );
};

export default Toggle;

Toggle.defaultProps = {
  drivers: {},
  regions: {}
};

Toggle.propTypes = {
  setCurrentEld: PropTypes.func.isRequired,
  item: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired
};
