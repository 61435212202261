import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
    ${({ theme }) => `
    padding: 2%;
    padding-top: 0;
    padding-bottom: 4%;
    
    `}
`;
const LeftWrapper = styled(Parent.LeftWrapper)`
    ${({ theme }) => `
    .active-region {
        background-color: ${theme.colors.LINK_COLOR};
    }
    .subheaderSection {
        padding: 11px 6%;
        font-size: ${theme.palette.titleLabel};
        margin-bottom:2px !important;
        // text-transform: capitalize;
      }
    `}
`;
const TerminalWrapper = styled(Parent.TerminalWrapper)`
    ${({ theme }) => `
    .Nodata {
      font-size: ${theme.palette.titleLabel};
    }
    `}
`;
const RegionWrapper = styled(Parent.RegionWrapper)`
    ${({ theme }) => `
    background-color: #CACACA;
    border-bottom: 1px solid ${theme.colors.PRIMARY_MAIN};
    .region {
        color: #333333;
        font-size: ${theme.palette.addbuttonFont};
    }
    :hover {
        background-color: ${theme.colors.LINK_COLOR};
    }
    `}
`;
const RegionListWrapper = styled(Parent.RegionListWrapper)`
    ${({ theme }) => `
    `}
`;
const TerminalListWrapper = styled(Parent.TerminalListWrapper)`
    ${({ theme }) => `
    `}
`;
const Department = styled(Parent.Department)`
    ${({ theme }) => `
    .buttonSection{
      display: flex;
    }
    .buttonStyle {
        margin-top: 0px !important;
    }
    .addRegion{
      margin-right: 10px;
    }
    `}
`;
const RightWrapper = styled(Parent.RightWrapper)`
    ${({ theme }) => `
    .subheaderSection {
        // text-transform: capitalize;
        font-size: ${theme.palette.titleLabel};
        margin-bottom:2px !important;
    }
    .MuiPaper-root{
        box-shadow: none;
    }
    `}
`;
const ToolsContainer = styled(Box)`
  ${({ theme, selectedindex }) => `
  position: absolute;
  background: ${
    (theme.palette.primary || {}).main || theme.colors.PRIMARY_MAIN
  };
  border: solid 2px
    ${(theme.palette.primary || {}).border || theme.colors.PRIMARY_BORDER};
  top: ${selectedindex ? selectedindex + 'px' : '280px'};
  right: 25px;
  li {
    list-style: none;
  }
  a {
    color: ${
      (theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN
    };
    font-size: 20px;
  }
  .ToolsOptionClose {
    color: ${
      (theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN
    };
    position: absolute;
    margin-top: 10px;
    margin-right: 8px;
  }

  ul {
    margin-top: 20px;
    width: 175px;
    height: 75px;
    padding-inline-start: 25px;
  }
  cursor: pointer;
  `}
`;
const Styled = {
    Wrapper,
    LeftWrapper,
    RightWrapper,
    RegionListWrapper,
    TerminalListWrapper,
    RegionWrapper,
    TerminalWrapper,
    Department,
    ToolsContainer
  };
  export default Styled;
