import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import CircularProgress from '@material-ui/core/CircularProgress';

import Label from '../../../components/UI/FormLabel/Clutch';
import SubHeader from '../../../components/SubHeader/Clutch';
import Message from '../../../components/UI/Message/Clutch';
import Link from '../../../components/UI/Link';
import Button from '../../../components/UI/Button/Clutch';
import { imageUrls, pageUrls } from '../../../config/constants/keys';
import { getImagePath, getLogo } from '../../../utils/utils';

const layout = window.$environment?.CURRENT_LAYOUT;
const Input = lazy(() => import(`../../../components/UI/Input/${layout}`));
const CheckBox = lazy(() =>
  import(`../../../components/UI/Checkbox/${layout}`)
);
const FormStyle = require(`../../../components/UI/Form/${layout}/serverStyle`)
  .default;

const RenderComponent = ({
  values,
  handleEdit,
  errors,
  isNotValid,
  validateForm,
  customProps,
  submitAction
}) => {
  const { i18n } = useTranslation();
  return (
    <>
      <customProps.Styled.LoginHeader>
        <img alt ="logo" src={getLogo(imageUrls.clutchLogo)} className="ClutchLogo"></img>
        <SubHeader
          variant="h2"
          text={i18n.t('Login to your account')}
          mode="light"
          className="loginHeader"
        />
      </customProps.Styled.LoginHeader>
      {customProps.hasLoginApiStatus?.errorMessage && (
        <customProps.Styled.LoginError>
          <Message
            type="error"
            message={customProps.hasLoginApiStatus.errorMessage}
          />
        </customProps.Styled.LoginError>
      )}
      <customProps.Styled.userNameWrapper>
        <FormStyle.FieldsWrapper width="100%">
          <Label variant="body1" mode="light" className="labelSize">
            {i18n.t('Username')}
          </Label>
          <FormStyle.TextWrapper mode="light">
            <Field
              name="username"
              handleOnChange={(event) =>
                handleEdit(event, { field: 'username', type: 'username' })
              }
              as={Input}
              mode="light"
              placeholder={i18n.t('Username or Email')}
              value={values.username}
              type="text"
              id="usernameLogin"
              onLoadFocus
            />
            {errors['username'] && !isNotValid && (
              <Message type="error" message={errors['username']} />
            )}
          </FormStyle.TextWrapper>
        </FormStyle.FieldsWrapper>
      </customProps.Styled.userNameWrapper>
      <customProps.Styled.passwordWrapper>
        <FormStyle.FieldsWrapper width="100%">
          <Label variant="body1" mode="light">
            {i18n.t('common.password')}
          </Label>
          <FormStyle.TextWrapper className="passwordField">
            <Field
              name="password"
              handleOnChange={(event) =>
                handleEdit(event, { field: 'password', type: 'password' })
              }
              as={Input}
              mode="light"
              placeholder={i18n.t('common.password')}
              value={values.password}
              type="password"
              id="loginPassword"
            />
            <img
              src={getImagePath(imageUrls.passwordIcon)}
              alt="logedIcon"
              onClick={() => {
                document.getElementById('loginPassword').type =
                  document.getElementById('loginPassword').type === 'password'
                    ? 'text'
                    : 'password';
              }}
            />
            {errors['password'] && !isNotValid && (
              <Message type="error" message={errors['password']} />
            )}
          </FormStyle.TextWrapper>
        </FormStyle.FieldsWrapper>
      </customProps.Styled.passwordWrapper>
      <customProps.Styled.PasswordHandler>
        <CheckBox
          type={window.$environment.WHITE_THEME && window.$environment.SERVICE_PROVIDER != 'simplex' ? "NormalGrey" :"NormalWhite"}
          checked={values.rememberMe}
          className="remember"
          label={i18n.t('Remember me')}
          onClick={(event) =>
            handleEdit(event, { field: 'rememberMe', type: 'checkbox' })
          }
        />
        <Link href={pageUrls.recovery}>{i18n.t('Forgot password?')}</Link>
      </customProps.Styled.PasswordHandler>
      <customProps.Styled.LoginButtonWrapper>
        <FormStyle.ButtonsWrapper>
          <Button
            type="submit"
            label={
              customProps?.hasLoginApiStatus?.isLoading ? (
                <CircularProgress color="inherit" />
              ) : (
                i18n.t('Login')
              )
            }
            onClick={() => {
              const validateResponse = validateForm();
              validateResponse.then((data) => {
                submitAction(data);
              });
            }}
            id="loginButton"
          />
        </FormStyle.ButtonsWrapper>
      </customProps.Styled.LoginButtonWrapper>
      {(window.$environment.SERVICE_PROVIDER === 'clutch' || window.$environment.SERVICE_PROVIDER === 'at') && <customProps.Styled.registerSection>
        <span className="registerLabel">Don't have an account?</span>
        <a href="/registration" className="linkToregister">
          Click here to Register
        </a>
      </customProps.Styled.registerSection>}
    </>
  );
};

export default RenderComponent;

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  customProps: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};
