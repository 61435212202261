import React, { useEffect, lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { hasRestrictedRole } from '@/utils/utils';
import { ROLES } from '@/config/constants/keys'
import Box from '@material-ui/core/Box';

import ParentStyled from './style';
import { loadStyle, loadPageConfig } from '@/utils/utils';
import RenderComponent from '@/components/modules/DynamicRenderPage';

let Styled = loadStyle(ParentStyled, 'pages/web/Parts');

const Breadcrumb = lazy(() => import(`@/components/Breadcrumb`));
const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.partsInventoryEnabled[0].name}`));
const Content = lazy(() => import(`@/${config.partsInventoryEnabled[1].name}`));
const TopActions =
  config.partsInventoryEnabled[0].children &&
  lazy(() => import(`${config.partsInventoryEnabled[0].children.name}`));

const Parts = (parentProps) => {
  const {
    partsList,
    i18n,
    history,
    theme,
    fetchParts,
    filters,
    callback
  } = parentProps;
  // const history = useHistory();
  // const theme = useTheme();
  // const i18n = useTranslation();

  const apiId = 'fetchParts';
  // const hasFetchApiStatus = hasValue(apiStatus.status, 'api', apiId);
  const basicRequest = {
    displayLength: '10',
    displayStart: '1',
    sortCol: 'partName',
    sortDir: 'asc'
  };
  const [request, setRequest] = useState({
    ...basicRequest
  });

  const changeRequest = (fields) => {
    setRequest({ ...basicRequest, ...fields });
  };
  useEffect(() => {
    fetchParts({
      apiId,
      data: { ...request }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request]);
  const viewOnly = hasRestrictedRole([ROLES.VIEW_ONLY_FM])
  const commonParentProps = {
    history,
    i18n,
    Styled,
    theme,
    fetchParts,
    filters,
    partsList
  };
  return (
    <>
      <RenderComponent
        {...config.partsInventoryEnabled[0]}
        parentProps={{
          ...parentProps,
          changeRequest,
          request,
        }}
        commonParentProps={commonParentProps}
        component={Title}
        childComponent={TopActions}
      />
      <RenderComponent
        {...config.partsInventoryEnabled[1]}
        parentProps={{
          ...parentProps,
          changeRequest,
          request,
          viewOnly
        }}
        commonParentProps={commonParentProps}
        component={Content}
      />
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    partsList: state.partsList,
    filters: state.filters
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchParts: (data) => dispatch.partsList.fetchData(data),
    getFilters: (data) => dispatch.filters.fetchData(data),
    saveParts: (data) => dispatch.partsList.saveParts(data),
    updateParts: (data) => dispatch.partsList.updateParts(data),
    handleDeleteAlert: (data) => dispatch.partsList.handleDeleteAlert(data)
  };
};

export default connect(mapState, mapDispatch)(Parts);
Parts.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  fetchParts: PropTypes.func.isRequired
};
