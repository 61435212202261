import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
  display: flex;
  justify-content: space-around;
  padding: 2% 11% 5%;
  align-items: flex-end;
  flex-direction: column;
  .basicMeasures {
    border-radius: 4px;
    margin: 20px 0px;
  }
  .basicMeasures {
    ${window.$environment.WHITE_THEME ? `background: white;` : ''}
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flexbox;
    display: flex;
  }
  .chartContainer {
    margin-right: 20px;
    cursor: pointer;
    width: 30%;
    .legend {
      padding-bottom: 20px;
    }

    .legend ul {
      display: flex;
      width: 100%;
      justify-content: center;
      padding-left: 15px;
    }

    .legend ul li {
      width: 23%;
    }

    .legendValue .label {
      padding-left: 8px;
      font-size: 16;
      font-family: 'Yanone Kaffeesatz';
      color: #c0bbb7;
    }
  }
  .measures {
    display: flex;
    justify-content: center;
    .chartContainer {
      width: 40%;
      display: inline-block;
      margin-right: 40px;
    }
  }
  .crashSummary {
    width: 100%;
    flex-direction: column;
    align-items: center;
    .chartContainer {
      margin: 20px 0px;
      width: 50%;
    }
  }
  .dashboard_csa_wrapper {
    border-radius: 4px;
    margin: 20px 0px;
    width: 48%;
    display: block;
    // flex-direction: column;
  }
  .dashboard_csa_wrapper:first-child,
  .dashboard_csa_wrapper:last-child {
    width: 100%;
  }
  .panel-content {
    padding: 10px;
    font-size: 13px;
  }
  .panelHeading {
    padding: 10px 15px;
    color: #999999;
    text-align: center;
    font-size: 25px;
    font-weight: 700;
    .sub-heading {
      display: block;
      font-size: 16px;
      font-weight: 400;
      color: #c0bbb7;
    }
  }
  .bottomWrapper {
    .inspection {
      display: flex;
      flex-direction: column;
      width: 15%;
      flex: 0 0 22.5%;
      max-width: 180px;
      border-radius: 4px;
      margin-left: 40px;
    }

    .overall-inspection {
      font-size: 18px;
      padding: 10px 15px;
      text-transform: capitalize;
      color: #c0bbb7;
      text-align: center;
    }

    .inspection-content {
      padding-bottom: 25px;
      padding-top: 17px;
    }

    .inspection-content p {
      padding: 1px 0;
      margin: 0;
      font-size: 16px;
      text-align: center;
      line-height: 1;
    }

    .inspection:nth-child(1) {
      border: 2px solid #224f9c;
    }

    .inspection:nth-child(2) {
      border: 2px solid #902121;
    }

    .inspection:nth-child(3) {
      border: 2px solid #bb7a0f;
    }

    .inspection:last-child {
      border: 2px solid #158f43;
    }

    .inspection:nth-child(1) .overall-inspection {
      background-color: #224f9c;
      border: 1px solid #224f9c;
    }

    .inspection:nth-child(2) .overall-inspection {
      background-color: #902121;
      border: 1px solid #902121;
    }

    .inspection:nth-child(3) .overall-inspection {
      background-color: #bb7a0f;
      border: 1px solid #bb7a0f;
    }

    .inspection:last-child .overall-inspection {
      background-color: #158f43;
      border: 1px solid #158f43;
    }
    .bottomBoxWrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
  }
  .middleWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .section {
      flex: 0 0 42%;
      align-self: flex-start;
    }
    table {
      border-collapse: collapse;
    }
    #csadriver_details tr th,
    #csadriver_details tr td {
      color: #c0bbb7;
      font-family: Yanone Kaffeesatz;
      font-size: 15px;
      font-weight: 400;
      line-height: 1.4;
    }

    #csadriver_details thead th {
      text-align: center;
    }

    #csadriver_details tr {
      border-bottom: 0.5px solid #c0bbb7;
    }

    #csadriver_details tr:last-child {
      border-bottom: 0;
    }

    .TableWrapper {
      text-align: center;
    label {
      font-size: 14px;
    }
    }
  }
`;

const Styled = {
  Wrapper
};
export default Styled;
