import React, { lazy, Suspense, useEffect, useState } from 'react';

import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router } from 'react-router-dom';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import getEnvironment from './config/servers';
import { gsStyle } from './utils/style/rangePicker';
const env = process.env.REACT_APP_MODE;
getEnvironment(env, window.location.host);
const SERVICE_PROVIDER = window.$environment?.SERVICE_PROVIDER

if (SERVICE_PROVIDER) {
  var store = require('./controller/store').default;
  var Routes = lazy(() => import(`./routes`));
  var getLocale = require('./config/locales/i18n');
  var i18n = getLocale.default(window.$environment?.SERVICE_PROVIDER);
  var { theme, GlobalTheme } = require('./services/theme').default;
}

const gMapApiKey = window.$environment?.GMAP_KEY;
const gAnalyticsKey = window.$environment?.GANALYTICS_KEY;
// const zenDeskKey = window.$environment?.ZENDESK_KEY;
const script = `https://maps.google.com/maps/api/js?key=${gMapApiKey}&libraries=geometry&language=${localStorage.getItem('gsLanguage')}`;
const twilioScript =
  'https://media.twiliocdn.com/sdk/js/chat/v3.3/twilio-chat.min.js';
const markerClusterScript =
  'https://unpkg.com/@google/markerclustererplus@4.0.1/dist/markerclustererplus.min.js';

const googleTagManagerScript = `https://www.googletagmanager.com/gtag/js?id=${gAnalyticsKey}`;
const googleTagManagerScriptClutch = 'https://www.googletagmanager.com/gtag/js?id=UA-171507546-1';
const faceBookScript = 'https://www.facebook.com/tr?id=346489423406607&ev=PageView&noscript=1';
// const zendeskChatScript = `https://static.zdassets.com/ekr/snippet.js?key=${zenDeskKey}`
const loadScript = (src, id) => {
  return new Promise((resolve, reject) => {
    let script = document.createElement('script');
    script.src = src;
    // script.id = id;
    script.addEventListener('load', function () {
      resolve();
    });
    script.addEventListener('error', function (e) {
      reject(e);
    });
    document.body.appendChild(script);
  });
};

const App = () => {
  const [themeLoaded, setThemeLoaded] = useState(false);
  const [updatedTheme, setUpdatedTheme] = useState({});
  const [mapLoaded, setMapLoaded] = useState(false);
  const [markerClusterLoaded, setMarkerClusterLoaded] = useState(false);

  useEffect(() => {
    loadScript(script, 'mapScript').then(() => {
      setMapLoaded(true);
    });
    let head = document.head || document.getElementsByTagName("head")[0]
    let style = document.createElement("style")
    head.appendChild(style)
    style.type = "text/css"
    style.appendChild(document.createTextNode(gsStyle(theme)))
  // if (window.$environment?.SERVICE_PROVIDER === 'tp') {
  //   loadScript(zendeskChatScript, 'ze-snippet').then(()=>{
  //   window.zESettings = {
  //     webWidget: {
  //        color: {
  //       theme: theme.colors.SECONDARY_MAIN,
  //           button: theme.colors.SECONDARY_MAIN,
  //           launcherText: theme.colors.HEADER_TEXT,
  //           header: theme.colors.SECONDARY_MAIN,
  //        },
  //        launcher: {
  //         chatLabel: {
  //           '*': ' '
  //         },
  //         label: {
  //           '*': ' ',
  //         }
  //       },
  //       chat: {
  //         menuOptions: {
  //           emailTranscript: false
  //         }
  //     }
  //  }
  // }
  //   })
  // }
    if (window.$environment?.SERVICE_PROVIDER !== 'spireon') {
      loadScript(twilioScript, 'twilioScript');
    }

    loadScript(markerClusterScript, 'markerClusterScript').then(() => {
      setMarkerClusterLoaded(true);
    });
    if (env === 'production' && window.$environment?.SERVICE_PROVIDER === 'gs') {
      loadScript(googleTagManagerScript, 'googleTagManagerScript').then(() => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', 'UA-69509752-1');
      });
    }
    if (env === 'production' && window.$environment?.SERVICE_PROVIDER === 'clutch' && window.location.href.includes('registration')) {
      loadScript(googleTagManagerScriptClutch, 'googleTagManagerScriptClutch').then(() => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', 'UA-171507546-1');
      });
    }
  }, []);

  const updateTheme = (data) => {
    sessionStorage.setItem("reseller_data", JSON.stringify(data))
    let newTheme = {
      ...theme,
      colors: {
        ...theme.colors,
        LOGIN_BUTTON: data.theme,
        LINK_COLOR: data.theme,
        MULTISELECT_BACKGROUND: data.theme,
        TAB_BOTTOM_BORDER: data.theme,
        MENU_BORDER_COLOR: data.theme,
      }
    }
    setUpdatedTheme(newTheme)
    setThemeLoaded(true)
  }
  useEffect(() => {
    if (window.$environment?.SERVICE_PROVIDER == 'eldx') {
      if (window.location.pathname.split("/")[1]) {
        sessionStorage.setItem("eldX_reseller", window.location.pathname.split("/")[1])
        store.dispatch.login.fetchResellerData({
          apiId: 'resellerDetailsApi',
          callback: updateTheme
        })


      }
    }
  }, [])

  const ADMIN_ROOT = '/admin';
  const WEB_ROOT = '/web/';
  return (
    <>
      {SERVICE_PROVIDER ? (
        <I18nextProvider i18n={i18n}>
          <Provider store={store}>
            <GlobalTheme />
            <MuiThemeProvider theme={themeLoaded ? updatedTheme : theme}>
              <CssBaseline />
              <ThemeProvider theme={themeLoaded ? updatedTheme : theme}>
                <Router>
                  <Route
                    path="/"
                    render={({ history, ...rest }) => {
                      return (
                        <Suspense fallback={null}>
                          {mapLoaded && markerClusterLoaded && (
                            <Routes
                              {...rest}
                              history={history}
                              folderPath={
                                history.location.pathname.indexOf(ADMIN_ROOT) === 0
                                  ? ADMIN_ROOT
                                  : WEB_ROOT
                              }
                            />
                          )}
                        </Suspense>
                      );
                    }}
                  />
                </Router>
              </ThemeProvider>
            </MuiThemeProvider>
          </Provider>
        </I18nextProvider>
      ) : null}
    </>
  );
};

export default App;
