import styled from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => `
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  padding-bottom: 2%;
  color: ${theme.colors.PRIMARY_CONTRAST};
  .appliedBreadcrumb {
    padding-right: 15px;
    font-size: 18px;
    color: ${theme.colors.PRIMARY_CONTRAST};
  }
  .breadCrumbTag {
    cursor: pointer;
    border: 2px solid ${theme.colors.PRIMARY_BORDER};
    border-right: 0;
    background: ${theme.colors.PRIMARY_MAIN};
    color: ${theme.colors.LINK_COLOR};
    padding: 0 20px;
    font-size: 14px;
    height: 21px;
    position: relative;
    display: flex;
    .arrow {
      display: none;
    }
    ::before {
      position: absolute;
      content: '';
      border-top: 12px solid transparent;
      border-bottom: 11px solid transparent;
      border-left: 12px solid ${theme.colors.PRIMARY_BORDER};
      z-index: 1;
      right: -12px;
      top: -3px;
    }
    ::after {
      position: absolute;
      content: '';
      border-top: 9px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 9px solid ${theme.colors.PRIMARY_MAIN};
      z-index: 1;
      right: -9px;
      top: 0.6px;
    }
  }
  `}
`;

const Styled = {
  Wrapper
};
export default Styled;
