import { driverStatusRefreshApi } from '../../../config/apis';

const initialState = {
  driverStatus: [],
  totalElements: 0
};

export const driverStatusList = {
  state: { ...initialState },
  reducers: {
    listSuccess(state, payload) {
      return {
        ...state,
        ...payload
      };
    },
    listFailed() {
      return { ...initialState };
    }
  },
  effects: (dispatch) => ({
    async refreshData(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: driverStatusRefreshApi,
            data: payload.data.data
          },
          id: payload.apiId
        });

        response &&
          this.listSuccess({
            driverStatus: response?.content,
            totalElements: response?.totalElements
          });
      } catch (error) {
        this.listFailed();
      }
    }
  })
};
