import styled from 'styled-components';
import Popper from '@material-ui/core/Popper';
import { imageUrls } from '../../../../config/constants/keys';
import { getImagePath } from '../../../../utils/utils';

export const PopperContainer = styled(Popper)`
  .MuiPaper-root {
    background: ${(props) =>
        props.theme.palette.primary
          ? props.theme.palette.primary.main
          : props.theme.colors.PRIMARY_MAIN}
      none repeat scroll 0 0;
    border: 2px solid
      ${(props) =>
        props.theme.palette.primary
          ? props.theme.palette.primary.light
          : props.theme.colors.LIGHT_BORDER};
    color: ${(props) =>
      props.theme.palette.secondary
        ? props.theme.palette.secondary.main
        : props.theme.colors.SECONDARY_MAIN};
    font: 22px Yanone Kaffeesatz;
    left: 0;
    padding: 25px;
    position: absolute;
    top: 100%;
    width: 250px;
    z-index: 1;
  }
`;

export const DocClose = styled.div`
  background: rgba(0, 0, 0, 0) url(${getImagePath(imageUrls.popupClose)})
    no-repeat scroll 0 0;
  height: 14px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 14px;
  z-index: 2;
  cursor: pointer;
`;
export const ModalContent = styled.div``;

export const ModalHeader = styled.div``;
