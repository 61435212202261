import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
    padding: 0;
    position: relative;
  .logsInfoDisplay {
    font-size: 12px;
    margin-bottom: 0;
    background: -webkit-linear-gradient(top, #d0d8db 0%, #b3bec3 100%);
    padding: 8px 30px;
    padding-left: 1%;
    font-weight: bold;
    color: #42505a;
  }
`}
`;

const Styled = { Wrapper };

export default Styled;
