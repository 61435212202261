import styled from 'styled-components';
import Parent from './style';

const ExportWrapper = styled(Parent.ExportWrapper)``;
const Header = styled(Parent.Header)`
${({ theme }) => `
padding: 10px 2% 50px 20%;
padding-bottom: 0;
justify-content: flex-start;
position: relative;
.udcheckWrapper{
  flex: 0 0 20%;
  padding: 0;
  padding-bottom: 30px !important;
  .MuiFormControlLabel-root {
    min-width: 219px;
    .MuiFormControlLabel-label {
      font-size: 20px !important;
    }
  } 
}
.filterForm {
  padding-top: 0;
  p {
    font-size: ${theme.palette.headerFont};
  }
}
div[type='text'],
div[type='date'] {
  width: 19% !important;
}
.exportWrapper {
  padding-top: 3px;
  padding-bottom: 30px !important;
  .exportWrapper {
    font-size: 18px;
  }
}
.breadcrumbClass{
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 60px;
}
`}
`;
const InfoContainer = styled(Parent.InfoContainer)`
border-top: 2px solid #000;
margin-top: 15px;
padding-top: 0;
.seperator {
  justify-content: flex-end;
  flex-direction: column;
  p:first-child {
    color: #000;
    font-size: 14px;
    text-align: right;
    padding-top: 0;
  }
  :first-child {
    padding-top: 0;
  }
}
p:last-child {
  font-size: 18px;
}
`;
const ToolsContainer = styled(Parent.ToolsContainer)`
${({ theme }) => `
border: 1px solid #ddd;
background: ${theme.colors.WHITE};
right: 2%;
`}
`;
const UdLists = styled(Parent.UdLists)``;
const Arrowdown = styled(Parent.Arrowdown)`
${({ theme }) => `
.udlogExpandedtable{
  border-top: 2px solid #ddd;
  background: #fff;
  form{
    padding: 0;
  }
  .MuiTable-root .MuiTableBody-root tr:hover, 
  .MuiTable-root .MuiTableBody-root tr {
      background-color: ${theme.colors.WHITE} !important;
  }
}
.MuiGrid-root {
  width: 10%;
  img {
    width: 17px;
    height: 16px;
  }
}
`}
`;
const UdlogTable = styled(Parent.UdlogTable)`
${({ theme }) => `
padding: 1px 2%;
border-top: 2px solid ${theme.colors.BLACK};
.spireonNewOnly,
.spireonOldOnly{
  display: none;
}

`}
`;
const UnidentifiedSection = styled(Parent.UnidentifiedSection)`
${({ theme }) => `
.MuiTableHead-root tr:nth-child(odd) {
  background-color: ${theme.colors.WHITE} !important;
}
.MuiTableHead-root .MuiTableCell-root {
  font-size: 14px !important;
}
  .MuiPaper-root .MuiBox-root:first-child {
    overflow-x: initial;
  }
  .select__option{
    font-size: 12px !important;
  }
  .select__control {
    color: #373737 !important;
    background: rgb(235, 235, 228);
    border: 0;
    min-height: 28px;
  }
  .Selectclass,
  .MuiInputBase-root{
    border: 1px solid #d0d0d0 !important;
  }
  input {
    padding-left: 2px !important;
  }
  .saveButton{
    justify-content: flex-start;
    border-top: 1px solid #ddd;
    border-bottom: 2px solid #ddd;
    padding-bottom: 5px;
  }
  #button{
    padding: 0;
  }
  .select--is-disabled{
    pointer-events: auto;
  }
  .MuiInputBase-root.Mui-disabled{
    opacity: 0.8;
    cursor: no-drop;
  }
  .select__control.select__control--is-disabled:hover {
    cursor: no-drop;
  }
  .MuiInputBase-input.Mui-disabled{
    opacity: 1 !important;
    :hover {
      cursor: no-drop;
    }
  }
  input:focus{
    border: 0 !important; 
  }
  input,
  .MuiInputBase-root {
    font-size: 12px !important;
  }
.Selectclass{
  .select__control .select__single-value {
    font-size: 12px;
    top: 47%;
  }
  .select__placeholder{
    top: 43%;
    font-size: 12px !important;
  }
}
`}
`;
const Content = styled(Parent.Content)`
${({ theme }) => `
header {
  margin-left: 20%;
  padding-top: 21px;
  padding-bottom: 8px;
  border-bottom: 2px solid ${theme.colors.BLACK};
}
.logwrapper {
  min-height: calc(100vh - 77px);
  .MuiPaper-root{
    padding-bottom: 0;
  }
  .MuiTab-root {
      min-width: 100px;
  }
  .MuiButtonBase-root.MuiTab-root{
    padding: 0;
    line-height: 1.4;
    margin-right: 1%;
  }
  .MuiTabs-fixed{
    padding-bottom: 5px;
  }
  .MuiTab-root:hover .MuiTab-wrapper{
    color: #000;
  }
}
.logwrapper .MuiTabs-root {
  padding-left: 0;
}
`}
`;

const Styled = {
    ExportWrapper,
    Header,
    InfoContainer,
    ToolsContainer,
    UdLists,
    Arrowdown,
    UdlogTable,
    UnidentifiedSection,
    Content
  };
  
  export default Styled;