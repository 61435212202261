import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
  ${({ theme }) => `
  .auditSectionTitle{
    color: #c0bbb7;
}
form {
    justify-content: flex-start;
    .vertical {
        margin-right: 1%;
        width: 19%;
        @media only screen and (min-width: ${theme.breakpoints.values.smd}px) and (max-width: ${theme.breakpoints.values.md}px) {
                width:48%;
                margin-right: 2%;
            }
    }
    .MuiTypography-body1{
        font-size: 20px;
    }
    div[type="error"] p {
        font-size: 17px;
    }
    button.MuiButtonBase-root {
        padding:0;
        margin-top: 24px;
        @media (max-width: ${theme.breakpoints.values.md}px) {
            margin-top: 10px;
        }
    }
}
div[type="info"] p {
    margin-top: 25px;
    font-size: 16px;
    padding-bottom: 10px;
}
`}
`;
const Content = styled(Box)`
  .logwrapper {
    // overflow: hidden;
    .MuiTabs-root {
      padding-left: 3%;
    }
  }
`;

const Styled = { Wrapper, Content };
export default Styled;
