import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
position: relative;
.CSAForms .vertical-button .MuiButtonBase-root {
    margin-top: 33px;
}
`;
const TopWrapper = styled(Parent.TopWrapper)`
 
`;
const TableWrapper = styled(Parent.TableWrapper)`
 
`;
const Styled = { Wrapper, TopWrapper, TableWrapper };

export default Styled;
