import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
    ${({ theme }) => `
    padding-left: 1%;
    padding-right: 1%;
    padding-bottom: 1%;

    .vehicleModal{
        background-color: #ffffff !important;
    }
    `}
`;
const LeftWrapper = styled(Parent.LeftWrapper)`
    ${({ theme }) => `
    .active-region {
        background-color: ${theme.colors.ACTIVE_ROW};
        // color: white;
      }
    .subheaderSection{
        background-color: ${theme.colors.BACKGROUND} !important;
        font-size: 20px;
        text-transform: capitalize;
        color: #000000 !important;
    }
    `}
`;
const TerminalWrapper = styled(Parent.TerminalWrapper)`
    ${({ theme }) => `
      .terminal{
          font-size: 15px;
      }
      .Nodata {
        font-size: 15px;
      }
    `}
`;
const RegionWrapper = styled(Parent.RegionWrapper)`
    ${({ theme }) => `
    :hover {
        background-color: ${theme.colors.ACTIVE_ROW};
        // color: white;
      }
      .region {
         font-size: 15px;
      }
    `}
`;
const RegionListWrapper = styled(Parent.RegionListWrapper)`
    ${({ theme }) => `
    `}
`;
const TerminalListWrapper = styled(Parent.TerminalListWrapper)`
    ${({ theme }) => `
    `}
`;
const Department = styled(Parent.Department)`
    ${({ theme }) => `
    background-color: #ffffff;
    .action-wrapper{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .MuiButtonBase-root{
            margin-top: 0;
        }
    }
    .AddButton {
        display: flex;
        justify-content: flex-end;
        margin-right: 19px;
        position: relative !important;
      }
      .vehicleModal{
          background-color: #ffffff !important;
      }
    `}
`;
const RightWrapper = styled(Parent.RightWrapper)`
    ${({ theme }) => `
    .subheaderSection{
        background-color: ${theme.colors.BACKGROUND} !important;
        font-size: 20px;
        text-transform: capitalize;
        color: #000000 !important;
    }
    `}
`;
const Styled = {
    Wrapper,
    LeftWrapper,
    RightWrapper,
    RegionListWrapper,
    TerminalListWrapper,
    RegionWrapper,
    TerminalWrapper,
    Department
  };
  export default Styled;
