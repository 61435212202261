import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import Private from './Private';
import Public from './Public';

const NewUi = ({
  children,
  layout,
  setAuth,
  pageTitle,
  folderPath,
  showFilterPopup,
  callback
}) => {
  const privateRootProps = {
    layout,
    setAuth,
    folderPath,
    showFilterPopup,
    callback
  };
  const baseUrl = `${process.env.CDN_URL || ''}/${
    window.$environment?.SERVICE_PROVIDER
  }/${window.$environment.CURRENT_LAYOUT}`;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="manifest" href={`${baseUrl}/manifest.json`} />
      </Helmet>
      {layout === 'login' || layout === 'noi18n' ? (
        <Public isI18nNeeded={layout !== 'noi18n'}>{children}</Public>
      ) : (
        <Private {...privateRootProps}>{children}</Private>
      )}
    </>
  );
};

export default NewUi;
NewUi.defaultProps = {
  children: null,
  layout: '',
  setAuth: () => {}
};
NewUi.propTypes = {
  children: PropTypes.node,
  layout: PropTypes.string,
  setAuth: PropTypes.func
};
