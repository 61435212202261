import React, { useState, lazy } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import AddEditTerminal from './AddEditTerminal';
import AddEditDepartment from './AddEditDepartment';
import AddEditSubDepartment from './AddEditSubDepartment';

import { getImagePath } from '@/utils/utils';
import { imageUrls } from '@/config/constants/keys';

const layout = window?.$environment?.CURRENT_LAYOUT;
const NestedAccordion = lazy(() =>
  import(`@/components/NestedAccordion/${layout}`)
);
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Modal = require(`@/components/UI/Modal/${layout}`).default;

const Terminal = ({
  data,
  callback,
  updateTerminal,
  deleteTerminal,
  saveDepartment,
  updateDepartment,
  saveSubDepartment,
  updateSubDepartment,
  getTmezonePopup,
  deleteDepartment,
  deleteSubDepartment,
  Styled,
  isButtonStyle,
  isCrudRestricted,
  isTermCrudRestricted,
  isDeptCrudRestricted,
  isLocCrudRestricted,
  isShowDotNumber,
  neglectAccessLevel
}) => {
  const { i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [withCloseIcon, setwithCloseIcon] = useState(true);
  const [backgroundModal, setbackgroundModal] = useState(false);
 
  const [ModalObject, setModalObject] = useState({
    header: '',
    content: () => {
      return null;
    }
  });
  const clickedModal = (e) => {
    setOpen(true);
  };
  const closeModal = (event) => {
    setOpen(false);
  };
  const editItem = (head, currentItem) => {
    setwithCloseIcon(true);
    setbackgroundModal(false);
    if (head === 'terminal') {
      setModalObject({
        header: '',
        content: () => {
          return (
            <AddEditTerminal
              closeModal={closeModal}
              callback={callback}
              saveTerminal={updateTerminal}
              currentItem={currentItem}
              getTmezonePopup={getTmezonePopup}
              isCrudRestricted={isCrudRestricted}
              isButtonStyle={isButtonStyle}
              isShowDotNumber={isShowDotNumber}
              data={data}
              isEdit
              neglectAccessLevel={neglectAccessLevel}
            />
          );
        }
      });
    }
    if (head === 'department') {
      setModalObject({
        header: '',
        content: () => {
          return (
            <AddEditDepartment
              closeModal={closeModal}
              callback={callback}
              saveDepartment={updateDepartment}
              data={data}
              currentItem={currentItem}
              isCrudRestricted={isCrudRestricted}
              isButtonStyle={isButtonStyle}
              isEdit
              neglectAccessLevel={neglectAccessLevel}
            />
          );
        }
      });
    }
    if (head === 'location') {
      setModalObject({
        header: '',
        content: () => {
          return (
            <AddEditSubDepartment
              closeModal={closeModal}
              callback={callback}
              saveSubDepartment={updateSubDepartment}
              data={data}
              currentItem={currentItem}
              isEdit
              getTmezonePopup={getTmezonePopup}
              isCrudRestricted={isCrudRestricted}
              isButtonStyle={isButtonStyle}
              isShowDotNumber={isShowDotNumber}
              neglectAccessLevel={neglectAccessLevel}
            />
          );
        }
      });
    }
    clickedModal();
  };
  const deletePopup = (id, apiId, method ) => {
    setwithCloseIcon(false);
    setbackgroundModal(true);
    setModalObject({
      content: () => (
        <div className="deleteParent">
          <div className="imageSection">
            <img src={getImagePath(imageUrls.confirmIcon)} alt="Are you sure" />
          </div>
          <h2 className="departmentConfirm">{i18n.t('common.sureConfirm')}</h2>
          <div className="deletePopupBtn">
            <div className="cancelBtn">
              <Button
                label={i18n.t('common.cancel')}
                type={'reset'}
                onClick={() => {
                  closeModal();
                }}
              ></Button>
            </div>
            <div className="deletebtn">
              <Button
                label={i18n.t('common.deletePopup')}
                onClick={() => {
                  method({
                    apiId: apiId,
                    data: { data: { id: id } },
                    callback: callback,
                    i18n: i18n
                  });
                  closeModal();
                }}
              ></Button>
            </div>
          </div>
        </div>
      )
    });
  };
  const deleteItem = (head, currentItem) => {
    head === 'terminal' &&
      deletePopup(currentItem.terminalId, 'deleteTerminal', deleteTerminal);
    head === 'department' &&
      deletePopup(
        currentItem.departmentId,
        'deleteDepartment',
        deleteDepartment
      );
    head === 'location' &&
      deletePopup(
        currentItem.subDepartmentId,
        'deleteSubDepartment',
        deleteSubDepartment
      );
    clickedModal();
  };

  const addItem = (head, currentItem) => {
    setwithCloseIcon(true);
    if (head === 'terminal') {
      setbackgroundModal(false);
      setModalObject({
        header: '',
        content: () => {
          return (
            <AddEditDepartment
              closeModal={closeModal}
              callback={callback}
              saveDepartment={saveDepartment}
              data={data}
              currentItem={currentItem}
              isCrudRestricted={isCrudRestricted}
              isButtonStyle={isButtonStyle}
              neglectAccessLevel={neglectAccessLevel}
            />
          );
        }
      });
    } else {
      setModalObject({
        header: '',
        content: () => {
          return (
            <AddEditSubDepartment
              closeModal={closeModal}
              callback={callback}
              saveSubDepartment={saveSubDepartment}
              data={data}
              currentItem={currentItem}
              getTmezonePopup={getTmezonePopup}
              isCrudRestricted={isCrudRestricted}
              isButtonStyle={isButtonStyle}
              isShowDotNumber={isShowDotNumber}
              neglectAccessLevel={neglectAccessLevel}
            />
          );
        }
      });
    }
    clickedModal();
  };
  return (
    <>
      <Styled.TerminalWrapper>
        <>
          {data?.data?.terminalDtos?.length ? (
            <NestedAccordion
              data={data?.data?.terminalDtos}
              edit={editItem}
              delete={deleteItem}
              add={addItem}
              isButtonStyle={isButtonStyle}
              isTermCrudRestricted={isTermCrudRestricted}
              isDeptCrudRestricted={isDeptCrudRestricted}
              isLocCrudRestricted={isLocCrudRestricted}
              Styled={Styled}
              neglectAccessLevel={neglectAccessLevel}
            />
          ) : (
            <p className="Nodata">{i18n.t('common.noData')}</p>
          )}
        </>
      </Styled.TerminalWrapper>
      <Modal
        open={open}
        setOpen={setOpen}
        data={ModalObject}
        onCloseModal={closeModal}
        background="black"
        withCloseIcon={withCloseIcon}
        backgroundModal={backgroundModal}
        departmentTerminalModal
        isSuccess
      />
    </>
  );
};

export default Terminal;

Terminal.propTypes = {
  terminals: PropTypes.array
};
