import React, { useEffect, lazy } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RenderComponent from '@/components/modules/DynamicRenderPage';
import {
  objectArrayFromSameField,
  loadPageConfig
} from '@/utils/utils';


const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.addCompanies[0].name}`));
const Content = lazy(() => import(`@/${config.addCompanies[1].name}`));
const TopActions =
  config.addCompanies[0].children &&
  lazy(() => import(`${config.addCompanies[0].children.name}`));

const CompanyData = (parentProps) => {
  const { companyData, getCompanyData, history, i18n, theme, module, callback } = parentProps;
  const commonParentProps = { history, i18n, theme };
  const stateToDisplay = objectArrayFromSameField(companyData?.states);
  const allCycleRules = objectArrayFromSameField(companyData?.allCycleRules);
  const allCycleExceptions = objectArrayFromSameField(
    companyData?.allCycleExceptions
  );
  const allVehicleTypes = objectArrayFromSameField(
    companyData?.allVehicleTypes
  );
  useEffect(() => {
      getCompanyData({
        apiId: 'getCompanyData',
        type: 'add',
        callback,
        data: {
          error: ''
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <RenderComponent
        {...config[module][0]}
        parentProps={{
          ...parentProps
        }}
        commonParentProps={commonParentProps}
        component={Title}
        childComponent={TopActions}
      />
      <RenderComponent
        {...config[module][1]}
        parentProps={{
          ...parentProps,
          companyData: companyData,
          states: stateToDisplay,
          allCycleRules,
          allCycleExceptions,
          allVehicleTypes
        }}
        commonParentProps={commonParentProps}
        component={Content}
      />
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    companyData: state.fleetCompanyList.data
  };
};
const mapDispatch = (dispatch) => {
  return {
    getCompanyData: (data) => dispatch.fleetCompanyList.fetchCompanyData(data),
    saveCompanyData: (data) => dispatch.fleetCompanyList.saveData(data),
  };
};

export default connect(mapState, mapDispatch)(CompanyData);

CompanyData.propTypes = {
  login: PropTypes.object.isRequired,
  apiStatus: PropTypes.object.isRequired,
  fetchLogin: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
};
