import React, { forwardRef } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Modal from '@material-ui/core/Modal';

import Icon from '../../Icon/Clutch/index';
import { getImagePath } from '../../../../utils/utils';
import { imageUrls } from '../../../../config/constants/keys';
import useTheme from '@material-ui/core/styles/useTheme';

import useMediaQuery from '@material-ui/core/useMediaQuery';

const SimpleModal = forwardRef((props, ref) => {
  function getModalStyle() {
    if (!props.isUserProfile && !props.isExportPopup && !props.noDataPopup) {
      const top = 50;
      const left = 50;

      return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        outline: 'none',
        padding: '20px'
      };
    } else if (props.isUserProfile) {
      const top = props.optionListPopup ? props.top : 40;
      const right = props.right ? props.right : 1;

      return {
        top: `${top}px`,
        right: `${right}%`,
        outline: 'none',
        background: '#fff'
      };
    } else if (props.isExportPopup) {
      const top = 240;
      const left = 78.5;

      return {
        top: `${top}px`,
        left: `${left}%`,
        outline: 'none'
      };
    } else if(props.noDataPopup){
      const top = 30;
      const left = 50;

      return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        outline: 'none',
        padding: '20px'
      };
    }
  } 
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const md = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  let heightSet = sm ? 300 : md ? 342 : 450;
  let heightSet1 = sm ? 300 : md ? 342 : 516;
  let companyWidth =  sm ? 300 : md ? 342 : 700;
  const useStyles = makeStyles((theme) => ({
    // modal: {
    //   backgroundColor: isresets ? theme.colors.BLACK : ''
    // },
    paper: {
      position: 'absolute',
      width: props.isUserProfile
      ? ''
      : props.isExportPopup
      ? 168
      : props.withCloseIcon && !props.width && !props.companyData && !props.contractHistory && !props.timeZone && !props.inspectionPopup
      ? heightSet
      : props.width
      ? props.width
      : props.isLoader
      ? '100%'
      : props.contractHistory
      ? '690px'
      : props.connectionType
      ? 'auto'
      : props.timeZone
      ? '563px'
      : props.companyData
      ? companyWidth
      : props.inspectionPopup
      ? '400px'
      : props.inspectionPopup
      ? '350px'
      : heightSet1,
      backgroundColor: props.companyData ? '#ddd': theme.colors.MODAL_BACKGROUND,
      border: '0',
      borderRadius: props.companyData ? '6px': '',
      boxShadow: theme.shadows[5],
      padding: props.isUserProfile ? '' : '17px',
      color: theme.colors.MODAL_LINK,
      justifyContent: 'center',
      alignItems: props.background
        ? 'space-around'
        : props.alignItemsLeft
        ? 'left'
        : 'center',
      display: props.exportDisplay ? props.exportDisplay :'flex',
      flexDirection: 'column',
      height: props.isExportPopup ? 100 : props.isLoader ? '100%' : '',
      opacity: props.isLoader ? '0.8' : ''
    }
  }));
//   const useStyles = makeStyles((theme) => ({
//     paper: {
//       position: 'absolute',
//       width: props.isUserProfile
//         ? ''
//         : props.isExportPopup
//         ? 168
//         : props.withCloseIcon && !props.width
//         ? heightSet
//         : props.width
//         ? props.width
//         : props.isLoader
//         ? '100%'
//         : props.connectionType
//         ? 'auto'
//         : heightSet1,
//       backgroundColor: props.connectionType ? '#20263a' : '#181C2D',
//       boxShadow: theme.shadows[5],
//       padding: props.connectionType ? '28px !important' : '17px',
//       color: theme.palette.primary.contrastText,
//       justifyContent: 'center',
//       alignItems: props.background
//         ? 'space-around'
//         : props.alignItemsLeft
//         ? 'left'
//         : 'center',
//       display: props.isSuccess ? 'block' : 'flex',
//       flexDirection: 'column',
//       height: props.isExportPopup
//         ? 10
//         : props.isLoader
//         ? '100%'
//         : props.isError
//         ? '340px'
//         : '',
//       fontFamily: theme.typography.fontFamily,
//       borderRadius: '4px'
//       // overflow: props.isSuccess ? 'auto' : ''
//     }
//   }));
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const handleClose = () => {
    props.setOpen(false);
    return props.onCloseModal ? props.onCloseModal('closed') : '';
  };
  return (
    <props.ParentWrapper>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={props.open}
        onClose={props.onClose || handleClose}
        className={classes.modal}
        disableEscapeKeyDown={props.disableEscapeKeyDown || false}
        disableBackdropClick={props.disableBackdropClick || false}
      >
        {!props.isUserProfile && !props.deletePopup ? (
          <props.ModalWrapper2 style={modalStyle} className={classes.paper}>
            {props.withCloseIcon ? (
              <div id="closeBtn">
                <Icon
                  onClick={() => handleClose()}
                  src={getImagePath(imageUrls.closeIcon)}
                ></Icon>
              </div>
            ) : (
              ''
            )}
            {props.withCloseIcon || props.isSuccess ? (
              ''
            ) : (
              <h2
                id="modal-title"
                className={props.connectionType ? 'connectionHeading' : ''}
              >
                {props.data.header}
              </h2>
            )}
            {typeof props.data.content == 'string' ? (
              <p id="modal-description">{props.data.content}</p>
            ) : (
              <props.data.content />
            )}
            {props.withCloseIcon || props.isSuccess || props.connectionType ? (
              ''
            ) : (
              <p id="modal-footer">{props.data.id}</p>
            )}
          </props.ModalWrapper2>
        ) : (
          <props.ModalWrapper
            style={modalStyle}
            className={classes.paper}
            width={props.width}
          >
            {props.withCloseIcon ? (
              <div id="closeBtn">
                <Icon
                  onClick={() => handleClose()}
                  src={getImagePath(imageUrls.closeIcon)}
                ></Icon>
              </div>
            ) : (
              ''
            )}
            {typeof props.data.content == 'string' ? (
              <p id="modal-description">{props.data.content}</p>
            ) : (
              <props.data.content />
            )}
          </props.ModalWrapper>
        )}
      </Modal>
    </props.ParentWrapper>
  );
});

export default SimpleModal;
