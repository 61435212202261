import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import { getImagePath } from '../../../utils/utils';
const Wrapper = styled(Box)`
${({ theme }) => `
  padding: 3%;
  display: flex;
  flex-direction: column;
  form {
    flex-direction: column;
    width: 100%;
  }
  .test-table {
    width: 100%;
    border: 2px solid  ${theme.colors.PRIMARY_BORDER};
    border-collapse: collapse;
    thead td {
      color: ${theme.colors.LINK_COLOR};
      padding: 8px;
      padding-left: 0;
    }
    tr {
      border-bottom: 0.5px solid  ${theme.colors.PRIMARY_BORDER};
      td {
      color: ${theme.colors.PRIMARY_CONTRAST};
      padding: 8px;
      
      }
    }
    .title {
      color: ${theme.colors.LINK_COLOR};
    }
  }
  .subscription-tick {
    background: url(${getImagePath('icons/whitecheck_new.png')}) no-repeat left ;
    background-size: 22px;
    background-position: 8px;
  }
  .subscription-cross {
    background: url(${getImagePath('icons/close_white.png')}) no-repeat left ;
    background-size: 22px;
    background-position: 8px;
  }
  .buttonStyle {
    margin-top: 0 !important;
  }
  `}
`;
const TableWrapper = styled(Box)`
  padding-top: 2%;
`;

const Styled = { Wrapper, TableWrapper };

export default Styled;
