import styled from "styled-components";

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    .centerText {
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -65%);
        text-align: center;
    }
    .centerText3 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .text {
        font-size: 14px;
        font-weight: normal;
    }
    .count {
        font-size: 24px;
        font-weight: bold;
        padding: 0px;
        display: ruby-text;
    }
    .textsmall {
        font-size: 11px;
        font-weight: normal;
        line-height: 1;
    }
    .countsmall {
        font-size: 12px;
        font-weight: bold;
        padding: 0px;
        display: ruby-text;
    }
    .centerText2 {
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -65%);
        text-align: center;
        .text {
            font-size: 8px;
            font-weight: normal;
        }
        .count {
            font-size: 12px;
            font-weight: bold;
            padding: 0px;
            display: ruby-text;
        }
    }
    
`;

const ToolTip = styled.div`
        position: absolute;
        transform: translate(-50%, -100%);
        color: white;
        padding: 10px;
        borderRadius: 4px;
        z-index: 1000
        border-radius: 4px;
        background: #29324f;
        color: white;
        font-size: 0.75rem;
        border: 1px solid white;
        white-space: nowrap;
        &::after {
            content: '';
            position: absolute;
            top: 100%; 
            left: 50%; 
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: white transparent transparent transparent;;
        }
`;

const Styled = {
    Wrapper,
    ToolTip
};

export default Styled;