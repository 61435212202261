import base from './base';

export default {
  ...base,
  common: {
    ...base.common,
    en: 'EN',
    es: 'ES',
    summary: 'Company Summary'
  },
  inspections: {
    ...base.inspections,
    addNewForm: "Add a new form",
    addNewVehicleForm: "Add a new vehicle form",
    addNewTrailerForm: "Add a new trailer form",
    formName: "Form Name",
    vehiclesCount: "Vehicles Count",
    addForm: "Add Form",
    editForm: "Edit Form",
    addNewArea:"Add New Area",
    areasAndParts: "Areas and Parts",
    addNewParts: "Add new parts",
    defects: "Defects",
    addNewDefects: "Add new Defects",
    areaName: "Area Name",
    areaType: "Area Type"
  },
  footer: {
    address: {
      one: 'Business Address',
      two: 'Phone',
      three: 'email',
      onedetails: ' Houston, TX',
      twodetails: ' P. 844-636-1360 F. 888-512-1328',
      threedetails: 'support@trackonfleet.com'
    },
    patent: 'Patent pending',
    copyright: '2020 Trackon-ELD All Rights Reserved',
    PrivacyLegal: 'Privacy Policy'
  }
};
