import React, { useEffect, lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RenderComponent from '@/components/modules/DynamicRenderPage';

import ParentStyled from './style';
import { loadStyle, loadPageConfig } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'pages/web/Companies');

const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.companyList[0].name}`));
const Content = lazy(() => import(`@/${config.companyList[1].name}`));
const TopActions =
  config.companyList[0].children &&
  lazy(() => import(`${config.companyList[0].children.name}`));

const Companies = (parentProps) => {
  const {
    fetchCompanies,
    getFilters,
    history,
    i18n,
    theme
  } = parentProps;

  const commonParentProps = { history, i18n, Styled, theme };
  const [pagination, setPagination] = useState(false);
 
  useEffect(() => {
    fetchCompanies({
      apiId: 'fetchCompanies'
    });
    getFilters({ apiId: 'fetchFilters' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [removeData, setremoveData] = useState('false')

  return (
    <>
      <RenderComponent
        {...config.companyList[0]}
        parentProps={{
          ...parentProps,
          pagination,
          setPagination,
          setremoveData,
          removeData
        }}
        commonParentProps={commonParentProps}
        component={Title}
        childComponent={TopActions}
      />
      <RenderComponent
        {...config.companyList[1]}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        component={Content}
        pagination={pagination}
        setPagination={setPagination}
        setremoveData={setremoveData}
        removeData={removeData}
      />
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    companies: state.companies,
    filters: state.filters,
    logBook: state.logBook
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchCompanies: (data) => dispatch.companies.fetchData(data),
    filterSuccess: (data) => dispatch.companies.filterSuccess(data),
    getFilters: (data) => dispatch.filters.fetchData(data),
  };
};

export default connect(mapState, mapDispatch)(Companies);

Companies.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  companies: PropTypes.object.isRequired,
  fetchCompanies: PropTypes.func.isRequired,
  handleDeleteAlert: PropTypes.func.isRequired,
  handleRestoreAlert: PropTypes.func.isRequired,
  filterSuccess: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  getFilters: PropTypes.func.isRequired
};
