import React from 'react';

import WithData from '..';
import Content from './Component';

const Index = ({ callback }) => {

  return (
    <WithData callback={callback}>
      <Component />
    </WithData>
  );
};

export default Index;

const Component = ({
  children,
  callback,
  apiStatus,
  i18n,
  theme,
  handleLogout,
  history,
  login,
  truckInfo,
  updateAllTruck,
  isValidVin,
  ...rest
}) => {
  const contentProps = {
    children,
    callback,
    apiStatus,
    handleLogout,
    history,
    login,
    truckInfo,
    updateAllTruck,
    isValidVin,
    ...rest
  };
  return (
    <>
      <Content {...contentProps} />
    </>
  );
};
