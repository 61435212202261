import styled from 'styled-components';
import Parent from '../Base/style';

const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
border: 1px solid #ddd;
p {
    color: #000;
    font-size: 22px;
  }
  `}
`;
const Styled = { Wrapper };
export default Styled;
