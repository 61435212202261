import base from './base';

export default {
  ...base,
  common: {
    ...base.common,
    en: 'EN',
    es: 'ES',
    summary: 'Company Summary'
  },
  inspections: {
    ...base.inspections,
    addNewForm: "Add a new form",
    addNewVehicleForm: "Add a new vehicle form",
    addNewTrailerForm: "Add a new trailer form",
    formName: "Form Name",
    vehiclesCount: "Vehicles Count",
    addForm: "Add Form",
    editForm: "Edit Form",
    addNewArea:"Add New Area",
    areasAndParts: "Areas and Parts",
    addNewParts: "Add new parts",
    defects: "Defects",
    addNewDefects: "Add new Defects",
    areaName: "Area Name",
    areaType: "Area Type"
  },
  footer: {
    address: {
      one: 'Business Address',
      two: 'Telephone / Fax',
      three: 'Online',
      onedetails: '1221 E. Dyer Rd #250 Santa Ana, CA 92705',
      twodetails: ' 1-800-601-4511',
      threedetails: 'support@eld-pro.com'
    },
    patent: 'Patent pending',
    copyright: '2020 ELD Mandate Pro All Rights Reserved',
    PrivacyLegal: 'Privacy Policy'
  }
};
