import React, { lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { imageUrls } from '@/config/constants/keys';
import { getImagePath } from '@/utils/utils';
import AddEditParts from './AddEditParts';
import TopActions from './TopActions';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const Modal = require(`@/components/UI/Modal/${layout}`).default;
const Breadcrumb = lazy(() => import(`@/components/Breadcrumb`));

const Index = ({ theme, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Component {...contentProps} />
    </Box>
  );
};
export default Index;

const Component = ({
  partsList,
  Styled,
  fetchParts,
  changeRequest,
  saveParts,
  updateParts,
  callback,
  apiStatus,
  handleDeleteAlert,
  topAction,
  request,
  viewOnly
}) => {
  const { i18n } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const clickedModal = (e) => {
    setModalOpen(true);
  };
  const [withCloseIcon, setwithCloseIcon] = useState(true);
  const [backgroundModal, setbackgroundModal] = useState(false);
  const [ModalObject, setModalObject] = useState({
    header: '',
    content: () => {}
  });
  const closeModal = (event) => {
    setModalOpen(false);
  };

  const columns = [
    {
      id: 'partName',
      numeric: false,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('parts.partName')
    },
    {
      id: 'partsInInventory',
      numeric: false,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('parts.partsInventory')
    },
    {
      id: 'cost',
      numeric: false,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('parts.cost')
    },
    ...(!viewOnly
      ? [
          {
            id: 'actions',
            label: i18n.t('common.tools')
          }
        ]
      : [])
  ];
  const generateRows = () => {
    return partsList?.content || [];
  };

  const information = i18n.t('common.tableEmptyMessage');
  return (
    <>
      <Styled.Wrapper>
      {topAction && <TopActions {...{ i18n, saveParts, Styled, callback, request, viewOnly }} />}
        {/* <FormStyle.default.ButtonsWrapper>
          <Button
            className="buttonStyle"
            label={i18n.t('parts.addPart')}
            onClick={() => {
              setwithCloseIcon(true);
              setbackgroundModal(false);
              setModalObject({
                header: '',
                content: () => {
                  return (
                    <AddEditParts
                      closeModal={closeModal}
                      callback={callback}
                      saveParts={saveParts}
                      Styled={Styled}
                    />
                  );
                }
              });
              clickedModal();
            }}
          />
        </FormStyle.default.ButtonsWrapper> */}
        <Styled.TableWrapper>
          <div className="quickFilters"></div>
          <Table
            type="server"
            defaultSortBy={{ sortBy: 'partName', order: 'asc' }}
            baseRequest={request}
            title=""
            columns={[...columns]}
            changeRequest={changeRequest}
            data={generateRows()}
            emptyMessage={information}
            totalRecords={partsList?.totalElements}
            i18n={i18n}
            actions={!viewOnly? [
              {
                icon: (row, index) => {
                  return (
                    <Icon
                      src={getImagePath(imageUrls.edit)}
                      onClick={() => {
                        setwithCloseIcon(true);
                        setbackgroundModal(false);
                        setModalObject({
                          header: '',
                          content: () => {
                            return (
                              <AddEditParts
                                closeModal={closeModal}
                                callback={callback}
                                saveParts={updateParts}
                                currentItem={row}
                                Styled={Styled}
                              />
                            );
                          }
                        });
                        clickedModal();
                      }}
                    />
                  );
                }
              },
              {
                icon: (row, index) => {
                  return (
                    <Icon
                      src={getImagePath(imageUrls.delete)}
                      onClick={() => {
                        setwithCloseIcon(false);
                        setbackgroundModal(true);
                        setModalObject({
                          content: () => (
                            <div className="deleteParent">
                              <div className="imageSection">
                                <img
                                  src={getImagePath(imageUrls.confirmIcon)}
                                  alt="Are you sure"
                                />
                              </div>
                              <h2 className="departmentConfirm"> {i18n.t('common.sureConfirm')} </h2>
                              <div className="deletePopupBtn">
                                <div className="cancelBtn">
                                  <Button
                                    label={i18n.t('Cancel')}
                                    type={'reset'}
                                    onClick={() => {
                                      setModalOpen(false);
                                    }}
                                  ></Button>
                                </div>
                                <div className="deletebtn">
                                  <Button
                                    label={i18n.t('common.deletePopup')}
                                    onClick={() => {
                                      handleDeleteAlert({
                                        id: 'deleteAlert',
                                        data: { id: row.id },
                                        callback: callback
                                      });
                                      setModalOpen(false);
                                    }}
                                  ></Button>
                                </div>
                              </div>
                            </div>
                          )
                        });
                        setModalOpen(true);
                      }}
                    />
                  );
                }
              }
            ]: []}
            itemsPerPage={10}
          />
        </Styled.TableWrapper>
        <Modal
          open={modalOpen}
          setOpen={setModalOpen}
          data={ModalObject}
          onCloseModal={closeModal}
          background="black"
          withCloseIcon={withCloseIcon}
          backgroundModal={backgroundModal}
        />
      </Styled.Wrapper>
    </>
  );
};

Component.propTypes = {
  changeRequest: PropTypes.object.isRequired,
  fetchParts: PropTypes.func.isRequired
};
