import React, { useEffect, lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RenderComponent from '@/components/modules/DynamicRenderPage';

import ParentStyled from './style';
import { loadStyle, loadPageConfig } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'pages/web/FuelEconomy');

const config = loadPageConfig()
const Title = lazy(() => import(`@/${config.fuelEconomy[0].name}`));
const Content = lazy(() => import(`@/${config.fuelEconomy[1].name}`));
const TopActions =
  config.fuelEconomy[0].children &&
  lazy(() => import(`${config.fuelEconomy[0].children.name}`));
const FuelEconomy = (parentProps) => {
  const {
    history,
    i18n,
    theme,
    driverbehavior,
    fetchVehicles,
    vehicles,
    fetchFuelEconomy
  } = parentProps;

  useEffect(() => {
    fetchVehicles({
      apiId: 'fetchVehicles'
    });
    fetchFuelEconomy({
      apiId: 'fetchFuelEconomy'
    });
  }, []);

  const commonParentProps = { history, i18n, Styled, theme };

  const formatDataForChart = (responseData) => {
    let tempData = {
      labels: [],
      data: []
    };
    if (responseData) {
      responseData.forEach(function (item) {
        tempData['labels'].push(item.date);
        tempData['data'].push(item.count);
      });
    }
    return tempData;
  };

  const fuelEconomyData = formatDataForChart(
    driverbehavior.fuelEconomy.chart
  );

  const driverIdSuggestion = vehicles?.vehicleList.map((items) => ({
    label: items.unit_id,
    value: items.id
  }));

  return (
    <>
      <RenderComponent
        {...config.fuelEconomy[0]}
        parentProps={{
          ...parentProps,
          driverIdSuggestion
        }}
        commonParentProps={commonParentProps}
        component={Title}
        childComponent={TopActions}
      />
      <RenderComponent
        {...config.fuelEconomy[1]}
        parentProps={{ ...parentProps, driverIdSuggestion }}
        commonParentProps={commonParentProps}
        component={Content}
        chartData={fuelEconomyData}
        driverbehavior={driverbehavior}
      />
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    driverbehavior: state.driverbehavior,
    vehicles: state.vehicles
  };
};
const mapDispatch = (dispatch) => {
  return {
    clearApiStatus: () => dispatch.asyncRequests.clearApiStatus(),
    fetchChartData: (data) => dispatch.driverbehavior.fetchChartData(data),
    fetchVehicles: (data) => dispatch.vehicles.fetchData(data),
    fetchDriverBehaviorDetails: (data) =>
    dispatch.driverbehavior.fetchDriverBehaviorDetails(data),
    fetchFuelEconomy: (data)=> dispatch.driverbehavior.fetchFuelEconomy(data)
  };
};

export default connect(mapState, mapDispatch)(FuelEconomy);
FuelEconomy.propTypes = {
  apiStatus: PropTypes.object.isRequired
};
