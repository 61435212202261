import * as parent from '../../variables';

export const colors = {
  ...parent.colors,
  PRIMARY_MAIN: '#fff',
  BACKGROUND: '#F5F5F5',
  LIGHT_BORDER:'#bcb1b1',
  HEADER_COUNT_TEXT: '#000',
  PRIMARY_CONTRAST: '#000',
  SECONDARY_MAIN: '#007aff',
  PUBLIC_HEADERCOLOR: '#061c3f',
  LANGUAGE_SWITCH: '#1589ee',
  HEADER_TEXT: '#f4f6f9',
  BODY_BACKGROUND: '#fff',
  MODAL_BACKGROUND: '#fff',
  MODAL_BORDER: '#d9d9d9',
  MODAL_LINK: '#1589ee',
  LINK_COLOR: '#007aff',
  HEADER_COLOR: '#1589ee',
  CHECKBOX_TICK: '#000',
  ACTIVE_ROW:'#f4f4f4',
  PAGINATION_BACKGROUND: '#fff',
  PAGINATION_COLOR: '#000',
  SELECTED_AREA: 'rgba(32, 177, 232, 0.6)',
  BACKGROUND_MENU:'#f4f6f9'
};
export const fonts = ['Roboto', 'sans-serif'].join(',');
export const titleFont = 'Yanone Kaffeesatz';
