import React, { lazy } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import TopActions from './TopActions';
const layout = window?.$environment?.CURRENT_LAYOUT;
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));

const Index = ({ theme, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Component {...contentProps} />
    </Box>
  );
};
export default Index;
const Component = ({
  apiStatus,
  callback,
  history,
  Styled,
  handleExportCsaViolations,
  csaViolations,
  changeRequest,
  topAction,
  hasApiStatus,
  request,
  pagination,
  setPagination
}) => {
  const { i18n } = useTranslation();
  const information =
    hasApiStatus && hasApiStatus.errorMessage ? (
      <Message type="error" s message={i18n.t(hasApiStatus.errorMessage)} />
    ) : (
      i18n.t('common.tableEmptyMessage')
    );
  const columns = [
    {
      id: 'date',
      numeric: true,
      disablePadding: true,
      label: i18n.t('common.date'),
      needSorting: false
    },
    {
      id: 'driverName',
      numeric: false,
      disablePadding: false,
      label: i18n.t('Driver Name'),
      needSorting: false
    },
    {
      id: 'vin',
      numeric: true,
      disablePadding: false,
      label: i18n.t('vehicles.vin'),
      needSorting: false
    },

    {
      id: 'state',
      numeric: false,
      disablePadding: false,
      label: i18n.t('common.state'),
      needSorting: false
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: i18n.t('Description'),
      needSorting: false
    },
    {
      id: 'oos',
      numeric: false,
      disablePadding: false,
      label: i18n.t('csaViolation.oos'),
      needSorting: false
    },
    {
      id: 'severity',
      numeric: true,
      disablePadding: false,
      label: i18n.t('csaViolation.severity'),
      needSorting: false
    },
    {
      id: 'dvirLink.data',
      numeric: false,
      disablePadding: false,
      label: i18n.t('csaViolation.dvirLink'),
      needSorting: false
    },
    {
      id: 'dvirStatus',
      numeric: false,
      disablePadding: false,
      label: i18n.t('csaViolation.dvirStatus'),
      needSorting: false
    },
    {
      id: 'roadsideDotInspection.data',
      numeric: false,
      disablePadding: false,
      label: i18n.t('csaViolation.roadsideDot'),
      needSorting: false
    },
    {
      id: 'workOrder.data',
      numeric: false,
      disablePadding: false,
      label: i18n.t('csaViolation.workOrder'),
      needSorting: false
    },
    {
      id: 'workOrderStatus',
      numeric: false,
      disablePadding: false,
      label: i18n.t('csaViolation.workOrderStatus'),
      needSorting: false
    }
  ];
  const headerOne = [
    {
      id: 'date',
      numeric: true,
      disablePadding: true,
      label: i18n.t('common.date'),
      needSorting: false
    },
    {
      id: 'driverName',
      numeric: false,
      disablePadding: false,
      label: i18n.t('Driver Name'),
      needSorting: false
    },
    {
      id: 'vin',
      numeric: true,
      disablePadding: false,
      label: i18n.t('vehicles.vin'),
      needSorting: false
    },
    {
      label: i18n.t('leftMenu.csaViolation')
    },
    {
      id: 'dvirLink.data',
      numeric: false,
      disablePadding: false,
      label: i18n.t('csaViolation.dvirLink'),
      needSorting: false
    },
    {
      id: 'dvirStatus',
      numeric: false,
      disablePadding: false,
      label: i18n.t('csaViolation.dvirStatus'),
      needSorting: false
    },

    {
      id: 'roadsideDotInspection.data',
      numeric: false,
      disablePadding: false,
      label: i18n.t('csaViolation.roadsideDot'),
      needSorting: false
    },
    {
      id: 'workOrder',
      numeric: false,
      disablePadding: false,
      label: i18n.t('csaViolation.workOrder'),
      needSorting: false
    },
    {
      id: 'workOrderStatus',
      numeric: false,
      disablePadding: false,
      label: i18n.t('csaViolation.workOrderStatus'),
      needSorting: false
    }
  ];

  const headerTwo = [
    {
      id: 'state',
      numeric: false,
      disablePadding: false,
      label: i18n.t('common.state'),
      needSorting: false
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: i18n.t('Description'),
      needSorting: false
    },
    {
      id: 'oos',
      numeric: false,
      disablePadding: false,
      label: i18n.t('csaViolation.oos'),
      needSorting: false
    },
    {
      id: 'severity',
      numeric: true,
      disablePadding: false,
      label: i18n.t('csaViolation.severity'),
      needSorting: false
    }
  ];

  const generateRows = () => {
    return csaViolations?.aaData || [];
  };

  return (
    <Styled.Wrapper>
      {topAction && (
        <TopActions
          {...{
            Styled,
            apiStatus,
            history,
            callback,
            hasApiStatus,
            changeRequest,
            i18n,
            handleExportCsaViolations,
            request,
            pagination,
            setPagination
          }}
        />
      )}
      <Styled.TableWrapper>
        <div className="quickFilter"></div>
        <div className="tableWrapper">
          <Table
            type="server"
            baseRequest={request}
            changeRequest={changeRequest}
            title=""
            columns={[...columns]}
            headerOne={[...headerOne]}
            headerTwo={[...headerTwo]}
            data={generateRows()}
            emptyMessage={information}
            itemsPerPage={15}
            totalRecords={csaViolations?.iTotalRecords}
            actions={[]}
            i18n={i18n}
            mergedColumn={true}
            position={3}
            pageinationReset={pagination}
            pagenationClicked={(e) => {
              setPagination(!e);
            }}
          />
        </div>
      </Styled.TableWrapper>
    </Styled.Wrapper>
  );
};

Component.propTypes = {
  history: PropTypes.object.isRequired,
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  fetchViolations: PropTypes.func.isRequired,
  handleExportCsaViolations: PropTypes.func.isRequired
};
