import React, { useState, lazy } from 'react';
import PropTypes from 'prop-types';

import RenderComponent from './RenderComponent';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));

const ViewForm = ({ changeRequest, hasApiStatus, callback, history, setPagination }) => {
  const [values, setValues] = useState({
    driverName: null,
    vehicleVin: null,
    state: null,
    startDate: null,
    endDate: null
  });

  return (
    <>
      <Form
        type="server"
        direction="row"
        initialValues={{ ...values }}
        className="CSAForms"
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {
          Object.keys(fields).forEach(
            (key) => fields[key] === null && delete fields[key]
          );

          changeRequest({
            ...fields,
            startDate: fields?.startDate?.replace(
              /(\d\d)\/(\d\d)\/(\d{4})/,
              '$3-$1-$2'
            ),
            endDate: fields?.endDate?.replace(
              /(\d\d)\/(\d\d)\/(\d{4})/,
              '$3-$1-$2'
            )
          });
          setPagination(true);
        }}
        handleOnCancel={(fields) => {
          changeRequest();
          setPagination(true);
        }}
        customProps={{
          history,
          callback,
          hasApiStatus
        }}
      />
    </>
  );
};

ViewForm.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default ViewForm;
