import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import ReactDatePicker from 'react-datepicker';
import { getDateLocale } from '@/utils/utils';
const layout = window?.$environment?.CURRENT_LAYOUT;
const Input = lazy(() => import(`@/components/UI/Input/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));
const Select = lazy(() => import(`@/components/UI/Select/${layout}`));
const Upload = lazy(() => import(`@/components/FileUpload/${layout}`));
const SubHeader = lazy(() => import(`@/components/SubHeader/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const RenderComponent = ({
  values,
  handleEdit,
  errors,
  isNotValid,
  validateForm,
  submitAction,
  onClear,
  customProps: {
    i18n,
    Styled,
    history,
    hasDutyStatusApiStatus,
    regions,
    fetchTerminals,
    fetchDepartments,
    fetchSubDepartments,
    drivers
  }
}) => {
  return (
    <>
      <FormStyle.default.FieldsWrapper className="fieldWrapper">
        <Label htmlFor="driverIds">{i18n.t('common.driver')}</Label>
        <FormStyle.default.TextWrapper>
          <Field
            name="driverIds"
            onChange={(event) => {
              handleEdit(event, { field: 'driverIds' });
            }}
            as={Select}
            // disabled={disabledCriteria?.regionId}
            placeholder={i18n.t('common.pleaseSelect')}
            value={values.driverIds}
            suggestions={drivers.data}
            isClearable
            isMulti
            allowSelectAll
          />
            {errors['driverIds'] && !isNotValid &&  (
            <Message type="error" message={errors['driverIds']} />
          )}
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.FieldsWrapper className="fieldWrapper">
        <Label htmlFor="startDate">{i18n.t('common.dateFrom')}</Label>
        <FormStyle.default.TextWrapper>
          <Field
            name="startDate"
            onChange={(event) => {
              handleEdit(event, { field: 'startDate', type: 'date' });
            }}
            as={ReactDatePicker}
            locale={getDateLocale()}
            placeholderText={i18n.t('common.datePicker')}
            value={values.startDate}
            showPopperArrow={false}
            showTimeSelect={false}
            maxDate={values.endDate ? new Date(values.endDate) : new Date()}
            showDisabledMonthNavigation
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
          {errors['startDate'] && !isNotValid && (
            <Message type="error" message={errors['startDate']} />
          )}
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.FieldsWrapper className="fieldWrapper">
        <Label htmlFor="endDate">{i18n.t('common.dateTo')}</Label>
        <FormStyle.default.TextWrapper>
          <Field
            name="endDate"
            onChange={(event) => {
              handleEdit(event, { field: 'endDate', type: 'date' });
            }}
            as={ReactDatePicker}
            locale={getDateLocale()}
            placeholderText={i18n.t('common.datePicker')}
            value={values.endDate}
            showPopperArrow={false}
            showTimeSelect={false}
            maxDate={new Date()}
            showDisabledMonthNavigation
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            minDate={
              values.startDate ? new Date(values.startDate) : new Date('01/01/2000')
            }
          />
          {errors['endDate'] && !isNotValid && (
            <Message type="error" message={errors['endDate']} />
          )}
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>

      <FormStyle.default.ButtonsWrapper className="buttonWrapper">
        <Button
          type="submit"
          className="buttonStyle"
          label={i18n.t('common.submit')}
          onClick={(e, d) => {
            const validateResponse = validateForm();
            validateResponse.then((data) => {
              submitAction(data);
            });
          }}
        />
      </FormStyle.default.ButtonsWrapper>
    </>
  );
};

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  customProps: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};

export default RenderComponent;
