import styled from 'styled-components';
import Input from '@material-ui/core/Input';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { getStyle } from '../../../../utils/utils';

const InputWrapper = styled(Input)`
  ${({ style, theme, mode }) => `
  background: ${
    mode === 'light'
      ? theme.colors.WHITE
      : getStyle(
          style?.background,
          theme.palette.primary?.main,
          theme.colors.PRIMARY_MAIN
        )
  }
    none repeat scroll 0 0; 
  border: 2px solid
    ${getStyle(
      style?.border,
      theme.palette.primary?.border,
      theme.colors.PRIMARY_BORDER
    )}
  height: 45px;
  font-size: 1.2em;
  padding: 0;
  font-family: ${(theme.palette || {}).fontFamily};
  color: ${
    mode === 'light'
      ? theme.colors.BLACK + '!important'
      : getStyle(
          style?.color,
          theme.palette.primary?.contrastText,
          theme.colors.PRIMARY_CONTRAST
        )
  }
  .MuiInputBase-input.Mui-disabled{
    opacity: 0.5;
  }
  .MuiInputBase-input {
    color: ${ window.$environment.SERVICE_PROVIDER == 'eldx' ? theme.colors.PRIMARY_CONTRAST :
      mode === 'light'
        ? theme.colors.BLACK
        : getStyle(
            style?.color,
            theme.palette.primary?.contrastText,
            theme.colors.PRIMARY_CONTRAST
          )
    };
    padding: 8px 15px;
  }
  `}
`;
const TextAreaWrapper = styled(TextareaAutosize)`
  ${({ style, theme, mode }) => `

  background: ${
    mode === 'light'
      ? theme.colors.WHITE
      : getStyle(
          style?.background,
          theme.palette.primary?.main,
          theme.colors.PRIMARY_MAIN
        )
  }
    none repeat scroll 0 0;
  border: 2px solid
    ${getStyle(
      style?.border,
      theme.palette.primary?.border,
      theme.colors.PRIMARY_BORDER
    )}
  height: 70px !important;
  width: 100%;
  font-size: 18px;
  padding: 12px 15px;
  overflow-y: auto !important;
  font-family: ${(theme.palette || {}).fontFamily};
  color: ${
    mode === 'light'
      ? theme.colors.BLACK + '!important'
      : getStyle(
          style?.color,
          theme.palette.primary?.contrastText,
          theme.colors.PRIMARY_CONTRAST
        )
  }

  .MuiInputBase-input {
    color: ${
      mode === 'light'
        ? theme.colors.BLACK + '!important'
        : getStyle(
            style?.color,
            theme.palette.primary?.contrastText,
            theme.colors.PRIMARY_CONTRAST
          )
    };
  }
  `}
`;

const MainWrapper = styled.div`
  ${({ style, theme, mode, width, height }) => `
  input,
  .MuiInputBase-root {
    border-color: ${
      mode === 'light'
        ? 'transparent'
        : getStyle(
            style?.border,
            theme.palette.primary?.border,
            theme.colors.PRIMARY_BORDER
          )
    }
    background-color: ${
      mode === 'light'
        ? theme.colors.WHITE
        : getStyle(
            style?.background,
            theme.palette.primary?.main,
            theme.colors.PRIMARY_MAIN
          )
    }
  }
  .MuiInputBase-root {
    .MuiInput-underline:after {
      border: none;
    }
  }
  width: ${width || '100%'};
  height: ${height || 'auto'};
  .MuiInputBase-inputMultiline {
    height: 150px;
    background-color: ${
      theme.palette.primary?.border || theme.colors.PRIMARY_MAIN
    };
    border: 2px solid ${
      theme.palette.primary?.border || theme.colors.PRIMARY_BORDER
    };
    padding: 5px;

  }
  /* Change the white to any color ;) */
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active  {
      -webkit-box-shadow: 0 0 0 30px ${theme.colors.PRIMARY_MAIN} inset !important;
  }
  
  /*Change text in autofill textbox*/
  input:-webkit-autofill {
      -webkit-text-fill-color: ${theme.colors.PRIMARY_CONTRAST} !important;
  }
  `}
`;

const Styled = { InputWrapper, TextAreaWrapper, MainWrapper };
export default Styled;
