import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { colors, fonts, headerFont, subTitle, titleLabel, formlabelFont, buttonFont, checkboxFont, inputFont, addbuttonFont, companyDataFont, filterFont, iftaReport, inspectionInput, inspectionresolution } from './variables';

import parentTheme from '../';
const theme = createMuiTheme({
  ...parentTheme({ colors, fonts, headerFont, subTitle, titleLabel,formlabelFont, buttonFont, checkboxFont, inputFont, addbuttonFont, companyDataFont, filterFont, iftaReport, inspectionInput, inspectionresolution }),
  palette: {
    ...parentTheme({ colors, fonts, headerFont, subTitle, titleLabel,formlabelFont, buttonFont, checkboxFont, inputFont, addbuttonFont,companyDataFont, filterFont, iftaReport, inspectionInput, inspectionresolution }).palette,
    contentStyle: {
      minHeight: 'calc(100vh - 181px)',
      padding: '0',
      backgroundColor: colors.BODY_BACKGROUND
    },
    contentlogsStyle: {
      minHeight: 'calc(100vh - 181px)',
      padding: '0',
      backgroundColor: colors.BODY_BACKGROUND
    },
    footerTheme: {
      flexDirection: 'column',
      minHeight: '130px',
      height: 'auto',
      justifyContent:'flex-start'
    },
    footerPadding: {
      paddingLeft: '0',
      padding: '20px 0',
      marginTop: '0'
    },
    footerAlign: {
      paddingRight: '10px',
      textAlign: 'left'
    },
    footerWidth: {
      justifyContent: 'center',
      width: '100%',
      fontSize: '16px'
    }
  }
  
});

export default theme;
