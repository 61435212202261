import React, { useEffect, useState, lazy } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import RenderComponent from '@/components/modules/DynamicRenderPage';

import ParentStyled from './style';
import { loadStyle, loadPageConfig, getLogsInfoDisplay } from '@/utils/utils';

const Styled = loadStyle(ParentStyled, 'pages/web/LogBook');

const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.logBook[0].name}`));
const Content = lazy(() => import(`@/${config.logBook[1].name}`));
const TopActions =
  config.logBook[0].children &&
  lazy(() => import(`${config.logBook[0].children.name}`));
const layout = window?.$environment?.CURRENT_LAYOUT;

const Label = lazy(() => import(`@/components/UI/Label/${layout}`));
const ContentClutch = lazy(() => import(`@/${config.logBook[0].name}`));

const LogBook = (parentProps) => {
  const query = new URLSearchParams(useLocation().search);
  const match = useParams();
  const {
    history,
    logBook,
    fetchLogBookView,
    callback,
    getDocumentToken,
    i18n,
    theme,
    regeneratePdf
  } = parentProps;

  const [drivers, setDrivers] = useState([]);
  const [dates, setDates] = useState([]);
  const [unSignedDates, setUnsignedDates] = useState([]);
  const viewApiId = 'fetchLogBookView';
  const [newDate, setNewDate] = useState(null);
  useEffect(() => {
    setNewDate(logBook?.selectedDailyLog?.day);
  }, [logBook?.selectedDailyLog?.day]);
  const [currentDriver, setCurrentDriver] = useState({
    label: '',
    value: ''
  });
  const commonParentProps = { history, i18n, Styled, theme };
  const getDocumentTokenApiId = 'getDocToken';
  const driverId = query.get('driverId')
  const logDay = query.get('logDay')
  const [formValues, setFormValues] = useState({
    driverId: logBook.selectedDailyLog.driverId,
    driverName: logBook.selectedDailyLog.driverName,
    type: 'DAILY_LOG'
  });
  useEffect(() => {
    if (window.location.href.includes('logbookFilter') && match.date)
      fetchLogBookView({
        apiId: viewApiId,
        callback: callback,
        data: { data: { driverId: match.id, date: match.date } },
        history
      });
    else if (window.location.href.includes('logbookFilter') && !match.date)
      fetchLogBookView({
        apiId: viewApiId,
        callback: callback,
        data: { data: { driverId: match.id } },
        history
      });
    else{
      fetchLogBookView({
        apiId: viewApiId,
        callback,
        data: { data: { logId: match.id, driverId, date:logDay } },
        history
      });
    }
      
    getDocumentToken({ apiId: getDocumentTokenApiId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(() => {
  //   if (!logBook?.documentToken?.length) {
  //     getDocumentToken({ apiId: getDocumentTokenApiId });
  //   }
  // }, [logBook.documentToken]);

  useEffect(() => {
    const drivers = logBook.driverDetailsDto ? logBook.driverDetailsDto.map((item) => {
      return {
        label: item?.driverName,
        value: item?.driverId
      };
    }) : []
    // : logBook.drivers.map((item) => {
    //   return {
    //     label: item?.logBook?.dailyLog?.driverName,
    //     value: item?.logBook?.dailyLog?.driverId
    //   };
    // });
let dates = [], unSignedDates = [];
logBook.dailyLogs.forEach((item) => {
  if (item.status != "SIGNED") unSignedDates.push(new Date(`${item.day}T00:00:00`))
  dates.push(new Date(`${item.day}T00:00:00`));
});
setCurrentDriver({
  label: logBook.selectedDailyLog.driverName,
  value: logBook.selectedDailyLog.driverId,
  vehicleId: logBook.selectedDailyLog.vehicleId
});

setDrivers([...drivers]);
setDates(dates);
setUnsignedDates(unSignedDates)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
  logBook,
  logBook.selectedDailyLog.driverId,
  logBook.selectedDailyLog.vehicleId
]);
return (
  <Styled.LogbookWrap>
    <>
      {logBook.selectedDailyLog.driverId &&
        (window.$environment.APP_LAYOUT === 'Clutch' ) && (
          <RenderComponent
            {...config.logBook[0]}
            parentProps={
              {
              ...parentProps,
              ...(
                // window.$environment?.SERVICE_PROVIDER === 'cyntrx'?
              {
              formValues,
              setFormValues,
              currentDriver,
              unSignedDates,
              dates,
              drivers,
              newDate,
              setNewDate
              }
              // : {}
              )
            }
          }
            commonParentProps={commonParentProps}
            component={ContentClutch}
            drivers={drivers}
            dates={dates}
            currentDriver={currentDriver}
            getDocumentToken={getDocumentToken}
            regeneratePdf={regeneratePdf}
          />
        )}

      {window.$environment.APP_LAYOUT !== 'Clutch' && (
        <RenderComponent
          {...config.logBook[0]}
          parentProps={{
            ...parentProps,
            formValues,
            setFormValues,
            currentDriver,
            unSignedDates,
            dates,
            drivers,
            newDate,
            setNewDate
          }}
          commonParentProps={commonParentProps}
          component={Title}
          withBackground={window.$environment.CURRENT_LAYOUT === 'SpireonNew'}
          childComponent={
            window.$environment.CURRENT_LAYOUT === 'SpireonNew'
              ? () => (
                <Label className="logsInfoDisplay">
                  {getLogsInfoDisplay(
                    i18n,
                    logBook.selectedDailyLog.driverName,
                    logBook.selectedDailyLog.day
                  )}
                </Label>
              )
              : TopActions
          }
        />
      )}
      {logBook.selectedDailyLog.driverId &&
        window.$environment.APP_LAYOUT !== 'Clutch' && (
          <RenderComponent
            {...config.logBook[1]}
            parentProps={{
              ...parentProps,
              formValues,
              setFormValues,
              currentDriver,
              dates,
              unSignedDates,
              drivers,
              newDate,
              setNewDate
            }}
            commonParentProps={commonParentProps}
            component={Content}
          />
        )}
    </>
  </Styled.LogbookWrap>
);
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    logBook: state.logBook,
    breadCrumbForMap: state.breadCrumbForMap
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchLogBookView: (data) => dispatch.logBook.fetchData(data),
    getLogbookEvents: (data) => dispatch.logBook.getLogbookEvents(data),
    updateShowEvents: (data) => dispatch.logBook.updateShowEvents(data),
    handleLogBookStatus: (data) =>
      dispatch.logBookStatus.handleLogBookStatus(data),
    clearApiStatus: () => dispatch.asyncRequests.clearApiStatus(),
    fetchLogbookBreadcrumb: (data) => dispatch.breadCrumbForMap.fetchData(data),
    dailyLogDownloadData: (data) => dispatch.logBook.downloadData(data),
    getDocumentToken: (data) => dispatch.logBook.documentToken(data),
    regeneratePdf: (data) => dispatch.logBook.regeneratePdf(data)
  };
};

export default connect(mapState, mapDispatch)(LogBook);
LogBook.defaultTypes = {};
LogBook.propTypes = {
  history: PropTypes.object.isRequired,
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  logBook: PropTypes.object.isRequired,
  fetchLogBookView: PropTypes.func.isRequired,
  getLogbookEvents: PropTypes.func.isRequired,
  updateShowEvents: PropTypes.func.isRequired,
  handleLogBookStatus: PropTypes.func.isRequired,
  clearApiStatus: PropTypes.func.isRequired,
  fetchLogbookBreadcrumb: PropTypes.func.isRequired,
  breadCrumbForMap: PropTypes.array.isRequired,
  regeneratePdf: PropTypes.func.isRequired,
};



