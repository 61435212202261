const initialState = {};
export const viewReport = {
  state: { ...initialState },
  reducers: {
    setSuccess: (state, payload) => {
      return payload;
    },
    clearReceipt: () => {
      return '';
    }
  },
  effects: (dispatch) => ({
    async viewReportDocument(payload) {
      try {
        const getApi = await import(
          `../../../config/apis/${window.$environment.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('getDocumentTokenApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject
          },
          id: payload.apiId
        });

        if (response) {
          let a = document.createElement('a');
          a.href = ``;
          a.onclick = function () {
            window.open(`${window.$environment.BE_BASE_URL}/document/open/${payload.data}?token=${response}`);
            // window.close();
            return false
          }
          document.body.appendChild(a);
          a.click()
          a.remove()
        }
        return false
      } catch (error) { }
    },
    async downloadReport(payload) {
      try {
        const getApi = await import(
          `../../../config/apis/${window.$environment.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('getDocumentTokenApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject
          },
          id: payload.apiId
        });
        response &&
          this.setSuccess(
            `${window.$environment.BE_BASE_URL}/document/download/${payload.data}?token=${response}`
          );
      } catch (error) { }
    }
  })
};
