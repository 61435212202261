import PropTypes from 'prop-types';
import React, { lazy, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import useTheme from '@material-ui/core/styles/useTheme';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { getLastQuaters, getNewObject, loadStyle } from '@/utils/utils';
import ParentStyled from './style';

import { BACKEND_DATE_FORMAT } from '@/config/constants/static';
import { loadPageConfig } from '@/utils/utils';
import moment from 'moment';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

let Styled = loadStyle(ParentStyled, 'pages/web/IFTAStateStateList');

const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.iftaList[0].name}`));
const Content = lazy(() => import(`@/${config.iftaList[1].name}`));

const IFTAState = (parentProps) => {
  const { fetchIFTAStates, fetchVehicles, getStates } = parentProps;

  const apiId = 'fetchVehicles';
  const apiIftaMileage = 'listIftaStateApi';

  const history = useHistory();
  const theme = useTheme();
  const i18n = useTranslation();
  const { state } = useLocation();

  const commonParentProps = { history, i18n, Styled, theme };

  const getDocumentTokenApiId = 'getDocToken';
  const fetchStateApiId = 'getStatesApi';

  const lastQuater = getLastQuaters(true);

  const basicRequest = {
    displayLength: 15,
    displayStart: 1,
    fromDate: state?.dateTimeFrom ? moment(new Date(state?.dateTimeFrom)).format(BACKEND_DATE_FORMAT) : lastQuater[0].format(BACKEND_DATE_FORMAT),
    toDate: state?.dateTimeTo ? moment(new Date(state?.dateTimeTo)).format(BACKEND_DATE_FORMAT) : lastQuater[1].format(BACKEND_DATE_FORMAT),
    ...(state?.id?.value ? { vehicleIdPub: state?.id?.value } : {}),
  };
  const [request, setRequest] = useState({
    ...basicRequest
  });


  useEffect(() => {
    getStates(fetchStateApiId);
    fetchVehicles(apiId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchIFTAStates({ apiId: apiIftaMileage, data: { ...request } });
  }, [request]);



  const sortColumnMapping = {
    date: "date",
  }
  const changeRequest = (fields) => {
    if (fields?.sortCol) {
      const type = fields.sortCol
      fields.sortCol = sortColumnMapping[type] ? sortColumnMapping[type] : null
    }
    setRequest({ ...basicRequest, ...fields });
  };

  return (
    <>
      <RenderComponent
        {...config.iftaList[0]}
        parentProps={{ ...parentProps }}
        commonParentProps={commonParentProps}
        component={Title}
      />
      <RenderComponent
        {...config.iftaList[1]}
        parentProps={{ ...parentProps, driverListDropDown: parentProps?.driverListDropDown?.data, changeRequest, request, apiIftaMileage }}
        commonParentProps={commonParentProps}
        component={Content}
        request={request}
        changeRequest={changeRequest}
        getDocumentTokenApiId={getDocumentTokenApiId}
        fetchIFTAStates={fetchIFTAStates}
      />
    </>
  );
};

const RenderComponent = ({
  name,
  props,
  commonProps,
  title,
  parentProps,
  commonParentProps,
  component,
  ...rest
}) => {
  const Component = component;
  let newProps = props?.length ? getNewObject(parentProps, props) : {};
  newProps = {
    ...newProps,
    ...(commonProps?.length ? getNewObject(commonParentProps, commonProps) : {})
  };
  return <Component {...rest} {...newProps} commonProps={commonProps} />;
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    iftaList: state.iftaList,
    vehicles: state.iftaVehicleInfo,
    states: state.getStates,
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchIFTAStates: (data) => dispatch.iftaList.fetchData(data),
    fetchVehicles: (data) => dispatch.iftaVehicleInfo.fetchData(data),
    getStates: (data) => dispatch.getStates.getStates(data),
    handleDeleteAlert: (data) => dispatch.iftaList.handleDeleteAlert(data),
  };
};

export default connect(mapState, mapDispatch)(IFTAState);

IFTAState.propTypes = {
  callback: PropTypes.func.isRequired,
  fetchIFTAStates: PropTypes.func.isRequired,
  apiStatus: PropTypes.object.isRequired,
  fetchVehicles: PropTypes.func.isRequired,
  getStates: PropTypes.func.isRequired,
};
