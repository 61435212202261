import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
    
`}
`;
const UploadWrapper = styled(Parent.UploadWrapper)`
${({ theme }) => `
`}
`;
const PopupUpload = styled(Parent.PopupUpload)`
.filepond--wrapper{
    height: 42px !important;
}
`;

const Styled = { Wrapper, UploadWrapper, PopupUpload };
export default Styled;
