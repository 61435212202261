import React from 'react';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import RadioGroup from '@material-ui/core/RadioGroup';

const RadioButton = (props) => {
  const { RadioWrapper } = props;
  const handleChange = (event) => {
    props.onClick(event.target.value);
  };

  return (
    <RadioWrapper className="radio">
      <RadioGroup
        value={props.value}
        onChange={handleChange}
        defaultValue={props?.default}
      >
        {props.options.map((items) => {
          return (
            <FormControlLabel
              value={items.value}
              control={<Radio disabled={props.disabled} />}
              label={items.label}
              key={items.value}
              disabled={items.disabled}
            />
          );
        })}
      </RadioGroup>
    </RadioWrapper>
  );
};

RadioButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  value: PropTypes.string
};

RadioButton.defaultProps = {
  label: ''
};
export default RadioButton;