import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
ul{
    margin: 0;
}
li {
    border-bottom: 0 !important;
    .uploadSection{
        font-size: ${theme.palette.titleLabel} !important;
        align-self: center;
        padding-bottom: 0;
        font-size: 12px;
        padding-left: 0;
        span {
         color: #C92100;
        }
        .uploadText {
            font-size: 14px;
            font-weight: 500;
            color: #007aff;
        }
    }
    .imageUploadsection{
        display: none;
    }
    .imageUploadsectionsp {
        display: block;
        padding-right: 8px;
    }
    .iconContainer {
        align-items: center;
        .MuiAvatar-root {
            width: 27px;
            height: 27px;
        }
    }
    
    .downloadImage{
        font-size: 12px;
        color: #343E48;
        align-items: center;
    }
    .uploadSection + .MuiFormLabel-root{
        font-size: 15px !important;
        color: #222 !important;
        align-self: center;
        padding-bottom: 0;
        flex: 0 0 auto;
        padding-left: 31px;

    }
}
`}
`;
const UpdateWrapper = styled(Parent.UpdateWrapper)`
${({ theme }) => `
.uploadSection,
.details {
    font-size: 12px !important;
}
.details {
   color: #000 !important;
   font-size: 15px !important;
   font-weight: 500;
   opacity: .87;
   padding-bottom: 0;
}

.uploadDetails {
    font-size: 12px !important;
    color: #6d6d6d !important;
    padding-bottom: 0;
    line-height: 1.2 !important;
}
`}
`;
const ItemUrl = styled(Parent.ItemUrl)`
`;

const Styled = { Wrapper, UpdateWrapper, ItemUrl };
export default Styled;
