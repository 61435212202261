import {
  vehiclePageMapApi,
  vehiclePageHistoryMapApi,
  basicMeasuresApi,
  crashSummaryApi,
  inspectionSummaryApi,
  driverDetailsApi
} from '../../../config/apis';

const initialState = {
  data: [],
  type: '',
  basicMeasures: {},
  crashSummmary: [],
  inspectionSummary: [],
  driverDetails: {}
};

export const CSASnapshot = {
  state: { ...initialState },
  reducers: {
    listSuccess(state, payload, type) {
      return { ...state, data: [...payload], type: type };
    },
    listFailed(state) {
      return { ...state };
    },
    basicMeasureSuccess(state, payload) {
      return {...state, basicMeasures: {...payload.data}}
    },
    crashSummarySuccess(state, payload) {
      return { ...state, crashSummmary: [...payload.data] };
    },
    inspectionSummarySuccess(state, payload) {
      return { ...state, inspectionSummary: [...payload.data] };
    },
    driverStatusSuccess(state, payload) {
      return { ...state, driverDetails: {...payload.data} };
    }
  },
  effects: (dispatch) => ({
    async fetchVehiclePageMap(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: vehiclePageMapApi
          },
          id: payload.apiId,
          callback: payload.callback
        });
        response && this.listSuccess(response, 'vehicleMap');
      } catch (error) {
        // this.listFailed();
      }
    },
    async fetchVehiclePageHistoryMap(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            data: { ...payload.data },
            api: vehiclePageHistoryMapApi
          },
          id: payload.apiId,
          callback: payload.callback
        });
        response && this.listSuccess(response, 'vehicleHistoryMap');
      } catch (error) {
        // this.listFailed();
      }
    },
    async fetchBasicMeasures(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: basicMeasuresApi
          },
          id: payload.apiId
        });
        response && this.basicMeasureSuccess(response);
      } catch (error) {
        // this.listFailed();
      }
    },
    async fetchCrashSummary(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: crashSummaryApi
          },
          id: payload.apiId
        });
        response && this.crashSummarySuccess(response);
      } catch (error) {
        // this.listFailed();
      }
    },
    async fetchInspectionSummary(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: inspectionSummaryApi
          },
          id: payload.apiId
        });
        response && this.inspectionSummarySuccess(response);
      } catch (error) {
        // this.listFailed();
      }
    },
    async fetchDriverDetails(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: driverDetailsApi
          },
          id: payload.apiId
        });
        response && this.driverStatusSuccess(response);
      } catch (error) {
        // this.listFailed();
      }
    }
  })
};
