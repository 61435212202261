import React, { useEffect, useState, lazy } from 'react';
import PropTypes from 'prop-types';

// import Checkbox from '../../../components/UI/Checkboxs';
// import Button from '../../../components/UI/Button';
// import Label from '../../../components/UI/Label/style';
// import CustomMessage from '../../../components/CustomMessage';
import { hasValue } from '../../../utils/utils';

import Wrapper from './style';
const layout = window?.$environment?.CURRENT_LAYOUT;
// const Select = lazy(() => import(`../../../components/UI/Select/${layout}`));

const Component = ({
  apiStatus,
  subscriptions,
  getSubscriptions,
  saveSubscriptions
}) => {
  const [portal, setPortal] = useState({ label: 'gorilla', value: 'gs' });
  const handleSubscription = () => {
    saveSubscriptions(portal.label);
  };

  useEffect(() => {
    getSubscriptions('gorilla');
  }, [getSubscriptions]);
  const header = [
    { id: 'lite', text: 'Prime8 ELD' },
    { id: 'pro', text: 'Fleet Xpress' },
    { id: 'stdard', text: 'Xtreme' }
  ];
  const category = [
    { id: 'webFeatures', text: 'Web' },
    { id: 'mobileFeatures', text: 'Mobile' }
  ];
  useEffect(() => {
    let webRows =
      subscriptions.data[header[0].id] &&
      subscriptions.data[header[0].id][category[0].id];
    let mobileRows =
      subscriptions.data[header[0].id] &&
      subscriptions.data[header[0].id][category[1].id];
    const getData = () => {
      for (let i = 0; i < header.length; i++) {
        const item = header[i];
        for (let j = 0; j < category.length; j++) {
          const test = category[j];

          if (webRows && test.id === 'webFeatures') {
            const rows = Object.keys(webRows);
            for (let k = 0; k < rows.length; k++) {
              const row = rows[k];

              if (
                subscriptions.data[item.id] &&
                subscriptions.data[item.id][test.id][row]
              ) {
                if (typeof webRows[row] === 'boolean') {
                  webRows[row] = {};
                  webRows[row][item.id] = { isPresent: true };
                } else {
                  const isPresent = webRows[row][item.id] !== undefined;
                  if (!isPresent) {
                    webRows[row][item.id] = { isPresent: true };
                  }
                }
              } else {
                if (typeof webRows[row] === 'boolean') {
                  webRows[row] = {};

                  webRows[row][item.id] = { isPresent: false };
                } else {
                  const isPresent = webRows[row][item.id] !== undefined;
                  if (!isPresent) {
                    webRows[row][item.id] = { isPresent: false };
                  }
                }
              }
            }
          }
          if (mobileRows && test.id === 'mobileFeatures') {
            const rows = Object.keys(mobileRows);
            for (let k = 0; k < rows.length; k++) {
              const row = rows[k];

              if (
                subscriptions.data[item.id] &&
                subscriptions.data[item.id][test.id][row]
              ) {
                if (typeof mobileRows[row] === 'boolean') {
                  mobileRows[row] = {};
                  mobileRows[row][item.id] = { isPresent: true };
                } else {
                  const isPresent = mobileRows[row][item.id] !== undefined;
                  if (!isPresent) {
                    mobileRows[row][item.id] = { isPresent: true };
                  }
                }
              } else {
                if (typeof mobileRows[row] === 'boolean') {
                  mobileRows[row] = {};

                  mobileRows[row][item.id] = { isPresent: false };
                } else {
                  const isPresent = mobileRows[row][item.id] !== undefined;
                  if (!isPresent) {
                    mobileRows[row][item.id] = { isPresent: false };
                  }
                }
              }
            }
          }
        }
      }
    };
    getData();
    setData({ web: webRows, mobile: mobileRows });
  }, [category, header, subscriptions]);

  const [data, setData] = useState({});
  useEffect(() => {
    generateHeader();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const generateHeader = () => {
    const isCheckedAll = (id) => {
      let isAllClicked = true;
      if (data.web) {
        const webList = Object.keys(data.web);

        for (let index = 0; index < webList.length; index++) {
          const element = data.web[webList[index]];
          if (element[id].isPresent) {
            continue;
          } else {
            isAllClicked = false;
            return;
          }
        }
      }
      if (data.mobile) {
        const mobileList = Object.keys(data.mobile);

        for (let index = 0; index < mobileList.length; index++) {
          const element = data.mobile[mobileList[index]];
          if (element[id].isPresent) {
            continue;
          } else {
            isAllClicked = false;
            return;
          }
        }
      }
      return isAllClicked;
    };
    const handleUnCheckAll = (id) => {
      let tempData = { ...data };
      if (tempData.web) {
        const webList = Object.keys(tempData.web);

        for (let index = 0; index < webList.length; index++) {
          tempData.web[webList[index]][id].isPresent = false;
        }
      }
      if (tempData.mobile) {
        const mobileList = Object.keys(tempData.mobile);

        for (let index = 0; index < mobileList.length; index++) {
          tempData.mobile[mobileList[index]][id].isPresent = false;
        }
      }
      setData(tempData);
    };
    const handleCheckAll = (id) => {};
    return (
      <ul className="header">
        <li className={'headerItem'}></li>
        {header.map((item) => (
          <li className={'headerItem'}>
            {item.text}
            {isCheckedAll(item.id) ? (
              <sapn
                className={'check'}
                onClick={() => handleUnCheckAll(item.id)}
              >
                Uncheck All
              </sapn>
            ) : (
              <sapn className={'check'} onClick={() => handleCheckAll(item.id)}>
                Check All
              </sapn>
            )}
          </li>
        ))}
      </ul>
    );
  };
  const handlePortalChange = (event) => {
    setPortal(() => {
      getSubscriptions(event.label);
      return event;
    });
  };
  const hasApiStatus = hasValue(apiStatus.status, 'api', 'saveSubscriptions');
  const handleOnClick = (type, item, id) => {
    let temp = { ...data };
    temp[type][item][id] = !temp[type][item][id];
    setData(temp);
  };
  return (
    <Wrapper>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end'
        }}
      >
        {/* <Label variant="body1">Choose Portal</Label>
        <Select
          className="choose-portal"
          value={portal}
          onChange={handlePortalChange}
          suggestions={[
            { label: 'gorilla', value: 'gs' },
            { label: 'trackon', value: 'to' },
            { label: 'mapanything', value: 'ma' },
            { label: 'eldmandate', value: 'em' },
            { label: 'truckerpath', value: 'tp' }
          ]}
        /> */}
      </div>
      <div className="table">
        {generateHeader()}
        <div className={'subHeader'}>WEB</div>
        {data.web &&
          Object.keys(data.web).map((item) => {
            return (
              <ul className="row">
                <li className="rowItem">{item}</li>
                {Object.keys(data.web[item]).map((test) => {
                  return (
                    <li className="rowItem">
                      {/* <Checkbox
                        checked={data.web[item][test].isPresent}
                        onClick={() => handleOnClick('web', item, test)}
                      /> */}
                    </li>
                  );
                })}
              </ul>
            );
          })}

        <div className={'subHeader'}>Mobile</div>
        {data.mobile &&
          Object.keys(data.mobile).map((item) => {
            return (
              <ul className="row">
                <li className="rowItem"> {item}</li>
                {Object.keys(data.mobile[item]).map((test) => {
                  return (
                    <li className="rowItem">
                      {/* <Checkbox
                        checked={data.mobile[item][test].isPresent}
                        onClick={() => handleOnClick('mobile', item, test)}
                      /> */}
                    </li>
                  );
                })}
              </ul>
            );
          })}
      </div>
      {/* as model for success and failure message */}
      {/* {hasApiStatus && hasApiStatus.errorMessage && (
        <CustomMessage type="error" message={hasApiStatus.errorMessage} />
      )}
      <Button label={'Submit'} onClick={handleSubscription} /> */}
    </Wrapper>
  );
};

export default Component;

Component.propTypes = {
  subscriptions: PropTypes.object.isRequired,
  apiStatus: PropTypes.object.isRequired,
  getSubscriptions: PropTypes.func.isRequired,
  saveSubscriptions: PropTypes.func.isRequired
};
