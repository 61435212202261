import { UI_DATE_LOWER_FORMAT } from '@/config/constants/static';
import { getDateLocale } from '@/utils/utils';
import { Field } from 'formik';
import { debounce } from 'lodash';
import React, { lazy, useCallback, useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';

const layout = window?.$environment?.CURRENT_LAYOUT;
const SubHeader = lazy(() => import(`@/components/SubHeader/${layout}`));
const Input = lazy(() => import(`@/components/UI/Input/${layout}`));
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const CheckBox = lazy(() => import(`@/components/UI/Toggle/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Select = lazy(() => import(`@/components/UI/Select/${layout}`));
const RadioButton = lazy(() => import(`@/components/UI/Radio/${layout}`));

const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);

const RenderComponent = (props) => {
  const { i18n } = useTranslation();
  const {
    customProps,
    handleEdit,
    values,
    errors,
    isNotValid,
    vehicleList,
    submitAction,
    stateList,
    drivers,
    validateForm,
  } = props;
  const {
    Styled,
    isEdit,
    history,
    errorMessage = '',
    isUnknownState,
    fetchLatLong,
    setError,
    setLatLng,
  } = customProps;

  const showError = (field, validationErrors, isNotValid, apiError) => {
    if (validationErrors[field] && !isNotValid) {
      return <Message type="error" message={errors[field]} />;
    }
    if (apiError?.[field]) {
      return <Message type="error" message={apiError[field]} />;
    }
  };

  useEffect(() => {
    const afterDotNumber = 2;
    const startMeterReading = parseFloat(values?.startMeter);
    const endMeterReading = parseFloat(values?.endMeter);
    if (startMeterReading > -1 && endMeterReading > -1 && endMeterReading - startMeterReading > -1) {
      handleEdit((endMeterReading - startMeterReading).toFixed(afterDotNumber) || 0, { field: 'totalMiles' });
    }
  }, [values?.startMeter, values?.endMeter]);

  const fetchData = useCallback(debounce(async () => {
    // Your fetch logic here
    if (
      values?.state &&
      values?.addressFrom &&
      values.addressTo &&
      values?.startCity &&
      values?.endCity
    ) {
      const payload = {
        fromAddress: `${values?.addressFrom},${values?.startCity},${values?.state?.value}`,
        toAddress: `${values?.addressTo},${values?.endCity},${values?.state?.value}`,
      }
      const getLatLong = await fetchLatLong({
        data: payload,
        i18n
      });
      setLatLng(getLatLong)
      if (getLatLong?.error?.message) {
        setError(getLatLong?.error?.message || getLatLong?.errors[0]);
        return;
      }
    }
  }, 500), [values.addressFrom, values.addressTo, values.endCity, values.startCity, values.state]);

  useEffect(() => {
    fetchData();
    return () => fetchData.cancel();
  }, [fetchData])

  return (
    <>
      <SubHeader
        className="subheaderSection spireonDriver notspireonNew userHeading"
        text={isEdit ? i18n.t('iftaMileage.editState') : i18n.t('iftaMileage.addState')}
        variant="h2"
      />
      <Styled.Wrapper className="vehicleWrap">
        <Styled.LeftWrapper>
          <FormStyle.default.FieldsWrapper width="100%">
            <Label variant="body1">{i18n.t('iftaMileage.totalMiles')}</Label>
            <FormStyle.default.TextWrapper className="userForm">
              <Field
                name="totalMiles"
                handleOnChange={(event) => {
                  handleEdit(event, { field: 'totalMiles' });
                }}
                as={Input}
                value={values['totalMiles']}
                disabled={true}
                placeholder=""
                type="text"
              />
              {showError(
                'totalMiles',
                errors,
                isNotValid,
                customProps.hasApiStatus?.errorMessage
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper width="100%" className="filterFields">
            <Label variant="body1" className="filterLabel">
              {i18n.t('iftaMileage.Jurisdiction')}
            </Label>
            <FormStyle.default.TextWrapper className="filterField">
              <Field
                name="state"
                onChange={(event) => {
                  handleEdit(event, { field: 'state' });
                }}
                as={Select}
                isDisabled={false}
                placeholder={i18n.t('common.pleaseSelect')}
                value={values.state}
                suggestions={stateList}
                isClearable={true}
                disabled={!isUnknownState}
              />
              {errors?.state && !isNotValid && (
                <Message type="error" message={errors.state} />
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper width="100%" className="filterFields">
            <Label variant="body1" className="filterLabel">
              {i18n.t('common.vehicle')}
            </Label>
            <FormStyle.default.TextWrapper className="filterField">
              <Field
                name="vehicle"
                onChange={(event) => {
                  handleEdit(event, { field: 'vehicle' });
                }}
                as={Select}
                isDisabled={false}
                placeholder={i18n.t('common.pleaseSelect')}
                value={values.vehicle}
                suggestions={vehicleList}
                isClearable={true}
                disabled={isEdit}
              />
              {errors?.vehicle && !isNotValid && (
                <Message type="error" message={errors.vehicle} />
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper width="100%" className="filterFields">
            <Label variant="body1" className="filterLabel">
              {i18n.t('common.driver')}
            </Label>
            <FormStyle.default.TextWrapper className="filterField">
              <Field
                name="driver"
                onChange={(event) => {
                  handleEdit(event, { field: 'driver' });
                }}
                as={Select}
                isDisabled={false}
                placeholder={i18n.t('common.pleaseSelect')}
                value={values.driver}
                suggestions={drivers}
                isClearable={true}
                disabled={isEdit}
              />
              {errors?.driver && !isNotValid && (
                <Message type="error" message={errors.driver} />
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper width="100%" className="filterFields">
            <Label variant="body1" className="filterLabel">{i18n.t('common.date')}</Label>
            <FormStyle.default.TextWrapper>
              <Field
                name="date"
                onChange={(event) =>
                  handleEdit(event, { field: 'date', type: 'date' })
                }
                locale={getDateLocale()}
                as={ReactDatePicker}
                placeholderText={UI_DATE_LOWER_FORMAT}
                value={values.date}
                type="date"
                showPopperArrow={false}
                showTimeSelect={false}
                maxDate={new Date(Date.now() - 864e5)}
                showDisabledMonthNavigation
                disabled={isEdit}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper
            width="100%"
            className="roleSection"
          >
            <Label variant="body1">{i18n.t('iftaMileage.taxable')}</Label>
            <div className="roles">
              <FormStyle.default.FieldsWrapper className="checkWrapper">
                <FormStyle.default.CheckBoxWrapper direction="row" width="100%">
                  <RadioButton
                    aria-labelledby="subscriptionTier"
                    value={values.taxable}
                    options={[
                      {
                        label: i18n.t('common.yes'),
                        value: 'active'
                      },
                      {
                        label: i18n.t('common.no'),
                        value: 'inactive'
                      }
                    ]}
                    onClick={(event) => {
                      handleEdit(event, {
                        field: 'taxable',
                        type: 'radio'
                      });
                    }}
                  />
                </FormStyle.default.CheckBoxWrapper>
              </FormStyle.default.FieldsWrapper>
            </div>
          </FormStyle.default.FieldsWrapper>
        </Styled.LeftWrapper>
        <Styled.RightWrapper>
          <div className="subTitle"><Message type="info" message={i18n.t('common.fromLocation').toUpperCase()} /></div>
          {isUnknownState && (
            <>
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1">{i18n.t('common.address')}</Label>
                <FormStyle.default.TextWrapper className="userForm">
                  <Field
                    name="addressFrom"
                    handleOnChange={(event) => {
                      handleEdit(event, { field: 'addressFrom' });
                    }}
                    as={Input}
                    value={values['addressFrom']}
                    type="text"
                    disabled={!isUnknownState}
                  />
                  {showError(
                    'addressFrom',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1">{i18n.t('iftaMileage.startCity')}</Label>
                <FormStyle.default.TextWrapper className="userForm">
                  <Field
                    name="startCity"
                    handleOnChange={(event) => {
                      handleEdit(event, { field: 'startCity' });
                    }}
                    as={Input}
                    value={values['startCity']}
                    type="text"
                    disabled={!isUnknownState}
                  />
                  {showError(
                    'startCity',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
            </>
          )}
          <FormStyle.default.FieldsWrapper width="100%">
            <Label variant="body1">{i18n.t('iftaMileage.startMeter')}</Label>
            <FormStyle.default.TextWrapper className="userForm">
              <Field
                name="startMeter"
                handleOnChange={(event) => {
                  if (parseFloat(event) < 0) {
                    return;
                  }
                  handleEdit(event, { field: 'startMeter' });
                }}
                as={Input}
                placeholderText={i18n.t('iftaMileage.startMeter')}
                value={values['startMeter'] === 0 ? "0" : values['startMeter']}
                type="number"
                disabled={false}
              />
              {showError(
                'startMeter',
                errors,
                isNotValid,
                customProps.hasApiStatus?.errorMessage
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <div className="subTitle"><Message type="info" message={i18n.t('common.toLocation').toUpperCase()} /></div>
          {isUnknownState && (
            <>
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1">{i18n.t('common.address')}</Label>
                <FormStyle.default.TextWrapper className="userForm">
                  <Field
                    name="addressTo"
                    handleOnChange={(event) => {
                      handleEdit(event, { field: 'addressTo' });
                    }}
                    as={Input}
                    placeholderText={i18n.t('common.addressTo')}
                    value={values['addressTo']}
                    type="text"
                    disabled={!isUnknownState}
                  />
                  {showError(
                    'addressTo',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1">{i18n.t('iftaMileage.endCity')}</Label>
                <FormStyle.default.TextWrapper className="userForm">
                  <Field
                    name="endCity"
                    handleOnChange={(event) => {
                      handleEdit(event, { field: 'endCity' });
                    }}
                    as={Input}
                    value={values['endCity']}
                    type="text"
                    disabled={!isUnknownState}
                  />
                  {showError(
                    'endCity',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
            </>
          )}
          <FormStyle.default.FieldsWrapper width="100%">
            <Label variant="body1">{i18n.t('iftaMileage.endMeter')}</Label>
            <FormStyle.default.TextWrapper className="userForm">
              <Field
                name="endMeter"
                handleOnChange={(event) => {
                  if (parseFloat(event) < 0) {
                    return;
                  }
                  handleEdit(event, { field: 'endMeter' });
                }}
                as={Input}
                value={values['endMeter']}
                type="number"
                validate={(e) => { }}
                disabled={false}
              />
              {showError(
                'endMeter',
                errors,
                isNotValid,
                customProps.hasApiStatus?.errorMessage
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <Styled.SubmitUser>
            {Boolean(errorMessage) && <Message type="error" message={`${errorMessage}`} />}
            <FormStyle.default.ButtonsWrapper className="submitSection">
              <Button
                className="buttonStyle"
                type="reset"
                label={i18n.t('common.cancel')}
                onClick={(e) => {
                  history.goBack()
                }}
              />
              <Button
                type="submit"
                className="buttonStyle"
                label={i18n.t('common.save')}
                onClick={async (e, d) => {
                  const validateResponse = await validateForm();
                  submitAction(validateResponse);
                }}
              />
            </FormStyle.default.ButtonsWrapper>
          </Styled.SubmitUser>
        </Styled.RightWrapper>
      </Styled.Wrapper>
    </>
  );
};

RenderComponent.propTypes = {
};

export default RenderComponent;
