import React, { useEffect, lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RenderComponent from '@/components/modules/DynamicRenderPage';

import ParentStyled from './style';
import { loadStyle, loadPageConfig } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'pages/web/FleetCompanies');

const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.fleetCompanies[0].name}`));
const Content = lazy(() => import(`@/${config.fleetCompanies[1].name}`));
const TopActions =
  config.fleetCompanies[0].children &&
  lazy(() => import(`${config.fleetCompanies[0].children.name}`));

const Users = (parentProps) => {
  const {
    fetchFleetCompanies,
    getFilters,
    history,
    i18n,
    theme,
  } = parentProps;

  const commonParentProps = { history, i18n, Styled, theme };
  const [pagination, setPagination] = useState(false);

  useEffect(() => {
    fetchFleetCompanies({
      apiId: 'fetchFleetCompanies',
    });
    getFilters({ apiId: 'fetchFilters' });
  }, []);

  const [removeData, setremoveData] = useState('false')

  return (
    <>
      <RenderComponent
        {...config.fleetCompanies[0]}
        parentProps={{
          ...parentProps,
          pagination,
          setPagination,
          setremoveData,
          removeData
        }}
        commonParentProps={commonParentProps}
        component={Title}
        childComponent={TopActions}
      />
      <RenderComponent
        {...config.fleetCompanies[1]}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        component={Content}
        pagination={pagination}
        setPagination={setPagination}
        setremoveData={setremoveData}
        removeData={removeData}
      />
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    fleetCompanies: state.fleetCompanyList,
    filters: state.filters,
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchFleetCompanies: (data) => dispatch.fleetCompanyList.fetchData(data),
    filterSuccess: (data) => dispatch.fleetCompanyList.filterSuccess(data),
    getFilters: (data) => dispatch.filters.fetchData(data),
  };
};

export default connect(mapState, mapDispatch)(Users);

Users.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  fleetCompanies: PropTypes.object.isRequired,
  fetchFleetCompanies: PropTypes.func.isRequired,
  filterSuccess: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  getFilters: PropTypes.func.isRequired
};
