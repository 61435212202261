const initialState = {};
export const csaViolations = {
  state: { ...initialState },
  reducers: {
    getSuccess(state, payload) {
      return { ...payload };
    },
    getFailed(state) {
      return { ...state };
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload) {
      try {
        const getApi = await import(
          `@/config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('getCsaViolationsApi');

        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject,
            data: payload.data?.request
          },
          id: payload.apiId
        });
        response && this.getSuccess(response);
      } catch (error) {
        this.getFailed();
      }
    },
    async exportData(payload, rootState) {
      try {
        const getApi = await import(
          `@/config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('exportCsaViolationsApi');

        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject
          },
          callback: payload.callback,
          id: payload.id
        });
        payload.callback('success', response);
      } catch (error) {
        this.getFailed();
      }
    }
  })
};
