import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import RenderComponent from './RenderComponent';

import { hasValue } from '../../../../../utils/utils';
import { phoneRegExp } from '../../../../../utils/constants';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`../../../../../components/UI/Form/${layout}`));

const CompanyInfoForm = ({
  apiStatus,
  clearApiStatus,
  addCompanyInfo,
  states,
  getCompanyInfoApi,
  getCompanyInfo,
  addTruck
}) => {
  const { i18n } = useTranslation();

  const apiId = 'addCompany';
  const hasApiStatus = hasValue(apiStatus.status, 'api', apiId);
  return (
    <div>
      <div className="formLogin forgotPassword">
        <Form
          type="server"
          direction="column"
          initialValues={{
            companyName: getCompanyInfoApi?.companyName
              ? getCompanyInfoApi.companyName
              : '',
            address: getCompanyInfoApi?.address
              ? getCompanyInfoApi.address
              : '',
            state: getCompanyInfoApi?.state
              ? {
                  label: getCompanyInfoApi?.state,
                  value: getCompanyInfoApi?.state
                }
              : '',
            city: getCompanyInfoApi?.city ? getCompanyInfoApi.city : '',
            zipCode: getCompanyInfoApi?.zipCode
              ? getCompanyInfoApi.zipCode
              : '',
            companyPhone: getCompanyInfoApi?.companyPhone
              ? getCompanyInfoApi.companyPhone
              : '',
            dotNumber: getCompanyInfoApi?.dotNumber
              ? getCompanyInfoApi.dotNumber
              : '',
            initialTrucks: getCompanyInfoApi?.initialTrucks
              ? getCompanyInfoApi.initialTrucks
              : ''
          }}
          RenderComponent={RenderComponent}
          handleOnSubmit={(fields) => {
            if (getCompanyInfoApi?.companyId) {
              fields.companyId = getCompanyInfoApi?.companyId;
            }
            fields.state = fields.state.value;
            addCompanyInfo({
              id: 'addCompany',
              data: fields
            });
          }}
          customProps={{
            hasApiStatus,
            clearApiStatus,
            states,
            addTruck
          }}
          validationSchema={Yup.object().shape({
            companyName: Yup.string()
              .required(i18n.t('common.fieldRequiredMessage'))
              .test(
                'len',
                'Must have atleast 4 characters',
                (val) => val && val.length > 3
              ),
            address: Yup.string().required(
              i18n.t('common.fieldRequiredMessage')
            ),
            state: Yup.string().required(i18n.t('common.fieldRequiredMessage')),
            city: Yup.string().required(i18n.t('common.fieldRequiredMessage')),
            zipCode: Yup.string().required(
              i18n.t('common.fieldRequiredMessage')
            ),
            companyPhone: Yup.string()
              .matches(phoneRegExp, i18n.t('common.validNumFormat'))
              .required(i18n.t('common.fieldRequiredMessage')),
            dotNumber: Yup.number()
              .typeError('Please use numbers with maximum length 9')
              .required(i18n.t('common.fieldRequiredMessage')),
            initialTrucks: Yup.number()
              .typeError('Please use integers with maximum length 3')
              .required(i18n.t('common.fieldRequiredMessage'))
              .min(1, 'Please enter a valid integer value')
              .integer('Please enter valid integer')
          })}
        />
      </div>
    </div>
  );
};

export default CompanyInfoForm;

CompanyInfoForm.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  requestForgotPassword: PropTypes.func.isRequired,
  callback: PropTypes.func,
  clearApiStatus: PropTypes.func.isRequired
};
