import styled from 'styled-components';
import Parent from './style';
const Wrapper = styled(Parent.Wrapper)`
`;
const TopWrapper = styled(Parent.TopWrapper)``;
const SearchWrapper = styled(Parent.SearchWrapper)``;
const TableWrapper = styled(Parent.TableWrapper)`
${({ theme }) => `
`}
`;
const LabelWrap = styled(Parent.LabelWrap)``;

const Styled = {
    Wrapper,
    SearchWrapper,
    TopWrapper,
    TableWrapper,
    LabelWrap
  };
  export default Styled;
