/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Styled from './style';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { externalUrls, imageUrls } from '../../../../../config/constants/keys';
import { getImagePath } from '../../../../../utils/utils';

const ApplicationDownload = ({ history, loginInfo }) => {
  const [username, setUserName] = useState('');
  return (
    <Styled.Wrapper>
      <div className="welcomeText">
        <h1 className="stepTitle">Application Download</h1>
        <div className="iframeWrapper">
          <iframe
            src="https://www.youtube.com/embed/rCJ6OplERYY"
            title="hardware-installation"
            className="respIframe"
          ></iframe>
        </div>
        <div className="details">
        Your drivers will need to download the application to their phone or tablet. The application is easy to find on the Google Play Store, as well as on the Apple App Store. As with the previous video, you can view it now, find the <a className="linkColor" href='https://www.youtube.com/embed/rCJ6OplERYY'>link</a> or select share video below and send the link to someone. Select “Next” to move to the next page when ready.
        </div>
      </div>
      <div className="downloadWrapper">
          <span className="downloadLabel">Download the App</span>
        <Link href={'https://play.google.com/store?hl=en'} target="_blank" className="downloadApp">
          <img
            src={getImagePath(imageUrls.googleplay)}
            alt="Icon for google play"
            className="downloadApp"
          />
        </Link>
        <Link href={'https://www.apple.com/ios/app-store/'} target="_blank" className="downloadApp">
          <img
            src={getImagePath(imageUrls.appStore)}
            alt="Icon for app store"
            
          />
        </Link>
      </div>
    </Styled.Wrapper>
  );
};

export default ApplicationDownload;

ApplicationDownload.propTypes = {
  history: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired
};
