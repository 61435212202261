import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
  .partsName {
    font-size: 16px;
  }
`;
const TopWrapper = styled(Parent.TopWrapper)``;
const AreasListkWrapper = styled(Parent.AreasListkWrapper)`
.addNewParts {
  font-size: 14px!important;
}`;
const AreaButtonkWrapper = styled(Parent.AreaButtonkWrapper)``;
const leftSection = styled(Parent.leftSection)``;
const rightSection = styled(Parent.rightSection)``;
const Styled = {
  Wrapper,
  TopWrapper,
  AreaButtonkWrapper,
  AreasListkWrapper,
  leftSection,
  rightSection
};

export default Styled;
