import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const TopWrapper = styled(Box)`
  ${({ theme }) => `
  flex: 0 0 64%;
  display: flex;
  @media (max-width: ${theme.breakpoints.values.smd}px)  {
    flex-direction: column;
  }
  `}
`;

const SearchWrapper = styled.div`
  ${({ theme }) => `
  flex: 0 0 40%;
  @media (max-width: ${theme.breakpoints.values.mlg}px)  {
    flex: 0 0 38%;
  }
  #clearSearch {
    display: none;
  }
  `}
`;

const TableWrapper = styled(Box)`
  padding-top: 0;
`;
const Styled = {
  Wrapper,
  SearchWrapper,
  TopWrapper,
  TableWrapper
};
export default Styled;
