import React, { lazy, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Select = lazy(() => import(`@/components/UI/Select/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));

const defaultFilter = [
    {
        label: 'Last 30 days',
        value: 'MONTHLY'
    },
    {
        label: 'Current Policy time Period',
        value: 'YEARLY'
    }
];

const MainFilter = React.memo(({ onChange = () => {} }) => {
    const items = defaultFilter;
    const selectRef = useRef();
    const [value, setValue] = useState(defaultFilter[0]);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const i18n = useTranslation();

    const handleChange = useCallback((event) => {
        if (event === value) {
            return;
        }
        setValue(event);
        onChange(event);
        setMenuIsOpen(false);
    }, [value, onChange]);

    const toggleMenu = () => {
        setMenuIsOpen(prev => !prev);
        selectRef.current && selectRef.current.focus();
    };

    return (
        <FormStyle.default.FieldsWrapper className="horizontal" onClick={toggleMenu}>
            <Label variant="body1" htmlFor="severityId">
                {i18n.t('newScoreCard.selectPeriod')}
            </Label>
            <FormStyle.default.TextWrapper>
                <Select
                    ref={selectRef}
                    menuIsOpen={menuIsOpen}
                    onMenuClose={() => setMenuIsOpen(false)}
                    id="severityId"
                    className="choose-period"
                    value={value}
                    onChange={handleChange}
                    placeholder={i18n.t('common.pleaseSelect')}
                    suggestions={items || []}
                />
            </FormStyle.default.TextWrapper>
        </FormStyle.default.FieldsWrapper>
    );
});

export default MainFilter;
