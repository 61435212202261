import React, { lazy, useState } from 'react';

import { imageUrls } from '@/config/constants/keys';
import filterListForm from './Form';
import ExportPopup from './ExportPopup';
import ParentStyled from './style';
import { loadStyle } from '@/utils/utils';
import { useHistory } from 'react-router-dom';
const isEs = () => localStorage.getItem(window.$environment?.SERVICE_PROVIDER + 'Language') == 'es';

let Styled = loadStyle(
  ParentStyled,
  'components/modules/DataTableWithSearchExport'
);
const layout = window?.$environment?.CURRENT_LAYOUT;

const Search = lazy(() => import(`@/components/UI/SimpleSearch/${layout}`));
const Export = lazy(() => import(`@/components/UI/Export/${layout}`));

const TopActions = ({
  filterSuccess,
  columns,
  i18n,
  needExportAll,
  exportAllData,
  apiIds,
  needFilter,
  list,
  needSearch,
  fileName,
  exportCsvData,
  exportPdfData,
  logBook,
  getDocumentToken,
  needExportPopup,
  setPagination,
  isOldView
}) => {
  const history = useHistory();

  const [values, setValues] = useState({
    driver: '',
    dateFrom: '',
    dateTo: ''
  });
  const [isExportClicked, setIsExportClicked] = useState(false);
  const isWebfleet = window.$environment.SERVICE_PROVIDER === 'webfleet'
  const selectChange = (e) => {
    filterSuccess(e);
    setPagination(true);
  };
  const handleExportAsCsv = () => {
    const query = new URLSearchParams(history.location.search);
    const beBaseUrl = window?.$environment?.BE_BASE_URL;

    getDocumentToken && getDocumentToken({ apiId: 'exportUser' });
    let pageurl = window.location.href.includes('alerts')
      ? '/alerts/export/CSV?token='
      : window.location.href.includes('driverStatus')
        ? `/driverStatus/export/CSV?id=${query.get('id')}&token=`
        : '/violations/exportNonDeletedViolations?token=';
    let url = beBaseUrl + pageurl + logBook.documentToken;
    const iframe = document.getElementById('importUserTemplateFrame');
    iframe.contentWindow.fetch(url, {
        headers: {
          'Accept-Language': isEs() ? 'es' : 'en'
        }
    }).then(response => {
        if(response.ok) {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'file.csv';
                a.click();
            });
        } else {
            console.log('Error: ' + response.status);
        }
    }).catch(error => {
        console.log(error);
    });
  };
  return (
    <Styled.Wrapper>
      {isOldView && (
        <div className="exportAlertSection">
          <Export
            src={imageUrls.exportIcon}
            data={[...columns]}
            filename={i18n.t('Export')}
            toolTip={i18n.t('common.exportData')}
            className="exportContainer export"
            onClick={() => {
              window.$environment.CURRENT_LAYOUT === 'SpireonNew'
                ? handleExportAsCsv()
                : setIsExportClicked(true);
            }}
            buttonName={i18n.t('Export')}
          />
          {needExportAll && (
            <Export
              src={imageUrls.exportAll}
              addFilter={isWebfleet}
              filename={i18n.t('Export All')}
              toolTip={i18n.t('common.exportAll')}
              className="exportContainer"
              onClick={() => {
                exportAllData({ apiId: apiIds.export });
              }}
              buttonName={i18n.t('Export All')}
            />
          )}
        </div>
      )}
      {needFilter &&
        filterListForm(
          values,
          list,
          filterSuccess,
          setValues,
          i18n,
          setPagination
        )}
      <div style={{display:'flex'}}>
        {needSearch && (
          <Styled.SearchWrapper>
            <Search
              onChange={(e) => selectChange(e)}
              default={list?.list}
              fromData
              placeHolder={i18n.t('dashboard.tabs.driverStatus.heading')}
              query={'driver_Status'}
              id="driver_Status"
            ></Search>
          </Styled.SearchWrapper>
        )}
        {!isOldView && (
          <>
            <Export
              src={imageUrls.exportIcon}
              data={[...columns]}
              filename={i18n.t('Export')}
              toolTip={i18n.t('common.export')}
              className={
                needExportPopup
                  ? 'exportHistory exportContainer'
                  : 'exportContainer'
              }
              onClick={() => {
                setIsExportClicked(true);
              }}
              buttonName={i18n.t('Export')}
            />
            {needExportAll && (
              <Export
                src={imageUrls.exportAll}
                addFilter={isWebfleet || window.$environment.WHITE_THEME}
                filename={i18n.t('Export All')}
                toolTip={i18n.t('common.exportAll')}
                className="exportContainer"
                onClick={() => {
                  exportAllData({ apiId: apiIds.export });
                }}
                buttonName={i18n.t('Export All')}
              />
            )}
          </>
        )}

      </div>

      <ExportPopup
        isOpen={isExportClicked}
        setIsOpen={setIsExportClicked}
        list={list}
        fileName={fileName}
        exportCsvData={exportCsvData}
        exportPdfData={exportPdfData}
        Styled={Styled}
        logBook={logBook}
        history={history}
        getDocumentToken={getDocumentToken}
      />
    </Styled.Wrapper>
  );
};
export default TopActions;
