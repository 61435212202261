import styled from 'styled-components';
import Parent from '../style';

const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
    .policyLine {
        display: flex;
        padding-top: 30px;
        padding-bottom: 15px;
        padding-left: 2%;
        .policyText {
            color: ${theme.colors.PRIMARY_CONTRAST};
            padding-right: 20px;
            font-size: 20px;
            p {
                margin: 0;
            }
            p:last-child {
                margin-top: 5px;
                color: ${theme.colors.UPLOAD_LABEL};
            }
        }
        .filepond--drop-label.filepond--drop-label label {
            text-align: center;
            width: 100%;
        }
        .filepond--drop-label {
            padding-left: 0;
        }
        .filepond--list {
            height: 100%;
            width: 100%;
            left: 0;
            right: 0;
        }
        .filepond--wrapper {
            height: 120px;
            width: 120px;
        }
        .filepond--image-preview-overlay-idle {
            opacity: 0 !important;
        }
        .filepond--item {
            margin: 0;
        }
        .filepond--image-preview-overlay,
        .filepond--item,
        .filepond--root,
        .filepond--root .filepond--drop-label,
        .filepond--browser.filepond--browser,
        .filepond--list-scroller {
            height: 100% !important;
        }
        .filepond--image-canvas-wrapper {
            width: 412px !important;
            height: 421px !important;
        }
        .filepond--image-preview-overlay svg {
            width: 120px !important;
            height: 120px !important;
        }
        .filepond--file-info,
        .filepond--action-remove-item{
            position: absolute;
            top: 42px;
        }
        .filepond--image-bitmap,
        .filepond--image-clip {
            width: 100% !important;
            height: 100% !important;
        }
    }
    .selectedCheckbox {
        display: flex;
        flex-wrap: wrap;
        padding-top: 35px;
    }
    #submitPolicy {
        min-width: 210px;
    }
`}
`;


const Styled = { Wrapper };

export default Styled;
