import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
.MuiExpansionPanelSummary-root { 
  h2 {
    text-transform: capitalize;
  }
}
.MuiPaper-root {
  box-shadow: none;
}
.MuiAccordionSummary-content.Mui-expanded h2 {
  text-transform: capitalize;
  font-size: 22px !important;
}
.MuiAccordionSummary-root.Mui-expanded {
  min-height: 50px;
  background-color: ${theme.colors.LINK_COLOR};
  opacity: 1;
  padding-left: 5%;
}
.MuiAccordion-root.Mui-expanded{
  margin: 0;
}
.MuiAccordionDetails-root {
  padding: 0 5%;
}
.MuiCollapse-wrapperInner {
  background-color: ${theme.colors.PRIMARY_MAIN};
}
.MuiExpansionPanelDetails-root {
  position: relative;
  padding-top: 0px;
}
.viewAll {
  position: absolute;
  top: 5px;
  width: 50%;
  right: 20px;
  top: 10px;
  width: 50%;
  right: 5%;
  padding-bottom:0;
  font-family: tahoma;
  font-size: 14px;
  a{
    color: #fff;
  }
  .normalCheckbox {
    svg {
      width: 22px;
      height: 22px;
      background: #f8f8f8;
    }
    .Checkbox {
      top: 0;
      rect.square {
        stroke: #fff;
      }
    }
      label {
      color: #fff !important;
      position: relative;
      font-size: 14px;
      top: -3px;
    }
  }
}
.statusIcon .iconContainer:first-child {
  order:2;
  img {
    width: 26px;
    height: 25px;
    margin-left: 8px;
  }
}
`}
`;
const Header = styled(Parent.Header)``;
const Content = styled(Parent.Content)`
.MuiPaper-root {
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
  padding: 0;
  border-radius: 0;
  box-shadow: none;
}
`;

const Accordion = { Wrapper, Header, Content  };

export default Accordion;
