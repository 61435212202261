import styled from 'styled-components';
import { Box } from '@material-ui/core';

const ChartWrapper = styled(Box)`
  ${({ theme }) => `
  .btn-disabled {
    opacity: 0.8;
    cursor: not-allowed !important;
  }
  .updateEventsectionWrapper{
    display:flex;
    justify-content: space-between;
    .disableProvider {
      opacity: 0.5;
      pointer-events: none;
    }
    .providerWrapper{
      .ant-switch-checked {
          background-color: ${theme.colors.SECONDARY_MAIN} !important;
      }
      .ant-switch {
        margin: 0;
        padding: 0;
        font-size: 14px;
        list-style: none;
        position: relative;
        display: inline-block;
        box-sizing: border-box;
        min-width: 44px;
        height: 22px;
        line-height: 22px;
        vertical-align: middle;
        border: 0;
        border-radius: 100px;
        background-color: ${window.$environment.WHITE_THEME ? '#00000066' : '#ffffff80'};
        cursor: pointer;
        transition: all .2s;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      .ant-switch-checked .ant-switch-inner {
        margin: 0 25px 0 7px;
      }
      .ant-switch-handle:before {
        position: absolute;
        inset: 0;
        background-color: #fff;
        border-radius: 9px;
        box-shadow: 0 2px 4px #00230b33;
        transition: all .2s ease-in-out;
        content: "";
      }
      .ant-switch-handle {
        position: absolute;
        top: 2px;
        left: 2px;
        width: 18px;
        height: 18px;
        transition: all .2s ease-in-out;
      }
      .ant-switch-checked .ant-switch-handle {
        left: calc(100% - 20px);
      }
      .ant-switch-inner {
        display: block;
        margin: 0 7px 0 25px;
        color: #fff;
        font-size: 12px;
        transition: margin .2s;
      }

      width: fit-content;
      align-items: center;
      gap: 10px;
      display: flex;
      .providerLabel {
        color: ${theme.colors.PRIMARY_CONTRAST};
        font-size: 20px;
        margin-top: 3px;
        cursor: pointer;
      }
      .infoIcon{
        color: ${theme.colors.PRIMARY_CONTRAST};
      }
    }
    .providerIcon{
      width: fit-content;
      .MuiAvatar-root{
        width: 35px !important;
        height: 35px !important;
      }
    }
  }
width: 100%;
.graphMessage {
  color: #fff;
  text-align: center;
}
  .svg-container {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    position: relative;
    width: 100%;
    margin-top: 4%;
    padding-bottom: 26%;
    vertical-align: top;
  }
  .svg-content {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    position: absolute;
    top: 0;
    left: 0;
    text {
      font-size: 3px !important;
    }
    .overlay .selectedArea {
      fill: ${theme.colors.SECONDARY_MAIN} !important;
      fill-opacity: 0.6;
    }
    rect.square {
      fill: ${theme.colors.FORM_BACKGROUND};
    }
    leftTime,
    rightTime{
      fill:  ${theme.colors.SECONDARY_MAIN};
    }
    rect.leftChip,
    rect.rightChip{
      fill: #fff;
      stroke:  ${theme.colors.SECONDARY_MAIN};
    }
    line.leftStick,
    line.rightStick{
      stroke:  ${theme.colors.SECONDARY_MAIN};
    }
    .leftPoly,
    .rightPoly {
      fill: ${theme.colors.SECONDARY_MAIN};
    }
    .stepGraph polyline {
      stroke: ${theme.colors.SECONDARY_MAIN};
    }
  }
  .updateEventsection {
    flex-wrap: wrap;
    padding-bottom: 16px;
    @media (max-width: ${theme.breakpoints.values.lg}px) {
      justify-content: center;
      padding-top: 12px;
      .MuiBox-root {
        margin-bottom: 5px;
      }
    }
    .button-status{
      width:95%;
    }
    .button--disabled, .button--disabled:hover, .button--status-normal {
      background-color: transparent;
    }
    .MuiButton-outlinedSizeSmall {
      padding: 3px 14px !important;
    }
    .MuiButtonBase-root{
      border: 1px solid ${theme.colors.INPUT_BORDER};
    }
    #update_graph + div {
      .button--disabled:hover {
        background-color: transparent;
      }
      // #button {
      //   border: none;
      // }
    }
    #update_graph .button-update {
      // background: transparent !important;
    }
  }
  .button--disabled: hover {
    background-color: transparent;
    color: ${theme.palette.primary.contrastText};
    cursor: inherit;
  }
   
  .button--status-normal: hover,
  .button--update-normal: hover,
  .button--update-normal,
  .button-add {
    color: ${theme.palette.secondary.contrastText + '!important'};
    background-color: ${theme.colors.SECONDARY_MAIN} !important;
    cursor: pointer;
  }
  .button-add {
    font-size: 12px !important;
    text-transform: uppercase;
  }

  .active {
    background-color: ${theme.palette.secondary.main}!important ;
    color: ${theme.palette.secondary.contrastText}!important ;
  }

  .button-status {
    margin-right: 4px;
    padding-top: 0px;
    padding-bottom: 0px;
    color: ${theme.palette.primary.contrastText} !important;
    background-color: transparent;
    font-size: 12px;
    border: 1px solid ${theme.colors.INPUT_BORDER}!important;
    // border: ${`1px solid ${theme.palette.primary.border}`};
    @media (max-width: ${theme.breakpoints.values.md}px) {
      font-size: 15px;
    }
    @media (max-width: ${theme.breakpoints.values.sm}px) {
      padding: 0;
    }
  }

  .button-update {
    font-size: 12px !important;
    margin-left: 0.5rem;
    color: ${theme.palette.primary.contrastText} !important;
    background-color: ${theme.palette.secondary.main};
    border: ${`1px solid ${theme.palette.secondary.main}`};
    text-transform: uppercase;
    padding: 0px 26px;
    width: auto !important;
    @media (max-width: ${theme.breakpoints.values.md}px) {
      font-size: 15px;
      padding: 0 15px;
    }

  }
  #addNewRow {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    justify-content: flex-end;
  }
  .cancelSubmitwrapper {
    width:100%;
  }
  `}
`;
const LabelWrap = styled(Box)`
  p {
    font-size: 21px;
    margin-bottom: 20px;
  }
`;
const StatusModifier = styled(Box)`
  ${({ viewOnly, theme }) => `
.MuiTable-root {
  pointer-events: ${viewOnly ? 'none' : 'auto'};
}
  .MuiInputBase-root .MuiInputBase-input{
    color: ${theme.colors.INPUT_COLOR};
  }
  .MuiPaper-rounded .MuiBox-root {
    overflow: visible;
  }
  .MuiTableHead-root .MuiTableRow-head .MuiTableCell-root:first-child {
    min-width: 140px;
  }
  .driverId,
  .stateModifier {
    .select__placeholder {
      font-size: 14px;
    }
    .select__dropdown-indicator {
      padding-left: 0;
      padding: 3px;
    }
  }
  .react-datepicker--time-only {
    border: 2px solid colors.PRIMARY_BORDER;
    color: colors.PRIMARY_CONTRAST;
    font-family: 'Open sans';
    font-size: 12px;
    font-weight: normal;
    .react-datepicker__time-list {
      padding-top: 6px;
    }
    // .react-datepicker__time-list::-webkit-scrollbar {
    //   width: 0;
    // }
    .react-datepicker__time-list-item {
      border-top: none !important;
    }
    .react-datepicker__header--time {
      display: none;
    }
  }
  `}
`;
const Styled = { ChartWrapper, LabelWrap, StatusModifier };
export default Styled;
