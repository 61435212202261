import { UI_DATE_LOWER_FORMAT } from '@/config/constants/static';
import { getDateLocale } from '@/utils/utils';
import CalendarToday from '@material-ui/icons/CalendarToday';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import React, { lazy } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Input = lazy(() => import(`@/components/UI/Input/${layout}`));
const Select = lazy(() => import(`@/components/UI/Select/${layout}`));
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const RenderComponent = ({
  values,
  handleEdit,
  errors,
  isNotValid,
  validateForm,
  customProps,
  canadaForm,
  submitAction
}) => {
  const { i18n } = useTranslation();
  //Date format to display date in UI from config
  const dateFormat = UI_DATE_LOWER_FORMAT;
  const fields = {
    driverId: {
      type: 'select',
      value: '',
      field: 'driverId',
      placeholder: i18n.t('common.pleaseSelect'),
      label: i18n.t('Driver Name')
    },
    startDate: {
      type: 'date',
      value: '',
      field: 'startDate',
      placeholder: dateFormat,
      label: i18n.t('common.dateFrom'),
      maxDate: 'endDate',
    },
    endDate: {
      type: 'date',
      value: '',
      field: 'endDate',
      placeholder: dateFormat,
      label: i18n.t('common.dateTo'),
      minValue: 'startDate'
    },

    outputFileComment: {
      type: 'text',
      value: '',
      field: 'outputFileComment',
      placeholder: canadaForm ? i18n.t("Comment") : i18n.t('Dot Officer Code'),
      label: canadaForm? i18n.t("Comment") : i18n.t('Dot Officer Code')
    },
    email: {
      type: 'text',
      value: '',
      field: 'email',
      placeholder: i18n.t('Email'),
      label: i18n.t('Email')
    }
  };
  return (
    <>
      <FormStyle.default.FieldsWrapper
        className="vertical driverId"
        width="18%"
      >
        <Label variant="body1">{fields.driverId.label}</Label>
        <FormStyle.default.TextWrapper className="driverTextbox">
          <Field
            name={fields.driverId.field}
            onChange={(event) => handleEdit(event, fields.driverId)}
            as={Select}
            placeholder={
              window.$environment.SERVICE_PROVIDER === 'spireon'
                ? 'Select User'
                : fields.driverId.placeholder
            }
            // placeholder={
            //   fields.driverId.placeholder ? fields.driverId.placeholder : ''
            // }
            value={values.driverId}
            //type={fields.driverId.label}
            suggestions={customProps.drivers.data}
            isClearable
          />

          {errors['driverId'] && !isNotValid && (
            <Message type="error" message={errors['driverId']} />
          )}
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>

      <FormStyle.default.FieldsWrapper className="vertical" width="18%">
        <Label variant="body1">{fields.startDate.label}</Label>
        <FormStyle.default.TextWrapper>
          <Field
            name={fields.startDate.field}
            onChange={(event) => handleEdit(event, fields.startDate)}
            as={ReactDatePicker}
            locale={getDateLocale()}
            placeholderText={
              fields.startDate.placeholder ? fields.startDate.placeholder : ''
            }
            value={values.startDate}
            type={fields.startDate.label}
            showPopperArrow={false}
            showTimeSelect={fields.startDate.isTimeNeeded}
            minDate={
              fields.startDate.minValue && values[fields.startDate.minValue]
                ? new Date(values[fields.startDate.minValue])
                : new Date('01/01/2000')
            }
            maxDate={values[fields.startDate.maxDate] ? new Date(values[fields.startDate.maxDate]) : new Date()}
            showDisabledMonthNavigation
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
          {window.$environment.CURRENT_LAYOUT === 'SpireonNew' && (
            <CalendarToday />
          )}
          {errors['startDate'] && !isNotValid && (
            <Message type="error" message={errors['startDate']} />
          )}
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.FieldsWrapper className="vertical" width="18%">
        <Label variant="body1">{fields.endDate.label}</Label>
        <FormStyle.default.TextWrapper>
          <Field
            name={fields.endDate.field}
            onChange={(event) => handleEdit(event, fields.endDate)}
            as={ReactDatePicker}
            locale={getDateLocale()}
            placeholderText={
              fields.endDate.placeholder ? fields.endDate.placeholder : ''
            }
            value={values.endDate}
            type={fields.endDate.label}
            showPopperArrow={false}
            showTimeSelect={fields.endDate.isTimeNeeded}
            minDate={
              fields.endDate.minValue && values[fields.endDate.minValue]
                ? new Date(values[fields.endDate.minValue])
                : new Date('01/01/2000')
            }
            maxDate={new Date()}
            showDisabledMonthNavigation
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
          {window.$environment.CURRENT_LAYOUT === 'SpireonNew' && (
            <CalendarToday />
          )}
          {errors['endDate'] && !isNotValid && (
            <Message type="error" message={errors['endDate']} />
          )}
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.FieldsWrapper className="vertical" width="18%">
        <Label variant="body1">{fields.outputFileComment.label}</Label>
        <FormStyle.default.TextWrapper>
          <Field
            name={fields.outputFileComment.field}
            handleOnChange={(event) =>
              handleEdit(event, fields.outputFileComment)
            }
            as={Input}
            placeholder={
              fields.outputFileComment.placeholder
                ? fields.outputFileComment.placeholder
                : ''
            }
            value={values.outputFileComment}
            type={fields.outputFileComment.label}
          />
          {errors['outputFileComment'] && !isNotValid && (
            <Message type="error" message={errors['outputFileComment']} />
          )}
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      {canadaForm && <FormStyle.default.FieldsWrapper className="vertical" width="18%">
        <Label variant="body1">{fields.email.label}</Label>
        <FormStyle.default.TextWrapper>
          <Field
            name={fields.email.field}
            handleOnChange={(event) =>
              handleEdit(event, fields.email)
            }
            as={Input}
            placeholder={
              fields.email.placeholder
                ? fields.email.placeholder
                : ''
            }
            value={values.email}
            type={fields.email.label}
          />
          {errors['email'] && !isNotValid && (
            <Message type="error" message={errors['email']} />
          )}
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>}

      <FormStyle.default.ButtonsWrapper className="vertical-button">
        <Button
          type="submit"
          label={i18n.t('common.submit')}
          onClick={() => {
            const validateResponse = validateForm();
            validateResponse.then((data) => submitAction(data));
          }}
        />
      </FormStyle.default.ButtonsWrapper>
    </>
  );
};

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  customProps: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};
export default RenderComponent;
