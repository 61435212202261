import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  flex-direction: column;
`;
const TableWrapper = styled(Box)`
  ${({ theme }) => `
  padding-top: 2%;
  a {
    color: #f58220;
    text-decoration: none;
  }
  .viewReport {
    color: ${theme.colors.SECONDARY_MAIN};
    text-decoration: none;
  }
  `}
`;
const Inspectionview = styled.div`
  .MuiGrid-container {
    justify-content: flex-end;
    .MuiAvatar-root {
      width: 32px;
      height: 32px;
    }
  }
   .roadDetails {
    display: flex;
    }
    .viewmore {
      display: none;
    }
`;
const Styled = {
  Wrapper,
  TableWrapper,
  Inspectionview
};
export default Styled;
