import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Header = styled(Box)`
  ${({ theme }) => `
  display: flex;
  padding-bottom: 30px;
  // padding: 3%;
  position: relative;
  justify-content: space-between;
  .filterForm {
    flex: 0 0 90%;
    .MuiCheckbox-root {
      padding: 7px !important;
    }
    div[type='text'],
    div[type='date'], div[type='select'] {
      width: 18%;
      flex-direction: column;
      p {
        color: ${theme.colors.PRIMARY_CONTRAST};
      }
      @media (max-width: ${theme.breakpoints.values.md}px) {
        width: auto;
        margin-bottom: 16px;
      }
      @media only screen and (min-width: ${theme.breakpoints.values.md}px) and (max-width: ${theme.breakpoints.values.mlg}px) {
        margin-right: 18px;
      }
    }
    div[type='submit'],
    div[type='reset'] {
      align-items: flex-end;
      @media (max-width: ${theme.breakpoints.values.md}px) {
        margin-bottom: 16px;
      }
    }
    .MuiInput-root {
      @media (max-width: ${theme.breakpoints.values.md}px) {
        padding: 12px 10px;
      }
    }
    .MuiInput-root,
    .react-datepicker-wrapper {
      @media (max-width: ${theme.breakpoints.values.md}px) {
        min-width: 180px;
      }
    }
    .MuiTypography-body1 {
      font-size: 20px;
    }
  }
  .exportForm {
    flex-wrap: nowrap !important;
    padding-left: 10px;
    padding-bottom: 10px;
  }

  .MuiGrid-root {
    justify-content: flex-end;
  }
  .exportContainer {
    display: flex;
    align-items: flex-end;
  }
  .tooltiptext {
    width: 155px;
    font-size: 15px;
    right: 0;
    top: 82px;
  }
  .MuiAvatar-root {
    width: 35px;
    height: 35px;
  }
  @media (max-width: ${theme.breakpoints.values.md}px) {
    flex-direction: column;
  }
  `}
`;
const ToolsContainer = styled(Box)`
  ${({ theme, selectedindex }) => `
  position: absolute;
  background: ${
    (theme.palette.primary || {}).main || theme.colors.PRIMARY_MAIN
  };
  border: solid 2px
    ${(theme.palette.primary || {}).border || theme.colors.PRIMARY_BORDER};
  top: ${selectedindex ? selectedindex + 'px' : '280px'};
  right: 0;
  li {
    list-style: none;
  }
  a {
    color: ${
      (theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN
    };
    font-size: 20px;
  }
  .ToolsOptionClose {
    color: ${
      (theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN
    };
    position: absolute;
    margin-top: 10px;
    margin-right: 8px;
  }

  ul {
    margin-top: 20px;
    width: 175px;
    height: auto;
    padding-inline-start: 25px;
  }
  cursor: pointer;
  `}
`;

const tableWrapper = styled(Box)`
  // padding: 0 3%;
  position: relative;
  .spireonNewOnly,
  .spireonOldOnly {
    display: none;
  }
`;

const Content = styled(Box)`
  // overflow: hidden;
  .logwrapper {
  }
`;

const InfoContainer = styled(Box)`
  ${({ theme }) => `

  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 2%;
  @media (max-width: ${theme.breakpoints.values.md}px) {
    justify-content: flex-start;
  }
  .seperator {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
    flex: 0 0 20%;
    justify-content: flex-end;
    p:first-child {
      padding-top: 6px;
      font-size: 20px;
    }
  }
   .seperator:first-child {
    padding-top: 6px;
   }
  p {
    padding-left: 10px;
    text-transform: capitalize;
    color: ${
      (theme.palette.primary || {}).contrastText ||
      theme.colors.PRIMARY_CONTRAST
    };
    :last-child {
      color: ${
        (theme.palette.secondary || {}).main || theme.colors.SECONDARY_MAIN
      };
      font-size: 25px;
    }
  }
  `}
`;

const Styled = { Header, ToolsContainer, tableWrapper, Content, InfoContainer };

export default Styled;
