import styled from 'styled-components'

const Wrapper = styled.div `
.MuiStepLabel-labelContainer .MuiTypography-root{
    color: #fff;
}
.MuiStepper-root {
    background-color: #20263a;
    width: 20%;
}
.contentWrapper {
    background-color: #181c2d;
    width: 100%;

}
`;

const Styled = {
    Wrapper
  };
  export default Styled;