import React, { useState, lazy, useEffect } from 'react';
import PropTypes from 'prop-types';
import { findWhere } from 'underscore';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';

import { getImagePath, hasValue, filterList, getQueryStringKeys, isdvirOnly, hasRestrictedRole, isInsuracePortalUser } from '@/utils/utils';
import { imageUrls, pageUrls, ROLES } from '@/config/constants/keys';
import { useTheme } from '@material-ui/core/styles';
import { Link as LinkLi } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TopActions from './TopActions';

const layout = window?.$environment?.CURRENT_LAYOUT;
const CloseButton = lazy(() => import(`@/components/CloseButton/${layout}`));

const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Modal = require(`@/components/UI/Modal/${layout}`).default;

const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Component {...contentProps} filters={filters} />
    </Box>
  );
};
export default Index;

const Component = ({
  history,
  users,
  apiStatus,
  fetchUsers,
  handleDeleteAlert,
  handleResetPassword,
  callback,
  handleRestoreAlert,
  filterSuccess,
  handleExportUser,
  handleImportUser,
  Styled,
  i18n,
  logBook,
  topAction,
  filterButtons,
  setButtons,
  pagination,
  changeRequest,
  request,
  setFilterData,
  setPagination,
  filters,
  setremoveData,
  removeData,
  viewOnly,
  viewExportOptions,
  showFilters,
  showAllUsers,
  isMechanicUser,
  newUI
}) => {
  const beBaseUrl = window?.$environment?.BE_BASE_URL;
  const userRole = getQueryStringKeys(history.location.search, 'role');
  const [initialFlag, setInitialFlag] = React.useState(false)
  const isDvirOnlyFlagEnabled = isdvirOnly();
  const insuracePortalUserRole = isInsuracePortalUser()
  useEffect(() => {
    if (!initialFlag && users.filteredList) {
      if (userRole) {
        const arrayList = [...users.list];
        let a = filterList(arrayList, 'role', userRole);
        setTableData(a);
        if (users.filteredList.length) setInitialFlag(true)
      }
      else {
        setTableData(users.filteredList);
      }
    } else if (users.filteredList && initialFlag) {
      setTableData(users.filteredList);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users.filteredList]);
  const theme = useTheme();
  const [isDocClicked, setisDocClicked] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showCompany, setShowCompany] = useState((userRole && userRole == 'driver') || !userRole ? true : false);
  const [selectedDocArray, setSelectedDocArray] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const [open, setOpen] = useState(false);

  const closeModal = (event) => {
    setOpen(false);
  };
  const clickedModal = (e) => {
    setOpen(true);
  };
  const [ModalListObject, setModalListObject] = useState({
    header: '',
    content: () => { }
  });

  const columns = [
    ...(insuracePortalUserRole && showCompany ? [{
      id: 'companyName',
      numeric: false,
      disablePadding: true,
      label: i18n.t('policyHolder'),
      needSorting: true
    }] : []),
    {
      id: 'full_name',
      numeric: false,
      disablePadding: true,
      label: i18n.t('vehicles.fullName'),
      needSorting: true
    },
    ...(showAllUsers
      ? [
        {
          id: 'role',
          numeric: false,
          disablePadding: false,
          label: i18n.t('vehicles.role'),
          needSorting: false
        }
      ]
      : []),
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: i18n.t('vehicles.email'),
      needSorting: true
    },
    ...(!isMechanicUser && !insuracePortalUserRole && window.$environment.SERVICE_PROVIDER !== 'webfleet'
      ? [
        {
          id: 'empId',
          numeric: false,
          disablePadding: false,
          label: i18n.t('vehicles.empId'),
          needSorting: true
        }
      ]
      : []),
    {
      id: 'phone',
      numeric: false,
      disablePadding: false,
      label: i18n.t('common.phone'),
      needSorting: false
    },
    ...(!insuracePortalUserRole
      ? [
        {
          id: 'assigned_vehicles',
          numeric: false,
          disablePadding: false,
          label: i18n.t('vehicles.assignedVehicles'),
          needSorting: false
        }] : []),
    {
      id: 'last_login',
      numeric: false,
      disablePadding: false,
      label: i18n.t('vehicles.lastLogin'),
      needSorting: false
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: i18n.t('Status'),
      needSorting: true
    },
    ...(!insuracePortalUserRole
      ? [
        {
          id: 'eld',
          numeric: false,
          disablePadding: false,
          label: i18n.t('ELD'),
          needSorting: false
        }] : []),
    {
      id: 'actions',
      label: i18n.t('Actions')
    }
  ];
  if (isDvirOnlyFlagEnabled) {
    columns.splice(-2, 1);
    if (hasRestrictedRole([ROLES.SERVICE_MANAGER])) {
      columns.splice(5, 1)
    }
  }
  const [tableData, setTableData] = React.useState(users.filteredList);

  const getELDStatusIcon = (data) => {
    if (data === 'NOT_STARTED' || data === 'TAMPERED' || data === 'DISCONNECTED') {
      return "eld-inActive";
    } else if (data === 'CONNECTED') {
      return "eld-active";
    } else if (data === '') {
      return "eld-loggedOut"
    } else {
      return ""
    }
  }
  const generateRows = () => {
    const updatedList = tableData.map((item, index) => {
      item.eld = <div className={getELDStatusIcon(item.eldState)} />;
      return item;
    });
    return updatedList;
  };
  const notIconClicked = (e, type, index) => {
    const selected = findWhere(users.filteredList, { id: e.id });
    setSelectedDocArray(selected.documents.docList);
    setSelectedType(type);
    setisDocClicked(true);
    // setSelectedIndexfn(index)
  };
  const setSelectedIndexfn = (index) => {
    let addValue =
      window.$environment.SERVICE_PROVIDER === 'clutch' ? 125 :
        window.$environment.CURRENT_LAYOUT === 'NewUi' ? 110 : 75;
    let mulVal = window.$environment.SERVICE_PROVIDER === 'clutch' ? 45 :
      window.$environment.CURRENT_LAYOUT === 'NewUi' ? 39 : 38;
    let value = mulVal * index;
    let changeValue = addValue + value;
    setSelectedIndex(changeValue);
  };
  const hasUserListApiStatus = hasValue(apiStatus.status, 'api', fetchUsers);
  const information =
    hasUserListApiStatus && hasUserListApiStatus.errorMessage ? (
      <Message
        type="error"
        message={i18n.t(hasUserListApiStatus.errorMessage)}
      />
    ) : (
      i18n.t('common.tableEmptyMessage')
    );
  const userFilter = (value, index) => {
    if (value == 'All' || value == 'Driver') setShowCompany(true)
    else setShowCompany(false)
    setisDocClicked(false);
    setButtons(
      filterButtons.length &&
      filterButtons.map((key, i) => {
        if (i === index) return { ...key, selected: true };
        else return { ...key, selected: false };
      })
    );
    setPagination(true);
    if (insuracePortalUserRole) {
      if (value === 'All') {
        filterSuccess(users.list);
      } else {
        const arrayList = [...users.list];
        let a = filterList(arrayList, 'role', value);
        // filterSuccess(a);
        setTableData(a);
      }
    } else setFilterData(value)
    setremoveData('clear')
  };

  const clickedRow = (row, id) => {
    setSelectedIndexfn(row);
  };
  return (
    <>
      <Styled.Wrapper>
        {topAction && (
          <TopActions
            {...{
              history,
              users,
              callback,
              filterSuccess,
              handleExportUser,
              handleImportUser,
              Styled,
              i18n,
              logBook,
              setPagination,
              filterButtons,
              setButtons,
              filters,
              setremoveData,
              removeData,
              viewExportOptions,
              isMechanicUser,
              newUI
            }}
          />
        )}
        {showFilters &&
          (<Styled.ButtonsWrapper>
            <div className="userBtnWrapper" id={'userBtnWrapper'}>
              <span className="quickfilter">{i18n.t('users.quickFilter')}</span>
              {filterButtons.length &&
                filterButtons.map((items, index) => {
                  return items.show ? (
                    <Button
                      label={items.label}
                      className={`userFilterButton ${items.selected ? 'active' : ''
                        }`}
                      onClick={() => userFilter(items.type, index)}
                    ></Button>
                  ) : null;
                })}
            </div>
          </Styled.ButtonsWrapper>)
        }
        <Styled.TableWrapper>
          <Table
            type={insuracePortalUserRole ? "client" : "server"}
            defaultSortBy={{ sortBy: 'full_name', order: 'asc' }}
            title=""
            baseRequest={request}
            totalRecords={users.count}
            changeRequest={changeRequest}
            columns={[...columns]}
            data={generateRows()}
            emptyMessage={information}
            itemsPerPage={10}
            actions={[
              {
                icon: (row, index) => {
                  return (
                    <LinkLi
                      component={Link}
                      to={viewOnly ? pageUrls.viewUser.replace(':id', row.id) : isMechanicUser ? pageUrls.editMechanic.replace(':id', row.id) : pageUrls.editUser.replace(':id', row.id)}
                    >
                      {viewOnly ?
                        (<VisibilityIcon className="historyIcon" />) :
                        (<Icon
                          src={getImagePath(imageUrls.edit)}
                          tooltip={i18n.t('common.edit')}
                        />)}
                    </LinkLi>
                  );
                }
              },
              ...(hasRestrictedRole(['FLMANAGER']) ? [
                {
                  icon: (row, index) => {
                    return (
                      <Icon
                        onClick={() => {
                          handleResetPassword({
                            id: 'resetPassword',
                            data: { id: row.id },
                            callback: callback
                          })
                        }}
                        src={getImagePath(imageUrls.updateIcon)}
                        tooltip={i18n.t('common.resetPassword')}
                      />
                    );
                  }
                }
              ] : []),
              ...(!viewOnly && window.$environment.SERVICE_PROVIDER !== 'webfleet' ? [{
                icon: (row, index) => {
                  return (row.role == 'Driver' && window.$environment.SERVICE_PROVIDER == 'riskTheory' && hasRestrictedRole(['FLMANAGER'])) || (row.role.includes('Default') && !hasRestrictedRole(['OWNER'])) ? null : (
                    <Icon
                      src={
                        row.status === 'Active'
                          ? getImagePath(imageUrls.delete)
                          : getImagePath(imageUrls.undelete)
                      }
                      tooltip={
                        row.status === 'Active' ? 'Deactivate' : 'Activate'
                      }
                      onClick={() => {
                        setModalListObject({
                          content: () =>
                            row.status === 'Active' ? (
                              <div className="deleteParent">
                                <div className="imageSection">
                                  <img
                                    src={getImagePath(imageUrls.confirmIcon)}
                                    alt="Are you sure"
                                  />
                                </div>
                                <h2> {i18n.t('common.sureConfirm')} </h2>
                                <p>{i18n.t('common.popupMessage')}</p>
                                <div className="deletePopupBtn">
                                  <div className="cancelBtn">
                                    <Button
                                      label={i18n.t('Cancel')}
                                      type={'reset'}
                                      onClick={() => {
                                        closeModal();
                                      }}
                                    ></Button>
                                  </div>
                                  <div className="deletebtn">
                                    <Button
                                      label={i18n.t('vehicles.deactivateUser')}
                                      onClick={() => {
                                        handleDeleteAlert({
                                          id: 'deleteAlert',
                                          data: { id: row.id },
                                          callback: callback,
                                          filters: isMechanicUser ? { userRole: 'MECHANIC' } : null
                                        });
                                        closeModal();
                                      }}
                                    ></Button>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="deleteParent">
                                <div className="imageSection">
                                  <img
                                    src={getImagePath(imageUrls.confirmIcon)}
                                    alt="Are you sure"
                                  />
                                </div>
                                <h2> {i18n.t('common.sureConfirm')} </h2>
                                <p>{i18n.t('common.activateMessage')} </p>
                                <div className="deletePopupBtn">
                                  <div className="cancelBtn">
                                    <Button
                                      label={i18n.t('Cancel')}
                                      type={'reset'}
                                      onClick={() => {
                                        closeModal();
                                      }}
                                    ></Button>
                                  </div>
                                  <div className="deletebtn">
                                    <Button
                                      label={i18n.t('vehicles.activateIt')}
                                      onClick={() => {
                                        handleRestoreAlert({
                                          id: 'restoreAlert',
                                          data: { id: row.id },
                                          callback: callback
                                        });
                                        closeModal();
                                      }}
                                    ></Button>
                                  </div>
                                </div>
                              </div>
                            )
                        });
                        clickedModal();
                      }}
                    />
                  );
                }
              }] : []),
            ]}
            clickedRow={(row, id) => clickedRow(id)}
            pageinationReset={pagination}
            pagenationClicked={(e) => {
              setPagination(!e);
              setisDocClicked(false);
            }}
            i18n={i18n}
            newUI={window.$environment.SERVICE_PROVIDER === 'webfleet'}
          />

          {isDocClicked && (
            <Styled.DocContainer
              selectedindex={selectedIndex}
              selectedType={selectedType}
            >
              <CloseButton
                className="ToolsOptionClose"
                icon={imageUrls.popupClose}
                onClick={() => {
                  setisDocClicked(false);
                }}
              />
              <div className="documentWrapper">
                {selectedDocArray.map((item, key) => {
                  return (
                    <ul>
                      <li>
                        <a
                          variant="body2"
                          href={
                            beBaseUrl +
                            item.url.substring(item.url.indexOf('/')) +
                            '?token=' +
                            logBook?.documentToken
                          }
                          target="_blank"
                          style={{
                            color:
                              item.valid === 'true'
                                ? theme.colors.GREEN
                                : theme.colors.RED_TWO,
                            pointerEvents:
                              item.valid === 'true' ? 'default' : 'none'
                          }}
                        >
                          {item.name}
                        </a>
                      </li>
                    </ul>
                  );
                })}
              </div>
            </Styled.DocContainer>
          )}
        </Styled.TableWrapper>
      </Styled.Wrapper>
      <Modal open={open} setOpen={setOpen} data={ModalListObject} deletePopup />
    </>
  );
};

Component.propTypes = {
  history: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  handleDeleteAlert: PropTypes.func.isRequired,
  handleResetPassword: PropTypes.func.isRequired,
  handleRestoreAlert: PropTypes.func.isRequired,
  filterSuccess: PropTypes.func.isRequired,
  handleExportUser: PropTypes.func.isRequired,
  handleImportUser: PropTypes.func.isRequired,
  handleExportUserTemplate: PropTypes.func.isRequired
};
