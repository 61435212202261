import styled from 'styled-components';

export const SelectWrapper = styled.div`
  ${({ theme, isMulti }) => `
  .css-yk16xz-control,
  .select__control--menu-is-open,
  .select__menu {
    background: ${theme.colors.BACKGROUND};
    border: 2px solid ${theme.colors.LIGHT_BORDER};
    border-radius: 0px;
    z-index: 1000;
  }
  .select__control {
    color: ${theme.colors.PRIMARY_CONTRAST} !important;
    box-shadow: none;
  }
  .select--is-disabled {
    opacity: 0.5;
  }
  .select__control--menu-is-open .select__dropdown-indicator svg {
    transform: rotate(180deg);
  }
  .select__input input {
    color: ${theme.colors.PRIMARY_CONTRAST} !important;
    font-family: ${(theme.palette || {}).fontFamily};
  }
  .css-1rhbuit-multiValue {
    border: 1px solid #817777;
    background: none;
    color: ${theme.colors.WHITE};
  }
  .css-12jo7m5 {
    color: ${theme.colors.WHITE};
  }
  .css-yk16xz-control:hover {
    border-color: #817777;
  }
  .react-select__option :focus,
  .react-select__menu-list:focus {
    background-color: ${
      theme.palette.secondary
        ? theme.palette.secondary.main
        : theme.colors.SECONDARY_MAIN
    };
  }
  .select__menu {
    color: ${theme.colors.WHITE};
  }
  .select__indicator-separator {
    display: none;
  }
  .basic-multi-select:active {
    border: none;
  }

  .css-8r3dx3-control,
  .select__control--is-focused {
    min-height: 45px !important;
    overflow-y: ${isMulti? 'hidden !important' : 'auto !important;'};
    max-height: 200px;
  }
  .select__indicators{
    align-items: flex-start;
    padding-top: 3px;
  }
  .select__control--is-focused .select__dropdown-indicator,
  .select__control--is-focused .select__clear-indicator {
    color: ${theme.colors.WHITE};
  }

  .select__menu {
    font: 16px Yanone Kaffeesatz;
  }
  .select__menu::-webkit-scrollbar {
    width: 0 !important;
  }
  .select__option--is-disabled {
    pointer-events: none;
    opacity: 0.5;
    :hover{
      background: ${theme.colors.BACKGROUND};
    }
  }
  `}
`;

const Styled = { SelectWrapper };
export default Styled;