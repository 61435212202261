import styled from 'styled-components';
import LogoContainer from './style';

export default styled(LogoContainer)`
  ${({ theme, isWeblogin, imgWidth }) => `
left: ${isWeblogin ? '0' : ''};
width: 20%;
text-align: center;
img {
    width: 170px;
    top: 3px;
    height: auto;
    left: 4%;
    z-index: 1;
}
`}
`;
