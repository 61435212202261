import PropTypes from 'prop-types';
import React, { lazy } from 'react';
import { object, string } from 'yup';

import { hasValue } from '../../../utils/utils';

const Form = lazy(() =>
  import(`../../../components/UI/Form/${window.$environment?.CURRENT_LAYOUT}`)
);

const LoginForm = ({
  apiStatus,
  fetchLogin,
  history,
  Styled,
  i18n,
  hasRegistration,
  innerComponent,
  newUI,
  redirectedFromFleetops,
  redirectedUrl: redirectedUrlsValue
}) => {
  const apiId = 'loginform';
  //Check whether the api is calling
  const hasLoginApiStatus = hasValue(apiStatus.status, 'api', apiId);
  const RenderComponent = lazy(() => import(`@/${innerComponent}`));
  return (
    <Styled.LoginWrapper newUI={newUI}>
      <div className="formLogin">
        <Form
          type="server"
          direction="column"
          initialValues={{ username: '', password: '', rememberMe: false }}
          RenderComponent={RenderComponent}
          handleOnSubmit={(fields) => {
            fetchLogin({
              data: fields,
              apiId,
              callback: (out) => {
                if (out == 'mfaEnabled') {
                  history.push('/user/mfa')
                } else {
                  // if(window.$environment.SERVICE_PROVIDER === 'tp' && window?.zE){
                  // window.zE('webWidget', 'chat:end');
                  // window.zE('webWidget', 'logout');
                  // }
                  let redirectUrl = window.$environment.SERVICE_PROVIDER == 'eldx' ? window.$environment.RESELLER : '/'
                  if (redirectedFromFleetops) {
                    let redirectionURL = new URL(redirectedFromFleetops)
                    redirectionURL.searchParams.set('token', out.token)
                    redirectionURL.searchParams.set('partnerId', window.$environment?.DOMAIN)
                    redirectUrl = redirectionURL.toString();
                  } else if (redirectedUrlsValue) {
                    redirectUrl = redirectedUrlsValue;
                  }
                  window.location.href = redirectUrl;
                  //history.push('/')
                }

              }
            });
          }}
          customProps={{
            hasLoginApiStatus,
            history,
            Styled,
            i18n,
            hasRegistration: hasRegistration
          }}
          validationSchema={object().shape({
            username: string().required(i18n.t('common.fieldRequiredMessage')),
            password: string().required(i18n.t('common.fieldRequiredMessage'))
          })}
        />
      </div>
    </Styled.LoginWrapper>
  );
};
export default LoginForm;

LoginForm.defaultType = {
  hasRegistration: true
};
LoginForm.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  fetchLogin: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  Styled: PropTypes.object.isRequired,
  i18n: PropTypes.array.isRequired,
  hasRegistration: PropTypes.bool
};
