import React, { useState, useEffect, lazy } from 'react';
import PropTypes from 'prop-types';
import ParentStyled from './style';
import { loadStyle } from '@/utils/utils';
import { hasValue, getImagePath } from '@/utils/utils';
import { imageUrls } from '@/config/constants/keys';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { useTranslation } from 'react-i18next';

const layout = window?.$environment?.CURRENT_LAYOUT;

const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const SubHeader = lazy(() => import(`@/components/SubHeader/${layout}`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const Modal = require(`@/components/UI/Modal/${layout}`).default;

let Styled = loadStyle(ParentStyled, 'components/modules/WorkOrdersManagement');

const DefectList = ({
  defects,
  getDocumentToken,
  setOpen,
  setModalObject
}) => {
  const beBaseUrl = window?.$environment?.BE_BASE_URL;
  const [pagination, setPagination] = useState(false);
  const { i18n } = useTranslation();

  const columns = [
    {
      id: 'categoryType',
      numeric: false,
      disablePadding: true,
      label: i18n.t('defectDetails.area'),
      needSorting: true
    },
    {
      id: 'sectionName',
      numeric: false,
      disablePadding: false,
      label: i18n.t('defectDetails.part'),
      needSorting: true
    },
    {
      id: 'info',
      numeric: false,
      disablePadding: false,
      label: i18n.t('defectDetails.defectDetails'),
      needSorting: true,
      width: '20%'
    },
    {
      id: 'mechanicComments',
      numeric: false,
      disablePadding: false,
      label: i18n.t('defectDetails.commentsByMechanic'),
      needSorting: true,
      width: '20%'

    },
    {
      id: 'mechanicFixStatus',
      numeric: false,
      disablePadding: true,
      label: i18n.t('defectDetails.fixStatus'),
      needSorting: true
    },
    {
      id: 'driverComments',
      numeric: false,
      disablePadding: false,
      label: i18n.t('defectDetails.comments'),
      needSorting: true,
      width: '20%'
    },
    {
      id: 'driverApproved',
      numeric: false,
      disablePadding: true,
      label: i18n.t('defectDetails.status'),
      needSorting: true
    },
    {
      id: 'viewPhoto',
      numeric: false,
      disablePadding: false,
      label: i18n.t('defectDetails.viewPhoto'),
      needSorting: false
    },
  ];

  const viewPictureCallback = (type, data, currentItem) => {
    currentItem &&
      setModalObject({
        header: '',
        content: () => {
          return (
            <Carousel
              showIndicators={false}
              showThumbs={false}
              showStatus={false}
            >
              {currentItem.inspectionFiles.map((photo) => {
                return (
                  <div style={{ minHeight: "100px" }}>
                    <img
                      src={`${beBaseUrl}/document/open/${photo.id}?token=${data}`}
                    />
                    <p className="legend">
                      {`${photo?.documentType?.fullName}`}
                    </p>
                  </div>
                );
              })}
            </Carousel>
          );
        }
      });
    setOpen(true);
  };
  const viewPicture = (item) => {
    getDocumentToken({
      apiId: 'getDocToken',
      callback: viewPictureCallback,
      item: item
    });
  };

  const generateRows = () => {
    const updatedList = defects.map((item) => {
      let result = { ...item };
      result.mechanicFixStatus = item?.mechanicFixStatus ? 'Fixed' : 'Not Fixed';
      result.driverApproved = item?.driverApproved === null || item?.driverApproved === undefined ? 'Pending' : item.driverApproved ? 'Approved' : 'Rejected';
      item.viewPhoto = item?.inspectionFiles?.length ? (
        <Icon
          src={getImagePath(imageUrls.full)}
          tableicon
          onClick={() => {
            viewPicture(item);
          }}
          tooltip={i18n.t('workOrders.viewPhotos')}
        ></Icon>
      ) : (
        <div className="noPointer"><Icon src={getImagePath(imageUrls.notFull)} tableicon></Icon></div>
      );
      return result;
    });
    return updatedList;
  };

  const information =
    i18n.t('common.tableEmptyMessage')


  return (
    <>
      <Styled.TableWrapper className="defectList">
        <SubHeader
          className="subheaderSection statusHeader userHeading"
          text={i18n.t('workOrders.defectDetails')}
          variant="h2"
          mode="light"
        />
        <Table
          type="client"
          defaultSortBy={{ sortBy: 'unit_id', order: 'asc' }}
          title=""
          columns={[...columns]}
          data={generateRows()}
          emptyMessage={information}
          itemsPerPage={5}
          actions={[]}
          defaultSort={'categoryType'}
          i18n={i18n}
          pageinationReset={pagination}
          pagenationClicked={(e) => {
            setPagination(!e);
          }}
        />
      </Styled.TableWrapper>
    </>
  );
};

DefectList.propTypes = {
  callback: PropTypes.func.isRequired,
  defect: PropTypes.object.isRequired,
};

export default DefectList