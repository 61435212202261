import panorama from '@/assets/panorama';
import React from 'react';
import videojs from 'video.js';
import 'videojs-panorama/dist/videojs-panorama.css';

import 'video.js/dist/video-js.css';

const CAMERA_TYPE_2 = "Waylens Air II";

export const VideoJS = (props) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const { options, onReady } = props;

  React.useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;

      if (!videoElement) return;

      const player = playerRef.current = videojs(videoElement, options, () => {
        videojs.log('player is ready');
      });
      if (options?.cameraType !== CAMERA_TYPE_2) {
        panorama(player, {
          PanoramaThumbnail: true,
          maxLat: 90,
          minLat: 16,
          initLat: 15.5,
          initLon: 90,
          initFov: 70,
          rotateZ: 0,
          rotateX: 0,
          clickAndDrag: true,
          scrollable: false,

          // 360 options
          VREnable: true, // enanble the splitView toggle button
          isUpsidedown: false, // set inital value of upsidedown*
          upsidedownToggle: true, // enanble the upsidedown toggle button
          isVR: true, // set inital value of splitView*
          // *current value wiil store in localStorage

          videoType: 'fisheye',
          helperCanvas: {
            width: 300,
            height: 300,
            
          },
        })
      }

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    }
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <video crossorigin="anonymous" ref={videoRef} className='video-js vjs-default-skin vjs-static-controls vjs-fluid myplayer-dimensions vjs-controls-enabled vjs-workinghover vjs-v7 vjs-panorama vjs-playing vjs-has-started vjs-live vjs-waiting vjs-user-inactive' />
    </div>
  );
}

export default VideoJS;