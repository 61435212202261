import Box from '@material-ui/core/Box';
import styled from 'styled-components';

const Wrapper = styled(Box)`
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  flex-direction: column;
  .text-green {
    color: limegreen !important;
    font-weight: bolder;
  }
    .text-yellow {
    color: yellow !important;
    font-weight: bolder;
  }
    .text-orange {
    color: orange !important;
    font-weight: bolder;
  }
    .text-red {
    color: red !important;
    font-weight: bolder;
  }
  .renewalsFilter {
    margin-top: 15px;
    .normalCheckbox{
      display: flex;
      align-items: flex-end;
      label {
        margin-bottom: -2px;
      }
    }
  }
`;
const TopWrapper = styled(Box)`
  ${({ theme }) => `
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
align-items: center;
  @media (max-width: ${theme.breakpoints.values.smd}px) {
    flex-direction: column;
  }
  `}
`;
const IconWrapper = styled.div`
  margin-left: 0;
  align-self: flex-end;
  .MuiAvatar-root {
    width: 34px !important;
    height: auto !important;
  }
  .userOld{
    display: none;
  }
`;

const ImpExpWrapper = styled.div`
  ${({ theme }) => `
  flex: 0 0 57%;
  @media (max-width: ${theme.breakpoints.values.mlg}px) {
    flex: 0 0 55%;
  }
  @media (max-width: ${theme.breakpoints.values.md}px) {
    flex: 0 0 50%;
  }
  @media (max-width: ${theme.breakpoints.values.smd}px) {
    justify-content: flex-start;
    padding-top: 14px;
  }
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
  justify-content: flex-end;
  .MuiAvatar-root {
    width: 35px;
    height: auto !important;
  }
  .exportContainer .MuiAvatar-root,
  .importUserTemplateContainer  .MuiAvatar-root {
    width: 35px !important;
    height: auto !important;
  }
  .importUserTemplateContainer {
    margin-right: 10px;
    .tooltiptext {
      right: -90px;
      top: 49px;
    }
  }
  .exportContainer {
    margin-left: 10px;
    .tooltiptext {
      width: 70px;
      right: 0;
      top: 49px;
    }
  }
  .tooltiptext {
    top: 40px;
    font-size: 15px;
    right: -70px;
  }
  .importUserContainer {
    position: relative;
  }
  .userOld {
    display: none;
  }
  `}
`;

const SearchWrapper = styled.div`
  ${({ theme }) => `
  flex: 0 0 40%;
  @media (max-width: ${theme.breakpoints.values.mlg}px) {
    flex: 0 0 38%;
  }
  #clearSearch {
    display: none;
  }
  `}
`;

const ButtonsWrapper = styled(Box)`
  ${({ theme }) => `
  .userBtnWrapper {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
    flex-wrap: wrap;
    .MuiButton-root {
      padding-left: 0;
      padding-right: 0;
    }
  }
  padding-top: 2.5%;
  .MuiButton-root {
    background:${theme.colors.PRIMARY_MAIN}!important;
    border: 2px solid ${theme.colors.PRIMARY_BORDER};
    color: ${theme.colors.PRIMARY_CONTRAST}!important;
    width: 135px;
    font-size: 18px !important;
  }
  .MuiBox-root {
    margin-right: 21px;
    @media (min-width: ${theme.breakpoints.values.xs}px) and (max-width: ${
    theme.breakpoints.values.md
  }px) {
      margin-top: 10px;
    }
  }
  .MuiButton-root:hover {
    background: ${
      (theme.palette.primary || {}).main || theme.colors.PRIMARY_MAIN
    };
  }
  .MuiTouchRipple-root {
    display: none;
  }
  .active {
    color: ${theme.colors.LINK_COLOR} !important;
    border: 2px solid ${theme.colors.LINK_COLOR} !important;
  }
  .quickfilter {
    display: none;
  }
  `}
`;

const DocContainer = styled.div`
  ${({ theme, selectedindex, selectedType }) => `
  position: absolute;
  background: ${
    (theme.palette.primary || {}).main || theme.colors.PRIMARY_MAIN
  };
  border: 2px solid ${theme.colors.PRIMARY_BORDER};
  top: ${selectedindex ? selectedindex + 'px' : '120px'};
  right: 265px;
 
  li {
    list-style: none;
    margin: 0;
  }
  a {
    font-size: 22px;
    text-decoration: none;
    :hover {
      text-decoration: underline !important;
    }
  }
  .documentWrapper{
    padding: 25px;
    padding-bottom: 13px;
  }
  .ToolsOptionClose {
    color: ${
      selectedType === 'full' ? theme.colors.GREEN : theme.colors.RED_TWO
    } !important;
    margin-top: 10px;
    width: 100%;
    background-position: right;
    right: 3px;
    background-size: 14px;
    height: 20px;
    position: absolute;
    right: 15px;
  }

  ul {
    width: 175px;
    min-height: 35px;
    padding-left: 0;
    width: 300px;
    margin-block-start: 0em;
    margin-block-end: 0em;
    li {
        padding-left:0;
    }
  }
  cursor: pointer;
  `}
`;
const TableWrapper = styled(Box)`
.MuiPaper-rounded .MuiBox-root:first-child {
  overflow-x: inherit !important;
}
.severity-wrapper {
  width: 150px;
  height: 10px;
  display: flex;
  .addBorder {
    border-right: 0.1px solid black;
  }
  .leftCurve{
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }
  .rightCurve {
    border-bottom-right-radius: 5px;
    border-right: none !important;
    border-top-right-radius: 5px;
  }
  .green-wrapper{
    background: #00ff00;
    height: 100%;        
  }
  .yellow-wrapper {
    background: #ffff00;
    height: 100%;
  }
  .red-wrapper{
    height: 100%;
    background: #ff0000;
  }
}
position:relative;
  padding-top: 2.5%;
  table {
    .MuiTableCell-head {
      padding-right 0 !important;
    }
    .MuiTableBody-root .MuiTableCell-root:nth-child(7) .MuiGrid-container {
      justify-content: center;
    }
    .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root:nth-child(7) {
      text-align: center !important;
    }
    overflow-x: scroll;
  }
  .eld-active {
    background: green;
    height: 10px;
    width: 10px;
    border-radius: 5px;
    margin-top: 5px;
  }
  .eld-inActive {
    background: red;
    height: 10px;
    width: 10px;
    border-radius: 5px;
    margin-top: 5px;
  }
  .eld-loggedOut {
    background: #716F6E;
    height: 10px;
    width: 10px;
    border-radius: 5px;
    margin-top: 5px;
  }

`;
const Styled = {
  Wrapper,
  IconWrapper,
  ImpExpWrapper,
  SearchWrapper,
  TopWrapper,
  ButtonsWrapper,
  TableWrapper,
  DocContainer
};
export default Styled;
