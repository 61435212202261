import styled from 'styled-components';
import Parent, { FormStyle as ParentStyle } from '../Base/serverStyle';
const TitleWrapper = styled(ParentStyle.TitleWrapper)``;
const ButtonsWrapper = styled(ParentStyle.ButtonsWrapper)``;
const FieldsWrapper = styled(ParentStyle.FieldsWrapper)`
div[type="error"] p {
  font-size: 14px !important;
  font-family: tahoma;
}
`;
const TextWrapper = styled(ParentStyle.TextWrapper)``;
const TextareaWrapper = styled(ParentStyle.TextareaWrapper)``;
const CheckBoxWrapper = styled(ParentStyle.CheckBoxWrapper)``;
const FormWrapper = styled(Parent.FormWrapper)`
.MuiFormLabel-root{
  font-family tahoma;
}
.react-datepicker-wrapper {
  min-height: auto;
  height: auto;
  .react-datepicker__input-container{
    height: auto;
  }
  input {
    border: 1px solid #c8c8b5 !important;
    font-size: 12px !important;
    outline: none;
    height: 20px;
  }
}
.react-datepicker__day{
  line-height: 1.6em;
  outline: none;
}

.react-datepicker__day--highlighted{
  background: #ffffff !important;
  border-radius: 0 !important;
  border: 2px solid #d3d3d3 !important;
  border-bottom: 2px solid #f7b946 !important;
  color: #555555;
}
.react-datepicker__close-icon{
  top: 3px;
}
.react-datepicker__navigation--next{
  border-left-color: #000;
}
.react-datepicker__day--disabled {
  pointer-events: none;
}
li.react-datepicker__time-list-item {
  font: 12px/1.428 Arial, "Geneva CY", sans-serif;
}
.react-datepicker__month-select,
.react-datepicker__year-select,
.react-datepicker__current-month {
  background: #cccccc !important;
}
.react-datepicker__navigation--previous {
  border-right-color: #000;
}
.react-datepicker__navigation--next--disabled,
.react-datepicker__navigation--next--disabled:hover {
  border-left-color: #222;
}
.react-datepicker__day--disabled{
  background: #e6e6e6 !important;
  border: 1px solid #d3d3d3 !important;
  color: #555555;
}

`;
const FormStyle = {
  TitleWrapper,
  ButtonsWrapper,
  FieldsWrapper,
  TextWrapper,
  TextareaWrapper,
  CheckBoxWrapper
};

export const Styled = {
  FormWrapper
};
export default FormStyle;
