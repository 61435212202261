import {
  vehiclePageMapApi,
  vehiclePageHistoryMapApi,
  driverStatusTableHistoryMapApi
} from '../../../config/apis';

const initialState = {
  data: [],
  type: ''
};

export const vehiclePageMap = {
  state: { ...initialState },
  reducers: {
    listSuccess(state, payload, type) {
      return { ...state, data: [...payload], type: type };
    },
    listFailed() {
      return { ...initialState };
    }
  },
  effects: (dispatch) => ({
    async fetchVehiclePageMap(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: vehiclePageMapApi
          },
          id: payload.apiId,
          callback: payload.callback
        });
        response && this.listSuccess(response, 'vehicleMap');
      } catch (error) {
        this.listFailed();
      }
    },
    async fetchVehiclePageHistoryMap(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            data: { ...payload.data },
            api: vehiclePageHistoryMapApi
          },
          id: payload.apiId,
          callback: payload.callback
        });
        response && this.listSuccess(response, 'vehicleHistoryMap');
      } catch (error) {
        this.listFailed();
      }
    },
    async fetchDriverStatusTableHistoryMap(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            data: { ...payload.data },
            api: driverStatusTableHistoryMapApi
          },
          id: payload.apiId,
          callback: payload.callback
        });
        response && this.listSuccess(response, 'vehicleHistoryMap');
      } catch (error) {
        this.listFailed();
      }
    }
  })
};
