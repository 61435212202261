import styled from 'styled-components';
import { getImagePath, getStyle } from '../../../../utils/utils';
import { imageUrls } from '../../../../config/constants/keys';

const ImportWrapper = styled.div`
  ${({ theme, userImport, importVehicle }) => `
  cursor:pointer;
  .csv-reader-input {
    background:
      url(${
        userImport
          ? getImagePath(imageUrls.importUser)
          : getImagePath(imageUrls.import)
      });
    height: 35px;
    width: 35px;
    label {
      visibility: hidden;
      background: ${getStyle(
        '',
        (theme.palette.primary || {}).main,
        theme.colors.PRIMARY_MAIN
      )};
      color: ${theme.colors.WHITE};
      margin-top: 40px;
      position: absolute;
      padding-left: 7px;
      border-radius: 0px;
      border: solid 1px ${theme.colors.WHITE};
      width: 70px;
      font-size: 15px;
    }
    :hover {
      label {
        visibility: visible;
      }
    }
    .tooltiptext {
      visibility: hidden;
      width: 70px;
      background: ${getStyle(
        '',
        (theme.palette.primary || {}).main,
        theme.colors.PRIMARY_MAIN
      )};
      color: ${theme.colors.WHITE};
      text-align: center;
      padding: 2px 0;
      border-radius: 0px;
      border: solid 1px ${theme.colors.WHITE};
      position: absolute;
      z-index: 1;
      right: -50px;
      top: 40px;
    }

    :hover .tooltiptext {
      visibility: visible;
    }
  }
  input {
    -webkit-appearance: none;
    opacity: 0;
    width: 100%;
    height: ${importVehicle ? '25px' : '35px'};
    color: transparent;
  }

  .importVehicleCsvReaderInput {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
    width: 100%;
    color: ${theme.colors.SECONDARY_MAIN};
    border-radius: 0px;
    input {
      cursor: pointer;
    }
    label {
      position: absolute;
      pointer-events: none;
      font-size: 18px;
    }
    :hover {
      label {
        text-decoration: underline;
      }
    }
  }
  `}
`;

export default ImportWrapper;
