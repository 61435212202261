import React, { useEffect, useState } from 'react';
import {
  COOKIE_AUTH_DETAILS,
  pageUrls
} from '../../../../../config/constants/keys';
import { getCookie, setCookie } from '../../../../../services/cookie';

import Styled from './style';
import { useTranslation } from 'react-i18next';
import { hasRestrictedRole } from '@/utils/utils';
const Unhandled = ({ history, message, pageTitle, callback, apiStatus }) => {
  const [showDash, goToDashboard] = useState(false);
  useEffect(() => {
    const token = getCookie(COOKIE_AUTH_DETAILS);
    if (token) {
      if (token.onboardCompleted) {
        goToDashboard(true);
      } else {
        goToDashboard(false);
      }
    }
  }, []);
  const { i18n } = useTranslation();
  return (
    <>
      <Styled.Wrapper>
        <h1>{i18n.t('apology')}...</h1>
        <p>{i18n.t('page moved')}</p>

        {showDash ? (
          <p>
            {i18n.t('Click')}
            {''}
            <a
              href={
                hasRestrictedRole(['DRIVER']) ? pageUrls.profile : '/dashboard'
              }
              className="here"
            >
              {i18n.t('here')}
            </a>
            {''}{' '}
            {hasRestrictedRole(['DRIVER'])
              ? i18n.t('to go back to user profile')
              : i18n.t('to go back to dashboard')}
          </p>
        ) : (
          <p>
            {i18n.t('Click')}
            {''}
            <a href="/welcome" className="here">
              {i18n.t('here')}
            </a>
            {''} {i18n.t('to go back to onboarding')}
          </p>
        )}

        {message}
      </Styled.Wrapper>
    </>
  );
};

export default Unhandled;
