import React, { lazy, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Field } from 'formik';
import { hasRestrictedRole, getImagePath } from '@/utils/utils';
import { ROLES } from '@/config/constants/keys';
import { getAuthDetails  } from '../../../utils/utils'
import { imageUrls } from '@/config/constants/keys';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Policy = lazy(() =>
  import(`../../../components/modules/Policy/${layout}`)
);
const SubHeader = lazy(() => import(`../../../components/SubHeader/${layout}`));
const Message = lazy(() => import(`../../../components/UI/Message/${layout}`));
const Button = lazy(() => import(`../../../components/UI/Button/${layout}`));
const Upload = lazy(() => import(`../../../components/FileUpload/${layout}`));
const UploadCustomPolicy = lazy(() => import(`@/components/modules/CustomPolicyUpload`));
const FormStyle = require(`../../../components/UI/Form/${layout}/serverStyle`);
const Modal = require(`@/components/UI/Modal/${layout}`);
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const beBaseUrl = window?.$environment?.BE_BASE_URL;
const userDetails = getAuthDetails()

const SubmitPolicyButton = ({customProps, i18n, checkFields, values, validateForm, submitAction}) => {
  return (
    <FormStyle.default.ButtonsWrapper className="policySubmit">
        <Button
          type="submit"
          id="submitPolicy"
          label={
            customProps?.hasApiStatus?.isLoading ? (
              <CircularProgress color="inherit" />
            ) : (
              i18n.t('common.submit')
            )
          }
          onClick={() => {
            if (checkFields(values)) {
              const validateResponse = validateForm();
              validateResponse.then((data) => {
                submitAction(data);
              });
            }
          }}
        />
    </FormStyle.default.ButtonsWrapper>
  )
}

const RenderComponent = ({
  values,
  handleEdit,
  errors,
  isNotValid,
  validateForm,
  customProps,
  submitAction,
  commonProps,
  Styled
}) => {
  const { logBook, policies, saveCustomPolicy, customPolicy, editCustomPolicy, handleDeleteCustomPolicyAlert, callback, fetchCustomPolicy, downloadCustomPolicy} = customProps;
  const { i18n } = useTranslation();
  const [docLink, setDocLink] = useState()
  const viewOnly = hasRestrictedRole([ROLES.VIEW_ONLY_FM])
  const isSpireon = window.$environment?.SERVICE_PROVIDER === 'spireon';
  const enableCustomPolicy = userDetails?.enterpriseFeatureDto?.customPoliciesEnabled && isSpireon;
  useEffect(() => {
    if (logBook?.documentToken && policies?.fileId)
      setDocLink(
        beBaseUrl +
        '/document/open/' +
        policies?.fileId +
        '?token=' +
        logBook?.documentToken
      )
  }, [logBook, policies])
  const [errFields, setErrFields] = useState({})
  const fetchCustomPolicyApiID = 'fetchCustomPolicyApiID';

  useEffect(() => {
    if(enableCustomPolicy) {
      fetchCustomPolicy({
        apiId: fetchCustomPolicyApiID
      })
    }
  }, [])

  const [ModalObject, setModalObject] = useState({
    header: '',
    content: () => { }
  });
  const [open, setOpen] = useState(false);

  const clickedModal = (e) => {
    setOpen(true);
  };
  const closeModal = (event) => {
    setOpen(false);
  };

  const [withCloseIcon, setwithCloseIcon] = useState(true);
  const [backgroundModal, setbackgroundModal] = useState(false);
  const [currentItem] = useState({});
  const uploadApi = 'customPolicyUploadApi';
  const [pagination, setPagination] = useState(false);
  const languages = [
    {
      label: 'English',
      value: 'en'
    },
    {
      label: 'Spanish',
      value: 'es'
    },
  ]

  const columns = [
    {
      id: 'policy',
      numeric: false,
      disablePadding: true,
      label: i18n.t('common.policy'),
      needSorting: false
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: i18n.t('common.name'),
      needSorting: false
    },
    {
      id: 'language',
      numeric: false,
      disablePadding: true,
      label: i18n.t('common.language'),
      needSorting: false
    },
    {
      id: 'actions'
    }
  ];

  const generateRows = (data) => {
    const updatedList = data?.map((item) => {
      var result = { ...item };
      result.policy = (
        <Icon
          variant="square"
          src={getImagePath(imageUrls.eldIcon)}
          onClick={() =>
            // window.open(item.link, '_blank')
            downloadCustomPolicy({
              apiId: 'downloadCustomPolicyApi',
              data: item?.policyDocId, 
            })
          }
        />
      );
      result.name = (
        <>
          <p>{item.name}</p>
        </>
      );
      result.language = (
        <>
          <p>{(languages.find(el => {
            return el.value === item.language 
          }
          ))?.label}</p>
        </>
      );
      result.languageValue = item.language;
      result.nameValue = item.name
      return result;
    });
    return updatedList || [];
  };

  const checkFields = (formValues) => {
    if(window?.$environment?.SERVICE_PROVIDER != 'webfleet') return true
    else {
      let errorFields = {}
      if (formValues.DRIVER_SELECTION_MVR) {
        if (!formValues.policyParameters.NUMBER_OF_ACCIDENTS_ALLOWED) errorFields.NUMBER_OF_ACCIDENTS_ALLOWED = true
        if (!formValues.policyParameters.NUMBER_OF_CITATIONS_ALLOWED) errorFields.NUMBER_OF_CITATIONS_ALLOWED = true
        if (!formValues.policyParameters.MIN_YEARS_ON_SIMILAR_EQUIPMENT) errorFields.MIN_YEARS_ON_SIMILAR_EQUIPMENT = true
        if (!formValues.policyParameters.MIN_DRIVER_AGE) errorFields.MIN_DRIVER_AGE = true
      }
      if (formValues.DRIVING_TRAINING) {
        if (!formValues.policyParameters.TRAINING_DAYS) errorFields.TRAINING_DAYS = true
        if (!formValues.policyParameters.MIN_HOURS) errorFields.MIN_HOURS = true
      }
      if (formValues.ACCIDENT_INVESTIGATION) {
        if (!formValues.policyParameters.SAFETY_PERSON_FULL_NAME) errorFields.SAFETY_PERSON_FULL_NAME = true
      }
      setErrFields(errorFields)
      if (Object.keys(errorFields) == 0) return true
    }    
  }
  
  return (
    <>
      {customProps.hasApiStatus?.errorMessage && (
        <Message type="error" message={customProps.hasApiStatus.errorMessage} />
      )}
      <div className="section-wrapper">
        <SubHeader variant="h2" className="titlePolicy maininfo" text={i18n.t('policies.mainInfo')} />
        <div className="policyLine">
          <div className="policyText">
            <p>{i18n.t('policies.companyLogo')}</p>
            <p>{i18n.t('policies.chooseImage')}</p>
          </div>
          <FormStyle.default.FieldsWrapper className="vertical">
            <FormStyle.default.TextWrapper>
              <Field
                name="upload"
                onUpload={(event) => {
                  handleEdit(event, { field: 'file', type: 'file' });
                }}
                id="upload"
                as={Upload}
                allowMultiple={false}
                totalFiles={1}
                placeholderText=""
                value={values.fileName}
                type="file"
                normal
                acceptedFileTypes={['image/jpeg', 'image/png']}
                labelFileTypeNotAllowed={'Cannot upload audio and video'}
                allowFileTypeValidation
                imageUpload
                disabled={viewOnly}
                docLink={docLink}
                fileChosen={(event) => {
                  if (event && event.length === 0) {
                    handleEdit(event, { field: 'file', type: 'file' })
                  }
                }}
              />

              {errors['upload'] && !isNotValid && (
                <Message type="error" message={errors['upload']} />
              )}
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          {enableCustomPolicy && <div className='submitPolicyButtonContainer'><SubmitPolicyButton customProps={customProps} i18n={i18n} checkFields={checkFields} values={values} validateForm={validateForm} submitAction={submitAction}/></div>}
        </div>
      </div>
      <div className="section-wrapper">
        {enableCustomPolicy ? <SubHeader className="customPolicy" variant="h2" text={i18n.t('policies.customPolicies')} /> : <SubHeader className="titlePolicy selectpolicy" variant="h2" text={i18n.t('policies.selectPolicies')} />}
        <div className="selectedCheckbox">
          {enableCustomPolicy ? <div className='customPolicy'>
           <div className='customPolicyTemplate'>
                <div>The custom policy document should be in Docx format and should follow the Custom Policy Document Template.</div>
                <div className='customPolicyTemplateButton'>
                   <a className='customPolicyTemplateLink' href='https://docs.google.com/viewer?url=https://gs-public-manuals.s3.amazonaws.com/custom+policy+template/Custom+Policy+Document+Template+.docx' target="_blank">
                       <Button label={i18n.t('common.customPolicyTemplate')}/>
                    </a>
                </div>
            </div>
            <div className='uploadButtonContainer'>
              <Button
               className="buttonBulkUploadDoc"
               type={'submit'}
               label={i18n.t('dailyDocuments.uploadNewDoc')}
               onClick={() => {
                setbackgroundModal(false);
                setwithCloseIcon(true);
                setModalObject({
                  header: '',
                  content: () => {
                    return (
                      <UploadCustomPolicy
                        currentItem={currentItem}
                        closeModal={closeModal}
                        saveDocument={saveCustomPolicy}
                        uploadApi={uploadApi}
                        callback={callback}
                        parent="policies"
                        languages={languages}
                        />
                      );
                    }
                  });
                  clickedModal();
                 }}
               />
              </div>

              <div className="customPolicyTableContainer">
                {isSpireon && <div className="driverstatusTitle">
                  <span className="spireonOldOnly">
                    Custom Polices
                  </span>
                </div>}
                <Table
                  type="client"
                  defaultSortBy={{ sortBy: 'date', order: 'desc' }}
                  title=""
                  columns={[...columns]}
                  itemsPerPage={10}
                  data={generateRows(customPolicy?.customPolicyList)}
                  emptyMessage={i18n.t('common.tableEmptyMessage')}
                  isPaginationNeeded="true"
                  pageinationReset={pagination}
                  pagenationClicked={(e) => {
                    setPagination(!e);
                  }}
                  actions={[
                    {
                      icon: (row, index) => {
                        return (
                          <React.Fragment key={index}>
                            <Icon
                              src={getImagePath(imageUrls.edit)}
                              onClick={() => {
                              setwithCloseIcon(false);
                              setbackgroundModal(true);
                              setModalObject({
                                content: () => (
                                  <UploadCustomPolicy
                                    currentItem={currentItem}
                                    closeModal={closeModal}
                                    saveDocument={editCustomPolicy}
                                    uploadApi={uploadApi}
                                    callback={callback}
                                    parent="policies"
                                    currentData={{
                                      id: row?.id,
                                      policyDocId: row?.policyDocId,
                                      name: row.nameValue,
                                      language: row.languageValue
                                    }}
                                    // types={types}
                                    languages={languages}
                                    />
                                  )
                              });
                              clickedModal();
                             }}
                            />
                            <div>&nbsp;</div>
                            <Icon
                            src={getImagePath(imageUrls.deleteIcon)}
                            onClick={() => {
                              setwithCloseIcon(false);
                              setbackgroundModal(true);
                              setModalObject({
                                content: () => (
                                  <div className="deleteParent">
                                    <div className="imageSection">
                                      <img
                                        src={getImagePath(imageUrls.confirmIcon)}
                                        alt="Are you sure"
                                      />
                                    </div>
                                    <h2> {i18n.t('common.sureConfirm')} </h2>
                                    <div className="deletePopupBtn">
                                      <div className="cancelBtn">
                                        <Button
                                          label={i18n.t('Cancel')}
                                          type={'reset'}
                                          onClick={() => {
                                            closeModal();
                                          }}
                                        ></Button>
                                      </div>
                                      <div className="deletebtn">
                                        <Button
                                          label={i18n.t('common.deletePopup')}
                                          onClick={() => {
                                            handleDeleteCustomPolicyAlert({
                                              id: 'deleteAlert',
                                              data: {
                                                id: row?.id,
                                              },
                                              callback: callback
                                            });
                                            closeModal();
                                          }}
                                        ></Button>
                                      </div>
                                    </div>
                                  </div>
                                )
                              });
                              clickedModal();
                            }}
                          />
                          </React.Fragment>
                        );
                      }
                    }
                  ]}
                  i18n={i18n}
                />
              </div>
          </div>
            : 
            customProps?.policies?.policies?.map((item, index) => (
              <Policy
                item={item}
                i18n={i18n}
                values={values}
                key={index}
                errFields={errFields}
                handleEdit={handleEdit}
                downloadDocument={customProps.downloadDocument}
                disabled={viewOnly}
              />
            ))
          }
        </div>
      </div>
      {!viewOnly && !enableCustomPolicy && <SubmitPolicyButton customProps={customProps} i18n={i18n} checkFields={checkFields} values={values} validateForm={validateForm} submitAction={submitAction}/>}
      {enableCustomPolicy && <Modal.default
          open={open}
          setOpen={setOpen}
          data={
            ModalObject || {
              header: '',
              content: () => { }
            }
          }
          onCloseModal={closeModal}
          background="black"
          withCloseIcon={withCloseIcon}
          backgroundModal={backgroundModal}
          deletePopup
        />
      }
    </>
  );
};

export default RenderComponent;

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  customProps: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};
