import {
  getRegions,
  getRegionsByCompany,
  getTerminals,
  getDepartments,
  getLocations
} from '../../../config/apis';

const initialState = {
  data: {
    regions: [],
    terminals: [],
    departments: [],
    subDepartments: []
  },
  selectedItems: {}
};

export const regions = {
  state: { ...initialState },
  reducers: {
    handleGetRegions: (state, data) => {
      return { ...state, data: { ...state.data, ...data } };
    },
    handleGetTerminals: (state, data) => {
      return { ...state, data: { ...state.data, ...data } };
    },
    handleGetDepartments: (state, data) => {
      return { ...state, data: { ...state.data, ...data } };
    },
    handleGetLocations: (state, data) => {
      return { ...state, data: { ...state.data, ...data } };
    },
    updateAll: (state, data) => {
      return {...state, data };
    }
  },
  effects: (dispatch) => ({
    async fetchRegions(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: getRegions
          },
          id: payload.apiId
        });
        if (response) {
          this.handleGetRegions({ regions: [...response] });
        }
      } catch (error) {
        this.handleGetRegions({ regions: [] });
      }
    },

    async fetchRegionsByCompany(payload) {
      try {
        const url = getRegionsByCompany.url.split('{')[0];

        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: { ...getRegionsByCompany, url: url + payload }
          },
          id: payload.apiId
        });
        if (response) {
          this.handleGetRegions({ regions: [...response] });
        }
      } catch (error) {
        this.handleGetRegions({ regions: [] });
      }
    },

    async fetchTerminals(payload) {
      try {
        const url = getTerminals.url.split('{')[0];

        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: { ...getTerminals, url: url + payload.data.id }
          },
          id: payload.apiId
        });
        if (response) {
          this.handleGetTerminals({ terminals: [...response] });
        }
      } catch (error) {
        this.handleGetTerminals({ terminals: [] });
      }
    },
    async fetchDepartments(payload) {
      try {
        const url = getDepartments.url.split('{')[0];

        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: { ...getDepartments, url: url + payload.data.id }
          },
          id: payload.apiId
        });
        if (response) {
          this.handleGetDepartments({ departments: [...response] });
        }
      } catch (error) {
        this.handleGetDepartments({ departments: [] });
      }
    },

    async fetchSubDepartments(payload) {
      try {
        const url = getLocations.url.split('{')[0];

        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: { ...getLocations, url: url + payload.data.id }
          },
          id: payload.apiId
        });
        if (response) {
          this.handleGetLocations({ subDepartments: [...response] });
        }
      } catch (error) {
        this.handleGetLocations({ subDepartments: [] });
      }
    }
  })
};
