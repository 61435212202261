/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Styled from './style';
import Button from '@material-ui/core/Button';


const HardwareInstallation = ({ history, loginInfo }) => {
  const [username, setUserName] = useState('');
  return (
    <Styled.Wrapper>
      <div className="welcomeText">
        <h1 className="stepTitle">Vehicle Installation</h1>
        <div className="iframeWrapper">
          <iframe
            src="https://www.youtube.com/embed/5fzN8v26v28"
            title="hardware-installation"
            className="respIframe"
          ></iframe>
        </div>
        <div className="details">
        This video will show you exactly how to install your hardware to your truck. You can find this video on <a className="linkColor" href='https://www.youtube.com/embed/5fzN8v26v28'>YouTube</a>, or you can simply select “Share Video” at the bottom of the page and send yourself an email with the link. To move to the next page, select “Next.”
        </div>
      </div>

    </Styled.Wrapper>
  );
};

export default HardwareInstallation;

HardwareInstallation.propTypes = {
  history: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired
};
