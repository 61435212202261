/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, lazy } from 'react';
import PropTypes from 'prop-types';
import Styled from './style';
import Button from '@material-ui/core/Button';
import Message from '../../../../../components/UI/Message/Clutch';
import { hasValue } from '../../../../../utils/utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import green from '@material-ui/core/colors/green';
import {
  pageUrls,
  COOKIE_AUTH_DETAILS
} from '../../../../../config/constants/keys';
import { getCookie, setCookie } from '../../../../../services/cookie';

import underscore from 'underscore';
const layout = window.$environment?.CURRENT_LAYOUT;
const Input = lazy(() =>
  import(`../../../../../components/UI/Input/${layout}`)
);

const AdditionalDetails = ({
  history,
  apiStatus,
  loginInfo,
  truckInfo,
  updateAllTruck,
  isValidVin
}) => {
  const apiId = 'isValidVIN';
  const saveId = 'updateTrucks';
  const hasValidVinApiStatus = hasValue(apiStatus?.status, 'api', apiId);
  const isSaveSuccess = hasValue(apiStatus?.status, 'api', saveId);
  const [data, setData] = useState(
    truckInfo.onboardingTrucks
      ? truckInfo.onboardingTrucks.map((item) => {
          item.isValid = false;
          item.hasError = '';
          return item;
        })
      : []
  );
  const [duplicate, setDuplicate] = useState(false);
  const [length, setLength] = useState(false);
  const [isValid, setIsValidAll] = useState(false);
  const [idx, currentIdx] = useState(0);
  const handleEdit = (event, index, companyId, vehicleId) => {
    setData(
      data.map((key, i) => {
        if (i === index)
          return { ...key, vin: event, isValid: false, hasError: '' };
        return key;
      })
    );
    setIsValidAll(false);
    const found = data.some((el) => {
      return el.vehicleId !== vehicleId && el.vin === event;
    });

    if (found) {
      setData(
        data.map((key, i) => {
          if (i === index) return { ...key, vin: event, isValid: true };
          return key;
        })
      );
    } else if (!found && event.length === 17) {
      currentIdx(index);
      isValidVin({
        apiId: 'isValidVIN',
        data: {
          vin: event,
          companyId: companyId
        }
      });
    }
  };
  const goToDashboard = () => {
    // var valueArr = data.map(function (item) {
    //   return item.vin;
    // });
    // var isDuplicate = valueArr.some(function (item, idx) {
    //   return valueArr.indexOf(item) !== idx;
    // });
    // setDuplicate(isDuplicate);
    let present = document.querySelector("[type = 'error']");
    if (!present) {
      var newArr = underscore.map(data, function (o) {
        return underscore.omit(o, ['isValid', 'hasError']);
      });

      updateAllTruck({
        data: newArr,
        id: 'updateTrucks'
      });
    }
  };

  const updateValue = (val) => {
    return val.substring(0, 17);
  };
  const showError = (field, apiError, index) => {
    if (apiError?.[field]) {
      setData(
        data.map((key, i) => {
          if (i === index) return { ...key, hasError: apiError[field] };
          return key;
        })
      );
    }
  };
  useEffect(() => {
    showError(
      `vin`,
      hasValue(apiStatus?.status, 'api', `isValidVIN`)?.errorMessage,
      idx
    );
  }, [apiStatus?.status]);

  useEffect(() => {
    if (truckInfo.updateSuccess === 'Success') {
      tokenVal.onboardCompleted = true;
      const token = getCookie(COOKIE_AUTH_DETAILS);
      setCookie(COOKIE_AUTH_DETAILS, tokenVal, token.rememberMe);
      history.push(pageUrls.dashboard);
    }
  }, [truckInfo.updateSuccess]);
  const [tokenVal, setTokenVal] = useState({});
  useEffect(() => {
    const token = getCookie(COOKIE_AUTH_DETAILS);
    setTokenVal(token);
  }, []);
  return (
    <Styled.Wrapper>
      <div className="welcomeText">
        <h1 className="stepTitle">Additional Details</h1>
        <div className="details">
          {data.map((key, index) => {
            return (
              <div className="truckInfoitems">
                <div className="headerSection">
                  <h2>Truck Information</h2>
                  <span className="index">
                    <span className="indexNo">{index + 1}</span>
                    <span className="nextHalf">
                      <span>/</span>
                      {data.length}
                    </span>
                  </span>
                </div>
                <div className="infoWrapper infoFirst">
                  <div className="listItems">
                    <span className="listLabel">Vehicle Type</span>
                    <span className="itemValue">{key.type}</span>
                  </div>
                  <div className="listItems">
                    <span className="listLabel">Unit ID</span>
                    <span className="itemValue">{key.unitId}</span>
                  </div>
                </div>
                <div className="infoWrapper">
                  <div className="listItems">
                    <span className="listLabel">Year</span>
                    <span className="itemValue">{key.year}</span>
                  </div>
                  <div className="listItems">
                    <span className="listLabel">Make</span>
                    <span className="itemValue">{key.make}</span>
                  </div>
                  <div className="listItems">
                    <span className="listLabel">Model</span>
                    <span className="itemValue">{key.model}</span>
                  </div>
                </div>
                <div className="infoWrapper">
                  <div className="listItems vinWrapper">
                    <span className="listLabel">VIN#</span>
                    <Input
                      handleOnChange={(event) => {
                        handleEdit(event, index, key.companyId, key.vehicleId);
                      }}
                      value={key.vin ? key.vin : ''}
                      placeholder="Type VIN Number"
                      updateValue={(e) => updateValue(e)}
                      name={`vin`}
                    />
                    <div className="apiProgress">
                      {index === idx &&
                      hasValidVinApiStatus?.isValidVinLoading ? (
                        <CircularProgress style={{ color: green[500] }} />
                      ) : (
                        ''
                      )}
                    </div>
                    {key.isValid ? (
                      <Message
                        type="error"
                        message={`Vehicle with vin ${key.vin} already exists`}
                      />
                    ) : (
                      ''
                    )}
                    {key.hasError !== '' ? (
                      <Message type="error" message={key.hasError} />
                    ) : (
                      ''
                    )}
                    {key.vin?.length > 0 && key.vin?.length < 17 ? (
                      <Message
                        type="error"
                        message="VIN number must be 17 character long. Numbers and letters only. I, O, or Q are not allowed"
                      />
                    ) : (
                      ''
                    )}
                    {!key.vin ? (
                      <Message type="error" message="This field is required" />
                    ) : (
                      ''
                    )}
                    <div className="validVinIcon">
                      {!key.isValid &&
                      key.vin?.length === 17 &&
                      !hasValidVinApiStatus?.isValidVinLoading &&
                      key.hasError === '' ? (
                        <CheckCircleOutlinedIcon
                          style={{ color: green[500] }}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="gotoWrapper">
          <Button
            variant="contained"
            id="goToDashboard"
            onClick={() => {
              goToDashboard();
            }}
          >
            Go To Dashboard
          </Button>
        </div>
      </div>
    </Styled.Wrapper>
  );
};

export default AdditionalDetails;

AdditionalDetails.propTypes = {
  history: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired
};
