import React, { useEffect, lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useParams } from 'react-router-dom';
import RenderComponent from '@/components/modules/DynamicRenderPage';

import ParentStyled from './style';
import { loadStyle, loadPageConfig } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'pages/web/DriverStatus');

const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.userDriverStatus[0].name}`));
const Content = lazy(() => import(`@/${config.userDriverStatus[1].name}`));
const TopActions =
  config.userDriverStatus[0].children &&
  lazy(() => import(`${config.userDriverStatus[0].children.name}`));

const DriverStatus = (parentProps) => {
  const { fetchDriverStatus, theme, i18n, history } = parentProps;
  const [pagination, setPagination] = useState(false);

  const match = useParams();

  const formId = 'driverStatus';
  const basicRequest = {
    displayLength: '15',
    displayStart: '1',
    sortCol: 'full_name',
    sortDir: 'asc'
  };
  const [request, setRequest] = useState({
    ...basicRequest
  });

  const changeRequest = (fields) => {
    setRequest({ ...basicRequest, ...fields });
  };
  const commonParentProps = { history, i18n, Styled, theme };

  useEffect(() => {
    fetchDriverStatus({
      apiId: formId,
      data: {
        status: match.id,
        request
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, match.id]);

  return (
    <>
      <RenderComponent
        {...config.userDriverStatus[0]}
        parentProps={{
          ...parentProps,
          changeRequest,
          request,
          pagination,
          setPagination
        }}
        commonParentProps={commonParentProps}
        component={Title}
        childComponent={TopActions}
        header={`dashboard.driverStatus.${match.id}`}
      />
      <RenderComponent
        {...config.userDriverStatus[1]}
        parentProps={{
          ...parentProps,
          changeRequest,
          request,
          pagination,
          setPagination,
          match
        }}
        commonParentProps={commonParentProps}
        component={Content}
      />
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    driverStatus: state.driverStatus
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchDriverStatus: (data) => dispatch.driverStatus.fetchData(data)
  };
};

export default connect(mapState, mapDispatch)(DriverStatus);
DriverStatus.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  fetchDriverStatus: PropTypes.func.isRequired,
  driverStatus: PropTypes.object.isRequired
};
