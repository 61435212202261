import React, { useState, useEffect, lazy } from 'react';
import { Link } from 'react-router-dom';

import 'react-pagination-js/dist/styles.css';

import Pagination from 'react-pagination-js';

import Icon from '@material-ui/core/Icon';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import Styled from './serverStyle';

const layout = window?.$environment?.CURRENT_LAYOUT;
const SvgIcon = lazy(() => import(`../../../SvgIcon/${layout}`));
const TableHead = ({
  headCells,
  changeRequest,
  sortedColumn,
  setSortedColumn
}) => {
  const [headerCells, setHeaderCells] = useState([]);

  useEffect(() => {
    setHeaderCells([...headCells]);
  }, [headCells]);
  const sortHandler = (column, order) => {
    const list = [...headerCells];
    const columnIndex = list.findIndex((item) => item.id === column);
    list.splice(columnIndex, 1, {
      ...list[columnIndex],
      sortOrder: order === 'asc' ? 'desc' : 'asc'
    });
    setHeaderCells(list);
    changeRequest && changeRequest({ sortCol: column, sortDir: order });
  };
  return (
    <>
      {headerCells.map((headCell, index) => {
        if (headCell.id === 'actions') {
          return (
            <Styled.HeaderCell
              column={index}
              className={headCell.id}
            ></Styled.HeaderCell>
          );
        } else if (headCell.needSorting) {
          return (
            <Styled.HeaderCell
              column={index}
              className={headCell.id}
              key={headCell.id}
            >
              <Icon>
                {!headCell.sortOrder ? (
                  <SvgIcon
                    value={'unsort'}
                    className="unsort"
                    onClick={() => {
                      sortHandler(headCell.id, headCell.sortOrder || 'asc');
                    }}
                  />
                ) : headCell.sortOrder === 'asc' ? (
                  <ArrowDropDownIcon
                    onClick={() => {
                      sortHandler(headCell.id, headCell.sortOrder || 'asc');
                    }}
                  />
                ) : (
                  <ArrowDropUpIcon
                    onClick={() => {
                      sortHandler(headCell.id, headCell.sortOrder || 'asc');
                    }}
                  />
                )}
              </Icon>

              {headCell.label}
            </Styled.HeaderCell>
          );
        } else {
          return (
            <Styled.HeaderCell
              key={headCell.id}
              align={'center'}
              column={index}
              className={headCell.id}
            >
              <p style={{ paddingTop: '7px', margin: '0px' }}>
                {headCell.label}  
              </p>
               
            </Styled.HeaderCell>
          );
        }
      })}
    </>
  );
};

const TableRow = ({ data, columns, actions }) => {
  return (
    <>
      {data.map((row, index) => {
        const modifiedColumns = columns.filter(
          (column) => column.id !== 'actions'
        );
        return (
          <React.Fragment key={'row' + index}>
            {modifiedColumns.map((column, number) => {
              return (
                <Styled.RowCell
                  align={column.numeric ? 'right' : 'left'}
                  key={'row' + number}
                  className={column.id}
                >
                  {column.link ? (
                    <Link to={column.link + '/' + row.companyId}>
                      {row[column.id]}
                    </Link>
                  ) : (
                    row[column.id]
                  )}
                </Styled.RowCell>
              );
            })}
            {actions.map((action, number) => {
              return (
                <Styled.RowCell
                  align={'center'}
                  key={`row action${number}`}
                  className="actions"
                  disabled={row.readOnly}
                >
                  {action.icon(row, index, row.readOnly)}
                </Styled.RowCell>
              );
            })}
          </React.Fragment>
        );
      })}
    </>
  );
};

const Table = ({
  columns,
  data,
  emptyMessage,
  actions,
  isPaginationNeeded,
  changeRequest,
  handlePagination,
  sizeOfPage,
  totalRecords
}) => {
  const [sortedColumn, setSortedColumn] = useState('');
  return (
    <>
      <Styled.Wrapper columns={columns}>
        <TableHead
          rowCount={data.length}
          headCells={columns}
          actionsCount={actions.length}
          changeRequest={changeRequest}
          sortedColumn={sortedColumn}
          setSortedColumn={setSortedColumn}
        />
        <TableRow
          rowCount={data.length}
          data={data}
          columns={columns}
          actionsCount={actions.length}
          actions={actions}
          sortedColumn={sortedColumn}
        />
      </Styled.Wrapper>
      {isPaginationNeeded && (
        <PaginationComponent
          handlePagination={handlePagination}
          changeRequest={changeRequest}
          totalRecords={totalRecords}
          sizeOfPage={sizeOfPage}
        />
      )}
    </>
  );
};

export default Table;

const PaginationComponent = ({ changeRequest, totalRecords, sizeOfPage }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalSize, setTotalSize] = useState(0);
  useEffect(() => {
    setTotalSize(
      totalRecords % sizeOfPage !== 0
        ? Math.floor(totalRecords / sizeOfPage) + 1
        : Math.floor(totalRecords / sizeOfPage)
    );
  }, [totalRecords, sizeOfPage]);

  useEffect(() => {
    changeRequest({ displayStart: currentPage, displayLength: 15 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);
  const paginationHandler = (index) => {
    setCurrentPage(index);
  };
  return (
    <Styled.PaginationWrapper>
      <Pagination
        currentPage={currentPage}
        totalSize={totalSize}
        sizePerPage={1}
        changeCurrentPage={paginationHandler}
      />
    </Styled.PaginationWrapper>
  );
};
