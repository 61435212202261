import styled from 'styled-components';
import Parent from './style';
const Wrapper = styled(Parent.Wrapper)`
    ${({ theme }) => `
        .paymentInfo {
            padding: 1%;
            .MuiFormLabel-root{
                font-size: 24px;
            }
        }
        .paymentInfo + h2 {
            margin-bottom: 0 !important;
        }
        .contractWrapper{
            padding: 2%;
            .MuiBox-root {
                align-self: center;
            }
        }
        .contractWrapper{
            padding-left: 21%;
            .buttonStyle{
                font-size: 23px;
            }
        }
        .contractButtons{
            position: absolute;
            top: 0;
            padding-top: 2%;
            right: 2%;
        }
        .fieldSection:first-child {
            order:1;
        }
        .fieldSection:nth-child(2){
            order:3;
        }
        .fieldSection:nth-child(3){
            order:2;
        }
        .fieldSection:nth-child(4){
            order:4;
        }
        .fieldSection:nth-child(5){
            order:5;
        }
    `}
`;
const TableWrapper = styled(Parent.TableWrapper)`
padding: 0 2%;
`;
const Styled = { Wrapper, TableWrapper };

export default Styled;
