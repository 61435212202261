import styled from 'styled-components';
import * as Parent from '../Base/style';

export const ModalWrapper = styled(Parent.ModalWrapper)`
${({ theme }) => `
.deletePopupBtn {
    button[type="submit"] {
        font-family: ${theme.palette.titleFont};
        font-size: 23px !important;
        background: #C92100;
        min-height: 45px;
        border-radius: 0;
    }
}
button.MuiButtonBase-root { 
    padding: 0 13px !important; 
}
.successpopup h2 {
    font-size: 20px;
    margin-top: 0;
    line-height: 26px;
}
img {
    width: 58px !important;
}
.carousel .slide img {
    width: 100% !important;
}
.deleteParent p {
    font-size: 16px;
}
`}
`;
export const ModalWrapper2 = styled(Parent.ModalWrapper2)`
${({ theme }) => `
.deletePopupBtn {
    button[type="submit"] {
        font-family: ${theme.palette.titleFont};
        font-size: 23px !important;
        background: #C92100;
        min-height: 45px;
        border-radius: 0;
    }
}
.deletedRow {
    color: ${theme.colors.MODAL_HEADING};
    padding-bottom: 20px;
}
button.MuiButtonBase-root { 
    padding: 0 13px !important; 
}
img {
    width: 58px !important;
}
.carousel .slide img {
    width: 100% !important;
}
.successpopup h2 {
    font-size: 20px;
    margin-top: 0;
    line-height: 26px;
}
`}
`;
export const ParentWrapper = styled(Parent.ParentWrapper)``;
