import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Parent from './style';
const Wrapper = styled(Parent.Wrapper)`
  ${({ theme }) => `
form {
  ${window.$environment.WHITE_THEME || window.$environment.clutchNewUI ? `
        background-color: #fff;
        padding: 2% 3%;
        margin-bottom: 30px;
        border-radius: 5px;
        .addLogTitle {
          padding-left: 0 !important;
        }
`:''}
    justify-content: flex-start;
    padding: 2%;
    .MuiBox-root {
      width: 23%;
    }
  @media (max-width: ${theme.breakpoints.values.sm}px) {
    .MuiBox-root {
      width: 100% !important;
    }
  }
  .addLogTitle {
    padding: 16px 11px;
    padding-left: 0;
    color: ${theme.colors.PRIMARY_CONTRAST} !important;
    font-size: 25px !important;
    font-weight: 700;
    text-transform: capitalize;
    background-color: transparent !important;
    box-shadow: none;
    margin-bottom: 0 !important;
    padding-bottom: 4px;
    padding-top: 0;
  }
  .MuiBox-root:nth-child(5) {
    width: 100%;
    .MuiFormLabel-root,
    .MuiBox-root {
      width: 23.4%;
      @media (max-width: ${theme.breakpoints.values.sm}px) {
        width: 100% !important;
      }
    }
  }
  .MuiBox-root:nth-child(6) {
    justify-content: flex-start;
    flex-direction: row;
    @media (max-width: ${theme.breakpoints.values.smd}px) {
      flex-direction: column;
    }
    width:100%;
    .MuiBox-root {
      flex: 0 0 10.5%;
      &:first-child {
        order: 2;
      }
      &:last-child {
        margin-right: 1.2em;
      }
    }
  }
}
button[type="submit"]{
  background-color: ${theme.colors.LINK_COLOR};
  height: 40px;
  border-radius: 4px;
  color: ${theme.colors.PRIMARY_CONTRAST};
  font-size: 12px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  :hover {
    background-color: ${theme.colors.LINK_COLOR};
  }
}
button[type="reset"]{
  background-color: rgba(0, 0, 0, 0.3) !important;
  height: 40px;
  border-radius: 4px;
  color: rgba(255, 255, 255, 0.66) !important;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  :hover {
    background-color: rgba(0, 0, 0, 0.3) !important;
    color: rgba(255, 255, 255, 0.66) !important;
  }
}
.MuiBox-root {
  width: 19%;
  flex-direction: column;
  margin-bottom: 0;
  margin-right: 2%;
  @media (max-width: ${theme.breakpoints.values.md}px) {
    width: 100%;
  }
  .filepond--drop-label {
    padding-left: 6px;
  }
  p {
    flex: 0 0 20%;
    padding: 0;
    font-size: 20px;
    @media (max-width: ${theme.breakpoints.values.md}px) {
      font-size: 16px;
    }
  }
  .MuiBox-root {
    margin-right: 1em;
    margin-top: 0px;
    width: 100%;
  }
}
`}
`;

const Content = styled(Box)`${({ theme }) => `
.logwrapper > header{
  ${theme.colors.NEW_THEME ? `
  .Mui-selected {
    :hover{
      color: #fff;
      .MuiTab-wrapper {
        color: #fff;
      }
    }
  }
  ` : ''}
}
  .logwrapper {
    overflow: hidden;
    display: flex;
    header.MuiAppBar-positionStatic {
      width: 16%;
      border-right: 1px solid #2c3249;
      padding-top: 3%;
    }
    div[role='tabpanel'].MuiTypography-root {
      width: 85%;
    }
    .MuiTab-wrapper {
      align-items: normal;
      text-align: left;
    }
    .MuiButtonBase-root.MuiTab-root {
      border-right: 0;
      padding-left: 8%;
      width: 100%;
      text-transform: capitalize !important;
      border-bottom: 1px solid ${theme.colors.SECONDARY_BORDER};
      background: ${theme.colors.PRIMARY_MAIN};
      :hover {
        border-bottom: 1px solid #2c3249 !important;        
        color: ${theme.colors.TAB_HIGHLIGHT};
      }
    }
    .MuiTabs-flexContainer .Mui-selected {
      background-color: ${theme.colors.BACKGROUND_MENU};
      border-bottom: 0 !important;
      font-weight: 700;
      color: ${theme.colors.PRIMARY_CONTRAST};
    }
  }
`}`;

const Styled = { Wrapper, Content };

export default Styled;
