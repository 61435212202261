import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
${({ theme }) => `
.registrationWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 30px;
    .companyDetails {
        flex: 0 0 44%;
    }
    #contractLength{
        opacity: 1;
        color: #fff;
    }
    .AmountSection{
        color: ${theme.colors.PRIMARY_CONTRAST};
        justify-content: flex-end;
        font-size: 20px;
        padding-top: 20px;
        margin-bottom: 0;
    }
    .createWrapper {
        display: flex;
        justify-content: space-between;
        padding-top: 20px;
        #producerCode {
            width: 40%;
        }
        #producerCode + .MuiBox-root {
            margin-top: -19px;
        }
    }
    #dropin-container{
        margin-top: -14px;
    }
    .halfWidth {
        flex: 0 0 40%;
    }
    .subscriptionField {
        .MuiBox-root:first-child {
            flex: 0 0 25%;
        }
        #subscriptionTier {
            color: ${theme.colors.LINK_COLOR};
        }
    }
    #agree {
        flex-direction: column;
        .agreeCheckbox {
            display: flex;
        }
    }
    .agreeLabel {
        width: 100%;
        display: flex;
        padding-left: 0;
        padding-top: 8px;
        color: ${theme.colors.PRIMARY_CONTRAST};
        .link {
            padding-left: 5px;
            padding-top: 0px;
            color: ${theme.colors.PRIMARY_CONTRAST};
            text-decoration: underline;
        }
    }
    .companyDetails .normalCheckbox {
        padding-right: 0;
    }
    #nameOnCard {
        border-radius: 50%;
    }
    #submit-button {
     display: none;
     }
     .paymentSection {
        background: #1b1b1b;
        border-radius: 9px;
        .paymentName{
            align-self: center;
        }
     }
}

`}
`;

const Styled = { Wrapper };

export default Styled;
