import styled from 'styled-components';
import Parent from '../Base/style';

const ImportWrapper = styled(Parent)`
${({ userImport}) => `
.csv-reader-input {
      height: 26px;
    width: 26px;
}
`}
`;

export default ImportWrapper;
