import styled from 'styled-components';
import Parent from '../style';

const PolicyWrapper = styled(Parent.PolicyWrapper)`
.viewSample {
    display: none;
}
`;
const SelectedPolicy = styled(Parent.SelectedPolicy)`
flex: 0 0 25%;
padding-top: 8px;
.viewMore {
    align-self: center;
    padding-left: 14px;
}
.MuiAvatar-root {
    width: auto;
    height: auto;
}
.subFormsection {
    padding-top: 20px;
}
.subFields {
    display: flex;
    flex-direction: column;
    label {
     padding-bottom: 5px;
     font-size: 16px;
    }
    .MuiInputBase-root {
        width: 90%;
    }
}
.normalCheckbox {
    .Checkbox { 
        top: 2px;
    }
    span {
        padding-left: 10px;
        font-size: 20px;
    }
}
`;
const Styled = { SelectedPolicy , PolicyWrapper };
export default Styled;
