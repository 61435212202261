const initialState = {};
export const pendingMechanicInspections = {
  state: { ...initialState },
  reducers: {
    getSuccess(state, payload) {
      return { ...payload };
    },
    getFailed(state) {
      return { ...initialState };
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload) {
      try {
        const getApi = await import(
          `../../../config/apis/${window.$environment.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('fetchPendingMechanicInspections');
        let response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...apiObject,
              url: apiObject.url
            },
            data: {...payload.data?.request, state: payload.data.state}
          },
          id: payload.apiId
        });
          response &&
          response.content.map((item) => {
            item['driverName'] = item.driver?.name;
            item['mechanicName'] = item.mechanic?.name;
            item['unitId'] = item.unit_id?.name;
          });
        response && this.getSuccess(response);
      } catch (error) {
        this.getFailed();
      }
    },
    async download(payload, rootState) {
      try {
        const getApi = await import(
          `../../../config/apis/${window.$environment.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('dailyLogDownloadApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject,
            data: payload.data
          },
          callback: payload.callback,
          id: payload.id
        });
        payload.callback('success', response);
      } catch (error) {
        this.getFailed();
      }
    }
    // async download(payload, rootState) {
    //   try {
    //     const getApi = await import(
    //       `../../../config/apis/${window.$environment.SERVICE_PROVIDER}`
    //     );
    //     const apiObject = getApi.default('bulkDownloadReportApi');
    //     const response = await dispatch.asyncRequests.handleAsyncRequests({
    //       payload: {
    //         api: apiObject
    //       },
    //       callback: payload.callback,
    //       id: payload.id
    //     });
    //     payload.callback('success', response);
    //   } catch (error) {
    //     this.getFailed();
    //   }
    // }
  })
};
