import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { object, string, number } from 'yup';

import RenderComponent from './RenderComponent';

import { objectArrayFromSameField, hasValue } from '@/utils/utils';

import Styled from './style';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));

const Component = ({
  closeModal,
  saveDot,
  callback,
  currentItem,
  states,
  apiStatus
}) => {
  const { i18n } = useTranslation();
  const apiId = 'saveDotApi';
  const hasApiStatus = hasValue(apiStatus.status, 'api', apiId);
  const statesList = objectArrayFromSameField(states);

  return (
    <Styled.Wrapper>
      <Form
        type="server"
        direction="column"
        initialValues={{
          id: currentItem?.id,
          dotNumber: currentItem?.dotNumber || '',
          carrierName: currentItem?.carrierName || '',
          mainAddress: currentItem?.mainAddress || '',
          city: currentItem?.city || '',
          state: currentItem?.state
            ? { label: currentItem?.state, value: currentItem?.state }
            : statesList[0],
          zipCode: currentItem?.zipCode || ''
        }}
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {
          saveDot({
            apiId: apiId,
            data: {
              ...fields,
              state: fields.state?.value
            },
            callback
          });
          closeModal();
        }}
        customProps={{
          statesList,
          hasApiStatus,
          apiStatus
        }}
        handleClear={() => {}}
        validationSchema={object().shape({
          dotNumber: string()
          .matches(/^[0-9]*$/, i18n.t('Please enter valid number'))
          .required(i18n.t('common.fieldRequiredMessage'))
          .test(
            'len',
            i18n.t('Please enter no more than 9 characters.'),
            (val) => val?.length < 10
          ),
          carrierName: string().required(
            i18n.t('common.fieldRequiredMessage')
          ),
          mainAddress: string().required(
            i18n.t('common.fieldRequiredMessage')
          ),
          city: string().required(i18n.t('common.fieldRequiredMessage')),
          zipCode: string().required(i18n.t('common.fieldRequiredMessage'))
        })}
      />
    </Styled.Wrapper>
  );
};

export default Component;

Component.propTypes = {
  apiStatus: PropTypes.object,
  callback: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  saveDot: PropTypes.func,
  currentItem: PropTypes.object
};
