import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
  ${({ theme }) => `
  .filterWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .CSAForms {
      width: 70%;
    }
  }
  .graphContainer{
    .noData{
      display: flex;
      justify-content: center;
    }
    background-color: #20263a;
    padding: 20px;
    
    .csafield {
      width: 18% !important;
    }
  }
.graphTabs {
  .MuiTab-wrapper {
    flex-direction: row;
    .iconContainer{
      flex-basis: 5%;
    }
    svg{
      margin-right: 10px;
    }
    @media (max-width: ${theme.breakpoints.values.mlg - 1}px) {
      display: none;
    }
  }
  .Mui-selected  .MuiTab-wrapper {
    color: ${theme.colors.ORANGE};
  }
  .MuiButtonBase-root.MuiTab-root {
    width:12.5%;
    padding: 15px;
   
  }
  .MuiTabs-flexContainer{
    justify-content: center;
  }
  header{
    background-color: ${theme.colors.HEADER_COLOR};
    border-radius: 7px 7px 0 0;
    border-top: 1px solid ${theme.colors.HEADER_COLOR};
    border-bottom: 2px solid ${theme.colors.PRIMARY_BORDER};
    box-shadow: none;
    .MuiTabs-flexContainer{
      box-shadow: none;
      border: none;
    }
  }
  .MuiButtonBase-root.MuiTab-root{
    width: 16%;
    padding: 10px 0px;
    border-left: 1px solid ${theme.colors.PRIMARY_BORDER};
    border-right: 1px solid ${theme.colors.PRIMARY_BORDER};
    margin: 0;
  }
  .MuiTabs-flexContainer .Mui-selected{
    background-color: ${theme.colors.HEADER_COLOR};
  }
  .Mui-selected .MuiTab-wrapper{
    color: ${theme.colors.INPUT_COLOR};
    font-weight: bold;
    svg path{
      stroke: ${theme.colors.SECONDARY_MAIN} !important;
      fill: ${theme.colors.SECONDARY_MAIN};
    }
  }
  .MuiTab-root:hover .MuiTab-wrapper{
    color: ${theme.colors.INPUT_COLOR};
  }
  .severity-wrapper {
    width: 150px;
    height: 10px;
    display: flex;
    .addBorder {
      border-right: 0.1px solid black;
    }
    .leftCurve{
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
    }
    .rightCurve {
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
      border-right: none !important;
    }
    .green-wrapper{
      background: #00ff00;
      height: 100%;        
    }
    .yellow-wrapper {
      background: #ffff00;
      height: 100%;
    }
    .red-wrapper{
      height: 100%;
      background: #ff0000;
    }
  }
}
  .pageWrapper {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 30px;
    .innerCell {
      padding: 4px 0px;
      height: 28px;
    }
    .borderTop {
      border-top: 2px solid #2c3249;
    }
  }
  position: relative;
  .CSAForms {
    justify-content: flex-start;
    .MuiFormLabel-root {
      padding-bottom: 0px !important;
    }
    .vertical-button {
      .MuiBox-root:first-child {
        margin-right: 14px;
      }
      .MuiButtonBase-root {
        margin-top: 24px;
        padding-left: 0;
        padding-right: 0;
        .MuiButton-label {
          padding: 2px 22px;
        }
      }
    }
    .MuiBox-root {
      padding-top: 10px;
    }
  }
`}
`;

const Styled = { Wrapper };

export default Styled;
