const leftMenu = [
  {
    icon: 'logs.svg',
    label: 'Companies',
    roles: ['Admin'],
    path: '/admin/companies'
  },
  { icon: 'user.svg', label: 'Users', roles: ['Admin'], path: '/admin/users' },
  {
    icon: 'vehicle.svg',
    label: 'Subscriptions',
    roles: ['Admin'],
    path: '/admin/subscriptions'
  }
];
export default leftMenu;
