import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Parent from './style';
const Wrapper = styled(Parent.Wrapper)`
padding: 0;
.formWrap {
  padding-left: 5%;
}
`;
const LeftWrapper = styled(Parent.LeftWrapper)`
flex: 0 0 49.5%;
`;
const RightWrapper = styled(Parent.RightWrapper)`
flex: 0 0 49.5%;
.documentsHeader{
  margin-bottom: 0 !important;
  ul {
    margin-top:0;
  }
}
`;
const documentWrapper = styled(Parent.documentWrapper)`
${({ theme }) => `
padding-right: 2%;
ul {
  margin: 0;
   li {
     .MuiGrid-container .MuiAvatar-rounded {
       width: 27px;
       height: 27px;
     }
     
   }
 }
`}
`;
const submitUser = styled(Parent.submitUser)`
${({ theme }) => `
border-top: 2px solid ${theme.colors.BLACK};
.submitSection {
  justify-content: center;
  padding: 2% 0;
  .MuiButtonBase-root {
    margin-top: 0;
    min-width: 210px;
  }
}
`}

`;
const Styled = {
  Wrapper,
  RightWrapper,
  LeftWrapper,
  documentWrapper,
  submitUser
};
export default Styled;
