import * as Base from './base';

export const privacyPolicyScreen = Base.privacyPolicyScreen;

export const archiveLogsList = Base.archiveLogsList;
export const downloadCenter = Base.downloadCenter;
export const manageScheduling = Base.manageScheduling;
export const iftaList = Base.iftaList;
export const addState = Base.addState;
export const editState = Base.editState;
export const vehicleDTCView = Base.vehicleDTCView;

export const dashboard = [
  {
    name: 'pages/web/Dashboard/Header',
    props: ['dashboard'],
    commonProps: ['i18n', 'history', 'Styled'],
    headerText: 'dashboard.header.text'
  },
  {
    name: 'pages/web/Dashboard/Component',
    iconsAsLink: true,
    accordion: true,
    alternateComponent: false,
    props: [
      'dashboard',
      'getDriverStatus',
      'refreshVehicleStatus',
      'refreshDriverStatus',
      'enableNotification',
      'clearAllAlerts',
      'clearAll',
      'viewReport',
      'filterSuccess',
      'refreshDriverStatusMap',
      'getVehicleStatus',
      'handleAcceptPermissionRequest',
      'handleDeletePermissionRequest',
      'callback',
      'clearAllRecentAccidents',
      'clearAllDailyLogs',
      'viewReport',
      'receipt',
      'handleDeleteAlert'
    ],
    commonProps: ['Styled', 'i18n', 'history'],
    dataList: [
      {
        id: 'driverBehavior',
        nameKey: 'dashboard.tabs.driverBehavior',
        value: null,
        defaultExpanded: true,
        disabled: true,
        dependency: 'geometrisEnabled'
      },
      {
        id: 'vehicleStatus',
        nameKey: 'dashboard.tabs.vehicleStatus',
        value: null,
        defaultExpanded: true,
        disabled: true,
        dependency: 'geometrisEnabled'
      },
      {
        id: 'alerts',
        nameKey: 'dashboard.tabs.alerts.heading',
        value: null,
        defaultExpanded: true,
        disabled: true
      },
      {
        id: 'driverStatus',
        nameKey: 'dashboard.tabs.driverStatus.heading',
        value: null,
        defaultExpanded: true,
        disabled: true
      },
      {
        id: 'dailyLogs',
        nameKey: 'dashboard.tabs.dailyLogs.heading',
        value: null,
        defaultExpanded: true,
        disabled: true
      },
      {
        id: 'potentialViolations',
        nameKey: 'dashboard.tabs.potentialViolations.heading',
        value: null,
        defaultExpanded: true,
        disabled: true
      },
      {
        id: 'recentAccidents',
        nameKey: 'dashboard.tabs.recentAccidents',
        value: null,
        defaultExpanded: true,
        disabled: true
      },
      {
        id: 'developerRequests',
        nameKey: 'dashboard.tabs.developerRequests',
        value: null,
        defaultExpanded: true,
        disabled: true
      }
    ]
  }
];

export const vehicleList = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'vehicles.listHeading',
    children: {
      name: './TopActions',
      props: [
        'vehicles',
        'callback',
        'filterSuccess',
        'filterBoth',
        'handleExportVehicle',
        'handleExportTrailer',
        'handleImportVehiclePost',
        'handleImportTrailerPost',
        'logBook',
        'setPagination',
        'filters'
      ],
      commonProps: ['Styled', 'history', 'i18n'],
      title: 'topActions'
    }
  },
  {
    name: 'pages/web/Vehicles/OverRideComponent',
    props: [
      'vehicles',
      'apiStatus',
      'fetchVehicles',
      'handleDeleteAlert',
      'callback',
      'filterBoth',
      'filterSuccess',
      'handleExportVehicle',
      'handleExportTrailer',
      'handleImportVehicle',
      'handleImportTrailer',
      'handleImportVehiclePost',
      'handleImportTrailerPost',
      'fetchVehiclePageHistoryMap',
      'vehiclePageMap',
      'openDocument',
      'getDrivers',
      'trailers',
      'fetchTrailers',
      'fetchVehiclePageMap',
      'logBook',
      'handleTrailerDeleteAlert',
      'filters'
    ],
    commonProps: ['Styled', 'i18n', 'history', 'theme'],
    title: 'component',

    columns: [
      {
        id: 'full_name',
        numeric: false,
        disablePadding: true,
        label: 'vehicles.fullName',
        needSorting: true
      },
      {
        id: 'role',
        numeric: false,
        disablePadding: false,
        label: 'vehicles.role',
        needSorting: false
      },
      {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: 'vehicles.email',
        needSorting: true
      },
      {
        id: 'empId',
        numeric: true,
        disablePadding: false,
        label: 'vehicles.empId',
        needSorting: true
      },
      {
        id: 'phone',
        numeric: true,
        disablePadding: false,
        label: 'common.phone',
        needSorting: false
      },
      {
        id: 'assigned_vehicles',
        numeric: false,
        disablePadding: false,
        label: 'vehicles.assignedVehicles',
        needSorting: false
      },
      {
        id: 'document',
        label: 'common.documents'
      },
      {
        id: 'last_login',
        numeric: false,
        disablePadding: false,
        label: 'vehicles.lastLogin',
        needSorting: false
      },
      {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
        needSorting: true
      },
      {
        id: 'eld',
        numeric: false,
        disablePadding: false,
        label: 'ELD',
        needSorting: false
      },
      {
        id: 'actions',
        label: ''
      }
    ]
  }
];

export const userList = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'users.listHeading',
    children: {
      name: './TopActions',
      props: [
        'users',
        'callback',
        'filterSuccess',
        'handleExportVehicle',
        'handleExportUser',
        'handleImportUser',
        'logBook',
        'setPagination',
        'filterButtons',
        'setButtons',
        'filters',
        'setremoveData',
        'removeData'
      ],
      commonProps: ['Styled', 'i18n'],
      title: 'topActions'
    }
  },
  {
    name: 'pages/web/Users/Component',
    topAction: false,
    props: [
      'apiStatus',
      'users',
      'handleImportUser',
      'handleExportUser',
      'filterSuccess',
      'handleRestoreAlert',
      'handleDeleteAlert',
      'handleResetPassword',
      'fetchUsers',
      'changeRequest',
      'request',
      'setFilterData',
      'callback',
      'handleExportUserTemplate',
      'filters',
      'logBook',
      'setPagination',
      'filterButtons',
      'setButtons'
    ],
    commonProps: ['Styled', 'i18n', 'history', 'theme'],
    title: 'component',

    columns: [
      {
        id: 'full_name',
        numeric: false,
        disablePadding: true,
        label: 'vehicles.fullName',
        needSorting: true
      },
      {
        id: 'role',
        numeric: false,
        disablePadding: false,
        label: 'vehicles.role',
        needSorting: false
      },
      {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: 'vehicles.email',
        needSorting: true
      },
      {
        id: 'empId',
        numeric: true,
        disablePadding: false,
        label: 'vehicles.empId',
        needSorting: true
      },
      {
        id: 'phone',
        numeric: true,
        disablePadding: false,
        label: 'common.phone',
        needSorting: false
      },
      {
        id: 'assigned_vehicles',
        numeric: false,
        disablePadding: false,
        label: 'vehicles.assignedVehicles',
        needSorting: false
      },
      {
        id: 'document',
        label: 'common.documents'
      },
      {
        id: 'last_login',
        numeric: false,
        disablePadding: false,
        label: 'vehicles.lastLogin',
        needSorting: false
      },
      {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
        needSorting: true
      },
      {
        id: 'eld',
        numeric: false,
        disablePadding: false,
        label: 'ELD',
        needSorting: false
      },
      {
        id: 'actions',
        label: ''
      }
    ]
  }
];

export const vehicleStatusViewMore = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'dashboard.tabs.vehicleStatus',
    children: {
      name: './TopActions',
      props: ['vehicleStatus', 'filterSuccess', 'setPagination'],
      commonProps: ['i18n', 'Styled'],
      title: 'topActions'
    }
  },
  {
    name: 'pages/web/VehicleStatusList/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'fetchVehicleStatus',
      'vehicleStatus',
      'filterSuccess',
      'setPagination'
    ],
    commonProps: ['Styled', 'i18n', 'history', 'theme'],
    title: 'component',
    topAction: false
  }
];

export const driverStatusViewMore = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'dashboard.tabs.driverStatus.heading',
    children: {
      name: './TopActions',
      props: [
        'driverStatusList',
        'changeRequest',
        'filterSuccess',
        'request',
        'pagination',
        'setPagination'
      ],
      commonProps: ['i18n', 'Styled'],
      title: 'topActions'
    }
  },
  {
    name: 'pages/web/DriverStatusViewMore/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'fetchDriverStatus',
      'list',
      'driverStatusList',
      'getDriverStatus',
      'refreshDriverStatusMap',
      'driverStatusFilteredList',
      'filterSuccess',
      'changeRequest',
      'request',
      'pagination',
      'setPagination'
    ],
    commonProps: ['Styled', 'i18n', 'history', 'theme'],
    title: 'component',
    topAction: false
  }
];

export const potentialViolationsViewMore = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'potentialViolations.pageHeading',
    vertical: true,
    children: {
      name: 'components/modules/DataTableWithSearchExport/TopActions',
      props: [
        'filterSuccess',
        'columns',
        'exportAllData',
        'list',
        'exportCsvData',
        'exportPdfData',
        'logBook',
        'pagination',
        'setPagination'
      ],
      commonProps: ['i18n'],
      title: 'topActions',
      apiIds: {
        export: 'exportAllPotentialViolationsData',
        list: 'potentialViolations',
        delete: 'deletePotentialViolations'
      },
      fileName: 'Violations.pdf',
      needSearch: false,
      needFilter: true,
      needExportAll: true,
      isOldView: true
    }
  },
  {
    name: 'components/modules/DataTableWithSearchExport/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'list',
      'callback',
      'filterSuccess',
      'exportAllData',
      'exportCsvData',
      'exportPdfData',
      'deleteData',
      'logBook',
      'getDocumentToken',
      'columns',
      'pagination',
      'setPagination'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled', 'emProps'],
    title: 'component',
    apiIds: {
      export: 'exportAllPotentialViolationsData',
      list: 'potentialViolations',
      delete: 'deletePotentialViolations'
    },
    fileName: 'Violations.pdf',
    needSearch: false,
    needFilter: true,
    needExportAll: true,
    isOldView: true
  }
];

export const policyEdit = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'policies.heading'
  },
  {
    name: 'pages/web/Policies/Component',
    props: [
      'clearApiStatus',
      'submitPoliciesAndProcedures',
      'policies',
      'uploadLogo',
      'apiStatus',
      'downloadDocument',
      'policyDownload',
      'logBook',
      'deleteLogo',
      'clearState'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled', 'emPolicyEdit'],
    title: 'component'
  }
];

export const dailyDocuments = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'common.manageDailyDocuments',
    vertical: true,
    children: {
      name: './TopActions',
      props: [
        'callback',
        'apiStatus',
        'fetchDailyDocuments',
        'callback',
        'drivers',
        'currentDriver',
        'saveDocument',
        'dailyDocuments',
        'setCurrentDriver',
        'setModalObject',
        'ModalObject',
        'closeModal',
        'clickedModal',
        'open',
        'setOpen',
        'handleDeleteAlert',
        'filterByParams',
        'setbackgroundModal',
        'withCloseIcon',
        'backgroundModal',
        'setwithCloseIcon',
        'setPagination',
        'pagination',
        'filterByDriverAndDate'
      ],
      commonProps: ['Styled', 'i18n', 'history'],
      title: 'topActions'
    }
  },
  {
    name: 'pages/web/DailyDocuments/Component',
    props: [
      'apiStatus',
      'dailyDocuments',
      'filterListSuccess',
      'fetchDailyDocuments',
      'setCurrentDriver',
      'setModalObject',
      'ModalObject',
      'closeModal',
      'clickedModal',
      'open',
      'setOpen',
      'filterEldSuccess',
      'viewReport',
      'setbackgroundModal',
      'withCloseIcon',
      'backgroundModal',
      'setwithCloseIcon',
      'handleDeleteAlert',
      'callback',
      'setPagination',
      'pagination',
      'filterByDriverAndDate'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component'
  }
];

export const fuelConsumptionReport = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'fuelConsumption.pageHeading',
    children: {
      name: './TopActions',
      props: [
           'fuelConsumption', 
            'filterSuccess', 
            'setPagination', 
            'filters',   
            
            'getVehicleListDropDown',
            'vehicleList',
            'driverList',
            'getDrivers',
            'getStateListData',
            'addEditFuelData',
            'callback',
          ],
      commonProps: ['Styled', 'i18n'],
      title: 'topActions'
    }
  },
  {
    name: 'pages/web/FuelConsumptionReport/Component',
    props: [
      'fuelConsumption',
      'filterSuccess',
      'pagination',
      'setPagination',
      'filters',

      'getVehicleListDropDown',
      'vehicleList',
      'driverList',
      'getDrivers',
      'getStateListData',
      'addEditFuelData',
      'callback',
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component'
  }
];

export const usDotManagementenabled = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'usDotManagement.pageHeading',
    children: {
      name: './TopActions',
      props: ['options', 'filterSuccess', 'usDotManagement', 'setPagination'],
      commonProps: ['Styled', 'i18n', 'history'],
      title: 'topActions'
    }
  },
  {
    name: 'pages/web/UsDotNoManagement/Component',
    props: [
      'usDotManagement',
      'filterSuccess',
      'handleDeleteAlert',
      'callback',
      'options',
      'setModalListObject',
      'closeModal',
      'clickedModal',
      'getStates',
      'pagination',
      'setPagination'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    topAction: false
  }
];

export const workOrders = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'workOrders.pageHeading',
    children: {
      name: './TopActions',
      props: ['filterSuccess', 'workOrders', 'setPagination'],
      commonProps: ['Styled', 'i18n', 'history'],
      title: 'topActions'
    }
  },
  {
    name: 'pages/web/WorkOrders/Component',
    props: [
      'workOrders',
      'filterSuccess',
      'Styled',
      'history',
      'viewReport',
      "changeRequest",
      "request",
      "filter",
      "setFilter",
      'setPagination',
      'pagination',
      'filters',
      'clearState'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    topAction: false
  }
];

export const departmentsList = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'common.departments',
    children: {
      name: './TopActions',
      props: [
        'isTermCrudRestricted',
        'callback',
        'setAddEditModalObject',
        'closeAddEditModal',
        'saveTerminal',
        'data',
        'getTmezonePopup',
        'isCrudRestricted',
        'clickedAddEditModal',
        'saveRegion',
        'closeAddEditModal',
        'isTermCrudRestricted',
        'getTmezonePopup',
        'isCrudRestricted',
        'isDeptCrudRestricted',
        'isLocCrudRestricted'
      ],
      commonProps: ['Styled', 'i18n'],
      title: 'topActions',
      isButtonStyle: true,
      isShowDotNumber: false
    }
  },
  {
    name: 'pages/web/Department/Component',
    props: [
      'apiStatus',
      'callback',
      'fetchTerminals',
      'data',
      'saveRegion',
      'updateRegion',
      'deleteRegion',
      'saveTerminal',
      'deleteTerminal',
      'updateTerminal',
      'saveDepartment',
      'updateDepartment',
      'deleteDepartment',
      'saveSubDepartment',
      'updateSubDepartment',
      'deleteSubDepartment',
      'setAddEditModalObject',
      'clickedAddEditModal',
      'closeAddEditModal',

      'isTermCrudRestricted',
      'getTmezonePopup',
      'isCrudRestricted',
      'isDeptCrudRestricted',
      'isLocCrudRestricted'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    topAction: false,
    isButtonStyle: true
  }
];

export const alertViewMore = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'common.alerts',
    vertical: true,
    children: {
      name: 'components/modules/DataTableWithSearchExport/TopActions',
      props: [
        'filterSuccess',
        'columns',
        'exportAllData',
        'list',
        'exportCsvData',
        'exportPdfData',
        'logBook',
        'getDocumentToken',
        'setPagination'
      ],
      commonProps: ['i18n'],
      title: 'topActions',
      needSearch: false,
      needFilter: true,
      needExportAll: true,
      fileName: 'Alerts.pdf',
      isOldView: true,
      apiIds: {
        export: 'exportAllAlertData',
        list: 'alertList',
        delete: 'deleteAlerts'
      }
    }
  },
  {
    name: 'components/modules/DataTableWithSearchExport/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'list',
      'callback',
      'columns',
      'logbook',
      'pagination',
      'setPagination',
      'deleteData'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled', 'emAlerts'],
    title: 'component',
    apiIds: {
      export: 'exportAllAlertData',
      list: 'alertList',
      delete: 'deleteAlerts'
    },
    fileName: 'Alerts.pdf',
    needSearch: false,
    needFilter: true,
    needExportAll: true,
    topAction: false,
    isOldView: true
  }
];

export const inspections = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'inspections.listHeading',
    vertical: true,
    children: {
      name: './TopActions',
      props: [
        'isAdvancedDviRequired',
        'isCustomDviRequired',
        'list',
        'bulkDownloadReport',
        'apiStatus',
        'clearApiStatus',
        'callback',
        'changeRequest',
        'hasApiStatus',
        'filters',
        'request',
        'setPagination',
        'pagination'
      ],
      commonProps: ['Styled', 'i18n', 'history'],
      title: 'topActions'
    }
  },
  {
    name: 'pages/web/Inspections/Component',
    props: [
      'apiStatus',
      'callback',
      'fetchInspections',
      'bulkDownloadReport',
      'inspections',
      'list',
      'changeRequest',
      'isAdvancedDviRequired',
      'isCustomDviRequired',
      'logBook',
      'clearApiStatus',
      'getDocumentToken',
      'filters',
      'viewReport',
      'request',
      'setPagination',
      'pagination'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    type: 'inspections',
    topAction: false
  }
];

export const subscriptionLink = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'SUBSCRIPTION LIST',
    children: {
      name: './TopActions',
      props: [
        'subscriptionTier',
        'setSubscription',
        'apiId',
        'callback',
        'upgrade'
      ],
      commonProps: ['Styled', 'i18n'],
      title: 'topActions'
    }
  },
  {
    name: 'pages/web/Subscriptions/Component',
    props: [
      'fetchSubscriptions',
      'subscriptions',
      'subscriptionTier',
      'setSubscription',
      'apiId',
      'callback',
      'upgrade'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    type: 'subscriptions',
    topAction: false,
    isPrimeNeeded: true
  }
];

export const login = [
  {
    name: 'pages/web/Login/Component',
    props: ['apiStatus', 'login', 'fetchLogin'],
    commonProps: ['Styled', 'i18n', 'history'],
    hasRegistration: false,
    innerComponent: 'pages/web/Login/RenderComponent'
  }
];

export const advancedDvirEnabled = Base.advancedDvirEnabled;
export const refillsHistory = Base.refillsHistory;
export const companyDocument = Base.companyDocument;
export const MFAPage = Base.MFAPage;
export const recovery = Base.recovery;
export const addWorkOrders = Base.addWorkOrders;
export const editWorkOrder = Base.editWorkOrder;
// export const privacyPolicy = Base.privacyPolicy;
export const reportMileage = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'iftaMileage.listHeading'
  },
  {
    name: 'pages/web/IFTAMileage/Component',
    props: [
      'apiStatus',
      'callback',
      'history',
      'fetchIftaMileage',
      'getIftaAuditCSV',
      'ifta',
      'filterList',
      'fetchVehicles',
      'vehicles',
      'logBook',
      'getDocumentToken',
      'handleExportIfta',
      'generateReport'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    isOldView: true
  }
];
export const logBook = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'logs.logBook',
    children: {
      name: './TopActions',
      props: [
        'apiStatus',
        'fetchLogBookView',
        'viewApiId',
        'history',
        'callback',
        'drivers',
        'dates',
        'unSignedDates',
        'logBook',
        'currentDriver',
        'fetchLogbookBreadcrumb',
        'fetchLogbookBreadcrumbApiId',
        'setFormValues',
        'formValues',
        'setNewDate',
        'getDocumentToken',
        'handleLogBookStatus',
        'clearApiStatus',
        'dailyLogDownloadData',
        'newDate',
        'setNewDate',
        'regeneratePdf'
      ],
      commonProps: ['Styled', 'i18n'],
      title: 'topActions'
    }
  },
  {
    name: 'pages/web/LogBook/Component',
    props: [
      'apiStatus',
      'logBook',
      'callback',
      'handleLogBookStatus',
      'clearApiStatus',
      'breadCrumbForMap',
      'getLogbookEvents',
      'updateShowEvents',
      'fetchLogBookView',
      'dailyLogDownloadData',
      'changedDate',
      'fetchLogbookBreadcrumb',
      'getDocumentToken',
      'formValues',
      'setFormValues',
      'currentDriver',
      'dates',
      'drivers',
      'newDate',
      'setNewDate',
      'regeneratePdf',
    ],
    title: 'component',
    topAction: false,
    commonProps: ['Styled', 'i18n', 'history', 'theme']
  }
];
export const addUser = Base.addUser;
export const editUser = Base.editUser;
export const logsView = Base.logsView;
export const addVehicle = Base.addVehicle;
export const editVehicle = Base.editVehicle;
export const profile = Base.profile;
export const resetPassword = Base.resetPassword;
export const accidentsList = Base.accidentsList;
export const reportsEnabled = Base.reportsEnabled;
export const unhandled = Base.unhandled;
export const logsEdit = Base.logsEdit;
export const editLog = Base.editLog;
export const editLog1 = Base.editLog1;
export const customDvirEnabled = Base.customDvirEnabled;
export const addOrEditNewForm = Base.addOrEditNewForm;
export const addOrEditNewFormTrailer = Base.addOrEditNewFormTrailer;
// export const logBook = Base.logBook;
export const logsList = [
  {
    name: 'components/modules/LogsManagement/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'list',
      'callback',
      'filters',
      'filterSuccess',
      'handleLogBookStatus',
      'clearApiStatus',
      'viewReport',
      'setPagination',
      'pagination'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    apiIds: {
      export: 'exportAllPotentialViolationsData',
      list: 'potentialViolations',
      delete: 'deletePotentialViolations'
    },
    fileName: 'Violations.pdf',
    needSearch: false,
    needFilter: true,
    needExportAll: true,
    showExtraOptions: true,
    header: 'logs.manageLogsHeader',
    verticalTab: false,
    columns: [
      {
        id: 'driver_name',
        numeric: false,
        disablePadding: true,
        label: 'common.driver',
        needSorting: true
      },
      {
        id: 'vehicle_name',
        numeric: false,
        disablePadding: false,
        label: 'common.vehicle',
        needSorting: true
      },
      {
        id: 'date',
        numeric: false,
        disablePadding: false,
        label: 'common.date',
        needSorting: true
      },
      {
        id: 'hours',
        numeric: true,
        disablePadding: false,
        label: 'common.hoursWorked',
        needSorting: true
      },
      {
        id: 'distance',
        numeric: true,
        disablePadding: false,
        label: 'common.distance',
        needSorting: true
      },
      {
        id: 'cycleRule',
        numeric: false,
        disablePadding: false,
        label: 'common.cycleRule',
        needSorting: true
      },
      {
        id: 'actions',
        label: 'common.tools'
      }
    ]
  },
  {
    name: 'components/modules/LogsManagement/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'list',
      'callback',
      'filters',
      'filterSuccess',
      'handleLogBookStatus',
      'clearApiStatus',
      'setPagination',
      'pagination'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    apiIds: {
      export: 'exportAllPotentialViolationsData',
      list: 'potentialViolations',
      delete: 'deletePotentialViolations'
    },
    fileName: 'Violations.pdf',
    needSearch: false,
    needFilter: true,
    needExportAll: true,
    header: 'logs.manageLogsHeader',
    verticalTab: false,
    columns: [
      {
        id: 'driver_name',
        numeric: false,
        disablePadding: true,
        label: 'common.driver',
        needSorting: true
      },
      {
        id: 'vehicle_name',
        numeric: false,
        disablePadding: false,
        label: 'common.vehicle',
        needSorting: true
      },
      {
        id: 'date',
        numeric: false,
        disablePadding: false,
        label: 'common.date',
        needSorting: true
      },
      {
        id: 'hours',
        numeric: true,
        disablePadding: false,
        label: 'common.hoursWorked',
        needSorting: true
      },
      {
        id: 'distance',
        numeric: true,
        disablePadding: false,
        label: 'common.distance',
        needSorting: true
      },
      {
        id: 'cycleRule',
        numeric: false,
        disablePadding: false,
        label: 'common.cycleRule',
        needSorting: true
      },
      {
        id: 'actions',
        label: 'common.tools'
      }
    ]
  }
];
export const logsUdList = [
  {
    name: 'pages/web/UndefinedDriverList/Component',
    props: [
      'apiStatus',
      'udLogList',
      'unAssignedEntries',
      'fetchUdLogList',
      'fetchUnAssignedEntries',
      'clearUdAssignEntryListById',
      'setUdAssignEntryList',
      'saveUnAssignedEntries',
      'callback',
      'udFilterSuccess',
      'udSetSelectedRow',
      'filters',
      'clearAll'
    ],
    commonProps: ['history', 'i18n', 'Styled'],
    title: 'component',
    verticalTab: false,
    showExtraOptions: true
  }
];
export const logsHistory = Base.logsHistory;

export const hardAcceleration = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'dashboard.tabs.driverBehavior',
    vertical: true,
    children: {
      name: './TopActions',
      props: [
        'apiStatus',
        'callback',
        'clearApiStatus',
        'getDrivers',
        'chat',
        'fetchDriverBehaviorDetails',
        'fetchChartData',
        'fetchTableData',
        'driverList'
      ],
      commonProps: ['Styled', 'i18n'],
      title: 'topActions'
    }
  },
  {
    topAction: false,
    name: 'pages/web/HardAcceleration/Component',
    props: [
      'apiStatus',
      'callback',
      'clearApiStatus',
      'getDrivers',
      'chat',
      'fetchDriverBehaviorDetails',
      'fetchChartData',
      'fetchTableData'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component'
  }
];

export const hardDeceleration = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'dashboard.tabs.driverBehavior',
    vertical: true,
    children: {
      name: './TopActions',
      props: [
        'apiStatus',
        'callback',
        'clearApiStatus',
        'getDrivers',
        'chat',
        'fetchDriverBehaviorDetails',
        'fetchChartData',
        'fetchTableData',
        'driverList'
      ],
      commonProps: ['Styled', 'i18n'],
      title: 'topActions'
    }
  },
  {
    topAction: false,
    name: 'pages/web/HardDeceleration/Component',
    props: [
      'apiStatus',
      'callback',
      'clearApiStatus',
      'getDrivers',
      'chat',
      'fetchDriverBehaviorDetails',
      'fetchChartData',
      'fetchTableData'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component'
  }
];

export const speeding = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    vertical: true,
    header: 'dashboard.tabs.driverBehavior',
    children: {
      name: './TopActions',
      props: [
        'apiStatus',
        'callback',
        'clearApiStatus',
        'getDrivers',
        'chat',
        'fetchDriverBehaviorDetails',
        'fetchChartData',
        'fetchTableData',
        'driverList'
      ],
      commonProps: ['Styled', 'i18n'],
      title: 'topActions'
    }
  },
  {
    topAction: false,
    name: 'pages/web/Speeding/Component',
    props: [
      'apiStatus',
      'callback',
      'clearApiStatus',
      'getDrivers',
      'chat',
      'fetchDriverBehaviorDetails',
      'fetchChartData',
      'fetchTableData'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component'
  }
];

export const driveHrsExceeding = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'dashboard.tabs.driverBehavior',
    vertical: true,
    children: {
      name: './TopActions',
      props: [
        'apiStatus',
        'callback',
        'clearApiStatus',
        'getDrivers',
        'chat',
        'fetchDriverBehaviorDetails',
        'fetchChartData',
        'fetchTableData',
        'driverList'
      ],
      commonProps: ['Styled', 'i18n'],
      title: 'topActions'
    }
  },
  {
    topAction: false,
    name: 'pages/web/DriveHrsExceeding/Component',
    props: [
      'apiStatus',
      'callback',
      'clearApiStatus',
      'getDrivers',
      'chat',
      'fetchDriverBehaviorDetails',
      'fetchChartData',
      'fetchTableData'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component'
  }
];

export const fuelEconomy = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'dashboard.tabs.driverBehavior',
    vertical: true,
    children: {
      name: './TopActions',
      props: [
        'apiStatus',
        'callback',
        'clearApiStatus',
        'getDrivers',
        'chat',
        'fetchDriverBehaviorDetails',
        'fetchChartData',
        'fetchTableData',
        'driverIdSuggestion'
      ],
      commonProps: ['Styled', 'i18n'],
      title: 'topActions'
    }
  },
  {
    topAction: false,
    name: 'pages/web/FuelEconomy/Component',
    props: [
      'apiStatus',
      'callback',
      'clearApiStatus',
      'getDrivers',
      'chat',
      'fetchDriverBehaviorDetails',
      'fetchChartData',
      'fetchTableData'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component'
  }
];

export const seatBeltWarning = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'dashboard.tabs.driverBehavior',
    vertical: true,
    children: {
      name: './TopActions',
      props: [
        'apiStatus',
        'callback',
        'clearApiStatus',
        'getDrivers',
        'chat',
        'fetchDriverBehaviorDetails',
        'fetchChartData',
        'fetchTableData',
        'driverList'
      ],
      commonProps: ['Styled', 'i18n'],
      title: 'topActions'
    }
  },
  {
    topAction: false,
    name: 'pages/web/SeatBeltWarning/Component',
    props: [
      'apiStatus',
      'callback',
      'clearApiStatus',
      'getDrivers',
      'chat',
      'fetchDriverBehaviorDetails',
      'fetchChartData',
      'fetchTableData'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component'
  }
];

export const hardTurn = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'dashboard.tabs.driverBehavior',
    vertical: true,
    children: {
      name: './TopActions',
      props: [
        'apiStatus',
        'callback',
        'clearApiStatus',
        'getDrivers',
        'chat',
        'fetchDriverBehaviorDetails',
        'fetchChartData',
        'fetchTableData',
        'driverList'
      ],
      commonProps: ['Styled', 'i18n'],
      title: 'topActions'
    }
  },
  {
    topAction: false,
    name: 'pages/web/HardTurn/Component',
    props: [
      'apiStatus',
      'callback',
      'clearApiStatus',
      'getDrivers',
      'chat',
      'fetchDriverBehaviorDetails',
      'fetchChartData',
      'fetchTableData'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component'
  }
];

export const userDriverStatus = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: '',
    children: {
      name: './TopActions',
      props: [
        'changeRequest',
        'Styled',
        'i18n',
        'setPagination',
        'pagination',
        'request'
      ],
      commonProps: ['Styled', 'i18n'],
      title: 'topActions'
    }
  },
  {
    name: 'pages/web/DriverStatus/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'driverStatus',
      'changeRequest',
      'match',
      'pagination',
      'setPagination',
      'pagination',
      'request'
    ],
    commonProps: ['Styled', 'i18n', 'history', 'theme'],
    title: 'component',

    columns: [
      {
        id: 'full_name',
        numeric: false,
        disablePadding: false,
        needSorting: true,
        label: 'vehicles.fullName'
      },
      {
        id: 'hire_date',
        numeric: true,
        disablePadding: false,
        needSorting: true,
        label: 'common.hireDate'
      },
      {
        id: 'email',
        numeric: false,
        disablePadding: false,
        needSorting: true,
        label: 'vehicles.email'
      },
      {
        id: 'empId',
        numeric: true,
        disablePadding: false,
        needSorting: true,
        label: 'vehicles.empId'
      },
      {
        id: 'phone',
        numeric: true,
        disablePadding: false,
        label: 'common.phone'
      },
      {
        id: 'assigned_vehicles',
        numeric: false,
        disablePadding: false,
        label: 'vehicles.assignedVehicles'
      },
      {
        id: 'joined',
        numeric: false,
        disablePadding: false,
        label: 'users.joined'
      },
      {
        id: 'status',
        numeric: false,
        disablePadding: false,
        needSorting: true,
        label: 'logs.status'
      }
    ]
  }
];

export const vehicleNotInUse = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: '',
    remainingHeader: true,
    children: {
      name: './TopActions',
      props: ['filterSuccess', 'notInUseVehicles', 'setPagination'],
      commonProps: ['Styled', 'i18n'],
      title: 'topActions'
    }
  },
  {
    name: 'pages/web/NotInUseVehicles/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'notInUseVehicles',
      'filterSuccess',
      'pagination',
      'setPagination'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component'
  }
];

export const outOfService = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: '',
    remainingHeader: true,
    children: {
      name: './TopActions',
      props: ['filterSuccess', 'notInUseVehicles', 'setPagination'],
      commonProps: ['Styled', 'i18n'],
      title: 'topActions'
    }
  },
  {
    name: 'pages/web/OutOfService/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'notInUseVehicles',
      'filterSuccess',
      'pagination',
      'setPagination'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component'
  }
];

export const driverStatusHistory = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'users.driverStatusHistory',
    children: {
      name: 'components/modules/DataTableWithSearchExport/TopActions',
      props: [
        'filterSuccess',
        'exportAllData',
        'list',
        'exportCsvData',
        'exportPdfData',
        'logBook',
        'setPagination'
      ],
      commonProps: ['i18n'],
      title: 'topActions',
      apiIds: {
        export: 'exportAllDriverStatusHistoryData',
        list: 'driverStatusHistory'
      },
      fileName: 'DriverStatusHistory.pdf',
      needSearch: true,
      needFilter: false,
      needExportAll: false,
      isOldView: true,
      defaultSortBy: { sortBy: 'truck_id', order: 'desc' },
      columns: [
        {
          id: 'truck_id',
          numeric: true,
          disablePadding: false,
          label: 'common.truckId',
          needSorting: true
        },
        {
          id: 'geo_location',
          numeric: false,
          disablePadding: true,
          label: 'common.geocodedLocation',
          needSorting: true
        },
        {
          id: 'driver_Status',
          numeric: false,
          disablePadding: false,
          label: 'dashboard.tabs.driverStatus.heading',
          needSorting: true
        },
        {
          id: 'engine_status',
          numeric: false,
          disablePadding: false,
          label: 'common.mph',
          needSorting: true
        },

        {
          id: 'time',
          numeric: false,
          disablePadding: false,
          label: 'common.updatedTime',
          needSorting: true
        }
      ]
    }
  },
  {
    name: 'components/modules/DataTableWithSearchExport/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'fetchDriverStatusHistory',
      'callback',
      'list',
      'filterSuccess',
      'pagination',
      'setPagination'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    needFilter: false,
    needExportAll: false,
    noAction: true,
    needSearch: true,
    apiIds: {
      export: 'exportAllDriverStatusHistoryData',
      list: 'driverStatusHistory'
    },
    fileName: 'DriverStatusHistory.pdf',

    columns: [
      {
        id: 'truck_id',
        numeric: true,
        disablePadding: false,
        label: 'common.truckId',
        needSorting: true
      },
      {
        id: 'geo_location',
        numeric: false,
        disablePadding: true,
        label: 'common.geocodedLocation',
        needSorting: true
      },
      {
        id: 'driver_Status',
        numeric: false,
        disablePadding: false,
        label: 'dashboard.tabs.driverStatus.heading',
        needSorting: true
      },
      {
        id: 'engine_status',
        numeric: false,
        disablePadding: false,
        label: 'common.mph',
        needSorting: true
      },

      {
        id: 'time',
        numeric: false,
        disablePadding: false,
        label: 'common.updatedTime',
        needSorting: true
      }
    ],
    topAction: false,
    isOldView: true,
  }
];

export const csaViolation = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'csaViolation.listHeading',
    vertical: true,
    children: {
      name: './TopActions',
      props: [
        'apiStatus',
        'callback',
        'hasApiStatus',
        'changeRequest',
        'handleExportCsaViolations',
        'request',
        'setPagination',
        'pagination'
      ],
      commonProps: ['Styled', 'i18n'],
      title: 'topActions'
    }
  },
  {
    name: 'pages/web/CSAViolation/Component',
    props: [
      'apiStatus',
      'changeRequest',
      'csaViolations',
      'hasApiStatus',
      'handleExportCsaViolations',
      'request',
      'setPagination',
      'pagination'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    topAction: false
  }
];

export const companyDataEdit = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'companyData.pageHeading',
    children: {
      name: './TopActions',
      props: [],
      commonProps: ['Styled', 'i18n', 'history'],
      title: 'topActions'
    }
  },
  {
    name: 'pages/web/CompanyData/Component',
    props: [
      'apiStatus',
      'callback',
      'getCompanyData',
      'saveCompanyData',
      'cancelSubscription',
      'updateCard',
      'updateCardDetails',
      'states',
      'allCycleRules',
      'allCycleExceptions',
      'allVehicleTypes',
      'companyData'
    ],
    innerComponent: 'pages/web/CompanyData/RenderComponent',
    commonProps: ['Styled', 'i18n', 'history', 'theme'],
    title: 'component',
    topAction: false
  }
];

export const paymentInformation = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    vertical: true,
    header: 'paymentInformation.listHeading',
    children: {
      name: './TopActions',
      props: ['paymentInformation'],
      commonProps: ['Styled', 'i18n', 'history'],
      title: 'topActions'
    }
  },
  {
    name: 'pages/web/PaymentInformation/Component',
    props: [
      'apiStatus',
      'receipt',
      'downloadReceipt',
      'fetchPaymentInformation',
      'paymentInformation',
      'clearState'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    topAction: false
  }
];

export const privacyPolicy = [
  {
    name: 'pages/web/PrivacyPolicy/tp',
    props: [],
    commonProps: [],
    title: 'component'
  }
];

export const partsInventoryEnabled = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'workOrders.manageParts',
    children: {
      name: './TopActions',
      props: [
        'partsList',
        'fetchParts',
        'saveParts',
        'callback',
        'apiStatus',
        'changeRequest',
        'request'
      ],
      commonProps: ['Styled', 'i18n', 'history'],
      title: 'topActions'
    }
  },
  {
    name: 'pages/web/Parts/Component',
    props: [
      'apiStatus',
      'partsList',
      'fetchParts',
      'filters',
      'saveParts',
      'callback',
      'changeRequest',
      'handleDeleteAlert',
      'updateParts',
      'getFilters',
      'request'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    topAction: false
  }
];

export const subscriptionMessage = Base.subscriptionMessage;
