import React, { lazy } from 'react';
import { useTranslation } from 'react-i18next';

import { pageUrls } from '../../../config/constants/keys';
import useTheme from '@material-ui/core/styles/useTheme';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Link = lazy(() => import(`../../UI/Link`));

const Footer = (props) => {
  const i18n = useTranslation();
  const theme = useTheme();
  const { FooterContainer, FooterContacts, FooterCopy } = props;
  return (
    <FooterContainer style={{ ...theme.palette.footerTheme }}>
      <FooterContacts>
        <div
          className="footerContactItems"
          style={{ ...theme.palette.footerPadding }}
        >
          {window.$environment?.SERVICE_PROVIDER !== 'trackon' && (
            <div
              className="footerContactsAddress flexDiv"
              style={{ ...theme.palette.footerContact }}
            >
              <div className="addressImg"></div>
              <div
                className="textFooter"
                style={{ ...theme.palette.footerAlign }}
              >
                <span>{i18n.t('footer.address.one')}:</span>
                {i18n.t('footer.address.onedetails')}
              </div>
            </div>
          )}
          <div className="footerContactsPhone flexDiv">
            <div className="phoneImg"></div>
            <div
              className="textFooter"
              style={{ ...theme.palette.footerAlign }}
            >
              <span>{i18n.t('footer.address.two')}:</span>
              {i18n.t('footer.address.twodetails')}
            </div>
          </div>
          <div className="footerContactsEmail flexDiv">
            <div className="emailImg"></div>
            <div
              className="textFooter"
              style={{ ...theme.palette.footerAlign }}
            >
              <span>{i18n.t('footer.address.three')}:</span>
              <a href={`mailto:${i18n.t('footer.address.threedetails')}`}>
                {i18n.t('footer.address.threedetails')}
              </a>
            </div>
          </div>
        </div>
      </FooterContacts>
      <FooterCopy style={{ ...theme.palette.footerWidth }}>
        <span className="patentText">{i18n.t('footer.patent')}</span>
        <span className="patentText">{i18n.t('footer.copyright')}</span>
        {/* {window.$environment?.SERVICE_PROVIDER === 'tp' ||
        window.$environment?.SERVICE_PROVIDER === 'em' ? (
          <Link
            className="normalLink"
            id="privacyCheck"
            href={pageUrls.privacyPolicy}
            newPage
          >
            {i18n.t('common.privacyPolicy')}
          </Link>
        ) :  */}
        {window.$environment?.SERVICE_PROVIDER !== 'trackon' && (
          <Link
            className="normalLink"
            id="privacyCheck"
            href={pageUrls.privacyPolicy}
            newPage
          >
            {i18n.t('footer.PrivacyLegal')}
          </Link>
        )}
      </FooterCopy>
    </FooterContainer>
  );
};

export default Footer;
