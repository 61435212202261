import PropTypes from 'prop-types';
import React, { lazy, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import RenderComponent from '@/components/modules/DynamicRenderPage';

import { hasValue, loadPageConfig, loadStyle, removeEmptyValues } from '@/utils/utils';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import ParentStyled from './style';
let Styled = loadStyle(ParentStyled, 'pages/web/Camera');

const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.cameraEvents[0].name}`));
const Content = lazy(() => import(`@/${config.cameraEvents[1].name}`));

const CameraEvents = (parentProps) => {
  const {
    history,
    i18n,
    theme,
    getVehicles,
    vehicleList,
    fetchEventsByTotalCount,
    allEventData,
    apiStatus
  } = parentProps;
  const { id } = useParams();
  const commonParentProps = { history, i18n, Styled, theme };

  const basicRequest = {
    displayStart: 1,
    displayLength: 10,
  };

  const [request, setRequest] = useState({
    ...basicRequest
  });

  const sortColumnMapping = {
    vehicle: 'vehicleName',
    count: 'eventCount'
  }

  const changeRequest = (fields, dataUsageFlg) => {
    if (fields?.sortCol) {
      const type = fields.sortCol
      fields.sortCol = sortColumnMapping[type] ? sortColumnMapping[type] : fields.sortCol
    }

    setRequest({ ...basicRequest, ...fields });
  };

  const apiId = "eventsTotalCount";
  const hasSaveApiStatus = hasValue(apiStatus.status, 'api', apiId);


  useEffect(() => {
    let page = Math.round((request.displayStart / 10) + 1)
    const data = removeEmptyValues({
      companyId: parseInt(id),
      vehicleId: parseInt(request?.vehicle),
      pageNumber: page - 1,
      pageSize: request?.displayLength,
      sortCol: request?.sortCol,
      sortDir: request?.sortDir
    })
    fetchEventsByTotalCount({
        apiId,
        data: {  ...data  },
      });
      

  }, [request]);


  const [pagination, setPagination] = useState(false);

  useEffect(() => {
    !vehicleList.length && id && getVehicles({ apiId: 'getVehicles', data: { id } })
  }, [])

  return (
    <>
      <RenderComponent
        {...config.cameraEvents[0]}
        parentProps={{
          ...parentProps,
          pagination,
          setPagination
        }}
        commonParentProps={commonParentProps}
        component={Title}
      />
      <RenderComponent
        {...config.cameraEvents[1]}
        parentProps={{
          ...parentProps,
          changeRequest,
          request,
        }}
        commonParentProps={commonParentProps}
        component={Content}
        allEventData={allEventData}
        pagination={pagination}
        setPagination={setPagination}
        vehicleList={vehicleList}
        hasSaveApiStatus={hasSaveApiStatus}
      />
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    filters: state.filters,
    vehicleList: state.vehicles.fleetVehicleList,
    allEventData: state.cameraEvents?.cameraTotalEventData,
  };
};
const mapDispatch = (dispatch) => {
  return {
    getVehicles: (data) => dispatch.vehicles.fetchFleetData(data),
    fetchEventsByTotalCount: (data) => dispatch.cameraEvents.getCameraEventsByTotalCount(data),
  };
};

export default connect(mapState, mapDispatch)(CameraEvents);

CameraEvents.propTypes = {
  callback: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
};
