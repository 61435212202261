import { UI_DATE_FORMAT } from '@/config/constants/static';
import { getDateLocale } from '@/utils/utils';
import { Field } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { lazy } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';


const layout = window?.$environment?.CURRENT_LAYOUT;
const Select = lazy(() => import(`@/components/UI/Select/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Input = lazy(() => import(`@/components/UI/Input/${layout}`));

const RenderComponent = ({
  values,
  handleEdit,
  validateForm,
  customProps,
  submitAction,
  cancelAction,
}) => {
  const { i18n } = useTranslation();

  return (
    <>
      <FormStyle.default.FieldsWrapper className="csafield vertical">
        <Label variant="body1" htmlFor="vehicle">
          {i18n.t('cameraEvents.eventsTypes')}
        </Label>
        <FormStyle.default.TextWrapper>
          <Field
            name="eventType"
            onChange={(event) => handleEdit(event, { field: 'eventType' })}
            as={Select}
            placeholder={i18n.t('common.pleaseSelect')}
            value={values['eventType']}
            suggestions={customProps.eventTypes}
          />
          <Message type="error" message={customProps.validationError} />
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.FieldsWrapper className="csafield vertical">
        <FormStyle.default.TextWrapper>
          <Label variant="body1" htmlFor="eventType">
            {i18n.t('camera.startDate')}
          </Label>
          <Field
            name="startDate"
            onChange={(event) => {
              handleEdit(event, {
                field: 'startDate',
              });

              customProps.setInitialValues({
                ...values,
                startDate: moment(event).format(
                  UI_DATE_FORMAT 
                )
              });
            }}
            locale={getDateLocale()}
            as={ReactDatePicker}
            placeholderText={"MM/DD/YYYY"}
            value={values['startDate']}
            maxDate={
              values.endDate ? new Date(values.endDate) : '01/01/3000'
            }
            type="date"
            showPopperArrow={false}
            showTimeSelect={false}
            showDisabledMonthNavigation
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            isClearable={true}
          />
          <Message type="error" message={customProps.startDateValidationError} />
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.FieldsWrapper className="csafield vertical">
        <FormStyle.default.TextWrapper>
          <Label variant="body1" htmlFor="eventType">
            {i18n.t('camera.endDate')}
          </Label>
          <Field
            name="endDate"
            onChange={(event) => {
              handleEdit(event, {
                field: 'endDate',
              });

              customProps.setInitialValues({
                ...values,
                endDate: moment(event).format(
                  UI_DATE_FORMAT 
                )
              });
            }}
            locale={getDateLocale()}
            as={ReactDatePicker}
            placeholderText={"MM/DD/YYYY"}
            value={values['endDate']}
            type="date"
            showPopperArrow={false}
            showTimeSelect={false}
            showDisabledMonthNavigation
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            isClearable={true}
            minDate={values?.startDate ? new Date(values.startDate) : null}
          />
          <Message type="error" message={customProps.endDateValidationError} />
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.ButtonsWrapper className="vertical-button">
        <Button
          type="submit"
          label={i18n.t('common.view')}
          onClick={(e, d) => {
            const validateResponse = validateForm();
            validateResponse.then((data) => {
              submitAction(data);
            });
          }}
        />
        <Button
          className=""
          type={'reset'}
          label={i18n.t('common.clear')}
          onClick={() => {
            cancelAction();
          }}
        />
      </FormStyle.default.ButtonsWrapper>
    </>
  );
};

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  hasApiStatus: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};

export default RenderComponent;
