import React, { useState, lazy, useEffect } from 'react';
import PropTypes from 'prop-types';
import RenderComponent from './RenderComponent';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));

const Filters = ({
    hasApiStatus,
    callback,
    history,
    severityList,
    eventsList,
    filterList,
    handleReset,
    values, setInitialValues
}) => {

    const [validationError, setValidationError] = useState('');

    return (
        <>
            <Form
                type="server"
                direction="row"
                initialValues={{ ...values }}
                className="CSAForms"
                RenderComponent={RenderComponent}
                handleOnSubmit={(fields) => {
                    setValidationError('');
                    const finalData = JSON.parse(JSON.stringify(fields));
                    Object.keys(finalData).forEach((key) => {
                        finalData[key] = finalData[key]?.value || null
                    });
                    filterList(finalData);
                }}
                handleOnCancel={(fields) => {
                    handleReset();
                    setInitialValues({
                        severityId: null
                    });
                    setValidationError('');
                }}
                customProps={{
                    history,
                    callback,
                    hasApiStatus,
                    validationError,
                    severityList,
                    eventsList,
                    setInitialValues
                }}
            />
        </>
    );
};

Filters.propTypes = {
    apiStatus: PropTypes.object.isRequired,
    callback: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
};

export default Filters;
