import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import RenderComponent from './RenderComponent';

import {
  getListOfSelectedField,
  objectArrayFromMultipleFields
} from '@/utils/utils';

import Styled from './style';
const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));

const Component = ({
  closeModal,
  saveDepartment,
  callback,
  currentItem,
  data,
  isEdit,
  commonProps,
  isCrudRestricted,
  neglectAccessLevel
}) => {
  const { i18n } = useTranslation();
  const saveDepartmentApi = 'saveDepartmentApi';
  const fleetmanagerCallback = (item, value, field) => {
    if (currentItem?.fleetManagerIds?.includes(item[value]))
      return {
        label: `${item[field[0]]} ${item[field[1]]}`,
        value: item[value],
        disabled: true
      };
    else
      return {
        label: `${item[field[0]]} ${item[field[1]]}`,
        value: item[value]
      };
  };
  const fleetManagerList = objectArrayFromMultipleFields(
    data?.data?.fleetManagers,
    'id',
    ['firstName', 'lastName'],
    fleetmanagerCallback
  );
  return (
    <Styled.Wrapper>
      <Form
        type="server"
        direction="column"
        initialValues={{
          departmentId: currentItem?.departmentId,
          terminal:
            currentItem?.terminalName ||
            data?.data?.terminalDtos.filter(
              (item) => item.terminalId === currentItem?.terminalId
            )[0]?.terminalName,
          terminalId: currentItem?.terminalId || '',
          departmentName: currentItem?.departmentName || '',
          fleetManagerIds: currentItem?.departmentId
            ? fleetManagerList?.filter((item) =>
                currentItem?.fleetManagerIds?.includes(item.value)
              ) || []
            : []
        }}
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {
          let finalData = {
            terminalId: fields?.terminalId || null,
            departmentName: fields.departmentName || null
          };
          if (fields?.fleetManagerIds?.length) {
            finalData.fleetManagerIds =
              getListOfSelectedField(fields.fleetManagerIds, 'value') || null;
          }
          if (fields?.departmentId)
            finalData['departmentId'] = fields.departmentId;
          saveDepartment({
            apiId: saveDepartmentApi,
            data: {
              ...finalData
            },
            callback,
            i18n: i18n
          });
          closeModal();
        }}
        customProps={{
          fleetManagerList,
          isEdit,
          isCrudRestricted,
          commonProps,
          neglectAccessLevel
        }}
        handleClear={() => {}}
        validationSchema={Yup.object().shape({
          departmentName: Yup.string().required(
            i18n.t('common.fieldRequiredMessage')
          )
        })}
      />
    </Styled.Wrapper>
  );
};

export default Component;

Component.propTypes = {
  apiStatus: PropTypes.object,
  callback: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  saveDepartment: PropTypes.func,
  currentItem: PropTypes.object
};
