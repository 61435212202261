import styled from 'styled-components';
import Parent from '../Base/style';
const SelectWrapper = styled(Parent.SelectWrapper)`
${({ theme }) => `
.select__control{
    border: 0;
    background: #fff;
    border: 1px solid #d3d7db;
    outline: none;
    font-size: 12px;
    min-height: 20px !important;
    height: 20px;
    overflow-y: hidden !important;
    &:hover {
        border: 1px solid #d3d7db;
    }
}
.select__input input {
    border: 0 !important;
}
.css-b8ldur-Input{
    margin: 0;
}
.select__value-container{
    height: 20px;
}
.select__indicators {
    padding-top: 0;
    .select__indicator {
        padding: 0;
        svg {
            fill: #131313;
        }
    }
}
.select__placeholder {
    font-size: 12px;
}
.select__menu {
    background: #fff;
    border: 1px solid #efefef;
    border-radius: 0px;
    z-index: 1000;
    color: #000 !important;
    font-size: 12px !important;
}

.select__control{
    margin-top: 5px !important;
}

.select__multi-value{
    background: #fff !important;
    border: none !important;
    padding-right: 10px;
}
.select__multi-value__remove{
    top:-1px !important;
    right: -4px;
}
.select__multi-value__remove:hover{
    background-color: #fff !important;
    color: #000 !important;
}

.select__multi-value__label{
    padding: 0px !important;
}


.select__option--is-focused,
.select__option:hover {
    background: #75828a !important;
    color: #fff;
}
.select__value-container{
    padding-left: 0;
}
.select__single-value{
    font-family: ${(theme.palette || {}).fontFamily} !important;
    margin-top: 2px;
}
`}
`;
const Styled = { SelectWrapper };
export default Styled;
