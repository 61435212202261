import styled from 'styled-components';

const DropDownWrapper = styled.div`
  cursor: pointer;
  background: ${(props) =>
      props.theme.palette.primary
        ? props.theme.palette.primary.main
        : props.theme.colors.PRIMARY_MAIN}
    none repeat scroll 0 0;
  border: 2px solid
    ${(props) =>
      props.theme.palette.primary
        ? props.theme.palette.primary.border
        : props.theme.colors.PRIMARY_BORDER};
  height: 45px;
  font: 1.2em;
  padding-left: 0.5rem;
  color: ${(props) =>
    props.style
      ? props.style.color
      : props.theme.palette.secondary.contrastText
      ? props.theme.palette.secondary.contrastText
      : props.theme.colors.PRIMARY_CONTRAST};
  .MuiPaper-elevation1 {
    background-color: colors.BLACK;
    margin-top: 0px;
    margin-left: 0px;
    padding-left: 0px;
    left: 50;
  }
`;

export default DropDownWrapper;
