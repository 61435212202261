import styled from 'styled-components';

const Wrapper = styled.div`
  ${({ theme }) => `
  position: relative;
  flex-direction: column;
  .contentWrap {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flexbox;
    display: flex;
    width: 100%;
    /* Change the white to any color ;) */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active  {
        -webkit-box-shadow: 0 0 0 30px #efefef inset !important;
    }

    /*Change text in autofill textbox*/
    input:-webkit-autofill {
        -webkit-text-fill-color: #000 !important;
    }
  }
  .registrationLink {
    color: ${theme.colors.WHITE} !important;
  }
  .registrationheader: hover {
    text-decoration: none !important;
  }
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  .MuiTypography-h1 {
    align-items: center;
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flexbox;
    display: flex;
    padding: 0px;
  }
  `}
`;
const ContentWrapper = styled.div`
  ${({ theme }) => `
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  flex-direction: column;
  flex: 0 0 33%;
  margin-left: 5%;
  margin-top: 42px;
  .wrapBox {
    flex: 0 0 85%;
    padding-bottom: 20px;
    background-color: #20263a;
    @media only screen and (min-width: ${theme.breakpoints.values.lg}px) and (max-height: ${theme.breakpoints.values.xxl}px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      flex: 0 0 55%;
    }
  }
  `}
`;

const Styled = {
  Wrapper,
  ContentWrapper,
};
export default Styled;
