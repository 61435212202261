const initialState = [];
export const getStates = {
  state: [...initialState],
  reducers: {
    setStates(state, payload) {
      return [...payload];
    },
    resetStates(state) {
      return [...initialState];
    }
  },
  effects: (dispatch) => ({
    async getStates(payload) {
      try {
        const getApi = await import(
          `../../../config/apis/${window.$environment.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('getStatesApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject
          },
          id: payload.apiId
        });
        response && this.setStates(response);
      } catch (error) {
        this.resetStates();
      }
    }
  })
};
