import styled from 'styled-components';
import * as Parent from '../Base/style';

export const ChatWrapper = styled(Parent.ChatWrapper)`
${({ theme }) => `
.selected-chat-highlight,
.chat-tile:hover,
.userNames:hover {
    background-color:  #e4e4e4;
}
.message-button-wrapper .MuiButton-label{
    font-size: 16px;
}
.message-button-wrapper .MuiButton-label,
.chat-popup{
    font-family: tahoma;
}
#selectDriverId .select__control {
    background-color: #BDBDBD !important;
}
.chat-send-section{
    background-color: #ededed;
    border-bottom-right-radius: 8px;
}
.chatWindow {
    background-color: #ededed;
    margin-top: -19px;
    border-top-right-radius: 8px;
}
.header-wrapper {
    border-bottom: 1px solid #dbdde3;
    padding-bottom: 3px;
    width: 89%;
    box-shadow: -4px 9px 8px #c8c8c8;
    margin-left: 10px;
    font-weight: 600;
}
.select__control {
    background-color: #bdbdbd;
    color: #fff;
}
.select__multi-value {
    background: #fff;
    border: 0;
}
.select__multi-value__remove:hover {
    background: transparent !important;
}
.message-type-wrapper {
    textarea {
        background: #ededed none repeat scroll 0 0;
    }
}
.rightFloat {
    background: ${theme.colors.LINK_COLOR} !important;
}
.chat-send-section .MuiInput-root{
    border: 1px solid #ddd;
}
.chat-send-section .MuiInputBase-input {
    padding: 10px 15px;
}
.chat-send-section .MuiInput-root,
.chat-send-section .MuiInputBase-input {
    background: #fff!important;
    
}
.userWrapper2{
    .select__placeholder,
    .select__input input{
        color: #fff!important;
        font-family: tahoma;
    }
}
.search-new,
.parentWrapper {
    .MuiInputBase-input,
    .MuiInput-root{
        background-color: #919191 !important;
        border: 0;
        color: #fff !important;
    }
   
    .clear{
        right: 32px;
    }
}
.MuiInputBase-input:focus {
    // border: 0;
}
.tri-right.left-in:after{
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
    left: -9px;
	right: auto;
    top: 20px;
	bottom: auto;
	border: 10px solid;
	border-color: #919191
    #919191 transparent transparent;
    transform: rotate(224deg);
}
.talk-bubble.tri-right.left-in {
    background-color: #919191;
    border:0;
}
`}
`;
