import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { colors, fonts } from './variables';

const defaultTheme = createMuiTheme();

//create config for colors;
const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.PRIMARY_MAIN,
      contrastText: colors.PRIMARY_CONTRAST,
      border: colors.PRIMARY_BORDER
    },
    secondary: {
      main: colors.SECONDARY_MAIN,
      contrastText: colors.SECONDARY_CONTRAST
    },
    grey: { color: colors.DAILY_LOG_GRAPH_BORDER },
    background: {
      default: colors.PRIMARY_MAIN
    },
    error: {
      main: colors.RED
    },
    warning: {
      main: colors.WARNING
    },
    leftMenuText: {
      color: colors.PRIMARY_CONTRAST,
      fontSize: '14px',
      textDecoration: 'none',
      paddingTop: '2px'
    },
    fontFamily: fonts,
    bodyFontSize: '14px',
    bodySmallFontSize: '16px',
    backgroundColor: '#fff',
    contentStyle: {
      minHeight: '100vh',
      padding: '2% 2% 3.5% 2%',
      backgroundColor: colors.PRIMARY_MAIN
    },
    contentlogsStyle: {
      minHeight: '100vh',
      padding: '0',
      paddingTop: '2%',
      backgroundColor: colors.PRIMARY_MAIN
    }
  },
  colors: { ...colors },
  overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: "14px",
          top:'-4px',
        }
      },
    MuiExpansionPanelDetails: {
      root: {
        padding: '16px 16px 0px 16px'
      }
    },
    MuiButtonBase: {
      root: {
        disabled: true
      }
    },
    MuiTableSortLabel: {
      root: {
        color: colors.SECONDARY_MAIN + ' !important',
        '&:hover': {
          color: colors.SECONDARY_MAIN + ' !important'
        }
      },
      iconDirectionDesc: {},
      active: {
        color: colors.SECONDARY_MAIN + ' !important'
      },
      icon: {
        color: colors.SECONDARY_MAIN + ' !important'
      }
    },
    MuiTableRow: {
      root: {
        backgroundColor: colors.BACKGROUND,
        color: colors.PRIMARY_CONTRAST,
        '&:hover': {
          backgroundColor: colors.BACKGROUND
        },
        fontWeight: 'bold'
      }
    },
    MuiSvgIcon: { root: { fontSize: '145.5%' } },
    MuiTable: {
      root: {
        border: '1px solid ' + colors.PRIMARY_BORDER
      }
    },
    MuiTypography: {
      body1: {
        color: colors.PRIMARY_CONTRAST,
        fontSize: '14px'
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: 'none'
      }
    },
    MuiInput: {
      root: {
        width: '100%',
        color: colors.PRIMARY_CONTRAST,
        border: '1px solid ' + '#f0f2f2',
        height: '40px',
        borderRadius: '3px',
        backgroundColor: '#f0f2f2',
        fontSize: '16px',
        marginBottom: '18px',
        '&:active': {
          border: '1px solid' + '#bdd731'
        },
        '&:focus': {
          border: '1px solid' + '#bdd731'
        }
      }
    },
    MuiInputBase: {
      input: {
        color: colors.PRIMARY_CONTRAST,
        backgroundColor: '#f0f2f2',
        '&::placeholder': {
          color: '#868e96',
          fontSize: '14px',
          opacity: '0.5',
          fontWeight: '400'
        },
        '&:active': {
          border: '1px solid' + colors.INPUT_FOCUS,
          borderRadius: '3px'
        },
        '&:focus': {
          border: '1px solid' + colors.INPUT_FOCUS,
          borderRadius: '3px'
        }
      }
    },
    MuiTableCell: {
      head: {
        color: colors.WHITE
      },
      paddingCheckbox: {
        color: colors.PRIMARY_MAIN + ' !important'
      },
      body: {
        backgroundColor: colors.BLACK,
        color: colors.PRIMARY_CONTRAST,
        borderBottom: '.5px solid ' + colors.PRIMARY_MAIN,
        fontSize: '17px'
      }
    },
    MuiIconButton: {
      root: {
        color: colors.SECONDARY_MAIN
      }
    },
    MuiButton: {
      root: {
        color: colors.WHITE,
        backgroundColor: colors.SECONDARY_MAIN,
        fontWeight: '700 !important',
        fontSize: '14px !important',
        textTransform: 'capitalize !important'
        // padding: '0px!important'
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      xsm: 320,
      sm: 450,
      smd: 481,
      md: 768,
      mlg: 960,
      lg: 1024,
      xl: 1280,
      xxl: 1366
    }
  },

  typography: {
    body1: {
      color: colors.PRIMARY_CONTRAST,
      fontSize: '14px',
      textTransform: 'none'
    },
    body2: {
      fontSize: '14px'
    },
    h1: {
      fontSize: '20px',
      fontWeight: 'bold',
      color: '#191e22',
      [defaultTheme.breakpoints.up('sm')]: {
        fontSize: '24px'
      },
    },
    h2: {
      fontSize: '20px',
      color: colors.SECONDARY_MAIN,
      [defaultTheme.breakpoints.up('sm')]: {
        fontSize: '22px'
      },
      fontWeight: '700'
    },
    backgroundColor: '#f0f2f2',
    fontFamily: fonts,
    color: colors.PRIMARY_CONTRAST,
    subtitle1: {
      fontSize: '16px',
      [defaultTheme.breakpoints.between('sm', 'md')]: {
        fontSize: '18px'
      },
      [defaultTheme.breakpoints.up('lg')]: {
        fontSize: '25px'
      }
    }
  }
});

export default theme;
