import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Wrapper = styled(Box)`
  ${({ theme }) => `
  .MuiTypography-body1 {
    font-size: 18px;
  }
  .filepond--wrapper {
    .filepond--drop-label label {
      color: ${theme.colors.PRIMARY_CONTRAST};
      opacity: 1;
    }
  }
  .select__menu {
    height: 200px;
    overflow: auto;
    .select__menu-list {
      height: 210px;
      max-height: 100%;
    }
  }
  .subheaderSection {
    padding: 11px 20px;
    margin-top: 10px !important;
  }
  .fieldSection {
    margin-bottom: 16px;
    .MuiInputBase-root {
    margin-bottom: 0;
    }
    .MuiFormLabel-root{
      padding-right: 7px !important;
      line-height: 1.2;
    }
  }
  form {
    div:last-child {
      justify-content: center;
      #button {
        // width: 210px;
        margin-top: 0;
      }
    }

  }

  `}
`;
const UploadWrapper = styled(Box)`
 ${({ stateOffset, fleetOffset }) => `
.tcSection {
	height: 300px;
	overflow-y: scroll;
}
.scrollbar {
  min-height: 400px;
  color: #fff;
  padding-right: 4%;
}
#scroll::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: rgba(216, 216, 216, 0.08);
  opacity: 0.08;
}

#scroll::-webkit-scrollbar {
  display: block;
	width: 10px;
	background-color: rgba(216, 216, 216, 0.08);
}

#scroll::-webkit-scrollbar-thumb {
  height: 54px;
  background-color: ${window.$environment.WHITE_THEME ? '#5e5f67'  : '#d8d8d8'};
}
#fleetManager .select__menu {
  position:fixed;
  top: ${fleetOffset}px;
  width: 63%;
}
#terminalState .select__menu {
    position:fixed;
    top: ${stateOffset}px;
    width: 63%;
  }
`}
`;
const PopupUpload = styled(Box)``;
const Styled = { Wrapper, UploadWrapper, PopupUpload };

export default Styled;
