import React, { lazy, useState } from 'react';

import ContractHistory from './ContractHistory';
const layout = window?.$environment?.CURRENT_LAYOUT;

const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`).default;
const Modal = require(`@/components/UI/Modal/${layout}`).default;
const TopActions = ({ i18n, paymentInformation, Styled }) => {
  const [open, setOpen] = useState(false);

  const clickedModal = (e) => {
    setOpen(true);
  };
  const closeModal = (event) => {
    setOpen(false);
  };
  const [ModalListObject, setModalListObject] = useState({
    header: '',
    content: () => {
      return null;
    }
  });
  return (
    <Styled.Wrapper>
      <div className="contractWrapper">
        <FormStyle.TextWrapper>
        <span className={`${window.$environment.SERVICE_PROVIDER==="riskTheory"&&"CRMcontractExpiry"} contractExpiry`}>
            {i18n.t('paymentInformation.contractExpiry')} :
          </span>
          {paymentInformation?.contractExpirationDate}
        </FormStyle.TextWrapper>
        <FormStyle.ButtonsWrapper className="contractButtons">
          <Button
            type="submit"
            className="buttonStyle"
            label={i18n.t('paymentInformation.contractHistory')}
            onClick={() => {
              clickedModal();
              setModalListObject({
                header: '',
                content: () => {
                  return (
                    <ContractHistory
                      contractHistoryList={
                        paymentInformation?.contractHistoryDtos || []
                      }
                      Styled={Styled}
                      i18n={i18n}
                    />
                  );
                }
              });
            }}
          />
        </FormStyle.ButtonsWrapper>
      </div>

      <Modal
        open={open}
        setOpen={setOpen}
        data={ModalListObject}
        onCloseModal={closeModal}
        background="black"
        withCloseIcon
        contractHistory
        width={720}
      />
    </Styled.Wrapper>
  );
};
export default TopActions;
