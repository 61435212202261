import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
  ${({ theme }) => `
flex: 0 0 60%;
position: initial;
.filterForm {
    .MuiTypography-body1 {
        font-size: 14px;
    }
    div[type='date'] {
        position: relative;
        width: 10% ;
        .MuiSvgIcon-root {
          position: absolute;
          right: 0;
          width: 18px;
          height: 18px;
          color: #767676;
          bottom: 7px;
        }
    }
}

.exportAlertSection {
    display flex;
    justify-content: flex-end;
    width: 100%;
    position: absolute;
    width: 60%;
    position: absolute;
    right: 1%;
    top: 0;
    padding-top: 1%;
    .exportWrapper {
        color: #fff;
        font-size: 23px;
        font-family: ${theme.palette.titleFont};
        border-radius: 0px;
        height: 45px;
        padding: 5px 20px !important;
        background: #C92100;
        :hover {
            box-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);
            text-decoration: none;
        }
    }
    .exportContainer {
        padding-left: 6px;
    }
    .export {
        margin-right: 1% !important;
        cursor pointer;
    }
}
.filterForm {
    padding-top: 1%;
    align-items: flex-end;
    flex: 0 0 100%;
    padding-left: 1%;
    margin-top: 10px;
    padding-bottom: 1%;
    .MuiButtonBase-root{
        margin-top: 27px;
    }
    .MuiBox-root[type="text"] p {
        display: none;
    }
    p {
        color: #000000 !important;
        font-size: 20px !important;
        opacity: 0.87;
    }
}
`}
`;
const ExportOptionWrapper = styled(Parent.ExportOptionWrapper)`
`;
const TableWrapper = styled(Parent.TableWrapper)`
  ${({ theme }) => `
padding-top: 0;
.spireonOnly,
.quickFilter{
    display: none;
}
.MuiTable-root .MuiTableBody-root .MuiTableCell-root:last-child {
    padding-left: 0 !important;
    .iconContainer .MuiAvatar-root {
        width: 15px !important;
        height: 18px !important;
    }
}
`}
`;
const SearchWrapper = styled(Parent.SearchWrapper)``;
const Styled = { Wrapper, ExportOptionWrapper, TableWrapper, SearchWrapper };

export default Styled;
