import React, { lazy } from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

import WithData from '..';
import Content from '../Component';

import Styled from './style';
const layout = window?.$environment?.CURRENT_LAYOUT;
const Title = lazy(() => import(`../../../../components/Title/${layout}`));
const Index = ({ callback }) => {
  return (
    <WithData callback={callback}>
      <Component />
    </WithData>
  );
};

export default Index;

const Component = ({ history, callback, i18n, theme, ...rest }) => {
  const contentProps = {
    Styled,
    i18n,
    callback,
    ...rest
  };
  return (
    <>
      <Title header={i18n.t('policies.heading')} />
      <Box style={{ ...theme.palette.contentStyle }}>
        <Content {...contentProps} />
      </Box>
    </>
  );
};

Component.defaultTypes = {};
Component.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired
};
