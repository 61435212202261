import { addIftaStateApi, editIftaStateApi, getIftaStateApi, validateOdometer, fetchLatLong } from '@/config/apis';

const initialState = {};
export const iftaDetails = {
    state: { ...initialState },
    reducers: {
        getSuccess(state, payload) {
          return { ...state, ...payload };
        },
        setErrorFlg(state, payload) {
          return state;
        },
        filterSuccess(state, status) {
          let iftaStateResponse = [ ...state.list ];
          if (status) {
            iftaStateResponse = iftaStateResponse.filter(ele => ele.iftaMileageId)
          }
          return {
            ...state,
            list: [ ...state.list ],
            filteredList: [ ...iftaStateResponse ]
          };
        }
    },
    effects: (dispatch) => ({
      async fetchData(payload, rootState) {
        this.setErrorFlg(false);
        try {
          const response = await dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              api: {
                ...getIftaStateApi,
                url: getIftaStateApi.url.replace('{id}', payload.data.id)
              }
            },
            callback: payload.callback,
            id: payload.id
          });
          if (response)  {
            this.setErrorFlg(response.error)
            this.getSuccess(response);
          }
        } catch (error) {
          this.setErrorFlg(false);
        }
      },
      filterList(status) {
        this.filterSuccess(status);
      },
      async addIftaState(payload) {
        try {
          const data = payload.param;
          const response = await dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              api: addIftaStateApi,
              data: data
            },
            callback: payload.callback,
            id: payload.apiId
          });
          payload.callback('success', response);
          return response;
        } catch (error) {
          // this.saveFailed();
        }
      },
      async validateOdometer(payload) {
        try {
          const data = payload.param;
          const response = await dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              api: validateOdometer,
              data: data
            },
            callback: payload.callback,
            id: payload.apiId
          });
          return {
            data: response,
            error: null
          };
        } catch (error) {
          return {
            data: null,
            error: error
          };
          // this.saveFailed();
        }
      },
      async editIftaState(payload) {
        try {
          const data = payload.param;
          const response = await dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              api: editIftaStateApi,
              data: data
            },
            callback: payload.callback,
            id: payload.apiId
          });
          payload.callback('success', response);
          return response;
        } catch (error) {
          // this.saveFailed();
        }
      },
      async fetchLatLong(payload, rootState) {
        try {
          const fetchLatLongFromAddress = dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              api: {
                ...fetchLatLong,
                url: fetchLatLong.url + `?address=${payload.data?.fromAddress}`
              },
            },
            id: 'fetchLatLong'
          });
          const fetchLatLongToAddress = dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              api: {
                ...fetchLatLong,
                url: fetchLatLong.url + `?address=${payload.data?.toAddress}`
              },
            },
            id: 'fetchLatLong'
          });
          const responses = await Promise.all([fetchLatLongFromAddress, fetchLatLongToAddress]);

          const response1 = responses[0];
          const response2 = responses[1];

          if (response1 && response1?.lat && response2 && response2?.lat) {
            let finalData = {
              "latLngBeg": response1,
              "latLngEnd": response2,
            }
            return finalData
          }else {
            return {
              ...initialState,
              error:{ message: payload.i18n.t('companyData.invalidGaragingAddress') }
            }
          }
        } catch (error) {
          return {
            ...initialState,
            error: {
              ...error,
              message: payload.i18n.t('companyData.invalidGaragingAddress') }
          }
        }
      },
    }),
};
