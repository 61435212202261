const initialState = {};

export const feedback = {
  state: { ...initialState },
  reducers: {
    listSuccess(state, payload) {
      return {
        ...payload
      };
    },
    listFailed() {
      return { ...initialState };
    }
  },
  effects: (dispatch) => ({
    async sendFeedback(payload, rootState) {
      try {
        const getApi = await import(
          `../../../config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('sendFeedback');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject,
            data: payload.data
          },
          id: payload.apiId,
          callback: payload.callback
        });
        payload.callback('success', response);
      } catch (error) {
        this.listFailed();
      }
    }
  })
};
