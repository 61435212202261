import React, { lazy, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import RenderComponent from './RenderComponent';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));

const FeedbackForm = ({ hasApiStatus, callback, sendFeedback }) => {
  const { i18n } = useTranslation();
  const apiId = 'sendFeedback';
  const [initialValues, setInitialValues] = useState({
    message: ''
  });
  return (
    <>
      <Form
        type="server"
        direction="row"
        initialValues={{ ...initialValues }}
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {
          sendFeedback({
            apiId: apiId,
            data: {
              ...fields
            },
            callback: callback
          });
        }}
        handleOnCancel={(fields) => {}}
        customProps={{
          callback,
          hasApiStatus
        }}
        validationSchema={Yup.object().shape({
          message: Yup.string().required(i18n.t('common.fieldRequiredMessage'))
        })}
      />
    </>
  );
};

FeedbackForm.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired
};

export default FeedbackForm;
