import styled from 'styled-components';
import Parent from '../Base/style';
const ButtonContainer = styled(Parent)`
${({ theme }) => `
margin-right: 20px;
.MuiButton-root {
    border-radius: 2px;
    border: 0;
    font-size: 14px !important;
    font-weight: 700;
    padding: 0 !important;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    height: 36px !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
}
.reset,
.reset:hover {
  font-weight: 400;
}
.MuiButton-label { 
    margin-right: 20px;
    margin-left: 20px;
}
}
`}
`;

export default ButtonContainer;
