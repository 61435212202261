import {
  logBookViewApi,
  logBookFilterViewApi,
  dailyLogDownloadApi,
  loadMoreEventsApi,
  getDocumentTokenApi,
  regeneratePDFLogBookApi
} from '../../../../config/apis';
import { UI_LOGBOOK_DATE } from '@/config/constants/static';
import moment from 'moment';
import { pageUrls } from '@/config/constants/keys';
const initialState = {
  dailyLogs: [],
  drivers: [],
  driverDetailsDto: [],
  selectedDailyLog: {},
  violationsListDto: [],
  documentToken: '',
  eventsData: {
    showEvents: false,
    data: {}
  }
};

export const logBook = {
  state: { ...initialState },
  reducers: {
    viewSuccess(state, payload) {
      return {
        dailyLogs: [...(payload.dailyLogs || [])],
        drivers: [...(payload.drivers || state.drivers)],
        driverDetailsDto: [...(payload.driverDetailsDto || state.driverDetailsDto)],
        selectedDailyLog: { ...(payload.selectedDailyLog || []) },
        violationsListDto: [...(payload.violationsListDto || [])],
        documentToken: state.documentToken,
        eventsData: { ...initialState.eventsData }
      };
    },
    viewFailed(state) {
      return { ...initialState };
    },
    setdocumentToken(state, payload) {
      return { ...state, documentToken: payload };
    },
    setEventData(state, payload) {
      return {
        ...state, eventsData: {
          showEvents: true,
          data: payload
        }
      };
    },
    updateShowEvents(state, payload) {
      return {
        ...state,
        eventsData: {
          showEvents: payload,
          data: state.eventsData.data
        }
      }
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload, rootState) {
      try {
        const newApi = payload.data.data.logId
          ? {
            ...logBookViewApi,
            url: `${logBookViewApi.url.replace('{id}', payload.data.data.logId)}?driverId=${payload.data.data.driverId ? payload.data.data.driverId : ''}&logDay=${payload.data.data.date ? payload.data.data.date : ''}`
          }
          : payload.data.data.driverId && payload.data.data.date
            ? {
              ...logBookFilterViewApi,
              url: logBookFilterViewApi.url.replace(
                '{id}/{date}',
                payload.data.data.driverId +
                '/' +
                moment(payload.data.data.date).format(UI_LOGBOOK_DATE)
              )
            }
            : payload.data.data.driverId
              ? {
                ...logBookFilterViewApi,
                url: logBookFilterViewApi.url.replace(
                  ['{id}/{date}'],
                  payload.data.data.driverId
                )
              }
              : {};
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...newApi
            }
          },
          callback: (type, message) => {
            payload.callback(
              type,
              message,
              () => payload.history && payload.history.push(pageUrls.logsList)
            );
          },
          id: payload.apiId
        });
        this.viewSuccess(response);
        if (window.$environment.SERVICE_PROVIDER == 'spireon' && (!payload.data.data.logId)) {
          if (response?.selectedDailyLog?.idPub) {
            window.history.pushState('', 'Log Book', `${window.$environment.CURRENT_LAYOUT == 'SpireonNew' ? '/spireon' : ''}/logs/logbook/${response?.selectedDailyLog?.idPub}?driverId=${response?.selectedDailyLog?.driverId}&logDay=${response?.selectedDailyLog?.day}`)
          }
        }
        if (window.$environment.SERVICE_PROVIDER !== 'spireon') {
          if (
            response &&
            payload.data.data.driverId &&
            payload.data.data.date
          ) {
            window.history.pushState(
              '',
              'Log Book',
              `${window.$environment.SERVICE_PROVIDER == 'eldx' ? `${window.$environment.RESELLER}` : ''}/logs/logbookFilter/${payload.data.data.driverId}/${moment(
                payload.data.data.date
              ).format(UI_LOGBOOK_DATE)}`
            );
          }
          if (
            response &&
            payload.data.data.driverId &&
            !payload.data.data.date
          ) {
            window.history.pushState(
              '',
              'Log Book',
              `${window.$environment.SERVICE_PROVIDER == 'eldx' ? `${window.$environment.RESELLER}` : ''}/logs/logbookFilter/${payload.data.data.driverId}`
            );
          }
        }
      } catch (error) {
        this.viewFailed();
      }
    },
    async getLogbookEvents(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...loadMoreEventsApi,
              url: loadMoreEventsApi.url.replace('{id}', payload.data.id)
            }
          },
          callback: payload.callback,
          id: payload.apiId
        });
        response && this.setEventData(response);
      } catch (error) {
        // this.viewFailed();
      }
    },
    async downloadData(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...dailyLogDownloadApi
            },
            data: {
              ...payload.data.data
            }
          },
          id: payload.apiId
        });
        payload.callback && payload.callback('success', response);
      } catch (error) {
        this.viewFailed();
      }
    },
    async documentToken(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: getDocumentTokenApi
          },
          id: payload.apiId
        });
        response && this.setdocumentToken(response);

        if (payload.callback && !payload.item)
          payload.callback('success', response)

        else if (payload.callback && payload.item)
          payload.callback('success', response, payload.item)

      } catch (error) { }
    },
    async regeneratePdf(payload, rootState) {
      try {
        const newApi = {
          ...regeneratePDFLogBookApi,
          url: `${regeneratePDFLogBookApi.url.replace('{driverId}', payload.data.data.driverId).replace('{startDate}', payload.data.data.date).replace('{endDate}', payload.data.data.date)}`
        }

        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...newApi
            },
            data: {}
          },
          id: 'regeneratePdfLogbook'
        });

        if (response.status === 200) {
          setTimeout(() => {
            payload.newCallback();
          }, 5000); // 5000 milliseconds = 5 seconds
        } else {
          payload.callback('error', "Data regeneration failed.");
        }
        // payload.callback && payload.callback('success', response);
      } catch (error) {
        if (error.message) {
          payload.callback('error', "Data regeneration failed.");
        }
      }
    },
  })
};
