import { getStatesApi } from '../../../../config/apis';

const initialState = [];

export const getStates = {
  state: [...initialState],
  reducers: {
    setFetchSuccess(state, payload) {
      return [...payload];
    },
    setFetchFailed() {
      return [...initialState];
    }
  },
  effects: (dispatch) => ({
    async getStates(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: getStatesApi
          },
          id: payload.id
        });
        response && this.setFetchSuccess(response);
      } catch (error) {
        this.setFetchFailed();
      }
    }
  })
};
