import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)``;
const SearchWrapper = styled(Parent.SearchWrapper)``;
const TopWrapper = styled(Parent.TopWrapper)`
`;
const TableWrapper = styled(Parent.TableWrapper)`
  ${({ theme }) => `

`}
`;
const LabelWrap = styled(Parent.LabelWrap)``;

const AddWrapper = styled.div`
`;
const Styled = {
  Wrapper,
  SearchWrapper,
  TopWrapper,
  TableWrapper,
  LabelWrap,
  AddWrapper
};
export default Styled;
