import * as parent from '../variables';

export const colors = {
  ...parent.colors,
  SELECTED_MENU_BG: '#f1f1f1',
  PRIMARY_MAIN: '#f1f1f1',
  HEADER_COUNT_TEXT: '#000',
  PRIMARY_CONTRAST: '#000',
  PRIMARY_BORDER: '#d7d7d7',
  GREY: '#999',
  DAILY_LOG_GRAPH_BORDER: '#919191',
  NORMAL_LINK: '#646464',
  MODAL_TEXT: '#797979',
  MODAL_HEADING: '#000',
  MODAL_BACKGROUND: '#f1f1f1',
  LIST_TEXT: '#000000',
  HEADER_LIST_TEXT: '#000000',
  LIGHT_BORDER: '#2d2b2b',
  SECONDARY_MAIN: '#a8a8a8',
  WHITE: '#fff',
  INPUT_BACKGROUND: '#f1f1f1',
  RED: '#ff0000',
  BLACK: '#000',
  BACKGROUND: '#1b1b1b',
  BLACK_THREE: '#373737',
  RED_TWO: '#d34343',
  GREEN: '#32cd32',
  GREEN_TWO: '#97AE46',
  GREY_ELEVEN: '#777070',
  GREY_TWELVE: '#c3c3c3',
  BLACK_FOUR: '#333333',
  GRAPH_BLACK: '#212121',
  CHART_BLUE: '#00e2ff',
  WARNING: '#ffff00',
  GREY_DATA: '#696969',
  LINK_COLOR: '#4d4d4c',
  SELECTED_TAB_COLOR: 'rgba(0, 0, 0, 0.75)',
  HEADER_COLOR: '#ffffff',
  DOCUMENT_LINE: '#2C3249',
  INPUT_BORDER: '#d1d1d1',
  DIABLED_COLOR: 'rgba(0, 0, 0, 0.75)',
  RADIO_BACKGROUND: '#f1f1f1',
  FORM_COLOR: '#000',
  SUGGESTED_COLOR: '#00b7a0',
  BODY_BACKGROUND: '#f1f1f1',
  CHECKBOX_TICK: '#4d4d4c',
  PAGINATION_BACKGROUND: '#fff',
  PAGINATION_COLOR: '#fff',
  SELECTED_AREA: 'rgba(32, 177, 232, 0.6)',
  MAP: {
    LIGHT_BLACK: '#424D66',
    LIGHTER_BLACK: '#424D66',
    BLACK: '#424D66',
    GREY: '#d1d1d1',
    LIGHT_GREY: '#d1d1d1',
    LIGHTER_GREY: '#d1d1d1',
    WATER: '#2B364F',
    ROAD: '#37425C'
  },
  LOGIN_BUTTON: '#4d4d4c',
  TITLE_COLOR: '#191e22',
  INPUT_COLOR: '#000',
  INPUT_FOCUS: '#5ab8f9',
  TAB_LABEL: 'rgb(69, 85, 96)',
  BACKGROUND_MENU: '#d1d1d1',
  PAGINATION_ALTERNATE_BACKGROUND: '#f1f1f1',
  TABLE_ROW_COLOR: 'rgba(0, 0, 0, 0.75)',
  SORT_ICON: 'rgba(0, 0, 0, 0.75)',
  LOGOUT_BUTTON: '#f1f1f1',
  PAGINATION_HEADER: 'rgba(0, 0, 0, 0.75)',
  HEADER_TEXT: '#000',
  PAGINATION_BORDER: 'rgba(0, 0, 0, 0.3)',
  RESET_BUTTON: 'rgba(0, 0, 0, 0.3)',
  ACTION_BORDER: 'rgba(0, 0, 0)',
  PRIMARY_BUTTON_TEXT: '#fff',
  FORM_INPUT_BG: '#f1f1f1',
  FORM_BACKGROUND: '#fff',
  CHECKBOX_BORDER: '#d1d1d1',
  RESET_COLOR: 'rgba(0, 0, 0)',
  SELECT_ACTIONS: '#000',
  SECONDARY_BORDER: '#2C3249',
  MULTISELECT_BACKGROUND: '#4d4d4c',
  MULTISELECT_LABEL: '#fff',
  TAB_HIGHLIGHT: '#000',
  TAB_HIGHLIGHT_BACKGROUND: '#d1d1d1',
  TAB_BOTTOM_BORDER: '#4d4d4c',
  ACCORDION_BACKGROUND: '#fff',
  USER_LOGO_BACKGROUND: '#c3c3c3',
  USER_LOGO_COLOR: '#000',
  POLICIES_BACKGROUND: '#fff',
  MENU_BACKGROUND: '#ffffff',
  MENU_BORDER_COLOR: '#4d4d4c',
  MENU_TITLE: 'rgba(0, 0, 0, 0.8)',
  SELECTED_MENU_TITLE: '#000000',
  RANGE_PICKER: {
    CELL_BG: '#dfdfdf',
    CELL_HOVER_BG: '#bbbbbb'
  }
};
export default colors;
export const fonts = ['Open Sans', 'sans-serif'].join(',');

