import React from 'react';
import { useTranslation } from 'react-i18next';

import ParentStyled from './style';
import { loadStyle } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'pages/web/Dashboard');

const PotentialViolations = ({ potentialViolations, heading, other }) => {
  const { i18n } = useTranslation();
  const monthArray = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  const formatTime = (data) => {
    let date = new Date(data);
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = ('0' + date.getDate()).slice(-2);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = `${monthArray[month]} ${day}, ${year} ${hours}:${minutes} ${ampm}`;
    return strTime;
  };
  return (
    <Styled.DriverDashboard newUI={window.$environment.SERVICE_PROVIDER === 'webfleet'}>
      {heading ? (
        <div className={`dashboardComponents ${other ? 'otherHeading' :'dashboardViolation'}`}>
          <div className="headingsWrapper">
            <span className="mainHeading">{i18n.t(heading)}</span>
          </div>
        </div>
      ) : (
        ''
      )}
      {!potentialViolations.length ? (
        <span className="noDatavioltion">{i18n.t('No data available')}</span>
      ) : (
        <ul>
          {potentialViolations?.map((item) => {
            return item.items ? (
              <li>
                <span className="name">
                  {item.items ? item.items[0] : item.driver || ''}
                </span>
                <span className="id">
                  (ID:{item.items ? item.items[1] : item.id || ''})
                </span>
                <span className="message">
                  {item.items ? item.items[3] : item.description || ''}
                </span>
              </li>
            ) : other?
            (
              <li>
                <span className="name">{item.label}</span>
                <span className="message">{item.value}</span>
              </li>
            ) : (
              <li>
                <span className="name">{formatTime(item.date)}</span>
                <span className="message">{i18n.t(item.description)}</span>
              </li>
            );
          })}
        </ul>
      )}
    </Styled.DriverDashboard>
  );
};

export default PotentialViolations;

PotentialViolations.defaultProps = {};

PotentialViolations.propTypes = {};
