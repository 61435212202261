import React from 'react';
import DocWrapper from './style'
import PropTypes from 'prop-types';

const ShowDoc = (props) => {
  return (
    <DocWrapper color={props.color}>
      <a className="showDoc" href={props.src}> {props.label} </a>
    </DocWrapper>
  );
}

ShowDoc.propTypes = {
  label: PropTypes.string,
  src: PropTypes.string
}

ShowDoc.defaultProps = {
  label: "",
};

export default ShowDoc;
