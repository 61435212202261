import React, { lazy } from 'react';
import { Link } from 'react-router-dom';
import { Link as LinkLi } from '@material-ui/core';
import { pageUrls } from '@/config/constants/keys';
import useTheme from '@material-ui/core/styles/useTheme';
import ParentStyled from './style';
import { loadStyle, hasRestrictedRole } from '@/utils/utils';
const LogoContainer = loadStyle(ParentStyled, 'components/Logo');

// const Link = lazy(() => import(`@/components/UI/Link`));

const Logo = ({ src, width, isWeblogin }) => {
  const theme = useTheme();
  const getUrl = () => {
    if (hasRestrictedRole(['FLMANAGER'])) {
      return pageUrls.dashboard;
    } else if (hasRestrictedRole(['MECHANIC'])) {
      return pageUrls.workOrders;
    } else if (hasRestrictedRole(['DRIVER'])) {
      return pageUrls.profile;
    } else {
      return pageUrls.dashboard;
    }
  };
  return (
    <LogoContainer imgWidth={width} isWeblogin={isWeblogin}>
      <LinkLi 
      component={Link}
      to={getUrl()}>
        <img alt="logo" src={src} style={{ ...theme.palette.oldLogo }} />
      </LinkLi>
    </LogoContainer>
  );
};

export default Logo;
