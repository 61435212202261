import React, { useEffect, useState, lazy } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import RenderComponent from './RenderComponent';

import { hasValue } from '../../../../../utils/utils';
const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`../../../../../components/UI/Form/${layout}`));
const TruckInfoForm = ({
  apiStatus,
  clearApiStatus,
  states,
  addFleetManagerInfo,
  getFleetManagerInfo,
  getCompanyInfoApi,
  showNextStep,
  addTruckInfo,
  addTruckInfoApiCall,
  truckTypes,
  truckInfo
}) => {
  const { i18n } = useTranslation();
  const apiId = 'addTruckInfo';
  const hasApiStatus = hasValue(apiStatus.status, 'api', apiId);
  const [array, setArray] = useState(
    truckInfo.getTruckInfoData ? truckInfo.getTruckInfoData : []
  );
  const [trucks, setTrucks] = useState(getCompanyInfoApi.initialTrucks);
  const [index, setIndex] = useState(0);
  const [values, setValues] = useState(
    array[index]
      ? {
          type: { label: array[index]?.type, value: array[index]?.type },
          year: array[index].year,
          make: { label: array[index]?.make, value: array[index]?.make },
          model: { label: array[index]?.model, value: array[index]?.model },
          vehicleId: array[index]?.vehicleId,
          connectionType: array[index]?.connectionType,
          unitId: array[index].unitId
        }
      : {
          type: '',
          year: '',
          make: '',
          model: '',
          vehicleId: '',
          connectionType: '',
          unitId: ''
        }
  );
  useEffect(() => {
    if (
      document.getElementById('nextTruckInfoButton') &&
      document.getElementById('nextButtonStep')
    ) {
      if (index >= 0 && index < getCompanyInfoApi.initialTrucks) {
        document.getElementById('nextTruckInfoButton').style.display = 'block';
        document.getElementById('nextButtonStep').style.display = 'none';
      } else if (index === getCompanyInfoApi.initialTrucks) {
        document.getElementById('nextButtonStep').style.display = 'block';
        document.getElementById('nextTruckInfoButton').style.display = 'none';
      }
    }
  }, [
    document.getElementById('nextTruckInfoButton'),
    document.getElementById('backButtonStep'),
    index
  ]);

  useEffect(() => {
    if (
      document.getElementById('backButtonStep') &&
      document.getElementById('backTruckInfoButton')
    ) {
      if (index >= 1) {
        document.getElementById('backButtonStep').style.display = 'none';
        document.getElementById('backTruckInfoButton').style.display = 'block';
      } else {
        document.getElementById('backButtonStep').style.display = 'block';
        document.getElementById('backTruckInfoButton').style.display = 'none';
      }
    }
  }, [
    document.getElementById('backButtonStep'),
    document.getElementById('backTruckInfoButton'),
    index
  ]);
  const previousClicked = () => {
    // clickedModalDelete()
    if (array[index - 1]) {
      setValues({
        ...values,
        type: { label: array[index - 1]?.type, value: array[index - 1]?.type },
        year: array[index - 1].year,
        make: { label: array[index - 1]?.make, value: array[index - 1]?.make },
        model: {
          label: array[index - 1]?.model,
          value: array[index - 1]?.model
        },
        vehicleId: array[index - 1]?.vehicleId,
        connectionType: array[index - 1]?.connectionType,
        unitId: array[index - 1].unitId
      });
      setIndex(index - 1);
    }
  };
  let dummyArray = truckInfo.getTruckInfoData ? truckInfo.getTruckInfoData : [];
  return (
    <div>
      <div className="HeadingWrap">
        <h1 className="stepTitle"> Truck Info</h1>
        <div className="index">
          {index === getCompanyInfoApi.initialTrucks && (
            <div>
              <span className="indexNo">{getCompanyInfoApi.initialTrucks}</span>
              <span className="nextHalf">
                <span>/</span>
                {getCompanyInfoApi.initialTrucks}
              </span>
            </div>
          )}
          {index !== getCompanyInfoApi.initialTrucks && (
            <div className="otherHalf">
              <span className="indexNo">{index + 1}</span>
              <span className="nextHalf">
                <span>/</span> {getCompanyInfoApi.initialTrucks}
              </span>
            </div>
          )}
        </div>
      </div>

      <div className="formLogin forgotPassword">
        <Form
          type="server"
          direction="column"
          initialValues={{ ...values }}
          RenderComponent={RenderComponent}
          handleOnSubmit={(fields) => {
            if (getCompanyInfoApi?.companyId) {
              fields.companyId = getCompanyInfoApi?.companyId;
              fields.userId = getFleetManagerInfo?.userId;
            }
            fields.model = fields?.model?.value;
            fields.make = fields?.make?.value;
            fields.type = fields?.type?.value;
            fields.connectionType = fields?.connectionType || '9 Pin';
            if (array[index]) {
              array[index] = fields;
              setIndex(index + 1);
              dummyArray[index] = fields;
              if (index + 1 === getCompanyInfoApi.initialTrucks) {
                addTruckInfo({
                  id: 'addTruckInfo',
                  data: dummyArray.splice(0, getCompanyInfoApi.initialTrucks)
                });
              }
            } else {
              dummyArray = [...array, fields];
              setArray([...dummyArray]);
              setIndex(index + 1);
              if (dummyArray.length === getCompanyInfoApi.initialTrucks) {
                addTruckInfo({
                  apiId: 'addTruckInfo',
                  data: dummyArray
                });
              }
            }
            if (
              index < getCompanyInfoApi.initialTrucks &&
              index + 1 !== getCompanyInfoApi.initialTrucks
            ) {
              setValues(
                array[index + 1]
                  ? {
                      type: {
                        label: array[index + 1]?.type,
                        value: array[index + 1]?.type
                      },
                      year: array[index + 1].year,
                      make: {
                        label: array[index + 1]?.make,
                        value: array[index + 1]?.make
                      },
                      model: {
                        label: array[index + 1]?.model,
                        value: array[index + 1]?.model
                      },
                      vehicleId: array[index + 1]?.vehicleId,
                      connectionType: array[index + 1]?.connectionType,
                      unitId: array[index + 1].unitId
                    }
                  : {
                      type: '',
                      year: '',
                      make: '',
                      model: '',
                      vehicleId: '',
                      connectionType: '',
                      unitId: ''
                    }
              );
            }
          }}
          customProps={{
            hasApiStatus,
            clearApiStatus,
            truckTypes,
            previousClicked,
            truckInfo,
            trucks,
            index
          }}
          validationSchema={Yup.object().shape({
            type: Yup.string().required(i18n.t('common.fieldRequiredMessage')),
            year: Yup.number()
              .typeError('Must be a number')
              .required(i18n.t('common.fieldRequiredMessage'))
              .test(
                'len',
                `Must be a year between 1970 & ${new Date().getFullYear()} in yyyy format `,
                (val) =>
                  val &&
                  val.toString().length === 4 &&
                  val > 1969 &&
                  val < new Date().getFullYear() + 1
              ),
            make: Yup.string().required(i18n.t('common.fieldRequiredMessage')),
            model: Yup.string().required(i18n.t('common.fieldRequiredMessage')),
            unitId: Yup.string().required(i18n.t('common.fieldRequiredMessage'))
            // connectionType: Yup.string().required(
            //   i18n.t('common.fieldRequiredMessage')
            // )
          })}
        />
      </div>
    </div>
  );
};

export default TruckInfoForm;

TruckInfoForm.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  requestForgotPassword: PropTypes.func.isRequired,
  callback: PropTypes.func,
  clearApiStatus: PropTypes.func.isRequired
};
