import React, { lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { string, object } from 'yup';

import Box from '@material-ui/core/Box';

import { hasValue } from '@/utils/utils';
import RenderComponent from './RenderComponent';
import { COOKIE_AUTH_DETAILS, pageUrls } from '@/config/constants/keys';
import { getCookie } from '@/services/cookie';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));
const Modal = require(`@/components/UI/Modal/${layout}`).default;
const ErrorIcon = lazy(() => import(`@/components/ErrorIcon/${layout}`));
const SuccessIcon = lazy(() => import(`@/components/SuccessIcon/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const AddForm = ({
  apiStatus,
  handleAddLog,
  callback,
  drivers,
  clearApiStatus,
  Styled,
  theme,
  history,
  i18n,
  vehicleList
}) => {
  const token = getCookie(COOKIE_AUTH_DETAILS);
  const apiId = 'addLog';
  const hasApiStatus = hasValue(apiStatus.status, 'api', apiId);
  const [openCallback, setOpenCallback] = useState(false);
  const [removeValues, setRemoveValues] = useState('false');
  const [initialValues, setInitialValues] = useState({
    driverId: '',
    vehicleName: '',
    date: '',
    logData: ''
  });
  const [ModalListObject, setModalListObject] = useState({
    header: '',
    content: () => (
      <Button
        type="submit"
        label={i18n.t('common.ok')}
        onClick={() => setOpenCallback(false)}
      />
    )
  });
  const addLogsCallback = (type, message) => {
    setModalListObject({
      header: '',
      content: () => (
        <div className="successpopup">
          {type === 'error' ? <ErrorIcon /> : <SuccessIcon />}
          <h2> {i18n.t(message)} </h2>
          <div className="deletePopupBtn">
            <Button
              type="submit"
              label={i18n.t('common.ok')}
              onClick={() => {
                setRemoveValues('true');
                setOpenCallback(false);
              }}
            />
          </div>
        </div>
      )
    });
    setOpenCallback(true);
  };
  const driverVehicleCallback = (item, field, value) => {
    return {
      label: item[value],
      value: item[field]
    };
  };
  const objectArrayFromDiffFields = (list, field, value, callback) => {
    return list?.length
      ? list.map((item) => {
        if (callback) return callback(item, field, value);
        else
          return {
            label: item[value],
            value: item[field]
          };
      })
      : [];
  };
  const fleetVehicleList = objectArrayFromDiffFields(
    vehicleList, 
    'vehicleId',
    'name',
    driverVehicleCallback
  )
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Styled.Wrapper>
        <Form
          type="server"
          direction="row"
          initialValues={{ ...initialValues }}
          RenderComponent={RenderComponent}
          handleOnSubmit={(fields) => {
            let data = {};

            data.driverId = fields.driverId.value;
            data.driverName = fields.driverId.label;
            data.logData = fields.logData[0];
            data.date = fields.date;
            data.vehicleName = fields.vehicleName.label;
            //data.companyId = 323;
            if (
              document?.querySelectorAll('span.filepond--file-status-main')[0]
                ?.innerText === ''
            ) {
              setRemoveValues('false');
              handleAddLog({
                data: { data },
                apiId,
                callback: addLogsCallback
              });
            }
          }}
          customProps={{
            hasApiStatus,
            drivers,
            history,
            fleetVehicleList,
            i18n,
            removeValues
          }}
          validationSchema={object().shape({
            driverId: string()
              .nullable()
              .required(i18n.t('common.fieldRequiredMessage')),
            vehicleName: string().required(
              i18n.t('common.fieldRequiredMessage')
            ),
            date: string().required(i18n.t('common.fieldRequiredMessage')),
            logData: string().required(i18n.t('common.fieldRequiredMessage'))
          })}
        />
        <Modal
          open={openCallback}
          setOpen={setOpenCallback}
          data={ModalListObject}
          isSuccess
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
        />
      </Styled.Wrapper>
    </Box>
  );
};

AddForm.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  handleAddLog: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  drivers: PropTypes.object.isRequired,
  vehicleList: PropTypes.array.isRequired,
  Styled: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  i18n: PropTypes.array.isRequired
};

export default AddForm;
