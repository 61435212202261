import React, { useState, useEffect, lazy } from 'react';

import { useTranslation } from 'react-i18next';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Select = lazy(() => import(`../../../UI/Select/${layout}`));
const Button = lazy(() => import(`../../../UI/Button/${layout}`));
const FormStyle = require(`../../../UI/Form/${layout}/serverStyle`);
const Timezone = ({
  saveTerminal,
  payload,
  updateTerminal,
  closeModal,
  saveSubDepartment,
  updateSubDepartment,
  Styled,
  saveCompanyData,
  saveRegistration,
  callback,
  registrationcancelPopup,
  successCallback
}) => {
  const { i18n } = useTranslation();
  const options = [
    { label: 'Central Time (US&Canada)', value: 'US/Central' },
    { label: 'Atlantic Time (US&Canada)', value: 'SystemV/AST4ADT' },
    { label: 'Eastern Time (US&Canada)', value: 'US/Eastern' },
    { label: 'Mountain Time (US&Canada)', value: 'US/Mountain' },
    { label: 'Indiana (East)', value: 'America/Indianapolis' },
    { label: 'Saskatchewan', value: 'Canada/Saskatchewan' },
    { label: 'Arizona', value: 'US/Arizona' },
    { label: 'Pacific Time (US&Canada)', value: 'US/Pacific' },
    { label: 'Alaska', value: 'US/Alaska' }
  ];
  const [value, setValue] = useState(options[0]);
  useEffect(() => {
    payload?.data?.timeZone &&
      setValue(
        options.filter((item) => item.value === payload?.data?.timeZone)[0]
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);
  const submit = (data) => {
    closeModal();
    if (data?.apiId === 'saveTerminalApi') {
      if (data?.data?.terminalId) updateTerminal(payload);
      else saveTerminal(payload);
    } else if (data?.apiId === 'saveSubDepartmentApi') {
      if (data?.data?.subDepartmentId) updateSubDepartment(payload);
      else saveSubDepartment(payload);
    } else if (data?.apiId === 'saveCompanyData' || data?.apiId === 'addSaveFleetCompany' || data?.apiId === 'editSaveFleetCompany') {
      saveCompanyData({
        apiId: data?.apiId,
        data: data.data,
        callback: callback,
        successCallback
      });
    } else if (data?.apiId === 'saveRegistration') {
      let sendData = data.data;
      sendData.data.timeZone = data.data.timeZone;
      saveRegistration({
        apiId: 'saveRegistration',
        data: sendData,
        callback: callback
      });
    }
  };
  return (
    <Styled.PopupUpload>
      <p className="timeZone">
      {i18n.t('common.timeZone')}
      </p>
      <Select
        value={value}
        onChange={(event) => {
          setValue(event);
        }}
        suggestions={options}
      />
      <FormStyle.default.ButtonsWrapper className="buttonWrapper">
        <Button
          type="reset"
          className="buttonStyle"
          label={i18n.t('common.cancel')}
          onClick={() => {
            closeModal();
            registrationcancelPopup && registrationcancelPopup(326)
          }}
        />
          <Button
          type="submit"
          className="buttonStyle"
          label={i18n.t('common.save')}
          onClick={() => {
            payload.data['timeZone'] = value?.value;
            submit(payload);
          }}
        />
      </FormStyle.default.ButtonsWrapper>
    </Styled.PopupUpload>
  );
};

export default Timezone;
