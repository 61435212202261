import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Field } from 'formik';
import ReactDatePicker from 'react-datepicker';
import { getDateLocale } from '@/utils/utils';
import { UI_DATE_LOWER_FORMAT } from '@/config/constants/static';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Select = lazy(() => import(`@/components/UI/Select/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));

const RenderComponent = ({
  values,
  handleEdit,
  validateForm,
  customProps,
  submitAction,
  cancelAction
}) => {
  const { i18n } = useTranslation();

  return (
    <>
    {window?.$environment?.SERVICE_PROVIDER === 'riskTheory' &&  
    <FormStyle.default.FieldsWrapper className="csafield vertical">
        <Label variant="body1" htmlFor="driver">
          {i18n.t('common.vehcile')}
        </Label>
        <FormStyle.default.TextWrapper>
          <Field
            name="vehicleName"
            onChange={(event) => {
              handleEdit(event, { field: 'vehicleId' });
            }}
            id="vehicleName"
            as={Select}
            placeholder={i18n.t('common.pleaseSelect')}
            value={values.vehicleId}
            suggestions={customProps?.vehicleListDropDown}
          />
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>}
      <FormStyle.default.FieldsWrapper className="csafield vertical">
        <Label variant="body1" htmlFor="driver">
          {i18n.t('common.driver')}
        </Label>
        <FormStyle.default.TextWrapper>
          <Field
            name="driverName"
            onChange={(event) => {
              handleEdit(event, { field: 'id' });
            }}
            id="driverName"
            as={Select}
            placeholder={i18n.t('common.pleaseSelect')}
            value={values.id}
            suggestions={customProps?.driverList}
          />
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.FieldsWrapper className="csafield vertical">
        <Label variant="body1" htmlFor="startDate">
          {i18n.t('common.dateFrom')}
        </Label>
        <FormStyle.default.TextWrapper>
          <Field
            id="startDate"
            name="startDate"
            onChange={(event) => {
              handleEdit(event, { field: 'startDate', type: 'date' });
            }}
            as={ReactDatePicker}
            locale={getDateLocale()}
            placeholderText={UI_DATE_LOWER_FORMAT}
            value={values.startDate}
            showPopperArrow={false}
            showTimeSelect={false}
            maxDate={new Date()}
            showDisabledMonthNavigation
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            isClearable
          />
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.FieldsWrapper className="csafield vertical">
        <Label variant="body1" htmlFor="endDate">
          {i18n.t('common.dateTo')}
        </Label>
        <FormStyle.default.TextWrapper>
          <Field
            id="endDate"
            name="endDate"
            onChange={(event) => {
              handleEdit(event, { field: 'endDate', type: 'date' });
            }}
            as={ReactDatePicker}
            locale={getDateLocale()}
            placeholderText={UI_DATE_LOWER_FORMAT}
            value={values.endDate}
            showPopperArrow={false}
            showTimeSelect={false}
            maxDate={new Date()}
            showDisabledMonthNavigation
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            isClearable
            minDate={values.startDate ? new Date(values.startDate) : ''}
          />
        </FormStyle.default.TextWrapper>
      </FormStyle.default.FieldsWrapper>
      <FormStyle.default.ButtonsWrapper className="vertical-button">
        <Button
          type="submit"
          label={i18n.t('common.view')}
          onClick={(e, d) => {
            const validateResponse = validateForm();
            validateResponse.then((data) => {
              submitAction(data);
            });
          }}
        />
        <Button
          className=""
          type={'reset'}
          label={i18n.t('common.clear')}
          onClick={() => {
            cancelAction();
          }}
        />
      </FormStyle.default.ButtonsWrapper>
    </>
  );
};

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  hasApiStatus: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};

export default RenderComponent;
