import styled from 'styled-components';
import * as Parent from '../Base/style';
import { getImagePath } from '../../../utils/utils';

export const FooterContainer = styled(Parent.FooterContainer)`
${({ theme, style }) => `
background:  #42505A;
`}
`;
export const FooterContacts = styled(Parent.FooterContacts)`
${({ theme }) => `
.footerContactItems{
  padding-left: 24%;
}

`}
`;
export const FooterCopy = styled(Parent.FooterCopy)`
${({ theme }) => `
#privacyCheck {
  color: #b0b0b0 !important;
}
`}
`;
