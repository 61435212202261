import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import RenderComponent from './RenderComponent';

import { hasValue } from '../../../../../utils/utils';
import { phoneRegExp, emailRegExp } from '../../../../../utils/constants';
const layout = window?.$environment?.CURRENT_LAYOUT;

const Form = lazy(() => import(`../../../../../components/UI/Form/${layout}`));
const FleetManagerForm = ({
  apiStatus,
  clearApiStatus,
  states,
  addFleetManagerInfo,
  getFleetManagerInfo,
  getCompanyInfoApi
}) => {
  const { i18n } = useTranslation();
  const apiId = 'addFleetManager';
  const hasApiStatus = hasValue(apiStatus.status, 'api', apiId);
  return (
    <div>
      <div className="formLogin forgotPassword">
        <Form
          type="server"
          direction="column"
          initialValues={{
            firstName: getFleetManagerInfo?.firstName
              ? getFleetManagerInfo.firstName
              : '',
            lastName: getFleetManagerInfo?.lastName
              ? getFleetManagerInfo.lastName
              : '',
            email: getFleetManagerInfo?.email ? getFleetManagerInfo?.email : '',
            userPhone: getFleetManagerInfo?.userPhone
              ? getFleetManagerInfo.userPhone
              : '',
            password: ''
          }}
          RenderComponent={RenderComponent}
          handleOnSubmit={(fields) => {
            if (getCompanyInfoApi?.companyId) {
              fields.companyId = getCompanyInfoApi?.companyId;
            }

            delete fields['confPassword'];
            addFleetManagerInfo({
              id: 'addFleetManager',
              data: fields
            });
          }}
          customProps={{
            hasApiStatus,
            clearApiStatus,
            states
          }}
          validationSchema={Yup.object().shape({
            firstName: Yup.string()
              .required(i18n.t('common.fieldRequiredMessage'))
              .test(
                'len',
                i18n.t('Please enter value between 2 and 30 characters'),
                (val) => val && val.length > 1 && val.length < 31
              ),
            lastName: Yup.string()
              .required(i18n.t('common.fieldRequiredMessage'))
              .test(
                'len',
                i18n.t('Please enter value between 2 and 30 characters'),
                (val) => val && val.length > 1 && val.length < 31
              ),
            email: Yup.string()
              .matches(emailRegExp, i18n.t('common.emailValidationMessage'))
              .required(i18n.t('common.fieldRequiredMessage')),
            userPhone: Yup.string()
              .matches(phoneRegExp, i18n.t('common.validNumFormat'))
              .required(i18n.t('common.fieldRequiredMessage')),
            password: Yup.string().required(
              i18n.t('common.fieldRequiredMessage')
            ),
            confPassword: Yup.string()
              .required(i18n.t('common.fieldRequiredMessage'))
              .oneOf([Yup.ref('password'), null], 'Passwords must match')
          })}
        />
      </div>
    </div>
  );
};

export default FleetManagerForm;

FleetManagerForm.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  requestForgotPassword: PropTypes.func.isRequired,
  callback: PropTypes.func,
  clearApiStatus: PropTypes.func.isRequired
};
