const initialState = {};

export const partsList = {
  state: { ...initialState },
  reducers: {
    getSuccess(state, payload) {
      return { ...payload };
    },
    getFailed(state) {
      return { ...state };
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload, rootState) {
      try {
        const getApi = await import(
          `../../../config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('getPartsApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject,
            data: payload.data
          },
          id: payload.apiId
        });
        if (response) {
          response.content.map(
            (item) => (item['cost'] = item.cost?.toFixed(2))
          );

          response && this.getSuccess(response);
        }
      } catch (error) {
        this.getFailed();
      }
    },
    async saveParts(payload, root) {
      try {
        const getApi = await import(
          `../../../config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('savePartsApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject,
            data: payload.data
          },
          id: payload.apiId
        });
        response &&
          dispatch.partsList.fetchData({
            data: {
              displayLength: '15',
              displayStart: '1',
              sortCol: 'partName',
              sortDir: 'asc'
            }
          });
        payload.callback &&
          payload.callback('success', payload.i18n.t('common.partAdded'));
      } catch (error) {
        payload.callback && payload.callback('error', error.message, payload);
      }
    },
    async updateParts(payload, root) {
      try {
        const getApi = await import(
          `../../../config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('updatePartsApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject,
            data: payload.data
          },
          id: payload.apiId,
          callback: payload.callback
        });
        response &&
          dispatch.partsList.fetchData({
            data: {
              displayLength: '15',
              displayStart: '1',
              sortCol: 'partName',
              sortDir: 'asc'
            }
          });
        payload.callback &&
          payload.callback('success', 'Parts updated successfully');
      } catch (error) {
        payload.callback && payload.callback('error', error.message, payload);
      }
    },
    async handleDeleteAlert(payload, root) {
      try {
        const getApi = await import(
          `../../../config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('deletePartsApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...apiObject,
              url: apiObject.url.replace('{id}', payload.data.id)
            }
          },
          callback: payload.callback,
          id: payload.apiId
        });
        dispatch.partsList.fetchData({
          data: {
            displayLength: '15',
            displayStart: '1',
            sortCol: 'partName',
            sortDir: 'asc'
          }
        });
        payload.callback &&
          payload.callback('success', 'Parts deleted successfully');
      } catch (error) {}
    }
  })
};
