import Box from '@material-ui/core/Box';
import styled from 'styled-components';

const Wrapper = styled(Box)`
${({ theme }) => `
  .contractWrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1%;
    font-size: 24px;
    color: ${theme.colors.PRIMARY_CONTRAST};
    .buttonStyle {
      margin-top: -9px;
      font-size: 20px;
    }
    .contractExpiry {
      text-transform: uppercase;
      color: ${theme.colors.LINK_COLOR};
      padding-right: 10px;
    }
      .CRMcontractExpiry{
      font-size:20px;
      }
  }
  .fieldSection {
    flex: 0 0 46%;
  }
  .paymentInfo {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1% 0;
  }
 `}
`;

const TableWrapper = styled(Box)`
.contractHistorytitle {
  margin-top: 8px;
  margin-bottom: 11px !important;
}
.contractHistory {
  table {
    min-width: auto;
    border: 0;
  }
  .MuiTableCell-root{
    border-bottom: 0;
  }
  // .MuiTableCell-head{
  //   font-size: 18px;
  // }
  .MuiTableRow-root{
    background-color: transparent !important;
  }
  // .MuiTableBody-root .MuiTableCell-root{
  //   font-size: 18px;
  //   border-bottom: 0;
  // }
}
.tcSection {
	height: 400px;
	overflow-y: scroll;
}
.scrollbar {
  min-height: 350px;
  color: #fff;
  padding-right: 4%;
}
#scroll::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: rgba(216, 216, 216, 0.08);
  opacity: 0.08;
}

#scroll::-webkit-scrollbar {
  display: block;
	width: 10px;
	background-color: rgba(216, 216, 216, 0.08);
}

#scroll::-webkit-scrollbar-thumb {
  height: 54px;
  background-color: ${window.$environment.WHITE_THEME ? '#5e5f67'  : '#d8d8d8'};
}
`;

const Styled = { Wrapper, TableWrapper };

export default Styled;
