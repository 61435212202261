import React, { useEffect, lazy, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useHistory } from 'react-router-dom';
import useTheme from '@material-ui/core/styles/useTheme';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Content from './Component';

import ParentStyled from './style';
import { loadStyle, hasValue, hasEnabledFeature } from '@/utils/utils';
const Breadcrumb = lazy(() => import(`@/components/Breadcrumb`));

let Styled = loadStyle(ParentStyled, 'pages/web/Suspended');

const Title = lazy(() => import(`@/components/Title`));

const Suspended = ({ updateSuspendedCard, callback, ...rest }) => {
  const history = useHistory();
  const theme = useTheme();
  const i18n = useTranslation();

  const contentProps = {
    Styled,
    i18n,
    history,
    updateSuspendedCard,
    callback,
    ...rest
  };

  return (
    <>
      <Title header="suspended.listHeading" i18n={i18n} />
      <Box style={{ ...theme.palette.contentStyle }}>
        <Content {...contentProps} />
      </Box>
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests
  };
};
const mapDispatch = (dispatch) => {
  return {
    updateSuspendedCard: (data) => dispatch.companyData.updateSuspendedCard(data)
  };
};

export default connect(mapState, mapDispatch)(Suspended);
