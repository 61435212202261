import styled from 'styled-components';
import Parent, { FormStyle as ParentStyle } from '../Base/serverStyle';

const TitleWrapper = styled(ParentStyle.TitleWrapper)``;
const ButtonsWrapper = styled(ParentStyle.ButtonsWrapper)``;
const FieldsWrapper = styled(ParentStyle.FieldsWrapper)``;
const TextWrapper = styled(ParentStyle.TextWrapper)`
`;
const TextareaWrapper = styled(ParentStyle.TextareaWrapper)``;
const CheckBoxWrapper = styled(ParentStyle.CheckBoxWrapper)``;
const FormWrapper = styled(Parent.FormWrapper)`
${({ theme }) => `
.MuiFormLabel-root {
  font-size: ${theme.palette.formlabelFont};
  // font-family: ${theme.palette.tableFont};
}
.react-datepicker__navigation--next{
  border-left-color: #000;
}
.react-datepicker__day--disabled {
  pointer-events: none;
}
.react-datepicker-wrapper input {
  background: ${theme.colors.BACKGROUND} none repeat scroll 0 0;
  border: 0;
  font-family: ${theme.palette.tableFont};
  font-size: 16px;
}
li.react-datepicker__time-list-item {
  font: 12px/1.428 Arial, "Geneva CY", sans-serif;
}
.react-datepicker__month-select,
.react-datepicker__year-select,
.react-datepicker__current-month {
  background: #cccccc !important;
}
.react-datepicker__navigation--previous {
  border-right-color: #000;
}
.react-datepicker__day{
  line-height: 1.6em;
}
.react-datepicker__navigation--next--disabled,
.react-datepicker__navigation--next--disabled:hover {
  border-left-color: #222;
}
.react-datepicker__day {
  background: #e6e6e6 !important;
  border: 1px solid #d3d3d3 !important;
  color: #555555;
}
.react-datepicker__day--today {
  border: 1px solid #fcefa1!important;
  background: #fbf9ee!important;
  color: #363636!important;
  font-weight: normal;
}
`}
`;
const FormStyle = {
  TitleWrapper,
  ButtonsWrapper,
  FieldsWrapper,
  TextWrapper,
  TextareaWrapper,
  CheckBoxWrapper
};

export const Styled = {
  FormWrapper
};
export default FormStyle;
