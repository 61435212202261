import React, { useState, useEffect, lazy } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ViewForm from './Form';
import moment from 'moment';
import Box from '@material-ui/core/Box';

import { hasValue, getImagePath, getLastQuaters, hasEnabledFeature } from '@/utils/utils';
import { imageUrls } from '@/config/constants/keys';
import { UI_LOGBOOK_DATE, UI_DATE_FORMAT } from '@/config/constants/static';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const CheckBox = lazy(() => import(`@/components/UI/Toggle/${layout}`));
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Export = lazy(() => import(`@/components/UI/Export/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`).default;
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));
const Input = lazy(() => import(`@/components/UI/Input/${layout}`));

const Index = ({ theme, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Component {...contentProps} />
    </Box>
  );
};
export default Index;

const Component = ({
  apiStatus,
  callback,
  history,
  Styled,
  fetchIftaMileage,
  getIftaAuditCSV,
  handleExportIfta,
  generateReport,
  ifta,
  filterList,
  vehicles,
  iftaApiId,
  logBook,
  commonProps,
  getDocumentToken,
  getDocumentTokenApiId,
  isOldView,
  ...rest
}) => {
  const { i18n } = useTranslation();
  const [hideStates, setHideStates] = useState(true);
  const [pageination, setPageination] = useState(false);
  const hasApiStatus = hasValue(apiStatus.status, 'api', fetchIftaMileage);
  const beBaseUrl = window?.$environment.BE_BASE_URL;
  const information =
    hasApiStatus && hasApiStatus.errorMessage ? (
      <Message type="error" s message={i18n.t(hasApiStatus.errorMessage)} />
    ) : (
      i18n.t('common.tableEmptyMessage')
    );
  useEffect(() => {
    filterList(hideStates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideStates]);
  const lastQuater = getLastQuaters(true)
  const [values, setValues] = useState({
    id: '',
    fromOdometer: 'true',
    dateTimeFrom: lastQuater[0].format(UI_LOGBOOK_DATE),
    dateTimeTo: lastQuater[1].format(UI_LOGBOOK_DATE)
  });
  useEffect(() => {
    let url = ifta?.downloadUrl
      ? `${beBaseUrl.replace(/\/api/g, '')}${ifta.downloadUrl}?token=${logBook?.documentToken
      }`
      : '';
    document.getElementById('downloadTruckGpsHistory').src = url;
  }, [ifta?.downloadUrl]);
  const exportIfta = (data) => {
    getDocumentToken({ apiId: getDocumentTokenApiId });
    handleExportIfta({
      id: 'exportIfta',
      data: { ...data }
    });
  };
  const columns = [
    {
      id: 'state',
      numeric: false,
      disablePadding: true,
      label: i18n.t('iftaMileage.Jurisdiction'),
      needSorting: true
    },
    {
      id: 'totalMiles',
      numeric: true,
      disablePadding: false,
      label: i18n.t('iftaMileage.totalMiles'),
      needSorting: true
    },
    ...(hasEnabledFeature('taxableMilesViewEnabled') ? [{
      id: 'totalTaxableMiles',
      numeric: true,
      disablePadding: false,
      label: i18n.t('iftaMileage.totalTaxableMiles'),
      needSorting: true
    }] : []),
    {
      id: 'fuelUsage',
      numeric: true,
      disablePadding: false,
      label: i18n.t('iftaMileage.fuelUsage'),
      needSorting: true
    }
  ];

  const fetchReport = (fields) => {
    getDocumentToken({ apiId: getDocumentTokenApiId });
    generateReport({
      apiId: "fetchIFTAReport",
      data: {
        id: fields.vehicleType == "inactive" && fields.id.label == 'All' ? '0' : fields.id.value,
        fromOdometer: fields.fromOdometer,
        dateTimeFrom: fields.dateTimeFrom?.replace(
          /(\d\d)\/(\d\d)\/(\d{4})/,
          '$3-$1-$2'
        ),

        dateTimeTo: fields.dateTimeTo?.replace(
          /(\d\d)\/(\d\d)\/(\d{4})/,
          '$3-$1-$2'
        )
      },
      callback
    })
  }

  const generateRows = () => {
    let updatedList = ifta && ifta.filteredList ? ifta.filteredList : []
    return updatedList || [];
  };
  const tableData = (fields) => {
    setValues({ ...fields });
  };
  return (
    <Styled.Wrapper>
      {isOldView ? (
        <p className="searchTitle">{i18n.t(`iftaMileage.truck`)}</p>
      ) : (
        <p className="searchTitle">{i18n.t(`iftaMileage.search`)}</p>
      )}
      <Styled.TopWrapper>
        <ViewForm
          apiStatus={apiStatus}
          history={history}
          callback={callback}
          Styled={Styled}
          hasApiStatus={hasApiStatus}
          vehicles={vehicles}
          iftaApiId={iftaApiId}
          fetchIftaMileage={(data) => {
            setHideStates(true)
            setPageination(true);
            fetchIftaMileage(data)
          }}
          handleExportIfta={handleExportIfta}
          fetchReport={fetchReport}
          tableData={(fields) => tableData(fields)}
          commonProps={commonProps}
          isOldView={isOldView}
          i18n={i18n}
        />
      </Styled.TopWrapper>
      <Styled.TableWrapper>
        <div className={`iftaErrorsSection ${ifta.iftaErrors ? 'showIftaError' : ''}`}>
          <img src={getImagePath(imageUrls.confirmIcon)} alt="warn"></img>
          We found error(s) for this date range. View the report {` `}
          <a onClick={(e) => {
            getIftaAuditCSV({ id: "iftaErrorsCsv" })
          }} >here</a>
        </div>
        <div className="exportSection">
          <CheckBox
            type="NormalGrey"
            label={i18n.t(`iftaMileage.hideLabel`)}
            checked={hideStates}
            onClick={(event) => {
              setHideStates(!hideStates);
            }}
            className="enable"
          />
          {!isOldView && (
            <Export
              src={imageUrls.exportIcon}
              filename={'users'}
              toolTip={i18n.t('iftaMileage.tooltip')}
              className="exportContainer"
              buttonName={i18n.t('iftaMileage.tooltip')}
              onClick={() =>
                exportIfta({
                  id: values?.id?.value || '',
                  fromOdometer: values.fromOdometer,
                  dateTimeFrom: values.dateTimeFrom?.replace(
                    /(\d\d)\/(\d\d)\/(\d{4})/,
                    '$3-$1-$2'
                  ),
                  dateTimeTo: values.dateTimeTo?.replace(
                    /(\d\d)\/(\d\d)\/(\d{4})/,
                    '$3-$1-$2'
                  )
                })
              }
            />
          )}
        </div>
        <div className="quickFilters"></div>
        <Table
          type="client"
          defaultSortBy={{ sortBy: 'state', order: 'asc' }}
          title=""
          columns={[...columns]}
          data={generateRows()}
          emptyMessage={information}
          itemsPerPage={15}
          actions={[]}
          i18n={i18n}
          pageinationReset={pageination}
          pagenationClicked={(e) => {
            setPageination(!e);
          }}
        />
      </Styled.TableWrapper>
      {isOldView ? (
        <>
          <FormStyle.FieldsWrapper className="fieldSection">
            <Label variant="body1">{i18n.t('common.total')}</Label>
            <FormStyle.TextWrapper>
              <Input value={ifta?.total} disabled />
            </FormStyle.TextWrapper>
          </FormStyle.FieldsWrapper>
          <Export
            src={imageUrls.exportIcon}
            filename={'users'}
            toolTip={i18n.t('iftaMileage.tooltip')}
            className="exportContainer"
            buttonName={i18n.t('iftaMileage.tooltip')}
            onClick={() =>
              exportIfta({
                id: values?.id?.value || '',
                fromOdometer: values.fromOdometer,
                dateTimeFrom: values.dateTimeFrom?.replace(
                  /(\d\d)\/(\d\d)\/(\d{4})/,
                  '$3-$1-$2'
                ),
                dateTimeTo: values.dateTimeTo?.replace(
                  /(\d\d)\/(\d\d)\/(\d{4})/,
                  '$3-$1-$2'
                )
              })
            }
          />
        </>
      ) : (
        <p className="total">
          {i18n.t('common.total')}
          <span className="amount">{Math.round((ifta.total + + Number.EPSILON) * 100) / 100}</span>
        </p>
      )}

      <iframe id="downloadTruckGpsHistory" style={{ display: 'none' }}></iframe>
    </Styled.Wrapper>
  );
};

Component.propTypes = {
  history: PropTypes.object.isRequired,
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  fetchIftaMileage: PropTypes.func.isRequired
};
