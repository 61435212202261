import React, { useState, useEffect, lazy } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import PdfView from './PdfViewer';
import { getLogsInfoDisplay, hasRestrictedRole, isDriver } from '@/utils/utils';
import Box from '@material-ui/core/Box';
import { pageUrls, ROLES } from '@/config/constants/keys';
import TopActions from './TopActions';
import {
  UI_DATE_FORMAT_WITH_TIME_UTC,
  UI_DATE_FORMAT
} from '@/config/constants/static';
import Violations from '../Dashboard/Notfications';
import { useParams } from 'react-router-dom';
const layout = window?.$environment?.CURRENT_LAYOUT;

const Map = lazy(() => import(`@/components/GoogleMap/${layout}`));
const Label = lazy(() => import(`@/components/UI/Label/${layout}`));
const Link = lazy(() => import(`@/components/UI/Link`));
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));

const Message = lazy(() => import(`@/components/UI/Message/${layout}`));

const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Component {...contentProps} />
    </Box>
  );
};
export default Index;

const Component = ({
  apiStatus,
  callback,
  fetchLogBookView,
  getLogbookEvents,
  updateShowEvents,
  i18n,
  history,
  drivers,
  dates,
  unSignedDates,
  logBook,
  handleLogBookStatus,
  clearApiStatus,
  currentDriver,
  breadCrumbForMap,
  viewApiId,
  fetchLogbookBreadcrumb,
  fetchLogbookBreadcrumbApiId,
  logId,
  dailyLogDownloadData,
  showMap,
  Styled,
  violationListing,
  getDocumentToken,
  formValues,
  setFormValues,
  topAction,
  newDate,
  setNewDate,
  mapRequired,
  regeneratePdf
}) => {
  const match = useParams();
  useEffect(() => {
    logBook?.selectedDailyLog?.vehicleId &&
      fetchLogbookBreadcrumb({
        apiId: fetchLogbookBreadcrumbApiId,
        data: {
          id: logBook.selectedDailyLog.vehicleId,
          day: logBook.selectedDailyLog.day
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logBook?.selectedDailyLog?.vehicleId]);

  useEffect(() => {
    setFormValues({
      driverId: logBook.selectedDailyLog.driverId,
      driverName: logBook.selectedDailyLog.driverName,
      type: 'DAILY_LOG'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logBook.selectedDailyLog.driverId]);

  const generateRows = () => {
    const updatedList = logBook?.violationsListDto?.map((item) => {
      const formattedDate = moment(item.date).format(UI_DATE_FORMAT_WITH_TIME_UTC);
      item.date = formattedDate;
      if (typeof item.description === 'string') {
        item.description = (
          <div id={item?.id}>
            <div style={{fontWeight: "bold"}}>{item.description}</div>
            <div style={{fontSize: "14px"}}>{formattedDate} (UTC)</div>
          </div>
        );
      }        
      return item;
    });
    return updatedList || [];
  };
  
  const columns = [
    {
      id: 'description',
      numeric: false,
      disablePadding: true,
      label: i18n.t('common.description'),
      needSorting: false,
    },
    // {
    //   id: 'date',
    //   numeric: false,
    //   disablePadding: false,
    //   label: i18n.t('logs.dateInUtc'),
    //   needSorting: false,
    // }
  ];
  const isMapRequired = mapRequired === undefined || mapRequired;
  const [currentIndex, setCurrentIndex] = useState(0);
  return (
    <Styled.LogBookContainer className="">
      {topAction && (
        <TopActions
          {...{
            apiStatus,
            fetchLogBookView,
            i18n,
            viewApiId,
            history,
            callback,
            drivers,
            dates,
            unSignedDates,
            logBook,
            currentDriver,
            fetchLogbookBreadcrumb,
            fetchLogbookBreadcrumbApiId,
            setFormValues,
            formValues,
            setNewDate,
            getDocumentToken,
            handleLogBookStatus,
            clearApiStatus,
            dailyLogDownloadData,
            Styled,
            regeneratePdf
          }}
        />
      )}
      {window.$environment.CURRENT_LAYOUT !== 'SpireonNew' && (
        <Label className="logsInfoDisplay">
          {getLogsInfoDisplay(
            i18n,
            logBook.selectedDailyLog.driverName,
            logBook.selectedDailyLog.day
          )}
        </Label>
      )}
      <div className="displayContainer">
        <div className='linkButtonsContainer'>
          <div className="linkContainer">
              <div className="prev">
                <Link
                  className="uppercase"
                  onClick={() => {
                    if (currentIndex < logBook?.dailyLogs.length - 1) {
                      setCurrentIndex(currentIndex + 1);
                      const fetchLogBookViewHandler = (date) => {
                        fetchLogBookView({
                          apiId: viewApiId,
                          callback: callback,
                          data: { data: { date, driverId: currentDriver.value } },
                        })
                      }
                      fetchLogBookViewHandler(logBook?.dailyLogs[currentIndex + 1]?.day || newDate);
                      fetchLogbookBreadcrumb({
                        apiId: 'fetchLogbookBreadcrumb',
                        data: {
                          id: logBook?.selectedDailyLog?.vehicleId,
                          day: logBook?.dailyLogs[currentIndex + 1]?.day || newDate
                        }
                      });
                    }
                  }}
                >
                  {i18n.t('common.previous')}
                </Link>
              </div>
              <div className="next">
                <Link
                  className="uppercase"
                  onClick={() => {
                    if (currentIndex > 0) {
                      setCurrentIndex(currentIndex - 1);
                      const fetchLogBookViewHandler = (date) => {
                        fetchLogBookView({
                          apiId: viewApiId,
                          callback: callback,
                          data: { data: { date, driverId: currentDriver.value } },
                        })
                      }
                      fetchLogBookViewHandler(logBook?.dailyLogs[currentIndex - 1]?.day || newDate);
                      fetchLogbookBreadcrumb({
                        apiId: 'fetchLogbookBreadcrumb',
                        data: {
                          id: logBook?.selectedDailyLog?.vehicleId,
                          day: logBook?.dailyLogs[currentIndex - 1]?.day || newDate
                        }
                      });
                    }
                  }}
                >
                  {i18n.t('common.next')}
                </Link>
              </div>
            </div> 

           <div className="linkContainer commonLinks logEditButtons">
              <div>
                <Link
                  className="uppercase logEdit logEditButton"
                  onClick={() => {
                    history.push(
                      logBook?.selectedDailyLog?.status === 'SIGNED'? hasRestrictedRole([ROLES.VIEW_ONLY_FM]) ? pageUrls.signedLogsView.replace(
                        ':id',
                        logBook?.selectedDailyLog?.idPub
                      ) :
                        pageUrls.logsEdit.replace(
                          ':id',
                          logBook?.selectedDailyLog?.idPub
                        ): 
                        pageUrls.logsView.replace(
                          ':id',
                          logBook?.selectedDailyLog?.id
                        )
                    );
                  }}
                >
                  {(hasRestrictedRole([ROLES.VIEW_ONLY_FM]) || (window.$environment.SERVICE_PROVIDER === 'spireon' && logBook?.selectedDailyLog?.status != 'SIGNED')) ? i18n.t('common.view') : i18n.t('common.edit')}
                </Link>
              </div>
              {!isDriver() && <div>
                <Link
                  className="uppercase dailyDocument logEditButton"
                  href={`${pageUrls.dailyDocuments}?driver=${logBook?.selectedDailyLog?.driverId
                    }&day=${moment(logBook?.selectedDailyLog?.day).format(
                      UI_DATE_FORMAT
                    )}`}
                >
                  {i18n.t('logs.viewDocument')}
                </Link>
              </div>}
              <div>
                <Link
                  className="uppercase history logEditButton"
                  onClick={() => {
                    history.push(
                      pageUrls.logsHistory.replace(
                        ':id',
                        logBook?.selectedDailyLog?.idPub
                      )
                    );
                  }}
                >
                  {i18n.t('common.history')}
                </Link>
              </div>
            </div>
        </div>

        <div className="leftWrapper">
         <div className='pdfViolationsContainer'>
          {logBook.dailyLogs.length && (
            // <div className='pdfLinkContainer'>
            <PdfView
              currentIndex={currentIndex}
              Styled={Styled}
              setCurrentIndex={setCurrentIndex}
              list={logBook?.dailyLogs}
              i18n={i18n}
              driver={currentDriver.value}
              date={newDate}
              documentToken={logBook?.documentToken}
              updateShowEvents={updateShowEvents}
              getLogbookEvents={(idPub) => getLogbookEvents({
                apiId: 'LoadMoreEvents',
                callback: callback,
                data: { id: idPub }
              })}
              fetchLogBookView={(date) =>
                fetchLogBookView({
                  apiId: viewApiId,
                  callback: callback,
                  data: { data: { date, driverId: currentDriver.value } },
                })
              }
              fetchLogbookBreadcrumb={fetchLogbookBreadcrumb}
              id={logBook?.selectedDailyLog?.vehicleId}
              eventsData={logBook?.eventsData}
              getDocumentToken={getDocumentToken}
              selectedDailyLog={logBook?.selectedDailyLog}
              regeneratePdf={({date, callbackFuntion}) =>
                regeneratePdf({
                  apiId: 'fetchLogbookBreadcrumb',
                  callback: callback,
                  data: { data: { date: newDate, driverId: currentDriver.value } },
                  newCallback: () =>   {
                    fetchLogBookView({
                      apiId: 'fetchLogBookView',
                      callback: callback,
                      data: { data: { driverId: currentDriver.value , date: newDate} },
                      history
                    });
                    callbackFuntion();
                  }
                })
              }
            />
            // </div>
          )}

        <div className='violationsContainer'>
         {violationListing ? (
            <Violations
              potentialViolations={logBook?.violationsListDto || []}
            />
          ) : (
            <Styled.ViolationContainer>
              <div className="heading">{i18n.t('logs.violations')}</div>
              {logBook?.violationsListDto.length ? (
                <Table
                  type="client"
                  title=""
                  columns={[...columns]}
                  data={generateRows()}
                  actions={[]}
                  itemsPerPage={window.$environment.SERVICE_PROVIDER === 'spireon' ? logBook?.violationsListDto.length : 10}
                  emptyMessage={''}
                  i18n={i18n}
                  noPagination={window.$environment.SERVICE_PROVIDER === 'spireon'}
                  isViolationsTable={true}
                />
              ) : (
                <Message message={i18n.t('logs.noViolation')} type="info" />
              )}
            </Styled.ViolationContainer>
          )}

          <Styled.MapContainer>
              <Styled.MapWrapper>
                {isMapRequired && (
                  <Map
                    height="350px"
                    type={'logBookBreadCrumbMap'}
                    data={breadCrumbForMap}
                  ></Map>
                )}
              </Styled.MapWrapper>
          </Styled.MapContainer>
          </div>
         </div>
        </div>
        <div className="rightSide">
        </div>
      </div>
    </Styled.LogBookContainer>
  );
};

Component.defaultProps = {
  drivers: {}
};

Component.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  manageLogList: PropTypes.object.isRequired,
  logBook: PropTypes.object.isRequired,
  handleLogBookStatus: PropTypes.func.isRequired,
  clearApiStatus: PropTypes.func.isRequired,
  fetchLogbookBreadcrumb: PropTypes.func.isRequired,
  breadCrumbForMap: PropTypes.array.isRequired
};
