import styled from 'styled-components';
import Box from '@material-ui/core/Box';

import { getStyle } from '../../../../utils/utils';

const ExportContainer = styled(Box)`
  ${({ theme}) => `
  position: relative;
  align-items: center;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex; 
  .tooltiptext {
    visibility: hidden;
    width: 120px;
    background: ${getStyle(
      '',
      (theme.palette.primary || {}).main,
      theme.colors.PRIMARY_MAIN
    )};
    color: ${theme.colors.WHITE};
    text-align: center;
    padding: 2px 0;
    border-radius: 0px;
    border: solid 1px ${theme.colors.WHITE};
    position: absolute;
    z-index: 1;
    right: -30px;
    top: 70px;
  }

  :hover .tooltiptext {
    visibility: visible;
  }
  `}
`;

export default ExportContainer;
