import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import RenderComponent from './RenderComponent';

import { hasValue } from '../../../../../utils/utils';
const layout = window?.$environment?.CURRENT_LAYOUT;

const Form = lazy(() => import(`../../../../../components/UI/Form/${layout}`));
const AgreeForm = ({
  apiStatus,
  clearApiStatus,
  getToCheckout,
  addSigneeName,
  getSigneeInfo,
  getCompanyInfoApi
}) => {
  const { i18n } = useTranslation();

  const apiId = 'addCompany';
  const hasApiStatus = hasValue(apiStatus.status, 'api', apiId);
  let name;
  return (
    <div>
      <div className="formLogin forgotPassword">
        <Form
          type="server"
          direction="column"
          initialValues={{
            signeeName: getSigneeInfo ? getSigneeInfo : '',
            agree: ''
          }}
          RenderComponent={RenderComponent}
          handleOnSubmit={(fields) => {
            let obj = {
              companyId: getCompanyInfoApi?.companyId
            };
            addSigneeName({
              id: 'addSignee',
              data: fields.signeeName,
              companyId: obj
            });
            getToCheckout();
          }}
          customProps={{
            hasApiStatus,
            clearApiStatus
          }}
          validationSchema={Yup.object().shape({
            signeeName: Yup.string()
              .required(i18n.t('common.fieldRequiredMessage'))
              .test(
                'len',
                'Must have atleast 4 characters',
                (val) => val && val.length > 3
              ),
            agree: Yup.string().required(i18n.t('common.termsValidation'))
          })}
        />
      </div>
    </div>
  );
};

export default AgreeForm;

AgreeForm.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  clearApiStatus: PropTypes.func.isRequired
};
