import React, { lazy } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import ViewForm from './TopActions';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Export = lazy(() => import(`@/components/UI/Export/${layout}`));
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const BarChart = lazy(() => import(`@/components/UI/Graph/BarGraph/${layout}`));

const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Component {...contentProps} />
    </Box>
  );
};
export default Index;

const LineChart = lazy(() =>
  import(`@/components/UI/Graph/LineGraph/${layout}`)
);
const Component = ({
  apiStatus,
  callback,
  history,
  Styled,
  chat,
  fetchChartData,
  chartData,
  driverIdSuggestion,
  topAction,
  fetchDriverBehaviorDetails,
  driverbehavior
}) => {
  const theme = useTheme();
  const { i18n } = useTranslation();
  return (
    <Styled.Wrapper>
      <Styled.TopWrapper>
        {topAction && (
        <ViewForm
          apiStatus={apiStatus}
          history={history}
          callback={callback}
          Styled={Styled}
          fetchChartData={fetchChartData}
          driverIdSuggestion = {driverIdSuggestion}
          fetchDriverBehaviorDetails={fetchDriverBehaviorDetails}
        />
        )}
      </Styled.TopWrapper>

      <div className="basicMeasures">
        <div className="chartContainer">
          {driverbehavior.chartSuccess && chartData && Object.keys(chartData.data).length !== 0 ? (
            <LineChart
              width={'470px'}
              height={'230px'}
              labels={chartData.labels}
              data={chartData.data}
              title={i18n.t('graphTitles.fuelEconomy')}
              displayLegend={false}
              theme={theme}
              noHyperLink
            ></LineChart>
          ) : (
            <div id="noData">
              <div id="nameWrap">
                <p>{i18n.t('graphTitles.fuelEconomy')}</p>
              </div>
              <div id="msgWrap">
                <p>{i18n.t('common.noDataToPlot')} </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </Styled.Wrapper>
  );
};
// export default Component;

Component.propTypes = {
  history: PropTypes.object.isRequired,
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  fetchViolations: PropTypes.func.isRequired,
  handleExportCsaViolations: PropTypes.func.isRequired
};
