import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Parent from './style';
const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
padding: 0;
.vehicleWrap{
  padding-top: 20px;
}
form {
  justify-content: flex-start;
  .MuiInputBase-root.Mui-disabled{
    border-bottom: none !important;
  }
  .MuiInputBase-input.Mui-disabled{
    opacity: 1;
  }
  .react-datepicker-wrapper input:disabled{
    border-bottom: none !important;
    padding-left: 0;
  }
  .basic-multi-select.select--is-disabled .select__control{
    border-bottom: none !important;
    .select__indicators{
      display: none;
    }
  }
}
.formWrap {
  padding: 30px 5% 0 20px;
}
.policyHeader {
  margin-bottom: 18px !important;
}
.roleSection {
  display: none;
}
.filterField,
.userForm {
  flex: 0 0 50%;
  width: 300px;
}
.spNew {
    flex-direction: column;
    label {
      font-size: 15px;
    }
}
.userHeading,
.notspireonNew {
    display: none;
}
.spireonNew{
  display: block;
  padding: 0;
}
.spireonNewonly {
    display: block;
}
.view-only-label{
  font-size: 16px;
  opacity: 0.5;
}
.spnocycle {
  display: block;
  .MuiFormLabel-root{
  font-size: 15px;
  }
}
.spireonNewonly.topSection{
  height: 20px;
  background-color: #f5f5f5;
  width: 100%;
  position: absolute;
}
.formWrap.spireonNewonly{
  display: flex;
  width: 100%;
  padding: 0;
  padding-left: 2%;
  .userdetailssp,
  .vehicleForsp {
    width: 46%;
  }
}
.spireonDriver {
    font-size: 20px;
    color: #000000 !important;
    text-transform: capitalize;
    background: none;
    padding-left: 0 !important;
    border: 0;
    margin-bottom: 0 !important;
}
.align-left{
  padding-left: 2% !important;
}
`}
`;
const LeftWrapper = styled(Parent.LeftWrapper)`
flex: 0 0 67%;
.driverCheckbox {
  padding-left: 0;
  .normalCheckbox {
    margin: 1px 2% 8px 0;
    label{
      font-weight: normal;
    }
  }
}
`;
const RightWrapper = styled(Parent.RightWrapper)`
${({ theme }) => `
flex: 0 0 33%;
.documentsHeader{
  margin-bottom: 0 !important;
  ul {
    margin-top:0;
  }
}
`}
`;
const documentWrapper = styled(Parent.documentWrapper)`
${({ theme }) => `
padding-right: 5%;
ul {
 margin: 0;
  li {
    .MuiGrid-container .MuiAvatar-rounded {
      width: 27px;
      height: 27px;
    }

  }
}
`}
`;
const submitUser = styled(Parent.submitUser)`
${({ theme }) => `
.submitSection {
    justify-content: flex-end;
    padding: 30px 5%;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    button {
        margin-top: 0;
        min-width: auto;
        padding: 0 15px !important;
    }
    .reset {
      background: transparent !important;
      box-shadow: none;
      color: rgba(0, 0, 0, 0.54) !important;
      font-weight: 500;
      :hover {
        background: transparent !important;
        color: rgba(0, 0, 0, 0.54) !important;
      }
    }
}
`}

`;
const PolicyProcedure = styled(Parent.PolicyProcedure)`
${({ theme }) => `
padding-right: 5%;
ul {
  border: 0 !important;
  margin-top: 0;
}
li {
  border-bottom:0 !important;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  padding: 8px 10px;
  padding-left: 6px;
  &:hover {
    background-color: transparent;
  }
  p:first-child {
   align-self: center;
   color: #6d6d6d;
   font-size: 14px;
   text-align: left;
   min-width: 100px;
   a{
    color: #007aff;
    font-size: 14px;
   }
  }
  p:last-child {
    color: #222;
    font-size: 15px
  }
}
.signedWrapper {
    p {
    text-align: left !important;
  }
  p:first-child {
    color: #222;
    font-size: 15px;
    }
    p:last-child {
      font-size: 12px;
      color: ${theme.colors.LIST_TEXT};
    }
}
`}
`;
const Styled = {
  Wrapper,
  RightWrapper,
  LeftWrapper,
  documentWrapper,
  submitUser,
  PolicyProcedure
};
export default Styled;
