import React, { lazy, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { getImagePath } from '@/utils/utils';
import { imageUrls } from '@/config/constants/keys';

import TopActions from './TopActions';

const layout = window?.$environment?.CURRENT_LAYOUT;

const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));

const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Component {...contentProps} />
    </Box>
  );
};
export default Index;

const Component = ({
  usDotManagement,
  filterSuccess,
  Styled,
  handleDeleteAlert,
  callback,
  options,
  topAction,
  setModalListObject,
  setwithCloseIcon,
  setbackgroundModal,
  closeModal,
  clickedModal,
  pagination,
  setPagination
}) => {
  const { i18n } = useTranslation();

  const columns = [
    {
      id: 'dotNumber',
      numeric: false,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('usDotManagement.usDotNo')
    },
    {
      id: 'carrierName',
      numeric: false,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('usDotManagement.carrierName')
    },
    {
      id: 'mainAddress',
      numeric: false,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('usDotManagement.mainAddress')
    },
    {
      id: 'city',
      numeric: false,
      needSorting: true,
      disablePadding: false,
      label: i18n.t('common.city')
    },
    {
      id: 'state',
      numeric: false,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('common.state')
    },
    {
      id: 'zipCode',
      numeric: false,
      needSorting: true,
      disablePadding: false,
      label: i18n.t('common.zipCode')
    },
    {
      id: 'actions',
      label: i18n.t('Actions')
    }
  ];
  const generateRows = () => {
    return usDotManagement?.filteredList || [];
  };
  useEffect(() => {
    generateRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usDotManagement?.filteredList]);

  const information = i18n.t('common.tableEmptyMessage');

  return (
    <>
      <Styled.Wrapper>
        {topAction && (
          <TopActions
            {...{
              Styled,
              usDotManagement,
              i18n,
              options,
              filterSuccess,
              setPagination
            }}
          />
        )}
        <Styled.TableWrapper>
          <div className="quickFilter"></div>
          <Table
            type="client"
            title=""
            columns={[...columns]}
            data={generateRows()}
            emptyMessage={information}
            actions={[
              {
                icon: (row, index) => {
                  return (
                    <Icon
                      src={getImagePath(imageUrls.edit)}
                      onClick={() => {
                        options(row);
                      }}
                    />
                  );
                }
              },
              {
                icon: (row, index) => {
                  return (
                    <Icon
                      src={getImagePath(imageUrls.delete)}
                      onClick={() => {
                        setwithCloseIcon(false);
                        setbackgroundModal(true);
                        setModalListObject({
                          content: () => (
                            <div className="deleteParent">
                              <div className="imageSection">
                                <img
                                  src={getImagePath(imageUrls.confirmIcon)}
                                  alt="Are you sure"
                                />
                              </div>
                              <h2> {i18n.t('common.sureConfirm')} </h2>
                              <div className="deletePopupBtn">
                                <div className="cancelBtn">
                                  <Button
                                    label={i18n.t('Cancel')}
                                    type={'reset'}
                                    onClick={() => {
                                      closeModal();
                                    }}
                                  ></Button>
                                </div>
                                <div className="deletebtn">
                                  <Button
                                    label={i18n.t('common.deletePopup')}
                                    onClick={() => {
                                      handleDeleteAlert({
                                        id: 'deleteAlert',
                                        data: { id: row.id },
                                        callback: callback,
                                        i18n: i18n
                                      });
                                      closeModal();
                                    }}
                                  ></Button>
                                </div>
                              </div>
                            </div>
                          )
                        });
                        clickedModal();
                      }}
                    />
                  );
                }
              }
            ]}
            defaultSortBy={{ sortBy: 'dotNumber', order: 'asc' }}
            itemsPerPage={10}
            i18n={i18n}
            pageinationReset={pagination}
            pagenationClicked={(e) => {
              setPagination(!e);
            }}
          />
        </Styled.TableWrapper>
      </Styled.Wrapper>
    </>
  );
};

Component.propTypes = {
  filterSuccess: PropTypes.func.isRequired
};
