import React from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import ParentStyled from './style';
import { loadStyle } from '@/utils/utils';
const Wrapper = loadStyle(ParentStyled, 'components/UI/Link');

const LinkWrapper = ({
  style,
  size,
  id,
  key,
  dataValue,
  className,
  type,
  onClick,
  href,
  children,
  newPage,
  tabIndex
}) => {
  const handleOnClick = (e) => {
    !href && onClick && onClick(e);
  };
  return (
    <Wrapper>
      <Link
        className={className}
        type={type}
        size={size}
        disableripple="true"
        onClick={handleOnClick}
        style={style}
        id={id ? id : 'button'}
        key={key}
        data-value={dataValue}
        href={href}
        target={newPage ? "_blank" : ''}
        {...(tabIndex? {tabIndex}: {})}
      >
        {children}
      </Link>
    </Wrapper>
  );
};

LinkWrapper.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object
};

LinkWrapper.defaultProps = {
  label: ''
};

export default LinkWrapper;
