import {
  alertListApi,
  exportAllalertApi,
  alertDeleteApi
} from '../../../config/apis';
import { deleteJsonArrayItemByField } from '../../../utils/utils';

const initialState = {
  list: [],
  filteredList: [],
  selectedRow: { index: -1, row: {} },
  alertMessage: ''
};
const isEs = () => localStorage.getItem(window.$environment?.SERVICE_PROVIDER + 'Language') == 'es'
export const alertList = {
  state: { ...initialState },
  reducers: {
    listSuccess(state, payload) {
      return { ...state, list: [...payload], filteredList: [...payload] };
    },
    listFailed() {
      return { ...initialState };
    },
    messageSuccess(state, payload) {
      return { ...state, alertMessage: payload };
    },
    messageFailed(state) {
      return { ...state, alertMessage: '' };
    },
    filterSuccess(state, list) {
      return { ...state, filteredList: [...list] };
    },
    setSelectedItem(state, data) {
      return { ...state, selectedRow: { ...data } };
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: alertListApi
          },
          id: payload.apiId
        });
        response && this.listSuccess(response);
      } catch (error) {
        this.listFailed();
      }
    },

    async fetchAlertMessageData(payload, rootState) {
      try {
        const getApi = await import(
          `@/config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('fetchAlertMessage');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...apiObject,
              url: apiObject.url.replace('{id}', payload.data.id)
            }
          },
          id: payload.apiId
        });

        response && this.messageSuccess(response);
      } catch (error) {
        this.messageFailed();
      }
    },

    async handleExportAllAlert(payload, rootState) {
      try {
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: exportAllalertApi
          },
          id: payload.id
        });
      } catch (error) {}
    },
    async handleDeleteAlert(payload, rootState) {
      try {
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...alertDeleteApi,
              url: alertDeleteApi.url.replace('{id}', payload.data.data.id)
            }
          },
          callback: payload.callback,
          id: payload.apiId
        });

        this.listSuccess(
          deleteJsonArrayItemByField(
            rootState.alertList.list,
            'id',
            payload.data.data.id
          )
        );
        //remove item
        payload.callback('success', isEs() ? 'Alerta ha sido borrada' :'Alert has been deleted');
      } catch (error) {}
    }
  })
};
