import styled from 'styled-components';
import Parent from '../Base/style';
const SearchWrapper = styled(Parent)`
.clear {
    right: -9px;
    width: 24px;
    height: 24px;
    top: -3px;
    margin-top: 4px;
}
.MuiInputBase-root {
    border: 0;
    background: #efefef none repeat scroll 0 0 !important;
    border-radius: 11px;
}
.MuiInputBase-root .MuiInputBase-input{
    background: #efefef none repeat scroll 0 0 !important;
    border-radius: 0;
    border-top-right-radius: 11px;
    border-bottom-right-radius: 11px;
    font-size: 14px !important;
    margin-left: 11px;
    height: auto;
}
.MuiInputBase-root .MuiInputBase-input::placeholder{
    color: #222 !important;
    opacity: 0.7;
}
.MuiInputBase-root.MuiInput-root{
    height: 23px !important;
    border: 0 !important;
}
#simpleSearchWrapper {
    width: 24%;
}
`;
export default SearchWrapper;
