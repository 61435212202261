import React, { lazy } from 'react';

const layout = window?.$environment?.CURRENT_LAYOUT;

const Search = lazy(() => import(`@/components/UI/SimpleSearch/${layout}`));

const TopActions = ({
  Styled,
  driverStatusList,
  changeRequest,
  filterSuccess,
  setPagination,
  i18n
}) => {
  const selectChange = (e) => {
    changeRequest({ search: e });
    // filterSuccess(e);
  };
  return (
    <Styled.SearchWrapper>
      <Search
        id={'clearSearch'}
        default={[...driverStatusList]}
        onChange={(e) => selectChange(e)}
        width="35%"
       // fromData
       // query={[
        //    'name'
         // ]}
          id='driver_Status_more'
          placeHolder={i18n.t('Driver Name')}
      />
    </Styled.SearchWrapper>
  );
};
export default TopActions;
