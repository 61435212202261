import styled from 'styled-components';
import Parent from '../Base/style';

const Wrapper = styled(Parent.Wrapper)``;
const UploadWrapper = styled(Parent.UploadWrapper)``;
const PopupUpload = styled(Parent.PopupUpload)`
.buttonWrapper {
    justify-content: center;
    .MuiBox-root:first-child {
        margin-right: 4%;
    }
    .MuiButtonBase-root{
        padding: 1px 40px;
    }
}
`;
const Styled = { Wrapper, UploadWrapper, PopupUpload };
export default Styled;
