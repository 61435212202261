import styled from 'styled-components';
import Parent from './style';
const Wrapper = styled(Parent.Wrapper)`
flex: 0 0 68%;
position: relative;
padding-bottom: 26px;
justify-content: space-between;
.breadcrumbClass {
    position: absolute;
    bottom: -33px;
    width: 100%;
    padding-left: 0;
    left: -28%;
}
`;
const TopWrapper = styled(Parent.TopWrapper)` 
`;
const IconWrapper = styled(Parent.IconWrapper)`
${({ theme }) => `
.userNew {
    display: none;
}
.userOld {
    display: block;
}
.addUserButtonhover:hover {
    text-decoration: none !important;
}
.userOld .addUser {
    padding: 0 8px;
    min-width: 125px;
    font-size: ${theme.palette.addbuttonFont} !important;
    :hover {
        font-size: ${theme.palette.addbuttonFont} !important;
    }
}
position: absolute;
right: 28px;
top: -2px;
`}
`;

const ImpExpWrapper = styled(Parent.ImpExpWrapper)`
position: relative;
top: 35px;
.userNew {
    display: none;
}
.userOld {
    display: block;
  }
.importUserTemplateContainer{
    order: 2;
    padding-left: 7px;
    margin-right: 0;
}
.importUserContainer{
    order: 1;
}
.exportContainer{
    order: 3;
}
importVehicleCsvReaderInput{
    cursor: pointer;
}
.importUserContainer.userOld {
    .importVehicleCsvReaderInput{
        font-family: tahoma;
        justify-content: flex-end;
        align-items: center;
    }
    label{
        font-size: 14px;
    }
}

`;

const SearchWrapper = styled(Parent.SearchWrapper)`

`;

const ButtonsWrapper = styled(Parent.ButtonsWrapper)`
${({ theme }) => `
padding-top: 0;
.quickfilter{
    display: inline-block;
    color: ${theme.colors.WHITE};
    align-self: center;
    font-size: 15px;
    font-size: ${theme.palette.headerFont};
}
.userBtnWrapper {
    background: ${theme.colors.LINK_COLOR};
    padding: 3px 3%;
    .MuiButton-root{
        color: #fff !important;
        width: auto;
        font-size: 16px !important;
        background: ${theme.colors.LINK_COLOR}!important;
        border: 0!important;
        font-style: italic;
        font-family: ${theme.palette.tableFont};
        &:hover {
            text-decoration: underline;
            box-shadow: none;
            font-size: 16px !important;
        }
    }
    .active {
        text-decoration: underline;
    }
    .MuiBox-root{
        margin-right: 1%;
    }
}
`}
`;

const DocContainer = styled(Parent.DocContainer)`
border: 1px solid #ddd;
padding-bottom: 10px;
li a {
    font-size: 22px;
}
.ToolsOptionClose{
    background-size: 10px;
}
`;
const TableWrapper = styled(Parent.TableWrapper)`
padding: 0 2%; 
`;
const Styled = {
  Wrapper,
  IconWrapper,
  ImpExpWrapper,
  SearchWrapper,
  TopWrapper,
  ButtonsWrapper,
  TableWrapper,
  DocContainer
};
export default Styled;
