import React, { lazy, useEffect, useState } from 'react';

import {
  getImagePath,
  hasValue,
  hasEnabledFeature,
  hasRestrictedRole
} from '@/utils/utils';
import { imageUrls } from '@/config/constants/keys';
import UnassignedEntry from './UdAssignEntryList';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));

const UdTable = ({
  udLogList,
  fetchUnAssignedEntries,
  i18n,
  unAssignedEntries,
  apiStatus,
  columns,
  saveUnAssignedEntries,
  setUdAssignEntryList,
  clearUdAssignEntryListById,
  callback,
  Styled,
  actions,
  pageinationReset,
  pagenationClicked,
  selectedDriverProfile,
  saveIconClicked,
  setSaveIconClicked,
  selectedId,
  setSelectedId,
  errorMsg,
  setError,
  clearAll,
  setClearAllClicked,
  clearAllClicked
}) => {
  const [parentTableWidth, setParentTableWidth] = useState('100');
  const [expandedClass, setExpandedClass] = useState(false);
  const [currentLog, setCurrentLog] = useState([]);
  const [dataSaved, setDataSaved] = useState(false)

  useEffect(() => {
    let array = currentLog?.map((item) => {
      const container = document.getElementById('tdEntry' + item);
      let source = document.getElementById('entry' + item);
      if (container) container.height = '10';
      if (source) source.style.display = 'none';
      clearUdAssignEntryListById({ logId: item });
      setExpandedClass(false);
      setParentTableWidth('100');
      setCurrentLog([]);
    });
  }, [selectedDriverProfile]);
  useEffect(() => {
    if (clearAllClicked) {
      hideAllContainer()
      setTimeout(() => {
        setClearAllClicked(false)
      }, 100)
    }
  }, [clearAllClicked])
  const hideContainer = (item) => {
    const container = document.getElementById(
      'tdEntry' + item
    );
    let source = document.getElementById('entry' + item);
    currentLog.splice(currentLog.indexOf(item), 1);
    setCurrentLog(currentLog);
    setExpandedClass(false);
    container.height = '10';
    container.removeAttribute('id')
    source.style.display = 'none';
    clearUdAssignEntryListById({ logId: item });
  }

  const hideAllContainer = () => {
    const container = document.querySelectorAll('*[id^="tdEntry"]');
    const source = document.querySelectorAll('*[id^="entry"]');
    container.forEach(item => {
      item.height = '10';
      item.removeAttribute('id')
    })
    source.forEach(item => {
      item.style.display = 'none';
    })
    clearAll()
  }
  const generateRows = () => {
    const items = udLogList.filteredList.map((item) => {
      item = {
        ...item,
        driver_name: (
          <Styled.Arrowdown>
            <span>{i18n.t(item.driver_name)}</span>
            {(window.$environment.SERVICE_PROVIDER === 'spireon' ||
              (hasEnabledFeature('udBulkEditEnabled') &&
                !hasRestrictedRole(['ADMIN']))) && (
                <>
                  <Icon
                    className={expandedClass ? 'expanded' : ''}
                    src={getImagePath(imageUrls.arrowdownIcon)}
                    onClick={(event) => {
                      const container = document.getElementById(
                        'tdEntry' + item.idPub
                      );
                      let source = document.getElementById('entry' + item.idPub);
                      setParentTableWidth(
                        document.getElementById('udLogTable').clientWidth
                      );
                      if (
                        container &&
                        container.height &&
                        parseInt(container.height, 10) > 10
                      ) {
                        hideContainer(item.idPub)
                      } else {
                        currentLog.push(item.idPub);
                        setCurrentLog(currentLog);
                        setExpandedClass(true);

                        let parent = source.nodeName.toLowerCase();
                        let next = source;
                        do {
                          parent = next.nodeName.toLowerCase();
                          if (parent !== 'td') {
                            next = next.parentElement;
                          }
                        } while (parent !== 'td');

                        next.id = 'tdEntry' + item.idPub;
                        // !unAssignedEntries[item.idPub] &&
                        fetchUnAssignedEntries({
                          id: 'fetchUnAssignedEntries',
                          data: { id: item.idPub, tab: selectedDriverProfile }
                        });
                      }
                      setError('')
                    }}
                  />
                  <div
                    id={'entry' + item.idPub}
                    className="udlogExpandedtable"
                    style={{
                      display: 'none',
                      width: parentTableWidth + 'px'
                    }}
                  >
                    <UnassignedEntry
                      i18n={i18n}
                      unAssignedEntries={{ ...unAssignedEntries }}
                      id={item.idPub}
                      logDate={item.date}
                      saveUnAssignedEntries={(data) => {
                        saveUnAssignedEntries(data)
                        setTimeout(() => {
                          hideAllContainer()
                        }, 200)
                      }}
                      setUdAssignEntryList={setUdAssignEntryList}
                      apiStatus={apiStatus}
                      callback={callback}
                      Styled={Styled}
                      distance={item.distance}
                      selectedDriverProfile={selectedDriverProfile}
                      saveIconClicked={saveIconClicked}
                      setSaveIconClicked={setSaveIconClicked}
                      selectedId={selectedId}
                      setSelectedId={setSelectedId}
                      errorMsg={errorMsg}
                      setError={setError}
                      dataSaved={dataSaved}
                      setDataSaved={setDataSaved}
                    />
                  </div>
                </>
              )}
          </Styled.Arrowdown>
        )
      };

      return item;
    });
    return [...items];
  };
  const hasUdLogListApiStatus = hasValue(apiStatus.status, 'api', 'udLogList');

  const information =
    hasUdLogListApiStatus && hasUdLogListApiStatus.errorMessage ? (
      <Message
        type="error"
        message={i18n.t(hasUdLogListApiStatus.errorMessage)}
      />
    ) : (
      'common.tableEmptyMessage'
    );
  return (
    <Table
      type="client"
      title=""
      columns={[...columns]}
      data={generateRows()}
      emptyMessage={information}
      itemsPerPage={
        window.$environment.SERVICE_PROVIDER === 'spireon' ? 10 : 15
      }
      actions={actions}
      actionRestricted={hasRestrictedRole(['ADMIN'])}
      i18n={i18n}
      id="udLogTable"
      defaultSortBy={{ sortBy: 'driver_name', order: 'asc' }}
      pageinationReset={pageinationReset}
      pagenationClicked={pagenationClicked}
      sortCallBack={() => hideAllContainer()}
    />
  );
};

export default UdTable;
