import styled from 'styled-components';
import React from 'react';
import { getImagePath } from '@/utils/utils'; 
import Box from '@material-ui/core/Box';
const isEs = () => window.$environment?.SERVICE_PROVIDER == 'webfleet' && localStorage.getItem(window.$environment?.SERVICE_PROVIDER + 'Language') == 'es'

const LoaderContainer = styled(Box)`
  background: no-repeat url(${getImagePath(isEs() ? 'ajax-Loader_es.gif' :'ajax-Loader.gif')}) center;
  position: absolute;
  width: 100%;
  height: 100%;
`;

const Loader = (props) => {
  return <LoaderContainer />
}

export default Loader;
