import React, { useEffect, useState, lazy } from 'react';
import PropTypes from 'prop-types';
import { string, object } from 'yup';
import { useTranslation } from 'react-i18next';

import { hasValue } from '@/utils/utils';
import RenderComponent from './RenderComponent';
import { pageUrls } from '@/config/constants/keys';
import moment from 'moment';
import { BACKEND_DATE_FORMAT } from '@/config/constants/static';

const layout = window?.$environment?.CURRENT_LAYOUT;

const Form = lazy(() => import(`@/components/UI/Form/${layout}`));

const TrailerForm = ({
  apiStatus,
  saveTrailerData,
  trailer,
  history,
  saveDocument,
  deleteDocument,
  callback,
  fetchTerminals,
  fetchDepartments,
  fetchSubDepartments,
  regions,
  type,
  isValidVin
}) => {
  const { i18n } = useTranslation();

  const apiId = type + 'SaveTrailerData';
  const hasApiStatus = hasValue(apiStatus.status, 'api', apiId);
  const [item, setItem] = useState({});
  const [validationlist, setValidationlist] = useState({
    type: string().required(i18n.t('common.fieldRequiredMessage')),
    name: string()
      .required(i18n.t('common.fieldRequiredMessage'))
      .max(10, i18n.t('common.validName')),
    vin: string().required(i18n.t('common.fieldRequiredMessage')),
    make: string().required(i18n.t('common.fieldRequiredMessage')),
    model: string().required(i18n.t('common.fieldRequiredMessage')),
    next_maintenance_date: string().required(
      i18n.t('common.fieldRequiredMessage')
    )
  });
  useEffect(() => {
    setItem(trailer?.trailerDetails || {});
  }, [trailer]);

  const objectArrayFromDiffFields = (list, value, field1, field2, callback) => {
    return list?.length
      ? list.map((item) => {
          if (callback) return callback(item, field1, field2, value);
          else
            return {
              label: field2 ? `${item[field1]} ${item[field2]}` : item[field1],
              value: item[value]
            };
        })
      : [];
  };

  const objectArrayFromSameField = (list) => {
    return list?.length
      ? list.map((item) => {
          return {
            label: item,
            value: item
          };
        })
      : [];
  };

  const vehicleList = objectArrayFromDiffFields(
    trailer?.vehicles,
    'vehicleId',
    'name'
  );

  const mechanicList = objectArrayFromDiffFields(
    trailer?.mechanics,
    'id',
    'firstName',
    'lastName'
  );
  const trailerTypes = objectArrayFromSameField(trailer.trailerTypes);
  const years = objectArrayFromSameField(trailer.years);
  const regionList = objectArrayFromDiffFields(
    regions,
    'regionId',
    'regionName'
  );
  const terminalList = objectArrayFromDiffFields(
    trailer?.terminals,
    'terminalId',
    'terminalName'
  );
  const departmentList = objectArrayFromDiffFields(
    trailer?.departments,
    'departmentId',
    'departmentName'
  );
  const subDepartmentList = objectArrayFromDiffFields(
    trailer?.subDepartments,
    'subDepartmentId',
    'subDepartmentName'
  );

  const initialValues = {
    idPub: item.idPub,
    isEdit: item.isEdit,
    name: item.name || '',
    companyId: item.companyId || '',
    lienholder: item.lienholder || '',
    type: item.type ? { label: item.type, value: item.type } : trailerTypes[0],
    year: item.year ? { label: item.year, value: item.year } : years[0],
    vin: item.vin || '',
    make: item.make || '',
    model: item.model || '',
    next_maintenance_date: item.next_maintenance_date || '',
    plateNumber: item.plateNumber || '',
    gvw: item.gvw || '',
    mechanicId:
      mechanicList.filter((mech) => mech.value === item.mechanicId)[0] || '',
    vehicleIdPub:
      vehicleList.filter((veh) => veh.value === item.vehicleIdPub)[0] || '',
    esn: item.esn || '',
    regionId: regionList.filter((reg) => reg.value === item.regionId)[0] || '',
    terminalId:
      terminalList.filter((ter) => ter.value === item.terminalId)[0] || '',
    departmentId:
      departmentList.filter((dep) => dep.value === item.departmentId)[0] || '',
    subDepartmentId:
      subDepartmentList.filter(
        (sub) => sub.value === item.subDepartmentId
      )[0] || '',
    documentsList: trailer.documents
  };
  return (
    <Form
      type="server"
      direction="column"
      initialValues={{ ...initialValues }}
      RenderComponent={RenderComponent}
      handleOnSubmit={(fields) => {
        let finalData = {
          type: fields.type?.value || null,
          vehicleIdPub: fields.vehicleIdPub?.value || null,
          vin: fields.vin,
          make: fields.make,
          model: fields.model,
          lienholder: fields.lienholder,
          next_maintenance_date: moment(fields.next_maintenance_date).format(
            BACKEND_DATE_FORMAT
          ),
          plateNumber: fields.plateNumber,
          gvw: fields.gvw,
          mechanicId: fields.mechanicId?.value || null,
          name: fields.name,
          idPub: fields.idPub,
          year: fields.year?.value || null,
          isEdit: fields.isEdit,
          companyId: fields.companyId,
          esn: fields.esn,
          regionId: fields.regionId.value || null,
          terminalId: fields.terminalId.value || null,
          departmentId: fields.departmentId.value || null,
          subDepartmentId: fields.subDepartmentId.value || null
        };

        saveTrailerData({
          data: {
            data: {
              ...finalData
            }
          },
          apiId,
          callback: () => {
            history.push(pageUrls.vehicleList);
          }
        });
      }}
      customProps={{
        hasApiStatus,
        saveDocument,
        deleteDocument,
        apiStatus,
        callback,
        regionList,
        trailerTypes,
        fetchTerminals,
        fetchDepartments,
        fetchSubDepartments,
        terminalList,
        departmentList,
        subDepartmentList,
        setValidationlist,
        validationlist,
        years,
        mechanicList,
        vehicleList,
        trailer,
        regions,
        type,
        isValidVin
      }}
      handleOnCancel={() => {
        history.push(pageUrls.vehicleList);
      }}
      validationSchema={object().shape({
        ...validationlist
      })}
    />
  );
};

TrailerForm.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired
};

export default TrailerForm;
