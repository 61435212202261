import React, { lazy } from 'react';

import { useTranslation } from 'react-i18next';
import { getEsKey } from '@/utils/utils';
const layout = window?.$environment?.CURRENT_LAYOUT;
const Label = lazy(() => import(`../../Label/${layout}`));

const Message = ({ message, type, MessageContainer }) => {
  const { i18n } = useTranslation();
  return (
    <MessageContainer type={type}>
      <Label>{message ? i18n.t(getEsKey(message.trim())) : message}</Label>
    </MessageContainer>
  );
};

export default Message;
