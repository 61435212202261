import React, { useEffect, lazy, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import RenderComponent from '@/components/modules/DynamicRenderPage';

import ParentStyled from './style';
import { loadStyle, getNewObject, loadPageConfig, isInsuracePortalUser } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'pages/web/Accidents');

const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.accidentsList[0].name}`));
const Content = lazy(() => import(`@/${config.accidentsList[1].name}`));

const Accidents = (parentProps) => {
  const insuracePortalUserRole = isInsuracePortalUser();
  const { fetchAccidents, list, getFilters, getDocumentToken, logBook, fetchFleetCompanies, fetchAccidentsPagination } = parentProps;
  const [pagination, setPagination] = useState(false);
  const basicRequest = {
    displayLength: '15',
    displayStart: '1'
  };
  const [request, setRequest] = useState({
    ...basicRequest
  });
  const history = useHistory();
  const theme = useTheme();
  const i18n = useTranslation();
  const commonParentProps = { history, i18n, Styled, theme };

  const fetchApi = 'fetchAccidentsApi';
  const fetchPaginateApi = 'fetchAccidentsPaginateApi'
  const apiId = 'getFilters';
  const getDocumentTokenApiId = 'getDocToken';

  const sortColumnMapping = {
    unitId: "vehicleName",
    work_order_id: "idPub",
    type: "unitType",
    reported_date: "createDate",
    scheduled_date: "beginDate",
    due_date: "endDate",
    workOrderType: "type",
    status: "state"
  }
  const changeRequest = (fields) => {
    if (fields?.sortCol) {
      const type = fields.sortCol
      fields.sortCol = sortColumnMapping[type] ? sortColumnMapping[type] : fields.sortCol
    }
    setRequest({ ...basicRequest, ...fields });
  };

  useEffect(() => {
    if(insuracePortalUserRole) {
      fetchAccidentsPagination({
        apiId: fetchPaginateApi,
        data: { request: request },
      });
    }   

  }, [request]);

  useEffect(() => {
    !insuracePortalUserRole && !list.length && fetchAccidents({ apiId: fetchApi });
    getFilters({ apiId });
    if (!logBook?.documentToken?.length) {
      getDocumentToken({ apiId: getDocumentTokenApiId });
    }
    fetchFleetCompanies({
      apiId: 'fetchFleetCompanies',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <RenderComponent
        {...config.accidentsList[0]}
        parentProps={{
          ...parentProps,
        }}
        commonParentProps={commonParentProps}
        component={Title}
      />
      <RenderComponent
        {...config.accidentsList[1]}
        parentProps={{
          ...parentProps,
          changeRequest,
          request,
          pagination,
          setPagination
        }}
        commonParentProps={commonParentProps}
        component={Content}
        pagination={pagination}
        setPagination={setPagination}
      />
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    list: state.accidents.list,
    paginateList: state.accidents.paginateList,
    filters: state.filters,
    logBook: state.logBook,
    fleetCompanies: state.fleetCompanyList.companyList,
  };
};

const mapDispatch = (dispatch) => {
  return {
    fetchAccidents: (data) => dispatch.accidents.fetchData(data),
    fetchAccidentsPagination: (data) => dispatch.accidents.fetchPaginationData(data),
    getFilters: (data) => dispatch.filters.fetchData(data),
    viewReport: (data) => dispatch.viewReport.viewReportDocument(data),
    getDocumentToken: (data) => dispatch.logBook.documentToken(data),
    fetchFleetCompanies: (data) => dispatch.fleetCompanyList.fetchCompanyList(data),
  };
};

export default connect(mapState, mapDispatch)(Accidents);

Accidents.defaultTypes = {};
Accidents.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  accidents: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
  fetchFleetCompanies: PropTypes.func.isRequired,
  fetchAccidentsPagination: PropTypes.func.isRequired,
  fetchAccidents: PropTypes.func.isRequired,
  getFilters: PropTypes.func.isRequired
};
