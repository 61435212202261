import React, { lazy } from 'react';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Table = lazy(() => import(`../../../components/UI/Table/${layout}`));
const Link = lazy(() => import(`../../../components/UI/Link`));
const VehicleStatus = ({ vehicleStatus, i18n, viewOnMap, noPagination, noSort }) => {
  const columns = [
    {
      id: 'vehicleName',
      numeric: false,
      disablePadding: false,
      label: i18n.t('common.vehicleName'),
      needSorting: noSort ? false : true
    },
    {
      id: 'vin',
      numeric: false,
      disablePadding: false,
      label: i18n.t('vehicles.vin'),
      needSorting: noSort ? false : true
    },
    {
      id: 'esn',
      numeric: false,
      disablePadding: false,
      label: i18n.t('common.deviceSn'),
      needSorting: noSort ? false : true
    },
    {
      id: 'latestLocation',
      disablePadding: false,
      label: i18n.t('dashboard.tabs.driverStatus.lastKnownLocation'),
      needSorting: noSort ? false : true
    },
    {
      id: 'seatBeltStatus',
      disablePadding: false,
      label: i18n.t('vehicles.seatBeltStatus'),
      needSorting: noSort ? false : true
    }
  ];
  const generateRows = () => {
    var filtered = vehicleStatus.filter(function (el) {
      return el != null;
    });
    const updatedList = filtered?.map((item) => {
      const row = {};
      for (let i = 0; i < item.infoWithLink.length; i++) {
        const rowItem = item.infoWithLink[i];
        row[columns[i].id] = rowItem.link ? (
          <Link
            href={window.location.origin + rowItem.link.replace('.', '').replace('?id=', '/')}
            newPage
          >
            {rowItem.item}
          </Link>
        ) : (
          rowItem.item
        );
        row.latestLocation =
          item.infoWithLink[3].item === '(0.0,0.0)' ? (
            <span disabled>{'View On Map'}</span>
          ) : (
            <Link
              onClick={() => viewOnMap && viewOnMap(item.infoWithLink[3].item)}
            >
              {'View On Map'}
            </Link>
          );
        row.seatBeltStatus =
          item.infoWithLink[4] != undefined &&
          item.infoWithLink[4] !== null &&
          item.infoWithLink[4].item === 0 ? (
            <span className="seatbeathStatus notBuckled">Not Buckled</span>
          ) : item.infoWithLink[4] != undefined &&
            item.infoWithLink[4] !== null &&
            item.infoWithLink[4].item === 1 ? (
            <span className="seatbeathStatus buckled">Buckled</span>
          ) : item.infoWithLink[4] != undefined &&
            item.infoWithLink[4] !== null &&
            item.infoWithLink[4].item === 2 ? (
            <span className="seatbeathStatus noData">Error</span>
          ) : (
            <span className="seatbeathStatus noData">No Data Available</span>
          );
      }
      return row;
    });

    return updatedList;
  };
  const information = i18n.t('common.tableEmptyMessage');
  return (
    <>
      <Table
        type="client"
        defaultSortBy={{ sortBy: 'vin', order: 'desc' }}
        title=""
        columns={[...columns]}
        data={generateRows()}
        emptyMessage={information}
        itemsPerPage={10}
        actions={[]}
        i18n={i18n}
        noPagination = {noPagination || false}
      />
    </>
  );
};

export default VehicleStatus;
