import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';

// import Header from '../../../../components/Header';
import Component from './Component';

const NewCompany = ({ auth, theme, i18n, history, ...rest }) => {
  return (
    <>
      {/* <Header
        header={i18n.t('COMPANY REGISTRATION')}
        auth={auth}
        i18n={i18n}
        history={history}
      /> */}
      <Box style={{ ...theme.palette.contentStyle }}>
        <Component {...rest} theme={theme} i18n={i18n} />
      </Box>
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    company: state.company
  };
};
const mapDispatch = (dispatch) => {
  return {
    addCompany: (data) => dispatch.companies.addCompany(data)
  };
};

export default connect(mapState, mapDispatch)(NewCompany);

NewCompany.defaultTypes = {
  company: {}
};

NewCompany.propTypes = {
  auth: PropTypes.object.isRequired,
  apiStatus: PropTypes.object.isRequired,
  company: PropTypes.object,
  addCompany: PropTypes.func.isRequired
};
