import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
  ${({ theme }) => `
flex: 0 0 60%;
position: initial;
.filterForm .MuiTypography-body1 {
    color: #848c96 !important;
    font-size: 14px;
}
.exportAlertSection {
    display flex;
    justify-content: flex-end;
    width: 100%;
    position: absolute;
    width: 60%;
    position: absolute;
    right: 1%;
    top: 0;
    padding-top: 0;
    .exportWrapper {
        color: #fff;
        font-size: 23px;
        font-family: ${theme.palette.titleFont};
        border-radius: 0px;
        height: 40px;
        padding: 5px 12px !important;
        background: #C92100;
        :hover {
            box-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);
            text-decoration: none;
        }
    }
    .export {
        margin-right: 1% !important;
        cursor pointer;
    }
}
.exportWrapper {
    :hover {
        box-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);
        text-decoration: none;
        cursor:pointer;
    }
}
.filterForm {
    padding-bottom: 21px;
    padding-top: 1%;
    align-items: flex-start;
    border-top: 2px solid #000;
    flex: 0 0 100%;
    padding-left: 1%;
    margin-top: 10px;
    .MuiButtonBase-root{
        margin-top: 27px;
    }
}
.MuiBox-root {
    margin-right: 9px;
  }
`}
`;
const ExportOptionWrapper = styled(Parent.ExportOptionWrapper)`
.MuiBox-root{
    background: #C92100 !important;
}
`;
const TableWrapper = styled(Parent.TableWrapper)`
  ${({ theme }) => `
padding-top: 0;
.spireonOnly{
    display: block;
    color: #42505a;
    font: bold 12px "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding: 10px;
    padding-left: 10px;
    background: -webkit-linear-gradient(top, #d0d8db 0%, #b3bec3 100%);
    align-items: center;
    margin-top: 20px;
}
.quickFilter{
    display: none;
}
`}
`;
const SearchWrapper = styled(Parent.SearchWrapper)``;
const Styled = { Wrapper, ExportOptionWrapper, TableWrapper, SearchWrapper };

export default Styled;
