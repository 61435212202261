import { driversForChatApi,sendBroadCastMessageApi, createChatTokenApi, addUsertoChannelApi } from '../../../config/apis';

const initialState = {
  drivers: [],
  chatToken: {}
};

export const chat = {
  state: { ...initialState },
  reducers: {
    getSuccess: (state, payload) => {
      return { ...state, drivers: [...payload] };
    },
    getFailed: () => {
      return { ...initialState };
    },
    getTokenSuccess: (state, payload) => {
        return { ...state, chatToken: {...payload}};
    }
  },
  effects: (dispatch) => ({
    async getDrivers(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: driversForChatApi
          },
          id: payload.apiId
        });
        if (response) {
          this.getSuccess(response);
        }
      } catch (error) {
        this.getFailed();
      }
    },
    async sendBroadCastMessage(payload) {
        try {
          const response = await dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              api: sendBroadCastMessageApi,
              data: payload.data
            },
            id: payload.apiId
          });
          payload.callback && payload.callback()
        } catch (error) {
        //   this.getFailed();
        }
      },
      async createToken(payload) {
        try {
          const response = await dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              api: createChatTokenApi
            },
            id: payload.apiId
          });
          if (response) {
            this.getTokenSuccess(response);
          }
        } catch (error) {
        //   this.getFailed();
        }
      },
      async addUsertoChannel(payload) {
        try {
          const response = await dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              api: addUsertoChannelApi
            },
            id: payload.apiId
          });
          payload.callback && payload.callback()
        //   if (response) {
        //     this.getTokenSuccess(response);
        //   }
        } catch (error) {
        //   this.getFailed();
        }
      },

  })
};
