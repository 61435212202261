import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
  ${({ theme }) => `
flex: 0 0 60%;
position: initial;
.filterForm .MuiTypography-body1 {
    color: ${theme.colors.PRIMARY_CONTRAST} !important;
    // font-family: ${theme.palette.tableFont};
    font-size: 24px;
}
.exportAlertSection {
    display flex;
    justify-content: flex-end;
    width: 100%;
    position: absolute;
    right: 3%;
    top: 0;
    padding-top: 2%;
    .exportWrapper {
        color: #fff;
        font-size: 18px;
        border-radius: 0px;
        height: 45px;
        padding: 9px 20px !important;
        background: ${theme.colors.LINK_COLOR};
        :hover {
            box-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);
            text-decoration: none;
        }
    }
    .export {
        margin-right: 1% !important;
    }
}
.filterForm {
    padding-left: 21%;
    padding-bottom: 3%;
    padding-top: 1%;
    align-items: flex-end;
}
`}
`;
const ExportOptionWrapper = styled(Parent.ExportOptionWrapper)``;
const TableWrapper = styled(Parent.TableWrapper)`
  ${({ theme }) => `
padding-top: 0;
.spireonOnly{
    display: none;
}
.quickFilter{
    display: block;
    height: 20px;
    background: ${theme.colors.LINK_COLOR};
}
.MuiPaper-root {
    padding: 0 3%;
}
`}
`;
const SearchWrapper = styled(Parent.SearchWrapper)``;
const Styled = { Wrapper, ExportOptionWrapper, TableWrapper, SearchWrapper };

export default Styled;
