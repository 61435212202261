import React, { lazy, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';

import Styled from './style';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Input = lazy(() => import(`@/components/UI/Input/${layout}`));
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Select = lazy(() => import(`@/components/UI/Select/${layout}`));
const SubHeader = lazy(() => import(`@/components/SubHeader/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const RenderComponent = ({
  values,
  handleEdit,
  validateForm,
  customProps,
  submitAction,
  errors,
  isNotValid
}) => {
  const [stateOffset, setStateOffset] = useState(0);
  const { i18n } = useTranslation();
  const showError = (field, validationErrors, isNotValid, apiError) => {
    if (validationErrors[field] && !isNotValid) {
      return <Message type="error" message={errors[field]} />;
    }
    if (apiError?.[field]) {
      return <Message type="error" message={apiError[field]} />;
    }
  };
  return (
    <Styled.PopupUpload>
      <SubHeader
        text={
          values.id
            ? i18n.t('usDotManagement.editDot')
            : i18n.t('usDotManagement.addDot')
        }
        variant="h1"
      />
      <Styled.UploadWrapper stateOffset={stateOffset}>
        <div className="tcSection" id="scroll">
          <div className="scrollbar">
            <div className="uploadFields">
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1" htmlFor="dotNumber">
                  {i18n.t('usDotManagement.usDotNo')}
                </Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="dotNumber"
                    id="dotNumber"
                    handleOnChange={(event) =>
                      handleEdit(event, { field: 'dotNumber' })
                    }
                    value={values?.dotNumber}
                    as={Input}
                  />
                  {showError(
                    'dotNumber',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1" htmlFor="carrierName">
                  {i18n.t('usDotManagement.carrierName')}
                </Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="carrierName"
                    id="carrierName"
                    handleOnChange={(event) =>
                      handleEdit(event, { field: 'carrierName' })
                    }
                    value={values?.carrierName}
                    as={Input}
                  />
                  {showError(
                    'carrierName',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1" htmlFor="mainAddress">
                  {i18n.t('usDotManagement.mainAddress')}
                </Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="mainAddress"
                    id="mainAddress"
                    handleOnChange={(event) =>
                      handleEdit(event, { field: 'mainAddress' })
                    }
                    value={values?.mainAddress}
                    as={Input}
                  />
                  {showError(
                    'mainAddress',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1" htmlFor="city">
                  {i18n.t('common.city')}
                </Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="city"
                    id="city"
                    handleOnChange={(event) => handleEdit(event, { field: 'city' })}
                    value={values?.city}
                    as={Input}
                  />
                  {showError(
                    'city',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1">{i18n.t('common.state')}</Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="state"
                    onChange={(event) => handleEdit(event, { field: 'state' })}
                    value={values?.state}
                    as={Select}
                    suggestions={customProps.statesList}
                    id="terminalState"
                  />
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1" htmlFor="zipCode">
                  {i18n.t('usDotManagement.zip')}
                </Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="zipCode"
                    id="zipCode"
                    handleOnChange={(event) =>
                      handleEdit(event, { field: 'zipCode' })
                    }
                    value={values?.zipCode}
                    as={Input}
                  />
                  {showError(
                    'zipCode',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              <FormStyle.default.ButtonsWrapper>
                <Button
                  type="submit"
                  className="buttonStyle"
                  label={
                    values.id ? i18n.t('common.update') : i18n.t('common.add')
                  }
                  onClick={() => {
                    const validateResponse = validateForm();
                    validateResponse.then((data) => {
                      submitAction(data);
                    });
                  }}
                />
              </FormStyle.default.ButtonsWrapper>
            </div>
          </div>
        </div>
      </Styled.UploadWrapper>
    </Styled.PopupUpload>
  );
};

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  customProps: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};

export default RenderComponent;
