import styled from 'styled-components';
import { getStyle } from '../../../utils/utils';
import Box from '@material-ui/core/Box';

const ParentWrapper = styled.div`
  ${({ theme }) => `
  #udNotification {
    background: ${theme.colors.LINK_COLOR};
    color: ${theme.colors.WHITE} !important;
    text-align: center;
    font-size: 16px;
    font-family: Arial, "Geneva CY", sans-serif;
    width: 100%;
    a {
      color: ${theme.colors.WHITE} !important;
    }
  }

`}
`;
const Wrapper = styled.div``;
const Main = styled.main`
  ${({ theme, drawerwidth, widthnounit }) => `

  background-color: ${theme.colors.PRIMARY_MAIN};
  width: ${`calc(100% - ${drawerwidth})`};
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  overflow: hidden;
    `}
`;
const HeaderInner = styled(Box)`
  ${({ theme }) => `
display: flex;
background-color: ${theme.colors.PUBLIC_HEADERCOLOR};
min-height: 71px;
position: relative;
`}
`;
const LeftDrawer = styled(Box)`
  ${({ theme, drawerwidth, widthnounit }) => `

  width: ${drawerwidth};
  .MuiIconButton-root:hover {
    background-color: ${getStyle(
      '',
      (theme.palette.primary || {}).main,
      theme.colors.PRIMARY_MAIN
    )};
  }
  .drawerIcon {
    position: absolute;
    top: 2.9%;
    z-index: 1000;
    left: ${widthnounit - 1}%;
    color: ${getStyle(
      '',
      (theme.palette.secondary || {}).main,
      theme.colors.SECONDARY_MAIN
    )};
    background-color: ${getStyle(
      '',
      (theme.palette.primary || {}).main,
      theme.colors.PRIMARY_MAIN
    )};
    border: solid 3px
      ${getStyle(
        '',
        (theme.palette.primary || {}).border,
        theme.colors.PRIMARY_BORDER
      )};
    padding: 0px;
    font-size: 1.2rem;
    ${theme.breakpoints.down('xl')}: {
      top: 2.9% !important;
    }
    .MuiSvgIcon-root {
      width: 0.7em;
      height: 0.7em;
    }
  }
  .MuiBox-root {
    width: 100%;
  }

  #logoContainer {
    text-align: center;
    img {
      @media (min-width: ${theme.breakpoints.values.xsm}px) and (max-width: ${
    theme.breakpoints.values.sm
  }px) {
        width: 53px;
        height: 53px;
      }
      @media (min-width: ${theme.breakpoints.values.xs}px) and (max-width: ${
    theme.breakpoints.values.xsm
  }px) {
        width: 45px;
        height: 45px;
      }
      @media (min-width: ${theme.breakpoints.values.md}px) and (max-width: ${
    theme.breakpoints.values.lg
  }px) {
        width: 45px;
        height: 45px;
      }

      @media (min-width: ${theme.breakpoints.values.sm}px) and (max-width: ${
    theme.breakpoints.values.md - 1
  }px) and (orientation: landscape) {
        width: 85px;
        height: 80px;
      }
      @media (min-width: ${theme.breakpoints.values.lg}px) and (max-width: ${
    theme.breakpoints.values.xl - 1
  }px) {
        width: 95px;
        height: 80px;
      }
    }
  }
  `}
`;
const Content = styled(Box)`
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
`;
const FooterWrapper = styled(Box)`
  width: 100%;
`;

const ModalContainer = styled.div`
  position: fixed;
  bottom: 140px;
  right: 40px;
  cursor: pointer;
  z-index: 1000;
  .chatWrapper {
    background-color: rgba(255, 255, 255, 0.7);
    width: 100%;
    display: block;
    border-radius: 25px;
    width: 65px;
    height: 57px;
    text-align: center;
  }
  .chatIcon {
    width: 50px;
    height: 50px;
    top: 3px;
    position: relative;
  }
`;
const Styled = {
  Wrapper,
  Main,
  HeaderInner,
  LeftDrawer,
  Content,
  FooterWrapper,
  ModalContainer,
  ParentWrapper
};

export default Styled;
