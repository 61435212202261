import React, { lazy } from 'react';
import { useTranslation } from 'react-i18next';

import { getImagePath } from '../../utils/utils';
import { imageUrls } from '../../config/constants/keys';
import { removeLocalStorage } from '@/services/storage';

import ParentStyled from './style';
import { loadStyle } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'components/CautionIcon');
const layout = window?.$environment?.CURRENT_LAYOUT;

const Button = lazy(() => import(`@/components/UI/Button/${layout}`));

const CautionIcon = ({ noButton, message }) => {
  const i18n = useTranslation();

  return (
    <Styled.Container>
      <img src={getImagePath(imageUrls.confirmIcon)} alt="caution" />
      <h2 className="resetHeading">
        {message ||
          `Please logout any active sessions in the current browser and click the
        button below to continue.`}
      </h2>
      {!noButton && (
        <div className="cancelBtn">
          <Button
            label={i18n.t('common.ok')}
            type={'submit'}
            onClick={() => {
              removeLocalStorage('currentLayout');
              window.location.reload();
            }}
          />
        </div>
      )}
    </Styled.Container>
  );
};

export default CautionIcon;
