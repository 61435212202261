import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
.radio{
    .MuiFormControlLabel-label{
        font-size: 20px !important;
    }
}
form {
  padding: 3%;
  padding-top: 0;
}
.MuiFormLabel-root {
  padding-right: 10px;
}
#subscriptionTier {
  font-size: ${theme.palette.formlabelFont};
}
.normalCheckbox {
  span {
    font-size: ${theme.palette.formlabelFont};
    padding-left: 10px;
  }
}
#creditCardUpdate {
  margin-top: 0;
}
.buttonSection {
  display: flex;
  justify-content: center;
  .companyInfoSubmit{
    order: 2;
    margin-left: 1%;
  }
}
`}
`;
const leftSection = styled(Parent.leftSection)`
${({ theme }) => `
border-bottom: 2px solid #000;
.checkboxesCompany{
  .agreeTerms{
    flex: 0 0 48%;
  }
}
.values {
  font-size: ${theme.palette.companyDataFont};
}
`}
`;
const rightSection = styled(Parent.rightSection)`
padding-top: 30px;
`;
const TextWrapper = styled(Parent.TextWrapper)`
`;

const FieldsWrapper = styled(Parent.FieldsWrapper)`

`;
const wrapperSection = styled(Parent.wrapperSection)`
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    min-height: 100%;
  }
`;

const InsuranceCompanyWrapper= styled(Parent.InsuranceCompanyWrapper)`
`;

const Styled = {
  Wrapper,
  TextWrapper,
  FieldsWrapper,
  leftSection,
  rightSection,
  wrapperSection,
  InsuranceCompanyWrapper
};

export default Styled;
