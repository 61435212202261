/* eslint-disable react-hooks/exhaustive-deps */
import React, {lazy} from 'react';
import { useTranslation } from 'react-i18next';

import { pageUrls } from '../../../../config/constants/keys';
const layout = window?.$environment?.CURRENT_LAYOUT;

const LineChart = lazy(() =>
  import(`@/components/UI/Graph/LineGraph/${layout}`)
);
const BarChart = lazy(() => import(`@/components/UI/Graph/BarGraph/${layout}`));
const Component = ({
  history,
  callback,
  driverbehavior,
  hardDeccelerationData,
  hardAccelerationData,
  speedingData,
  driveHrsExceedingData,
  fuelEconomyData,
  seatBeltWarningData,
  hardTurnData,
  Styled,
  theme
}) => {
  const i18n = useTranslation();
  return (
    <Styled.Wrapper>
      <div className="basicMeasures">
        <div className="chartContainer">
          {hardAccelerationData &&
          Object.keys(hardAccelerationData.data).length !== 0 ? (
            <BarChart
              width={'470px'}
              height={'230px'}
              labels={hardAccelerationData.labels}
              data={hardAccelerationData.data}
              areagraph
              title={i18n.t('graphTitles.hardAcceleration')}
              history={history}
              pageUrls={pageUrls.hardAcceleration}
              theme={theme}
              noHyperLink = {false}
            ></BarChart>
          ) : (
            <div
              id="noData"
              onClick={() => window.open(pageUrls.hardAcceleration, "_blank")}
            >
              <div id="nameWrap">
                <p>{i18n.t('graphTitles.hardAcceleration')}</p>
              </div>
              <div id="msgWrap">
                <p>{i18n.t('common.noDataToPlot')} </p>
              </div>
            </div>
          )}
        </div>
        <div className="chartContainer">
          {hardDeccelerationData &&
          Object.keys(hardDeccelerationData.data).length !== 0 ? (
            <LineChart
              width={'470px'}
              height={'230px'}
              labels={hardDeccelerationData.labels}
              data={hardDeccelerationData.data}
              areagraph
              title={i18n.t('graphTitles.hardDeceleration')}
              displayLegend={false}
              history={history}
              pageUrls={pageUrls.hardDeceleration}
              theme={theme}
              noHyperLink = {false}
            ></LineChart>
          ) : (
            <div
              id="noData"
              onClick={() => window.open(pageUrls.hardDeceleration, "_blank")}
            >
              <div id="nameWrap">
                <p>{i18n.t('graphTitles.hardDeceleration')}</p>
              </div>
              <div id="msgWrap">
                <p>{i18n.t('common.noDataToPlot')}</p>
              </div>
            </div>
          )}
        </div>
        <div className="chartContainer">
          {speedingData && Object.keys(speedingData.data).length !== 0 ? (
            <LineChart
              width={'470px'}
              height={'230px'}
              labels={speedingData.labels}
              data={speedingData.data}
              title={i18n.t('graphTitles.speeding')}
              history={history}
              displayLegend={false}
              pageUrls={pageUrls.speeding}
              theme={theme}
              noHyperLink = {false}
            ></LineChart>
          ) : (
            <div id="noData" onClick={() => window.open(pageUrls.speeding, "_blank")}>
              <div id="nameWrap">
                <p>{i18n.t('graphTitles.speeding')}</p>
              </div>
              <div id="msgWrap">
                <p>{i18n.t('common.noDataToPlot')}</p>
              </div>
            </div>
          )}
        </div>
      </div>
      { window.$environment.SERVICE_PROVIDER != 'riskTheory' && <div className="middleWrapper">
          <div className="chartContainer">
            {driveHrsExceedingData &&
            Object.keys(driveHrsExceedingData.data).length !== 0 ? (
              <LineChart
                width={'470px'}
                height={'230px'}
                labels={driveHrsExceedingData.labels}
                data={driveHrsExceedingData.data}
                title={i18n.t('graphTitles.hoursExceeding')}
                displayLegend={false}
                history={history}
                pageUrls={pageUrls.driveHrsExceeding}
                theme={theme}
                noHyperLink = {false}
              ></LineChart>
            ) : (
              <div
                id="noData"
                onClick={() => window.open(pageUrls.driveHrsExceeding, "_blank")}
              >
                <div id="nameWrap">
                  <p>{i18n.t('graphTitles.hoursExceeding')}</p>
                </div>
                <div id="msgWrap">
                  <p>{i18n.t('common.noDataToPlot')}</p>{' '}
                </div>
              </div>
            )}
          </div>
          <div className="chartContainer">
            {fuelEconomyData && Object.keys(fuelEconomyData.data).length !== 0 ? (
              <LineChart
                width={'470px'}
                height={'230px'}
                labels={fuelEconomyData.labels}
                data={fuelEconomyData.data}
                title={i18n.t('graphTitles.fuelEconomy')}
                displayLegend={false}
                history={history}
                pageUrls={pageUrls.fuelEconomy}
                theme={theme}
                noHyperLink = {false}
              ></LineChart>
            ) : (
              <div id="noData" onClick={() => window.open(pageUrls.fuelEconomy, "_blank")}>
                <div id="nameWrap">
                  <p>{i18n.t('graphTitles.fuelEconomy')}</p>
                </div>
                <div id="msgWrap">
                  <p>{i18n.t('common.noDataToPlot')}</p>
                </div>
              </div>
            )}
          </div>
          <div className="chartContainer">
            {seatBeltWarningData &&
            Object.keys(seatBeltWarningData.data).length !== 0 ? (
              <LineChart
                width={'470px'}
                height={'230px'}
                labels={seatBeltWarningData.labels}
                data={seatBeltWarningData.data}
                title={i18n.t('graphTitles.nonBuckledSeatbelt')}
                displayLegend={false}
                history={history}
                pageUrls={pageUrls.seatBeltWarning}
                theme={theme}
                noHyperLink = {false}
              ></LineChart>
            ) : (
              <div
                id="noData"
                onClick={() => window.open(pageUrls.seatBeltWarning, "_blank")}
              >
                <div id="nameWrap">
                  {' '}
                  <p>{i18n.t('graphTitles.nonBuckledSeatbelt')}</p>
                </div>
                <div id="msgWrap">
                  <p>{i18n.t('common.noDataToPlot')} </p>
                </div>
              </div>
            )}
          </div>
        </div>
      }
      <div className="bottomWrapper">
        <div className="chartContainer">
          {hardTurnData && Object.keys(hardTurnData.data).length !== 0 ? (
            <LineChart
              width={'470px'}
              height={'230px'}
              labels={hardTurnData.labels}
              data={hardTurnData.data}
              title={i18n.t('graphTitles.hardTurn')}
              displayLegend={false}
              history={history}
              pageUrls={pageUrls.hardTurn}
              theme={theme}
              noHyperLink = {false}
            ></LineChart>
          ) : (
            <div id="noData" onClick={() => window.open(pageUrls.hardTurn, "_blank")}>
              <div id="nameWrap">
                <p>{i18n.t('graphTitles.hardTurn')}</p>
              </div>
              <div id="msgWrap">
                <p>{i18n.t('common.noDataToPlot')}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </Styled.Wrapper>
  );
};

export default Component;
