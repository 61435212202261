import base from '../base';

export default {
  ...base,
  inspections: {
    ...base.inspections,
    addNewForm: "Add a new form",
    addNewVehicleForm: "Add a new vehicle form",
    addNewTrailerForm: "Add a new trailer form",
    formName: "Form Name",
    vehiclesCount: "Vehicles Count",
    addForm: "Add Form",
    editForm: "Edit Form",
    addNewArea:"Add New Area",
    areasAndParts: "Areas and Parts",
    addNewParts: "Add new parts",
    defects: "Defects",
    addNewDefects: "Add new Defects",
    areaName: "Area Name",
    areaType: "Area Type"
  },
};
