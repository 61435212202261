import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { colors, fonts, secondaryFont, headerFont, subTitle, tableFont, titleLabel, formlabelFont, buttonFont, checkboxFont, inputFont, addbuttonFont, companyDataFont, iftaReport, inspectionInput, inspectionresolution, companyDashboard} from './variables';
import parentTheme from '../../';

const theme = createMuiTheme({
  ...parentTheme({ colors, fonts, secondaryFont, headerFont, subTitle, tableFont, titleLabel, formlabelFont, buttonFont, checkboxFont, inputFont, addbuttonFont, companyDataFont, iftaReport, inspectionInput, inspectionresolution, companyDashboard }),
  palette: {
    ...parentTheme({ colors, fonts, secondaryFont, headerFont, subTitle, tableFont, titleLabel, formlabelFont, buttonFont,checkboxFont, inputFont, addbuttonFont, companyDataFont,iftaReport, inspectionInput, inspectionresolution, companyDashboard }).palette,
    contentStyle: {
      minHeight: 'calc(100vh - 77px)',
      padding: '0',
      backgroundColor: colors.BODY_BACKGROUND
    },
    contentlogsStyle: {
      minHeight: 'calc(100vh - 77px)',
      padding: '0',
      backgroundColor: colors.BODY_BACKGROUND
    },
    oldLogo: {
      position: 'absolute',
      top:'-23px',
      width: '220px',
      height: '128px'
    }
  }
});

export default theme;
