import React, { lazy } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';

import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import ViewForm from './TopActions';
import { getImagePath } from '../../../utils/utils';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Export = lazy(() => import(`@/components/UI/Export/${layout}`));
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const BarChart = lazy(() => import(`@/components/UI/Graph/BarGraph/${layout}`));
const LineChart = lazy(() =>
  import(`@/components/UI/Graph/LineGraph/${layout}`)
);

const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Component {...contentProps} />
    </Box>
  );
};
export default Index;

const Component = ({
  apiStatus,
  callback,
  history,
  Styled,
  fetchTableData,
  chat,
  fetchChartData,
  tableData,
  chartData,
  driverList,
  vehicleListDropDown,
  vehicleList,
  topAction,
  fetchDriverBehaviorDetails,
  driverbehavior
}) => {
  const { i18n } = useTranslation();
  const theme = useTheme();
  const information = i18n.t('common.tableEmptyMessage');
  const isRishTheory = window?.$environment?.SERVICE_PROVIDER === 'riskTheory';
  const columns = [
    {
      id: 'driverName',
      numeric: false,
      disablePadding: true,
      label: i18n.t('common.driver'),
      needSorting: true
    },
    {
      id: 'vechileName',
      numeric: false,
      disablePadding: true,
      label: i18n.t('common.vehcile'),
      needSorting: true
    },
    {
      id: 'count',
      numeric: true,
      disablePadding: false,
      label: i18n.t('Recorded Events'),
      needSorting: true
    },
    {
      id: 'icon',
      numeric: true,
      disablePadding: false,
      label: '',
      needSorting: false
    }
  ];

  const generateRows = () => {
    let data = tableData;
    data.forEach(ele => {
      if (vehicleList) {
       ele.vechileName = vehicleList.filter(item => ele.esn && item.esn == ele.esn)[0]?.name
      }
      if(isRishTheory) {
        if(ele?.driverId) {
          ele.icon = <img class="infoImg" src={`${getImagePath('icons/driver-behaviour.svg')}`} alt="driver"/>
        } else if (ele?.esn) {
          ele.icon = <img class="infoImg" src={`${getImagePath('icons/vehicle-behaviour.svg')}`} alt="vehicle"/>
        }
      }
    })
    return data || [];
  };

  return (
    <Styled.Wrapper>
      <Styled.TopWrapper>
      {topAction && (
        <ViewForm
          apiStatus={apiStatus}
          history={history}
          callback={callback}
          Styled={Styled}
          driverList={driverList}
          vehicleListDropDown={vehicleListDropDown}
          fetchTableData={fetchTableData}
          fetchChartData={fetchChartData}
          fetchDriverBehaviorDetails={fetchDriverBehaviorDetails}
        />
        )}
      </Styled.TopWrapper>

      <div className="basicMeasures">
        <div className="chartContainer">
          {driverbehavior.chartSuccess && chartData && Object.keys(chartData.data).length !== 0 ? (
            <LineChart
              width={'470px'}
              height={'230px'}
              labels={chartData.labels}
              data={chartData.data}
              areagraph
              title={i18n.t('graphTitles.hardDeceleration')}
              displayLegend={false}
              theme={theme}
              noHyperLink
            ></LineChart>
          ) : (
            <div id="noData">
              <div id="nameWrap">
                <p>{i18n.t('graphTitles.hardDeceleration')}</p>
              </div>
              <div id="msgWrap">
                <p>{i18n.t('common.noDataToPlot')}</p>
              </div>
            </div>
          )}
        </div>
      </div>

      <Styled.TableWrapper>
        <Table
          type="client"
          title=""
          columns={[...columns]}
          data={generateRows()}
          emptyMessage={information}
          sizeOfPage={15}
          actions={[]}
          i18n={i18n}
         defaultSortBy={{ sortBy: 'count', order: 'desc' }}
        />
      </Styled.TableWrapper>
    </Styled.Wrapper>
  );
};
// export default Component;

Component.propTypes = {
  history: PropTypes.object.isRequired,
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  fetchViolations: PropTypes.func.isRequired,
  handleExportCsaViolations: PropTypes.func.isRequired
};
