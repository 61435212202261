import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Parent from './style';
const Wrapper = styled(Parent.Wrapper)`
padding: 0;
.formWrap {
  padding-left: 5%;
}
.spireonNewonly {
  display: none;
}
`;
const LeftWrapper = styled(Parent.LeftWrapper)`
flex: 0 0 49.5%;
`;
const RightWrapper = styled(Parent.RightWrapper)`
flex: 0 0 49.5%;
.documentsHeader{
  margin-bottom: 0 !important;
  ul {
    margin-top:0;
  }
}
`;
const documentWrapper = styled(Parent.documentWrapper)`
${({ theme }) => `
padding-right: 2%;
ul {
 margin: 0;
  li {
    .MuiGrid-container .MuiAvatar-rounded {
      width: 27px;
      height: 27px;
    }

  }
}
`}
`;
const submitUser = styled(Parent.submitUser)`
${({ theme }) => `
border-top: 2px solid ${theme.colors.BLACK};
.submitSection {
  justify-content: center;
  padding: 2% 0;
  .MuiButtonBase-root {
    margin-top: 0;
    min-width: 210px;
  }
}
`}

`;
const PolicyProcedure = styled(Parent.PolicyProcedure)`
${({ theme }) => `
ul {
  border: 0 !important;
}
li {
  border-bottom:0 !important;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  padding: 8px 10px;
  &:hover {
    background-color: ${theme.colors.BACKGROUND};
  }
  p:first-child {
   font-family: tahoma;
   align-self: center;
  }
  p:last-child {
    font-size: ${theme.palette.titleLabel};
  }
}
.signedWrapper {
    p {
    text-align: left !important;
  }
  p:first-child {
    // font-family: Yanone Kaffeesatz,tahoma;
    color: #222;
    font-size: ${theme.palette.titleLabel};
    }
    p:last-child {
    font-size: 16px;
    color: ${theme.colors.LIST_TEXT};
    font-family: tahoma;
    }
}
`}
`;
const Styled = {
  Wrapper,
  RightWrapper,
  LeftWrapper,
  documentWrapper,
  submitUser,
  PolicyProcedure
};
export default Styled;
