import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const TopWrapper = styled(Box)`
  ${({ theme }) => `

  display: flex;
  margin: 0 15px;
  @media (max-width: ${theme.breakpoints.values.smd}px) {
    flex-direction: column;
  }
  .AddButton {
    justify-content: flex-end;
    .MuiAvatar-root {
      width: 32px;
      height: 32px;
    }
  }
  `}
`;

const SearchWrapper = styled.div`
  ${({ theme }) => `
  .clear {
    position: static !important;
  }

  flex: 0 0 40%;
  @media (max-width: ${theme.breakpoints.values.mlg}px) {
    flex: 0 0 38%;
  }
  #clearSearch {
    display: none;
  }
  `}
`;

const Styled = {
  SearchWrapper,
  TopWrapper
};
export default Styled;
