import { hasEnabledFeature } from "@/utils/utils";
const isDownloadCenterEnabled = hasEnabledFeature('downloadCenterEnabled')
const leftMenu = [
  {
    label: 'common.account',
    roles: ['FLMANAGER', 'ADMIN', 'DRIVER', 'MECHANIC'],
    path: '/user/profile'
  },
  {
    label: 'leftMenu.companyData',
    roles: ['FLMANAGER', 'ADMIN'],
    path: '/company/edit'
  },

  {
    label: 'leftMenu.DOTAudit',
    roles: ['FLMANAGER', 'ADMIN'],
    path: '/logs/fmcsaLogs'
  },
  {
    label: 'leftMenu.policiesProcedures',
    roles: ['FLMANAGER', 'ADMIN'],
    path: '/policy/edit'
  },
  {
    label: 'leftMenu.documents',
    roles: ['FLMANAGER', 'ADMIN'],
    path: '/company/document/edit'
  },
  {
    label: 'leftMenu.dailyDocuments',
    roles: ['FLMANAGER', 'ADMIN'],
    path: '/dailyDocuments/list'
  },
  ...(isDownloadCenterEnabled ? [{
    label: 'leftMenu.downloadCenter',
    roles: ['FLMANAGER', 'ADMIN'],
    path: '/downloadCenter/list'
  }] : []),
  {
    label: 'common.departments',
    roles: ['FLMANAGER', 'ADMIN'],
    path: '/department/list/default'
  },
  {
    label: 'leftMenu.fuelReport',
    roles: ['FLMANAGER', 'ADMIN'],
    path: '/fuel/list'
  },
  {
    label: 'leftMenu.IFTAReport',
    roles: ['FLMANAGER'],
    path: '/report/mileage'
  },
  {
    label: 'leftMenu.reports',
    roles: ['FLMANAGER', 'ADMIN'],
    path: '/report'
  },
  {
    label: 'leftMenu.freight',
    roles: ['FLMANAGER', 'ADMIN'],
    openInNewTab: true,
    module: 'fleetOpsEnabled'
  },
  {
    label: 'leftMenu.feedback',
    roles: ['FLMANAGER', 'ADMIN', 'DRIVER', 'MECHANIC'],
    path: '/feedback'
  }
];
export default leftMenu;
