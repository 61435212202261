import styled from 'styled-components';
import Parent from '../Base/style';
import { getStyle } from '../../../../utils/utils';

const ButtonContainer = styled(Parent)`
${({ theme }) => `
.MuiButton-root,
.MuiButton-root: hover  {
  background: ${theme.colors.MODAL_LINK};
  color: ${getStyle(
    '',
    (theme.palette.secondary || {}).contrastText,
    theme.colors.SECONDARY_MAIN
  )}
  font-size: ${theme.palette.buttonFont} !important;
}
.MuiButton-root: hover  {
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);
  outline: none;
  cursor: pointer;
}
.reset, .reset: hover {
  background: ${theme.colors.HEADER_TEXT}!important
  border: 2px solid ${theme.colors.BLACK};
}
`}
`;

export default ButtonContainer;
