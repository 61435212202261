import styled from 'styled-components';
import Parent from './style';
const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
padding: 0;
.quickfilter {
    display: block;
    background: -webkit-linear-gradient(top, #d0d8db 0%, #b3bec3 100%);
    color: #f4f6f9;
    padding: 10px 3%;
}
`}
`;
const TableWrapper = styled(Parent.TableWrapper)`
${({ theme }) => `
  padding: 0;
`}
`;
const Styled = { Wrapper, TableWrapper};

export default Styled;
