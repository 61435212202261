import RenderComponent from '@/components/modules/DynamicRenderPage';
import { COOKIE_AUTH_DETAILS } from '@/config/constants/keys';
import { getCookie } from '@/services/cookie';
import { loadPageConfig, loadStyle } from '@/utils/utils';
import PropTypes from 'prop-types';
import React, { lazy, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import ParentStyled from './style';

let Styled = loadStyle(ParentStyled, 'pages/web/NewScorecard');
const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.newScoreCard[0].name}`));
const Content = lazy(() => import(`@/${config.newScoreCard[1].name}`));

const NewScorecard = (parentProps) => {
  const inputref = useRef();
  const i18n = useTranslation();
  const history = useHistory();
  const match = useParams();
  const { fetchDetails, clearScoreCard, } = parentProps;
  const commonParentProps = { history, i18n, Styled };
  const fetchDataApi = 'getData';
  const token = getCookie(COOKIE_AUTH_DETAILS);

  useEffect(() => {
    fetchDetails({ apiId: fetchDataApi, data: { id: match.id || token?.companyId } });
    return () => {
      clearScoreCard();
    }
  }, []);

  return (
    <>
      <RenderComponent
        {...config?.newScoreCard[0]}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        component={Title}
        header={i18n.t(config?.newScoreCard[0].header)}
        i18n={i18n}
      />
      <RenderComponent
        {...config?.newScoreCard[1]}
        companyId={match.id || token?.companyId}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        fetchDetails={fetchDetails}
        component={Content}
        i18n={i18n}
      />
      <div id="tooltip" ref={inputref} className="tooltips"></div>
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    scoreCard: state.newScoreCard.data?.data,
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchDetails: (data) => dispatch.newScoreCard.fetchScoreCard(data),
    clearScoreCard: () => dispatch.newScoreCard.clearScoreCard(),
  };
};

export default connect(mapState, mapDispatch)(NewScorecard);

NewScorecard.propTypes = {
  apiStatus: PropTypes.object.isRequired,
};
