import React, { useEffect, lazy } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useHistory } from 'react-router-dom';
import useTheme from '@material-ui/core/styles/useTheme';
import { useTranslation } from 'react-i18next';

import ParentStyled from './style';
import { loadStyle, getNewObject, loadPageConfig } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'pages/web/Policies');
const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.policyEdit[0].name}`));
const Content = lazy(() => import(`@/${config.policyEdit[1].name}`));

const Policies = (parentProps) => {
  const { fetchPoliciesAndProcedures, getDocumentToken } = parentProps;
  const history = useHistory();
  const theme = useTheme();
  const i18n = useTranslation();

  const apiId = 'fetchPoliciesAndProcedures';
  const getDocumentTokenApiId = 'getDocToken';

  useEffect(() => {
    fetchPoliciesAndProcedures({
      apiId
    });
    getDocumentToken({ apiId: getDocumentTokenApiId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const commonParentProps = { history, i18n, Styled, theme };

  return (
    <>
      <RenderComponent
        {...config.policyEdit[0]}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        component={Title}
        withBackgroundPolicy={window.$environment.CURRENT_LAYOUT === 'SpireonNew'}
      />
      <RenderComponent
        {...config.policyEdit[1]}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        component={Content}
      />
    </>
  );
};

const RenderComponent = ({
  name,
  props,
  commonProps,
  title,
  parentProps,
  commonParentProps,
  component,
  ...rest
}) => {
  const Component = component;
  let newProps = props?.length ? getNewObject(parentProps, props) : {};
  newProps = {
    ...newProps,
    ...(commonProps?.length ? getNewObject(commonParentProps, commonProps) : {})
  };
  return <Component {...rest} {...newProps} commonProps={commonProps} />;
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    policies: state.policies,
    policyDownload: state.policyDownload,
    logBook: state.logBook,
    customPolicy: state.customPolicy,
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchPoliciesAndProcedures: (data) => dispatch.policies.fetchData(data),
    submitPoliciesAndProcedures: (data) => dispatch.policies.saveData(data),
    uploadLogo: (data) => dispatch.documents.saveData(data),
    downloadDocument: (data) => dispatch.policyDownload.downloadDocument(data),
    getDocumentToken: (data) => dispatch.logBook.documentToken(data),
    deleteLogo: (data) => dispatch.documents.deleteData(data),
    clearState: (data) => dispatch.policyDownload.clearState(data),
    fetchCustomPolicy: (data) => dispatch.customPolicy.fetchData(data),
    saveCustomPolicy: (data) => dispatch.customPolicy.saveData(data),
    editCustomPolicy: (data) => dispatch.customPolicy.editData(data),
    handleDeleteCustomPolicyAlert: (data) => dispatch.customPolicy.deleteData(data),
    downloadCustomPolicy: (data) => dispatch.customPolicy.downloadCustomPolicy(data)
  };
};

export default connect(mapState, mapDispatch)(Policies);
Policies.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  policies: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  fetchPoliciesAndProcedures: PropTypes.func.isRequired,
  submitPoliciesAndProcedures: PropTypes.func.isRequired,
  saveCustomPolicy: PropTypes.func.isRequired,
};
