import Styled from 'styled-components';

const PrivacyWrapper = Styled.div`
${({ theme }) => `
  padding: 10px;
  font-family: Arial;
  background: ${theme.colors.WHITE};

  h1 {
      text-align: center;
      font-size: 24px;
  }
  .contentWrapper {
    padding: 20px 30px;
    font-size: 12px;
    a {
      color: ${theme.colors.LINK_COLOR};
      text-decoration: none;
    }
  }
  min-height: calc(100vh - 77px);
`}
`;

export default PrivacyWrapper;
