import styled from 'styled-components';
import Parent from '../Base/style';
const Container = styled(Parent.Container)`
  ${({ theme }) => `
a {
    color: #495964 !important;
    text-decoration: none;
    &:hover {
      color: #76828b !important;
        text-decoration: underline !important;
    }
}
.active {
    background-color: ${theme.colors.ACTIVE_ROW}!important;
    border: 0 !important;
}

.MuiTable-root {
    border: 0;
}
.nodataspireon{
    height: 30px !important;
}
.MuiPaper-root {
    box-shadow: none;
}
.MuiTableSortLabel-active .MuiTableSortLabel-iconDirectionAsc {
  left: -4px;
  top: -11px !important;
}
  .MuiTableSortLabel-active .MuiTableSortLabel-iconDirectionDesc {
    left: -4px;
    top: -7px !important;
  }
.MuiTableSortLabel-active .MuiTableSortLabel-iconDirectionAsc,
.MuiTableSortLabel-active .MuiTableSortLabel-iconDirectionDesc {
  svg {
    width: 1.9rem;
    height: 1.6rem;
    color: #8A8A8A;
    top: -2px;
  }
}
.MuiTableSortLabel svg {
    position: relative;
    top: 0px;
}
#sort {
    fill: #DCDCDC;
}
.MuiTableHead-root {
    .MuiTableRow-root:hover {
        background-color: #ffffff;
    }
    .MuiTableCell-head {
        font-size: 12px;
        padding: 10px 15px 10px 25px;
        color: #000 !important;
        line-height:1.16 !important;
        border-width: 0 1px 1px 0;
        vertical-align: middle;
        border-color: #ededed;
        border-style: solid;
        .MuiTableSortLabel-root {
            color: #000 !important;
        }
    }
    .MuiTableCell-root {
        border-bottom: 0;
        font-size: 12px;
    }
}
.MuiTableSortLabel-iconDirectionDesc {
    color: #DCDCDC !important;
}
.MuiTableBody-root{
    td {
        color: #222;
    }
    tr:hover {
        background-color: #dadada !important;
    }
    tr:nth-child(even) {
        background-color: #ffffff;
    }
    tr:nth-child(odd) {
        background-color: #efefef;
        .active {
            background: #f0dfd0 !important;
        }
    }
    .MuiTableCell-root {
        border: 0;
        font-size: 12px;
        color: #495964;
        line-height: 1.16 !important;
    }
} 
`}
`;
const TableContainer = styled(Parent.TableContainer)`
  overflow-x: auto;
`;
const PaginationContainer = styled(Parent.PaginationContainer)`
  .MuiTablePagination-root {
    .MuiTablePagination-caption {
      font-size: 12px;
      opacity: 0.54;
    }
    .MuiTablePagination-actions {
      .MuiButtonBase-root {
        padding: 0;
      }
      svg.MuiSvgIcon-root {
        width: 30px;
        height: 30px;
      }
    }
    .MuiTablePagination-selectRoot {
      .MuiSelect-root.MuiSelect-select {
        color: #000;
        opacity: 0.54;
        font-size: 12px;
        padding-top: 6px;
        padding-right: 29px;
      }
      svg {
        width: 30px;
        height: 30px;
        top: -1px;
      }
    }
    .MuiSelect-select:focus {
      background-color: transparent;
    }
  }
`;
const PaginationWrapper = styled(Parent.PaginationWrapper)`
  .MuiToolbar-gutters {
    display: flex;
    min-height: 36px;
    background-image: -webkit-linear-gradient(top, #d0d8db 0%, #b3bec3 100%);
    padding-left: 0;
    .MuiTablePagination-spacer {
      display: none;
    }
    .MuiButtonBase-root.MuiIconButton-root {
      padding: 2px;
    }
    .MuiSelect-select.MuiSelect-select{
      padding: 2px 22px 1px 4px;
      font-size: 12px;
      font-weight: normal;
      background: #fff;
    }
    .MuiTablePagination-input.MuiTablePagination-selectRoot {
      svg {
        width: 15px;
        height: 15px;
        top: 2px;
      }
    }
    .MuiButtonBase-root.MuiIconButton-root[aria-label="next page"] {
      border-left: 1px solid #acb8be;
      border-radius: 0;
    }
    .MuiButtonBase-root.MuiIconButton-root[aria-label="last page"],
    .MuiButtonBase-root.MuiIconButton-root[aria-label="previous page"] {
      border-right: 1px solid #acb8be;
      border-radius: 0;
    }
  }

  .MuiTablePagination-caption {
    order: 3;
    font-size: 12px;
  }
  .MuiTablePagination-selectRoot + .MuiTablePagination-caption {
    position: absolute;
    right: 20px;
    opacity: 0.54;
    color: #000000 !important;
  }
  [id^='mui-'] {
    order: 1;
    color: #333;
    font-size: 11px;
    font-weight: bold;
    padding-left: 6px;
  }
  .MuiTablePagination-selectRoot {
    order: 2;
  }
  .pagenoLabel {
    font-size: 11px;
    font-weight: bold;
    color: #42505a !important;
    padding-right: 10px;
    padding-left: 7px;
  }
  .pageInput {
    width: 30px;
    margin-right: 7px;
    .MuiInputBase-input {
      padding-left: 3px;
    }
  }
`;
const Styled = {
  Container,
  TableContainer,
  PaginationContainer,
  PaginationWrapper
};

export default Styled;
