import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import { getImagePath } from '../../../../../utils/utils';
const Wrapper = styled(Box)`
${({ theme }) => `
.welcomeText {
  padding-top: 3%;
  padding-right:3%;
  padding-bottom: 5%;
}
.details {
  opacity: 0.67;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  padding-top: 20px;
}
.downloadPdfWrapper {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.8px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
  padding-bottom: 15px;
  padding-top: 29px;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  justify-content: space-between;
}
.serialNo {
  background: rgba(255, 255, 255, 0.15);
  padding: 10px 16px;
  border-radius: 4px;
}
.items {
  padding-left: 16px;
}
.download {
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  background:  url(${getImagePath('icons/download.svg')}) no-repeat
    left center;
  background-position: 14px;
  padding: 12px 9px;
  border-radius: 4px;
  border:0;
  text-align: right;
  // min-width: 160px;
  border: 1px solid #fff;
  height: 39px;
  .MuiButton-label {
    padding-left: 29px;
  }
  a {
    color: #fff;
    text-decoration: none;
  }
}
.headingWrapper {
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  justify-content: space-between;
  .downloadAll {
    margin-top: 25px;
  }
}
  `}
`;

const Styled = { Wrapper };

export default Styled;