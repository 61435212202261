import React from 'react';
import Typography from '@material-ui/core/Typography';

const CustomHeading = (props) => {
  return (
    <Typography
      variant={props.variant ? props.variant : 'inherit'}
      align={props.align}
      color={props.type ? props.type : 'initial'}
    >
      {props.message}
    </Typography>
  );
};

export default CustomHeading;
