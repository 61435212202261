import React, { lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import { UI_DATE_FORMAT } from '@/config/constants/static';

import { getImagePath, hasValue } from '@/utils/utils';
import { imageUrls, pageUrls } from '@/config/constants/keys';
const layout = window?.$environment?.CURRENT_LAYOUT;
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Modal = require(`@/components/UI/Modal/${layout}`).default;
const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Component {...contentProps} />
    </Box>
  );
};
export default Index;

const Component = ({
  developerRequest,
  Styled,
  approveData,
  denyData,
  callback
}) => {
  const { i18n } = useTranslation();
  const columns = [
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('Description')
    },
    {
      id: 'driver',
      numeric: false,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('developerRequests.developerName')
    },
    {
      id: 'date',
      numeric: false,
      disablePadding: false,
      needSorting: true,
      label: i18n.t('common.date')
    },
    {
      id: 'actions'
    }
  ];
  const generateRows = () => {
    const updatedList = developerRequest?.map((item) => {
      item.date = item.date ? moment(item.date).format("MM/DD/YYYY hh:mm:ss") : '';
      return item;
    });
    return updatedList;
  };
  const information = i18n.t('common.tableEmptyMessage');
  const [ModalListObject, setModalListObject] = useState({
    header: '',
    content: () => {}
  });
  const [open, setOpen] = useState(false);

  const closeModal = (event) => {
    setOpen(false);
  };
  const clickedModal = (e) => {
    setOpen(true);
  };
  return (
    <>
      <Styled.Wrapper>
        <div className="quickfilter"></div>
        <Styled.TableWrapper>
          <Table
            type="client"
            title=""
            columns={[...columns]}
            data={generateRows()}
            emptyMessage={information}
            defaultSortBy={{ sortBy: 'date', order: 'desc' }}
            i18n={i18n}
            actions={[
              {
                icon: (row, index) => {
                  return (
                    <Icon
                      src={getImagePath(imageUrls.tickIcon)}
                      onClick={() => {
                        setModalListObject({
                          content: () => (
                            <div className="deleteParent">
                              <div className="imageSection">
                                <img
                                  src={getImagePath(imageUrls.confirmIcon)}
                                  alt="Are you sure"
                                />
                              </div>
                              <h2> {i18n.t('common.sureConfirm')} </h2>
                              <div className="deletePopupBtn">
                                <div className="cancelBtn">
                                  <Button
                                    label={i18n.t('Cancel')}
                                    type={'reset'}
                                    onClick={() => {
                                      closeModal();
                                    }}
                                  ></Button>
                                </div>
                                <div className="deletebtn">
                                  <Button
                                    label={i18n.t('common.acceptPopup')}
                                    onClick={() => {
                                      approveData({
                                        apiId: 'approveData',
                                        data: {
                                          id: row.id
                                        },
                                        callback: callback
                                      });
                                      closeModal();
                                    }}
                                  ></Button>
                                </div>
                              </div>
                            </div>
                          )
                        });
                        clickedModal();
                      }}
                    />
                  );
                }
              },
              {
                icon: (row, index) => {
                  return (
                    <Icon
                      src={getImagePath(imageUrls.deleteIcon)}
                      onClick={() => {
                        setModalListObject({
                          content: () => (
                            <div className="deleteParent">
                              <div className="imageSection">
                                <img
                                  src={getImagePath(imageUrls.confirmIcon)}
                                  alt="Are you sure"
                                />
                              </div>
                              <h2> {i18n.t('common.sureConfirm')} </h2>
                              <div className="deletePopupBtn">
                                <div className="cancelBtn">
                                  <Button
                                    label={i18n.t('Cancel')}
                                    type={'reset'}
                                    onClick={() => {
                                      closeModal();
                                    }}
                                  ></Button>
                                </div>
                                <div className="deletebtn">
                                  <Button
                                    label={i18n.t('common.deletePopup')}
                                    onClick={() => {
                                      denyData({
                                        apiId: 'denyData',
                                        data: {
                                          id: row.id
                                        },
                                        callback: callback
                                      });
                                      closeModal();
                                    }}
                                  ></Button>
                                </div>
                              </div>
                            </div>
                          )
                        });
                        clickedModal();
                      }}
                    />
                  );
                }
              }
            ]}
          />
        </Styled.TableWrapper>
      </Styled.Wrapper>
      <Modal open={open} setOpen={setOpen} data={ModalListObject} deletePopup />
    </>
  );
};

// export default Component;

Component.propTypes = {
  developerRequests: PropTypes.array.isRequired
};
