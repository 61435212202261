import styled from 'styled-components';
import Parent from './style';
const Wrapper = styled(Parent.Wrapper)`
${({ theme }) => `
padding: 0;
.quickfilter {
  display: block;
  background: ${theme.colors.LINK_COLOR};
  height: 20px;
}
`}
`;
const TableWrapper = styled(Parent.TableWrapper)`
${({ theme }) => `
  padding: 0;
  .MuiPaper-root {
    padding: 0 3%;
  }
`}
`;
const Styled = { Wrapper, TableWrapper};

export default Styled;
