import styled from 'styled-components';
import Parent from '../Base/style';
const CheckboxWrapper = styled(Parent)`
${({ theme }) => `
.normalCheckbox {
    svg {
        width: 18px;
        height: 18px;
    }
    rect.square {
        stroke: #010101;
        fill: #fff;
        rx: 2;
        ry: 2;
        opacity: 0.54;
    }
    label {
      font-size: 15px;
    }
    .Checkbox.checked .square {
        fill-opacity: 1;
        fill: ${theme.colors.SECONDARY_MAIN};
        stroke: ${theme.colors.SECONDARY_MAIN};
    }
  }
  `}
`;
export default CheckboxWrapper;
