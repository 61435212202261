import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import RenderComponent from './RenderComponent';

import {
  getListOfSelectedField,
  objectArrayFromDiffFields,
  objectArrayFromMultipleFields,
  objectArrayFromSameField
} from '@/utils/utils';

import Styled from './style';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));

const Component = ({
  closeModal,
  saveTerminal,
  callback,
  currentItem,
  isEdit,
  data,
  getTmezonePopup,
  isCrudRestricted,
  isButtonStyle,
  isShowDotNumber,
  neglectAccessLevel
}) => {
  const { i18n } = useTranslation();
  const saveTerminalApi = 'saveTerminalApi';

  const fleetmanagerCallback = (item, value, field) => {
    if (currentItem?.fleetManagerIds?.includes(item[value]))
      return {
        label: `${item[field[0]]} ${item[field[1]]}`,
        value: item[value],
        disabled: true
      };
    else
      return {
        label: `${item[field[0]]} ${item[field[1]]}`,
        value: item[value]
      };
  };
  const fleetManagerList = objectArrayFromMultipleFields(
    data?.data?.fleetManagers,
    'id',
    ['firstName', 'lastName'],
    fleetmanagerCallback
  );

  const regionList = objectArrayFromDiffFields(
    data?.data?.regionsDtos,
    'id',
    'regionName'
  );
  const states = objectArrayFromSameField(data?.data?.arrayOfStates);

  const dotNumbers = objectArrayFromSameField(data?.data?.dotNumbers);
  return (
    <Styled.Wrapper>
      <Form
        type="server"
        direction="column"
        initialValues={{
          terminalId: currentItem?.terminalId || '',
          regionId:
            regionList.filter(
              (item) => item?.value === currentItem?.regionId
            )[0] || regionList[0],
          terminalName: currentItem?.terminalName || '',
          address: currentItem?.address || '',
          city: currentItem?.city || '',
          state: currentItem?.state
            ? { label: currentItem?.state, value: currentItem?.state }
            : states[0],
          zipCode: currentItem?.zipCode || '',
          fleetManagerIds:
            fleetManagerList?.filter((item) =>
              currentItem?.fleetManagerIds?.includes(item.value)
            ) || [],
          timeZone: currentItem?.timeZone || '',
          dotNumber: currentItem?.dotNumber
            ? { label: currentItem?.dotNumber, value: currentItem?.dotNumber }
            : ''
        }}
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {
          let finalData = {
            regionId: fields.regionId?.value || null,
            terminalName: fields.terminalName || null,
            address: fields.address || null,
            city: fields.city || null,
            state: fields.state?.value || null,
            zipCode: fields.zipCode || null,
            dotNumber: fields.dotNumber?.value || ''
          };
          if (fields?.fleetManagerIds?.length) {
            finalData.fleetManagerIds =
              getListOfSelectedField(fields.fleetManagerIds, 'value') || null;
          }
          if (fields.terminalId) finalData['terminalId'] = fields.terminalId;
          saveTerminal({
            apiId: saveTerminalApi,
            data: {
              ...finalData
            },
            callback: getTmezonePopup,
            i18n: i18n
          });
          closeModal();
        }}
        customProps={{
          fleetManagerList,
          isEdit,
          regionList,
          states,
          dotNumbers,
          isButtonStyle,
          isCrudRestricted,
          isShowDotNumber,
          neglectAccessLevel
        }}
        handleClear={() => {}}
        validationSchema={Yup.object().shape({
          terminalName: Yup.string().required(
            i18n.t('common.fieldRequiredMessage')
          ),
          address: Yup.string().required(i18n.t('common.fieldRequiredMessage')),
          city: Yup.string().required(i18n.t('common.fieldRequiredMessage')),
          zipCode: Yup.string().required(i18n.t('common.fieldRequiredMessage'))
        })}
      />
    </Styled.Wrapper>
  );
};

export default Component;

Component.propTypes = {
  apiStatus: PropTypes.object,
  callback: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  saveTerminal: PropTypes.func,
  currentItem: PropTypes.object
};
