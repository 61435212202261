import styled from 'styled-components';
import Parent from './style';

const Wrapper = styled(Parent.Wrapper)``;
const Profile = styled(Parent.Profile)`
  .section-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
    ${window?.$environment?.clutchNewUI &&
    `
    background-color: #fff;
    padding: 2% 3%;
    margin-bottom: 30px;
    border-radius: 5px;
`}
    .userHeading {
      ${window?.$environment?.clutchNewUI && `
      padding-left: 0 !important;
      margin-top: 0;`}
    }
    .heading {
        ${window?.$environment?.clutchNewUI && `
         padding-left: 0 !important;
         margin-top: 0;`}
         text-transform: capitalize;
      }
  }
`;
const Styled = { Wrapper, Profile };
export default Styled;
