import React, { lazy, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

import Table from '../Dashboard/VehicleStatus';
import TopActions from './TopActions';
const layout = window?.$environment?.CURRENT_LAYOUT;

const Breadcrumb = lazy(() => import(`@/components/Breadcrumb`));
const GoogleMap = lazy(() => import(`@/components/GoogleMap/${layout}`));

const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <>
      <Box style={{ ...theme.palette.contentStyle }}>
        <Breadcrumb filters={filters} />
        <Component {...contentProps} />
      </Box>
    </>
  );
};
export default Index;

const Component = ({ vehicleStatus, i18n, Styled, topAction, pagination,
  setPagination, filterSuccess }) => {
  const viewOnMapClicked = (e) => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    setviewOnMap(e);
  };
  const [viewOnMap, setviewOnMap] = useState(null);

  return (
    <>
      {topAction && <TopActions {...{ Styled, vehicleStatus, i18n, filterSuccess, setPagination }} />}
      <Styled.Wrapper>
        {window.$environment?.SERVICE_PROVIDER !== 'cyntrx' && <Styled.MapWrapper>
          <GoogleMap
            type={'vehicleStatusMap'}
            height={'400px'}
            data={vehicleStatus?.vehicleStatus}
            viewOnMap={viewOnMap}
          />
          <div className="quickFilter">Vehicle Status</div>
        </Styled.MapWrapper>}
        <Styled.TableWrapper>
          <Table
            vehicleStatus={vehicleStatus?.vehicleFilteredList}
            i18n={i18n}
            viewOnMap={(e) => viewOnMapClicked(e)}
            pageinationReset={pagination}
            pagenationClicked={(e) => {
              setPagination(!e);
            }}
          />
        </Styled.TableWrapper>
      </Styled.Wrapper>
    </>
  );
};

Component.propTypes = {
  vehicleStatus: PropTypes.object.isRequired,
  filterSuccess: PropTypes.func.isRequired
};
