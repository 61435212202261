import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import RenderComponent from './RenderComponent';

import {
  getListOfSelectedField,
  objectArrayFromSameField,
  objectArrayFromMultipleFields
} from '@/utils/utils';

import Styled from './style';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));

const Component = ({
  closeModal,
  saveSubDepartment,
  currentItem,
  isEdit,
  data,
  getTmezonePopup,
  isCrudRestricted,
  commonProps,
  isShowDotNumber,
  neglectAccessLevel
}) => {
  const { i18n } = useTranslation();
  const saveSubDepartmentApi = 'saveSubDepartmentApi';

  const fleetmanagerCallback = (item, value, field) => {
    if (currentItem?.fleetManagerIds?.includes(item[value]))
      return {
        label: `${item[field[0]]} ${item[field[1]]}`,
        value: item[value],
        disabled: true
      };
    else
      return {
        label: `${item[field[0]]} ${item[field[1]]}`,
        value: item[value]
      };
  };
  const fleetManagerList = objectArrayFromMultipleFields(
    data?.data?.fleetManagers,
    'id',
    ['firstName', 'lastName'],
    fleetmanagerCallback
  );
  const states = objectArrayFromSameField(data?.data?.arrayOfStates);

  const dotNumbers = objectArrayFromSameField(data?.data?.dotNumbers);
  return (
    <Styled.Wrapper>
      <Form
        type="server"
        direction="column"
        initialValues={{
          subDepartmentId: currentItem?.subDepartmentId,
          departmentId: currentItem?.departmentId || '',
          departmentName: currentItem?.departmentName || '',
          subDepartmentName: currentItem?.subDepartmentName || '',
          address: currentItem?.address || '',
          city: currentItem?.city || '',
          state: currentItem?.state
            ? { label: currentItem?.state, value: currentItem?.state }
            : states[0],
          zipCode: currentItem?.zipCode || '',
          fleetManagerIds: currentItem?.subDepartmentId
            ? fleetManagerList?.filter((item) =>
                currentItem?.fleetManagerIds?.includes(item.value)
              ) || []
            : [],
          timeZone: currentItem?.timeZone || '',
          dotNumber: currentItem?.dotNumber
            ? { label: currentItem?.dotNumber, value: currentItem?.dotNumber }
            : ''
        }}
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {
          let finalData = {
            subDepartmentName: fields.subDepartmentName,
            address: fields.address || null,
            city: fields.city || null,
            state: fields.state?.value || null,
            zipCode: fields.zipCode || null,
            departmentId: fields.departmentId,
            dotNumber: fields.dotNumber?.value || ''
          };
          if (fields?.fleetManagerIds?.length) {
            finalData.fleetManagerIds =
              getListOfSelectedField(fields.fleetManagerIds, 'value') || null;
          }
          if (fields.subDepartmentId)
            finalData['subDepartmentId'] = fields.subDepartmentId;
          saveSubDepartment({
            apiId: saveSubDepartmentApi,
            data: {
              ...finalData
            },
            callback: getTmezonePopup,
            i18n: i18n
          });
          closeModal();
        }}
        customProps={{
          fleetManagerList,
          isEdit,
          states,
          dotNumbers,
          isCrudRestricted,
          commonProps,
          isShowDotNumber,
          neglectAccessLevel
        }}
        handleClear={() => {}}
        validationSchema={Yup.object().shape({
          subDepartmentName: Yup.string().required(
            i18n.t('common.fieldRequiredMessage')
          ),
          zipCode: Yup.string().required(i18n.t('common.fieldRequiredMessage'))
        })}
      />
    </Styled.Wrapper>
  );
};

export default Component;

Component.propTypes = {
  apiStatus: PropTypes.object,
  callback: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  saveSubDepartment: PropTypes.func,
  currentItem: PropTypes.object
};
